import _ from "lodash";
import {
    AlignedData,
    DeliveryChannel,
    Payment,
    ReducePrinter,
    Today,
    Values,
    Vat as DashboardVat,
    ObjDataData
} from "../../models/dashboard.model";

import { Channels, Vat } from "tilby-models";
import { MathUtils } from "@tilby/tilby-ui-lib/utilities";

export const findNames = (printer: ReducePrinter, sub_typology: keyof ReducePrinter, deliveryChannels: Channels[]) => {
    if (sub_typology.toLowerCase().indexOf(TICKET[0]) > -1) {
        return defaultValue(printer.payments.filter(p => !!p.ticket_name).map(p => p.ticket_name), sub_typology);
    } else if (CASH_MOVEMENTS_CATEGORIES.indexOf(sub_typology.toLowerCase()) > -1) {
        return sub_typology === 'sales'
            ? CASH_MOVEMENTS_SALES_CATEGORIES
            : [sub_typology];
    } else if (['summary_nrc_invoices', 'credit_notes', ...REFUNDS_VOIDS_CATEGORIES, ...claimedPaymentsNames(printer), ...unclaimedPaymentsNames(printer)].indexOf(sub_typology) > -1) {
        return [sub_typology];
    } else if (DEPARTMENTS.indexOf(sub_typology) > -1) {
        return defaultValue(printer.departments.map(d => d.name), sub_typology);
    } else if (CHANNELS.indexOf(sub_typology) > -1) {
        return defaultValue(Object.values(printer.channels||[]).map(d => deliveryChannels?.find(c => c.id === d.channel)?.name), sub_typology);
    }
    else if (printer[sub_typology]) {
        const vats: string[] = printer[sub_typology].vats.map((vat: DashboardVat) => vat.name);
        const unclaimed = 'unclaimed' in printer[sub_typology] ? ['unclaimed'] : [];
        return defaultValue(vats.concat(unclaimed), sub_typology);
    }
    return []
};
export const findValues = (printer: ReducePrinter, sub_typology: keyof ReducePrinter, effective_typology: keyof AlignedData, today: Today, n_of_printers: number, deliveryChannels:DeliveryChannel[]): Values | null => {
    /**
     * confronto con sub_typology se è un gruppo e confronto con effective_typology se è una elemento
     * se sub_typology ed effective_typology sono uguali allora il livello di mezzo viene tolto
     */
    if (sub_typology?.toLowerCase().indexOf('ticket') > -1) {
        return {
            name: effective_typology,
            value: printer.payments?.find(p => p.ticket_name === effective_typology)?.amount || 0,
            count: printer.cash_movements?.tickets?.find(t => t.name === effective_typology)?.count || 0,
        };
    } else if (effective_typology === 'unclaimed' && printer[sub_typology]) {
        return {name: 'unclaimed', value: printer[sub_typology].unclaimed || 0};
    } else if (effective_typology === 'summary_nrc_invoices') {
        return {
            name: 'summary_nrc_invoices',
            value: sumAmounts(printer[sub_typology]?.vats),
            count: printer[sub_typology]?.count || 0
        };
    } else if (effective_typology === 'credit_notes') {
        return {
            name: 'credit_notes',
            value: justOnce(n_of_printers, printer.name, today.credit_notes?.amount || 0),
            count: printer[sub_typology]?.count || 0
        };
    } else if (REFUNDS_VOIDS_CATEGORIES.indexOf(effective_typology as keyof ReducePrinter) > -1) {
        return {
            name: effective_typology,
            value: printer[<keyof ReducePrinter>effective_typology]?.amount || 0,
            count: printer[sub_typology]?.count || 0
        };
    } else if (claimedPaymentsNames(printer).indexOf(effective_typology) > -1) {
        return {name: effective_typology, value: claimedPaymentsValues(printer)?.[effective_typology] || 0};
    } else if (unclaimedPaymentsNames(printer).indexOf(sub_typology) > -1) {
        return sub_typology.toLowerCase().indexOf('ticket') > -1
            ? {
                name: effective_typology,
                value: printer.payments?.find(p => p.ticket_name === effective_typology)?.amount || 0
            }
            : {name: effective_typology, value: unclaimedPaymentsValues(printer)?.[effective_typology] || 0};
    } else if (unclaimedPaymentsNames(printer).indexOf(sub_typology) > -1) {
        return {name: effective_typology, value: unclaimedPaymentsValues(printer)?.[effective_typology] || 0};
    } else if (CASH_MOVEMENTS_CATEGORIES.indexOf(sub_typology) > -1) {
        const isSales_sum = effective_typology === CASH_MOVEMENTS_SALES_CATEGORIES[0];
        return sub_typology === 'sales'
           ? {
                    name: effective_typology,
                    value: isSales_sum? (printer.cash_movements?.sales ?? 0): justOnce(n_of_printers, printer.name, today.cash_movements.total[<'income' | 'outcome'>effective_typology]?.amount ?? 0),
                    ...(!isSales_sum&&{count: justOnce(n_of_printers, printer.name, today.cash_movements.total[<'income' | 'outcome'>effective_typology]?.count ?? 0)})
            }
            : {name: effective_typology, value: printer.other_changes_amount || 0};
    } else if (DEPARTMENTS.indexOf(sub_typology) > -1) {
        return {
            name: effective_typology,
            value: printer.departments?.find(d => d.name === effective_typology)?.amount ?? 0
        };
    } else if (CHANNELS.indexOf(sub_typology) > -1) {
        return {
            name: effective_typology,
            value: Object.values(printer.channels||[])?.find(d => deliveryChannels?.find(c => c.id === d.channel)?.name === effective_typology)?.amount ?? 0
        };
    } else if (printer[sub_typology]) {
        const vat = printer[sub_typology].vats?.find((v: any) => v.name === effective_typology)
        return {name: vat?.name, value: vat?.amount || 0, parentCount: printer[sub_typology].count};
    }
    return null;
};
export const TICKET = ['ticket'];
export const TOTAL = ['total'];
export const TOTAL_CATEGORIES: (keyof ReducePrinter|string)[] = ['fiscal_receipts', 'invoices', 'summary_rc_invoices', 'summary_nrc_invoices', 'credit_notes'];
export const REFUNDS_VOIDS = ['refunds_voids'];
export const REFUNDS_VOIDS_CATEGORIES: (keyof ReducePrinter)[] = ['refunds', 'voids'];
export const CLAIMED_PAYMENTS_AMOUNT = ['claimed_payments_amount'];
export const UNCLAIMED_PAYMENTS_AMOUNT = ['unclaimed_payments_amount'];
export const CASH_MOVEMENTS = ['cash_movements'];
export const CASH_MOVEMENTS_CATEGORIES = ['sales', ...TICKET, 'other_changes_amount'];
export const CASH_MOVEMENTS_SALES_CATEGORIES = ['sales_sum', 'income', 'outcome'];
export const DEPARTMENTS: (keyof ReducePrinter)[] = ['departments'];
export const CHANNELS: (keyof ReducePrinter)[] = ['channels'];
export const PRICE_CHANGES: (keyof ReducePrinter)[] = ['price_changes'];
export const SURCHANGES = ['surchanges'];
export const WASTES = ['wastes'];
export const FOOD_COST: (keyof ReducePrinter)[] = ['food_cost'];
export const ORDERS: (keyof ReducePrinter)[] = ['orders'];
export const DELETED_ORDERS = ['deleted_orders'];
export const claimedPaymentsNames = (printer: ReducePrinter) => {
    const payments = printer.payments?.reduce((s: string[], a: Payment) => !a.unclaimed && a.name ? ([...s, a.name]) : s, []) || [];
    return defaultValue(payments, 'contanti');
}
export const claimedPaymentsValues = (printer: ReducePrinter) => printer.payments?.reduce((s: any, a: Payment) => !a.unclaimed && a.name ? {
    ...s,
    [a.name]: ((a.amount || 0) + (s.name || 0))
} : s, {}) || [];
export const unclaimedPaymentsNames = (printer: ReducePrinter) => {
    const allUnclaimedPayments: Payment[] = printer.payments?.reduce((s: Payment[], a: Payment) => a.unclaimed ? ([...s, a]) : s, []) || [];
    const allUnclaimedPaymentWithJustOneTicket = [...(allUnclaimedPayments?.find(u => u.ticket_name) ? [<Payment>allUnclaimedPayments?.find(u => u.ticket_name), ...allUnclaimedPayments.filter(u => !u.ticket_name)] : [...allUnclaimedPayments.filter(u => !u.ticket_name)])] || [];
    return defaultValue(allUnclaimedPaymentWithJustOneTicket.map(p => p?.name) || [], 'ticket');
};
export const unclaimedPaymentsValues = (printer: ReducePrinter) =>
    printer.payments?.reduce((s: any, a: Payment) => a.unclaimed && a.name ? {
        ...s,
        [a.name]: ((a.amount || 0) + (s.name || 0))
    } : s, {}) || [];

export const exempts = {
    0: "ES N4",
    10: "EE N1",
    11: "NS N2",
    12: "NI N3",
    13: "RM N5",
    14: "AL N6",
    15: "VI"
};
export const getVatName = (vatName: string) => (exempts[<keyof typeof exempts><unknown>vatName] || `${vatName}%` || "");
export const getVatValue = (vats: Vat[], vatName:string|number) => (exempts[<keyof typeof exempts><any>vatName] || `${vats[<number>vatName]?.value}%` || "");

export const sumAmounts = (vats: DashboardVat[]=[]) => vats.map((v: any) => v.amount).reduce((s: number, a: number) => s + a, 0) || 0;
export const sumDiscounts = (printer: ReducePrinter) => printer.price_changes?.reduce((sum, change) => ['discount_fix', 'discount_perc'].includes(change.type) ? sum + change.amount : sum, 0);
export const sumSurcharges = (printer: ReducePrinter) => printer.price_changes?.reduce((sum, change) => ['surcharge_fix', 'surcharge_perc'].includes(change.type) ? sum + change.amount : sum, 0);


const defaultValue = (arr: any[], defaultValue = '--') => !!arr?.length ? arr : [defaultValue];
export const justOnce = (n_of_printers: number, printerName: string, value: number, defaultValue = '') => (n_of_printers <= 2 || printerName?.toLowerCase() === 'totale') ? value : defaultValue;

export const alignDataPrinters = (objDataData: ObjDataData, deliveryChannels: Channels[]) => {
    const printers: ReducePrinter[] = objDataData.printers as ReducePrinter[];
    const n_of_printers = printers.length;
    /**
     * typology è il livello padre
     * sub_typology è il livello figlio
     * effective_typology è il livello figlio del figlio
     */
    const columnsModel:{typologies?:string[],sub_typologies?:(keyof ReducePrinter|string)[],effective_typologies:(printer:ReducePrinter,sub_typologiy:keyof ReducePrinter,deliverychannels:Channels[])=>any[],values: (printer: ReducePrinter, sub_typology: keyof ReducePrinter, effective_typology: keyof AlignedData, today: Today, n_of_printers: number, deliveryChannels:DeliveryChannel[])=> Values|number|string | null}[] = [
        {
            typologies: TOTAL,
            sub_typologies: TOTAL_CATEGORIES,
            effective_typologies: findNames,
            values: findValues,
        },
        {
            typologies: REFUNDS_VOIDS,
            sub_typologies: REFUNDS_VOIDS_CATEGORIES,
            effective_typologies: findNames,
            values: findValues,
        },
        {
            typologies: CLAIMED_PAYMENTS_AMOUNT,
            sub_typologies: claimedPaymentsNames(printers[0]),
            effective_typologies: findNames,
            values: findValues,
        },
        {
            typologies: UNCLAIMED_PAYMENTS_AMOUNT,
            sub_typologies: unclaimedPaymentsNames(printers[0]),
            effective_typologies: findNames,
            values: findValues,
        },
        {
            typologies: CASH_MOVEMENTS,
            sub_typologies: CASH_MOVEMENTS_CATEGORIES,
            effective_typologies: findNames,
            values: findValues,
        },
        {
            sub_typologies: DEPARTMENTS,
            effective_typologies: findNames,
            values: findValues,
        },
        {
            sub_typologies: CHANNELS,
            effective_typologies: findNames,
            // effective_typologies: [/!*...channel.getChannelNames()*!/],
            values: findValues,
        },
        {
            sub_typologies: PRICE_CHANGES,
            effective_typologies: () => PRICE_CHANGES,
            values: sumDiscounts,
        },
        {
            sub_typologies: SURCHANGES,
            effective_typologies: () => SURCHANGES,
            values: sumSurcharges,

        },
        {
            sub_typologies: WASTES,
            effective_typologies: () => WASTES,
            values: (printer: ReducePrinter, _: keyof AlignedData) => justOnce(n_of_printers,printer.name,objDataData.today.waste || 0),
        },
        {
            sub_typologies: FOOD_COST,
            effective_typologies: () => FOOD_COST,
            values: (printer: ReducePrinter, _: keyof AlignedData) => printer.food_cost || 0,

        },
        {
            sub_typologies: ORDERS,
            effective_typologies: () => DELETED_ORDERS,
            values: (printer: ReducePrinter, _: keyof AlignedData) => justOnce(n_of_printers,printer.name,objDataData.today.deleted_orders || 0),

        },

    ];
    return columnsModel.flatMap(({typologies = [], sub_typologies = [], effective_typologies, values}) => {
        const result = sub_typologies.flatMap((sub_typology) =>
            effective_typologies(printers[0], <keyof ReducePrinter>sub_typology,deliveryChannels).map((effective_typology: any) => {
                const x: {orgHierarchy:string[]} = {
                    orgHierarchy: [...((typologies[0]) ? [typologies[0]] : []), ...((sub_typology !== effective_typology) ? [sub_typology] : []), effective_typology],
                    // typology: typologies[0],
                    // ...(sub_typology === effective_typology|| sub_typology===typologies[0] ? {sortValue:'end'}:{sub_typology}),
                    // effective_typology,
                }
                printers.forEach((p, i, printers) => {
                    if (p.name?.toLowerCase() && values) {
                        if (printers.length === 2 && i === 0) {
                            return;
                        }
                        // @ts-ignore
                        x[p.name?.replace(/\./g, ' ').toLowerCase()] = values(p, <keyof ReducePrinter>sub_typology, effective_typology, objDataData.today,n_of_printers,deliveryChannels);
                    }
                });
                return x;
            })
        );
        return result;
    });
};

export const getWeatherIcon = (dashboardData: any) => {
    let code = _.toInteger(_.get(dashboardData.today_weather, 'status_code'));
    let weather_icon;

    switch (code) {
        case 1:
        case 2:
            weather_icon = 'weather-clear';
            break;
        case 3:
        case 4:
            weather_icon = 'weather-few-clouds';
            break;
        case 8:
            weather_icon = 'weather-clouds';
            break;
        case 5:
        case 6:
        case 7:
            weather_icon = 'weather-drizzle-day';
            break;
        case 9:
            weather_icon = 'weather-rain-day';
            break;
        case 10:
            weather_icon = 'weather-showers-day';
            break;
        case 11:
            weather_icon = 'weather-snow';
            break;
        case 12:
            weather_icon = 'weather-snow-rain';
            break;
        case 13:
            weather_icon = 'weather-storm';
            break;
        case 14:
        case 15:
            weather_icon = 'weather-mist';
            break;
        case 16:
            weather_icon = 'weather-storm-day';
            break;
        case 17:
            weather_icon = 'weather-hail';
            break;
        case 18:
            weather_icon = 'weather-snow-scattered-day';
            break;
        default:
            weather_icon = 'weather-none-available';
            break;
    }
    return weather_icon;
}

export const getPayments = (dashboardData: any, labelPrepaidsCards: string) => {
    let paymentsPrepaid: any = [];
    let payments = [];

    _.forEach(dashboardData.today_payments.payments, (payment) => {
        if (payment.type_id === 16) {
            paymentsPrepaid.push(payment);
        } else {
            payments.push(payment);
        }
    });

    if (paymentsPrepaid.length > 0) {
        payments.push({
            type_id: 16,
            name: labelPrepaidsCards,
            amount: _.sumBy(paymentsPrepaid, 'amount')
        });
    }

    return payments;
}

export const getToday = (dashboardData: any, payments: any, current_cash_movements: number, current_cash_in_register: number, weather_icon: string) => {

    let today : any = {
        total_amount: MathUtils.round(dashboardData.today_dashboard.items_sold.closed_sales.amount - dashboardData.today_dashboard.refunds.closed_sales.amount),
        total_sales: dashboardData.today_dashboard.closed_sales.total.count,
        payments: payments,
        cash_movements: current_cash_movements,
        current_cash: current_cash_in_register,
        invoices: dashboardData.today_dashboard.invoices,
        fiscal_receipts: dashboardData.today_dashboard.fiscal_receipts,
        summary_invoices: dashboardData.today_dashboard.summary_invoices,
        weather_icon: weather_icon,
        weather: dashboardData.today_weather,
        waste: dashboardData.today_dashboard.waste,
        deleted_orders: dashboardData.today_dashboard.deleted_orders
    };

    today.credit_notes = {
        count: _.get(dashboardData.today_dashboard.not_rt_credit_notes, 'count', 0),
        amount: _.get(dashboardData.today_dashboard.not_rt_credit_notes, 'amount', 0)
    };

    return today;
}
