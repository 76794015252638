import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { Items } from "tilby-models";

@Component({
    selector: "app-showcase-grid",
    templateUrl: "./showcase-grid.component.html",
    styleUrls: ["./showcase-grid.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatGridListModule,
        TilbyCurrencyPipe,
        TilbyGesturesDirective
    ]
})
export class ShowcaseGridComponent implements OnChanges, OnInit {
    @Input() itemsSet!: Items[];
    @Input() gridCols: number = 4;
    @Input() priceList: number = 1;
    @Input() getStockStatus: Function = () => {};
    @Input() showThumbnail: boolean = true;
    @Input() displayMode: string = "normal";
    @Input() customClass: string = '';
    @Output() onItemTap: EventEmitter<Items> = new EventEmitter<Items>();
    @Output() onItemPress: EventEmitter<Items> = new EventEmitter<Items>();
    rowHeight : string = '200px';
    priceIdentifier: keyof Items = "price1";

    ngOnInit(): void {
        this.rowHeight = this.calculateRowHeight();
        this.priceIdentifier = `price${this.priceList}` as keyof Items;
    }

    itemTap(item: Items): void {
        this.onItemTap.emit(item);
    }

    itemPress(item: Items): void {
        this.onItemPress.emit(item);
    }

    trackByFunction(index: number, item: Items): number {
        return item.id!;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.priceList) {
            this.priceIdentifier = `price${changes.priceList.currentValue}` as keyof Items;
        }
        if (changes.displayMode) {
            this.rowHeight = this.calculateRowHeight();
        }
    }

    calculateRowHeight(): string {
        return this.showThumbnail ? this.displayMode === "normal" ? "200px" : "180px" : this.displayMode === "normal" ? "90px" : "60px";
    }
}
