export interface BaseModel {
    mobile_columns: any[];
    tablet_columns: any[];
}

export class ColumnsDefaultModel {
    static suppliers: BaseModel = {
        // tablet_landscape_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "name", "hide": false, "flex": 1},
        //     {"colId": "phone1", "hide": false, "flex": 1},
        //     {"colId": "email1", "hide": false, "flex": 1},
        //     {"colId": "email_pec", "hide": false, "flex": 1},
        //     {"colId": "params", "width": 100, "hide": false, "pinned": "right"}
        // ],
        // tablet_portrait_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "name", "hide": false, "flex": 1},
        //     {"colId": "phone1", "hide": false, "flex": 1},
        //     {"colId": "email_pec", "hide": false, "flex": 1},
        //     {"colId": "params", "width": 100, "hide": false, "pinned": "right"}],
        // mobile_landscape_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "name", "hide": false, "flex": 1},
        //     {"colId": "phone1", "hide": false, "flex": 1},
        //     {"colId": "email1", "hide": false, "flex": 1},
        //     {"colId": "email_pec", "hide": false, "flex": 1},
        //     {"colId": "params", "width": 100, "hide": false, "pinned": "right"}],
        // mobile_portrait_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "name", "hide": false, "flex": 1},
        //     {"colId": "phone1", "hide": false, "flex": 1},
        //     {"colId": "email_pec", "hide": false, "flex": 1},
        //     {"colId": "params", "width": 100, "hide": false, "pinned": "right"}],
        //     tablet_landscape_columns: [
        //         {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //         {"colId": "name", "hide": false, "flex": 1},
        //         {"colId": "phone1", "hide": false, "flex": 1},
        //         {"colId": "email1", "hide": false, "flex": 1},
        //         {"colId": "email_pec", "hide": false, "flex": 1},
        //         {"colId": "params", "width": 100, "hide": false, "pinned": "right"}
        //     ],
        //     tablet_portrait_columns: [
        //         {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //         {"colId": "name", "hide": false, "flex": 1},
        //         {"colId": "phone1", "hide": false, "flex": 1},
        //         {"colId": "email_pec", "hide": false, "flex": 1},
        //         {"colId": "params", "width": 100, "hide": false, "pinned": "right"}],
        tablet_columns: [
            {"colId": "0","minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "name", "hide": false, "flex": 1},
            {"colId": "phone1", "hide": false, "flex": 1},
            {"colId": "email1", "hide": false, "flex": 1},
            {"colId": "email_pec", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "name", "hide": false, "flex": 1},
            {"colId": "phone1", "hide": false, "flex": 1},
            {"colId": "email1", "hide": false, "flex": 1},
            {"colId": "email_pec", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}]

    };
    static customers: BaseModel = {
        // tablet_landscape_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "company_name", "hide": false, "flex": 1},
        //     {"colId": "first_name", "hide": false, "flex": 1},
        //     {"colId": "last_name", "hide": false, "flex": 1},
        //     {"colId": "tot_spent", "hide": false, "flex": 1},
        //     {"colId": "fidelity", "hide": false, "flex": 1},
        //     {"colId": "avg_spent", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        // tablet_portrait_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "company_name", "hide": false, "flex": 1},
        //     {"colId": "first_name", "hide": false, "flex": 1},
        //     {"colId": "last_name", "hide": false, "flex": 1},
        //     {"colId": "tot_spent", "hide": false, "flex": 1},
        //     {"colId": "fidelity", "hide": false, "flex": 1},
        //     {"colId": "avg_spent", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        // mobile_landscape_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "company_name", "hide": false, "flex": 1},
        //     {"colId": "first_name", "hide": false, "flex": 1},
        //     {"colId": "last_name", "hide": false, "flex": 1},
        //     {"colId": "tot_spent", "hide": false, "flex": 1},
        //     {"colId": "avg_spent", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        // mobile_portrait_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "company_name", "hide": false, "flex": 1},
        //     {"colId": "first_name", "hide": false, "flex": 1},
        //     {"colId": "last_name", "hide": false, "flex": 1},
        //     {"colId": "tot_spent", "hide": false, "flex": 1},
        //     {"colId": "avg_spent", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        tablet_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "company_name", "hide": false, "flex": 1},
            {"colId": "first_name", "hide": false, "flex": 1},
            {"colId": "last_name", "hide": false, "flex": 1},
            {"colId": "tot_spent", "hide": false, "flex": 1},
            {"colId": "fidelity", "hide": false, "flex": 1},
            {"colId": "avg_spent", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "company_name", "hide": false, "flex": 1},
            {"colId": "first_name", "hide": false, "flex": 1},
            {"colId": "last_name", "hide": false, "flex": 1},
            {"colId": "tot_spent", "hide": false, "flex": 1},
            {"colId": "avg_spent", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
    };
    static history_sales: BaseModel = {
        // tablet_landscape_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "closed_at", "hide": false, "flex": 1},
        //     {"colId": "sale_number", "hide": false, "flex": 1},
        //     {"colId": "is_summary", "hide": false, "flex": 1},
        //     {"colId": "final_amount", "hide": false, "flex": 1},
        //     {"colId": "channel", "hide": false, "flex": 1},
        //     {"colId": "seller_name", "hide": false, "flex": 1},
        //     {"colId": "customer_name", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        // tablet_portrait_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "closed_at", "hide": false, "flex": 1},
        //     {"colId": "sale_number", "hide": false, "flex": 1},
        //     {"colId": "is_summary", "hide": false, "flex": 1},
        //     {"colId": "final_amount", "hide": false, "flex": 1},
        //     {"colId": "channel", "hide": false, "flex": 1},
        //     {"colId": "seller_name", "hide": false, "flex": 1},
        //     {"colId": "customer_name", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        // mobile_landscape_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "closed_at", "hide": false, "flex": 1},
        //     {"colId": "sale_number", "hide": false, "flex": 1},
        //     {"colId": "final_amount", "hide": false, "flex": 1},
        //     {"colId": "customer_name", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ],
        // mobile_portrait_columns: [
        //     {"colId": "0", "width": 70, "hide": false, "pinned": "left", "flex": 1},
        //     {"colId": "closed_at", "hide": false, "flex": 1},
        //     {"colId": "sale_number", "hide": false, "flex": 1},
        //     {"colId": "final_amount", "hide": false, "flex": 1},
        //     {"colId": "customer_name", "hide": false, "flex": 1},
        //     {"colId": "params", "hide": false, "pinned": "right", "flex": 1}
        // ]
        tablet_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "closed_at", "hide": false, "flex": 1},
            {"colId": "type_document", "hide": false, "flex": 1},
            {"colId": "sale_number", "hide": false, "flex": 1},
            {"colId": "final_amount", "hide": false, "flex": 1},
            {"colId": "printer", "hide": false, "flex": 1},
            {"colId": "is_summary", "hide": false, "flex": 1},
            {"colId": "channel", "hide": false, "flex": 1},
            {"colId": "seller_name", "hide": false, "flex": 1},
            {"colId": "customer_name", "hide": false, "flex": 1},
            {"colId": "payment_type", "hide": false, "flex": 1},
            {"colId": "sequential_number", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "closed_at", "hide": false, "flex": 1},
            {"colId": "sale_number", "hide": false, "flex": 1},
            {"colId": "final_amount", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ]
    };
    static giftcards: BaseModel = {
        tablet_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "code", "hide": false, "flex": 1},
            {"colId": "initial_value", "hide": false, "flex": 1},
            {"colId": "last_value", "hide": false, "flex": 1},
            {"colId": "is_divisible", "hide": false, "flex": 1},
            {"colId": "valid_since", "hide": false, "flex": 1},
            {"colId": "valid_until", "hide": false, "flex": 1},
            {"colId": "active", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "code", "hide": false, "flex": 1},
            {"colId": "active", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
    };
    static settings_um_static_token: BaseModel = {
        tablet_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "trunc_token", "hide": false, "flex": 1},
            {"colId": "description", "hide": false, "flex": 1},
            {"colId": "client_id", "hide": false, "flex": 1},
            {"colId": "created_at", "hide": false, "flex": 1},
            {"colId": "username", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "trunc_token", "hide": false, "flex": 1},
            {"colId": "description", "hide": false, "flex": 1},
            {"colId": "client_id", "hide": false, "flex": 1},
            {"colId": "created_at", "hide": false, "flex": 1},
            {"colId": "username", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ]
    };
    static history_cash_movements: BaseModel = {
        tablet_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "movement_type", "hide": false, "flex": 1},
            {"colId": "date", "hide": false, "flex": 1},
            {"colId": "description", "hide": false, "flex": 1},
            {"colId": "amount", "hide": false, "flex": 1},
            {"colId": "movement_account", "hide": false, "flex": 1},
            {"colId": "payment_method_name", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "movement_type", "hide": false, "flex": 1},
            {"colId": "date", "hide": false, "flex": 1},
            {"colId": "description", "hide": false, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ]
    };

    static file_importer: BaseModel = {
        tablet_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "status", "hide": false, "flex": 1},
            {"colId": "name", "hide": false, "flex": 1},
            {"colId": "dateToProcess", "hide": false, "flex": 1},
            {"colId": "typeInsert", "hide": false, "flex": 1},
            {"colId": "totalRows", "hide": false, "flex": 1},
            {"colId": "rowsProcessed", "hide": false, "flex": 1},
            {"colId": "totalErrors", "minWidth":62, "width": 62, "hide": false},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "minWidth": 50, "width": 50, "hide": false, "pinned": "left"},
            {"colId": "status", "hide": false, "flex": 1},
            {"colId": "name", "hide": false, "flex": 1},
            {"colId": "dateToProcess", "hide": false, "flex": 1},
            {"colId": "typeInsert", "hide": false, "flex": 1},
            {"colId": "totalRows", "hide": false, "flex": 1},
            {"colId": "rowsProcessed", "hide": false, "flex": 1},
            {"colId": "totalErrors", "minWidth":62, "width": 62, "hide": false},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ]
    };

    static file_exporter: BaseModel = {
        tablet_columns: [
            {"colId": "0", "minWidth": 20, "width": 20, "hide": false, "pinned": "left"},
            {"colId": "status", "hide": false, "flex": 1},
            {"colId": "created_at", "hide": false, "flex": 1},
            {"colId": "table", "hide": false, "flex": 1},
            {"colId": "completed_at", "hide": true, "flex": 1},
            {"colId": "createdby_id", "hide": true, "flex": 1},
            {"colId": "format", "hide": true, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ],
        mobile_columns: [
            {"colId": "0", "hide": false, "pinned": "left"},
            {"colId": "status", "hide": false, "flex": 1},
            {"colId": "created_at", "hide": false, "flex": 1},
            {"colId": "table", "hide": false, "flex": 1},
            {"colId": "completed_at", "hide": true, "flex": 1},
            {"colId": "createdby_id", "hide": true, "flex": 1},
            {"colId": "format", "hide": true, "flex": 1},
            {"colId": "params", "minWidth":62, "width": 62, "hide": false, "pinned": "right"}
        ]
    };
}

