import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').factory('showSaleItem', ["$mdDialog", "checkManager", "util", function($mdDialog, checkManager, util) {

    showSaleItemController.$inject = ["$scope", "$mdDialog", "item", "stockDictionary"];
    function showSaleItemController($scope, $mdDialog, item, stockDictionary) {
        $scope.item = item;
        $scope.stockDictionary = stockDictionary;
        $scope.priceList = util.getCurrentPriceList();
        $scope.context = "showItemInfo";
        $scope.enableCarousel = false;


        //variable to style contexts
        $scope.switch = true;

        var parseSaleItemOptions = function() {
            var options = [];

            _.times(5, function(i) {
                var idx = i + 1;

                if(checkManager.getPreference('items.option' + idx + '_name') &&  $scope.item["option" + idx + "_value"]) {
                    options.push({
                        name: checkManager.getPreference('items.option' + idx + '_name'),
                        value: $scope.item["option" + idx + "_value"]
                    });
                }
            });

            if (options.length) {
                return options;
            } else {
                return null;
            }
        };

        var parseCombinations = function() {
            var combinations = [];
            _.each($scope.item.combinations, function(comb) {
                combinations.push({
                    name: _.map(comb.combination_values, 'variation_value').join(', '),
                    //quantity: comb.quantity,
                    price: comb['price' + $scope.priceList],
                    id: comb.id
                });
            });
            if (combinations.length) {
                return combinations;
            } else {
                return null;
            }
        };

        $scope.combinations = parseCombinations();
        $scope.saleItemOptions = parseSaleItemOptions();
        $scope.allergens = _.map($scope.item.allergens, 'name').join(', ');
        $scope.components = _.map($scope.item.components, 'name').join(', ');

        $scope.itemImages = _.cloneDeep($scope.item.images);

        if (_.isEmpty($scope.itemImages)) {
            $scope.itemImages.push({
                image_url: "assets/images/sales/piatto-preview.png"
            });
        }

        $scope.goToItemInfo = function() {
            $scope.context = "showItemInfo";
        };

        $scope.goToCombinations = function() {
            $scope.context = "showCombinations";
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.addItem = function() {
            $mdDialog.hide(true);
        };

        $scope.getPriceList = function() {
            return item['price' + $scope.priceList];
        };

        $scope.isItemInStock = function isItemInStock() {
            return !!checkManager.getPreference('cashregister.show_stock_on_showcase') && $scope.item.stock_type != null;
        };

        $scope.getCombinationStockAvailability = function(combination) {
            if (!!checkManager.getPreference('cashregister.show_stock_on_showcase') && $scope.item.stock_type === 'simple' && $scope.stockDictionary.get('combination_id_' + combination.id)) {
                switch ($scope.stockDictionary.get('combination_id_' + combination.id).available) {
                    case 'available': case 'producible':
                        return 'stock-info-available';
                    case 'unavailable':
                        return 'stock-info-unavailable';
                    case 'alert':
                        return 'stock-info-alert';
                    default:
                        return 'stock-info-nostock';
                }
            }
        };

        $scope.getItemStockQuantity = function getItemStockQuantity() {
            var item = $scope.stockDictionary.get('item_id_' + $scope.item.id);
            if (item) {
                return _.round(item.stock_quantity, 4);
            } else {
                return '-';
            }
        };

        $scope.getCombinationStockQuantity = function getItemStockQuantity(combination) {
            var combinationItem = $scope.stockDictionary.get('combination_id_' + combination.id);
            if (combinationItem) {
                return _.round(combinationItem.stock_quantity, 4);
            } else {
                return '-';
            }
        };

        $scope.getItemUnit = function getItemUnit() {
            var item = $scope.stockDictionary.get('item_id_' + $scope.item.id);
            if (item) {
                return item.unit;
            }
        };

        $scope.getCombinationUnit = function getCombinationUnit(combination) {
            var combinationItem = $scope.stockDictionary.get('combination_id_' + combination.id);
            if (combinationItem) {
                return combinationItem.unit;
            }
        };

        $scope.isItemContentInfo = function isItemContentInfo() {
            return item.brand !== undefined || item.season !== undefined || item.code !== undefined;
        };

        $scope.getItemContentInfo = function getItemContentInfo() {
            var contentInfo = [];
            if (item.brand) {
                contentInfo.push(item.brand);
            }
            if (item.season) {
                contentInfo.push(item.season);
            }
            if (item.code) {
                contentInfo.push(item.code);
            }
            return contentInfo.join(', ');
        };

        $scope.isRawMaterial = function isRawMaterial() {
            var stock = $scope.stockDictionary.get('item_id_' + $scope.item.id);
            return _.isNil(stock.stock_quantity);
        };

        $scope.onShowComplete = function() {
            $scope.enableCarousel = true;
        };
    }

    var showSaleItem = {
        show: function(item, stockDictionary) {
            return $mdDialog.show({
                controller: showSaleItemController,
                template: require('./show-sale-item.html'),
                locals: {
                    item: item,
                    stockDictionary: stockDictionary
                },
                onComplete: function(scope) {
                    scope.onShowComplete();
                }
            });
        }
    };

    return showSaleItem;
}]);
