import { Injectable, inject } from "@angular/core";
import { filter } from "rxjs";

import {
    Customers,
    Items
} from "tilby-models";

import {
    ConfigurationManagerService,
    EntityManagerService,
    StorageManagerService,
    StorageUpdateNotification
} from "src/app/core";

export type BarcodeItem = {
    item_id: number
    combination_id?: number
    quantity?: number
    price?: number
}

@Injectable({
    providedIn: 'root'
})
export class EntityDictionariesStoreService {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);

    private itemsBarcodes = new Map<string, BarcodeItem>();
    private customersFidelities = new Map<string, number>();
    private customersPhones = new Map<string, number>();
    private customersTaxCodes = new Map<string, number>();

    private isInit = false;

    constructor(
    ) {
    }

    public async init() {
        if (this.isInit) {
            return;
        }

        StorageManagerService.storageUpdates$.pipe(
            filter(data => data.entityName === 'items' || data.entityName === 'customers')
        ).subscribe((data) => {
            switch (data.entityName) {
                case 'items':
                    this.handleItemUpdate(data);
                    break;
                case 'customers':
                    this.handleCustomerUpdate(data);
                    break;
            }
        });

        await this.initItemsDictionaries();
        await this.initCustomersDictionaries();

        this.isInit = true;
    }

    private async initItemsDictionaries() {
        this.itemsBarcodes.clear();

        const items = await this.entityManagerService.items.fetchCollectionOffline() as Items[];

        for (let item of items) {
            this.compileItemsDictionaries(item);
        }
    }

    private async initCustomersDictionaries() {
        this.customersFidelities.clear();
        this.customersTaxCodes.clear();

        const customers = await this.entityManagerService.customers.fetchCollectionOffline() as Customers[];

        for (let customer of customers) {
            this.compileCustomersDictionaries(customer);
        }
    }

    private compileCustomersDictionaries(customer: Customers) {
        if (customer.tax_code) {
            this.customersTaxCodes.set(customer.tax_code.toUpperCase(), customer.id!);
        }

        if (customer.fidelity) {
            this.customersFidelities.set(customer.fidelity.toUpperCase(), customer.id!);
        }

        if (customer.custom_1) {
            const customerCustom1 = customer.custom_1.toUpperCase();
            const prefix = this.configurationManagerService.getPreference("fidelity_prefix");

            if (prefix && customerCustom1.startsWith(prefix) && !this.customersFidelities.has(customerCustom1)) {
                this.customersFidelities.set(customerCustom1, customer.id!);
            }
        }

        if (customer.phone) {
            this.customersPhones.set(customer.phone, customer.id || -1);
        }

        if (customer.mobile) {
            this.customersPhones.set(customer.mobile, customer.id || -1);
        }
    }

    private compileItemsDictionaries(item: Items) {
        if (Array.isArray(item.barcodes)) {
            for (let itemBarcode of item.barcodes) {
                this.itemsBarcodes.set(itemBarcode.barcode.toUpperCase(), {
                    item_id: item.id!
                });
            }
        }

        if (Array.isArray(item.combinations)) {
            for (let combination of item.combinations) {
                if (Array.isArray(combination.barcodes)) {
                    for (let combinationBarcode of combination.barcodes) {
                        this.itemsBarcodes.set(combinationBarcode.barcode.toUpperCase(), {
                            combination_id: combination.id,
                            item_id: item.id!
                        });
                    }
                }
            }
        }
    }

    private removeItemFromDictionaries(itemId: number) {
        this.itemsBarcodes.forEach((val, key, map) => {
            if (val.item_id === itemId) {
                map.delete(key);
            }
        });
    }

    private removeCustomerFromDictionaries(customerId: string | number) {
        this.customersTaxCodes.forEach((val, key, map) => {
            if (val === customerId) {
                map.delete(key);
            }
        });

        this.customersFidelities.forEach((val, key, map) => {
            if (val === customerId) {
                map.delete(key);
            }
        });
    }

    private async handleItemUpdate(data: StorageUpdateNotification) {
        if (!data.entity || !data.id) {
            return this.initItemsDictionaries();
        }

        const item = data.entity as Items;

        switch (data.action) {
            case 'UPDATED':
                this.removeItemFromDictionaries(item.id!);
                this.compileItemsDictionaries(item);
                break;
            case 'DELETED':
                this.removeItemFromDictionaries(item.id!);
                break;
        }
    }

    private async handleCustomerUpdate(data: StorageUpdateNotification) {
        if (!data.entity || !data.id) {
            return this.initCustomersDictionaries();
        }

        const customer = data.entity as Customers;

        switch (data.action) {
            case 'UPDATED':
                this.removeCustomerFromDictionaries(customer.id!);
                this.compileCustomersDictionaries(customer);
                break;
            case 'DELETED':
                this.removeCustomerFromDictionaries(customer.id!);
                break;
        }
    }

    public getCustomerByTaxCode(taxCode: string) {
        return this.customersTaxCodes.get(taxCode.toUpperCase());
    }

    public getCustomerByPhone(phone: string) {
        return this.customersPhones.get(phone);
    }

    public getCustomerByFidelity(fidelity: string) {
        return this.customersFidelities.get(fidelity.toUpperCase());
    }

    public getItemByBarcode(barcode: string) {
        return this.itemsBarcodes.get(barcode.toUpperCase());
    }
}