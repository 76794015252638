import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-tab-settings-um',
  templateUrl: './tab-settings-um.component.html',
  styleUrls: ['./tab-settings-um.component.scss']
})
export class TabSettingsUmComponent implements OnInit {

    @Output() tabPanelChange = new EventEmitter<number>();
    @Input() getTabChange!: number;

    constructor() { }

    ngOnInit() {
    }

}
