import * as angular from 'angular';

angular.module('history').factory('eInvoiceErrorViewer', ["$mdDialog", function($mdDialog) {

    eInvoiceErrorViewerController.$inject = ["$scope", "$mdDialog", "sale"];
    function eInvoiceErrorViewerController($scope, $mdDialog, sale) {
        $scope.e_invoice = sale.e_invoice;

        $scope.isPresent = function(){
            return $scope.e_invoice.external_message && $scope.e_invoice.external_message !== "";
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

    }

    var eInvoiceErrorViewer = {
        show: function(sale) {
            return $mdDialog.show({
                controller: eInvoiceErrorViewerController,
                template: require('./e-invoice-error-viewer.html'),
                locals: {
                    sale: sale
                },
            });
        }
    };

    return eInvoiceErrorViewer;
}]);
