export const donationBanner = [
    ``,
    `        * EMERGENZA  EMILIA-ROMAGNA *         `,
    ``,
    `Aiuta le aree colpite dall'alluvione con un`,
    `bonifico di qualsiasi entita' alle seguenti`,
    `coordinate bancarie:`,
    ``,
    `AGENZIA REGIONALE SIC.T. PROTEZIONE`,
    `CIVILE EMILIA ROMAGNA.`,
    `IBAN IT69G0200802435000104428964`,
    `Causale "ALLUVIONE EMILIA-ROMAGNA"`,
    ``,
    `Ogni euro raccolto, l'utilizzo che ne verra'`,
    `fatto, verranno resocontati pubblicamente`,
    `dalla Regione Emilia Romagna.`,
    ``,
    `Info su https://bit.ly/fondi-emilia`,
].map(line => line.padEnd(46, ' ')).join('\n');