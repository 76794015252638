import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fileImporter', ['application']);

angular.module('fileImporter').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('file_importer');

    $stateProvider.state('app.file_importer', {
        url: '/old_file-importer',
        redirectTo: 'app.file_importer.showcase',
    });

    $stateProvider.state('app.file_importer.showcase', {
        url: '/showcase',
        views: {
            "appContent@app": {
                template: require('./partial/file-importer-showcase/file-importer-showcase.html'),
                controller: 'FileImporterShowcaseCtrl'
            }
        }
    });

    $stateProvider.state('app.file_importer.add', {
        params: {
            fileConfig: null
        },
        url: '/add',
        views: {
            "appContent@app": {
                template: require('./partial/file-importer-add/file-importer-add.html'),
                controller: 'FileImporterAddCtrl'
            }
        },
        resolve: {
            fileConfig: ["$stateParams", "checkManager", function($stateParams, checkManager) {
                return _.defaults($stateParams.fileConfig || {}, {
                    upload: {
                        mode: 'file',
                        url: null
                    },
                    type: 'xls',
                    typeInsert: 'items',
                    decimalSeparator: checkManager.getPreference('file_importer.decimal_separator') || null,
                    delimitatorColumn:  checkManager.getPreference('file_importer.delimitator_column') || null,
                    separationFixed: checkManager.getPreference('file_importer.separation_fixed') || null,
                    delimitatorString:  checkManager.getPreference('file_importer.delimitator_string') || null,
                    sheetNumber:1 
                });
            }]
        }
    });

    $stateProvider.state('app.file_importer.fields', {
        url: '/fields/:id',
        params: {
            id: null,
            fileConfig: null,
        },
        views: {
            "appContent@app": {
                template: require('./partial/file-importer-fields/file-importer-fields.html'),
                controller: 'FileImporterFieldsCtrl'
            }
        },
        resolve: {
            fileSchema: ["restManager", "$stateParams", function(restManager, $stateParams) {
                return restManager.getOne('importing', 'schema', { id: $stateParams.id });
            }],
            fileConfig: ["$stateParams", function($stateParams) {
                return _.defaults($stateParams.fileConfig || {}, {
                    id: _.toInteger($stateParams.id),
                    primaryKey: null,
                    overwriteMethod: 'onlyFields',
                });
            }]
        }
    });

    $stateProvider.state('app.file_importer.schedule', {
        url: '/schedule/:id',
        params: {
            id: null,
            fileConfig: null,
        },
        views: {
            "appContent@app": {
                template: require('./partial/file-importer-schedule/file-importer-schedule.html'),
                controller: 'FileImporterScheduleCtrl'
            }
        },
        resolve: {
            fileConfig: ["$stateParams", function($stateParams) {
                return _.defaults($stateParams.fileConfig || {}, {
                    id: _.toInteger($stateParams.id),
                    name: null,
                    email: null,
                    executionTime: null,
                    executionTimePeriods: []
                });
            }]
        }
    });
}]);