import * as angular from 'angular';

angular.module('application').component('showcaseList', {
    bindings: {
        isPortrait: '<',
        itemsSet: '<',
        priceList: '<',
        onItemPress: '<',
        onItemTap: '<',
        getStockStatus: '<',
        displayModeCompact: '<'
    },
    template: require('./showcase-list.html'),
    controller: ["$state", "$injector", function($state, $injector) {
        this.$onInit = () => {
            const currentModule = $state.current.name.split('.')[1];

            switch(currentModule) {
                case 'orders':
                    this.currentModule = 'orders';
                    this.activeService = $injector.get('ActiveOrder');
                    break;
                case 'cashregister':
                    this.currentModule = 'cashregister';
                    this.activeService = $injector.get('ActiveSale');
                    break;
                default:
                    break;
            }
        };

        this.$onChanges = (changes) => {
            if(changes.priceList) {
                this.priceIdentifier = `price${changes.priceList.currentValue}`;
            }
        };
    }]
});