import { Inject, Injectable, inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MagicPortalDialogComponent } from "../../magic-portal-dialog";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../../services";
import { PayPalDialogComponent } from "./paypal-dialog.component";
import { PaypalDialogModel } from "./paypal-dialog.model";
import { DevLogger } from "src/app/shared/dev-logger";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { Validators } from "@angular/forms";
import { restManager } from "app/ajs-upgraded-providers";

type PaypalDialogFormValue = { client_id: string, client_secret: string };
export type PaypalDialogForm = CustomFormGroup<CustomForm<PaypalDialogFormValue>>;
export type PaypalDialogParams = PaypalDialogModel;
export type PaypalDialogData = InputItemDataMagicForm<PaypalDialogFormValue>;

@Injectable({
    providedIn: 'root'
})
export class PayPalDialogService {
    private readonly openDialogsService = inject(OpenDialogsService);
    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<PayPalDialogComponent>>;
    private _form?: PaypalDialogForm;

    constructor(
        @Inject(restManager) private restManagerService: any,
    ){}

    private log(...args: any[]) {
        DevLogger.log("ITEM_LABELS_DIALOG_STATE_SERVICE", ...args);
    }

    // OPEN DIALOG
    public async openDialog(config: NonNullableConfigData<PaypalDialogParams>) {
        const {title} = config.data;
        // await this.resolveEntities();
        // this.resetInitialValues();
        // this.init();
        this.createForm();

        const configPortal: NonNullablePortalConfigData<PaypalDialogData,PayPalDialogComponent> = {
            ...config,
            data: {
                component: PayPalDialogComponent,
                titleLabel: title,
                form: this._form!,
                confirmDisabled: () => !this._form?.valid,
                dontCloseDialogOnConfirm$: new BehaviorSubject<boolean>(true),
            },
        };
        this.dialogRef = this.openDialogsService.openPortalDialogRef<PayPalDialogComponent,PortalConfigData<PaypalDialogData, PayPalDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({
                width: "800px",
            }),
            ...configPortal,
        });
        return lastValueFrom(this.dialogRef.afterClosed()).then((res: PortalConfigData<InputItemDataMagicForm<PaypalDialogFormValue>, PayPalDialogComponent>) => res ? this._form?.valid && this._form.value : null);
    }

    // FUNCTIONS IN OPEN DIALOG
    private async resolveEntities() {}
    private resetInitialValues() {}
    private init() {}
    private createForm() {
        this._form = new CustomFormGroup<CustomForm<PaypalDialogFormValue>>({
            client_id: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'Client Id', id:'input-client_id', class:'tw-w-1/3 '}),
            client_secret: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'Client Secret', id:'input-client_secret', class:'tw-w-1/3 '}),
        })
    }

    public confirm(formValue: Partial<PaypalDialogFormValue>) {
        this.restManagerService.post('alvolo/api/paypal/config', {payload: formValue})
        .then((result:any) => {
            if(result) {
                this.openDialogsService.openAlertDialog({ data: { messageLabel: `SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.SAVED` } })
                this.dialogRef?.close(formValue)
            }
        })
        .catch((error:any) => {
            error.data.error.error.message.includes('Unable to update the paypal configuration of the shop')
            && this.openDialogsService.openAlertDialog({ data: { messageLabel: `SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.ERROR` }});
        })
    }

    get client_id() {
        return this._form!.controls.client_id;
    }

    get client_secret() {
        return this._form!.controls.client_secret;
    }

    // FUNTIONS IN CREATE FORM

}
