import {ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataRefundItemsSelectorDialog } from '../dialog.model';
import { OpenDialogsService } from '../services/open-dialogs.service';
import * as _ from 'lodash';
import { SalesItems } from 'tilby-models';
import {mobileCheck} from "src/utilities";

@Component({
    selector: 'app-refund-items-selector-dialog',
    templateUrl: './refund-items-selector-dialog.component.html',
    styleUrls: ['./refund-items-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefundItemsSelectorDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<OpenDialogsService>,
        @Inject(MAT_DIALOG_DATA) public data: DataRefundItemsSelectorDialog,
        protected translateService: TranslateService
    ){
        this.data.saleItems = _.cloneDeep(this.data.saleItems);
        for (let saleItem of this.data.saleItems as unknown as Array<{quantity: number, _quantities: number[], _quantity: number}>) {
            if (Number.isInteger(saleItem.quantity)) {
              saleItem._quantities = Array.from({ length: saleItem.quantity + 1 }, (_, i) => i);
            } else {
              saleItem._quantities = [0, saleItem.quantity];
            }
            saleItem._quantity = 0;
        }
    }

    check(){
        let saleItems = _.cloneDeep(this.data.saleItems as unknown as Array<{quantity: number, _quantities?: number[], _quantity?: number}>).filter((saleItem:SalesItems&any) => saleItem._quantity);
        for(let saleItem of saleItems) {
            saleItem.quantity = saleItem._quantity || 0;
            delete saleItem._quantity;
            delete saleItem._quantities;
        }
        this.data.saleItems = saleItems as SalesItems[];
        this.dialogRef.close(this.data.saleItems);
    }

    selectAll() {
        for(let saleItem of this.data.saleItems as unknown as Array<{quantity: number, _quantities: number[], _quantity: number}>) {
            saleItem._quantity = saleItem._quantities[saleItem._quantities.length - 1];
        }
    }

    unselectAll() {
        for(let saleItem of this.data.saleItems as unknown as Array<{quantity: number, _quantities: number[], _quantity: number}>) {
            saleItem._quantity = 0;
        }
    }

    onBarcodeScanned = (barcode: String) => {
        for(let saleItem of this.data.saleItems  as unknown as Array<{quantity: number, _quantities: number[], _quantity: number, barcode: string}>) {
            if(saleItem.barcode === barcode) {
                let quantityIndex = _.findIndex(saleItem._quantities, (qty : any) => qty === saleItem._quantity)

                if(quantityIndex < (saleItem._quantities.length - 1)) {
                    saleItem._quantity = saleItem._quantities[quantityIndex + 1];
                    return;
                }
            }
        }
    }

    hasCheckDisabled() {
        for(let saleItem of this.data.saleItems as unknown as Array<{quantity: number, _quantities: number[], _quantity: number}>) {
            if (saleItem._quantity > 0) return false;
        }
        return true;
    }

    isMobile = mobileCheck();
}
