import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataRadioListSelectorDialog } from '../dialog.model';
import { OpenDialogsService } from '../services/open-dialogs.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-radio-list-selector-dialog',
    templateUrl: './radio-list-selector-dialog.component.html',
    styleUrls: ['./radio-list-selector-dialog.component.scss']
})
export class RadioListSelectorDialogComponent {
    selectedElementId?:number;
    elements:any[];
    hideCancel = this.data.hideCancel;
    addAction = this.data.addAction;
    default = this.data.defaultValue;
    label = null;
    confirmLabel = null;
    cancelLabel = null;

    constructor(
        public dialogRef: MatDialogRef<OpenDialogsService>,
        @Inject(MAT_DIALOG_DATA) public data: DataRadioListSelectorDialog,
        protected translateService: TranslateService
    ){
        this.label = data.label || translateService.instant('APPLICATION.RADIO.SELECT_OPTION');
        this.confirmLabel = data.confirmLabel || translateService.instant('APPLICATION.RADIO.CONFIRM');
        this.cancelLabel = data.cancelLabel || translateService.instant('APPLICATION.RADIO.CANCEL');
        this.elements = !_.isEmpty(data.elements) ? _.clone(data.elements) : [];
        this.selectedElementId = _.isInteger(this.data.defaultValue) ? ((this.data.defaultValue||0)+1) : undefined;
    }

    executeAction(action : any) {
        this.dialogRef.beforeClosed().subscribe(() => {
            action.callback();
        });
        this.dialogRef.close();
    }

    confirm() {
        let value = this.elements.find((element) => element.id === this.selectedElementId)
        this.dialogRef.close(value);
    }

    hasCheckDisabled() {
        return !this.selectedElementId;
    }
}
