import { Injectable } from '@angular/core';
import { validate as validateUuid, v4 as generateUuid } from 'uuid';
import { EnvironmentConfig } from 'src/environments/environment-config';

type UserAgentOs = 'Android' | 'iOS' | 'Windows' | 'macOS' | 'Linux' | 'Unknown';

type DeviceMeta = {
    client_serial: string;
    client_uuid: string;
    client_os: string;
}

type NetworkInformation = {
    client_lan_ip: string;
}

@Injectable({
    providedIn: 'root'
})
export class EnvironmentInfoService {
    private _isAndroid = false;
    private _isAppleMobile = false;
    private _isElectronApp = false;
    private _isMobileApp = false;
    private _isPoynt = false;
    private _baseUrl: string;
    private _deviceMeta: DeviceMeta = {
        client_serial: '',
        client_uuid: '',
        client_os: ''
    }

    private _sessionId = this.initSessionId();
    private _userAgentOS = this.setUserAgentFromNavigator();

    private initSessionId(): string {
        const localStorage = window.localStorage;
        let deviceId;

        if (localStorage) {
            deviceId = window.localStorage.getItem("scl-device-id");
        }

        if (!deviceId || !validateUuid(deviceId)) {
            deviceId = generateUuid();

            if (localStorage) {
                window.localStorage.setItem("scl-device-id", deviceId);
            }
        }

        return deviceId;
    }

    private setUserAgentFromNavigator(): UserAgentOs {
        const userAgent = window.navigator.userAgent;

        //Obtain os info from the user agent
        if (userAgent.match(/(Android)/)) {
            return 'Android';
        } else if (userAgent.match(/(iPhone|iPod|iPad)/)) {
            return 'iOS';
        } else if (userAgent.match(/(Windows)/)) {
            return 'Windows';
        } else if (userAgent.match(/(Macintosh)/)) {
            return 'macOS';
        } else if (userAgent.match(/(X11)/)) {
            return 'Linux';
        }

        return 'Unknown';
    }

    constructor() {
        switch (EnvironmentConfig.clientId) {
            case 'Scloby3PG':
                this._isMobileApp = true;

                switch (window.device.manufacturer) {
                    case 'POYNT':
                        this._isAndroid = true;
                        this._isPoynt = true;
                        break;
                    case 'Apple':
                        this._isAppleMobile = true;
                        break;
                    default:
                        this._isAndroid = true;
                        break;
                }
                break;
            case 'SclobyElectron3':
                this._isElectronApp = true;
                break;
            default:
                if (window.require) {
                    this._isElectronApp = true;

                    Object.assign(EnvironmentConfig, {
                        redirectUri: "http://ok.login.tilby.com/"
                    });
                }
                break;
        }

        if (this._isElectronApp) {
            this._baseUrl = "./";

            let getElectronSysInfo = async () => {
                const sysinfo = window.require('systeminformation');

                try {
                    let systemInfo = await sysinfo.system();
                    Object.assign(this._deviceMeta, {
                        client_serial: systemInfo.serial,
                        client_uuid: systemInfo.uuid
                    });
                } catch (err) {
                    //Nothing to do
                }

                try {
                    let osInfo = await sysinfo.osInfo();
                    Object.assign(this._deviceMeta, {
                        client_os: `${osInfo.distro} ${osInfo.release}`
                    });
                } catch (err) {
                    //Nothing to do
                }
            };

            getElectronSysInfo();
        } else if (this._isMobileApp) {
            this._baseUrl = document.URL.substring(0, document.URL.lastIndexOf("/")).replace(/.*?:\/\//g, "") + "/";

            Object.assign(this._deviceMeta, {
                client_serial: window.device.serial,
                client_uuid: window.device.uuid,
                client_os: `${window.device.platform} ${window.device.version}`
            });
        } else {
            this._baseUrl = "/";
        }
    }

    public getBaseUrl(): string {
        return this._baseUrl;
    }

    public async getNetworkInfo(): Promise<NetworkInformation> {
        if (this._isElectronApp) {
            const sysinfo = window.require('systeminformation');

            let defInterface = await sysinfo.networkInterfaceDefault();
            let interfaces: any[] = await sysinfo.networkInterfaces();
            let targetIface = interfaces.find((i) => (i.iface === defInterface));

            if (targetIface) {
                return { client_lan_ip: targetIface.ip4 };
            } else {
                throw 'INTERFACE_NOT_FOUND';
            }
        } else if (this._isMobileApp) {
            let clientIp = '';
            
            if(window.networkinterface) {
                clientIp = await new Promise((resolve) => window.networkinterface!.getWiFiIPAddress((result) => resolve(result.ip), () => resolve('')));

                if(!clientIp) {
                    clientIp = await new Promise((resolve) => window.networkinterface!.getCarrierIPAddress((result) => resolve(result.ip), () => resolve('')));
                }
            }

            if(!clientIp) {
                throw 'INTERFACE_NOT_FOUND';
            }

            return { client_lan_ip: clientIp };
        } else {
            throw 'INVALID_PLATFORM';
        }
    }

    public isAndroid(): boolean {
        return this._isAndroid;
    }

    public isAppleMobile(): boolean {
        return this._isAppleMobile;
    }

    public isMobileApp(): boolean {
        return this._isMobileApp;
    }

    public isElectronApp(): boolean {
        return this._isElectronApp;
    }

    public isWebApp(): boolean {
        return !this._isMobileApp && !this._isElectronApp;
    }

    public isPoynt(): boolean {
        return this._isPoynt;
    }

    public canUseTcpSockets(): boolean {
        return this._isMobileApp || this._isElectronApp;
    }

    public canUseSerialPorts(): boolean {
        return this._isElectronApp;
    }

    public isCameraBarcodeSupported(): boolean {
        return this._isMobileApp && !this._isPoynt;
    }

    public canOpenExternalLinks(): boolean {
        return !this._isPoynt;
    }

    public canDownloadFiles(): boolean {
        return !this._isMobileApp;
    }

    public canScanNetwork(): boolean {
        return this._isMobileApp || this._isElectronApp;
    }

    public canShare(): boolean {
        return this._isMobileApp && !this._isPoynt;
    }

    public canPrint(): boolean {
        return this._isElectronApp || !this._isMobileApp;
    }

    public usesLoginView(): boolean {
        return this._isMobileApp || this._isElectronApp;
    }

    public getUserAgentOS(): UserAgentOs {
        return this._userAgentOS;
    }

    public isWindows(): boolean {
        return this._userAgentOS === 'Windows';
    }

    public isMacOS(): boolean {
        return this._userAgentOS === 'macOS';
    }

    public getDeviceMeta(): DeviceMeta {
        return this._deviceMeta;
    }

    public getSessionId(): string {
        return this._sessionId;
    }
}