<!-- AG Grid Angular Component -->
<ag-grid-angular
    [id]="feature"
    [cacheBlockSize]="cacheBlockSize"
    [loadingCellRenderer]="loadingCellRenderer"

    [rowClassRules]="rowClassRules"
    class="ag-theme-material tw-h-full tw-w-full"

    [isRowSelectable]="isRowSelectable"
    [rowModelType]="rowModelType"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="(tableData.rowData$ | async) || [null]"
    [rowSelection]="'multiple'"
    [suppressRowClickSelection]="true"
    (rowClicked)="onRowOrExternalClicked($event)"
    [animateRows]="true"
    [getRowId]="getRowId"
    [sideBar]="sideBar"
    [statusBar]="statusBar"
    [context]="context"
    [suppressDragLeaveHidesColumns]="true"
    [suppressColumnMoveAnimation]="true"
    [excelStyles]="excelStyles"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="selectionChanged()"
    [localeText]="$any(localText)"
    (columnMoved)="onColumnMoved($event)"
    (columnVisible)="onColumnVisible($event)"
    (sortChanged)="onColumnSortChanged($event)"
    (columnResized)="onColumnResized($event)"
    (toolPanelVisibleChanged)="onToolPanelVisible($event)"

    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    (modelUpdated)="onModelUpdated($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    (filterChanged)="onFilterChanged($event)"
    [getRowClass]="getRowClass"
></ag-grid-angular>
