import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fidelity').directive('topbarFidelity', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-fidelity.html'),
        controller: ["$scope", "$element", "$attrs", "$mdSidenav", "$state", "$timeout", "connection", "offlineDialog", "checkManager", function($scope, $element, $attrs, $mdSidenav, $state, $timeout, connection, offlineDialog, checkManager) {

            var dateTimeout;
            const newCustomerModuleEnabled = checkManager.isModuleAngular('customers');

            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            $scope.isDetailsView = function() {
                return $scope.context.view === "details";
            };

            $scope.isSearchView = function() {
                return $scope.context.view === "search";
            };

            $scope.isViewMode = function() {
                return $scope.context.mode === "view";
            };

            $scope.isSetupMode = function() {
                return $scope.context.mode === "setup";
            };

            $scope.isViewModeLinkVisible = function() {
                return !newCustomerModuleEnabled && $scope.isSetupMode();
            };

            $scope.showSearch = function() {
                var searchStatus = "app.fidelity.search";

                if ($state.current.name !== searchStatus) {
                    $state.go(searchStatus);
                } else {
                    angular.element("#fidelitySearchInput").trigger('focus');
                }
            };

            $scope.goToSetup = function() {
                var setupStatus = "app.fidelity.setup";

                if ($state.current.name !== setupStatus) {
                    $state.go(setupStatus);
                }
            };

            var changeState = function(targetState) {
                if ($state.current.name !== targetState) {
                    if (_.isFunction($scope.context.checkBeforeExit)) {
                        $scope.context.checkBeforeExit().then(function() {
                            $state.go(targetState);
                        });
                    } else {
                        $state.go(targetState);
                    }
                }
            };

            $scope.goToSetupCampaigns = function() {
                changeState("app.fidelity.setup.campaigns");
            };

            $scope.goToSetupRules = function() {
                changeState("app.fidelity.setup.rules");
            };

            $scope.goToSetupPrizes = function() {
                changeState("app.fidelity.setup.prizes");
            };

            $scope.goToView = function() {
                changeState("app.fidelity.search");
            };

            $scope.isSetupEnabled = function() {
                return checkManager.isFunctionUserEnabledOptin("fidelity.setup");
            };

            $scope.updateMovementsQuery = function() {
                $scope.context.changeQueryStatus();
            };

            $scope.updateDateRange = function() {
                if ($scope.context.dateFilter.from <= $scope.context.dateFilter.to) {
                    if (dateTimeout) {
                        $timeout.cancel(dateTimeout);
                    }
                    dateTimeout = $timeout($scope.context.changeQueryStatus, 1500);
                }
            };
        }]
    };
});
