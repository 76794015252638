import { Component, Inject, inject } from "@angular/core";
import { AddTimeSlotDialogStateService, AddTimeSlotFormValue, AddTimeSlotOptionDialogData } from "./add-time-slot-dialog.state.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { PortalConfigData } from "../services";
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import { SettingsAlvoloContentComponent } from "src/app/features";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { TilbyInputComponent, TilbyMagicFormComponent, TilbySelectInputComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { itemSelector } from "app/ajs-upgraded-providers";
import { DevLogger } from "src/app/shared/dev-logger";
import { Items } from "tilby-models";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
    selector: 'app-add-time-slot-dialog',
    templateUrl: './add-time-slot-dialog.component.html',
    styleUrls: ['./add-time-slot-dialog.component.scss'],
    standalone: true,
    imports: [
        TilbyMagicFormComponent,
        TilbySelectInputComponent,
        TilbyInputComponent,
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        MatProgressSpinner
    ],
    providers:[OnDestroyService]
})
export class AddTimeSlotDialogComponent {
    protected readonly addTimeSlotDialogStateService = inject(AddTimeSlotDialogStateService);
    protected readonly onDestroyService = inject(OnDestroyService);
    protected readonly itemSelectorService = inject(itemSelector);
    timeSlotFormGroup: any;

    constructor(
        public dialogRef: MatDialogRef<SettingsAlvoloContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputItemDataMagicForm<AddTimeSlotFormValue>,
    ){
        this.subscriptions();
    }

    cancel() {
        this.dialogRef.close({ cancel: true });
    }

    addDeliveryPrice() {
        this.addTimeSlotDialogStateService.addDeliveryPriceFormGroup();
    }

    removeDeliveryPrice(index: number) {
        this.addTimeSlotDialogStateService.removeDeliveryPriceFormGroup(index);
    }

    addHiddenCategory() {
        this.addTimeSlotDialogStateService.addHiddenCategoryFormGroup();
    }

    removeHiddenCategory(index: number) {
        this.addTimeSlotDialogStateService.removeHiddenCategoryFormGroup(index);
    }

    addHiddenProducts() {
        this.addTimeSlotDialogStateService.addHiddenProductsFormGroup();
    }

    removeHiddenProducts(index: number) {
        this.addTimeSlotDialogStateService.removeHiddenProductsFormGroup(index);
    }

    async checkProduct(index: number) {
        await this.itemSelectorService.show(this.addTimeSlotDialogStateService.products).then((product: Items) => {
            this.addTimeSlotDialogStateService.editProductSelected(index, product)
        });
    }

    private subscriptions(){
        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe((res: PortalConfigData<AddTimeSlotOptionDialogData, AddTimeSlotDialogComponent>) =>
        {
            const operative_mode_type = [];
            if(res.form.value.operative_mode?.delivery) operative_mode_type.push('delivery');
            if(res.form.value.operative_mode?.takeaway) operative_mode_type.push('takeaway');
            if(res.form.value.operative_mode?.pickup) operative_mode_type.push('pickup');

            this.addTimeSlotDialogStateService.confirm(
                {
                    ...res.form.value, 
                    operative_mode: operative_mode_type,
                    distance_based: res.form.value.distance_based?.map(distance => ({distance_based_delivery_km: distance.distance_based_delivery_km, distance_based_delivery_price: distance.distance_based_delivery_price})),
                    hidden_categories_group: res.form.value.hidden_categories_group?.map(category => ({services: category.services, categories: category.categories})),
                    hidden_products_group: res.form.value.hidden_products_group?.map(product => ({services: product.services, products: product.products}))
                }
            )
        })
    }
}