import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as html2pdf from 'html2pdf.js';
import * as $ from 'jquery';
import * as jsBarcode from 'jsbarcode';
import * as QRCode from 'qrcode';

angular.module('giftcard').controller('GiftcardShowcaseCtrl', giftcardShowcaseCtrl);

giftcardShowcaseCtrl.$inject = ["$scope", "$rootScope", "$translate", "confirmDialog", "restManager", "toast", "modifyGiftcardDialog", "alertDialog", "$timeout", "checkManager", "$filter", "environmentInfo", "errorsLogger"];

function giftcardShowcaseCtrl($scope, $rootScope, $translate, confirmDialog, restManager, toast, modifyGiftcardDialog, alertDialog, $timeout, checkManager, $filter, environmentInfo, errorsLogger) {

    $scope.topbar_context = {
        mode: 'showcase',
        title: $translate.instant('GIFTCARD.TOPBAR.GIFTCARD')
    };

    $scope.giftcardsData = {};

    $scope.currentPage = 0;
    $scope.loadingInProgress = true;

    $scope.hasNoResults = function() {
        return $scope.giftcardsData === false || $scope.giftcardsData.length === 0;
    };

    $scope.decrementPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage--;
            getGiftcardsPage($scope.currentPage);
        }
    };

    $scope.incrementPage = function() {
        if ($scope.currentPage < $scope.giftcardsData.pages - 1) {
            $scope.currentPage++;
            getGiftcardsPage($scope.currentPage);
        }
    };

    $scope.goToFirstPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage = 0;
            getGiftcardsPage($scope.currentPage);
        }
    };

    $scope.goToLastPage = function() {
        if ($scope.currentPage < $scope.giftcardsData.pages - 1) {
            $scope.currentPage = $scope.giftcardsData.pages - 1;
            getGiftcardsPage($scope.currentPage);
        }
    };

    const parseGiftcardsData = function(giftcard) {
        _.assign(giftcard, {
            _validSince: giftcard.valid_since ? moment(giftcard.valid_since, 'YYYY-MM-DD').format('L') : '-',
            _validUntil: giftcard.valid_until ? moment(giftcard.valid_until, 'YYYY-MM-DD').format('L') : '-'
        });
        return giftcard;
    };

    const getGiftcardsPage = function(pageNumber) { //status: 0 -> closed + stored, 1-> stored, 2-> closed
        $rootScope.$broadcast("loader:changeStatus", "giftcardShowcaseLoader", { enabled: true });
        $scope.loadingInProgress = true;

        // let the scope.digest to hide items
        $scope.$evalAsync(function() {
            $scope.giftcardsData.results = [];

            const query = {
                pagination: true,
                page: pageNumber
            };

            restManager.getList("giftcards", query).then(function(result) {
                _.forEach(result.results, parseGiftcardsData);
                $scope.giftcardsData = result;

                $rootScope.$broadcast("loader:changeStatus", "giftcardShowcaseLoader", { enabled: false });
                $scope.loadingInProgress = false;
            });
        });
    };

    getGiftcardsPage(0);

    $scope.deactivateGiftcard = (giftcard) => {
        confirmDialog.show($translate.instant('GIFTCARD.SHOWCASE.CONFIRM_DEACTIVATE')).then(function(answer) {
            if(answer) {
                restManager.deleteOne("giftcards", giftcard.uuid).then(function() {
                    getGiftcardsPage($scope.currentPage);
                    toast.show({ message: $translate.instant('GIFTCARD.SHOWCASE.SUCCESS_DEACTIVATE') });
                }, function(error) {
                    alertDialog.show($translate.instant('GIFTCARD.SHOWCASE.SAVE_ERROR_DEACTIVATE'));
                });
            }
        });
    };

    $scope.modifyGiftcard = (giftcard) => {
        modifyGiftcardDialog.show(giftcard).then(function(result) {
            if(result && result.uuid === giftcard.uuid) {
                getGiftcardsPage($scope.currentPage);
                toast.show({ message: $translate.instant('GIFTCARD.SHOWCASE.SUCCESS_MODIFY') });
            }
        });
    };

    const hostName = checkManager.getPreference("general.shopname") || $rootScope.userActiveSession.shop.name;

    const getBarcode = (giftcard) => {
        let barcodeElement = $('<svg></svg>');
        const currentBarcode = giftcard.code;
        const barcodeSettings = { format: "EAN13", fontSize:14 };

        try {
            jsBarcode(barcodeElement[0], currentBarcode, barcodeSettings);
        } catch(err) {
            errorsLogger.err(err);
        }

        return barcodeElement[0].outerHTML;
    };

    const generateHtmlForPDF = async (giftcard) => {
        _.assign(giftcard, {
            _validSince: giftcard.valid_since ? moment(giftcard.valid_since, 'YYYY-MM-DD').format('l') : "-",
            _validUntil: giftcard.valid_until ? moment(giftcard.valid_until, 'YYYY-MM-DD').format('l') : "-"
        });
        let giftcards_types;
        try {
            giftcards_types = await restManager.getOne('giftcards_types', null, { uuid: giftcard.type_uuid });
        } catch (err) {
            errorsLogger.err(err);
        }
        const wrapperStart = `
        <style type="text/css">
        h1, h2, h3 {width:100%; text-align: center;}
        h2 {font-weight: normal;}
        </style>
        <div style="height:1038px;width:21cm;padding: 5px;">`;
        const wrapperEnd = "</div>";
        const rowStart = `<div style="display:flex;flex-direction:column;justify-content:center;align-content:center;height:100%;margin:0;font-family: Helvetica;">`;
        const rowEnd = "</div>";
        const genericDivStart = `<div style='width:100%; text-align: center;'>`;
        const genericDivEnd = `</div>`;
        const breakPage = "<span class='break-page'></span>";
        const contents = [];
        const giftcardTypeName = giftcard.type_name ? `<h1>${giftcard.type_name}</h1>` : `<h1>${giftcards_types[0].name}</h1>`;
        const giftcardValue = `<h2>${$translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALUE')}</h2>`;
        const giftcardInitialValue = `<h1 style="font-size: 56px;">${$filter('sclCurrency')(giftcard.initial_value)}</h1>`;
        let giftcardValidity;
        if(giftcard.valid_until) {
            giftcardValidity = `<h2>${$translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_2', {valid_since: giftcard._validSince, valid_until: giftcard._validUntil})}</h2>`;
        } else {
            giftcardValidity = `<h2>${$translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_1', {valid_since: giftcard._validSince})}</h2>`;
        }

        const giftcardDivisible = `<h2>${$translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_DIVISIBLE')}</h2>`;
        const giftcardNoDivisible_1 = `<h2 style="margin-bottom:0;">${$translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_1')}</h2>`;
        const giftcardNoDivisible_2 = `<h2 style="margin-top:0;">${$translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_2')}</h2>`;
        const giftcardRegulation = giftcards_types ? `<h2>${giftcards_types[0].description}</h2>`: "";
        const giftcardOriginEnvUUID = `${giftcard.origin_environment} - ${giftcard.uuid}`;

        const barcode = getBarcode(giftcard);
        contents.push(wrapperStart);
        contents.push(rowStart);
        contents.push(giftcardTypeName);
        contents.push(giftcardValue);
        contents.push(giftcardInitialValue);
        contents.push(giftcardValidity);
        if(giftcard.is_divisible) {
            contents.push(giftcardDivisible);
        } else {
            contents.push(giftcardNoDivisible_1);
            contents.push(giftcardNoDivisible_2);
        }
        contents.push(giftcardRegulation);
        contents.push("<br><br><br><br>");
        contents.push(genericDivStart);
        contents.push(barcode);
        contents.push(genericDivEnd);

        contents.push("<br><br><br><br>");
        contents.push(genericDivStart);
        contents.push(giftcardOriginEnvUUID);
        contents.push(genericDivEnd);

        contents.push(rowEnd);
        contents.push(wrapperEnd, breakPage);

        const contentBoxes = contents.join('');

        const opt = {
            margin: 0,
            filename: `${hostName}-${giftcard.uuid}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1, ignoreElements: (el) => (el.nodeName === 'HEAD' || el.id === 'content') },
            jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['css'], after: '.break-page' }
        };

        html2pdf().set(opt).from(contentBoxes).save().catch((err) => {
            toast.show({
                message: $translate.instant('GIFTCARD.SHOWCASE.ERROR_CREATE_PDF'),
                actions: [{
                    text: $translate.instant('MISC.OK'),
                    action: 'dismiss',
                    highlight: true
                }],
                hideDelay: 0
            });
        });
    };

    $scope.downloadGiftcard = (giftcard) => {
        $rootScope.$broadcast("loader:changeStatus", "giftcardShowcaseLoader", { enabled: true });
        $scope.loadingInProgress = true;

        $timeout(() => generateHtmlForPDF(giftcard).finally(() => {
            $rootScope.$broadcast("loader:changeStatus", "giftcardShowcaseLoader", { enabled: false });
            $scope.loadingInProgress = false;
        }));
    };

    $scope.canShare = function() {
        return environmentInfo.canShare();
    };

    const ecommerceHomeImageUrl = checkManager.getPreference("ecommerce.home.image_url");

    const shareImage = async (giftcard) => {
        await generateImageToShare(giftcard).then(imageOut => {
            if($scope.canShare()) {
                const shareLabel = "Giftcard " + hostName;
                const optShare = {
                    message: shareLabel,
                    subject: shareLabel,
                    files: [imageOut]
                };
                window.plugins.socialsharing.shareWithOptions(
                    optShare,
                    () => {},
                    (err) => {
                        errorsLogger.err(err);
                        toast.show({
                            message: $translate.instant('GIFTCARD.SHOWCASE.ERROR_SHARE_IMAGE'),
                            actions: [{
                                text: $translate.instant('MISC.OK'),
                                action: 'dismiss',
                                highlight: true
                            }],
                            hideDelay: 0
                        });
                    }
                );
            }
        });
    };

    const generateImageToShare = async (giftcard) => {
        _.assign(giftcard, {
            _validSince: giftcard.valid_since ? moment(giftcard.valid_since, 'YYYY-MM-DD').format('l') : "-",
            _validUntil: giftcard.valid_until ? moment(giftcard.valid_until, 'YYYY-MM-DD').format('l') : "-"
        });
        let giftcards_types;
        try {
            giftcards_types = await restManager.getOne('giftcards_types', null, { uuid: giftcard.type_uuid });
        } catch (err) {
            errorsLogger.err(err);
        }
        const giftcardTypeName = giftcard.type_name ? giftcard.type_name : giftcards_types[0].name;
        const giftcardInitialValue = $filter('sclCurrency')(giftcard.initial_value);
        let giftcardValidity;
        if(giftcard.valid_until) {
            giftcardValidity = $translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_2', {valid_since: giftcard._validSince, valid_until: giftcard._validUntil});
        } else {
            giftcardValidity = $translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_1', {valid_since: giftcard._validSince});
        }
        const giftcardDivisible = $translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_DIVISIBLE');
        const giftcardNoDivisible_1 = $translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_1');
        const giftcardNoDivisible_2 = $translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_2');
        const giftcardRegulation = $translate.instant('GIFTCARD.SHOWCASE.PDF.REGULATION');

        const divContainerCanvas = document.querySelector(".giftcardmodule-content-list");
        const getCanvas = document.querySelector('#imgToShare');
        if(getCanvas) {
            divContainerCanvas.removeChild(getCanvas);
        }
        const canvas = document.createElement('canvas');
        canvas.setAttribute('id', 'imgToShare');
        canvas.setAttribute('width', '800');
        canvas.setAttribute('height', '800');
        canvas.style.visibility = 'hidden';
        divContainerCanvas.appendChild(canvas);

        const ctx = canvas.getContext('2d');
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.direction = 'ltr';
        if (ecommerceHomeImageUrl) {
            let logo = new Image(200);
            logo.crossOrigin = "anonymous";
            logo.onload = () => {
                ctx.drawImage(logo, 300, 20, 200, 200);
            };
            logo.src = ecommerceHomeImageUrl;
        } else {
            ctx.font = 'bold 40px monospace';
            ctx.fillText(hostName, 400, 135);
        }
        ctx.font = 'bold 44px monospace';
        ctx.fillText(giftcardTypeName, 400, 270);
        ctx.font = 'bold 72px monospace';
        ctx.fillText(giftcardInitialValue, 400, 350);
        ctx.font = 'normal 22px monospace';
        ctx.fillText(giftcardValidity, 400, 430);
        if(giftcard.is_divisible) {
            ctx.fillText(giftcardDivisible, 400, 480);
            ctx.fillText(giftcardRegulation, 400, 580);
        } else {
            ctx.fillText(giftcardNoDivisible_1, 400, 480);
            ctx.fillText(giftcardNoDivisible_2, 400, 530);
            ctx.fillText(giftcardRegulation, 400, 580);
        }

        const optQRcode = {
            errorCorrectionLevel: 'M',
            scale: 1,
            width: 200
        };
        return QRCode.toCanvas(giftcard.code, optQRcode).then(canvasQR => {
            let qrCodeImage = new Image();
            qrCodeImage.src = canvasQR.toDataURL("image/png");
            const fnLoad = qrCodeImage.onload = () => {
                ctx.drawImage(qrCodeImage, 300, 600, 200, 200);
                return $timeout(() => {
                    return canvas.toDataURL("image/png");
                }, 1000);
            };
            return fnLoad();
        }).catch(err => {
            errorsLogger.err(err);
        });
    };

    $scope.shareGiftcard = (giftcard) => {
        $rootScope.$broadcast("loader:changeStatus", "giftcardShowcaseLoader", { enabled: true });
        $scope.loadingInProgress = true;

        $timeout(() => shareImage(giftcard).finally(() => {
            $rootScope.$broadcast("loader:changeStatus", "giftcardShowcaseLoader", { enabled: false });
            $scope.loadingInProgress = false;
        }));
    };
}
