<ng-container *ngIf="{} as data">
    <section class="tw-px-6 tw-overflow-x-auto" tabindex="0">
        <table mat-table [dataSource]="dataSource">

            <ng-container *ngFor="let column of $any(dataSource[1])|keyvalue; let i = index" [matColumnDef]="$any(column.key)">
                <th mat-header-cell *matHeaderCellDef> {{column.key}} </th>
                <td [ngClass]="{'tw-w-96':i==0}" mat-cell class="tw-contents sm:tw-table-cell" *matCellDef="let item">
                    <ng-container *ngIf="$any(column.key) as columnName">
                        <div *ngIf="columnName == 'name'" class="text-2xl tw-hidden md:tw-block">{{item[columnName]}}</div>
                        <div [ngClass]="{'tw-flex tw-justify-center tw-items-center tw-p-2 tw-cursor-pointer tw-w-12 tw-h-12 sm:tw-w-16 sm:tw-h-16 md:tw-w-20 md:tw-h-20 lg:tw-w-28 lg:tw-h-28 xl:tw-w-32 xl:tw-h-32':i>0,'tw-pointer-events-none':!item[columnName]}"><mat-icon *ngIf="columnName !== 'name'" [svgIcon]="item[columnName]" class="tw-h-full tw-w-full tw-fill-[#ddd6e2]" (click)="clickHandler(item[columnName],item)"></mat-icon></div>
                    </ng-container>
                </td>
            </ng-container>

<!--            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </section>
</ng-container>
