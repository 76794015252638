import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fidelity').controller('FidelitySearchCtrl', ["$scope", "$state", "$filter", "$timeout", "$translate", "user", "restManager", "promptDialog", "alertDialog", "barcodeManager", "entityManager", function($scope, $state, $filter, $timeout, $translate, user, restManager, promptDialog, alertDialog, barcodeManager, entityManager) {

    $scope.topbar_context = {
        mode: "view",
        view: "search",
        searchField: ""
    };

    $scope.user = user;

    $scope.customers = null;
    $scope.foundCustomers = [];
    var searchTimeout = null;

    entityManager.customers.fetchCollectionOffline().then(function(customers) {
        $scope.customers = customers ? _.filter(customers, function(customer) {
            return !_.isEmpty(customer.fidelity);
        }) : [];

        if ($scope.pendingSearch) {
            searchByCustomer();
            delete $scope.pendingSearch;
            $scope.$digest();
        }
    });

    var searchByCustomer = function() {
        var searchWords = _.words($scope.topbar_context.searchField);
        $scope.foundCustomers = $scope.customers;
        _.each(searchWords, function(word) {
            $scope.foundCustomers = $filter('filter')($scope.foundCustomers, word);
        });
    };

    $scope.topbar_context.onSearchFieldChange = function() {
        if (searchTimeout) {
            $timeout.cancel(searchTimeout);
        }
        if ($scope.topbar_context.searchField.length < 3) {
            return;
        }
        searchTimeout = $timeout(searchByCustomer, 1000);
    };

    $scope.topbar_context.performSearch = function() {
        if (searchTimeout) {
            $timeout.cancel(searchTimeout);
        }
        barcodeManager.searchBarcodeCustomerByFidelity($scope.topbar_context.searchField).then(function(result) {
            console.log(result);
            if (result) {
                $state.go('app.fidelity.details', { customer: result });
            } else {
                if ($scope.customers) {
                    searchByCustomer();
                } else {
                    //Customers still loading
                    $scope.pendingSearch = true;
                }
            }
        });
    };

    $scope.selectCustomer = function(customer) {
        $state.go('app.fidelity.details', { customer: customer });
    };

    $scope.topbar_context.sendFidelityReport = function() {
        promptDialog.show({title: $translate.instant('FIDELITY.SEARCH.SEND_EMAIL_REPORT'), label: "Email", type: "email"}).then(function(mailAddress) {
            restManager.getList("fidelities_export", {
                email: mailAddress
            }).then(function() {
                alertDialog.show($translate.instant('FIDELITY.SEARCH.SEND_EMAIL_REPORT_OK'));
            }, function(error) {
                var errMsg;
                switch(error.data.error.message) {
                    case 'email param not found':
                        errMsg = $translate.instant('FIDELITY.SEARCH.SEND_EMAIL_REPORT_MISSING_MAIL');
                        break;
                    default:
                        errMsg = $translate.instant('FIDELITY.SEARCH.SEND_EMAIL_REPORT_ERROR');
                        break;
                }
                alertDialog.show(errMsg);
            });
        });
    };

    $timeout(function() {
        angular.element("#fidelitySearchInput").trigger('focus');
    });


}]);
