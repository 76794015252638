import * as angular from 'angular';
import { camelCase } from 'lodash';
import {
    BarcodeManagerService,
    ConnectionService,
    StorageManagerService,
    EntityBase
} from 'src/app/core';

/*
This service is used for backward compatibility with the old services. 
It'll be used to broadcast events from the new observables to the legacy services using the old $rootScope broadcast method
*/
export class LegacyBroadcasterService {
    private isInit = false;

    constructor(
        private $rootScope: angular.IRootScopeService
    ) {
    }

    /**
     * Initializes the service if it hasn't been initialized.
     */
    public init() {
        if (this.isInit) {
            return;
        }

        //Barcode manager
        BarcodeManagerService.barcodeEvents$.subscribe((data) => this.$rootScope.$broadcast(`barcodeManager:${data.type.capitalize()}`, data));

        //Connection manager
        ConnectionService.connectionStatus$.subscribe((data) => this.$rootScope.$broadcast(`connection:changed`, { status: data.online ? 'online' : 'offline', timestamp: data.timestamp }));

        //Storage manager
        StorageManagerService.storageUpdates$.subscribe((data) => this.$rootScope.$broadcast(`storage-updated:${camelCase(data.entityName)}`, data));

        //Entity base
        EntityBase.entityUpdates$.subscribe((data) => this.$rootScope.$broadcast(`entity-updated:${camelCase(data.entityName)}`, data));
        EntityBase.offlineFirstCompletion$.subscribe((data) => this.$rootScope.$broadcast(`OfflineFirst-${camelCase(data.entityName)}:completed`, data));

        this.isInit = true;
    }
}

LegacyBroadcasterService.$inject = ["$rootScope"];

angular.module("core").service("legacyBroadcaster", LegacyBroadcasterService);