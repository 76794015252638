import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.scss']
})
export class PrintersComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.printers");
    }
  ngOnInit(): void {
  }

}
