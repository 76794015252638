import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Component, EventEmitter, Output, inject } from "@angular/core";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { SettingsAlvoloCreateShopComponent } from "./settings-alvolo-create-shop/settings-alvolo-create-shop.component";
import { SettingsAlvoloActivateComponent } from "./settings-alvolo-activate/settings-alvolo-activate.component";
import { ConfigurationManagerService } from "src/app/core";
import { DevLogger } from "src/app/shared/dev-logger";

@Component({
    selector: "app-settings-alvolo-not-initialized",
    templateUrl: "./settings-alvolo-not-initialized.component.html",
    styleUrls: ["./settings-alvolo-not-initialized.component.scss"],
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatOptionModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, SettingsAlvoloCreateShopComponent, SettingsAlvoloActivateComponent ],
})
export class SettingsAlvoloNotInitializedComponent {
    private readonly configurationManagerService = inject(ConfigurationManagerService)
    @Output() aliasEmitter: EventEmitter<string> = new EventEmitter();
    next!: boolean;

    ngOnInit() {
        const alias = this.configurationManagerService.getSetting('alvolo.general.alias');
        const shop = this.configurationManagerService.getShopPreference("alvolo.shop");
        if(!alias && !shop) {
            this.next = false;
        } else if(alias && !shop) {
            this.next = true;
        } 
    }

    eventEmitterNextStepHandler() {
        this.next = true;
    }
}

