import * as angular from 'angular';
import * as _ from 'lodash';
import * as semver from 'semver';

const { tilbyVersion } = require('app/tilby.properties.json');
const updateCheckUrl = "https://checkupdates.scloby.com/latest.json";

type updateInfo = {
    latest_version: string,
    immediate: boolean,
    url?: string
}

type apkInfo = {
    name: string,
    path: string,
    size: number,
    app: {
        name: string
        package: string,
        version: {
            code: number,
            name: string
        }
    }
}

type updateCheckResult = {
    updateAvailable: boolean,
    updateRequired: boolean,
    hasUpdateUrl: boolean,
    platformClass: string
}

class UpdateManager {
    private latestUpdateInfo?: updateInfo;
    private latestUpdateStatus?: updateCheckResult

    private platformClass?: string;

    constructor(
        private $http: any,
        private environmentInfo: any,
        private util: any
    ) {
    }

    private async hasGooglePlayStore(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if(window.appAvailability) {
                window.appAvailability.check('com.android.vending', (info: any) => resolve(true), () => resolve(false));
            } else {
                resolve(false);
            }
        })
    }

    private async getPlatformClass(): Promise<string> {
        if(!this.platformClass) {
            if(this.environmentInfo.isElectronApp()) {
                switch(this.environmentInfo.getUserAgentOS()) {
                    case 'Windows':
                        this.platformClass = 'windows';
                    break;
                    case 'macOS':
                        this.platformClass = 'macos';
                    break;
                    default:
                        this.platformClass = 'unknown';
                    break;
                }
            } else if(this.environmentInfo.isMobileApp()) {
                if(this.environmentInfo.isAppleMobile()) {
                    this.platformClass = 'ios';
                } else {
                    let hasPlayServices = await this.hasGooglePlayStore();

                    if(hasPlayServices) {
                        this.platformClass = 'android-playservices';
                    } else {
                        switch(_.chain(window.device.manufacturer).toLower().upperFirst().value()) {
                            case 'Huawei':
                                this.platformClass = 'android-huawei';
                            break;
                            case 'Poynt':
                            case 'Newland':
                                this.platformClass = 'android-poynt';
                            break;
                            default:
                                this.platformClass = 'android-apk';
                            break;
                        }
                    }
                }
            } else {
                this.platformClass = 'web';
            }
        }

        return this.platformClass;
    }

    public async checkForUpdates(): Promise<updateCheckResult> {
        let checkResult: updateCheckResult = {
            updateAvailable: false,
            updateRequired: false,
            hasUpdateUrl: false,
            platformClass: ''
        }

        checkResult.platformClass = await this.getPlatformClass();

        if (!['web'].includes(checkResult.platformClass)) {
            try {
                //Download update info
                let updateInfoObj: any = await this.$http({ method: 'GET', url: updateCheckUrl, responseType: 'application/json' });
                this.latestUpdateInfo = updateInfoObj?.data[checkResult.platformClass];

                //If there is a new version
                if (semver.gt(this.latestUpdateInfo?.latest_version||'', tilbyVersion)) {
                    checkResult.updateAvailable = true;
                    checkResult.hasUpdateUrl = !!this.latestUpdateInfo?.url;

                    if (this.latestUpdateInfo?.immediate) { //Mandatory update
                        checkResult.updateRequired = true;
                    }
                }
            } catch (err) {
                //Nothing to do
            }
        }

        this.latestUpdateStatus = checkResult;

        return Object.assign({}, this.latestUpdateStatus);
    }

    public async updateNow(onProgressChange: any) {
        let updateUrl = this.latestUpdateInfo?.url;

        if (!updateUrl) {
            return;
        }

        //If the apk updater plugin is found in the current environment, download the apk directly
        if (updateUrl.endsWith('.apk') && window.ApkUpdater) {
            try {
                //Start the apk download
                let apkInfo: apkInfo = await window.ApkUpdater.download(updateUrl, {
                    onDownloadProgress: (status: any) => {
                        if (_.isFunction(onProgressChange)) {
                            onProgressChange(status.progress);
                        }
                    }
                });

                //Check if the bundle name and the version are the ones we expect
                if (apkInfo?.app.package === 'com.scloby.pos') {
                    //Launch the package installation
                    await window.ApkUpdater.install();
                }
            } catch (error) {
                //TODO: handle download errors
            }
        } else {
            //Open the update url for all of the other cases
            this.util.openExternalLink(updateUrl);
        }
    }

    public getLatestUpdateInfo(): updateInfo|void {
        return _.cloneDeep(this.latestUpdateInfo);
    }

    public getLatestUpdateStatus(): updateCheckResult|void {
        return _.cloneDeep(this.latestUpdateStatus)
    }
}

angular.module('application').service('updateManager', UpdateManager);

UpdateManager.$inject = ["$http", "environmentInfo", "util"];
