<ng-container>
    <app-customer-fidelity-points-form
        #customerFidelityPointsFormComponent
        [customer]="customer"
        [chainShops]="chainShops"
        [campaigns]="campaigns"
        [selectedCampaignId]="selectedCampaignId"
        [user]="user"
        [points]="points"
        (submit)="refresh($event)"
    ></app-customer-fidelity-points-form>
    <app-grid-server-side
        #appGridComponent
        class="tw-block tw-h-[calc(100%-5.5rem)]"
        (rowClicked)="rowClicked($event)"
        [fixedButtons]="fixedButtons"
        [exportFormatterOptions]="exportFormatterOptions"
        [cacheBlockSize]="query.per_page"
        [datasource]="datasource"
        [tableData]="tableData[0]"
        [feature]="'fidelity'"
    ></app-grid-server-side>
</ng-container>
