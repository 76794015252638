import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-settings-e-commerce',
  templateUrl: './settings-e-commerce.component.html',
  styleUrls: ['./settings-e-commerce.component.scss']
})
export class SettingsECommerceComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.settings.e_commerce");
    }

  ngOnInit(): void {
  }

}
