import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeModeService {
    private renderer: Renderer2;
    isDarkTheme = false;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null,null);
        this.renderer.addClass(document.body, 'mat-app-background');
    }

    switchTheme(theme: string) {
        if (theme === 'dark') {
            this.renderer.addClass(document.body, 'dark-theme');
            this.isDarkTheme = true;
        } else {
            this.renderer.removeClass(document.body, 'dark-theme');
            this.isDarkTheme = false;
        }
    }
}
