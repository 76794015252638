<tilby-dialog-toolbar title="Transazioni da fatturare" [hideConfirm]="true">
    <div class="tw-text-right tw-pr-5" toolbar-content>
        <mat-form-field>
            <mat-label>{{"HISTORY.SUMMARY_INVOICE_DIALOG.FROM_TO" | translate}}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="closed_at_since" #dateRangeStart>
                <input matEndDate formControlName="closed_at_max" #dateRangeEnd (dateChange)="addEvent()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker (closed)="onClose()"></mat-date-range-picker>
        </mat-form-field>
        @if (range.controls.closed_at_since.hasError('matStartDateInvalid')) {
            <mat-error class="tw-text-[0.9rem]">{{"HISTORY.SUMMARY_INVOICE_DIALOG.MANDATORY_START_DATE" | translate}}</mat-error>
        }
        @if (range.controls.closed_at_max.hasError('matEndDateInvalid')) {
            <mat-error class="tw-text-[0.9rem]">{{"HISTORY.SUMMARY_INVOICE_DIALOG.MANDATORY_END_DATE" | translate}}</mat-error>
        }
        @if (range.controls.closed_at_max.hasError('matEndYearInvalid')) {
            <mat-error class="tw-text-[0.9rem]">{{"HISTORY.SUMMARY_INVOICE_DIALOG.INVALID_END_YEAR" | translate}}</mat-error>
        }
    </div>
</tilby-dialog-toolbar>
<tilby-dialog-content>
    <section class="tw-max-h-80 tw-min-h-80 tw-overflow-auto" tabindex="0">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef translate="HISTORY.SUMMARY_INVOICE_DIALOG.NAME"></th>
                    <td mat-cell *matCellDef="let element">{{element.company_name}} {{element.first_name}} {{element.last_name}}</td>
                </ng-container>
                <ng-container matColumnDef="invoice_type">
                    <th mat-header-cell *matHeaderCellDef translate="HISTORY.SUMMARY_INVOICE_DIALOG.INVOICE_TYPE"></th>
                    <td mat-cell *matCellDef="let element">
                        {{element.collected ? ('HISTORY.SUMMARY_INVOICE_DIALOG.COLLECTED' | translate) : ('HISTORY.SUMMARY_INVOICE_DIALOG.NOT_COLLECTED' | translate) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="documents_to_be_invoiced">
                <th mat-header-cell *matHeaderCellDef translate="HISTORY.SUMMARY_INVOICE_DIALOG.DOCUMENTS_TO_BE_INVOICED"></th>
                <td mat-cell *matCellDef="let element">{{element.transaction_count}}</td>
            </ng-container>
            <ng-container matColumnDef="total_to_be_invoiced">
                <th mat-header-cell *matHeaderCellDef translate="HISTORY.SUMMARY_INVOICE_DIALOG.TOTAL_TO_BE_INVOICED"></th>
                <td mat-cell *matCellDef="let element">{{element.transaction_total | tilbyCurrency}}</td>
            </ng-container>
            <ng-container matColumnDef="star" stickyEnd>
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="confirm(element)">
                        <mat-icon>list</mat-icon>
                    </button>
                </td>
            </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                @if (!isLoading && dataSource) {
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                }
        </table>
        <div *ngIf="!isLoading && dataSource.length === 0" class="tw-text-center">
            <p translate="HISTORY.SUMMARY_INVOICE_DIALOG.NO_TRANSACTIONS_TO_BE_INVOICED"></p>
        </div>
        <div *ngIf="isLoading" class="tw-h-[250px] tw-text-center tw-flex-1">
            <mat-spinner class="primary tw-top-[90px] tw-left-[280px]" diameter="60"></mat-spinner>
        </div>
    </section>
</tilby-dialog-content>
