<tilby-dialog-toolbar mat-dialog-title (confirm)="confirm()" [hideConfirm]="!_form?.valid"
    [title]="'HISTORY.EXPENSE_REPORT.TITLE'"
/>
<tilby-dialog-content>
    @if(totalAmount != null) {
        <div class="tw-flex tw-mb-5 tw-font-bold">
            <span translate [translateParams]="{ amount: totalAmount | tilbyCurrency }">HISTORY.EXPENSE_REPORT.SALE_AMOUNT</span>
        </div>
    }
    <!-- @if(expenseReportsAmount != null) {
        <div class="tw-flex tw-mb-5 tw-font-bold">
            <span translate [translateParams]="{ amount: expenseReportsAmount | tilbyCurrency }">HISTORY.EXPENSE_REPORT.EXPENSE_REPORTS_AMOUNT</span>
        </div>
    } -->
    @if(_form) {
        <tilby-magic-form [form]="_form" />
    }
</tilby-dialog-content>