import {
    Component,
    Injectable,
    inject,
} from '@angular/core';

import {
    CommonModule
} from '@angular/common';

import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    TilbyMagicFormComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogToolbarComponent
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
    lastValueFrom
} from 'rxjs';

import {
    Validators
} from '@angular/forms';

import {
    EntityManagerService
} from 'src/app/core';

import {
    TranslateModule
} from '@ngx-translate/core';

import { Sales } from 'tilby-models';
import { MathUtils } from 'src/app/shared/utils';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';

type ExpenseReportForm = CustomForm<ExpenseReportFormValue>;

type ExpenseReportFormValue = {
    amount: number,
    covers: number
};

type ExpenseReportDialogInput = {
    sale: Sales
};

@Component({
    selector: 'app-expense-report-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        TilbyDialogProgressBarComponent,
        TilbyMagicFormComponent,
        MatButtonModule,
        TilbyCurrencyPipe,
        TranslateModule,
    ],
    templateUrl: './expense-report-dialog.component.html',
    styleUrls: ['./expense-report-dialog.component.scss'],
    providers: []
})
class ExpenseReportDialogComponent {
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly data: ExpenseReportDialogInput = inject(MAT_DIALOG_DATA);
    private readonly entityManagerService = inject(EntityManagerService);;

    protected _form?: CustomFormGroup<ExpenseReportForm>;

    protected totalAmount: number | undefined;
    protected expenseReportsAmount: number | undefined;

    async ngOnInit() {
        const sale = this.data.sale;
        this.totalAmount = sale.final_amount || 0;

        const parentSales = await this.entityManagerService.sales.fetchCollectionOnline({ sale_parent_uuid: sale.uuid, pagination: false }) as Sales[];
        const emittedReports = parentSales.filter(sale => !!sale.sale_documents?.some((doc) => doc.document_type === 'expense_report'));

        this.expenseReportsAmount = emittedReports.reduce((prev, curr) => MathUtils.round(prev + (curr.final_amount || 0)), 0);

        this._form = new CustomFormGroup<ExpenseReportForm>({
            amount: new CustomFormControl(
                {
                    value: undefined,
                    disabled: false 
                }, {
                    validators: [
                        Validators.required,
                        Validators.min(0.01),
                        Validators.max(this.totalAmount - this.expenseReportsAmount),
                    ],
                }, {
                    ...new CustomFormControlProps(),
                    label: "HISTORY.EXPENSE_REPORT.AMOUNT",
                    inputType: 'number',
                    class: "tw-w-5/12",
                    inputConstraint: {
                        min: 0,
                        max: MathUtils.round(this.totalAmount - this.expenseReportsAmount),
                    },
                }
            ),
            covers: new CustomFormControl(sale.covers || 1, {
                    validators: [
                        Validators.required,
                        Validators.min(0),
                        Validators.max(sale.covers || 1),
                    ],
                }, {
                    ...new CustomFormControlProps(),
                    label: "HISTORY.EXPENSE_REPORT.COVERS",
                    inputType: 'select',
                    inputChoices: Array.from({ length: sale.covers || 1 }, (_, i) => ({ key: `${i + 1}`, value: i + 1 })),
                    class: "tw-w-5/12",
                    inputConstraint: {
                        min: 0,
                        max: MathUtils.round(sale.covers || 1),
                    },
                }
            )
        });
    }

    public async confirm() {
        if (!this._form?.valid) {
            return;
        }

        this.matDialogRef.close({ amount: this._form.value.amount, covers: this._form.value.covers });
    }
}


@Injectable({
    providedIn: 'root'
})
export class ExpenseReportDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<ExpenseReportDialogInput>): Promise<ExpenseReportFormValue | null> {
        const dialogConfig: NonNullableConfigData<any> = {
            ...this.switchMobileDesktopDimensions({ width: '800px' }),
            disableClose: true,
            ...config
        };

        return lastValueFrom(this.dialogRef.open(ExpenseReportDialogComponent, dialogConfig).afterClosed());
    }
}
