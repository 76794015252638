import { Injectable, Injector, inject } from '@angular/core';
import {
    errorsLogger,
    util
} from "app/ajs-upgraded-providers";

import { EntityBase } from "./entity-base";

import {
    Allergens,
    BookingShifts,
    Bookings,
    CashMovements,
    Categories,
    ChainCampaigns,
    ChainPrizes,
    ChainPromotions,
    Channels,
    Components,
    Customers,
    DailyClosingSchema,
    Departments,
    Dfge,
    EntitySchema,
    FidelitiesPoints,
    Items,
    NonFiscalDocuments,
    Orders,
    PaymentMethods,
    Printers,
    RawMaterials,
    Rooms,
    Sales,
    SaleTransactions,
    ShopPreferences,
    ShopSettings,
    Stock,
    Suppliers,
    Tickets,
    UserSetting,
    Vat
} from 'tilby-models';

import {
    ConfigurationManagerService,
    ConnectionService,
    DevicePreferences,
    ShopSessionEntity,
    UserActiveSessionManagerService,
    UserSessionsEntity
} from 'src/app/core';

import { pickBy } from 'src/app/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class EntityManagerService {
    //Injections
    private injector = inject(Injector);
    private errorsLoggerService = inject(errorsLogger);
    private utilService = inject(util);
    private userActiveSessionManagerService = inject(UserActiveSessionManagerService);

    private syncTimeout?: number | null;
    private syncPromise: Promise<void> | null = null;
    private syncCounter = 0;
    private logDebug = false;

    public shopSession = inject(ShopSessionEntity);
    public userSessions = inject(UserSessionsEntity);

    //Create entityBase wrappers for each entity
    public activityCodes = new EntityBase<any>('activity_codes');
    public allergens = new EntityBase<Allergens>('allergens');
    public applicationReports = new EntityBase<any>('application_reports');
    public bookings = new EntityBase<Bookings>('bookings');
    public bookingShifts = new EntityBase<BookingShifts>('booking_shifts');
    public campaigns = new EntityBase<ChainCampaigns>('campaigns');
    public cashMovements = new EntityBase<CashMovements>('cash_movements');
    public categories = new EntityBase<Categories>('categories');
    public channels = new EntityBase<Channels>('channels');
    public components = new EntityBase<Components>('components');
    public customers = new EntityBase<Customers>('customers');
    public dailyClosings = new EntityBase<DailyClosingSchema>('daily_closings');
    public departments = new EntityBase<Departments>('departments');
    public dgfe = new EntityBase<Dfge>('dgfe');
    public entitySchemas = new EntityBase<EntitySchema>('entity_schemas');
    public fidelitiesPoints = new EntityBase<FidelitiesPoints>('fidelities_points');
    public items = new EntityBase<Items>('items');
    public nonfiscalDocuments = new EntityBase<NonFiscalDocuments>('nonfiscal_documents');
    public orders = new EntityBase<Orders>('orders');
    public paymentMethods = new EntityBase<PaymentMethods>('payment_methods');
    public printers = new EntityBase<Printers>('printers');
    public prizes = new EntityBase<ChainPrizes>('prizes');
    public promotions = new EntityBase<ChainPromotions>('promotions');
    public rawMaterials = new EntityBase<RawMaterials>('raw_materials');
    public rooms = new EntityBase<Rooms>('rooms');
    public sales = new EntityBase<Sales>('sales');
    public saleTransactions = new EntityBase<SaleTransactions>('sale_transactions');
    public shopPreferences = new EntityBase<ShopPreferences>('shop_preferences');
    public devicePreferences = new EntityBase<DevicePreferences>('device_preferences');
    public shopSettings = new EntityBase<ShopSettings>('shop_settings');
    public slaveDevices = new EntityBase<any>('slave_devices');
    public stock = new EntityBase<Stock>('stock');
    public suppliers = new EntityBase<Suppliers>('suppliers');
    public tickets = new EntityBase<Tickets>('tickets');
    public userPreferences = new EntityBase<UserSetting>('user_preferences');
    public userSettings = new EntityBase<UserSetting>('user_settings');
    public vat = new EntityBase<Vat>('vat');
    //--------------------------------------------------------------------//

    public offlineFirstEntities: EntityBase<any>[];

    constructor(
    ) {
        ConnectionService.connectionStatus$.subscribe((status) => {
            if (status.online) {
                this.setWorkerTimeout(3000);
            } else {
                this.stopSyncAll();
            }
        });

        //Create a list of all offline-first entities
        this.offlineFirstEntities = Object.values(pickBy(this, (entity) => entity instanceof EntityBase && entity.isOfflineFirst));
    }

    private logDebugEvent(...args: any[]) {
        if (this.logDebug) {
            this.errorsLoggerService.debug('[ entityManager ]', ...args);
        }
    };

    private logWarnEvent(...args: any[]) {
        this.errorsLoggerService.warn('[ entityManager ]', ...args);
    }

    /**
     * Returns a promise that is resolved as soon as there isn't a sync task running
     */
    private async waitForPendingSync() {
        if (this.syncPromise) {
            try {
                await this.syncPromise;
            } catch (err) {
                //Nothing to do
            }
        }
    };

    /**
     * Attempts to send all the pending entity changes to the API
     */
    private async doSyncAll() {
        try {
            for (const entity of this.offlineFirstEntities) {
                await entity.syncEntityCollection();
            }
        } finally {
            //TODO: notify the sync completion with a subject
        }
    }

    /**
     * Clean-up function that removes the entities that are no longer required on the device storage
     */
    private async doCleanAll() {
        try {
            const configurationManagerService = this.injector.get(ConfigurationManagerService);
            const ignoreOpenOrders = !!configurationManagerService.getPreference('orders.load_old_orders');
            const dayStartTime = this.utilService.getDayStartTime().toISOString();

            await this.orders.cleanupEntity((order) =>
                // Clean up orders matching either of the following conditions:
                // - The order is stored/closed/missed
                // - The order has an open date that is before the day start time and hasn't a deliver date or the deliver date is also before the day start time

                ['closed', 'stored', 'missed'].includes(order.status) ||
                (!ignoreOpenOrders &&
                    (
                        order.open_at < dayStartTime &&
                        (order.deliver_at == null || order.deliver_at < dayStartTime)
                    )
                )
            );

            await this.sales.cleanupEntity((sale) => ['closed', 'stored'].includes(sale.status));
            
            const openSales = (await this.sales.fetchCollectionOffline()).map((sale) => sale.uuid);

            await this.saleTransactions.cleanupEntity((saleTransaction) => !openSales.includes(saleTransaction.sale_uuid));

            await this.dailyClosings.deleteAllSynced();
            await this.dgfe.deleteAllSynced();
            await this.cashMovements.deleteAllSynced();
            await this.applicationReports.deleteAllSynced();
        } finally {
            //TODO: notify the cleanup completion with a subject
        }
    };

    /**
     * Cancels the current sync timeout if present
     */
    private clearWorkerTimeout() {
        if (this.syncTimeout) {
            window.clearTimeout(this.syncTimeout);
            this.syncTimeout = null;
        }
    };

    /**
     * Sets the worker timeout
     */
    private setWorkerTimeout = (timeout?: number) => {
        this.clearWorkerTimeout();
        this.syncTimeout = window.setTimeout(() => this.executeSyncTask('syncAllWorker'), timeout || 15000, false);
    };

    /**
     * Runs the desired task. If a sync is present waits for completion before starting the task
     * @param {*} targetTask The task to execute
     */
    private async executeSyncTask(targetTask: ('syncAllWorker' | 'doSyncAll')) {
        //If there is a sync in progress wait for it to finish before launching another sync
        await this.waitForPendingSync();
        this.clearWorkerTimeout();

        try {
            this.syncPromise = this[targetTask]();
            await this.syncPromise;
        } finally {
            this.syncPromise = null;
            this.setWorkerTimeout();
        }
    };

    /**
     * Worker task that runs both syncAll and cleanAll.
     */
    private async syncAllWorker() {
        try {
            this.logDebugEvent('syncAll started');
            await this.doSyncAll();
            this.logDebugEvent('syncAll completed');
        } catch (err) {
            this.logWarnEvent('syncAll failed');
        }

        //Execute cleanAll once every 4 syncAlls (1 per minute)
        this.syncCounter = ((this.syncCounter + 1) % 4);

        if (this.syncCounter === 0) {
            try {
                this.logDebugEvent('cleanAll started');
                await this.doCleanAll();
                this.logDebugEvent('cleanAll completed');
            } catch (err) {
                this.logWarnEvent('cleanAll failed');
            }
        }
    };

    /**
     * executes an entity sync immediately
     * @return null
     */
    public async syncAll() {
        return this.executeSyncTask('doSyncAll');
    };

    /**
     * Resets the synchronizing flags of all offline-first entities.
     *
     * @return {Promise<void>} A promise that resolves when the sync status is reset.
     */
    public async resetSyncStatus() {
        for (const entity of this.offlineFirstEntities) {
            await entity.resetSyncStatus();
        }
    };

    async loadSettings(entities: (keyof EntityManagerService)[], forceReload: boolean) {
        for (const entityName of entities) {
            const isUserEntity = entityName.startsWith('user');
            const statusPath = `initEntity::${entityName}${(isUserEntity ? `::${this.userActiveSessionManagerService.getSession()?.id}` : '')}`;
            const entityStatus = localStorage.getItem(statusPath);

            if ((entityStatus && ['Pending', 'Fetching'].includes(entityStatus)) || forceReload) {
                try {
                    const entity = this[entityName];

                    if ('reloadEntityCollection' in entity) {
                        await entity.reloadEntityCollection({ avoidSync: true });
                    }

                    localStorage.setItem(statusPath, "OK");
                } catch (err) {
                    //Nothing to do
                }
            }
        }
    };

    /**
     * Starts the sync/cleanup routines.
     */
    public startAsyncRoutines() {
        const configurationManagerService = this.injector.get(ConfigurationManagerService);
        this.logDebug = !!(configurationManagerService.getSetting('logging.enable_entity_manager_debug'))
        this.setWorkerTimeout();
    }

    /**
     * Stops the synchronization of all tasks.
     *
     * @return {Promise<void>} Promise that resolves when synchronization is stopped.
     */
    private async stopSyncAll() {
        await this.waitForPendingSync();
        this.clearWorkerTimeout();
    };
}