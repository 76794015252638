
<tilby-dialog-toolbar mat-dialog-title
    [hideConfirm]="true"
    title="CASHREGISTER.PENDING_PRINTS_DIALOG.TITLE"
/>
<tilby-dialog-content>
    @for(pendingPrint of pendingPrints; track pendingPrint.id) {
        <div class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center tw-border-b tw-border-b-black tilby-dialog-text">
            <div class="tw-flex tw-flex-col tw-self-center tw-truncate tw-w-1/4">
                <span class="tw-font-bold tw-truncate">{{pendingPrint.name}}</span>
                <div>
                    <span class="tw-mr-2">{{pendingPrint.first_error_at | tilbyDate: "HH:mm" }}</span>
                    <span class="tw-font-bold">{{pendingPrint.printer.name}}</span>
                </div>
            </div>
            <span class="tw-font-bold tw-text-red-500">{{'CASHREGISTER.PENDING_PRINTS_DIALOG.ATTEMPTS' | translate:({ attempts: pendingPrint.attempts }) }}</span>
            @if(pendingPrint.is_printing) {
                    <mat-spinner diameter="24" strokeWidth="2" class="tw-self-center tw-mr-2"></mat-spinner>
            } @else {
                <div>
                    <button mat-icon-button (click)="retryPendingPrint(pendingPrint)" >
                        <mat-icon>print_error</mat-icon>
                    </button>
                    <button [matMenuTriggerFor]="$any(menu)" mat-icon-button>
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item translate="CASHREGISTER.PENDING_PRINTS_DIALOG.IGNORE_PRINT" (click)="deletePendingPrint(pendingPrint)"></button>
                        <button mat-menu-item translate="CASHREGISTER.PENDING_PRINTS_DIALOG.USE_ALTERNATIVE_PRINTER" (click)="useAlternativePrinter(pendingPrint)"></button>
                    </mat-menu>
                </div>
            }
        </div>
        <mat-divider></mat-divider>
    } @empty {
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-full">
            <span class="tw-self-center" translate="CASHREGISTER.PENDING_PRINTS_DIALOG.EMPTY"></span>
        </div>
    }
</tilby-dialog-content>