import { inject, Injectable } from '@angular/core';
import {
    CustomFormControl, CustomFormControlProps,
    CustomFormGroup
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { FiscalPrinters } from 'app/ajs-upgraded-providers';
import { SalesCustomer } from 'tilby-models';
import { EnvironmentInfoService } from 'src/app/core';
import { InputItemDataMagicForm } from "../../shared/model/model";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { ReceiptOptionsDialogComponent } from "./receipt-options-dialog.component";
import { FiscalPrintersService } from "../../../../app/modules/printers/service/fiscal-printers/fiscal-printers";
import { DevLogger } from "../../shared/dev-logger";
import { Validators } from "@angular/forms";
import { CustomForm } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import { MatDialogRef } from '@angular/material/dialog';
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import { lastValueFrom } from 'rxjs';
import { DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';

export type ReceiptFormValue = { courtesyReceipt: boolean, email: string };
export type ReceiptForm = CustomFormGroup<CustomForm<ReceiptFormValue>>;
export type ConfirmMode = "email" | "print" | "save";
export type ReceiptOptionsReturn = {
    mode: ConfirmMode,
    courtesyReceipt: boolean,
    email?: string,
}
export type ReceiptOptionsDialogData = {
    printerDocumentData: DocumentPrinterOptions,
    saleUuid: string,
    saleCustomer?: SalesCustomer
}

@Injectable({
    providedIn: 'root'
})
export class ReceiptOptionsDialogStateService {
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly fiscalPrintersService: FiscalPrintersService = inject(FiscalPrinters);

    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<ReceiptOptionsDialogComponent>, ReceiptOptionsReturn>

    private _receiptForm?: ReceiptForm;
    private _courtesyReceipt = false;
    private _mailDisabled = false;

    private log(...args: any[]) {
        DevLogger.log('RECEIPT_OPTIONS_DIALOG_STATE_SERVICE', ...args);
    }

    // DialogInit
    public openDialog(config: NonNullableConfigData<ReceiptOptionsDialogData>) {
        const {printerDocumentData, saleUuid, saleCustomer} = config.data;
        this.log('RECEIPT_OPTION_DIALOG');
        this.init(printerDocumentData, saleCustomer);
        const configPortal: NonNullablePortalConfigData<InputItemDataMagicForm<ReceiptFormValue> & { saleUuid: string }, ReceiptOptionsDialogComponent> = {
            data: {
                form: this._receiptForm!,
                saleUuid,
                component: ReceiptOptionsDialogComponent,
                titleLabel: 'CASHREGISTER.RECEIPT_OPTIONS_DIALOG.TITLE',
                hideConfirm: true,
                bottomActionButtons: this.createBottomActionButtons(),
            }
        };
        this.dialogRef = this.openDialogsService.openPortalDialogRef<ReceiptOptionsDialogComponent, PortalConfigData<InputItemDataMagicForm<ReceiptFormValue>, ReceiptOptionsDialogComponent>, ReceiptOptionsReturn>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });
        return lastValueFrom(this.dialogRef.afterClosed());
    }

    private init(printerDocumentData: DocumentPrinterOptions, saleCustomer?: SalesCustomer) {
        this._courtesyReceipt = printerDocumentData.options.courtesyReceipt || false;
        this._receiptForm = this.createReceiptForm(saleCustomer);
        this._mailDisabled = false;

        if (['fiscal_receipt'].includes(printerDocumentData.document_type.id)) {
            this._mailDisabled = true;

            if (!this.environmentInfoService.isWebApp()) {
                this.fiscalPrintersService.canUseEReceipt(printerDocumentData.printer).then((canUse: boolean) => {
                    this._mailDisabled = !canUse;
                });
            }
        }
    }
    private createReceiptForm(saleCustomer?: SalesCustomer) {
        return new CustomFormGroup({
            email: new CustomFormControl({ value: saleCustomer?.email || '', disabled: false }, { validators: [Validators.email, Validators.required] }, { ...new CustomFormControlProps(), label: 'CASHREGISTER.RECEIPT_OPTIONS_DIALOG.EMAIL', icon: { prefix: 'mail' } }),
            courtesyReceipt: new CustomFormControl({ value: this._courtesyReceipt, disabled: false }, { validators: [Validators.required] }, { ...new CustomFormControlProps(), label: 'CASHREGISTER.RECEIPT_OPTIONS_DIALOG.PRINT_COURTESY_RECEIPT', class: 'tw-w-full', inputType: 'checkbox' }),
        })
    }

    private createBottomActionButtons() {
        return <GeneralInputButton[]>[
            { name: 'CASHREGISTER.RECEIPT_OPTIONS_DIALOG.SEND_VIA_EMAIL', click: () => this.checkAndConfirm('email'), isDisable: () => this._mailDisabled || this.email?.invalid },
            { name: 'CASHREGISTER.RECEIPT_OPTIONS_DIALOG.PRINT', click: () => this.checkAndConfirm('print') },
            { name: 'CASHREGISTER.RECEIPT_OPTIONS_DIALOG.SAVE_ONLY', click: () => this.checkAndConfirm('save') },
        ];
    }


    // State of Dialog Content
    private get email() {
        return this._receiptForm?.controls.email;
    }
    private get courtesyReceipt() {
        return this._receiptForm?.controls.courtesyReceipt;
    }

    // BottomActionButtonsCallback
    private checkAndConfirm(mode: ConfirmMode) {
        if (this._receiptForm && !this.checkFormValue(this._receiptForm) && mode == 'email') return

        this.dialogRef?.close({
            mode,
            courtesyReceipt: this.courtesyReceipt?.value,
            ...(this.email?.value && { email: this.email?.value })
        });
    }
    private checkFormValue(form: ReceiptForm) {
        form.markAllAsTouched();
        if (form.valid) {
            return form.value as ReceiptFormValue;
        }
    }
}
