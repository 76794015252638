
    <tilby-dialog-toolbar title="GIFT_CARD.PAYMENT_DIALOG.TITLE" [disabled]="!form.valid" (confirm)="confirm()"/>
    <tilby-dialog-content>
        <tilby-magic-form [form]="form"/>
        @if(form.valid && validationStatusMessage){
            <div class="tw-flex tw-justify-center tw-items-center">
                <span>{{validationStatusMessage}}</span>
            </div>
        }
    </tilby-dialog-content>

