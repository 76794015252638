<mat-toolbar class="tw-bg-white">
    <div class="tw-flex tw-truncate">
        <p class="tw-text-md tw-truncate" [translate]="label||''"></p>
    </div>
</mat-toolbar>
<mat-dialog-content class="tw-min-h-[calc(100vh-117px)] sm:tw-min-h-[200px] tw-w-full sm:tw-w-96">
    <mat-radio-group [(ngModel)]="selectedElementId" class='tw-flex tw-flex-col tw-leading-6 sm:tw-leading-none'>
        <mat-radio-button *ngFor="let element of elements" [value]="element.id" class="tw-mt-3 radio-list-selector">{{element.name}}</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="" *ngIf="!hideCancel">{{cancelLabel}}</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="confirm()" [disabled]="hasCheckDisabled()">{{confirmLabel}}</button>
    <button class="tw-ml-8" mat-fab aria-label="Add" (click)="executeAction(addAction)" *ngIf="addAction">
        <mat-icon>add</mat-icon>
    </button>
</mat-dialog-actions>
