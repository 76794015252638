import * as angular from 'angular';
import * as _ from 'lodash';
import { v4 as generateUuid } from 'uuid';

angular.module('orders').factory('splitOrder', splitOrder);

splitOrder.$inject = ["$mdDialog", "$rootScope", "$translate", "entityManager", "OperatorManager", "progressivesManager", "connection", "orderUtils"];

function splitOrder($mdDialog, $rootScope, $translate, entityManager, OperatorManager, progressivesManager, connection, orderUtils) {

    splitOrderController.$inject = ["$scope", "$mdDialog", "order"];
    function splitOrderController($scope, $mdDialog, order) {
        $scope.coverNumbers = _.range(0, 51);
        $scope.tables = [];
        $scope.selectedTable = null;

        entityManager.orders.fetchCollectionOffline().then(function(orders) {
            var busyTables = _.filter(_.map(orders, 'table_id'), _.isFinite);
            entityManager.rooms.fetchCollectionOffline().then(function(rooms) {
                $scope.tables = [];
                _.each(rooms, function(room) {
                    _.each(room.tables, function(table) {
                        if (table.order_type === 'multiple' || !_.includes(busyTables, table.id)) {
                            $scope.tables.push({
                                room_id: room.id,
                                room_name: room.name,
                                table_id: table.id,
                                table_name: table.name,
                                name: room.name + " - " + table.name
                            });
                        }
                    });
                });
            });
        });

        var cleanUpNewOrder = function(order) {
            delete order.checkout_at;
            delete order.closed_at;
            delete order.created_at;
            delete order.createdby_id;
            delete order.deleted_at;
            delete order.deletedby_id;
            delete order.deliver_at;
            delete order.id;
            delete order.last_sent_at;
            delete order.last_sent_by;
            delete order.lastupdate_by;
            delete order.open_at;
            delete order.operator_id;
            delete order.operator_name;
            delete order.order_number;
            delete order.previous;
            delete order.previous_order;
            delete order.room_id;
            delete order.room_name;
            delete order.order_customer;
            delete order.table_id;
            delete order.table_name;
            delete order.updated_at;
            delete order.updatedby_id;
        };

        var cleanUpNewOrderItem = function(orderItem) {
            var cleanOrderItemSubEntity = function(subEntity) {
                delete subEntity.id;
                delete subEntity.order_item_id;
                delete subEntity.created_at;
                delete subEntity.updated_at;
                delete subEntity.deleted_at;
            };

            delete orderItem.added_at;
            delete orderItem.created_at;
            delete orderItem.deleted_at;
            delete orderItem.id;
            delete orderItem.lastupdate_at;
            delete orderItem.lastupdate_by;
            delete orderItem.operator_id;
            delete orderItem.operator_name;
            delete orderItem.order_id;
            delete orderItem.order_name;
            delete orderItem.updated_at;
            _.each(orderItem.variations, cleanOrderItemSubEntity);
            _.each(orderItem.ingredients, cleanOrderItemSubEntity);
        };

        var updateNewOrderData = function(newOrder) {
            const opData = OperatorManager.getOperatorData();

            Object.assign(newOrder, {
                covers: $scope.newOrderCovers,
                type: "normal",
                status: "open",
                operator_id: opData.id,
                operator_name: opData.full_name,
                open_at: new Date().toISOString(),
                uuid: generateUuid()
            });

            if ($scope.selectedTable && connection.isOnline()) {
                Object.assign(newOrder, {
                    covers: $scope.newOrderCovers,
                    room_id: $scope.selectedTable.room_id,
                    room_name: $scope.selectedTable.room_name,
                    table_id: $scope.selectedTable.table_id,
                    table_name: $scope.selectedTable.table_name
                });
            }

            _.each(newOrder.order_items, updateNewOrderItemData);
        };

        var updateNewOrderItemData = function(orderItem) {
            const opData = OperatorManager.getOperatorData();

            _.set($scope.originalOrder, ['deleted_items', orderItem.uuid, 'split_order_quantity'], orderItem.quantity);

            Object.assign(orderItem, {
                added_at: new Date().toISOString(),
                operator_id: opData.id,
                operator_name: opData.full_name,
                uuid: generateUuid()
            });
        };

        var updateOrderPrices = function() {
            orderUtils.calculateOrderPrices($scope.originalOrder);
            orderUtils.calculateOrderPrices($scope.splitOrder);
        };

        $scope.canConfirmOrder = function() {
            return !_($scope.splitOrder.order_items).reject({ quantity: 0 }).isEmpty();
        };

        var moveOrderItem = function(orderItem, destinationOrder) {
            var destination;
            if (orderItem.quantity) {
                destination = _.find(destinationOrder.order_items, {
                    uuid: orderItem.uuid
                });
                if (destination) {
                    if (orderItem.quantity % 1) {
                        destination.quantity = orderItem.quantity;
                        orderItem.quantity = 0;
                    } else {
                        destination.quantity++;
                        orderItem.quantity--;
                    }
                }
                updateOrderPrices();
            }
        };

        $scope.moveToSplitOrder = function(orderItem) {
            moveOrderItem(orderItem, $scope.splitOrder);
        };

        $scope.moveToOriginalOrder = function(orderItem) {
            moveOrderItem(orderItem, $scope.originalOrder);
        };

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.isOnline = function() {
            return connection.isOnline();
        };

        $scope.visibleItems = function(order) {
            return _.filter(order.order_items, function(orderItem) {
                return orderItem.quantity > 0;
            });
        };

        $scope.confirm = function() {
            _.remove($scope.originalOrder.order_items, {
                quantity: 0
            });
            _.remove($scope.splitOrder.order_items, {
                quantity: 0
            });
            if ($scope.originalOrder.order_items.length) {
                updateNewOrderData($scope.splitOrder);
                $scope.originalOrder.covers = ($scope.originalOrder.covers - $scope.splitOrder.covers >= 1) ? $scope.originalOrder.covers - $scope.splitOrder.covers : 1;
                $mdDialog.hide({
                    originalOrder: $scope.originalOrder,
                    splitOrder: $scope.splitOrder
                });
            } else {
                $mdDialog.cancel();
            }
        };

        $scope.originalOrder = _.cloneDeep(order);
        $scope.splitOrder = _.cloneDeep($scope.originalOrder);
        $scope.newOrderCovers = 1;

        cleanUpNewOrder($scope.splitOrder);

        _.each($scope.splitOrder.order_items, function(orderItem) {
            cleanUpNewOrderItem(orderItem);
            orderItem.quantity = 0;
        });

        updateOrderPrices();
    }

    var splitOrder = {
        show: function(order) {
            return $mdDialog.show({
                controller: splitOrderController,
                template: require('./split-order.html'),
                locals: {
                    order: order
                }
            }).then(function(orders) {
                return progressivesManager.getProgressives().then(function(progressive) {
                    var orderNumber;

                    if (connection.isOnline()) {
                        orderNumber = progressive.order_number + 1;

                        if (_.isNaN(orderNumber) || _.isUndefined(orderNumber)) {
                            orderNumber = 1;
                        }

                        orders.splitOrder.name = $translate.instant('ORDERS.NEW_ORDER.ONLINE_ORDER', { orderNumber: orderNumber });
                    } else {
                        orderNumber = progressive.order_number_offline + 1;

                        if (_.isNaN(orderNumber) || _.isUndefined(orderNumber)) {
                            orderNumber = 1;
                        }

                        var nameString;

                        if ($rootScope.userActiveSession.first_name && $rootScope.userActiveSession.last_name) {
                            nameString = $rootScope.userActiveSession.first_name[0] + $rootScope.userActiveSession.last_name[0];
                        } else {
                            nameString = $rootScope.userActiveSession.username.substr(0, 3);
                        }

                        orders.splitOrder.name = $translate.instant('ORDERS.NEW_ORDER.OFFLINE_ORDER', { orderNumber: orderNumber, nameString: nameString });
                    }
                    orders.splitOrder.order_number = orderNumber;

                    return orders;
                });
            });
        }
    };

    return splitOrder;
}
