import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { DevLogger } from "src/app/shared/dev-logger";
import { RefundOrRemoveItemDialogStateService, RefundOrRemoveItemForm } from "./refund-or-remove-item-dialog.state.service";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";

@Component({
    selector: 'app-refund-or-remove-item-dialog',
    standalone: true,
    imports: [CommonModule, TilbyMagicFormComponent],
    templateUrl: './refund-or-remove-item-dialog.component.html',
    styleUrls: ['./refund-or-remove-item-dialog.component.scss'],
    providers: [OnDestroyService],
    host:{class:'tw-p-0'}
  })
  export class RefundOrRemoveItemDialogComponent {

    protected readonly data:InputItemDataMagicForm<RefundOrRemoveItemForm>&{bottomActionButtons:GeneralInputButton[], titleLabel:string, hideConfirm:boolean} = inject(MAT_DIALOG_DATA);
    protected readonly onDestroyService= inject(OnDestroyService);
    protected readonly refundOrRemoveItemDialogStateService= inject(RefundOrRemoveItemDialogStateService);

    protected showForm = false;

    constructor(){
        this.subscriptions();
    }

    private subscriptions(){
        this.refundOrRemoveItemDialogStateService.showForm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe((res: boolean) => {
            this.showForm = res;
            if (res) {
                this.data.bottomActionButtons = [];
                this.data.titleLabel = 'CASHREGISTER.REFUND_REMOVE.CAUSE';
                this.data.hideConfirm = false
            }
        });
    }

    protected log(...args: any[]) {
        DevLogger.log('REFUND_OR_REMOVE_ITEM_DIALOG_COMPONENT', ...args);
    }

  }
