import { snakeCase } from "lodash";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentConfig } from "src/environments/environment-config";
import { UserActiveSessionManagerService } from "src/app/core";
import { OAuthData, User } from "src/app/models";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class OauthService {
    private static readonly defaultHeaders = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    constructor(
        private http: HttpClient,
        private userActiveSession: UserActiveSessionManagerService
    ) {
        // Fix redirect uri if we are in a not-secure context
        if (['SclobyApp3', 'Scloby3Beta'].includes(EnvironmentConfig.clientId) && window.location.protocol == 'http') {
            EnvironmentConfig.redirectUri = EnvironmentConfig.redirectUri.replace('https', 'http');
        }
    }

    /**
     * Sends the access token request using the one-time code provided in the URL.
     *
     * @return {Promise<OAuthData>} Returns a promise that resolves to the OAuth data
     */
    public checkAccess() {
        const code = this.checkCodeFromUrl();

        if (!code) {
            throw 'NO_CODE_PROVIDED';
        }

        return this.askAccessToken(code);
    }

    /**
     * Sends a logout request for the current user, invaliding its session.
     *
     * @param {number} userId - The ID of the user to be logged out.
     * @param {string} accessToken - The access token of the user.
     */
    public async logout(userId: number, accessToken: string) {
        const userActiveSession = this.userActiveSession.getSession();

        //Prepare URL
        const logoutParams = new HttpParams()
            .set('logout', 'true')
            .set('user_id', userId ? userId : userActiveSession?.id || '');

        const url = this.getTilbyLoginUrl(['clientId']) + '&' + logoutParams.toString();

        //Prepare payload
        const finalAccessToken = accessToken ? accessToken : userActiveSession?.oauthdata?.access_token || '';
        const payload = new HttpParams().set('access_token', finalAccessToken);

        //Send request
        try {
            await firstValueFrom(this.http.post(url, payload.toString(), { headers: OauthService.defaultHeaders }));
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                if (error.status === -1) {
                    return;
                }
            }

            throw error;
        }
    }

    /**
     * Logs out the users contained in the array, invaliding their sessions.
     *
     * @param {User[]} users - An array of User objects representing all the users.
     * @return {Promise<void>} A Promise that resolves when all of the logout requests are completed
     */
    public async logoutAll(users: User[]) {
        for (let user of users) {
            try {
                await this.logout(user.id, user.oauthdata.access_token);
            } catch (error) {
                //TODO: log errors
            }
        }
    }

    /**
     * Retrieves the value of the 'code' query parameter from the URL.
     *
     * @return {string} The value of the 'code' query parameter, or null if it is not present.
     */
    public checkCodeFromUrl() {
        return new URLSearchParams(window.location.search).get('code');
    }

    /**
     * Generates the Tilby login URL based on the given parameters list.
     *
     * @param {string[]} paramsList - The list of parameters used to generate the login URL.
     * @return {string} The generated Tilby login URL.
     */
    public getTilbyLoginUrl(paramsList: string[]) {
        let loginUrl = EnvironmentConfig.tilbyLoginUrl + 'signin.php';

        let loginParams = new HttpParams();

        for (const param of paramsList) {
            if (EnvironmentConfig.hasOwnProperty(param)) {
                loginParams = loginParams.set(snakeCase(param), EnvironmentConfig[param as keyof EnvironmentConfig]);
            }
        }

        if (loginParams.keys().length) {
            loginUrl += '?' + loginParams.toString();
        }

        return loginUrl;
    }

    /**
     * Gets the access token from the URL.
     *
     * @return {string} The access token from the URL.
     */
    public checkAccessTokenFromUrl() {
        return new URLSearchParams(window.location.search).get('access_token');
    }

    /**
     * Requests an access token using the provided authorization code.
     *
     * @param {string} code - The authorization code.
     * @return {Promise<OAuthData>} A promise that resolves to the OAuth data containing the access token.
     */
    public askAccessToken(code: string): Promise<OAuthData> {
        const accessTokenUri = EnvironmentConfig.tilbyLoginUrl + 'accesstoken.php';

        const payload = new HttpParams()
            .set('client_id', EnvironmentConfig.clientId)
            .set('client_secret', EnvironmentConfig.clientSecret)
            .set('redirect_uri', EnvironmentConfig.redirectUri)
            .set('code', code);

        //Obtain auth data
        return firstValueFrom(this.http.post<OAuthData>(accessTokenUri, payload.toString(), { headers: OauthService.defaultHeaders }));
    }

    /**
     * Refreshes the OAuth token using the provided OAuth data.
     *
     * @param {OAuthData} oauthdata - The current OAuth data used to refresh the token.
     * @return {Promise<OAuthData>} - The updated OAuth data.
     */
    public async refreshToken(oauthdata: OAuthData): Promise<OAuthData> {
        if (!oauthdata?.refresh_token) {
            throw 'MISSING_REFRESH_TOKEN';
        }

        const accessTokenUri = EnvironmentConfig.tilbyLoginUrl + 'accesstoken.php';

        const payload = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('client_id', EnvironmentConfig.clientId)
            .set('client_secret', EnvironmentConfig.clientSecret)
            .set('refresh_token', oauthdata.refresh_token);

        const result = await firstValueFrom(this.http.post<OAuthData>(accessTokenUri, payload.toString(), { headers: OauthService.defaultHeaders }));

        if ((result as any)?.error == 'invalid_request') {
            throw result;
        }

        return result;
    }
}