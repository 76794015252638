import * as angular from 'angular';
import * as jsBarcode from 'jsbarcode';
import * as moment from 'moment-timezone';
import { ItemLabelsTemplate, ItemLabelsTemplateData } from 'src/app/shared/model/item-labels-template.model';
import { ChainPromotions, Items, PromotionsItems } from 'tilby-models';

type PriceListName = keyof Pick<Items, 'price1' | 'price2' | 'price3' | 'price4' | 'price5' | 'price6' | 'price7' | 'price8' | 'price9' | 'price10'>;

export class ItemLabelsGeneratorService {
    constructor(
        private $translate: any,
        private $filter: any,
        private fiscalUtils: any,
        private util: any,
        private barcodeManager: any
    ) {
    }

    public getDefaultTemplates(): ItemLabelsTemplate[] {
        return [{
            id: 'product_label',
            name: this.$translate.instant('PROMOTIONS.LABELS_GENERATOR.PRODUCT_LABEL'),
            data: {
                height: 2 * 72,
                width: 3 * 72,
                content: [{
                    type: 'text',
                    params: {
                        x: 5,
                        y: 17,
                        style: 'font-size: 10px;font-family: monospace;font-weight: bold;'
                    },
                    content: 'name'
                }, {
                    type: 'text',
                    params: {
                        x: 5,
                        y: 37,
                        style: 'font-size: 10px;font-family: monospace;'
                    },
                    content: 'promotion_name'
                }, {
                    type: 'text',
                    params: {
                        x: 5,
                        y: 57,
                        style: 'font-size: 10px;font-family: monospace;'
                    },
                    content: { type: 'promotion_to_date', prefix: this.$translate.instant('PROMOTIONS.LABELS_GENERATOR.PROMOTION_TO_DATE') }
                }, {
                    type: 'text',
                    params: {
                        x: 211,
                        y: 87,
                        'text-anchor': 'end',
                        style: 'font-family: monospace;text-decoration: line-through;'
                    },
                    content: 'original_price'
                }, {
                    type: 'text',
                    params: {
                        x: 211,
                        y: 111,
                        'text-anchor': 'end',
                        style: 'font-family: monospace;font-weight: bold;font-size: 16px;'
                    },
                    content: 'discounted_price'
                }, {
                    type: 'svg',
                    params: {
                        x: 5,
                        y: 75
                    },
                    content: { type: 'barcode', options: { height: 40, width: 1, margin: 0 } }
                }]
            }
        }, {
            id: 'front',
            name: this.$translate.instant('PROMOTIONS.LABELS_GENERATOR.FRONT'),
            data: {
                height: 2 * 72,
                width: 6 * 72,
                content: [{
                    type: 'text',
                    params: {
                        x: 5,
                        y: 17,
                        style: 'font-family: monospace;font-weight: bold;'
                    },
                    content: 'name'
                }, {
                    type: 'text',
                    params: {
                        x: 5,
                        y: 37,
                        style: 'font-family: monospace;'
                    },
                    content: 'promotion_name'
                }, {
                    type: 'text',
                    params: {
                        x: 5,
                        y: 57,
                        style: 'font-family: monospace;'
                    },
                    content: 'promotion_to_date'
                }, {
                    type: 'text',
                    params: {
                        x: 427,
                        y: 87,
                        'text-anchor': 'end',
                        style: 'font-family: monospace;text-decoration: line-through;'
                    },
                    content: 'original_price'
                }, {
                    type: 'text',
                    params: {
                        x: 427,
                        y: 111,
                        'text-anchor': 'end',
                        style: 'font-family: monospace;font-weight: bold;font-size: 16px;'
                    },
                    content: 'discounted_price'
                }, {
                    type: 'svg',
                    params: {
                        x: 5,
                        y: 75
                    },
                    content: { type: 'barcode', options: { height: 40, width: 1, margin: 0 } }
                }]
            }
        }];
    }

    public generateLabel(labelTemplate: ItemLabelsTemplateData, promotion: ChainPromotions | null, priceList: Number, item: Items & PromotionsItems) {
        const labelNode = this.util.createXMLNode('svg', { overflow: 'hidden', width: labelTemplate.width, height: labelTemplate.height });

        if (!Array.isArray(labelTemplate?.content)) {
            return labelNode.toString();
        }

        const priceListName = `price${priceList}` as PriceListName;

        for (let element of labelTemplate.content) {
            let nodeContent = '';
            const elementType = element.type;
            const elementContent = typeof element.content === 'string' ? { type: element.content } : element.content;

            switch (elementContent.type) {
                case 'name':
                    nodeContent = item.name;
                    break;
                case 'order_name':
                    nodeContent = item.order_name || '';
                    break;
                case 'description':
                    nodeContent = item.description || '';
                    break;
                case 'promotion_name':
                    nodeContent = promotion?.name || '';
                    break;
                case 'print_date':
                    nodeContent = moment().format('l');
                    break;
                case 'promotion_from_date':
                    nodeContent = promotion?.to_date ? moment(promotion.from_date).format('l') : '';
                    break;
                case 'promotion_to_date':
                    nodeContent = promotion?.to_date ? moment(promotion.to_date).format('l') : '';
                    break;
                case 'original_price':
                    nodeContent = this.$filter('sclCurrency')(item[priceListName]);
                    break;
                case 'discounted_price':
                    let discountedPrice = promotion?.perc_discount == null ? item.discounted_price : this.fiscalUtils.roundDecimals(item[priceListName]! - this.fiscalUtils.roundDecimals(item[priceListName]! * promotion.perc_discount / 100));
                    nodeContent = this.$filter('sclCurrency')(discountedPrice);
                    break;
                case 'barcode':
                    const barcodeElement = document.createElement('svg');
                    const targetBarcode = item.barcodes?.[0]?.barcode || this.barcodeManager.getGenericItemBarcode(item);
                    const barcodeSettings = Object.assign({ format: "EAN13" }, elementContent?.options || {});

                    try {
                        jsBarcode(barcodeElement, targetBarcode, barcodeSettings);
                    } catch (e) {

                    }

                    nodeContent = barcodeElement.innerHTML;
                    break;
            }

            if(nodeContent) {
                if (elementType === 'text') {
                    if (elementContent.prefix) {
                        nodeContent = elementContent.prefix + nodeContent;
                    }
    
                    if (elementContent.suffix) {
                        nodeContent = nodeContent + elementContent.suffix;
                    }
                }

                labelNode.appendChild(this.util.createXMLNode(elementType, element.params, nodeContent, { _escape: !['svg'].includes(elementType) }));
            }
        }

        return labelNode.toString();
    }
}

ItemLabelsGeneratorService.$inject = ['$translate', '$filter', 'fiscalUtils', 'util', 'barcodeManager'];

angular.module('application').service('itemLabelsGenerator', ItemLabelsGeneratorService);