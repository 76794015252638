<section class="tw-p-5 tw-h-[calc(100vh-113px)] tw-overflow-scroll">
    <tilby-magic-form [form]="generalShopInformationsFormGroup">
        <ng-container [formGroup]="generalShopInformationsFormGroup">
            <tilby-map-coordinates #contentProjection formControlLabel="map">
                @if(withoutAddress){
                    <img mapCoordinates src="https://maps.googleapis.com/maps/api/staticmap?center={{city}},{{country}}&zoom=12&size=1600x300&key={{google_maps_api}}"/>
                }
                @if(latitudeAndLongitude){
                    <img mapCoordinates src="https://maps.googleapis.com/maps/api/staticmap?center={{latitude}},{{longitude}}&zoom=16&size=600x400&markers=color:red%7C{{latitude}},{{longitude}}&key={{google_maps_api}}"/>
                }
            </tilby-map-coordinates>
        </ng-container>
    </tilby-magic-form>
</section>