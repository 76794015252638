import * as angular from 'angular';
import * as async from 'async';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { stringToLines } from 'src/app/shared/string-utils';

angular.module('printers').factory('EpsonMFDriver', ["$q", "checkManager", "fiscalUtils", "epsonUtils", "util", function($q, checkManager, fiscalUtils, epsonUtils, util) {
	/**
	 *  Tilby Epson Driver for FP 81-II and FP 90 III
	 *
	 *  Usage:
	 *  EpsonMFDriver.setup() and after call public methods:
	 *  - getPrinterStatus
	 *  - autoConfigurePrinter
	 *  - printFiscalReceipt
	 *  - printInvoice
	 *  - printCourtesyReceipt
	 *  - printNonFiscal
	 *  - printOrder
	 *  - openCashDrawer
	 *  - dailyClosing
	 *  - dailyRead
	 *  - deposit
	 *  - withdrawal
	 *  - readDgfe (read/print) (won't implement)
	 *  - readDgfeBetween (read/print)
	 *  - printFiscalMemory
	 *  - printFiscalMemoryBetween
	 *
	 *  - printFreeNonFiscal
	 *  - printSummaryInvoice
	 */

	var scope = {};
	var giftString = "Omaggio";

	function getSclID() {
		var sclID = '', random;
		for (var i = 0; i < 8; i++) {
			random = Math.random() * 16 | 0;
			sclID += random.toString(16);
		}
		return sclID;
	}

	/**
	 * dateToString
	 */
	function dateToString(date) {
		return moment(date).format('DD/MM/YYYY HH:mm');
	}

	/**
	 * orderToLines
	 */
	function orderToLines(order) {
		var commands = [];

		var printerColumns = scope.printer.columns || 46;

		function border(car) {
			if (!car) {
				car = "=";
			}

			return _.repeat(car, printerColumns);
		}

		// Border
		commands.push(border());

		// Alert
		if (!scope.printer.columns || scope.printer.columns === 48) {
			commands.push("ATTENZIONE: Si e' verificato un problema di");
			commands.push("connettivita' al cameriere: " + order.operator_name);
			commands.push("Si prega di inserire manualmente i dati della");
			commands.push("comanda sulla schermata di cassa.");
		} else if (scope.printer.columns === 32) {
			commands.push("ATTENZIONE: Si e' verificato un");
			commands.push("problema di connettivita' al");
			commands.push("cam: " + order.operator_name);
			commands.push("Si prega di inserire manualmente");
			commands.push("i dati della comanda sulla ");
			commands.push("schermata di cassa.");
		}
		// Border
		commands.push(border());

		// Ordername
		if (order.name) {
			commands.push(order.name);
		}

		// Date/Time and #
		var dateNumber = (order.open_at ? dateToString(order.open_at) : "") + (order.order_number ? (" #" + order.order_number) : "");
		commands.push(dateNumber);

		// Delivery Date/Time
		if (order.deliver_at) {
			commands.push("Da consegnare: " + dateToString(order.deliver_at));
		}

		// Room name & Table name
		if (order.room_name && order.table_name) {
			commands.push(order.room_name + " " + order.table_name);
		}

		// Covers
		if (order.covers) {
			commands.push("Numero di coperti: " + order.covers);
		}

		// Customer
		if (order.order_customer) {
            var customerName = util.getCustomerCaption(order.order_customer);
            
            if(customerName) {
                commands.push("Cliente: " + customerName);
            }
        }

		// Border
		commands.push(border());

		//commands.push(toNonFiscalLine("Q.TA' / DESCRIZIONE         EURO"));
		var itemsHeader = _.padEnd("Q.TA' / DESCRIZIONE", printerColumns - 4, ' ') + "EURO";
		//console.log("Ciao:"+(printerColumns - itemsHeader.length - 4));

		commands.push(itemsHeader);
		commands.push('');
		// Order_items
		_.each(order.order_items, function(oi) {
			var rowPrice = fiscalUtils.roundDecimalsToString(oi.price * oi.quantity);
			var itemLine = _.padEnd(oi.quantity + "x " + oi.name, (printerColumns - rowPrice.length), ' ') + rowPrice;
			commands.push(itemLine);
			// Ingredients
			_.each(oi.ingredients, function(i) {
				var ingredientRow = '';
				if (i.type === 'added') {
					ingredientRow += "  + ";
				} else if (i.type === 'removed') {
					ingredientRow += "  - ";
				}
				ingredientRow += i.name;
				if (i.price_difference) {
					var iPrice = fiscalUtils.roundDecimalsToString(i.price_difference * oi.quantity);
					ingredientRow += _.padStart(iPrice, printerColumns - ingredientRow.length, ' ');
				}
				commands.push(ingredientRow);
			});
			// Variations
			_.each(oi.variations, function(i) {
				var variationRow = '';
				variationRow += '  ' + i.name + ": " + i.value;
				if (i.price_difference) {
					var iPrice = fiscalUtils.roundDecimalsToString(i.price_difference * oi.quantity);
					variationRow += _.padStart(iPrice, printerColumns - variationRow.length, ' ');
				}
				commands.push(variationRow);
			});
		});

		// Border
		commands.push(border("-"));

		// Amount
		var totString = 'T0TALE EURO';
		var totAmount = fiscalUtils.roundDecimalsToString(order.amount);
		totString += _.padStart(totAmount, printerColumns - totString.length, ' ');
		commands.push(totString);

		return commands;
	}

	/**
	 * linesToCommands
	 */
	function linesToCommands(lines) {
		if (!_.isArray(lines)) {
			if (_.isString(lines)) {
				lines = lines.split("\n");
			}
		}

		var root = util.createXMLNode();
		var nfXml = util.createXMLNode("printerNonFiscal");
		root.appendChild(nfXml);

		// Opening fiscal receipt
		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		_.forEach(lines, function (l) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: l.replace(/[<>]/g, ' ').replace(String.fromCharCode(160), ' ')
			}));
		});

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));
		return root.toString();
	}

	function configureInvoiceNumber(invoiceNumber, successFunction, errorFunction) {
		// Set Invoice Number
		var invoiceNumberConf = _.padStart(invoiceNumber, 4, '0');
		// Max invoice rows = 99
		var invoiceRows = '99';
		// Header rows
		var headerRows = '06';
		// printHeader
		var printHeader = '1';
		// Text 'Esenzione'
		var esenzione = "              ";
		// Prefix/Suffix
		var ilength = 0;
		if (scope.printer && scope.printer.invoice_prefix) {
			ilength = scope.printer.invoice_prefix.toString().length;
		}

		var invoicePrefix = _.repeat('0', 10 - ilength);

		if (scope.printer.invoice_prefix) {
			invoicePrefix += scope.printer.invoice_prefix.toString();
		}
		// Pos
		var pos = '1';

		var invoiceSetupData = invoiceNumberConf.toString() + invoiceRows.toString() + headerRows.toString() + printHeader.toString() + esenzione.toString() + invoicePrefix.toString() + pos.toString();
		var command = "<printerCommand><directIO command=\"4025\" data=\"" + invoiceSetupData + "\" comment=\"Configuring invoice parameters\"></printerCommand>";

		console.log("Executing command:" + command);
		epsonUtils.sendCommands(scope.printer, command).then(function() {
			successFunction("OK");
		}, function(error) {
			//console.log(error);
			if (error === 'EPSON.ERROR_17') {
				errorFunction("FISCAL_CLOSING_NEEDED");
			} else {
				errorFunction(error);
			}
		});
	}

	/**
	 * @description configurationToCommands
	 */
	function configurationToCommands(options) {
		return fiscalUtils.getPrinterConfigurationResources().then(function(resources) {
			/* Configurazione fatture
			Numero Fattura     4 byte
			N.Righe            2 byte (99)
			N.R.bianche testa  2 byte (0)
			Si/No Stampa intes 1 byte (1)
			Esenzione          14 byte
			PREF/SUF           10 byte
			POS                1 byte (0, solo numero - 1 pref.sx, 2 suff.dx)     */
			
			// Read Currenct Invoice Number
			return getNextInvoiceNumber(scope.printer).then(function(invoiceNumber) {
				var commands = [];
				// Set Invoice Number
				var invoiceNumberConf = _.padStart(invoiceNumber, 4, '0');
				// Max invoice rows = 99
				var invoiceRows = '99';
				// Header rows
				var headerRows = '06';
				// printHeader
				var printHeader = '1';
				// Text 'Esenzione'
				var esenzione = "              ";
				// Prefix/Suffix
				var invoicePrefix = _.get(scope, "printer.invoice_prefix") ? _.padStart(scope.printer.invoice_prefix, 10, '0') : _.repeat('0', 10);
				// Pos
				var pos = '1';

				var invoiceSetupData = _.join([invoiceNumberConf, invoiceRows, headerRows, printHeader, esenzione, invoicePrefix, pos], '');
				commands.push("<directIO command=\"4025\" data=\"" + invoiceSetupData + "\" comment=\"Configuring invoice parameters\">");

				var maxHeadersLines = 0;
				var headerColumns = 40;

				//Setup Headers
				_.times(13, function(i) {
					var lineNumber = (i + 1);
					var finalLine = _.chain(options['header' + (i + 1)]).trim().truncate({ length: headerColumns, omission: '' }).pad(headerColumns, ' ').value();

					commands.push(util.createXMLNode('directIO', { command: 3016, data: _.padStart(i + 1, 2, '0') + finalLine + "rr", comment: "Configuring header line " + lineNumber }).toString());

					if(!_.chain(finalLine).trim().isEmpty().value()) {
						maxHeadersLines = lineNumber;
					}
				});

				//Setup invoice footer
				_.times(2, function(i) {
					var lineNumber = _.toString(i + 1);
					var finalLine = _.chain(options['invoice_footer' + (i + 1)]).trim().truncate({ length: headerColumns, omission: '' }).pad(headerColumns, ' ').value();

					commands.push(util.createXMLNode('directIO', { command: 4027, data: lineNumber + finalLine + "rr", comment: "Configuring invoice footer line" + lineNumber }).toString());
				});

				// Setup invoice header lines number
				commands.push(util.createXMLNode('directIO', { command: 4015, data: "170" + _.padStart(maxHeadersLines, 2, '0') + "rr", comment: "Configuring max invoice header lines" }).toString());

				commands.push(util.createXMLNode('directIO', { command: 3016, data: "99aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaarr" }).toString());
				commands.push(util.createXMLNode('directIO', { command: 3016, data: "98aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaarr" }).toString());

				// Vat setup
				_.forEach(resources.vats, function (v) {
					//commands.push(">>/?V/$" + v.id + "/*" + (v.value * 100));
					if (_.inRange(v.id, 1, 5)) {
						var id = _.padStart(v.id, 2, '0');
						var value = _.padEnd(_.padStart(v.value, 2, '0'), 4, '0');

						commands.push("<directIO command=\"4005\" data=\"" + id + value + "\" comment=\"Configuring VAT id:" + id + "\">");
					}
				});

				var fallbackZeroVat = _.find(resources.vats, function(v) {
					return v.value === 0 && _.inRange(v.id, 1, 5);
				});

				// Departments setup
				_.forEach(resources.departments, function(d) {
					if (_.inRange(d.vat.id, 1, 5) || (d.vat.value === 0 && fallbackZeroVat)) {
						var vatId = _.inRange(d.vat.id, 1, 5) ? d.vat.id : fallbackZeroVat.id;
						// Default price | vat.id | name | maximum price=0 | minimum price=0 | auto_close=0 | group_code =1
						var command = '4002';
						// Department code and name
						var depId = _.padStart(d.printer_code, 2, '0');
						var depName = _.padEnd(d.name, 20, ' ');

						var p1 = "000000000";
						var p2 = "000000000";
						var p3 = "000000000";

						var data = depId + depName + p1 + p2 + p3;

						// Single Item NO
						data += "0";

						// Tax
						data += _.padStart(vatId, 2, '0');

						// Price max (no minimum limit)
						var pMax = "999999999";
						//var pMax = "000000000";
						data += pMax;

						/*
						N.REP        2 byte
						DESC        20 byte
						P1           9 byte
						P2           9 byte
						P3           9 byte
						Single item  1 byte (0)
						TAX          2 byte
						Limite       9 byte
						Gruppo stamp 2 byte
						Sup. Gruppo  2 byte
						UM fattura   2 byte
						NU           2 byte (random) */

						// Department group
						data += "00";

						// Super group
						data += "00";

						// UM fattura
						data += "  ";

						// 2 byte random
						data += "OK";

						var iocmd = "<directIO command=\"" + command + "\" data=\"" + data + "\" comment=\"Configuring Department " + d.printer_code + "\">";
						commands.push(iocmd);
					} else {
						console.log("WARNING: Backend has DEPARTMENT with associated IVA id:" + d.vat.id + ", must be maximum 4");
					}
				});

				// Printer identifier
				if (scope.printer.printer_number) {
					var printerNumber = _.padStart(scope.printer.printer_number, 3, '0');
					commands.push("<directIO command=\"4015\" data=\"11" + printerNumber + "RR\" comment=\"Configuring Printer identifier:" + printerNumber + "\">");
				}
				// Set cashdrawer off
				commands.push("<directIO command=\"4014\" data=\"26000\" comment=\"Setting cashdrawer off\" />");

				// Set Ora Legale on
				commands.push("<directIO command=\"4014\" data=\"34100\" comment=\"Setting ora legale on\" />");

				// Set display message
				if (!_.isEmpty(options.display_message)) {
					var displayMessage = _.padEnd(options.display_message.substring(0, 40), 40, ' ');
					commands.push("<directIO command=\"1062\" data=\"013" + displayMessage + "00\"/>");
					commands.push("<directIO command=\"4015\" data=\"13997RR\"/>");
					commands.push("<directIO command=\"4015\" data=\"13005RR\"/>");
				} else {
					//Erases messages and disables displaying
					commands.push("<directIO command=\"1062\" data=\"013" + _.repeat(' ', 40) + "00\"/>");
					commands.push("<directIO command=\"4015\" data=\"13000RR\"/>");
				}

				// Set line spacing
				commands.push("<directIO command=\"4015\" data=\"18001RR\"/>");

				//<directIO command="1062" data="013Testo di prova                          00"/> </printerCommand>
				//console.log(commands);
				return commands;
			});
		});
	}

	/**
	 * @description getNextInvoiceNumber
	 */
	function getNextInvoiceNumber(printer) {
		var d = $q.defer();
		var requestXml = "<printerCommand><directIO command=\"4225\"/></printerCommand>";
		var printerEndpoint = epsonUtils.getPrinterEndpoint(printer.ip_address, printer.ssl);

		epsonUtils.eposSend(printerEndpoint, requestXml).then(function(res) {
			if (res.result.status === 2) {
				// Get first 4 bytes (receipt number) and strip leading zeros
				var lastNumber = parseInt(res.addInfo.responseData.substring(0, 4).replace(/^0+/, ''));

				if (!_.isNaN(lastNumber)) {
					d.resolve(lastNumber);
				} else {
					d.reject("ERROR_NOT_A_NUMBER");
				}
			} else {
				d.reject("ERROR");
			}
		}, function(error) {
			d.reject("CONNECTION_ERROR");
		});

		return d.promise;
	}

	/**
	 *  parseReceiptDate
	 */
	function parseReceiptDate(fiscalReceiptDate, fiscalReceiptTime) {
		var datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{1,4})/;
		var timePattern = /(\d{1,2}):(\d{1,2})/;

		var rd = fiscalReceiptDate.match(datePattern);
		var day = parseInt(rd[1]);
		var month = parseInt(rd[2]) - 1;
		var year = parseInt(rd[3]);

		var rt = fiscalReceiptTime.match(timePattern);
		var hours = parseInt(rt[1]);
		var mins = parseInt(rt[2]);

		var date = new Date(year, month, day, hours, mins, 0);
		if (_.isNaN(date.getTime())) {
			return undefined;
		} else {
			return date;
		}
	}

	function parseReceiptDateFromDocument(document) {
		var datePattern = /(\d{1,2})-(\d{1,2})-(\d{1,2})  (\d{1,2}):(\d{1,2})/;

		var rd = document.match(datePattern);
		if(_.isArray(rd)) {
			var day = _.toInteger(rd[1]);
			var month = _.toInteger(rd[2]) - 1;
			var year = _.toInteger(rd[3]) + 2000;
			var hours = _.toInteger(rd[4]);
			var mins = _.toInteger(rd[5]);
			var date = new Date(year, month, day, hours, mins, 0);

			if (_.isNaN(date.getTime())) {
				return undefined;
			} else {
				return date;
			}
		} else {
			return undefined;
		}
	}

	/**
	 *  parseInvoiceDate
	 */
	function parseInvoiceDate(invoice) {

		var resDate = invoice.match(/DATA(\s+)\d{2}\-\d{2}\-\d{2}\s+ORA\s+(\d{2}):(\d{2})/g);
		var datePattern = /DATA\s+(\d{2})\-(\d{2})\-(\d{2})\s+ORA\s+(\d{2}):(\d{2})/;
		if (resDate) {
			var rd = invoice.match(datePattern);

			var day = parseInt(rd[1]);
			var month = parseInt(rd[2]) - 1;
			var year = parseInt(rd[3]) + 2000;

			var hours = parseInt(rd[4]);
			var mins = parseInt(rd[5]);

			return new Date(year, month, day, hours, mins, 0);
		}
	}

	/**
	 *  parseCreditNoteNumber
	 */
	function parseCreditNoteNumber(receipt) {
		var res = receipt.match(/NOTA DI CREDITO N.\s+\d+/g);
		if (res !== null) {
			return (parseInt(res.toString().replace("NOTA DI CREDITO N.", "").trim()));
		} else {
			return (undefined);
		}
	}

	/**
	 * @description getReceiptByNumber
	 * @param  {string} ip_address
	 * @param  {string} date in format GGMMAA
	 * @param  {integer} type: 0 All, 1 Fiscal Receipt (credit note and fiscal closing), 2 Invoice, 3 'Titoli di accesso', 4 Credit Note, 5 Fiscal Closing
	 * @param  {integer} number, document number
	 * @return {function} callback with content
	 */
	function getDocumentByDateNumber(ip_address, date, type, number, successFunction, errorFunction) {
		var requestXml = "<printerCommand><queryContentByNumbers fromNumber=\"" + number + "\" toNumber=\"" + number + "\" dataType=\"" + type + "\" fromDay=\"" + date.getDate() + "\" fromMonth=\"" + parseInt(date.getMonth() + 1) + "\" fromYear=\"" + (date.getFullYear() - 2000) + "\" toDay=\"" + date.getDate() + "\" toMonth=\"" + parseInt(date.getMonth() + 1) + "\" toYear=\"" + (date.getFullYear() - 2000) + "\" /></printerCommand>";
		async.retry({times: 10, interval: 5000, errorFilter: function(err) {
			return (err === "CONNECTION_ERROR");
		}}, function(cb) {
			sendRequest(ip_address, requestXml, function(response) {
				var xmlDoc = $.parseXML(response);
				var xml = $(xmlDoc);
				var lc = xml.find("lineCount");
				if (lc && lc[0]) {
					cb(null, lc[0].textContent.split("\n").slice(1).join("\n"));
				}
				else {
					cb("RECEIPT_NOT_FOUND");
				}
			}, cb);
		}, function(err, result) {
			if(err) {
				errorFunction(err);
			} else {
				successFunction(result);
			}
		});
	}

	/**
	 * @description getReceiptByNumber
	 * @param  {string} ip_address
	 * @param  {string} date in format GGMMAA
	 * @param  {integer} type: 0 All, 1 Fiscal Receipt (credit note and fiscal closing), 2 Invoice, 3 'Titoli di accesso', 4 Credit Note, 5 Fiscal Closing
	 * @param  {integer} number, document number
	 * @return {function} callback with content
	 */
	function getDgfeByDate(ip_address, from, to, successFunction, errorFunction) {
		var requestXml = "<printerCommand><queryContentByDate dataType=\"0\" fromDay=\"" + from.substring(0, 2) + "\" fromMonth=\"" + from.substring(2, 4) + "\" fromYear=\"" + from.substring(4, 6) + "\" toDay=\"" + to.substring(0, 2) + "\" toMonth=\"" + to.substring(2, 4) + "\" toYear=\"" + to.substring(4, 6) + "\" /></printerCommand>";

		sendRequest(ip_address, requestXml, function(response) {
			var xmlDoc = $.parseXML(response);
			var xml = $(xmlDoc);
			var lc = xml.find("lineCount");
			if (lc && lc[0]) {
				successFunction(lc[0].textContent.split("\n").slice(1).join("\n").replace(/\t{3}/g, ""));
			} else {
				errorFunction("RECEIPT_NOT_FOUND");
			}
		}, errorFunction);
	}

	/**
	 * @description saleToCourtesyReceiptXml
	 */
	function saleToCourtesyReceiptXml(sale, options) {

		var root = util.createXMLNode();
		var courtesyXml = util.createXMLNode("printerNonFiscal");

		root.appendChild(courtesyXml);

		var addLine = function (line) {
			courtesyXml.appendChild(util.createXMLNode("printNormal", {
				data: line
			}));
		};

		// Opening fiscal receipt
		courtesyXml.appendChild(util.createXMLNode("beginNonFiscal"));

		// Insert header
		addLine("SCONTRINO DI CORTESIA");
		addLine("");

		if (sale.name) {
			addLine(sale.name);
		}
		addLine("");

		// Items
		_.forEach(sale.sale_items, function (si) {
			var name = si.name || si.department_name;
			var data = si.quantity + "x " + name;

			addLine(data);
		});

		if (sale.id) {
			addLine("");
			courtesyXml.appendChild(util.createXMLNode("printBarCode", {
				position: 180,
				width: 2,
				height: 66,
				hRIPosition: "2",
				hRIFont: "B",
				codeType: "CODE39",
				code: sale.id
			}));
		}
		// Closing fiscal receipt
		courtesyXml.appendChild(util.createXMLNode("endNonFiscal"));

		return root.toString();
	}

	/**
	 * @description asyncDGFERequest
	 */
	function asyncDGFERequest(printer, from, to, successFunction, errorFunction) {
		/*
			This procedure use direct i/o command 3101 with data format:
			operator     2 byte
			gg1;mm1;aa1  6 byte
			gg2;mm2;aa2  6 byte
			type         1 byte (0 reset, 1 continue)

			DGFE data row is in the responseData node with format:
			...
			same as previous
			...
			data          6 byte (MMDDYY)
			n.scontr.     4 byte
			n.riga scon.  4 byte
			riga dgfe     40 byte

			OP GGMMAA NS   NRIGA  DATI
			ex. 00 131014 0026 0002   RIMBORSO PER RESTITUZIONE MERCE VENDUTA

			read until responseCommand is 3102
		*/

		var requestXmlStart = "<printerCommand><directIO command=\"3101\" data=\"00" + from + to + "0\"></printerCommand>";
		var requestXmlContinue = "<printerCommand><directIO command=\"3101\" data=\"00" + from + to + "1\"></printerCommand>";

		var dgfe = [];

		var requestXml = requestXmlStart;
		async.doWhilst(function(cb) {
			epsonUtils.sendCommands(printer, requestXml).then(function([res, info]) {
				var data = info.responseData.substring(16, 100);
				var response = info.responseCommand;
				dgfe.push(data);
				cb(null, response);
			}, function(error) {
				cb(error, null);
			});
		}, function(result) {
			requestXml = requestXmlContinue;
			return result === "3101";
		}, function(err, results) {
			if(err) {
				errorFunction(err);
			} else {
				successFunction(_.join(dgfe, '\n'));
			}
		});
	}

	/**
	 * @description saleToReceiptXml
	 */
	function saleToReceiptXml(sale, options) {
		var printerColumns = scope.printer.columns || 46;

		var root = util.createXMLNode();
		var saleXml = util.createXMLNode('printerFiscalReceipt');

		root.appendChild(saleXml);

		/*  printRecMessage

			messageType defines the row type to be printed
			1 = Additional header
			2 = Additional row (before MF logotype)
			3 = Additional promo (after MF logotype)
			4 = Additional description (in the body of the receipt or direct invoice).
			5 = Additional invoice header. The font is always normal so the attribute can be omitted.
			6 = Invoice client lines. The font is always normal so the attribute can be omitted
			7 = Customer Id. Sets CustomerId field in www/json_files/rec.json file (see Fiscal Printer Intelligent Features Guide for details). The font has no relevance so the attribute can be omitted. Requires firmware version >= 4.01.

			index indicates the line number:
			Range 1 - 9 for additional header (type 1).
			Range 1 - 99 for Additionalonal promo and post total descriptions (types 2 and 3).
			Range 1 - 20 (type 5)
			Range 1 - 5 (type 6)
			No meaning for additional row and Customer Id (types 4 and 7). The attribute can be omitted.

			message represents the text to be printed. The maximum lengths are as follows:
			38 – Message type 4
			46 – All other message types

		 */

		// Sale Name (additional header)
		var printName = (scope.options.print_name === false) ? false : true;

		if(printName) {
			const headerRows = fiscalUtils.getFiscalReceiptHeaderLines(sale);
	
			for(let i = 0; i < headerRows.length; i++) {
				saleXml.appendChild(util.createXMLNode("printRecMessage", {
					message: headerRows[i],
					index: i + 1,
					messageType: 1
				}));
			}
		}

		// Credit note header
		if (sale.final_amount < 0) {
			// Append 'PRATICA DI RESO' message
			saleXml.appendChild(util.createXMLNode("printRecMessage", {
				description: "PRATICA DI RESO",
				messageType: 4
			}));
		}

		// Opening fiscal receipt
		saleXml.appendChild(util.createXMLNode('beginFiscalReceipt'));

		var printDetails = (options.print_details === false) ? false : true;

		// Credit note
		if (sale.final_amount < 0) {
			printDetails = true;
		}

		// Sale items
		if (printDetails) {
			// Check Notes
			var printNotes = (scope.options.print_notes === false) ? false : true;
			var printDescription = (scope.options.print_description === true) ? true : false;

			_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
				var originalItem = si.item_id ? _.get(options, ['itemsMap', si.item_id]) || {} : {};

				epsonUtils.saleItemToCommands(saleXml, si, {
					description: printDescription ? originalItem.description : null,
					printNotes: printNotes,
					printerColumns: printerColumns
				});

				// Discount/Surcharges
				epsonUtils.applyPriceChanges(si.price_changes, saleXml, fiscalUtils.roundDecimals(si.price * si.quantity), si.department_id);
			});
		} else { // Department aggregation
			var departmentTotals = fiscalUtils.extractDepartments(sale);

			_.forEach(departmentTotals, function (depTotal) {
				saleXml.appendChild(util.createXMLNode("printRecItem", {
					description: depTotal.name,
					quantity: 1,
					unitPrice: depTotal.amount.toString().replace(".", ","),
					department: depTotal.id
				}));
			});
		}

		// Sub-total and his discount/surcharges
		saleXml.appendChild(util.createXMLNode("printRecSubtotal", {
			option: 1
		}));

		/*
			option can be
			0 = Print on the receipt and show on the display
			1 = Only print on the receipt
			2 = Only show on the display 	*/

		// Apply discount/surcharges on subtotal

		if (sale.final_amount >= 0 && printDetails) {
			epsonUtils.applyPriceChanges(sale.price_changes, saleXml, fiscalUtils.roundDecimals(sale.amount));
		}

		// Check and add payments
		if (sale.final_amount >= 0) {
			_(epsonUtils.extractPayments(sale)).forEach(function(p) {
				saleXml.appendChild(util.createXMLNode("printRecTotal", {
					description: p.method_name,
					payment: p.amount,
					index: p.index,
					paymentType: p.payment_type
				}));
			});
		} else { // if credit note
			saleXml.appendChild(util.createXMLNode("printRecTotal", {
				amount: "0"
			}));
		}

		var printCustomerDetail = (scope.options.print_customer_detail === false) ? false : true;

		// Customer
		if (printCustomerDetail && sale.sale_customer) {
			var customerIndex = 1;
			var saleCustomer = sale.sale_customer;

			var addCustomerLine = function(line) {
				saleXml.appendChild(util.createXMLNode("printRecMessage", {
					message: line,
					messageType: 2,
					index: customerIndex++
				}));
			};

			var customerInfo = fiscalUtils.getCustomerInfo(saleCustomer);

			if(!_.isEmpty(customerInfo)) {
				addCustomerLine("");
				_.forEach(customerInfo, addCustomerLine);
			}
		}

		// Sale ID Barcode
		var printBarcodeId = ((scope.options.print_barcode_id === false) ? false : true) && _.isInteger(sale.id);
		var printQRCode = !printBarcodeId && (options.qr_code_url);

		if (printBarcodeId) {
			saleXml.appendChild(util.createXMLNode('printBarCode', {
				position: 0,
				width: 2,
				height: 66,
				hRIPosition: 2,
				hRIFont: "B",
				codeType: "CODE39",
				code: sale.id.toString()
			}));
		}

		// Print Seller
		var printSeller = (scope.options.print_seller === false) ? false : true;

		if (printSeller && sale.seller_name) {
			var sellerPrefix = scope.options.seller_prefix || "Ti ha servito";

			saleXml.appendChild(util.createXMLNode("printRecMessage", {
				messageType: 4,
				message: sellerPrefix + " " + sale.seller_name.trim().split(" ")[0]
			}));
		}

		var sanitizeTailRow = function(row) {
			var sanitizedRow = row.replace(String.fromCharCode(8364), "EUR0")
				.replace("IMPORTO", "IMPORT0")
				.replace(String.fromCharCode(160), ' ')
				.replace('\u001B', '');
			return sanitizedRow;
		};

		if(scope.options.use_legacy_tail) {
			var tailRows = [];

			if (_.isString(options.tail)) {
				tailRows.push('');

				_.forEach(stringToLines(options.tail, printerColumns), function(line) {
					tailRows.push(line);
				});
			}

			if (_.isString(scope.options.tail)) {
				tailRows.push('');

				_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
					tailRows.push(line);
				});

				tailRows.push('');
			}

			if(sale.sclID) {
				tailRows.push('');
				tailRows.push('[SCL-ID: ' + sale.sclID + ']');
			}

			//var tails = tail.match(/.{1,24}/g);
			_.forEach(tailRows, function(row, idx) {
				saleXml.appendChild(util.createXMLNode('printRecMessage', {
					messageType: 3,
					message: sanitizeTailRow(row),
					index: idx + 1
				}));
			});
		} else {
			// Add Tail (tail for this sale + general tail)
			var saleTail = _.isString(options.tail) ? stringToLines(options.tail, printerColumns) : [];
			var printerTail = [];

			if (_.isString(scope.options.tail)) {
				_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
					printerTail.push(line);
				});
			}

			if(sale.sclID) {
				printerTail.push('');
				printerTail.push('[SCL-ID: ' + sale.sclID + ']');
			}

			if (printQRCode && _.isString(options.qr_code_message)) {
				printerTail.push(' ');

				_.forEach(stringToLines(options.qr_code_message, printerColumns), function(line) {
					printerTail.push(line);
				});
			}

			_.forEach(saleTail, function(row) {
				saleXml.appendChild(util.createXMLNode('printRecMessage', {
					messageType: 4,
					message: sanitizeTailRow(row),
				}));
			});

			_.forEach(printerTail, function(row, idx) {
				saleXml.appendChild(util.createXMLNode('printRecMessage', {
					messageType: 3,
					message: sanitizeTailRow(row),
					index: idx + 1
				}));
			});
		}

		if(printQRCode) {
			saleXml.appendChild(util.createXMLNode('printBarCode', {
				qRCodeAlignment: 1,
				qRCodeSize: 4,
				codeType: "QRCODE2",
				code: options.qr_code_url
			}));
		}

		// Open cash drawer
		var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

		if (openCashdrawer) {
			saleXml.appendChild(util.createXMLNode("openDrawer"));
		}

		var amountMessage = 'EURO ' + fiscalUtils.decimalToString(sale.final_amount);
		var displayMessage = _.padEnd('TOTALE', 20 - amountMessage.length, ' ');

		saleXml.appendChild(util.createXMLNode("displayText", {
			data: displayMessage + amountMessage
		}));

		saleXml.appendChild(util.createXMLNode("endFiscalReceipt"));

		return root.toString();
	}

	/**
	 * @description saleToNonFiscalXml
	 */
	function saleToNonFiscalXml(sale, options) {
		var root = util.createXMLNode();
		var nfXml = util.createXMLNode("printerNonFiscal");
		const savePaper = !!checkManager.getPreference('cashregister.save_paper_on_prebill');

		root.appendChild(nfXml);

		var addLine = function (line) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: line
			}));
		};

		// Opening fiscal receipt
		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		// Sale Name (additional header)
		var printName = (savePaper || scope.options.print_name === false) ? false : true;
		var printerColumns = scope.printer.columns || 46;

		if(printName) {
			for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
				addLine(row);
			}
		}

		var printDetails = (options.print_details === false) ? false : true;

		// Sale items
		if (printDetails) {
			_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
				var rowDescription = si.quantity + "x " + (si.name || si.department_name);
				var rowPrice = fiscalUtils.decimalToString(si.price * si.quantity);
				var row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowPrice.length, " ") + rowPrice;

				addLine(row);

				// Notes
				var printNotes = (scope.options.print_notes === false) ? false : true;

				if (printNotes && si.notes) {
					var notesLines = si.notes.split("\n");
					_.forEach(notesLines, function (nl) {
						if (nl.trim()) {
							addLine(nl.trim());
						}
					});
				}

				// Discount/Surcharges
				var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);

				_(si.price_changes).sortBy('index').forEach(function(pc) {
					var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
					partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

					if(!_.isNil(pcAmount)) {
						var rowDescription = pc.description;
						var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
						var row = _.padEnd(_.truncate(rowDescription, { length: (46 - rowPrice.length - 2) }), 46 - rowAmount.length, " ") + rowAmount;

						addLine(row);
					}
				});

				if(si.type === 'gift') {
					addLine(giftString);
				}
			});
		}

		// Sub-total and his discount/surcharges
		var subTotDescr = "SUBTOTALE";
		var subTotAmount = fiscalUtils.decimalToString(sale.amount);

		nfXml.appendChild(util.createXMLNode("printNormal", {
			data: _.padEnd(subTotDescr, printerColumns - subTotAmount.length, " ") + subTotAmount,
			font: 2
		}));

		// Apply discount/surcharges on subtotal
		if (sale.final_amount >= 0 && printDetails) {
			var partialPrice = fiscalUtils.roundDecimals(sale.amount);

			_(sale.price_changes).sortBy('index').forEach(function(pc) {
				var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
				partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

				if(!_.isNil(pcAmount)) {
					var rowDescription = pc.description;
					var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
					var row = _.padEnd(rowDescription, 46 - rowAmount.length, " ") + rowAmount;

					addLine(row);
				}
			});
		}

		var totDescription = 'TOTALE EURO';
		var totAmount = fiscalUtils.decimalToString(sale.final_amount);
		var totRow = _.padEnd(totDescription, printerColumns - totAmount.length, " ") + totAmount;

		nfXml.appendChild(util.createXMLNode("printNormal", {
			data: totRow,
			font: 3
		}));

		/*
			No data about:
			- payments
			- customerDetail
		*/

		// Add Tail (tail for this sale + general tail)
		var tailRows = [];

		if (_.isString(options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});
		}

		if (_.isString(scope.options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});

			tailRows.push('');
		}

		_.forEach(tailRows, function (row) {
			addLine(row);
		});

		if(!savePaper) {
			addLine("");
			addLine(_.pad("RITIRARE LO SCONTRINO", printerColumns, ' '));
			addLine(_.pad("FISCALE ALLA CASSA", printerColumns, ' '));
			addLine("");
		}

		if(scope.options.print_sale_qr_code) {
			nfXml.appendChild(util.createXMLNode('printBarCode', {
				qRCodeAlignment: 1,
				qRCodeSize: 8,
				codeType: "QRCODE2",
				code: 's_uid=' + sale.uuid
			}));
		}

		// Open cash drawer
		var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

		if (openCashdrawer) {
			nfXml.appendChild(util.createXMLNode("openDrawer"));
		}

		var amountMessage = 'EURO ' + fiscalUtils.decimalToString(sale.final_amount);
		var displayMessage = 'TOTALE';

		nfXml.appendChild(util.createXMLNode("displayText", {
			data: _.padEnd(displayMessage, 20 - amountMessage.length, " ") + amountMessage
		}));

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));
		return root.toString();
	}

	function getTicketChangeXml(sale) {
		var ticketChange = (scope.options.ticket_change === false) ? false : true;

		if (ticketChange && sale.change > 0 && sale.change_type === 'ticket') {
			var root = util.createXMLNode();
			var nfXml = util.createXMLNode("printerNonFiscal");

			root.appendChild(nfXml);

			var addLine = function (line) {
				nfXml.appendChild(util.createXMLNode("printNormal", {
					data: line
				}));
			};

			nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

			var ticketChangeAmount = util.round(sale.change);

			var ticketMessage = ["",
				"################ RESTO TICKET #################",
				"",
				"QUESTO COUPON VALE:               " + fiscalUtils.decimalToString(ticketChangeAmount) + " EURO",
				"",
				"Presenta alla cassa prima del pagamento questo",
				"coupon per avere riconosciuto il credito.    ",
				"Valido 30 giorni dalla data di emissione.    ",
				"Non convertibile in denaro.                  ",
				"",
				"###############################################"
			];

			_.forEach(ticketMessage, function (tm, idx) {
				addLine(tm);
			});

			nfXml.appendChild(util.createXMLNode("printBarCode", {
				width: 2,
				height: 66,
				hRIPosition: 2,
				hRIFont: "B",
				codeType: "EAN13",
				position: 180,
				code: (1212000000000 + _.round(ticketChangeAmount * 100))
			}));

			nfXml.appendChild(util.createXMLNode("endNonFiscal"));
			return root.toString();
		} else {
			return null;
		}
	}

	function getQRCodeXml(url, message) {
		var printerColumns = scope.printer.columns || 46;

		var root = util.createXMLNode();
		var nfXml = util.createXMLNode("printerNonFiscal");

		root.appendChild(nfXml);

		var addLine = function (line) {
			nfXml.appendChild(util.createXMLNode("printNormal", {
				data: line
			}));
		};

		nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

		if(_.isString(message)) {
			_.forEach(stringToLines(message, printerColumns), function(line) {
				addLine(line);
			});
		}

		nfXml.appendChild(util.createXMLNode('printBarCode', {
			qRCodeAlignment: 1,
			qRCodeSize: 4,
			codeType: "QRCODE2",
			code: url
		}));

		nfXml.appendChild(util.createXMLNode("endNonFiscal"));
		return root.toString();
	}

	/**
	 * @description saleToInvoiceXml
	 */
	function saleToInvoiceXml(sale, options, callback) {
		getNextInvoiceNumber(scope.printer).then(function(invoiceNumber) {
			var printerColumns = scope.printer.columns || 46;

			var root = util.createXMLNode();
			var invoiceXml = util.createXMLNode("printerFiscalDocument");

			root.appendChild(invoiceXml);

			/*  printRecMessage

			messageType defines the row type to be printed
			1 = Additional header
			2 = Additional row (before MF logotype)
			3 = Additional promo (after MF logotype)
			4 = Additional description (in the body of the receipt or direct invoice).
			5 = Additional invoice header. The font is always normal so the attribute can be omitted.
			6 = Invoice client lines. The font is always normal so the attribute can be omitted
			7 = Customer Id. Sets CustomerId field in www/json_files/rec.json file (see Fiscal Printer Intelligent Features Guide for details). The font has no relevance so the attribute can be omitted. Requires firmware version >= 4.01.

			index indicates the line number:
			Range 1 - 9 for additional header (type 1).
			Range 1 - 99 for Additionalonal promo and post total descriptions (types 2 and 3).
			Range 1 - 20 (type 5)
			Range 1 - 5 (type 6)
			No meaning for additional row and Customer Id (types 4 and 7). The attribute can be omitted.

			message represents the text to be printed. The maximum lengths are as follows:
			38 – Message type 4
			46 – All other message types
			*/
			// Sale Name (additional header)
			var printName = (scope.options.print_name === false) ? false : true;

			if(printName) {
				const headerRows = fiscalUtils.getFiscalReceiptHeaderLines(sale);
	
				for(let i = 0; i < headerRows.length; i++) {
					invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
						message: headerRows[i],
						index: i + 1,
						messageType: 5
					}));
				}
			}

			// Invoice lines for index
			var il = 1;
			// Customer info in fiscal receipt
			if (sale.sale_customer && ((sale.sale_customer.first_name && sale.sale_customer.last_name) || (sale.sale_customer.company_name))) {
				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: util.getCustomerCaption(sale.saleCustomer),
					messageType: 6,
					index: il++
				}));

				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: sale.sale_customer.billing_street + " " + sale.sale_customer.billing_number,
					messageType: 6,
					index: il++
				}));

				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: sale.sale_customer.billing_zip + " " + sale.sale_customer.billing_city + " " + sale.sale_customer.billing_prov,
					messageType: 6,
					index: il++
				}));

				// CF + PIVA
				var fiscalCodes = ((sale.sale_customer.vat_code ? ("P.IVA " + sale.sale_customer.vat_code + " ") : "")) + ((sale.sale_customer.tax_code ? ("C.F. " + sale.sale_customer.tax_code) : ""));

				invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
					message: fiscalCodes,
					messageType: 6,
					index: il++
				}));

				if (sale.sale_customer.fidelity) {
					invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
						message: "FIDELITY " + sale.sale_customer.fidelity,
						messageType: 6,
						index: il++
					}));
				}
			}

			// <beginFiscalDocument operator="1" documentType="directInvoice" documentNumber="0" />
			// Opening direct invoice
			invoiceXml.appendChild(util.createXMLNode("beginFiscalDocument", {
				documentType: "directInvoice",
				documentNumber: invoiceNumber
			}));

			var printDetails = (options.print_details === false) ? false : true;

			// Sale items
			if (printDetails) {
				var printNotes = (scope.options.print_notes === false) ? false : true;

				_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
					epsonUtils.saleItemToCommands(invoiceXml, si, {
						printNotes: printNotes,
						printerColumns: printerColumns
					});

					// Discount/Surcharges
					epsonUtils.applyPriceChanges(si.price_changes, invoiceXml, fiscalUtils.roundDecimals(si.price * si.quantity), si.department_id);
				});
			} else { // Department aggregation
				var departmentTotals = fiscalUtils.extractDepartments(sale);

				_.forEach(departmentTotals, function (depTotal) {
					invoiceXml.appendChild(util.createXMLNode("printRecItem", {
						description: depTotal.name,
						quantity: 1,
						unitPrice: depTotal.amount.toString().replace(".", ","),
						department: depTotal.id
					}));
				});
			}

			// Sub-total
			invoiceXml.appendChild(util.createXMLNode("printRecSubtotal", {
				option: 1
			}));

			/*
				option can be
				0 = Print on the receipt and show on the display
				1 = Only print on the receipt
				2 = Only show on the display 	*/

			// Apply discount/surcharges on subtotal
			if (sale.final_amount >= 0 && printDetails) {
				epsonUtils.applyPriceChanges(sale.price_changes, invoiceXml, fiscalUtils.roundDecimals(sale.amount));
			}

			// Check and add payments
			var payments = epsonUtils.extractPayments(sale);

			_.forEach(payments, function(p) {
				invoiceXml.appendChild(util.createXMLNode("printRecTotal", {
					description: p.method_name,
					payment: p.amount,
					index: p.index,
					paymentType: p.payment_type
				}));
			});

			var bottomLines = 0;

			if(scope.options.allows_invoice_tail) { //if Printer firmware allows invoice tail text, print payments and other details there
				_.forEachRight(payments, function (p) {
					var row = p.method_name;
					row += _.padStart(fiscalUtils.roundDecimalsToString(p.amount), printerColumns - row.length, ' ');

					invoiceXml.appendChild(util.createXMLNode("printRecMessage", {
						message: row,
						index: ++bottomLines,
						messageType: 2
					}));
				});
			}

			// Open cash drawer
			var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

			if (openCashdrawer) {
				invoiceXml.appendChild(util.createXMLNode("openDrawer"));
			}

			var amountMessage = 'EURO ' + fiscalUtils.decimalToString(sale.final_amount);
			var displayMessage = _.padEnd('TOTALE', 20 - amountMessage.length, ' ');

			invoiceXml.appendChild(util.createXMLNode("displayText", {
				data: displayMessage + amountMessage
			}));

			invoiceXml.appendChild(util.createXMLNode("endFiscalReceipt"));

			callback(root.toString());
		}, function(error) {
			console.log(error);
		});
	}

	/**
	 * saleToSummaryInvoiceXml
	 */
	function saleToSummaryInvoiceXml(sale, invoiceNumber, options, callback) {
		var lines = [];
		var printerColumns = 46;

		function border(car) {
			if (!car) {
				car = "=";
			}

			return _.repeat(car, printerColumns);
		}

		// Shop Headers
		var max = 46;
		for (var o in scope.options) {
			var matches = o.match(/header\d+$/);
			if (matches) {
				var lineHeader = scope.options[o].substring(0, max);
				if(!_.isEmpty(lineHeader)){
					var finalLine = _.pad(lineHeader, max, ' ');
					lines.push(finalLine);
				}
			}
		}
		lines.push("");

		// Add customer
		var customer = sale.sale_customer;

		lines.push(util.getCustomerCaption(customer) || '');
		lines.push((customer.billing_street || "") + " " + (customer.billing_number || ""));
		lines.push((customer.billing_zip || "") + " " + (customer.billing_city || ""));

		if (customer.tax_code) {
			lines.push("C.F. " + customer.tax_code);
		} else {
			lines.push("");
		}

		if (customer.vat_code) {
			lines.push("P.IVA " + customer.vat_code);
		} else {
			lines.push("");
		}

		// Write Invoice number
		lines.push("FATTURA N. " + (!_.isEmpty(scope.printer.invoice_prefix) ? scope.printer.invoice_prefix : "") + invoiceNumber);
		lines.push(dateToString());
		lines.push("");

		lines.push("");
		lines.push("Fattura Riepilogativa con Corrisp.Non Riscosso");

		lines.push("");

		// 48 car

		lines.push("Q.TA' / Descrizione              IVA   Importo");
		//lines.push("");
		//lines.push(border('-'));

		var printDetails = true;
		if (options.print_details === false) {
			printDetails = false;
		}

		if (printDetails) {
			var groupedSaleItems = _.groupBy(sale.sale_items, function(si) {
				if (si.reference_sequential_number && si.reference_date) {
					return "Rif.Scontrino " + si.reference_sequential_number + " del " + moment(si.reference_date).format('DD-MM-YYYY');
				} else {
					return null;
				}
			});

			var firstGroup = true;

			var lastGroup;
			if (groupedSaleItems['null']) {
				lastGroup = _.cloneDeep(groupedSaleItems['null']);
			}
			if (lastGroup) {
				delete groupedSaleItems['null'];
				groupedSaleItems['null'] = lastGroup;
			}

			_.each(groupedSaleItems, function(group, ref) {
				if (ref !== 'null') {
					if (firstGroup) {
						firstGroup = false;
					} else {
						//lines.push("");
					}
					/*lines.push(border('-'));
					lines.push(ref);
					lines.push(border('-'));*/

					var total = _.reduce(group, function(total, si) {
						return si.final_price * si.quantity + total;
					}, 0);

					var data = ref;
					data += _.padStart(fiscalUtils.decimalToString(total), printerColumns - ref.length, ' ');
					//console.log(total)
					//commands.push("=\"/(" + ref + ")");
					lines.push(data);
					//commands.push("=\"/(" + border("-") + ")");
				} else {
					if (!firstGroup) {
						lines.push("");
					}
					lines.push(border('-'));
				}

				_.each(group, function(si) {
					if (ref === 'null') {
						// Quantity, name/department, vat, rowPrice
						var data = si.quantity + "x " + (si.name || si.department_name);
						data = _.padEnd(data.substring(0, printerColumns - 15), printerColumns - 15, ' ');
						var rowPrice = fiscalUtils.roundDecimals(si.price * si.quantity);
						data += _.padStart(_.toString(si.vat_perc), 5, ' ');
						data += _.padStart(fiscalUtils.decimalToString(rowPrice), 10, ' ');

						lines.push(data);

						// Notes
						if (si.notes) {
							var notesLines = si.notes.split("\n");
							_.each(notesLines, function(nl) {
								if (nl.trim()) {
									lines.push("# " + nl.trim());
								}
							});
						}

						// Price Changes
						_(si.price_changes).sortBy('index').forEach(function(pc) {
							var pcString = '';
							var pcValue = fiscalUtils.decimalToString(pc.value);

							if (_.includes(['discount_fix', 'discount_perc'], pc.type)) {
								pcString = pc.description || 'SCONTO';
							} else if (_.includes(['surcharge_fix', 'surcharge_perc'], pc.type)) {
								pcString = pc.description || 'MAGGIORAZIONE';
							} else {
								return;
							}

							pcString += _.repeat(' ', printerColumns - 2 - pcString.length - pcValue.length);

							if (pc.type.indexOf("_perc") === -1) {
								pcString += "  ";
							}
							pcString += pcValue;
							if (pc.type.indexOf("_perc") > -1) {
								pcString += " %";
							}

							lines.push(pcString);
						});
						
						if(si.type === 'gift') {
							lines.push(giftString);
						}
					}
				});
			});
		}
		// Hide details
		else {
			var departmentTotals = fiscalUtils.extractDepartments(sale);
			lines.push(border('-'));
			_.forEach(departmentTotals, function(depTot) {
				// Quantity, name/department, vat, rowPrice
				var data = depTot.name;
				data = data.substring(0, 33);
				var rowPrice = fiscalUtils.roundDecimalsToString(depTot.amount);
				data += _.padStart(rowPrice, printerColumns - data.length, ' ');
				lines.push(data);
			});
		}

		if (printDetails) {
			lines.push(border('-'));
			var subTot = 'SUB-TOTALE';
			var subTotalAmount = fiscalUtils.decimalToString(sale.amount);

			subTot += _.padStart(subTotalAmount, printerColumns - subTot.length, ' ');
			lines.push(subTot);

			// Price Changes on subtotal
			_(sale.price_changes).sortBy('index').forEach(function(pc) {
				var pcString = '';
				var pcValue = fiscalUtils.decimalToString(pc.value);

				if (_.includes(['discount_fix', 'discount_perc'], pc.type)) {
					pcString = pc.description || 'SCONTO';
				} else if (_.includes(['surcharge_fix', 'surcharge_perc'], pc.type)) {
					pcString = pc.description || 'MAGGIORAZIONE';
				} else {
					return;
				}

				pcString += _.repeat(' ', printerColumns - 2 - pcString.length - pcValue.length);

				if (pc.type.indexOf("_perc") === -1) {
					pcString += "  ";
				}
				pcString += pcValue;
				if (pc.type.indexOf("_perc") > -1) {
					pcString += " %";
				}

				lines.push(pcString);
			});
		}

		lines.push(border('-'));
		var tot = 'TOTALE EURO NON RISCOSSO';
		var totAmount = fiscalUtils.decimalToString(sale.amount);

		tot += _.padStart(totAmount, printerColumns - tot.length, ' ');
		lines.push(tot);

		// Add IVA ESC.
		var net = fiscalUtils.decimalToString(sale.final_net_amount);
		var netString = "T0TALE IVA ESC. EURO";

		netString += _.padStart(net, printerColumns - netString.length, ' ');
		lines.push(netString);

		var aggregateTaxes = fiscalUtils.extractTax(sale);
		lines.push("");

		// Add Taxable
		var data = "";
		_.forEach(aggregateTaxes, function(aggrTax, taxVal) {
			if (aggrTax.taxable > 0) {
				data = "IMPONIBILE IVA " + _.toInteger(taxVal) + "%";
				data += _.padStart(fiscalUtils.decimalToString(aggrTax.taxable), printerColumns - data.length, ' ');
				lines.push(data);
			}
		});

		// Add IVA Aggregates
		_.forEach(aggregateTaxes, function(aggrTax, taxVal) {
			if (aggrTax.taxable > 0) {
				data = "IVA " + _.toInteger(taxVal) + "%";
				data += _.padStart(fiscalUtils.decimalToString(aggrTax.tax), printerColumns - data.length, ' ');
				lines.push(data);
			}
		});

		// Generate XML
		if (!_.isArray(lines)) {
			if (_.isString(lines)) {
				lines = lines.split("\n");
			}
		}

		console.log(lines);

		var root = util.createXMLNode();
		var nfXml = util.createXMLNode('printerFiscalDocument');

		root.appendChild(nfXml);

		// Opening fiscal receipt
		nfXml.appendChild(util.createXMLNode('beginFiscalDocument', {
			documentType: 'freeInvoice',
			documentNumber: _.toString(invoiceNumber)
		}));

		_.forEach(lines, function(l) {
			if (l.indexOf("TOTALE EURO NON RISCOSSO") > -1) {
				nfXml.appendChild(util.createXMLNode('directIO', {
					command: "1096",
					data: '010000'
				}));
			}

			var fontSize;
			if (l.indexOf("Fattura Riepilogativa con Corrisp.Non Riscosso") > -1) {
				fontSize = 4;
			} else if ((l.indexOf("TOTALE EURO NON RISCOSSO") > -1) || (l.indexOf("T0TALE IVA ESC. EURO") > -1)) {
				fontSize = 3;
			}

			nfXml.appendChild(util.createXMLNode('printFiscalDocumentLine', {
				documentLine: l,
				font: fontSize
			}));
		});

		nfXml.appendChild(util.createXMLNode('endFiscalDocument'));
		callback(root.toString());
	}

	/**
	 * @description saleToShippingInvoiceXml
	 */
	function saleToShippingInvoiceXml(sale, invoiceNumber, options, callback) {
		var lines = [];
		var printerColumns = 46;

		function addBorder(pattern) {
			return _.repeat(pattern, _.round(printerColumns / pattern.length));
		}

		// SHOP HEADERS
		var max = 46;
		for (var o in scope.options) {
			var matches = o.match(/header\d+$/);
			if (matches) {
				var lineHeader = scope.options[o].substring(0, max);
				if(!_.isEmpty(lineHeader)){
					var finalLine = _.pad(lineHeader, max, ' ');
					lines.push(finalLine);
				}
			}
		}
		lines.push("");

		// CUSTOMER DATA
		var customer = sale.sale_customer;
		var name = util.getCustomerCaption(customer);

		lines.push("Indirizzo fatturazione:");
		lines.push(name);
		lines.push((customer.billing_street || "") + " " + (customer.billing_number || ""));
		lines.push((customer.billing_zip || "") + " " + (customer.billing_city || ""));

		if (customer.tax_code) {
			lines.push("C.F. " + customer.tax_code);
		} else {
			lines.push("");
		}

		if (customer.vat_code) {
			lines.push("P.IVA " + customer.vat_code);
		} else {
			lines.push("");
		}

		lines.push("Indirizzo spedizione:");
		lines.push(name);
		lines.push((customer.shipping_street || "") + " " + (customer.shipping_number || ""));
		lines.push((customer.shipping_zip || "") + " " + (customer.shipping_city || ""));

		// Write Invoice number
		lines.push("FATTURA ACCOMPAGNATORIA N. " + (!_.isEmpty(scope.printer.invoice_prefix) ? scope.printer.invoice_prefix : "") + invoiceNumber);

		var receiptDate = new Date();
		var receiptDateStr = "DATA    " + _.padStart(receiptDate.getDate(), 2, '0') + "-" + _.padStart(receiptDate.getMonth() + 1, 2, '0') + "-" + (receiptDate.getFullYear() - 2000);
		var receiptTimeStr = "ORA    " + _.padStart(receiptDate.getHours(), 2, '0') + ":" + _.padStart(receiptDate.getMinutes(), 2, '0');

		lines.push(receiptDateStr + _.padStart(receiptTimeStr, printerColumns - receiptDateStr.length, ' '));
		lines.push("");

		//SALE ITEMS
		const saleItems = fiscalUtils.extractSaleItems(sale);

		lines.push(_.padStart("EURO  ", printerColumns, ' '));

		_.forEach(saleItems, function(saleItem) {
			// Quantity, name/department, vat, rowPrice
			var originalItem = saleItem.item_id ? _.get(options, ['itemsMap', saleItem.item_id]) || {} : {};

			lines.push(_.repeat(' ', 4) + _.truncate(saleItem.name, { 'length': printerColumns - 4 }));

			if(originalItem.code !== "nota") {
				var priceRow = _.repeat(' ', 4) + saleItem.quantity + _.toString(originalItem.unit) + _.pad('x', 9, ' ') + (saleItem.type === 'gift' ? giftString : fiscalUtils.decimalToString(saleItem.price) + _.repeat(' ', 4) + "IVA " + saleItem.vat_perc + "%");
				if(saleItem.type !== 'gift') {
					priceRow += _.padStart(fiscalUtils.decimalToString(saleItem.price * saleItem.quantity), printerColumns - priceRow.length, ' ');
				}

				lines.push(priceRow);
			}

			if(originalItem.code && originalItem.code !== "nota" && saleItem.price) {
				lines.push("        Cod.Art. " + originalItem.code);
			}

			if(originalItem.description) {
				_.forEach(_.split(originalItem.description, '\n'), function(row) {
					var descLines = row.match(new RegExp('.{1,' + (printerColumns - 8) + '}', 'g'));
					_.forEach(descLines, function(dLine) {
						lines.push("        " + dLine);
					});
				});
			}

			if (originalItem.code !== "nota") {
				var partialPrice = fiscalUtils.roundDecimals(saleItem.price * saleItem.quantity);

				_(saleItem.price_changes).sortBy('index').forEach(function(pc) {
					var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
					partialPrice = fiscalUtils.roundDecimals(pcAmount);

					if(!_.isNil(pcAmount)) {
						partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);
						var discountRow = _.repeat(' ', 4) + (pc.description || _.startsWith(pc.type, 'discount') ? "Sconto" : "Maggioraz.");

						var discountAmount = fiscalUtils.roundDecimalsToString(pcAmount);
						var pcValue = pc.type.indexOf("_fix") !== -1 ? fiscalUtils.decimalToString(pc.value) : pc.value;

						if(pc.type !== 'discount_perc' || pc.value !== 100) {
							discountRow += " " + pcValue + (_.endsWith(pc.type, "_perc") ? '%' : '');
						}

						if(pc.description !== "Scont. merce art. 15 del DPR 633/72") {
							discountRow += _.padStart(discountAmount, printerColumns - discountRow.length, ' ');
						}

						lines.push(discountRow);
					}
				});
			}

			// Notes
			if (saleItem.notes) {
				_.forEach(_.split(saleItem.notes, '\n'), function(row) {
					var notesLines = row.match(new RegExp('.{1,' + (printerColumns - 9) + '}', 'g'));
					_.forEach(notesLines, function(nLine) {
						lines.push("        #" + nLine);
					});
				});
			}
		});

		// Price Changes on subtotal
		if(!_.isEmpty(sale.price_changes)) {
			lines.push("");

			_(sale.price_changes).sortBy('index').forEach(function(pc) {
				var pcString = '        ';
				var pcValue = fiscalUtils.decimalToString(pc.value);

				if (_.includes(['discount_fix', 'discount_perc'], pc.type)) {
					pcString += pc.description || 'Sconto';
				} else if (_.includes(['surcharge_fix', 'surcharge_perc'], pc.type)) {
					pcString += pc.description || 'Maggioraz.';
				} else {
					return;
				}

				pcString += _.repeat(' ', printerColumns - 2 - pcString.length - pcValue.length);

				if (pc.type.indexOf("_perc") === -1) {
					pcString += "  ";
				}
				pcString += pcValue;
				if (pc.type.indexOf("_perc") > -1) {
					pcString += " %";
				}

				lines.push(pcString);
			});
		}

		lines.push("");

		//OTHER DATA
		if(options.shippingMeta) {
			lines.push(addBorder(' -'));

			var itemsCount = _.get(options, ['shippingMeta', 'package_count']) || 0;
			var totalWeight = 0;

			var causeStr = _.truncate(_.padEnd("Causale: " + options.shippingMeta.cause || '', 24, ' '), { length: 24 });
			var paymentStr = _.truncate(_.padStart("Pagamento: " + options.shippingMeta.payment || '' , 21, ' '), { length: 21});
			lines.push( causeStr + "-" + paymentStr);
			var transportStr = _.truncate(_.padEnd("Trasporto: " + options.shippingMeta.transport || '', 24, ' '), { length: 24 });
			var appearanceStr = _.truncate(_.padStart("Aspetto: " + options.shippingMeta.appearance || '' , 21, ' '), { length: 21 });
			lines.push(transportStr + "-" + appearanceStr);

			for(let si of saleItems) {
				var originalItem = si.item_id ? _.get(options, ['itemsMap', si.item_id]) || {} : {};

				if(si.item_id) {
					totalWeight += originalItem.weight * si.quantity || 0;
				}
			}

			_.assign(options.shippingMeta, {
				total_weight: totalWeight,
				package_count: itemsCount
			});

			var totalWeightStr = totalWeight >= 1000 ? fiscalUtils.decimalToString(totalWeight / 1000) + "Kg" : _.toInteger(totalWeight) + "g";
			lines.push(_.padEnd("N.Colli: " + itemsCount, 24, ' ') + "-" + _.padStart("Peso: " + totalWeightStr , 21, ' '));

			if(options.shippingMeta.transport_date) {
				lines.push(_.truncate("Data/ora trasporto: " + dateToString(options.shippingMeta.transport_date), { 'length': 46 }));
			}

			if(options.shippingMeta.port) {
				lines.push(_.truncate("Porto: " + options.shippingMeta.port, { 'length': 46 }));
			}
			if(options.shippingMeta.carrier) {
				lines.push(_.truncate("Vettore: " + options.shippingMeta.carrier, { 'length': 46 }));
			}
		}

		var aggregateTaxes = fiscalUtils.extractTax(sale);

		// Add Taxable
		lines.push(addBorder(' -'));
		lines.push("ALIQ.          IMPONIBILE                  IVA");

		var taxTotal = 0;
		var taxableTotal = 0;

		_.forEach(aggregateTaxes, function(aggrTax, taxVal) {
			if (aggrTax.taxable > 0) {
				var taxLine = "";
				taxLine = _.get(options, ['shippingVats', _.toInteger(taxVal), 'code']) + " " + taxVal.replace(".", ",") + "%";
				taxLine += _.padStart(fiscalUtils.decimalToString(aggrTax.taxable), 25 - taxLine.length, ' ');
				taxLine += _.padStart(fiscalUtils.decimalToString(aggrTax.tax), printerColumns - taxLine.length, ' ') + "  ";
				lines.push(taxLine);
				taxTotal += aggrTax.tax;
				taxableTotal += aggrTax.taxable;
			}
		});

		lines.push(addBorder(' -'));
		var taxTotalStr = "TOT";
		taxTotalStr += _.padStart(fiscalUtils.decimalToString(taxableTotal), 25 - taxTotalStr.length, ' ');
		taxTotalStr += _.padStart(fiscalUtils.decimalToString(taxTotal), printerColumns - taxTotalStr.length, ' ') + "  ";
		lines.push(taxTotalStr);

		lines.push("");
		var tot = 'TOTALE EURO';
		var totAmount = fiscalUtils.decimalToString(sale.final_amount);
		tot += _.padStart(totAmount, printerColumns - tot.length, ' ');
		lines.push(tot);

		var payments = epsonUtils.extractPayments(sale);

		_.forEachRight(payments, function (p) {
			var row = p.method_name;
			row += _.padStart(fiscalUtils.roundDecimalsToString(p.amount), printerColumns - row.length, ' ');
			lines.push(row);
		});

		lines.push("");
		lines.push("Firma del vettore");
		lines.push("");
		lines.push(addBorder('. '));
		lines.push("");
		lines.push("Firma del destinatario");
		lines.push("");
		lines.push(addBorder('. '));
		lines.push("");

		if(!_.isEmpty(_.get(options, ['shippingMeta', 'notes']))) {
			_.forEach(stringToLines(options.shippingMeta.notes, printerColumns), function(line) {
				lines.push(line);
			});

			lines.push("");
		}

		// Generate XML
		if (!_.isArray(lines)) {
			if (_.isString(lines)) {
				lines = lines.split("\n");
			}
		}

		var root = util.createXMLNode();
		var nfXml = util.createXMLNode('printerFiscalDocument');

		root.appendChild(nfXml);

		lines.push("");
		if(_.isString(options.shippingTail)) {
			_.forEach(_.split(options.shippingTail, '\n'), function(row) {
				var tailLines = row.match(new RegExp('.{1,' + (printerColumns) + '}', 'g'));
				_.forEach(tailLines, function(tLine) {
					lines.push(tLine);
				});
			});
		}

		// Opening fiscal receipt
		nfXml.appendChild(util.createXMLNode('beginFiscalDocument', {
			documentType: 'freeInvoice',
			documentNumber: _.toString(invoiceNumber),
			documentAmount: sale.final_amount
		}));

		_.forEach(lines, function(l) {
			if (l.indexOf("TOTALE EURO") > -1) {
				nfXml.appendChild(util.createXMLNode('directIO', {
					command: "1096",
					data: '010000'
				}));
			} else {
				nfXml.appendChild(util.createXMLNode('printFiscalDocumentLine', {
					documentLine: l
				}));
			}
		});

		nfXml.appendChild(util.createXMLNode('endFiscalDocument'));
		callback(root.toString());
	}

	/**
	 * internalPrintFreeNonFiscal
	 */
	function internalPrintFreeNonFiscal(lines, successFunction, errorFunction) {
		var freeXml = linesToCommands(lines);
		isAvailable(scope.printer, function() {
			epsonUtils.sendCommands(scope.printer, freeXml).then(successFunction, errorFunction);
		}, errorFunction);
	}

	/**
	 *  isAvailable
	 *  @param printer
	 *  @param command
	 *  @return INACTIVE | PRINT_IN_PROGRESS | PAYMENT_IN_PROGRESS | OPEN_DOCUMENT | ACTIVE_FIDELITY
	 */
	function isAvailable(printer, successFunction, errorFunction) {
		/*
			Epson Serial Format (substrings)
			(2, 8): number
			(8, 10): model
			(10, 12): manufacturer (it should always be '99' for EPSON)
		*/

		var printerEndpoint = epsonUtils.getPrinterEndpoint(printer.ip_address, printer.ssl);
		var command = epsonUtils.addSoapEnvelope('<printerCommand><queryPrinterStatus/></printerCommand>');
		var rtCommand = epsonUtils.addSoapEnvelope('<printerCommand><queryPrinterStatus  statusType=\"1\"/></printerCommand>');
		var serialCommand = "<printerCommand><directIO command=\"3217\" data=\"01\"/></printerCommand>";
		var answer = {};

		fiscalUtils.sendRequest(printerEndpoint, { data: command, method: 'POST', timeout: 3000, headers: { 'Content-Type': 'application/xml' } }).then(function(response) {
			response = epsonUtils.parseEpsonXml(response);

			if(_.isObject(_.get(response, 'addInfo'))) {
				_.assign(answer, response.addInfo);
			}

			if(response.error) {
				errorFunction(response.error);
			} else {
				epsonUtils.sendCommands(printer, serialCommand).then(function([res, add_info]) {
					var serialString = add_info.responseData;

					_.assign(answer, {
						printer_serial: serialString.substring(8, 10) + " " + serialString.substring(10, 12) + serialString.substring(2, 8)
					});

					if(_.toNumber(_.get(response, ["addInfo", "cpuRel"])) >= 5) {
						//The printer has an RT firmware, check if is in MF mode or RT mode
						fiscalUtils.sendRequest(printerEndpoint, { data: rtCommand, method: 'POST', timeout: 3000, headers: { 'Content-Type': 'application/xml' } }).then(function(responseRT) {
							responseRT = epsonUtils.parseEpsonXml(responseRT);

							if(_.get(responseRT, ["addInfo", "rtMainStatus"]) === "02") {
								errorFunction("RT_MODE_ENABLED");
							} else {
								successFunction(answer);
							}
						}, function(error) {
							errorFunction("CONNECTION_ERROR");
						});
					} else {
						successFunction(answer);
					}
				}, errorFunction);
			}
		}, function(error) {
			errorFunction("CONNECTION_ERROR");
		});
	}

	// Send WS request
	function sendRequest(ip_address, command, successFunction, errorFunction) {
		var printerEndpoint = epsonUtils.getPrinterEndpoint(ip_address, scope.printer.ssl);
		command = epsonUtils.addSoapEnvelope(command);

		fiscalUtils.sendRequest(printerEndpoint, { data: command, method: 'POST', headers: { 'Content-Type': 'application/xml' } }).then(function(response) {
			successFunction(response.data.replace(/&luot;/g, "&quot;"));
		}, function(error) {
			errorFunction("CONNECTION_ERROR");
		});
	}

	/**
	 * parseUnclaimed
	 */
	function parseUnclaimed(receipt) {
		var resCn = receipt.match(/CORRISPETTIVI\s{1}NON\s{1}RISCOSSI\s+(\s*?([\d\.]+(\,\d{1,2})?|\,\d{1,2}))/g);
		return parseFloat(resCn[0].replace("CORRISPETTIVI NON RISCOSSI", "").replace(".", "").replace(",", ".").trim());
	}

	/**
	 * readVatStats
	 */
	function readDailyVAT(printer, successFunction, errorFunction) {
		// Read VAT totals
		var command = function(vatCode) {
			return "<printerCommand><directIO command=\"2050\" data=\"200" + vatCode + "\"/></printerCommand>";
		};

		// Read VAT value
		var commandValue = function(vatCode) {
			return "<printerCommand><directIO command=\"4205\" data=\"0" + vatCode + "\"/></printerCommand>";
		};

		var vatIDs = _.range(1, 5); //[1..4]
		var vatStat = {};
		var total = 0;

		async.waterfall([
			function(callback) {
				async.eachSeries(vatIDs, function(vatCode, cb) {
					epsonUtils.sendCommands(printer, commandValue(vatCode)).then(function([res, info]) {
						var value = Math.round(parseInt(info.responseData.substring(2, 6))) / 100;

						_.set(vatStat, ['vat_value_' + vatCode], value);

						cb(null);
					}, function(error) {
						cb(error);
					});
				}, function(err) {
					callback(err);
				});
			}, function(callback) {
				async.eachSeries(vatIDs, function(vatCode, cb) {
					epsonUtils.sendCommands(printer, command(vatCode)).then(function([res, info]) {
						var taxable = _.round(_.toInteger(info.responseData.substring(5, 14))) / 100;
						var tax = _.round(_.toInteger(info.responseData.substring(15, 25))) / 100;

						_.set(vatStat, ['vat_tot_' + vatCode], fiscalUtils.roundDecimals(taxable + tax));
						_.set(vatStat, ['vat_taxable_' + vatCode], taxable);
						_.set(vatStat, ['vat_amount_' + vatCode], tax);
						total += _.get(vatStat, ['vat_tot_' + vatCode]);

						cb(null);
					}, function(error) {
						cb(error);
					});
				}, function(err) {
					callback(err);
				});
			}
		], function(err) {
			if(err) {
				errorFunction(err);
			} else {
				vatStat.total = fiscalUtils.roundDecimals(total);
				successFunction(vatStat);
			}
		});
	}

	/**
	 * @desc print a free invoice using the xml commands provided by invoiceFunction
	 *
	 * @param sale - the tilby sale you want to print
	 *
	 * @return successFunction with printed sale or errorFunction with errors
	 */
	function printFreeInvoice(sale, invoiceFunction, options, successFunction, errorFunction) {
		isAvailable(scope.printer, function(printerInfo) {
			getNextInvoiceNumber(scope.printer).then(function(invoiceNumber) {
				invoiceFunction(sale, invoiceNumber, options, function(invoiceXml) {
					console.log(invoiceXml);
					epsonUtils.sendCommands(scope.printer, invoiceXml).then(function() {
						configureInvoiceNumber(invoiceNumber + 1, function() {
							getDocumentByDateNumber(scope.printer.ip_address, new Date(), 2, invoiceNumber, function(invoice) {
								successFunction([{
									sequential_number: invoiceNumber,
									date: new Date(),
									printer_serial: printerInfo.printer_serial,
									document_content: invoice
								}]);

								// Print payment receipt
								if (options.tail) {
									var tailCopies = options.tail.indexOf('FIRMA') === -1 ? 1 : 2;

									async.timesSeries(tailCopies, function(n, next) {
										internalPrintFreeNonFiscal(options.tail, function() {
											console.log("Payment receipt successfully printed");
											next(null);
										}, function(error) {
											console.log("Error printing Payment receipt: " + error);
											next(error);
										});
									});
								}
							}, function(error) {
								//errorFunction(error);
								console.log("[error] ERROR retrieving fiscal receipt from DGFE!!!");
								successFunction([]);
							});
						}, errorFunction);
					}, errorFunction);
				});
			});
		}, errorFunction);
	}

	/**
	 * Public methods
	 */
	return {
		/**
		 * @desc setup Epson FP90-III or Epson FP81-II printer Intelligent Printer
		 *
		 * @param {boolean} options.print_notes: (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
		 * @param {boolean} options.print_name: (default true), if true prints the sale.name on top
		 * @param {boolean} options.print_barcode_id: (default true), if true, prints a barcode on bottom that contains the sale.id
		 * @param {boolean} options.ticket_change: (default true), if true enables ticket change
		 * @param {boolean} options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
		 * @param {boolean} options.print_seller: boolean (default true), if true print seller
		 * @param {string} options.seller_prefix: customize seller phrase
		 * @param {string} options.tail: general tail for the receipts
		 *
		 * @param {object} printer, the tilby fiscal printer (must be an Epson Intelligent which supports web services) *
		 * @param {integer} printer.id: the printer id
		 * @param {string} printer.name: the printer name
		 * @param {string} printer.driver: must be 'epson in this case'
		 * @param {string} printer.connection_type: not used (the driver will use only web service system)
		 * @param {string} printer.ip_address: string, printer IP
		 * @param {string} priner.mac_address_bt: not used
		 */
		setup: function(printer, options) {
			if (!_.isObject(printer)) {
				throw "Wrong or missing printer data";
			} else if (printer.driver !== 'epson') {
				throw "Wrong driver";
			} else if (!printer.ip_address) {
				throw "Missing ip_address";
			}

			_.assign(scope, {
				printer: printer,
				options: options
			});
		},

		getPrinterStatus: function() {
			var d = $q.defer();
			var periodicCheckDate = "<printerCommand><directIO command=\"4228\" data=\"00\"/></printerCommand>";
			var answer = {};

			isAvailable(scope.printer, function(printerInfo) {
				_.assign(answer, printerInfo);

				epsonUtils.sendCommands(scope.printer, periodicCheckDate).then(function([res_pc, add_info_pc]) {
					answer.periodicCheck = add_info_pc.responseData;
					d.resolve(answer);
				}, function(err) {
					d.resolve(answer);
				});
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc print a fiscal receipt (also known as 'scontrino fiscale', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 * @param options.can_open_cash_drawer: (default true) - true if user has permission, false otherwise
		 * @param options.tail: string, print this string at the end of receipt
		 * @param options.print_details: if false, print only department totals and hide single items and discount details
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printFiscalReceipt: function(sale, options, successFunction, errorFunction) {
			var printAdditionalDocuments = function() {
				async.waterfall([function(callback) { // Print additional payment receipt for credits cards
					if (options.tail && options.tail.indexOf('FIRMA') > -1) {
						internalPrintFreeNonFiscal(options.tail, function() {
							console.log("Payment receipt successfully printed");
							callback();
						}, function(error) {
							console.log("Error printing Payment receipt: " + error);
							callback();
						});
					} else {
						callback();
					}
				}, function(callback) { //Print ticket change
					var ticketChangeXml = getTicketChangeXml(sale);

					if(ticketChangeXml) {
						epsonUtils.sendCommands(scope.printer, ticketChangeXml).then(function() {
							callback();
						}, function() {
							callback();
						});
					} else {
						callback();
					}
				}, function(callback) {
					var printBarcodeId = ((scope.options.print_barcode_id === false) ? false : true) && _.isInteger(sale.id);
					var printQRCode = printBarcodeId && (options.qr_code_url);

					if(printQRCode) {
						var qrCodeXml = getQRCodeXml(options.qr_code_url, options.qr_code_message);

						epsonUtils.sendCommands(scope.printer, qrCodeXml).then(function() {
							callback();
						}, function() {
							callback();
						});
					} else {
						callback();
					}
				}, function(callback) {
					if(_.isArray(options.otherDocuments) && !_.isEmpty(options.otherDocuments)) {
						async.eachSeries(options.otherDocuments, function(document, cb) {
							internalPrintFreeNonFiscal(document, function() {
								cb();
							}, function(error) {
								cb();
							});
						}, function(err) {
							callback();
						});
					} else {
						callback();
					}
				}]);
			};

			isAvailable(scope.printer, function(printerInfo) {
				var returnDocument = function(document, responseData) {
					var documentType = 'fiscal_receipt';
					var documentNumber = parseInt(responseData.fiscalReceiptNumber);
					if (_.isNaN(documentNumber)) {
						documentNumber = null;
					}

					var printDate;

					if(responseData.fiscalReceiptDate && responseData.fiscalReceiptTime) {
						printDate = parseReceiptDate(responseData.fiscalReceiptDate, responseData.fiscalReceiptTime);
					} else {
						printDate = parseReceiptDateFromDocument(document);
					}

					if (sale.final_amount < 0) {
						documentType = 'credit_note';
						documentNumber = parseCreditNoteNumber(document);
					}

					var documentsToReturn = [{
						document_type: documentType,
						document_content: document
					}];

					if(_.isArray(options.otherDocuments)) {
						_.forEach(options.otherDocuments, function(printerDoc) {
							documentsToReturn.push({
								document_type: 'receipt',
								document_content: _.isArray(printerDoc) ? printerDoc.join('\n') : printerDoc
							});
						});
					}

					_.forEach(documentsToReturn, function(printerDoc) {
						_.assign(printerDoc, {
							sequential_number: documentNumber,
							date: (printDate ? printDate.toISOString() : null),
							printer_serial: printerInfo.printer_serial
						});
					});

					successFunction(documentsToReturn);
				};

				var version = _.toNumber(_.get(printerInfo, 'cpuRel'));

				if (version < 4.012) {
					//Versions >= 4.012 allow printRecMessage type 4 with more than 100 lines, if the firmware is an earlier version use type 3
					scope.options.use_legacy_tail = true;
				}
				var receiptProgressiveCmd = "<printerCommand><directIO command=\"1070\" data=\"00\"/></printerCommand>";
				epsonUtils.sendCommands(scope.printer, receiptProgressiveCmd).then(function([res, add_info]) {
					var currentReceipt = _.toInteger(add_info.responseData.substring(2, 6));
					var clonedSale = _.cloneDeep(sale);
					clonedSale.sclID = getSclID();

					var saleXml = saleToReceiptXml(clonedSale, options);
					epsonUtils.sendCommands(scope.printer, saleXml).then(function([message, response]) {
						printAdditionalDocuments();
						getDocumentByDateNumber(scope.printer.ip_address, new Date(), 1, response.fiscalReceiptNumber, function(receipt) {
							returnDocument(receipt, response);
						}, function(error) {
							//errorFunction(error);
							console.log("[error] ERROR retrieving fiscal receipt from DGFE!!!");
							successFunction([]);
						});
					}, function(error) {
						if(error === "CONNECTION_ERROR") {
							async.retry({times: 10, interval: 5000, errorFilter: function(err) {
								return (err === "CONNECTION_ERROR");
							}}, function(cb) {
								epsonUtils.sendCommands(scope.printer, receiptProgressiveCmd).then(function([res, add_info]) {
									var newReceipt = _.toInteger(add_info.responseData.substring(2, 6));
									cb(null, newReceipt);
								}, cb);
							}, function(err, result) {
								if(err) {
									errorFunction('SALE_PRINT_ERROR');
								} else {
									if(result > currentReceipt) {
										var documentsToScan = _.range(currentReceipt, result);
										var now = new Date();
										var foundDoc;
										var searchPattern = new RegExp(_.escapeRegExp('[SCL-ID: ' + clonedSale.sclID + ']'));
										async.detect(documentsToScan, function(progr, cb) {
											getDocumentByDateNumber(scope.printer.ip_address, now, 1, progr, function(document) {
												if(document.search(searchPattern)) {
													foundDoc = document;
													cb(null, true);
												} else {
													cb(null, false);
												}
											}, function(err) {
												cb(null, false);
											});
										}, function(err, result) {
											if(result) {
												printAdditionalDocuments();
												returnDocument(foundDoc, {
													fiscalReceiptNumber: result
												});
											} else {
												errorFunction(error);
											}
										});
									} else {
										errorFunction(error);
									}
								}
							});
						} else {
							errorFunction(error);
						}
					});
				}, errorFunction);
			}, errorFunction);
		},

		/**
		 * @desc print an Invoice (also known as 'Fattura', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 * @param options.can_open_cash_drawer - true if user has permission, false otherwise
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printInvoice: function(sale, options, successFunction, errorFunction) {
			isAvailable(scope.printer, function(printerInfo) {
				var version = _.toNumber(_.get(printerInfo, 'cpuRel'));

				if (version >= 4.018) {
					//Versions >= 4.018 allow printRecMessage type 2 on invoices
					scope.options.allows_invoice_tail = true;
				}

				saleToInvoiceXml(sale, options, function(invoiceXml) {
					epsonUtils.sendCommands(scope.printer, invoiceXml).then(function() {
						getNextInvoiceNumber(scope.printer).then(function(invoiceNumber) {
							getDocumentByDateNumber(scope.printer.ip_address, new Date(), 2, (invoiceNumber - 1), function(invoice) {
								successFunction([{
									sequential_number: (invoiceNumber - 1),
									date: (parseInvoiceDate(invoice) ? parseInvoiceDate(invoice).toISOString() : null),
									document_type: 'invoice',
									printer_serial: printerInfo.printer_serial,
									document_content: invoice
								}]);

								// Print payment receipt
								if (options.tail) {
									var tailCopies = options.tail.indexOf('FIRMA') === -1 ? 1 : 2;

									async.timesSeries(tailCopies, function(n, next) {
										internalPrintFreeNonFiscal(options.tail, function() {
											console.log("Payment receipt successfully printed");
											next(null);
										}, function(error) {
											console.log("Error printing Payment receipt: " + error);
											next(error);
										});
									});
								}
							}, function(error) {
								//errorFunction(error);
								console.log("[error] ERROR retrieving fiscal receipt from DGFE!!!");
								successFunction([]);
							});
						}, errorFunction);
					}, errorFunction);
				});
			}, errorFunction);
		},

		/**
		 * @desc print a summary invoice
		 *
		 * @param sale - the tilby sale you want to print
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printSummaryInvoice: function(sale, options, successFunction, errorFunction) {
			return printFreeInvoice(sale, saleToSummaryInvoiceXml, options, function(saleDocuments) {
				_.forEach(saleDocuments, function(document) {
					_.assign(document, {
						document_type: 'summary_invoice'
					});
				});

				successFunction(saleDocuments);
			}, errorFunction);
		},

		/*
		 * @desc print a summary invoice
		 *
		 * @param sale - the tilby sale you want to print
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printShippingInvoice: function(sale, options, successFunction, errorFunction) {
			return printFreeInvoice(sale, saleToShippingInvoiceXml, options, function(saleDocuments) {
				_.forEach(saleDocuments, function(document) {
					_.assign(document, {
						document_type: 'shipping_invoice',
						meta: options.shippingMeta
					});
				});

				successFunction(saleDocuments);
			}, errorFunction);
		},

		/**
		 * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printCourtesyReceipt: function(sale, options, successFunction, errorFunction) {
			var courtesyXml = saleToCourtesyReceiptXml(sale, options);
			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, courtesyXml).then(function([message, response]) {
					successFunction(message);
				}, errorFunction);
			}, errorFunction);
		},

		/**
		 * @desc print a non fiscal receipt (also known as 'preconto', for italians)
		 *
		 * @param sale - the tilby sale you want to print
		 *
		 * @return successFunction with printed sale or errorFunction with errors
		 */
		printNonFiscal: function(sale, options, successFunction, errorFunction) {
			var nonFiscalXml = saleToNonFiscalXml(sale, options);

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, nonFiscalXml).then(successFunction, errorFunction);
			}, errorFunction);
		},

		/**
		 * @desc open cash drawer
		 *
		 * @return successFunction if drawer is correctly opened or errorFunction with errors
		 */
		openCashDrawer: function(successFunction, errorFunction) {
			var openCashDrawerXml = "<printerCommand><openDrawer/></printerCommand>";
			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, openCashDrawerXml).then(successFunction, errorFunction);
			}, errorFunction);
		},

		/**
		 * @desc do a daily closing
		 *
		 * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
		 */
		dailyClosing: function(successFunction, errorFunction) {

			var dailyReadXml = "<printerFiscalReport><printXReport/></printerFiscalReport>";

			// We use direct/io to do fiscal closing in order to get receipt number and date in response
			var dailyClosingXml = "<printerCommand><directIO command=\"3001\" data=\"00\"/></printerCommand>";
			isAvailable(scope.printer, function(printerInfo) {
				readDailyVAT(scope.printer, function(vatStats) {
					epsonUtils.sendCommands(scope.printer, dailyReadXml).then(function() {
						epsonUtils.sendCommands(scope.printer, dailyClosingXml).then(function([message, response]) {
							var docNumber = parseInt(response.responseData.substring(12, 16));
							var day = parseInt(response.responseData.substring(2, 4));
							var month = parseInt(response.responseData.substring(4, 6)) - 1;
							var year = parseInt(response.responseData.substring(6, 8)) + 2000;
							var hours = parseInt(response.responseData.substring(8, 10));
							var mins = parseInt(response.responseData.substring(10, 12));

							var printDate = new Date(year, month, day, hours, mins, 0);

							getDocumentByDateNumber(scope.printer.ip_address, new Date(), 5, docNumber, function(closure) {

								var dailyClosing = {
									sequential_number: docNumber,
									date: printDate.toISOString(),
									printer_serial: printerInfo.printer_serial,
									document_content: closure
								};

								dailyClosing = _.merge(dailyClosing, vatStats);
								dailyClosing.unclaimed = parseUnclaimed(closure) || 0;
								successFunction(dailyClosing);
							}, function(error) {
								//errorFunction(error);
								// TO FIX!!! WS ERROR VERSION
								console.log("[error] ERROR fiscal closing from DGFE!!!");
								successFunction();
							});
						}, errorFunction);
					}, errorFunction);
				}, errorFunction);
			}, errorFunction);
		},


		/**
		 * @desc do a daily read
		 *
		 * @return successFunction if read is correctly done or errorFunction with errors
		 */
		dailyRead: function() {
			var d = $q.defer();
			var dailyReadXml = "<printerFiscalReport><printXReport/></printerFiscalReport>";

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, dailyReadXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc do a cash deposit
		 * @param {number} cash to deposit (euro)
		 * @return promise
		 */
		deposit: function(cash) {
			var d = $q.defer();
			var cashAmount = _.round(cash * 100).toString();
			var cashXml = _.padStart(cashAmount, 9, '0');

			// operator 0
			cashXml = "00" + cashXml;
			var depositXml = "<printerCommand><directIO command=\"1031\" data=\"" + cashXml + "\"/></printerCommand>";

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, depositXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc do a cash withdrawal
		 * @param {number} cash to withdrawal (euro)
		 * @return promise
		 */
		withdrawal: function(cash) {
			var d = $q.defer();
			var cashAmount = _.round(cash * 100).toString();
			var cashXml = _.padStart(cashAmount, 9, '0');

			// operator 0
			cashXml = "00" + cashXml;
			var withdrawalXml = "<printerCommand><directIO command=\"1032\" data=\"" + cashXml + "\"/></printerCommand>";

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, withdrawalXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc readDgfe
		 *
		 * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		readDgfeBetween: function(mode, from, to) {
			var d = $q.defer();

			switch(mode) {
				case 'print':
					var printDGFEXml = "<printerCommand><printContentByDate operator=\"0\" dataType=\"0\" fromDay=\"" + from.substring(0, 2) + "\" fromMonth=\"" + from.substring(2, 4) + "\" fromYear=\"" + from.substring(4, 6) + "\" toDay=\"" + to.substring(0, 2) + "\" toMonth=\"" + to.substring(2, 4) + "\" toYear=\"" + to.substring(4, 6) + "\" /></printerCommand>";
					// Bug on response
					isAvailable(scope.printer, function() {
						epsonUtils.sendCommands(scope.printer, printDGFEXml).then(d.resolve, d.reject);
					}, d.reject);
				break;
				case 'read':
					isAvailable(scope.printer, function(printerInfo) {
						var version = _.toNumber(_.get(printerInfo, 'cpuRel'));

						if (version < 4.016) {
							asyncDGFERequest(scope.printer, from, to, d.resolve, d.reject);
						} else {
							// From version 4.016
							getDgfeByDate(scope.printer.ip_address, from, to, d.resolve, d.reject);
						}
					}, d.reject);
				break;
				default:
					d.reject("MODE_ERROR");
				break;
			}

			return d.promise;
		},

		/**
		 * @desc printFiscalMemory
		 *
		 * @return promise
		 */
		printFiscalMemory: function() {
			var d = $q.defer();
			var fiscalMemoryXml = "<printerCommand><directIO command=\"3015\" data=\"00\"/></printerCommand>";

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, fiscalMemoryXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},

		/**
		 * @desc printFiscalMemoryBeetween
		 *
		 * @param corrispettivi: boolean, if true prints 'corrispettivi'
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		printFiscalMemoryBetween: function(corrispettivi, from, to) {
			var d = $q.defer();
			var fiscalMemoryXml = "<printerCommand><directIO command=\"3013\" data=\"00" + from + to + "\"/></printerCommand>";

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, fiscalMemoryXml).then(d.resolve, d.reject);
			}, d.reject);

			return d.promise;
		},
		/**
		 *  @desc automagically configure RCH Print!F
		 *
		 *  ( /printers )
		 *  @param printer, the printer you want to configure
		 *  @param printer.invoice_prefix (from printer)
		 *  @param printer.printer_number (from printer)
		 *
		 *  ( /shop_preferences )
		 *
		 *  @param options.header1 (max 40 chars - receipt + invoice)
		 *  @param options.header2 (max 40 chars - receipt + invoice))
		 *  @param options.header3 (max 40 chars - receipt + invoice))
		 *  @param options.header4 (max 40 chars - receipt + invoice))
		 *  @param options.header5 (max 40 chars - receipt + invoice))
		 *  @param options.header6 (max 40 chars - receipt + invoice))
		 *
		 *  @param options.header7 (max 40 chars - invoice only)
		 *  @param options.header8 (max 40 chars - invoice only)
		 *  @param options.header9 (max 40 chars - invoice only)
		 *  @param options.header10 (max 40 chars - invoice only)
		 *  @param options.header11 (max 40 chars - invoice only)
		 *  @param options.header12 (max 40 chars - invoice only)
		 *  @param options.header13 (max 40 chars - invoice only)
		 *
		 *  @param options.invoice_footer1 (max 40 chars)
		 *  @param options.invoice_footer2 (max 40 chars)
		 *  @param options.invoice_footer3 (not available in epson printers)
		 *  @param options.invoice_footer4 (not available in epson printers)
		 *  @param options.invoice_footer5 (not available in epson printers)
		 *  @param options.invoice_footer6 (not available in epson printers)
		 *
		 *  @param options.display_message (not available)
		 *
		 */
		autoConfigurePrinter: function(printer, options) {
			var d = $q.defer();

			isAvailable(printer, function() {
				configurationToCommands(options).then(function(commands) {
					var xmlCommands = [];

					_.forEach(commands, function(cmd) {
						console.log(cmd);
						xmlCommands.push("<printerCommand>" + cmd + "</printerCommand>");
					});

					var iocmd = function(command, callback) {
						console.log("Executing command:" + command);
						epsonUtils.sendCommands(printer, command).then(function([message]) {
							//console.log(command);
							callback(null, message);
						}, function(error) {
							//console.log(error);
							if (error === 'EPSON.ERROR_17') {
								callback("FISCAL_CLOSING_NEEDED");
							} else {
								callback(error);
							}
						});
					};

					async.eachSeries(xmlCommands, iocmd, function(err) {
						if(!err) {
							console.log("Configuration done!");
							d.resolve("OK");
						} else {
							d.reject(err);
						}
					});
				});
			}, d.reject);

			return d.promise;
		},

		/**
		 * @description set a logo for the printer
		 * @param {base64} image
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		setLogo: (logoData, printer, locations) => new Promise((successFunction, errorFunction) => {
			isAvailable(printer, function() {
				var i;
				var logoNode;
				var root = util.createXMLNode();
				var nfXml = util.createXMLNode("printerNonFiscal");

				root.appendChild(nfXml);

				nfXml.appendChild(util.createXMLNode("beginNonFiscal"));
				if(!_.isNil(logoData)) {
					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "0"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "1"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "2"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "3"
					}));

					nfXml.appendChild(util.createXMLNode("setLogo", {
						index: "0",
						option: "2",
						location: "4"
					}));

					logoNode = util.createXMLNode("setLogo", {
						location: locations[0],
						option: "0",
						index: "1",
						graphicFormat: "R"
					}, logoData);

					nfXml.appendChild(logoNode);

					for(i = 1; i < locations.length; i++) {
						nfXml.appendChild(util.createXMLNode("setLogo", {
							index: "1",
							option: "2",
							location: locations[i]
						}));
					}
				} else {
					for(i = 0; i < locations.length; i++) {
						logoNode = util.createXMLNode("setLogo", {
							index: "0",
							option: "0",
							location: locations[i],
						});
						nfXml.appendChild(logoNode);
					}
				}

				nfXml.appendChild(util.createXMLNode("endNonFiscal"));
				var freeXml =  root.toString();
				epsonUtils.sendCommands(printer, freeXml).then(successFunction, errorFunction);
			}, errorFunction);
		}),

		/**
		 * @description print a logo for the printer
		 * @param {base64} image
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		printLogo: (base64Data, printer) => new Promise((successFunction, errorFunction) => {
			isAvailable(printer, function() {
				var root = util.createXMLNode();
				var nfXml = util.createXMLNode("printerNonFiscal");

				root.appendChild(nfXml);

				nfXml.appendChild(util.createXMLNode("beginNonFiscal"));

				if(!_.isNil(base64Data)) {
					var logoNode = util.createXMLNode("printGraphicCoupon", {
						operator: "1",
						graphicFormat: "R"
					}, base64Data);

					nfXml.appendChild(logoNode);
				}

				nfXml.appendChild(util.createXMLNode("endNonFiscal"));
				var freeXml =  root.toString();

				epsonUtils.sendCommands(printer, freeXml).then(successFunction, errorFunction);
			}, errorFunction);
		}),

		/**
		 * @description print a free non fiscal receipt (such as a receipt copy)
		 * @param {array of strings} lines, lines you want to print
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		printFreeNonFiscal: (lines, options) => new Promise((resolve, reject) => {
			let freeXml = linesToCommands(lines);

			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, freeXml).then(resolve, reject);
			}, reject);
		}),

		/**
		 * @description printOrder
		 * @param  {object} order you want to fail-over print
		 * @return successFunction("OK") or errorFunction(errors)
		 */
		printOrder: function(order, successFunction, errorFunction) {
			var freeXml = linesToCommands(orderToLines(order));
			isAvailable(scope.printer, function() {
				epsonUtils.sendCommands(scope.printer, freeXml).then(successFunction, errorFunction);
			}, errorFunction);
		},

		/**
		 *  isReachable
		 */
		isReachable: function(successFunction, errorFunction) {
			return isAvailable(scope.printer, successFunction, errorFunction);
		}
	};
}]);