import {Component} from '@angular/core';

@Component({
    selector: 'app-skeleton-card',
    templateUrl: './skeleton-card.component.html',
    styleUrls: ['./skeleton-card.component.scss'],
    host: {class:'tw-flex-1 tw-bg-gray-200 tw-p-4 tw-block'}
})
export class SkeletonCardComponent {

}
