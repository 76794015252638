import * as angular from 'angular';
import * as moment from 'moment-timezone';

angular.module('application').filter('sclDate', function() {
    return function(date, format, timezone) {
        if(date) {
            var dateObj = moment(date);

            if(timezone) {
                dateObj.tz(timezone);
            }

            return dateObj.format(format || 'l LT');
        } else {
            return null;
        }
    };
});
