<ng-container
    *ngIf="{
            showToolbarContext: toolbarEventsContextService.showToolbarContext$|async,
            componentPortal: rightSidenavService.componentPortal$|async,
            centralToolbarComponent: centralToolbarComponent$|async,
            isLoading: skeletonLoaderService.isLoading | async,
            backBtn: toolbarEventsContextService.backButton$|async,
            label: toolbarEventsContextService.label,
            showButtonsBar: toolbarEventsService.showButtonsBar$|async
            } as data"
>
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false" [ngClass]="{'*:tw-pointer-events-none mat-pointer-events-none': lockUIActions()}">

        <mat-sidenav #sidenavFixed class="sidenav tw-w-min frame-sidenav"
                     *ngIf="!isMobile && !isSidenavOpen"
                     fixedInViewport
                     role="navigation"
                     [mode]="'side'"
                     [@.disabled]="true"
        >
            <div class="tw-flex tw-flex-col tw-h-full">
                <mat-toolbar-row>
                    <button
                        [color]="'primary'"
                        type="button"
                        aria-label="Toggle sidenav"
                        mat-icon-button
                        class="frame-button"
                        (click)="toggle()"
                    >
                        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                    </button>
                </mat-toolbar-row>
                <!--                [userMenu]="userMenu"-->
                <app-fixed-nav-list
                    *ngIf="currentRoute"
                    class="tw-flex tw-flex-col tw-h-full"
                    [currentRoute]="($any(currentRoute)?.self?.url) || $any(currentRoute)?.url || currentRoute"
                    (goTo)="goTo($event)"
                    (menuClickOpenSettings)="toggleSubMenuSettings()"
                    (automaticPrintOpenSettings)="toggleAutomaticPrintSettings()"
                ></app-fixed-nav-list>
            </div>
        </mat-sidenav>
        <mat-sidenav
            #sidenavDetail
            class="tw-w-full sm:tw-w-[26rem] tw-rounded-tl-3xl"
            *ngIf="data.componentPortal"
            [opened]="rightSidenavService.isOpen"
            (closed)="rightSidenavService.isOpen=false"
            [mode]="'over'"
            position="end">
            <ng-template [cdkPortalOutlet]="$any(data.componentPortal)"></ng-template>
        </mat-sidenav>
        <mat-sidenav #sidenavMenu
                     id="sidenavMenu"
                     class="tw-rounded-tr-3xl tw-w-[20rem]"
                     role="navigation"
                     [mode]="'over'"
                     [autoFocus]="false"
                     *ngIf="isSidenavOpen">
            <app-sidenav-menu
                #appSidenavMenu
                (closeMenuSidenav)="closeMenuSidenav()"
                (goTo)="goTo($event)"/>
        </mat-sidenav>

        <mat-sidenav-content (click)="closeMenuSidenav()" class="sidenav-content tw-overflow-hidden" [class.tw-ml-[80px]]="!isMobile">
            <mat-toolbar class="frame-toolbar tw-px-0 sm:tw-px-4" color="primary" [attr.border-color]="'primary'" [class.tw-pointer-events-none]="isSidenavOpen">
                <app-generalized-toolbar id="generalized-toolbar">
                    <app-left-toolbar start [menuIcon]="isMobile" (menuClickChange)="toggle()" id="left-toolbar"></app-left-toolbar>
                    <div [cdkPortalOutlet]="data.centralToolbarComponent" center class="tw-contents"></div>
                    <app-buttons-bar *ngIf="data.showButtonsBar" end class="tw-flex tw-justify-end" id="buttons-bar"></app-buttons-bar>
                </app-generalized-toolbar>
            </mat-toolbar>
            <!--        <router-outlet></router-outlet>-->
            <ng-container *ngTemplateOutlet="data.isLoading?skeletonLoader:page"></ng-container>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <ng-template #skeletonLoader>
<!--        <app-skeleton-page></app-skeleton-page>-->
        <app-skeleton-dashboard></app-skeleton-dashboard>
    </ng-template>
    <ng-template #page>
        <div id="contentContainer" class="tw-relative tw-h-[calc(100%-56px)] sm:tw-h-[calc(100%-4rem)]" [ngClass]="{'tw-flex':data.showToolbarContext}"  [class.tw-pointer-events-none]="isSidenavOpen">
            <app-left-context-toolbar *ngIf="data.showToolbarContext" [label]="data.label|translate" [btn]="data.backBtn||undefined" class="sm:tw-pr-10 tw-h-12"></app-left-context-toolbar>
            <div appUpgradeUiView class="tw-flex-[2]" [ngClass]="{'absolute-container':data.showToolbarContext && !isContextTabs}"></div>
            <app-buttons-bar *ngIf="data.showToolbarContext" [isContextToolbar]="true"
                             class="tw-flex tw-flex-1 tw-justify-end tw-h-12 sm:tw-pr-4 tw-pr-0"></app-buttons-bar>
        </div>
    </ng-template>
</ng-container>
