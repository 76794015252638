import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataConfirmDialog} from "../dialog.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-email-export-dialog',
    templateUrl: './email-export-dialog.component.html',
    styleUrls: ['./email-export-dialog.component.scss'],
    providers: [OnDestroyService],
    host:{class:'tw-p-0'}
})
export class EmailExportDialogComponent {
    private readonly onDestroyService= inject(OnDestroyService);
    public readonly data: DataConfirmDialog= inject(MAT_DIALOG_DATA);
    private readonly dialogRef= inject(MatDialogRef<MagicPortalDialogComponent<EmailExportDialogComponent>>);


    emailForm: FormGroup<{email:FormControl<string|null>}>;
    validators = {
        emailMaxLength:255
    }
    constructor() {
        this.emailForm = new FormGroup<{email:FormControl<string|null>}>({
            email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(this.validators.emailMaxLength)])
        });

        MagicPortalDialogComponent.setStatusValidity(this.emailForm.status);
        this.emailForm.statusChanges.pipe(this.onDestroyService.takeUntilDestroy)
            .subscribe(status=>MagicPortalDialogComponent.setStatusValidity(status));

        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy)
            .subscribe(res=>!!res&&this.submit());
    }

    get email(){
        return this.emailForm.controls.email;
    }

    private submit(){
        this.dialogRef.close(this.emailForm.value);
    }

}
