    <div class="tw-flex tw-items-start tw-justify-between">
        <div class="historymodule-items-title-header tw-flex tw-flex-col tw-leading-tight" title="ID: {{sale?.id}}">
            <div class="tw-flex tw-items-start tw-justify-between tw-h-10 tw-leading-10">
                <span class="historymodule-name">{{sale?.name}}</span>
                <button mat-icon-button (click)="openSaleJsonDetail()">
                    <mat-icon>data_object</mat-icon>
                </button>
            </div>
            <div class="historymodule-info">{{sale?.closed_at | tilbyDate:'dd/MM/yyyy HH:mm'}}</div>
        </div>
        <button mat-icon-button (click)="closeSidenav()">
            <mat-icon alt="">close</mat-icon>
        </button>
    </div>
    <div class="tw-flex tw-items-start tw-justify-between">
        <div *ngIf="sale?.sale_customer || sale?.customer_tax_code"
             class="history-module-header-customer-name">{{getCustomerCaption()}}</div>
        <div class="tw-flex"></div>
        <div class="historymodule-header-total">tot: {{ sale?.final_amount || 0 | tilbyCurrency }}</div>
<!--        <div class="historymodule-header-total">tot: {{ sale.final_amount || '0' | sclCurrency: sale.$currency : sale.$digits }}</div>-->
    </div>
