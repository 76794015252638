import { Component, Inject, Input, OnInit } from "@angular/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { $state } from "app/ajs-upgraded-providers";
import { Sales } from "tilby-models";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import { DeliverySale } from "../../model/delivery.model";
import {MatTableModule} from "@angular/material/table";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {MatIconModule} from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ConfigurationManagerService, EntityManagerService } from "src/app/core";

@Component({
    selector: 'table-delivery',
    templateUrl: './table-delivery.component.html',
    styleUrls: ['./table-delivery.component.scss'],
    imports: [
        CommonModule,
        MatTableModule,
        TilbyCurrencyPipe,
        MatIconModule,
        TranslateModule,
        MatButtonModule
    ],
    standalone: true
})
export class TableDeliveryComponent implements OnInit {

    @Input() sales: Sales[] = [];

    channels : string[] = [];
    displayedColumns = ['channel','deliver_at','order_type','description','name','element','final_amount'];
    dataSource: DeliverySale[] = [];
    cashRegisterRoute = this.configurationManagerService.isModuleAngular('tables_and_cashregister') ? 'app.new.cashregister.content.showcase' : 'app.cashregister.content.showcase';

    constructor(private tilbyDatePipe: TilbyDatePipe,
        private translateService: TranslateService,
        private configurationManagerService :ConfigurationManagerService,
        private entityManagerService: EntityManagerService,
        @Inject($state) private state: any) {
    }

    async ngOnInit() {
        this.dataSource = this.getObjectSales(this.sales.filter((sale) => sale.order_type === 'take_away' || sale.order_type === 'delivery'));
        this.channels = await this.entityManagerService.channels.fetchCollectionOffline().then(channels => channels.map(channel => channel.id));
    }

    getObjectSales(sales : Sales[]): DeliverySale[] {
        let salesData = sales
        .map((item) => {
            const name = item.sale_customer?.first_name
              ? `${item.sale_customer.first_name} ${item.sale_customer.last_name}`
              : item.sale_customer?.company_name;
            const element = item.sale_items?.reduce((acc, curr) => acc + curr.quantity, 0);
            return {
              id: item.id,
              uuid: item.uuid,
              deliver_at: item?.deliver_at,
              channel: item.channel,
              order_type: item.order_type,
              final_amount: item.final_amount,
              name: name,
              element: element,
              description: item.name
            };
        })
        .sort(({deliver_at:a1},{deliver_at:b1})=>{
            const sortDescMilli = (b:string,a:string) => TilbyDatePipe.toUtcDateMillis(a)-TilbyDatePipe.toUtcDateMillis(b);
            const sort1 = sortDescMilli(b1 as any as string,a1 as any as string);
            return sort1 || 0;
        }
        );
        return salesData;
    }

    dateFormatted(date: Date | undefined) {
        let dateNow = TilbyDatePipe.shopDate() || '';
        const currentDateParts = dateNow?.split('T')[0].split('-').map(Number);
        let dateSale = TilbyDatePipe.shopDate({date:date}) || '';
        const saleDateParts = dateSale?.split('T')[0].split('-').map(Number);
        if (saleDateParts![2] === currentDateParts![2]) {
            return this.tilbyDatePipe.transform(dateSale, 'HH:mm');
        } else if (saleDateParts![2] - currentDateParts![2] === 1) {
            return this.translateService.instant("TABLES.DELIVERY.TOMORROW") + ',' + this.tilbyDatePipe.transform(dateSale, 'HH:mm');
        } else {
            return this.tilbyDatePipe.transform(dateSale, 'd/MM/yyyy HH:mm');
        }
    }

    channelImage(channel : string) {
        return this.channels.includes(channel) ? channel : '-';
    }

    getType(type: string) {
        switch(type) {
            case 'take_away': {
                return this.translateService.instant("TABLES.DELIVERY.TAKE_AWAY");
            }
            case 'delivery': {
                return this.translateService.instant("TABLES.DELIVERY.DELIVERY");
            }
            default : {
                return ''
            }
        }
    }

    clickSale(row: DeliverySale) { // TODO: set model
        this.state.go(this.cashRegisterRoute, {
            action: 'open-sale-id',
            id: row.uuid,
            saleType: row.order_type
        });
    }

    newTakeAwaySale() {
        this.state.go(this.cashRegisterRoute, {
            action: 'create-new-sale',
            saleType: 'take_away'
        });
    };

    newDeliverySale() {
        this.state.go(this.cashRegisterRoute, {
            action: 'create-new-sale',
            saleType: 'delivery'
        });
    };
}


// TODO : CARICAMENTO TAKE-AWAY E ASPORTO IN CASSA (AngularJS - Vedere chiamata attuale su orders e riprodurla su sale con relative modali)
