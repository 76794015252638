import * as angular from 'angular';
import { BedzzleCommonFolio, BedzzleCommonFoliosResponse, BedzzleCommonTransaction, BedzzleCommonTransactionResponse } from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';
import { ConfigurationManagerService } from 'src/app/core';

export type EricsoftApiConfig = {
    ip_address: string;
    port: number;
    username: string;
    password: string;
    property_id: string;
}

export class EricsoftApi {
    private apiUrl: string;

    constructor(
        private $http: angular.IHttpService,
        ipAddress: string,
        port: number,
        private username: string,
        private password: string,
        private propertyId: string
    ) {
        if(ipAddress.includes('/')) {
            this.apiUrl = ipAddress;

            if(!this.apiUrl.endsWith('/')) {
                this.apiUrl += '/';
            }
        } else {
            this.apiUrl = `http://${ipAddress}:${port}/TbyApi/application/`;
        }
    }

    private async sendApiRequest(url: string, method: string, data?: any, params: any = {}): Promise<any> {
        const response = await this.$http({
            method: method,
            url: this.apiUrl + url,
            data: data,
            params: params,
            timeout: 60000,
            headers: {
                'Authorization': `Basic ${window.btoa(`${this.username}:${this.password}`)}`
            }
        });

        return response.data;
    }

    public async getRoomFolios(): Promise<BedzzleCommonFolio[]> {
        const response = await this.sendApiRequest('room_folios', 'GET', null, { propertyId: this.propertyId }) as BedzzleCommonFoliosResponse;

        return response.folios;
    }

    public async getMiscSalesFolios(): Promise<BedzzleCommonFolio[]> {
        const response = await this.sendApiRequest('misc_sales_folios', 'GET', null, { propertyId: this.propertyId }) as BedzzleCommonFoliosResponse;

        return response.folios;
    }

    public async createTransaction(transactionData: BedzzleCommonTransaction): Promise<any> {
        const response = await this.sendApiRequest('transactions', 'POST', { ...transactionData, propertyId: this.propertyId }) as BedzzleCommonTransactionResponse

        return response;
    }

    public async rollbackTransaction(outletId: string, orderId: string): Promise<{ status: string }> {
        const response = await this.sendApiRequest('transactions', 'DELETE', {
            outletId: outletId,
            orderId: orderId
        });

        return response;
    }
}

const configAttributes: Array<keyof EricsoftApiConfig> = ['ip_address', 'port', 'username', 'password', 'property_id'];

export class EricsoftPMSApiService {
    constructor(
        private $http: any,
        private checkManager: ConfigurationManagerService
    ) {
    }

    public getCurrentConfig(): EricsoftApiConfig {
        const config: any = {};

        for (const key of configAttributes) {
            config[key] = this.checkManager.getPreference(`ericsoft_pms.${key}`) || '';
        }

        config['port'] = parseInt(config['port']) || 5000;

        return <EricsoftApiConfig>config;
    }

    public async setupApi(config: EricsoftApiConfig): Promise<void> {
        try {
            for (const key of configAttributes) {
                await this.checkManager.setShopPreferenceSync(`ericsoft_pms.${key}`, config[key]);
            }
        } catch (err) {
            throw 'SETUP_FAILED';
        }
    }

    public getApiInstance(): EricsoftApi {
        const config = this.getCurrentConfig();

        for(const key in config) {
            // Check if something is missing (port is optional)
            if(!config[key as keyof EricsoftApiConfig]) {
                throw `MISSING_${key.toUpperCase()}`;
            }
        }

        return new EricsoftApi(this.$http, config['ip_address'], config['port'] || 5000, config['username'], config['password'], config['property_id']);
    }
}

EricsoftPMSApiService.$inject = ['$http', 'checkManager'];

angular.module('application').service('ericsoftPMSApi', EricsoftPMSApiService);