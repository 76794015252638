import * as angular from 'angular';

export type OperatorUser = {
    id: number
    first_name: string
    last_name: string
    full_name: string
    username: string
};

type operatorType = 'currentOperator' | 'currentSeller';

const userDataToOperatorUser = (userData: any): OperatorUser => ({
    id: userData.id,
    first_name: userData.first_name,
    last_name: userData.last_name,
    full_name: (userData.first_name && userData.last_name) ? `${userData.first_name} ${userData.last_name}` : userData.username,
    username: userData.username
});

export class OperatorManagerService {
    private currentOperator: OperatorUser | undefined;
    private currentSeller: OperatorUser | undefined;

    constructor(
        private $rootScope: any,
        private userSessionsManager: any
    ) {
    }

    private async changeOp(target: operatorType): Promise<void> {
        let userData = await this.userSessionsManager.show({ disableNewUser: true, canDismiss: true });

        if (userData) {
            this[target] = userDataToOperatorUser(userData);
        }
    }

    private getOp(target: operatorType, userData?: any): OperatorUser {
        if (!this[target]) {
            this[target] = userDataToOperatorUser(this.$rootScope.userActiveSession);
        }

        if (typeof userData === 'object') {
            return userDataToOperatorUser(userData);
        }

        return structuredClone(this[target]!);
    }

    public async changeOperator(): Promise<void> {
        return this.changeOp('currentOperator');
    }

    public async changeSeller(): Promise<void> {
        return this.changeOp('currentSeller');
    }

    public getOperatorData(userData?: any): OperatorUser {
        return this.getOp('currentOperator', userData);
    }

    public getSellerData(userData?: any): OperatorUser {
        return this.getOp('currentSeller', userData);
    }
}

OperatorManagerService.$inject = ['$rootScope', 'userSessionsManager']

angular.module('core').service('OperatorManager', OperatorManagerService);