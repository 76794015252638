export class SclDictionary<DictionaryKey extends string,Value extends Record<string,any>=Record<string,any>> {
	private _internalObj: Record<DictionaryKey, Value>=this.resetObject();

	private resetObject() {
		return {} as Record<DictionaryKey, Value>;
	}

	public clear() {
		this._internalObj = this.resetObject();
	}

	public containsKey(key: string): boolean {
		return key in this._internalObj;
	}

	public init(dataSet: any[], key: string) {
		this._internalObj = this.resetObject();
		return this.bulkSet(dataSet, key);
	}

	public forEach(callback: (value: Value, key: string, obj: Record<DictionaryKey, Value>) => void) {
		Object.entries<Value>(this._internalObj).forEach(([key, value]) =>
            !!value && callback(value, key, this._internalObj));
	}

	public get(key: DictionaryKey): Value|undefined {
		return this._internalObj[key];
	}

	public isEmpty(): boolean {
		return this.keys().length === 0;
	}

	public keys(): string[] {
		return Object.keys(this._internalObj);
	}

	public remove(key: DictionaryKey) {
		delete this._internalObj[key];
	}

	public set(key: DictionaryKey, value: Value) {
		this._internalObj[key] = value;
	}

	public size(): number {
		return Object.keys(this._internalObj).length;
	}

	public bulkSet(values: Value[], key: keyof Value) {
		for(let value of values) {
			this._internalObj[value[key]] = value;
		}
	}

	public values(): Value[] {
		return Object.values(this._internalObj);
	}
}
