<div class="tw-flex tw-w-full">
    <app-tilby-keypad-display [form]="form" class="tw-flex-1 tw-max-w-full"/>
    @if(!isMobile()){<mat-icon fontIcon="collapse_content" class="tw-text-4xl tw-m-auto tw-w-14 sale-keypad-button tw-bg-transparent material-symbols-outlined" (click)="activateSideKeyboard.emit(true)"/>}
</div>
<div class="tw-grid tw-grid-rows-3 tw-grid-cols-4 tw-grid-flow-col tw-gap-1 tw-h-[calc(100%-56px)] tw-pb-[13px]">
    @for(btn of buttons; track $index){
        @if(btn.isIt()){
            <tilby-general-input-button
                    class="sale-keypad-button tw-w-full tw-truncate"
                    [class.tw-opacity-25]="btn.isDisable?.()"
                    [class.tw-pointer-events-none]="btn.isDisable?.()"
                    [class.tw-bg-green-500]="btn.selected?.()"
                    [btn]="btn"/>
        }
    }
</div>
