import {
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    signal
} from '@angular/core';

import {GeneralInputButton} from '@tilby/tilby-ui-lib/models';
import {TilbyInfoPanelComponent} from '@tilby/tilby-ui-lib/components/tilby-info-panel';
import { ConfigurationManagerService, SkeletonLoaderService } from 'src/app/core/services';
import { $state, sessionManager } from 'app/ajs-upgraded-providers';
import { ThemeModeService } from 'src/app/core';

@Component({
    selector: 'app-toolbar-utente',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: true,
    imports: [
        TilbyInfoPanelComponent
    ]
})
export class ToolbarUtenteComponent {
    // Injects
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly state = inject($state);
    private readonly sessionManagerService = inject(sessionManager);
    private readonly skeletonLoaderService = inject(SkeletonLoaderService);
    private readonly themeModeService = inject(ThemeModeService);

    // Inputs
    @Input() operatorName = '';
    @Input() shopName = '';

    // Outputs
    @Output() goTo = new EventEmitter<string>();
    @Output() closeLeftMenu = new EventEmitter<void>();

    // Configs
    private canExitUser = signal(this.configurationManagerService.getPreference('users.enable_user_logout') !== false);
    private canSwitchUser = signal(this.configurationManagerService.getPreference('users.enable_user_switch') !== false);
    private cantCloseSessions = signal(!this.configurationManagerService.isUserPermitted('sidenav.close_sessions'));
    private showThemeSwitch = this.configurationManagerService.getPreference('migration.show_theme_switch');

    btns: GeneralInputButton[] = [
        {
            isIt: signal(true),
            name: 'SIDENAV.MODULES.SETTINGS.USER',
            icon: signal('account_circle'),
            click: () => this.goTo.emit(this.configurationManagerService.isModuleAngular('settings.user') ? 'new.settings.user' : 'settings.user')
        },
        {
            isIt: this.canSwitchUser,
            name: 'SIDENAV.USER.SWITCH',
            icon: signal('cached'),
            click: () => this.switchUser()
        },
        {
            isIt: this.canExitUser,
            name: 'SIDENAV.USER.LOGOUT',
            icon: signal('exit_to_app'),
            click: () => this.logoutActiveUserSession()
        },
        {
            isIt: signal(true),
            isDisable: this.cantCloseSessions,
            name: 'SIDENAV.USER.DEEP_LOGOUT',
            icon: signal('highlight_off'),
            click: () => this.configurationManagerService.isUserPermitted('sidenav.close_sessions') && this.logoutCloseSessionsDeep()
        }
    ];

    private switchUser() {
        this.closeLeftMenu.emit();
        this.state.go('app.lockScreen');
    }
    // private switchThemeAndLoad() {
    //     this.themeModeService.switchTheme();
    //     this.skeletonLoaderService.isLoading.next(true);
    //     setTimeout(() => this.skeletonLoaderService.isLoading.next(false), 2000);
    // }

    private logoutActiveUserSession() {
        this.closeLeftMenu.emit();
        this.sessionManagerService.logoutActiveUserSession(true);
    }

    private logoutCloseSessionsDeep() {
        this.closeLeftMenu.emit();
        this.sessionManagerService.logoutCloseSessionsDeep(true);
    }
}
