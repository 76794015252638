import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').controller('MultipleOrderCtrl', ["$scope", "$state", "$stateParams", "$translate", "orders", "rooms", "checkManager", "ActiveOrder", "entityManager", "durationFilter", "confirmDialog", "alertDialog", "orderUtils", function($scope, $state, $stateParams, $translate, orders, rooms, checkManager, ActiveOrder, entityManager, durationFilter, confirmDialog, alertDialog, orderUtils) {

    var tableId = _.toInteger($stateParams.table_id);
    var roomId = _.toInteger($stateParams.room_id);

    $scope.orders = _.get(orders, ['byTable', tableId ]) || [];
    $scope.room = _.find(rooms, { id: roomId }) || {};
    $scope.table = _.find($scope.room.tables, {
        id: tableId
    });

    $scope.isMultiple = $scope.table.order_type === "multiple";

    if($scope.isMultiple) {
        $scope.orders = _.orderBy($scope.orders, ['open_at', 'created_at'], ['asc', 'asc']);
    }

    let index = _.findIndex(rooms, { id: _.toInteger($stateParams.id) });
    if (index === -1) {
        index = 0;
    }

    $scope.topbar_context = {
        multipleordermode: true,
        back_label: $scope.table.name,
        currentRoom: rooms[index],
        room_id: $stateParams.room_id
    };

    $scope.showOrder = function(order) {
        if (order.status === 'checkout') {
            if (checkManager.isModuleEnabled('cashregister')) {
                entityManager.sales.fetchCollectionOffline({ order_id: order.id, status: 'open' }).then(function(results) {
                    if (results.length) {
                        $state.go('app.cashregister.content.showcase', {
                            action: 'open-sale-id',
                            id: _.head(results).id
                        });
                    } else {
                        confirmDialog.show($translate.instant('TABLES.MULTIPLE_ORDER.CLOSE_CONFIRM')).then(function(answer) {
                            if (answer) {
                                order.status = "closed";
                                entityManager.orders.putOneOfflineFirst(order).then(function() {
                                    $state.reload('app.tables');
                                });
                            }
                        });
                    }
                });
            } else {
                alertDialog.show($translate.instant('TABLES.TABLE.CASHREGISTER_NOT_ENABLED'));
            }
        } else {
            $state.go('app.orders.content', {
                type: 'normal',
                table: $scope.table,
                room: $scope.room,
                orderId: order.id
            });
        }
    };

    $scope.deleteOrder = function(ev, order) {
        if (checkManager.isUserPermitted('orders.delete_order')) {
            confirmDialog.show($translate.instant('TABLES.MULTIPLE_ORDER.DELETE_CONFIRM')).then(function(answer) {
                if(answer) {
                    entityManager.orders.deleteOneOfflineFirst(order.id).then(function() {
                        _.pull($scope.orders, order);
                    });
                }
            });
        }
    };

    $scope.mergeOrders = function() {
        confirmDialog.show($translate.instant('TABLES.MULTIPLE_ORDER.MERGE_CONFIRM')).then(function(answer) {
            if(answer) {
                var mergedOrder = orderUtils.mergeOrders($scope.orders, {
                    room_id: $scope.room.id,
                    room_name: $scope.room.name,
                    table_id: $scope.table.id,
                    table_name: $scope.table.name
                });

                entityManager.orders.postOneOfflineFirst(mergedOrder).then(function(result) {
                    ActiveOrder.switchOrdersInParking(result);

                    _.forEach($scope.orders, function(order) {
                        if(order.uuid !== result.uuid) {
                            entityManager.orders.deleteOneOfflineFirst(order.id);
                        }
                    });

                    $state.go('app.orders.content');
                });
            }
        });
    };

    $scope.getDuration = function(order) {
        return durationFilter(order.open_at);
    };

    $scope.addOrder = function() {
        $state.go('app.orders.content', {
            type: 'normal',
            table: $scope.table,
            room: $scope.room,
            orderId: null
        });
    };
}]);
