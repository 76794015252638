import {Component, Inject, Input, OnInit} from '@angular/core';
import {Sales} from 'tilby-models';
import {util} from 'app/ajs-upgraded-providers';
import {RightSidenavService} from 'src/app/core/services';
import {HistorySaleDetailComponent} from '../history-sale-detail.component';
import { OpenDialogsService } from 'src/app/dialogs';
import { GridService } from 'src/app/shared/components/grid/grid.service';
import { removeFieldsWithPrefix } from 'src/app/shared/string-utils';

@Component({
    selector: 'app-history-sale-detail-toolbar, [app-history-sale-detail-toolbar]',
    templateUrl: './history-sale-detail-toolbar.component.html',
    styleUrls: ['./history-sale-detail-toolbar.component.scss']
})
export class HistorySaleDetailToolbarComponent implements OnInit {
    @Input() sale?: Sales;

    constructor(
        @Inject(util) private utilService: any,
        private openDialogsService: OpenDialogsService,
        private rightSidenavService:RightSidenavService<HistorySaleDetailComponent>,
        private gridService: GridService
        ) { }

    ngOnInit(): void {
    }

    closeSidenav() {
        this.rightSidenavService.isOpen = false;
        this.gridService.history_sales$.closeDetail.next();
    }

    async openSaleJsonDetail() {
        await this.openDialogsService.openGenericJsonDetailDialog({data: { title:'HISTORY.SALES.DETAIL.TITLE_OPEN_JSON_DETAIL', nameFile: 'sale_json_data', json: removeFieldsWithPrefix(this.sale || {}, '$') } });
    }

    getCustomerCaption() {
        return this.sale?.customer_tax_code || this.utilService.getCustomerCaption(this.sale?.sale_customer);
    }
}
