import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss']
})
export class KioskComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.kiosk");
    }
  ngOnInit(): void {
  }

}
