import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('editTable', ["$mdDialog", "$state", "entityManager", "confirmDialog", "$timeout", function($mdDialog, $state, entityManager, confirmDialog, $timeout) {

    editTableController.$inject = ["$scope", "$translate", "dialog", "tableData", "room", "tables"];
    function editTableController($scope, $translate, dialog, tableData, room, tables) {

        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function() { //TODO: check room boundaries
            $scope.data.covers = _.toInteger($scope.data.covers);
            var newShape = _.pick($scope.selectedDimensions, ['shape', 'icon', 'width', 'height']);
            _.merge($scope.data,newShape);
            $mdDialog.hide($scope.data);
        };

        $scope.updateAvailableDimensions = function(){
            $timeout(function(){ //TODO: find a better solution
                $scope.selectedDimensions = $scope.selectedShape.sizes[0];
            },100);
        };

        $scope.deleteTable = function() {
            confirmDialog.show($translate.instant('TABLES.EDIT_TABLE.CONFIRM_DELETE')).then(function(answer) {
                if (answer) {
                    var tmpRoom = _.cloneDeep(room);
                    _.remove(tmpRoom.tables, {
                        id: tableData.id
                    });
                    entityManager.rooms.putOneOnline(tmpRoom).then(function() {
                        $state.reload("app.tables");
                    });
                }
                else {
                    reShowDialog();
                }
            });
        };

        var reShowDialog = function() {
             if(dialog) {
                dialog.show($scope.data, room, dialog);
            }
        };

        $scope.tables = _.cloneDeep(tables);

        $scope.covers = _.range(1,51);

        $scope.data = _.clone(tableData);

        $scope.selectedShape = _.find($scope.tables, function(shape) {
            var x = _.find(shape.sizes, {shape: $scope.data.shape});
            if(x) {
                $scope.selectedDimensions = x;
                return true;
            }
            else {
                return false;
            }
        });        
    }

    var editTable = {
        show: function(tableData, currentRoom, dialog) {
            $mdDialog.show({
                    controller: editTableController,
                    template: require('./edit-table.html'),
                    locals: {
                        dialog: dialog,
                        tableData: tableData,
                        room: currentRoom
                    }
                }).then(function(answer) {
                    var tmpRoom = _.cloneDeep(currentRoom);
                    var tableToUpdate = _.find(tmpRoom.tables,{id: answer.id});
                    if(tableToUpdate){
                        _.merge(tableToUpdate,answer);
                        entityManager.rooms.putOneOnline(tmpRoom).then(function() {
                            $state.reload("app.tables");
                        });
                    }
                });
        }
    };

    return editTable;
}]);

