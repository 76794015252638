import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').directive('topbarCashregister', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-cashregister.html'),
        controller: topbarCashregisterController
    };
});

topbarCashregisterController.$inject = ["$scope", "$rootScope", "$mdSidenav", "$state", "$timeout", "$mdMedia", "$translate", "connection", "util", "checkManager", "offlineDialog", "checkStock", "checkExternalStock", "leanPMS"];

function topbarCashregisterController($scope, $rootScope, $mdSidenav, $state, $timeout, $mdMedia, $translate, connection, util, checkManager, offlineDialog, checkStock, checkExternalStock, leanPMS) {
    const supportedPMSRoomServices = [leanPMS];

    Object.assign($scope, {
        isCheckExternalStockEnabled: checkManager.isFunctionEnabledOptin('cashregister.check_external_stock'),
        isCheckStockEnabled: !!checkManager.getPreference('cashregister.check_stock'),
        isPriceListUpdateEnabled: checkManager.getPreference("cashregister.disable_price_list_apply_prompt") ? false : true,
        isSwitchPriceListEnabled: !!checkManager.getPreference('cashregister.enable_switch_price_list'),
        PMSRoomService: supportedPMSRoomServices.find((service) => service.isEnabled()),
        priceLists: [],
        useFullMenuBar: $mdMedia('min-width: 800px'),
        isTablesModuleEnabled: checkManager.isModuleEnabled('tables')
    });

    for(let idx = 1; idx <= 10; idx++) {
        if(!checkManager.getPreference(`price_list_${idx}_hide`)) {
            $scope.priceLists.push({
                index: idx,
                name: checkManager.getPreference(`price_list_${idx}_name`) || $translate.instant('CASHREGISTER.TOPBAR.PRICE_LIST_START') + ' ' + (idx)
            });
        }
    }

    $scope.priceListMenuHide =  _.isEmpty($scope.priceLists);

    $scope.isConnectionOffline = function() {
        return connection.isOffline();
    };

    $scope.onBarcodeScanned = function(result) {
        $scope.context.onSubmitBarcode(result);
    };

    $scope.openOfflineDialog = function() {
        offlineDialog.show();
    };

    $scope.openLeftMenu = function() {
        $mdSidenav('left').toggle();
    };

    $scope.openCheckStock = function() {
        checkStock.show();
    };

    $scope.openCheckExternalStock = function() {
        checkExternalStock.show();
    };

    $scope.openPMSRoomService = function() {
        if($scope.PMSRoomService) {
            $scope.PMSRoomService.openDialog();
        }
    };

    $scope.goBack = function() {
        if ($scope.context.modeview === 'payments') {
            $state.go('app.cashregister.content.showcase');
        } else if ($scope.context.modeview === 'tickets') {
            $scope.context.modeview = 'payments';
            $scope.context.back_label = $translate.instant('CASHREGISTER.TOPBAR.PAYMENT');
        }
    };

    $scope.viewPhotoGrid = function() {
        if($scope.context.showcaseMode !== 'photoGrid') {
            $scope.context.showcaseMode = 'photoGrid';
            checkManager.setUserPreference("showcase_mode", 'photoGrid');
        }
    };

    $scope.viewNoPhotoGrid = function() {
        if($scope.context.showcaseMode !== 'noPhotoGrid') {
            $scope.context.showcaseMode = 'noPhotoGrid';
            checkManager.setUserPreference("showcase_mode", 'noPhotoGrid');
        }
    };

    $scope.viewList = function() {
        if($scope.context.showcaseMode !== 'list') {
            $scope.context.showcaseMode = 'list';
            checkManager.setUserPreference("showcase_mode", 'list');
        }
    };

    $scope.selectDisplayMode = function(mode) {
        if(['compact', 'normal'].includes(mode)) {
            $scope.context.displayMode = mode;
            checkManager.setUserPreference("showcase_display_mode", mode);
        }
    };

    $scope.selectKeyboardMode = function(mode) {
        if(['big', 'compact'].includes(mode)) {
            $scope.context.keyboardMode = mode;
            checkManager.setUserPreference("cashregister.keyboard_mode", mode);
        }
    };

    $scope.orderByName = function() {
        $scope.context.order_by = '+name';
        checkManager.setUserPreference("showcase_order_by", '+name');
    };

    $scope.orderByIndex = function() {
        $scope.context.order_by = '+index';
        checkManager.setUserPreference("showcase_order_by", '+index');
    };

    $scope.orderByPriceAsc = function() {
        $scope.context.order_by = '+price';
        checkManager.setUserPreference("showcase_order_by", '+price');
    };

    $scope.orderByPriceDesc = function() {
        $scope.context.order_by = '-price';
        checkManager.setUserPreference("showcase_order_by", '-price');
    };

    $scope.showUnavailableItems = function(show) {
        $scope.context.showUnavailable = show;
        checkManager.setUserPreference("showcase_show_unavailable", show);
    };

    $scope.selectCategoryTabMode = function() {
        if ($scope.context.categoryView !== 'tab') {
            $scope.context.categoryView = 'tab';
            checkManager.setUserPreference("showcase_category_view", 'tab');
            $rootScope.$broadcast('cashregister-showcase:update-items');
        }
    };

    $scope.selectCategoryGridMode = function() {
        if ($scope.context.categoryView !== 'grid') {
            $scope.context.categoryView = 'grid';
            checkManager.setUserPreference("showcase_category_view", 'grid');
            $rootScope.$broadcast('cashregister-showcase:update-items');
        }
    };

    $scope.showSearch = function() {
        $scope.context.showSearch = true;
        $timeout(function() {
            angular.element('#cashregisterShowcaseSearch').trigger('focus');
        });
    };

    $scope.openCashDrawer = function() {
        $scope.context.openCashDrawer();
    };

    $scope.dailyClosing = function() {
        $scope.context.dailyClosing();
    };

    $scope.addCashMovement = function() {
        $scope.context.addCashMovement();
    };

    $scope.goToPriceList = function(priceList) {
        if($scope.isSwitchPriceListEnabled && priceList !== $scope.context.priceList) {
            $scope.context.priceList = priceList;
            checkManager.setUserPreference("price_list", String(priceList));
            if($scope.isPriceListUpdateEnabled) {
                $rootScope.$broadcast("cashregister:pricelist-changed");
            }
        }
    };

    $scope.$on("cashregister:reset-pricelist", function(event, data) {
        $scope.context.priceList = util.resetPriceList($scope.context.priceList);
    });

    $scope.$on("activeSale:use-pricelist", function(event, data) {
        var newPriceList;

        if(data.priceList === 'default') {
            newPriceList = util.getCurrentPriceList();
        } else {
            newPriceList = _.toInteger(data.priceList);
        }

        if(newPriceList !== $scope.context.priceList) {
            $scope.context.priceList = newPriceList;
            if(!data.skipNotify) {
                $rootScope.$broadcast("cashregister:pricelist-changed");
            }
        }
    });

    $scope.$watch(function() { return $mdMedia('min-width: 905px'); }, function(full) {
        $scope.useFullMenuBar = full;
    });

    $scope.goTo = function(destination) {
        $mdSidenav('left').close();
        $state.go("app." + destination);
    };

    $scope.goToTables = function() {
        if(checkManager.isModuleAngular('tables_and_cashregister')) {
            $scope.goTo('new.tables');
        } else {
            $scope.goTo('tables.rooms-view');
        }
    };
}
