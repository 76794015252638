import * as angular from 'angular';
import * as _ from 'lodash';
import { validate as validateUuid } from 'uuid';
import { paymentMethodTypes } from 'src/app/core/constants';

angular.module('prepaid').factory('prepaidSale', prepaidSale);

prepaidSale.$inject = ["$filter", "$translate", "checkManager", "entityManager", "fiscalUtils", "restManager", "util"];

function prepaidSale($filter, $translate, checkManager, entityManager, fiscalUtils, restManager, util) {
    const cardMethodType = _.find(paymentMethodTypes, { id: 16 });
    const knownBEErrorCodes = ['PREPAID_CUSTOMER_WITHOUT_FIDELITY', 'PREPAID_CUSTOMER_NOT_FOUND'];

    const getCustomerUuid = (sale) => {
        let customer_uuid = sale.sale_customer?.uuid;

        return validateUuid(customer_uuid) ? customer_uuid : null;
    };

    const hasUnallowedChange = (sale, movements, currentCredit) => {
        let paymentAmount = _(movements).filter({ from: 'payment' }).sumBy('amount');

        if(sale.final_amount < 0) {
            return false;
        } else if(util.round(_.sumBy(sale.payments, 'amount')) > sale.final_amount) {
            if(!_(sale.payments).reject({ payment_method_type_id: 16 }).isEmpty()) {
                return paymentAmount === currentCredit.total;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const getCardMethod = (method, amount, date) => ({
        amount: amount,
        date: date,
        payment_method_id: method.id,
        payment_method_name: method.name,
        payment_method_type_id: cardMethodType.id,
        payment_method_type_name: cardMethodType.name,
        unclaimed: method.unclaimed
    });

    const getPrepaidMovements = (sale) => {
        let movements = [];

        for(let payment of sale.payments) {
            if(payment.payment_method_type_id === 16) {
                movements.push({
                    amount: Math.abs(payment.amount),
                    movement_type: payment.amount >= 0 ? 'unload' : 'load',
                    from: 'payment'
                });
            }
        }

        if(!sale.is_summary) {
            const rechargeDepartment = parseInt(checkManager.getPreference('prepaid.recharge_department_id'));

            if(rechargeDepartment) {
                for(let saleItem of sale.sale_items) {
                    if(saleItem.department_id === rechargeDepartment) {
                        movements.push({
                            amount: util.round(Math.abs(saleItem.price * saleItem.quantity)),
                            movement_type: saleItem.quantity > 0 ? 'load' : 'unload',
                            from: 'item'
                        });
                    }
                }
            }
        }

        return movements;
    };

    const prepaidSale = {
        //  online
        isEnabled: () => checkManager.isFunctionEnabledOptin("prepaid.enabled"),
        prePrintHook: async (sale, printerDocumentData) => {
            //Check recharge items quantity
            const rechargeDepartment = parseInt(checkManager.getPreference('prepaid.recharge_department_id'));

            if(rechargeDepartment) {
                for(let saleItem of sale.sale_items) {
                    if(saleItem.department_id === rechargeDepartment && !_.isInteger(saleItem.quantity)) {
                        throw 'PREPAID_RECHARGE_INVALID_QUANTITY';
                    }
                }
            }

            //Get prepaid movements from sale
            let prepaidMovements = getPrepaidMovements(sale);

            //Stop if there is nothing related to the prepaid cards in this sale
            if(_.isEmpty(prepaidMovements)) {
                return;
            }

            const customerUuid = getCustomerUuid(sale);
            const customerFidelity = sale.sale_customer?.fidelity;

            //Check if customer uuid and fidelities are properly compiled
            if(!customerUuid || !customerFidelity) {
                throw 'PREPAID_NO_CUSTOMER';
            }

            //Check if both claimed and unclaimed card payment methods are configured
            const paymentMethods = await entityManager.paymentMethods.fetchCollectionOffline();
            const normalPaymentMethod = _.find(paymentMethods, { payment_method_type_id: 16, unclaimed: false });
            const ticketPaymentMethod = _.find(paymentMethods, { payment_method_type_id: 16, unclaimed: true });

            if(!normalPaymentMethod || !ticketPaymentMethod) {
                throw 'PREPAID_METHODS_MISCONFIGURED';
            }

            //Get the last movement for this customer to obtain the credit
            let lastMovement;

            try {
                let movements = await restManager.getList('prepaid_movements', {
                    customer_uuid: customerUuid,
                    valid_to: 'null',
                    pagination: 'false'
                });

                lastMovement = _.head(movements) || { credit: 0, ticket_credit: 0 };
            } catch(error) {
                throw 'PREPAID_CONNECTION_ERROR';
            }

            let currentCredit = {
                normal: lastMovement.credit,
                ticket: lastMovement.ticket_credit,
                total: util.round(lastMovement.credit + lastMovement.ticket_credit)
            };

            if(hasUnallowedChange(sale, prepaidMovements, currentCredit)) {
                throw 'PREPAID_HAS_CHANGE';
            }

            //Check if the credit is enough for this transaction
            let transactionAmount = prepaidMovements.reduce((amount, movement) => {
                amount[movement.movement_type] = util.round(amount[movement.movement_type] + movement.amount);

                return amount;
            }, { load: 0, unload: 0 });

            if(currentCredit.total < transactionAmount.unload) {
                throw 'PREPAID_UNSUFFICIENT_CREDIT';
            }

            const now = new Date().toISOString();

            let movementsToCommit = [];

            //Unload movements (can be payments or refunded prepaid recharges)
            if(transactionAmount.unload) {
                //Pay as much as possible using the ticket amount, then use the normal amount if there is still amount to pay
                if(currentCredit.ticket >= transactionAmount.unload) {
                    movementsToCommit.push({ is_unclaimed: true, amount: transactionAmount.unload, movement_type: 'unload' });
                } else {
                    if(currentCredit.ticket > 0) {
                        movementsToCommit.push({ is_unclaimed: true, amount: currentCredit.ticket, movement_type: 'unload' });
                    }

                    movementsToCommit.push({ is_unclaimed: false, amount: util.round(transactionAmount.unload - currentCredit.ticket), movement_type: 'unload' });
                }

                if(sale.final_amount > 0) {
                    //Replace card methods with calculated ones
                    const salePrepaidPayments = _.remove(sale.payments, { payment_method_type_id: 16 });

                    if(!_.isEmpty(salePrepaidPayments)) {
                        for(let movement of movementsToCommit) {
                            sale.payments.push(getCardMethod(movement.is_unclaimed ? ticketPaymentMethod : normalPaymentMethod, movement.amount, now));
                        }
                    }
                }
            }

            //Load movements (can be prepaid recharges or payments of refunded/voided sales)
            if(transactionAmount.load) {
                if(sale.final_amount > 0) {
                    let paymentsTotals = sale.payments.reduce((tot, payment) => {
                        let target = fiscalUtils.isMethodUnclaimed(payment.payment_method_type_id, payment.unclaimed) ? 'unclaimed' : 'claimed';
                        tot[target] = util.round(tot[target] + payment.amount);

                        return tot;
                    }, { claimed: 0, unclaimed: 0 });

                    if(paymentsTotals.claimed >= transactionAmount.load) {
                        movementsToCommit.push({ is_unclaimed: false, amount: transactionAmount.load, movement_type: 'load' });
                    } else {
                        movementsToCommit.push({ is_unclaimed: false, amount: paymentsTotals.claimed, movement_type: 'load' });
    
                        let remainingAmount = util.round(transactionAmount.load - paymentsTotals.claimed);
                        movementsToCommit.push({ is_unclaimed: (paymentsTotals.unclaimed >= remainingAmount), amount: remainingAmount, movement_type: 'load' });
                    }
                } else {
                    movementsToCommit.push({ is_unclaimed: false, amount: transactionAmount.load, movement_type: 'load' });
                }
            }

            //Assign sale and customer info to the movements
            const sale_uuid = validateUuid(sale.id) ? sale.id : sale.uuid;

            for(let movement of movementsToCommit) {
                Object.assign(movement, {
                    sale_uuid: sale_uuid,
                    customer_uuid: customerUuid
                });
            }

            try {
                let results = await restManager.post('prepaid_movements', movementsToCommit);

                //if the server returned a setup movement, it probably fixed a previous transaction attempt for this sale, adjust previous credit accordingly
                return {
                    oldAmount: _.isArray(results) && _.head(results).movement_type === 'cancel' ? util.round(_.head(results).credit + _.head(results).ticket_credit) : currentCredit.total,
                    newAmount: _.isArray(results) ? util.round(_.last(results).credit + _.last(results).ticket_credit) : util.round(results.credit + results.ticket_credit),
                    saleUuid: sale_uuid
                };
            } catch(error) {
                if(error.status === -1) {
                    try {
                        await restManager.post('prepaid_rollback', { sale_uuid: sale_uuid });
                    } catch(err) {
                        //NOTHING TO DO
                    } finally {
                        throw 'PREPAID_NETWORK_ERROR';
                    }
                } else {
                    if(knownBEErrorCodes.includes(error?.data?.error_code)) {
                        throw error.data.error_code;
                    } else {
                        throw 'PREPAID_TRANSACTION_ERROR';
                    }
                }
            }
        },
        printFailHook: async (sale, printerDocumentData, prepaidInfo) => {
            if(!_.isEmpty(prepaidInfo)) {
                try {
                    await restManager.post('prepaid_rollback', { sale_uuid: prepaidInfo.saleUuid });
                } catch(err) {
                    //Nothing to do
                }
            }
        },
        getData: async (sale, printerDocumentData, prepaidInfo) => {
            if(!_.isEmpty(prepaidInfo)) {
                let rowsDesc = [];
                let rowsData = [];

                if (_.isFinite(prepaidInfo.oldAmount)) {
                    rowsDesc.push(checkManager.getPreference("prepaid.prevamount_message") || $translate.instant('PREPAID.SALE.PREVIOUS_CREDIT'));
                    rowsData.push($filter('sclCurrency')(prepaidInfo.oldAmount));
                }

                if (_.isFinite(prepaidInfo.newAmount)) {
                    rowsDesc.push(checkManager.getPreference("prepaid.amount_message") || $translate.instant('PREPAID.SALE.CURRENT_CREDIT'));
                    rowsData.push($filter('sclCurrency')(prepaidInfo.newAmount));
                }

                const maxRowLength = _.chain(rowsDesc).maxBy('length').size().value();

                let rowsToSend = rowsDesc.map((row, idx) => (_.padEnd(row, maxRowLength, " ") + " " + rowsData[idx]));

                return {
                    tail: rowsToSend
                };
            }
        }
    };

    return prepaidSale;
}