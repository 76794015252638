import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('digitalPayments').factory('verifoneOcius', verifoneOcius);

verifoneOcius.$inject = ["$q", "$rootScope", "waitDialog", "$interval", "$translate", "checkManager", "verifoneOciusDriver"];

function verifoneOcius($q, $rootScope, waitDialog, $interval, $translate, checkManager, verifoneOciusDriver) {
    var performPayment = function(amount, options) {
        let paymentMethod = options.paymentMethod;
        var paymentTimeout = _.toInteger(checkManager.getPreference('cashregister.verifone_ocius.payment_timeout')) || 60000;

        verifoneOciusDriver.init(paymentMethod.bundle_name, paymentMethod.schema_name, { timeout: paymentTimeout });

        var dialogDefer = $q.defer();

        var intervalPromise = $interval(function() {
            var pluginStatus = verifoneOciusDriver.getStatus();

            var state = {
                message: pluginStatus.readableStatus,
            };

            if(pluginStatus.requiresConfirmation) {
                Object.assign(state, {
                    customActions: [{
                        label: $translate.instant('DIGITAL_PAYMENTS.VERIFONE_OCIUS.REPRINT'),
                        callback: verifoneOciusDriver.reprintMerchantReceipt
                    }, {
                        label: $translate.instant('APPLICATION.CONFIRM_DIALOG.NO'),
                        callback: verifoneOciusDriver.sendNoAnswer
                    }, {
                        label: $translate.instant('APPLICATION.CONFIRM_DIALOG.YES'),
                        callback: verifoneOciusDriver.sendYesAnswer
                    }]
                });
            } else {
                Object.assign(state, {
                    cancelAction: {
                        label: $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CANCEL_PAYMENT'),
                        callback: verifoneOciusDriver.cancelPayment
                    },
                });
            }

            $rootScope.$broadcast("wait-dialog:update-state", state);
        }, 1000, 0 , false);

        waitDialog.show({ message: $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CONNECTING_POS'), promise: dialogDefer.promise });

        return verifoneOciusDriver.performPayment(amount).then(function(resultObj) {
            dialogDefer.resolve();

            // resolve to print and store sale on closed
            return {
                //acquirer_id : result.acquirer_id,
                //acquirer_name : result.acquirer_name,
                card_circuit_name: resultObj.response.schemeName,
                payment_data: JSON.stringify(resultObj.response),
                tail: resultObj.receiptData,
                unclaimed: false
            };
        }, function(error) {
            dialogDefer.reject();

            throw {
                response: (error === 'USER_CANCELED' ? null : $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.ERROR_DURING_PAYMENTS', { value: error.response })),
                receiptData: error.receiptData
            };
        }).finally(function() {
            $interval.cancel(intervalPromise);
        });
    };

    var verifoneOcius = {
        payment: function(amount, options) {
            return performPayment(amount, options);
        },
        refund: function(amount, options) {
            return performPayment(-amount, options);
        }
    };

    return verifoneOcius;
}