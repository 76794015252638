/**
 * Reflows a string into multiple lines, with the given number of characters per line.
 * @param str The string of array of strings to reflow
 * @param charsPerLine The number of characters per line.
 * @returns The array of reflowed lines.
 */
export const stringToLines = (str: string | string[], charsPerLine?: number): string[] => {
    const stringLines = typeof str === 'string' ? str.split('\n') : str;

    if (!charsPerLine) {
        return stringLines;
    }

    const result = [];

    for (let row of stringLines) {
        const linesWrapped = row.match(new RegExp(`.{1,${charsPerLine}}`, 'g')) || [];

        for (let line of linesWrapped) {
            result.push(line);
        }
    }

    return result;
}

/**
 * Removes fields from an object that have a given prefix.
 *
 * @param {Record<string, any>} obj - The object to remove fields from.
 * @param {string} prefix - The prefix to check for in the field names.
 * @return {object} - The object with the fields removed.
 */
export const removeFieldsWithPrefix = (obj: Record<string, any>, prefix: string): object => {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            removeFieldsWithPrefix(obj[key], prefix);
        } else if (key.startsWith(prefix)) {
            delete obj[key];
        }
    }
    return obj;
}

const escapeReplaceMap: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
};

/**
 * Escapes the HTML entities in a string.
 * Reimplementation of lodash's _.escape
 *
 * @param {string} str - The string to escape.
 * @return {string} - The escaped string.
 */
export const escape = (str: string): string => {
    return str.replace(/[&<>"']/g, (char) => escapeReplaceMap[char] || char);
}