import { Component, Input } from '@angular/core';
import { ReactiveFormsModule} from "@angular/forms";
import {CustomForm, CustomFormGroup, TilbyInputComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {TotalFormValue} from "../tilby-sale-keypad";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";

@Component({
  selector: 'app-tilby-keypad-display',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TilbyInputComponent
  ],
  templateUrl: './tilby-keypad-display.component.html',
  styleUrl: './tilby-keypad-display.component.scss',
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },TilbyCurrencyPipe]

})
export class TilbyKeypadDisplayComponent {
    @Input() inputClass="tw-flex the-dense-zone just-1 tw-pb-1 pointer-events-none-for-all-children tw-text-4xl";
    @Input({required: true}) form!: CustomFormGroup<CustomForm<TotalFormValue>>;
}
