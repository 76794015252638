import {Component, OnDestroy} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {TilbyDatePipe} from '@tilby/tilby-ui-lib/pipes/tilby-date';
import {NgIf} from "@angular/common";
import { ConnectionService } from 'src/app/core';

@Component({
    selector: 'app-offline-message',
    templateUrl: './offline-message.component.html',
    styleUrls: ['./offline-message.component.scss'],
    imports: [
        TranslateModule,
        NgIf
    ],
    standalone: true
})
export class OfflineMessageComponent implements OnDestroy{

    offlineDuration=this.getOfflineDuration();
    interval?:NodeJS.Timer;
    constructor(
        private connectionService: ConnectionService
    ) {
        this.interval = setInterval(()=>this.offlineDuration=this.getOfflineDuration(),10000)
    }

    private getOfflineDuration() {
        return TilbyDatePipe.durationBetweenDates(this.connectionService.offlineAt().toString())
    }
    ngOnDestroy(){
        clearInterval(this.interval);
    }
}
