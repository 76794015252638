import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-stock-movements',
  templateUrl: './stock-movements.component.html',
  styleUrls: ['./stock-movements.component.scss']
})
export class StockMovementsComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.stock.movements");
    }


    ngOnInit(): void {
  }

}
