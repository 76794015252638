import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.items");
    }
  ngOnInit(): void {
  }

}
