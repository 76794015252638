<tilby-dialog-toolbar title="SIDENAV.MODULES.QRCODE_WAITER.TITLE" [hideConfirm]="true" [customActions]="createActionButtons"></tilby-dialog-toolbar>
<tilby-dialog-content>
    <div id="container" class="tw-flex tw-justify-between tw-gap-2 tw-w-[650px]">
        <div>
            <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="tw-w-[200px] tw-h-[300px]">
                <div *cdkVirtualFor="let item of (isLoading ? devices : $any(EMPTY_ITEMS))" class="item" (click)="onItemClick(item)">
                    <div class="tw-flex tw-flex-row item">
                        <div class="tw-mt-6 tw-ml-4" *skeleton="!devices?.length; appearance:'circle'; theme:{width:'40px', height:'40px', background: 'darkgray'}" >
                            <mat-icon [ngStyle]="{ 'color': item === deviceSelected ? 'black' : 'gray' }" style="transform: scale(2)">{{ itemsIcon }}</mat-icon>
                        </div>
                        <div class="tw-flex tw-flex-col">
                            <ng-container *skeleton="!devices?.length; theme:{width:'170px', height:'20px', background: 'darkgray', margin: '5px 0px 1px 0px'}"><div class="tw-mt-4 tw-ml-4" *ngIf="item.name">{{ item.name }} </div></ng-container>
                            <ng-container *skeleton="!devices?.length; theme:{width:'120px', height:'18px', background: 'darkgray'}"><div class="tw-text-sm tw-ml-4" *ngIf="item.updated_at">{{ buildFieldLegend2(item) }}</div></ng-container>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
        <mat-divider vertical></mat-divider>
        <div id="section" class="tw-w-full tw-flex tw-justify-center">
            <div *ngIf="!deviceSelected || !devices.length" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-full tw-w-[100%]">
                <mat-icon style="transform: scale(7);" class="tw-mb-20">
                    <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
                <div class="tw-whitespace-nowrap" translate>SETTINGS.USERS_MANAGEMENT.DEVICE_NOT_SELECTED_MSG_DIALOG</div>
            </div>
            <div *ngIf="deviceSelected && devices.length" class="tw-flex tw-items-center tw-justify-center tw-flex-col">
                <div class="container tw-relative">
                    <div [ngClass]="{'tw-opacity-50': hasSessionToken()}">
                        <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                    <div *ngIf="hasSessionToken()" class="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0">
                        <div class="tw-flex tw-justify-center tw-items-center tw-h-full">
                            <div class="tw-h-[80px] tw-w-[200px] tw-bg-white tw-flex tw-justify-center tw-items-center tw-opacity-80">
                                <p class="tw-text-black tw-font-bold tw-text-center tw-flex tw-items-center">{{'SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.CODE_ALREADY_USED' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tw-flex tw-mt-4" *ngIf="enabledQrCodeGenerator">
                    <button mat-stroked-button color="primary" (click)="regenerate()">
                        <mat-icon>refresh</mat-icon>
                        {{ 'SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.REGENERATE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</tilby-dialog-content>