import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { RoomsStateService } from '../../rooms.state.service';
import {TranslateModule} from "@ngx-translate/core";

export interface People {
    icon: string;
    covers: number;
    totalCovers: number;
}
export interface TopbarTablesTotal{
    label: string;
    price: number;
    people: People;
}

@Component({
  selector: 'app-topbar-tables-total',
  standalone: true,
    imports: [CommonModule, MatIconModule, TilbyCurrencyPipe,TranslateModule],
  templateUrl: './topbar-tables-total.component.html',
  styleUrls: ['./topbar-tables-total.component.scss'],
    host:{class:'tw-flex tw-flex-auto tw-justify-end'}
})
export class TopbarTablesTotalComponent {
    private readonly roomsStateService =inject(RoomsStateService);
    totalsArray$= this.roomsStateService.totalsArray$;

}
