import * as angular from "angular";
import { CashdrawerPaymentDialog } from "../dialog/cashdrawer-payment-dialog/cashdrawer-payment-dialog";
import { CimaCashdrawerDriver } from "./cima-cashdrawer-driver";
import { DigitalPaymentHandler } from "src/app/shared/model/digital-payments.model";

class CimaCashdrawer implements DigitalPaymentHandler {
    constructor(
        private $translate: any,
        private CashdrawerPaymentDialog: CashdrawerPaymentDialog,
        private CimaCashdrawerDriver: CimaCashdrawerDriver
    ) {
    }

    public payment(amount: number, options: any) {
        let paymentMethod = options.paymentMethod;

        if (!paymentMethod.schema_name) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.SCHEMA_NAME_NOT_FOUND');
        }

        return this.CashdrawerPaymentDialog.show(amount, options.paymentMethod, this.CimaCashdrawerDriver, "Cassetto Cima");
    }
}

CimaCashdrawer.$inject = ["$translate", "CashdrawerPaymentDialog", "CimaCashdrawerDriver"];

angular.module('digitalPayments').service('CimaCashdrawer', CimaCashdrawer);
