import * as angular from 'angular';

angular.module('history', ['application']);

angular.module('history').config(["$stateProvider", "$translatePartialLoaderProvider", function ($stateProvider, $translatePartialLoaderProvider) {

    $translatePartialLoaderProvider.addPart('history');

    $stateProvider.state('app.history', {
        url: '/history',
        redirectTo: 'app.new.history.sales'
    });

    $stateProvider.state('app.history.e_invoices', {
        url: '/e-invoices',
        views: {
            "appContent@app": {
                template: require('./partial/history-e-invoices/history-e-invoices.html'),
                controller: 'HistoryEInvoicesCtrl'
            }
        }
    });

    $stateProvider.state('app.history.e_invoices_passive', {
        url: '/e-invoices/passive',
        views: {
            "appContent@app": {
                template: require('./partial/history-e-invoices-passive/history-e-invoices-passive.html'),
                controller: 'HistoryEInvoicesPassiveCtrl'
            }
        }
    });
    /* Add New States Above */
}]);
