import * as angular from 'angular';

import {downgradeComponent, downgradeInjectable} from "@angular/upgrade/static";
import {SkeletonPageComponent} from "../src/app/core/components/skeleton-loaders";
import {
    NewMaterialSidenavComponent
} from "../src/app/core/components/new-material-sidenav/new-material-sidenav.component";
import {
    SettingsUsersManagementComponent,
    CustomersDetailsComponent,
    CustomersShowcaseComponent,
    DashboardComponent,
    GiftcardShowcaseComponent,
    GiftcardTypesPanelComponent,
    HistorySalesShowcaseComponent,
    HistoryCashMovementsComponent,
    SettingsModelDocumentComponent,
    SettingsLabelPanelComponent,
    SettingsShiftsComponent,
    SettingsChannelsComponent,
    SettingsUserComponent,
    SuppliersDetailsComponent,
    SuppliersShowcaseComponent,
    TablesComponent,
    CashregisterComponent,
    CashregisterShowcaseComponent,
    ActiveSaleComponent,
    PromotionEngineService,
    SettingsAlvoloComponent,
    ActiveSaleService,
    SaleTransactionUtilsService,
    FileImporterComponent,
    ActiveSaleStoreService,
    SalePrintingUtilsService,
    SettingsMySubscriptionComponent,
    SettingsKioskComponent,
    SaleBatchPrintService
} from "../src/app/features";

import {DualService} from '../src/app/dual.service';
import {
    AddNewItemToSaleDialogService,
    DocumentPrintersManagerDialogStateService,
    EInvoiceDialogService,
    LotteryCodeDialogStateService,
    OpenDialogsService,
    ReceiptOptionsDialogStateService,
    RoundTotalDialogStateService,
    CurrencyConverterDialog,
    ItemLabelsDialogService,
    EditShopPreferenceDialogService,
    PrinterErrorFiscalDialogService,
    AddCashMovementDialogService,
    SaleDocumentsViewerDialogService,
    SignupFormDialogService
} from "../src/app/dialogs";

import { RoomsStateService } from 'src/app/features/tables/rooms.state.service';
import { CustomersCallService } from 'src/app/core/services/customers-call.service';
import {
    AppToAppService,
    BarcodeManagerService,
    ConnectionService,
    ConfigurationManagerService,
    EnvironmentInfoService,
    EntityDictionariesStoreService,
    EntityManagerService,
    ScreenOrientationService,
    OauthService,
    IndexeddbManagerService,
    StorageManagerService,
    ShopSessionEntity,
    UserSessionsEntity,
    CoreStateService,
    SidenavMenuComponent,
    LoginViewService,
    StartupQueriesService,
    UserActiveSessionManagerService,
} from 'src/app/core';


import {
    ShowcaseGridComponent,
    ShowcaseListComponent
} from 'src/app/features/cashregister/components';
import { CategoryGridComponent } from 'src/app/features/cashregister/components/category-grid/category-grid.component';
import { CategoryTabComponent } from 'src/app/features/cashregister/components/category-tab/category-tab.component';
import { OrderPrinterSelectDialogService } from 'src/app/dialogs/order-printer-select-dialog';
import {
    SaleUtilsService,
    SalePaymentService,
} from 'src/app/features/cashregister/services';
import { RefundOrRemoveItemDialogStateService } from 'src/app/dialogs/refund-or-remove-item-dialog';
import { CashregisterPaymentsComponent } from 'src/app/features/cashregister/components/cashregister-payments/cashregister-payments.component';
import { FileImporterAddComponent } from 'src/app/features/settings/settings-file-importer/components/settings-file-importer-add/settings-file-importer-add.component';
import { FileImporterFieldsComponent } from 'src/app/features/settings/settings-file-importer/components/settings-file-importer-fields/settings-file-importer-fields.component';
import { FileImporterScheduleComponent } from 'src/app/features/settings/settings-file-importer/components/settings-file-importer-schedule/settings-file-importer-schedule.component';
import { SaleItemManagerDialogService } from 'src/app/dialogs/sale-item-manager-dialog';
import { CampgestPsmPaymentsDialogService } from 'src/app/dialogs/cashregister/campgest-psm-payments-dialog';
import { CampgestPmsApiService } from 'src/app/shared/services/digital-payments/campgest/campgest-pms-api.service';
import { SettingsFileExporterShowcaseComponent } from 'src/app/features/settings/settings-file-exporter/components/settings-file-exporter-showcase/settings-file-exporter-showcase.component';
import { WellbyPmsPaymentsDialogService } from 'src/app/dialogs/cashregister/wellby-pms-payments-dialog/wellby-pms-payments-dialog.component';
import { WellbyPmsApiService } from 'src/app/shared/services/digital-payments/wellby/wellby-pms-api.service';

angular
    .module("TilbyApp")
    .factory("dual", downgradeInjectable(DualService))
    .factory("userActiveSession", downgradeInjectable(UserActiveSessionManagerService))
    .factory("openDialogsService", downgradeInjectable(OpenDialogsService))
    .factory("customersCallService", downgradeInjectable(CustomersCallService))
    .factory("activeSaleStore", downgradeInjectable(ActiveSaleStoreService))
    .directive("skeletonPage", downgradeComponent({component: SkeletonPageComponent}))
    .directive("newSidenav", downgradeComponent({component: NewMaterialSidenavComponent}))
    .directive("newSidenavMenuComponent", downgradeComponent({component: SidenavMenuComponent,outputs: ['goTo', 'closeMenuSidenav']}))

angular
    .module("suppliersNew")
    .directive("suppliersShowcase", downgradeComponent({component: SuppliersShowcaseComponent}))
    .directive("suppliersDetails", downgradeComponent({component: SuppliersDetailsComponent}));

angular
    .module("cashregisterNew")
    .factory("receiptOptionsDialogService", downgradeInjectable(ReceiptOptionsDialogStateService))
    .factory("addNewItemToSale", downgradeInjectable(AddNewItemToSaleDialogService))
    .factory("addCashMovement", downgradeInjectable(AddCashMovementDialogService))
    .factory("documentPrintersManager", downgradeInjectable(DocumentPrintersManagerDialogStateService))
    .factory("orderPrinterSelect", downgradeInjectable(OrderPrinterSelectDialogService))
    .factory("eInvoiceDialog", downgradeInjectable(EInvoiceDialogService))
    .factory("lotteryCodeDialog", downgradeInjectable(LotteryCodeDialogStateService))
    .factory("promotionEngine", downgradeInjectable(PromotionEngineService))
    .factory("newSaleUtils", downgradeInjectable(SaleUtilsService))
    .factory("ActiveSaleNew", downgradeInjectable(ActiveSaleService))
    .factory("salePayment", downgradeInjectable(SalePaymentService))
    .factory("refundOrRemoveItem", downgradeInjectable(RefundOrRemoveItemDialogStateService))
    .factory("roundTotalDialog", downgradeInjectable(RoundTotalDialogStateService))
    .factory("currencyConverterDialog", downgradeInjectable(CurrencyConverterDialog))
    .factory("saleTransactionUtils", downgradeInjectable(SaleTransactionUtilsService))
    .factory("salePrintingUtils", downgradeInjectable(SalePrintingUtilsService))
    .factory("saleItemManagerDialog", downgradeInjectable(SaleItemManagerDialogService))
    .factory("campgestPsmPaymentsDialog", downgradeInjectable(CampgestPsmPaymentsDialogService))
    .factory("campgestPmsApiService", downgradeInjectable(CampgestPmsApiService))
    .factory("wellbyPmsPaymentsDialog", downgradeInjectable(WellbyPmsPaymentsDialogService))
    .factory("wellbyPmsApiService", downgradeInjectable(WellbyPmsApiService))
    .factory("saleBatchPrint", downgradeInjectable(SaleBatchPrintService))
    .directive("cashregisterNewComponent", downgradeComponent({component: CashregisterComponent}))
    .directive("activeSaleComponent", downgradeComponent({component: ActiveSaleComponent}))
    .directive("cashregisterShowcaseComponent", downgradeComponent({component: CashregisterShowcaseComponent}))
    .directive("cashregisterPaymentsComponent", downgradeComponent({component: CashregisterPaymentsComponent}))

angular
    .module("fileImporterNew")
    .directive("appFileImporter", downgradeComponent({component: FileImporterComponent}))
    .directive("appFileImporterAdd", downgradeComponent({component: FileImporterAddComponent}))
    .directive("appFileImporterFields", downgradeComponent({component: FileImporterFieldsComponent}))
    .directive("appFileImporterSchedule", downgradeComponent({component: FileImporterScheduleComponent}))

    angular
    .module("fileExporterNew")
    .directive("appFileExporter", downgradeComponent({component: SettingsFileExporterShowcaseComponent}))

angular
    .module("customersNew")
    .directive("customersShowcase", downgradeComponent({component: CustomersShowcaseComponent}))
    .directive("customersDetails", downgradeComponent({component: CustomersDetailsComponent}))

angular
    .module("historyNew")
    .directive("historySalesShowcase", downgradeComponent({component: HistorySalesShowcaseComponent}))
    .directive("historyCashMovements", downgradeComponent({component: HistoryCashMovementsComponent}))
    .service("saleDocumentsViewer", downgradeInjectable(SaleDocumentsViewerDialogService))

angular
    .module("giftcardNew")
    .directive("giftcardShowcase", downgradeComponent({component: GiftcardShowcaseComponent}))
    .directive("giftcardTypesPanel", downgradeComponent({component: GiftcardTypesPanelComponent}))

angular
    .module("dashboardNew")
    .directive("dashboard", downgradeComponent({component: DashboardComponent}));

angular
    .module("tablesNew")
    .directive("tables", downgradeComponent({component: TablesComponent}))
    .factory("roomsStateService", downgradeInjectable(RoomsStateService));

angular
    .module("settingsNew")
    .directive("settingsUsersManagement", downgradeComponent({component: SettingsUsersManagementComponent}))
    .directive("settingsModelDocument", downgradeComponent({component: SettingsModelDocumentComponent}))
    .directive("settingsLabelPanel", downgradeComponent({component: SettingsLabelPanelComponent}))
    .directive("settingsShifts", downgradeComponent({component: SettingsShiftsComponent}))
    .directive("settingsChannels", downgradeComponent({component: SettingsChannelsComponent}))
    .directive("settingsUser", downgradeComponent({component: SettingsUserComponent}))
    .directive("settingsAlvolo", downgradeComponent({component: SettingsAlvoloComponent}))
    .directive("mySubscription", downgradeComponent({component: SettingsMySubscriptionComponent}))
    .directive("settingsKiosk", downgradeComponent({component: SettingsKioskComponent}))


angular.module("core")
    .service("environmentInfo", downgradeInjectable(EnvironmentInfoService))
    .service("screenOrientation", downgradeInjectable(ScreenOrientationService))
    .service("oauth", downgradeInjectable(OauthService))
    .service("barcodeManager", downgradeInjectable(BarcodeManagerService))
    .service("connection", downgradeInjectable(ConnectionService))
    .service("coreState", downgradeInjectable(CoreStateService))
    .service("indexeddbManager", downgradeInjectable(IndexeddbManagerService))
    .service("storageManager", downgradeInjectable(StorageManagerService))
    .service("shopSession", downgradeInjectable(ShopSessionEntity))
    .service("userSessions", downgradeInjectable(UserSessionsEntity))
    .service("loginView", downgradeInjectable(LoginViewService))
    .service("checkManager", downgradeInjectable(ConfigurationManagerService))
    .service("entityManager", downgradeInjectable(EntityManagerService))
    .factory("configurationManagerService", downgradeInjectable(ConfigurationManagerService))
    .service("entityDictionariesStore", downgradeInjectable(EntityDictionariesStoreService))
    .service("startupQueries", downgradeInjectable(StartupQueriesService))
    .service("appToApp", downgradeInjectable(AppToAppService))

angular.module("application")
    .directive("newShowcaseGrid", downgradeComponent({ component: ShowcaseGridComponent}))
    .directive("newShowcaseList", downgradeComponent({ component: ShowcaseListComponent}))
    .directive("newCategoryGrid", downgradeComponent({ component: CategoryGridComponent}))
    .directive("newCategoryTab", downgradeComponent({ component: CategoryTabComponent}))
    .factory("itemLabelsDialog", downgradeInjectable(ItemLabelsDialogService))
    .factory("signupFormDialog", downgradeInjectable(SignupFormDialogService))
    .service('editShopPreferenceDialogService', downgradeInjectable(EditShopPreferenceDialogService))
    .service('printerErrorFiscal', downgradeInjectable(PrinterErrorFiscalDialogService))
