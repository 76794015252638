type CountryCode = {
    name: string,
    countryCode: string,
    alpha3: string
}

export const countryCodes: Record<string, CountryCode> = {
    "AF": {
        name: "Afghanistan",
        countryCode: "004",
        alpha3: "AFG"
    },
    "AX": {
        name: "Åland Islands",
        countryCode: "248",
        alpha3: "ALA"
    },
    "AL": {
        name: "Albania",
        countryCode: "008",
        alpha3: "ALB"
    },
    "DZ": {
        name: "Algeria",
        countryCode: "012",
        alpha3: "DZA"
    },
    "AS": {
        name: "American Samoa",
        countryCode: "016",
        alpha3: "ASM"
    },
    "AD": {
        name: "Andorra",
        countryCode: "020",
        alpha3: "AND"
    },
    "AO": {
        name: "Angola",
        countryCode: "024",
        alpha3: "AGO"
    },
    "AI": {
        name: "Anguilla",
        countryCode: "660",
        alpha3: "AIA"
    },
    "AQ": {
        name: "Antarctica",
        countryCode: "010",
        alpha3: "ATA"
    },
    "AG": {
        name: "Antigua and Barbuda",
        countryCode: "028",
        alpha3: "ATG"
    },
    "AR": {
        name: "Argentina",
        countryCode: "032",
        alpha3: "ARG"
    },
    "AM": {
        name: "Armenia",
        countryCode: "051",
        alpha3: "ARM"
    },
    "AW": {
        name: "Aruba",
        countryCode: "533",
        alpha3: "ABW"
    },
    "AU": {
        name: "Australia",
        countryCode: "036",
        alpha3: "AUS"
    },
    "AT": {
        name: "Austria",
        countryCode: "040",
        alpha3: "AUT"
    },
    "AZ": {
        name: "Azerbaijan",
        countryCode: "031",
        alpha3: "AZE"
    },
    "BS": {
        name: "Bahamas",
        countryCode: "044",
        alpha3: "BHS"
    },
    "BH": {
        name: "Bahrain",
        countryCode: "048",
        alpha3: "BHR"
    },
    "BD": {
        name: "Bangladesh",
        countryCode: "050",
        alpha3: "BGD"
    },
    "BB": {
        name: "Barbados",
        countryCode: "052",
        alpha3: "BRB"
    },
    "BY": {
        name: "Belarus",
        countryCode: "112",
        alpha3: "BLR"
    },
    "BE": {
        name: "Belgium",
        countryCode: "056",
        alpha3: "BEL"
    },
    "BZ": {
        name: "Belize",
        countryCode: "084",
        alpha3: "BLZ"
    },
    "BJ": {
        name: "Benin",
        countryCode: "204",
        alpha3: "BEN"
    },
    "BM": {
        name: "Bermuda",
        countryCode: "060",
        alpha3: "BMU"
    },
    "BT": {
        name: "Bhutan",
        countryCode: "064",
        alpha3: "BTN"
    },
    "BO": {
        name: "Bolivia (Plurinational State of)",
        countryCode: "068",
        alpha3: "BOL"
    },
    "BQ": {
        name: "Bonaire, Sint Eustatius and Saba",
        countryCode: "535",
        alpha3: "BES"
    },
    "BA": {
        name: "Bosnia and Herzegovina",
        countryCode: "070",
        alpha3: "BIH"
    },
    "BW": {
        name: "Botswana",
        countryCode: "072",
        alpha3: "BWA"
    },
    "BV": {
        name: "Bouvet Island",
        countryCode: "074",
        alpha3: "BVT"
    },
    "BR": {
        name: "Brazil",
        countryCode: "076",
        alpha3: "BRA"
    },
    "IO": {
        name: "British Indian Ocean Territory",
        countryCode: "086",
        alpha3: "IOT"
    },
    "BN": {
        name: "Brunei Darussalam",
        countryCode: "096",
        alpha3: "BRN"
    },
    "BG": {
        name: "Bulgaria",
        countryCode: "100",
        alpha3: "BGR"
    },
    "BF": {
        name: "Burkina Faso",
        countryCode: "854",
        alpha3: "BFA"
    },
    "BI": {
        name: "Burundi",
        countryCode: "108",
        alpha3: "BDI"
    },
    "CV": {
        name: "Cabo Verde",
        countryCode: "132",
        alpha3: "CPV"
    },
    "KH": {
        name: "Cambodia",
        countryCode: "116",
        alpha3: "KHM"
    },
    "CM": {
        name: "Cameroon",
        countryCode: "120",
        alpha3: "CMR"
    },
    "CA": {
        name: "Canada",
        countryCode: "124",
        alpha3: "CAN"
    },
    "KY": {
        name: "Cayman Islands",
        countryCode: "136",
        alpha3: "CYM"
    },
    "CF": {
        name: "Central African Republic",
        countryCode: "140",
        alpha3: "CAF"
    },
    "TD": {
        name: "Chad",
        countryCode: "148",
        alpha3: "TCD"
    },
    "CL": {
        name: "Chile",
        countryCode: "152",
        alpha3: "CHL"
    },
    "CN": {
        name: "China",
        countryCode: "156",
        alpha3: "CHN"
    },
    "CX": {
        name: "Christmas Island",
        countryCode: "162",
        alpha3: "CXR"
    },
    "CC": {
        name: "Cocos (Keeling) Islands",
        countryCode: "166",
        alpha3: "CCK"
    },
    "CO": {
        name: "Colombia",
        countryCode: "170",
        alpha3: "COL"
    },
    "KM": {
        name: "Comoros",
        countryCode: "174",
        alpha3: "COM"
    },
    "CG": {
        name: "Congo",
        countryCode: "178",
        alpha3: "COG"
    },
    "CD": {
        name: "Congo, Democratic Republic of the",
        countryCode: "180",
        alpha3: "COD"
    },
    "CK": {
        name: "Cook Islands",
        countryCode: "184",
        alpha3: "COK"
    },
    "CR": {
        name: "Costa Rica",
        countryCode: "188",
        alpha3: "CRI"
    },
    "CI": {
        name: "Côte d'Ivoire",
        countryCode: "384",
        alpha3: "CIV"
    },
    "HR": {
        name: "Croatia",
        countryCode: "191",
        alpha3: "HRV"
    },
    "CU": {
        name: "Cuba",
        countryCode: "192",
        alpha3: "CUB"
    },
    "CW": {
        name: "Curaçao",
        countryCode: "531",
        alpha3: "CUW"
    },
    "CY": {
        name: "Cyprus",
        countryCode: "196",
        alpha3: "CYP"
    },
    "CZ": {
        name: "Czechia",
        countryCode: "203",
        alpha3: "CZE"
    },
    "DK": {
        name: "Denmark",
        countryCode: "208",
        alpha3: "DNK"
    },
    "DJ": {
        name: "Djibouti",
        countryCode: "262",
        alpha3: "DJI"
    },
    "DM": {
        name: "Dominica",
        countryCode: "212",
        alpha3: "DMA"
    },
    "DO": {
        name: "Dominican Republic",
        countryCode: "214",
        alpha3: "DOM"
    },
    "EC": {
        name: "Ecuador",
        countryCode: "218",
        alpha3: "ECU"
    },
    "EG": {
        name: "Egypt",
        countryCode: "818",
        alpha3: "EGY"
    },
    "SV": {
        name: "El Salvador",
        countryCode: "222",
        alpha3: "SLV"
    },
    "GQ": {
        name: "Equatorial Guinea",
        countryCode: "226",
        alpha3: "GNQ"
    },
    "ER": {
        name: "Eritrea",
        countryCode: "232",
        alpha3: "ERI"
    },
    "EE": {
        name: "Estonia",
        countryCode: "233",
        alpha3: "EST"
    },
    "SZ": {
        name: "Eswatini",
        countryCode: "748",
        alpha3: "SWZ"
    },
    "ET": {
        name: "Ethiopia",
        countryCode: "231",
        alpha3: "ETH"
    },
    "FK": {
        name: "Falkland Islands (Malvinas)",
        countryCode: "238",
        alpha3: "FLK"
    },
    "FO": {
        name: "Faroe Islands",
        countryCode: "234",
        alpha3: "FRO"
    },
    "FJ": {
        name: "Fiji",
        countryCode: "242",
        alpha3: "FJI"
    },
    "FI": {
        name: "Finland",
        countryCode: "246",
        alpha3: "FIN"
    },
    "FR": {
        name: "France",
        countryCode: "250",
        alpha3: "FRA"
    },
    "GF": {
        name: "French Guiana",
        countryCode: "254",
        alpha3: "GUF"
    },
    "PF": {
        name: "French Polynesia",
        countryCode: "258",
        alpha3: "PYF"
    },
    "TF": {
        name: "French Southern Territories",
        countryCode: "260",
        alpha3: "ATF"
    },
    "GA": {
        name: "Gabon",
        countryCode: "266",
        alpha3: "GAB"
    },
    "GM": {
        name: "Gambia",
        countryCode: "270",
        alpha3: "GMB"
    },
    "GE": {
        name: "Georgia",
        countryCode: "268",
        alpha3: "GEO"
    },
    "DE": {
        name: "Germany",
        countryCode: "276",
        alpha3: "DEU"
    },
    "GH": {
        name: "Ghana",
        countryCode: "288",
        alpha3: "GHA"
    },
    "GI": {
        name: "Gibraltar",
        countryCode: "292",
        alpha3: "GIB"
    },
    "GR": {
        name: "Greece",
        countryCode: "300",
        alpha3: "GRC"
    },
    "GL": {
        name: "Greenland",
        countryCode: "304",
        alpha3: "GRL"
    },
    "GD": {
        name: "Grenada",
        countryCode: "308",
        alpha3: "GRD"
    },
    "GP": {
        name: "Guadeloupe",
        countryCode: "312",
        alpha3: "GLP"
    },
    "GU": {
        name: "Guam",
        countryCode: "316",
        alpha3: "GUM"
    },
    "GT": {
        name: "Guatemala",
        countryCode: "320",
        alpha3: "GTM"
    },
    "GG": {
        name: "Guernsey",
        countryCode: "831",
        alpha3: "GGY"
    },
    "GN": {
        name: "Guinea",
        countryCode: "324",
        alpha3: "GIN"
    },
    "GW": {
        name: "Guinea-Bissau",
        countryCode: "624",
        alpha3: "GNB"
    },
    "GY": {
        name: "Guyana",
        countryCode: "328",
        alpha3: "GUY"
    },
    "HT": {
        name: "Haiti",
        countryCode: "332",
        alpha3: "HTI"
    },
    "HM": {
        name: "Heard Island and McDonald Islands",
        countryCode: "334",
        alpha3: "HMD"
    },
    "VA": {
        name: "Holy See",
        countryCode: "336",
        alpha3: "VAT"
    },
    "HN": {
        name: "Honduras",
        countryCode: "340",
        alpha3: "HND"
    },
    "HK": {
        name: "Hong Kong",
        countryCode: "344",
        alpha3: "HKG"
    },
    "HU": {
        name: "Hungary",
        countryCode: "348",
        alpha3: "HUN"
    },
    "IS": {
        name: "Iceland",
        countryCode: "352",
        alpha3: "ISL"
    },
    "IN": {
        name: "India",
        countryCode: "356",
        alpha3: "IND"
    },
    "ID": {
        name: "Indonesia",
        countryCode: "360",
        alpha3: "IDN"
    },
    "IR": {
        name: "Iran (Islamic Republic of)",
        countryCode: "364",
        alpha3: "IRN"
    },
    "IQ": {
        name: "Iraq",
        countryCode: "368",
        alpha3: "IRQ"
    },
    "IE": {
        name: "Ireland",
        countryCode: "372",
        alpha3: "IRL"
    },
    "IM": {
        name: "Isle of Man",
        countryCode: "833",
        alpha3: "IMN"
    },
    "IL": {
        name: "Israel",
        countryCode: "376",
        alpha3: "ISR"
    },
    "IT": {
        name: "Italy",
        countryCode: "380",
        alpha3: "ITA"
    },
    "JM": {
        name: "Jamaica",
        countryCode: "388",
        alpha3: "JAM"
    },
    "JP": {
        name: "Japan",
        countryCode: "392",
        alpha3: "JPN"
    },
    "JE": {
        name: "Jersey",
        countryCode: "832",
        alpha3: "JEY"
    },
    "JO": {
        name: "Jordan",
        countryCode: "400",
        alpha3: "JOR"
    },
    "KZ": {
        name: "Kazakhstan",
        countryCode: "398",
        alpha3: "KAZ"
    },
    "KE": {
        name: "Kenya",
        countryCode: "404",
        alpha3: "KEN"
    },
    "KI": {
        name: "Kiribati",
        countryCode: "296",
        alpha3: "KIR"
    },
    "KP": {
        name: "Korea (Democratic People's Republic of)",
        countryCode: "408",
        alpha3: "PRK"
    },
    "KR": {
        name: "Korea, Republic of",
        countryCode: "410",
        alpha3: "KOR"
    },
    "KW": {
        name: "Kuwait",
        countryCode: "414",
        alpha3: "KWT"
    },
    "KG": {
        name: "Kyrgyzstan",
        countryCode: "417",
        alpha3: "KGZ"
    },
    "LA": {
        name: "Lao People's Democratic Republic",
        countryCode: "418",
        alpha3: "LAO"
    },
    "LV": {
        name: "Latvia",
        countryCode: "428",
        alpha3: "LVA"
    },
    "LB": {
        name: "Lebanon",
        countryCode: "422",
        alpha3: "LBN"
    },
    "LS": {
        name: "Lesotho",
        countryCode: "426",
        alpha3: "LSO"
    },
    "LR": {
        name: "Liberia",
        countryCode: "430",
        alpha3: "LBR"
    },
    "LY": {
        name: "Libya",
        countryCode: "434",
        alpha3: "LBY"
    },
    "LI": {
        name: "Liechtenstein",
        countryCode: "438",
        alpha3: "LIE"
    },
    "LT": {
        name: "Lithuania",
        countryCode: "440",
        alpha3: "LTU"
    },
    "LU": {
        name: "Luxembourg",
        countryCode: "442",
        alpha3: "LUX"
    },
    "MO": {
        name: "Macao",
        countryCode: "446",
        alpha3: "MAC"
    },
    "MG": {
        name: "Madagascar",
        countryCode: "450",
        alpha3: "MDG"
    },
    "MW": {
        name: "Malawi",
        countryCode: "454",
        alpha3: "MWI"
    },
    "MY": {
        name: "Malaysia",
        countryCode: "458",
        alpha3: "MYS"
    },
    "MV": {
        name: "Maldives",
        countryCode: "462",
        alpha3: "MDV"
    },
    "ML": {
        name: "Mali",
        countryCode: "466",
        alpha3: "MLI"
    },
    "MT": {
        name: "Malta",
        countryCode: "470",
        alpha3: "MLT"
    },
    "MH": {
        name: "Marshall Islands",
        countryCode: "584",
        alpha3: "MHL"
    },
    "MQ": {
        name: "Martinique",
        countryCode: "474",
        alpha3: "MTQ"
    },
    "MR": {
        name: "Mauritania",
        countryCode: "478",
        alpha3: "MRT"
    },
    "MU": {
        name: "Mauritius",
        countryCode: "480",
        alpha3: "MUS"
    },
    "YT": {
        name: "Mayotte",
        countryCode: "175",
        alpha3: "MYT"
    },
    "MX": {
        name: "Mexico",
        countryCode: "484",
        alpha3: "MEX"
    },
    "FM": {
        name: "Micronesia (Federated States of)",
        countryCode: "583",
        alpha3: "FSM"
    },
    "MD": {
        name: "Moldova, Republic of",
        countryCode: "498",
        alpha3: "MDA"
    },
    "MC": {
        name: "Monaco",
        countryCode: "492",
        alpha3: "MCO"
    },
    "MN": {
        name: "Mongolia",
        countryCode: "496",
        alpha3: "MNG"
    },
    "ME": {
        name: "Montenegro",
        countryCode: "499",
        alpha3: "MNE"
    },
    "MS": {
        name: "Montserrat",
        countryCode: "500",
        alpha3: "MSR"
    },
    "MA": {
        name: "Morocco",
        countryCode: "504",
        alpha3: "MAR"
    },
    "MZ": {
        name: "Mozambique",
        countryCode: "508",
        alpha3: "MOZ"
    },
    "MM": {
        name: "Myanmar",
        countryCode: "104",
        alpha3: "MMR"
    },
    "NA": {
        name: "Namibia",
        countryCode: "516",
        alpha3: "NAM"
    },
    "NR": {
        name: "Nauru",
        countryCode: "520",
        alpha3: "NRU"
    },
    "NP": {
        name: "Nepal",
        countryCode: "524",
        alpha3: "NPL"
    },
    "NL": {
        name: "Netherlands",
        countryCode: "528",
        alpha3: "NLD"
    },
    "NC": {
        name: "New Caledonia",
        countryCode: "540",
        alpha3: "NCL"
    },
    "NZ": {
        name: "New Zealand",
        countryCode: "554",
        alpha3: "NZL"
    },
    "NI": {
        name: "Nicaragua",
        countryCode: "558",
        alpha3: "NIC"
    },
    "NE": {
        name: "Niger",
        countryCode: "562",
        alpha3: "NER"
    },
    "NG": {
        name: "Nigeria",
        countryCode: "566",
        alpha3: "NGA"
    },
    "NU": {
        name: "Niue",
        countryCode: "570",
        alpha3: "NIU"
    },
    "NF": {
        name: "Norfolk Island",
        countryCode: "574",
        alpha3: "NFK"
    },
    "MK": {
        name: "North Macedonia",
        countryCode: "807",
        alpha3: "MKD"
    },
    "MP": {
        name: "Northern Mariana Islands",
        countryCode: "580",
        alpha3: "MNP"
    },
    "NO": {
        name: "Norway",
        countryCode: "578",
        alpha3: "NOR"
    },
    "OM": {
        name: "Oman",
        countryCode: "512",
        alpha3: "OMN"
    },
    "PK": {
        name: "Pakistan",
        countryCode: "586",
        alpha3: "PAK"
    },
    "PW": {
        name: "Palau",
        countryCode: "585",
        alpha3: "PLW"
    },
    "PS": {
        name: "Palestine, State of",
        countryCode: "275",
        alpha3: "PSE"
    },
    "PA": {
        name: "Panama",
        countryCode: "591",
        alpha3: "PAN"
    },
    "PG": {
        name: "Papua New Guinea",
        countryCode: "598",
        alpha3: "PNG"
    },
    "PY": {
        name: "Paraguay",
        countryCode: "600",
        alpha3: "PRY"
    },
    "PE": {
        name: "Peru",
        countryCode: "604",
        alpha3: "PER"
    },
    "PH": {
        name: "Philippines",
        countryCode: "608",
        alpha3: "PHL"
    },
    "PN": {
        name: "Pitcairn",
        countryCode: "612",
        alpha3: "PCN"
    },
    "PL": {
        name: "Poland",
        countryCode: "616",
        alpha3: "POL"
    },
    "PT": {
        name: "Portugal",
        countryCode: "620",
        alpha3: "PRT"
    },
    "PR": {
        name: "Puerto Rico",
        countryCode: "630",
        alpha3: "PRI"
    },
    "QA": {
        name: "Qatar",
        countryCode: "634",
        alpha3: "QAT"
    },
    "RE": {
        name: "Réunion",
        countryCode: "638",
        alpha3: "REU"
    },
    "RO": {
        name: "Romania",
        countryCode: "642",
        alpha3: "ROU"
    },
    "RU": {
        name: "Russian Federation",
        countryCode: "643",
        alpha3: "RUS"
    },
    "RW": {
        name: "Rwanda",
        countryCode: "646",
        alpha3: "RWA"
    },
    "BL": {
        name: "Saint Barthélemy",
        countryCode: "652",
        alpha3: "BLM"
    },
    "SH": {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        countryCode: "654",
        alpha3: "SHN"
    },
    "KN": {
        name: "Saint Kitts and Nevis",
        countryCode: "659",
        alpha3: "KNA"
    },
    "LC": {
        name: "Saint Lucia",
        countryCode: "662",
        alpha3: "LCA"
    },
    "MF": {
        name: "Saint Martin (French part)",
        countryCode: "663",
        alpha3: "MAF"
    },
    "PM": {
        name: "Saint Pierre and Miquelon",
        countryCode: "666",
        alpha3: "SPM"
    },
    "VC": {
        name: "Saint Vincent and the Grenadines",
        countryCode: "670",
        alpha3: "VCT"
    },
    "WS": {
        name: "Samoa",
        countryCode: "882",
        alpha3: "WSM"
    },
    "SM": {
        name: "San Marino",
        countryCode: "674",
        alpha3: "SMR"
    },
    "ST": {
        name: "Sao Tome and Principe",
        countryCode: "678",
        alpha3: "STP"
    },
    "SA": {
        name: "Saudi Arabia",
        countryCode: "682",
        alpha3: "SAU"
    },
    "SN": {
        name: "Senegal",
        countryCode: "686",
        alpha3: "SEN"
    },
    "RS": {
        name: "Serbia",
        countryCode: "688",
        alpha3: "SRB"
    },
    "SC": {
        name: "Seychelles",
        countryCode: "690",
        alpha3: "SYC"
    },
    "SL": {
        name: "Sierra Leone",
        countryCode: "694",
        alpha3: "SLE"
    },
    "SG": {
        name: "Singapore",
        countryCode: "702",
        alpha3: "SGP"
    },
    "SX": {
        name: "Sint Maarten (Dutch part)",
        countryCode: "534",
        alpha3: "SXM"
    },
    "SK": {
        name: "Slovakia",
        countryCode: "703",
        alpha3: "SVK"
    },
    "SI": {
        name: "Slovenia",
        countryCode: "705",
        alpha3: "SVN"
    },
    "SB": {
        name: "Solomon Islands",
        countryCode: "090",
        alpha3: "SLB"
    },
    "SO": {
        name: "Somalia",
        countryCode: "706",
        alpha3: "SOM"
    },
    "ZA": {
        name: "South Africa",
        countryCode: "710",
        alpha3: "ZAF"
    },
    "GS": {
        name: "South Georgia and the South Sandwich Islands",
        countryCode: "239",
        alpha3: "SGS"
    },
    "SS": {
        name: "South Sudan",
        countryCode: "728",
        alpha3: "SSD"
    },
    "ES": {
        name: "Spain",
        countryCode: "724",
        alpha3: "ESP"
    },
    "LK": {
        name: "Sri Lanka",
        countryCode: "144",
        alpha3: "LKA"
    },
    "SD": {
        name: "Sudan",
        countryCode: "729",
        alpha3: "SDN"
    },
    "SR": {
        name: "Suriname",
        countryCode: "740",
        alpha3: "SUR"
    },
    "SJ": {
        name: "Svalbard and Jan Mayen",
        countryCode: "744",
        alpha3: "SJM"
    },
    "SE": {
        name: "Sweden",
        countryCode: "752",
        alpha3: "SWE"
    },
    "CH": {
        name: "Switzerland",
        countryCode: "756",
        alpha3: "CHE"
    },
    "SY": {
        name: "Syrian Arab Republic",
        countryCode: "760",
        alpha3: "SYR"
    },
    "TW": {
        name: "Taiwan, Province of China",
        countryCode: "158",
        alpha3: "TWN"
    },
    "TJ": {
        name: "Tajikistan",
        countryCode: "762",
        alpha3: "TJK"
    },
    "TZ": {
        name: "Tanzania, United Republic of",
        countryCode: "834",
        alpha3: "TZA"
    },
    "TH": {
        name: "Thailand",
        countryCode: "764",
        alpha3: "THA"
    },
    "TL": {
        name: "Timor-Leste",
        countryCode: "626",
        alpha3: "TLS"
    },
    "TG": {
        name: "Togo",
        countryCode: "768",
        alpha3: "TGO"
    },
    "TK": {
        name: "Tokelau",
        countryCode: "772",
        alpha3: "TKL"
    },
    "TO": {
        name: "Tonga",
        countryCode: "776",
        alpha3: "TON"
    },
    "TT": {
        name: "Trinidad and Tobago",
        countryCode: "780",
        alpha3: "TTO"
    },
    "TN": {
        name: "Tunisia",
        countryCode: "788",
        alpha3: "TUN"
    },
    "TR": {
        name: "Turkey",
        countryCode: "792",
        alpha3: "TUR"
    },
    "TM": {
        name: "Turkmenistan",
        countryCode: "795",
        alpha3: "TKM"
    },
    "TC": {
        name: "Turks and Caicos Islands",
        countryCode: "796",
        alpha3: "TCA"
    },
    "TV": {
        name: "Tuvalu",
        countryCode: "798",
        alpha3: "TUV"
    },
    "UG": {
        name: "Uganda",
        countryCode: "800",
        alpha3: "UGA"
    },
    "UA": {
        name: "Ukraine",
        countryCode: "804",
        alpha3: "UKR"
    },
    "AE": {
        name: "United Arab Emirates",
        countryCode: "784",
        alpha3: "ARE"
    },
    "GB": {
        name: "United Kingdom of Great Britain and Northern Ireland",
        countryCode: "826",
        alpha3: "GBR"
    },
    "US": {
        name: "United States of America",
        countryCode: "840",
        alpha3: "USA"
    },
    "UM": {
        name: "United States Minor Outlying Islands",
        countryCode: "581",
        alpha3: "UMI"
    },
    "UY": {
        name: "Uruguay",
        countryCode: "858",
        alpha3: "URY"
    },
    "UZ": {
        name: "Uzbekistan",
        countryCode: "860",
        alpha3: "UZB"
    },
    "VU": {
        name: "Vanuatu",
        countryCode: "548",
        alpha3: "VUT"
    },
    "VE": {
        name: "Venezuela (Bolivarian Republic of)",
        countryCode: "862",
        alpha3: "VEN"
    },
    "VN": {
        name: "Viet Nam",
        countryCode: "704",
        alpha3: "VNM"
    },
    "VG": {
        name: "Virgin Islands (British)",
        countryCode: "092",
        alpha3: "VGB"
    },
    "VI": {
        name: "Virgin Islands (U.S.)",
        countryCode: "850",
        alpha3: "VIR"
    },
    "WF": {
        name: "Wallis and Futuna",
        countryCode: "876",
        alpha3: "WLF"
    },
    "EH": {
        name: "Western Sahara",
        countryCode: "732",
        alpha3: "ESH"
    },
    "YE": {
        name: "Yemen",
        countryCode: "887",
        alpha3: "YEM"
    },
    "ZM": {
        name: "Zambia",
        countryCode: "894",
        alpha3: "ZMB"
    },
    "ZW": {
        name: "Zimbabwe",
        countryCode: "716",
        alpha3: "ZWE"
    }
};