export class ZvtPosResources {
    public static intermediateStatusInformationMap: Record<number, string> = {
        0x01: "PT is waiting for amount-confirmation",
        0x02: "Please watch PIN-Pad",
        0x03: "Please watch PIN-Pad",
        0x04: "Not accepted",
        0x05: "PT is waiting for response from FEP PT is sending auto-reversal",
        0x06: "PT is sending post-bookings",
        0x07: "Card not admitted",
        0x08: "Card unknown / undefined",
        0x09: "Expired card",
        0x0a: "Insert card",
        0x0b: "Please remove card!",
        0x0c: "Card not readable",
        0x0d: "Processing error",
        0x0e: "Please wait...",
        0x0f: "PT is commencing an automatic end-of-day batch",
        0x10: "Invalid card",
        0x11: "Balance display",
        0x12: "System malfunction",
        0x13: "Payment not possible",
        0x14: "Credit not sufficient",
        0x15: "Incorrect PIN",
        0x16: "Limit not sufficient",
        0x17: "Please wait...",
        0x18: "PIN try limit exceeded",
        0x19: "Card-data incorrect",
        0x1a: "Service-mode",
        0x1b: "Approved. Please fill-up",
        0x1c: "Approved. Please take goods",
        0x1d: "Declined",
        0x26: "PT is waiting for input of the mobile number",
        0x27: "PT is waiting for repeat of mobile number",
        0x28: "Currency selection, please wait...",
        0x29: "Language selection, please wait...",
        0x2a: "For loading please insert card",
        0x2b: "Emergency transaction, please wait",
        0x2c: "Application selection, please wait",
        0x41: "Please watch PIN-Pad. Please remove card!",
        0x42: "Please watch PIN-Pad. Please remove card!",
        0x43: "Not accepted. Please remove card!",
        0x44: "PT is waiting for response from FEP Please remove card!",
        0x45: "PT is sending auto-reversal. Please remove card!",
        0x46: "PT is sending post-booking. Please remove card!",
        0x47: "Card not admitted. Please remove card!",
        0x48: "Card unknown / undefined. Please remove card!",
        0x49: "Expired card. Please remove card!",
        0x4b: "Please remove card!",
        0x4c: "Card not readable. Please remove card!",
        0x4d: "Processing error. Please remove card!",
        0x4e: "Please wait. Please remove card!",
        0x4f: "PT is commencing an automatic end-of-day batch. Please remove card!",
        0x50: "Invalid card. Please remove card!",
        0x51: "Balance display. Please remove card!",
        0x52: "System malfunction. Please remove card!",
        0x53: "Payment not possible. Please remove card!",
        0x54: "Credit not sufficient. Please remove card!",
        0x55: "Incorrect PIN. Please remove card!",
        0x56: "Limit not sufficient. Please remove card!",
        0x57: "Please wait... Please remove card!",
        0x58: "PIN try limit exceeded. Please remove card!",
        0x59: "Card-data incorrect. Please remove card!",
        0x5a: "Service-mode. Please remove card!",
        0x5b: "Approved. Please fill-up. Please remove card!",
        0x5c: "Approved. Please take goods. Please remove card!",
        0x5d: "Declined. Please remove card!",
        0x66: "PT is waiting for input of the mobil-number. Please remove card!",
        0x67: "PT is waiting for repeat of the mobil- number. Please remove card!",
        0x68: "PT has detected customer card insertion",
        0x69: "Please select DCC",
        0xc7: "PT is waiting for input of the mileage",
        0xc8: "PT is waiting for cashier",
        0xc9: "PT is commencing an automatic diagnosis",
        0xca: "PT is commencing an automatic initialisation",
        0xcb: "Merchant-journal full",
        0xcc: "Debit advice not possible, PIN required",
        0xd2: "Connecting dial-up",
        0xd3: "Dial-up connection made",
        0xe0: "PT is waiting for application-selection",
        0xe1: "PT is waiting for language-selection",
        0xe2: "PT requests to use the cleaning card",
        0xf1: "Offline",
        0xf2: "Online",
        0xf3: "Offline transaction"
    }

    public static errorMessagesInformationMap: Record<number, string> = {
        0x64: "Card not readable (LRC-/parity-error)",
        0x65: "Card-data not present (neither track-data nor chip found)",
        0x66: "Processing-error (also for problems with card-reader mechanism)",
        0x67: "Function not permitted",
        0x68: "Function not permitted",
        0x6a: "Turnover-file full",
        0x6b: "Function deactivated (PT not registered)",
        0x6c: "Abort via timeout or abort-key",
        0x6e: "Card in blocked-list (response to command 06 E4)",
        0x6f: "Wrong currency",
        0x71: "Credit not sufficient (chip-card)",
        0x72: "Chip error",
        0x73: "Card-data incorrect",
        0x77: "End-of-day batch not possible",
        0x78: "Card expired",
        0x79: "Card not yet valid",
        0x7a: "Card unknown",
        0x7b: "Fallback to magnetic stripe for girocard not possible",
        0x7c: "Fallback to magnetic stripe not possible (used for non girocard cards)",
        0x7d: "Communication error (communication module does not answer or is not present)",
        0x7e: "Fallback to magnetic stripe not possible, debit advice possible (used only for giro- card)",
        0x83: "Function not possible",
        0x85: "Key missing",
        0x89: "PIN-pad defective",
        0x9a: "ZVT protocol error",
        0x9b: "Error from dial-up/communication fault",
        0x9c: "Please wait",
        0xa0: "Receiver not ready",
        0xa1: "Remote station does not respond",
        0xa3: "No connection",
        0xa4: "Submission of Geldkarte not possible",
        0xb1: "Memory full",
        0xb2: "Merchant-journal full",
        0xb4: "Already reversed",
        0xb5: "Reversal not possible",
        0xb7: "Pre-authorisation incorrect (amount too high) or amount wrong",
        0xb8: "Error pre-authorisation",
        0xbf: "Voltage supply to low (external power supply)",
        0xc0: "Card locking mechanism defective",
        0xc1: "Merchant-card locked",
        0xc2: "Diagnosis required",
        0xc3: "Maximum amount exceeded",
        0xc4: "Card-profile invalid. New card-profiles must be loaded.",
        0xc5: "Payment method not supported",
        0xc6: "Currency not applicable",
        0xc8: "Amount too small",
        0xc9: "Max. transaction-amount too small",
        0xcb: "Function only allowed in EURO",
        0xcc: "Printer not ready",
        0xcd: "Cashback not possible",
        0xd2: "Function not permitted for service-cards/bank-customer-cards",
        0xdc: "Card inserted",
        0xdd: "Error during card-eject",
        0xde: "Error during card-insertion",
        0xe0: "Remote-maintenance activated",
        0xe2: "Card-reader does not answer / card-reader defective",
        0xe3: "Shutter closed",
        0xe4: "Terminal activation required",
        0xe7: "Min. one goods-group not found",
        0xe8: "No goods-groups-table loaded",
        0xe9: "Restriction-code not permitted",
        0xea: "Card-code not permitted",
        0xeb: "Function not executable (PIN-algorithm unknown)",
        0xec: "PIN-processing not possible",
        0xed: "PIN-pad defective",
        0xf0: "Open end-of-day batch present",
        0xf1: "Ec-cash/Maestro offline error",
        0xf5: "OPT-error",
        0xf6: "OPT-data not available",
        0xfa: "Error transmitting offline-transactions",
        0xfb: "Turnover data-set defective",
        0xfc: "Necessary device not present or defective",
        0xfd: "Baudrate not supported",
        0xfe: "Register unknown",
        0xff: "System error"
    }
}