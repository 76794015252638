import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { OpenDialogsService } from "../../services";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-add-new-user-dialog.component',
    templateUrl: './add-new-user-dialog.component.html',
    styleUrls: ['./add-new-user-dialog.component.scss']
})
export class AddNewUserDialogComponent implements OnInit {

    addNewUserDialogForm!: FormGroup;

    constructor(public dialogRef: MatDialogRef<OpenDialogsService>, private formBuilder: FormBuilder, private translate: TranslateService) { }

     ngOnInit(): void {
        this.addNewUserDialogForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            repeat_email: ['', [Validators.required, Validators.email]],
            phone: ['',[Validators.required]],
            repeat_phone: ['',[Validators.required]]
        }, { validator: [this.emailRepeatValidator, this.phoneRepeatValidator] });
    }

    submit() {
        if (this.addNewUserDialogForm.valid) {
            const formData = this.addNewUserDialogForm.value;
            const filteredFormData: Record<string, any> = {};
            for (const key in formData) {
                if (formData[key] !== '' && formData[key] !== null) filteredFormData[key] = formData[key];
            }
            this.dialogRef.close(filteredFormData);
        } else {
             this.addNewUserDialogForm.markAllAsTouched();
        }
    }

    isFormInvalid(): boolean {
        return this.addNewUserDialogForm.invalid;
    }

    emailRepeatValidator = (control: AbstractControl): ValidationErrors | null => {
        const email = control.get('email')?.value;
        const repeatEmail = control.get('repeat_email')?.value;
        if (email && repeatEmail && email !== repeatEmail) {
            const errors = control.get('repeat_email')?.errors || {};
            control.get('repeat_email')?.setErrors({ ...errors, emailMismatch: true });
            return { emailMismatch: true };
        }
        return null;
    }

    phoneRepeatValidator = (control: AbstractControl): ValidationErrors | null => {
        const phone = control.get('phone')?.value;
        const repeatPhone= control.get('repeat_phone')?.value;
        if (phone && !repeatPhone) {
            const errors = control.get('phone')?.errors || {};
            control.get('phone')?.setErrors({ ...errors, phoneMismatch1: true });
            control.get('repeat_phone')?.setValidators([Validators.required]);
            return { phoneMismatch1: true };
        }
        else if (!phone && repeatPhone) {
            const errors = control.get('repeat_phone')?.errors || {};
            control.get('repeat_phone')?.setErrors({ ...errors, phoneMismatch2: true });
            control.get('phone')?.setValidators([Validators.required]);
            return { phoneMismatch2: true };
        }
        else if (phone && repeatPhone && phone !== repeatPhone) {
          const errors = control.get('repeat_phone')?.errors || {};
          control.get('repeat_phone')?.setErrors({ ...errors, phoneMismatch: true });
          const errors2 = control.get('phone')?.errors || {};
          control.get('phone')?.setErrors({ ...errors2, phoneMismatch: true });
          return { phoneMismatch: true };
        }
        return null;
    }

    // emailRepeatValidator = (): ValidatorFn => {
    //     return this.generalRepeatValidator('email','repeat_email','emailMismatch');
    // }

    // phoneRepeatValidator = (): ValidatorFn => {
    //     return this.generalRepeatValidator('phone','repeat_phone','phoneMismatch');
    // }

    onPhoneInputChange() {
        this.phone.addValidators([Validators.required]);
        this.repeatPhone.addValidators([Validators.required]);
        this.email.removeValidators([Validators.required]);
        this.repeatEmail.removeValidators([Validators.required]);
        this.email.updateValueAndValidity();
        this.repeatEmail.updateValueAndValidity();

        if (this.email.valid && this.repeatEmail.valid && this.email.value !== '' && this.repeatEmail.value !== '') {
            this.phone.removeValidators([Validators.required]);
            this.repeatPhone.removeValidators([Validators.required]);
        }

        this.phone.updateValueAndValidity();
        this.repeatPhone.updateValueAndValidity();

        if (this.phone.valid && this.repeatPhone.valid) {
            if (this.email.value === '' && this.repeatEmail.value !== '') {
                this.email.addValidators([Validators.required]);
                this.email.updateValueAndValidity();
            } else if (this.email.value !== '' && this.repeatEmail.value === '') {
                this.repeatEmail.addValidators([Validators.required]);
                this.repeatEmail.updateValueAndValidity();
            }
        }
    }

    onEmailInputChange() {
        this.email.addValidators([Validators.required]);
        this.repeatEmail.addValidators([Validators.required]);
        this.phone.removeValidators([Validators.required]);
        this.repeatPhone.removeValidators([Validators.required]);
        this.phone.updateValueAndValidity();
        this.repeatPhone.updateValueAndValidity();
        if (this.phone.valid && this.repeatPhone.valid && this.phone.value !== '' && this.repeatPhone.value !== '') {
            if (this.repeatEmail.value === '')
                this.email.removeValidators([Validators.required]);
            if (this.email.value === '')
                this.repeatEmail.removeValidators([Validators.required]);
        }
        this.email.updateValueAndValidity();
        this.repeatEmail.updateValueAndValidity();
    }

    get phone() {
        return this.addNewUserDialogForm?.controls.phone;
    }

    get repeatPhone() {
        return this.addNewUserDialogForm?.controls.repeat_phone;
    }

    get email() {
        return this.addNewUserDialogForm?.controls.email;
    }

    get repeatEmail() {
        return this.addNewUserDialogForm?.controls.repeat_email;
    }

}
