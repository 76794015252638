import {Component, Inject, Injectable, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { lastValueFrom } from 'rxjs';
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogProgressBarComponent, TilbyDialogTabsComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SalesToBeInvoiced } from './models/sales_to_be_invoiced.model';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { TranslateModule } from '@ngx-translate/core';
import { restManager } from 'app/ajs-upgraded-providers';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonDirective } from 'src/app/directives/skeleton.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export type TilbyDialogData = {sale?: SalesToBeInvoiced};


@Component({
  selector: 'app-tilby-dialog',
  templateUrl: './history-summary-invoice-viewing-ui-dialog.component.html',
  styleUrls: ['./history-summary-invoice-viewing-ui-dialog.component.scss'],
  imports: [CommonModule, TilbyDialogTabsComponent, TilbyDialogProgressBarComponent, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent, TilbyDialogToolbarComponent, TilbyCurrencyPipe, FormsModule, ReactiveFormsModule, MatDialogModule, MatTableModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, TranslateModule, NgxSkeletonLoaderModule, SkeletonDirective, MatProgressSpinnerModule],
  standalone: true,
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }]
})
export class HistorySummaryInvoiceViewingUiDialogComponent {
    protected readonly data: TilbyDialogData= inject(MAT_DIALOG_DATA);
    private readonly matDialogRef= inject(MatDialogRef);
    dataSource: SalesToBeInvoiced[] = [];
    isLoading: boolean = this.data.sale ? false : true;
    displayedColumns = [
        'name',
        'invoice_type',
        'documents_to_be_invoiced',
        'total_to_be_invoiced',
        'star',
    ];

    range = new FormGroup({
        closed_at_since: new FormControl<Date>(this.fromDateToUTC(new Date(new Date().getFullYear(), 0, 1)), {validators: [Validators.required]}),
        closed_at_max: new FormControl<Date>(new Date(), {validators: [Validators.required]}),
    });

    constructor(
        @Inject(restManager) private restManagerService: any
    ){
        if(this.data.sale){
            this.dataSource.push(this.data.sale!);
        } else {
            this.restManagerService.getList(`analytics/sales/to_be_invoiced?closed_at_max=${new Date(new Date().setHours(24, 59, 59, 999)).toISOString()}&closed_at_since=${this.range.value.closed_at_since?.toISOString()}`)
            .then((data: any) => {
                this.dataSource = data.results;
                this.isLoading = false;
            })
            .catch((error: any) => {
                this.isLoading = false;
            })
        }
    }

    addEvent() {
        (this.range.value.closed_at_since?.getFullYear()! < this.range.value.closed_at_max?.getFullYear()!) && this.range.controls.closed_at_max.setErrors({matEndYearInvalid: true})
        if(this.range.valid){
            this.isLoading = true;
            this.restManagerService.getList(`analytics/sales/to_be_invoiced?closed_at_max=${this.getISOStringStartEndDates().closed_at_max}&closed_at_since=${this.getISOStringStartEndDates().closed_at_since}`)
            .then((data: any) => {
                this.dataSource = data.results;
                this.isLoading = false;
            })
            .catch((error: any) => {
                this.isLoading = false;
            })
        }
    }

    onClose(){
        !this.getISOStringStartEndDates().closed_at_since && this.range.controls.closed_at_since.setErrors({matStartDateInvalid: true})
        !this.getISOStringStartEndDates().closed_at_max && this.range.controls.closed_at_max.setErrors({matEndDateInvalid: true})
    }

    getISOStringStartEndDates(){
        return {
            closed_at_since: this.range.value.closed_at_since && this.generateISOString(this.range.value.closed_at_since!),
            closed_at_max: this.range.value.closed_at_max && new Date(new Date(this.range.value.closed_at_max!).setHours(24,59,59)).toISOString()
        }
    }

    generateISOString(insertDate: Date) {
        return insertDate.getFullYear() + '-' + this.addZero(insertDate.getMonth() + 1) + '-' + this.addZero(insertDate.getDate()) + 'T00:00:00.000Z';
    }

    addZero(num: number) {
        if (num < 10) {
            return '0' + num;
        }
        return num;
    }

    fromDateToUTC(date: Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    protected confirm(sale: SalesToBeInvoiced) {
        this.matDialogRef.close(sale);
    }
}

@Injectable({
    providedIn: 'root'
})
export class HistorySummaryInvoiceViewingUiService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    data!:TilbyDialogData;
    dataSource: SalesToBeInvoiced[] = [];

    public openDialog(salesToBeInvoicedCustomer?: SalesToBeInvoiced): Promise<SalesToBeInvoiced | undefined> {
        this.data = {sale: salesToBeInvoicedCustomer};
        const config:NonNullableConfigData<TilbyDialogData>={...this.switchMobileDesktopDimensions(), disableClose:true, data: this.data };
        return lastValueFrom(this.dialogRef.open(HistorySummaryInvoiceViewingUiDialogComponent,config).afterClosed());
    }
}
