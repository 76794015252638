import { Injectable, Injector, inject } from "@angular/core";
import { EntityHook } from "../entity.model";
import { Sales } from "tilby-models";

import { 
    EntityManagerService
} from "src/app/core";

import {
    SaleTransactionUtilsService
} from "src/app/features/cashregister";

@Injectable({
    providedIn: 'root'
})
export class SalesEntityHook implements EntityHook {
    private readonly injector = inject(Injector);

    public async createOfflineFirstAfterStorage(entityId: string | number, entityData: Sales) {
        const entityManagerService = this.injector.get(EntityManagerService);
        const saleTransactionUtilsService = this.injector.get(SaleTransactionUtilsService);

        if (!saleTransactionUtilsService.areTransactionsEnabled()) {
            return;
        }

        const saleTransaction = saleTransactionUtilsService.newSaleToTransaction(entityData);

        await entityManagerService.saleTransactions.saveOneOffline(saleTransaction, { dirty: false });
    }
}