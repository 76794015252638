import * as angular from 'angular';

angular.module('settings').directive('fileDropZone', function(){
        var config = {
            restrict: 'A',
            template:'<label class="drop-zone">'+
                     '<input type="file" multiple accept="jpg" onclick="this.value=null" />'+
                     '<div ng-transclude class="drop-zone-text" flex layout="column" layout-align="center center"></div>'+       // <= transcluded stuff
                     '</label>',
            transclude:true,
            replace: true,
            scope: {
                file: '='
            },
            link: function(scope, element, attributes, ngModel){
                var upload = element[0].querySelector('input');    
                    upload.addEventListener('dragover', uploadDragOver, false);
                    upload.addEventListener('drop', uploadFileSelect, false);
                    upload.addEventListener('change', uploadFileSelect, false);                
                    config.scope = scope;                
                    config.model = ngModel; 
            }
        };
        return config;

        // Helper functions
        function uploadDragOver(e) { e.stopPropagation(); e.preventDefault(); e.dataTransfer.dropEffect = 'copy'; }
        function uploadFileSelect(e) {
            e.stopPropagation();
            e.preventDefault();
            var files = e.dataTransfer ? e.dataTransfer.files: e.target.files;
            if(files.length > 0){
                var file = files[0];

                var reader = new FileReader();
                reader.onload = (function(file) {
                    return function(e) { 

                        var image = new Image();
                        image.src = e.target.result;

                        image.onload = function() {
                            var data={
                                data:e.target.result,
                                dataSize: e.target.result.length,
                                width: this.width,
                                height: this.height
                            };
                            for(var p in file){ data[p] = file[p]; }

                            config.scope.$apply(function(){ 
                                config.scope.file = {
                                    data: data.data,
                                    size:{
                                        w: data.width,
                                        h: data.height
                                    },
                                    result: null,
                                    edited_logo: null,
                                    final_logo: null,
                                    final_brightness: "1",
                                    final_width: "400",
                                    final_original: null
                                }; 
                            });
                            angular.copy(file);
                        };
                    };
                })(file);
                reader.readAsDataURL(file);
            }
        }
    }
);