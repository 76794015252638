<div class="label-panel">
    <mat-progress-bar mode="query" *ngIf="isProcessing"></mat-progress-bar>
    <div class="label-panel-content tw-flex tw-flex-row">
        <!-- left -->
        <section class="label-panel-left-column tw-flex tw-flex-col left-section">
            <div class="wrapper-sticky">
                <div class="tw-flex tw-justify-end">
                    <button mat-icon-button aria-label="Filter label panel" (click)="filterLabelPanel()" [color]="filterToggle ? 'primary' : ''" *ngIf="labelPanelList.length > 0">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Add new label panel" (click)="addNewLabelPanel(labelPanelForm.form.dirty)" [disabled]="isNewEntryInProgress">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-form-field class="tw-flex tw-mt-1.5" appearance="fill" *ngIf="filterToggle">
                    <mat-label>{{'SETTINGS.LABEL_PANEL.SEARCH'|translate}}</mat-label>
                    <mat-icon matIconPrefix>search</mat-icon>
                    <input matInput name="search" [(ngModel)]="filterList">
                    <button *ngIf="filterList" matSuffix mat-icon-button aria-label="Clear" (click)="filterList=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="label-panel-list-container" *ngIf="labelPanelList">
                <mat-nav-list dense>
                    <mat-list-item *ngFor="let labelPanel of labelPanelList | matchesLabelPanel:filterList; let m=index;"
                        (click)="selectLabelPanel(labelPanel, labelPanelForm.form.dirty)"
                        [activated]="isSelected(labelPanel)">
                        <div matListItemTitle>{{labelPanel.name}}</div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </section>
        <!-- right -->
        <section class="tw-flex tw-flex-col right-section" [ngClass]="{'tw-flex-wrap tw-content-center tw-justify-center' : !selectedLabelPanel}">
            <div class="wrapper-sticky wrapper-icon-border tw-flex tw-flex-auto tw-flex-row tw-justify-end" *ngIf="selectedLabelPanel">
                <button mat-icon-button aria-label="Save button" (click)="saveLabelPanel()" [disabled]="labelPanelForm.form.invalid">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete button" (click)="deleteLabelPanel()" [disabled]="!selectedLabelPanel">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <form autocomplete="off" #labelPanelForm="ngForm" class="" [ngClass]="{'tw-flex tw-flex-auto tw-flex-col tw-p-4' : selectedLabelPanel}">
                <input #importLabelPanelId type="file" accept=".tilbylabel" (change)="importLabelPanel($event, labelPanelForm.form.dirty)" style="display: none;" />
                <div class="tw-flex tw-flex-col" *ngIf="selectedLabelPanel">
                    <div class="tw-flex tw-flex-auto tw-flex-col md:tw-flex-row wrapper-preview md:wrapper-sticky">
                        <div class="tw-flex tw-flex-auto tw-flex-col md:tw-w-2/5">
                            <mat-form-field class="tw-flex-auto" appearance="fill">
                                <mat-label>{{'SETTINGS.LABEL_PANEL.NAME'|translate}}</mat-label>
                                <mat-icon matIconPrefix>style</mat-icon>
                                <input matInput name="name" [(ngModel)]="selectedLabelPanel.name" required>
                                <mat-error *ngIf="labelPanelForm.form.controls.name?.invalid">{{'NG_MESSAGES.REQUIRED_FIELD'|translate}}</mat-error>
                            </mat-form-field>
                            <div class="tw-flex tw-flex-auto tw-flex-col sm:tw-flex-row">
                                <mat-form-field class="tw-flex-auto" appearance="fill">
                                    <mat-label>{{'SETTINGS.LABEL_PANEL.HEIGHT'|translate}}</mat-label>
                                    <mat-icon matIconPrefix>height</mat-icon>
                                    <input matInput type="number" min="100" name="dataHeight" [(ngModel)]="selectedLabelPanel.data.height" (change)="generatePreview()">
                                </mat-form-field>
                                <mat-form-field class="tw-flex-auto sm:tw-ml-2" appearance="fill">
                                    <mat-label>{{'SETTINGS.LABEL_PANEL.WIDTH'|translate}}</mat-label>
                                    <mat-icon matIconPrefix style="transform: rotate(90deg);">height</mat-icon>
                                    <input matInput type="number" min="100" name="dataWidth" [(ngModel)]="selectedLabelPanel.data.width" (change)="generatePreview()">
                                </mat-form-field>
                            </div>
                            <div class="tw-flex tw-flex-auto tw-flex-col sm:tw-flex-row">
                                <mat-form-field class="tw-flex-auto" appearance="fill">
                                    <mat-label>{{'SETTINGS.LABEL_PANEL.LABELS_PER_ROW'|translate}}</mat-label>
                                    <mat-icon matIconPrefix style="transform: rotate(90deg);">view_column</mat-icon>
                                    <mat-select name="per_row" [(ngModel)]="selectedLabelPanel.data.per_row">
                                        <mat-option *ngFor="let num of pagination" [value]="num">
                                            {{ num }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="tw-flex-auto sm:tw-ml-2" appearance="fill">
                                    <mat-label>{{'SETTINGS.LABEL_PANEL.LABELS_PER_COLUMN'|translate}}</mat-label>
                                    <mat-icon matIconPrefix>view_column</mat-icon>
                                    <mat-select name="per_column" [(ngModel)]="selectedLabelPanel.data.per_column">
                                        <mat-option *ngFor="let num of pagination" [value]="num">
                                            {{ num }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="tw-flex tw-flex-auto tw-flex-row tw-flex-wrap tw-content-center tw-justify-center label-preview">
                            <div id="editorPreview" #editorPreview></div>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="tw-flex tw-flex-auto tw-flex-col wrapper-item">
                        <mat-card class="tw-flex tw-flex-auto tw-basis-full tw-flex-col item" *ngFor="let item of selectedLabelPanel.data.content; let i=index;">
                            <mat-card-content>
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" class="options">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="deleteItem(i)">
                                        <mat-icon>delete</mat-icon>
                                        <span>{{'SETTINGS.LABEL_PANEL.MENU.DELETE'|translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="duplicateItem(item, i)">
                                        <mat-icon>content_copy</mat-icon>
                                        <span>{{'SETTINGS.LABEL_PANEL.MENU.DUPLICATE'|translate}}</span>
                                    </button>
                                </mat-menu>

                                <div class="tw-flex tw-flex-auto tw-basis-full tw-flex-col lg:tw-flex-row" [ngSwitch]="item.type">
                                    <!-- when type == 'text' -->
                                    <div class="tw-flex tw-flex-auto tw-basis-full tw-w-full lg:tw-w-3/4 tw-flex-row tw-flex-wrap card-left" *ngSwitchCase="'text'">
                                        <div class="tw-flex tw-flex-auto sm:tw-w-1/2 tw-flex-col">
                                            <mat-form-field class="tw-flex-auto" appearance="fill">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.TYPE'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>title</mat-icon>
                                                <mat-select [name]="'type'+i" [(ngModel)]="item.type" (selectionChange)="changeType(item)">
                                                    <mat-option *ngFor="let el of types" [value]="el.value">{{el.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-auto" appearance="fill" *ngIf="item.type === 'text'">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.CONTENT'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>article</mat-icon>
                                                <mat-select [name]="'contentype'+i" [(ngModel)]="item.content.type" (selectionChange)="updateStyle(item)">
                                                    <mat-option *ngFor="let el of contents" [value]="el.value">{{el.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-auto" appearance="fill" *ngIf="item.content.type === 'promotion_to_date'">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.PREFIX'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>emergency</mat-icon>
                                                <input matInput [name]="'prefix'+i" [(ngModel)]="item.content.prefix" (selectionChange)="updateStyle(item)">
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-auto" appearance="fill" *ngIf="item.type === 'text'">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.FONT_FAMILY'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>text_format</mat-icon>
                                                <mat-select [name]="'fontFamily'+i" [(ngModel)]="item.style.fontFamily" (selectionChange)="updateStyle(item)">
                                                    <mat-option *ngFor="let el of fontFamily" [value]="el">{{el}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-auto" appearance="fill" *ngIf="item.type === 'text'">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.FONT_SIZE'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>format_size</mat-icon>
                                                <mat-select [name]="'fontSize'+i" [(ngModel)]="item.style.fontSize" (selectionChange)="updateStyle(item)">
                                                    <mat-option *ngFor="let el of fontSize" [value]="el">{{el}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>


                                        </div>
                                        <div class="tw-flex tw-flex-auto sm:tw-w-1/2 tw-flex-col" *ngIf="item.type === 'text'">
                                            <mat-form-field class="tw-flex-auto sm:tw-ml-2" appearance="fill">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.TEXT_DECORATION'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>format_underlined</mat-icon>
                                                <mat-select [name]="'textDecoration'+i" [(ngModel)]="item.style.textDecoration" (selectionChange)="updateStyle(item)">
                                                    <mat-option *ngFor="let el of textDecoration" [value]="el.value">{{el.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field class="tw-flex-auto sm:tw-ml-2" appearance="fill">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.TEXT_DECORATION'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>palette</mat-icon>
                                                <mat-select [name]="'fill'+i" [(ngModel)]="item.style.fill" (selectionChange)="updateStyle(item)">
                                                    <mat-option *ngFor="let el of colors" [value]="el.value">
                                                        <mat-icon matIconPrefix [ngStyle]="{color:el.value}">stop</mat-icon>
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div class="tw-flex tw-flex-auto tw-basis-full tw-flex-col lg:tw-flex-row lg:tw-justify-center text-style-bar sm:tw-ml-2">
                                                <div class="tw-flex tw-basis-full xs:tw-basis-1/2 tw-flex-col tw-items-center tw-justify-center">
                                                    <div class="text-group-button">{{'SETTINGS.LABEL_PANEL.TEXT_ANCHOR'|translate}}</div>
                                                    <div class="tw-flex tw-flex-row tw-justify-center tw-content-center group-button">
                                                        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="align-button-toggle-group" [name]="'anchor'+i" [(ngModel)]="item.params['text-anchor']">
                                                            <mat-button-toggle *ngFor="let anchor of textAnchor" [value]="anchor.value" aria-label="Text Anchor {{anchor.value}}" (change)="changeTextAnchor(item, anchor)">
                                                                <mat-icon *ngIf="anchor.value === 'start'">format_align_left</mat-icon>
                                                                <mat-icon *ngIf="anchor.value === 'middle'">format_align_center</mat-icon>
                                                                <mat-icon *ngIf="anchor.value === 'end'">format_align_right</mat-icon>
                                                            </mat-button-toggle>
                                                        </mat-button-toggle-group>
                                                    </div>
                                                </div>
                                                <div class="tw-flex tw-basis-full xs:tw-basis-1/2 tw-flex-col tw-items-center tw-justify-center">
                                                    <div class="text-group-button">{{'SETTINGS.LABEL_PANEL.FONT_STYLE'|translate}}</div>
                                                    <div class="tw-flex tw-flex-row tw-justify-center tw-content-center group-button">
                                                        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="font-button-toggle-group" [name]="'text'+i" [(ngModel)]="item.style.multiFont" multiple>
                                                            <mat-button-toggle value="bold" aria-label="Font Weight" (change)="changeFontWeight(item)">
                                                                <mat-icon>format_bold</mat-icon>
                                                            </mat-button-toggle>
                                                            <mat-button-toggle value="italic" aria-label="Font Style" (change)="changeFontStyle(item)">
                                                                <mat-icon>format_italic</mat-icon>
                                                            </mat-button-toggle>
                                                        </mat-button-toggle-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-form-field class="tw-flex tw-flex-auto tw-basis-full" appearance="fill" *ngIf="item.type === 'text'">
                                            <mat-label>{{'SETTINGS.LABEL_PANEL.ADDITIONAL_STYLE'|translate}}</mat-label>
                                            <mat-icon matIconPrefix>queue</mat-icon>
                                            <textarea matInput [name]="'extraStyle'+i" [(ngModel)]="item.params.extraStyle" (change)="updateStyle(item)"></textarea>
                                        </mat-form-field>
                                    </div>

                                    <!-- when type == 'svg' -->
                                    <div class="tw-flex tw-flex-auto tw-basis-full tw-w-full lg:tw-w-3/4 tw-flex-row tw-flex-wrap card-left" *ngSwitchCase="'svg'">
                                        <div class="tw-flex tw-flex-col tw-flex-auto tw-basis-full">
                                            <mat-form-field class="" appearance="fill">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.TYPE'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>title</mat-icon>
                                                <mat-select [name]="'type'+i" [(ngModel)]="item.type" (selectionChange)="changeType(item)">
                                                    <mat-option *ngFor="let el of types" [value]="el.value">{{el.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div class="tw-flex tw-flex-row">
                                                <mat-form-field class="tw-flex-auto tw-basis-1/2" appearance="fill">
                                                    <mat-label>{{'SETTINGS.LABEL_PANEL.HEIGHT'|translate}}</mat-label>
                                                    <mat-icon matIconPrefix>height</mat-icon>
                                                    <input matInput type="number" min="10" [name]="'height'+i" [(ngModel)]="item.content.options.height" (change)="updateStyle(item)">
                                                </mat-form-field>
                                                <mat-form-field class="tw-flex-auto sm:tw-ml-2 tw-basis-1/2" appearance="fill">
                                                    <mat-label>{{'SETTINGS.LABEL_PANEL.WIDTH'|translate}}</mat-label>
                                                    <mat-icon matIconPrefix style="transform: rotate(90deg);">height</mat-icon>
                                                    <input matInput type="number" min="0.7" step=".01" [name]="'width'+i" [(ngModel)]="item.content.options.width" (change)="updateStyle(item)">
                                                </mat-form-field>
                                            </div>
                                            <mat-form-field appearance="fill">
                                                <mat-label>{{'SETTINGS.LABEL_PANEL.MARGIN'|translate}}</mat-label>
                                                <mat-icon matIconPrefix>fit_screen</mat-icon>
                                                <input matInput type="number" [name]="'margin'+i" [(ngModel)]="item.content.options.margin" (change)="updateStyle(item)">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="tw-flex tw-flex-auto tw-basis-full tw-w-full lg:tw-w-1/4 tw-flex-col tw-justify-center card-right">
                                        <div class="tw-flex-auto text-arrow">{{'SETTINGS.LABEL_PANEL.POSITIONING'|translate}}</div>
                                        <div class="tw-flex tw-flex-auto tw-flex-col wrapper-box-arrow">
                                            <div class="tw-flex tw-flex-auto tw-flex-row tw-justify-center tw-items-center">
                                                <mat-form-field class="tw-flex-auto lg:tw-hidden" appearance="fill" style="max-width: 60px;">
                                                    <mat-label>{{'SETTINGS.LABEL_PANEL.X'|translate}}</mat-label>
                                                    <input matInput [name]="'x'+i" [(ngModel)]="item.params.x" (change)="updateStyle(item)">
                                                </mat-form-field>

                                                <button mat-mini-fab class="button-arrow" aria-label="Button decrease Y" (click)="decreaseY(item)">
                                                    <mat-icon class="icon-arrow icon-arrow-180Deg">arrow_drop_down_circle</mat-icon>
                                                </button>
                                                <mat-form-field class="tw-flex-auto lg:tw-hidden" appearance="fill" style="max-width: 60px;">
                                                    <mat-label>{{'SETTINGS.LABEL_PANEL.Y'|translate}}</mat-label>
                                                    <input matInput [name]="'y'+i" [(ngModel)]="item.params.y" (change)="updateStyle(item)">
                                                </mat-form-field>
                                            </div>
                                            <div class="tw-flex tw-flex-auto tw-flex-row tw-flex-wrap tw-items-center tw-justify-center lg:tw-mt-4">
                                                <button mat-mini-fab class="button-arrow" aria-label="Button decrease X" (click)="decreaseX(item)">
                                                    <mat-icon class="icon-arrow icon-arrow-90Deg">arrow_drop_down_circle</mat-icon>
                                                </button>
                                                <div class="tw-hidden lg:tw-flex tw-flex-col" style="max-width: 60px;">
                                                    <mat-form-field class="tw-flex-auto" appearance="fill">
                                                        <mat-label>{{'SETTINGS.LABEL_PANEL.X'|translate}}</mat-label>
                                                        <input matInput [name]="'x'+i" [(ngModel)]="item.params.x" (change)="updateStyle(item)">
                                                    </mat-form-field>
                                                    <mat-form-field class="tw-flex-auto" appearance="fill">
                                                        <mat-label>{{'SETTINGS.LABEL_PANEL.Y'|translate}}</mat-label>
                                                        <input matInput [name]="'y'+i" [(ngModel)]="item.params.y" (change)="updateStyle(item)">
                                                    </mat-form-field>
                                                </div>
                                                <button mat-mini-fab class="button-arrow lg:tw-hidden" aria-label="Button increase Y" (click)="increaseY(item)">
                                                    <mat-icon class="icon-arrow">arrow_drop_down_circle</mat-icon>
                                                </button>
                                                <button mat-mini-fab class="button-arrow" aria-label="Button increase X" (click)="increaseX(item)">
                                                    <mat-icon class="icon-arrow icon-arrow-270Deg">arrow_drop_down_circle</mat-icon>
                                                </button>
                                            </div>
                                            <div class="tw-hidden lg:tw-flex tw-flex-auto tw-flex-row tw-items-center tw-justify-center">
                                                <button mat-mini-fab class="button-arrow" aria-label="Button increase Y" (click)="increaseY(item)">
                                                    <mat-icon class="icon-arrow">arrow_drop_down_circle</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </form>
            <div *ngIf="!selectedLabelPanel" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh-113px)]">
                <mat-icon style="transform: scale(7);" class="tw-mb-20">
                    <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
                <div class="tw-pl-10 tw-pr-10 tw-text-center">{{'SETTINGS.LABEL_PANEL.NOT_SELECTED_MSG'|translate}}</div>
            </div>
            <!--div class="debugger model-document-preview tw-flex tw-flex-col">
                    <pre style="overflow: auto;">{{ labelPanelForm.value | json }}</pre>
                    <pre style="overflow: auto;">{{ selectedLabelPanel | json }}</pre>
                </div-->
        </section>
    </div>
</div>
