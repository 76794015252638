import { inject, Injectable } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { NonNullableConfigData } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import { DevLogger } from "../../shared/dev-logger";
import { OrderPrinterSelectDialogComponent } from "./order-printer-select-dialog.component";
import { InputItemDataMagicForm } from 'src/app/shared/model/model';
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, ListItemGeneric } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { lastValueFrom } from "rxjs";
import { Printers } from 'tilby-models';

type OrderPrinterSelectFormValue = { printers: undefined, setAsDefault: boolean };
export type OrderPrinterSelectForm = CustomFormGroup<CustomForm<OrderPrinterSelectFormValue>>;
export type OrderPrinterSelectFormDialogData = InputItemDataMagicForm<OrderPrinterSelectFormValue>;
export type OrderPrinterSelectParams = { printers: Printers[], defaultPrinterId?: number, enableSetAsDefault?: boolean };
export type OrderPrinterSelectDialogOutput = { setAsDefault: boolean, selectedPrinter?: Printers };

@Injectable({
    providedIn: 'root'
})
export class OrderPrinterSelectDialogService {
    private readonly openDialogsService = inject(OpenDialogsService);

    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<OrderPrinterSelectDialogComponent>, OrderPrinterSelectDialogOutput>
    private _form?: OrderPrinterSelectForm;

    private printers: Printers[] = [];
    private defaultPrinterId?: number;

    private log(...args: any[]) {
        DevLogger.log('ORDER_PRINTER_SELECT_DIALOG_STATE_SERVICE', ...args);
    }

    // OPEN DIALOG
    public async openDialog(config?: NonNullableConfigData<OrderPrinterSelectParams>) {
        //Destructoring in this properties
        ({ printers: this.printers = [], defaultPrinterId: this.defaultPrinterId } = config?.data || {});
        this.log('OPEN_DIALOG', config?.data);
        this._form = this.createForm(config);

        const configPortal: NonNullablePortalConfigData<OrderPrinterSelectFormDialogData, OrderPrinterSelectDialogComponent> = {
            data: {
                form: this._form!,
                component: OrderPrinterSelectDialogComponent,
                titleLabel: 'APPLICATION.ORDER_PRINTER.TITLE',
                hideConfirm: true,
            }
        };

        this.dialogRef = this.openDialogsService.openPortalDialogRef<OrderPrinterSelectDialogComponent, PortalConfigData<OrderPrinterSelectFormDialogData, OrderPrinterSelectDialogComponent>, OrderPrinterSelectDialogOutput>({
            ...this.openDialogsService.switchMobileDesktopDimensions(),
            ...configPortal
        });

        return lastValueFrom(this.dialogRef.afterClosed());
    }

    // FUNCTIONS IN OPEN DIALOG
    private createForm(config?: NonNullableConfigData<OrderPrinterSelectParams>) {
        const listItems: ListItemGeneric[] = this.printers.map(({ id, name }) => ({ name, id, type: 'generic', icon: 'print', ...(this.defaultPrinterId === id && { class: 'tw-bg-blue-100 tw-cursor-pointer' }), ...(this.defaultPrinterId !== id && { class: 'tw-cursor-pointer' }), click: () => id && this.selectedPrinter(id) }));

        return new CustomFormGroup<CustomForm<OrderPrinterSelectFormValue>>({
            printers: new CustomFormControl(undefined, {}, { ...new CustomFormControlProps(), inputType: 'listOfItems', listItems, class: 'order-printer-select-dialog-list' }),
            setAsDefault: new CustomFormControl(undefined, {}, { ...new CustomFormControlProps(), inputType: config?.data.enableSetAsDefault ? 'slideToggle' : 'hidden', loopTrackBy: (i: number, item: ListItemGeneric) => item.id || item.name || i, class: 'tw-w-full tw-px-4 tw-py-2', label: 'APPLICATION.ORDER_PRINTER.DEFAULT' })
        });
    }

    selectedPrinter(id: number) {
        if (this._form?.valid) {
            const selectedPrinter = this.printers.find(p => p.id === id);
            this.dialogRef?.close({ setAsDefault: this.setAsDefault, selectedPrinter });
        }
    }

    get setAsDefault() {
        return this._form!.value.setAsDefault;
    }
}
