import { Injectable } from '@angular/core';
import { StorageManagerService } from 'src/app/core';

const entityName = 'shop_session';

@Injectable({
	providedIn: 'root'
})
export class ShopSessionEntity {
	constructor(
		private storageManager: StorageManagerService
	) {
	}

	public getOne(id: string) {
		return this.storageManager.getOne(entityName, id);
	}

	public getCollection(filter?: any) {
		return this.storageManager.getCollection(entityName, filter);
	}

	public saveOne(entity: any) {
		return this.storageManager.saveOne(entityName, entity);
	}

	public deleteOne(id: string) {
		return this.storageManager.deleteOne(entityName, id);
	}

	public destroyStorage() {
		return this.storageManager.destroyStorage();
	}
}