import {Component, inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TilbyMagicFormComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {
    ReceiptForm,
} from './receipt-options-dialog.state.service';
import { InputItemDataMagicForm } from 'src/app/shared/model/model';
import {QRCodeModule} from "angularx-qrcode";
import { ConfigurationManagerService, UserActiveSessionManagerService } from 'src/app/core';
import {mobileCheck} from "@tilby/tilby-ui-lib/utilities";

@Component({
  selector: 'app-receipt-options-dialog',
  standalone: true,
    imports: [CommonModule, TilbyMagicFormComponent, MatButtonModule, TranslateModule, QRCodeModule],
  templateUrl: './receipt-options-dialog.component.html',
  styleUrls: ['./receipt-options-dialog.component.scss']
})
export class ReceiptOptionsDialogComponent {
    protected readonly data:InputItemDataMagicForm<ReceiptForm>&{saleUuid:string} = inject(MAT_DIALOG_DATA);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);
    protected readonly mobileCheck = mobileCheck();

    protected qrCode=this.initQrCode();


    private initQrCode() {
        const saleUuid = this.data.saleUuid;

        if (this.configurationManagerService.getPreference('cashregister.public_receipt') && saleUuid) {
            const shopId = this.userActiveSessionManagerService.getSession()?.shop.id;

            if (shopId) {
                return `https://er.tilby.com/${shopId}/${saleUuid}.pdf`;
            }
        }
    }

}
