import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('application').factory('userSessionsManager', ["$mdDialog", function ($mdDialog) {
    userSessionsManagerController.$inject = ["$scope", "$timeout", "$mdDialog", "users", "connection", "options"];
    function userSessionsManagerController($scope, $timeout, $mdDialog, users, connection, options) {
        $scope.users = users;
        $scope.userSelected = null;
        $scope.errorTimeout = null;
        $scope.pinDigitClass = [];
        $scope.canDismiss = options.canDismiss;

        var clearPinField = function() {
            $scope.pincode = '';
            _.times(4, function(idx) {
                $scope.pinDigitClass[idx] = '';
            });
            $scope.errorTimeout = null;
        };

        clearPinField();

        if(options.selectActiveUser) {
            $scope.userSelected = _.find(users, { active: 1 });

            if($scope.userSelected) {
                $scope.hideUsers = true;
            }
        }

        $scope.hasPin = function() {
            return _.get($scope, ["userSelected", "pin"]);
        };

        $scope.newLogin = function() {
            $mdDialog.hide();
        };

        $scope.showNewUser = function() {
            return connection.isOnline() && !options.disableNewUser;
        };

        $scope.returnUser = function(user) {
            $mdDialog.hide(user);
        };

        $scope.selectUserSession = function(user) {
            clearPinField();
            $scope.userSelected = user;
            if (_.isEmpty(user.pin)) {
                $scope.returnUser(user);
            }
        };

        $scope.cancel = function() {
            if(options.canDismiss) {
                $mdDialog.cancel();
            }
        };

        $scope.onDigitChange = function(pin) {
            if (pin) {
                if ($scope.userSelected && !$scope.errorTimeout) {
                    _.times(pin.length, function(idx) {
                        $scope.pinDigitClass[idx] = 'circle-pin-correct';
                    });

                    if (pin.length === 4) {
                        if (pin === $scope.userSelected.pin) {
                            $scope.returnUser($scope.userSelected);
                        } else {
                            _.times(4, function(idx) {
                                $scope.pinDigitClass[idx] = 'circle-pin-incorrect';
                            });

                            $scope.errorTimeout = $timeout(clearPinField, 2000);
                        }
                    }
                } else {
                    $scope.pincode = '';
                }
            } else {
                clearPinField();
            }
        };
    }

    var userSessionsManager = {
        show: function(options) {
            if(!_.isObject(options)) {
                options = {};
            }

            return $mdDialog.show({
                controller: userSessionsManagerController,
                template: require('./user-sessions-manager.html'),
                resolve: {
                    users: ["entityManager", function(entityManager) {
                        return entityManager.userSessions.getCollection();
                    }]
                },
                locals: {
                    options: options
                },
                escapeToClose: false,
                clickOustsideToClose: false,
                onComplete: function() {
                    $(".md-dialog-container").css("z-index", 1000);
                }
            });
        }
    };

    return userSessionsManager;
}]);
