import * as angular from 'angular';
import * as _ from 'lodash';

const { tilbyVersion } = require('app/tilby.properties.json');

angular.module('application').factory('deviceInfoDialog', deviceInfoDialog);

deviceInfoDialog.$inject = ["$mdDialog", "$translate", "environmentInfo", "connection", "checkManager", "FiscalProviders", "restManager"];

function deviceInfoDialog($mdDialog, $translate, environmentInfo, connection, checkManager, FiscalProviders, restManager) {
    deviceInfoDialogController.$inject = ["$scope", "options"];

    function deviceInfoDialogController($scope, options) {
        const deviceInfo = environmentInfo.getDeviceMeta();

        Object.assign($scope, {
            tilbyVersion: tilbyVersion,
            currentCountry: checkManager.getShopCountry(),
            deviceInfo: [{
                label: 'MESSAGE_RECOVERY',
                value: checkManager.getSetting('messaging.recovery_enabled') ? $translate.instant('APPLICATION.DEVICE_INFO_DIALOG.ENABLED') : $translate.instant('APPLICATION.DEVICE_INFO_DIALOG.DISABLED')
            }],
        });

        if(deviceInfo.client_uuid) {
            $scope.deviceInfo.push({
                label: 'DEVICE_UUID',
                value: deviceInfo.client_uuid
            });
        }

        if(deviceInfo.client_serial) {
            $scope.deviceInfo.push({
                label: 'DEVICE_SERIAL',
                value: deviceInfo.client_serial
            });
        }

        const fetchRemoteInfo = async () => {
            try {
                let localAddress = await environmentInfo.getNetworkInfo();

                $scope.deviceInfo.push({
                    label: 'DEVICE_LAN_IP',
                    value: localAddress.client_lan_ip
                });
            } catch(err) {}

            try {
                let remoteAddress = await restManager.getList('my_address');

                $scope.deviceInfo.push({
                    label: 'DEVICE_WAN_IP',
                    value: remoteAddress.ipv4
                });
            } catch(err) {}

            $scope.deviceInfo.push({
                label: 'DEVICE_OS',
                value: deviceInfo.client_os || environmentInfo.getUserAgentOS()
            });
    
            $scope.deviceInfo.push({
                label: 'SHOP_ID',
                value: options.user?.shop.name
            }, {
                label: 'USERNAME',
                value: options.user?.username
            });

            if(['FR'].includes($scope.currentCountry)) {
                let fiscalProvider = FiscalProviders.getConfiguredFiscalProvider();

                if(fiscalProvider) {
                    $scope.hasFiscalProvider = true;
                    
                    if(fiscalProvider?.getVersion()) {
                        let fiscalProviderVersion = await fiscalProvider.getVersion();

                        Object.assign($scope, fiscalProviderVersion, { certNumber: 'XXXX' });
                    }
                }

            }
        };

        fetchRemoteInfo();

        $scope.isOnline = function() {
            return connection.isOnline();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    const deviceInfoDialog = {
        show: function(options) {
            return $mdDialog.show({
                controller: deviceInfoDialogController,
                template: require('./device-info-dialog.html'),
                locals: {
                    options: options
                }
            });
        }
    };

    return deviceInfoDialog;
}