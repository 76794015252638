import {Component, Input} from "@angular/core";
import {ColDef, FirstDataRenderedEvent, GetDataPath, ValueParserParams} from "ag-grid-community";
import {autobind} from "../../../models/decorators.model";
import {TranslateService} from "@ngx-translate/core";
import {
    CASH_MOVEMENTS, CASH_MOVEMENTS_CATEGORIES,
    CHANNELS, CLAIMED_PAYMENTS_AMOUNT, DELETED_ORDERS,
    DEPARTMENTS,
    exempts,
    getVatValue, ORDERS,
    TICKET, TOTAL_CATEGORIES, UNCLAIMED_PAYMENTS_AMOUNT
} from "../../../features/dashboard/components/dashboard/dashboard.utils";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { DevLogger } from "src/app/shared/dev-logger";
import {ReducePrinter } from "src/app/features";
import { EntityManagerService } from "src/app/core";
import { Vat } from "tilby-models";

@Component({
    selector: 'app-fiscal-grid',
    templateUrl: './fiscal-grid.component.html',
    styleUrls: ['./fiscal-grid.component.scss']
})
export class FiscalGridComponent {
    /**
     * typology è il livello padre
     * sub_typology è il livello figlio
     * effective_typology è il livello figlio del figlio
     */
    @Input() rowData: any & Object;
    rowDataValues: any[] = [];

    autoGroupColumnDef: ColDef = {
        cellRendererParams: (params: any) => {
            this.debugLog('cellRendererSelector', params);
            const isSpecificCategory = (category:string) => params.data?.orgHierarchy?.map((label:string|number)=>`${label}`.toLowerCase()).indexOf(category?.toLowerCase())>-1;
            const isOneOfTheseCategories = (categories:string[]) => categories.some(isSpecificCategory);
            if (typeof params?.value === 'string' && params.value!=='--' && !(isOneOfTheseCategories([...DEPARTMENTS,...CHANNELS,...TICKET]))) {
                if(this.verifyTranslationExists(params.value)) params.value = this.translateService.instant(`DASHBOARD.DIALOG.${params?.value?.toUpperCase()}`);
            }
            else if(typeof params?.value === 'number' && isOneOfTheseCategories([...TOTAL_CATEGORIES])){
                params.value= getVatValue(this.vats,params?.value);
            }
            if(typeof params.value === "string" && Object.values(exempts).indexOf(params.value)<0){
                params.value = params.value.capitalize();
            }
            params.suppressCount = true;
            return params;
        },
        minWidth: 200,
        headerName: '',
        suppressMenu: true
    };
    columnTypes: Record<string, ColDef> = {
        numberValue: {
            enableValue: true,
            aggFunc: a => {
                const q = a.values?.filter(v => ['unclaimed', 'aggFunc-summary_rc_invoices', 'summary_nrc_invoices', 'credit_notes', 'sales_sum'].indexOf(v?.name) < 0);
                const x = q.reduce((s, a) => s + (a?.value || 0), 0);
                const y = x ?? 0;
                this.debugLog(a.values, q, x, y, a)
                const checkIfCountOnGroups = [...CLAIMED_PAYMENTS_AMOUNT,...UNCLAIMED_PAYMENTS_AMOUNT,...CASH_MOVEMENTS,...DEPARTMENTS,...CHANNELS,...ORDERS].indexOf(a?.rowNode?.key||'') < 0;
                const checkIfValueOnGroups = [...DEPARTMENTS,...CHANNELS].indexOf(a?.rowNode?.key as keyof ReducePrinter||'') < 0;
                const checkIfJustOnceOnCashMovement = [...CASH_MOVEMENTS].indexOf(a?.rowNode?.key||'') > -1 && (typeof a.values?.[0]?.count !== "number") ;
                const checkIfJustOnceOnSales = [CASH_MOVEMENTS_CATEGORIES[0]].indexOf(a?.rowNode?.key||'') > -1 && (typeof a.values?.[2]?.count !== "number") ;
                const checkIfJustOnceOnGroups = checkIfJustOnceOnCashMovement || checkIfJustOnceOnSales;
                return {
                    name: `aggFunc-${a.rowNode?.key}`,
                    value: (checkIfValueOnGroups&&!checkIfJustOnceOnGroups)?y:'',
                    ...(checkIfCountOnGroups && {count: q?.reduce((s, a) => s + a?.count, 0) || q?.[0]?.parentCount || 0})
                };
            },
            valueParser: this.numberParser,
        }
    };
    groupDefaultExpanded = 0;
    defaultColDef: ColDef = {
        flex: 1,
        minWidth: 150,
        resizable: true,
    };
    vats: Vat[] = [];
    getDataPath: GetDataPath = (data: any) => data.orgHierarchy;
    // Each Column Definition results in one Column.
    columnDefs: ColDef[] = [];

    constructor(
        private tilbyCurrencyPipe: TilbyCurrencyPipe,
        private translateService: TranslateService,
        private entityManagerService: EntityManagerService,
    ) {
    }

    private verifyTranslationExists(str: string){
        return `DASHBOARD.DIALOG.${str.toUpperCase()}`!==this.translateService.instant(`DASHBOARD.DIALOG.${str.toUpperCase()}`);
    }

    debugLog(...args: any[]) {
        DevLogger.debug('[ FiscalGridComponent ]', ...args);
    }

    async ngOnInit() {
        // this.debugLog('rowData', this.rowData);
        this.vats= await this.entityManagerService.vat.fetchCollectionOffline();
        this.initTable();
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    numberParser(params: ValueParserParams) {
        this.debugLog('numberParser', params)
        return parseInt(params.newValue);
    }
    @autobind
    valueFormatter(p: any) {
        this.debugLog('valueFormatter', p)
        const defaultValueToVisualize = this.tilbyCurrencyPipe.transform(0);
        let count = ``;
        if(p.value?.count!=null && !isNaN(p.value?.value) && (typeof p.value?.value ==='number')){
            count = `(${p.value.count || 0})`;
        }
        if([...ORDERS,...DELETED_ORDERS].indexOf(p.node.key||'')>-1){
            return (typeof p.value === 'object')
                ? (p.value.value ?? 0)
                : (p.value ?? 0);
        }
        return (typeof p.value === 'object') && (!!p.value) && ('value' in p.value)
            ? `${typeof p.value.value === 'number' ? this.tilbyCurrencyPipe.transform(p.value.value) : p.value.value ?? defaultValueToVisualize} ${count}`
            : `${typeof p.value === 'number' ? this.tilbyCurrencyPipe.transform(p.value) : p.value ?? defaultValueToVisualize} ${count}`
    }

    initTable() {
        this.columnDefs = [{
            field: 'sortValue',
            hide: true,
            sort: 'asc',
            suppressMenu: true,
        }];
        Object.entries(this.rowData[0]).forEach(([k, v]) => {
            this.columnDefs.push({
                field: k,
                suppressMenu: true,
                ...(k === 'orgHierarchy' && {hide: true}),
                ...((k === 'typology' || k === 'sub_typology') && {rowGroup: true, hide: true}),
                ...((k.indexOf('typology') > -1) && {hide: true}),
                ...((k.indexOf('typology') === -1) && {
                    type: 'numberValue',
                    valueFormatter: (p) => p.value?.value || 0
                }),
                valueFormatter: this.valueFormatter,
            });
        });
        this.rowDataValues = [...this.rowData];
    }
}
