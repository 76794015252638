import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').controller('RoomsEditCtrl', ["$scope", "$stateParams", "$state", "entityManager", "checkManager", "rooms", "orders", function($scope, $stateParams, $state, entityManager, checkManager, rooms, orders) {
    $scope.action = "edit";
    $scope.id = _.toInteger($stateParams.id);

    var index = _.findIndex(rooms, { id: $scope.id });

    if (index === -1) {
        index = 0;
    }

    $scope.parentobj.currentTab = index;
    $scope.tables = _.get(rooms, [index, 'tables']) || [];
    $scope.floor = _.get(rooms, [index, 'floor']);
    $scope.orders = orders.byTable;

    angular.copy({}, $scope.topbar_context);

    _.assign($scope.topbar_context, {
        editmode: true,
        selected_tables: [],
        rooms: rooms,
        currentRoom: rooms[index],
        mapZoom: _.toNumber(checkManager.getPreference("rooms.zoom_factor")) || 1
    });

    $scope.room = rooms[index] ? {
        id: rooms[index].id,
        width: rooms[index].width * 80,
        height: rooms[index].height * 80,
    } : {};

    $scope.roomStyle = rooms[index] ? {
        width: $scope.room.width + 'px',
        height: $scope.room.height + 'px',
        background: 'url(assets/images/tables/' + $scope.floor + '.png) repeat'
    } : {};

    $scope.savePositionTable = function(table) {
        var roomsUpdate = _.cloneDeep(rooms[$scope.parentobj.currentTab]);

        _.remove(roomsUpdate.tables, {
            id: table.id
        });
        roomsUpdate.tables.push(table);

        return entityManager.rooms.putOneOnline(roomsUpdate);
    };

    $scope.selectTable = function(table) {
        var selected_tables = $scope.topbar_context.selected_tables;

        if (table.edit_state === 'selected') {
            selected_tables.push(table);
        } else if (table.edit_state === 'unselected') {
            _.remove(selected_tables, function(element) {
                return table.id === element.id;
            });
        }
    };

    $scope.getRoomStyle = function() {
        var roomStyle = _.clone($scope.roomStyle);
        /*if($scope.topbar_context.mapZoom !== 1) {
            _.assign(roomStyle, {
                'transform': "scale(" + $scope.topbar_context.mapZoom + ")",
                'transform-origin': "top left"
            });
        }*/
        return roomStyle;
    };

    $scope.parentobj.goToRoom = function(id) {
        $scope.topbar_context.selected_tables = [];
        $state.go("app.tables.rooms-edit", {
            id: id
        });
    };

    $scope.$on('connection:changed', function(event, data) {
        if(data.status === 'offline') {
            $state.go('app.tables.rooms-view', {
                id: $scope.id
            });
        }
    });
}]);
