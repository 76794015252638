import { groupBy } from "@tilby/tilby-ui-lib/utilities";
import { Items } from "tilby-models";
import { ItemsByCategory } from "./cashregister.component";

export const parseItemsCollection = (items:Items[]) => {
    const result = groupBy(items,item=>item.category_id);

    result.favorite = items.filter(item=>item.favorite);

    if(!result.null) {
        result.null = [];
    }

    return result as ItemsByCategory;
};
