import {SalesEInvoice} from "tilby-models";

export type CodeName<Code extends string =string,Name extends string =string> = {code:Code,name:Name};

export type PaymentConditionsCode = "TP01" | "TP02" | "TP03";
export const PAYMENT_CONDITIONS:CodeName<PaymentConditionsCode>[] = [
    { code:"TP01", name:"Pagamento a Rate" },
    { code:"TP02", name:"Pagamento Completo" },
    { code:"TP03", name:"Anticipo" }
];

// export type VatReceivabilitiesCode = "I" | "D" | "S";
export const VAT_RECEIVABILITIES: CodeName<SalesEInvoice.VatReceivabilityEnum,`[${SalesEInvoice.VatReceivabilityEnum}]: ${string}`>[] = [
    { code: "I", name: "[I]: Immediata" },
    { code: "D", name: "[D]: Differita" },
    { code: "S", name: "[S]: Split Payment" }
];

export type DeductionCausesCode = "A" | "B" | "C" | "D" | "E" | "G" | "H" | "I" | "L" | "M" | "M2" | "N" | "O" | "P" | "Q" | "R" | "S" | "T" | "U" | "V" | "W" | "X" | "Y" | "ZO" | "L1" | "M1" | "O1" | "V1";
export const DEDUCTION_CAUSES: CodeName<DeductionCausesCode,DeductionCausesCode>[] = [
    { code:"A", name:"A" },
    { code:"B", name:"B" },
    { code:"C", name:"C" },
    { code:"D", name:"D" },
    { code:"E", name:"E" },
    { code:"G", name:"G" },
    { code:"H", name:"H" },
    { code:"I", name:"I" },
    { code:"L", name:"L" },
    { code:"M", name:"M" },
    { code:"M2", name:"M2" },
    { code:"N", name:"N" },
    { code:"O", name:"O" },
    { code:"P", name:"P" },
    { code:"Q", name:"Q" },
    { code:"R", name:"R" },
    { code:"S", name:"S" },
    { code:"T", name:"T" },
    { code:"U", name:"U" },
    { code:"V", name:"V" },
    { code:"W", name:"W" },
    { code:"X", name:"X" },
    { code:"Y", name:"Y" },
    { code:"ZO", name:"ZO" },
    { code:"L1", name:"L1" },
    { code:"M1", name:"M1" },
    { code:"O1", name:"O1" },
    { code:"V1", name:"V1" }
];

export type DeductionTypesCode = "RT01" | "RT02" | "RT03" | "RT04" | "RT05" | "RT06";
export const DEDUCTION_TYPES:CodeName<DeductionTypesCode>[] = [
    { code: "RT01", name: "Ritenuta persone fisiche" },
    { code: "RT02", name: "Ritenuta persone giuridiche" },
    { code: "RT03", name: "Contributo INPS" },
    { code: "RT04", name: "Contributo ENASARCO" },
    { code: "RT05", name: "Contributo ENPAM" },
    { code: "RT06", name: "Altro contributo previdenziale" }
];

export type WelfareTypesCode = "TC01" | "TC02" | "TC03" | "TC04" | "TC05" | "TC06" | "TC07" | "TC08" | "TC09" | "TC10" | "TC11" | "TC12" | "TC13" | "TC14" | "TC15" | "TC16" | "TC17" | "TC18" | "TC19" | "TC20" | "TC21" | "TC22";
export const WELFARE_TYPES:CodeName<WelfareTypesCode>[] = [
    { code: "TC01", name: "Cassa nazionale previdenza e assistenza avvocati e procuratori legali" },
    { code: "TC02", name: "Cassa previdenza dottori commercialisti" },
    { code: "TC03", name: "Cassa previdenza e assistenza geometri" },
    { code: "TC04", name: "Cassa nazionale previdenza e assistenza ingegneri e architetti liberi professionisti" },
    { code: "TC05", name: "Cassa nazionale del notariato" },
    { code: "TC06", name: "Cassa nazionale previdenza e assistenza ragionieri e periti commerciali" },
    { code: "TC07", name: "Ente nazionale assistenza agenti e rappresentanti di commercio (ENASARCO)" },
    { code: "TC08", name: "Ente nazionale previdenza e assistenza consulenti del lavoro (ENPACL)" },
    { code: "TC09", name: "Ente nazionale previdenza e assistenza medici (ENPAM)" },
    { code: "TC10", name: "Ente nazionale previdenza e assistenza farmacisti (ENPAF)" },
    { code: "TC11", name: "Ente nazionale previdenza e assistenza veterinari (ENPAV)" },
    { code: "TC12", name: "Ente nazionale previdenza e assistenza impiegati dell'agricoltura (ENPAIA)" },
    { code: "TC13", name: "Fondo previdenza impiegati imprese di spedizione e agenzie marittime" },
    { code: "TC14", name: "Istituto nazionale previdenza giornalisti italiani (INPGI)" },
    { code: "TC15", name: "Opera nazionale assistenza orfani sanitari italiani (ONAOSI)" },
    { code: "TC16", name: "Cassa autonoma assistenza integrativa giornalisti italiani (CASAGIT)" },
    { code: "TC17", name: "Ente previdenza periti industriali e periti industriali laureati (EPPI)" },
    { code: "TC18", name: "Ente previdenza e assistenza pluricategoriale (EPAP)" },
    { code: "TC19", name: "Ente nazionale previdenza e assistenza biologi (ENPAB)" },
    { code: "TC20", name: "Ente nazionale previdenza e assistenza professione infermieristica (ENPAPI)" },
    { code: "TC21", name: "Ente nazionale previdenza e assistenza psicologi (ENPAP)" },
    { code: "TC22", name: "INPS" }
];

export type NatureCode = "N1" | "N2" | "N3" | "N4" | "N5" | "N6" | "N7";
export const NATURES:CodeName<NatureCode>[] = [
    { code: "N1", name: "escluse ex art. 15" },
    { code: "N2", name: "non soggette" },
    { code: "N3", name: "non imponibili" },
    { code: "N1", name: "escluse ex art. 15" },
    { code: "N4", name: "esenti" },
    { code: "N5", name: "regime del margine / IVA non esposta in fattura" },
    { code: "N6", name: "inversione contabile" },
    { code: "N7", name: "IVA assolta in altro stato UE " }
];

type PurchaseOrderField = "document_id" | "date" | "num_item" | "commission_code" | "cup_code" | "cig_code";
export const PURCHASE_ORDER_FIELDS: PurchaseOrderField[] = ['document_id', 'date', 'num_item', 'commission_code', 'cup_code', 'cig_code'];
