import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').service('orderItemManager', itemManager);

itemManager.$inject = ["$mdDialog", "$mdMedia", "$filter", "$translate", "entityManager", "checkManager"];

function itemManager($mdDialog, $mdMedia, $filter, $translate, entityManager, checkManager) {
    itemManagerController.$inject = ["$scope", "$mdDialog", "rowItem", "options", "item", "category", "components", "departments"];

    function itemManagerController($scope, $mdDialog, rowItem, options, item, category, components, departments) {
        Object.assign($scope, {
            applyAll: true,
            components: [],
            currentTab: 'options',
            unbundledQuantity: 1,
            departments: departments,
            disableExits: (options?.cashregisterMode && !options?.enableExits) ? true : false,
            disableUnbundle: options?.disableUnbundle ? true : false,
            halfPortionEnabled: checkManager.isFunctionEnabledOptin("orders.half_portion"),
            ingredients: [],
            ingredientsRemovalPrice: checkManager.getPreference("orders.ingredients_removal_affects_price"),
            integerval: /^\d*$/,
            rowItem: angular.copy(rowItem),
            switchTab: (tab) => { $scope.currentTab = tab; },
            tabsMode: $mdMedia('max-width: 639px'),
            variations: []
        });

        if(options?.cashregisterMode) {
            Object.assign($scope, {
                disableChangePrice: !checkManager.isUserPermitted("cashregister.use_price_changes"),
                disableChangeQuantity: !checkManager.isUserPermitted("cashregister.change_item_quantity"),
                disableDeleteItem: !checkManager.isUserPermitted("cashregister.delete_item"),
                disableRequiredVariations: checkManager.getPreference("cashregister.disable_required_variations"),
            });
        }

        if (item) {
            //Initialize variations (if the item does not use combinations)
            if(!rowItem.combination_id) {
                $scope.variations = structuredClone([...(item.variations || []), ...(category?.variations || [])]);

                for(let variation of $scope.variations) {
                    if($scope.disableRequiredVariations) {
                        variation.required = false;
                    }

                    let found = _.find(variation.variation_values, { default_value: true });
    
                    if (found) {
                        variation.variation_value_id = found.id;
                    } else if(!variation.required) {
                        variation.variation_value_id = 0;
                    }
                }
            }

            let itemComponentsMap = _.keyBy(item.components, 'id');
            //Initialize Ingredients
            const itemIngredients = _.chain(item.components).sortBy((component) => _.lowerCase(component.name)).cloneDeep().value();

            for(let ingredient of itemIngredients) {
                Object.assign(ingredient, {
                    $type: 'ingredient',
                    quantity: 1
                });
            }

            //Initialize components
            const availableComponents = _.chain(components)
                .filter((component) => itemComponentsMap[component.id] == null && (!component.categories?.length || _.find(component.categories, { id: item.category_id })))
                .sortBy((component) => _.lowerCase(component.name))
                .cloneDeep()
                .value();

            for(let component of availableComponents) {
                Object.assign(component, {
                    $type: 'component',
                    quantity: 0
                });
            }

            $scope.ingredients = [...itemIngredients, ...availableComponents];
        }

        if ($scope.rowItem.quantity) {
            $scope.newQuantity = $scope.rowItem.quantity;
            let ingredientsMap = _.keyBy($scope.ingredients, 'id');
            let variationsMap = _.keyBy($scope.variations, 'id');

            //Map existing variations
            if(Array.isArray($scope.rowItem.variations)) {
                for(let variation of $scope.rowItem.variations) {
                    let found = variationsMap[variation.variation_id];

                    if (found) {
                        found.variation_value_id = variation.variation_value_id;
                    }
                }
            }

            //Map existing ingredient variations
            if(Array.isArray($scope.rowItem.ingredients)) {
                for(let ingredient of $scope.rowItem.ingredients) {
                    let found = ingredientsMap[ingredient.ingredient_id];

                    if (found) {
                        switch(ingredient.type) {
                            case 'removed':
                                found.quantity = -1;
                            break;
                            case 'added':
                                found.quantity = (found.$type === 'ingredient') ? (ingredient.quantity + 1) : ingredient.quantity;
                            break;
                        }
                    }
                }
            }
        } else {
            Object.assign($scope, {
                disableUnbundle: true,
                newQuantity: 1
            });
        }

        const parseNewComponents = () => {
            const newComponents = [];

            for(let ingredient of $scope.ingredients) {
                if((ingredient.$type === 'component' && ingredient.quantity !== 0) || (ingredient.$type === 'ingredient' && ingredient.quantity !== 1)) {
                    let newComponent = {
                        type: ingredient.quantity === -1 ? 'removed' : 'added',
                        name: ingredient.name,
                        ingredient_id: ingredient.id,
                        price_difference: ingredient.price_difference
                    };

                    if(ingredient.$type === 'ingredient' && ingredient.quantity > 1) {
                        newComponent.quantity = (ingredient.quantity - 1);
                    } else {
                        newComponent.quantity = Math.abs(ingredient.quantity);
                    }

                    newComponents.push(newComponent);
                }
            }

            return newComponents;
        };

        const parseNewVariations = () => {
            const newVariations = [];

            if(!$scope.rowItem.combination_id) {
                for(let variation of $scope.variations) {
                    if (parseInt(variation.variation_value_id)) {
                        let found = _.find(variation.variation_values, { id: variation.variation_value_id });
    
                        newVariations.push({
                            name: variation.name,
                            value: found.value,
                            price_difference: found.price_difference,
                            linked_item_uuid: found.linked_item_uuid,
                            variation_id: variation.id,
                            variation_value_id: variation.variation_value_id
                        });
                    }
                }
            }

            return newVariations;
        };

        $scope.getPriceDifference = function(ingredient) {
            if(!ingredient.price_difference || (!$scope.ingredientsRemovalPrice && ingredient.$type === 'ingredient')) {
                return '';
            } else {
                return (ingredient.price_difference >= 0 ? '+' : '') + $filter('sclCurrency')((ingredient.quantity > 1) ? ingredient.price_difference * ingredient.quantity : ingredient.price_difference);
            }
        };

        $scope.incrementIngredientQuantity = function(ingredient) {
            if(ingredient.quantity <= -1) {
                switch(ingredient.$type) {
                    case 'ingredient':
                        ingredient.quantity = 1;
                    break;
                    case 'component':
                        ingredient.quantity = 0;
                    break;
                }
            } else {
                ingredient.quantity++;
            }
        };

        $scope.decrementIngredientQuantity = function(ingredient) {
            switch(ingredient.$type) {
                case 'ingredient':
                    if(ingredient.quantity <= 1) {
                        ingredient.quantity = -1;
                    } else {
                        ingredient.quantity--;
                    }
                break;
                case 'component':
                    if(ingredient.quantity <= 0) {
                        ingredient.quantity = -1;
                    } else {
                        ingredient.quantity--;
                    }
                break;
            }
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.deleteItem = () => {
            $scope.applyAll = true;
            $scope.newQuantity = 0;
            $scope.answer();
        };

        $scope.answer = function() {
            if(!$scope.canConfirm) {
                return;
            }

            const result = angular.copy($scope.rowItem);
            const selectedDepartment = $scope.departments.find((department) => department.id === result.department_id);

            Object.assign(result, {
                department: selectedDepartment,
                department_id: selectedDepartment.id,
                department_name: selectedDepartment.name,
                half_portion: $scope.rowItem.half_portion ? true : false,
                ingredients: parseNewComponents(),
                vat_perc: selectedDepartment.vat.value,
                variations: parseNewVariations()
            });

            if (!$scope.applyAll && !$scope.disableUnbundle && $scope.unbundledQuantity < result.quantity) {
                const updatedRowItem = angular.copy(rowItem);

                updatedRowItem.quantity -= $scope.unbundledQuantity;
                result.quantity = $scope.unbundledQuantity;

                delete result.id;

                $mdDialog.hide({ rowItem: updatedRowItem, unbundledRowItem: result });
            } else {
                result.quantity = $scope.newQuantity;

                $mdDialog.hide({ rowItem: result });
            }
        };

        const exits = _.toInteger(checkManager.getPreference('orders.exits')) || 5;

        $scope.exits = [{
            name: $translate.instant("ORDERS.ORDER_ITEM_MANAGER.NO_EXIT"),
            value: null
        }];

        for(let exit = 1; exit <= exits; exit++) {
            $scope.exits.push({
                name: `${$translate.instant("ORDERS.ORDER_ITEM_MANAGER.EXIT_LABEL")} ${exit}`,
                value: exit
            });
        }

        $scope.$watch(function() { return $mdMedia('max-width: 639px'); }, function(maxWidth) {
            $scope.tabsMode = maxWidth;
        });
    }

    let isShowing = false;

    return {
        show: function(rowItem, options) {
            if(isShowing) {
                return Promise.reject(null);
            }
            isShowing = true;

            return $mdDialog.show({
                controller: itemManagerController,
                template: require('./order-item-manager.html'),
                locals: {
                    rowItem: rowItem,
                    options: options
                },
                resolve: {
                    components: () => entityManager.components.fetchCollectionOffline(),
                    departments: () => entityManager.departments.fetchCollectionOffline(),
                    item: () => (rowItem.item_id ? entityManager.items.fetchOneOffline(rowItem.item_id) : Promise.resolve(null)),
                    category: () => (rowItem.category_id ? entityManager.categories.fetchOneOffline(rowItem.category_id) : Promise.resolve(null))
                },
                onComplete: (scope) => {
                    setTimeout(() => {
                        scope.canConfirm = true;
                    }, 500);
                }
            }).finally(function() {
                isShowing = false;
            });
        }
    };
}

angular.module('cashregister').service('saleItemManager', ['orderItemManager', function(orderItemManager) {
    return {
        show: (rowItem, options) => {
            return orderItemManager.show(rowItem, Object.assign({ cashregisterMode: true }, typeof options === 'object' ? options : {}));
        }
    };
}]);