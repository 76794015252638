import {
    Component,
    ElementRef,
    Injectable,
    ViewChild,
    inject
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";

import {
    lastValueFrom
} from "rxjs";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

type SignaturePadDialogData = {
    canvasSize?: { width: number, height: number }
    dialogTitle?: string
    showSignatureGuide?: boolean
    signatureRequired?: boolean
};

@Component({
    selector: "signature-pad-dialog",
    standalone: true,
    imports: [
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
    ],
    templateUrl: "./signature-pad-dialog.component.html",
    styleUrls: ["./signature-pad-dialog.component.scss"],
})
export class SignaturePadDialogComponent {
    protected readonly data: SignaturePadDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);

    @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
    private ctx?: CanvasRenderingContext2D;

    private isDrawing = false;
    protected hasDrawnSignature = false;

    ngAfterViewInit() {
        const canvas = this.signatureCanvas.nativeElement;
        this.ctx = canvas.getContext('2d')!;

        // resize canvas
        if(this.data.canvasSize) {
            canvas.width = this.data.canvasSize.width;
            canvas.height = this.data.canvasSize.height;
        }

        this.initCanvasPositionData();

        if(this.data.showSignatureGuide) {
            this.initSignatureGuide();
        }

        this.ctx.lineWidth = 4;
        this.ctx.lineCap = 'round';
    }
    
    private canvasOffset: { left: number, top: number } = { left: 0, top: 0 };
    private scalingFactor: { x: number, y: number } = { x: 1, y: 1 };

    private initSignatureGuide() {
        if(!this.ctx) {
            return;
        }
        const canvas = this.signatureCanvas.nativeElement;

        // Set line width and color for signature guide
        this.ctx.lineWidth = 2;
        this.ctx.strokeStyle = '#555555';

        // Create a gray line on the bottom 10% of the canvas, leaving 5% on the left and right
        this.ctx.beginPath();
        this.ctx.moveTo(canvas.width * 0.05, canvas.height * 0.9);
        this.ctx.lineTo(canvas.width * 0.95, canvas.height * 0.9);
        this.ctx.stroke();

        //Draw an X on the left of the line (the X takes 5% of the canvas width)
        const widthDelta = canvas.width * 0.03;
        const crossBottom = canvas.height * 0.9;
        const crossTop = crossBottom - widthDelta;
        const crossLeft = canvas.width * 0.01;
        const crossRight = crossLeft + widthDelta;

        this.ctx.beginPath();
        this.ctx.moveTo(crossLeft, crossTop);
        this.ctx.lineTo(crossRight, crossBottom);
        this.ctx.moveTo(crossLeft, crossBottom);
        this.ctx.lineTo(crossRight, crossTop);
        this.ctx.stroke();

        // Restore default color
        this.ctx.strokeStyle = '#000000';
    }

    private initCanvasPositionData() {
        // Get canvas offset relative to viewport
        const canvasOffset = this.signatureCanvas.nativeElement.getBoundingClientRect();
        this.canvasOffset = { left: canvasOffset.left, top: canvasOffset.top };

        // Get scaling factor
        this.scalingFactor = {
            x: this.signatureCanvas.nativeElement.clientWidth / this.signatureCanvas.nativeElement.width,
            y: this.signatureCanvas.nativeElement.clientHeight / this.signatureCanvas.nativeElement.height
        };
    }

    private getCursorPosition(e: PointerEvent): [number, number] {
        // Return cursor position in canvas
        return [(e.clientX - this.canvasOffset.left) / this.scalingFactor.x, (e.clientY - this.canvasOffset.top) / this.scalingFactor.y];
    }

    private moveTo(e: PointerEvent) {
        if (!this.ctx) {
            return;
        }

        this.ctx.beginPath();
        this.ctx.moveTo(...this.getCursorPosition(e));
    }

    private lineTo(e: PointerEvent) {
        if (!this.ctx) {
            return;
        }

        this.ctx.lineTo(...this.getCursorPosition(e));
    }

    private draw(e: PointerEvent) {
        if (!this.ctx) {
            return;
        }

        this.lineTo(e);
        this.ctx.stroke();

        this.moveTo(e);
    }

    protected onPointerDown(event: PointerEvent) {
        this.isDrawing = true;

        this.initCanvasPositionData();
        this.moveTo(event);
    }

    protected onPointerMove(event: PointerEvent) {
        if (!this.isDrawing) {
            return;
        }

        this.draw(event);
    }

    protected onPointerUp(event: PointerEvent) {
        if(this.isDrawing) {
            this.isDrawing = false;
            this.hasDrawnSignature = true;
        }
    }

    protected confirm() {
        this.matDialogRef.close(this.signatureCanvas.nativeElement.toDataURL("image/png"));
    }
}

@Injectable({
    providedIn: "root",
})
export class SignaturePadDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<SignaturePadDialogData>): Promise<string | undefined> {
        const data: SignaturePadDialogData = config?.data || {};

        const configEdited: NonNullableConfigData<SignaturePadDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({ width: "800px" }),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(SignaturePadDialogComponent, configEdited).afterClosed()
        );
    }
}
