import * as angular from 'angular';
import {EntityManagerService} from "../../src/app/core/services";

angular.module('suppliersNew', ['application']);

angular.module('suppliersNew').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('suppliers');

    $stateProvider.state('app.new.suppliers', {
        url: '/suppliers',
        component:"suppliersShowcase",
        params: {
          remove: {dynamic: true, value: null}
        }
    });

    $stateProvider.state('app.new.suppliers.showcase', {
        url: '/showcase',
        redirectTo: 'app.new.suppliers',
    });

    $stateProvider.state('app.new.suppliers.details', {
        url: '/details/:supplierId',
        template: '<suppliers-details [supplier]="$resolve.supplier"></suppliers-details>',
        params: {
            isNew: {dynamic: true, value: null}
        },
        resolve: {
            supplier: ["entityManager", "$stateParams", async function(entityManager: EntityManagerService, $stateParams: any) {
                return {...(await entityManager.suppliers.fetchOneOnline($stateParams.supplierId)), ...($stateParams.isNew && {isNew: true})};
            }]
        }
    });

    $stateProvider.state('app.new.suppliers.new', {
        url: '/new',
        template: '<suppliers-details></suppliers-details>',
    });
}]);