import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').factory('savedSales', savedSales);

savedSales.$inject = ["$mdDialog", "ActiveSale", "entityManager", "ExternalSalesManager", "newSaleUtils"];

function savedSales($mdDialog, ActiveSale, entityManager, ExternalSalesManager, newSaleUtils) {

    savedSalesController.$inject = ["$scope", "$mdDialog", "$translate"];
    function savedSalesController($scope, $mdDialog, $translate) {
        $scope.sales = [];
        $scope.salesFetched = false;
        $scope.extSalesMan = ExternalSalesManager;

        $scope.getSaleIcon = function(sale) {
            return $scope.extSalesMan.isExternal(sale) ? "move_to_inbox" : "receipt";
        };

        var currentSaleUuid = ActiveSale.isActiveSale();

        if (currentSaleUuid) {
            $scope.selectedSale = ActiveSale.currentSale.uuid;
        }

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.selectSale = function(sale) {
            $mdDialog.hide(sale?.id);
        };

        $scope.showAssignedCaption = function(sale) {
            if (sale.order_id || sale.order_uuid) {
                if (sale.assigned_id) {
                    return sale.assigned_name;
                } else {
                    return $translate.instant('CASHREGISTER.SAVED_SALES.NOT_ASSIGNED');
                }
            } else {
                if (sale.seller_id) {
                    return sale.seller_name;
                } else {
                    return '';
                }
            }
        };

        $scope.isAssigned = function(sale) {
            if (sale.order_id || sale.order_uuid) {
                return _.isFinite(sale.assigned_id);
            } else {
                return _.isFinite(sale.seller_id);
            }
        };

        const fetchSales = async () => {
            const sales = await  entityManager.sales.fetchCollectionOffline({ status: 'open' });

            for(const sale of sales) {
                newSaleUtils.calculateSalePrices(sale);
            }

            $scope.sales = sales || [];
        };

        $scope.$on("storage-updated:sales", fetchSales);
        $scope.$on("OfflineFirst-sales:completed", fetchSales);

        fetchSales().finally(function() {
            $scope.salesFetched = true;
        });

        $scope.getSaleLabelName = function(sale) {
            switch(sale.channel) {
                case 'website':
                    let tmpName;

                    if (sale.sale_customer) {
                        tmpName = sale.sale_customer.company_name ? sale.sale_customer.company_name : `${sale.sale_customer.first_name} ${sale.sale_customer.last_name}`;
                    }

                    return $translate.instant('CASHREGISTER.SAVED_SALES.SAVED_SALE_LABEL', { id: sale.id, name: tmpName });
                default:
                    return sale.name;
            }
        };
    }

    const savedSales = {
        show: () => {
            return $mdDialog.show({
                controller: savedSalesController,
                template: require('./saved-sales.html')
            });
        }
    };

    return savedSales;
}