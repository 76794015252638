import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { $state } from 'app/ajs-upgraded-providers';
import { fromEvent, Subscription } from 'rxjs';
import { EnvironmentInfoService } from 'src/app/core';

@Component({
    selector: 'app-barcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent {
    @Input() hideButton: any;
    @Input() idSelector: string = 'barcodeInput';
    @Output() onSubmitBarcode = new EventEmitter<string>();
    barcodeInput: string =  "";
    currentModuleClass: string;
    isAppleMobile: boolean;
    keyPressSubscription?: Subscription;

    constructor(
        private environmentInfoService: EnvironmentInfoService,
        @Inject($state) private state: any,
        private renderer: Renderer2
    ) {
        this.currentModuleClass = 'barcode-icon-' + this.state.current.name.split('.')[1];
        this.isAppleMobile = this.environmentInfoService.isAppleMobile();
    }

    ngOnInit() {
        if (!this.isAppleMobile) {
          this.keyPressSubscription = fromEvent(window, 'keypress')
          .subscribe((event: any&KeyboardEvent) => this.onKeyPress(event));
        }
    }

    ngOnDestroy() {
        if (this.keyPressSubscription) {
          this.keyPressSubscription.unsubscribe();
        }
    }

    onKeyPress(event: KeyboardEvent) {
        if (event.target instanceof HTMLBodyElement) {
            switch (event.code) {
                case 'Enter':
                    this.barcodeSubmitted();
                    break;
                case 'Escape':
                    this.barcodeInput = '';
                    break;
                default:
                    this.barcodeInput += event.key;
                    break;
            }
        }
    }

    barcodeSubmitted() {
        if(this.barcodeInput.length) {
            this.onSubmitBarcode.emit(this.barcodeInput);
            this.barcodeInput = "";
        }
    };

    // APPLE WORKAROUND
    focusBarcodeInput() {
        const barcodeInput = this.renderer.selectRootElement('#'+this.idSelector);
        barcodeInput.focus();
        barcodeInput.value = '';
    };
}
