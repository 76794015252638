import {Component, Injectable, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { lastValueFrom } from 'rxjs';
import {
    BaseDialogService, NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent } from '@tilby/tilby-ui-lib/components/tilby-magic-form';

@Component({
  selector: 'app-change-sale-name-dialog',
  standalone: true,
  imports: [CommonModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent, TilbyDialogToolbarComponent, MatDialogModule, TilbyMagicFormComponent],
  templateUrl: './change-sale-name-dialog.component.html',
  styleUrls: ['./change-sale-name-dialog.component.scss']
})
export class ChangeSaleNameDialogComponent {

    private readonly data: {saleName: string} = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    editSaleNameForm!: CustomFormGroup<CustomForm<{saleName: string}>>;

    constructor() {
        this.editSaleNameForm = new CustomFormGroup<CustomForm<{saleName: string}>>({
            saleName: new CustomFormControl(
                this.data.saleName,
                {
                    validators: [
                        Validators.required,
                        Validators.maxLength(30)
                    ]
                },
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.CHANGE_NAME_SALE.NAME_SALE',
                    inputType: 'text',
                    hint: () => `${this.editSaleNameForm?.controls.saleName?.value?.length || 0}/30`
                }
            )
        });
    }

    get saleName(){
        return this.editSaleNameForm.controls.saleName;
    }

    confirm(): void {
        this.matDialogRef.close(this.saleName.value);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ChangeSaleNameDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: {saleName: string}) {
        if(!data) {
            data={saleName:''};
        }
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions({ height: '170px', width: '300px' }), disableClose:true, data };
        return lastValueFrom(this.dialogRef.open(ChangeSaleNameDialogComponent,config).afterClosed()).then((res : string | undefined) => {
            return {
                saleName : res
            }
        });
    }
}
