import {Pipe, PipeTransform} from '@angular/core';
import {MovementType} from "../models";

@Pipe({
    name: 'getType',
})
export class FidelityPointsGetTypePipe implements PipeTransform {

    transform(points:number):MovementType {
         return points >= 0 ? "load" : "unload"
    }

}
@Pipe({
    name: 'getPointsSign',
})
export class FidelityPointsGetPointsSignPipe implements PipeTransform {

    transform(type:MovementType): 1|-1 {
        switch(type){
            case "load":
                return 1;
            case "unload":
                return -1;
        }
    }

}
