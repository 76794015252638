import {
    Component,
    inject,
    Injectable,
    Injector,
    OnInit
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";
import { isObject } from "angular";
import { TranslateService } from "@ngx-translate/core";
import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import { lastValueFrom } from 'rxjs';
import { EntityManagerService } from 'src/app/core';
import { OpenDialogsService } from '../services';
import { errorsLogger } from 'app/ajs-upgraded-providers';

export interface PrinterErrorFiscalDialogData {error: string|any&object, options?: { printerId?:number }};

@Component({
    selector: 'app-printer-error-fiscal-dialog',
    templateUrl: './printer-error-fiscal-dialog.component.html',
    styleUrls: ['./printer-error-fiscal-dialog.component.scss'],
    imports: [
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        TilbyDialogActionButtonsComponent
    ],
    standalone: true
})
export class PrinterErrorFiscalDialogComponent implements OnInit{
    private readonly printerErrorFiscal = inject(PrinterErrorFiscalDialogService);
    protected readonly data: PrinterErrorFiscalDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef: MatDialogRef<PrinterErrorFiscalDialogComponent> = inject(MatDialogRef);

    actions: string[] | null = null;
    error: string = this.doTranslateError(this.data.error);
    options = isObject(this.data.options) ? this.data.options : {};
    protected actionButtons: GeneralInputButton[] = [];

    doTranslateError(error:string|any&object){
        return this.printerErrorFiscal.translateError(error);
    }

    ngOnInit() {
        const { printerId } = this.options;

        if(printerId) {
            switch(this.data.error) {
                case 'FISCAL_CLOSING_NEEDED':
                    this.actions = ['PERFORM_DAILY_CLOSING'];
                    break;
                default: break;
            }
        }
        this.actionButtons = this.createActionButtons();
    }

    private createActionButtons(): GeneralInputButton[] {
        const actionButtons: GeneralInputButton[] = this.actions?.map(action => (
            {
                isIt: () => true,
                name: `APPLICATION.PRINTER_ERROR_FISCAL.${action}`,
                click: (...any: any[]) => this.matDialogRef.close(action),
            }
        )) || [];
        return [
            {
                isIt: () => true,
                name: 'DIALOG.EMAIL.ACTION.CANCEL',
                click: (...any: any[]) => this.cancel(),
            },
            ...actionButtons
        ];
    }

    private cancel() {
        this.matDialogRef.close();
    };
}

@Injectable({
    providedIn: 'root'
})
export class PrinterErrorFiscalDialogService extends BaseDialogService {
    private readonly translate = inject(TranslateService);
    private readonly dialogRef = inject(MatDialog);
    private readonly injector = inject(Injector);
    private readonly errorLoggerService = inject(errorsLogger);

    public translateError(error: any) {
        let errorCode;
        let translationId = '';
        let translateParams: object = {};
        let translatePath;

        switch (typeof error) {
            case 'object':
                if(error?.sclErrorId) {
                    translationId = error.sclErrorId;
                    translateParams = error.sclErrorData;
                } else if (error instanceof Error) {
                    this.errorLoggerService.err(error);
                    return error.message;
                } else {
                    try {
                        return JSON.stringify(error);
                    } catch(error) {}
                }
            break;
            case 'string':
                translationId = error;
            break;
        }

        ['RCH', 'EPSON', 'CUSTOM', 'AXON', 'DTR'].some((printerType) => {
            const replacePath = `${printerType}.ERROR_`;

            if(translationId.startsWith(replacePath)) {
                errorCode = parseInt(translationId.replace(replacePath, ''), 10);

                if (printerType !== 'EPSON' || [7, 8, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 34, 35, 37, 38, 41].includes(errorCode)) {
                    translatePath = 'APPLICATION.PRINTER_ERROR_FISCAL.PRINTER_GENERIC_ERROR';
                    translateParams = { ...translateParams, errorCode };
                }

                return true;
            }

            return false;
        });

        translatePath ||= `APPLICATION.PRINTER_ERROR_FISCAL.${translationId}`;

        let translateString = this.translate.instant(translatePath, translateParams);

        if(translatePath === translateString) { //Happens when the translation is not found
            translateString = this.translate.instant(translationId, translateParams);
        }

        return translateString;
    };

    public async openDialog(config: NonNullableConfigData<PrinterErrorFiscalDialogData>) {
        let errors = config.data.error;

        if(!errors) {
            return;
        }

        if (!Array.isArray(errors)) {
            errors = [errors];
        }

        for (const error of errors) {
            const errorConfig = {
                ...config,
                data: {
                    ...config.data,
                    error
                }
            }

            const action = await lastValueFrom(this.dialogRef.open<PrinterErrorFiscalDialogComponent, PrinterErrorFiscalDialogData>(PrinterErrorFiscalDialogComponent, {...this.switchMobileDesktopDimensions({minWidth: '30vw'}), ...errorConfig}).afterClosed());

            switch (action) {
                case 'PERFORM_DAILY_CLOSING':
                    const printerId = errorConfig.data.options?.printerId;

                    if (printerId) {
                        const entityManagerService = this.injector.get(EntityManagerService);
                        const printer = await entityManagerService.printers.fetchOneOffline(printerId);

                        if (printer) {
                            await this.injector.get(OpenDialogsService).openDailyClosingDialog({data: printer});
                        }
                    }
                    break;
                default:
                    break;
            }

            if (action) {
                break;
            }
        }
    }

    // per retrocompatibilità con downgrade
    public async show(error: any, options: any) {
        await this.openDialog({data: {error, options}});
    }

}
