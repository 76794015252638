import * as angular from 'angular';
import * as moment from 'moment-timezone';

angular.module('giftcard').factory('modifyGiftcardDialog', ["$mdDialog", "$translate", "restManager", "alertDialog", function($mdDialog, $translate, restManager, alertDialog) {
    modifyGiftcardDialogController.$inject = ["$scope", "giftcard"];
    function modifyGiftcardDialogController($scope, giftcard) {
        $scope.data = {
            is_divisible: giftcard.is_divisible,
            valid_until: giftcard.valid_until ? moment(giftcard.valid_until, 'YYYY-MM-DD').toDate() : null
        };
        $scope.minDate = new Date(giftcard.valid_since);

        $scope.confirm = function() {
            let data = {
                is_divisible: $scope.data.is_divisible
            };

            if ($scope.data.valid_until) {
                data.valid_until = moment($scope.data.valid_until).format('YYYY-MM-DD');
            }

            restManager.put('giftcards/' + giftcard.uuid, null, data).then(function(result) {
                $mdDialog.hide(result);
            }, function(error) {
                alertDialog.show($translate.instant('GIFTCARD.MODIFY_GIFTCARD_DIALOG.SAVE_ERROR'));
            });
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    var modifyGiftcard = {
        show: function(giftcard) {
            return $mdDialog.show({
                controller: modifyGiftcardDialogController,
                template: require('./modify-giftcard-dialog.html'),
                locals: {
                    giftcard: giftcard
                }
            });
        }
    };

    return modifyGiftcard;
}]);

