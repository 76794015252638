import { CommonModule } from "@angular/common";
import { Component, Inject, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { SettingsAlvoloContentComponent } from "src/app/features";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { SatispayDialogData, SatispayDialogForm, SatispayDialogService } from "./satispay-dialog.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { restManager } from "app/ajs-upgraded-providers";
import { MagicPortalDialogComponent } from "../../magic-portal-dialog";
import { PortalConfigData } from "../../services";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';


@Component({
    selector: 'satispay-dialog',
    templateUrl: './satispay-dialog.component.html',
    styleUrls: ['./satispay-dialog.component.scss'],
    standalone: true,
    imports: [
        TilbyMagicFormComponent,
        CommonModule,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        MatButtonModule,
    ],
    providers:[OnDestroyService]
})
export class SatispayDialogComponent {
    protected readonly onDestroyService = inject(OnDestroyService);
    protected readonly satispayDialogService = inject(SatispayDialogService);
    
    constructor(
        public dialogRef: MatDialogRef<SettingsAlvoloContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputItemDataMagicForm<SatispayDialogForm>,
    ){
        this.subscriptions();
    }

    cancel() {
        this.dialogRef.close({ cancel: true });
    }

    private subscriptions(){
        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe((res: PortalConfigData<SatispayDialogData, SatispayDialogComponent>) => res.form.valid && this.satispayDialogService.confirm(res.form.value));
    }
}