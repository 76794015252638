import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

import {mobileCheck} from 'src/utilities';
import {
    ThemeModeService,
    ToolbarEventsService
} from 'src/app/core';
import {OpenDialogsService} from 'src/app/dialogs';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-left-toolbar',
    templateUrl: './left-toolbar.component.html',
    styleUrls: ['./left-toolbar.component.scss'],
    host: {class: 'tw-flex tw-items-center'}
})
export class LeftToolbarComponent implements OnInit {
    @Input() menuIcon = false;
    @Output() menuClickChange = new EventEmitter<void>();
    logoLight: SafeResourceUrl;
    logoDark:SafeResourceUrl;
    isMobile = mobileCheck();
    moduleTitle?: string;
    isOffline$=this.toolbarEventsService.isOffline$;

    constructor(
        public themeModeService: ThemeModeService,
        private domSanitizer:DomSanitizer,
        public toolbarEventsService:ToolbarEventsService,
        public translateService: TranslateService,
        public openDialogsService: OpenDialogsService,
        private onDestroyService: OnDestroyService
    ) {
       this.logoLight = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tilby/Tilby_a.svg');
       this.logoDark = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tilby/Tilby_a-dark.svg');
       this.toolbarEventsService.moduleTitle.pipe(this.onDestroyService.takeUntilDestroy).subscribe(moduleTitle => {this.moduleTitle = "TOPBAR.TITLE."+moduleTitle;})
    }

    ngOnInit(): void {
    }

    menuClick($event: MouseEvent) {
        $event.stopPropagation();
        this.menuClickChange.emit();
    }

    openOfflineDialog() {
        return this.openDialogsService.openOfflineDialog();
    }
}
