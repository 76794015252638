<ng-container *ngIf="!hide">
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"
            aria-label="Example icon-button with a menu"
            class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDeleteDialog($event)">
            <mat-icon>delete_forever</mat-icon>
            <span>{{ 'CUSTOMERS.SHOWCASE.ACTIONS.DELETE_THIS_ROW' | translate }}</span>
        </button>
        <ng-container *ngIf="params?.data as item">
            <a mat-menu-item [disabled]="!(item.email1||item.email)" href="mailto:{{item.email1||item.email}}">
                <mat-icon>email</mat-icon>
                <span>{{ 'CUSTOMERS.SHOWCASE.ACTIONS.SEND_AN_EMAIL' | translate }}</span>
            </a>
            <a mat-menu-item [disabled]="!(item.phone1||item.mobile)" href="tel:{{item.phone1||item.mobile}}">
                <mat-icon>call</mat-icon>
                <span>{{ 'CUSTOMERS.SHOWCASE.ACTIONS.CALL' | translate }}</span>
            </a>
        </ng-container>
    </mat-menu>
</ng-container>
