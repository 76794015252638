import {Component, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

export interface BottomSheetItem{
    label?:string;
    icon?:string;
    callback:()=>void;
}
export interface BottomSheetData{
    items:BottomSheetItem[]
}

@Component({
  selector: 'app-generic-bottom-sheet',
  standalone: true,
    imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './generic-bottom-sheet.component.html',
  styleUrls: ['./generic-bottom-sheet.component.scss']
})
export class GenericBottomSheetComponent {

    constructor(
        private _bottomSheetRef: MatBottomSheetRef<GenericBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) protected data:BottomSheetData
    ) {
    }

    itemSelected(item: BottomSheetItem) {
        this._bottomSheetRef.dismiss(item);
    }
}
