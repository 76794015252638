<div class="model-document tw-flex tw-flex-col max-height">
    <mat-progress-bar mode="query" *ngIf="isProcessing"></mat-progress-bar>
    <div class="model-document-content tw-flex tw-flex-row">
        <!-- left -->
        <section class="model-document-left-column tw-flex tw-flex-col left-section">
            <div class="wrapper-icon">
                <div class="tw-flex tw-justify-end">
                    <button mat-icon-button aria-label="Filter model document" (click)="filterModelDocument()" [color]="filterToggle ? 'primary' : ''" *ngIf="modelDocumentList.length > 0">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Add new model document" (click)="addNewModelDocument(modelDocumentForm.form.dirty)"
                        [disabled]="isNewEntryInProgress">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-form-field class="tw-flex tw-mt-1.5" appearance="fill" *ngIf="filterToggle">
                    <mat-label>{{'SETTINGS.MODEL_DOCUMENT.SEARCH'|translate}}</mat-label>
                    <mat-icon matIconPrefix>search</mat-icon>
                    <input matInput name="search" [(ngModel)]="filterList">
                    <button *ngIf="filterList" matSuffix mat-icon-button aria-label="Clear" (click)="filterList=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="model-document-list-container" *ngIf="modelDocumentList && modelDocumentList.length > 0">
                <mat-nav-list dense>
                    <mat-list-item *ngFor="let modelDocument of modelDocumentList | matchesModelDocument:filterList"
                        (click)="selectModelDocument(modelDocument, modelDocumentForm.form.dirty)"
                        [activated]="isSelected(modelDocument)">
                        <div matListItemTitle>{{modelDocument.name}}</div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </section>
        <!-- right -->
        <section class="tw-flex tw-flex-col right-section" [ngClass]="{'tw-flex-wrap tw-content-center tw-justify-center' : !selected}">
            <div class="wrapper-icon wrapper-icon-border tw-flex tw-justify-end" *ngIf="selected">
                <button mat-icon-button aria-label="Save button" (click)="saveModelDocument()" [disabled]="modelDocumentForm.form.invalid">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete button" (click)="deleteModelDocument()" [disabled]="!selectedModelDocument">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <form autocomplete="off" #modelDocumentForm="ngForm" [ngClass]="{'tw-flex tw-flex-auto tw-flex-col tw-p-4' : selected}">
                <input #importModelDocumentId type="file" accept=".tilbytemplate" (change)="importModelDocument($event, modelDocumentForm.form.dirty)" style="display: none;" />
                <div class="tw-flex  tw-flex-col" *ngIf="selected">
                    <div class="tw-flex tw-flex-row tw-mt-2.5">
                        <mat-form-field class="tw-flex-1" appearance="fill">
                            <mat-label>{{'SETTINGS.MODEL_DOCUMENT.NAME'|translate}}</mat-label>
                            <mat-icon matIconPrefix>style</mat-icon>
                            <input matInput name="name" [(ngModel)]="selectedModelDocument.name" required>
                            <mat-error *ngIf="modelDocumentForm.form.controls.name?.invalid">{{'NG_MESSAGES.REQUIRED_FIELD'|translate}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="tw-flex-1" appearance="fill">
                            <mat-label>{{'SETTINGS.MODEL_DOCUMENT.TYPE_DOCUMENT'|translate}}</mat-label>
                            <mat-icon matIconPrefix>title</mat-icon>
                            <mat-select name="type" [(ngModel)]="selectedModelDocument.type">
                                <mat-option *ngFor="let dt of documentType" [value]="dt.value">
                                    {{ dt.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="tw-flex tw-flex-row tw-mt-2.5">
                        <mat-form-field class="tw-flex-1" appearance="fill">
                            <mat-label>{{'SETTINGS.MODEL_DOCUMENT.PROGRESSIVE_PREFIX'|translate}}</mat-label>
                            <mat-icon matIconPrefix>receipt</mat-icon>
                            <input matInput name="progressive_prefix" [(ngModel)]="selectedModelDocument.progressive_prefix">
                        </mat-form-field>
                    </div>
                    <div class="tw-flex tw-flex-row tw-flex-wrap tw-mt-2.5">
                        <mat-slide-toggle class="tw-flex-1" name="has_progressive" [(ngModel)]="selectedModelDocument.has_progressive">
                            {{'SETTINGS.MODEL_DOCUMENT.HAS_PROGRESSIVE'|translate}}
                        </mat-slide-toggle>
                        <mat-slide-toggle class="tw-flex-1" name="progressive_per_printer" [(ngModel)]="selectedModelDocument.progressive_per_printer">
                            {{'SETTINGS.MODEL_DOCUMENT.PROGRESSIVE_PER_PRINTER'|translate}}
                        </mat-slide-toggle>
                    </div>
                    <div class="tw-flex tw-flex-row tw-flex-wrap tw-mt-2.5 tw-mb-2.5">
                        <mat-slide-toggle class="tw-flex-1" name="daily_progressive" [(ngModel)]="selectedModelDocument.daily_progressive">
                            {{'SETTINGS.MODEL_DOCUMENT.DAILY_PROGRESSIVE'|translate}}
                        </mat-slide-toggle>
                        <mat-slide-toggle class="tw-flex-1" name="reset_progressive_on_closing" [(ngModel)]="selectedModelDocument.reset_progressive_on_closing">
                            {{'SETTINGS.MODEL_DOCUMENT.RESET_PROGRESSIVE_ON_CLOSING'|translate}}
                        </mat-slide-toggle>
                    </div>
                    <mat-divider class="tw-mt-2.5 tw-mb-2.5"></mat-divider>
                    <div class="wrapper-item tw-mt-3">
                        <div class="tw-flex tw-flex-col item-container" *ngIf="selectedModelDocument.rules && selectedModelDocument.rules.length > 0">
                            <mat-accordion displayMode="default">
                                <mat-expansion-panel *ngFor="let item of selectedModelDocument.rules; let i=index;"
                                    [expanded]="item.expansionPanel" (opened)="item.expansionPanel = true" (closed)="item.expansionPanel = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="tw-basis-4/5">
                                            <span>{{ getDetails(item) }}</span>
                                        </mat-panel-title>
                                        <mat-panel-description class="tw-justify-end">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="fnClickMenu($event)" aria-label="menu open or close accordion">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="deleteItem(item)" aria-label="Delete button">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>{{'SETTINGS.MODEL_DOCUMENT.MENU.DELETE'|translate}}</span>
                                                </button>
                                                <button mat-menu-item (click)="duplicateItem(item)" aria-label="Duplicate button">
                                                    <mat-icon>content_copy</mat-icon>
                                                    <span>{{'SETTINGS.MODEL_DOCUMENT.MENU.DUPLICATE'|translate}}</span>
                                                </button>
                                            </mat-menu>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <ng-template matExpansionPanelContent [ngSwitch]="item.type">
                                        <div class="tw-flex tw-flex-row tw-mt-2.5">
                                            <!-- type -->
                                            <mat-form-field class="tw-flex-1" appearance="fill">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.TYPE_BLOCK'|translate}}</mat-label>
                                                <mat-select [name]="'itemType'+i" [(ngModel)]="item.type" (change)="changeType(item)">
                                                    <mat-option *ngFor="let el of types" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('shop_preference')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.PREFERENCE_ID'|translate}}</mat-label>
                                                <mat-select [name]="'preference_id'+i" [(ngModel)]="item.options.preference_id">
                                                    <mat-option *ngFor="let el of shop_preferences" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('shop_setting')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.SETTING_ID'|translate}}</mat-label>
                                                <mat-select [name]="'setting_id'+i" [(ngModel)]="item.options.setting_id">
                                                    <mat-option *ngFor="let el of shop_settings" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('sale_field')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.FIELD_ID'|translate}}</mat-label>
                                                <mat-select [name]="'field_id'+i" [(ngModel)]="item.options.field_id">
                                                    <mat-option *ngFor="let el of sale_fields" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <div class="tw-flex-1 tw-flex tw-content-center tw-items-center tw-pl-1" *ngIf="item.type === 'sale_payments' || item.type === 'sale_vats'">
                                                <mat-slide-toggle [name]="'print_currency'+i" [(ngModel)]="item.options.print_currency">
                                                    {{'SETTINGS.MODEL_DOCUMENT.PRINT_CURRENCY'|translate}}
                                                </mat-slide-toggle>
                                            </div>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('separator')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.ROWS'|translate}}</mat-label>
                                                <mat-select [name]="'rows'+i" [(ngModel)]="item.options.rows">
                                                    <mat-option *ngFor="let el of separatorRows" [value]="el">
                                                        {{ el }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('barcode_fixed')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_VALUE'|translate}}</mat-label>
                                                <input matInput [name]="'barcode_value'+i" [(ngModel)]="item.options.barcode_value" required>
                                                <mat-error *ngIf="modelDocumentForm.form.controls.barcode_value?.invalid">
                                                    {{'NG_MESSAGES.REQUIRED_FIELD'|translate}}
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('barcode_sale_field')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_SALE_FIELS.LABEL'|translate}}</mat-label>
                                                <mat-select [name]="'field_id'+i" [(ngModel)]="item.options.field_id">
                                                    <mat-option *ngFor="let el of barcode_fields_id" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="tw-flex tw-flex-col" *ngIf="(item.type === 'barcode_fixed' || item.type === 'barcode_sale_field') && item.expansionPanel">
                                            <div class="tw-flex tw-flex-row tw-mt-2.5">
                                                <mat-form-field class="tw-flex-1" appearance="fill">
                                                    <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_TYPE'|translate}}</mat-label>
                                                    <mat-select [name]="'barcode_type'+i" [(ngModel)]="item.options.barcode_type">
                                                        <mat-option *ngFor="let el of barcode_type" [value]="el.value">
                                                            {{ el.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="tw-flex-1" appearance="fill">
                                                    <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_HRI.LABEL'|translate}}</mat-label>
                                                    <mat-select [name]="'barcode_hri'+i" [(ngModel)]="item.options.barcode_hri">
                                                        <mat-option *ngFor="let el of barcode_hri" [value]="el.value">
                                                            {{ el.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="tw-flex-1" appearance="fill">
                                                    <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_FONT'|translate}}</mat-label>
                                                    <mat-select [name]="'barcode_font'+i" [(ngModel)]="item.options.barcode_font">
                                                        <mat-option *ngFor="let el of barcode_font" [value]="el.value">
                                                            {{ el.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="tw-flex tw-flex-row tw-mt-2.5">
                                                <mat-form-field class="tw-flex-1" appearance="fill">
                                                    <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_WIDTH'|translate}}</mat-label>
                                                    <mat-select [name]="'width'+i" [(ngModel)]="item.options.width">
                                                        <mat-option *ngFor="let el of barcode_width" [value]="el">
                                                            {{ el }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="tw-flex-1" appearance="fill">
                                                    <mat-label>{{'SETTINGS.MODEL_DOCUMENT.BARCODE_HEIGHT'|translate}}</mat-label>
                                                    <mat-select [name]="'height'+i" [(ngModel)]="item.options.height">
                                                        <mat-option *ngFor="let el of barcode_height" [value]="el">
                                                            {{ el }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                        </div>

                                        <div class="tw-flex tw-flex-row tw-mt-2.5" *ngSwitchCase="('sale_vats')">
                                            <mat-slide-toggle class="tw-flex-1" [name]="'print_code'+i"
                                            [(ngModel)]="item.options.print_code">{{'SETTINGS.MODEL_DOCUMENT.PRINT_CODE'|translate}}</mat-slide-toggle>
                                            <mat-slide-toggle class="tw-flex-1" [name]="'print_value'+i"
                                            [(ngModel)]="item.options.print_value">{{'SETTINGS.MODEL_DOCUMENT.PRINT_VALUE'|translate}}</mat-slide-toggle>
                                            <mat-slide-toggle class="tw-flex-1" [name]="'print_taxable'+i"
                                            [(ngModel)]="item.options.print_taxable">{{'SETTINGS.MODEL_DOCUMENT.PRINT_TAXABLE'|translate}}</mat-slide-toggle>
                                            <mat-slide-toggle class="tw-flex-1" [name]="'print_tax'+i"
                                            [(ngModel)]="item.options.print_tax">{{'SETTINGS.MODEL_DOCUMENT.PRINT_TAX'|translate}}</mat-slide-toggle>
                                            <mat-slide-toggle class="tw-flex-1" [name]="'print_total'+i"
                                            [(ngModel)]="item.options.print_total">{{'SETTINGS.MODEL_DOCUMENT.PRINT_TOTAL'|translate}}</mat-slide-toggle>
                                        </div>

                                        <div class="tw-flex tw-flex-row tw-mt-2.5" *ngIf="isShown(item)">
                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('text')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.TEXT_CONTENT'|translate}}</mat-label>
                                                <textarea matInput [name]="'content'+i" [(ngModel)]="item.options.content"></textarea>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('date_time')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.FORMAT'|translate}}</mat-label>
                                                <mat-select [name]="'format'+i" [(ngModel)]="item.options.format">
                                                    <mat-option *ngFor="let el of dateTimeFormats" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngIf="item.type === 'date_time' || item.type === 'progressive' || item.type === 'sale_field'">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.PREFIX'|translate}}</mat-label>
                                                <input matInput [name]="'prefix'+i" [(ngModel)]="item.options.prefix">
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('progressive')">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.SUFFIX'|translate}}</mat-label>
                                                <input matInput [name]="'suffix'+i" [(ngModel)]="item.options.suffix">
                                            </mat-form-field>
                                        </div>

                                        <div class="tw-flex tw-flex-row tw-mt-2.5" *ngIf="isShown(item)">
                                            <mat-form-field class="tw-flex-1" appearance="fill">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.MARGIN_TOP'|translate}}</mat-label>
                                                <mat-select [name]="'margin_top'+i" [(ngModel)]="item.margin_top">
                                                    <mat-option *ngFor="let el of marginTop" [value]="el">
                                                        {{ el }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="tw-flex-1" appearance="fill">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.MARGIN_BOTTOM'|translate}}</mat-label>
                                                <mat-select [name]="'margin_bottom'+i" [(ngModel)]="item.margin_bottom">
                                                    <mat-option *ngFor="let el of marginBottom" [value]="el">
                                                        {{ el }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="text-style-bar tw-flex tw-flex-row tw-mt-2.5" *ngIf="isShown(item)">
                                            <mat-form-field class="tw-flex-1" appearance="fill">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.FONT_SIZE'|translate}}</mat-label>
                                                <mat-select [name]="'font_size'+i" [(ngModel)]="item.font_size">
                                                    <mat-option *ngFor="let el of fontSize" [value]="el">
                                                        {{ el }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <div class="tw-flex-1 tw-flex tw-flex-col sm:tw-flex-row tw-flex-wrap tw-justify-evenly tw-content-center">
                                                <div class="tw-flex-1 tw-flex tw-flex-col">
                                                    <div class="text-group-button">{{'SETTINGS.MODEL_DOCUMENT.ALIGN.TITLE'|translate}}</div>
                                                    <div class="tw-flex tw-flex-row tw-flex-wrap tw-justify-center tw-content-center group-button">
                                                        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [name]="'align'+i" [(ngModel)]="item.align">
                                                            <mat-button-toggle *ngFor="let align of aligns" [value]="align.value" aria-label="Text align {{align.value}}">
                                                                <mat-icon *ngIf="align.value === 'left'">format_align_left</mat-icon>
                                                                <mat-icon *ngIf="align.value === 'center'">format_align_center</mat-icon>
                                                                <mat-icon *ngIf="align.value === 'right'">format_align_right</mat-icon>
                                                            </mat-button-toggle>
                                                        </mat-button-toggle-group>
                                                    </div>
                                                </div>
                                                <div class="tw-flex-1 tw-flex tw-flex-col tw-mt-4 md:tw-mt-0">
                                                    <div class="text-group-button">{{'SETTINGS.MODEL_DOCUMENT.BOLD_TITLE'|translate}}</div>
                                                    <div class="tw-flex tw-flex-row tw-flex-wrap tw-justify-center tw-content-center group-button">
                                                        <button mat-icon-button class="button-self radius10" aria-label="Font Weight Button"
                                                        (click)="changeFontWeight(item)" [ngClass]="{'isActive': item.bold}">
                                                            <mat-icon>format_bold</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tw-flex tw-flex-row tw-mt-2.5" *ngSwitchCase="('sale_field')">
                                            <mat-form-field class="tw-flex-1" appearance="fill">
                                                <mat-label>{{'SETTINGS.MODEL_DOCUMENT.SKIP_EMPTY.LABEL'|translate}}</mat-label>
                                                <mat-select [name]="'skip_empty'+i" [(ngModel)]="item.options.skip_empty">
                                                    <mat-option *ngFor="let el of skipEmpty" [value]="el.value">
                                                        {{ el.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="tw-flex tw-flex-col tw-mt-2.5 wrapper-fields" *ngIf="(item.type === 'sale_content' || item.type === 'sale_totals')">
                                            <div class="text-group-button">{{'SETTINGS.MODEL_DOCUMENT.FIELDS'|translate}}</div>
                                            <div class="tw-flex tw-flex-row tw-flex-wrap">
                                                <div class="field tw-flex tw-flex-col" *ngFor="let field of item.options.fields;let j=index;">
                                                    <div class="tw-flex-1 tw-flex tw-justify-end tw-mb-2.5">
                                                        <button mat-icon-button class="remove-field" aria-label="Button Remove field" (click)="removeField(item, field)">
                                                            <mat-icon>highlight_off</mat-icon>
                                                        </button>
                                                    </div>

                                                    <mat-form-field class="tw-flex-1" appearance="fill">
                                                        <mat-label>{{'SETTINGS.MODEL_DOCUMENT.INDEX'|translate}}</mat-label>
                                                        <input matInput type="number" min="0" [name]="'fieldIndex'+j" [(ngModel)]="field.index">
                                                    </mat-form-field>

                                                    <mat-form-field class="tw-flex-1" appearance="fill">
                                                        <mat-label>{{'SETTINGS.MODEL_DOCUMENT.FIELD'|translate}}</mat-label>
                                                        <mat-select [name]="'fieldField'+j" [(ngModel)]="field.field">
                                                            <ng-container *ngSwitchCase="('sale_content')">
                                                                <mat-option *ngFor="let el of saleContentFields" [value]="el.value">
                                                                    {{ el.name }}
                                                                </mat-option>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="('sale_totals')">
                                                                <mat-option *ngFor="let el of saleTotalsFields" [value]="el.value">
                                                                    {{ el.name }}
                                                                </mat-option>
                                                            </ng-container>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('sale_content')">
                                                        <mat-label>{{'SETTINGS.MODEL_DOCUMENT.PATTERN'|translate}}</mat-label>
                                                        <input matInput [name]="'fieldPattern'+j" [(ngModel)]="field.pattern">
                                                    </mat-form-field>

                                                    <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('sale_totals')">
                                                        <mat-label>{{'SETTINGS.MODEL_DOCUMENT.PREFIX'|translate}}</mat-label>
                                                        <input matInput [name]="'fieldPrefix'+j" [(ngModel)]="field.prefix">
                                                    </mat-form-field>

                                                    <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('sale_content')">
                                                        <mat-label>{{'SETTINGS.MODEL_DOCUMENT.SKIP_EMPTY.LABEL'|translate}}</mat-label>
                                                        <mat-select [name]="'fieldSkip_empty'+j" [(ngModel)]="field.skip_empty">
                                                            <mat-option *ngFor="let el of skipEmpty" [value]="el.value">
                                                                {{ el.name }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <mat-form-field class="tw-flex-1" appearance="fill" *ngSwitchCase="('sale_content')">
                                                        <mat-label>{{'SETTINGS.MODEL_DOCUMENT.LENGTH'|translate}}</mat-label>
                                                        <mat-select [name]="'fieldLength'+j" [(ngModel)]="field.length">
                                                            <mat-option *ngFor="let el of length" [value]="el">
                                                                {{ el }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <section class="tw-flex-1 tw-flex tw-flex-col tw-items-center" *ngSwitchCase="('sale_totals')">
                                                        <mat-checkbox color="primary" [name]="'fieldCurrency'+j" [(ngModel)]="field.currency">
                                                            {{'SETTINGS.MODEL_DOCUMENT.CURRENCY'|translate}}
                                                        </mat-checkbox>
                                                    </section>

                                                    <div class="tw-flex-1 tw-flex tw-flex-col tw-mt-1.5">
                                                        <div class="text-group-button">{{'SETTINGS.MODEL_DOCUMENT.ALIGN.TITLE'|translate}}</div>
                                                        <div class="group-button tw-flex tw-flex-row tw-content-center tw-justify-center group-button">
                                                            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [name]="'fieldalign'+j" [(ngModel)]="field.align">
                                                                <mat-button-toggle *ngFor="let align of aligns" [value]="align.value" aria-label="Text align {{align.value}}">
                                                                    <mat-icon *ngIf="align.value === 'left'">format_align_left</mat-icon>
                                                                    <mat-icon *ngIf="align.value === 'center'">format_align_center</mat-icon>
                                                                    <mat-icon *ngIf="align.value === 'right'">format_align_right</mat-icon>
                                                                </mat-button-toggle>
                                                            </mat-button-toggle-group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button mat-fab class="btn-field" color="primary" aria-label="Add new field" (click)="addNewField(item)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </ng-template>

                                    <mat-action-row class="tw-justify-center">
                                        <div class="tw-flex tw-flex-col">
                                            <div class="text-order-button">
                                                {{'SETTINGS.MODEL_DOCUMENT.PRINTER_ORDER'|translate}}
                                            </div>
                                            <div class="tw-flex tw-flex-row tw-items-align-center">
                                                <button mat-mini-fab class="btn-printers-order" (click)="upIndex(item, i)" [disabled]="i === 0" aria-label="arrow drop up" matTooltip="tooltipButtonOrderUp">
                                                    <mat-icon>arrow_drop_up</mat-icon>
                                                </button>
                                                <button mat-mini-fab class="btn-printers-order" style="pointer-events: none;" aria-label="index">
                                                    {{ i + 1 }}
                                                </button>
                                                <button mat-mini-fab class="btn-printers-order" (click)="downIndex(item, i)" [disabled]="i === selectedModelDocument.rules.length - 1" aria-label="arrow drop down" matTooltip="tooltipButtonOrderDown">
                                                    <mat-icon>arrow_drop_down</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </mat-action-row>
                                </mat-expansion-panel>
                            </mat-accordion>

                        </div>
                        <button mat-raised-button class="button-add-new-block" (click)="addNewBlock()" aria-label="Add new block button">
                            <span>{{'SETTINGS.MODEL_DOCUMENT.ADD_BLOCK'|translate}}</span>
                        </button>
                        <!--div class="debugger model-document-preview tw-flex tw-flex-col">
                            <pre style="overflow: auto;">{{ modelDocumentForm.value | json }}</pre>
                            <pre style="overflow: auto;">{{ selectedModelDocument | json }}</pre>
                        </div-->
                        <div style="height: 200px;"></div>
                    </div>
                </div>
            </form>
            <div *ngIf="!selected" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh-113px)]">
                <mat-icon style="transform: scale(7);" class="tw-mb-20">
                    <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
                <div class="tw-pl-10 tw-pr-10 tw-text-center">{{'SETTINGS.MODEL_DOCUMENT.NOT_SELECTED_MSG'|translate}}</div>
            </div>
        </section>
    </div>
</div>
