<tilby-dialog-toolbar title="APPLICATION.SUPPORT_DIALOG.TITLE" [hideConfirm]="true"/>

<tilby-dialog-content>
    <h1 translate>APPLICATION.SUPPORT_DIALOG.QUESTION</h1>
    <form autocomplete="off" name="supportDialogForm" (ngSubmit)="search()" #supportDialogForm="ngForm">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="wrapper-search tw-flex tw-flex-row tw-gap-4 tw-justify-around tw-items-center">
                <mat-form-field class="input-container-search tw-basis-10/12 the-dense-zone no-space-bottom">
                    <mat-label translate>APPLICATION.SUPPORT_DIALOG.SEARCH_PLACEHOLDER</mat-label>
                    <input matInput type="text" [(ngModel)]="data.query" name="query">
                </mat-form-field>
                <button mat-raised-button class="tw-basis-3/12" color="primary" type="submit" aria-label="Search button"
                        [mat-dialog-close]="data.query" cdkFocusInitial>
                    <span translate>APPLICATION.SUPPORT_DIALOG.SEARCH</span>
                </button>
            </div>
            <button mat-raised-button color="primary" (click)="consultManuals()"
                    aria-label="Consult the manuals button" [mat-dialog-close]="data.query" cdkFocusInitial>
                <span translate>APPLICATION.SUPPORT_DIALOG.BTN_MANUALS</span>
            </button>
            <button mat-raised-button color="primary" (click)="openTicket()"
                    aria-label="Open ticket button" [mat-dialog-close]="data.query" cdkFocusInitial>
                <span translate>APPLICATION.SUPPORT_DIALOG.BTN_OPEN_TICKET</span>
            </button>
            <button mat-raised-button color="primary" (click)="shareScreen()"
                    aria-label="Share screen button" cdkFocusInitial>
                <span translate>APPLICATION.SUPPORT_DIALOG.BTN_SHARE_SCREEN</span>
            </button>
        </div>
    </form>
</tilby-dialog-content>

