import * as angular from 'angular';

angular.module("tables").constant("tables", [
	{
	    "id": 1,
	    "shape": "circle",
	    "icon": "ic_table_circle.svg",
	    "label": "TABLES.SHAPES.CIRCLE",
	    "sizes": [{
	        "dimension": "4",
	        "shape": "circle4",
	        "icon": "ic_table_circle4.svg",
	        "width": 96,
	        "height": 96
	    }, {
	        "dimension": "8",
	        "shape": "circle8",
	        "icon": "ic_table_circle8.svg",
	        "width": 192,
	        "height": 192
	    }]
	}, {
	    "id": 2,
	    "shape": "square",
	    "icon": "ic_table_square.svg",
	    "label": "TABLES.SHAPES.SQUARE",
	    "sizes": [{
	        "dimension": "1 x 1",
	        "shape": "rect1x1",
	        "icon": "ic_table_rect1x1.svg",
	        "width": 96,
	        "height": 96
	    }]
	}, {
	    "id": 3,
	    "shape": "rect_vert",
	    "icon": "ic_table_rect_vert.svg",
	    "label": "TABLES.SHAPES.RECT_VERT",
	    "sizes": [{
	        "dimension": "1 x 2",
	        "shape": "rect1x2",
	        "icon": "ic_table_rect1x2.svg",
	        "width": 96,
	        "height": 192
	    }, {
	        "dimension": "1 x 3",
	        "shape": "rect1x3",
	        "icon": "ic_table_rect1x3.svg",
	        "width": 96,
	        "height": 256
	    }]
	}, {
	    "id": 4,
	    "shape": "rect_hor",
	    "icon": "ic_table_rect_hor.svg",
	    "label": "TABLES.SHAPES.RECT_HORIZ",
	    "sizes": [{
	        "dimension": "2 x 1",
	        "shape": "rect2x1",
	        "icon": "ic_table_rect2x1.svg",
	        "width": 192,
	        "height": 96
	    }, {
	        "dimension": "3 x 1",
	        "shape": "rect3x1",
	        "icon": "ic_table_rect3x1.svg",
	        "width": 256,
	        "height": 96
	    }]
	}, {
	    "id": 5,
	    "shape": "chair",
	    "icon": "ic_chair.svg",
	    "label": "TABLES.SHAPES.CHAIR",
	    "sizes": [{
	        "dimension": "1 x 1",
	        "shape": "chair1x1",
	        "icon": "ic_chair1x1.svg",
	        "width": 40,
	        "height": 40
	    }]
	}
]);