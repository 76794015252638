

export type CashregisterCategoriesType = 'tabs' | 'grid';
export type CashregisterItemsType = 'with_photo' | 'without_photo' | 'list';
export type CashregisterViewType = 'normal' | 'compact';
export type CashregisterOrderType = 'name' | 'index' | 'price_asc' | 'price_desc';
export type CashregisterAvailabilityType = 'all_items' | 'only_items_available';
export type CashregisterKeyboardType = 'normal' | 'big';
export type CashregisterSearchCategoryType = 'only_selected_category' | 'all_categories';

export interface CashregisterCategories {
    id: string;
    name: CashregisterCategoriesType;
    imageUrl: `${CashregisterCategoriesType}.svg`;
}

export interface CashregisterItems {
    id: string;
    name: CashregisterItemsType;
    imageUrl: `${CashregisterItemsType}.svg`;
}

export interface CashregisterView {
    id: string;
    name: CashregisterViewType;
    imageUrl: `${CashregisterViewType}.svg`;
}

export interface CashregisterOrder {
    id: string;
    name: CashregisterOrderType;
}

export interface CashregisterAvailability {
    id: string;
    name: CashregisterAvailabilityType;
}

export interface CashregisterSearchCategories {
    id: string;
    name: CashregisterSearchCategoryType;
}

export const CASHREGISTER_CATEGORIES: CashregisterCategories[] = [
    {
        "id": "tab",
        "name": "tabs",
        "imageUrl": "tabs.svg"
    },
    {
        "id": "grid",
        "name": "grid",
        "imageUrl": "grid.svg"
    }
];

export const CASHREGISTER_ITEMS: CashregisterItems[] = [
    {
        "id": 'photoGrid',
        "name": "with_photo",
        "imageUrl": "with_photo.svg"
    },
    {
        "id": 'noPhotoGrid',
        "name": "without_photo",
        "imageUrl": "without_photo.svg"
    },
    {
        "id": 'list',
        "name": "list",
        "imageUrl": "list.svg"
    }
];

export const CASHREGISTER_VIEW: CashregisterView[] = [
    {
        "id": "normal",
        "name": "normal",
        "imageUrl": "normal.svg"
    },
    {
        "id": "compact",
        "name": "compact",
        "imageUrl": "compact.svg"
    }
];

export const CASHREGISTER_ORDER: CashregisterOrder[] = [
    {
        "id": "+name",
        "name": "name"
    },
    {
        "id": "+index",
        "name": "index"
    },
    {
        "id": "+price",
        "name": "price_asc"
    },
    {
        "id": "-price",
        "name": "price_desc"
    }
];

export const CASHREGISTER_AVAILABILITY: CashregisterAvailability[] = [
    {
        "id": "true",
        "name": "all_items"
    },
    {
        "id": "false",
        "name": "only_items_available"
    }
]

export const CASHREGISTER_SEARCH_IN_ALL_CATEGORIES: CashregisterSearchCategories[] = [
    {
        "id": "false",
        "name": "only_selected_category"
    },
    {
        "id": "true",
        "name": "all_categories"
    }
]
