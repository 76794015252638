import * as angular from 'angular';
import * as _ from 'lodash';
import {filter, firstValueFrom, map, mergeMap, Observable, Subject} from 'rxjs';
import {TilbyTcpSocket, TilbyTcpSocketUtils} from 'src/app/shared/net/tilby-tcp-socket';
import {ZvtPosResources} from './zvt-pos-resources';

export interface ZvtPosStatusResponse {
    additionalText?: string
    aidParameter?: any
    amount?: number
    cardName?: string
    cardSequenceNumber?: string
    cardType?: number
    cardTypeID?: number
    contractNumber?: string
    currencyCode?: number
    date?: string
    expirationDate?: string
    geldkartePayment?: any
    lockedGoodsGroups?: any
    originalTrace?: string
    PAN?: any
    paymentType?: any
    receiptNumber?: any
    resultCodeAs?: any
    statusCode?: number
    terminalId?: any
    time?: string
    trace?: string
    turnoverNumber?: any
}

export class ZvtPosDriver {
    private amount?: number;
    private terminalPassword?: string;
    private ipAddress?: string;
    private port?: string;
    private socket?: TilbyTcpSocket;
    private socketData?: Observable<Uint8Array>;
    private intermediateStatus?: Subject<string>;

    constructor(
        private util: any,
    ) {
    }

    private logEvent(...message: any) {
        console.debug('[ ZvtPosDriver ]', ...message);
    };

    private numStringToBCD(numString: string) {
        return _.chain(numString)
            .split('')
            .chunk(2)
            .map((chunk) => _.join(chunk, ''))
            .map((byte) => parseInt(byte, 16))
            .value();
    }

    private BCDToNumString(bcdArray: Uint8Array): string {
        return Array.from(bcdArray).map(byte => byte.toString(16).padStart(2, '0')).join('');
    }

    private getPasswordData() {
        return this.numStringToBCD(this.terminalPassword || '');
    };

    private getPacket(commandBlock: number[], dataBlock: number[]) {
        return ([
            ...commandBlock,
            parseInt(dataBlock.length.toString(), 16),
            ...dataBlock
        ]);
    }

    private async sendCommand(command: any) {
        await this.socket?.send(new Uint8Array(command));
    };

    private async sendAck() {
        await this.sendCommand([0x80, 0x00, 0x00]);
    };

    private async establishConnection() {
        try {
            await this.socket?.connect(this.ipAddress!, parseInt(this.port!), {timeout: 10000});
        } catch (error) {
            throw 'CONNECTION ERROR';
        }
    };

    private async performRegistration() {
        let registrationPacket = this.getPacket([0x06, 0x00], [
            ...this.getPasswordData(),
            (
                0b10000000 | //Receipt-printout via ECR using command "Print Lines" (06D1)
                0b00000000 | //RFU
                0b00000000 | //Start of administration function on PT possible
                0b00000000 | //Amount input on PT possible
                0b00001000 | //ECR does not require intermediate status-Information
                0b00000000 | //Administration receipt not printed by ECR
                0b00000000 | //Receipt printed by the PT
                0b00000000   //RFU
            )
        ]);

        this.sendCommand(registrationPacket);

        let completionCommandObservable = this.socketData!.pipe(
            filter((packet) => this.getPacketCommand(packet) === '060f')
        )

        await firstValueFrom(completionCommandObservable);
        await this.sendAck();
    }

    private async performLogoff() {
        let logOffPacket = this.getPacket([0x06, 0x02], []);

        await this.sendCommand(logOffPacket);
    }

    private async startPaymentTransaction() {
        this.logEvent(`Start paying ${this.amount}...`);

        const ackPattern = [0x80, 0x00, 0x00];

        this.socket = TilbyTcpSocketUtils.getSocket();

        this.socketData = this.socket!.onData.pipe(
            map((packet: Uint8Array) => {
                //If the payment terminal returns the ack and the answer in a single packet, return the packets separately
                if (ackPattern.every((byte, idx) => (packet[idx] === byte)) && packet.length > 3) {
                    return [packet.slice(0, 3), packet.slice(3)];
                }

                return [packet];
            }),
            mergeMap((packets: Uint8Array[]) => packets)
        );

        try {
            //Connection step
            await this.establishConnection();
            //Registration Step
            await this.performRegistration();
            await new Promise(resolve => setTimeout(resolve, 250));

            //Payment Step
            try {
                if ((this.amount ?? 0) >= 0) {
                    await this.doPayment();
                } else {
                    throw 'REVERSAL_NOT_AVAILABLE';
                }
            } finally {
                await this.performLogoff();
            }
        } catch (error) {
            throw error;
        } finally {
            await this.cleanup();
        }
    };

    private parseIntermediateStatusInformation(packet: Uint8Array): string {
        return ZvtPosResources.intermediateStatusInformationMap[packet[3]];
    }

    private parseErrorStatusMessage(packet: Uint8Array): string {
        let errorCode = packet[3];

        if (errorCode > 0 && errorCode < 100) {
            return 'Transaction failed';
        }

        return ZvtPosResources.errorMessagesInformationMap[packet[3]];
    }

    private getVariableLengthSegment(packet: Uint8Array, tagOffset: number, headerSize: number): Uint8Array {
        let headerStart = tagOffset + 1;
        let header = packet.slice(headerStart, headerStart + headerSize);
        let dataLength = parseInt(Array.from(header.map((byte) => byte ^ 0xf0)).join(''));

        return packet.slice(headerStart + headerSize, headerStart + headerSize + dataLength);
    }

    private getDataSegment(packet: Uint8Array, tagOffset: number, dataLength: number): Uint8Array {
        return packet.slice(tagOffset + 1, tagOffset + 1 + dataLength);
    }

    private byteArrayToASCII(packet: Uint8Array) {
        return Array.from(packet).map((byte) => String.fromCharCode(byte)).join('');
    }

    private parseStatusInformation(packet: Uint8Array) {
        let statusInformation: ZvtPosStatusResponse = {
            statusCode: packet[3]
        };

        for (let i = 4; i < packet.length; i++) {
            let dataLength = 0;
            let dataSegment;

            switch (packet[i]) {
                case 0x04: //Amount (6 byte BCD)
                    dataLength = 6;
                    statusInformation.amount = parseInt(this.BCDToNumString(this.getDataSegment(packet, i, dataLength))) / 100;
                    break;
                case 0x0b: //Trace (3 byte BCD)
                    dataLength = 3;
                    statusInformation.trace = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x37: //Original Trace (Reversal) (3 byte BCD)
                    dataLength = 3;
                    statusInformation.originalTrace = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x0c: //Time (3 byte BCD) HHMMSS
                    dataLength = 3;
                    statusInformation.time = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x0d: //Date (2 byte BCD) MMDD
                    dataLength = 2;
                    statusInformation.date = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x0e: //Exp. Date (2 byte BCD) MMYY
                    dataLength = 2;
                    statusInformation.expirationDate = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x17: //card sequence number, (2 byte BCD packed)
                    dataLength = 2;
                    statusInformation.cardSequenceNumber = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x19: //Payment type (1 byte)
                    dataLength = 1;
                    statusInformation.paymentType = packet[i + 1];
                    break;
                case 0x22: //PAN / EF_ID (LLVAR)
                    dataSegment = this.getVariableLengthSegment(packet, i, 2);
                    dataLength = dataSegment.length + 2;
                    statusInformation.PAN = this.BCDToNumString(dataSegment).replace(/e/g, "*").replace(/f/g, "");
                    break;
                case 0x29: //terminal-ID (4 byte BCD packed)
                    dataLength = 4;
                    statusInformation.terminalId = this.BCDToNumString(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x49: //Currency Code (2 byte BCD packed)
                    dataLength = 2;
                    statusInformation.currencyCode = parseInt(this.BCDToNumString(this.getDataSegment(packet, i, dataLength)));
                    break;
                case 0x4c: //locked goods groups (LLVAR)
                    dataSegment = this.getVariableLengthSegment(packet, i, 2);
                    dataLength = dataSegment.length + 2;
                    statusInformation.lockedGoodsGroups = dataSegment;
                    break;
                case 0x87: //Receipt number (2 byte BCD packed).
                    dataLength = 2;
                    statusInformation.receiptNumber = parseInt(this.BCDToNumString(this.getDataSegment(packet, i, dataLength)));
                    break;
                case 0x8a: //card-type (= ZVT card-type ID) (1 byte binary)
                    dataLength = 1;
                    statusInformation.cardType = packet[i + 1];
                    break;
                case 0x8c: //card-type-ID of the network operator (1 byte binary)
                    dataLength = 1;
                    statusInformation.cardTypeID = packet[i + 1];
                    break;
                case 0x9a: //Geldkarte payment-/ failed payment records (LLLVAR)
                    dataSegment = this.getVariableLengthSegment(packet, i, 3);
                    dataLength = dataSegment.length + 3;
                    statusInformation.geldkartePayment = dataSegment;
                    break;
                case 0xba: //AID-parameter (5 byte binary)
                    dataLength = 5;
                    statusInformation.aidParameter = this.getDataSegment(packet, i, dataLength);
                    break;
                case 0x2a: //Contract-number for credit-cards, (15 byte, ASCII, not null-terminated).
                    dataLength = 15;
                    statusInformation.contractNumber = this.byteArrayToASCII(this.getDataSegment(packet, i, dataLength));
                    break;
                case 0x3c: //additional text for credit-cards, (LLLVAR, ASCII, not null-terminated).
                    dataSegment = this.getVariableLengthSegment(packet, i, 3);
                    dataLength = dataSegment.length + 3;
                    statusInformation.additionalText = this.byteArrayToASCII(dataSegment);
                    break;
                case 0xa0: //result-code-AS, (1 byte binary)
                    dataLength = 1;
                    statusInformation.resultCodeAs = packet[i + 1];
                    break;
                case 0x88: //turnover-no (3 byte BCD packed)
                    dataLength = 3;
                    statusInformation.turnoverNumber = this.getDataSegment(packet, i, dataLength);
                    break;
                case 0x8b: //name of the card-type (LLVAR, ASCII, null-terminated)
                    dataSegment = this.getVariableLengthSegment(packet, i, 2).filter((byte) => byte);
                    dataLength = dataSegment.length + 2;
                    statusInformation.cardName = this.byteArrayToASCII(dataSegment);
                    break;
                case 0x06: //TLV Container
                    //TODO
                    break;
            }

            i += dataLength;
        }

        return statusInformation;
    }

    private getPacketCommand = (packet: Uint8Array) => Array.from(packet.slice(0, 2)).map((byte) => byte.toString(16).padStart(2, '0')).join('')

    private doPayment = async () => {
        let paymentPacket = this.getPacket([0x06, 0x01], [
            0x04, ..._.chain(this.util.round((this.amount || 0) * 100)).toInteger().padStart(12, '0').thru(this.numStringToBCD).value(),
            0x19, 0b01000000 //Payment type (Auto-select)
        ]);

        this.sendCommand(paymentPacket);

        await new Promise((resolve, reject) => {
            this.socketData?.subscribe((responsePacket) => {
                let responseCommand = this.getPacketCommand(responsePacket);

                switch (responseCommand) {
                    case '040f': //Status information
                        let statusInfo = this.parseStatusInformation(responsePacket);
                        this.logEvent('Received transaction status:', statusInfo);
                        break;
                    case '04ff': //Intermediate status information
                        let intStatusInfo = this.parseIntermediateStatusInformation(responsePacket);
                        this.logEvent('Received intermediate status:', intStatusInfo);
                        this.intermediateStatus?.next(intStatusInfo);
                        break;
                    case '06d3': //Print Text block
                        //TODO ?
                        break;
                    case '060f': //Completion
                        this.logEvent('Received completion event');
                        resolve(0);
                        break;
                    case '061e': // Abort
                        this.logEvent('Received abort event');
                        reject(this.parseErrorStatusMessage(responsePacket))
                        break;
                }

                this.sendAck();
            })
        });
    };

    private async cleanup() {
        this.amount = undefined;
        this.terminalPassword = undefined;
        this.ipAddress = undefined;
        this.port = undefined;

        if (this.socket) {
            await this.socket.close();
            this.socket = undefined;
            this.socketData = undefined;
        }

        if (this.intermediateStatus) {
            this.intermediateStatus.complete();
            this.intermediateStatus = undefined;
        }
    };

    public init(terminalPassword: string, ipAddress: string, port: string) {
        this.terminalPassword = _.chain(terminalPassword).truncate({ length: 6, omission: '' }).padEnd(6, '0').value();
        this.ipAddress = ipAddress;
        this.port = port;
    };

    public performPayment(amount: number) {
        this.amount = amount;
        this.intermediateStatus = new Subject();

        return {
            finalStatus: this.startPaymentTransaction(),
            intermediateStatus: this.intermediateStatus.asObservable()
        }
    }
}

ZvtPosDriver.$inject = ['util'];

angular.module('digitalPayments').service('ZvtPosDriver', ZvtPosDriver);
