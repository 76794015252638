export interface Size<Shape extends string> {
    dimension: string;
    shape: string;
    icon: string;
    width: number;
    height: number;
}
type StaticElementsShape = 'ph_wall_h'|'ph_wall_v'|'ph_plant1'|'ph_plant2';
type  StaticArmchairShape = 'ph_armchair_dx'|'ph_armchair_sx'|'ph_armchair_top'|'ph_armchair_bottom';
type  StaticSofaShape = 'ph_sofa_dx'|'ph_sofa_sx'|'ph_sofa_top'|'ph_sofa_bottom';
type  StaticPlantShape = 'ph_plant';
type  StaticCashregisterShape = 'ph_cashreg_counter' | 'ph_cashreg_only';
export type AllStaticElementsShape =StaticElementsShape | StaticArmchairShape | StaticSofaShape | StaticPlantShape | StaticCashregisterShape;
type TableShape = 'circle'|'square'|'rect_hor'|'rect_vert'|'chair';
type Table = 'square' | 'circle4' | 'circle8' | 'oval_hor' | 'oval_vert' | 'rect_hor_6' | 'rect_vert_6' | 'rect_hor_12' | 'rect_vert_12';
type Counter = 'counter_top' | 'counter_bottom' | 'counter_dx' | 'counter_sx';
type Chair = 'chair_dx' | 'chair_sx' | 'chair_top' | 'chair_bottom';
export type AllTablesShape = /*TableShape |*/Table | Counter | Chair;

export interface TableWithIcon<Shape extends AllStaticElementsShape|AllTablesShape|TableShape> {
    id: number;
    shape: Shape;
    icon: string;
    label: string;
    sizes: Size<Shape>[];
}

export const TABLES:TableWithIcon<TableShape>[] = [
    {
        "id": 1,
        "shape": "circle",
        "icon": "ic_table_circle",
        "label": "TABLES.SHAPES.CIRCLE",
        "sizes": [{
            "dimension": "4",
            "shape": "circle4",
            "icon": "ic_table_circle4",
            "width": 96,
            "height": 96
        }, {
            "dimension": "8",
            "shape": "circle8",
            "icon": "ic_table_circle8",
            "width": 192,
            "height": 192
        }]
    },
    {
        "id": 2,
        "shape": "square",
        "icon": "ic_table_square",
        "label": "TABLES.SHAPES.SQUARE",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "rect1x1",
            "icon": "ic_table_rect1x1",
            "width": 96,
            "height": 96
        }]
    },
    {
        "id": 3,
        "shape": "rect_vert",
        "icon": "ic_table_rect_vert",
        "label": "TABLES.SHAPES.RECT_VERT",
        "sizes": [{
            "dimension": "1 x 2",
            "shape": "rect1x2",
            "icon": "ic_table_rect1x2",
            "width": 96,
            "height": 192
        }, {
            "dimension": "1 x 3",
            "shape": "rect1x3",
            "icon": "ic_table_rect1x3",
            "width": 96,
            "height": 256
        }]
    },
    {
        "id": 4,
        "shape": "rect_hor",
        "icon": "ic_table_rect_hor",
        "label": "TABLES.SHAPES.RECT_HORIZ",
        "sizes": [{
            "dimension": "2 x 1",
            "shape": "rect2x1",
            "icon": "ic_table_rect2x1",
            "width": 192,
            "height": 96
        }, {
            "dimension": "3 x 1",
            "shape": "rect3x1",
            "icon": "ic_table_rect3x1",
            "width": 256,
            "height": 96
        }]
    },
    {
        "id": 5,
        "shape": "chair",
        "icon": "ic_chair",
        "label": "TABLES.SHAPES.CHAIR",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "chair1x1",
            "icon": "ic_chair1x1",
            "width": 40,
            "height": 40
        }]
    }
];
export const NEW_TABLES:TableWithIcon<AllTablesShape>[] = [
    {
        "id": 1,
        "shape": "circle4",
        "icon": "circle4",
        "label": "TABLES.SHAPES.CIRCLE",
        "sizes": [
            {
            "dimension": "4",
            "shape": "circle4",
            "icon": "circle4",
            "width": 96,
            "height": 96
        },
            {
            "dimension": "8",
            "shape": "circle8",
            "icon": "circle8",
            "width": 96*2,
            "height": 96*2
        }
        ]
    },
    {
        "id": 2,
        "shape": "square",
        "icon": "square",
        "label": "TABLES.SHAPES.SQUARE",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "rect1x1",
            "icon": "square",
            "width": 96,
            "height": 96
        }]
    },
    {
        "id": 3,
        "shape": "rect_hor_6",
        "icon": "rect_hor_6",
        "label": "TABLES.SHAPES.RECT_HOR",
        "sizes": [{
            "dimension": "2 x 1",
            "shape": "rect2x1",
            "icon": "rect_hor_6",
            "width": 96*2,
            "height": 96
        },
            {
                "dimension": "3 x 1",
                "shape": "rect3x1",
                "icon": "ic_table_rect3x1",
                "width": 96*3,
                "height": 96
            },
            {
                "dimension": "5 x 1",
                "shape": "rect5x1",
                "icon": "rect_hor_12",
                "width": 96*5,
                "height": 96
            }
            ]
    },
    {
        "id": 4,
        "shape": "rect_vert_6",
        "icon": "rect_vert_6",
        "label": "TABLES.SHAPES.RECT_VERT",
        "sizes": [{
            "dimension": "1 x 2",
            "shape": "rect1x2",
            "icon": "rect_vert_6",
            "width": 96,
            "height": 96*2
        },
            {
                "dimension": "1 x 3",
                "shape": "rect1x3",
                "icon": "ic_table_rect1x3",
                "width": 96,
                "height": 96*3
            },
            {
            "dimension": "1 x 5",
            "shape": "rect1x5",
            "icon": "rect_vert_12",
            "width": 96,
            "height": 96*5
        }]
    },
    {
        "id": 5,
        "shape": "chair_dx",
        "icon": "chair_dx",
        "label": "TABLES.SHAPES.CHAIR",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "chair_dx",
            "icon": "chair_dx",
            "width": 40,
            "height": 40
        }]
    },
    {
        "id": 6,
        "shape": "chair_sx",
        "icon": "chair_sx",
        "label": "TABLES.SHAPES.CHAIR",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "chair_sx",
            "icon": "chair_sx",
            "width": 40,
            "height": 40
        }]
    },
    {
        "id": 7,
        "shape": "chair_top",
        "icon": "chair_top",
        "label": "TABLES.SHAPES.CHAIR",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "chair1x1",
            "icon": "chair_top",
            "width": 40,
            "height": 40
        } ]
    },
    {
        "id": 8,
        "shape": "chair_bottom",
        "icon": "chair_bottom",
        "label": "TABLES.SHAPES.CHAIR",
        "sizes": [{
            "dimension": "1 x 1",
            "shape": "chair_bottom",
            "icon": "chair_bottom",
            "width": 40,
            "height": 40
        }]
    },
    {
        "id": 9,
        "shape": "oval_hor",
        "icon": "oval_hor",
        "label": "TABLES.SHAPES.OVAL_HORIZ",
        "sizes": [{
            "dimension": "2 x 1",
            "shape": "oval_hor",
            "icon": "oval_hor",
            "width": 96*2,
            "height": 96
        }]
    },
    {
        "id": 10,
        "shape": "oval_vert",
        "icon": "oval_vert",
        "label": "TABLES.SHAPES.OVAL_VERT",
        "sizes": [{
            "dimension": "1 x 2",
            "shape": "oval_vert",
            "icon": "oval_vert",
            "width": 96,
            "height": 96*2
        }]
    },
    {
        "id": 11,
        "shape": "counter_top",
        "icon": "counter_top",
        "label": "TABLES.SHAPES.COUNTER",
        "sizes": [{
            "dimension": "4 x 1",
            "shape": "counter_top",
            "icon": "counter_top",
            "width": 96*4,
            "height": 96
        }]
    },
    {
        "id": 12,
        "shape": "counter_bottom",
        "icon": "counter_bottom",
        "label": "TABLES.SHAPES.COUNTER",
        "sizes": [{
            "dimension": "4 x 1",
            "shape": "counter_bottom",
            "icon": "counter_bottom",
            "width": 96*4,
            "height": 96
        }]
    },
    {
        "id": 13,
        "shape": "counter_dx",
        "icon": "counter_dx",
        "label": "TABLES.SHAPES.COUNTER",
        "sizes": [{
            "dimension": "1 x 4",
            "shape": "counter_dx",
            "icon": "counter_dx",
            "width": 96,
            "height": 96*4
        }]
    },
    {
        "id": 14,
        "shape": "counter_sx",
        "icon": "counter_sx",
        "label": "TABLES.SHAPES.COUNTER",
        "sizes": [{
            "dimension": "1 x 4",
            "shape": "counter_sx",
            "icon": "counter_sx",
            "width": 96,
            "height": 96*4
        }]
    },
];
export const STATIC_ELEMENTS:TableWithIcon<AllStaticElementsShape>[]=[
    {
        "id": 1,
        "shape": "ph_wall_v",
        "icon": "ph_wall_v",
        "label": "STATIC_ELEMENTS.SHAPES.WALL_V",
        "sizes": [{
            height: 300,
            width: 50,
            icon: "ph_wall_v",
            shape: "ph_wall_v",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 2,
        "shape": "ph_wall_h",
        "icon": "ph_wall_h",
        "label": "STATIC_ELEMENTS.SHAPES.WALL_H",
        "sizes": [{
            height: 50,
            width: 300,
            icon: "ph_wall_h",
            shape: "ph_wall_h",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 3,
        "shape": "ph_plant1",
        "icon": "ph_plant1",
        "label": "STATIC_ELEMENTS.SHAPES.PLANT_1",
        "sizes": [{
            height: 100,
            width: 100,
            icon: "ph_plant1",
            shape: "ph_plant1",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 4,
        "shape": "ph_plant2",
        "icon": "ph_plant2",
        "label": "STATIC_ELEMENTS.SHAPES.PLANT_2",
        "sizes": [{
            height: 100,
            width: 100,
            icon: "ph_plant2",
            shape: "ph_plant2",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 5,
        "shape": "ph_armchair_dx",
        "icon": "ph_armchair_dx",
        "label": "STATIC_ELEMENTS.SHAPES.ARMCHAIR_DX",
        "sizes": [{
            height: 60,
            width: 60,
            icon: "ph_armchair_dx",
            shape: "ph_armchair_dx",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 6,
        "shape": "ph_armchair_sx",
        "icon": "ph_armchair_sx",
        "label": "STATIC_ELEMENTS.SHAPES.ARMCHAIR_SX",
        "sizes": [{
            height: 60,
            width: 60,
            icon: "ph_armchair_sx",
            shape: "ph_armchair_sx",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 7,
        "shape": "ph_armchair_top",
        "icon": "ph_armchair_top",
        "label": "STATIC_ELEMENTS.SHAPES.ARMCHAIR_TOP",
        "sizes": [{
            height: 60,
            width: 60,
            icon: "ph_armchair_top",
            shape: "ph_armchair_top",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 8,
        "shape": "ph_armchair_bottom",
        "icon": "ph_armchair_bottom",
        "label": "STATIC_ELEMENTS.SHAPES.ARMCHAIR_BOTTOM",
        "sizes": [{
            height: 60,
            width: 60,
            icon: "ph_armchair_bottom",
            shape: "ph_armchair_bottom",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 9,
        "shape": "ph_sofa_dx",
        "icon": "ph_sofa_dx",
        "label": "STATIC_ELEMENTS.SHAPES.SOFA_DX",
        "sizes": [{
            height: 150,
            width: 60,
            icon: "ph_sofa_dx",
            shape: "ph_sofa_dx",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 10,
        "shape": "ph_sofa_sx",
        "icon": "ph_sofa_sx",
        "label": "STATIC_ELEMENTS.SHAPES.SOFA_SX",
        "sizes": [{
            height: 150,
            width: 60,
            icon: "ph_sofa_sx",
            shape: "ph_sofa_sx",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 11,
        "shape": "ph_sofa_top",
        "icon": "ph_sofa_top",
        "label": "STATIC_ELEMENTS.SHAPES.SOFA_TOP",
        "sizes": [{
            height: 60,
            width: 150,
            icon: "ph_sofa_top",
            shape: "ph_sofa_top",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 12,
        "shape": "ph_sofa_bottom",
        "icon": "ph_sofa_bottom",
        "label": "STATIC_ELEMENTS.SHAPES.SOFA_BOTTOM",
        "sizes": [{
            height: 60,
            width: 150,
            icon: "ph_sofa_bottom",
            shape: "ph_sofa_bottom",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 13,
        "shape": "ph_plant",
        "icon": "ph_plant",
        "label": "STATIC_ELEMENTS.SHAPES.PLANT",
        "sizes": [{
            height: 100,
            width: 100,
            icon: "ph_plant",
            shape: "ph_plant",
            dimension: "1 x 1"
        }]
    },
    {
        "id": 14,
        "shape": "ph_cashreg_counter",
        "icon": "ph_cashreg_counter",
        "label": "STATIC_ELEMENTS.SHAPES.CASHREGISTER_COUNTER",
        "sizes": [{
            height: 96,
            width: 96*3,
            icon: "ph_cashreg_counter",
            shape: "ph_cashreg_counter",
            dimension: "3 x 1"
        }]
    },
    {
        "id": 15,
        "shape": "ph_cashreg_only",
        "icon": "ph_cashreg_only",
        "label": "STATIC_ELEMENTS.SHAPES.CASHREGISTER_ONLY",
        "sizes": [{
            height: 96/3,
            width: 96,
            icon: "ph_cashreg_only",
            shape: "ph_cashreg_only",
            dimension: "3 x 1"
        }]
    },
]
export const STATIC_ELEMENT_PREFIX = 'ph_';
export const ALL_ROOM_ELEMENTS = [...NEW_TABLES,...STATIC_ELEMENTS];
