import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'matchesShift'
})
export class MatchesShiftPipe implements PipeTransform {
    transform(list: Array<any>, name: string): Array<any> {
        name = name.trim().toLowerCase();
        return [...list.filter(item => item.name && item.name.toLowerCase().includes(name))];
    }
}
