import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').factory('showOrderItem', ["$mdDialog", function($mdDialog) {

    showOrderItemController.$inject = ["$scope", "$mdDialog", "util", "item"];
    function showOrderItemController($scope, $mdDialog, util, item) {
        $scope.item = item;
        $scope.enableCarousel = false;

        $scope.itemImages = _.cloneDeep($scope.item.images);
        $scope.priceList = util.getCurrentPriceList();

        if (_.isEmpty($scope.itemImages)) {
            $scope.itemImages.push({
                image_url: "assets/images/sales/piatto-preview.png"
            });
        }

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.addItem = function() {
            $mdDialog.hide();
        };

        $scope.getAllergensList = function() {
            return _.map($scope.item.allergens, 'name').join(', ');
        };

        $scope.getComponentsList = function() {
            return _.map($scope.item.components, 'name').join(', ');
        };

        $scope.getPriceList = function() {
            return item['price' + $scope.priceList];
        };

        $scope.onShowComplete = function() {
            $scope.enableCarousel = true;
        };
    }

    var showOrderItem = {
        show: function(item) {
            return $mdDialog.show({
                controller: showOrderItemController,
                template: require('./show-order-item.html'),
                locals: {
                    item: item
                },
                onComplete: function(scope) {
                    scope.onShowComplete();
                }
            });
        }
    };

    return showOrderItem;
}]);
