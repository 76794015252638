import {Component, OnInit} from '@angular/core';
import {UpgradeModule} from "@angular/upgrade/static";
import {IconRegistry} from "./icons/icon-registry";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-angular',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [OnDestroyService]
})
export class AppComponent implements OnInit {
    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private upgrade: UpgradeModule,
    ) {
        new IconRegistry(iconRegistry, sanitizer);
    }

    ngOnInit(): void {
        this.upgrade.bootstrap(document.body, ['TilbyApp'], {strictDi: true});
    }

}
