import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ConfigurationManagerService } from '../core/services/configuration-manager/configuration-manager.service';
import { ConfigurationPreferences } from '../core';

@Directive({
  selector: '[touchZoom]',
  standalone: true
})
export class TouchZoomDirective {
  private initialDistance: number = 0;
  private initialScale: number = 1;
  private scale: number = 1.0;
  private doubleTouchMoved = false;

  @Input() stepConfig = '' as keyof ConfigurationPreferences;
    private _enabled=true;
  @Input() set enabled(value: boolean){
      this._enabled=value;
      if(!value){
          this.initialScale=1;
          this.el.nativeElement.style.transform = `scale(${this.initialScale})`;
      }
  }
  get enabled(){
      return this._enabled;
  }

  constructor(private el: ElementRef, protected configurationManagerService: ConfigurationManagerService) {}

  ngOnInit(): void {
    this.initialScale = this.enabled ? Number(this.configurationManagerService.getPreference(this.stepConfig)) || 1.0 : 1.0;
    this.el.nativeElement.style.transform = `scale(${this.initialScale})`;
    this.el.nativeElement.style.transformOrigin = `top left`;
  }

  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
    if (event.touches.length === 2 && this.enabled) {
      event.preventDefault();
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      this.initialDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
    }
  }

  @HostListener('touchmove', ['$event']) onTouchMove(event: TouchEvent) {
    if (event.touches.length === 2  && this.enabled) {
      event.preventDefault();
      this.doubleTouchMoved = true;
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const currentDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      const newScale = (currentDistance / this.initialDistance) * this.initialScale;
      this.scale = Math.max(0.5, Math.min(newScale, 2.0));
      this.el.nativeElement.style.transform = `scale(${this.scale})`;
      this.el.nativeElement.style.transformOrigin = `top left`;

    }
  }

  @HostListener('touchend', ['$event']) onTouchEnd(event: TouchEvent) {
    if (this.doubleTouchMoved  && this.enabled) {
      event.preventDefault();
      this.doubleTouchMoved = false;
      this.initialScale = this.scale;
      this.configurationManagerService.setUserPreference(this.stepConfig, this.scale.toString());
    }
  }
}
