<div class="tw-flex tw-items-center">
    <button class="tw-ml-2" mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
    <h2 mat-dialog-title cdkFocusInitial class="tw-pb-0 tw-pt-[3px] tw-flex tw-items-center">{{ title }}</h2>
</div>
<mat-dialog-content class="mat-typography tw-pt-0">
    <mat-selection-list #list [multiple]="false" [hideSingleSelectionIndicator]="!data.showCheckbox" (selectionChange)="selectionHandler($event)">
        @for(item of data.list; track $index){
            <mat-list-option [togglePosition]="'before'" [selected]="!!item.isSelected" [value]="item">
                <div matListItemTitle class="tw-flex">
                    @if(item.preIcon){<mat-icon [fontIcon]="item.preIcon" class="tw-pl-1"/>}
                    @if(!item.noTranslate){<div class="tw-pl-2">{{(item.name | translate:{value: item.nameValue})}}</div>}
                    @if(item.noTranslate){<div class="tw-pl-2">{{(item.name)}}</div>}
                </div>
            </mat-list-option>
            <mat-divider></mat-divider>
        }
    </mat-selection-list>
</mat-dialog-content>
