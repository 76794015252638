<div style="height: 8px">
    <mat-progress-bar *ngIf="isProcessing" mode="indeterminate"></mat-progress-bar>
</div>
<div class="tw-p-5">
    <tilby-magic-form [form]="fileImporterFormGroup"/>
    <form [formGroup]="fileImporterFormGroup" class="formGroup">
        <button disabled="{{isProcessing}}" type="button" mat-stroked-button (click)="fileInput.click()" translate="FILE_IMPORTER.ADD.CHOOSE_FILE"></button>
        <input hidden (change)="checkFileExtension($event)" accept=".csv, .xls, .xlsx" #fileInput type="file" id="file">
        {{fileToUpload?.name}}
    </form>
    <div *ngIf="fileImporterFormGroup.controls.file_types.value === 'csv'" class="tw-pt-5">
        <label class="sass tw-ml-2 tw-py-2 tw-text-xl tw-w-[100%]" translate="FILE_IMPORTER.ADD.CSV_SEPARATOR_TYPE"></label>
        <form [formGroup]="fileImporterAddFormCSV">
            <ng-container formGroupName="csv_separator_type">
                <tilby-radio-input formControlLabel="separator_type_controller"></tilby-radio-input>
                <tilby-select-input formControlLabel="delimitatorColumn"></tilby-select-input>
                <tilby-select-input formControlLabel="delimitatorString"></tilby-select-input>
            </ng-container>
            <ng-container formGroupName="csv_fixed_length">
                <tilby-radio-input formControlLabel="delimiters_type_controller"></tilby-radio-input>
                <tilby-input formControlLabel="csv_insert_delimiters"></tilby-input>
            </ng-container>
        </form>
    </div>
</div>