<div class="tw-w-max tw-flex tw-flex-wrap">
    <span class="tw-text-sm tw-mr-8">{{'TOAST.GRID_PREFERENCE.TITLE'| translate}}</span>
    <span>
        <span class="tw-p-0 tw-leading-[0] tw-text-sm tw-mr-4" mat-button (click)="onActionBtnClick()">
            {{'TOAST.GRID_PREFERENCE.CANCEL'| translate}}
        </span>
        <span class="tw-p-0 tw-leading-[0] tw-text-sm tw-text-[#448AFF]" mat-button (click)="saveUserPreference()">
            {{'TOAST.GRID_PREFERENCE.SAVE'| translate}}
        </span>
    </span>
</div>
