import * as angular from 'angular';
import * as _ from 'lodash';

import {
    MigrationHelper
} from 'app/modules/application/service/migration-helper/migration-helper';

import {
    ConfigurationManagerService,
    ConfigurationPreferences,
    EntityManagerService,
    EnvironmentInfoService,
    UserActiveSessionManagerService
} from 'src/app/core';

import { stringToLines } from 'src/app/shared/string-utils';
import { MathUtils } from 'src/app/shared/utils';

import {
    ChainCampaigns,
    FidelitiesPoints,
    Sales,
    SalesCustomer
} from 'tilby-models';

type CustomerFidelityInfo = {
    customer_uuid: string;
    total_credit: number;
    loyalty_points: number;
}

type CustomerDisplayPayload = {
    customer: {
        prepaid_credit?: number;
        loyalty_points?: number;
    },
    sale: Sales | null;
    settings: Partial<ConfigurationPreferences>;
    shop: string;
    strings: {
        total: string;
        credit_balance: string;
        points_balance: string;
    }
}

export class CustomerDisplayManagerService {
    private customerFidelityInfo: CustomerFidelityInfo | null = null;

    constructor(
        private readonly $filter: any,
        private readonly $http: any,
        private readonly $translate: any,
        private readonly checkManager: ConfigurationManagerService,
        private readonly documentPrinter: any,
        private readonly environmentInfoService: EnvironmentInfoService,
        private readonly entityManager: EntityManagerService,
        private readonly userActiveSessionManagerService: UserActiveSessionManagerService,
        private readonly migrationHelper: MigrationHelper
    ) {
    }

    /**
     * Generates a sale item display row based on the given parameters.
     *
     * @param {number} displayRowSize - The size of the display row.
     * @param {string} itemName - The name of the item.
     * @param {number} itemPrice - The price of the item.
     * @returns {string} - The generated sale item display row.
     */
    private getSaleItemDispRow(displayRowSize: number, itemName: string, itemPrice: number): string {
        let priceString = this.$filter('sclCurrency')(itemPrice, '');
        let itemNameString = _.chain(itemName).truncate({ length: displayRowSize - (priceString.length + 1), omission: '' }).padEnd(displayRowSize - priceString.length).value();

        return itemNameString + priceString;
    }

    /**
     * Generates the display row for the subtotal with a given display row size.
     * 
     * @param {number} displayRowSize - The size of the display row.
     * @param {Object} options - (optional) Additional options for generating the subtotal display row.
     * @param {boolean} options.useAbsValue - (optional) Specifies whether to use the absolute value of the final amount.
     * @param {string} options.subtotalLabel - (optional) The label to be used for the subtotal. If not provided, the default label will be used.
     * @return {string} The generated subtotal display row.
     */
    private getSubTotalDispRow(displayRowSize: number, options?: { useAbsValue: boolean, subtotalLabel?: string }): string {
        const activeSale = this.migrationHelper.getActiveSale();

        const finalAmountString = this.$filter('sclCurrency')(options?.useAbsValue ? Math.abs(activeSale.currentSale.final_amount!) : activeSale.currentSale.final_amount, '');

        const subTotalLabelString = _.chain(options?.subtotalLabel || this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.SUBTOTAL'))
            .truncate({ length: displayRowSize - (finalAmountString.length + 1), omission: '' })
            .padEnd(displayRowSize - finalAmountString.length)
            .value();

        return subTotalLabelString + finalAmountString;
    }

    /**
     * Updates the customer fidelity internal cache.
     *
     * @param {SalesCustomer} saleCustomer - The sales customer object.
     * @return {Promise<void>} A promise that resolves when the customer fidelity information is updated.
     */
    private async updateCustomerFidelityInfo(saleCustomer?: SalesCustomer) {
        const activeSale = this.migrationHelper.getActiveSale();

        if (saleCustomer?.uuid === this.customerFidelityInfo?.customer_uuid) {
            return;
        }

        const customerFidelity = saleCustomer?.fidelity;

        if (!customerFidelity) {
            this.customerFidelityInfo = null;
            return;
        }

        try {
            let customerPoints;

            const prepaidCredit = await activeSale.getCustomerPrepaidInfo();
            const campaigns = await this.entityManager.campaigns.fetchCollectionOnline();

            if (Array.isArray(campaigns)) {
                const activeCampaign = campaigns.find((campaign: ChainCampaigns) => campaign.isValid);

                if (activeCampaign) {
                    const fidelitiesPoints = await this.entityManager.fidelitiesPoints.fetchCollectionOffline({ fidelity: customerFidelity, campaign_id: activeCampaign.id });
                    customerPoints = fidelitiesPoints.find((fidelityPoint: FidelitiesPoints) => fidelityPoint.campaign_id === activeCampaign.id);
                }
            }

            this.customerFidelityInfo = {
                customer_uuid: saleCustomer?.uuid!,
                total_credit: MathUtils.round(prepaidCredit?.total_credit || 0),
                loyalty_points: MathUtils.round(customerPoints?.points || 0)
            };
        } catch (err) { }
    };

    /**
     * Updates the display with two rows of information based on the given event type and data.
     *
     * @param {string} eventType - The type of event triggering the display update.
     * @param {any} data - The data associated with the event.
     */
    public async updateTwoRowsDisplay(eventData: { event: string, data?: any }) {
        const activeSale = this.migrationHelper.getActiveSale();

        const { event: eventType, data } = eventData;
        const displayRowSize = parseInt(this.checkManager.getPreference('customer_display.display_row_size') || '') || 20;
        const printer = activeSale.printerDocumentData?.printer;
        const canUsePrinter = !!(printer && this.documentPrinter.isPrinterUsable(printer));
        const serialDisplayPort = this.environmentInfoService.canUseSerialPorts() ? this.checkManager.getPreference('customer_display.serial_port_address') : null;

        if (!canUsePrinter && !serialDisplayPort) {
            return;
        }

        const rowsToDisplay: String[] = [];

        switch (eventType) {
            case 'customer-added':
                const welcomeText = this.checkManager.getPreference('customer_display.greeting_message') || this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.DEFAULT_GREETING');
                let fidelityRow = "";

                await this.updateCustomerFidelityInfo(data);

                if (this.customerFidelityInfo?.loyalty_points) {
                    fidelityRow += `${this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.POINTS_BALANCE_TWO_ROWS')}: ${this.customerFidelityInfo?.loyalty_points}`;
                }

                if (this.customerFidelityInfo?.total_credit) {
                    if (fidelityRow) {
                        fidelityRow += " - ";
                    }

                    fidelityRow += `${this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.CREDIT_BALANCE_TWO_ROWS')}: ${this.customerFidelityInfo?.total_credit}`;
                }

                rowsToDisplay.push(
                    (`${welcomeText} ${data.first_name}`).padEnd(displayRowSize),
                    fidelityRow.padEnd(displayRowSize),
                );
                break;
            case 'item-added':
                rowsToDisplay.push(
                    this.getSaleItemDispRow(displayRowSize, data.name, data.price),
                    this.getSubTotalDispRow(displayRowSize)
                );
                break;
            case 'item-changed':
                let name = data.currentSaleItem.name;
                let price = (data.currentSaleItem.quantity >= data.previousSaleItem.quantity) ? data.currentSaleItem.price : -data.currentSaleItem.price;

                rowsToDisplay.push(
                    this.getSaleItemDispRow(displayRowSize, name, price),
                    this.getSubTotalDispRow(displayRowSize)
                );
                break;
            case 'item-removed':
                rowsToDisplay.push(
                    this.getSaleItemDispRow(displayRowSize, data.name, -data.price),
                    this.getSubTotalDispRow(displayRowSize)
                );
                break;
            case 'priceChange-added':
                rowsToDisplay.push(
                    this.getSaleItemDispRow(displayRowSize, data.priceChange.description, data.priceChange.amount),
                    this.getSubTotalDispRow(displayRowSize)
                );
                break;
            case 'priceChange-removed':
                rowsToDisplay.push(
                    this.getSaleItemDispRow(displayRowSize, data.priceChange.description, -data.priceChange.amount),
                    this.getSubTotalDispRow(displayRowSize)
                );
                break;
            case 'go-to-payments':
                let subtotalLabel = activeSale.currentSale.final_amount! > 0 ? this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.TO_PAY') : this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.REFUND');

                rowsToDisplay.push(
                    (' ').repeat(displayRowSize),
                    this.getSubTotalDispRow(displayRowSize, { subtotalLabel: subtotalLabel, useAbsValue: true })
                );
                break;
            case 'sale-opened':
                if (_.isEmpty(data)) {
                    let thankYouMessage = this.checkManager.getPreference('customer_display.thankyou_message');

                    if (thankYouMessage) {
                        let thankYouRows = stringToLines(thankYouMessage, displayRowSize);

                        for (let i = 0; i < 2; i++) {
                            rowsToDisplay.push((thankYouRows[i] || '').padEnd(displayRowSize));
                        }
                    } else {
                        rowsToDisplay.push(
                            this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.DEFAULT_THANK_YOU_FIRST_ROW').padEnd(displayRowSize),
                            this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.DEFAULT_THANK_YOU_SECOND_ROW').padEnd(displayRowSize)
                        );
                    }
                }
                break;
            default:
                break;
        }

        if (!rowsToDisplay.length) {
            return;
        }

        //Send to the current printer (if possible)
        if (canUsePrinter) {
            this.documentPrinter.displayText(printer, rowsToDisplay);
        }

        //Send to the display serial port (if possible)
        if (serialDisplayPort) {
            this.documentPrinter.displayText({
                connection_type: 'sp',
                driver: 'escpos',
                ip_address: serialDisplayPort,
                type: 'receipt'
            }, rowsToDisplay);
        }
    }

    //Caches the sale uuid in order to pass it to the color display even if the sale is closed
    private saleUuid: string | undefined;

    /**
     * Returns a payload for the customer display.
     *
     * @param {Sales | null} sale - The sale object to include in the payload. Defaults to null.
     * @param {CustomerFidelityInfo | null} customerFidelityInfo - The customer fidelity information to include in the payload. Defaults to null.
     * @return {CustomerDisplayPayload} The payload object containing the sale, settings, customer info and strings.
     */
    public getCustomerDisplayPayload(sale?: Sales | null, customerFidelityInfo?: CustomerFidelityInfo | null): CustomerDisplayPayload {
        return {
            sale: sale || null,
            shop: this.userActiveSessionManagerService.getSession()?.shop.name!,
            settings: this.checkManager.getShopPreferences('customer_display.'),
            customer: {
                prepaid_credit: customerFidelityInfo?.total_credit,
                loyalty_points: customerFidelityInfo?.loyalty_points
            },
            strings: {
                total: this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.TOTAL'),
                credit_balance: this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.CREDIT_BALANCE'),
                points_balance: this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.POINTS_BALANCE')
            }
        }
    }

    /**
     * Updates the color display for a given sale.
     * 
     * @param {Sales} sale - the sale object to update the color display for
     * @return {Promise<void>} a promise that resolves when the color display is updated
     */
    public async updateColorDisplay(sale?: Sales) {
        // Get the uuid of the sale (if the sale is closed, pass the cached one for the goodbye message)
        const saleUuid = sale?.uuid || this.saleUuid;
        // Update the cached sale uuid
        this.saleUuid = sale?.uuid;

        await this.updateCustomerFidelityInfo(sale?.sale_customer);

        const payload = this.getCustomerDisplayPayload(sale, this.customerFidelityInfo);

        if (this.checkManager.getPreference('cashregister.public_receipt') && saleUuid) {
            const shopId = this.userActiveSessionManagerService.getSession()?.shop.id;

            if (shopId) {
                Object.assign(payload, {
                    qrcode: {
                        url: `https://er.tilby.com/${shopId}/${saleUuid}.pdf`,
                        text: this.$translate.instant('CASHREGISTER.CUSTOMER_DISPLAY.SCAN_SALE_QR_CODE')
                    }
                });
            }
        }

        if (this.environmentInfoService.isElectronApp() && this.checkManager.getPreference('customer_display.enable_color_display')) {
            window.require('electron').ipcRenderer.send('update-customer-display', payload);
        }

        if (this.checkManager.getPreference('customer_display.enable_network_display')) {
            const ipAddress = window.localStorage.getItem('deviceSettings::NetworkDisplayAddress') || this.checkManager.getPreference('customer_display.network_display_ip_address');

            if (!ipAddress) {
                return;
            }

            this.sendNetworkDisplayMessage(ipAddress, payload);
        }
    }

    /**
     * Configure a network display to override the display saved in the shop configuration.
     * @param ipAddress The IP address of the network display
     * @param version The version of the network display
     * @returns {Promise<void>} A promise that resolves when the network display is configured
     */
    public async configureNetworkDisplay(ipAddress: string, version: string) {
        if (!ipAddress) {
            return;
        }

        // Create a test payload
        const payload = this.getCustomerDisplayPayload(null, null);

        // Send the test payload
        await this.sendNetworkDisplayMessage(ipAddress, payload);

        // Save the IP address to the local storage (overrides the shop configuration if it exists)
        window.localStorage.setItem('deviceSettings::NetworkDisplayAddress', ipAddress);
    }

    /**
     * Sends a network display message to the given IP address.
     * @param ipAddress The IP address of the network display
     * @param payload The payload to send
     * @returns {Promise} A promise that resolves when the network display is sent
     */
    public async sendNetworkDisplayMessage(ipAddress: string, payload: CustomerDisplayPayload) {
        if (!ipAddress) {
            return;
        }

        let port = 2013;

        // If the IP address includes a port, split it and use the port from the string
        if (ipAddress.includes(':')) {
            const split = ipAddress.split(':');
            ipAddress = split[0];
            port = parseInt(split[1]);
        }

        return this.$http.post(`http://${ipAddress}:${port}/screen_update`, payload);
    }
}

CustomerDisplayManagerService.$inject = [
    '$filter',
    '$http',
    '$translate',
    'checkManager',
    'documentPrinter',
    'environmentInfo',
    'entityManager',
    'userActiveSession',
    'migrationHelper',
];

angular.module('cashregister').service('CustomerDisplayManager', CustomerDisplayManagerService);