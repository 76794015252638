import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('cashregister').controller('CashregisterShowcaseCtrl', CashregisterShowcaseCtrl);

CashregisterShowcaseCtrl.$inject = ["$scope", "$mdMedia", "$timeout", "$rootScope", "$stateParams", "$state", "$filter", "$translate", "categories", "connection", "items", "departments", "alertDialog", "confirmDialog", "checkManager", "ActiveSale", "entityManager", "restManager", "addNewItemToSale", "showSaleItem", "selectSaleItemCombination", "documentPrinter", "printerErrorFiscal", "dailyClosingDialog", "stockDictionary", "barcodeManager", "addCashMovement", "toast", "util", "newSaleUtils"];

function CashregisterShowcaseCtrl($scope, $mdMedia, $timeout, $rootScope, $stateParams, $state, $filter, $translate, categories, connection, items, departments, alertDialog, confirmDialog, checkManager, ActiveSale, entityManager, restManager, addNewItemToSale, showSaleItem, selectSaleItemCombination, documentPrinter, printerErrorFiscal, dailyClosingDialog, stockDictionary, barcodeManager, addCashMovement, toast, util, newSaleUtils) {
    const countNoStockAsUnavailable = checkManager.getPreference('cashregister.count_nostock_as_unavailable');
    const rechargeDepartmentId = parseInt(checkManager.getPreference('prepaid.recharge_department_id'));
    const currentCountry = checkManager.getShopCountry();

    let stockToast = null;

    const getKeypadValue = () => _.toNumber($scope.keypadDynamicValue.replace(',', '.'));
    const resetKeypadValue = () => { $scope.keypadDynamicValue = $filter('sclCurrency')(0, ''); };

    const visibleCategories = _(categories).sortBy('index').filter({ display: true }).value();
    const hideEmptyCategories = checkManager.getPreference('showcase_hide_empty_categories');

    Object.assign($scope, {
        art15ButtonString: checkManager.getPreference("cashregister.art_15_button_string") || $translate.instant('CASHREGISTER.SHOWCASE.DISCOUNT_ART_15'),
        canAddNewItem: checkManager.isUserPermitted("cashregister.add_new_item"),
        canChangePrice: checkManager.isUserPermitted("cashregister.use_price_changes"),
        canChangeSaleItemQuantity: checkManager.isUserPermitted("cashregister.change_item_quantity"),
        canSurcharge: !['FR'].includes(currentCountry),
        enableSaleItemAction: () => ActiveSale.hasActiveSaleItem(),
        getItemPriceList: (item) => item[priceListStr],
        giftButtonString: checkManager.getPreference("cashregister.gift_button_string") || $translate.instant('CASHREGISTER.SHOWCASE.GIFT'),
        hasVisibleItems: () => (!_.isEmpty($scope.visibleItems)),
        isCategoryGridMode: () => ($scope.topbar_context.categoryView === 'grid'),
        isCategoryTabMode: () => ($scope.topbar_context.categoryView === 'tab'),
        isDisplayModeCompact: () => ($scope.topbar_context.displayMode === 'compact'),
        isDisplayModeNormal: () => ($scope.topbar_context.displayMode === 'normal'),
        isPortrait: () => $mdMedia('xs'),
        isRechargeItemSelected: () => (ActiveSale.getActiveSaleItem()?.department_id === rechargeDepartmentId),
        isSelectedCategory: (category) => ($scope.selectedCategory === category),
        itemsFavorite: items.favorite,
        keypadValueIsZero: () => getKeypadValue() ? false : true,
        quantityModifiers: {
            rows: 0,
            value: 0
        },
        sale: ActiveSale,
        searchResults: {},
        searchText: "",
        showArt15Button: currentCountry === 'IT',
        showKeyboard: window.localStorage.getItem('cashregister::showKeyboard') === 'true',
        showStockOnShowcase: !!checkManager.getPreference('cashregister.show_stock_on_showcase'),
        visibleCategories: visibleCategories,
        visibleItems: [],
        topbar_context: {
            back_label: $translate.instant('CASHREGISTER.SHOWCASE.TITLE'),
            categoryView: checkManager.getPreference("showcase_category_view") || 'tab', // tab grid
            displayMode: checkManager.getPreference("showcase_display_mode") || 'normal',
            isShowcaseModeEnabled: (mode) => (mode === 'list' || _.size($scope.visibleItems) < 500),
            keyboardMode: checkManager.getPreference("cashregister.keyboard_mode") || 'compact',
            order_by: checkManager.getPreference("showcase_order_by") || '+name', // +name +price -price
            priceList: _.get(ActiveSale, ['currentSale', 'sale_customer', 'default_pricelist']) || util.getCurrentPriceList(),
            showcaseMode: checkManager.getPreference("showcase_mode") || 'photoGrid', //photoGrid noPhotoGrid list
            showcaseviewmode: true,
            showSearch: false,
            showUnavailable: checkManager.getPreference("showcase_show_unavailable") === false ? false : true,
            stockDictionary: stockDictionary
        }
    });

    $scope.showcaseView = $scope.topbar_context.categoryView === 'tab' ? 'categoryTab' : 'categories';

    const initDepartments = () => {
        const saleDepartments = departments.filter((department) => department.display !== false);
        const itemDepartments = saleDepartments.filter((department) => department.id !== rechargeDepartmentId);

        Object.assign($scope, {
            keyboardItemDepartments: itemDepartments.slice(0, 5),
            keyboardSaleDepartments: saleDepartments.slice(0, 5),
            menuItemDepartments: itemDepartments.slice(5),
            menuSaleDepartments: saleDepartments.slice(5),
            visibleDepartments: _.reject(departments, { display: false }), //Using reject instead of filter because the 'display' field could not be present
        });
    };

    initDepartments();

    $scope.$on('cashregister-showcase:update-departments', () => {
        initDepartments();
    });

    const resetQuantityModifiers = () => {
        $scope.quantityModifiers = {
            rows: 0,
            value: 0
        };
    };

    $scope.setKeyboardVisibility = (visible) => {
        if($scope.showKeyboard !== visible) {
            $scope.showKeyboard = visible;
            window.localStorage.setItem('cashregister::showKeyboard', visible);
        }
    };

    //Parse quick coupons
    const quickCoupons = newSaleUtils.getConfiguredQuickCoupons();

    if(quickCoupons.length) {
        $scope.quickCoupons = quickCoupons;
    }

    if(!_.isEmpty(items.null)) {
        $scope.visibleCategories.push({
            name: $translate.instant('CASHREGISTER.SHOWCASE.NO_CATEGORY'),
            id: "null"
        });
    }

    const categoriesById = _.keyBy($scope.visibleCategories, 'id');

    resetKeypadValue();

    if($scope.isPortrait()) {
        if ($scope.topbar_context.showcaseMode !== 'list') {
            $scope.topbar_context.showcaseMode = 'list';
            checkManager.setUserPreference("showcase_mode", 'list');
        }
    }

    $scope.getItemStockStatus = function(item) {
        if ($scope.showStockOnShowcase) {
            var itemStock;
            if (!_.isEmpty(item.stock_type) && (itemStock = stockDictionary.get('item_id_' + item.id))) {
                switch (itemStock.available) {
                    case 'available': case 'producible':
                        return 'stock-info-available';
                    case 'unavailable':
                        return 'stock-info-unavailable';
                    case 'alert':
                        return 'stock-info-alert';
                    default:
                        return 'stock-info-nostock';
                }
            } else {
                return 'stock-info-nostock';
            }
        } else {
            return 'stock-disabled';
        }
    };

    var priceListStr = 'price' + $scope.topbar_context.priceList;

    const itemFilterFunction = (item) => {
        let stockCheck = $scope.topbar_context.showUnavailable ? true : (countNoStockAsUnavailable ? !['stock-info-nostock', 'stock-info-unavailable'].includes($scope.getItemStockStatus(item)) : ($scope.getItemStockStatus(item) !== 'stock-info-unavailable'));
        return stockCheck && Number.isFinite(item[priceListStr]);
    };

    var filterVisibleItems = function() {
        var orderBy = {
            field: _.startsWith($scope.topbar_context.order_by, 'price', 1) ? 'price' + $scope.topbar_context.priceList : _.trim($scope.topbar_context.order_by, '+-'),
            order: $scope.topbar_context.order_by[0] === '-' ? "desc" : "asc"
        };

        $scope.visibleItems = _($scope.itemsCategory).filter(itemFilterFunction).orderBy([orderBy.field], [orderBy.order]).value();
    };

    const filterVisibleCategories = () => {
        $scope.visibleCategories = visibleCategories.filter((category) => {
            if(Array.isArray(items[category.id])) {
                const itemsInCategory = items[category.id].filter(itemFilterFunction);

                return itemsInCategory.length > 0;
            }
        });

        updateCategoryBarHeight();

        if($scope.selectedCategory) {
            if(!$scope.visibleCategories.includes($scope.selectedCategory) && $scope.visibleCategories[0]) {
                $scope.selectCategory($scope.visibleCategories[0]);
            } else {
                $scope.selectCategory($scope.selectedCategory);
            }
        } else {
            filterVisibleItems();
        }
    };

    $scope.$watch('topbar_context.priceList', function(newPriceList, oldPriceList) {
        if(newPriceList !== oldPriceList && newPriceList) {
            priceListStr = 'price' + newPriceList;
            if(hideEmptyCategories) {
                filterVisibleCategories();
            } else {
                filterVisibleItems();
            }
        }
    });

    //Triggered by manual priceList changes
    $scope.$on("cashregister:pricelist-changed", function() {
        if(!ActiveSale.isEmpty()) {
            confirmDialog.show($translate.instant('CASHREGISTER.SHOWCASE.APPLY_NEW_PRICELIST')).then(function(answer) {
                if(answer) {
                    ActiveSale.applyPriceList($scope.topbar_context.priceList);
                }
            });
        }
    });

    $scope.$watch('topbar_context.order_by', function(newOrder, oldOrder) {
        filterVisibleItems();
    });

    $scope.$watch('topbar_context.showUnavailable', function(newSetting, oldSetting) {
        if(hideEmptyCategories) {
            filterVisibleCategories();
        } else {
            filterVisibleItems();
        }
    });

    var updateCategoryBarHeight = function() {
        var categoriesPerRow = $scope.bigTablet ? 6 : 4;
        var visibleCategoriesLen = $scope.visibleCategories.length;

        var rows = _.ceil((visibleCategoriesLen) / categoriesPerRow);
        if (rows > 3) {
            rows = 3;
        }
        $scope.categoryBarHeight = {
            'min-height': (rows * 52) + 'px',
            'max-height': (rows * 52) + 'px'
        };
    };

    $scope.$watch(function() {
        return $mdMedia('(min-width: 1024px)');
    }, function(big) {
        $scope.bigTablet = big;
        updateCategoryBarHeight();
    });

    $scope.bigTablet = $mdMedia('(min-width: 1024px)');
    updateCategoryBarHeight();

    $scope.goToCategory = function(id) {
        let targetCategory;

        //Hide full screen keyboard if the user is switching category
        if($scope.itemsCategory && $scope.topbar_context.keyboardMode === 'big') {
            $scope.setKeyboardVisibility(false);
        }

        switch (id) {
            case 'favorite':
                $scope.showcaseView = 'favorites';
                targetCategory = $scope.itemsFavorite;
                break;
            default:
                switch($scope.topbar_context.categoryView) {
                    case 'grid':
                        $scope.showcaseView = 'categories';
                    break;
                    case 'tab':
                        $scope.showcaseView = `category_${id}`;
                    break;
                }

                targetCategory = items[id] || [];
                break;
        }

        $scope.itemsCategory = targetCategory;

        filterVisibleItems();

        $scope.topbar_context.showcaseMode = _.size($scope.visibleItems) < 500 ? (checkManager.getPreference("showcase_mode") || 'photoGrid') : 'list';

        $timeout(function() {
            $(".md-virtual-repeat-scroller").scrollTop(0);
            $(".items-grid-list").scrollTop(0);
        });
    };

    $scope.goToSection = async (section) => {
        switch (section) {
            case 'favorites':
                $scope.goToCategory('favorite');
            break;
            case 'categories':
                let currentCategory = $scope.selectedCategory ? $scope.selectedCategory.id : 'null';
                $scope.goToCategory(currentCategory);

                $timeout(function() {
                    const currentCategoryElement = document.getElementById(`cat_${currentCategory}`);

                    if(currentCategoryElement) {
                        currentCategoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                });
            break;
            default:
            break;
        }
    };

    $scope.selectCategory = function(category) {
        $scope.selectedCategory = category;

        $timeout(function() {
            $scope.goToCategory(category.id);
        });
    };

    if($scope.itemsFavorite.length) {
        $scope.goToSection('favorites');
    } else {
        $scope.selectedCategory = $scope.visibleCategories[0];
        $scope.goToSection('categories');
    }

    $scope.cloneSaleItem = function() {
        let numClones = getKeypadValue();

        if($scope.quantityModifiers.rows && !numClones) {
            return resetQuantityModifiers();
        }

        try {
            if(numClones > 100) {
                throw 'ERROR_MULTIPLIER_MUST_BE_LOWER';
            }

            if(!_.isInteger(numClones)) {
                throw 'ERROR_MULTIPLIER_MUST_BE_INT';
            }

            if(ActiveSale.isActiveSaleItem()) {
                ActiveSale.cloneSaleItem(null, (numClones || 2) - 1);
            } else {
                $scope.quantityModifiers = {
                    rows: numClones,
                    value: 0
                };
            }
        } catch(error) {
            switch(error) {
                case 'ERROR_MULTIPLIER_MUST_BE_INT':
                case 'ERROR_MULTIPLIER_MUST_BE_LOWER':
                    alertDialog.show($translate.instant(`CASHREGISTER.SHOWCASE.${error}`));
                break;
                default:
                break;
            }
        }

        resetKeypadValue();
    };

    $scope.changeSaleItemPrice = function() {
        const saleItem = ActiveSale.getActiveSaleItem();
        let newPrice = getKeypadValue();

        if(newPrice && saleItem) {
            ActiveSale.editSaleItem(saleItem, { price: util.round(newPrice / saleItem.quantity) });
        }

        resetKeypadValue();
    };

    $scope.changeSaleItemQuantity = function() {
        let newQuantity = getKeypadValue();

        if($scope.quantityModifiers.value && !newQuantity) {
            return resetQuantityModifiers();
        }

        if(newQuantity > 0) {
            if(ActiveSale.isActiveSaleItem()) {
                $rootScope.$broadcast('active-sale:saleitem-quantity', { quantity: newQuantity });
            } else {
                $scope.quantityModifiers = {
                    rows: 0,
                    value: newQuantity
                };
            }
        } else {
            alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.ERROR_QUANTITY_MUST_BE_POSITIVE'));
        }

        resetKeypadValue();
    };

    $scope.onSubmitBarcode = function(barcodeInput) {
        barcodeManager.manageBarcodeInput(barcodeInput);
    };

    $scope.topbar_context.onSubmitBarcode = $scope.onSubmitBarcode;

    const checkSaleItemBeforeAdd = async (item, combinationId) => {
        let result = {};

        //Check item vat and department
        if (!item.vat_perc && !item.department) {
            alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.NO_VAT_FOR_ITEM'));
            throw 'NO_VAT_FOR_ITEM';
        }

        //Ask combination if necessary
        let hasCombinations = item.combinations?.length;

        if (hasCombinations && !combinationId) {
            let comb = await selectSaleItemCombination.show(item, stockDictionary);
            result.combinationId = comb.id;
        }

        //Check manual production
        if(item.stock_type === 'bom' && item.manual_production && connection.isOnline()) {
            let itemStock = stockDictionary.get(`item_id_${item.id}`);

            if(_.chain(itemStock).get('stock_quantity').isFinite().value() && item.quantity_alert && itemStock.stock_quantity < 1) {
                let itemStockBackup = _.cloneDeep(itemStock);
                let multiplier = item.quantity_alert || 1;
                let loadQuantity = Math.ceil(Math.abs(itemStock.stock_quantity) / multiplier) * multiplier;

                let productionMovement = {
                    entry_type: "item",
                    type: "load",
                    quantity: loadQuantity,
                    load_cause: "bom_production",
                    date: new Date().toISOString(),
                    name: item.name,
                    item_id: item.id
                };

                if(checkManager.getPreference('cashregister.disable_manual_production_confirm') !== true) {
                    let answer = await confirmDialog.show($translate.instant('CASHREGISTER.SHOWCASE.CONFIRM_MANUAL_PRODUCTION'));

                    if(!answer) {
                        throw 'CANCELED';
                    }
                }

                Object.assign(itemStock, {
                    available: 'available',
                    stock_quantity: itemStock.stock_quantity + loadQuantity
                });

                restManager.post('stock_movements', productionMovement).catch(function(error) {
                    angular.copy(itemStockBackup, itemStock);
                });
            }
        }

        return result;
    };

    $scope.addItemToSale = async (item, combinationId, quantity, barcode, overrides) => {
        const quantityModifiers = Object.assign({}, $scope.quantityModifiers);
        let options = {};

        if(!_.isObject(overrides)) {
            overrides = {};
        }

        let result = await checkSaleItemBeforeAdd(item, combinationId);

        if(result.combinationId) {
            combinationId = result.combinationId;
        }

        if(!quantity && quantityModifiers.value) {
            quantity = quantityModifiers.value;
        }

        if(quantityModifiers.rows) {
            options.addAsNew = true;
        }

        let newPrice = getKeypadValue();

        if(newPrice && $scope.canChangePrice) {
            overrides.price = newPrice;
        }

        resetKeypadValue();

        let saleItem = await ActiveSale.addItemToSale(item, $scope.topbar_context.priceList, combinationId, quantity, barcode, overrides, options);

        if(quantityModifiers.rows) {
            ActiveSale.cloneSaleItem(saleItem, quantityModifiers.rows - 1);
        }

        resetQuantityModifiers();
    };

    $scope.onDepartmentAction = async (department) => {
        if(ActiveSale.isActiveSaleItem()) {
            let saleItem = ActiveSale.getActiveSaleItem();

            ActiveSale.editSaleItem(saleItem, {
                department: department,
                department_id: department.id,
                department_name: department.name,
                vat_perc: department.vat.value
            });
        } else {
            if (!$scope.keypadValueIsZero()) {
                const quantityModifiers = Object.assign({}, $scope.quantityModifiers);
                let overrides = {};
                let price = getKeypadValue();
                let checkPrice = _.toInteger(checkManager.getPreference("cashregister.dynamic_item_price_check"));

                if(checkPrice && price >= checkPrice) {
                    let answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.HIGH_VALUE_WARNING', { value: $filter('sclCurrency')(checkPrice) }));

                    if(!answer) {
                        return;
                    }
                }

                if(quantityModifiers.value) {
                    overrides.quantity = quantityModifiers.value;
                }

                let saleItem = await ActiveSale.addDynamicItemToSale(department, price, overrides);

                if(quantityModifiers.rows) {
                    ActiveSale.cloneSaleItem(saleItem, quantityModifiers.rows - 1);
                }

                resetKeypadValue();
                resetQuantityModifiers();
            }
        }
    };

    $scope.addQuickCoupon = (coupon) => {
        let targetDepartment = _.find(departments, { id: coupon.department_id });

        if(targetDepartment) {
            ActiveSale.addDynamicItemToSale(targetDepartment, util.round(coupon.value), {
                type: 'coupon',
                quantity: -1,
                name: coupon.name
            });
        } else {
            alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.QUICK_COUPON_MISSING_DEPARTMENT'));
        }
    };

    var commonPriceChangeFunction = function(priceChangeFunction) {
        if ($scope.canChangePrice) {
            if (ActiveSale.isActiveSale() && $scope.keypadDynamicValue !== $filter('sclCurrency')(0, '')) {
                var pcValue = _.toNumber(_.replace($scope.keypadDynamicValue, ',', '.'));

                priceChangeFunction.apply(ActiveSale, [pcValue]);
                resetKeypadValue();
            }
        }
    };

    $scope.addSurchargeFix = function() {
        if($scope.canSurcharge) {
            commonPriceChangeFunction(ActiveSale.isActiveSaleItem() ? ActiveSale.addSaleItemSurchargeFix : ActiveSale.addSaleSurchargeFix);
        }
    };

    $scope.addDiscountFix = function() {
        commonPriceChangeFunction(ActiveSale.isActiveSaleItem() ? ActiveSale.addSaleItemDiscountFix : ActiveSale.addSaleDiscountFix);
    };

    $scope.addSurchargePerc = function() {
        if($scope.canSurcharge) {
            commonPriceChangeFunction(ActiveSale.isActiveSaleItem() ? ActiveSale.addSaleItemSurchargePerc : ActiveSale.addSaleSurchargePerc);
        }
    };

    $scope.addDiscountPerc = function() {
        commonPriceChangeFunction(ActiveSale.isActiveSaleItem() ? ActiveSale.addSaleItemDiscountPerc : ActiveSale.addSaleDiscountPerc);
    };

    $scope.makeGiftSaleItem = function(type) {
        switch(type) {
            case 'as_art_15':
                let zeroVatDepartment = _.find(departments, (department) => (department.vat.value === 0 && department.vat.code === 'N2' && department.id !== rechargeDepartmentId));

                if(zeroVatDepartment) {
                    ActiveSale.giftSaleItem(null, $translate.instant('CASHREGISTER.SHOWCASE.DISCOUNT_ART_15_STRING'), zeroVatDepartment);
                } else {
                    alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.ART_15_MISSING_N2_DEPARTMENT'));
                }
            break;
            case 'as_gift':
                ActiveSale.giftSaleItem(null, $translate.instant('CASHREGISTER.SHOWCASE.GIFT'));
            break;
            default:
            break;
        }
    };

    $scope.exitSearch = function() {
        $scope.clearSearchText();
        $scope.topbar_context.showSearch = false;
    };

    $scope.clearSearchText = function() {
        $scope.searchText = "";
        $scope.searchResults = {};
    };

    $scope.searchItems = function(options) {
        if(!_.isObject(options)) {
            options = {};
        }

        if ($scope.searchText.length < 3) {
            $scope.searchResults = {};
        } else {
            var searchTarget;
            var searchAllCategories;

            if(options.allCategories || !['categories', 'categoryTab'].includes($scope.showcaseView)) {
                searchTarget = _.pickBy(items, function(itemsInCategory, categoryId) { return !_.isNil(categoriesById[categoryId]); });
                searchAllCategories = true;
            } else {
                searchTarget = $scope.selectedCategory && items[$scope.selectedCategory.id] ? _.pick(items, [$scope.selectedCategory.id]) : items;
                searchAllCategories = false;
            }

            var searchableItems = _(searchTarget).values().sumBy(_.size);

            if(searchableItems <= 10000 || options.forceSearch) {
                $rootScope.$broadcast("loader:changeStatus", "itemSearchLoader", { enabled: true });

                $timeout(function() {
                    $scope.searchResults = performSearch(searchTarget, $scope.searchText, {
                        allCategories: searchAllCategories,
                        priceList: priceListStr,
                        showUnavailable: $scope.topbar_context.showUnavailable
                    });

                    $timeout(function() {
                        $rootScope.$broadcast("loader:changeStatus", "itemSearchLoader", { enabled: false });
                    });
                });
            } else {
                $scope.searchResults = {
                    needsEnter: true
                };
            }
        }
    };

    var performSearch = function(searchTarget, searchText, options) {
        var unavailableStockStatuses = ['stock-info-unavailable'];

        if(countNoStockAsUnavailable) {
            unavailableStockStatuses.push('stock-info-nostock');
        }

        var itemFilter = function(item) {
            if(!_.isFinite(item[options.priceList || 'price1'])) { //Pricelist check
                return false;
            }

            if(!options.showUnavailable) { //Stock check
                if(unavailableStockStatuses.includes($scope.getItemStockStatus(item))) {
                    return false;
                }
            }

            if(_.isEmpty($filter('filter')([_.omit(item, ['thumbnail', 'images'])], searchText))) {
                return false;
            }

            return true;
        };

        var resultsByCategory = _(searchTarget).mapValues(function(itemsInCategory) {
            return _.filter(itemsInCategory, itemFilter);
        }).pickBy(function(itemsInCategory) {
            return !_.isEmpty(itemsInCategory);
        }).value();

        var favorites = resultsByCategory.favorite;
        delete resultsByCategory.favorite;

        var results = _(resultsByCategory).mapValues(function(itemsInCategory, categoryId) {
            return {
                id: categoryId,
                name: _.get(categoriesById, [categoryId, 'name']),
                items: itemsInCategory
            };
        }).values().value();

        if(favorites) {
            results.unshift({
                id: 'favorite',
                name: $translate.instant('CASHREGISTER.SHOWCASE.FAVOURITES'),
                items: favorites
            });
        }

        return {
            allCategories: options.allCategories,
            hasResults: _.isEmpty(results),
            results: results
        };
    };

    $scope.showItem = function(item) {
        showSaleItem.show(item, stockDictionary).then(function(answer) {
            if (answer) {
                $scope.addItemToSale(item);
            }
        });
    };

    $scope.addNewItem = async (type, barcode) => {
        var params = { type: type, barcode: barcode };

        if ($scope.keypadDynamicValue !== $filter('sclCurrency')(0, '')) {
            params.price = _.toFinite($scope.keypadDynamicValue.replace(',', '.'));
            resetKeypadValue();
        }

        const res = await addNewItemToSale.openDialog({ data: params });

        if (!res) {
            return;
        }

        const { item, quantity } = res;

        if (item.id) {
            var catId = item.category_id;

            if (catId && items[catId]) {
                items[catId].push(item);
            } else {
                items.null.push(item);
            }
        }

        $scope.addItemToSale(item, undefined, quantity, barcode, { type: type || 'sale' });
    };

    $scope.openCashDrawer = async () => {
        await ActiveSale.verifyPrinterDocumentDataAndSelect();

        try {
            await documentPrinter.openCashDrawer(ActiveSale.printerDocumentData);
        } catch(error) {
            printerErrorFiscal.show(error, { printerId: ActiveSale.printerDocumentData.printer.id });
        }
    };

    $scope.topbar_context.openCashDrawer = $scope.openCashDrawer;

    $scope.dailyClosing = async () => {
        await ActiveSale.verifyPrinterDocumentDataAndSelect();

        await dailyClosingDialog.show(_.cloneDeep(ActiveSale.printerDocumentData.printer));

        ActiveSale.resetDocumentData();
    };

    $scope.topbar_context.dailyClosing = $scope.dailyClosing;

    $scope.topbar_context.addCashMovement = function() {
        addCashMovement.openDialog();
    };

    var destroyStockToast = function() {
        if (stockToast) {
            toast.hide();
            stockToast = null;
        }
    };

    $scope.$on("activeSale:item-added", function(event, item) {
        if (document.activeElement.id === 'barcodeInput') {
            $timeout(function() {
                $('#barcodeInput').val('');
                $('#barcodeInput').trigger('focus');
            });
        }
    });

    $scope.$on('activeSale:sale-opened', (event, data) => {
        resetKeypadValue();
        resetQuantityModifiers();
    });

    $scope.$on("barcodeManager:Item", async (event, eventData) => {
        // Avoid adding items when a payment is in progress
        if(ActiveSale.paymentInProgress) {
            return;
        }

        const foundData = eventData.itemData;
        const foundArray = foundData.data;

        if(foundArray.length) {
            for(let found of foundArray) {
                try {
                    if(found.quantity && !Number.isFinite(found.quantity)) {
                        await alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.PRICE_UNIT_IS_ZERO'));
                        continue;
                    }

                    const item = await entityManager.items.fetchOneOffline(found.item_id);

                    if(!item) {
                        await alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.ARTICLE_NOT_FOUND'));
                        continue;
                    }

                    if(found.price != null) {
                        item[priceListStr] = found.price;
                    }

                    if (Number.isFinite(item[priceListStr])) {
                        $scope.addItemToSale(item, found.combination_id, found.quantity, eventData.barcodeInput);
                    } else {
                        await alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.ARTICLE_NOT_PRESENT_IN_PRICELIST'));
                    }
                } catch(err) {
                    console.error(err);
                }
            }

            if(foundArray.length !== foundData.total) {
                alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.SOME_ITEMS_NOT_FOUND'));
            }
        } else {
            if (checkManager.getPreference('cashregister.add_item_on_barcode_not_found') !== false) {
                $scope.addNewItem('sale', eventData.barcodeInput);
            } else {
                alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.ARTICLE_NOT_FOUND'));
            }
        }
    });

    $scope.$on("cashregister-showcase:update-items", function(event) {
        $scope.itemsFavorite = items.favorite;

        $scope.goToSection($scope.showcaseView === 'favorites' ? 'favorites' : 'categories');
    });

    if(!checkManager.getPreference('cashregister.disable_stock_notifications')) {
        $scope.$on("cashregister-showcase:notify-stock", function(event, stockMessage) {
            var toastMessage;

            switch (stockMessage.available) {
                case 'available': case 'producible':
                    break;
                case 'alert':
                    toastMessage = $translate.instant('CASHREGISTER.SHOWCASE.ITEM_STOCK_LOW', {value: stockMessage.name});
                    break;
                case 'unavailable':
                    toastMessage = $translate.instant('CASHREGISTER.SHOWCASE.ITEM_STOCK_ZERO', {value: stockMessage.name});
                    break;
                default:
                    break;
            }

            if (toastMessage) {
                if(stockMessage.item_id) {
                    entityManager.items.fetchOneOffline(stockMessage.item_id).then(function(item) {
                        if(_.get(item, 'manual_production') !== true) {
                            toast.show({ message: toastMessage, hideDelay: 0 });
                        }
                    });
                } else {
                    toast.show({ message: toastMessage, hideDelay: 0 });
                }
            }
        });
    }

    $scope.$on("$destroy", destroyStockToast);

    $scope.$on("connection:changed", function(event, data) {
        switch(data.status) {
            case 'offline':
                if (!!checkManager.getPreference('cashregister.check_stock')) {
                    stockToast = toast.show({
                        message: $translate.instant('CASHREGISTER.SHOWCASE.STOCK_OFFLINE'),
                        hideDelay: 0
                    });
                }
            break;
            case 'online':
                destroyStockToast();
            break;
        }
    });

    $scope.$on("cashregister-showcase:show-keyboard", function(event, showKeyboard) {
        $scope.setKeyboardVisibility(showKeyboard);
    });

    $scope.$on('cashregister:addNewItem', function() {
        $scope.addNewItem('sale');
    });

    if($stateParams.action === 'daily-closing') {
        $scope.dailyClosing().then(function() {
            if($stateParams.autohide) {
                $state.go('.', { autohide: null, action: null });
                util.minimizeApp();
            }
        });
    }
}
