import { CommonModule } from "@angular/common";
import { Component, ElementRef, Renderer2, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { DevLogger } from "src/app/shared/dev-logger";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { RoundTotalDialogStateService, RoundTotalFormValue } from "./round-total-dialog.state.service";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-round-total-dialog',
    standalone: true,
    imports: [CommonModule, TilbyMagicFormComponent],
    templateUrl: './round-total-dialog.component.html',
    styleUrls: ['./round-total-dialog.component.scss'],
    providers: [OnDestroyService]
  })
  export class RoundTotalDialogComponent {

    protected readonly data:InputItemDataMagicForm<RoundTotalFormValue>&{ titleLabel:string, confirmDisabled: () => boolean} = inject(MAT_DIALOG_DATA);
    protected readonly roundTotalDialogStateService = inject(RoundTotalDialogStateService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    protected readonly onDestroyService = inject(OnDestroyService);

    private isProgrammaticChange = false;

    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.subscriptions();
    }

    ngAfterViewInit(): void {
        this.renderCalculateValue('.floor_decimals', `( ${this.hasPlus(this.roundTotalDialogStateService.floorTotalPriceChange)}${this.tilbyCurrencyPipe.transform(this.roundTotalDialogStateService.floorTotalPriceChange)} )`);
        this.renderCalculateValue('.floor_5_cents', `( ${this.hasPlus(this.roundTotalDialogStateService.floor5centsPriceChange)}${this.tilbyCurrencyPipe.transform(this.roundTotalDialogStateService.floor5centsPriceChange)} )`);
        this.renderCalculateValue('.rounds_decimal', `( ${this.hasPlus(this.roundTotalDialogStateService.roundTotalPriceChange)}${this.tilbyCurrencyPipe.transform(this.roundTotalDialogStateService.roundTotalPriceChange)} )`);
    }

    renderCalculateValue(type: string, value: string) {
        const floorDecimalsElement = this.el.nativeElement.querySelector(type);
        const divElement = floorDecimalsElement.querySelector('.mdc-form-field');
        const spanElement = this.renderer.createElement('span');
        const text = this.renderer.createText(value);
        this.renderer.addClass(spanElement, 'tw-ml-3');
        this.renderer.addClass(spanElement, 'tw-text-slate-400');
        this.renderer.appendChild(spanElement, text);
        this.renderer.appendChild(divElement, spanElement);
    }

    protected log(...args: any[]) {
        DevLogger.log('ROUND_TOTAL_DIALOG_COMPONENT', ...args);
    }

    hasPlus(value: number) {
        return value > 0 ? '+' : '';
    }

    private subscriptions(){
        this.floor_decimals?.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(floor_decimals => {
            this.handleSlideToggleChange('floor_decimals', floor_decimals);
            this.data.confirmDisabled = () => !floor_decimals;
        });

        this.rounds_decimal?.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(rounds_decimal => {
            this.handleSlideToggleChange('rounds_decimal', rounds_decimal);
            this.data.confirmDisabled = () => !rounds_decimal;
        });

        this.floor_5_cents?.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(floor_5_cents => {
            this.handleSlideToggleChange('floor_5_cents', floor_5_cents);
            this.data.confirmDisabled = () => !floor_5_cents;
        });
    }

    private handleSlideToggleChange(controlName: string, newValue: boolean) {
        if (!this.isProgrammaticChange) {
            this.isProgrammaticChange = true;

            const controlProperties = [
                { name: 'floor_decimals', key: 'floorDecimals' },
                { name: 'rounds_decimal', key: 'roundDecimals' },
                { name: 'floor_5_cents', key: 'floor5cents' }
            ];

            for (const controlProp of controlProperties) {
                const control = this.data.form.get(controlProp.name);

                if (controlName === controlProp.name) {
                    this.data.titleLabel = `TOT: ${this.tilbyCurrencyPipe.transform(
                        newValue
                            ? this.roundTotalDialogStateService.getPreviewNewTotal(controlProp.key)
                            : this.roundTotalDialogStateService.getPreviewNewTotal()
                    )}`;
                } else {
                    control?.setValue(false, { emitEvent: false });
                    control?.[newValue ? 'disable' : 'enable']({ emitEvent: false });
                }
            }

            this.isProgrammaticChange = false;
        }
    }

    get floor_decimals(){
        return this.data.form?.controls.floor_decimals;
    }

    get floor_5_cents(){
        return this.data.form?.controls.floor_5_cents;
    }

    get rounds_decimal(){
        return this.data.form?.controls.rounds_decimal;
    }
}
