<div class="tw-flex tw-justify-end">
    <ng-container *ngFor="let button of buttons">
        <button mat-icon-button (click)="buttonClickHandler(button.callback, button.type)" [hidden]="!button.enabled">
          <mat-icon>{{ button.icon }}</mat-icon>
        </button>
     </ng-container>
</div>

<div *ngIf="openFilter" style="height: 70px;" class="tw-flex tw-justify-center">
    <mat-form-field style="max-width: 200px;">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="filter..." value="" type="text" [(ngModel)]="filterText" (input)="applyFilter()">
    </mat-form-field>
</div>

<cdk-virtual-scroll-viewport itemSize="1" class="viewport" [ngStyle]="{'height': !openFilter ? 'calc(100vh - 160px)' : 'calc(100vh - 230px)'}">
    <div *cdkVirtualFor="let item of (isLoading ? items : $any(EMPTY_ITEMS))" class="item" (click)="onItemClick(item)">
        <div class="tw-flex tw-flex-row item">
            <div class="tw-mt-6 tw-ml-4" *skeleton="!items?.length; appearance:'circle'; theme:{width:'40px', height:'40px', background: 'darkgray'}" >
                <mat-icon [ngStyle]="{ 'color': item === selectedItem ? 'black' : 'gray' }" style="transform: scale(2)">{{ itemsIcon }}</mat-icon>
            </div>
            <div class="tw-flex tw-flex-col">
                <ng-container *skeleton="!items?.length; theme:{width:'170px', height:'20px', background: 'darkgray', margin: '5px 0px 1px 0px'}"><div class="tw-mt-4 tw-ml-4" *ngIf="fieldBuilderLegend1">{{ fieldBuilderLegend1(item) }} </div></ng-container>
                <ng-container *skeleton="!items?.length; theme:{width:'120px', height:'18px', background: 'darkgray'}"><div class="tw-text-sm tw-ml-4" *ngIf="fieldBuilderLegend2">{{ fieldBuilderLegend2(item) }}</div></ng-container>
            </div>
        </div>
    </div>
</cdk-virtual-scroll-viewport>
