import * as angular from 'angular';

angular.module('dashboard', ['application']);

angular.module('dashboard').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {

    $translatePartialLoaderProvider.addPart('dashboard');

    $stateProvider.state('app.dashboard', {
        url: '/old_dashboard',
        views: {
            "appContent@app": {
                template: require('./partial/dashboard/dashboard.html'),
                controller: 'DashboardCtrl'
            }
        },
        resolve: {
            deliveryChannels: ["entityManager", function(entityManager) {
                return entityManager.channels.fetchCollectionOffline();
            }],
        }
    });

    /* Add New States Above */
}]);
