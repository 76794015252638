<tilby-dialog-toolbar mat-dialog-title
    [title]="dialogTitle"
    [hideConfirm]="false"
    (confirm)="confirm()"
/>
<tilby-dialog-content>
    <div class="tw-flex tw-flex-col tw-gap-1 tw-w-full tw-h-full tw-text-4xl">
        <mat-form-field appearance="outline">
            <input matInput type="text" [(ngModel)]="pager" readonly />
        </mat-form-field>
        <div class="tw-grid tw-grid-cols-3 tw-gap-1 tw-col-span-2 tw-h-full">
            <button mat-button class="keypad-button" (click)="addPager('1')">1</button>
            <button mat-button class="keypad-button" (click)="addPager('2')">2</button>
            <button mat-button class="keypad-button" (click)="addPager('3')">3</button>
        </div>
        <div class="tw-grid tw-grid-cols-3 tw-gap-1 tw-col-span-2 tw-h-full">
            <button mat-button class="keypad-button" (click)="addPager('4')">4</button>
            <button mat-button class="keypad-button" (click)="addPager('5')">5</button>
            <button mat-button class="keypad-button" (click)="addPager('6')">6</button>
        </div>
        <div class="tw-grid tw-grid-cols-3 tw-gap-1 tw-col-span-2 tw-h-full">
            <button mat-button class="keypad-button" (click)="addPager('7')">7</button>
            <button mat-button class="keypad-button" (click)="addPager('8')">8</button>
            <button mat-button class="keypad-button" (click)="addPager('9')">9</button>
        </div>
        <div class="tw-grid tw-grid-cols-3 tw-gap-1 tw-col-span-2 tw-h-full">
            <button mat-button class="keypad-button tw-col-span-2" (click)="addPager('0')">0</button>
            <button mat-button class="keypad-button" (click)="removePager()" tilbyGestures (longPress)="clear()">
                <mat-icon class="tw-text-red-500 tw-scale-150" fontIcon="backspace"/>
            </button>
        </div>
    </div>
</tilby-dialog-content>
