import {Component, inject} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import { ConfigurationManagerService } from 'src/app/core';
import { Sales } from 'tilby-models';

@Component({
    selector: 'app-grid-clickable-history-sales-button',
    templateUrl: './grid-clickable-history-sales-button.component.html',
    styleUrls: ['./grid-clickable-history-sales-button.component.scss'],
})
export class GridClickableHistorySalesButtonComponent implements ICellRendererAngularComp {
    private configurationManagerService = inject(ConfigurationManagerService);
    protected isExpenseReportEnabled = !!(this.configurationManagerService.getPreference('cashregister.expense_report.department_id') && this.configurationManagerService.getPreference('cashregister.expense_report.template_id'));

    private params!: ICellRendererParams;
    private sale?: Sales;
    
    protected isEInvoice = false;
    protected isCreditNote = false;
    protected canUseExpenseReport = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.sale = params.data as Sales;

        this.isEInvoice = !!this.sale.e_invoice?.invoice_progressive;
        this.isCreditNote = this.sale.final_amount! < 0;
        this.canUseExpenseReport = this.isExpenseReportEnabled && !this.sale.sale_documents?.some((doc) => doc.document_type == 'expense_report')
    }

    refresh(): boolean {
        return false;
    }

    onOpenDetail($event: any) {
        this.params.context.componentParent.clickableAction.emit({
            action: 'detail',
            data: this.sale,
            node: this.params.node
        });
    }

    onRefundVoid($event: any) {
        this.params.context.componentParent.clickableAction.emit({
            action: 'history-sale-credit-note-cancel',
            data: this.sale
        });
    }

    onCourtesyReceipt($event: any) {
        this.params.context.componentParent.clickableAction.emit({
            action: 'history-sale-credit-print-courtesy-receipt',
            data: this.sale
        });
    }

    onDuplicateSale($event: any) {
        this.params.context.componentParent.clickableAction.emit({
            action: 'duplicate-sale',
            data: this.sale
        });
    }

    onCreateExpenseReport($event: any) {
        this.params.context.componentParent.clickableAction.emit({
            action: 'history-sale-expense-report',
            data: this.sale
        });
    }

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }
}
