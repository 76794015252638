import { Injectable } from "@angular/core";

type DialogKeys = 'dialog-import-tobacchi';

@Injectable({
    providedIn: "root"
})
export class DialogStateService {

    private dialogsJustOpen: { [key in DialogKeys]: boolean } = {
        'dialog-import-tobacchi': false
    };

    /**
     * Sets the state of the given dialog key to true indicating that the dialog has just been opened.
     *
     * @param {DialogKeys} key - The key of the dialog to set as just opened.
     * @return {void} This function does not return anything.
     */
    setDialogJustOpen(key: DialogKeys): void {
        this.dialogsJustOpen[key] = true;
    }

    /**
     * Checks if a dialog with the specified key has just been opened.
     *
     * @param key - The key of the dialog to check.
     * @returns True if the dialog has just been opened, false otherwise.
     */
    isDialogJustOpen(key: DialogKeys): boolean {
        return this.dialogsJustOpen[key];
    }

}
