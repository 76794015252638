import { Component, ViewChild, inject } from "@angular/core";
import { restManager } from "app/ajs-upgraded-providers";
import { ModuleEvents, ToolbarEventsService } from "src/app/core";
import { GridDownloadDialogService } from "src/app/dialogs";
import { GridCellFormatterService, GridClientSideComponent, GridFixedButtons, headersTranslate } from "src/app/shared/components";
import { Subscription, catchError, from, map, of } from "rxjs";
import { DataExport, FileExporterFe, TableData } from "src/app/shared/model/model";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { autobind } from "src/app/models/decorators.model";
import { GridClickableButtonFileExporterComponent } from "src/app/shared/components/grid/grid-clickable-button-file-exporter/grid-clickable-button-file-exporter.component";
import { Rest } from "src/app/models";

@Component({
    selector: 'app-settings-file-exporter-showcase',
    templateUrl: './settings-file-exporter-showcase.component.html',
    styleUrls: ['./settings-file-exporter-showcase.component.scss'],
})
export class SettingsFileExporterShowcaseComponent {
    @ViewChild("appGridComponent", { static: true }) gridRef!: GridClientSideComponent;
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly restManagerService = inject(restManager);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    private movColumnsSwitch = false;
    private isAdvancedFilterActive = false;
    selectRowUIEnabled: boolean = true;
    fileIntervalSubscription: Subscription = new Subscription();
    checkFileStatusSubscription: Subscription = new Subscription();

    fixedButtons: GridFixedButtons = {
        checkbox: { visible: false, lockPosition: "left" },
        customButton: {
            visible: true,
            cellRenderer: GridClickableButtonFileExporterComponent,
            lockPosition: "right",
        },
    };
    gridOptions = {
        loadingCellRendererParams: {
            loadingMessage: 'One moment please...',
        },
        enableCellChangeFlash: false
    };
    rows: FileExporterFe[] = [];
    tableData: TableData[] = [
        {
            rowData$: from<Promise<FileExporterFe[]>>(this.restManagerService.getList('massive_export/files'))
                .pipe(
                    map(res => {
                        this.gridRef.gridApi?.setAnimateRows(false);
                        this.rows = res;
                        return res.sort((fileA, fileB) => (TilbyDatePipe.toUtcDateMillis(fileB.created_at) - TilbyDatePipe.toUtcDateMillis(fileA.created_at)))
                    }),
                    catchError((error) => {
                        return of([]);
                    })
                ),
            dataType: new FileExporterFe(),
            headersTranslate: headersTranslate.file_exporter,
            columnsFormatter: this.gridCellFormatterService.file_exported,
        },
    ];

    ngOnInit(): void {
        this.createToolbarButtons();
    }

    ngAfterViewInit(): void {
        this.gridRef.gridApi?.setAnimateRows(false);
    }

    createToolbarButtons(){
        this.toolbarEventsService.moduleTitle.next("FILE_EXPORTER");
        this.toolbarEventsService.searchBarValue$.next('');
        this.toolbarEventsService.searchBarAction$.next({ openToolPanel: 'filters' });
        this.toolbarEventsService.searchBarActionFilled$.next({ openToolPanel: 'filters' });
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: [
                {
                isIt: () => true,
                name: 'columns_panel',
                icon: () => 'view_week',
                label: () => 'TOPBAR.ACTIONS.COLUMN_SELECTED',
                click: _ => this.toolbarEventsService.events.next({openToolPanel: 'columns'})
            },
            {
                isIt: () => true,
                name: 'movable_columns',
                icon: () => 'drag_indicator',
                label: () => this.movColumnsSwitch ? 'TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT': 'TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT',
                click: _ => this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = !this.movColumnsSwitch})
            },
            {
                isIt: () => true,
                name: 'save_columns_position',
                icon: () => 'save',
                label: () => 'TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE',
                click: _ => this.toolbarEventsService.events.next({save: true})
            },
            {
                isIt: () => true,
                name: 'advanced_filter',
                icon: () => this.isAdvancedFilterActive ? 'filter_alt_off' : 'filter_list_alt',
                label: () => this.isAdvancedFilterActive ? 'TOPBAR.ACTIONS.ADVANCED_FILTERS_DISABLED' : 'TOPBAR.ACTIONS.ADVANCED_FILTERS_ENABLED',
                click: _ => this.toolbarEventsService.events.next({type: 'advFiltersSwitch', advFiltersSwitch: this.isAdvancedFilterActive = !this.isAdvancedFilterActive})
            },
            {
                isIt: () => false,
                name: 'export',
                icon: () => 'download',
                label: () => 'TOPBAR.ACTIONS.EXPORT',
                click: async _ => await this.gridDownloadDialogService.openDialog('file_exporter',{data: this.tableData.map(tableData => ({...tableData,sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length}))},'files').then(res=>!!res&&this.onExport(res,'files'))
            },
            ]
        })
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents>&{id?:number}) {
        if ("search" in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("openToolPanel" in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
        else if ("type" in event && event.type === 'advFiltersSwitch') { this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);}
        else if ("type" in event && event.type === 'movColumnsSwitch') this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        else if ("save" in event) await this.gridRef.openDialogSaveColumnsPreference();
    }

    crud({method, id}: Rest) {
        if (method === 'delete') this.gridRef.onRemoveSelected(id);
    }

    @autobind
    onExport(dataExport: DataExport,tableToCall?:string) {
        this.gridRef?.onBtnExport(dataExport,tableToCall);
    }
}