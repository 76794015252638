<div class="container slider">
    <div [@slideAnimation]="currentIndex()" class="tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center">
        @for (slide of slides; track $index) {
            @if (isCurrentSlideIndex($index)) {
                <div class="slide-animation slide tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center" tilbyGestures (swipeLeft)="prevSlide()" (swipeRight)="nextSlide()">
                    <img class="slide nonDraggableImage tw-max-w-full" [src]="slide.image">
                </div>

            }
        }
    </div>
</div>
@if(slides.length > 1) {
    <nav class="nav">
        <div class="wrapper">
            <ul class="dots">
                @for (slide of slides; track $index) {
                    <li class="dot">
                        <span [ngClass]="{ active: isCurrentSlideIndex($index) }" (click)="setCurrentSlideIndex($index)"></span>
                    </li>
                }
            </ul>
        </div>
    </nav>
}
