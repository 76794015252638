import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { Sales, SalesPayments } from 'tilby-models';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
  standalone: true,
  imports: [CommonModule, MatListModule, TilbyGesturesDirective, TilbyCurrencyPipe]
})
export class PaymentsListComponent {

    @Input() sale: Sales | undefined;
    @Input() getPaymentDetail: ((payment: SalesPayments) => string) | undefined;

    @Output() removeSalesPayment = new EventEmitter<SalesPayments>();

    swipeLeftHandler(salesPayments:SalesPayments) {
        this.removeSalesPayment.emit(salesPayments);
    }
}
