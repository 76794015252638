import { Injectable, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EntityManagerService } from "../entity";
import { ConfigurationManagerStoreService } from "./configuration-manager-store.service";
import { ShopPreferences } from "tilby-models";
import {
    ConfigurationAnalytics,
    ConfigurationFunctions,
    ConfigurationModules,
    ConfigurationPermissions,
    ConfigurationPreferences,
    ConfigurationSettings,
    ThemeModeService,
    UserActiveSessionManagerService
} from "src/app/core";
import { DateAdapter } from "@angular/material/core";
import { firstValueFrom, merge } from "rxjs";
//Constants
import { currencies, uiLanguages } from "src/app/core/constants";
// Legacy services
import * as moment from 'moment-timezone';
import { $translate, RootScope } from "app/ajs-upgraded-providers";
// Pipes
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { TilbyNumberPipe } from "@tilby/tilby-ui-lib/pipes/tilby-number";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

type ConfigurationValueInput<V> = V extends number ? string : V;

@Injectable({
    providedIn: 'root'
})
export class ConfigurationManagerService {
    private readonly entityManager = inject(EntityManagerService);
    private readonly configurationManagerStore = inject(ConfigurationManagerStoreService);
    private readonly translate = inject(TranslateService);
    private readonly userActiveSessionManager = inject(UserActiveSessionManagerService);
    private readonly dateAdapter = inject(DateAdapter);
    private readonly themeModeService = inject(ThemeModeService);
    //Legacy services
    private readonly rootScope = inject(RootScope);
    private readonly legacyTranslate = inject($translate)
    //Pipes
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly tilbyNumberPipe = inject(TilbyNumberPipe);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);

    constructor(
    ) {
        merge(
            ConfigurationManagerStoreService.shopPreferencesUpdate$,
            ConfigurationManagerStoreService.userPreferencesUpdate$
        ).subscribe((preference) => {
            switch (preference.id) {
                case 'general.ui_language':
                    this.setupLanguage();
                    break;
                case 'general.currency':
                    this.setupCurrency();
                    break;
                case 'general.timezone':
                    this.setupTimezone();
                    break;
                case 'general.ui_theme':
                    this.setupTheme();
                    break;
                default: break;
            }
        });
    }

    private logError(...args: any[]) {
        console.error('[ ConfigurationManager ]', ...args);
    }

    /**
     * Returns the value to send to the API based on the input value.
     *
     * @param {string | number | boolean} value - The input value.
     * @return {string} The value to send.
     */
    private getValueForSend(value: string | number | boolean): string {
        switch (value) {
            case true:
                return 'on';
            case false:
                return 'off';
            default:
                return String(value ?? '');
        }
    }

    /**
     * Loads all settings and preferences into the configuration stores.
     *
     * @param {number} userId - The ID of the user. If not provided, the ID of the active session user will be used.
     * @return {Promise<any>} - A promise that resolves when all settings and preferences are loaded.
     */
    public async loadAllSettingsAndPreferences(userId?: number): Promise<any> {
        if (!userId) {
            userId = this.userActiveSessionManager.getSession()?.id;
        }

        if (!userId) {
            this.logError('[ loadAllSettingsAndPreferences ] userId is not defined');
            return;
        }

        await this.configurationManagerStore.initShopSettings()
        await this.configurationManagerStore.initUserSettings(userId);
        await this.configurationManagerStore.initShopPreferences()
        await this.configurationManagerStore.initUserPreferences(userId);
    }

    /**
     * Retrieves the preferred language.
     *
     * @return {Object} The preferred language object.
     */
    public getPreferredLanguage() {
        //Check if the user/shop has a chosen language
        const languageCode = (this.getPreference('general.ui_language')) || '';

        //Language code xx is for showing the translations ids in the UI
        if (languageCode === 'xx') {
            return { moment_locale: 'en', id: 'xx' };
        }

        let preferredLanguage = uiLanguages[languageCode];

        if (!preferredLanguage) {
            //TODO: don't rely on legacy translate
            const clientLanguage = this.legacyTranslate.resolveClientLocale();
            const localeId = this.legacyTranslate.negotiateLocale(clientLanguage) || 'en_GB';

            preferredLanguage = structuredClone(uiLanguages[localeId]);
            preferredLanguage.moment_locale = clientLanguage;
        } else {
            preferredLanguage = structuredClone(preferredLanguage);
        }

        return preferredLanguage;
    }

    /**
     * Sets up the currency related modules for the application.
     *
     * @private
     * @return {void}
     */
    private setupCurrency() {
        const currentCurrency = (this.getShopPreference('general.currency') as string) || 'EUR';

        // Update currency
        this.rootScope.currentCurrency = currencies[currentCurrency] || currencies['EUR'];

        // Update pipes
        this.configureCurrencyPipes();
    }

    /**
     * Configures the currency pipes based on the user/shop configuration.
     *
     * @private
     * @return {void}
     */
    private configureCurrencyPipes() {
        const locale = (this.getPreference('general.ui_language')) || 'it';
        const currency = (this.getPreference('general.currency')) || 'EUR';

        const { decimal_separator, thousands_separator } = uiLanguages[locale] || {
            decimal_separator: '.',
            thousands_separator: ','
        };

        //Update legacy pipe
        Object.assign(this.rootScope.currentCurrency, { decimal_separator, thousands_separator });

        //Update tilby pipe
        this.tilbyCurrencyPipe.setupCurrency(locale, currency);
    }

    /**
     * Sets up the timezone related modules for the application.
     *
     * @private
     */
    private setupTimezone() {
        const timezone = this.getPreference('general.timezone') || 'Europe/Rome';

        //Setup moment timezone (legacy)
        moment.tz.setDefault(timezone);

        //Setup tilby date pipe
        this.tilbyDatePipe.setPipeTimezone(timezone);
    }

    /**
     * Sets up the language related modules for the application.
     *
     * @return {Promise<void>} A promise that resolves once the language setup is complete.
     */
    public async setupLanguage() {
        const preferredLanguage = this.getPreferredLanguage();
        const languageId = preferredLanguage.id;

        //Setup legacy components (moment and $translate)
        moment.locale(preferredLanguage.moment_locale);
        this.legacyTranslate.use(languageId);

        //Setup components
        this.translate.setDefaultLang('it');
        this.dateAdapter.setLocale(languageId);
        this.tilbyNumberPipe.setup(languageId);
        this.configureCurrencyPipes();
        await firstValueFrom(this.translate.use(languageId));
    }

    public setupTheme() {
        const theme = this.getPreference('general.ui_theme') || 'light';
        this.themeModeService.switchTheme(theme);
    }

    /**
     * Sets up the locale related modules by calling specific setup functions
     * for currency, language, and timezone.
     *
     * @return {Promise<void>} A promise that resolves when the setup is complete.
     */
    public async setupLocale() {
        this.setupCurrency();
        await this.setupLanguage();
        this.setupTimezone();
        this.setupTheme();
    }

    /**
     * Retrieves the startup module.
     *
     * @return {any} The value of the 'onstartup_load_module' setting.
     */
    public getStartupLoadModule(): any {
        return this.getSetting('onstartup_load_module');
    }

    /**
     * Retrieves the status of the modules.
     *
     * @return {Object} An object containing the enabled modules and if they are enabled at the shop or user level
     */
    public getModulesStatus() {
        const userModules: Record<string, boolean> = {};
        const shopModules: Record<string, boolean> = {};
        const enabledModules: Record<string, boolean> = {};

        // Get the shop-level modules status
        this.configurationManagerStore.shopModules.forEach((value, key) => {
            if (value) {
                shopModules[key] = value;
                enabledModules[key] = value;
            }
        });

        // Get the user-level modules status
        this.configurationManagerStore.userModules.forEach((value, key) => {
            if (value) {
                userModules[key] = value;
            } else {
                delete enabledModules[key];
            }
        });

        return {
            userModules,
            shopModules,
            enabledModules
        }
    }

    /**
     * Determines if a module is enabled.
     * A module is enabled if it's enabled at the shop level and is not disabled at the user level
     *
     * @param {string} moduleName - The name of the module.
     * @return {boolean} Whether the module is enabled or not.
     */
    public isModuleEnabled<T extends keyof ConfigurationModules>(moduleName: T): boolean {
        const { shopModules, userModules } = this.configurationManagerStore;

        const shopStatus = shopModules.get(moduleName);

        if (!shopStatus) {
            return false;
        }

        if (userModules.has(moduleName)) {
            return !!(userModules.get(moduleName));
        }

        return !!(shopStatus);
    }

    /**
     * Checks if the migrated version of a module is enabled.
     *
     * @param {string} moduleName - The name of the module to check.
     * @return {boolean} Returns true if the migrated version of the module is enabled, false otherwise.
     */
    public isModuleAngular(moduleName: string): boolean {
        return Boolean(this.getPreference(`migration.${moduleName}` as keyof ConfigurationPreferences));
    }

    /**
     * Determines if the specified function is enabled using an opt-out rule
     * A function is enabled if it's not disabled at either the shop and the user level.
     *
     * @param {string} functionName - The name of the function.
     * @returns {boolean} - True if the function is enabled, false if it is opt-out.
     */
    public isFunctionEnabledOptout<T extends keyof ConfigurationFunctions>(functionName: T): boolean {
        const { shopFunctions, userFunctions } = this.configurationManagerStore;

        return !(shopFunctions.get(functionName) === false || userFunctions.get(functionName) === false);
    }

    /**
     * Determines if the specified function is enabled using an opt-in rule.
     * A function is enabled if it's not disabled at both the shop and the user level and enabled at either level.
     *
     * @param {string} functionName - The name of the function.
     * @return {boolean} Returns true if the function is enabled, false otherwise.
     */
    public isFunctionEnabledOptin<T extends keyof ConfigurationFunctions>(functionName: T): boolean {
        const shopStatus = this.configurationManagerStore.shopFunctions.get(functionName);
        const userStatus = this.configurationManagerStore.userFunctions.get(functionName);

        if (shopStatus === false || userStatus === false) {
            return false;
        }

        return !!(userStatus || shopStatus);
    }

    /**
     * Checks if the specified function is enabled.
     * User-level enablement take precedence over shop-level enablement.
     *
     * @param {string} functionName - The name of the function.
     * @return {boolean} Returns true if the function is enabled, false otherwise.
     */
    public isFunctionUserEnabledOptin<T extends keyof ConfigurationFunctions>(functionName: T): boolean {
        const { userFunctions, shopFunctions } = this.configurationManagerStore;

        if (userFunctions.has(functionName)) {
            return !!(userFunctions.get(functionName));
        }

        return !!(shopFunctions.get(functionName));
    }

    /**
     * Checks if the user has a specific permission using an opt-out rule (the user is permitted unless the permission is explicitly set to false).
     *
     * @param {string} permissionName - The name of the permission.
     * @return {boolean} Returns true if the user is permitted, false otherwise.
     */
    public isUserPermitted<T extends keyof ConfigurationPermissions>(permissionName: T): boolean {
        const { userPermissions } = this.configurationManagerStore;

        if (userPermissions.has(permissionName)) {
            return !!(userPermissions.get(permissionName));
        }

        return true;
    }

    /**
     * Checks if the user has a specific permission using an opt-in rule (the permission must exist and be set to true).
     *
     * @param {string} permissionName - The name of the permission.
     * @return {boolean} - Returns true if the user is permitted, false otherwise.
     */
    public isUserPermittedOptIn<T extends keyof ConfigurationPermissions>(permissionName: T): boolean {
        const userStatus = this.configurationManagerStore.userPermissions.get(permissionName);

        if (userStatus != null) {
            return !!userStatus;
        }

        return false;
    }

    /**
     * Retrieves the value of a setting from the configuration manager.
     * If the setting is not set at the shop level, the user level is ignored, otherwise user level settings override shop level ones.
     *
     * @param {string} settingName - The name of the setting to retrieve.
     * @return {string | number | boolean | null} The value of the setting, or null if it does not exist.
     */
    public getSetting<K extends keyof ConfigurationSettings, V extends ConfigurationSettings[K]>(settingName: K): ConfigurationValueInput<V> | null {
        const shopStatus = this.configurationManagerStore.shopSettings.get(settingName);
        const userStatus = this.configurationManagerStore.userSettings.get(settingName);

        if (shopStatus == null) {
            return null;
        }

        if (userStatus == null) {
            return shopStatus as ConfigurationValueInput<V>;
        }

        return userStatus as ConfigurationValueInput<V>;
    }

    /**
     * Retrieves the value of a specific setting for the user,
     * falling back to the shop level if the setting is not
     * found at the user level.
     *
     * @param {string} settingName - The name of the setting to retrieve.
     * @return {string | number | boolean | null} The value of the setting, or null if not found.
     */
    public getSettingUserFirst<K extends keyof ConfigurationSettings, V extends ConfigurationSettings[K]>(settingName: K): ConfigurationValueInput<V> | null {
        const { userSettings, shopSettings } = this.configurationManagerStore;

        if (userSettings.has(settingName)) {
            return userSettings.get(settingName)! as ConfigurationValueInput<V>;
        }

        if (shopSettings.has(settingName)) {
            return shopSettings.get(settingName)! as ConfigurationValueInput<V>;
        }

        return null;
    }

    /**
     * Retrieves the analytics setting for the given setting name.
     * User analytics settings take precedence over shop analytics settings.
     *
     * @param {string} settingName - The name of the setting to retrieve.
     * @return {string | number | boolean | null} The value of the setting, or null if not found.
     */
    public getAnalyticsSetting<K extends keyof ConfigurationAnalytics, V extends ConfigurationAnalytics[K]>(settingName: K): ConfigurationValueInput<V> | null {
        const { userAnalytics, shopAnalytics } = this.configurationManagerStore;

        if (userAnalytics.has(settingName)) {
            return userAnalytics.get(settingName)! as ConfigurationValueInput<V>;
        }

        if (shopAnalytics.has(settingName)) {
            return shopAnalytics.get(settingName)! as ConfigurationValueInput<V>;
        }

        return null;
    }

    /**
     * Retrieves the value of a preference given its name.
     * User preferences take precedence over shop preferences.
     *
     * @param {string} preferenceName - The name of the preference.
     * @return {string | number | boolean | null} The value of the preference. Returns `null` if the preference is not found.
     */
    public getPreference<K extends keyof ConfigurationPreferences, V extends ConfigurationPreferences[K]>(preferenceName: K): ConfigurationValueInput<V> | null {
        const { userPreferences, shopPreferences } = this.configurationManagerStore;

        if (userPreferences.has(preferenceName)) {
            return userPreferences.get(preferenceName)! as ConfigurationValueInput<V>;
        }

        if (shopPreferences.has(preferenceName)) {
            return shopPreferences.get(preferenceName)! as ConfigurationValueInput<V>;
        }

        return null;
    }

    /**
     * Retrieves the shop preference value associated with the given name.
     *
     * @param {string} name - The name of the shop preference.
     * @return {string | number | boolean | null} The value of the shop preference, or null if it does not exist.
     */
    public getShopPreference<K extends keyof ConfigurationPreferences, V extends ConfigurationPreferences[K]>(preferenceName: K): ConfigurationValueInput<V> | null {
        return this.configurationManagerStore.shopPreferences.get(preferenceName) as ConfigurationValueInput<V> ?? null;
    }

    /**
     * Retrieves the shop preferences with an optional filter.
     *
     * @param {string} filter - An optional filter to apply to the shop preferences.
     * @return {Record<string, string | number | boolean | null>} - The filtered shop preferences.
     */
    public getShopPreferences(filter?: string): Partial<ConfigurationPreferences> {
        return this.configurationManagerStore.getFilteredStore<ConfigurationPreferences>(this.configurationManagerStore.shopPreferences, filter);
    }

    /**
     * Retrieves the user preferences from the configuration manager store.
     *
     * @param {string} filter - An optional filter to apply to the user preferences.
     * @return {Record<string, string | number | boolean | null>} - The user preferences that match the given filter.
     */
    public getUserPreferences(filter?: string): Partial<ConfigurationPreferences> {
        return this.configurationManagerStore.getFilteredStore<ConfigurationPreferences>(this.configurationManagerStore.userPreferences, filter);
    }

    /**
     * Sets the shop preference with the specified name to the given value.
     *
     * @param {string} preferenceName - The name of the shop preference.
     * @param {string | number | boolean} value - The value to set for the shop preference.
     * @return {void}
     */
    public setShopPreference<T extends keyof ConfigurationPreferences, V extends ConfigurationPreferences[T]>(preferenceName: T, value: V): void {
        if (value === undefined) {
            this.logError(`[ setShopPreference ] value is required to set ${preferenceName}`);
        }

        const obj = {
            id: preferenceName,
            value: this.getValueForSend(value)
        };

        if (!this.configurationManagerStore.shopPreferences.has(preferenceName)) {
            this.entityManager.shopPreferences.postOneOfflineFirst(obj);
        } else {
            this.entityManager.shopPreferences.putOneOfflineFirst(obj);
        }

        this.configurationManagerStore.updateShopPreference(obj);
    }

    /**
     * Sets a user preference with the specified name and value.
     *
     * @param {string} name - The name of the user preference.
     * @param {string | number | boolean} value - The value to set for the user preference.
     * @return {void} This function does not return any value.
     */
    public setUserPreference<K extends keyof ConfigurationPreferences, V extends ConfigurationPreferences[K]>(name: K, value: V): void {
        if (value === undefined) {
            this.logError(`[ setUserPreference ] value is required to set ${name}`);
            return;
        }

        const obj = {
            id: name,
            value: this.getValueForSend(value)
        };

        if (!this.configurationManagerStore.userPreferences.has(name)) {
            this.entityManager.userPreferences.postOneOfflineFirst(obj);
        } else {
            this.entityManager.userPreferences.putOneOfflineFirst(obj);
        }

        this.configurationManagerStore.updateUserPreference(obj);
    }

    /**
     * Retrieves the country of the shop.
     *
     * @return {string} The country of the shop.
     */
    public getShopCountry(): string {
        return (this.getShopPreference('general.address_country') as string) || 'IT'
    }

    /**
     * Sets the shop preference synchronously.
     *
     * @param {string} name - The name of the shop preference.
     * @param {string | number | boolean} value - The value to set for the shop preference.
     * @return {Promise<any>} - A Promise that resolves when the shop preference is set.
     */
    public async setShopPreferenceSync<K extends keyof ConfigurationPreferences, V extends ConfigurationPreferences[K]>(name: K, value: V): Promise<any> {
        if (value === undefined) {
            this.logError(`[ setShopPreferenceSync ] value is required to set ${name}`);
            throw 'MISSING_VALUE';
        }

        const obj: ShopPreferences = {
            id: name,
            value: this.getValueForSend(value)
        };

        if (!this.configurationManagerStore.shopPreferences.has(name)) {
            await this.entityManager.shopPreferences.postOneOnline(obj);
        } else {
            await this.entityManager.shopPreferences.putOneOnline(obj);
        }

        this.configurationManagerStore.updateShopPreference(obj);
    }
}
