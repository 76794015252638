import { CommonModule } from "@angular/common";
import { Component, Injectable, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "src/app/dialogs/services";
import { MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MathUtils } from "@tilby/tilby-ui-lib/utilities";
import { MagicPortalDialogComponent } from "src/app/dialogs/magic-portal-dialog/magic-portal-dialog.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { ConfigurationManagerService, currencies } from "src/app/core";
import { MatInputModule } from "@angular/material/input";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";

type CurrencyConverterFormValues = {
    secondary_currency?: string | null,
    secondary_exchange_rate?: number | null
}

type CurrencyConverterParams = CurrencyConverterFormValues & {
    currency: string,
    final_amount: number
};

@Component({
    selector: 'app-currency-converter-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        TilbyCurrencyPipe,
        TranslateModule
    ],
    templateUrl: './currency-converter-dialog.component.html',
    styleUrls: ['./currency-converter-dialog.component.scss'],
    providers: [
        OnDestroyService,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }
    ]
})
export class CurrencyConverterDialogComponent {
    protected readonly currencyConverterDialogStateService = inject(CurrencyConverterDialog);
    protected readonly configurationManager = inject(ConfigurationManagerService);
    protected readonly onDestroyService = inject(OnDestroyService);

    protected readonly data = inject(MAT_DIALOG_DATA);
    protected readonly dialogRef = inject(MatDialogRef<CurrencyConverterDialogComponent>);

    protected currencies = Object.keys(currencies);
    protected secondaryFinalAmount = 0;
    protected secondaryDigits: number = 2;
    protected secondaryCurrencyCode: string = '';

    protected currencyConverterForm = new FormGroup({
        secondary_currency: new FormControl(this.data.secondary_currency || null, [Validators.required]),
        secondary_exchange_rate: new FormControl(this.data.secondary_exchange_rate || 1, [Validators.required])
    });;

    constructor() {
        try {
            const allowedCurrenciesPref = this.configurationManager.getPreference('cashregister.currency_converter.allowed_currencies') || '';

            if(allowedCurrenciesPref) {
                const allowedCurrencies = JSON.parse(allowedCurrenciesPref);

                if(Array.isArray(allowedCurrencies) && allowedCurrencies.length) {
                    const allowedCurrenciesMap: Record<string, boolean> = allowedCurrencies.reduce((acc, currCode: string) => {
                        acc[currCode] = true;
                        return acc;
                    }, {});

                    this.currencies = this.currencies.filter(currency => allowedCurrenciesMap[currency]);
                }
            }
        } catch(err) {
            console.error(err);
        }

        this.updateSecondaryCurrency(this.data.secondary_currency)
    }

    private updateOutput(inputValues: CurrencyConverterFormValues) {
        this.secondaryFinalAmount = MathUtils.round((inputValues.secondary_exchange_rate || 0) * this.data.final_amount, 2);

        this.updateSecondaryCurrency(inputValues.secondary_currency || '');
    }

    updateSecondaryCurrency(currency: string) {
        const currentCurrency = currencies[currency];

        this.secondaryCurrencyCode = currentCurrency?.code ?? '';
        this.secondaryDigits = currentCurrency?.decimal_digits ?? 2;
    }

    ngAfterViewInit(): void {
        MagicPortalDialogComponent.confirmDisabledSubject$.next(this.currencyConverterForm.status != 'VALID');
        this.updateOutput(this.currencyConverterForm.value);
        this.subscriptions();
    }

    private subscriptions() {
        this.currencyConverterForm.statusChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(res => {
            MagicPortalDialogComponent.confirmDisabledSubject$.next(res != 'VALID');
        });

        this.currencyConverterForm.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(res => {
            this.updateOutput(res);
        });

        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe(res => {
            this.dialogRef.close({
                secondary_currency: this.currencyConverterForm.value.secondary_currency,
                secondary_exchange_rate: this.currencyConverterForm.value.secondary_exchange_rate
            });
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class CurrencyConverterDialog {
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);

    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<CurrencyConverterDialogComponent>>

    public openDialog(config: NonNullableConfigData<CurrencyConverterParams>) {
        const { final_amount, currency, secondary_currency, secondary_exchange_rate, ...dialogConfig } = config.data;

        const configPortal: NonNullablePortalConfigData<CurrencyConverterParams, CurrencyConverterDialogComponent> = {
            ...dialogConfig,
            data: {
                component: CurrencyConverterDialogComponent,
                currency: currency,
                final_amount: final_amount,
                secondary_currency: secondary_currency,
                secondary_exchange_rate: secondary_exchange_rate,
                dontCloseDialogOnConfirm$: new BehaviorSubject(true),
                titleLabel: this.translateService.instant('CASHREGISTER.CURRENCY_CONVERTER.TITLE')
            }
        };

        this.dialogRef = this.openDialogsService.openPortalDialogRef<
            CurrencyConverterDialogComponent,
            PortalConfigData<CurrencyConverterParams, CurrencyConverterDialogComponent>,
            CurrencyConverterFormValues
        >({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });

        return lastValueFrom(this.dialogRef.afterClosed())
    }
}
