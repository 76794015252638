import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('promotions').controller('PromotionsShowcaseCtrl', ["$scope", "$rootScope", "$translate", "$state", "user", "promptDialog", "confirmDialog", "itemLabelsDialog", "entityManager", function($scope, $rootScope, $translate, $state, user, promptDialog, confirmDialog, itemLabelsDialog, entityManager) {

    $scope.topbar_context = {
        mode: 'showcase',
        title: $translate.instant('PROMOTIONS.TOPBAR.PROMOTIONS')
    };

    $scope.user = user;
    $scope.promotionsData = {};
    $scope.filteredPromotionsData = undefined;

    var weekDaysMap = _.zipObject(moment.localeData('en').weekdaysMin(false), _.map(moment.weekdaysShort(false), _.upperFirst));

    $scope.currentPage = 0;
    $scope.loadingInProgress = true;

    $scope.selectPromotion = function(promotion) {
        $state.go("app.promotions.details", {
            promotionId: promotion.id
        });
    };

    $scope.addPromotion = function() {
        $state.go("app.promotions.new");
    };

    $scope.getColorEnabled = function(promotion) {
        return promotion.active ? '#a5d6a7' : '#ff5252';
    };

    $scope.hasNoResults = function() {
        return $scope.promotionsData === false || $scope.promotionsData.length === 0;
    };

    $scope.decrementPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage--;
            getPromotionsPage($scope.currentPage);
        }
    };

    $scope.incrementPage = function() {
        if ($scope.currentPage < $scope.promotionsData.pages - 1) {
            $scope.currentPage++;
            getPromotionsPage($scope.currentPage);
        }
    };

    $scope.goToFirstPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage = 0;
            getPromotionsPage($scope.currentPage);
        }
    };

    $scope.goToLastPage = function() {
        if ($scope.currentPage < $scope.promotionsData.pages - 1) {
            $scope.currentPage = $scope.promotionsData.pages - 1;
            getPromotionsPage($scope.currentPage);
        }
    };

    $scope.togglePromotion = function(promotion) {
        var promotionToChange = _.cloneDeep(promotion);

        promotionToChange.active = !promotionToChange.active;

        entityManager.promotions.putOneOnline(promotionToChange).then(function(result) {
            getPromotionsPage($scope.currentPage);
        });
    };

    $scope.duplicatePromotion = function(promotion) {
        promptDialog.show({title: $translate.instant('PROMOTIONS.SHOWCASE.COPY_TITLE'), defaultValue: ($translate.instant('PROMOTIONS.SHOWCASE.COPY_TEXT', {value: promotion.name})).substring(0,255), minLength: 0, maxLength: 255}).then(function(newName) {
            var clonedPromotion = _.cloneDeep(promotion);

            _.assign(clonedPromotion, {
                name: newName,
                active: false,
                id: undefined
            });

            _.forEach(clonedPromotion.items, function(item) {
                delete item.id;
            });

            _.forEach(clonedPromotion.required_items, function(item) {
                delete item.id;
            });

            entityManager.promotions.postOneOnline(clonedPromotion).then(function(result) {
                getPromotionsPage($scope.currentPage);
            }, function(error) {});
        });
    };

    $scope.generateLabels = function(promotion) {
        itemLabelsDialog.openDialog({ data: { promotion }});
    };

    $scope.deletePromotion = function(promotion) {
        confirmDialog.show($translate.instant('PROMOTIONS.SHOWCASE.CONFIRM_DELETE', { name: promotion.name })).then(function(answer) {
            if(answer) {
                entityManager.promotions.deleteOneOnline(promotion.id).then(function() {
                    getPromotionsPage($scope.currentPage);
                });
            }
        });
    };

    var parsePromotionsData = function(promotion) {
        _.assign(promotion, {
            _fromDate: promotion.from_date ? moment(promotion.from_date, 'YYYY-MM-DD').format('l') : null,
            _toDate: promotion.to_date ? moment(promotion.to_date, 'YYYY-MM-DD').format('l') : null,
            _startTime: moment(promotion.start_time || '00:00:00', 'HH:mm:ss').seconds(0).format('LT'),
            _endTime: moment(promotion.end_time || '23:59:59', 'HH:mm:ss').seconds(0).format('LT'),
            _weekDays: _.isEmpty(promotion.weekdays_period) || _.size(promotion.weekdays_period) === 7 ? $translate.instant('PROMOTIONS.SHOWCASE.EVERY_DAY') : _.map(promotion.weekdays_period, function(day) { return weekDaysMap[day]; }).join(',')
        });
    };

    var getPromotionsPage = function(pageNumber) { //status: 0 -> closed + stored, 1-> stored, 2-> closed
        $rootScope.$broadcast("loader:changeStatus", "promotionsShowcaseLoader", { enabled: true });
        $scope.loadingInProgress = true;

        // let the scope.digest to hide items
        $scope.$evalAsync(function() {
            $scope.promotionsData.results = [];

            var query = {
                pagination: true,
                orderby_asc: 'name',
                page: pageNumber
            };

            entityManager.promotions.fetchCollectionOnline(query).then(function(result) {
                $scope.promotionsData = result;
                _.forEach(result.results, parsePromotionsData);

                $rootScope.$broadcast("loader:changeStatus", "promotionsShowcaseLoader", { enabled: false });
                $scope.loadingInProgress = false;
            });
        });
    };

    getPromotionsPage(0);
}]);
