<h2 mat-dialog-title>{{'PRINTERS.DAILY_CLOSING_DIALOG.TITLE'|translate}}</h2>
<mat-dialog-content class="">
    <form autocomplete="off" [formGroup]="dailyClosingDialogForm" class="tw-flex">
        <mat-form-field [color]="'primary'" class="items tw-flex-1 tw-px-0" appearance="fill">
            <mat-label>{{'PRINTERS.DAILY_CLOSING_DIALOG.CASH_VERIFICATION'|translate}}</mat-label>
            <input matInput formControlName="cashVerification" type="number" [autofocus]="true">
            <mat-error *ngIf="cashVerification?.invalid">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<div class="tw-flex tw-justify-center tw-mx-6 tw-mb-3" translate [translateParams]="{ value: data.printer.name }">PRINTERS.DAILY_CLOSING_DIALOG.PROMPT</div>
<mat-dialog-actions class="tw-px-5 tw-pb-2" align="end">
    <button mat-button [mat-dialog-close]="'cancel'">{{'DIALOG.EMAIL.ACTION.CANCEL'| translate}}</button>
    <button mat-flat-button [disabled]="dailyClosingDialogForm.invalid" (click)="answer()" color="primary">{{ 'PRINTERS.DAILY_CLOSING_DIALOG.CONFIRM' | translate}}</button>
</mat-dialog-actions>
