import { CommonModule } from "@angular/common";
import {
    Component,
    Injectable,
    inject
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { lastValueFrom } from "rxjs";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseInputButton } from "@tilby/tilby-ui-lib/models";

export type TilbyDialogData = {
    textContent: string,
    textToggle: string,
    classTextContent: string
};

type ConfirmDialogReturn = {
    confirm: boolean,
    toggleChoice: boolean
};

@Component({
    selector: 'app-confirm-dialog-with-remember.component',
    templateUrl: './confirm-dialog-with-remember.component.html',
    styleUrls: ['./confirm-dialog-with-remember.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent, MatButtonModule, MatInputModule, MatSlideToggleModule],
})
export class ConfirmDialogWithRememberComponent {
    protected readonly data: TilbyDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);

    buttons: BaseInputButton[] = [
        { name: 'MISC.NO', click: () => this.closeDialog(false) },
        { name: 'MISC.YES', click: () => this.closeDialog(true) }
    ];

    toggleChoiceForm = new FormGroup({
        toggleChoice: new FormControl(false)
    });

    closeDialog(confirm: boolean) {
        this.matDialogRef.close({ confirm, toggleChoice: this.toggleChoiceForm.value.toggleChoice });
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogWithRememberService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<TilbyDialogData>): Promise<ConfirmDialogReturn> {
        const configEdited: NonNullableConfigData<TilbyDialogData> = { ...config, ...this.switchMobileDesktopDimensions(), disableClose: true };
        return lastValueFrom(this.dialogRef.open(ConfirmDialogWithRememberComponent, configEdited).afterClosed());
    }
}