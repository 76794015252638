<mat-toolbar class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-0 tw-bg-white sm:tw-p-4">
    <div class="tw-flex tw-truncate">
        <p class="tw-text-md tw-truncate tw-ml-4" translate>HISTORY.REFUND_ITEMS_SELECTOR.TITLE</p>
    </div>
    <div class="tw-flex">
        <app-barcode (onSubmitBarcode)="onBarcodeScanned($event)" [idSelector]="'barcodeInputdialog'"></app-barcode>
        <app-barcode-scanner (onBarcodeScannedChange)="onBarcodeScanned($event)" [topbarMode]="true"></app-barcode-scanner>
    </div>
</mat-toolbar>
<div class="tw-flex tw-justify-center tw-mb-3">
    <button class="tw-mr-4" mat-stroked-button color="primary" (click)="unselectAll()">
        <span translate>HISTORY.REFUND_ITEMS_SELECTOR.UNSELECT_ALL</span>
    </button>
    <button mat-stroked-button color="primary" (click)="selectAll()">
        <span translate>HISTORY.REFUND_ITEMS_SELECTOR.SELECT_ALL</span>
    </button>
</div>
<mat-dialog-content [ngClass]="[isMobile ? 'tw-min-h-[calc(100vh-165px)]' : 'tw-min-h-[calc(50vh+50px)]']" class="tw-w-full sm:tw-w-96">
    <cdk-virtual-scroll-viewport appendOnly itemSize="50" [ngClass]="[isMobile ? 'tw-min-h-[calc(100vh-205px)]' : 'tw-min-h-[50vh]']">
        <div *cdkVirtualFor="let item of data.saleItems" style="height:80px" class="tw-flex tw-flex-row">
            <mat-form-field appearance="fill" class="tw-mr-5 tw-w-28" style="min-width: 112px;">
                <mat-label translate>HISTORY.REFUND_ITEMS_SELECTOR.QUANTITY</mat-label>
                <mat-select [(ngModel)]="item._quantity">
                    <mat-option *ngFor="let qty of item._quantities" [value]="qty">{{qty}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="tw-flex tw-flex-wrap tw-w-full tw-h-5 tw-mr-1">
                <div class="sm:tw-w-2/3 tw-w-full">
                    <span>{{item.name}}</span>
                </div>
                <div class="sm:tw-w-1/3 tw-text-left tw-w-full sm:tw-text-right">
                    <span>{{(item.final_price * item.quantity) | tilbyCurrency}}</span>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="'Cancel'"><span translate>HISTORY.REFUND_ITEMS_SELECTOR.ACTION.CANCEL</span></button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="check()" [disabled]="hasCheckDisabled()"><span translate>HISTORY.REFUND_ITEMS_SELECTOR.ACTION.CONFIRM</span></button>
</mat-dialog-actions>
