import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DataConfirmDialog} from "../dialog.model";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  host:{class:'tw-p-0'}
})
export class ConfirmDialogComponent {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DataConfirmDialog,
  ) { }

}
