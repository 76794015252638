import { CommonModule } from "@angular/common";
import { Component, ContentChildren, ElementRef, Injectable, Injector, QueryList, Renderer2, ViewChild, ViewChildren, inject, runInInjectionContext } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, InputType, TilbyInputComponent, TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { lastValueFrom } from "rxjs";
import { AddressDetails, ContactDetails, EditShopPreferenceData, EditShopPreferenceFields } from "./model";
import { FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { countryCodesShort, subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import { Loader } from "@googlemaps/js-api-loader";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {mobileCheck} from 'src/utilities';
const { apiKeys } = require('app/tilby.properties.json');

@Component({
    selector: 'app-edit-shop-preference-dialog',
    templateUrl: './edit-shop-preference-dialog.component.html',
    styleUrls: ['./edit-shop-preference-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, TilbyMagicFormComponent, TilbyDialogToolbarComponent, TilbyDialogContentComponent, TilbyInputComponent, MatInputModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, TranslateModule],
})
export class EditShopPreferenceDialogComponent {
    protected readonly data: EditShopPreferenceData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly injector = inject(Injector);
    private readonly renderer2 = inject(Renderer2);
    isMobile: boolean = mobileCheck();
    editShopPreferenceForm!: CustomFormGroup<CustomForm<EditShopPreferenceFields>>;

    @ViewChild('searchTextField', { static: true }) inputRef?: ElementRef<HTMLInputElement>;
    @ViewChildren('contentProjection') elementsToShow?: QueryList<ElementRef<HTMLElement>>;
    @ContentChildren('contentProjection', { read: ElementRef }) childTemplate?: QueryList<ElementRef<HTMLElement>>;
    

    loader = new Loader({
        apiKey: apiKeys.googleMaps,
        version: "weekly",
        libraries: ["places"]
    });

    defaultBounds!: google.maps.LatLngBounds;
    searchBox?: google.maps.places.SearchBox;

    ngOnInit(){
        const noHasAddress = this.data.addressStreet === '' && this.data.addressProv === '' && this.data.addressZip === '' && this.data.addressCity === '' && this.data.addressCountry === '';
        const hasAddress = !noHasAddress;

        this.editShopPreferenceForm = new CustomFormGroup<CustomForm<EditShopPreferenceFields>>({
            shopName: new CustomFormControl(this.data.shopName, { validators: [Validators.required, Validators.maxLength(200)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.SHOP_NAME', inputType: 'text', class: "tw-w-full"}),
            companyName: new CustomFormControl(this.data.companyName, { validators: [Validators.required, Validators.maxLength(200)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.COMPANY_NAME', inputType: 'text', class: "tw-w-full"}),
            vat_code: new CustomFormControl(this.data.vat_code, { validators: [Validators.required, Validators.pattern("^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$")]}, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.VAT_CODE', inputType: 'text', class: "tw-w-full"}),
            address: new CustomFormGroup<CustomForm<AddressDetails>>({
                addressStreet: new CustomFormControl(this.data.addressStreet, { validators: [Validators.required, Validators.maxLength(200)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_STREET', inputType: this.checkAddress(hasAddress), class: "tw-w-full sm:tw-w-1/3"}),
                addressZip: new CustomFormControl(this.data.addressZip, { validators: [Validators.required, Validators.maxLength(20)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_ZIP', inputType: this.checkAddress(hasAddress), class: "tw-w-full sm:tw-w-1/3"}),
                addressCity: new CustomFormControl(this.data.addressCity, { validators: [Validators.required, Validators.maxLength(30)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_CITY', inputType: this.checkAddress(hasAddress), class: "tw-w-full sm:tw-w-1/3"}),
                addressCountry: new CustomFormControl(countryCodesShort.filter((country) => { return country.code === this.data.addressCountry})[0]?.name, { validators: [Validators.required, Validators.maxLength(30)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_COUNTRY', inputType:  this.checkAddress(hasAddress, 'autocomplete'), inputChoices: countryCodesShort.map(({code,name})=>({key:code,value: name})), class: "tw-w-full sm:tw-w-1/3"}),    
                addressProv: new CustomFormControl(this.data.addressProv, { validators: [Validators.required, this.data.addressCountry === 'IT' ? Validators.maxLength(2) : Validators.maxLength(30)] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_PROV', inputType: this.checkAddress(hasAddress), class: "tw-w-full sm:tw-w-1/3"}),
            }, {}, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_DETAILS', classLabel: "tw-px-1 tw-pb-4 tw-text-[18px]", class: "sm: tw-flex tw-flex-col"}),
            contacts: new CustomFormGroup<CustomForm<ContactDetails>>({  
                email: new CustomFormControl(this.data.email, { validators: [Validators.email] }, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.EMAIL', inputType: 'text', class: "tw-w-full sm:tw-w-1/3"}),
                phone: new CustomFormControl(this.data.phone, { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]}, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.PHONE', inputType: 'text', class: "tw-w-full sm:tw-w-1/3"}),
            }, {}, {...new CustomFormControlProps(), label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.CONTACT_DETAILS', classLabel: "tw-px-1 tw-pb-4 tw-text-[18px]", class: `sm: tw-flex tw-flex-col ${this.isMobile ? 'tw-pb-[60px]' : ''}`}),
        })

        if (noHasAddress) {
            this.editShopPreferenceForm.controls.address.controls.address_autocomplete = new CustomFormControl(noHasAddress ? '' : this.data.addressStreet, { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:  this.checkAddress(!hasAddress, 'contentProjection'), class: "sass tw-w-full sm:tw-w-1/3"})
        }
    }

    checkAddress(hasAddress: boolean, inputType: InputType = 'text') {
        return hasAddress ? inputType : 'hidden';
    }

    ngAfterViewInit(){
        this.initAutocomplete();

        runInInjectionContext(
            this.injector, 
            () => {
                subscribeInComponent(
                    this.editShopPreferenceForm.controls.address.controls.addressCountry?.valueChanges!,
                    () => {
                        this.editShopPreferenceForm.controls.address.controls.addressProv?.clearValidators();
                        this.editShopPreferenceForm.controls.address.controls.addressProv?.addValidators([Validators.required, Validators.maxLength(this.editShopPreferenceForm.controls.address.controls.addressCountry?.value !== 'Italy' ? 30 : 2)]);
                        this.editShopPreferenceForm.controls.address.controls.addressProv?.updateValueAndValidity();
                    }
                )
            }
        )

        this.childTemplate?.forEach((c, i) =>
            this.renderer2.appendChild(
                this.elementsToShow?.get(i)?.nativeElement,
                c.nativeElement
            )
        );
    }

    async initAutocomplete() {
        await this.loader.load();

        this.searchBox = this.inputRef ? new google.maps.places.SearchBox(this.inputRef.nativeElement, {
            bounds: this.defaultBounds
        }) : undefined;

        const searchBox = new google.maps.places.SearchBox(this.inputRef?.nativeElement!);

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places?.length == 0) {
                return;
            }

            const street_number = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("street_number"))?.short_name;
            const route = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("route"))?.short_name;
            const locality = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("locality"))?.short_name;
            const city = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("administrative_area_level_2"))?.short_name;
            const province = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("administrative_area_level_1"))?.short_name;
            const country = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("country"))?.short_name;
            const postal_code = places?.[0].address_components!.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("postal_code"))?.short_name;

            this.editShopPreferenceForm.controls.address.controls.address_autocomplete?.setValue(street_number ? route + ', ' + street_number : route);
            this.editShopPreferenceForm.controls.address.controls.addressCity?.setValue(locality);
            this.editShopPreferenceForm.controls.address.controls.addressZip?.setValue(postal_code);
            this.editShopPreferenceForm.controls.address.controls.addressProv?.setValue(city);
            this.editShopPreferenceForm.controls.address.controls.addressCountry?.setValue(countryCodesShort.filter(c => c.code === country)[0].name);
            this.editShopPreferenceForm.controls.address.controls.addressStreet?.setValue(street_number ? route + ', ' + street_number : route);

            this.editShopPreferenceForm.controls.address.controls.addressCity && (this.editShopPreferenceForm.controls.address.controls.addressCity.customProps.inputType = this.checkAddress(true));
            this.editShopPreferenceForm.controls.address.controls.addressZip && (this.editShopPreferenceForm.controls.address.controls.addressZip.customProps.inputType = this.checkAddress(true));
            this.editShopPreferenceForm.controls.address.controls.addressProv && (this.editShopPreferenceForm.controls.address.controls.addressProv.customProps.inputType = this.checkAddress(true));
            this.editShopPreferenceForm.controls.address.controls.addressCountry && (this.editShopPreferenceForm.controls.address.controls.addressCountry.customProps.inputType = this.checkAddress(true, 'autocomplete'));
            this.editShopPreferenceForm.controls.address.controls.addressStreet && (this.editShopPreferenceForm.controls.address.controls.addressStreet.customProps.inputType = this.checkAddress(true));

            this.editShopPreferenceForm.controls.address.controls.address_autocomplete && (this.editShopPreferenceForm.controls.address.controls.address_autocomplete.customProps.inputType = this.checkAddress(false));

            this.editShopPreferenceForm.controls.address.updateValueAndValidity();
        });
    }

    protected confirm() {
        (this.editShopPreferenceForm.valid) ? this.matDialogRef.close({
            shopName: this.editShopPreferenceForm.value.shopName,
            companyName: this.editShopPreferenceForm.value.companyName,
            vat_code: this.editShopPreferenceForm.value.vat_code,
            addressStreet: this.editShopPreferenceForm.value.address!.addressStreet,
            addressProv: this.editShopPreferenceForm.value.address!.addressProv,
            addressZip: this.editShopPreferenceForm.value.address!.addressZip,
            addressCity: this.editShopPreferenceForm.value.address!.addressCity,
            addressCountry: this.editShopPreferenceForm.value.address!.addressCountry,
            email: this.editShopPreferenceForm.value.contacts!.email,
            phone: this.editShopPreferenceForm.value.contacts!.phone,
        }) : this.editShopPreferenceForm.markAllAsTouched();
    }
}

@Injectable({
    providedIn: "root",
})
export class EditShopPreferenceDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data: EditShopPreferenceData) {
        const config: NonNullableConfigData<EditShopPreferenceData> = {
            ...this.switchMobileDesktopDimensions({width: '60%'}, {fullScreenForMobile: true}),
            disableClose: true,
            data
        };
        return lastValueFrom(
            this.dialogRef.open(EditShopPreferenceDialogComponent, config).afterClosed()
        ).then((res: EditShopPreferenceData) => {
            return res;
        });
    }
}