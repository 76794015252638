<div class="tw-flex tw-flex-row tw-h-full">
    <div class="tw-w-full">
        <mat-tab-group mat-stretch-tabs="true" [(selectedIndex)]="demo1TabIndex" color="primary" class="tw-h-full"
                (selectedTabChange)="tabPanelChange.emit($event.tab.textLabel)" tilbyGestures (swipeLeft)="swipeLeft()"
                (swipeRight)="swipeRight()">
            <mat-tab label="{{'CUSTOMERS.DETAILS.TABS.SUMMARY' | translate}}"><ng-content select="[Riepilogo]"></ng-content></mat-tab>
            <mat-tab label="{{'CUSTOMERS.DETAILS.TABS.REGISTRY' | translate}}"><ng-content select="[Anagrafica]"></ng-content></mat-tab>
            <mat-tab label="{{'CUSTOMERS.DETAILS.TABS.CREDIT' | translate}}"><ng-content select="[Credito]"></ng-content></mat-tab>
            <mat-tab label="{{'CUSTOMERS.DETAILS.TABS.POINTS' | translate}}"><ng-content select="[Punti]"></ng-content></mat-tab>
        </mat-tab-group>
    </div>
</div>
