import * as angular from 'angular';

angular.module("items").directive("itemsmoduleListItem", function() {
	return {
        restrict: 'E',
        replace: true,
        scope: {
            hideCategory: "=",
            hideDepartment: "=",
            item: "=",
            enableCheckbox: "@",
            onFavoriteClick: "=",
            onCheck: "=",
            onClick: "=",
            onDeleteClick: "=",
            onCloneClick: "=",
            onLabelsClick: "=",
            getPrice: "="
        },
		template: require('./itemsmodule-list-item.html')
	};
});