import { CommonModule } from '@angular/common';
import { Component } from "@angular/core";
import { SettingsAlvoloContentComponent } from "./settings-alvolo-content/settings-alvolo-content.component";
import { SettingsAlvoloOptionsComponent } from "./settings-alvolo-options-list/settings-alvolo-options-list.component";

@Component({
    selector: "app-settings-alvolo-initialized",
    templateUrl: "./settings-alvolo-initialized.component.html",
    styleUrls: ["./settings-alvolo-initialized.component.scss"],
    standalone: true,
    imports: [CommonModule, SettingsAlvoloContentComponent, SettingsAlvoloOptionsComponent]
})
export class SettingsAlvoloInitializedComponent {
    optionSelected!: { key: number; value: string };

    getOption(option: { key: number; value: string }) {
        this.optionSelected = option;
    }
}
