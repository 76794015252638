import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { GenericListData, GenericListDialogMessage, GenericListItem } from './generic-list-dialog.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-generic-list-dialog',
    templateUrl: './generic-list-dialog.component.html',
    styleUrls: ['./generic-list-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericListDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<GenericListDialogComponent,GenericListItem>,
        @Inject(MAT_DIALOG_DATA) public data: GenericListData<GenericListDialogMessage,GenericListItem>,
        protected translateService: TranslateService
    ) {}

    selectionHandler(selectionListChange: MatSelectionListChange) {
        const lineSelected = selectionListChange.options[0].value;
        this.dialogRef.close(lineSelected);
    }

    get title() {
        return this.translateService.instant(this.data.title.label, this.data.title.params);
    }

    cancel() {
        const objectToReturn: GenericListItem & { id: number | undefined } = { name: "cancel", id: -1};
        this.dialogRef.close(objectToReturn);
    };
}
