<tilby-dialog-content>
    <div class="sass {{data.classTextContent}}">
        <span translate="{{data.textContent}}"></span>
    </div>
</tilby-dialog-content>

<div class="tw-flex tw-justify-between">
    <form class="tw-flex tw-items-center tw-px-6" [formGroup]="toggleChoiceForm">
        <mat-slide-toggle formControlName="toggleChoice">{{ data.textToggle | translate }}</mat-slide-toggle>
    </form>
    <tilby-dialog-action-buttons align="end" [buttons]="buttons"/>
</div>