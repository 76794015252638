import * as angular from 'angular';

angular.module('fileImporter').component('topbarFileImporter', {
    bindings: {
        context: "<"
    },
    template: require('./topbar-file-importer.html'),
    controller: ["$mdSidenav", function($mdSidenav) {
        this.openLeftMenu = function() {
            $mdSidenav('left').toggle();
        };

        //Component Init
        this.$onInit = function() {

        };
    }]
});
