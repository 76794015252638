import {Component, Injectable, OnInit, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule} from "@angular/material/dialog";
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';

export type BestItemsData = {
    name: string;
    count: number;
    amount?: number;
    margin?: number;
}
export type BestItems = {
    by_count: Array<BestItemsData>;
    by_amount: Array<BestItemsData>;
    by_margin: Array<BestItemsData>;
}

type dialogOptions = {
    title: string;
    bestItems: BestItems | null;
}
@Component({
    selector: 'app-best-items-dialog',
    templateUrl: './best-items-dialog.component.html',
    styleUrls: ['./best-items-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        TranslateModule,
        MatDialogModule,
        MatRadioModule,
        MatTableModule,
        FormsModule,
        TilbyCurrencyPipe
    ]
})
export class BestItemsDialogComponent implements OnInit {
    protected readonly data: dialogOptions = inject(MAT_DIALOG_DATA);
    protected readonly dialogTitle = this.data.title || 'Best Products';
    bestItems: BestItems | null = null;
    orderTable: string = 'byAmount';
    displayedColumns: string[] = [ 'name', 'count', 'amount', 'margin'];
    dataSource: Array<BestItemsData> = [];

    ngOnInit() {
        this.bestItems = this.data?.bestItems;
        this.orderTable = 'byAmount';
        this.displayedColumns = [ 'name', 'count', 'amount'];
        this.dataSource = this.bestItems?.by_amount || [];
    }

    orderTableChange() {
        switch (this.orderTable) {
            case 'byCount':
                this.displayedColumns = [ 'name', 'count', 'amount'];
                this.dataSource = this.bestItems?.by_count || [];
                break;
            case 'byAmount':
                this.displayedColumns = [ 'name', 'count', 'amount'];
                this.dataSource = this.bestItems?.by_amount || [];
                break;
            case 'byMargin':
                this.displayedColumns = [ 'name', 'count', 'margin'];
                this.dataSource = this.bestItems?.by_margin || [];
                break;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class BestItemsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(options?: NonNullableConfigData<dialogOptions>) {
        const config: NonNullableConfigData<dialogOptions> = {
            ...this.switchMobileDesktopDimensions({ height: '70vh', width: '800px' }),
            disableClose: true,
            data: {
                title: options?.data?.title || 'Best Products',
                bestItems: options?.data?.bestItems || null
             },
            ...options
        };

        return lastValueFrom(this.dialogRef.open(BestItemsDialogComponent, config).afterClosed());
    }
}
