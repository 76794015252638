import * as angular from 'angular';
import { AngularJSObservableHelper } from 'app/libs/angularjs-observable-helper';
import * as _ from 'lodash';
import * as Papa from 'papaparse';

angular.module('items').controller('ItemsShowcaseCtrl', ["$scope", "$rootScope", "$state", "$filter", "$timeout", "$stateParams", "$translate", "environmentInfo", "screenOrientation", "categories", "departments", "channels", "suppliers", "itemsCollectionSize", "checkManager", "entityManager", "confirmDialog", "alertDialog", "itemLabelsDialog", "ImagesManager", "promptDialog", "waitDialog", "util", "leanPMS", function($scope, $rootScope, $state, $filter, $timeout, $stateParams, $translate, environmentInfo, screenOrientation, categories, departments, channels, suppliers, itemsCollectionSize, checkManager, entityManager, confirmDialog, alertDialog, itemLabelsDialog, ImagesManager, promptDialog, waitDialog, util, leanPMS) {
    _.assign($scope, {
        barcodeSearch: "",
        categories: categories,
        channels: channels,
        departments: departments,
        currentPage: 0,
        filterToggle: false,
        hasDoneSearch: false,
        itemsData: [],
        itemsQuery: {},
        loadingInProgress: true,
        priceList: _.toInteger(checkManager.getSetting('price_list')) || 1,
        searchResults: [],
        priceLists: [],
        searchText: "",
        scrollIndex: 0,
        selectedItems: {},
        topbar_context: {
            back_label: $translate.instant('ITEMS.SHOWCASE.TITLE'),
            landscapeMode: _.startsWith(screenOrientation.getOrientation(), 'landscape'),
            order_by: 0,
            showcaseView: 'itemsViewMode'
        }
    });

    _.times(10, function(idx) {
        if(!checkManager.getPreference("price_list_"  + (idx + 1) +  "_hide")) {
            $scope.priceLists.push({
                index: idx + 1,
                name: checkManager.getPreference("price_list_" + (idx + 1) + "_name") || $translate.instant('ITEMS.SHOWCASE.PRICE_LIST_START') + ' ' + (idx + 1)
            });
        }
    });

    //Setup CSV columns
    var csvColumns = ['id', 'name', 'sku', 'department_id', 'department_name', 'category_id', 'category_name', 'description', 'order_name', 'on_sale', 'not_discountable', 'index', 'favorite', 'season', 'brand', 'code', 'barcode1', 'barcode2', 'barcode3', 'barcode4', 'barcode5'];
    var optionNames = {};

    _.times(4, function(idx) {
        var optionName = checkManager.getPreference('items.option' + (idx + 1) + '_name');

        if(optionName) {
            csvColumns.push('option' + (idx + 1) + '_name', 'option' + (idx + 1) + '_value');
        }

        optionNames[idx + 1] = optionName;
    });

    csvColumns.push('price1', 'price2', 'price3', 'price4', 'price5', 'price6', 'price7', 'price8', 'price9', 'price10', 'cost', 'unit', 'stock_type', 'auto_unload');

    var generateCsv = function() {
        var itemsTmp = [];

        _.forEach(_.values($scope.selectedItems), function(item) {
            var itemToPush = _.pick(item, csvColumns);

            _.assign(itemToPush, {
                barcode1: _.get(item, ['barcodes', 0, 'barcode']),
                barcode2: _.get(item, ['barcodes', 1, 'barcode']),
                barcode3: _.get(item, ['barcodes', 2, 'barcode']),
                barcode4: _.get(item, ['barcodes', 3, 'barcode']),
                barcode5: _.get(item, ['barcodes', 4, 'barcode']),
                category_name: _.get(item, ['category', 'name']),
                department_name: _.get(item, ['department', 'name']),
                option1_name: optionNames[1],
                option2_name: optionNames[2],
                option3_name: optionNames[3],
                option4_name: optionNames[4]
            });

            itemsTmp.push(itemToPush);
        });

        return Papa.unparse(itemsTmp, {
            columns: csvColumns,
            delimiter: ";"
        });
    };

    if(_.isObject($stateParams.itemsQuery)) {
        _.assign($scope.itemsQuery, $stateParams.itemsQuery);
    }

    $scope.enableAllCategories = itemsCollectionSize < 10000;

    if(!$scope.enableAllCategories) {
        if(_.isNil($scope.itemsQuery.category_id)) {
            $scope.itemsQuery.category_id = 'nocategory';
        }
    }

    if(leanPMS.isEnabled()) {
        $scope.topbar_context.leanPmsImport = function() {
            confirmDialog.show($translate.instant('ITEMS.LEAN_PMS_IMPORT.CONFIRM_IMPORT')).then(function(answer) {
                if(answer) {
                    var importPromise = leanPMS.importItems().then(function(success) {
                        alertDialog.show($translate.instant('ITEMS.LEAN_PMS_IMPORT.IMPORT_COMPLETE'));
                    }, function(error) {
                        if(!_.isError(error) && error.message) {
                            alertDialog.show($translate.instant('ITEMS.' + error.message, error.errorInfo));
                        } else {
                            alertDialog.show($translate.instant('ITEMS.LEAN_PMS_IMPORT.UNKNOWN_ERROR'));
                        }
                    }).finally(function() {
                        $scope.fetchItems();
                    });

                    waitDialog.show({ message: $translate.instant('ITEMS.LEAN_PMS_IMPORT.IMPORTING'), promise: importPromise });
                }
            });
        };
    }

    $scope.topbar_context.update = function() {
        $scope.fetchItems();
    };

    $scope.topbar_context.showBulkButtons = function() {
        return !_.isEmpty($scope.selectedItems);
    };

    $scope.topbar_context.toggleFilterView = function() {
        $scope.filterToggle = !$scope.filterToggle;

        //Reload items with new query when switching back to items view
        if(!$scope.filterToggle) {
            $scope.fetchItems();
        }
    };

    $scope.clearFilterText = function() {
        $scope.itemsQuery.filter_text = '';
        $scope.onFilterUpdate();
    };

    $scope.topbar_context.bulkLabels = function() {
        itemLabelsDialog.openDialog({ data: {
            promotion: { base_pricelist: $scope.priceList },
            items: structuredClone(Object.values($scope.selectedItems))
        }});
    };

    $scope.topbar_context.bulkDownload = function() {
        util.downloadFile(generateCsv(), 'export_csv_items.csv', 'text/plain;charset=utf-8;');
    };

    $scope.topbar_context.bulkShare = function() {
        if(environmentInfo.canShare()) {
            var blob = new Blob(['\ufeff', generateCsv()], { type: 'text/plain' });
            var title = 'export_csv_items';

            util.blobToDataURL(blob).then(function(dataUrl) {
                window.plugins.socialsharing.shareWithOptions({
                    message: title, // not supported on some apps (Facebook, Instagram)
                    subject: title, // fi. for email
                    files: ['df:' + title + '.csv;' + dataUrl], // an array of filenames either locally or remotely
                  }, function() {

                }, function(error) {

                });
            });
        }
    };

    $scope.topbar_context.bulkDelete = function() {
        confirmDialog.show($translate.instant('ITEMS.SHOWCASE.CONFIRM_BULK_DELETE')).then(function(answer) {
            if(answer) {
                entityManager.items.deleteCollectionOnline(_($scope.selectedItems).values().map(function(item) { return item.id; }).value()).then(function() {
                    $scope.fetchItems();
                });
            }
        });
    };

    $scope.topbar_context.onSubmitBarcode = function(barcodeInput) {
        $scope.topbar_context.showcaseView = 'itemsBarcodeSearch';
        $scope.barcodeSearch = barcodeInput;
        initializeSearch(performBarcodeSearch);
    };

    $scope.hasNoItems = function() {
        return _.isEmpty($scope.itemsData);
    };

    $scope.getItemPriceList = function(item) {
        return item['price' + $scope.priceList];
    };

    $scope.filterSuppliers = function(filterText) {
        return $filter('filter')(suppliers, filterText);
    };

    $scope.selectAllItems = function() {
        _.forEach($scope.itemsData, function(item) {
            item._checked = $scope.allItemsSelected;
            $scope.onItemCheck(item);
        });
    };

    $scope.onItemCheck = function(item) {
        if(item._checked) {
            $scope.selectedItems[item.id] = item;
        } else {
            delete $scope.selectedItems[item.id];
        }
    };

    $scope.fetchItems = function() {
        $rootScope.$broadcast("loader:changeStatus", "itemsCategoryLoader", { enabled: true });
        $scope.loadingInProgress = true;
        $scope.allItemsSelected = false;

        $scope.itemsData = [];
        $scope.selectedItems = {};

        var query = _.assign({}, _.omit($scope.itemsQuery, ['_supplier', 'price_from', 'price_to', 'filter_text']));

        if (query.category_id === 'nocategory') {
            query.category_id = 'null';
        }

        if($scope.itemsQuery._supplier) {
            query.default_supplier_id = $scope.itemsQuery._supplier.id;
        }

        if(!_.isNil($scope.itemsQuery.price_from)) {
            query['price' + $scope.priceList + '_since'] = $scope.itemsQuery.price_from;
        }

        if(!_.isNil($scope.itemsQuery.price_to)) {
            query['price' + $scope.priceList + '_max'] = $scope.itemsQuery.price_to;
        }

        if(!_.isEmpty($scope.itemsQuery.filter_text)) {
            var encodedSearchText = util.fixedEncodeURIComponent($scope.itemsQuery.filter_text);

            query.x_query =  ['OR(sku_like=', encodedSearchText, ',id=', encodedSearchText,  ',code_like=', encodedSearchText, ',name_like=', encodedSearchText, ')'].join('');
        }

        switch($scope.topbar_context.order_by) {
            case 0:
                query.orderby_asc = 'name';
                break;
            case 1:
                query.orderby_asc = 'price' + $scope.priceList;
                break;
            case 2:
                query.orderby_desc = 'price' + $scope.priceList;
                break;
            case 3:
                query.orderby_asc = 'sku';
                break;
        }

        return entityManager.items.fetchCollectionOnline(query).then(function(result) {
            $scope.itemsData = result;

            $rootScope.$broadcast("loader:changeStatus", "itemsCategoryLoader", { enabled: false });
            $scope.loadingInProgress = false;
        });
    };

    $scope.fetchItems().then(function() {
        if($stateParams.scrollIndex) {
            $timeout(function() {
                $scope.scrollIndex = $stateParams.scrollIndex;
            }, 1000);
        }
    });

    $scope.onFilterUpdate = function() {
        if($scope.topbar_context.landscapeMode) {
            $scope.fetchItems();
        }
    };

    $scope.changeSelectedPrice = function(newSelected) {
        $scope.priceList = newSelected;

        if ($scope.topbar_context.order_by !== 0) {
            $scope.fetchItems();
        }
    };

    $scope.goToItemDetails = function(currentItem) {
        $rootScope.showAppLoader();

        $state.go('app.items.details', {
            id: _.isNil(currentItem) ? "new" : currentItem.id,
            categoryId: ($scope.itemsQuery.category_id === 'nocategory' || _.isNil(currentItem)) ? null : $scope.itemsQuery.category_id,
            showcaseState: _.pick($scope, ['itemsQuery', 'scrollIndex'])
        });
    };

    var refreshView = function() {
        switch($scope.topbar_context.showcaseView) {
            case 'itemsViewMode':
                $scope.fetchItems();
                break;
            case 'itemsBarcodeSearch':
                initializeSearch(performBarcodeSearch);
                break;
            default:
                break;
        }
    };

    const isMagoEnabled = () => {
        const integrations_mago_publish_sales = checkManager.getPreference('integrations.mago.publish_sales');
        const integrations_mago_publish_customers = checkManager.getPreference('integrations.mago.publish_customers');
        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    $scope.deleteItem = function(item) {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        var message = $translate.instant('ITEMS.SHOWCASE.WANT_TO_DELETE_TITLE');
        if (item.combinations.length !== 0) {
            message += $translate.instant('ITEMS.SHOWCASE.WANT_TO_DELETE_DESCRIPTION', {value: item.combinations.length});
        }

        console.log(message);

        confirmDialog.show(message).then(function(result) {
            if (result) {
                var imagesToDelete = _.cloneDeep(item.images);
                if (item.thumbnail) {
                    imagesToDelete.push({
                        image_url: item.thumbnail
                    });
                }
                _.forEach(imagesToDelete, function(image) {
                    ImagesManager.markToDelete(image);
                });

                entityManager.items.deleteOneOnline(item.id).then(function() {
                    // this is done after item because if some images don't exist (for some strange reason) it would avoid deltion of item
                    ImagesManager.saveAllImages(imagesToDelete);
                    refreshView();
                });
            }
        });
    };

    $scope.generateLabels = function(item) {
        itemLabelsDialog.openDialog({ data: {
            promotion: { base_pricelist: $scope.priceList },
            items: structuredClone(item)
        }});
    };

    $scope.cloneItem = function(item) {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        var cleanupItem = function(obj) {
            delete obj.id;
            delete obj.updatedby_id;
            delete obj.updated_at;
            delete obj.createdby_id;
            delete obj.created_at;
            delete obj.item_id;
            delete obj.variation_id;
            delete obj.bom_component_id;

            _.each(obj, function(attr, key) {
                if(_.isArray(attr)) {
                    _.each(attr, cleanupItem);
                } else if(_.isObject(attr)) {
                    cleanupItem(attr);
                }
            });
        };

        var cloneComponents = function(item) {
            return {
                allergens: _.map(item.allergens, function(allergen) {
                    return {id: allergen.id};
                }),
                components: _.map(item.components, function(component) {
                    return {id: component.id};
                })
            };
        };

        promptDialog.show({title: $translate.instant('ITEMS.SHOWCASE.COPY_TITLE'), defaultValue: ($translate.instant('ITEMS.SHOWCASE.COPY_TEXT', {value: item.name})).substring(0,255), minLength: 0, maxLength: 255}).then(function(newName) {
            var clonedItem = _(item).omit(['id', 'uuid', 'name', 'barcodes', 'combinations', 'option1_value', 'option2_value', 'option3_value', 'option4_value', 'category', 'sku', 'index']).cloneDeep();

            _.assign(clonedItem, {
                name: newName,
                barcodes: [],
                combinations: []
            });

            var components = cloneComponents(item);

            cleanupItem(clonedItem);
            _.assign(clonedItem, components);

            entityManager.items.postOneOnline(clonedItem).then(function(result) {
                refreshView();
            }, function(error) {
                alertDialog.show($translate.instant('ITEMS.SHOWCASE.COPY_ERROR'));
            });
        });
    };

    $scope.goToNewItem = function() {
         if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        $scope.goToItemDetails();
    };

    $scope.exitNewItemView = function() {
        $scope.topbar_context.showcaseView = 'itemsViewMode';
    };

    $scope.toggleFavorite = function(item) {
        item.favorite = !item.favorite;
        entityManager.items.putOneOnline(item);
    };

    $scope.exitSearch = function() {
        $scope.barcodeSearch = "";
        $scope.clearSearchText();
        $scope.topbar_context.showcaseView = 'itemsViewMode';
    };

    $scope.topbar_context.exitBarcodeSearch = $scope.exitSearch;

    $scope.clearSearchText = function() {
        $scope.searchResults = [];
        $scope.hasDoneSearch = false;
    };

    var initializeSearch = function(callback) {
        $rootScope.$broadcast("loader:changeStatus", "itemSearchLoader", { enabled: true });
        $scope.loadingInProgress = true;
        $scope.$evalAsync(callback);
    };

    var performBarcodeSearch = function() {
        var query = {
            barcode: $scope.barcodeSearch
        };
        itemsSearch(query);
    };

    $scope.searchEmpty = function() {
        return _.isEmpty($scope.searchResults);
    };

    var itemsSearch = function(query) {
        $scope.searchResults = [];
        $scope.hasDoneSearch = true;

        entityManager.items.fetchCollectionOnline(query).then(function(results) {
            if (results) {
                if (!_.isArray(results) && results.results) {
                    results = results.results;
                }

                var resultsGrouped = _.groupBy(results, function(item) {
                    return item.category_id;
                });

                $scope.searchResults = resultsGrouped;
            }
        }).finally(function() {
            $scope.$evalAsync(function() {
                $rootScope.$broadcast("loader:changeStatus", "itemSearchLoader", {
                    enabled: false
                });
            });
            $scope.loadingInProgress = false;
        });
    };

    AngularJSObservableHelper.attachObservableToScope($scope, screenOrientation.getOrientationObservable(), (orientation) => {
        $scope.topbar_context.landscapeMode = _.startsWith(orientation, 'landscape');
    });
}]);
