import {
    Component,
    computed,
    inject,
    Input,
    OnChanges,
    signal,
    SimpleChanges
} from '@angular/core';

import {ActiveSaleActionButtonsComponent} from "../active-sale-action-buttons";

import {PaymentMethods, Sales,} from "tilby-models";

import {EntityManagerService, PaymentMethodTypeClass, paymentMethodTypes} from "src/app/core";

import {TranslateService} from '@ngx-translate/core';
import {BadgeInputButton, BaseInputButton, LongPressInputButton} from '@tilby/tilby-ui-lib/models';
import {DevLogger} from "src/app/shared/dev-logger";

import {
    $state,
    itemListSelector,
    RootScope,
} from "app/ajs-upgraded-providers";

import {ConfirmDialogWithRememberService, PendingPrintsDialogService, PrinterErrorFiscalDialogService} from 'src/app/dialogs';

import {AsyncPipe, CommonModule,} from '@angular/common';

import {
    ActiveSaleService,
    SalePrintingUtilsService,
    SaleUpdatesData
} from 'src/app/features/cashregister';

import {ActiveSalePaymentService} from 'src/app/features/cashregister/services/active-sale-payment.service';

import {SaleMode} from 'src/app/shared/model/cashregister.model';
import {Exit} from "@tilby/tilby-ui-lib/components/tilby-order";
import {GenericListDialogMessage} from "src/app/dialogs/generic-list-dialog/generic-list-dialog.model";
import {CashregisterKeyboardComponent} from "../../cashregister-keyboard";
import {KeyboardParentToAddNewItemToSaleComponent} from '../../../keyboard-parent-to-add-new-item-to-sale';
import {subscribeInComponent} from '@tilby/tilby-ui-lib/utilities';
import {filter} from 'rxjs';

@Component({
    selector: 'app-active-sale-action-buttons-wrapper',
    standalone: true,
    templateUrl: './active-sale-action-buttons-wrapper.component.html',
    imports: [ActiveSaleActionButtonsComponent, CommonModule, AsyncPipe, CashregisterKeyboardComponent],
    providers: [ActiveSalePaymentService],
    styleUrls: ['./active-sale-action-buttons-wrapper.component.scss'],
})
export class ActiveSaleActionButtonsWrapperComponent extends KeyboardParentToAddNewItemToSaleComponent implements OnChanges{
    private readonly state = inject($state);
    private readonly rootScope = inject(RootScope);
    private readonly activeSalePaymentService = inject(ActiveSalePaymentService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly translateService = inject(TranslateService);
    private readonly itemListSelector = inject(itemListSelector);
    private readonly salePrintingUtilsService = inject(SalePrintingUtilsService);
    private readonly pendingPrintsDialogService = inject(PendingPrintsDialogService);
    private readonly confirmDialogWithRememberService = inject(ConfirmDialogWithRememberService);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);

    protected sideKeyboard = this.cashregisterStateService.sideKeyboard;

    protected isLoading = computed(() =>
        this.activeSaleService.printOrderInProgress() ||
        this.activeSaleService.printDocumentInProgress() ||
        this.activeSaleService.paymentInProgress()
    );

    private _sale?:Sales;

    // CHECK MOBILE AND VERTICAL PORTRAIT
    @Input() isMobilePortrait = false;
    @Input({required:true}) saleUpdates!:SaleUpdatesData;


    get sale(){return this._sale}

    private exitArray$ = this.cashregisterStateService.exitsArray$;
    private ordersExitEnabled = true; //this.configurationManagerService.getPreference('application.functions.orders.exit'); // TODO : Preference or Setting

    protected actionButtons: BadgeInputButton[] = this.calculateOrderButtonMobile('bill');
    protected fastPaymentsButtons: LongPressInputButton[] = [];
    protected layoutActionButtons: SaleMode = 'order';
    protected principalActionButton: BaseInputButton | undefined;
    protected showOnlyPayButton: boolean = false;

    // Configurations
    private readonly acceptsPayments = this.configurationManagerService.isUserPermitted('cashregister.accept_payments');

    private log(...args:any[]){
        DevLogger.log('ACTIVE_SALE_ACTIONS_BUTTONS',...args)
    }
    private async saleChanged(updates: SaleUpdatesData){
        this._sale = updates.currentSale;
        this.layoutActionButtons = updates.saleMode || 'bill';

        // Mobile Test
        if(this.isMobilePortrait){
            this.actionButtons = this.calculateOrderButtonMobile(this.layoutActionButtons);
        }
        else{
            this.actionButtons = this.calculateActionButtons(this.layoutActionButtons);
            this.fastPaymentsButtons = this.calculateFastPaymentButtons(this.layoutActionButtons);
            this.principalActionButton = this.layoutActionButtons === 'bill'
                ? { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PAY', click: ()=>this.pay(), icon:()=>'', isDisable:()=> (this.hasAllSaleItemsPriceNegative()  || this.checkIsEnrc() || !this.acceptsPayments || !this.sale) }
                : undefined;
        }
    }

    constructor() {
        super();
        this.subscriptions();
        this.showOnlyPayButton = this.isShowOnlyPayButton();
    }
    // START - LIFECYCLE
    ngOnChanges(simpleChanges: SimpleChanges){
        if(simpleChanges.saleUpdates && simpleChanges.saleUpdates.currentValue){
            this.saleChanged(simpleChanges.saleUpdates.currentValue);
        }
    }
    // END - LIFECYCLE

    private subscriptions() {
        subscribeInComponent(
            this.cashregisterStateService.addItemToSale$.pipe(filter(()=>this.sideKeyboard())),
            (item) => item && this.addItemToSale(item)
        );
    }

    // START - SALE CHANGED FUNCTIONS

    //MOBILE
    private calculateOrderButtonMobile(layout: SaleMode) : BadgeInputButton[] {
        if (layout === 'order') {
            return <BadgeInputButton[]>[
                ...(this.ordersExitEnabled&&[{ name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.EXIT', icon: ()=>'move_item', click:()=>this.exit(), badgeValue: -1 }] || []),
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PARKING', icon: ()=>'save', click:()=>this.save() },
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.WITHOUT_SEND', icon: ()=>'cancel_schedule_send', click:()=>this.cancelScheduleSend() },
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.SEND', icon: ()=>'send', class: 'tilby-body-background-primary-color', click:()=>this.send() },
            ]
        } else if (layout === 'bill') {
            return <BadgeInputButton[]>[
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PAY', icon: ()=>'receipt_long', click:()=>this.pay(), isDisable:()=>!this.sale || !this.acceptsPayments, class: !this.acceptsPayments ? 'button-disabled' : ''},
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.SPLIT', icon: ()=>'call_split', click:()=>this.callSplit(), isDisable:()=>!this.sale },
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.TRANSFER', icon: ()=>'move_up', click:()=>this.modifySale(), isDisable:()=>!this.sale },
                ...(this.ordersExitEnabled&&[{ name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.EXIT', icon: ()=>'move_item', click:()=>this.exit(), badgeValue: -1, isDisable:()=>!this.sale }] || []),
            ]
        }
        else return [];
    }
    // DESKTOP
    /**
     *  Calculates the action buttons based on the provided layout.
     *  @param layout - The layout of the action buttons.
     *  @returns An array of action buttons or undefined.
     */
    private calculateActionButtons(layout: SaleMode) : BadgeInputButton[] {
        if (layout === 'order') {
            return <BadgeInputButton[]>[
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PARKING', icon: ()=>'save', click: ()=>this.save() },
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.WITHOUT_SEND', icon: ()=>'cancel_schedule_send', click: ()=>this.cancelScheduleSend() },
                { name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.SEND', icon: ()=>'send', class: 'tilby-body-background-primary-color', click: ()=>this.send() },
            ]
        }
        else return [];
    }
    /**
     *  Calculates the fast payment buttons based on the provided layout.
     *  @param layout - The layout of the action buttons.
     *  @returns An array of fast payment buttons or undefined.
     */
    private calculateFastPaymentButtons(layout: SaleMode) : LongPressInputButton[] {
        if (layout !== 'bill') {
            return [];
        }

        if (this.hasAllSaleItemsPriceNegative()) {
            return [
                {
                    name: 'CASHREGISTER.ACTIVE_SALE.RETURN_OR_CANCEL',
                    click: () => this.emitCreditNote(),
                    longPress: () => undefined,
                    icon: signal(''),
                    isDisable: signal(!this.acceptsPayments)
                }
            ];
        } else if (this.checkIsEnrc()) {
            return [
                {
                    name: 'CASHREGISTER.ACTIVE_SALE.CREATE_SUMMARY_INVOICE',
                    click: () => this.paySummary(),
                    longPress: () => undefined,
                    icon: signal('')
                }
            ];
        } else {
            const fastPayments: LongPressInputButton[] = [];

            for (let i = 1; i <= 3; i++) {
                const fastPaymentClass = this.configurationManagerService.getPreference(`cashregister.fast_payment_${i}_type`);

                if (fastPaymentClass) {
                    fastPayments?.push({
                        name: this.configurationManagerService.getPreference(`cashregister.fast_payment_${i}_label`) || this.translateService.instant(`PAYMENT_CLASSES.${(<string>fastPaymentClass).toUpperCase()}`),
                        click: () => this.fastPayment(i, fastPaymentClass, 'click'),
                        longPress: () => this.fastPayment(i, fastPaymentClass, 'longPress'),
                        icon: signal(''),
                        isDisable:()=> !this.acceptsPayments || this.saleUpdates.hasPaidPayments
                    });
                }
            }

            if(!fastPayments.length) {
                if (!this.showOnlyPayButton) {
                    return <LongPressInputButton[]>[
                        {
                            name: 'PAYMENT_CLASSES.CASH',
                            click: () => this.fastPayment(1, 'cash', 'click'),
                            longPress: () => this.fastPayment(1, 'cash', 'longPress'),
                            isDisable: signal(!this.acceptsPayments)
                        },
                        {
                            name: 'PAYMENT_CLASSES.DIGITAL',
                            click: () => this.fastPayment(2, 'digital', 'click'),
                            longPress: () => this.fastPayment(2, 'digital', 'longPress'),
                            isDisable: signal(!this.acceptsPayments)
                        }
                    ]
                } else {
                    return [];
                }
            }

            return fastPayments;
        }
    }

    // END - SALE CHANGED FUNCTIONS

    // START - BUTTON ACTION FUNCTIONS
    private pay() {
        this.goToPayments();
    }
    private async save() {
        const {room_id,order_type } = this.activeSaleService.currentSale || {};

        //Saves and closes the current sale, so after this step currentSale is empty
        await this.activeSaleService.saveSale(undefined, true);

        if(this.configurationManagerService.getPreference("orders.open_tables_after_park")) {
            this.activeSaleService.goToTablesSelectedRoom(room_id,order_type);
        }
    }

    private goToTables(){
        const {order_type,room_id} =this.activeSaleService.currentSale;
        this.activeSaleService.goToTablesSelectedRoom(room_id,order_type);
    }
    private goToTablesAfterSend() {
        if(this.configurationManagerService.getPreference("orders.open_tables_after_send")) {
            this.goToTables();
        }
    }
    private goToTablesAfterSkipSend() {
        if(this.configurationManagerService.getPreference("orders.open_tables_after_skip_send")) {
            this.goToTables();
        }
    }

    private async cancelScheduleSend() {
        const skipPreference = "dialog.skip_printing.skip_question";
        const skipQuestion = this.configurationManagerService.getPreference(skipPreference);

        if(!skipQuestion) {
            const response = await this.confirmDialogWithRememberService.openDialog({
                data: {
                    textContent: 'CASHREGISTER.ACTIVE_SALE.SKIP_TRANSACTION_PRINTING_CONFIRM',
                    textToggle: 'CASHREGISTER.ACTIVE_SALE.REMEMBER_CHOICE',
                    classTextContent: 'tw-pb-4'
                }
            });

            if(!response.confirm) {
                return;
            }

            if(response.toggleChoice) {
                this.configurationManagerService.setUserPreference(skipPreference, true);
            }
        }

        await this.activeSaleService.skipTransactionsSend();
        this.goToTablesAfterSkipSend();
    }


    private async send() {
        const results = await this.activeSaleService.sendTransactions();

        if(this.salePrintingUtilsService.getPrintErrors(results).length) {
            this.pendingPrintsDialogService.openDialog();
        } else {
            this.goToTablesAfterSend();
        }
    }

    private async fastPayment(fastPaymentMethodIndex: number, fastPaymentMethod: PaymentMethodTypeClass, type: 'click' | 'longPress') {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        try {
            this.activeSaleService.paymentInProgress.set(true);

            await this.activeSalePaymentService.beforePayment();

            //Find payment method types for the button class
            const paymentMethodTypeIds  = paymentMethodTypes.filter((methodType) => methodType.class === fastPaymentMethod).map((methodType) => methodType.id);

            if(!paymentMethodTypeIds.length) {
                return;
            }

            //Find available payment methods for the button class
            const paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: paymentMethodTypeIds, hidden: false });

            //Get user default payment method id for this button if exists
            const defaultPaymentMethodPreference = `cashregister.fastpayment_${fastPaymentMethodIndex}` as `cashregister.fastpayment_${number}`;
            let defaultPaymentMethodIdPref = this.configurationManagerService.getPreference(defaultPaymentMethodPreference);

            let targetPaymentMethod: PaymentMethods | undefined;

            //If there is a default id, check if the related payment method exists for this class
            if(defaultPaymentMethodIdPref) {
                const defaultPaymentMethodId = parseInt(defaultPaymentMethodIdPref) || 0;

                targetPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethodId);
            }

            //Show a selection dialog if we cannot find the default payment method or the user is forcing the manual selection
            if(!targetPaymentMethod || type == 'longPress') {
                if(paymentMethods.length > 1) {
                    const selectResult = await this.itemListSelector.show(this.translateService.instant("CASHREGISTER.ACTIVE_SALE.SELECT_PAYMENT"), paymentMethods, { defaultElement: targetPaymentMethod });

                    //Save the selection if the user wants so
                    if(selectResult.setAsDefault) {
                        this.configurationManagerService.setUserPreference(defaultPaymentMethodPreference, selectResult.element.id);
                    }

                    targetPaymentMethod = selectResult.element;
                } else {
                    targetPaymentMethod = paymentMethods[0];
                }
            }

            if(!targetPaymentMethod) {
                return;
            }

            const paymentAmountForCashType = targetPaymentMethod.payment_method_type_id == 1 ? this.cashregisterStateService.keyboardTotalValue() : undefined;

            if (!!paymentAmountForCashType && (this.activeSaleService.currentSale.final_amount || 0) > (paymentAmountForCashType || 0)) {
                return this.openDialogsService.openAlertDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE.PAYMENT_AMOUNT_EXCEEDS_FINAL_AMOUNT' } });
            }

            await this.activeSaleService.fastPayment(targetPaymentMethod,paymentAmountForCashType);

            await this.activeSaleService.processSaleChange();
            await this.activeSalePaymentService.emitDocument();
        } catch(error) {
            if (error !== undefined && error !== null) this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: this.activeSaleService.printerDocumentData?.printer.id}}});
        } finally {
            //delete fastPaymentMethod.paying;
            this.activeSaleService.paymentInProgress.set(false);
        }
    }

    //MOBILE ONLY
    private async exit() {
        const exitSelected =(await this.openDialogsService.openGenericListDialog<GenericListDialogMessage,Exit>({data: {title: {label:'DIALOG.GENERIC_LIST_DIALOG.EXIT.TITLE'},list:this.exitArray$.getValue() }}));
        if(exitSelected){
            this.cashregisterStateService.setExit(exitSelected);
        }
    }
    private callSplit() {
        if (!this.activeSaleService.isActiveSale()) {
            return;
        }

        if(!this.sale?.sale_items?.length) {
            return this.openDialogsService.openAlertDialog({data:{messageLabel:'CASHREGISTER.ACTIVE_SALE_MODEL.NO_ITEM_IN_SALE'}});
        }

        this.activeSaleService.splitSale();
    }
    private modifySale() {
        if(this.activeSaleService.isActiveSale()) {
            this.activeSaleService.editSale();
        }
    }

    // END - BUTTON ACTION FUNCTIONS

    // START - PAYMENT FUNCTIONS

    public async goToPayments() {
        try {
            await this.activeSalePaymentService.beforePayment();
            this.state.go('app.new.cashregister.content.payments', {});
            ActiveSaleService.activeSaleEvents$.next({ event: 'go-to-payments' });
        } catch (error) {
            if (error !== undefined && error !== null) this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: this.activeSaleService.printerDocumentData?.printer.id}}});
        }
    };
    // END - PAYMENT FUNCTIONS

    async emitCreditNote() {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        try {
            this.activeSaleService.paymentInProgress.set(true);
            //Handle payments reversal
            await this.activeSalePaymentService.beforePayment();
            await this.activeSaleService.processPayments();

            await this.activeSalePaymentService.emitDocument();
        } catch(error) {
            if (error !== undefined && error !== null) this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: this.activeSaleService.printerDocumentData?.printer.id}}});
        } finally {
            this.activeSaleService.paymentInProgress.set(false);
        }
    };

    async paySummary() {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        try {
            this.activeSaleService.paymentInProgress.set(true);

            await this.activeSalePaymentService.beforePayment();
            await this.activeSalePaymentService.emitDocument();
        } catch(error) {
            if (error !== undefined && error !== null) this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: this.activeSaleService.printerDocumentData?.printer.id}}});
        } finally {
            this.activeSaleService.paymentInProgress.set(false);
        }
    };

    checkIsEnrc(): boolean {
        return (this.activeSaleService.currentSale?.is_summary && ['summary_e_nrc', 'generic_invoice'].includes(this.activeSaleService.printerDocumentData?.document_type?.id || '') || false);
    }

    private hasAllSaleItemsPriceNegative(): boolean {
        if (this.activeSaleService.currentSale.sale_items && this.activeSaleService.currentSale.sale_items.length > 0) {
            if (this.activeSaleService.currentSale.sale_items?.every((item) => (item.price * item.quantity) === 0)) {
                return false;
            } else {
                return this.activeSaleService.currentSale.sale_items?.every((item) => (item.price * item.quantity) <= 0);
            }
        } else {
            return false;
        }
    }

    isShowOnlyPayButton() {
        const fastPayments1 = this.configurationManagerService.getPreference('cashregister.fast_payment_1_type') as string;
        const fastPayments2 = this.configurationManagerService.getPreference('cashregister.fast_payment_2_type') as string;
        const fastPayments3 = this.configurationManagerService.getPreference('cashregister.fast_payment_3_type') as string;

        if (fastPayments1 === '' && fastPayments2 === '' && fastPayments3 === '') {
            return true;
        }
        return false;
    }
}
