import * as angular from 'angular';

angular.module('application').factory('spiaggeItApi', spiaggeItApi);

spiaggeItApi.$inject = ["restManager"];

function spiaggeItApi(restManager) {
    var spiaggeItApi = {
        setupShop: function(propertyId) {
            return restManager.post('spiagge_it/setup', { propertyId: propertyId });
        },
        getRoomFolios: function() {
            return restManager.getList('spiagge_it/room_folios');
        },
        getMiscSalesFolios: function() {
            return restManager.getList('spiagge_it/misc_sales_folios');
        },
        createTransaction: function(transactionData) {
            return restManager.post('spiagge_it/transactions', transactionData);
        },
        rollbackTransaction: function(outletId, orderId) {
            return restManager.deleteOne('spiagge_it/transactions', null, { orderId: orderId, outletId: outletId });
        }
    };

    return spiaggeItApi;
}