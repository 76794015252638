import * as angular from 'angular';
import * as _ from 'lodash';
import { Giftcards, GiftcardsTypes } from 'tilby-models';

class GiftCardPrinter {
    constructor(
        private $filter: any,
        private $translate: any,
        private documentPrinter: any,
        private restManager: any
    ) {
    }

    public async printSaleGiftCards (saleUuid: string, printerId: number) {
        const saleGiftCards: Giftcards[] = await this.restManager.getList("giftcards", { origin_sale: saleUuid });
        const giftcardTypes: GiftcardsTypes[] = await this.restManager.getList("giftcards_types");
        const giftCardTypesByUuid = _.keyBy(giftcardTypes, "uuid");

        if(saleGiftCards.length) {
            for(let giftCard of saleGiftCards) {
                let printOptions: any = {};

                if(giftCard.type_uuid && giftCardTypesByUuid[giftCard.type_uuid]?.print_options) {
                    printOptions = giftCardTypesByUuid[giftCard.type_uuid].print_options;
                }

                const giftCardRows: string[] = [];

                if(printOptions.print_value != false) {
                    giftCardRows.push((giftCard.type_name ? giftCard.type_name : this.$translate.instant("GIFT_CARD.PRINT.TITLE")), " ");
                }

                if(printOptions.print_validity != false) {
                    let expireRow = [this.$translate.instant("GIFT_CARD.PRINT.VALID_SINCE", { date: this.$filter("sclDate")(giftCard.valid_since) })];

                    if(giftCard.valid_until) {
                        expireRow.push(this.$translate.instant("GIFT_CARD.PRINT.VALID_UNTIL", { date: this.$filter("sclDate")(giftCard.valid_until) }));
                    }

                    giftCardRows.push(expireRow.join(' '), " ");
                }

                if(printOptions.print_value != false) {
                    giftCardRows.push(this.$translate.instant("GIFT_CARD.PRINT.VALUE", { value: this.$filter("sclCurrency")(giftCard.initial_value) }), " ");
                }

                if(printOptions.print_divisible != false) {
                    giftCardRows.push(this.$translate.instant(`GIFT_CARD.PRINT.${giftCard.is_divisible ? "DIVISIBLE_NEW" : "NOT_DIVISIBLE_NEW" }`), " ");
                }

                giftCardRows.push(_.toString(giftCard.type_description), " ");

                if(printOptions.print_environment != false) {
                    giftCardRows.push(giftCard.origin_environment || "");
                }

                if(printOptions.print_giftcard_uuid != false) {
                    giftCardRows.push(giftCard.uuid || "");
                }

                if(printOptions.print_timestamp != false) {
                    giftCardRows.push(" ", this.$filter("sclDate")(giftCard.created_at), " ");
                }

                giftCardRows.push(giftCard.code);

                try {
                    await this.documentPrinter.printFreeNonFiscal(giftCardRows.join('\n'), printerId, { barcode: { type: "EAN13", value: giftCard.code }, printLogo: printOptions.print_logo != false });
                } catch(err) {

                }
            }
        }
    }
}

GiftCardPrinter.$inject = ["$filter", "$translate", "documentPrinter", "restManager"];

angular.module('giftcard').service('GiftCardPrinter', GiftCardPrinter);
