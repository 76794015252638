import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('orders').factory('editOrder', ["$mdDialog", "$translate", "entityManager", function($mdDialog, $translate, entityManager) {

    editOrderController.$inject = ["$scope", "order", "deliveryChannels", "rooms", "orders"];
    function editOrderController($scope, order, deliveryChannels, rooms, orders) {
        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            $scope.data.paid = 0;

            if($scope.data.channel) {
                var usedChannel = _.find(deliveryChannels, { id: $scope.data.channel });

                if(usedChannel && usedChannel.force_paid) {
                    $scope.data.paid = true;
                }
            }

            if($scope.selectedTable) {
                _.assign($scope.data, {
                    room_id: $scope.selectedTable.room.id,
                    room_name: $scope.selectedTable.room.name,
                    table_id: $scope.selectedTable.id,
                    table_name: $scope.selectedTable.name
                });
            }

            $mdDialog.hide($scope.data);
        };

        $scope.clearNameField = function() {
            $scope.data.name = "";
            angular.element("#editOrderName").trigger('focus');
        };

        $scope.types = [{
            name: $translate.instant('ORDERS.EDIT_ORDER.NORMAL'),
            value: "normal"
        },{
            name: $translate.instant('ORDERS.EDIT_ORDER.TAKE_AWAY'),
            value: "take_away"
        },{
            name: $translate.instant('ORDERS.NEW_ORDER.DELIVERY'),
            value: "delivery"
        }];

        $scope.deliveryCircuits = _.map(deliveryChannels, function(channel) {
            var ch = _.clone(channel);

            if(!ch.image_url) {
                ch.image_url = "assets/images/channels/" + channel.id + ".png";
            }

            return ch;
        });

        $scope.coverNumbers = _.range(0,51);

        $scope.data = order;
        $scope.now = moment().milliseconds(0).seconds(0).toDate();

        if($scope.data.type === "take_away") {
            $scope.data._deliverAt = moment(order.deliver_at).toDate();
            $scope.data.covers = 1;
        }
        else {
            $scope.data._deliverAt = moment(order.deliver_at).milliseconds(0).seconds(0).toDate();
        }

        $scope.tables = [];
        $scope.selectedTable = null;

        _.forEach(rooms, function(room) {
            _.forEach(room.tables, function(table) {
                $scope.tables.push(_.assign({
                    room: room
                }, table));
            });
        });

        var ordersByTable = _.groupBy(orders, 'table_id');

        $scope.tables = _.filter($scope.tables, function(table) {
            return table.order_type === 'multiple' || _.isNil(ordersByTable[table.id]) || _.find(ordersByTable[table.id], { uuid: $scope.data.uuid });
        });
        
        if($scope.data.type === "normal" && $scope.data.table_id) {
            $scope.selectedTable = _.find($scope.tables, { id: $scope.data.table_id });
        }
    }

    var editOrder = {
        show: function(order) {
            return $mdDialog.show({
                controller: editOrderController,
                template: require('./edit-order.html'),
                locals: {
                    order: _.cloneDeep(order)
                },
                resolve: {
                    deliveryChannels: function() {
                        return entityManager.channels.fetchCollectionOffline().then(function(channels) {
                            return _.reject(channels, { id: 'pos' });
                        });
                    },
                    rooms: function() {
                        return entityManager.rooms.fetchCollectionOffline();
                    },
                    orders: ["entityManager", function(entityManager) {
                        return entityManager.orders.fetchCollectionOffline().then(function(orders) {
                            return _.filter(orders, function(order) {
                                return _.includes(['open','checkout'], order.status) && !_.isNil(order.table_id);
                            });
                        });
                    }]
                }
            }).then(function(answer) {
                switch(answer.type) {
                    case 'normal':
                        delete answer.deliver_at;
                    break;
                    case 'take_away':
                        delete answer.channel;
                        delete answer.external_id;
                    /* falls through */
                    case 'delivery':
                        delete answer.covers;
                        delete answer.table_id;
                        delete answer.table_name;
                        delete answer.room_id;
                        delete answer.room_name;
                        answer.deliver_at = answer._deliverAt.toISOString();
                    break;
                    default:
                    break;
                }

                delete answer._deliverAt;
                return answer;
            });
        }
    };

    return editOrder;
}]);
