import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {TicketsValues} from 'tilby-models';
import {TranslateModule} from "@ngx-translate/core";

function sortTicketsValues(ticketsValues: TicketsValues[]) {
  return ticketsValues.sort((a, b) => a.value - b.value);
}

@Component({
    selector: 'app-tickets-values-sorted',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TilbyCurrencyPipe,
        TilbyGesturesDirective,
        TranslateModule
    ],
    templateUrl: './tickets-values-sorted.component.html',
    styleUrl: './tickets-values-sorted.component.scss'
})
export class TicketsValuesSortedComponent {
    @Input({required: true,transform:sortTicketsValues}) ticketValues: TicketsValues[] = [];
    @Output() selectedTicketValue = new EventEmitter<TicketsValues>();
    @Output() longPressTicketValue = new EventEmitter<TicketsValues>();
    @Output() addNewTicketEmitter = new EventEmitter<void>();

    protected selectTicketValue(ticketValue: TicketsValues, type:'click' | 'longPress'='click') {
        if(type === 'click') this.selectedTicketValue.emit(ticketValue);
        else this.longPressTicketValue.emit(ticketValue);
    }

    protected async addNewTicket() {
        this.addNewTicketEmitter.emit();
    }
}
