import * as angular from 'angular';
import { IotHistoryManager } from './iot-history-manager';
import { IotHistoryStorage } from './iot-history-storage';
import { ORDER_HISTORY } from './iot-models';
import { CoreStateService } from 'src/app/core';
export class IotMessageRecoveryManager {
    retryAttempt: number;
    isActive: boolean;
    manualCheckTimeout?: number;

    private processingHistory: boolean;
    private maxHistoryElementsToSave?: number;

    constructor(
        private coreStateService: CoreStateService,
        private errorsLogger: any,
        private checkManager: any,
        private iotHistoryManager: IotHistoryManager,
        private startup: any,
        private iotHistoryStorage:  IotHistoryStorage
    ) {
        this.retryAttempt = 0;
        this.isActive = false;
        this.processingHistory = false;
    }

    logger(...args: any[]) {
        this.errorsLogger.debug("[IotMessageRecoveryManager]: ", ...args);
    }

    bootstrap() {
        this.processingHistory = false;
        this.retryAttempt = 0;
        this.isActive = false;
        let shopPreferenceMaxHistory = this.checkManager.getShopPreferences('iot.max_history');
        if(shopPreferenceMaxHistory["iot.max_history"]){
            this.maxHistoryElementsToSave = parseInt(shopPreferenceMaxHistory["iot.max_history"]);
        }else{
            this.maxHistoryElementsToSave = 200;
        }
        this.iotHistoryManager.getHistory({ limit: 2, range_max: undefined, range_min: undefined, order: ORDER_HISTORY.desc, is_v2_table: true }).then(x => {
            if (x.count > 0) {
                this.iotHistoryStorage.setStartHistory(x.items[x.count - 1].msg_id);
                this.iotHistoryStorage.setList(x.items);
            }
            this.startCheckLastId();
        }).catch(err => {
            console.log("[IotMessageRecoveryManager] bootstrap", err);
            this.startCheckLastId()
        });
    }
    getRecoveryTimeout = () => {
        return this.checkManager.getSetting('messaging.progressive_check_timeout');
    }
    /**
     * @description this is used to set timeout call for checkLastId. Create a new timeout if it does not exists or it adds more time to the next check
     */
    callCheckLastId = () => {
        if (this.manualCheckTimeout) {
            window.clearTimeout(this.manualCheckTimeout);
        }
        this.manualCheckTimeout = window.setTimeout(this.checkLastId, (parseInt(this.getRecoveryTimeout()) || 20) * 1000);
    }
    startCheckLastId = () => {
        this.logger("starting check last id");
        this.retryAttempt = 0;
        this.isActive = true;
        this.callCheckLastId();
    }
    stopCheckLastId = () => {
        this.retryAttempt = 0;
        this.isActive = false;
    }
    /**
     * Logs an event using errorsLogger.sendReport
     * @param {*} message
     * @returns
     */
    logMessagingEvent = (message: string) => this.errorsLogger.sendReport({
        type: 'iot',
        content: message
    });
    /**
     * @description this is used when ther're too many messages lost, so we need to restart application
     */
    handleDisasterRecovery = (message: string) => {
        this.logMessagingEvent(`${message} Restarting application...`);
        this.startup.setShopEntitiesStatus('PendingReload');
        this.startup.setUserEntitiesStatus('PendingReload');
        this.coreStateService.coreError$.next({ type: 'UNALIGNED_DEVICE', needsRestart: true });
    }
    /**
     * @description this function is used to recovery old messages lost, see isMissingMessages in iot history
     */
    recoverHistoryBackward = async (range_min: number) => {
        try {
            if (this.processingHistory === true) {
                return;
            }
            this.processingHistory = true;

            await this.iotHistoryManager.processHistory({ range_min, range_max: 0, limit: 100, order: ORDER_HISTORY.asc, is_v2_table:true });

            this.processingHistory = false;
        } catch (error) {
            //failing recover history
            this.logger("Error recover History", error);
        } finally {
            this.startCheckLastId()
        }
    }
    recoverHistory = async () => {
        try {
            // if a history process is already active, do not start again
            if (this.processingHistory === true) {
                return;
            }
            if (this.checkManager.getSetting('messaging.recovery_enabled')) {
                this.logger("starting recovery");

                this.processingHistory = true;
                await this.iotHistoryManager.processHistory({ range_min: this.iotHistoryStorage.lastMessageId, range_max: 0, limit: 100, order: ORDER_HISTORY.asc, is_v2_table: true });
                this.processingHistory = false;
            }
        } catch (error) {
            //failing recover history
            this.logger("Error recover History", error);
        } finally {
            // at the end restart check last Id
            this.startCheckLastId();
        }
    }
    checkLastId = () => {
        if (this.isActive === true) {
            this.iotHistoryManager.getHistory({ limit: 1, range_max: 0, range_min: undefined, order: ORDER_HISTORY.desc, is_v2_table:true }).then(res => {
                this.retryAttempt = 0;
                if (res.count === 1) {

                    if (res.firstItem.msg_id === this.iotHistoryStorage.lastMessageId) {
                        try {
                            let missingObject = this.iotHistoryManager.isMissingMessages();
                            if (missingObject.missing === true) {
                                this.logger("IS MISSING MESSAGES", missingObject);
                                this.recoverHistoryBackward(missingObject.firstElementMissing);
                            } else {
                                // everything is fine
                                // restart checkLastId
                                let diff = this.iotHistoryStorage.getDiffLastAndFirst();
                                if( this.maxHistoryElementsToSave && diff > this.maxHistoryElementsToSave ){
                                    this.logger("IS MISSING MESSAGES", missingObject);
                                    this.logger("History dangerously big, clean up", this.iotHistoryStorage.getDiffLastAndFirst());
                                    this.bootstrap();
                                    return;
                                }
                                this.callCheckLastId();
                            }
                            return;
                        } catch (error) {
                            // improbable error
                            this.callCheckLastId();
                            return;
                        }
                    } else {
                        if (res.firstItem.msg_id > this.iotHistoryStorage.lastMessageId) {
                            let max_messages_lost = parseInt(this.checkManager.getSetting('messaging.lost_messages_threshold')) || 200;
                            if ((res.firstItem.msg_id - this.iotHistoryStorage.lastMessageId) > max_messages_lost) {
                                //too many messages => restart app
                                this.handleDisasterRecovery(`Too many messages lost. last id received: ${res.firstItem.msg_id}. Last saved: ${this.iotHistoryStorage.lastMessageId}`);
                                this.stopCheckLastId();
                                return;
                            } else {
                                this.stopCheckLastId();
                                this.recoverHistory();
                                return;
                            }
                        } else {
                            // there's been an error or meanwhile has been arrived a notification
                        }
                        this.callCheckLastId();
                    }
                }else{
                    this.callCheckLastId();
                }
                // repeat function
            }).catch(err => {
                this.logger("CheckLastId error", err);
                //history failed
                this.retryAttempt++;
                if (this.retryAttempt > 5) {
                    // history broken or offline
                    // send messsage to user;
                    // TODO
                }
                this.callCheckLastId();
            });
        }
    }
}

IotMessageRecoveryManager.$inject = ["coreState", "errorsLogger", "checkManager", "IotHistoryManager", "startup", "IotHistoryStorage"];
angular.module('core').service('IotMessageRecoveryManager', IotMessageRecoveryManager);
