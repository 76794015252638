@for (ticket_value of ticketValues; track ticket_value) {
    <div class="tw-text-black tw-cursor-pointer tw-rounded-md tw-bg-gray-300 tw-max-h-12 tw-w-full tw-flex tw-flex-1 tw-items-center tw-justify-center tw-text-center tw-p-2 button-check"
        tilbyGestures (longPress)="selectTicketValue(ticket_value,'longPress')"
         (click)="selectTicketValue(ticket_value,'click')">
            {{ ticket_value.value|tilbyCurrency }}
    </div>
}
<div class="tw-text-black tw-cursor-pointer tw-rounded-md tw-bg-gray-300 tw-max-h-12 tw-w-full tw-flex tw-flex-1 tw-items-center tw-justify-center tw-text-center tw-p-2 button-check"
     (click)="addNewTicket()">
    {{ 'CASHREGISTER.PAYMENTS.ADD_TICKET' | translate }}
</div>
