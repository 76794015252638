import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('itemSelector', ["$mdDialog", "$filter", function($mdDialog, $filter) {
    itemSelectorController.$inject = ["$scope", "$timeout", "dataSet", "showId"];
    function itemSelectorController($scope, $timeout, dataSet, showId) {

        $scope.showId = showId || false;
        $scope.searchText = "";
        $scope.selectedElement = undefined;
        $scope.foundElements = [];

        $scope.elementsPerPage = 25;

        var initPagination = function() {
            $scope.totalPages = _.ceil($scope.foundElements.length / $scope.elementsPerPage);
            $scope.currentPage = 0;
            $scope.pagedElements = _.slice($scope.foundElements, 0, 0 + $scope.elementsPerPage);
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.confirm = function() {
            $mdDialog.hide($scope.selectedElement);
        };

        $scope.clearSearchText = function() {
            $scope.searchText = "";
            $scope.searchEntry();
        };

        $scope.incrementPage = function() {
            $scope.currentPage++;
            $scope.pagedElements = _.slice($scope.foundElements, $scope.elementsPerPage * $scope.currentPage, $scope.elementsPerPage * ($scope.currentPage + 1));
        };

        $scope.decrementPage = function() {
            $scope.currentPage--;
            $scope.pagedElements = _.slice($scope.foundElements, $scope.elementsPerPage * $scope.currentPage, $scope.elementsPerPage * ($scope.currentPage + 1));
        };

        $scope.goToFirstPage = function() {
            $scope.currentPage = 0;
            $scope.pagedElements = _.slice($scope.foundElements, $scope.elementsPerPage * $scope.currentPage, $scope.elementsPerPage * ($scope.currentPage + 1));
        };

        $scope.goToLastPage = function() {
            $scope.currentPage = $scope.totalPages - 1;
            $scope.pagedElements = _.slice($scope.foundElements, $scope.elementsPerPage * $scope.currentPage, $scope.elementsPerPage * ($scope.currentPage + 1));
        };

        $scope.isDatasetEmpty = function() {
            return _.isEmpty(dataSet);
        };

        $scope.searchElement = function() {
            if (!$scope.searchText.length) {
                $scope.foundElements = dataSet;
                initPagination();
            } else {
                performSearch();
            }
        };

        $scope.onShowComplete = function() {
            $timeout(function() {
                angular.element('#item-selector-searchbox').trigger('focus');
            });
        };

        var performSearch = function() {
            var searchWords = _.words($scope.searchText);
            $scope.foundElements = dataSet;
            _.each(searchWords,function(word) {
                $scope.foundElements = $filter('filter')($scope.foundElements, word);
            });
            initPagination();
        };

        performSearch();
    }

    var itemSelector = {
        show: function(dataSet, showId) {
            return $mdDialog.show({
                controller: itemSelectorController,
                template: require('./item-selector.html'),
                locals: {
                    dataSet: dataSet,
                    showId: showId
                },
                onComplete: function(scope) {
                    scope.onShowComplete();
                }
            });
        }
    };

    return itemSelector;
}]);
