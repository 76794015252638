<tilby-dialog-toolbar mat-dialog-title
    [title]="dialogTitle"
    [hideConfirm]="true"
/>
<tilby-dialog-content>
    <mat-radio-group [(ngModel)]="orderTable" aria-label="Select an option" (change)="orderTableChange()">
        <mat-radio-button value="byAmount">{{'DASHBOARD.DIALOG_BEST_PRODUCTS.BY_AMOUNT' | translate}}</mat-radio-button>
        <mat-radio-button value="byCount">{{'DASHBOARD.DIALOG_BEST_PRODUCTS.BY_COUNT' | translate}}</mat-radio-button>
        <mat-radio-button value="byMargin">{{'DASHBOARD.DIALOG_BEST_PRODUCTS.BY_MARGIN' | translate}}</mat-radio-button>
    </mat-radio-group>

    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef>{{'DASHBOARD.DIALOG_BEST_PRODUCTS.QUANTITY' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.count}}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>{{'DASHBOARD.DIALOG_BEST_PRODUCTS.REVENUE' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.amount | tilbyCurrency }}</td>
        </ng-container>

        <ng-container matColumnDef="margin">
            <th mat-header-cell *matHeaderCellDef>{{'DASHBOARD.DIALOG_BEST_PRODUCTS.MARGIN' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.margin | tilbyCurrency }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">{{'DASHBOARD.DIALOG_BEST_PRODUCTS.EMPTY' | translate}}</td>
        </tr>
    </table>
</tilby-dialog-content>

