const envConfig = require('tilby.env.config');
const platformConfig = require('tilby.platform.config');

export interface EnvironmentConfig {
    analyticsUrl: string,
	apiUrl: string,
	clientId: string,
	clientSecret: string,
	environment: "unstable" | "stable" | "beta" | "local",
	redirectUri: string,
	tilbyLoginUrl: string,
}

export const EnvironmentConfig: EnvironmentConfig = { ...envConfig, ...platformConfig };