<form [formGroup]="suppliersForm" (ngSubmit)="onSubmit(suppliersForm)" class="form tw-overflow-y-auto tw-pt-5" *ngIf="suppliersForm">
    <section class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12 md:tw-w-auto">
        <mat-form-field id="name" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-8/12 md:tw-mr-0">
            <mat-label translate>SUPPLIERS.DETAILS.COMPANY_NAME</mat-label>
            <input matInput formControlName="name" [maxlength]="validators.nameMaxLength" required>
            <mat-hint align="end">{{name?.value?.length || '0'}}/ {{validators.nameMaxLength}}</mat-hint>
            <mat-error *ngIf="name?.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
            <mat-error *ngIf="name?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.nameMaxLength}})</mat-error>
        </mat-form-field>
        <mat-form-field id="vat_code" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-4/12 md:tw-ml-0">
            <mat-label translate>SUPPLIERS.DETAILS.VAT_CODE</mat-label>
            <input matInput formControlName="vat_code" required>
            <mat-error *ngIf="vat_code?.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
            <mat-error *ngIf="vat_code?.hasError('pattern')" translate>NG_MESSAGES.INVALID_VALUE</mat-error>
        </mat-form-field>
    </section>
    <section>
        <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12 md:tw-w-auto">
            <div class="tw-flex-auto tw-w-full tw-ml-5 md:tw-flex-initial md:tw-w-8/12">
                 <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12">
                    <mat-form-field id="phone1" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                        <mat-label translate>SUPPLIERS.DETAILS.TELEPHONE_1</mat-label>
                        <input matInput type="tel" formControlName="phone1" [maxlength]="validators.phoneMaxLength+5">
                        <mat-hint align="end">{{phone1?.value?.length || '0'}}/ {{validators.phoneMaxLength}}</mat-hint>
                        <mat-error *ngIf="phone1?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.phoneMaxLength}})</mat-error>
                    </mat-form-field>
                    <mat-form-field id="phone2" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                        <mat-label translate>SUPPLIERS.DETAILS.TELEPHONE_2</mat-label>
                        <input matInput type="tel" formControlName="phone2" [maxlength]="validators.phoneMaxLength+5">
                        <mat-hint align="end">{{phone2?.value?.length || '0'}}/ {{validators.phoneMaxLength}}</mat-hint>
                        <mat-error *ngIf="phone2?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.phoneMaxLength}})</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field id="fax" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-4/12 md:tw-ml-0">
                <mat-label translate>SUPPLIERS.DETAILS.FAX</mat-label>
                <input matInput formControlName="fax">
                <mat-hint align="end">{{fax?.value?.length || '0'}}/ {{validators.faxMaxLength}}</mat-hint>
                <mat-error *ngIf="fax?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.faxMaxLength}})</mat-error>
            </mat-form-field>
        </div>
        <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12 md:tw-w-auto">
            <div class="tw-flex-auto tw-w-full tw-ml-5 md:tw-flex-initial md:tw-w-8/12">
                <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12">
                    <mat-form-field id="email" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                        <mat-label translate>SUPPLIERS.DETAILS.EMAIL_1</mat-label>
                        <input matInput type="email" formControlName="email1">
                        <mat-hint align="end">{{email1?.value?.length || '0'}}/ {{validators.emailMaxLength}}</mat-hint>
                        <mat-error *ngIf="email1?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                        <mat-error *ngIf="email1?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.emailMaxLength}})</mat-error>
                    </mat-form-field>
                    <mat-form-field id="email2" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                        <mat-label translate>SUPPLIERS.DETAILS.EMAIL_2</mat-label>
                        <input matInput type="email" formControlName="email2">
                        <mat-hint align="end">{{email2?.value?.length || '0'}}/ {{validators.emailMaxLength}}</mat-hint>
                        <mat-error *ngIf="email2?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                        <mat-error *ngIf="email2?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.emailMaxLength}})</mat-error>                    </mat-form-field>
                </div>
            </div>
            <mat-form-field id="email_pec" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-4/12 md:tw-ml-0">
                <mat-label translate>SUPPLIERS.DETAILS.EMAIL_PEC</mat-label>
                <input matInput type="email" formControlName="email_pec">
                <mat-hint align="end">{{email_pec?.value?.length || '0'}}/ {{validators.emailMaxLength}}</mat-hint>
                <mat-error *ngIf="email_pec?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                <mat-error *ngIf="email_pec?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.emailMaxLength}})</mat-error>            </mat-form-field>
        </div>
        <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12 md:tw-w-auto">
            <div class="tw-flex-auto tw-w-full tw-ml-5 md:tw-flex-initial md:tw-w-8/12">
                <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12">
                    <mat-form-field id="website" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                        <mat-label translate>SUPPLIERS.DETAILS.WEBSITE</mat-label>
                        <input matInput type="url" formControlName="website">
                        <mat-hint align="end">{{website?.value?.length || '0'}}/ {{validators.websiteMaxLength}}</mat-hint>
                        <mat-error *ngIf="website?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.websiteMaxLength}})</mat-error>
                    </mat-form-field>
                    <div class="tw-flex-auto tw-w-full tw-mt-2 md:tw-flex-initial md:tw-ml-0">
                        <mat-checkbox color="primary" formControlName="conto_vendita">{{'SUPPLIERS.DETAILS.CONSIGNMENT'| translate}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-4/12 md:tw-ml-0">
            </div>
        </div>
    </section>
    <div class="tw-text-xl tw-pl-5 tw-mb-4 tw-mt-3 md:tw-mt-0">
        <mat-label translate>SUPPLIERS.DETAILS.ADDRESS</mat-label>
    </div>
    <section>
        <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12 md:tw-w-auto">
            <mat-form-field id="address_street" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-8/12 md:tw-mr-0">
                <mat-label translate>SUPPLIERS.DETAILS.STREET_NAME</mat-label>
                <input matInput formControlName="address_street">
                <mat-hint align="end">{{address_street?.value?.length || '0'}}/ {{validators.address.streetMaxLength}}</mat-hint>
                <mat-error *ngIf="address_street?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.address.streetMaxLength}})</mat-error>
            </mat-form-field>
            <mat-form-field id="address_number" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-4/12 md:tw-ml-0">
                <mat-label translate>SUPPLIERS.DETAILS.STREET_NUMBER</mat-label>
                <input matInput formControlName="address_number">
                <mat-hint align="end">{{address_number?.value?.length || '0'}}/ {{validators.address.numberMaxLength}}</mat-hint>
                <mat-error *ngIf="address_number?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.address.numberMaxLength}})</mat-error>
            </mat-form-field>
            </div>
            <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12 md:tw-w-auto">
                <div class="tw-flex-auto tw-w-full tw-ml-5 md:tw-flex-initial md:tw-w-8/12">
                    <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-x-12">
                        <mat-form-field id="address_zip" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                            <mat-label translate>SUPPLIERS.DETAILS.ZIP_CODE</mat-label>
                            <input matInput formControlName="address_zip">
                            <mat-hint align="end">{{address_zip?.value?.length || '0'}}/ {{validators.address.zipMaxLength}}</mat-hint>
                            <mat-error *ngIf="address_zip?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.address.zipMaxLength}})</mat-error>
                        </mat-form-field>
                        <mat-form-field id="address_city" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                            <mat-label translate>SUPPLIERS.DETAILS.CITY</mat-label>
                            <input matInput formControlName="address_city">
                            <mat-hint align="end">{{address_city?.value?.length || '0'}}/ {{validators.address.cityMaxLength}}</mat-hint>
                            <mat-error *ngIf="address_city?.invalid">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators.address.cityMaxLength}})</mat-error>
                        </mat-form-field>
                        <mat-form-field id="address_prov" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial md:tw-ml-0 md:tw-mr-0">
                            <mat-label translate>SUPPLIERS.DETAILS.COUNTY</mat-label>
                            <input matInput formControlName="address_prov">
                            <mat-hint align="end">{{address_prov?.value?.length || '0'}}/ {{validators.address.provMaxLength}}</mat-hint>
                            <mat-error *ngIf="address_prov?.hasError('pattern')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                            <mat-error *ngIf="address_prov?.hasError('minlength')">{{'NG_MESSAGES.NAME_TOO_SHORT' | translate}}(max. {{validators.address.provMinLength}})</mat-error>
                            <mat-error *ngIf="address_prov?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}(max. {{validators.address.provMaxLength}})</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <mat-form-field id="country" class="tw-flex-auto tw-w-full tw-ml-5 tw-mr-5 md:tw-flex-initial md:tw-w-4/12 md:tw-ml-0">
                    <mat-label translate>SUPPLIERS.DETAILS.COUNTRY</mat-label>
                    <input matInput formControlName="country" [matAutocomplete]="$any(autoNazione)">
                    <mat-error *ngIf="country?.invalid" translate="NG_MESSAGES.INVALID_VALUE"></mat-error>
                    <mat-autocomplete autoActiveFirstOption #autoNazione="matAutocomplete">
                        <mat-option *ngFor="let country of (country?.valueChanges|async|filteredCountry)"
                                [value]="country.name">
                            {{country.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </section>
        <section>
            <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap">
                <mat-form-field id="notes" class="tw-flex-auto tw-w-full tw-mr-5 md:tw-flex-initial tw-ml-5">
                    <mat-label translate>SUPPLIERS.DETAILS.NOTE</mat-label>
                    <textarea matInput formControlName="notes"></textarea>
                </mat-form-field>
            </div>
        </section>
    </form>
