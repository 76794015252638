<div class="tw-w-full tw-overflow-y-auto calc-real-height">
    <div class="tw-h-[225px] md:tw-h-[130px] tw-mr-5 tw-mb-5">
        <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
    </div>
    <div class="tw-flex tw-h-[500px] min-[960px]:tw-h-[500px] min-[600px]:tw-h-[620px] tw-flex-wrap tw-mt-5 tw-mb-5">
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5">
            <div class="tw-h-[300px] min-[960px]:tw-h-[300px] tw-mr-5 ">
                <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5">
            <div class="tw-h-[300px] min-[960px]:tw-h-[300px] tw-mr-5">
                <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5">
            <div class="tw-h-[300px] min-[960px]:tw-h-[300px] tw-mr-5">
                <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5">
            <div class="tw-h-[250px] min-[960px]:tw-h-[250px] min-[600px]:tw-h-[300px] tw-mr-5">
                <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5">
            <div class="tw-h-[250px] min-[960px]:tw-h-[250px] tw-mr-5">
                <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5">
            <div class="tw-h-[250px] min-[960px]:tw-h-[250px] tw-mr-5">
                <ngx-skeleton-loader [theme]="{height:'100%', background:'gainsboro'}"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>
