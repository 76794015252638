import { CommonModule } from "@angular/common";
import { Component, Inject, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogRef, } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { NexiDialogData, NexiDialogForm, NexiDialogService } from "./nexi-dialog.service";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { MagicPortalDialogComponent } from "../../magic-portal-dialog";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { PortalConfigData } from "../../services";
import { SettingsAlvoloContentComponent } from "src/app/features";

@Component({
    selector: 'nexi-dialog',
    templateUrl: './nexi-dialog.component.html',
    styleUrls: ['./nexi-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, TilbyMagicFormComponent, MatButtonModule, TranslateModule],
    providers: [OnDestroyService]
})
export class NexiDialogComponent {
    protected readonly onDestroyService = inject(OnDestroyService);
    protected readonly nexiDialogService = inject(NexiDialogService);

    constructor(
        public dialogRef: MatDialogRef<SettingsAlvoloContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputItemDataMagicForm<NexiDialogForm>,

    ) {
        this.subscriptions();
    }

    cancel() {
        this.dialogRef.close({ cancel: true });
    }

    private subscriptions(){
        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe((res: PortalConfigData<NexiDialogData, NexiDialogComponent>) => res.form.valid && this.nexiDialogService.confirm(res.form.value));
    }
}