import * as angular from 'angular';

angular.module('giftcard').directive('topbarGiftcard', ["$mdSidenav", "connection", "offlineDialog", function($mdSidenav, connection, offlineDialog) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-giftcard.html'),
        controller: ["$scope", "$element", "$attrs", function($scope, $element, $attrs) {
            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };
            /*
            $scope.onBarcodeScanned = function(result) {
                $scope.context.onSubmitBarcode(result);
            };
            */
            $scope.goBack = function() {
                $scope.context.goBack();
            };
        }]
    };
}]);
