import {Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {STATIC_ELEMENTS, NEW_TABLES} from "../../../../core";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {DevLogger} from "../../../../shared/dev-logger";
import {BottomSheetItem, MagicPortalDialogComponent} from 'src/app/dialogs';
import { mobileCheck } from '@tilby/tilby-ui-lib/utilities';
type ShapeName = `shape_${number}`;
type Shape = {[props in ShapeName]:string}
export const TABLES_LABEL = 'Tables';
export const STATIC_ELEMENTS_LABEL = 'Static_Elements';
@Component({
    selector: 'app-room-element-shape-grid',
    standalone: true,
    imports: [CommonModule, TranslateModule, MatTableModule, MatIconModule, MatDialogModule],
    templateUrl: './room-element-shape-grid.component.html',
    styleUrls: ['./room-element-shape-grid.component.scss'],
    host:{class:'tw-flex tw-flex-auto tw-justify-center'}
})
export class RoomElementShapeGridComponent {
    private readonly dialogRef = inject(MatDialogRef<MagicPortalDialogComponent<RoomElementShapeGridComponent>>);

    displayedColumns: string[] = ['name'];
    dataSource:({position:number,name:string}&Shape)[] = [
        this.createTableDatasource(),
        this.createStaticElementsDatasource()
];

    constructor(
    ) {
        if(mobileCheck()) this.displayedColumns.shift();  //Remove Name column in mobile view
    }

    private log(...args:any[]){
        DevLogger.log('ROOM_ELEMENT_SHAPE_GRID',...args);
    }
    private createTableDatasource(){
        const shapes = NEW_TABLES.reduce((s,table,i) => ({
            ...s,
            [`shape_${i}`]:table.shape
        }),{});
        // this.setDisplayColumns(Object.keys(shapes));
        return {position: 1, name: TABLES_LABEL,...shapes};
    }

    private setDisplayColumns(shapesNames:string[]){
        this.displayedColumns=[...this.displayedColumns,...shapesNames];
    }

    private createStaticElementsDatasource(){
        const shapes = STATIC_ELEMENTS.reduce((s,elementShape,i) => ({
            ...s,
            [`shape_${i}`]:elementShape.shape
        }),{});
        this.setDisplayColumns(Object.keys(shapes));
        return {position: 2, name: STATIC_ELEMENTS_LABEL,...shapes};
    }

    protected clickHandler(itemElement: string,item:any) {
        this.log('CLICK', itemElement,item)
        const res:BottomSheetItem={
            label:item.name,
            icon:itemElement,
            callback:()=>this.log('CLICK_CALLBACK', itemElement,item)
        };
        this.dialogRef.close(res);
    }
}
