<ng-container>
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"
            aria-label="Example icon-button with a menu"
            class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openShareImageDialog($event)" *ngIf="canShare()">
            <span>{{ 'GIFTCARD.SHOWCASE.SHARE' | translate }}</span>
        </button>
        <button mat-menu-item (click)="openDownloadPdfDialog($event)">
            <span>{{ 'GIFTCARD.SHOWCASE.DOWNLOAD_PDF' | translate }}</span>
        </button>
        <button mat-menu-item (click)="openDeactivateDialog($event)" [disabled]="!isGiftcardActive()" *ngIf="!isGiftcardDeleted()">
            <span>{{ 'GIFTCARD.SHOWCASE.DEACTIVATE' | translate }}</span>
        </button>
    </mat-menu>
</ng-container>
