<ag-grid-angular
    class="ag-theme-material tw-h-[calc(100vh-64px)] tw-w-[100vw] sm:tw-h-[75vh] sm:tw-w-[75vw]"
    [suppressMovableColumns]="true"
    [suppressRowHoverHighlight]="true"
    [suppressRowClickSelection]="true"
    [suppressContextMenu]="true"
    [suppressCellFocus]="true"
    [columnDefs]="columnDefs"
    [treeData]="true"
    [getDataPath]="getDataPath"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [columnTypes]="columnTypes"
    [rowData]="rowDataValues"
    [suppressAggFuncInHeader]="true"
    [groupDefaultExpanded]="groupDefaultExpanded"
    (firstDataRendered)="onFirstDataRendered($event)"
    [animateRows]="true"
></ag-grid-angular>
<!--    [groupMaintainOrder]="false"-->
<!--    [rowGroupPanelShow]="'never'"-->
<!--    [groupAllowUnbalanced]="true"-->
