<ng-container *ngIf="!hide">
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"
            aria-label="Example icon-button with a menu"
            class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDeleteDialog($event)">
            <mat-icon>delete_forever</mat-icon>
            <span>{{ 'SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.ACTIONS.DELETE' | translate }}</span>
        </button>
    </mat-menu>
</ng-container>
