<div class="shifts-new">
    <mat-progress-bar mode="query" *ngIf="isProcessing"></mat-progress-bar>
    <div class="shifts-content tw-flex tw-flex-row">
        <!-- left -->
        <section class="shifts-left-column tw-flex tw-flex-col left-section">
            <div class="wrapper-sticky">
                <div class="tw-flex tw-justify-end">
                    <button mat-icon-button aria-label="Filter shifts" (click)="filterShifts()" [color]="filterToggle ? 'primary' : ''">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Add new shifts" (click)="addNewShifts()" [disabled]="isNewEntryInProgress || shiftForm?.dirty">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-form-field class="tw-flex tw-mt-1.5" appearance="fill" *ngIf="filterToggle">
                    <mat-label>{{'SETTINGS.SHIFTS.SEARCH'|translate}}</mat-label>
                    <mat-icon matIconPrefix>search</mat-icon>
                    <input matInput name="search" [(ngModel)]="filterList">
                    <button *ngIf="filterList" matSuffix mat-icon-button aria-label="Clear" (click)="filterList=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="shifts-list-container" *ngIf="shiftsList && shiftsList.length > 0">
                <mat-nav-list dense>
                    <mat-list-item *ngFor="let shift of shiftsList | matchesShift:filterList;" (click)="selectShift(shift, shiftForm?.dirty)" [activated]="isSelected(shift)">
                        <div matListItemTitle>{{shift.name}}</div>
                        <div matListItemLine>{{getWeekdaysPeriod(shift.weekdays_period)}}</div>
                        <div matListItemLine>{{getTime(shift)}}</div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </section>
        <!-- right -->
        <section class="tw-flex tw-flex-col right-section" [ngClass]="{'tw-flex-wrap tw-content-center tw-justify-center' : !selected}">
            <div class="wrapper-sticky wrapper-icon-border tw-flex tw-flex-row tw-justify-end" *ngIf="selected">
                <button mat-icon-button aria-label="Save button" (click)="save()" [disabled]="shiftForm?.invalid">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete button" (click)="delete()" [disabled]="firstEntry">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

            <!-- magic form -->
            <div class="tw-flex tw-flex-col tw-h-[calc(100vh-113px)] wrapper-magic-form" *ngIf="selected && shiftForm">
                <tilby-magic-form [form]="shiftForm"></tilby-magic-form>
            </div>
            <div *ngIf="!selected" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh-113px)]">
                <mat-icon style="transform: scale(7);" class="tw-mb-20">
                    <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
                <div class="tw-pl-10 tw-pr-10 tw-text-center">{{'SETTINGS.SHIFTS.NOT_SELECTED_MSG'|translate}}</div>
            </div>
        </section>
    </div>
</div>
