import { Component, inject } from "@angular/core";
import { OpenDialogsService } from "src/app/dialogs";
import { GridService } from "../grid.service";
import { restManager, util } from "app/ajs-upgraded-providers";
import { Subscription } from "rxjs";
import { ICellRendererParams } from "ag-grid-community";
import { GridComponent } from "../..";


@Component({
    
    selector: 'grid-clickable-button-file-exporter',
    templateUrl: './grid-clickable-button-file-exporter.component.html',
    styleUrls: ['./grid-clickable-button-file-exporter.component.scss'],
})
export class GridClickableButtonFileExporterComponent {
    private readonly openDialogsService = inject(OpenDialogsService);
    private gridService = inject(GridService);
    private restManager = inject(restManager);
    private util = inject(util);
    intervalSubscription: Subscription = new Subscription();
    showDownloadDetailedErrors?: boolean;
    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        if (this.gridService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id)) > -1) this.hide = true;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        if(!this.params.data) return;
    }

    refresh(): boolean {
        return false;
    }

    downloadFile = () => {
        this.restManager.getOne(`massive_export/file/${this.params.data.id}`)
        .then((result: any) => this.util.openExternalLink(result.url))
        .catch(() => this.openDialogsService.openAlertDialog({data: {messageLabel: 'FILE_EXPORTER.SHOWCASE.ERROR_DOWNLOAD_FILE'}}));
    };

    deleteFile = () => {
        this.openDialogsService.openConfirmDialog({data: {
            messageLabel: 'FILE_EXPORTER.SHOWCASE.MESSAGE_DELETE_FILE',
        }}).then((response) => {
            if(!response) return;

            this.restManager.deleteOne(`massive_export/file/${this.params.data.id}`)
            .then(() => {
                this.openDialogsService.openAlertDialog({data: {messageLabel: 'FILE_EXPORTER.SHOWCASE.MESSAGE_DELETE_FILE_DONE'}});
                (<GridComponent>this.params.context.componentParent).clickableButton.emit({
                    method: 'delete',
                    id: this.params.data.id
                });
            })
            .catch(() => this.openDialogsService.openAlertDialog({data: {messageLabel: 'FILE_EXPORTER.SHOWCASE.ERROR_DELETE_FILE'}}));
        })
    };

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }
}