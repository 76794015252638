import * as angular from 'angular';
import * as async from 'async';
import * as _ from 'lodash';

angular.module('digitalPayments').factory('satispay', satispay);

satispay.$inject = ["$q", "satispayPayments", "satispayApi"];

function satispay($q, satispayPayments, satispayApi) {
    var buildTail = function(response) {
        if(response) {
            var tailLines = [];
            tailLines.push(_.pad('SATISPAY', 46));
            tailLines.push("");
            if(response.sender) {
                tailLines.push("NAME:   " + response.sender.name);
            }
            tailLines.push("STATUS: " + response.status);
            tailLines.push("AMOUNT: " + _.toNumber(response.amount_unit / 100).toFixed(2));
            if(response.sender) {
                tailLines.push("CID:    " + response.sender.id);
            }
            if(response.receiver) {
                tailLines.push("SHOP:   " + response.receiver.id);
            }
            tailLines.push("TID:    " + response.id);
            tailLines.push("DATE:   " + response.insert_date);
            tailLines.push("");
            return tailLines.join('\n');
        } else {
            return "";
        }
    };

    var satispay = {
        payment: function(amount) {
            var d = $q.defer();

            satispayPayments.show({amount: _.round(amount * 100)}).then(function(payment) {
                console.log(payment);
                if(payment) {
                    satispayApi.acceptPayment(payment).then(function(response) {
                        var responseString = '';
                        try { responseString = JSON.stringify(response); } catch(e) {}

                        d.resolve({
                            acquirer_name : "Satispay",
                            payment_data: responseString,
                            tail: buildTail(response),
                            unclaimed: false
                        });
                    }, function(error) {
                        switch(error.status) {
                            case -1:
                                d.reject("SATISPAY_OFFLINE");
                            break;
                            case 400:
                            case 401:
                                d.reject("SATISPAY_UNABLE_TO_ACCEPT_PAYMENT");
                            break;
                            case 403:
                            case 404:
                                d.reject("SATISPAY_PAYMENT_NOT_FOUND");
                            break;
                            default:
                                d.reject("UNKNOWN_ERROR");
                            break;
                        }
                    });
                }
            }, function(error) {
                d.reject(_.isObject(error) ? 'CANCELED' : error);
            });

            return d.promise;
        },
        isEnabled: function() {
            return true; //for now we only need this for the printFail hook, which will determine if actions are necessary
        },
        printFailHook: function(sale, printerDocumentData) {
            var d = $q.defer();
            var satispayPayments = _.filter(sale.payments, { payment_method_type_id: 18 });
            var errorOccured = false;

            if(!_.isEmpty(satispayPayments)) {
                async.eachSeries(satispayPayments, function(payment, cb) {
                    try {
                        var sPayment = JSON.parse(payment.payment_data);
                        if(sPayment.id) {
                            satispayApi.rollbackPayment(sPayment).catch(function(error) {
                                errorOccured = true;
                            }).finally(cb);
                        } else {
                            cb();
                        }
                    } catch(e) {
                        cb();
                    }
                }, function(err, results) {
                    if(errorOccured) {
                        d.reject('SATISPAY_ROLLBACK_KO');
                    } else {
                        d.reject('SATISPAY_ROLLBACK_OK');
                    }
                });
            } else {
                d.resolve();
            }

            return d.promise;
        }
    };

    return satispay;
}