import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').factory('mergeOrders', ["$mdDialog", "$rootScope", "$timeout", "ActiveOrder", function($mdDialog, $rootScope, $timeout, ActiveOrder) {

    mergeOrdersController.$inject = ["$scope", "$mdDialog", "orders"];
    function mergeOrdersController($scope, $mdDialog, orders) {
        $scope.allOrders = _.sortBy(orders, ['room_name', 'table_name']);

        $scope.myOrders = _.filter($scope.allOrders, {
            operator_id: $rootScope.userActiveSession.id
        });

        $scope.orders = $scope.myOrders;

        $scope.showView = 'orders';
        $scope.selectTab = 'myOrders';

        $scope.selectedOrders = {};

        if (ActiveOrder.currentOrder.id) {
            var currentOrder = _.find($scope.allOrders, { id: ActiveOrder.currentOrder.id });
            if (currentOrder) {
                currentOrder.$checked = true;
                $scope.selectedOrders[currentOrder.id] = currentOrder;
            }
        }

        $scope.selectedTable = null;

        var getTablesFromOrders = function() {
            var selectedOrdersTables = [];
            var tablesMap = {};

            var tmpTables = _($scope.selectedOrders)
                .map(function(order) {
                    if (order.table_id) {
                        return _.pick(order, ['table_id', 'table_name', 'room_id', 'room_name']);
                    } else {
                        return;
                    }
                })
                .filter().value();

            _.each(tmpTables, function(table) {
                if (!tablesMap[table.table_id]) {
                    selectedOrdersTables.push(table);
                    tablesMap[table.table_id] = true;
                }
            });

            return selectedOrdersTables;
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            switch ($scope.showView) {
                case 'orders':
                    var selectedOrdersTables = getTablesFromOrders();
                    if (selectedOrdersTables.length > 1) {
                        $scope.selectedTablesList = selectedOrdersTables;
                        $scope.showView = 'tables';
                    } else {
                        $mdDialog.hide({
                            selectedOrdersList: _($scope.selectedOrders).keys().map(_.toInteger).value(),
                            selectedTable: _.head(selectedOrdersTables)
                        });
                    }
                    break;

                case 'tables':
                    $mdDialog.hide({
                        selectedOrdersList: _($scope.selectedOrders).keys().map(_.toInteger).value(),
                        selectedTable: $scope.selectedTable
                    });
                    break;

                default:
                    break;
            }
        };

        $scope.checkOrder = function(order) {
            $timeout(function() {
                if (order.$checked) {
                    $scope.selectedOrders[order.id] = order;
                } else {
                    delete $scope.selectedOrders[order.id];
                }
            });
        };

        $scope.canConfirm = function() {
            return _.keys($scope.selectedOrders).length > 1;
        };

        $scope.goToTab = function(name) {
            $scope.selectTab = name;

            switch (name) {
                case 'myOrders':
                    $scope.orders = $scope.myOrders;
                    break;
                case 'allOrders':
                    $scope.orders = $scope.allOrders;
                    break;
                default:
                    break;
            }
        };

        $scope.backToOrders = function() {
            $scope.showView = 'orders';
        };

        $scope.selectTable = function(table) {
            $scope.selectedTable = table;
        };
    }

    var mergeOrders = {
        show: function() {
            return $mdDialog.show({
                controller: mergeOrdersController,
                template: require('./merge-orders.html'),
                resolve: {
                    orders: ["entityManager", function(entityManager) {
                        return entityManager.orders.fetchCollectionOffline({ status: 'open' });
                    }]
                }
            });
        }
    };

    return mergeOrders;
}]);
