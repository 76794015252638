import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('printers').factory('ddtWine', ["$filter", "$q", "checkManager", "util", function($filter, $q, checkManager, util) {

	var underscoreRow = _.repeat("_", 38);

	var staticStrings = [
		["DOCUMENTO DI ACCOMPAGNAMENTO"],
		[
			"CAUSALE TRASPORTO: Vendita",
			"",
			"DESTINATARIO/LUOGO DI CONSEGNA"
		],
		["DEPOSITO PARTENZA"],
		[
			"TRASPORTO A CURA DEL DESTINATARIO",
			"ASPETTO ESTERIORE DEI BENI A VISTA"
		],
		[
			"VETTORE",
			"",
			underscoreRow,
			"",
			underscoreRow,
			"",
			"Firma del vettore",
			"",
			underscoreRow,
			"",
			"Firma del destinatario",
			"",
			underscoreRow
		]
	];

	var isPrintingAllowed = function(sale, bulkCategories) {
		return _.find(sale.sale_items, function(saleItem) {
			return (saleItem.quantity < 5 || saleItem.quantity > 60) && _.includes(bulkCategories, saleItem.category_id) && _.includes(['sale', 'gift'], saleItem.type);
		}) ? false : true;
	};

	var saleNeedsDDT = function(sale, bulkCategories) {
		return _.sumBy(sale.sale_items, function(saleItem) {
			return (_.includes(bulkCategories, saleItem.category_id) && _.includes(['sale', 'gift'], saleItem.type)) ? saleItem.quantity : 0;
		}) > 30 ? true : false;
	};

	var getCustomersInfo = function(sale) {
		var customer = sale.sale_customer;
		var customerRows = [];
		
		if(!_.isEmpty(customer)) {
			//Name
			customerRows.push(util.getCustomerCaption(customer));
			//Address
			customerRows.push(customer.shipping_street + ", " + customer.shipping_number);
			customerRows.push(customer.shipping_zip + " - " + customer.shipping_city + " (" + customer.shipping_prov + ")");
		} else {

		}
		return customerRows;
	};

	var getItemsCodeTable = function(itemsMap) {
		var codeTableRows = [];
		var codeTable = checkManager.getSetting("ddt_wine.code_table");

		if(!_.isEmpty(codeTable)) {
			var itemsCodeMap = {};

			//Generate items code map
			_.each(codeTable.split('\n'), function(row) {
				var parsedRow = _.split(row, '|');
				if(parsedRow.length === 2) {
					itemsCodeMap[_.padStart(parsedRow[0], 3, '0')] = parsedRow[1];
				}
			});

			var itemsCodes = _(itemsMap).filter(function(item) {
				return item.option4_name === "WineCode" && !_.isEmpty(item.option4_value) && !_.isEmpty(item.code);
			}).map('option4_value').uniq().sortBy().value();

			_.each(itemsCodes, function(code) {
				if(itemsCodeMap[code]) {
					codeTableRows.push(code + ": " + itemsCodeMap[code]);
				}
			});

			if(!_.isEmpty(codeTableRows)) {
				codeTableRows.unshift("TABELLA CODICI VINO");
			}
		}

		return codeTableRows;
	};

	var checkSaleCustomer = function(sale) {
		if(_.isEmpty(sale.sale_customer)) {
			return "DDT_WINE_MISSING_CUSTOMER";
		} else {
			var cust = sale.sale_customer;
			if(_.isEmpty(cust.shipping_street) || _.isEmpty(cust.shipping_zip) || _.isEmpty(cust.shipping_city) || _.isEmpty(cust.shipping_prov)) {
				return "DDT_WINE_INCOMPLETE_SHIPPING_ADDRESS";
			} else {
				return null;
			}
		}
	};

	var getItemsDetails = function(sale, itemsMap, bulkCategories) {
		var itemsRows = [];
		var itemsCount = 0;
		_.each(sale.sale_items, function(saleItem) {
			if(_.includes(['sale', 'gift'], saleItem.type)) {
				var item = itemsMap[saleItem.item_id];
				if(item && !_.isEmpty(item.code)) {
					var itemCV = item.option4_name === "WineCode" ? item.option4_value : "";
					var itemUnit = _.includes(bulkCategories, item.category_id) ? "lt" : "pz";
					itemsCount += itemUnit === "lt" ? 1 : saleItem.quantity;
					itemsRows.push(_.padEnd(itemCV, 4) + _.padEnd(_.truncate(saleItem.name, {length: 25 }), 25) + _.padStart($filter('number')(saleItem.quantity, 2), 6) + _.padStart(itemUnit, 3));
				}
			}
		});

		if(!_.isEmpty(itemsRows)) {
			itemsRows.unshift(_.padEnd("CV",4) + "ARTICOLI");
		}

		return {
			rows: itemsRows,
			count: itemsCount
		};
	};

	var getBulkCategories = function() {
		var bulkCatPreference = checkManager.getPreference("ddt_wine.bulk_categories");
		if(!_.isEmpty(bulkCatPreference)) {
			var bulkCategories = _(bulkCatPreference).split('|').map(_.toInteger).value();
			return bulkCategories;
		} else {
			return null;
		}
	};

	var ddtWine =  {
		isEnabled: function isEnabled() {
			return checkManager.getSetting("ddt_wine.print");
		},
		isPrintable: function isPrintable(sale, printerDocumentData, options) {
			var bulkCategories = getBulkCategories();
			var error = null;

			if(isPrintingAllowed(sale, bulkCategories)) {
				if(saleNeedsDDT(sale, bulkCategories)) {
					var customerStatus = checkSaleCustomer(sale);
					if(!_.isEmpty(customerStatus)) {
						error = customerStatus;
					}
				}
			} else {
				error = 'DDT_WINE_UNALLOWED_SALE';
			}

			return error ? $q.reject(error) : $q.resolve();
		},
		getData: function getData(sale, printerDocumentData) {
			var d = $q.defer();
			var ddtRows = [];

			var bulkCategories = getBulkCategories();
			
			//Step 1: check if the sale printing is allowed (we don't have bottles over 60 lt)
			if(saleNeedsDDT(sale, bulkCategories)) {
				var currentDate = new Date();
				//Static 0
				ddtRows = _.concat(ddtRows, staticStrings[0]);
				//Header
				var ddtHeader = checkManager.getSetting("ddt_wine.header");
				ddtRows = _.concat(ddtRows, ddtHeader.split("\n"));
				ddtRows.push("");
				//Progressive
				ddtRows.push(_.padEnd("DDT N. " + (_.get(printerDocumentData, "printer.invoice_prefix") || "") + _.padStart(checkManager.getPreference("ddt_wine.progressive") || 1, 8, '0'), 23) + "DATA " + $filter("sclDate")(currentDate, "DD-MM-YYYY"));
				//Static 1
				ddtRows = _.concat(ddtRows,staticStrings[1]);
				//Customer Shipping Address
				ddtRows = _.concat(ddtRows, getCustomersInfo(sale));
				ddtRows.push("");
				//Static 2
				ddtRows = _.concat(ddtRows, staticStrings[2]);
				//DDT Wine deposit
				var ddtDeposit = checkManager.getSetting("ddt_wine.deposit");
				ddtRows = _.concat(ddtRows, ddtDeposit.split("\n"));
				ddtRows.push("");
				//Static 3
				ddtRows = _.concat(ddtRows, staticStrings[3]);
				ddtRows.push("");
				//Items Detail
				var itemsDetail = getItemsDetails(sale, printerDocumentData.itemsMap, bulkCategories);
				ddtRows = _.concat(ddtRows, itemsDetail.rows);
				ddtRows.push("");
				//Items Code Table
				ddtRows = _.concat(ddtRows, getItemsCodeTable(printerDocumentData.itemsMap));
				ddtRows.push("");
				//Items Count
				ddtRows.push("N.COLLI: " + itemsDetail.count);
				ddtRows.push("");
				//Static 4
				ddtRows = _.concat(ddtRows, staticStrings[4]);
				ddtRows.push("");
				//Date
				ddtRows.push("DATA E ORA RITIRO: " + $filter("sclDate")(currentDate, "DD-MM-YYYY HH:mm"));
				ddtRows.push("");
				//DDT Footer
				var ddtFooter = checkManager.getSetting("ddt_wine.footer");
				ddtRows = _.concat(ddtRows, ddtFooter.split("\n"));

				d.resolve({
					otherDocument: ddtRows
				});
			} else {
				d.resolve(null);
			}

			return d.promise;
		},
		postPrintHook: function(sale) {
			var bulkCategories = getBulkCategories();

			if(saleNeedsDDT(sale, bulkCategories)) {
				var newProgressive = _.toInteger(checkManager.getPreference("ddt_wine.progressive") || 1);
				checkManager.setShopPreference("ddt_wine.progressive", _.toString(newProgressive + 1));
			}

			return $q.resolve();
		}
	};

	return ddtWine;
}]);