import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ILoaderService} from "@tilby/tilby-ui-lib/components/tilby-pull-to-request";
import { DevLogger } from 'src/app/shared/dev-logger';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements ILoaderService {
    private static _loaderEnabled$ =new BehaviorSubject<boolean>(false);

    constructor() { }

    // I need this method twice, once for Static class use, once for injection use (e.g. in library)
    static get loaderEnabled$() {
        return LoaderService._loaderEnabled$;
    }
    get loaderEnabled$() {
        return LoaderService._loaderEnabled$;
    }

    public static showLoader() {
        setTimeout(()=>LoaderService.loaderEnabled$.next(true));
    }

    public static hideLoader() {
        setTimeout(()=>LoaderService.loaderEnabled$.next(false));
    }

}


/*  --Decorator LoaderEnabled--
Use @LoaderEnabled() above any method that returns an observable.
This would inject few lines to show the loader before actually invoking
the caller function and also adds a map and catch section to hide the
loader once the subscription is complete.
*/
export function LoaderEnabled() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalFunction = descriptor.value;

        descriptor.value = function () {

            LoaderService.showLoader();
            DevLogger.debug('**InjectedCode-begin--LOADERON', {target, propertyKey, descriptor}, typeof originalFunction);

            return Promise.resolve(originalFunction.apply(this, arguments))
                .finally(() => {
                        LoaderService.hideLoader();
                        DevLogger.debug('**InjectedCode-map--LOADEROFF',{target, propertyKey, descriptor});
                    });
        };

        return descriptor;
    };

}
