import {
    Component,
    Injectable,
    inject
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    QRCodeErrorCorrectionLevel,
    QRCodeModule
} from "angularx-qrcode";

import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";

type QRCodeDialogData = {
    qrCode: {
        data: string,
        width: number
        errorCorrectionLevel: QRCodeErrorCorrectionLevel
    }
    customActions?: GeneralInputButton[]
    dialogTitle?: string
};

@Component({
    selector: "app-qrcode-dialog",
    standalone: true,
    imports: [
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        QRCodeModule
    ],
    templateUrl: "./qrcode-dialog.component.html",
    styleUrls: ["./qrcode-dialog.component.scss"],
})
export class TilbyDialogComponent {
    protected readonly data: QRCodeDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    protected qrCode = this.data.qrCode;

    protected confirm() {
        this.matDialogRef.close();
    }
}

@Injectable({
    providedIn: "root",
})
export class QRCodeDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<QRCodeDialogData>) {
        const data: QRCodeDialogData = config?.data;

        const configEdited: NonNullableConfigData<QRCodeDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data,
        };

        return lastValueFrom(
            this.dialogRef.open(TilbyDialogComponent, configEdited).afterClosed()
        );
    }
}
