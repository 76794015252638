import {Component} from '@angular/core';

@Component({
    selector: 'app-skeleton-contact',
    templateUrl: './skeleton-contact.component.html',
    styleUrls: ['./skeleton-contact.component.scss'],
    host: {class:'tw-px-4 tw-p-4 tw-block'}
})
export class SkeletonContactComponent {

}
