<div class="flex-1 tw-h-full tw-w-full tw-flex-col tw-justify-end tw-flex tw-col-span-2 sm:tw-col-span-1" [class.tw-justify-center]="!isMobile">
    @if(documentPrinterValueForm && !isMobile){
        <ng-container [formGroup]="documentPrinterValueForm">
            <tilby-radio-input formControlLabel="document" class="tw-flex-1 tw-pt-5 tw-pl-5"/>
        </ng-container>
    }
    <div class="tw-w-full tw-flex tw-flex-col tw-justify-end payments-keypad-cover">
        <app-tilby-keypad-display [form]="totalValueForm"/>
        <app-cashregister-keypad class="tw-h-2/3 tw-min-h-[200px] tw-w-full tw-flex-1" [field]="cashValue" (fieldChange)="onFieldChange($event)"/>
    </div>
</div>

@if(!ticketActive()){
    <div class="tw-grid tw-grid-cols-1 tw-h-full tw-overflow-y-auto tw-w-full tw-gap-2 tw-place-items-center" [class.tw-grid-cols-2]="!isMobilePortrait()">
        @for(paymentMethod of paymentMethods; track paymentMethod.id){
            @if(!paymentMethod.hidden){
            <div class="tw-col-span-1 tw-flex-col tw-gap-2 tw-cursor-pointer tw-rounded-md tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center tw-text-center tw-p-2 button-check"
                 [ngStyle]="{'background-color':'#'+(paymentMethod?.color??'b2dfdb')}"
                 (click)="addPaymentToSale(paymentMethod)"
            >
                <span [class.tw-text-xs]="paymentMethod.$info">{{ paymentMethod.name }}</span>
                @if(paymentMethod.$info; as info){
                    <span class="tw-text-sm tw-font-semibold">{{ info }}</span>
                }
            </div>
            }
        }
    </div>
} @else {
    <div class="tw-grid tw-grid-cols-1 tw-h-full tw-overflow-y-auto tw-w-full tw-gap-2 tw-place-items-center" [class.tw-grid-cols-2]="!isMobilePortrait()">
        <div class="tw-col-span-1 tw-flex tw-flex-col tw-gap-1 tw-h-full tw-w-full">
            @for (ticket of ticketsCollection;track ticket.id) {
                <div [ngStyle]="{'background-color':'#'+(ticket?.color??'b2dfdb')}" class=" tw-cursor-pointer tw-rounded-md tw-max-h-12 tw-w-full tw-flex tw-flex-1 tw-items-center tw-justify-center tw-text-center tw-p-2 button-check" [ngClass]="{'button-selected':ticket==selectedTicketCircuit}" (click)="selectTicketCircuit(ticket)">{{ ticket.name }}</div>
            }
        </div>
        <app-tickets-values-sorted class="tw-col-span-1 tw-flex tw-flex-col tw-gap-1 tw-h-full tw-w-full"
                [ticketValues]="selectedTicketCircuit?.ticket_values||[]"
                (selectedTicketValue)="selectTicketValue($event)"
                (longPressTicketValue)="removeTicketValue($event)"
                (addNewTicketEmitter)="addNewTicket()"
        />
    </div>
}


