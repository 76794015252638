import { Injectable } from "@angular/core";
import { StorageManagerService } from "src/app/core";

const entityName = 'user_sessions';

@Injectable({
    providedIn: 'root'
})
export class UserSessionsEntity {
    constructor(
        private storageManager: StorageManagerService
    ) {
    }

    public getOne(id: string) {
        return this.storageManager.getOne(entityName, id);
    }

    public getCollection(query?: any) {
        return this.storageManager.getCollection(entityName, query);
    }
    
    public deleteOne(id: string) {
        return this.storageManager.deleteOne(entityName, id);
    }

    public async getActiveSession() {
        return this.getAllActiveSessions().then(sessions => sessions[0]);
    }

    public getAllActiveSessions() {
        return this.storageManager.findAllByIndex(entityName, 1, 'active');
    }

    public saveOne(entity: any) {
        return this.storageManager.saveOne(entityName, entity);
    }

    public saveCollection(sessions: any[]) {
        return this.storageManager.saveCollection(entityName, sessions);
    }
}