import * as angular from 'angular';
import * as _ from 'lodash';
import { Giftcards, Sales } from "tilby-models";

class GiftCardSale {
    constructor(
        private $filter: any,
        private $translate: any,
        private restManager: any
    ) {
    }

    public isEnabled() {
        return true;
    }

    public async prePrintHook(sale: Sales, printerDocumentData: any) {
        let giftCards = [];

        //Find gift card payments
        let giftCardPayments = sale.payments?.filter((payment) => (payment.payment_method_type_id == 33));

        //TODO: we should merge the payment rows by barcode
        if(!giftCardPayments?.length) {
            return null;
        }

        for(let payment of giftCardPayments) {
            try {
                if(!payment.payment_data){
                    throw "GIFT_CARD.SALE_PAYMENT.NO_PAYMENT_DATA";
                }
                let giftCardInfo = JSON.parse(payment.payment_data);

                if(giftCardInfo.giftcard_uuid) {
                    let giftCardStatus = await this.restManager.post(`giftcards/${giftCardInfo.giftcard_uuid}/movement`, {
                        type: payment.amount > 0 ? "unload" : "restore",
                        amount: Math.abs(payment.amount),
                        origin_sale: sale.uuid
                    });

                    Object.assign(giftCardStatus, {
                        payment_amount: payment.amount
                    });

                    giftCards.push(giftCardStatus);
                }
            } catch(err) {
                throw err;
            }
        }

        return {
            giftCards: giftCards
        };
    }

    public async getData(sale: Sales, printerDocumentData: any, giftCardInfo: any&{giftCards: Giftcards[]}) {
        if (giftCardInfo) {
            let tailRows = []

            for (let giftCard of giftCardInfo.giftCards) {
                let giftCardRows = [
                    this.$translate.instant("GIFT_CARD.SALE_PAYMENT.TITLE"),
                    _.toString(giftCard.code),
                    _.toString(giftCard.uuid),
                    this.$translate.instant("GIFT_CARD.SALE_PAYMENT.USED_CREDIT", { value: this.$filter("sclCurrency")(giftCard.payment_amount) }),
                    this.$translate.instant("GIFT_CARD.SALE_PAYMENT.REMAINING_CREDIT", { value: this.$filter("sclCurrency")(giftCard.last_value) })
                ]

                if(giftCard.valid_until) {
                    giftCardRows.push(this.$translate.instant("GIFT_CARD.SALE_PAYMENT.EXPIRATION", { date: this.$filter("sclDate")(giftCard.valid_until) }));
                }

                giftCardRows.push(this.$translate.instant(`GIFT_CARD.SALE_PAYMENT.${giftCard.is_divisible ? "DIVISIBLE" : "NOT_DIVISIBLE" }`));

                tailRows.push(...giftCardRows);
            }

            return {
                tail: tailRows
            }
        }
    }

    public async printFailHook(sale: Sales, printerDocumentData: any, giftCardInfo: any&{giftCards: Giftcards[]}) {
        if (!giftCardInfo) {
            return;
        }

        let hasRollbackError = false;

        for(let giftCard of giftCardInfo.giftCards) {
            try {
                await this.restManager.post(`giftcards/${giftCard.uuid}/movement`, {
                    type: "restore",
                    amount: giftCard.payment_amount,
                    origin_sale: sale.uuid
                });
            } catch (err) {
                hasRollbackError = true;
            }
        }

        throw `GIFT_CARDS_ROLLBACK_${hasRollbackError ? "KO" : "OK"}`
    }
}

GiftCardSale.$inject = ["$filter", "$translate", "restManager"];

angular.module('giftcard').service('GiftCardSale', GiftCardSale);
