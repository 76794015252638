import {Component} from '@angular/core';

@Component({
    selector: 'app-skeleton-dashboard',
    templateUrl: './skeleton-dashboard.component.html',
    styleUrls: ['./skeleton-dashboard.component.scss'],
    host:{class:'tw-block tw-h-full'}
})
export class SkeletonDashboardComponent {

}
