import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-settings-batch',
  templateUrl: './settings-batch.component.html',
  styleUrls: ['./settings-batch.component.scss']
})
export class SettingsBatchComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.settings.batch");
    }

  ngOnInit(): void {
  }

}
