import * as angular from 'angular';
import * as html2pdf from 'html2pdf.js';
import * as _ from 'lodash';
import { EnvironmentConfig } from 'src/environments/environment-config';

angular.module('history').controller('HistoryEInvoicesPassiveCtrl', ["$scope", "$rootScope", "$window", "$timeout", "user", "$translate", "toast", "util", "invoicePassivePaymentsDialog", "restManager", "$sce", "environmentInfo", function ($scope, $rootScope, $window, $timeout, user, $translate, toast, util, invoicePassivePaymentsDialog, restManager, $sce, environmentInfo) {
        var now = new Date();

        _.assign($scope, {
            areAllSalesSelected: false,
            InvoicesPassiveData: null,
            loadingInProgress: false,
            showUiDetail: false,
            user: user,
            xmlInvoice: null,
            filter_context: {
                mode: "showcase",
                isCardsSection: true,
                filters: {
                    content: [],
                    upperFilter: null

                },
                dateFilter: {
                    from: new Date(now.getFullYear(), now.getMonth() - 5, 1),
                    to: new Date(now.getFullYear(), now.getMonth(), now.getDate())
                }
            },
            infoBox: {
                nInvoices: 0,
                nInvoicesToOpen: 0,
                nInvoicesGoingToExpire: 0,
                nInvoicesExpired: 0
            },
            topbar_context: {
                mode: "showcase",
                eInvoicesPerPage: 50,
                isDetailXml: false,
                goBack: function () {
                    _.assign($scope.topbar_context, {
                        mode: "showcase",
                        isDetailXml: false,
                    });
                }
            }
        });

        $scope.canDownload = function () {
            return environmentInfo.canDownloadFiles();
        };

        $scope.filter_context.canDownload = $scope.canDownload;

        $scope.downloadPdf = function (invoice) {
            var params = {
                id: invoice.id
            };
            var filename = invoice.nFattura;
            $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                enabled: true
            });
            $rootScope.$applyAsync();
            $scope.loadingInProgress = true;
            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "xml/download", params, "GET").then(function (result) {
                    var parser = new DOMParser();
                    var style = loadXMLDoc("assets/images/history/FoglioStileAssoSoftware.xsl");
                    var xmlDoc = parser.parseFromString(result["message"], "text/xml");
                    var doc = xmlDoc; //fattura elettronica XML
                    var Processor = new $window.XSLTProcessor();
                    Processor.importStylesheet(style);
                    var outputDocument = Processor.transformToDocument(doc);
                    var opt = {
                        margin: 0,
                        filename: filename,
                        html2canvas: { scale: 2, width: 900, logging: true, ignoreElements: (el) => (el.nodeName === 'HEAD' || el.id === 'content') },
                        pagebreak: { mode: ["avoid-all"] }
                    };
                    html2pdf().set(opt).from(outputDocument.firstChild.outerHTML).save(filename).then(function (x) {
                    }).catch(function (err) {
                        turnOffLoader();
                        toast.show({
                            message: "Qualcosa è andato storto nella creazione del pdf",
                            actions: [{
                                text: $translate.instant('MISC.OK'),
                                action: 'dismiss',
                                highlight: true
                            }],
                            hideDelay: 0
                        });
                    });
                    turnOffLoader();
                }).catch(function (err) {
                    turnOffLoader();
                    toast.show({
                        message: err.data.message || "Error getting invoice",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                });
            });
        };
        var loadXMLDoc = function (filename) {
            var xhttp;
            if ($window.ActiveXObject) {
                xhttp = new $window.ActiveXObject("Msxml2.XMLHTTP");
            }
            else {
                xhttp = new XMLHttpRequest();
            }
            xhttp.open("GET", filename, false);
            try { xhttp.responseType = "msxml-document"; } catch (err) { } // Helping IE11
            xhttp.send("");
            return xhttp.responseXML;
        };

        $scope.newSeeInvoice = function (invoice) {
            var params = {
                id: invoice.id
            };
            $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                enabled: true
            });
            $rootScope.$applyAsync();
            $scope.loadingInProgress = true;
            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "xml/download", params, "GET").then(function (result) {
                    var parser = new DOMParser();
                    var style = loadXMLDoc("assets/images/history/FoglioStileAssoSoftware.xsl");
                    _.forEach($scope.InvoicesPassiveData, function (temp) {
                        if (temp.id === invoice.id) {
                            if (temp.readValue !== true) {
                                temp.readValue = true;
                                var d = new Date();
                                temp.readAt = d.toISOString();
                                $scope.infoBox.nInvoicesToOpen--;
                            }
                        }
                    });
                    var xmlDoc = parser.parseFromString(result["message"], "text/xml");
                    var doc = xmlDoc; //fattura elettronica XML
                    var Processor = new $window.XSLTProcessor();
                    Processor.importStylesheet(style);
                    var outputDocument = Processor.transformToDocument(doc);
                    $scope.xmlInvoice = $sce.trustAsHtml(outputDocument.firstChild.outerHTML);
                    $scope.topbar_context.xmlID = invoice.id;
                    $scope.topbar_context.code = $rootScope.userActiveSession.oauthdata.access_token;
                    $scope.topbar_context.nFattura = invoice.nFattura;
                    $scope.topbar_context.mode = "detailXml";
                    $scope.topbar_context.isDetailXml = true;

                    turnOffLoader();
                }).catch(function (err) {
                    turnOffLoader();
                    toast.show({
                        message: err.data.message || "Error getting invoice",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                });
            });
        };
        $scope.hasNoResults = function () {
            if ($scope.InvoicesPassiveData == null) {
                return true;
            }
            if (_.isEmpty($scope.InvoicesPassiveData)) {
                return true;
            }
            return false;
        };
        $scope.updateInvoice = function (invoice, read, contab, paid) {
            var params = {
                id: invoice.id,
                read: read,
                contabilizzata: contab,
                paid: paid
            };
            if (read !== true && read !== false) {
                delete params.read;
            }
            if (contab !== true && contab !== false) {
                delete params.contabilizzata;
            }
            if (paid !== true && paid !== false) {
                delete params.paid;
            }
            $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                enabled: true
            });
            //$rootScope.$applyAsync(); 
            $scope.loadingInProgress = true;
            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "one", params, "POST").then(function (result) {
                    _.forEach($scope.InvoicesPassiveData, function (element) {
                        if (element.id === invoice.id) {
                            if (params.read === true) {
                                $scope.infoBox.nInvoicesToOpen--;
                            }
                            if (params.read === false) {
                                $scope.infoBox.nInvoicesToOpen++;
                            }
                            element.readValue = (params.read === true || params.read === false) ? params.read : element.readValue;
                            element.contabilizzata = (params.contabilizzata === true || params.contabilizzata === false) ? params.contabilizzata : element.contabilizzata;
                            element.paid = (params.paid === true || params.paid === false) ? params.paid : element.paid;
                        }
                    });
                    turnOffLoader();


                }).catch(function (err) {
                    turnOffLoader();
                    toast.show({
                        message: err.data.message || "Error updating",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                });

            });
        };
        $scope.downloadInvoice = function (invoicePassive) {
            var params = {
                id: invoicePassive.id
            };
            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "xml/download", params, "GET").then(function (result) {
                    util.downloadFile(result["message"], "fatturaPassiva" + invoicePassive.nFattura + ".xml", "application/xml");
                }).catch(function (err) {
                    toast.show({
                        message: err.data.message || "Error downloading",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                });
            });

        };
        $scope.filter_context.updateBulkInvoice = function (read, contab, paid) {
            var body = {
                list: []
            };
            var params = {
                id: null,
                read: read,
                contabilizzata: contab,
                paid: paid
            };
            if (read !== true && read !== false) {
                delete params.read;
            }
            if (contab !== true && contab !== false) {
                delete params.contabilizzata;
            }
            if (paid !== true && paid !== false) {
                delete params.paid;
            }
            _.forEach($scope.filter_context.selected, function (invoice) {
                var p = Object.assign({}, params);
                p.id = invoice.id;
                body.list.push(p);

            });

            $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                enabled: true
            });
            $rootScope.$applyAsync();
            $scope.loadingInProgress = true;
            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "more", body, "POST").then(function (result) {
                    _.forEach(body.list, function (element) {
                        _.forEach($scope.InvoicesPassiveData, function (elemTable) {

                            if (elemTable.id === element.id) {
                                elemTable.readValue = (element.read === true || element.read === false) ? element.read : elemTable.readValue;
                                elemTable.contabilizzata = (element.contabilizzata === true || element.contabilizzata === false) ? element.contabilizzata : elemTable.contabilizzata;
                                elemTable.paid = (element.paid === true || element.paid === false) ? element.paid : elemTable.paid;
                            }
                        });

                    });
                    getInvoicePassiveInfo();
                    turnOffLoader();


                }).catch(function (err) {
                    toast.show({
                        message: err.data.message || "Error updating",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                    turnOffLoader();
                });

            });
        };
        function turnOffLoader() {
            $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                enabled: false
            });
            $scope.loadingInProgress = false;
            $scope.deselectAllInvoices();
        }

        $scope.showDetail = function (field) {
            $scope.filter_context.filters.upperFilter = field;
            $scope.onUpperFilterChange();
        };

        $scope.onUpperFilterChange = function () {
            switch ($scope.filter_context.filters.upperFilter) {
                case 'scadute':
                case 'inScadenza':
                    $scope.filter_context.dateFilter.from = new Date(now.getFullYear(), now.getMonth(), 1);
                    $scope.filter_context.dateFilter.to = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                    break;
                default:
                    $scope.filter_context.dateFilter.from = new Date(now.getFullYear(), 0, 1);
                    $scope.filter_context.dateFilter.to = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                    break;
            }

            $scope.filter_context.applyFilter();
        };

        $scope.openModalPayments = function (invoicePassive) {
            invoicePassivePaymentsDialog.show(invoicePassive);
        };
        $scope.selected = function (e, inputHtml) {
            if (e.$checked === false) {
                $scope.filter_context.selected = _.reject($scope.filter_context.selected, function (value) {
                    return value.id === e.id;
                });
                if (inputHtml) {
                    $scope.areAllSalesSelected = false;
                }
            }
            if (e.$checked === true) {
                $scope.filter_context.selected.push(e);
            }
        };
        $scope.deselectAllInvoices = function () {
            $timeout(function () {
                _.each($scope.InvoicesPassiveData, function (sale) {
                    sale.$checked = false;
                    $scope.selected(sale);
                });

            });
        };


        $scope.selectAllInvoices = function () {
            $scope.filter_context.selected = [];
            _.each($scope.InvoicesPassiveData, function (sale) {
                if (!$scope.areAllSalesSelected) {
                    sale.$checked = true;
                    $scope.selected(sale);
                } else {
                    sale.$checked = false;
                    $scope.selected(sale);
                }
            });
        };

        // function used to catch filter's changes
        $scope.filter_context.changeQueryStatus = function () {
            getInvoicePassive();
        };
        $scope.filter_context.downloadAllInvoices = function () {
            var ids = "";
            $scope.filter_context.selected.forEach(function (e, i) {
                if (i === 0) {
                    ids = e.id;
                } else {
                    ids = ids + "," + e.id;
                }
            });

            try {
                var params = {
                    ids: ids,
                    code: restManager.getAccessToken(),
                    client_id: EnvironmentConfig.clientId
                };
                restManager.downloadOne("e_invoice/accounts_payable", "xmls/download", params).then(function (result) {
                    util.downloadZipFromLambda(result.data, "export_fatture_AAAMMDD.zip");
                }).catch(function (err) {
                    throw err;
                });
            } catch (error) {
                toast.show({
                    message: error.data.message || "Error downloading invoices",
                    actions: [{
                        text: $translate.instant('MISC.OK'),
                        action: 'dismiss',
                        highlight: true
                    }],
                    hideDelay: 0
                });
            }

            // });

        };
        function getDateForBackend() {
            // need this forma: GG/MM/YYYY
            var dateFilter = {
                from: (($scope.filter_context.dateFilter.from.getDate() < 10) ? ("0" + $scope.filter_context.dateFilter.from.getDate()) : $scope.filter_context.dateFilter.from.getDate()) + "/" + ((($scope.filter_context.dateFilter.from.getMonth() + 1) < 10) ? ("0" + ($scope.filter_context.dateFilter.from.getMonth() + 1)) : ($scope.filter_context.dateFilter.from.getMonth() + 1)) + "/" + $scope.filter_context.dateFilter.from.getFullYear(),
                to: (($scope.filter_context.dateFilter.to.getDate() < 10) ? ("0" + $scope.filter_context.dateFilter.to.getDate()) : $scope.filter_context.dateFilter.to.getDate()) + "/" + ((($scope.filter_context.dateFilter.to.getMonth() + 1) < 10) ? ("0" + ($scope.filter_context.dateFilter.to.getMonth() + 1)) : ($scope.filter_context.dateFilter.to.getMonth() + 1)) + "/" + $scope.filter_context.dateFilter.to.getFullYear(),
            };
            return dateFilter;
        }
        // TODO: handle pagination and scroll infinite
        var getInvoicePassive = function (eInvoicesPerPage, pageNumber) {
            $rootScope.$broadcast("loader:changeStatus", "historyShowcaseLoader", {
                enabled: true
            });
            $rootScope.$applyAsync();
            var d = getDateForBackend();
            $scope.loadingInProgress = true;
            // text is array of strings
            //extraFilter is a string enum that can be on of these: toRead, expiring, expired
            var extraFilter = null;
            if ($scope.filter_context.filters.upperFilter === "daLeggere") {
                extraFilter = "toRead";
            }
            if ($scope.filter_context.filters.upperFilter === "inScadenza") {
                extraFilter = "expiring";
            }
            if ($scope.filter_context.filters.upperFilter === "scadute") {
                extraFilter = "expired";
            }

            var params = {
                dateFrom: d.from,
                dateTo: d.to,
                text: $scope.filter_context.filters.content,
                extraFilter: extraFilter,
                offset: -1,
                limit: -1
            };
            $timeout(function () {
                $scope.InvoicesPassiveData = [];
                restManager.invoicePassive("e_invoice/accounts_payable", "list", params, "GET").then(function (result) {
                    result.listFPDB.forEach(function (x) {
                        x['amountPaidInt'] = Object.assign({}, { value: x.amountPaid });
                        if (x.amountPaid === 0 || x.amountPaid === null) {
                            x.amountPaid = "0,00";
                        } else {
                            x.amountPaid = "" + (x.amountPaid / 100).toFixed(2);
                            x.amountPaid = x.amountPaid.replace(".", ",");
                        }
                    });
                    $scope.InvoicesPassiveData = result.listFPDB;
                    getInvoicePassiveInfo();

                }).catch(function (err) {
                    toast.show({
                        message: err.data.message || "Error getting invoices",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                    turnOffLoader();
                });

            });

        };
        var getInvoicePassiveInfo = function () {

            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "info", null, "GET").then(function (result) {
                    $scope.infoBox = result;
                    turnOffLoader();

                }).catch(function (err) {
                    toast.show({
                        message: err.data.message || "Error getting invoices info",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                    turnOffLoader();
                });

            });

        };
        /**
         * 
         * @param {{id:String; paid:bool,amount:number}} data 
         */
        var updateInvoicePassivePaymentCallback = function (data) {
            _.forEach($scope.InvoicesPassiveData, function (element) {
                if (element.id === data.id) {
                    if (element["expiredAt"] != null || element["expiredAt"] !== undefined) {
                        var dArray = element["expiredAt"].split("/");
                        var expD = new Date(dArray[2] + "-" + dArray[1] + "-" + dArray[0]);
                        if (element.paid === false && data.paid === true && now.getMonth() === expD.getMonth()) {
                            if (expD.getDate() <= now.getDate()) {
                                $scope.infoBox["nInvoicesExpired"]--;
                            } else {
                                $scope.infoBox["nInvoicesGoingToExpire"]--;
                            }
                        }
                        if (element.paid === true && data.paid === false && now.getMonth() === expD.getMonth()) {
                            if (expD.getDate() <= now.getDate()) {

                                $scope.infoBox["nInvoicesExpired"]++;
                            } else {
                                $scope.infoBox["nInvoicesGoingToExpire"]++;
                            }

                        }
                    }
                    element.paid = (data.paid === true || data.paid === false) ? data.paid : element.paid;
                    element.paidValue = (data.paid === true || data.paid === false) ? data.paid : element.paid;
                    element.amountPaid = data.amount + "";
                    element.amountPaidInt.value = data.amount * 100;

                }
            });
        };
        $rootScope.$on("passiveInvoice:update", function (event, data) {
            updateInvoicePassivePaymentCallback(data);
        });
        getInvoicePassive();
    }]);