
import { Injectable } from "@angular/core";
import {
    ChannelsDefault,
    ChannelsFields,
    ChannelsFormGroup
} from "../settings-channels-form";
import { AbstractControl, Validators } from "@angular/forms";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { PaymentMethods } from "tilby-models";
import { KeyValue } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class ChannelsFormService {
    channelsForm!: CustomFormGroup<ChannelsFormGroup>;

    constructor(
    ) {
    }


    createForm(channel: ChannelsFields, paymentMethods: Array<PaymentMethods>): CustomFormGroup<ChannelsFormGroup> {
        if(!channel) {
            channel = new ChannelsDefault();
        }

        const paymentMethodsChoices: Array<KeyValue<string, number>> = [
            { key: 'SETTINGS.CHANNELS_MANAGEMENT.NO_PAYMENT_METHOD', value: -1 },
            ...paymentMethods.map((paymentMethod) => ({ key: paymentMethod.name, value: paymentMethod.id }))
        ];
        const isNewEntryInProgress = channel?.isNew ? true : false;

        this.channelsForm = new CustomFormGroup<ChannelsFormGroup>({
            generalForm: new CustomFormGroup({
                enabled: new CustomFormControl(channel.enabled ? channel.enabled : false, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.ENABLED',
                    inputType: 'slideToggle',
                    class: 'tw-w-full tw-mb-4'
                }),
                id: new CustomFormControl(
                    { value: channel.id || '', disabled: !isNewEntryInProgress },
                    { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.ID',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                name: new CustomFormControl(channel.name || '', { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.NAME',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                image_url: new CustomFormControl(channel.image_url || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.IMAGE_URL',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                url: new CustomFormControl(channel.url || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.URL',
                    inputType: 'text',
                    class: 'tw-w-full'
                }),
                add_to_mail_receipts: new CustomFormControl(channel.add_to_mail_receipts ? channel.add_to_mail_receipts : false, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.ADD_TO_MAIL_RECEIPTS',
                    inputType: 'checkbox',
                    class: 'tw-w-full tw-mb-2'
                }),
                default_payment_method_id: new CustomFormControl(channel.default_payment_method_id || -1, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.DEFAULT_PAYMENT_METHOD',
                    inputType: 'select',
                    inputChoices: paymentMethodsChoices,
                    class: 'tw-w-full'
                }),
                force_paid: new CustomFormControl(channel.force_paid ? channel.force_paid : false, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.FORCE_PAID',
                    inputType: 'checkbox',
                    class: 'tw-w-full tw-mb-2'
                }),
                allow_automatic_print: new CustomFormControl(channel.allow_automatic_print? channel.allow_automatic_print : false, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.ALLOW_AUTOMATIC_PRINT',
                    inputType: 'checkbox',
                    class: 'tw-w-full tw-mb-2'
                }),
                add_to_new_items: new CustomFormControl(channel.add_to_new_items? channel.add_to_new_items : false, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.CHANNELS_MANAGEMENT.ADD_TO_NEW_ITEMS',
                    inputType: 'checkbox',
                    class: 'tw-w-full tw-mb-2'
                }),
            }, {}, {...new CustomFormGroupProps()}),
        });

        this.checkUrl(this.url);

        if(isNewEntryInProgress) {
            this.id.enable();
        }

        this.url.valueChanges.subscribe(
            (url) => this.checkUrl(url)
        );

        return this.channelsForm;
    }

    updateForm(channel: ChannelsFields): void {
        this.channelsForm?.controls.generalForm.reset(channel);

        if(channel?.isNew) {
            this.id.enable();
        }
    }

    checkUrl(url: AbstractControl) {
        return url && url.value !== '' ? this.add_to_mail_receipts.enable() : this.add_to_mail_receipts.disable();
    }

    disableID() {
        this.id.disable();
    }

    get generalForm() {
        return this.channelsForm?.controls.generalForm;
    }
    get enabled() {
        return this.generalForm?.controls?.enabled;
    }
    get id() {
        return this.generalForm?.controls?.id;
    }
    get name() {
        return this.generalForm?.controls?.name;
    }
    get image_url() {
        return this.generalForm?.controls?.image_url;
    }
    get url() {
        return this.generalForm?.controls?.url;
    }
    get add_to_mail_receipts() {
        return this.generalForm?.controls?.add_to_mail_receipts;
    }
    get default_payment_method_id() {
        return this.generalForm?.controls?.default_payment_method_id;
    }
    get force_paid() {
        return this.generalForm?.controls?.force_paid;
    }
    get allow_automatic_print() {
        return this.generalForm?.controls?.allow_automatic_print;
    }
    get add_to_new_items() {
        return this.generalForm?.controls?.add_to_new_items;
    }
}
