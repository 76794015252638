import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {Feature, Features$} from "src/app/models/features.model";
import {SuppliersFields} from "src/app/features/suppliers/suppliers-form.model";
import {AgGridAngular} from "ag-grid-angular";
import {FidelityMovementsFields, PrepaidMovementsFields} from "src/app/features/customers/customers.model";
import {CustomersFields} from "src/app/features/customers/components/customers-details/tab-customer/customer-form/customers-form.model";
import {CashMovements, Giftcards, Sales} from 'tilby-models';
import { StaticTokenSession } from 'tilby-models';
import { FileExporterFe } from '../../model/model';


interface TableProperties {
    selectedRows:any[];
    idsDeleting:number[];
    gridIstances: GridInstances;
}

type GridInstances = { [id in Feature ]:AgGridAngular };

@Injectable({
  providedIn: 'root'
})
export class GridService implements Features$ {
    //FOR SERVER SIDE TABLE (IT REQUIRES REFRESH)
    tableProperties :TableProperties = {selectedRows:[], idsDeleting:[], gridIstances:{} as GridInstances};
    suppliers$ = {
        flash: new Subject<SuppliersFields>(),
        data: new Subject<SuppliersFields[]>(),
    };
    customers$ = {
        flash: new Subject<CustomersFields>(),
        data: new Subject<CustomersFields[]>(),
    };
    prepaid$ = {
        flash: new Subject<(PrepaidMovementsFields&{isNew:boolean})|null>(),
        data: new Subject<PrepaidMovementsFields[]>(),
    };
    fidelity$ = {
        flash: new Subject<(FidelityMovementsFields&{isNew:boolean})|null>(),
        data: new Subject<FidelityMovementsFields[]>(),
    };
    history_sales$ = {
        flash: new Subject<Sales&{isNew:boolean}>(),
        data: new Subject<Sales[]>(),
        closeDetail: new Subject<void>(),
    };
    giftcards$ = {
        flash: new Subject<(Giftcards&{isNew:boolean})|null>(),
        data: new Subject<Giftcards[]>()
    };
    settings_um_static_token$ = {
        flash: new Subject<StaticTokenSession>(),
        data: new Subject<StaticTokenSession[]>(),
    };
    history_cash_movements$ = {
        flash: new Subject<CashMovements&{isNew:boolean}>(),
        data: new Subject<CashMovements[]>()
    };

    file_importer$ = {
        flash: new Subject<CashMovements&{isNew:boolean}>(),
        data: new Subject<CashMovements[]>()   
    }

    file_exporter$ = {
        flash: new Subject<FileExporterFe&{isNew:boolean}>(),
        data: new Subject<FileExporterFe[]>()   
    }
}
