<tilby-dialog-toolbar title="DIALOG.EDIT_SHOP_PREFERENCE.TITLE" class="tw-px-3" [ngClass]="isMobile ? 'tw-mt-12' : ''" (confirm)="confirm()" [hideCancel]="true"/>
<tilby-dialog-content>
    <div class="tw-px-1 tw-pb-3">
        <span translate="DIALOG.EDIT_SHOP_PREFERENCE.FORM.TITLE"></span>
    </div>
    <div>
        <tilby-magic-form [form]="editShopPreferenceForm">
            <div #contentProjection class="tw-pb-5" [class.tw-hidden]="editShopPreferenceForm.controls.address.controls.address_autocomplete?.customProps?.inputType !== 'contentProjection'">
                <div class="mat-mdc-text-field-wrapper mdc-text-field mdc-text-field--focused" style="background-color: var(--mdc-filled-text-field-container-color);">
                    <div class="mat-mdc-form-field-focus-overlay"></div>
                    <div class="mat-mdc-form-field-flex ">
                        <div class="mat-mdc-form-field-infix">
                            <input mat-input type="text" formControlLabel="address_autocomplete" #searchTextField class="mat-mdc-input-element mat-mdc-form-field-input-control mdc-text-field__input cdk-text-field-autofill-monitored ng-star-inserted" min="null" max="null" id="mat-input-0" required="true" aria-required="true" pattern="" aria-describedby="mat-mdc-error-0" placeholder="{{'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_STREET' | translate}}">
                        </div>
                    </div>
                    <div matformfieldlineripple="" class=""></div>
                </div>
                <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align ng-tns-c2608167813-9">
                    <div class="mat-mdc-form-field-error-wrapper ng-tns-c2608167813-9 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%);">
                        @if(editShopPreferenceForm.controls.address.controls.address_autocomplete?.hasError('required') && editShopPreferenceForm.controls.address.controls.address_autocomplete?.touched) {
                            <mat-error _ngcontent-ng-c3258520137="" aria-atomic="true" class="mat-mdc-form-field-error mat-mdc-form-field-bottom-align ng-tns-c2608167813-9 ng-star-inserted" aria-live="polite" id="mat-mdc-error-0" style="line-height: normal; margin-top: -1rem;">
                                <div _ngcontent-ng-c3258520137="" class="ng-star-inserted" translate="{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}"></div>
                            </mat-error>
                        }
                    </div>
                </div>
            </div>
        </tilby-magic-form>
    </div>
</tilby-dialog-content>