import * as angular from 'angular';

angular.module('giftcard', ['application']);

angular.module('giftcard').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('giftcard');

    $stateProvider.state('app.giftcard', {
        url: '/old_giftcard',
        redirectTo: 'app.giftcard.showcase',
    });

    $stateProvider.state('app.giftcard.showcase', {
        url: '/showcase',
        views: {
            "appContent@app": {
                template: require('./partial/giftcard-showcase/giftcard-showcase.html'),
                controller: 'GiftcardShowcaseCtrl'
            }
        }
    });

    /* Add New States Above */
}]);
