import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.scss']
})
export class StockManagementComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.stock.management");
    }


    ngOnInit(): void {
  }

}
