<div *ngIf="!showError" id="container" class="tw-flex">
    <div class="list" [ngClass]="{'border-separator': isLandscape(), 'list': isLandscape(), 'list-full': !isLandscape() && !hasBack}">
        <app-settings-um-list *ngIf="isLandscape() || !hasBack"
            [enabledQrCodeGenerator]="true"
            [buttons]="customButtons"
            [items]="users"
            [allItems]="allUsers"
            [itemsIcon]="itemsIcon"
            (itemSelected)="onUserSelected($event)"
            [fieldBuilderLegend1]="buildFieldLegend1"
            [fieldBuilderLegend2]="buildFieldLegend2"
            [filterFunction]="customFilterFunction"
            [isLoading]="isLoading"
        ></app-settings-um-list>
    </div>
    <div id="section" class="tw-w-full" *ngIf="!this.isLoading || users.length > 0 && (isLandscape() || (!isLandscape() && hasBack))">
        <div *ngIf="!userSelected && this.isLoading" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh-113px)]">
            <mat-icon style="transform: scale(7);" class="tw-mb-20">
                <span class="material-symbols-outlined">psychology_alt</span>
            </mat-icon>
            <div class="tw-pl-10 tw-pr-10" translate>SETTINGS.USERS_MANAGEMENT.USER_NOT_SELECTED_MSG</div>
        </div>
        <div class="tw-flex tw-mr-5 tw-mt-3" [ngClass]="{'tw-justify-between': !isLandscape(), 'tw-justify-end': isLandscape()}" *ngIf="userSelected">
            <button mat-icon-button (click)="backFromUser()" *ngIf="!this.isLandscape()">
                <mat-icon>
                    <span class="material-symbols-outlined">arrow_back</span>
                </mat-icon>
            </button>
            <button mat-icon-button (click)="openDeleteDialog()" *ngIf="userSelected">
                <mat-icon>
                    <span class="material-symbols-outlined">delete</span>
                </mat-icon>
            </button>
        </div>
        <!-- <div *skeleton="!this.isLoading; theme:{background: 'darkgray', margin:'10px'}"></div> -->
        <div *ngIf="userSelected || !this.isLoading">
            <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
                <mat-label *skeleton="!this.isLoading; theme:{background: 'darkgray', 'max-width':'300px', margin:'50px 0px 0px 0px'}" class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.USER_DATA</mat-label>
                <section class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 sm:tw-gap-4 tw-mt-3">
                    <div *skeleton="!this.isLoading; theme:{height:'50px'}" class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.USERS_MANAGEMENT.FIRST_NAME</mat-label>
                            <input matInput disabled [value]="userSelected?.first_name">
                        </mat-form-field>
                    </div>
                    <div *skeleton="!this.isLoading; theme:{height:'50px'}" class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.USERS_MANAGEMENT.LAST_NAME</mat-label>
                            <input matInput disabled [value]="userSelected?.last_name">
                        </mat-form-field>
                    </div>
                    <div *skeleton="!this.isLoading; theme:{height:'50px'}" class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.USERS_MANAGEMENT.EMAIL</mat-label>
                            <input matInput disabled [value]="userSelected?.email">
                        </mat-form-field>
                    </div>
                    <div *skeleton="!this.isLoading; theme:{height:'50px'}" class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.USERS_MANAGEMENT.PHONE</mat-label>
                            <input matInput disabled [value]="userSelected?.phone">
                        </mat-form-field>
                    </div>
                </section>
            </section>
            <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
                <mat-label *skeleton="!this.isLoading; theme:{background: 'darkgray', 'max-width':'300px', margin:'40px 0px 0px 0px'}" class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.SECURITY</mat-label>
                <section class="tw-grid tw-grid-cols-1 tw-mt-3">
                    <div *skeleton="!this.isLoading; theme:{height:'50px'}" class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.USERS_MANAGEMENT.ID_BADGE</mat-label>
                            <input matInput disabled [value]="userSelected?.badge_id">
                            <button matSuffix  mat-icon-button aria-label="Edit" (click)="callUpdateBadgeId()">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </section>
            </section>
            <section class="tw-pt-7 tw-pl-8 tw-pr-8 tw-pb-4">
                <mat-label *skeleton="!this.isLoading; theme:{background: 'darkgray', 'max-width':'300px', margin:'40px 0px 0px 0px'}" class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.MODULES</mat-label>
                <section class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 tw-mt-3">
                    <div class="tw-w-full tw-mt-3" *ngFor="let module of modules">
                            <mat-slide-toggle class="mat-primary"
                              [(ngModel)]="enabledUserModules[module.name]"
                              [disabled]="!enabledModules[module.name]"
                              (ngModelChange)="enableModule(module.name)"
                              *skeleton="!this.isLoading; theme:{height:'30px', background: 'darkgray'}">
                              {{ module.string }}
                            </mat-slide-toggle>
                    </div>
                </section>
            </section>
            <section class="tw-pt-7 tw-pl-8 tw-pr-8 tw-pb-4">
                <mat-label *skeleton="!this.isLoading; theme:{background: 'darkgray', 'max-width':'300px', margin:'40px 0px 0px 0px'}" class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.ENABLED_USER_SETTINGS</mat-label>
                <section class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 tw-mt-3">
                    <div class="tw-w-full tw-mt-3" *ngFor="let setting of userSettings">
                            <mat-slide-toggle class="mat-primary"
                              [(ngModel)]="enabledUserSettings[setting.name]"
                              [disabled]="(setting.disabled && this.shopOwner && (this.shopOwner.username === this.userSelected?.username)) || false"
                              (ngModelChange)="toggleUserSetting(setting.name)"
                              *skeleton="!this.isLoading; theme:{height:'30px', background: 'darkgray'}">
                              {{ setting.string }}
                            </mat-slide-toggle>
                    </div>
                </section>
            </section>
            <section class="tw-pt-7 tw-pl-8 tw-pr-8 tw-pb-4" *ngIf="showCashdrawerPermissions">
                <mat-label *skeleton="!this.isLoading; theme:{background: 'darkgray', 'max-width':'300px', margin:'40px 0px 0px 0px'}" class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.CASHDRAWERS.TITLE</mat-label>
                <section class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 tw-mt-3">
                    <div class="tw-w-full tw-mt-3" *ngFor="let setting of cashdrawerPermissions">
                            <mat-slide-toggle class="mat-primary"
                              [(ngModel)]="enabledCashdrawerPermissions[setting.name]"
                              (ngModelChange)="toggleCashdrawerPermission(setting.name)"
                              *skeleton="!this.isLoading; theme:{height:'30px', background: 'darkgray'}">
                              {{ setting.string }}
                            </mat-slide-toggle>
                    </div>
                </section>
            </section>
            <section class="tw-pt-7 tw-pl-8 tw-pr-8 tw-pb-4">
                <mat-label class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.USER_PREFERENCES</mat-label>
                <section class="tw-grid tw-grid-cols-1 tw-mt-3">
                    <mat-form-field color="primary" class="tw-w-full">
                        <mat-label translate>SETTINGS.USERS_MANAGEMENT.DEFAULT_PRICE_LIST</mat-label>
                        <mat-select [(ngModel)]="enabledUserSettings['application.settings.price_list']"
                            (ngModelChange)="changePriceList()">
                            <mat-option class="mat-primary" *ngFor="let prList of priceLists" [value]="prList.value">
                              {{ prList.name }}
                            </mat-option>
                          </mat-select>

                    </mat-form-field>
                </section>
            </section>
            <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4" *ngIf="isRetailForceEnabled()">
                <mat-label class="tw-text-xl tw-font-bold" translate>SETTINGS.USERS_MANAGEMENT.FISCAL_PROVIDER_INFO</mat-label>
                <div class="tw-flex tw-w-full tw-justify-center tw-mt-5 tw-mb-5" *ngIf="settingUpRetailForce">
                    <mat-spinner class="tw-h-12 tw-w-12"></mat-spinner>
                </div>
                <section class="tw-grid tw-grid-cols-1 tw-mt-3" *ngIf="!settingUpRetailForce">
                    <div class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.RETAILFORCE.TERMINAL_NUMBER</mat-label>
                            <input matInput [(ngModel)]="enabledUserSettings['application.settings.retailforce.terminal_number']">
                        </mat-form-field>
                    </div>
                    <div class="tw-w-full">
                        <mat-form-field color="primary" class="tw-w-full">
                            <mat-label translate>SETTINGS.RETAILFORCE.CLIENT_ID</mat-label>
                            <input matInput [(ngModel)]="enabledUserSettings['application.settings.retailforce.client_id']">
                        </mat-form-field>
                    </div>
                    <button class="tw-pt-6 tw-pb-6" mat-stroked-button color="primary" (click)="setupRetailForce()"[disabled]="!canSetupRetailForce()" >
                        <span translate>SETTINGS.RETAILFORCE.FISCAL_SETUP</span>
                    </button>
                </section>
            </section>
        </div>
    </div>
</div>
<div *ngIf="showError" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
    <div>{{ 'SETTINGS.USERS_MANAGEMENT.ERROR_NOT_AUTHORIZED_1' | translate }}</div>
    <div><b>{{this.shopOwner.owner_first_name}} {{this.shopOwner.owner_last_name}} ({{this.shopOwner.username}})</b></div>
    <span><b>{{this.shopOwner.email_address}}</b></span>
    <span><b>{{this.shopOwner.phone_number}}</b></span>
    <span>{{ 'SETTINGS.USERS_MANAGEMENT.ERROR_NOT_AUTHORIZED_2' | translate }}</span>
</div>
