import { Injectable, inject } from "@angular/core";
import { operatorManager } from "app/ajs-upgraded-providers";
import { OperatorManagerService, OperatorUser } from "app/modules/core/service/operator-manager/operator-manager";
import {
    ConfigurationManagerService,
    EntityManagerService,
    StorageManagerService
} from "src/app/core";
import { keyBy, pickBy } from "src/app/shared/utils";
import {
    SaleBase,
    SaleTransactions,
    Sales,
    SalesCustomer,
    SalesItems,
    SalesItemsIngredients,
    SalesItemsPriceChanges,
    SalesItemsVariations,
    SalesPriceChanges
} from "tilby-models";
import {
    validate as validateUuid
} from 'uuid';
import { DevLogger } from "src/app/shared/dev-logger";
import {
    BehaviorSubject,
    Subject,
    filter
} from "rxjs";
import {
    BuildVariationsSalesOptions,
    CoverConfiguration,
    SaleTransactionUtilsService,
    SaleUtilsService,
    SaveTransactionOptions
} from "src/app/features/cashregister/services";
import {
    SaleMode,
    SaleType,
    SalesCashregister,
    VariationSale
} from "src/app/shared/model/cashregister.model";

export type SaleUpdatesData = {
    currentSale?: SalesCashregister
    hasPaidPayments: boolean
    saleMode?: SaleMode
    saleType?: SaleType
    variationSale?: VariationSale
}

export type EditSaleItemUpdate = {
    saleItem: SalesItems,
    newData: Partial<SalesItems>
};

type LoadSaleOptions = {
    applyCovers?: boolean,
    returnBuiltSale?: boolean,
    returnRawBuildSale?: boolean
};

@Injectable({
    providedIn: 'root'
})
export class ActiveSaleStoreService {
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly saleTransactionUtils = inject(SaleTransactionUtilsService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    private readonly operatorManagerService: OperatorManagerService = inject(operatorManager);

    public currentSale?: SalesCashregister; //(pristineSale + currentTransaction)
    private pristineSale?: Sales; //(API Sale or sum(saleTransactions))
    private variationSale?: VariationSale;
    private salesTransactions: Map<string, SaleTransactions> = new Map();
    private currentTransaction?: SaleTransactions;

    private coverConfig: CoverConfiguration = { type: 'none' };

    private static saleUpdateSubject = new BehaviorSubject<SaleUpdatesData>({
        currentSale: undefined,
        hasPaidPayments: false
    });

    private static saleUpdateErrorSubject = new Subject<string>();
    private static saleUpdateNotificationSubject = new Subject<string>();

    public static saleUpdates$ = ActiveSaleStoreService.saleUpdateSubject.asObservable();
    public static saleUpdateError$ = ActiveSaleStoreService.saleUpdateErrorSubject.asObservable();
    public static saleUpdateNotification$ = ActiveSaleStoreService.saleUpdateNotificationSubject.asObservable();

    constructor() {
        StorageManagerService.storageUpdates$.pipe(
            filter((data) => data.entityName === 'sale_transactions' && data.action === 'UPDATED')
        ).subscribe(data => this.onSaleTransactionUpdate(data.entity as SaleTransactions));
    }

    /**
     * Converts nullish values in an object to null.
     *
     * @param {any} obj - The object to process.
     * @return {any} The modified object with nullish values converted to null.
     */
    private nullishToNull(obj: any) {
        const newObj: any = {};

        for (const key in obj) {
            newObj[key] = obj[key] ?? null;
        }

        return newObj;
    }

    /**
     * Check if a transaction is allowed
     * Returns nothing if the transaction is allowed, throws an error otherwise.
     *
     * @param {SaleTransactions} transaction - the transaction to be checked
     */
    private checkTransaction(transaction: SaleTransactions) {
        //Check bill lock
        if (this.currentSale?.bill_lock) {
            try {
                //Throw if the transaction has items
                if (transaction.sale_items_transactions?.length) {
                    throw 'LOCKED_SALE';
                }

                const allowedSaleFields = ['bill_lock', 'lastupdate_at', 'lastupdate_by', 'payments', 'printed_prebills'];

                //Check if there are changes on non-allowed fields
                for (const key in transaction.sale_details) {
                    if (!allowedSaleFields.includes(key)) {
                        throw 'LOCKED_SALE';
                    }
                }
            } catch (err) {
                if (err !== 'LOCKED_SALE') {
                    throw err;
                }

                if (this.configurationManagerService.isUserPermitted('cashregister.bill_lock')) {
                    transaction.sale_details = transaction.sale_details || {};
                    transaction.sale_details.bill_lock = false;
                    ActiveSaleStoreService.saleUpdateNotificationSubject.next('SALE_UNLOCKED');
                } else {
                    throw err;
                }
            }
        }

        //Check for illegal item deletions if user is not allowed to delete items
        if (!this.configurationManagerService.isUserPermitted('cashregister.delete_item')) {
            const saleItemsByUuid = keyBy(this.pristineSale?.sale_items || [], (si) => si.uuid);

            for (const transactionItem of transaction.sale_items_transactions || []) {
                const originalSaleItem = saleItemsByUuid[transactionItem.sale_item_uuid];

                if (['sale', 'gift'].includes(originalSaleItem?.type) && transactionItem.quantity_difference < 0) {
                    throw 'CANNOT_DELETE_SENT_ITEMS';
                }
            }
        }
    }

    /**
     * Executes a transaction with the given operation.
     *
     * @param {function} operation - The operation to be executed on the transaction.
     * @param {SaleTransactions} transaction - The transaction object.
     * @param {OperatorUser} opData - The operator user data.
     */
    private executeTransaction(operation: (transaction: SaleTransactions, opData: OperatorUser) => void) {
        const opData = this.operatorManagerService.getSellerData();

        if (!this.currentTransaction) {
            this.currentTransaction = this.saleTransactionUtils.createSaleTransaction(this.currentSale!, opData);
        }

        const pristineTransaction = structuredClone(this.currentTransaction);

        try {
            operation(this.currentTransaction, opData);

            this.checkTransaction(this.currentTransaction);

            this.currentTransaction.sale_details = this.currentTransaction.sale_details || {};

            Object.assign(this.currentTransaction.sale_details, {
                lastupdate_at: new Date().toISOString(),
                lastupdate_by: opData.id
            });

            DevLogger.debug('CASHREGISTER', 'Transaction executed', this.currentTransaction);
        } catch (error) {
            this.currentTransaction = pristineTransaction;

            if (typeof error === 'string') {
                ActiveSaleStoreService.saleUpdateErrorSubject.next(error);
            } else {
                throw error;
            }
        }

        this.buildCurrentSale();
    }


    /**
     * Retrieves the sale item transaction associated with the given transaction and sale item.
     * If the sale item transaction does not exist, it is created in the transaction object.
     *
     * @param {SaleTransactions} transaction - The transaction object.
     * @param {SalesItems} saleItem - The sale item object.
     * @returns {SaleItemsTransactions} - The sale item transaction object.
     */
    private getSaleItemTransaction(transaction: SaleTransactions, saleItem: SalesItems) {
        let saleItemTransaction = transaction.sale_items_transactions?.find((transactionItem) => transactionItem.sale_item_uuid === saleItem.uuid);

        if (!saleItemTransaction) {
            saleItemTransaction = this.saleTransactionUtils.createSaleItemTransaction(saleItem);
            transaction.sale_items_transactions!.push(saleItemTransaction);
        }

        return saleItemTransaction;
    }

    /**
     * Builds the current sale object.
     *
     * @return {Object|null} The current sale object or null if there isn't an active sale.
     */
    private buildCurrentSale() {
        if (!this.pristineSale) {
            return;
        }

        //Set up currentSale or reset it
        this.currentSale = structuredClone(this.pristineSale);

        //If exists, apply current transaction to currentSale
        if (this.currentTransaction) {
            this.saleTransactionUtils.applyTransactionToSale(this.currentSale, this.currentTransaction);
        }

        const saleType: SaleType = this.canSaleBeAnOrder() ? 'order' : 'retail';
        const variationSales = saleType === 'order' ? this.getVariationSales({ mergeTransactions: true, coverConfig: this.coverConfig }) : [];

        this.variationSale = variationSales[0]?.variation_items?.length ? variationSales[0] : undefined;

        this.saleTransactionUtils.prepareSale(this.currentSale);

        ActiveSaleStoreService.saleUpdateSubject.next({
            currentSale: this.currentSale,
            hasPaidPayments: !!this.currentSale.payments?.some((p) => p.paid),
            saleMode: this.variationSale ? 'order' : 'bill',
            saleType: saleType,
            variationSale: this.variationSale
        });

        return this.currentSale;
    }

    /**
     * Check if the sale can be an order.
     *
     * @return {boolean} true if the sale can switch to order mode, false otherwise
     */
    private canSaleBeAnOrder() {
        if (!this.currentSale) {
            return false;
        }

        //If there is a parent sale (e.g. split sale and refunds), it can't switch to order mode
        if (this.currentSale.sale_parent_uuid) {
            return false;
        }

        return (
            this.currentSale.table_id ||
            ['take_away', 'delivery'].includes(this.currentSale.order_type || '') ||
            !!this.configurationManagerService.getPreference('cashregister.enable_print_order')
        );
    }

    /**
     * Retrieves the pending variation from the current sale.
     *
     * @return {VariationSale | undefined} The pending variation, or undefined if there is no current sale.
     */
    public getPendingVariation(): VariationSale | undefined {
        if (!this.currentSale) {
            return undefined;
        }

        return this.variationSale;
    }

    /**
     * Retrieves an array of pending sale transactions.
     *
     * @return {SaleTransactions[] | undefined} An array of pending sale transactions, or undefined if the current sale is not set or if transactions are not enabled.
     */
    private getPendingTransactions(): SaleTransactions[] | undefined {
        if (!this.currentSale) {
            return undefined;
        }

        if (!this.canSaleBeAnOrder()) {
            return [];
        }

        const pendingTransactions = this.saleTransactionUtils.getPendingTransactions([...this.salesTransactions.values()]);

        if (this.currentTransaction) {
            pendingTransactions.push(structuredClone(this.currentTransaction));
        }

        return pendingTransactions;
    }

    /**
     * Retrieves the variation sales based on the provided options.
     *
     * @param {BuildVariationsSalesOptions} options - The options for retrieving the variation sales.
     * @return {Array} An array containing the variation sales.
     */
    private getVariationSales(options: BuildVariationsSalesOptions) {
        if (!this.currentSale || !this.salesTransactions) {
            return [];
        }

        const transactionsToSend = [...this.salesTransactions.values()];

        if (this.currentTransaction) {
            transactionsToSend.push(this.currentTransaction);
        }

        return this.saleTransactionUtils.buildVariationSalesFromTransactions(this.currentSale, structuredClone(transactionsToSend), options);
    }

    /**
     * Handles updates to a sale transaction.
     *
     * @param {SaleTransactions} transaction - The transaction to handle.
     */
    private onSaleTransactionUpdate(transaction: SaleTransactions) {
        //Abort if the transaction is for a different sale
        if (!transaction || this.currentSale?.uuid !== transaction.sale_uuid) {
            return;
        }

        this.saleTransactionUtils.addTransactionToStore(this.salesTransactions, transaction);

        //Create the new pristine sale
        const sale = this.saleTransactionUtils.buildSaleFromTransactions([...this.salesTransactions.values()]);

        //Unselect the sale if it's not in an open state
        if (sale?.status !== 'open') {
            return this.openSale();
        }

        //Deploy the updated sale
        this.pristineSale = sale;
        this.buildCurrentSale();
    }

    /**
     * Updates the sale details of the current sale.
     *
     * @param {Partial<SaleBase>} saleDetails - The object containing the sale details to be updated.
     */
    public updateSaleDetails(saleDetails: Partial<SaleBase>) {
        if (!this.currentSale) {
            return;
        }

        this.executeTransaction((transaction) => {
            if (!transaction.sale_details) {
                transaction.sale_details = {};
            }

            Object.assign(transaction.sale_details, this.nullishToNull(saleDetails));

            //Clean up sale details with only the changed fields
            transaction.sale_details = pickBy(transaction.sale_details, (val, key) => val !== this.pristineSale?.[key as keyof SaleBase])
        });
    }

    /**
     * Checks if the current sale is in a pristine state.
     *
     * @return {boolean} Returns true if the current sale is in a pristine state, false otherwise.
     */
    public isPristine(): boolean {
        return !this.currentTransaction;
    }

    /**
     * Opens a sale and performs necessary updates.
     *
     * @param {Sales | SaleTransactions[]} saleData - The data for the sale. It can be either a Sales object or an array of SaleTransactions objects.
     * @return {void} 
     */
    public openSale(saleData?: Sales | SaleTransactions[]) {
        //Make sure we unload the current data
        this.pristineSale = undefined;
        this.currentSale = undefined;
        this.currentTransaction = undefined;
        this.salesTransactions.clear();

        ActiveSaleStoreService.saleUpdateSubject.next({
            currentSale: undefined,
            hasPaidPayments: false
        });

        if (!saleData) {
            return;
        }

        let sale: Sales | null;

        if (Array.isArray(saleData)) {
            sale = this.saleTransactionUtils.buildSaleFromTransactions(saleData);
        } else {
            sale = saleData;
        }

        if (sale?.status !== 'open') {
            throw 'CANNOT_OPEN_SALE';
        }

        //Load sale
        this.pristineSale = structuredClone(sale);

        //Load transactions
        if (Array.isArray(saleData)) {
            for (const saleTransaction of saleData) {
                this.saleTransactionUtils.addTransactionToStore(this.salesTransactions, saleTransaction);
            }
        }

        return this.buildCurrentSale();
    }

    /**
     * Loads a sale from the server or device storage based on the provided saleId.
     * If no saleId is provided, the function returns undefined.
     *
     * @param {number | string} saleId - The ID or UUID of the sale to load.
     * @param {Object} options - Additional options for the function.
     * @param {boolean} options.returnBuiltSale - Indicates whether to return the built sale object. Defaults to false.
     * @param {boolean} options.applyCovers - Indicates whether to apply covers to the sale. Works only if options.returnBuiltSale is true. Defaults to false.
     * @param {boolean} options.returnRawBuildSale - Indicates whether to return the raw built sale object (without using updateSale to clean up). Defaults to false.
     * @return {Promise<[SaleTransactions[], Sales?]>} A promise that resolves to an array of SaleTransactions objects and an optional Sales object if options.returnBuiltSale is true.
     */
    public async loadSale(saleId?: number | string, options?: LoadSaleOptions): Promise<[SaleTransactions[]?, Sales?]> {
        if (!saleId) {
            return [];
        }

        const fetchClause = validateUuid(`${saleId}`) ? { sale_uuid: saleId } : { sale_id: saleId };
        const saleTransactions = await this.saleTransactionUtils.fetchTransactions(fetchClause);

        this.coverConfig = await this.saleUtilsService.getCoverConfiguration();

        let result: [SaleTransactions[], Sales?] = [saleTransactions];

        if (options?.returnBuiltSale) {
            const builtSale = this.saleTransactionUtils.buildSaleFromTransactions(saleTransactions);

            if (builtSale) {
                if (options.applyCovers) {
                    this.saleUtilsService.applyCoverToSale(builtSale, this.coverConfig);
                }

                if (options?.returnRawBuildSale) {
                    result = [saleTransactions, builtSale];
                } else {
                    result = [saleTransactions, this.saleTransactionUtils.prepareSale(builtSale)];
                }
            }
        }

        return result;
    }

    /**
     * Saves the current sale.
     * 
     * @return {Promise<Sale>} The current sale.
     */
    public async saveSale(options?: { onlineFirstMode?: boolean }) {
        if (!this.currentSale) {
            return;
        }

        //If there are no transactions, we are working on a volatile sale (kiosk mode), so we need to save the sale instead of the transactions
        if(!this.salesTransactions.size) {
            const resultSale = await this.entityManagerService.sales.postOneOfflineFirst(structuredClone(this.currentSale));
            this.currentTransaction = undefined;

            return resultSale;
        }

        //Sync all the transactions related to this sale first
        if (options?.onlineFirstMode) {
            await this.entityManagerService.saleTransactions.syncEntityCollection({ sale_uuid: this.currentSale.uuid });
        }

        // Save current transaction (if any)
        await this.saveCurrentTransaction({ onlineFirstMode: options?.onlineFirstMode });

        return this.currentSale;
    }

    public async saveCurrentTransaction(options?: SaveTransactionOptions) {
        if (!this.currentTransaction) {
            return;
        }

        // Freeze the transaction to prevent it from being changed by the user
        const transactionToSave = structuredClone(this.currentTransaction);
        // Create a new transaction for the next user changes
        this.currentTransaction = undefined;

        // Save the transaction
        try {
            await this.saleTransactionUtils.saveTransaction(transactionToSave, options);
        } catch (err) {
            // Restore the current transaction
            this.currentTransaction = transactionToSave;

            throw err;
        }
    }

    /**
     * Updates pending transactions in bulk with the provided flags.
     *
     * @param {Pick<SaleTransactions, 'printed' | 'skip_printing'>} updateData - The data to update the pending transactions with.
     * @return {Promise<void>} - A promise that resolves when the update is complete.
     */
    private async bulkUpdatePendingTransactions(updateData: Pick<SaleTransactions, 'printed' | 'skip_printing'>) {
        if (!this.currentSale) {
            return;
        }

        const pendingTransactions = this.getPendingTransactions() || [];

        //Move the current transaction (if exists) to the sale transactions as it will be saved in the following loop
        if (this.currentTransaction) {
            this.saleTransactionUtils.addTransactionToStore(this.salesTransactions, this.currentTransaction);
            this.currentTransaction = undefined;
        }

        await this.saleTransactionUtils.bulkUpdatePendingTransactions(pendingTransactions, updateData);
    }

    /**
     * Marks pending transactions as skipped.
     *
     * @return {Promise<void>} Returns a promise that resolves when the transactions are marked as skipped and saved to storage.
     */
    public async markPendingTransactionsAsSkipped() {
        return this.bulkUpdatePendingTransactions({ printed: false, skip_printing: true });
    }

    /**
     * Marks pending transactions as printed.
     *
     * @return {Promise<void>} Returns a promise that resolves when the transactions are marked as printed and saved to storage.
     */
    public async markPendingTransactionsAsPrinted() {
        return this.bulkUpdatePendingTransactions({ printed: true, skip_printing: false });
    }

    /**
     * Add a customer to the current sale.
     *
     * @param {SalesCustomer} customer - The customer to be added.
     */
    public addSaleCustomer(customer: SalesCustomer) {
        if (!this.currentSale) {
            return;
        }

        this.updateSaleDetails({
            sale_customer: customer
        });
    }

    /**
     * Edits the customer information for the current sale (if open).
     *
     * @param {Partial<SalesCustomer>} updateData - The updated customer information.
     */
    public editSaleCustomer(updateData: Partial<SalesCustomer>) {
        if (!this.currentSale) {
            return;
        }

        this.updateSaleDetails({
            sale_customer: Object.assign({}, this.currentSale.sale_customer, updateData)
        });
    }

    /**
     * Removes the specified sale object from the current sale.
     *
     * @param {'sale_customer' | 'e_invoice'} object - The object to be removed from the sale. Can be either 'sale_customer' or 'e_invoice'.
     */
    private removeSaleObject(object: 'sale_customer' | 'e_invoice') {
        if (!this.currentSale) {
            return;
        }

        this.executeTransaction((transaction, operator) => {
            if (!transaction.sale_details) {
                transaction.sale_details = {};
            }

            //Mark the object as deleted if present in the pristine sale, or simply remove it if it's only in the transaction
            if (this.pristineSale?.[object]) {
                transaction.sale_details[object] = {
                    deleted_at: new Date().toISOString()
                };
            } else {
                delete transaction.sale_details[object];
            }
        });
    }

    /**
     * Removes the sale customer from the current sale.
     */
    public removeSaleCustomer() {
        return this.removeSaleObject('sale_customer');
    }

    /**
     * Removes the e-invoice data from the current sale.
     */
    public removeEInvoice() {
        return this.removeSaleObject('e_invoice');
    }


    /**
     * Add sale price changes to the current sale.
     *
     * @param {SalesPriceChanges | SalesPriceChanges[]} priceChanges - the sale price changes to add
     * @param {object} options - options
     * @param {boolean} options.replace - whether to replace existing price changes
     */
    public addSalePriceChanges(priceChanges: SalesPriceChanges | SalesPriceChanges[], options?: { replace?: boolean }) {
        if (!this.currentSale) {
            return;
        }

        const existingPriceChanges = options?.replace ? [] : (this.currentSale.price_changes || []);
        const priceChangesToAdd = Array.isArray(priceChanges) ? priceChanges : [priceChanges];

        this.updateSaleDetails({
            price_changes: [...existingPriceChanges, ...priceChangesToAdd]
        });
    }

    /**
     * Add sale item price changes to the current sale.
     *
     * @param {string} saleItemUuid - the sale item uuid
     * @param {SalesItemsPriceChanges | SalesItemsPriceChanges[]} priceChanges - the sale price changes to add
     * @param {object} options - options
     * @param {boolean} options.replace - whether to replace existing price changes
     */
    public addSaleItemPriceChanges(saleItemUuid: string, priceChanges: SalesItemsPriceChanges | SalesItemsPriceChanges[], options?: { replace?: boolean }) {
        if (!this.currentSale) {
            return;
        }

        const targetSaleItem = this.currentSale.sale_items?.find((si) => si.uuid === saleItemUuid);

        if (!targetSaleItem) {
            return;
        }

        const existingPriceChanges = options?.replace ? [] : (targetSaleItem.price_changes || []).filter((pc) => !this.getNotDiscountablePlaceHoldersIndexes().includes(pc.index));
        const priceChangesToAdd = Array.isArray(priceChanges) ? priceChanges : [priceChanges];

        this.editSaleItems([{
            saleItem: targetSaleItem,
            newData: {
                price_changes: [...existingPriceChanges, ...priceChangesToAdd]
            }
        }]);
    }

    /**
     * Get the indexes of sale discounts on a sale with not discountable items
     *
     * @return {number[]} The array of indexes.
     */
    private getNotDiscountablePlaceHoldersIndexes() {
        if (!this.currentSale) {
            return [];
        }

        return (this.currentSale.price_changes || [])
            .filter((pc) => pc.type === 'disc_perc_nd')
            .map((pc) => pc.index);
    }

    /**
     * Removes sale price changes from the current sale.
     *
     * @param {(pc: SalesPriceChanges) => boolean} predicate - the predicate function used to determine if a price change should be removed
     */
    public removeSalePriceChange(predicate: (pc: SalesPriceChanges) => boolean) {
        if (!this.currentSale) {
            return;
        }

        const oldPriceChanges = this.currentSale.price_changes || [];
        const newPriceChanges = oldPriceChanges.filter((pc) => !predicate(pc));

        //Update details if there are changes
        if (newPriceChanges.length !== oldPriceChanges.length) {
            this.updateSaleDetails({ price_changes: newPriceChanges });
        }
    }

    /**
     * Removes sale item price changes from a sale item.
     *
     * @param {string} saleItemUuid - the target sale item uuid
     * @param {(pc: SalesItemsPriceChanges) => boolean} predicate - the predicate function used to determine if a price change should be removed
     */
    public removeSaleItemPriceChange(saleItemUuid: string, predicate: (pc: SalesItemsPriceChanges) => boolean) {
        if (!this.currentSale) {
            return;
        }

        const targetSaleItem = this.currentSale.sale_items?.find((si) => si.uuid === saleItemUuid);

        if (!targetSaleItem) {
            return;
        }

        const salePlaceHolderIndexes = this.getNotDiscountablePlaceHoldersIndexes();
        const oldPriceChanges = (targetSaleItem.price_changes || []).filter((pc) => !salePlaceHolderIndexes.includes(pc.index));
        const newPriceChanges = oldPriceChanges.filter((pc) => !predicate(pc));

        //Update details if there are changes
        if (newPriceChanges.length !== oldPriceChanges.length) {
            this.editSaleItems([{ saleItem: targetSaleItem, newData: { price_changes: newPriceChanges } }]);
        }
    }

    /**
     * Edits sale price changes with new data.
     *
     * @param {(pc: SalesPriceChanges) => boolean} predicate - the predicate that changes the sale price changes. Must return true if changes are made
     * @returns {void}
     */
    public editSalePriceChanges(predicate: (pc: SalesPriceChanges) => boolean) {
        if (!this.currentSale) {
            return;
        }

        const newSalePriceChanges = structuredClone(this.currentSale.price_changes || []);

        const hasChanges = newSalePriceChanges.reduce((acc, pc) => predicate(pc) || acc, false);

        if (hasChanges) {
            this.updateSaleDetails({ price_changes: newSalePriceChanges });
        }
    }

    /**
     * Edits sale item price changes with new data.
     *
     * @param {string} saleItemUuid - the target sale item uuid
     * @param {(pc: SalesItemsPriceChanges) => boolean} predicate - the predicate that changes the sale price changes. Must return true if changes are made
     * @returns {void}
     */
    public editSaleItemPriceChanges(saleItemUuid: string, predicate: (pc: SalesItemsPriceChanges) => boolean) {
        if (!this.currentSale) {
            return;
        }

        const targetSaleItem = this.currentSale.sale_items?.find((si) => si.uuid === saleItemUuid);

        if (!targetSaleItem) {
            return;
        }

        const newSaleItemPriceChanges = structuredClone(targetSaleItem.price_changes || []).filter((pc) => !this.getNotDiscountablePlaceHoldersIndexes().includes(pc.index));
        const hasChanges = newSaleItemPriceChanges.reduce((acc, pc) => predicate(pc) || acc, false);

        if (hasChanges) {
            this.editSaleItems([{
                saleItem: targetSaleItem,
                newData: { price_changes: newSaleItemPriceChanges }
            }]);
        }
    }

    /**
     * Adds a sale item to the current sale.
     *
     * @param {SalesItems} saleItem - The sale item to be added.
     */
    public addSaleItem(saleItem: SalesItems) {
        if (!this.currentSale) {
            return;
        }

        const { uuid, quantity, ...saleItemDetails } = saleItem;

        this.executeTransaction((transaction, operator) => {
            transaction.sale_items_transactions!.push({
                sale_item_uuid: uuid,
                quantity_difference: quantity,
                sale_item_details: {
                    ...saleItemDetails,
                    lastupdate_at: new Date().toISOString(),
                    lastupdate_by: operator.id
                }
            });
        });
    }

    /**
     * Edits a sale item with new data.
     *
     * @param {SalesItems} saleItem - The sale item to be edited.
     * @param {Partial<SalesItems>} newData - The new data to update the sale item with.
     * @return {SalesItems | undefined} - The edited sale item, or the untouched sale item if there is no current sale open.
     */
    public editSaleItems(updateData: EditSaleItemUpdate[]) {
        if (!this.currentSale || !this.pristineSale) {
            return;
        }

        this.executeTransaction((transaction, opearator) => {
            for (const { saleItem, newData } of updateData) {
                const saleItemTransaction = this.getSaleItemTransaction(transaction, saleItem);
                const pristineSaleItem = this.pristineSale!.sale_items?.find((pristineSaleItem) => pristineSaleItem.uuid === saleItem.uuid);

                if (newData.quantity) {
                    // Update the quantity difference using the pristine sale item quantity (or 0 if not in the pristine sale)
                    const pristineSaleItemQuantity = pristineSaleItem?.quantity || 0;

                    saleItemTransaction.quantity_difference = newData.quantity - pristineSaleItemQuantity;
                }

                //Remove quantity from update data as we have it in the quantity_difference
                const { quantity, variations, ingredients, ...updateData } = newData;

                //Update variations
                if (variations) {
                    const newVariations = keyBy(variations, this.saleTransactionUtils.getVariationKey, { firstMatchOnly: true });
                    const pristineVariations = keyBy(structuredClone(pristineSaleItem?.variations || []), this.saleTransactionUtils.getVariationKey, { firstMatchOnly: true });
                    const updatedVariations: SalesItemsVariations[] = [];

                    //Remove deleted variations
                    for (const key in pristineVariations) {
                        const variation = pristineVariations[key];

                        if (!newVariations[key]) {
                            updatedVariations.push(Object.assign(variation, { deleted_at: new Date().toISOString() }));
                        }
                    }

                    //Add new variations
                    for (const key in newVariations) {
                        if (
                            !pristineVariations[key] || // New variation
                            (newVariations[key].deleted_at !== pristineVariations[key].deleted_at) // Restoring a deleted variation
                        ) {
                            updatedVariations.push(structuredClone(newVariations[key]));
                        }
                    }

                    if (updatedVariations.length) {
                        Object.assign(updateData, { variations: updatedVariations });
                    } else {
                        delete saleItemTransaction.sale_item_details.variations;
                    }
                }

                //Update ingredients
                if (ingredients) {
                    const newIngredients = keyBy(ingredients, this.saleTransactionUtils.getIngredientKey, { firstMatchOnly: true });
                    const pristineIngredients = keyBy(structuredClone(pristineSaleItem?.ingredients || []), this.saleTransactionUtils.getIngredientKey, { firstMatchOnly: true });

                    const updatedIngredients: SalesItemsIngredients[] = [];

                    //Remove deleted ingredients
                    for (const key in pristineIngredients) {
                        const ingredient = pristineIngredients[key];

                        if (!newIngredients[key]) {
                            updatedIngredients.push(Object.assign(ingredient, { quantity: 0 }));
                        }
                    }

                    //Add new/updated ingredients
                    for (const key in newIngredients) {
                        if (!pristineIngredients[key] || newIngredients[key].quantity !== pristineIngredients[key].quantity) {
                            updatedIngredients.push(structuredClone(newIngredients[key]));
                        }
                    }

                    Object.assign(updateData, { ingredients: updatedIngredients });
                }

                Object.assign(updateData, {
                    lastupdate_at: new Date().toISOString(),
                    lastupdate_by: opearator.id
                });

                Object.assign(saleItemTransaction.sale_item_details, this.nullishToNull(updateData));

                //Clean up sale item details with only the changed fields
                saleItemTransaction.sale_item_details = pickBy(saleItemTransaction.sale_item_details, (val, key) => val !== pristineSaleItem?.[key as keyof SalesItems])
            }
        });
    }

    /**
     * Removes sale items based on the following rules:
     * - If the item is in the pristine sale, create a sale item transaction 
     *   (or find an existing one in the current transaction) with the 
     *   'deleted_at' field set.
     * - If the item is not in the pristine sale, simply remove the sale item 
     *   from the current transaction (if it exists).
     *
     * @param {SalesItems} saleItems - the sale items to be removed
     */
    public removeSaleItems(saleItems: SalesItems | SalesItems[]) {
        if (!this.currentSale || !this.pristineSale) {
            return;
        }

        const itemsToRemove = Array.isArray(saleItems) ? saleItems : [saleItems];

        this.executeTransaction((transaction) => {
            for (const saleItem of itemsToRemove) {
                const pristineSaleItem = this.pristineSale!.sale_items?.find((pristineSaleItem) => pristineSaleItem.uuid === saleItem.uuid);

                if (pristineSaleItem) {
                    const saleItemTransaction = this.getSaleItemTransaction(transaction, saleItem);

                    Object.assign(saleItemTransaction, {
                        quantity_difference: -pristineSaleItem.quantity
                    });
                } else {
                    transaction.sale_items_transactions = transaction.sale_items_transactions?.filter((saleItemTransaction) => saleItemTransaction.sale_item_uuid !== saleItem.uuid) || [];
                }
            }
        });
    }
}
