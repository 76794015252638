import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').controller('RoomsViewCtrl', ["checkManager", "$scope", "$state", "$stateParams", "rooms", "orders", function(checkManager, $scope, $state, $stateParams, rooms, orders) {
    $scope.action = "view";
    $scope.id = _.toInteger($stateParams.id);

    var index = _.findIndex(rooms, { id: $scope.id });

    if (index === -1) {
        index = 0;
    }

    $scope.parentobj.currentTab = index;

    $scope.tables = _.get(rooms, [index , 'tables']) || [];
    $scope.floor = _.get(rooms, [index , 'floor']);
    $scope.orders = orders.byTable;

    angular.copy({}, $scope.topbar_context);

    _.assign($scope.topbar_context, {
        viewmode: true,
        rightmenu: true,
        currentRoom: rooms[index],
        orders: orders.all,
        rooms: rooms,
        mapZoom: _.toNumber(checkManager.getPreference("rooms.zoom_factor")) || 1
    });

    $scope.room = rooms[index] ? {
        id: rooms[index].id,
        width: rooms[index].width * 80,
        height: rooms[index].height * 80,
        name: rooms[index].name
    } : {};

    $scope.roomStyle = rooms[index] ? {
        width: $scope.room.width + 'px',
        height: $scope.room.height + 'px',
        background: 'url(assets/images/tables/' + $scope.floor + '.png) repeat'
    } : {};

    $scope.getRoomStyle = function() {
        var roomStyle = _.clone($scope.roomStyle);
        if($scope.topbar_context.mapZoom !== 1) {
            _.assign(roomStyle, {
                'transform': "scale(" + $scope.topbar_context.mapZoom + ")",
                'transform-origin': "top left"
            });
        }
        return roomStyle;
    };

    $scope.newTakeAwayOrder = function() {
        $state.go('app.orders.content', {
            type: 'take_away'
        });
    };

    $scope.newDeliveryOrder = function() {
        $state.go('app.orders.content', {
            type: 'delivery'
        });
    };

    $scope.parentobj.goToRoom = function(id) {
        $state.go("app.tables.rooms-view", {
            id: id
        });
    };
}]);
