import { CommonModule } from "@angular/common";
import { Component, ElementRef, Injectable, ViewChild, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Loader } from "@googlemaps/js-api-loader";
import { TranslateModule } from "@ngx-translate/core";
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { restManager } from "app/ajs-upgraded-providers";
import { lastValueFrom } from "rxjs";
import { AddressType } from "src/app/features/settings/settings-alvolo/model/general-form-fields";
const { apiKeys } = require('app/tilby.properties.json');

type TilbyDialogData = {
    titleToolbar: string
    address?: AddressType,
    latitude?: number,
    longitude?: number,
};

@Component({
    selector: "app-map-coordinates-dialog",
    templateUrl: "./map-coordinates-dialog.component.html",
    styleUrls: ["./map-coordinates-dialog.component.scss"],
    standalone: true,
    imports: [CommonModule, TilbyDialogToolbarComponent, TilbyDialogContentComponent, TranslateModule],
})
export class MapCoordinatesDialogComponent {
    protected readonly data: TilbyDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly restManagerService = inject(restManager);

    @ViewChild('searchTextField') inputRef?: ElementRef<HTMLInputElement>;
    @ViewChild('map') mapRef?: ElementRef<HTMLDivElement>;

    loader = new Loader({
        apiKey: apiKeys.googleMaps,
        version: "weekly",
        libraries: ["places"]
    });

    defaultBounds!: google.maps.LatLngBounds;
    searchBox?: google.maps.places.SearchBox;
    latitude!: number | undefined;
    longitude!: number | undefined;;
    place!: google.maps.places.PlaceResult | undefined;
    markers: google.maps.Marker[] = [];
    
    ngAfterViewInit() {    
        this.initAutocomplete();
    }

    async initAutocomplete() {
        await this.loader.load();

        this.defaultBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(+this.data.address?.latitude! || 45.070312, +this.data.address?.longitude! || 7.6868565),
        );

        this.searchBox = this.inputRef ? new google.maps.places.SearchBox(this.inputRef.nativeElement, { bounds: this.defaultBounds }) : undefined;

        const map = new google.maps.Map(
            this.mapRef!.nativeElement,
            {
                center: { lat: this.data.latitude || 45.070312, lng: this.data.longitude! || 7.6868565 },
                zoom: 16,
                streetViewControl: false,
                mapTypeId: "roadmap",
            }
        );

        map.addListener("bounds_changed", () => {
            this.searchBox?.setBounds(map.getBounds() as google.maps.LatLngBounds);
        });

        this.markers.push(
            new google.maps.Marker({
                map,
                draggable: true,
                position: { lat: this.data.latitude || 45.070312, lng: this.data.longitude || 7.6868565 },
            })
        );

        //mark listener
        this.markers[0].addListener('dragend', () => {
            const position = this.markers[0].getPosition();
            this.latitude = position?.lat();
            this.longitude = position?.lng();
        });

        //searchbox listener
        this.searchBox?.addListener("places_changed", () => {
            const places = this.searchBox?.getPlaces();

            this.place = places?.[0];
            this.latitude = places?.[0].geometry?.location?.lat();
            this.longitude = places?.[0].geometry?.location?.lng()
            
            if (places?.length == 0) {
                return;
            }

            this.markers.forEach((marker) => {
                marker.setMap(null);
            });
            this.markers = [];

            const bounds = new google.maps.LatLngBounds();

            places?.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
            }

            this.markers.push(
                new google.maps.Marker({
                    map,
                    draggable: true,
                    position: place.geometry.location,
                })
            );

            this.markers[0].addListener('dragend', () => {
                const position = this.markers[0].getPosition();
                this.latitude = position?.lat();
                this.longitude = position?.lng();
            });

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
            map.fitBounds(bounds);
        });
    }

    ngOnDestroy() {
        this.markers.forEach((marker) => {
            marker.setMap(null);
        });

        this.markers = [];
    }
    

    protected confirm() {
        this.matDialogRef.close({place: this.place, latitude: this.latitude, longitude: this.longitude});
    }
}


@Injectable({
    providedIn: "root",
})
export class MapCoordinatesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(titleToolbar: string, address?: AddressType, latitude?: number, longitude?: number, place_id?: string) {
        const data: TilbyDialogData = { titleToolbar, address, latitude, longitude };
        const config: NonNullableConfigData<TilbyDialogData> = {
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(MapCoordinatesDialogComponent, config).afterClosed()
        ).then(({place, latitude, longitude}) => {
            return {
                place,
                latitude,
                longitude
            }
        });
    }
}
