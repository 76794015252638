import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').factory('linkTableToOrder', linkTableToOrder);

linkTableToOrder.$inject = ["$mdDialog", "ActiveOrder", "entityManager"];

function linkTableToOrder($mdDialog, ActiveOrder, entityManager) {
    linkTableToOrderController.$inject = ["$scope", "$mdDialog", "rooms"];

    function linkTableToOrderController($scope, $mdDialog, rooms) {
        const getFreeTables = async () => {
            let orders = await entityManager.orders.fetchCollectionOffline({ status_in: ['open', 'checkout'] });
            let busyTables = _.chain(orders).map('table_id').uniq().filter((val) => (!_.isNil(val))).value();

            $scope.rooms = rooms.map((room) => {
                return _.chain(room)
                    .clone()
                    .assign({
                        tables: room.tables.filter((table) => (($scope.selectedTable === table.id) || (table.order_type === 'multiple') || (!busyTables.includes(table.id))))
                    })
                    .value();
            });
        };

        if (ActiveOrder.isActiveOrder() && ActiveOrder.currentOrder.table_id) {
            $scope.selectedTable = ActiveOrder.currentOrder.table_id;
        }

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.currentRoomHasTables = () => !_.isEmpty($scope.selectedRoom?.tables);

        $scope.selectTable = function(table) {
            if (ActiveOrder.isActiveOrder() && table.id !== ActiveOrder.currentOrder.table_id) {
                $mdDialog.hide({
                    room_id: $scope.selectedRoom.id,
                    room_name: $scope.selectedRoom.name,
                    table_id: table.id,
                    table_name: table.name
                });
            }
        };

        $scope.freeTable = function() {
            if (ActiveOrder.isActiveOrder() && ActiveOrder.currentOrder.table_id) {
                $mdDialog.hide();
            }
        };

        $scope.goToRoom = function(id) {
            $scope.selectedRoomIndex = _.findIndex($scope.rooms, { id: id });
            $scope.selectedRoom = $scope.rooms[$scope.selectedRoomIndex];
        };

        getFreeTables().finally(() => {
            $scope.selectedRoomIndex = $scope.selectedTable ? _.findIndex($scope.rooms, { id: $scope.selectedTable }) : 0;
            $scope.selectedRoom = $scope.rooms[$scope.selectedRoomIndex];
        });

        $scope.$on("storage-updated:orders", function() {
            getFreeTables().finally(() => {
                $scope.selectedRoom = $scope.rooms[$scope.selectedRoomIndex];
            });
        });
    }

    const linkTableToOrder = {
        show: function() {
            return $mdDialog.show({
                controller: linkTableToOrderController,
                template: require('./link-table-to-order.html'),
                resolve: {
                    rooms: ["entityManager", (entityManager) => entityManager.rooms.fetchCollectionOffline() ],
                }
            });
        }
    };

    return linkTableToOrder;
}