import * as angular from 'angular';

export type LeanPMSTax = {
    id: number,
    description: string,
    value: number,
    active: boolean,
    erp_code: string,
    external_id: string | null
}

export type LeanPMSCashTransactionType = {
    id: number,
    name: string
    category_code: string
}

export type LeanPMSProduct = {
    id: number,
    chain_product: string,
    hotel: number,
    name: string,
    name_translation: string,
    description: string,
    category_name: string,
    substatistictype: number,
    active: boolean,
    image: string | null,
    price: number,
    tax: number,
    tax_id: number,
    currency: string
}

export type LeanPMSChainProduct = {
    id: number,
    name: string,
    name_translation: string,
    description: string,
    category_name: string,
    active: boolean,
    image: string | null
    board_item_type: number
}

export type LeanPMSHotel = {
    id: number,
    name: string,
    hotel_code: string,
    description: string,
    address: string,
    city: string,
    province: string,
    country: number,
    postal_code: string,
    phone: string,
    email: string,
    latitude: number | null,
    longitude: number | null,
    chain_name: string,
    chain_cif: string,
    created_at: string,
    updated_at: string,
    bill_initials: string,
    production_date: string,
    cash_closed_datetime: string
}

type LeanPMSPaginatedResponse<T> = {
    count: number,
    next: string,
    previous: string,
    results: T[]
}

export type LeanPMSReservation = {
    id: number,
    reference: number,
    voucher: string,
    hotel: number,
    channel: string,
    created_at: string,
    updated_at: string,
    group: null,
    group_name: null,
    extras: LeanPMSReservationExtra[],
    guests: LeanPMSReservationGuest[],
    main_guest: LeanPMSReservationGuest,
    status: string,
    date_from: string,
    date_to: string,
    room: string,
    room_type: string,
    room_type_id: number,
    assigned_room_type: string,
    assigned_room_type_id: number,
    adults: number,
    children: number,
    babies: number,
    signature: null,
    language: number,
    contact_name: string,
    contact_email: string,
    contact_phone: string,
    cancelled_at: null,
    keyweft_set: any[]
    allow_minibar_charges: boolean,
    email_consent: null,
    sms_consent: null,
    contact_surname: string,
    currency: string,
    room_status: string,
    key_code: string,
    external_reference: null,
    linked_reservation: null,
    country_nationality: number,
    country_nationality_code: string,
    accepts_pos_charge: boolean,
    provisional: boolean
}

export type LeanPMSReservationExtra = {
    extra_id: number,
    day: string,
    board_type: string,
    rate: string,
    is_included: boolean,
    consumption_hour: string | null
}

type LeanPMSReservationGuest = {
    id: number,
    updated_at: string,
    created_at: string,
    identification: string,
    name: string,
    surname: string,
    sex: string,
    phone: string,
    email: string,
    address: string,
    postal_code: string,
    country: number,
    birthdate: string,
    migration_external_reference: string | null,
    language: number,
    province: string | null,
    city: string,
    issue_date: string,
    expiration_date: string,
    document_type: string,
    document_issue_country: number,
    email_consent: boolean,
    sms_consent: boolean | null,
    is_e_client: boolean,
    is_vip: boolean,
    third_party_external_id: string | null,
    is_deleted: boolean,
    date_country_arrival: string | null,
    country_alfa3: string,
    document_issue_country_alfa3: string,
    company_id: number | null,
    category: number,
    categories: any[],
    language_code: string,
    bbus_id: number | null,
    customer_id: number
}

export type LeanPMSGroupReservation = {
    id: number,
    name: string
    contact_name: string,
    contact_email: string,
    contact_phone: string
    reservations: LeanPMSReservation[]
}

export type LeanPMSCharge = ({ reservation: number } | { reservation_group: number }) & {
    chain_product: string,
    value: number,
    date: string,
    description: string,
    quantity: number,
    hotel: number,
    tax: number,
    is_billable: boolean
}

export class LeanPMSApiService {
    constructor(
        private restManager: any
    ) {
    }

    /**
     * Sets up the login credentials for a specific hostname.
     *
     * @param {string} hostname - The hostname for which the login credentials are being set up.
     * @param {string} username - The username for the login credentials.
     * @param {string} password - The password for the login credentials.
     * @return {Promise} - The response from the server after setting up the login credentials.
     */
    public setupLogin(hostname: string, username: string, password: string) {
        return this.restManager.post('lean_pms/setup_login', { hostname: hostname, username: username, password: password });
    }

    /**
     * Sets up a hotel with the given hotel ID.
     *
     * @param {string} hotelId - The ID of the hotel to be set up.
     * @return {Promise} A promise that resolves with the result of the setup.
     */
    public setupHotel(hotelId: number) {
        return this.restManager.post('lean_pms/setup_hotel', { hotel_id: hotelId });
    }

    /**
     * Retrieves a list of hotels from the API.
     *
     * @return {Promise} A promise that resolves to the list of hotels.
     */
    public getHotels(): Promise<LeanPMSHotel[]> {
        return this.restManager.getList('lean_pms/v2/hotels/');
    }

    /**
     * Retrieves the reservations for a specific hotel.
     *
     * @param {string} hotelId - The ID of the hotel.
     * @return {Promise} A promise that resolves to an array of reservations.
     */
    public getReservations(hotelId: number, reference?: number): Promise<LeanPMSPaginatedResponse<LeanPMSReservation>> {
        return this.restManager.getList('lean_pms/v2/reservations/', { status: 'Checkin', hotel: hotelId, reference: reference });
    }

    /**
     * Retrieves a list of group reservations for a specific hotel.
     *
     * @param {string} hotelId - The ID of the hotel.
     * @param {number} id - (optional) The ID of the group reservation.
     * @return {Promise<LeanPMSPaginatedResponse<LeanPMSGroupReservation>>} A promise that resolves to a paginated response of group reservations.
     */
    public getGroupReservations(hotelId: number, id?: number): Promise<LeanPMSPaginatedResponse<LeanPMSGroupReservation>> {
        return this.restManager.getList('lean_pms/v2/groupreservations/', { hotel: hotelId, id: id });
    }

    /**
     * Retrieves the list of products for a specific hotel.
     *
     * @param {string} hotelId - The ID of the hotel.
     * @return {Promise} A promise that resolves to the list of products.
     */
    public getProducts(hotelId: number): Promise<LeanPMSProduct[]> {
        return this.restManager.getList('lean_pms/v2/products/', { hotel: hotelId });
    }

    /**
     * Retrieves the taxes from the API.
     *
     * @return {Promise} A promise that resolves to the list of taxes.
     */
    public getTaxes(): Promise<LeanPMSTax[]> {
        return this.restManager.getList('lean_pms/v2/taxes/');
    }

    /**
     * Creates charges.
     *
     * @param {LeanPMSCharge[]} charges - The charges to be created.
     * @return {Promise} - A promise that resolves with the created charges.
     */
    public createCharges(charges: LeanPMSCharge[]) {
        return this.restManager.post('lean_pms/v2/charges/', charges);
    }

    /**
     * Performs the daily closing
     *
     * @param {string} dateFrom - The starting date for the daily closing.
     * @param {string} dateTo - The ending date for the daily closing.
     * @param {number} cashVerification - The cash verification amount.
     * @return {Promise<any>} A Promise that resolves to the response from the server.
     */
    public dailyClosing(dateFrom: string, dateTo: string, cashVerification: number) {
        return this.restManager.post('lean_pms/daily_closing', { from_date: dateFrom, to_date: dateTo, cash_verification: cashVerification });
    }

    /**
     * Retrieves the list of chain products from the lean_pms API.
     *
     * @return {Promise<any>} A Promise that resolves with the list of chain products.
     */
    public getChainProducts(): Promise<LeanPMSPaginatedResponse<LeanPMSChainProduct>> {
        return this.restManager.getList('lean_pms/v2/chain-products/?active=1');
    }

    /**
     * Retrieves the list of cash transaction types from the API.
     *
     * @return {Promise<any>} A promise that resolves to the list of cash transaction types.
     */
    public getCashTransactionsTypes(): Promise<LeanPMSCashTransactionType> {
        return this.restManager.getList('lean_pms/v2/cashtransactionstypes/?usable_from_api=True');
    }

    /**
     * Retrieves the list of reservations by PMS reference from the lean_pms API.
     *
     * @param {number} hotelID - The hotel ID.
     * @return {Promise<LeanPMSPaginatedResponse<LeanPMSReservation>>} A promise that resolves to an array of reservations.
     */
    public getReservationsByHotelID(hotelID: number): Promise<LeanPMSPaginatedResponse<LeanPMSReservation>> {
        return this.restManager.getList('lean_pms/v2/reservations/?hotel=' + hotelID);
    }

    /**
     * Setup webhooks
     *
     * @return {Promise} A promise that resolves with the result of the setup.
     */
    public setupWebhooks(): Promise<any> {
        return this.restManager.post('lean/webhooks/setup');
    }

    /**
     * Get Notes
     *
     * @return {Promise} A promise that resolves with the result of the information of notes.
     */
    public getNotes(hotel: number, reference: number): Promise<LeanPMSPaginatedResponse<any>> {
        return this.restManager.getList(`lean_pms/v2/statistics/reservations/?hotel=${hotel}&status=Checkin&reference=${reference}`);
    }

     /**
     * Disconnect webhooks
     *
     * @return {Promise} A promise that resolves with the result of the reset.
     */
    public disconnect(): Promise<any> {
        return this.restManager.post('lean/webhooks/reset');
    }
}

LeanPMSApiService.$inject = ['restManager'];

angular.module('application').service('leanPMSApi', LeanPMSApiService);
