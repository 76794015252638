import * as angular from 'angular';
import * as async from 'async';
import * as IpSubnetCalculator from 'ip-subnet-calculator';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { stringToLines } from 'src/app/shared/string-utils';
import { EpsonBarcode, EpsonFont, EpsonHRI } from 'src/app/shared/model/nf-thermal-printer.model';

angular.module('printers').factory('NonFiscalDriver', NonFiscalDriver);
NonFiscalDriver.$inject = ["$rootScope", "$translate", "$http", "$filter", "$window", "errorsLogger", "DocumentBuilder", "EscPosDriver", "EPosDriver", "fiscalUtils", "orderUtils", "util", "entityManager", "checkManager", "KDSManager"];

function NonFiscalDriver($rootScope, $translate, $http, $filter, $window, errorsLogger, DocumentBuilder, EscPosDriver, EPosDriver, fiscalUtils, orderUtils, util, entityManager, checkManager, KDSManager) {
    var scope = {};

    // Print border
    var newLine = function(times) {
        return _.repeat('\n\r', times || 1);
    };

    var border = function(length, char) {
        char = char || "=";
        length = Math.floor((length || scope.options.columns) / 2);

        return _.repeat(char, length) + newLine();
    };

    var setSize = function(drvObj, size) {
        drvObj.addTextDouble((size & 16) ? true : false, (size & 1) ? true : false);
    };

    var validateIPaddress = function validateIPaddress(ipaddress) {
        return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));
    };

    var intToIP = function intToIP(int) {
        return [((int >> 24) & 255), ((int >> 16) & 255), ((int >> 8) & 255), (int & 255)].join('.');
    };

    const logEvent = (...args) => {
        errorsLogger.debug('[NonFiscalDriver]', ...args);
    };

    const getDriverInfo = (printer) => {
        let printerDriver, port, config;

        switch (printer.driver) {
            case 'escpos':
                printerDriver = EscPosDriver;
                port = 9100;
                config = _.pick(printer, ['codepage_mapping', 'connection_type', 'line_spacing']);
            break;
            case 'epos':
                printerDriver = EPosDriver;
                port = 80;
                config = _.pick(printer, ['line_spacing']);
            break;
        }

        return printerDriver ? { driverObj: printerDriver, port: port, configuration: config } : undefined;
    };

    var setAlign = function(drvObj, align) {
        switch (align) {
            case 'center':
                drvObj.addTextAlign(drvObj.ALIGN_CENTER);
                break;
            case 'left':
                drvObj.addTextAlign(drvObj.ALIGN_LEFT);
                break;
            case 'right':
                drvObj.addTextAlign(drvObj.ALIGN_RIGHT);
                break;
            default:
                drvObj.addTextAlign(drvObj.ALIGN_CENTER);
        }
    };

    var setReversed = function(drvObj, mode) {
        drvObj.addTextStyle(mode, undefined, undefined, undefined);
    };

    var setUnderline = function(drvObj, mode) {
        drvObj.addTextStyle(undefined, mode, undefined, undefined);
    };

    var setBold = function(drvObj, mode) {
        drvObj.addTextStyle(undefined, undefined, mode, undefined);
    };

    /**
     * calculateDifferences
     */
    var calculateDifferences = function(oldOrder, newOrder) {
        oldOrder.order_items = orderUtils.getCleanOrderItems(oldOrder);
        newOrder.order_items = orderUtils.getCleanOrderItems(newOrder);

        // Get oldOrder order-items ids
        var oldOrderOrderItemsIds = _.map(oldOrder.order_items, 'uuid');
        var newOrderOrderItemsIds = _.map(newOrder.order_items, 'uuid');

        // Additions
        var additionsIds = _.difference(newOrderOrderItemsIds, oldOrderOrderItemsIds);

        // Removals
        var removalsIds = _.difference(oldOrderOrderItemsIds, newOrderOrderItemsIds);

        // Intersection
        var intersectionIds = _.intersection(oldOrderOrderItemsIds, newOrderOrderItemsIds);

        var variationOrder = _.pick(newOrder, ['type', 'deliver_at', 'open_at', 'name', 'order_number', 'room_id', 'room_name', 'table_name', 'operator_name', 'covers', 'order_customer', 'notes']);

        Object.assign(variationOrder, {
            variation: true,
            variation_items: []
        });

        var oldOrderItemsMap = _.keyBy(oldOrder.order_items, 'uuid');
        var newOrderItemsMap = _.keyBy(newOrder.order_items, 'uuid');

        // Additions orderItems
        _.forEach(additionsIds, function(additionId) {
            var addedOrderItem = _.get(newOrderItemsMap, additionId);
            addedOrderItem.type = 'addition';
            variationOrder.variation_items.push(addedOrderItem);
        });

        // Removals orderItems
        _.forEach(removalsIds, function(removalId) {
            var removedOrderItem = _.get(oldOrderItemsMap, removalId);
            removedOrderItem.type = 'removal';
            variationOrder.variation_items.push(removedOrderItem);
        });

        /* Intersection analysis:
            - check for quantity variations
            - check for property variations (contents & presence) */

        _.forEach(intersectionIds, function(intersectionId) {
            var oldOrderItem = _.get(oldOrderItemsMap, intersectionId);
            var newOrderItem = _.get(newOrderItemsMap, intersectionId);
            var diffOrderItem = _.cloneDeep(newOrderItem);

            if(!_.isEqual(oldOrderItem, newOrderItem)) {
                Object.assign(diffOrderItem, {
                    type: 'edit',
                    quantity_difference: (newOrderItem.quantity - oldOrderItem.quantity) || 0,
                    exit_difference: newOrderItem.exit !== oldOrderItem.exit,
                    half_portion_difference: newOrderItem.half_portion !== oldOrderItem.half_portion,
                    notes_difference: newOrderItem.notes !== oldOrderItem.notes,
                    variations_differences: !_.isEqual(oldOrderItem.variations, newOrderItem.variations),
                    ingredients_differences: !_.isEqual(oldOrderItem.ingredients, newOrderItem.ingredients)
                });

                // Finalize and push
                if(!_.isEmpty(diffOrderItem)) {
                    variationOrder.variation_items.push(diffOrderItem);
                }
            }

        });

        return variationOrder;
    };

    var groupItemsByPrinterOwnership = function(items, printer) {
        return _.groupBy(items, function(item) {
            if(item.printers[printer.id]) {
                return 'own';
            } else if(_.some(printer.other_printers, function(printerId) { return item.printers[printerId]; })) {
                return 'other';
            } else {
                return 'discard';
            }
        });
    };

    const isPrintersRoomOrder = function(printer, order) {
        const allowedRooms = new Set(printer.rooms);

        // if no rooms are allowed, allow all
        if(!allowedRooms.size) {
            return true;
        }

        if(order.room_id) {
            if(allowedRooms.has(order.room_id)) {
                return true;
            }
        } else {
            if((order.type === 'normal' || !order.type) && allowedRooms.has('no_rooms')) {
                return true;
            }
            if(order.type === 'take_away' && allowedRooms.has('take_away')) {
                return true;
            }
            if(order.type === 'delivery' && allowedRooms.has('delivery')) {
                return true;
            }
        }

        return false;
    };

    var buildShopHeader = function(drvObj, header) {
        var addLine = function(line) {
            drvObj.addText(line + newLine());
        };

        _.forEach(header, function(line, idx) {
            setAlign(drvObj, 'center');
            drvObj.addTextDouble(false, (!idx));
            addLine(line);
        });

        addLine("");
    };

    const settingsToCheck = [
        'order_number',
        'operator_name',
        'print_type',
        'date',
        'time',
        'room_name',
        'table_name',
        'covers',
        'delivery_info',
        'order_name',
        'customer_info',
        'order_notes',
        'order_categories',
        'total_pieces'
    ];

    var getHeaderFooterSettings = function(printer, settingsType) {
        const settings = {
            columns: printer.columns,
            settings_type: settingsType
        };
        
        //Initialize settings
        switch(settingsType) {
            case 'header':
                for(const settingName of settingsToCheck) {
                    const finalName = `header_${settingName}`;
                    if(['total_pieces'].includes(settingName)) {
                        settings[settingName] = printer[finalName] != null ? printer[finalName] : -1;
                    } else {
                        settings[settingName] = printer[finalName] != null ? printer[finalName] : printer['header_font_size_1'] != null ? printer['header_font_size_1'] : ['header_order_name', 'header_order_notes'].includes(finalName) ? 0 : 16;
                    }
                }
            break;
            case 'footer':
                for(const settingName of settingsToCheck) {
                    const finalName = `footer_${settingName}`;
                    settings[settingName] = printer[finalName] != null ? printer[finalName] : -1;
                }
            break;
            default: break;
        }

        return settings;
    };

    /**
     * buildOrderHeader
     */
    var buildOrderHeader = function(drvObj, printer, order, type) {
        var printerSettings = getHeaderFooterSettings(printer, type);
        var printDate = moment(order.printed_at);
        var headerData = [];

        if(printerSettings.order_categories !== -1) {
            headerData.push({ text: _(printer.categories).map('name').join(', ') + newLine(2), size: printerSettings.order_categories, align: 'center', bold: true });
        }

        if(printerSettings.order_number !== -1) {
            var orderNumberString =  order.order_number ? "#" + order.order_number : '';

            if(orderNumberString) {
                headerData.push({ text: orderNumberString, size: printerSettings.order_number });
            }
        }

        if(printerSettings.operator_name !== -1) {
            if(order.operator_name) {
                var opName = order.operator_name;

                if(printerSettings.operator_name > 0) {
                    opName += newLine();
                }

                headerData.push({ text: opName, size: printerSettings.operator_name });
            }
        }

        if(printerSettings.date !== -1) {
            var headerDate = printDate.format('L');

            if(printerSettings.operator_name === 0) {
                headerDate += newLine();
            }

            headerData.push({ text: headerDate, size: printerSettings.date });
        }

        if(printerSettings.time !== -1) {
            headerData.push({ text: printDate.format('LT'), size: printerSettings.time });
        }

        _.forEach(headerData, function(row) {
            if(!_.endsWith(row.text, newLine()) && row !== _.last(headerData)) {
                row.text += ' - ';
            }
        });

        if(!_.isEmpty(headerData)) {
            headerData.push({ text: newLine(2) });
        }

        if(printerSettings.print_type !== -1) {    
            if(order.variation) { // If order variation print
                headerData.push({ text: ' ' + scope.options.labels.variation + ' ' + newLine(2), size: printerSettings.print_type, bold: true, reversed: true });
            } else if(order.reprint) { // if reprint
                headerData.push({ text: ' ' + scope.options.labels.reprint_order + ' ' + newLine(2), size: printerSettings.print_type, bold: true, reversed: true });
            }
        }

        switch(order.type) {
            case 'normal':
                if(order.table_name && printerSettings.room_name !== -1) {
                    headerData.push({ text: order.room_name + newLine(), size: printerSettings.room_name });
                }

                if(order.table_name && printerSettings.table_name !== -1) {
                    headerData.push({ text: order.table_name + newLine(), size: printerSettings.table_name });
                }

                if(order.covers && printerSettings.covers !== -1) {
                    headerData.push({ text: order.covers + " " + scope.options.labels.covers + newLine(), size: printerSettings.covers });
                }
            break;
            case 'take_away':
            case 'delivery':
                if(printerSettings.delivery_info !== -1) {
                    var deliveryInfo = scope.options.labels[order.type] + newLine();

                    if((order.channel && order.channel !== 'pos') || order.external_id) {
                        deliveryInfo += (order.channel ? order.channel + ' ' : '') + (order.external_id ? '#' + order.external_id : '') + newLine();
                    }
    
                    var deliverAt = moment(order.deliver_at);
    
                    if(deliverAt.dayOfYear() === printDate.dayOfYear() && deliverAt.year() === printDate.year()) { //Same day
                        deliveryInfo += scope.options.labels.deliver_at + " " + deliverAt.format('LT') + newLine(2);
                    } else { //Different day
                        deliveryInfo += scope.options.labels.deliver_at_date + " " + deliverAt.format('L LT') + newLine(2);
                    }

                    headerData.push({ text: deliveryInfo, size: printerSettings.delivery_info });
                }
            break;
            default:
            break;
        }

        if(order.sale_notes) {
            headerData.push({ text: order.sale_notes + newLine(2), size: 0 });
        }

        _.forEach(headerData, function(row) {
            Object.assign(row, { bold: true, align: 'center' });
        });

        if(printerSettings.order_name !== -1) {
            headerData.push({ text: order.name + newLine(2), size: printerSettings.order_name, align: 'center' });
        }

        var orderCustomer = order.order_customer || {};
        var customerData = [];

        // Add customer (if exists)
        if(!_.isEmpty(orderCustomer) && printerSettings.customer_info !== -1) {
            customerData.push({ text: (order.type === 'delivery' ? scope.options.labels.customer_delivery : scope.options.labels.customer) });
            customerData.push({ text: " " + util.getCustomerCaption(orderCustomer), bold: true });

            if(orderCustomer.fidelity) {
                customerData.push({ text: "  " + scope.options.labels.fidelity + " " + orderCustomer.fidelity  });
            }

            if(orderCustomer.shipping_street) {
                customerData.push({ text: "  " + orderCustomer.shipping_street + " " + _.toString(orderCustomer.shipping_number) });
                customerData.push({ text: "  " + (orderCustomer.shipping_zip ? (orderCustomer.shipping_zip + " ") : "") + (orderCustomer.shipping_city ? orderCustomer.shipping_city + " " : "") + (orderCustomer.shipping_prov ? "(" + orderCustomer.shipping_prov + ")" : "") });
            }

            if(orderCustomer.phone) {
                customerData.push({ text: "  " + scope.options.labels.phone + " " + orderCustomer.phone });
            }

            if(orderCustomer.mobile) {
                customerData.push({ text: "  " + scope.options.labels.mobile + " " + orderCustomer.mobile });
            }

            if(orderCustomer.notes) {
                customerData.push({ text: "  " + scope.options.labels.customer_notes + " " + orderCustomer.notes.toUpperCase() });
            }

            for(const row of customerData) {
                Object.assign(row, {
                    text: row.text + newLine(),
                    size: printerSettings.customer_info
                });
            }

            headerData = _.concat(headerData, customerData);
        }

        if(!_.isEmpty(order.notes) && printerSettings.order_notes !== -1) {
            headerData.push({ text: border(printerSettings.columns, '='), size: 16 });
            headerData.push({ text: scope.options.labels.order_notes + " " + order.notes, size: printerSettings.order_notes });
        }

        if(printerSettings.total_pieces !== -1) {
            let totalQuantity = 0;

            if('variation_items' in order) {
                // Calculate total quantity considering only additions
                totalQuantity = order.variation_items.filter((item) => item.quantity_difference >= 0).reduce((total, item) => total + (item.quantity_difference || item.quantity || 0), 0);
            } else if('order_items' in order) {
                // Calculate total quantity considering the sum of the quantities of the whole order
                totalQuantity = order.order_items.reduce((total, item) => total + (item.quantity || 0), 0);
            }

            headerData.push({ text: border(printerSettings.columns, '='), size: 16 });
            headerData.push({ text: `${scope.options.labels.total_pieces} ${totalQuantity}${newLine()}`, size: printerSettings.total_pieces });
        }

        if(headerData.length) {
            headerData.unshift({ text: border(printerSettings.columns, '='), size: 16 });
        }

        headerData.push({ text: border(printerSettings.columns, '='), size: 16 });

        if(type === 'footer' && order.reprint_info) {
            headerData.push({ text: newLine(), size: 16 });
            headerData.push({ text: `${scope.options.labels.reprinted_at} ${moment().format('HH:mm DD/MM')}${newLine()}`, size: 16 });
            headerData.push({ text: `${scope.options.labels.reprint_source} ${order.reprint_info.reprint_source}${newLine()}`, size: 16 });
            headerData.push({ text: `${scope.options.labels.reprint_new} ${order.reprint_info.reprint_new}${newLine()}`, size: 16 });
        }

        for(const row of headerData) {
            setSize(drvObj, row.size);
            setBold(drvObj, row.bold || false);
            setAlign(drvObj, row.align || 'left');
            setReversed(drvObj, row.reversed || false);
            drvObj.addText(row.text);
        }
    };

    function buildVariation(drvObj, order, printer, callback) {
        const isOwnRoomOrder = isPrintersRoomOrder(printer, order);
        const skipContent = (!!order.goExit && !!scope.options.goExit_skip_content);

        if(!isOwnRoomOrder) {
            callback('EMPTY_ORDER');
            return;
        }

        // Filter order_items which have category_id contained in printersIds
        const variationsToPrint = groupItemsByPrinterOwnership(order.variation_items, printer);

        // Check if empty
        if (!variationsToPrint.own) {
            callback('EMPTY_ORDER');
            return;
        }

        const itemFontSize = printer.item_font_size ?? 17;
        const itemSpacing = printer.item_spacing ?? 1;
        const itemHideBorder = printer.item_hide_border ?? false;
        const itemHideBrackets = printer.item_hide_brackets ?? false;
        const itemsGroupBy = printer.items_group_by ?? 'exit';
        const splitOrders = printer.split_orders_by_exit ?? false;
        const addPrices = printer.add_prices_to_order ?? false;
        const variationFontSize = printer.variation_font_size ?? 16;
        const itemsAlign = printer.items_align ?? 'center';

        const topSpace = _.repeat(newLine(), (printer.top_space || scope.options.top_space));
        const bottomSpace = _.repeat(newLine(), (printer.bottom_space || scope.options.bottom_space));

        let handleItemDetails = (item) => {
            // INGREDIENTS (ADDING/REMOVAL)
            const addedOrRemoved = _.groupBy(item.ingredients, 'type');
            const underLineIngredients = item.ingredients_differences ? true : false;
            const underlineVariations = item.variations_differences ? true : false;
            const underlineNotes = item.notes_difference ? true : false;

            for (let addremType in addedOrRemoved) {
                setAlign(drvObj, 'left');
                setSize(drvObj, variationFontSize);
                setUnderline(drvObj, underLineIngredients);

                const typeLabel = scope.options.labels[addremType] || "";

                for (let ingredient of addedOrRemoved[addremType]) {
                    switch(addremType) {
                        case 'added':
                            drvObj.addText(`${typeLabel} ${ingredient.quantity !== 1 ? (ingredient.quantity + "x ") : ""}${ingredient.name}` + newLine());
                            break;
                        case 'removed':
                            drvObj.addText(`${typeLabel} ${ingredient.name}` + newLine());
                            break;
                    }
                }

                setUnderline(drvObj, false);
            }

            // VARIATIONS
            if (!Array.isArray(item.variations)) {
                item.variations = [];
            }

            for (let variation of item.variations) {
                setAlign(drvObj, 'left');
                setSize(drvObj, variationFontSize);

                drvObj.addText(`${variation.name}: `);
                setUnderline(drvObj, underlineVariations);
                drvObj.addText(variation.value);
                setUnderline(drvObj, false);
                drvObj.addText(newLine());
            }

            // NOTES
            if(item.notes) {
                setAlign(drvObj, 'left');
                setSize(drvObj, variationFontSize);

                drvObj.addText(`${scope.options.labels.notes}: `);
                setUnderline(drvObj, underlineNotes);
                drvObj.addText(item.notes);
                setUnderline(drvObj, false);
                drvObj.addText(newLine());
            }

            setSize(drvObj, 16);
            
            if(!itemHideBorder) {
                setAlign(drvObj, 'left');
                drvObj.addText(border(printer.columns, '-'));
            }
        };

        let addItemRow = (item, showSign) => {
            setAlign(drvObj, itemsAlign);
            setSize(drvObj, itemFontSize);

            const quantityBrackets = itemHideBrackets ? '' : '[';
            const quantitySign = (item.quantity_difference > 0 && showSign) ? "+" : "";
            const quantity = item.quantity_difference || item.quantity;
            const quantityBracketsEnd = itemHideBrackets ? '' : ']';
            const halfPortion = item.half_portion ? "1/2 " : "";
            const name = _.toUpper(item.order_name || item.name);
            const priceTag = addPrices ? ` ${$filter('sclCurrency')(item.final_price)}` : '';

            drvObj.addText(`${quantityBrackets}${quantitySign}${quantity}${quantityBracketsEnd} ${halfPortion}${name}${priceTag}${newLine()}`);

            handleItemDetails(item);
            drvObj.addText(_.repeat(newLine(), itemSpacing));
        };

        // Compile order prices if necessary
        if(addPrices) {
            const refOrder = structuredClone({ order_items: order.variation_items || [] });

            for(const item of refOrder.order_items) {
                item.quantity = item.quantity_difference || item.quantity;
            }

            orderUtils.calculateOrderPrices(refOrder);

            const orderItemsByUuid = _.keyBy(refOrder.order_items, 'uuid');

            for (const item of order.variation_items) {
                item.final_price = orderItemsByUuid[item.uuid].final_price;
            }
        }

        const groupedVariations = _.groupBy(variationsToPrint.own, itemsGroupBy);
        const groups = Object.keys(groupedVariations);
        const lastGroupIndex = groups.length - 1;

        for (let i = 0; i < groups.length; i++) {
            const groupIndex = groups[i];
            const itemGroup = groupedVariations[groupIndex];

            //Print header if it's the first group or the splitOrders is enabled
            if (i === 0 || splitOrders) {
                drvObj.addTextDouble(true, false);
                drvObj.addTextStyle(false, false, false, drvObj.COLOR_1);
                drvObj.addText(topSpace);

                buildOrderHeader(drvObj, printer, order, 'header');
            }

            if (groupIndex !== '0') {
                if (i > 0 && !splitOrders) {
                    drvObj.addText(border(printer.columns, '='));
                }

                let groupTitle;

                switch (itemsGroupBy) {
                    case 'exit':
                        groupTitle = `${scope.options.labels.exit} ${groupIndex}`;
                        break;
                    default:
                        break;
                }

                if(groupTitle) {
                    setAlign(drvObj, 'center');
                    setSize(drvObj, itemFontSize);
                    drvObj.addText(groupTitle + newLine());
                    setSize(drvObj, 16);
                    drvObj.addText(border(printer.columns, '='));
                }
            } else {
                drvObj.addText(newLine());
            }

            // PRODOTTI E VARIANTI

            let addTypeHeader = (header) => {
                setAlign(drvObj, 'center');
                setSize(drvObj, itemFontSize);
                setReversed(drvObj, true);
                drvObj.addText(` ${header} ` + newLine());
                setReversed(drvObj, false);
            };

            let itemsByType = _.groupBy(itemGroup, 'type');

            if (itemsByType.removal) {
                addTypeHeader(scope.options.labels.item_removal);

                for (let item of itemsByType.removal) {
                    addItemRow(item, false);
                }
            }

            if (itemsByType.addition) {
                addTypeHeader(scope.options.labels.item_addition);

                for (let item of itemsByType.addition) {
                    addItemRow(item, false);
                }
            }

            if (itemsByType.edit) {
                const editedItems = _.groupBy(itemsByType.edit, (item) => item.quantity_difference ? 'quantity' : 'other');

                if (editedItems.quantity) {
                    addTypeHeader(scope.options.labels.item_editquantity);

                    for (let item of editedItems.quantity) {
                        addItemRow(item, true);
                    }
                }

                if (editedItems.other) {
                    addTypeHeader(scope.options.labels.item_edit);

                    for (let item of editedItems.other) {
                        addItemRow(item, false);
                    }
                }
            }

            // Add bottom space
            drvObj.addText(bottomSpace);

            // Print other elements (printed only on the last group or when split_orders_by_exit is enabled, if skipContent is disabled)
            if (!skipContent && (i === lastGroupIndex || splitOrders) && variationsToPrint.other) {
                let addOtherItemRow = (item, showSign) => {
                    drvObj.addText(`[${((item.quantity_difference > 0 && showSign) ? "+" : "")}${item.quantity_difference}]${(item.half_portion ? "1/2 " : "")} ${_.toUpper(item.order_name || item.name)}${newLine()}`);
                };

                setAlign(drvObj, 'right');
                setSize(drvObj, 0);

                const otherVariations = _.groupBy(variationsToPrint.other, itemsGroupBy);

                for (let groupIndex in otherVariations) {
                    const otherItemGroup = otherVariations[groupIndex];

                    if (groupIndex != '0') {
                        switch (itemsGroupBy) {
                            case 'exit':
                                drvObj.addText(` - ${scope.options.labels.exit} ${groupIndex} - ` + newLine());
                                break;
                            default:
                                break;
                        }
                    }

                    // PRODOTTI E VARIANTI
                    const otherItemsByType = _.groupBy(otherItemGroup, 'type');

                    if (otherItemsByType.removal) {
                        drvObj.addText(` ${scope.options.labels.item_removal} ` + newLine());

                        for (let item of otherItemsByType.removal) {
                            addOtherItemRow(item, false);
                        }
                    }

                    if (otherItemsByType.addition) {
                        drvObj.addText(` ${scope.options.labels.item_addition} ` + newLine());

                        for (let item of otherItemsByType.addition) {
                            addOtherItemRow(item, false);
                        }
                    }

                    if (otherItemsByType.edit) {
                        const otherEdits = _.groupBy(otherItemsByType.edit, (item) => item.quantity_difference ? 'quantity' : 'other');

                        if (otherEdits.quantity) {
                            drvObj.addText(` ${scope.options.labels.item_editquantity} ` + newLine());

                            for (let item of otherEdits.quantity) {
                                addOtherItemRow(item, true);
                            }
                        }

                        if (otherEdits.other) {
                            drvObj.addText(` ${scope.options.labels.item_edit} ` + newLine());

                            for (let item of otherEdits.other) {
                                addOtherItemRow(item, true);
                            }
                        }
                    }
                }
            }

            setSize(drvObj, 16);

            // Print footer and cut if it's the last group or when split_orders_by_exit is enabled
            if (i === lastGroupIndex || splitOrders) {
                buildOrderHeader(drvObj, printer, order, 'footer');
                // Add bottom space
                drvObj.addText(bottomSpace);

                // Cut paper
                drvObj.addCut(drvObj.CUT_FEED);
            }
        }

        if (printer.enable_buzzer) {
            drvObj.addPulse();
        }

        drvObj.send(callback);
    }

    function buildOrder(drvObj, order, printer, callback) {
        const isOwnRoomOrder = isPrintersRoomOrder(printer, order);
        const skipContent = (!!order.goExit && !!scope.options.goExit_skip_content);

        if(!isOwnRoomOrder) {
            callback('EMPTY_ORDER');
            return;
        }

        const addPrices = printer.add_prices_to_order ?? false;

        // Compile order prices if necessary
        if(addPrices) {
            orderUtils.calculateOrderPrices(order);
        }

        // Filter order_items which have category_id contained in printer.categories
        let itemsToPrint = groupItemsByPrinterOwnership(order.order_items, printer);

        // Check if empty
        if (!itemsToPrint.own) {
            callback('EMPTY_ORDER');
            return;
        }

        const itemFontSize = printer.item_font_size ?? 17;
        const itemSpacing = printer.item_spacing ?? 1;
        const itemHideBorder = printer.item_hide_border ?? false;
        const itemHideBrackets = printer.item_hide_brackets ?? false;
        const splitOrders = printer.split_orders_by_exit ?? false;
        const itemsGroupBy = printer.items_group_by ?? 'exit';
        const variationFontSize = printer.variation_font_size ?? 16;
        const itemsAlign = printer.items_align ?? 'center';

        const topSpace = _.repeat(newLine(), (printer.top_space || scope.options.top_space));
        const bottomSpace = _.repeat(newLine(), (printer.bottom_space || scope.options.bottom_space));

        const groupedItems = _.groupBy(itemsToPrint.own, itemsGroupBy);
        const groups = Object.keys(groupedItems);
        const lastGroupIndex = groups.length - 1;

        for (let i = 0; i < groups.length; i++) {
            const groupIndex = groups[i];
            const itemGroup = groupedItems[groupIndex];

            //Print header if it's the first group or the splitOrders is enabled
            if (i === 0 || splitOrders) {
                drvObj.addTextStyle(false, false, false, drvObj.COLOR_1);
                drvObj.addText(topSpace);

                buildOrderHeader(drvObj, printer, order, 'header');
            }

            if (order.goExit) { // Go exit
                setAlign(drvObj, 'center');
                setSize(drvObj, itemFontSize);

                switch(itemsGroupBy) {
                    case 'exit':
                        drvObj.addText(`${scope.options.labels.goExit} ${groupIndex}`);
                    break;
                    default:
                        if(i === 0) {
                            drvObj.addText(`${scope.options.labels.goExit}`);
                            drvObj.addText(newLine());
                        }
                    break;
                }

                drvObj.addText(newLine());
                setSize(drvObj, 16);
                setAlign(drvObj, 'left');
                drvObj.addText(border(printer.columns, '=') + newLine());
            } else { // Normal order send
                if (groupIndex !== '0') {
                    if (i > 0 && !splitOrders) { //Add upper borders
                        setAlign(drvObj, 'left');
                        drvObj.addText(border(printer.columns, '='));
                    }

                    let groupTitle;

                    switch (itemsGroupBy) {
                        case 'exit':
                            groupTitle = `${scope.options.labels.exit} ${groupIndex}`;
                            break;
                        default:
                            break;
                    }

                    if(groupTitle) {
                        setAlign(drvObj, 'center');
                        setSize(drvObj, itemFontSize);
                        drvObj.addText(groupTitle + newLine());
                        setSize(drvObj, 16);
                        setAlign(drvObj, 'left');
                        drvObj.addText(border(printer.columns, '='));
                    }
                } else {
                    drvObj.addText(newLine());
                }
            }

            if (!skipContent) {
                // PRODOTTI E VARIANTI
                for (let item of itemGroup) {
                    let quantity = itemHideBrackets ? `${item.quantity} ` : `[${item.quantity}] `;

                    if (item.half_portion) {
                        quantity = quantity + "1/2 ";
                    }

                    const priceTag = addPrices ? ` ${$filter('sclCurrency')(item.final_price)}` : '';

                    setAlign(drvObj, itemsAlign);
                    setSize(drvObj, itemFontSize);
                    drvObj.addText(quantity + _.toUpper(item.order_name || item.name) + priceTag + newLine());

                    for (let l = 0; l < itemSpacing; l++) {
                        drvObj.addText(newLine());
                    }

                    // AGGIUNTE / RIMOZIONI
                    let addedOrRemoved = _.groupBy(item.ingredients, 'type');

                    for (let addremType in addedOrRemoved) {
                        setAlign(drvObj, 'left');
                        setSize(drvObj, variationFontSize);

                        let typeLabel = scope.options.labels[addremType] || "";

                        for (let ingredient of addedOrRemoved[addremType]) {
                            switch (addremType) {
                                case 'added':
                                    drvObj.addText(`${typeLabel}${ingredient.quantity !== 1 ? (ingredient.quantity + "x") : ""} ${ingredient.name}` + newLine());
                                    break;
                                case 'removed':
                                    drvObj.addText(`${typeLabel} ${ingredient.name}` + newLine());
                                    break;
                            }
                        }

                        setUnderline(drvObj, false);
                    }

                    // VARIATIONS
                    if (!Array.isArray(item.variations)) {
                        item.variations = [];
                    }

                    for (let variation of item.variations) {
                        setAlign(drvObj, 'left');
                        setSize(drvObj, variationFontSize);
                        drvObj.addText(`${variation.name}: ${variation.value}` + newLine());
                    }

                    // NOTES
                    if (item.notes) {
                        setAlign(drvObj, 'left');
                        setSize(drvObj, variationFontSize);
                        drvObj.addText(`${scope.options.labels.notes}: ${item.notes}` + newLine());
                    }

                    setSize(drvObj, 16);

                    if (!itemHideBorder && item !== _.last(itemGroup)) {
                        setSize(drvObj, 16);
                        setAlign(drvObj, 'left');
                        drvObj.addText(border(printer.columns, '-'));
                    }
                }

                // Add bottom space
                drvObj.addText(bottomSpace);

                // Print other elements if there are (printed only on the last group or when split_orders_by_exit is enabled)
                if ((i === lastGroupIndex || splitOrders) && itemsToPrint.other) {
                    const otherItems = _.groupBy(itemsToPrint.other, itemsGroupBy);

                    for (const groupIndex in otherItems) {
                        const otherItemGroup = otherItems[groupIndex];

                        setAlign(drvObj, 'right');
                        setSize(drvObj, 0);

                        // USCITA
                        if (groupIndex != '0') {
                            switch (itemsGroupBy) {
                                case 'exit':
                                    drvObj.addText(` - ${scope.options.labels.exit} ${groupIndex} - ` + newLine());
                                    break;
                                default:
                                    break;
                            }
                        }

                        // PRODOTTI E VARIANTI
                        for (const item of otherItemGroup) {
                            setSize(drvObj, 0);
                            drvObj.addText(item.quantity + " x " + (item.half_portion ? '1/2 ' : '') + _.toUpper(item.order_name || item.name) + newLine());
                        }
                    }
                }
            }

            // Print footer and cut if it's the last group or when split_orders_by_exit is enabled
            if (i === lastGroupIndex || splitOrders) {
                buildOrderHeader(drvObj, printer, order, 'footer');
                drvObj.addCut(drvObj.CUT_FEED);
            }
        }

        if (printer.enable_buzzer) {
            drvObj.addPulse();
        }

        drvObj.send(callback);
    }

    const buildNonFiscalSale = async (drvObj, sale, printer, callback) => {
        const addLine = (line) => drvObj.addText(line + newLine());
        const numCols = printer.columns || 48;

        var calculatePriceChanges = function(pChanges, partial) {
            _.forEach(pChanges, function(pChange) {
                var pcAmount = fiscalUtils.getPriceChangeAmount(pChange, partial);

                if(!_.isNil(pcAmount)) {
                    partial = fiscalUtils.roundDecimals(partial + pcAmount);
                    var amountStr;

                    if(['discount_fix', 'discount_perc', 'gift'].includes(pChange.type)) {
                        amountStr = pcAmount.toFixed(2).replace(".", ",");
                    } else if(['surcharge_fix', 'surcharge_perc'].includes(pChange.type)) {
                        amountStr = "+" + pcAmount.toFixed(2).replace(".", ",");
                    }

                    var rowDescription = pChange.description;
                    var row = _.padEnd(_.truncate(rowDescription, { length: (numCols - amountStr.length - 2) }), numCols - amountStr.length, " ") + amountStr;

                    addLine(row);
                }
            });
        };
        
        drvObj.addLogo();
        drvObj.addTextDouble(false, false);
        drvObj.addTextStyle(false, false, false, drvObj.COLOR_1);

        if(_.get(sale, "options.header")) {
            buildShopHeader(drvObj, sale.options.header);
        }

        drvObj.addTextDouble(false, false);

        if(!checkManager.getPreference("cashregister.save_paper_on_prebill")) {
            setAlign(drvObj, 'center');

            _($translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_HEADER')).split('\n').forEach(addLine);
        }

        setAlign(drvObj, 'left');

        for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
            addLine(row);
        }

        // Sale items
        _.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
            var rowDescription = si.quantity + "x " + (si.name || si.department_name);
            var rowPrice = (si.price * si.quantity).toFixed(2).replace(".", ",");
            var row = _.padEnd(_.truncate(rowDescription, { length: (numCols - rowPrice.length - 2) }), numCols - rowPrice.length, " ") + rowPrice;

            addLine(row);

            if(si.notes) {
                var notesLines = stringToLines(si.notes, numCols);

                _.forEach(notesLines, function(nl) {
                    if(nl.trim()) {
                        addLine(_.repeat(' ', 5) + nl.trim());
                    }
                });
            }

            // Discount/Surcharges
            if(si.price_changes) {
                var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);
                calculatePriceChanges(si.price_changes, partialPrice);
            }
        });

        // Sub-total and his discount/surcharges
        var subTotDescr = $translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_SUBTOTAL');
        var subTotAmount = sale.amount.toFixed(2).replace(".", ",");

        setBold(drvObj, true);
        addLine(_.padEnd(subTotDescr, numCols - subTotAmount.length, " ") + subTotAmount);
        setBold(drvObj, false);

        // Apply discount/surcharges on subtotal
        if(sale.final_amount >= 0) {
            var partialPrice = fiscalUtils.roundDecimals(sale.amount);
            var orderedPC = _.sortBy(sale.price_changes, 'index');
            calculatePriceChanges(orderedPC, partialPrice);
        }

        var totDescription = $translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_FINAL_AMOUNT', { currency: $rootScope.currentCurrency.code });
        var totAmount = sale.final_amount.toFixed(2);
        var totRow = _.padEnd(_.truncate(totDescription, { length: (numCols - totAmount.length - 2) }), numCols - totAmount.length, " ") + totAmount;

        drvObj.addTextDouble(false, true);
        addLine(totRow);
        drvObj.addTextDouble(false, false);

        setAlign(drvObj, 'left');

        if(sale.options?.tail) {
            let tailLines = stringToLines(sale.options.tail);

            addLine("");

            for(let line of tailLines) {
                addLine(line);
            }

            addLine("");
        }

        setAlign(drvObj, 'center');

        if(!checkManager.getPreference('cashregister.save_paper_on_prebill')) {
            _($translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_FOOTER')).split('\n').forEach(addLine);
        } else {
            addLine("");
        }

        if(checkManager.getPreference('fiscalprinter.print_sale_qr_code')) {
            drvObj.addSymbol(`s_uid=${sale.uuid}`, drvObj.SYMBOL_QRCODE_MODEL_2, drvObj.LEVEL_DEFAULT, 8);
        }

        const strookaKey = checkManager.getPreference('orders.strooka_shared_key');

        if(sale.table_id && strookaKey) {
            let strookaApiUrl = checkManager.getPreference('orders.strooka_api_url') || "https://api.strooka.com/plugin/scloby/tables/open/";

            if(!strookaApiUrl.endsWith("/")) {
                strookaApiUrl += "/";
            }

            try {
                let result = await $http({
                    url: `${strookaApiUrl}${sale.table_id}/`,
                    method: "POST",
                    headers: { 'Shared-Key': strookaKey },
                    data: { covers: sale.covers || 1, order_id: sale.id }
                });

                if(result.data?.url) {
                    var strookaUrl = _.unescape(result.data.url);
                    var strookaDomain = _.get(strookaUrl.match(/(^.*.)[\/][?]/), [1]);

                    drvObj.addTextSize(2,2);
                    var headerText = checkManager.getPreference('orders.strooka_header_text');

                    if(!headerText) {
                        if(drvObj instanceof EPosDriver) {
                            headerText = "Inquadra con il telefono il QRcode";
                        }
                    }

                    if(headerText) {
                        drvObj.addText(newLine(2) + headerText + newLine());
                    }

                    drvObj.addSymbol(strookaUrl, drvObj.SYMBOL_QRCODE_MODEL_2, drvObj.LEVEL_DEFAULT, 8);
                    drvObj.addText((drvObj instanceof EPosDriver ? "In alternativa accedi su" : "Accedi su") + newLine() + strookaDomain + newLine() + "ed inserisci il codice" + newLine());
                    drvObj.addTextSize(3,3);
                    drvObj.addText(_.toString(result.data.pin));
                    drvObj.addTextSize(1,1);
                }
            } catch(err) {}
        }

        drvObj.addCut(drvObj.CUT_FEED);
        drvObj.send(callback);
    };

    function printOrder(order, originalPayload, printer, callback) {
        logEvent(`Printing order on printer: [${printer.name}]`);

        let formatFunction;
        
        switch(printer.type) {
            case 'nonfiscal':
            case 'receipt':
                let driverInfo = getDriverInfo(printer);

                if(driverInfo.driverObj) {
                    switch(order.print_type) {
                        case 'order':
                        case 'sale':
                            if(order.variation) {
                                formatFunction = buildVariation;
                            } else {
                                formatFunction = buildOrder;
                            }
                        break;
                        case 'sale_nonfiscal':
                            formatFunction = buildNonFiscalSale;
                        break;
                        default:
                        break;
                    }
        
                    if(_.isFunction(formatFunction)) {
                        formatFunction(new driverInfo.driverObj(printer.ip_address, driverInfo.port, driverInfo.configuration), order, printer, callback);
                    }
                } else {
                    callback('INVALID_DRIVER');
                }
            break;
            case 'kds':
                if(!['sale'].includes(order.print_type)) {
                    KDSManager.sendOrders(printer, [originalPayload]).then(
                        (result) => { callback('PRINTED'); },
                        (error) => { callback('CONNECTION_ERROR'); }
                    );
                } else {
                    callback('EMPTY_ORDER');
                }
            break;
            default:
                callback('CONNECTION_ERROR');
        }
    }

    /**
     * asyncSendOrders
     */
    async function sendOrderToPrinters(order, originalPayload, printers, callback) {
        const results = [];
        const printersByIP = _.groupBy(printers, 'ip_address');
        const printAttempts = _.toInteger(checkManager.getPreference('orders.print_attempts')) || 1;
        const orderItems = [...order.order_items || [], ...order.variation_items || []];
        const itemIds = _.chain(orderItems).map('item_id').uniq().filter(_.identity).value();
        const categoryToPrinters = {};
        const itemToPrinters = {};

        logEvent("print_type", order.print_type);
        logEvent("variation", order.variation);

        //Set up channel info if present
        if(order.channel && order.channel !== 'pos') {
            try {
                const channel = await entityManager.channels.fetchOneOffline(order.channel);

                if(channel) {
                    order.channel = channel.name;
                }
            } catch(err) {
                errorsLogger.error("[NonFiscalDriver] Error while getting channel info for order");
            }
        }

        //Prepare category-to-printers map
        if (!Array.isArray(printers)) {
            printers = [];
        }

        for (let printer of printers) {
            if (!Array.isArray(printer.categories)) {
                printer.categories = [];
            }

            for (let category of printer.categories) {
                _.setWith(categoryToPrinters, [category.id, printer.id], true, Object);
            }
        }

        //Compile category name in items
        const categories = await entityManager.categories.fetchCollectionOffline();
        const categoriesById = _.keyBy(categories, 'id');

        for (let orderItem of orderItems) {
            if (orderItem.category_id && categoriesById[orderItem.category_id]) {
                orderItem.category_name = categoriesById[orderItem.category_id].name;
            }
        }

        //Prepare item-to-printers map
        for(let itemId of itemIds) {
            let item = await entityManager.items.fetchOneOffline(itemId);

            if(item) {
                for(let printer of _.toArray(item.printers)) {
                    _.setWith(itemToPrinters, [item.id, printer.id], true, Object);
                }
            }
        }

        //Apply maps to order items complete setup
        for(let orderItem of _.toArray(order.order_items || order.variation_items)) {
            if(!orderItem.exit) {
                orderItem.exit = 0;
            }

            orderItem.printers = Object.assign({}, _.get(categoryToPrinters, orderItem.category_id), _.get(itemToPrinters, orderItem.item_id));
        }

        //Send orders to 5 different printers in parallel, if there are printers with the same IP do them in series
        async.eachLimit(printersByIP, 5, function(printerSet, cbSet) {
            async.eachSeries(printerSet, function(printer, cbOrder) {
                var failedAttempts = 0;

                async.retry({ times: printAttempts }, function(cbAttempt) {
                    printOrder(order, originalPayload, printer, function(result) {
                        if(['PRINTED', 'EMPTY_ORDER'].includes(result)) {
                            cbAttempt(false, result);
                        } else {
                            failedAttempts++;
                            cbAttempt(true, result);
                        }
                    });
                }, function(err, res) {
                    results.push({
                        printer: printer,
                        result: res
                    });

                    cbOrder();

                    if(failedAttempts > 0) {
                        errorsLogger.sendReport({
                            type: 'printOrders',
                            content: `Printer [${printer.id}] [${printer.ip_address}] returned ${res} after ${(failedAttempts + 1)} attempts`
                        });
                    }
                });
            }, function(err, res) {
                cbSet();
            });
        }, function(err, res) {
            callback(results);
        });
    }

    /**
     * public methods
     */
    return {
        /**
         * @description setup
         * @param  {array of objects} printers
         *         for each printer:
         *         - printer.columns (integer)
         *         - printer.top_space (integer)
         *         - printer.bottom_space (integer)
         *         - printer.enable_buzzer (boolean)
         * @param {object} options
         */
        setup: function(printers, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            _.forEach(printers, function(printer) {
                if(!_.isArray(printer.other_printers)) {
                    printer.other_printers = [];
                }

                if(!_.isArray(printer.cashregisters)) {
                    printer.cashregisters = [];
                }

                if(!_.isArray(printer.rooms)) {
                    printer.rooms = [];
                }
            });

            scope.printers = printers;

            scope.options = {
                columns: _.get(options, "columns") || 48,
                top_space: _.get(options, "top_space") || 0,
                bottom_space: _.get(options, "bottom_space") || 3,
                goExit_skip_content: _.get(options, "goExit_skip_content") ? true : false,
                enable_buzzer: options.enable_buzzer ? true : false,
                labels: {
                    order: _.get(options, "labels.order") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ORDER'),
                    order_notes: _.get(options, "labels.order_notes") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ORDER_NOTES'),
                    variation: _.get(options, "labels.variation") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.VARIATION'),
                    reprint_order: _.get(options, "labels.reprint_order") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINT_ORDER'),
                    item_addition: _.get(options, "labels.item_addition") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_ADDITION'),
                    item_removal: _.get(options, "labels.item_removal") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_REMOVAL'),
                    item_edit: _.get(options, "labels.item_edit") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_EDIT'),
                    item_editquantity: _.get(options, "labels.item_editquantity") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_EDITQUANTITY'),
                    customer: _.get(options, "labels.customer") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.CUSTOMER'),
                    customer_delivery: _.get(options, "labels.customer") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.CUSTOMER_DELIVERY'),
                    fidelity: _.get(options, "labels.fidelity") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.FIDELITY'),
                    phone: _.get(options, "labels.phone") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.PHONE'),
                    mobile: _.get(options, "labels.mobile") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.MOBILE'),
                    customer_notes: _.get(options, "labels.customer_notes") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.CUSTOMER_NOTES'),
                    notes: _.get(options, "labels.notes") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.NOTES'),
                    total_pieces: _.get(options, "labels.total_pieces") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.TOTAL_PIECES'),
                    covers: _.get(options, "labels.covers") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.COVERS'),
                    table: _.get(options, "labels.table") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.TABLE'),
                    waiter: _.get(options, "labels.waiter") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.WAITER'),
                    exit: _.get(options, "labels.exit") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.EXIT'),
                    added: _.get(options, "labels.added") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.ADDED'),
                    removed: _.get(options, "labels.removed") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.REMOVED'),
                    of: _.get(options, "labels.of") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.OF'),
                    take_away: _.get(options, "labels.take_away") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.TAKE_AWAY'),
                    delivery: _.get(options, "labels.delivery") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.DELIVERY'),
                    deliver_at: _.get(options, "labels.deliver_at") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.DELIVER_AT'),
                    deliver_at_date: _.get(options, "labels.deliver_at_date") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.DELIVER_AT_DATE'),
                    goExit: _.get(options, "labels.goExit") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.GO_EXIT'),
                    reprinted_at: _.get(options, "labels.reprinted_at") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINTED_AT'),
                    reprint_source: _.get(options, "labels.reprint_source") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINT_SOURCE'),
                    reprint_new: _.get(options, "labels.reprint_new") || $translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINT_NEW'),
                }
            };
        },

        /**
         * discoverPrinters
         */
        discoverPrinters: function(driver, callback) {
            let printersInt = [];
            let driverInfo = getDriverInfo({ driver: driver });

            $window.chrome.socket.getNetworkList(function(adapters) {
                async.eachSeries(adapters, function(adapter, cbA) {
                    if(validateIPaddress(adapter.address)) {
                        logEvent(adapter);
                        var networkInfo = IpSubnetCalculator.calculateSubnetMask(adapter.address, adapter.prefixLength);

                        var ipLow = networkInfo.ipLow;
                        var ipHigh = networkInfo.ipHigh;

                        logEvent("Start scanning...");

                        var ipToScan = _.range(ipLow + 1, ipHigh - 1);

                        async.eachLimit(ipToScan, 32, function(ip, cb) {
                            driverInfo.driverObj.connectAttempt(intToIP(ip), driverInfo.port, function(result) {
                                if(result.connected) {
                                    printersInt.push(ip);
                                }
                                cb();
                            });
                        }, function(err) {
                            cbA();
                        });
                    } else {
                        cbA();
                    }
                }, function(err) {
                    printersInt.sort();
                    var printers = _.map(printersInt, intToIP);
                    logEvent("Printers found:" + JSON.stringify(printers));
                    callback(printers);
                });
            });
        },

        /**
         * smartPrintOrder: printer an order to all esc/pos printers, by category
         */
        smartPrintOrder: function(order, reprint, callback) {
            const localOrder = Object.assign(structuredClone(order) || {}, {
                reprint: reprint,
                print_type: 'order'
            });

            sendOrderToPrinters(localOrder, order, scope.printers, callback);
        },

        /**
         * smartPrintSale: print send a sale to all esc/pos printers, by category
         */
        smartPrintSale: function(sale, reprint, callback) {
            const {
                sale_items,
                sale_customer,
                order_type,
                sale_number,
                seller_name,
                ...saleDetails
            } = structuredClone(sale);

            const orderFromSale = {
                ...saleDetails,
                order_items: sale_items,
                order_customer: sale_customer,
                operator_name: seller_name,
                order_number: sale_number,
                type: order_type,
                reprint: reprint,
                print_type: 'sale'
            };

            sendOrderToPrinters(orderFromSale, sale, scope.printers, callback);
        },

        smartPrintVariationSale: async function(saleVariation, sale) {
            const localVariation = Object.assign(structuredClone(saleVariation), {
                print_type: 'order'
            });

            return new Promise((resolve) => sendOrderToPrinters(localVariation, sale, scope.printers, resolve));
        },

        printNonFiscalSale: function(sale, printer, options, callback) {
            const localSale = Object.assign(structuredClone(sale), {
                options: structuredClone(options || {}),
                print_type: 'sale_nonfiscal'
            });

            printOrder(localSale, sale, printer, callback);
        },

        /**
         * smartPrintDifferences: print differences to all esc/pos printers
         */
        smartPrintDifferences: function(order, callback) {
            const localOrder = structuredClone(order);

            const variationOrder = Object.assign(calculateDifferences(localOrder.previous_order, localOrder), {
                print_type: 'order'
            });

            sendOrderToPrinters(variationOrder, order, scope.printers, callback);
        },

        /**
         * smartGoExit
         */
        smartGoExit: function(order, exit, callback) {
            const goExitOrder = structuredClone(order);

            Object.assign(goExitOrder, {
                order_items: _.filter(goExitOrder.order_items, { 'exit': exit }),
                print_type: 'order',
                goExit: true
            });

            sendOrderToPrinters(goExitOrder, order, scope.printers, callback);
        },

        printDocument: async (saleToPrint, printerDocumentData, options_print) => {
            let printer = _.head(scope.printers);
            let driverInfo = getDriverInfo(printer);

            if(!driverInfo) {
                throw 'INVALID_DRIVER';
            }

            driverInfo.configuration.eReceiptMode = !!(printerDocumentData?.options?.eReceipt);

            let printerDriver = new driverInfo.driverObj(printer.ip_address, driverInfo.port, driverInfo.configuration);
            let options = _.isObject(options_print) ? _.cloneDeep(options_print) : {};
            options.columns = printer.columns;

            let documentData = await DocumentBuilder.buildDocument(printerDriver, saleToPrint, printerDocumentData, options);

            return new Promise((resolve, reject) => {
                printerDriver.send(function(result) {
                    if(result === 'PRINTED' || (printer.fiscal_provider && !options_print.isReprint)) {
                        resolve([(result === 'PRINTED'), [
                            Object.assign(documentData, {
                                date: new Date(),
                                printer_serial: null
                            })
                        ]]);
                    } else {
                        reject(result);
                    }
                });
            });
        },
        printFreeNonFiscal: async (documentToPrint, options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            let printer = _.head(scope.printers);
            let driverInfo = getDriverInfo(printer);

            if(!driverInfo) {
                throw 'INVALID_DRIVER';
            }

            let printerDriver = new driverInfo.driverObj(printer.ip_address, driverInfo.port, driverInfo.configuration);

            if(typeof documentToPrint == 'string') {
                documentToPrint = documentToPrint.split('\n');
            }

            if(!Array.isArray(documentToPrint)) {
                throw 'INVALID_DOCUMENT';
            }

            if(options.printLogo) {
                printerDriver.addLogo();
            }

            if(options.printCopyHeader) {
                setAlign(printerDriver, 'center');
                printerDriver.addTextDouble(false, false);
                printerDriver.addText("RECEIPT COPY\n\n");
            }

            if(options.header) {
                buildShopHeader(printerDriver, options.header);
                printerDriver.addTextDouble(false, false);
                setAlign(printerDriver, 'left');
            }

            for(const line of documentToPrint) {
                printerDriver.addText(line + '\n');
            }

            if(typeof options?.barcode == 'object') {
                setAlign(printerDriver, 'center');

                switch(options.barcode.type) {
                    case 'CODE39':
                    case 'EAN13':
                    default:
                        printerDriver.addText('\n');
                        printerDriver.addBarcode(options.barcode.value, EpsonBarcode[options.barcode.type] || EpsonBarcode.CODE39, EpsonHRI.BELOW, EpsonFont.FONT_B, 2, 66);
                        printerDriver.addText('\n');
                    break;
                    case 'QRCODE':
                        printerDriver.addText('\n');
                        printerDriver.addSymbol(options.barcode.value, printerDriver.SYMBOL_QRCODE_MODEL_2, printerDriver.LEVEL_DEFAULT, 8);
                        printerDriver.addText('\n');
                    break;
                }
            }

            printerDriver.addCut();

            return new Promise((resolve, reject) => {
                printerDriver.send(function(result) {
                    if(result === 'PRINTED') {
                        resolve();
                    } else {
                        reject('CONNECTION_ERROR');
                    }
                });
            });
        },
        isReachable: async () => {
            let printer = _.head(scope.printers);
            let driverInfo = getDriverInfo(printer);

            if(!driverInfo) {
                throw 'INVALID_DRIVER';
            }

            return new Promise((resolve, reject) => {
                driverInfo.driverObj.connectAttempt(printer.ip_address, driverInfo.port, function(result) {
                    if(result.connected) {
                        resolve();
                    } else {
                        reject('CONNECTION_ERROR');
                    }
                });
            });
        },
        openCashDrawer: async () => {
            let printer = _.head(scope.printers);
            let driverInfo = getDriverInfo(printer);

            if(!driverInfo) {
                throw 'INVALID_DRIVER';
            }

            let printerDriver = new driverInfo.driverObj(printer.ip_address, driverInfo.port, driverInfo.configuration);
            printerDriver.addPulse();

            return new Promise((resolve, reject) => {
                printerDriver.send(function(result) {
                    if(result === 'PRINTED') {
                        resolve();
                    } else {
                        reject();
                    }
                });
            });
        },
        displayText: async(printer, textLines) => {
            let driverInfo = getDriverInfo(printer);

            if(!driverInfo) {
                throw 'INVALID_PRINTER';
            }

            if(!_.isFunction(driverInfo.driverObj.displayText)) {
                throw 'METHOD_DOES_NOT_EXIST';
            }

            await driverInfo.driverObj.displayText(printer, textLines);
        }
    };
}