import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SettingsUsersManagementService {

    constructor(private translateService: TranslateService) {

    }

    getDateDifference(updateDate: string): string {
        const currentDate = new Date();
        const updateDateObj = new Date(updateDate);
        if (isNaN(updateDateObj.getTime())) {
            return this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.INVALID_DATE");
        }

        const diffInMillisecondi = Math.abs(currentDate.getTime() - updateDateObj.getTime());
        const diffInSecondi = Math.floor(diffInMillisecondi / 1000);
        
        if (diffInSecondi < 60) {
            return `${diffInSecondi} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.SECONDS_AGO")}`;
        } else if (diffInSecondi < 3600) {
            if(Math.floor(diffInSecondi / 60) === 1) {
                return `${Math.floor(diffInSecondi / 60)} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.MINUTE_AGO")}`;
            }else {
                return `${Math.floor(diffInSecondi / 60)} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.MINUTES_AGO")}`;
            }
        } else if (diffInSecondi < 86400) {
            if(Math.floor(diffInSecondi / 3600) === 1) {
                return `${Math.floor(diffInSecondi / 3600)} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.HOUR_AGO")}`;
            } else {
                return `${Math.floor(diffInSecondi / 3600)} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.HOURS_AGO")}`;
            }
        } else {
            if(Math.floor(diffInSecondi / 86400) === 1) {
                return `${Math.floor(diffInSecondi / 86400)} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.DAY_AGO")}`;
            } else {
                return `${Math.floor(diffInSecondi / 86400)} ${this.translateService.instant("SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.DAYS_AGO")}`;
            }
        }
    }
}
