<mat-progress-bar mode="query" *ngIf="inProgress"></mat-progress-bar>
<tilby-dialog-toolbar mat-dialog-title
    title="SETTINGS.EDIT_USER_ACEESS_DIALOG.TITLE_LANGUAGE"
    [hideConfirm]="true"></tilby-dialog-toolbar>
<mat-dialog-content>
    <h4 class="tw-my-0" translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.SUBTITLE_LANGUAGE</h4>
    <mat-action-list>
        <button mat-list-item *ngFor="let uiLanguage of uiLanguages" (click)="changeLanguage(uiLanguage)" [activated]="uiLanguage.id === data.uiLanguage.id" [disabled]="inProgress">
            <span>{{uiLanguage.value}}</span>
        </button>
    </mat-action-list>
</mat-dialog-content>
