<ng-container *ngIf="!!data.form.controls.printer.customProps.inputChoices.length; else NoPrinters">
    <tilby-magic-form [form]="data.form"/>
</ng-container>

<ng-template #NoPrinters>
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-8">
        <img src="assets/images/print-error.png" alt="" class="tw-w-40">
        {{'CASHREGISTER.DOCUMENTS_MANAGER.NO_PRINTER_AVAILABLE' | translate}}
    </div>
</ng-template>
