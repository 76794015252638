import * as angular from 'angular';
import * as async from 'async';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('core').factory('progressivesManager', ["$q", "$window", "$rootScope", "restManager", "util", function($q, $window, $rootScope, restManager, util) {
    var isProgressiveExpired = function(time) {
        if (_.isNil(time)) {
            return true;
        }

        return moment(time).isBefore(util.getDayStartTime());
    };

    var progressivesCache;

    var readProgressives = function() {
        var progressives = progressivesCache;

        if(_.isNil(progressives)) {
            try {
                progressives = JSON.parse($window.localStorage.getItem('progressives::' + $rootScope.userActiveSession.shop.name)) || {};
            } catch(e) {
                progressives = {};
            }
        }

        return _.clone(progressives);
    };

    var writeProgressives = function(progressives) {
        progressivesCache = _.clone(progressives);
        $window.localStorage.setItem('progressives::' + $rootScope.userActiveSession.shop.name, JSON.stringify(progressives));
    };

    var progressivesManager = {
        getProgressives: function() {
            var d = $q.defer();
            var progressives = readProgressives();
            var oldProgressives = _.clone(progressives);

            async.waterfall([
                //Offline Progressive Check
                function(cb) {
                    if(isProgressiveExpired(progressives.offline_last_reset)) {
                        _.assign(progressives, {
                            offline_last_reset: new Date().toISOString(),
                            order_number_offline: 0,
                            sale_number_offline: 0
                        });
                    }
                    cb();
                },
                //Online Progressive Check
                function(cb) {
                    if(isProgressiveExpired(progressives.online_last_update)) {
                        restManager.getList("progressive").then(function(onlineProgressives) {
                            _.assign(progressives, onlineProgressives);
                        }).finally(cb);
                    } else {
                        cb();
                    }
                }
            ], function(err, res) {
                //Update Progressives if necessary
                if (!_.isEqual(progressives, oldProgressives)) {
                    writeProgressives(progressives);
                }

                d.resolve(progressives);
            });

            return d.promise;
        },
        updateProgressives: function(prgObj) {
            var updateData = {};

            if(prgObj.entity) { //Progressive received through PubNub
                if (!isProgressiveExpired(prgObj.time)) {
                    switch(prgObj.entity) {
                        case 'sales':
                            updateData.sale_number = prgObj.progressive;
                        break;
                        case 'orders':
                            updateData.order_number = prgObj.progressive;
                        break;
                    }

                    updateData.online_last_update = prgObj.time;
                }
            } else {
                //Online Progressive (API call)
                if(_.isFinite(prgObj.order_number) && _.isFinite(prgObj.sale_number)) {
                    _.assign(updateData, _.pick(prgObj, ['order_number', 'sale_number']), { online_last_update: new Date().toISOString() });
                }
                //Offline progressives
                if(_.isFinite(prgObj.order_number_offline)) {
                    _.assign(updateData, _.pick(prgObj, 'order_number_offline'));
                }
                if(_.isFinite(prgObj.sale_number_offline)) {
                    _.assign(updateData, _.pick(prgObj, 'sale_number_offline'));
                }
            }

            if(!_.isEmpty(updateData)) {
                var progressives = readProgressives();
                var oldProgressives = _.clone(progressives);

                _.assign(progressives, updateData);
    
                if(!_.isEqual(progressives, oldProgressives)) {
                    writeProgressives(progressives);
                }
            }
        },
    };

    return progressivesManager;
}]);