import {Component, EventEmitter, Inject, Input, OnInit, Output, OnDestroy, ViewChild} from '@angular/core';
import {CustomersFields} from "../customer-form/customers-form.model";
import { ShopChain, User} from "src/app/models";
import {
    GridClickableTypeButtonComponent,
    GridFixedButtons,
    headersTranslate,
    ImplementServerSideGrid
} from "src/app/shared/components/grid";
import {GridServerSideComponent} from "src/app/shared/components/grid-server-side/grid-server-side.component";
import {IServerSideDatasource, RowClickedEvent} from "ag-grid-community";
import {QueryPaginationFidelity, TableData} from "src/app/shared/model/model";
import {from} from "rxjs";
import {FidelityMovementsFe} from "../../../../customers.model";
import {restManager, RootScope} from 'app/ajs-upgraded-providers';
import {OpenDialogsService} from "src/app/dialogs";
import * as _ from "lodash";
import {FormGroup} from "@angular/forms";
import {
    CustomersPointsFormGroups
} from "./customer-fidelity-points-form/custumer-fidelity-points-form.model";
import {GridCellFormatterService} from 'src/app/shared/components/grid/utils/grid-cell-formatter.service';
import {GridCellExportFormatterService} from 'src/app/shared/components/grid/utils/grid-cell-export-formatter.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {
    CustomerFidelityPointsFormComponent
} from "./customer-fidelity-points-form/customer-fidelity-points-form.component";
import {EntityManagerService} from "src/app/core/services/entity/entity-manager.service";
import {ModuleEvents, PaginatedResponse, ToolbarEventsService} from "src/app/core/services";
import {OnDestroyService} from "src/app/core/services/on-destroy.service"
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { ChainCampaigns, ChainFidelitiesMovements, FidelitiesPoints } from 'tilby-models';
import { IAngularEvent } from 'angular';

@Component({
    selector: 'app-customer-fidelity-points',
    templateUrl: './customer-fidelity-points.component.html',
    styleUrls: ['./customer-fidelity-points.component.scss'],
    providers: [OnDestroyService],
    host: {class: 'tw-block tw-h-full tw-w-full'}
})
export class CustomerFidelityPointsComponent implements OnInit, OnDestroy, ImplementServerSideGrid {
    @Input() user?: User;
    @Input() customer?: CustomersFields;
    @Input() points?: FidelitiesPoints[];
    @Output() pointsChange = new EventEmitter<FidelitiesPoints[]>();
    @Input() chainShops?: ShopChain[];
    @Input() campaigns?: ChainCampaigns[];
    @Input() selectedCampaignId?:number|null;
    @Output() selectedCampaignIdChange = new EventEmitter<number|null>();


    fixedButtons:GridFixedButtons= {checkbox:{visible:false,lockPosition: 'left'},customButton:{visible:true,cellRenderer: GridClickableTypeButtonComponent,lockPosition: 'left'}}
    exportFormatterOptions?: (...any:any[])=>any;

    @ViewChild('appGridComponent', {static: true}) gridRef!: GridServerSideComponent;
    @ViewChild('customerFidelityPointsFormComponent', {static: true}) customerFidelityPointsFormComponent!: CustomerFidelityPointsFormComponent;

    datasource: IServerSideDatasource = {
        getRows: (params) => this.gridRef.getRowsForServersideDatasource(params, ["fidelities_movements", this.query], (a, b?) => from(this.restManagerService.getList(a, b) as Promise<PaginatedResponse<ChainFidelitiesMovements>>), {
            sort_type: "desc",
            sort_col: 'date'
        })
    };
    query!: Readonly<QueryPaginationFidelity>;
    dateFilter = {
        from: TilbyDatePipe.shopDateStart({plus: {year: -2}}),
        to: TilbyDatePipe.shopDateEnd()
    };
    tableData: TableData[] = [
        {
            rowData$: null,
            dataType: new FidelityMovementsFe(),
            headersTranslate: headersTranslate.customers_fidelity,
            columnsFormatter: this.gridCellFormatterService.customers_fidelity
        }
    ];
    onFidelityUpdated: any;
    onEntityUpdatedCustomers: any;

    constructor(@Inject(restManager) private restManagerService: any,
                private entityManager: EntityManagerService,
                @Inject(RootScope) private $rootScope: any,
                private translate:TranslateService,
                private _snackBar: MatSnackBar,
                private openDialogsService:OpenDialogsService,
                private gridCellFormatterService: GridCellFormatterService,
                private toolbarEventsService: ToolbarEventsService,
                private onDestroyService: OnDestroyService,
                private gridCellExportFormatterService: GridCellExportFormatterService) {

        this.onFidelityUpdated = this.$rootScope.$on("fidelity-updated", (event: IAngularEvent, fidelityPoints: FidelitiesPoints) =>{
            if (fidelityPoints.fidelity === this.customer?.fidelity) {
                const totalToUpdate = _.find(this.points, { id: fidelityPoints.id });
                if (totalToUpdate) {_.assign(totalToUpdate, fidelityPoints);}
                else {
                    this.points?.push(fidelityPoints);
                    // if (fidelityPoints.campaign_id === this.selectedCampaignId) {
                    //     this.points = fidelityPoints;
                    // }
                }
                this._snackBar.open(this.translate.instant('FIDELITY.DETAILS.NEW_MOVEMENTS'), this.translate.instant('FIDELITY.DETAILS.RELOAD'), {duration: 0})
                    .afterDismissed().subscribe(answer => {
                    if(answer.dismissedByAction && this.customerFidelityPointsFormComponent?.pointsForm){
                        this.pointsChange.emit([...(this.points||[])]);
                        this.refresh(this.customerFidelityPointsFormComponent.pointsForm);
                    }
                });
            }
        });

        this.onEntityUpdatedCustomers = this.$rootScope.$on("entity-updated:customers", (event: IAngularEvent, data: {id:number}) => {
            if(data.id === this.customer?.id) {
                this.entityManager.customers.fetchOneOffline(this.customer?.id)
                    .then((cust) =>  _.assign(this.customer,cust));
            }
        });

    }

    ngOnInit(): void {
        this.query = {
            pagination: true,
            per_page: 50,
            date_since: this.dateFilter.from,
            date_max: this.dateFilter.to,
            campaign_id: this.selectedCampaignId || null,
            db_name: null,
            fidelity: this.customer?.fidelity || "-1"
        }
        this.exportFormatterOptions = this.gridCellExportFormatterService.fidelityExportFormatter;
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e))
    }

    ngOnDestroy(): void {
        this.onFidelityUpdated();
        this.onEntityUpdatedCustomers();
    }

    private callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {
        if ("search" in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("openToolPanel" in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
    }

    // getType(movement){
    //     return movement.points >= 0 ? "load" : "unload";
    // }

    async rowClicked($event: RowClickedEvent) {
        await this.openDialogsService.openFidelityMovementDetailsDialog({data: {movement:$event.data}});
    }

    refresh(pointsForm: FormGroup<CustomersPointsFormGroups>) {
        const {date_since,date_max,db_name,campaign_id=null} = pointsForm.value.customersPointsForm||{};
        this.selectedCampaignIdChange.emit(campaign_id)
        this.query = {
            pagination: true,
            per_page: 50,
            date_since: date_since ? TilbyDatePipe.shopDateStart({date:date_since}) : null,
            date_max: date_max ? TilbyDatePipe.shopDateEnd({date:date_max}) : null,
            campaign_id: campaign_id || null,
            db_name: (db_name && db_name !== "-1") ? db_name : null,
            fidelity: this.customer?.fidelity
        }

        this.datasource = {
            getRows: (params) => this.gridRef.getRowsForServersideDatasource(params, ["fidelities_movements", this.query], (a, b?) => from(this.restManagerService.getList(a, b) as Promise<PaginatedResponse<ChainFidelitiesMovements>>), {
                sort_type: "desc",
                sort_col: 'date'
            })
        };

        this.gridRef.refreshGrid();
    }


}
