<app-grid-server-side
    #appHistoryCashGridComponent
    (areRowsSelected)="isSelected=$event"
    (clickableAction)="actions($event)"
    (rowClicked)="rowClicked($event)"
    [fixedButtons]="fixedButtons"
    [cacheBlockSize]="query.per_page"
    [datasource]="datasource"
    [tableData]="tableData[0]"
    feature="history_cash_movements"
    [exportFormatterOptions]="exportFormatterOptions">
</app-grid-server-side>