import {CustomForm} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {Customers} from 'tilby-models';
import {ProvValidator} from "@tilby/tilby-ui-lib/utilities";
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';

interface CustomersFieldsFe{
    isNew?:boolean;
}

export type CustomersFields = Customers & CustomersFieldsFe;

export class CustomerDefault implements CustomersFields{
    avg_spent?: number;
    billing_city?: string;
    billing_country?: string;
    billing_number?: string;
    billing_prov?: string;
    billing_street?: string;
    billing_zip?: string;
    birthdate?: Date;
    company_name?: string;
    country?: string;
    readonly created_at?: Date;
    createdby_id?: number;
    credit?: number;
    custom_1?: string;
    custom_2?: string;
    custom_3?: string;
    custom_4?: string;
    custom_5?: string;
    custom_6?: string;
    custom_7?: string;
    custom_8?: string;
    custom_9?: string;
    custom_type?: string;
    default_pricelist?: number;
    readonly deleted_at?: Date;
    deletedby_id?: number;
    disable_mail_receipts?: boolean;
    discount_perc?: number;
    email?: string;
    email_pec?: string;
    external_id?: string;
    facebook?: string;
    fdem_mail_list_contact?: number;
    fdem_sms_list_contact?: number;
    fidelity?: string;
    first_name?: string;
    gender?: string;
    readonly id?: number;
    image?: string;
    instagram?: string;
    isNew?: boolean;
    last_name?: string;
    linkedin?: string;
    lottery_code?: string;
    mobile?: string;
    notes?: string;
    payment_token?: string;
    payments_allowed?: string;
    phone?: string;
    sales_count?: number;
    sclobypass_id?: string;
    sdi_code?: string;
    send_commercials?: boolean;
    shipping_city?: string;
    shipping_city_1?: string;
    shipping_city_2?: string;
    shipping_city_3?: string;
    shipping_city_4?: string;
    shipping_city_5?: string;
    shipping_city_6?: string;
    shipping_city_7?: string;
    shipping_city_8?: string;
    shipping_city_9?: string;
    shipping_country?: string;
    shipping_country_1?: string;
    shipping_country_2?: string;
    shipping_country_3?: string;
    shipping_country_4?: string;
    shipping_country_5?: string;
    shipping_country_6?: string;
    shipping_country_7?: string;
    shipping_country_8?: string;
    shipping_country_9?: string;
    shipping_number?: string;
    shipping_number_1?: string;
    shipping_number_2?: string;
    shipping_number_3?: string;
    shipping_number_4?: string;
    shipping_number_5?: string;
    shipping_number_6?: string;
    shipping_number_7?: string;
    shipping_number_8?: string;
    shipping_number_9?: string;
    shipping_prov?: string;
    shipping_prov_1?: string;
    shipping_prov_2?: string;
    shipping_prov_3?: string;
    shipping_prov_4?: string;
    shipping_prov_5?: string;
    shipping_prov_6?: string;
    shipping_prov_7?: string;
    shipping_prov_8?: string;
    shipping_prov_9?: string;
    shipping_street?: string;
    shipping_street_1?: string;
    shipping_street_2?: string;
    shipping_street_3?: string;
    shipping_street_4?: string;
    shipping_street_5?: string;
    shipping_street_6?: string;
    shipping_street_7?: string;
    shipping_street_8?: string;
    shipping_street_9?: string;
    shipping_zip?: string;
    shipping_zip_1?: string;
    shipping_zip_2?: string;
    shipping_zip_3?: string;
    shipping_zip_4?: string;
    shipping_zip_5?: string;
    shipping_zip_6?: string;
    shipping_zip_7?: string;
    shipping_zip_8?: string;
    shipping_zip_9?: string;
    source_channel?: string;
    source_store?: string;
    tax_code?: string;
    thumbnail?: string;
    tot_spent?: number;
    twitter?: string;
    readonly updated_at?: Date;
    updatedby_id?: number;
    uuid?: string;
    vat_code?: string;

}

export type CustomersFormGroups = {
    generalForm:CustomFormGroup<CustomersForm>,
    addressesForm:CustomFormGroup<AddressesFormGroups&{currentShippingAddress:any}>,
    optionsForm:CustomFormGroup<CustomersForm&{get_source:any}>,
}
export type AddressesFormGroups = {
    billingForm:CustomFormGroup<CustomersForm>,
    shippingForm?:CustomFormGroup<CustomersForm>,
    shippingForm_1?:CustomFormGroup<CustomersForm>,
    shippingForm_2?:CustomFormGroup<CustomersForm>,
    shippingForm_3?:CustomFormGroup<CustomersForm>,
    shippingForm_4?:CustomFormGroup<CustomersForm>,
    shippingForm_5?:CustomFormGroup<CustomersForm>,
    shippingForm_6?:CustomFormGroup<CustomersForm>,
    shippingForm_7?:CustomFormGroup<CustomersForm>,
    shippingForm_8?:CustomFormGroup<CustomersForm>,
    shippingForm_9?:CustomFormGroup<CustomersForm>,
}

// export type CustomersForm = Form<CustomersFields>;
export type CustomersFieldsNullable = { [prop in string & keyof CustomersFields]:CustomersFields[prop]|null }
export type CustomersForm = Partial<CustomForm<CustomersFieldsNullable>>;

export type VatValidateStatus = 'default'|'found'|'invalid';

export interface CustomerValidators extends ProvValidator {
    cityMaxLength: number;
    zipMaxLength: number;
    numberMaxLength: number;
    streetMaxLength: number;
    discountPercMin: number;
    discountPercMax: number;
    fidelityMaxLength: number;
    companyNameMaxLength: number,
    firstNameMaxLength: number,
    lastNameMaxLength: number,
    taxCodeMaxLength: number,
    sdiCodeMinLength: number,
    sdiCodeMaxLength: number,
    lotteryCodeMinLength: number,
    lotteryCodeMaxLength: number,
    phoneMaxLength: number,
    mobileMaxLength: number,
    emailMaxLength: number,
    emailPecMaxLength: number,
    socialMaxLength: number,
}

export interface PriceList { name: string; id: number | null }
