<tilby-dialog-toolbar mat-dialog-title
    [title]="dialogTitle"
    [hideConfirm]="fields.selectedOptions.selected.length < 1"
    (confirm)="confirm()"
/>
<tilby-dialog-content>
    <mat-selection-list #fields [(ngModel)]="selectedOptions">
        @for (field of signupFormFields; track field) {
        <mat-list-option [value]="field">{{field.label | translate}}</mat-list-option>
        }
    </mat-selection-list>

    @if (signupFormFields.length === 0) {
        <p translate>DIALOG.SIGNUP_FORM_DIALOG.NO_FIELDS</p>
    }
</tilby-dialog-content>
