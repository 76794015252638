import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EntityManagerService} from "../../core/services";
import {Printers} from "tilby-models";
import {MatSelectionListChange} from "@angular/material/list";

@Component({
    selector: 'app-general-document-printer-selector-dialog',
    templateUrl: './general-document-printer-selector-dialog.component.html',
    styleUrls: ['./general-document-printer-selector-dialog.component.scss']
})
export class GeneralDocumentPrinterSelectorDialogComponent {
    printers:Printers[]=[];
    constructor(
        public dialogRef: MatDialogRef<GeneralDocumentPrinterSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {titleLabel:string},
        private entityManagerService:EntityManagerService
    ) {
        this.entityManagerService.printers.fetchCollectionOffline({type:'fiscal'})
            .then((printers) => {
                this.printers = this.printers.concat(printers);
                return this.entityManagerService.printers.fetchCollectionOffline({ type: 'rt' });
            })
            .then((printers) => {
                this.printers = this.printers.concat(printers);
                return this.entityManagerService.printers.fetchCollectionOffline({ type: 'receipt' });
            })
            .then((printers) => {
                this.printers = this.printers.concat(printers);
            });
    }

    cancel() {
        this.dialogRef.close({cancel: true});
    };


    selectPrinter(printer: Printers) {
        this.dialogRef.close(printer);
    }

    selectionChange($event: MatSelectionListChange) {
        this.selectPrinter($event.source.selectedOptions.selected[0].value)
    }
}
