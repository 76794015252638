    <div class="tw-flex tw-flex-col">
        <div id="venduto" class="tw-py-6 tw-px-6">
            <div class="tw-text-sm tw-px-2 tw-py-1 tw-w-min tw-rounded tw-bg-yellow-200 tw-font-bold" translate>HISTORY.SALES.DETAIL.SOLD</div>
            <div title="etichetta_prodotto"
                 *ngFor="let sale_item of sale?.sale_items; trackBy: fieldByIdx"
                 class="tw-flex-col tw-py-4">
                <div>
                    <div class="tw-flex tw-flex-row tw-justify-between">
                        <div>{{(sale_item.name ||'').toTitleCase()}}</div>
                        <div class="">{{(sale_item.price*sale_item.quantity)|tilbyCurrency}}</div>
                    </div>
                    <div *ngIf="sale_item.type === 'gift'" class="" translate>HISTORY.SALE.GIFT</div>
                    <div class="sale-item-notes tw-flex tw-items-center tw-font-semibold">{{sale_item.quantity}}<span class="tw-text-[0.6rem] tw-m-[0.1rem]">x</span>{{sale_item.price}}</div>
                    <div class="tw-flex tw-flex-row tw-justify-between tw-text-xs tw-text-gray-600" *ngFor="let variation of sale_item.variations">
                        <span>{{ variation.name }} - {{ variation.value }}</span>
                        <span>{{ ((sale_item.quantity || 0) * (variation.price_difference || 0) | tilbyCurrency) || '-' }}</span>
                    </div>
                    <div class="tw-flex tw-flex-row tw-justify-between tw-text-xs tw-text-gray-600" *ngFor="let ingredient of sale_item.ingredients">
                        <span>{{ ingredient.type === 'removed' ? '-' : '+' }} {{ ingredient.quantity !== undefined && ingredient.quantity > 1 ? ingredient.quantity + 'x' : '' }} {{ ingredient.name }}</span>
                        <span *ngIf="ingredient.price_difference">
                          {{ (sale_item?.quantity || 0) * (ingredient.price_difference || 0) * (ingredient.quantity || 0) | tilbyCurrency }}
                        </span>
                    </div>
                    <div class="sale-item-notes">{{sale_item.notes}}</div>
                    <div *ngIf="sale_item.type === 'refund'" class="">{{sale_item.refund_cause_description}}</div>
                </div>
                <!--            <div *ngFor="let pc of sale_item.price_changes | orderBy:'+index'" class="historymodule-item-change-price tw-flex tw-flex-row">-->
                <div *ngFor="let pc of sale_item.price_changes " class="tw-flex tw-flex-row">
                    <div style="width: 100%">
                        <div class="tw-flex tw-justify-between">
                        <span class="tw-flex tw-flex-row tw-flex-1">{{priceChangeDescription(pc)}}</span>
                            <span *ngIf="pc.type === 'discount_fix'">{{ pc.value | tilbyCurrency }}</span>
                            <span *ngIf="pc.type !== 'discount_fix'" class="tw-text-right">{{ $any(pc).amount | tilbyCurrency }}</span>
                        </div>
                    </div>
                </div>
            <div class="tw-italic">{{sale_item.seller_name}}</div>
            </div>

            <!--             class=" tw-flex tw-flex-row" *ngFor="let priceChange of sale.price_changes | orderBy:'+index'" -->
            <div title="etichetta_sconto_sul_tot"
                 class=" tw-flex tw-flex-row tw-py-5" *ngFor="let priceChange of sale?.price_changes"
            >
                <div style="width: 100%" *ngIf="priceChange.type !== 'disc_perc_nd'">
                    <div class="tw-flex tw-justify-between">
                        <div [ngClass]="{'tw-text-right': priceChange.type!=='discount_fix'}">{{priceChangeDescription(priceChange)}}</div>
                        <div *ngIf="priceChange.type === 'discount_fix'" class="tw-text-right">{{ priceChange.value | tilbyCurrency }}</div>
                        <div *ngIf="priceChange.type !== 'discount_fix'" class="tw-text-right">{{ $any(priceChange).amount | tilbyCurrency }}</div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div id="pagamenti" class="tw-py-6 tw-px-6" *ngIf="hasPayments(sale)">
            <div class="tw-text-sm tw-px-2 tw-py-1 tw-rounded tw-w-min tw-bg-green-200 tw-font-bold" translate>HISTORY.SALES.DETAIL.PAYMENTS</div>
            <div title="etichetta_tot_pagato" class="tw-py-4 tw-justify-between tw-mt-0.5"
                 *ngIf="sale?.payments && sale?.payments?.length">
                <!--            <div class="historymodule-green-color">-->
                <!--                {{ 'HISTORY.SALE.PAID' | translate }}:-->
                <!--            </div>-->
                <div class="tw-flex tw-flex-row tw-pt-4" *ngFor="let payment of sale?.payments">
                    <div class="tw-flex tw-flex-1">{{payment.payment_method_name}}</div>
                    <div class="">{{ payment.amount | tilbyCurrency }}</div>
                </div>
                <div class="tw-flex tw-flex-row tw-pt-4" *ngIf="sale?.change">
                    <div class="tw-flex tw-flex-1" translate>HISTORY.SALE.CHANGE</div>
                    <div class="">{{ sale?.change||0 | tilbyCurrency }}</div>
                </div>
            </div>
        </div>

    </div>
