import {Inject, Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ConfigurationManagerService, EntityManagerService} from "src/app/core/services";
import * as _ from 'lodash';
import {Sales} from "tilby-models";
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import {$state, saleUtils} from "app/ajs-upgraded-providers";

@Injectable()
export class HistoryUtilsService {

    constructor(private configurationManagerService: ConfigurationManagerService,
                private entityManagerService: EntityManagerService,
                private translate: TranslateService,
                private tilbyDatePipe: TilbyDatePipe,
                @Inject($state)private $stateService: any,
                @Inject(saleUtils)private saleUtilsService: any) {

    }

    public async createSale(saleData:Partial<Sales>&{overrides?:any}, options?: any) : Promise<any> {
        if(!_.isObject(options)) {
            options = {};
        }

        let sale = await this.saleUtilsService.getSaleTemplate();
        let isItalianFiscalization = ['IT', 'MT'].includes(this.configurationManagerService.getShopCountry());

        if(saleData.e_invoice){
            sale.e_invoice = _.omit(options.e_invoice, ['id', 'sale_id', 'invoice_progressive', 'invoice_yearly_progressive', 'send_progressive', 'date', 'lastcreate_at', 'external_id', 'external_message', 'external_status', 'intermediary_name', 'last_status_update', 'status']);
        }

        if(saleData.price_changes) {
            sale.price_changes = this.saleUtilsService.getCleanSubEntity(saleData.price_changes);
            options.keepPriceChanges = true;
        }

        if(Array.isArray(saleData.sale_items)) {
            sale.sale_items = saleData.sale_items.map((saleItem) => {
                const newSaleItem = this.saleUtilsService.getCleanSaleItem(saleItem, options.keepPriceChanges);

                for(let priceChange of newSaleItem.price_changes) {
                    delete priceChange.promotion_id;
                }

                return newSaleItem;
            });
        }

        sale.payments = this.saleUtilsService.getCleanSubEntity(saleData.payments);

        if (_.isObject(saleData.sale_customer)) {
            sale.sale_customer = _.chain(saleData.sale_customer).omit('id').cloneDeep().value();
        } else {
            sale.sale_customer = null;

            if(_.isString(saleData.sale_customer)) {
                sale.customer_tax_code = saleData.sale_customer;
            }
        }

        switch(options.saleType) {
            case 'credit_note': case 'e_credit_note':
                sale.name = _.truncate(saleData.name, { length: 27, omission: '' }) + " NC";
            break;
            case 'refund_doc':
                sale.name = _.truncate(saleData.name, { length: 27, omission: '' }) + " RS";
            break;
            case 'void_doc':
                sale.name = _.truncate(saleData.name, { length: 27, omission: '' }) + " AN";
            break;
            case 'summary':
                sale.name = this.translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + sale.sale_number + " (R)";
                sale.is_summary = true;
            break;
            case 'summary_e_rc': case 'summary_e_nrc':
                sale.name = this.translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + sale.sale_number + " (RE)";
                sale.is_summary = true;
            break;
            default:
                sale.name = this.translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + sale.sale_number;
            break;
        }

        if (this.configurationManagerService.isModuleEnabled('cashregister')) {
            sale.assigned_id = sale.seller_id;
            sale.assigned_name = sale.seller_name;
        }

        if(['summary_e_rc', 'summary_e_nrc'].includes(options.saleType) && isItalianFiscalization) {
            let scontrini:any = {};

            _.forEach(sale.sale_items, (item) => {
                let seqNum = item.reference_sequential_number || item.reference_text;

                scontrini[seqNum] = {
                    date: this.tilbyDatePipe.transform(item.reference_date),
                    type: _.isString(seqNum) && /[0-9]{4}-[0-9]{4}/.test(seqNum) ? "Doc.Com" : "Scontrino"
                };
            });

            let cause: string[] = [];

            _.forIn(scontrini, (data : any, number) => cause.push(`Rif. ${data?.type} n. ${number} del ${data?.date}`));

            sale.e_invoice = {
                invoice_cause: cause.join('\n')
            };
        }

        if(_.isObject(saleData.overrides)) {
            Object.assign(sale, saleData.overrides);
        }

        this.saleUtilsService.calculateSalePrices(sale);

        return new Promise(async (resolve, reject) => {
            resolve(sale);

            if(!options.returnOnly) {
                let saleType = options.saleType;

                if(!isItalianFiscalization) {
                    saleType = sale.is_summary ? 'generic_invoice' : 'generic_receipt';
                }

                await this.entityManagerService.sales.postOneOfflineFirst(sale);

                if (this.configurationManagerService.isModuleEnabled('cashregister')) {
                    const targetState = this.configurationManagerService.isModuleAngular('tables_and_cashregister') ? 'app.new.cashregister.content.showcase' : 'app.cashregister.content.showcase';

                    this.$stateService.go(targetState, {
                        action: 'open-sale-id',
                        saleType: saleType,
                        id: sale.uuid
                    });
                }
            }
        });
    }
}
