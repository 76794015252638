import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('orders').controller('ActiveOrderCtrl', ActiveOrderCtrl);

ActiveOrderCtrl.$inject = ["$scope", "$stateParams", "$timeout", "$translate", "$filter", "ActiveOrder", "checkManager", "confirmDialog", "coverConfig", "radioListSelector", "printerErrorOrders", "$mdMedia", "$rootScope", "alertDialog", "editOrder", "parkedOrders", "linkTableToOrder", "mergeOrders", "addSelectCustomer", "orderItemManager", "orderPrinterSelect", "entityManager", "environmentInfo", "ExternalOrdersManager", "util", "orderUtils", "deliveryChannels", "errorsLogger", "documentPrinter"];

function ActiveOrderCtrl ($scope, $stateParams, $timeout, $translate, $filter, ActiveOrder, checkManager, confirmDialog, coverConfig, radioListSelector, printerErrorOrders, $mdMedia, $rootScope, alertDialog, editOrder, parkedOrders, linkTableToOrder, mergeOrders, addSelectCustomer, orderItemManager, orderPrinterSelect, entityManager, environmentInfo, ExternalOrdersManager, util, orderUtils, deliveryChannels, errorsLogger, documentPrinter) {
    const applyCoverToTotal = checkManager.getPreference('orders.apply_cover_to_total');
    const exits = _.toInteger(checkManager.getPreference('orders.exits')) || 5;
    const canDeleteOrders = checkManager.isUserPermitted('orders.delete_order');
    const canDeleteSentOrders = checkManager.isUserPermitted('orders.delete_sent_orders');

    Object.assign($scope, {
        order: ActiveOrder,
        extOrdersMan: ExternalOrdersManager,
        orderSendStatus: 'READY',
        exitSendStatus: _.fill(Array(exits), 'READY'),
        canAddCustomer: checkManager.isUserPermitted("orders.add_customer"),
        canAddNewItem: checkManager.isUserPermitted("cashregister.add_new_item"),
        canEditSentOrders: checkManager.isUserPermitted('orders.edit_sent_orders'),
        canSendCheckout: false,
        isExitEnabled: checkManager.getPreference('orders.enable_exits') !== false,
        isTablesModuleEnabled: checkManager.isModuleEnabled('tables'),
        deliveryChannels: _.keyBy(deliveryChannels, 'id'),
        displayMode: checkManager.getPreference("showcase_display_mode") || 'normal'
    });

    $scope.ePOSMode = checkManager.getPreference("nofiscalprinter.driver") === 'epos';
    $scope.canSendOrder = environmentInfo.canUseTcpSockets() || $scope.ePOSMode;
    $scope.canSendPreorder = $scope.canSendOrder && checkManager.isUserPermitted('cashregister.preorder');

    $scope.isDisplayModeNormal = function() {
        return $scope.displayMode === 'normal';
    };

    $scope.isDisplayModeCompact = function() {
        return $scope.displayMode === 'compact';
    };

    const halfPortionPref = _.toNumber(checkManager.getPreference("orders.half_portion_discount_value"));
    const halfPortionValue = _.isFinite(halfPortionPref) ? util.round(halfPortionPref / 100) : 0.5;

    var getReasonsArray = function(sourcePreference) {
        let reasonsString = _.toString(sourcePreference);
        let reasonsArr = [];

        if(!_.isEmpty(reasonsString)) {
            _.forEach(_.split(reasonsString, '\n'), function(reason, idx) {
                reasonsArr.push({ id: idx + 1, name: reason });
            });
        }

        return reasonsArr;
    };

    var exitLabel = $translate.instant("ORDERS.ACTIVE_ORDER.EXIT_LABEL") + " ";

    $scope.exits = [{
        name: "",
        value: null
    }];

    _.times(exits, function(i) {
        let exit = i + 1;

        $scope.exits.push({
            name: exitLabel + exit,
            value: exit
        });
    });

    $scope.getDeliverDate = function(order) {
        let now = moment();
        let deliverAt = moment(order.deliver_at);

        let timeString = deliverAt.format('LT');

        if(deliverAt.dayOfYear() !== now.dayOfYear() || deliverAt.year() !== now.year()) {
            timeString = _.upperFirst(deliverAt.fromNow()).replace('In un giorno', 'Domani') + ', ' + timeString;
        }

        return timeString;
    };

    $scope.getOrderItemPrice = (orderItem) => $filter('sclCurrency')(orderItem.price * orderItem.quantity * (orderItem.half_portion ? (1 - halfPortionValue) : 1));

    $scope.showNewOrder = function() {
        $scope.order.createNewOrder();
    };

    $scope.toggleOrderView = function() {
        $scope.viewOptions.hideOrder = !$scope.viewOptions.hideOrder;
    };

    $scope.canEditOrder = () => ($scope.isActiveOrder() && ($scope.canEditSentOrders || !$scope.order.currentOrder.last_sent_at));

    $scope.showEditOrder = async function() {
        if (!$scope.canEditOrder()) {
            return;
        }

        let order = await editOrder.show($scope.order.currentOrder);
        $scope.order.editOrder(order);
    };

    $scope.showParkedOrder = async function() {
        try {
            let order = await parkedOrders.show();
            $scope.order.switchOrdersInParking(_.cloneDeep(order));
        } catch (error) {
            // console.log(error);
            errorsLogger.debug("error in show park",error);
        }
    };

    $scope.canMergeOrders = () => ($scope.canEditSentOrders || !$scope.order.currentOrder.last_sent_at);

    $scope.showMergeOrders = async function() {
        if (!$scope.canMergeOrders()) {
            return;
        }

        let proceed = !$scope.order.isActiveOrder();

        if(!proceed) {
            proceed = await confirmDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.MERGE_UNSAVED_CONFIRM'));
        }

        if(proceed) {
            let ordersListAndTable = await mergeOrders.show();
            $scope.order.mergeOrders(ordersListAndTable);
        }
    };

    $scope.getNotes = function() {
        return $scope.order.currentOrder.notes || '';
    };

    $scope.canSplitOrder = () => ($scope.isActiveOrder() && ($scope.canEditSentOrders || !$scope.order.currentOrder.last_sent_at));

    $scope.splitOrder = function() {
        if (!$scope.canSplitOrder()) {
            return;
        }

        $scope.order.splitOrderFunc();
    };

    $scope.canDeleteOrder = () => {
        if(!canDeleteOrders) {
            return false;
        }

        if(!canDeleteSentOrders && $scope.order.currentOrder.last_sent_at) {
            return false;
        }

        return true;
    };

    $scope.deleteOrder = async function() {
        if ($scope.order.isActiveOrder()) {
            let proceed = await confirmDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.DELETE_CONFIRM'));

            if (proceed) {
                if(checkManager.getSetting('orders.delete_order_needs_reason')) {
                    let reasonsArr = getReasonsArray(checkManager.getSetting('orders.delete_order_reasons'));

                    if(!_.isEmpty(reasonsArr)) {
                        let answer = await radioListSelector.show(reasonsArr, { label: $translate.instant('ORDERS.ACTIVE_ORDER.DELETE_REASON') });

                        if(answer) {
                            $scope.order.deleteOrder({ reason: answer.name });
                        }
                    } else {
                        await alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.MISCONFIGURED_DELETE_REASONS'));
                        throw 'MISCONFIGURED_DELETE_REASONS';
                    }
                } else {
                    $scope.order.deleteOrder();
                }
            }
        }
    };

    $scope.getOrderCaption = function() {
        return orderUtils.getOrderCaption($scope.order.currentOrder, $scope.deliveryChannels);
    };

    $scope.getCustomerCaption = function() {
        return util.getCustomerCaption($scope.order.currentOrder.order_customer);
    };

    $scope.getCurrentAmount = function() {
        let currentAmount = $scope.order.currentOrder.amount;

        if(applyCoverToTotal) {
            currentAmount += orderUtils.getCoverValue($scope.order.currentOrder, coverConfig);
        }

        return $filter('sclCurrency')(currentAmount);
    };

    $scope.isActiveOrder = $scope.order.isActiveOrder;

    $scope.isExitSelected = function(exit) {
        return exit === $scope.order.activeExit;
    };

    $scope.setExit = function(exit) {
        if (exit === $scope.order.activeExit) {
            $scope.order.activeExit = null;
        } else {
            $scope.order.activeExit = exit;
        }
    };

    $scope.isExitSent = function(exit) {
        if($scope.exitSendStatus[exit.value - 1] === 'SENDING') {
            return 'sending';
        } else {
            return $scope.order.isExitSent(exit) ? 'sent' : 'pending';
        }
    };

    $scope.parkActiveOrder = function() {
        if ($scope.orderSendStatus === 'READY') {
            return $scope.order.parkActiveOrder();
        }
    };

    $scope.canLinkTable = () => ($scope.isActiveOrder() && ($scope.canEditSentOrders || !$scope.order.currentOrder.last_sent_at) && $scope.order.currentOrder.type === "normal");

    $scope.showLinkTableToOrder = async () => {
        if (!$scope.canLinkTable()) {
            return;
        }

        let tableInfo = await linkTableToOrder.show();

        $scope.order.linkTableToOrder(tableInfo);
    };

    $scope.showAddCustomer = async function() {
        if ($scope.isActiveOrder() && $scope.canAddCustomer) {
            let customer = await addSelectCustomer.show($scope.order.currentOrder.order_customer);
            $scope.order.addCustomer(customer);
        }
    };

    $scope.showRemoveCustomer = async function() {
        let answer = await confirmDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.REMOVE_CUSTOMER_CONFIRM'));

        if (answer) {
            ActiveOrder.removeCustomer();
        }
    };

    $scope.incrementQty = function(orderItem) {
        $scope.order.editOrderItem(orderItem, { quantity: orderItem.quantity + 1 });
    };

    var beforeItemDeletion = async function(orderItem, info) {
        if(!_.isObject(info)) {
            info = {};
        }

        if(!canDeleteSentOrders && $scope.order.currentOrder.last_sent_at) {
            let previousItems = $scope.order.currentOrder.previous_order?.order_items;
            let previousItem = _.find(previousItems, { uuid: orderItem.uuid });

            if(previousItem) {
                try {
                    switch(info.action) {
                        case 'delete':
                            throw 'CANNOT_DELETE_SENT_ITEMS';
                        case 'decrement': {
                            if(info.quantity < previousItem.quantity) {
                                throw 'CANNOT_DELETE_SENT_ITEMS';
                            }
                        }
                    }
                } catch(err) {
                    await alertDialog.show($translate.instant(`ORDERS.ACTIVE_ORDER.${err}`));
                    throw err;
                }
            }
        }

        if(checkManager.getSetting('orders.delete_item_needs_reason') && orderItem.id && !_.get($scope, ['order', 'currentOrder', 'deleted_items', orderItem.uuid])) {
            let reasonsArr = getReasonsArray(checkManager.getSetting('orders.delete_order_reasons')); //Same reasons as order

            if(!_.isEmpty(reasonsArr)) {
                let answer = await radioListSelector.show(reasonsArr, { label: $translate.instant('ORDERS.ACTIVE_ORDER.DELETE_REASON') });

                _.set($scope, ['order', 'currentOrder', 'deleted_items', orderItem.uuid], {
                    reason: answer.name
                });
            } else {
                await alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.MISCONFIGURED_DELETE_REASONS'));
                throw 'MISCONFIGURED_DELETE_REASONS';
            }
        }
    };

    $scope.decrementQty = async function(orderItem) {
        //Disable decrement on order items that are part of a menu that is not being edited
        if(orderItem.order_item_parent_uuid && orderItem.order_item_parent_uuid !== ActiveOrder.groupItemUuid) {
            return;
        } else if (!_.isInteger(orderItem.quantity) || orderItem.quantity <= 1) {
            $scope.removeOrderItem(orderItem);
        } else {
            let newQuantity = orderItem.quantity - 1;
            await beforeItemDeletion(orderItem, { action: 'decrement', quantity: newQuantity });
            $scope.order.editOrderItem(orderItem, { quantity: newQuantity });
        }
    };

    $scope.removeOrderItem = async function(orderItem) {
        let answer = await confirmDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.REMOVE_ITEM_CONFIRM', { itemName: orderItem.name, itemQuantity: orderItem.quantity }));

        if (answer) {
            await beforeItemDeletion(orderItem, { action: 'delete' });
            $scope.order.removeOrderItem(orderItem);
        }
    };

    $scope.isOrderItemOnExit = function(exit) {
        return _.find($scope.order.currentOrder.order_items, { exit: exit });
    };

    $scope.onOrderItemTap = async function(orderItem) {
        //If we are dealing with a menu item simply re-enable menu mode
        if(orderItem.is_group_item) {
            //Find the related item and enable menu mode if the item doesn't split group components
            if(orderItem.item_id) {
                let relatedItem = await entityManager.items.fetchOneOffline(orderItem.item_id);

                if(relatedItem?.split_group_components) {
                    return;
                }
            }

            ActiveOrder.groupItemUuid = orderItem.uuid;
            return;
        }

        let disableUnbundle = false;

        if(!canDeleteSentOrders && $scope.order.currentOrder.last_sent_at) {
            let previousItems = $scope.order.currentOrder.previous_order?.order_items;
            let previousItem = _.find(previousItems, { uuid: orderItem.uuid });

            if(previousItem) {
                disableUnbundle = true;
            }
        }

        let answer = await orderItemManager.show(orderItem, { disableUnbundle: disableUnbundle });

        //If the quantity is 0 and there is not an unbundled item, consider this an item deletion
        if(!answer.rowItem.quantity && !answer.unbundledRowItem) {
            return $scope.removeOrderItem(orderItem);
        }

        if(answer.rowItem.quantity < orderItem.quantity && !answer.unbundledRowItem) {
            await beforeItemDeletion(orderItem, { action: 'decrement', quantity: answer.rowItem.quantity });
        }

        if (answer.unbundledRowItem) {
            $scope.order.addOrderItem(answer.unbundledRowItem);
        }

        $scope.order.editOrderItem(orderItem, answer.rowItem, { replace: true });
    };

    $scope.isOrderSendable = function() {
        if ($scope.order.currentOrder.order_items.length) {
            return true;
        } else {
            return $scope.order.changed;
        }
    };

    $scope.sendOrderToPrinter = async function(options) {
        if ($scope.orderSendStatus === 'READY') {
            $scope.orderSendStatus = 'SENDING';

            let success = await $scope.order.sendOrderToPrinter(undefined, options);

            if(success) {
                $scope.orderSendStatus = 'SUCCESS';
                $timeout(function() {
                    $scope.orderSendStatus = 'READY';
                }, 3000);
            } else {
                $scope.orderSendStatus = 'READY';
            }
        }
    };

    $scope.sendExitToPrinter = async function(exit) {
        if($scope.exitSendStatus[exit - 1] === 'READY') {
            $scope.exitSendStatus[exit - 1] = 'SENDING';
            await $scope.order.sendExitToPrinter(exit);
            $scope.exitSendStatus[exit - 1] = 'READY';
        }
    };

    $scope.printNonFiscalOrder = async function(forceSelect) {
        const PREFERENCE_DEFAULT_PRINTER_ID = 'nofiscalprinter.default_preorder';
        if(_.isEmpty($scope.order.currentOrder.order_items) && !(checkManager.getPreference('orders.strooka_shared_key') && $scope.order.currentOrder.table_id)) {
            await alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.NON_FISCAL_NO_PRODUCTS'));
        } else {
            const defaultPrinterId = _.toInteger(checkManager.getPreference(PREFERENCE_DEFAULT_PRINTER_ID));
            let printer;

            if(defaultPrinterId && !forceSelect) {
                printer = await entityManager.printers.fetchOneOffline(defaultPrinterId);
                let currentDriver = $scope.ePOSMode ? 'epos' : 'escpos';

                if(_.get(printer, 'driver') !== currentDriver) {
                    printer = null;
                }
            }

            if(!printer) {
                const printers = await entityManager.printers.fetchCollectionOffline().then((printers) => printers.filter(printer => (printer.type == 'nonfiscal') && documentPrinter.isPrinterUsable(printer)));

                const { setAsDefault } = ({ selectedPrinter: printer } = await orderPrinterSelect.openDialog({ data: { printers, defaultPrinterId } }));

                if(!printer) {
                    return;
                }

                if(setAsDefault) {
                    checkManager.setUserPreference(PREFERENCE_DEFAULT_PRINTER_ID, printer.id);
                }
            }

            $scope.order.printNonFiscalOrder(printer);
        }
    };

    $scope.sendCheckout = async function() {
        if ($scope.orderSendStatus === 'READY') {
            if($scope.order.isEmpty()) {
                alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.ORDER_EMPTY'));
            } else {
                let proceed = checkManager.getPreference("orders.skip_checkout_confirm");

                if(!proceed) {
                    proceed = await confirmDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.CHECKOUT_CONFIRM'));
                }

                if(proceed) {
                    $scope.order.checkoutOrder();
                }
            }
        }
    };

    $scope.$on("activeOrder:item-added", function(event, item) {
        $timeout(() => {
            const element = angular.element(`#${item.uuid}`);

            if(!element.length) {
                return;
            }

            element[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
    });

    $scope.$on("activeOrder:changed", function(event, action) {
        switch (action) {
            case 'updated':
                alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.EXT_ORDER_UPDATED'),{multiple:false});
                break;
            case 'checkout':
                alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.EXT_ORDER_CHECKOUT'),{multiple:false});
                break;
            case 'deleted':
                alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.EXT_ORDER_CLOSED'),{multiple:false});
                break;
            case 'closed':
                alertDialog.show($translate.instant('ORDERS.ACTIVE_ORDER.EXT_ORDER_CLOSED'),{multiple:false});
                break;
            default:
                break;
        }
    });

    $scope.$on('sendOrder', function(data, event) {
        $scope.sendOrderToPrinter({ goToTables: false });
    });

    $scope.$on("createOrderFromTable", function(event, params) {
        $scope.order.createNewOrder(params);
    });

    if($stateParams.orderId) {
        ActiveOrder.loadOrderById($stateParams.orderId);
        _.assign($stateParams, {
            orderId: null,
            type: null
        });
    } else if($stateParams.type) {
        $scope.order.createNewOrder($stateParams);
    }

    $scope.$on('orders:created', function(event, data) {
        if(_.includes(['take_away', 'delivery'], data.type)) {
            let showCustomerDelivery = data.type === 'delivery' && !checkManager.getPreference('orders.disable_delivery_add_customer');
            let showCustomerTakeAway = data.type === 'take_away' && !checkManager.getPreference('orders.disable_take_away_add_customer');

            if(showCustomerDelivery || showCustomerTakeAway) {
                $scope.showAddCustomer();
            }
        }
    });

    $scope.$on('order:printed', function(event, data) {
        if(data.printType === 'order') {
            if (printerErrorOrders.hasError(data.results)) {
                printerErrorOrders.show(data.results, _.chain(data.order.order_items).map('category_id').union().value());
            }
        }
    });

    $scope.$on('active-order:updateDisplayMode', function() {
        $scope.displayMode = checkManager.getPreference("showcase_display_mode") || 'normal';
    });

    $scope.isPortrait = function() {
        return $mdMedia('xs');
    };

    $scope.addNewItem = function() {
        $rootScope.$broadcast('orders:addNewItem');
    };

    $scope.canSendCheckout = true;

}
