import * as angular from 'angular';
import { StateParams, StateProvider } from 'angular-ui-router';
import * as _ from 'lodash';
import { ConfigurationManagerService } from 'src/app/core';

angular.module('fileExporterNew', ['application']);

angular.module('fileExporterNew').config(["$stateProvider", function($stateProvider: StateProvider) {

    $stateProvider.state('app.new.file_exporter', {
        url: '/file-exporter',
        redirectTo: 'app.new.file_exporter.showcase',
    });

    $stateProvider.state('app.new.file_exporter.showcase', {
        url: '/showcase',
        component:"appFileExporter",
    });
}]);