import {Component, Input} from '@angular/core';
import {TilbyGeneralInputButtonComponent} from "@tilby/tilby-ui-lib/components/tilby-buttons";
import {GeneralInputButton} from "@tilby/tilby-ui-lib/models";

@Component({
    selector: 'app-tilby-quick-coupon-buttons',
    standalone: true,
    imports: [
        TilbyGeneralInputButtonComponent
    ],
    templateUrl: './tilby-quick-coupon-buttons.component.html',
    styleUrl: './tilby-quick-coupon-buttons.component.scss'
})
export class TilbyQuickCouponButtonsComponent {
    @Input({required:true}) buttons:GeneralInputButton[]=[];
}
