import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('cashregister').factory('checkExternalStock', ["$q", "$mdDialog", "$rootScope", "$timeout", "$translate", "checkManager", "barcodeManager", "alertDialog", function($q, $mdDialog, $rootScope, $timeout, $translate, checkManager, barcodeManager, alertDialog) {

    checkExternalStockController.$inject = ["$scope", "$mdDialog", "$http", "stockSettings"];
    function checkExternalStockController($scope, $mdDialog, $http, stockSettings) {
        $scope.loginInProgress = false;
        $scope.stockDictionaryFilterResult = [];
        $scope.enabledField = 'barcode';

        var accessToken;

        $scope.search = async () => {
            $scope.stockDictionaryFilterResult = [];
            var filter = {};
            if ($scope.checkExternalStockForm.code.$dirty) {
                filter.code = $scope.code;
            } else if ($scope.checkExternalStockForm.barcode.$dirty) {
                const searchData = await barcodeManager.searchBarcodeItem($scope.barcode);
                const item = searchData.data[0];

                if(item) {
                    $scope.code = item.code;
                    filter.barcode = $scope.barcode;
                } else {
                    return;
                }
            }

            if (!_.isEmpty(filter)) {
                var getParam = {
                    Articolo: stockSettings.codePrefix + $scope.code,
                    Negozio: stockSettings.shop,
                    Rete: stockSettings.network
                };

                $http({
                    url: stockSettings.apiUrl,
                    method: "GET",
                    params: getParam,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }).then(function(result) {
                    $scope.stockDictionaryFilterResult = result.data;
                });
            }
        };

        $scope.enableField = function(field) {
            if (field !== $scope.enabledField) {
                $scope.stockDictionaryFilterResult = [];
                $scope.enabledField = field;
                $scope.barcode = undefined;
                $scope.code = undefined;
                $scope.checkExternalStockForm.$setPristine();
            }
        };

        $scope.formEmpty = function() {
            return $scope.barcode === undefined && $scope.code === undefined;
        };

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.onBarcodeScanned = function(result) {
            $scope.barcode = result;
            $scope.checkExternalStockForm.barcode.$setDirty();
            $scope.search();
        };

        var askAccessToken = function() {
            var d = $q.defer();

            var askAccessTokenParam = {
                grant_type: 'password',
                username: stockSettings.username,
                password: stockSettings.password
            };

            $scope.loginInProgress = true;

            $timeout(function() {
                $rootScope.$broadcast("loader:changeStatus", "externalStockCheckLoader", {
                    enabled: true
                });

                $http.post(stockSettings.tokenUrl, $.param(askAccessTokenParam), {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                }).then(function(result) {
                    return d.resolve(result.data.access_token);
                }, function(error) {
                    return d.reject(error);
                });
            });

            return d.promise;
        };

        askAccessToken().then(function(token){
            accessToken = token;
        }).finally(function() {
            $scope.loginInProgress = false;
            $rootScope.$broadcast("loader:changeStatus", "externalStockCheckLoader", {
                enabled: false
            });
        });

    }

    var getStockSettings = function() {
        var stockSettings = {
            apiUrl: checkManager.getSetting('external_stock.api_url'),
            tokenUrl: checkManager.getSetting('external_stock.token_url'),
            shop: checkManager.getSetting('external_stock.shop'),
            network: checkManager.getSetting('external_stock.network'),
            username: checkManager.getSetting('external_stock.username'),
            password: checkManager.getSetting('external_stock.password'),
            codePrefix: checkManager.getSetting('external_stock.prefix') || ''
        };

        if(stockSettings.apiUrl && stockSettings.tokenUrl && stockSettings.shop && stockSettings.network && stockSettings.username && stockSettings.password) {
            return stockSettings;
        } else {
            return undefined;
        }
    };

    var checkExternalStock = {
        show: function() {
            var settings = getStockSettings();

            if(settings) {
                return $mdDialog.show({
                    controller: checkExternalStockController,
                    template: require('./check-external-stock.html'),
                    locals: {
                        stockSettings: settings
                    }
                });
            } else {
                return alertDialog.show($translate.instant('CASHREGISTER.CHECK_EXTERNAL_STOCK.STOCK_NOT_CONFIGURED'));
            }
        }
    };

    return checkExternalStock;
}]);
