import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('alertDialog', ["$rootScope", "$mdDialog", function($rootScope, $mdDialog) {
    alertDialogController.$inject = ["$scope", "$mdDialog", "message", "confirmLabel"];

    function alertDialogController($scope, $mdDialog, message, confirmLabel) {
        $scope.message = message;
        $scope.confirmLabel = confirmLabel;

        $scope.answer = function() {
            $mdDialog.hide();
        };
    }

    var alertDialog = {
        show: function(message, options) {
            $rootScope.hideAppLoader();

            var defaultOptions = {
                controller: alertDialogController,
                template: require('./alert-dialog.html'),
                locals: {
                    message: message,
                    confirmLabel: _.get(options, "confirmLabel") || "OK"
                },
                multiple: true
            };

            if (_.isObject(options)) {
                _.defaults(options, defaultOptions);

                if (options.blocking) {
                    options.clickOutsideToClose = false;
                    options.escapeToClose = false;
                }
            } else {
                options = defaultOptions;
            }

            return $mdDialog.show(options);
        }
    };

    return alertDialog;
}]);
