import { IScope } from 'angular';
import { Observable, Observer, Subscription } from 'rxjs';

export class AngularJSObservableHelper {
    public static attachObservableToScope($scope: IScope, observable: Observable<any>, observer: Partial<Observer<Event>>| ((value: any) => void)): Subscription {
        const subscription = observable.subscribe(observer);

        $scope.$on('$destroy', () => {
            subscription.unsubscribe();
        });

        return subscription;
    }
}
