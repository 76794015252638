import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').filter('sclCurrency', ["$rootScope", "util", function ($rootScope, util) {
    return function (amount, currencySymbol, fractionSize, symbolFirst) {
        if (_.isUndefined(currencySymbol)) {
            currencySymbol = $rootScope.currentCurrency.symbol_native;
        }

        if (_.isUndefined(fractionSize)) {
            fractionSize = $rootScope.currentCurrency.decimal_digits;
        }

        if(_.isNil(amount) || _.isNaN(amount)) {
            amount = 0;
        } else if(!_.isNumber(amount)) {
            amount = _.toNumber(amount);
        }

        var roundedNumber = util.round(amount, fractionSize).toFixed(fractionSize);
        var separatedNumber = roundedNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1###');

        var withDecimalSeparatorNumber = separatedNumber.replace('.', $rootScope.currentCurrency.decimal_separator || '.');
        var valueStr = withDecimalSeparatorNumber.replace(/###/g, $rootScope.currentCurrency.thousands_separator || '');

        if(!_.isEmpty(currencySymbol)) {
            if(!_.isNil(symbolFirst)) {
                return symbolFirst ? `${currencySymbol} ${valueStr}` : `${valueStr} ${currencySymbol}`;
            } else {
                return $rootScope.currentCurrency.symbol_first ? `${currencySymbol} ${valueStr}` : `${valueStr} ${currencySymbol}`;
            }
        }

        return valueStr;
    };
}]);
