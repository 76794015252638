import {Injectable, OnDestroy, signal} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, skip, Subject, takeUntil} from "rxjs";
import {
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    CustomFormGroupProps,
    KeyValueIconImage,
    ListItemGeneric
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {
    Channels,
    Customers,
    Rooms,
    RoomsTables,
    Sales,
    SalesCustomer
} from "tilby-models";
import {CustomForm} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {KeyValue} from '@angular/common';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationManagerService, FLOORS, NEW_TABLES, AllTablesShape, TableWithIcon, STATIC_ELEMENTS, AllStaticElementsShape, STATIC_ELEMENT_PREFIX} from 'src/app/core';
import { DevLogger } from 'src/app/shared/dev-logger';
import {TopbarTablesTotal} from "./components/topbar-tables-total";
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { ROOM_MULTIPLICATION_FACTOR } from './tables.component';
import { autobind } from 'src/app/models/decorators.model';

export interface DuplicateTableForm{
    new_name: string;
    row_elements: number;
    column_elements: number;
}

export type ShapeImage = {table?:TableWithIcon<AllTablesShape>,element?:TableWithIcon<AllStaticElementsShape>};

export type AddEditTable = {[prop in keyof Pick<RoomsTables, 'name'|'shape'|'covers'|'order_type'>]:RoomsTables[prop]}&{shapeImage:ShapeImage};

export type RoomForm = CustomForm<{[prop in keyof Pick<Rooms,'name'|'default_pricelist'|'floor'>]-?:Rooms[prop]}>;

export type OpenEditSaleDialogFormParams  = {
    id?: Sales['id'],
    name?: Sales['name'],
    saleType: Sales['order_type'] | Sales['external_id'],
    table?: {
        id : Sales['table_id'],
        covers : Sales['covers']
    },
    booking?: {
        id: Sales['booking_id']
    },
    order_id?: Sales['external_id'],
    delivery_at?: Sales['deliver_at'],
    channel?: Sales['channel'],
    customer?: Customers | SalesCustomer,
    longPress?:boolean,
    notes?:string,
}

type SaleFormFields = {
    type:string,
    name:string,
    delivery_at:string,
    channels:string,
    order_id:string,
    table_id:string,
    covers:number,
    booking_id:string,
    notes:string,
}

@Injectable({
    providedIn: 'root'
})
export class RoomsStateService implements OnDestroy{
    private readonly onDestroy$=new Subject<void>();
    tabIndex = signal(0);
    isEditMode = signal(false,{equal:(a,b)=>a==b});
    roomForm?: CustomFormGroup<RoomForm>;
    saleForm?: CustomFormGroup<CustomForm<SaleFormFields>>;
    tableForm?: CustomFormGroup<CustomForm<AddEditTable>>;
    copyTableForm?: CustomFormGroup<CustomForm<{new_name:string,row_elements:number,column_elements:number}>>;
    totalsArray$= new BehaviorSubject<TopbarTablesTotal[]>([]);

    selectedRoom: number = 0;

    constructor(
        protected translateService: TranslateService,
        private configurationManagerService :ConfigurationManagerService
    ) {}

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private log(...args:any[]){
        DevLogger.log('ROOM_STATE_SERVICE',...args);
    }


    public minRoomHeightWidth(roomSelected?:Rooms){
        let tmpTable = roomSelected?.tables?.reduce((maxTable : RoomsTables | null, table) => {
            if (!maxTable) return table;
            const currentTableValue = table.height + table.ypos;
            return currentTableValue > (maxTable.height + maxTable.ypos) ? table : maxTable;
        }, null);

        const minHeight = tmpTable ? (tmpTable.height + tmpTable.ypos + 40) : 40;

        tmpTable = roomSelected?.tables?.reduce((maxTable : RoomsTables | null, table) => {
            if (!maxTable) return table;
            const currentTableValue = table.width + table.xpos;
            return currentTableValue > (maxTable.width + maxTable.xpos) ? table : maxTable;
        }, null);

        const minWidth = tmpTable ? (tmpTable.width + tmpTable.xpos + 40) : 40;

        return {minHeight,minWidth};
    }
// START - ROOM FORM
    public createRoomForm(isEdit: boolean,roomSelected?:Rooms) {
        const sizeChoices: KeyValue<string, number>[] = Array.from({length: 48}, (_, i) => ({
            key: `${i + 3}m`,
            value: i + 3
        }));

        const floorChoices: KeyValueIconImage<string>[] = FLOORS.map((floor, _i) => ({
            key: '',
            value: floor.name,
            image: `assets/images/tables/${floor.imageUrl}`
        }));

        const pricesList: KeyValue<string, number>[] = [
            { key: 'CUSTOMERS.DETAILS.NONE', value: -1 },
            ...Array.from({ length: 10 }, (_, i) => ({
                key: this.configurationManagerService.getPreference(`price_list_${i+1}_name`) || 'ITEMS.DETAILS.PRICE_LIST_START_WITH_VALUE',
                value: i + 1
            }))
        ];

        const {minHeight,minWidth} = this.minRoomHeightWidth(roomSelected);

        const initValue = isEdit && roomSelected;

        this.roomForm = new CustomFormGroup<RoomForm>({
            name: new CustomFormControl(initValue?roomSelected?.name||'':'', {validators: [Validators.required, Validators.minLength(5), Validators.maxLength(15)]}, {
                ...new CustomFormControlProps(),
                label: `TABLES.${isEdit ? 'EDIT_ROOM' : 'NEW_ROOM'}.NAME`,
                hint: () => `${this.roomForm?.controls.name?.value?.length || 0}/15`,
                class: 'tw-w-full'
            }),
            default_pricelist: new CustomFormControl(initValue&&roomSelected?.default_pricelist?roomSelected?.default_pricelist:-1, {}, {
                ...new CustomFormControlProps(),
                label: `TABLES.${isEdit ? 'EDIT_ROOM' : 'NEW_ROOM'}.DEFAULT_PRICELIST`,
                inputType: 'select',
                class: 'tw-w-full',
                inputChoices: pricesList
            }),
            floor: new CustomFormControl(initValue?roomSelected?.floor:'parquet', {validators: [Validators.required]}, {
                ...new CustomFormControlProps(),
                label: `TABLES.${isEdit ? 'EDIT_ROOM' : 'NEW_ROOM'}.FLOOR`,
                inputType: 'radio',
                class: 'tw-w-full',
                inputChoices: floorChoices,
                id: 'room_floor_id'
            }),
        });
        return this.roomForm;
    }
// END - ROOM FORM

// START - SALE FORM
    // TODO : Vendita 1 - Attualmente messo a 1 nel giro order preso da orderTemplate, capire se ci sarà un corrispettivo dopo il merge tra orders e sales
    public createOrOpenSaleForm(params: OpenEditSaleDialogFormParams, deliveryChannels: Channels[], tables: (RoomsTables & {room: Rooms})[], isEdit:boolean) {

        const types = [{key: 'ORDERS.NEW_ORDER.NORMAL', value: 'normal'}, {
            key: 'ORDERS.NEW_ORDER.TAKE_AWAY',
            value: 'take_away'
        }, {key: 'ORDERS.NEW_ORDER.DELIVERY', value: 'delivery'}];

        const channels = deliveryChannels.map((item: { id: any; name: any; }) => {
            return {
                key: item.name,
                value: item.id,
                image: `assets/images/channels/${item.id}.png`,
                defaultImage: `assets/images/channels/pos.png`,
            };
        });

        const typeChoices: KeyValue<string, string>[] = Array.from(types);
        const channelChoices: KeyValueIconImage<string>[] = Array.from(channels);
        const tableChoices = tables.map((item: any) => {
            return {key: item.room.name + ' - ' + item.name, value: item.id};
        });
        const coversChoices = Array.from({length: 51}, (_, i) => ({key: `${i}`, value: i}));
        const coversActions = {
            prefix: {icon: 'remove', callback: () => this.covers?.setValue((this.covers.value! > 0 ? (this.covers.value||0) - 1 : 0))},
            suffix: {icon: 'add', callback: () => this.covers?.setValue((this.covers.value || 0) + 1)},
        };

        const linkedTableId = params.table?.id;
        const coversFilled = params.table?.covers;
        const showCovers= linkedTableId || coversFilled;

        this.saleForm = new CustomFormGroup<CustomForm<SaleFormFields>>({
            type: new CustomFormControl(params.saleType || 'normal', {}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.TYPE',
                inputType: 'select',
                class: 'tw-w-full',
                inputChoices: typeChoices
            }),
            name: new CustomFormControl(params.name || `${this.translateService.instant('TABLES_NEW.DIALOGS.SALE.NAME_TEMPLATE')}`, {validators: [Validators.required, Validators.maxLength(30)]}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.NAME',
                class: 'tw-w-full',
                hint: () => `${this.name?.value?.length || 0}/30`,
                customActions: {suffix: {icon: 'cancel', callback: () => this.name?.reset()}}
            }),
            delivery_at: new CustomFormControl(params.delivery_at || TilbyDatePipe.date(), {}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.DELIVERY_AT',
                inputType: 'datetime',
                class: 'tw-w-full'
            }),
            channels: new CustomFormControl(params.channel || '', {}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.CHANNEL',
                inputType: 'select',
                class: 'tw-w-full',
                inputChoices: channelChoices
            }),
            order_id: new CustomFormControl(params.order_id ||'', {}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.ORDER_ID',
                class: 'tw-w-full'
            }),
            table_id: new CustomFormControl(linkedTableId || '', {}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.TABLE',
                inputType: 'select',
                class: 'tw-w-full',
                inputChoices: tableChoices,
                customActions: {suffix: {icon: 'cancel', callback: () => { this.table_id?.reset()}}}
            }),
            covers: new CustomFormControl( coversFilled || 0, {validators: Validators.required}, {
                ...new CustomFormControlProps(),
                label: 'TABLES_NEW.DIALOGS.SALE.COVERS',
                inputType: showCovers? 'select':'hidden',
                inputChoices: coversChoices,
                customActions: coversActions,
                class: 'tw-w-full'
            }),
            booking_id: new CustomFormControl(params.booking?.id || '', {}, {
                ...new CustomFormControlProps(),
                label: '',
                class: 'tw-w-full'
            }),
            notes: new CustomFormControl(params.notes || '', {}, {
                ...new CustomFormControlProps(),
                inputType: isEdit?'textarea':'hidden',
                label: 'TABLES_NEW.DIALOGS.SALE.NOTES',
                class: 'tw-w-full'
            }),
        });

        const changesFunction = (value : string) => {
            if (value === 'normal') {
                this.delivery_at.customProps.inputType = 'hidden';
                this.channels.customProps.inputType = 'hidden';
                this.order_id.customProps.inputType = 'hidden';
                this.table_id.customProps.inputType = 'select';
                this.covers.customProps.inputType = 'select';
                this.booking_id.customProps.inputType = 'hidden';
            }
            if (value === 'take_away') {
                this.channels.customProps.inputType = 'hidden';
                this.order_id.customProps.inputType = 'hidden';
                this.delivery_at.customProps.inputType = 'datetime';
                this.table_id.customProps.inputType = 'hidden';
                this.covers.customProps.inputType = 'hidden';
                this.booking_id.customProps.inputType = 'hidden';
            }
            if (value === 'delivery') {
                this.channels.customProps.inputType = 'select';
                this.order_id.customProps.inputType = 'text';
                this.delivery_at.customProps.inputType = 'datetime';
                this.table_id.customProps.inputType = 'hidden';
                this.covers.customProps.inputType = 'hidden';
                this.booking_id.customProps.inputType = 'hidden';
            }
        }
// these subscriptions are takenUntil onDestroy but onDestroy is called customly from active-sale.service when dialog is close
        this.typeDelivery?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((value) => {
            changesFunction(value);
        });
        this.table_id?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((tableId) => {
            this.covers.customProps.inputType=(tableId || this.covers.value)?'select':'hidden';
        });

        changesFunction(params.saleType || 'normal');

        if(!this.configurationManagerService.isModuleEnabled('tables')){
            Object.entries(this.saleForm.controls).forEach(([k,v])=>{
                if(k!=='name' && k!=='notes') v.customProps.inputType='hidden';
            });
        }

        return this.saleForm;
    }
    get name() {
        return this.saleForm?.controls.name;
    }
    get typeDelivery() {
        return this.saleForm?.controls.type;
    }
    get channels() {
        return this.saleForm?.controls.channels!;
    }
    get order_id() {
        return this.saleForm?.controls.order_id!;
    }
    get delivery_at() {
        return this.saleForm?.controls.delivery_at!;
    }
    get table_id() {
        return this.saleForm?.controls.table_id!;
    }
    get covers() {
        return this.saleForm?.controls.covers!;
    }
    get booking_id() {
        return this.saleForm?.controls.booking_id!;
    }
    get notes() {
        return this.saleForm?.controls.notes!;
    }
// END - SALE FORM

// START - ADD_TABLE FORM
    public createAddEditTableForm(tableElementTypeChosen?:TableWithIcon<AllStaticElementsShape|AllTablesShape>,isEdit?:boolean,tableElementSelected?: RoomsTables){
        const isTable= !tableElementTypeChosen?.shape.startsWith(STATIC_ELEMENT_PREFIX);
        const orderTypeChoises:KeyValueIconImage<string>[] =['TABLES.ADD_TABLE.ORDER_TYPE_SINGLE','TABLES.ADD_TABLE.ORDER_TYPE_MULTIPLE'].map(type=>({key:type,value:type.toLowerCase().split('_').pop()||type}));
        const coversChoices:KeyValueIconImage<number>[] =Array.from({length:50},(_,i)=>({key:`${i+1}`,value:i+1}));
        const tableSizes:KeyValueIconImage<string>[] = tableElementTypeChosen? this.getTableSizes(tableElementTypeChosen) : [];
        const tableElementToListItem = <T extends AllStaticElementsShape|AllTablesShape>(tableElement: TableWithIcon<T>|undefined,callback:(param:TableWithIcon<T>)=>void):ListItemGeneric|undefined => tableElement?<ListItemGeneric>({id:tableElement.id, type:'generic',hideDivider:true, svgIcon:tableElement.icon, class:'tw-cursor-pointer tw-py-8 tw-px-10 tw-flex tw-justify-center tw-items-center', click:()=>tableElement && callback(tableElement)}):undefined;
        const tableShapes:Array<ListItemGeneric>=NEW_TABLES.map(table => tableElementToListItem(table,this.selectTable)!);
        const elementsShapes:Array<ListItemGeneric>=STATIC_ELEMENTS.map(element => tableElementToListItem(element,this.selectElement)!);
        const form =new CustomFormGroup<CustomForm<AddEditTable>>({
            name:new CustomFormControl(tableElementSelected?.name||'',{validators: [Validators.required, Validators.minLength(3), Validators.maxLength(25)]},{...new CustomFormControlProps(),label:`TABLES.${isEdit?'EDIT_TABLE':'ADD_TABLE'}.NAME`,hint: () => `${this.tableForm?.controls.name?.value?.length || 0}/25`,class:'tw-w-full',positionIndex:2}),
            shape:new CustomFormControl(tableElementSelected?tableElementSelected.shape:tableSizes[0]?.value,{validators: [Validators.required]},{...new CustomFormControlProps(),label:`TABLES.${isEdit?'EDIT_TABLE':'ADD_TABLE'}.SIZES`,inputType:'select',inputChoices:tableSizes,class:'',positionIndex:1}),
            covers:new CustomFormControl(tableElementSelected?.covers,{validators: [Validators.required]},{...new CustomFormControlProps(),label:`TABLES.${isEdit?'EDIT_TABLE':'ADD_TABLE'}.COVERS`,inputType:'select',inputChoices:coversChoices,class:'',positionIndex:3}),
            order_type:new CustomFormControl(tableElementSelected?.order_type||'single',{},{...new CustomFormControlProps(),label:`TABLES.${isEdit?'EDIT_TABLE':'ADD_TABLE'}.ORDER_TYPE`,inputType:"radio",inputChoices:orderTypeChoises,class:`tw-w-full tw-mb-0`,componentClass:'tw-flex-col tw-items-start',positionIndex:4}),
            shapeImage:new CustomFormGroup<CustomForm<ShapeImage>>({
                table:new CustomFormControl<TableWithIcon<AllTablesShape|AllStaticElementsShape>|undefined>(isTable?tableElementTypeChosen:undefined,{},{...new CustomFormControlProps(),label:`TABLES.EDIT_TABLE.TABLES`,inputType:"listOfItems",listItems:tableShapes,class:'tw-w-full',componentClass:'tw-flex-nowrap',matElementClass:'tw-gap-x-8 tw-flex tw-h-18 tw-overflow-auto tw-items-center tw-pr-5',matElementSelected:'tw-border-2 tw-border-solid tw-border-blue-500 tw-rounded-md', iconClass:'tw-fill-[#ddd6e2] tw-scale-[2.2] tw-m-0',positionIndex:0}),
                element:new CustomFormControl<TableWithIcon<AllTablesShape|AllStaticElementsShape>|undefined>(isTable?undefined:tableElementTypeChosen,{},{...new CustomFormControlProps(),label:`TABLES.EDIT_TABLE.ELEMENTS`,inputType:"listOfItems",listItems:elementsShapes,class:'tw-w-full',componentClass:'tw-flex-nowrap',matElementClass:'tw-gap-x-8 tw-h-18 tw-flex tw-overflow-auto tw-items-center tw-pr-5',matElementSelected:'tw-border-2 tw-border-solid tw-border-blue-500 tw-rounded-md', iconClass:'tw-scale-[2.2] tw-m-0',positionIndex:1})
            },{},{...new CustomFormGroupProps(),positionIndex:0})
        });
        this.tableForm=form as CustomFormGroup<CustomForm<AddEditTable>>;

        return form;
    }

    private get shapeImageControl(){return this.tableForm?.controls.shapeImage;}
    private get shapeImageTableControl(){return this.shapeImageControl?.controls.table;}
    private get shapeImageElementControl(){return this.shapeImageControl?.controls.element;}
    @autobind
    private selectTable(tableSelected:TableWithIcon<AllTablesShape>){
        this.shapeImageTableControl?.setValue(tableSelected);
    }
    @autobind
    private selectElement(elementSelected:TableWithIcon<AllStaticElementsShape>){
        this.shapeImageElementControl?.setValue(elementSelected);
    }

    public getTableSizes(tableTypeChosen:TableWithIcon<AllTablesShape|AllStaticElementsShape>){
        return tableTypeChosen.sizes.map(size=>({key:size.dimension,value:size.shape}));
    }

// END - ADD_TABLE FORM

// START - COPY_TABLE FORM
    public duplicateTableForm(tableSelected:RoomsTables, room: Rooms, marginTop:number, marginLeft:number ){
        const roomWidth = room.width * ROOM_MULTIPLICATION_FACTOR;
        const roomHeight = room.height * ROOM_MULTIPLICATION_FACTOR;

        const itemsAvailablePerRow = Math.trunc((roomWidth - tableSelected.xpos-tableSelected.width) / (tableSelected.width + marginLeft));
        const itemsAvailablePerColumn = Math.trunc((roomHeight - tableSelected.ypos-tableSelected.height) / (tableSelected.height + marginTop));

        const itemsPerRowLengths = itemsAvailablePerRow >= 1 ? itemsAvailablePerRow + 1 : 1;
        const itemsPerColumnLengths = itemsAvailablePerColumn >= 1 ? itemsAvailablePerColumn + 1 : 1;

        const rowsChoices:KeyValueIconImage<number>[]=Array.from({length:itemsPerRowLengths},(_,i)=>({key:`${i+1}`,value:i+1}));
        const columnsChoices:KeyValueIconImage<number>[]=Array.from({length:itemsPerColumnLengths},(_,i)=>({key:`${i+1}`,value:i+1}));

        this.copyTableForm=new CustomFormGroup<CustomForm<DuplicateTableForm>>({
            new_name:new CustomFormControl(tableSelected?.name||'',{validators:[Validators.required, Validators.minLength(2),Validators.maxLength(15)]},{...new CustomFormControlProps(),label:`TABLES.DUPLICATE_TABLE.NEW_NAME`,class:'tw-w-full tw-mb-8',hint:()=>`${this.new_name?.value.length||0}/15`}),
            row_elements:new CustomFormControl('',{validators:Validators.required},{...new CustomFormControlProps(),label:`TABLES.DUPLICATE_TABLE.ITEMS_PER_ROW`,class:'tw-w-full',inputType:'select',inputChoices:rowsChoices}),
            column_elements:new CustomFormControl('',{validators:Validators.required},{...new CustomFormControlProps(),label:`TABLES.DUPLICATE_TABLE.ITEMS_PER_COLUMN`,class:'tw-w-full',inputType:'select',inputChoices:columnsChoices}),
        });
        return this.copyTableForm;
    }

    private get new_name(){
        return this.copyTableForm?.controls.new_name;
    }
// END - COPY_TABLE FORM
}


