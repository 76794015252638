import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('newTable',["$mdBottomSheet", function($mdBottomSheet) {

    newTableController.$inject = ["$scope", "tables"];
	function newTableController($scope, tables) {
		$scope.selectTable = function(table) {
			$mdBottomSheet.hide(table);
		};

		$scope.tables = _.cloneDeep(tables);
    }

	var newTable = {
		show: function() {
			return $mdBottomSheet.show({
				controller: newTableController,
				template: require('./new-table.html')
			});
		}
	};

	return newTable;
}]);
