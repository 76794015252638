import * as angular from 'angular';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import { ConfigurationManagerService } from 'src/app/core';
import { CashdrawerDriver, CashdrawerStatus } from 'src/app/shared/model/cash-drawer.model';
import { PaymentMethods } from 'tilby-models';

class CashdrawerPaymentDialogController {
    private driverController?: Subject<string>;

    constructor(
        private $mdDialog: any,
        private $scope: any, 
        private $translate: any, 
        amount: any, 
        paymentMethod: any, 
        private cashdrawerDriver: any, 
        cashdrawerName: any
    ) {
        this.$scope.cashdrawerName = cashdrawerName;

        this.$scope.cancel = () => {
            if (!this.$scope.trSt) {
                $mdDialog.cancel('CANCELED');
            } else if (this.$scope.trSt.paid >= amount) {
                $mdDialog.hide($scope.trSt);
            } else {
                this.driverController?.next('ABORT_TRANSACTION');
            }
        };

        this.startTransaction(amount, paymentMethod);
    }

    private async startTransaction(amount: number, paymentMethod: any) {
        this.$scope.message = this.$translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CONNECTING', {drawer: this.$scope.cashdrawerName});
        this.$scope.inProgress = true;

        //Start payment transaction
        let driverInterface = this.cashdrawerDriver.performPayment(amount, paymentMethod.schema_name, paymentMethod.bundle_name);

        //Link the driver controller subject to the dialog
        this.driverController = driverInterface.driverController;

        //Subscribe to transaction status observable
        driverInterface.transactionStatus.subscribe({
            //Transaction status update
            next: (trSt: CashdrawerStatus) => {
                //Remove 'connecting' message and loader on first response
                if (!this.$scope.trSt) {
                    this.$scope.message = null;
                    this.$scope.inProgress = false;
                    delete this.$scope.closePreviousTransaction;
                }

                //Transaction is (being) canceled
                if (trSt.canceled) {
                    this.$scope.message = this.$translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.ABORTING');
                    this.$scope.inProgress = true;
                } else if (trSt.transactionStatus === 'BUSY') {
                    this.$scope.message = this.$translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.ANOTHER_OPEN_TRANSACTION', {drawer: this.$scope.cashdrawerName});
                    this.$scope.inProgress = true;

                    if (driverInterface.allowsClosingPendingTransactions) {
                        this.$scope.closePreviousTransaction = () => {
                            //Send transaction abort command to the driver
                            this.driverController?.next('ABORT_TRANSACTION');

                            this.$scope.message = this.$translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.FORCING');

                            //Remove this function and the related button
                            delete this.$scope.closePreviousTransaction;
                        };
                    }
                } else {
                    this.$scope.trSt = trSt;

                    if (trSt.message) {
                        this.$scope.message = this.$translate.instant(trSt.message);
                    }
                }
            },
            //Transaction error
            error: (error: string) => {
                if (error === 'REQ_TIMEOUT' && !this.$scope.trSt) {
                    error = 'FIRST_REQ_TIMEOUT';
                }

                this.$mdDialog.cancel(error);
            },
            //Transaction completion
            complete: () => {
                const finalStatus = this.$scope.trSt;

                if (finalStatus.canceled || finalStatus.paid < finalStatus.toPay) { //Payment not successful (Aborted)
                    this.$mdDialog.cancel('CANCELED');
                } else if (finalStatus.changeNotGiven) { //Payment successful but the drawer couldn't give the correct change
                    this.$scope.message = this.$translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CHANGE_NOT_GIVEN_ERROR');

                    //Expose confirm method to the dialog UI
                    this.$scope.confirm = () => {
                        this.$mdDialog.hide(finalStatus);
                    }
                } else { //Payment successful
                    this.$mdDialog.hide(finalStatus);
                }
            }
        });
    }
}

CashdrawerPaymentDialogController.$inject = ["$mdDialog", "$scope", "$translate", "amount", "paymentMethod", "cashdrawerDriver", "cashdrawerName"];

export class CashdrawerPaymentDialog {
    constructor(
        private $mdDialog: any,
        private $translate: any,
        private $filter: any,
        private configurationManager: ConfigurationManagerService
    ) {
    }

    public async show(amount: number, paymentMethod: PaymentMethods, cashdrawerDriver: CashdrawerDriver, cashdrawerName: string) {
        if (!paymentMethod.schema_name) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.SCHEMA_NAME_NOT_FOUND', {drawer: cashdrawerName});
        }

        return this.$mdDialog.show({
            controller: CashdrawerPaymentDialogController,
            template: require('./cashdrawer-payment-dialog.html'),
            locals: {
                amount: amount,
                paymentMethod: _.cloneDeep(paymentMethod),
                cashdrawerDriver: cashdrawerDriver,
                cashdrawerName: cashdrawerName
            }
        }).then((transaction: CashdrawerStatus) => {
            let transactionString = '';

            try {
                transactionString = JSON.stringify(transaction);
            } catch (e) {
            }

            let receiptTail: string[] = [];

            receiptTail.push("Dettaglio pagamento", "");
            receiptTail.push(`Da pagare ${_.padStart(this.$filter('sclCurrency')(transaction.toPay), 22, ' ')}`);
            receiptTail.push(`Pagato ${_.padStart(this.$filter('sclCurrency')(transaction.paid), 25, ' ')}`);
            receiptTail.push(`Resto erogato ${_.padStart(this.$filter('sclCurrency')(transaction.changeGiven), 18, ' ')}`);
            receiptTail.push(`Resto non erogato ${_.padStart(this.$filter('sclCurrency')(transaction.changeNotGiven), 14, ' ')}`);

            const paymentData = {
                unclaimed: false,
                payment_data: transactionString,
                tail: receiptTail.join('\n')
            };

            return this.configurationManager.getPreference('cashregister.register_cashdrawers_paid_amount')
                ? { ...paymentData, amount: transaction.paid }
                : paymentData;
        })
    }
}

CashdrawerPaymentDialog.$inject = ["$mdDialog", "$translate", "$filter", "checkManager"];

angular.module('digitalPayments').service('CashdrawerPaymentDialog', CashdrawerPaymentDialog);
