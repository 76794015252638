@if(isLoading()) {
    <div class="tw-w-full tw-flex tw-justify-center tw-py-3">
        <mat-spinner id="white-spinner" class="tw-h-100"></mat-spinner>
    </div>
} @else {
    <div class="tw-grid tw-grid-cols-5 tw-gap-1">
        <div class="tw-col-span-3">
            <div class="tw-w-full tw-flex">
                <tilby-general-input-button class="sale-keypad-button tw-bg-transparent tw-truncate" [btn]="toggleMainKeyboardButton"/>
                <app-tilby-keypad-display [form]="form" class="tw-flex-1" inputClass="tw-flex the-dense-zone tw-pb-1 pointer-events-none-for-all-children tw-text-xl tw-w-full side-keyboard-input-width"/>
            </div>
        </div>
        <div class="tw-col-span-2 tw-pb-1">
            <div id="infoKeypadSide" class="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
                @if(sale) {
                    <div id="finalAmountAndQuantityId">
                        <span id="finalAmountId" class="tw-font-bold tw-pr-2">{{(finalAmount || 0) | tilbyCurrency}}</span>
                        <span id="quantityId"class="tw-p-1 tw-rounded-md tw-bg-green-100 tw-font-bold">{{totalQuantity}}</span>
                    </div>
                } @else if(change){
                    <div>
                        <div class="tw-font-bold tw-text-sm tw-pr-2">{{"CASHREGISTER.ACTIVE_SALE.KEYBOARD_SIDE_CHANGE" | translate}}</div>
                        <div class="tw-font-bold tw-text-xl tw-pr-2">{{change | tilbyCurrency}}</div>
                    </div>
                }
            </div>
        </div>
    </div>
    <div class="tw-grid tw-grid-cols-5 tw-gap-1">
        <app-cashregister-keypad class="tw-col-span-3 tw-h-full" [field]="cashValue" (fieldChange)="onFieldChange.emit($event)"
                                [integerMultiply]="integerMultiply"/>
        <div class="tw-col-span-2 tw-grid tw-grid-rows-4 tw-grid-cols-2 tw-grid-flow-col tw-gap-1">
            @for (btn of buttons; track $index) {
                @if (btn.isIt()) {
                    <tilby-general-input-button
                        class="sale-keypad-button sale-keypad-button__dark tw-w-full tw-truncate"
                        [class.tw-opacity-25]="btn.isDisable?.()"
                        [class.tw-pointer-events-none]="btn.isDisable?.()"
                        [class.tw-bg-green-500]="btn.selected?.()"
                        [btn]="btn"/>
                }
            }
            @for(btn of fastPaymentsButtons; track $index){
                <button mat-button class="tw-h-full" [ngClass]="!btn.isDisable?.() ? 'tilby-body-background-primary-color' : 'tilby-body-background-primary-color-disabled button-disabled'"
                    tilbyGestures
                    (longPress)="btn.longPress()"
                    (click)="btn.click()"
                    [disabled]="btn.isDisable?.()">
                    {{btn.name | translate}}
                </button>
            }
            @if (principalActionButton) {
                <tilby-general-input-button
                    class="sale-keypad-button sale-keypad-button__dark tw-w-full tw-h-full tw-min-w-fit tw-text-base tw-truncate tw-col-start-2 tw-row-start-4 tw-row-span-1"
                    [class.tw-opacity-25]="principalActionButton.isDisable?.()"
                    [class.tw-pointer-events-none]="principalActionButton.isDisable?.()"
                    [btn]="principalActionButton"
                />
            }
        </div>
    </div>
}

