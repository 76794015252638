<div layout="column">
    <div style="height: 8px">
        <mat-progress-bar *ngIf="isProcessing" mode="indeterminate"></mat-progress-bar>
    </div>
    <form [formGroup]="fileImporterScheduleForm" layout="column" flex>
        <mat-form-field layout="column" class="layout-padding-no-inherit-16 tw-w-[calc(100%-41px)]">
            <label translate="FILE_IMPORTER.SCHEDULE.IMPORT_NAME"></label>
            <input type="text" matInput formControlName="import_name" [disabled]="isProcessing" [ngClass]="{ 'opacity-wait': isProcessing }">
            @if (fileImporterScheduleForm.controls.import_name.hasError('required')) {
                <mat-error translate="FILE_IMPORTER.SCHEDULE.IMPORT_NAME_REQUIRED"></mat-error>
            }
        </mat-form-field>
    </form>
</div>