import {Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TilbyMagicFormComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DocumentPrintersManagerDialogData, DocumentPrintersManagerDialogStateService} from './document-printers-manager-dialog.state.service';
import {TranslateModule} from "@ngx-translate/core";
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';
import {PrinterDocumentTypeId} from 'src/app/core';


@Component({
  selector: 'app-document-printers-manager-dialog',
  standalone: true,
    imports: [CommonModule, TilbyMagicFormComponent, TranslateModule],
  templateUrl: './document-printers-manager-dialog.component.html',
  styleUrls: ['./document-printers-manager-dialog.component.scss'],
  providers: [OnDestroyService]
})
export class DocumentPrinterManagerDialogComponent {
  protected readonly data: DocumentPrintersManagerDialogData = inject(MAT_DIALOG_DATA);
  protected readonly onDestroyService = inject(OnDestroyService);
  protected readonly documentPrintersManagerDialogStateService = inject(DocumentPrintersManagerDialogStateService);

  constructor() {
    this.subscriptions();
  }

  private subscriptions() {
    this.printerControl?.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe((printerSelection:string)=> this.documentPrintersManagerDialogStateService.printerControlChanges(printerSelection));

    this.documentControl?.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe((selectedDocumentId:PrinterDocumentTypeId|number)=> this.documentPrintersManagerDialogStateService.documentControlChanges(selectedDocumentId));
  }


  //FORM_CONTROLLERS GET
  private get printerControl() {
    return this.data.form?.controls.printer;
  }
  private get documentControl() {
    return this.data.form?.controls.document;
  }

}
