import { Injectable, inject } from "@angular/core";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { DevLogger } from "src/app/shared/dev-logger";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { RoundTotalDialogComponent } from "./round-total-dialog.component";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { lastValueFrom } from "rxjs";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TranslateService } from "@ngx-translate/core";
import { MathUtils } from "@tilby/tilby-ui-lib/utilities";

export type RoundTotalFormValue = {
    floor_decimals: boolean;
    rounds_decimal: boolean;
    floor_5_cents: boolean;
};

export type RoundTotalParams = { finalAmount?: number};
export type RoundTotalData = InputItemDataMagicForm<RoundTotalFormValue>;

@Injectable({
    providedIn: 'root',
})
export class RoundTotalDialogStateService {

    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly translateService = inject(TranslateService);

    floorTotalPriceChange : number = 0;
    roundTotalPriceChange : number = 0;
    floor5centsPriceChange : number = 0;

    finalAmount = 0;

    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<RoundTotalDialogComponent>>
    protected _form?: CustomFormGroup<CustomForm<RoundTotalFormValue>>;

    private log(...args: any[]) {
        DevLogger.log('ROUND_TOTAL_DIALOG_STATE_SERVICE', ...args);
    }

    public openDialog(config: NonNullableConfigData<RoundTotalParams>) {
        const { finalAmount } = config.data;
        this.finalAmount = finalAmount!;
        this.createForm();
        this.calculateValues();
        const configPortal: NonNullablePortalConfigData<RoundTotalData, RoundTotalDialogComponent> = {
            data: {
                form: this._form!,
                component: RoundTotalDialogComponent,
                titleLabel: `TOT: ${this.tilbyCurrencyPipe.transform(finalAmount!)}`,
                confirmDisabled: () => true,
            }
        };

        this.dialogRef = this.openDialogsService.openPortalDialogRef<RoundTotalDialogComponent, PortalConfigData<InputItemDataMagicForm<RoundTotalFormValue>, RoundTotalDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });
        return lastValueFrom(this.dialogRef.afterClosed()).then((res : PortalConfigData<InputItemDataMagicForm<RoundTotalFormValue>, RoundTotalDialogComponent>) => {
            if (res?.form.valid) {
                if (this._form?.get('floor_decimals')?.value) {
                    return {
                        description: this.translateService.instant('CASHREGISTER.ROUND_TOTAL.FLOOR'),
                        amount: this.floorTotalPriceChange
                    }
                }
                if (this._form?.get('floor_5_cents')?.value) {
                    return {
                        description: this.translateService.instant('CASHREGISTER.ROUND_TOTAL.FLOOR'),
                        amount: this.floor5centsPriceChange
                    }
                }
                if (this._form?.get('rounds_decimal')?.value) {
                    return {
                        description: this.translateService.instant('CASHREGISTER.ROUND_TOTAL.ROUND'),
                        amount: this.roundTotalPriceChange
                    }
                }
                return res;
            }
        });
    }

    private createForm() {
        this._form = new CustomFormGroup<CustomForm<RoundTotalFormValue>>({
            floor_decimals: new CustomFormControl({ value: false, disabled: false }, {}, { ...new CustomFormControlProps(), label: "CASHREGISTER.ROUND_TOTAL.FLOOR_DECIMALS", inputType: 'slideToggle', class: "tw-w-full tw-mb-3 tw-ml-10 floor_decimals" }),
            floor_5_cents: new CustomFormControl({ value: false, disabled: false }, {}, { ...new CustomFormControlProps(), label: "CASHREGISTER.ROUND_TOTAL.FLOOR_5_CENTS", inputType: 'slideToggle', class: "tw-w-full tw-mb-3 tw-ml-10 floor_5_cents" }),
            rounds_decimal: new CustomFormControl({ value: false, disabled: false }, {}, { ...new CustomFormControlProps(), label: "CASHREGISTER.ROUND_TOTAL.ROUND_DECIMALS", inputType: 'slideToggle', class: "tw-w-full tw-ml-10 rounds_decimal" }),
        });
    }

    private calculateValues() {
        this.floorTotalPriceChange = MathUtils.round(Math.floor(this.finalAmount) - this.finalAmount);
        this.roundTotalPriceChange = MathUtils.round(Math.round(this.finalAmount) - this.finalAmount);
        this.floor5centsPriceChange = MathUtils.round(this.floorByCents(this.finalAmount, 5) - this.finalAmount);
    }

    private floorByCents(amount : number, cents: number) {
        let currentCents = Math.floor((amount % 1) * 100);
        let integerPart = Math.floor(amount);
        let flooredCents = Math.floor(currentCents / cents) * (cents / 100);
        return MathUtils.round(integerPart + flooredCents);
    };

    getPreviewNewTotal(type?: string) {
        if(type === 'floorDecimals') {
            return MathUtils.round(this.finalAmount + this.floorTotalPriceChange);
        }
        else if(type === 'roundDecimals'){
            return MathUtils.round(this.finalAmount + this.roundTotalPriceChange);
        }
        else if(type === 'floor5cents') {
            return MathUtils.round(this.finalAmount + this.floor5centsPriceChange);
        }
        else {
            return this.finalAmount;
        }
    }
}
