import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('moveTable', ["$mdDialog", "$state", "entityManager", function($mdDialog, $state, entityManager) {

    moveTableController.$inject = ["$scope", "$mdDialog", "rooms", "currentRoom"];
    function moveTableController($scope, $mdDialog, rooms, currentRoom) {

        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function() {
            $mdDialog.hide($scope.targetRoom);
        };

        $scope.rooms = rooms;
        $scope.targetRoom = currentRoom;

    }

    var moveTable = {
        show: function(tableData, rooms, currentRoom) {
            $mdDialog.show({
                    controller: moveTableController,
                    template: require('./move-table.html'),
                    locals: {
                        rooms: rooms,
                        currentRoom: currentRoom
                    }
                }).then(function(targetRoom){
                    if(currentRoom.id !== targetRoom.id) {
                        var tmpSource = _.cloneDeep(currentRoom);
                        var tmpTarget = _.cloneDeep(targetRoom);
                        _.each(tableData, function(table){
                            _.remove(tmpSource.tables,table);
                            var x = _.find(tmpTarget.tables, {id: table.id});
                            table.xpos = 0;
                            table.ypos = 0;
                            if(!x) {
                                tmpTarget.tables.push(table);
                            }
                            else {
                                _.merge(x,table);
                            }
                        });
                        entityManager.rooms.putOneOnline(tmpSource).then(function() {
                            entityManager.rooms.putOneOnline(tmpTarget).then(function(){
                                $state.reload("app.tables");
                            });
                        });
                    }
                });
        }
    };

    return moveTable;
}]);