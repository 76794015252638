import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fidelity').controller('FidelityRulesCtrl', ["$scope", "$rootScope", "$q", "$translate", "restManager", "confirmDialog", "alertDialog", function($scope, $rootScope, $q, $translate, restManager, confirmDialog, alertDialog) {
    var pristineRule = null; //contains the rule before the editing happens

    $scope.ruleTypes = {
        at_sale: $translate.instant('FIDELITY.SETUP.RULES.AT_SALE'),
        every_euro: $translate.instant('FIDELITY.SETUP.RULES.EVERY_CURRENCY', { currency: $rootScope.currentCurrency.symbol_native })
    };

    $scope.topbar_context.view = "rules";
    $scope.topbar_context.selectedTab = 1;

    $scope.topbar_context.checkBeforeExit = function() {
        var d = $q.defer();
        var openRule = _.find($scope.rules, { $show: true });

        if (openRule) {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.RULES.EXIT_CONFIRM')).then(function(answer) {
                if (answer) {
                    if (openRule.id) { //We were editing a rule, so restore it to pristine state
                        angular.copy(pristineRule, openRule);
                    } else { //We were creating a rule, so remove it from the rules array
                        _.pullAt($scope.rules, 0);
                    }
                    d.resolve();
                } else {
                    $scope.topbar_context.selectedTab = 1;
                    d.reject();
                }
            }, function() {
                $scope.topbar_context.selectedTab = 1;
                d.reject();
            });
        } else {
            d.resolve();
        }

        return d.promise;
    };

    $scope.addRule = function() {
        var isAlreadyAdding = _.find($scope.rules, { $show: true });

        if (isAlreadyAdding) {
            alertDialog.show($translate.instant('FIDELITY.SETUP.RULES.CLOSE_RULE_CREATE'));
        } else {
            $scope.rules.unshift({ $show: true });
        }
    };

    $scope.switchFidelityRuleEdit = function(rule, dirty) {
        if (rule.$show) {
            if (dirty) {
                confirmDialog.show($translate.instant('FIDELITY.SETUP.RULES.CONFIRM_CLOSE')).then(function(answer) {
                    if (answer) {
                        if (rule.id) {
                            angular.copy(pristineRule, rule);
                            pristineRule = null;
                        } else {
                            _.pull($scope.rules, rule);
                        }
                    }
                });
            } else {
                if (!rule.id) {
                    _.pull($scope.rules, rule);
                } else {
                    delete rule.$show;
                }
            }
        } else {
            var alreadyOpenRule = _.find($scope.rules, { $show: true });

            if (alreadyOpenRule) {
                alertDialog.show($translate.instant('FIDELITY.SETUP.RULES.CLOSE_RULE'));
            } else {
                pristineRule = _.cloneDeep(rule);
                rule.$show = true;
            }
        }
    };

    $scope.saveRule = function(rule) {
        var operation;
        var ruleToSend = _.cloneDeep(rule);

        var params = [];

        delete ruleToSend.$show;

        if (ruleToSend.id) {
            operation = 'put';
            params.push(rule.id);
        } else {
            operation = 'post';
        }

        params.push(ruleToSend);

        restManager[operation]("rules", ...params).then(function(result) {
            angular.copy(result, rule);
            $scope.rulesMap[rule.id] = rule;
            pristineRule = null;
        }, function(error) {
            alertDialog.show($translate.instant('FIDELITY.SETUP.RULES.SAVE_ERROR'));
        });
    };

    $scope.deleteRule = function(rule) {
        confirmDialog.show($translate.instant('FIDELITY.SETUP.RULES.DELETE_CONFIRM')).then(function(answer) {
            if (answer) {
                if (rule.id) {
                    restManager.deleteOne("rules", rule.id).then(function() {
                        _.pull($scope.rules, rule);
                        delete $scope.rulesMap[rule.id];
                    });
                } else {
                    _.pull($scope.rules, rule);
                }
            }
        });
    };
}]);
