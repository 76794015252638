import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { downloadFile } from 'src/app/shared/data-conversion-utils';
import { Customers, Sales } from "tilby-models";

export type GenericJsonDialogData = { title?: string; nameFile?: string, json: Customers | Sales | undefined };

@Component({
    selector: 'app-generic-json-detail-dialog',
    templateUrl: './generic-json-detail-dialog.component.html'
})
export class GenericJsonDetailDialogComponent {

    @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

    constructor(
        public dialogRef: MatDialogRef<GenericJsonDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GenericJsonDialogData
    ) {
    }

    get dataObj(){
        return this.data.json;
    }

    cancel() {
        this.dialogRef.close({cancel: true});
    };

    saveFile() {
        let fileName = this.data.json?.id ? (`${this.data.nameFile}_id_${this.data.json.id}`) : this.data.nameFile;
        let content : string | Blob = JSON.stringify(this.data.json, null, 2);
        let mime, extension;

        mime = 'application/json';
        extension = 'json';

        downloadFile(content, `${fileName}${extension ? `.${extension}` : ''}`, mime);
    }
}
