import { Component, Inject, OnInit } from '@angular/core';
import { errorsLogger, restManager, $state} from 'app/ajs-upgraded-providers';
import { OpenDialogsService } from "src/app/dialogs";
import { TranslateService } from "@ngx-translate/core";
import { TilbyToolbar } from 'src/app/models';
import { ToolbarEventsService } from 'src/app/core/services';
import { ModuleEvents } from 'src/app/core/services/toolbar-events/toolbar-events.model';
import { GiftcardsTypes } from 'tilby-models';
import { GiftcardsTypesFormGroup } from '../giftcard-type-form';
import { GiftcardTypeFormService } from '../../services';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'giftcard-types-panel',
    templateUrl: './giftcard-types-panel.component.html',
    styleUrls: ['./giftcard-types-panel.component.scss'],
    providers: [OnDestroyService]
})
export class GiftcardTypesPanelComponent implements OnInit, TilbyToolbar  {
    giftcardTypesList: Array<GiftcardsTypes>;
    giftcardTypesForm?: CustomFormGroup<GiftcardsTypesFormGroup>;
    allWithCheckbox = false;
    pristineGiftcardType: GiftcardsTypes;
    selectedGiftcardType: GiftcardsTypes;
    isNewEntryInProgress: boolean;
    firstEntry: boolean;
    selected: boolean;

    constructor(
        private toolbarEventsService: ToolbarEventsService,
        private openDialogsService: OpenDialogsService,
        private translate: TranslateService,
        private onDestroyService: OnDestroyService,
        @Inject(errorsLogger) private errorsLoggerService: any,
        @Inject(restManager) private restManagerService: any,
        @Inject($state) private state: any,
        private giftcardTypeFormService: GiftcardTypeFormService,
        private _snackBar: MatSnackBar,

    ) {
        this.giftcardTypesList = [];
        this.pristineGiftcardType = {
            name: this.translate.instant('GIFTCARD.TYPES_PANEL.NEW_TYPE'),
            is_divisible: false,
            expire_after: 365,
            origin_environment_only: false
        };
        this.selectedGiftcardType = this.pristineGiftcardType;
        this.isNewEntryInProgress = false;
        this.firstEntry = false;
        this.selected = false;
    }

    async ngOnInit() {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        try {
            const results = await this.restManagerService.getList(['giftcards_types']);
            // results potrebbe essere un oggetto con errore type="not found" se non ci sono risultati
            if(Array.isArray(results)) {
                this.giftcardTypesList = _.orderBy(results, ['created_at'], ['asc']);
            }
        } catch (error) {
            this.errorsLoggerService.err(error, 'GIFTCARD_TYPES');
            this.giftcardTypesList = [];
        }
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('GIFTCARD_TYPES');
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {}

    back() {
        this.state.go("app.new.giftcard");
    }

    private async unsave(giftcardType: GiftcardsTypes) {
        const confirm = await this.openDialogsService.openConfirmDialog({ data: {
                messageLabel: 'GIFTCARD.TYPES_PANEL.QUESTION_SELECT',
                confirmLabel: 'GIFTCARD.TYPES_PANEL.CONFIRM_LABEL',
                cancelLabel: 'GIFTCARD.TYPES_PANEL.CANCEL_LABEL'
            }
        });

        if (confirm) {
            if(this.isNewEntryInProgress) {
                // elimina bozza
                this.giftcardTypesList.pop();
                this.isNewEntryInProgress = false;
            }
            this.giftcardTypesForm?.markAsPristine();
            this.select(giftcardType, false);
        }
    }

    select(giftcardType: GiftcardsTypes, isFormDirty = false) {
        if(isFormDirty || this.isNewEntryInProgress) {
            this.unsave(giftcardType);
        } else {
            const _giftcardType = structuredClone(giftcardType);
            this.pristineGiftcardType = _giftcardType;
            this.selectedGiftcardType = _giftcardType;
            if(!this.selected) {
                this.giftcardTypesForm = this.giftcardTypeFormService.createForm(_giftcardType);
            } else {
                this.giftcardTypeFormService.updateForm(_giftcardType);
            }
        }
        this.selected = true;
    }

    isSelected(sel: GiftcardsTypes): boolean {
        return sel.uuid === this.pristineGiftcardType.uuid;
    }

    addNew(){
        const newGiftcardType: GiftcardsTypes = {
            name: this.translate.instant('GIFTCARD.TYPES_PANEL.NEW_TYPE'),
            is_divisible: false,
            expire_after: 365
        };

        this.giftcardTypesList.push(newGiftcardType);
        this.select(newGiftcardType);
        // importante prima fare la select
        this.isNewEntryInProgress = true;
    }

    async save() {
        const giftcardTypeToSave: GiftcardsTypes = { ...this.selectedGiftcardType, ...this.generalFormGroup?.value, print_options: this.printOptionsFormGroup?.value };
        let res: GiftcardsTypes;
        let success: boolean = false;
        if(this.isNewEntryInProgress) {
            // POST
            res = await this.restManagerService.post("giftcards_types", giftcardTypeToSave);
            if(res) {
                success = true;
                const lastIndex = this.giftcardTypesList.length - 1;
                this.giftcardTypesList[lastIndex] = res;
                this.isNewEntryInProgress = false;
                if(this.firstEntry) {
                    this.firstEntry = false;
                }
                this.giftcardTypesForm?.markAsPristine();
                this.select(res);
            }
        } else {
            // PUT
            const {uuid} = this.selectedGiftcardType;
            if(uuid) {
                res = await this.restManagerService.put("giftcards_types", uuid, giftcardTypeToSave);
                if (res) {
                    success = true;
                    // cercare l'indice
                    const index = _.findIndex(this.giftcardTypesList, {uuid});
                    if(index !== -1) {
                        this.giftcardTypesList[index] = res;
                        this.giftcardTypesForm?.markAsPristine();
                        this.select(res);
                    }
                }
            }
        }
        if (success) {
            const labelSuccess = this.translate.instant('GIFTCARD.TYPES_PANEL.SUCCESS_SAVE');
            this._snackBar.open(labelSuccess, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
        } else {
            const labelError = this.translate.instant('GIFTCARD.TYPES_PANEL.ERROR_SAVE');
            this._snackBar.open(labelError, undefined, { duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom' });
        }

    }

    async delete(){
        const callbackFunction = async (giftcardTypes: GiftcardsTypes) => {
            let labelDelete: string;
            if(this.isNewEntryInProgress) {
                this.giftcardTypesList.pop();
                this.isNewEntryInProgress = false;
                labelDelete = this.translate.instant('GIFTCARD.TYPES_PANEL.SUCCESS_DELETE_DRAFT');
                this._snackBar.open(labelDelete, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
            } else {
                // delete da remoto
                const target = _.cloneDeep(giftcardTypes);
                let res:any = await this.restManagerService.deleteOne("giftcards_types", target.uuid);
                if(res && res.message === 'ok') {
                    const index = _.findIndex(this.giftcardTypesList, {uuid: target.uuid});
                    _.pullAt(this.giftcardTypesList, [index]);
                    labelDelete = this.translate.instant('GIFTCARD.TYPES_PANEL.SUCCESS_DELETE_REMOTE');
                    this._snackBar.open(labelDelete, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
                }
            }
            this.giftcardTypesForm?.markAsPristine();
            this.selected = false;
        };

        const res = await this.openDialogsService.openConfirmDialog({data: {
            messageLabel: this.translate.instant('GIFTCARD.TYPES_PANEL.QUESTION_DELETE'),
            confirmLabel: this.translate.instant('GIFTCARD.TYPES_PANEL.CONFIRM_LABEL'),
            cancelLabel: this.translate.instant('GIFTCARD.TYPES_PANEL.CANCEL_LABEL')
        }});
        if(res){
            await callbackFunction(this.selectedGiftcardType);
        }
    }

    get generalFormGroup() {
        return this.giftcardTypesForm?.controls.general;
    }

    get printOptionsFormGroup() {
        return this.giftcardTypesForm?.controls.print_options;
    }

    get name() {
        return this.generalFormGroup?.controls.name;
    }

    get description() {
        return this.generalFormGroup?.controls.description;
    }

    get expire_after() {
        return this.generalFormGroup?.controls.expire_after;
    }

    get is_divisible() {
        return this.generalFormGroup?.controls.is_divisible;
    }

}
