import { Component, Inject, OnInit, inject } from '@angular/core';
import { $translate, errorsLogger, restManager, util } from 'app/ajs-upgraded-providers';
import { OpenDialogsService } from 'src/app/dialogs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ConfigurationManagerService,
    EntityManagerService,
    EnvironmentInfoService,
    ModuleEvents,
    OauthService,
    ThemeModeService,
    ToolbarEventsService
} from 'src/app/core';
import { UiLanguage, uiLanguages } from 'src/app/core/constants/ui-languages';
import { DataAlertDialog } from 'src/app/dialogs/dialog.model';
import { EditFirstNameDialogService } from 'src/app/dialogs/edit-firstname-dialog';
import { EditLastNameDialogService } from 'src/app/dialogs/edit-lastname-dialog';
import { ServerlessUser } from 'tilby-models';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { SelectThemeDialogService } from 'src/app/dialogs/select-theme-dialog';

type UserData = {
    first_name?: string,
    last_name?: string,
    username?: string,
    email?: string,
    phone?: string,
    pin?: string | null,
    thumbnail?: string,
    uiLanguage?: UiLanguage
}

@Component({
  selector: 'app-settings-user',
  templateUrl: './settings-user.component.html',
  styleUrls: ['./settings-user.component.scss'],
  providers: [OnDestroyService]
})
export class SettingsUserComponent implements OnInit {
    private readonly openDialogService = inject(OpenDialogsService);
    userData: UserData;
    demoUsers: Array<string|undefined>;
    isChangeTheme = false;
    showThemeSwitch = this.configurationManager.getPreference('migration.show_theme_switch') || false;

    constructor(
        private toolbarEventsService: ToolbarEventsService,
        private onDestroyService: OnDestroyService,
        private entityManagerService: EntityManagerService,
        @Inject(restManager) private restManagerService: any,
        @Inject(util) private util: any,
        private openDialogsService: OpenDialogsService,
        private translate: TranslateService,
        private _snackBar: MatSnackBar,
        protected configurationManager: ConfigurationManagerService,
        @Inject(errorsLogger) private errorsLoggerService: any,
        private environmentInfo: EnvironmentInfoService,
        private oauth: OauthService,
        @Inject($translate) private $translate: any,
        private editFirstNameDialogService: EditFirstNameDialogService,
        private editLastNameDialogService: EditLastNameDialogService,
        protected themeModeService: ThemeModeService,
        protected selectThemeDialogService: SelectThemeDialogService
    ) {
        this.userData = {};
        this.demoUsers = ['utente.demo', 'demoitalia', 'demospain', 'demofrance', 'demogermany', 'demoaustria', 'demoenglish'];
    }

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        const generalUiLanguage = this.configurationManager.getPreference('general.ui_language') || 'it';
        const lang = Object.values(uiLanguages).filter((uiLang: UiLanguage) => uiLang.id === generalUiLanguage);

        this.getUserData().then((userData: UserData) => {
            this.userData = userData;
            if(!this.userData.phone) {
                this.userData.phone = ' ';
            }
            this.userData.uiLanguage = lang[0];
            this.getUserSession().then(userSession => {
                this.userData.username = userSession.username;
                this.userData.thumbnail = userSession.thumbnail;
            });
        });
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('USER');
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {}

    async getUserData() {
        try {
            const userData = await this.restManagerService.getOne('sessions/current_user');
            if(userData) {
                return userData;
            }
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
    }

    async getUserSession() {
        try {
            const userSession = await this.entityManagerService.userSessions.getActiveSession();
            if(userSession) {
                return userSession;
            }
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
    }

    getFullName() {
        return this.userData && this.userData.first_name + ' ' + this.userData.last_name;
    }

    getAvatar() {
        return this.userData && this.userData.thumbnail ? this.userData.thumbnail : (this.userData?.first_name?.charAt(0).toUpperCase() || '') + (this.userData?.last_name?.charAt(0).toUpperCase() || '');
    }

    canChangePassword() {
        return this.environmentInfo.canOpenExternalLinks();
    }

    changePassword() {
        if(Object.keys(this.userData).length) {
            if(this.demoUsers.includes(this.userData.username)) {
                const data:DataAlertDialog = {
                    messageLabel: this.translate.instant('SETTINGS.USER_SETTINGS.MSG_USER_DEMO'),
                    confirmLabel: this.translate.instant('MISC.OK')
                };
                this.openDialogsService.openAlertDialog({data});
            } else {
                return this.util.openExternalLink(this.oauth.getTilbyLoginUrl(['clientId', 'redirectUri']) + '&select_shop=1');
            }
        }
    }



    async openThemeDialog() {

        const res = await this.selectThemeDialogService.openDialog({theme: this.themeModeService.isDarkTheme ? 'DARK' : 'LIGHT'});

        if(!res) {
            return;
        }

        if ((res.theme === 'DARK' && !this.themeModeService.isDarkTheme) || (res.theme === 'LIGHT' && this.themeModeService.isDarkTheme)) {
            this.isChangeTheme = true;
            this.configurationManager.setUserPreference('general.ui_theme', res.theme.toLowerCase());
            setTimeout(() => { this.isChangeTheme = false;}, 0);
        }
    }

    openEditUserDialog(action: string) {
        if(Object.keys(this.userData).length) {
            const actionsExcludedInDemo = ['first_name', 'last_name', 'email', 'phone', 'pin'];
            if(this.demoUsers.includes(this.userData.username) && actionsExcludedInDemo.includes(action)) {
                const data:DataAlertDialog = {
                    messageLabel: this.translate.instant('SETTINGS.USER_SETTINGS.MSG_USER_DEMO'),
                    confirmLabel: this.translate.instant('MISC.OK')
                };
                this.openDialogsService.openAlertDialog({data});
            } else {
                const payload = {
                    first_name: this.userData.first_name || '',
                    last_name: this.userData.last_name || '',
                    pin: this.userData.pin || null,
                }
                switch(action) {
                    case 'first_name': {
                        this.editFirstNameDialogService.openDialog(payload).then((res: ServerlessUser) => {
                            if(res) {
                                this.userData.first_name = res.first_name;
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_FIRSTNAME'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                    case 'last_name': {
                        this.editLastNameDialogService.openDialog(payload).then((res: ServerlessUser) => {
                            if(res) {
                                this.userData.last_name = res.last_name;
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_LASTNAME'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                    case 'language': {
                        const _data = {
                            uiLanguage: this.configurationManager.getPreferredLanguage()
                        };
                        this.openDialogsService.openEditUiLanguageDialog({ data: _data }).then(res => {
                            if(res) {
                                this.userData.uiLanguage = res;
                                this.translate.use(res.id);
                                this.$translate.use(res.id);
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_LANGUAGE'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                    case 'email': {
                        this.openDialogsService.openEditEmailDialog({ data: { email: this.userData.email || '' }}).then(res => {
                            if (res) {
                                let error = false;
                                let message = '';
                                for(const key in res) {
                                    switch(key) {
                                        case 'message': {
                                            error = true;
                                            message = res[key];
                                        }
                                        break;
                                        case 'email': this.userData.email = res[key];
                                        break;
                                    }
                                }
                                if (error) {
                                    const data:DataAlertDialog = {
                                            messageLabel: message,
                                            confirmLabel: this.translate.instant('MISC.OK')
                                    };
                                    this.openDialogsService.openAlertDialog({data}).then(_ => this.openEditUserDialog('email'));
                                } else {
                                    this._snackBar.open(
                                        this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_EMAIL'), undefined,
                                        {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                    );
                                }
                            }
                        });
                    }
                    break;
                    case 'phone': {
                        this.openDialogsService.openEditPhoneDialog({ data: { phone: this.userData.phone || '' }}).then(res => {
                            if (res) {
                                let error = false;
                                let message = '';
                                for(const key in res) {
                                    switch(key) {
                                        case 'message': {
                                            error = true;
                                            message = res[key];
                                        }
                                        break;
                                        case 'phone': this.userData.phone = res[key];
                                        break;
                                    }
                                }
                                if (error) {
                                    const data:DataAlertDialog = {
                                            messageLabel: message,
                                            confirmLabel: this.translate.instant('MISC.OK')
                                    };
                                    this.openDialogsService.openAlertDialog({data}).then(_ => this.openEditUserDialog('phone'));
                                } else {
                                    this._snackBar.open(
                                        this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_PHONE'), undefined,
                                        {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                    );
                                }
                            }
                        });
                    }
                    break;
                    case 'pin': {
                        const _data = {
                            first_name: this.userData.first_name,
                            last_name: this.userData.last_name,
                            pin: this.userData.pin
                        };
                        this.openDialogsService.openEditPinDialog({ data: _data }).then((res: UserData) => {
                            if(res) {
                                this.userData.pin = res.pin;
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_PIN'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                }
            }
        }
    }

    changeEmailPreferences() {
        if(Object.keys(this.userData).length) {
            if(this.demoUsers.includes(this.userData.username)) {
                const data:DataAlertDialog = {
                    messageLabel: this.translate.instant('SETTINGS.USER_SETTINGS.MSG_USER_DEMO'),
                    confirmLabel: this.translate.instant('MISC.OK')
                };
                this.openDialogsService.openAlertDialog({data});
            } else {
                if(this.userData.email) {
                    const email = this.userData.email;
                    const lang = this.configurationManager.getPreferredLanguage();
                    if(lang && lang.id) {
                        let url;
                        switch(lang.id) {
                            case 'it':
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                                break;
                            case 'fr':
                                url = 'https://share.hsforms.com/1W1GodNqqR5mHNP4-EMz2GQ2n2jy?email=';
                                break;
                            case 'de':
                                url = 'https://share.hsforms.com/1GMiSie9lSEK_LKwUaZZwMQ2n2jy?email=';
                                break;
                            case 'en_GB':
                                url = 'https://share.hsforms.com/1384b9HA-QJiIaho9hD_E3Q2n2jy?email=';
                                break;
                            case 'en_US':
                                url = 'https://share.hsforms.com/1384b9HA-QJiIaho9hD_E3Q2n2jy?email=';
                                break;
                            case 'zh':
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                                break;
                            case 'es':
                                url = 'https://share.hsforms.com/11sb99eqKTmSMRfFSyAp35A2n2jy?email=';
                                break;
                            case 'da':
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                                break;
                            default:
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                        }
                        if(url) {
                            this.util.openExternalLink(url + email);
                        }
                    }
                }
            }
        }
    }

    resetUserPreference() {
        this.openDialogService.openConfirmDialog(
            {data: {
                messageLabel: 'DIALOG.RESET_SHOP_PREFERENCE.MESSAGE',
                cancelLabel: 'DIALOG.RESET_SHOP_PREFERENCE.CANCEL',
                confirmLabel: 'DIALOG.RESET_SHOP_PREFERENCE.CONFIRM'
            }})
        .then((reset) => {
            if(!reset) return;

            this.configurationManager.setUserPreference('dialog.skip_archive.skip_question', false );
            this.configurationManager.setUserPreference('dialog.skip_printing.skip_question', false );
            this.configurationManager.setUserPreference('dialog.skip_delete.skip_question', false );
        })
    }
}
