import * as angular from 'angular';

angular.module('settings').controller('BatchSettingsCtrl', BatchSettingsCtrl);

BatchSettingsCtrl.$inject = ["$scope", "$translate", "checkManager"];

function BatchSettingsCtrl($scope, $translate, checkManager) {
    Object.assign($scope, {
        topbar_context:  {
            title: $translate.instant('SETTINGS.TOPBAR_SETTINGS.BATCH_SETTINGS'),
            view: 'home'
        }
    });

    const generalAutoPrintEnable = checkManager.getShopPreference('general.auto_print.enable');

    if(!generalAutoPrintEnable) {
        checkManager.setShopPreference('general.auto_print.enable', true);
    }
}
