import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').factory('checkStock', ["$mdDialog", "util", function($mdDialog, util) {

    checkStockController.$inject = ["$scope", "$mdDialog", "$translate", "restManager"];
    function checkStockController($scope, $mdDialog, $translate, restManager) {
        _.assign($scope, {
            stockDictionaryFilterResult: [],
            enabledField: 'barcode',
            inProgress: false,
            queryValue: null
        });

        $scope.search = async () => {
            if(!$scope.formEmpty()) {
                $scope.inProgress = true;
                const query = { stock_location_id: 1 };

                if($scope.sku) {
                    query.sku = $scope.queryValue = $scope.sku;
                } else if ($scope.code) {
                    query.code = $scope.queryValue = $scope.code;
                } else if ($scope.name) {
                    query.name = $scope.queryValue = $scope.name;
                } else if ($scope.barcode) {
                    query.barcode = $scope.queryValue = $scope.barcode;
                }

                $scope.stockDictionaryFilterResult = [];
                const results = await restManager.getList('chain/stock', query);
                $scope.stockDictionaryFilterResult = _.orderBy(results, ['shop_name'], ['asc']);
                $scope.inProgress = false;
            }
        };

        $scope.enableField = function(field) {
            if (field !== $scope.enabledField) {
                //$scope.stockDictionaryFilterResult = [];
                $scope.enabledField = field;
                $scope.sku = undefined;
                $scope.barcode = undefined;
                $scope.code = undefined;
                $scope.name = undefined;
                $scope.checkstockForm.$setPristine();
            }
        };

        $scope.onBarcodeScanned = function(result) {
            $scope.barcode = result;
            $scope.checkstockForm.barcode.$setDirty();
            $scope.search();
        };

        $scope.formEmpty = function() {
            return !$scope.sku && !$scope.barcode && !$scope.code && !$scope.name;
        };

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.getQuantity = function(stock) {
            if (!_.isNil(stock.stock_quantity)) {
                return util.round(stock.stock_quantity, 4) + (stock.unit ? ' ' + stock.unit : '');
            } else {
                if (stock.available === 'available') {
                    return  $translate.instant('CASHREGISTER.CHECK_STOCK.AVAILABLE');
                } else if (stock.available === 'unavailable') {
                    return $translate.instant('CASHREGISTER.CHECK_STOCK.NOT_AVAILABLE');
                } else if (stock.available === 'alert') {
                    return $translate.instant('CASHREGISTER.CHECK_STOCK.LOW_STOCK');
                }
            }
        };

        $scope.getDetails = function(stock){
            var details = (stock.options_values && stock.combination ? stock.options_values + ' ' + stock.combination : stock.options_values || '' + stock.combination + '');

            if(!_.isNil(details) && !_.includes(['null','undefined'], details)) {
                return details;
            } else {
                return '-';
            }
        };
    }

    var checkStock = {
        show: function() {
            return $mdDialog.show({
                controller: checkStockController,
                template: require('./check-stock.html')
            });
        }
    };

    return checkStock;
}]);
