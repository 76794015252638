<tilby-pull-to-request *ngIf="scrollToReload" class="tw-absolute tw-left-[50%] tw-translate-x-1/2 tw-z-10" [targetElementRef]="hostRef" (refresh)="reloadData()"/>
<div class="dashboard-bg tw-w-full tw-overflow-y-auto tw-h-full" (scroll)="onScroll($event)">
    <div class="tw-h-[225px] md:tw-h-[130px] tw-mr-5 tw-mb-5" [ngClass]="isMobile ? 'tw-ml-5' : 'tw-ml-0'">
        <mat-card *skeleton="isLoading">
            <mat-card-content>
                <div class="tw-flex tw-justify-center tw-mb-4 md:tw-mb-2 sm:tw-justify-end">
                    <mat-icon [svgIcon]="this.objData.data.today.weather_icon||''"></mat-icon>
                    <span class="tw-w-auto tw-ml-2 tw-leading-[1.7rem]">{{objData.data.date | tilbyDate:"cccc dd MMMM YYYY"}}</span>
                </div>
                <div class="tw-flex tw-flex-wrap md:tw-flex-nowrap">
                    <div class="tw-w-full tw-text-center md:tw-w-[50px] md:tw-ml-3 tw-mb-4 md:tw-mb-0">
                        <mat-icon class="tw-h-[50px] tw-w-[50px] tw-text-[#689f38] tw-text-[50px]">account_circle</mat-icon>
                    </div>
                    <div class="tw-w-full md:tw-w-[550px] tw-mt-auto tw-mb-4 md:tw-ml-5 md:tw-mb-auto">
                        <h1 class="tw-font-normal tw-m-0 tw-text-center md:tw-text-left">{{ "DASHBOARD.HELLO" | translate : {value: user.first_name} }}</h1>
                    </div>
                    <div class="tw-flex tw-w-full tw-flex-col tw-mt-auto tw-mb-auto">
                        <span class="tw-w-full" translate>DASHBOARD.DAILY_GOAL</span>
                        <div class="tw-flex tw-flex-nowrap tw-mt-1">
                            <div class="tw-font-bold tw-whitespace-nowrap">{{objData.data.progress.current || 0 | tilbyCurrency}}</div>
                            <mat-progress-bar class="tw-mt-2 tw-ml-2 tw-mr-2" mode="determinate" [value]=objData.data.progress.percentage></mat-progress-bar>
                            <div class="tw-font-bold tw-whitespace-nowrap">{{objData.data.progress.target || 0 | tilbyCurrency}}</div>
                            <div class="tw-font-bold tw-whitespace-nowrap tw-ml-1" [hidden]="!objData.data.progress.remainder">
                                + {{objData.data.progress.remainder || 0 | tilbyCurrency}}
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="tw-flex tw-h-[500px] min-[960px]:tw-h-[500px] min-[600px]:tw-h-[620px] tw-flex-wrap tw-my-5" [ngClass]="isMobile ? 'tw-ml-5' : 'tw-ml-0'">
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5 tw-mt-4 sm:tw-mt-0 tw-pr-5 tw-h-[300px] min-[960px]:tw-h-[300px]">
            <mat-card *skeleton="isLoading" class="tw-h-[300px] min-[960px]:tw-h-[300px]">
                <div class="tw-flex tw-justify-between tw-items-center tw-p-4">
                    <div class="tw-align-sub">
                        <mat-icon class="tw-align-sub">store_mall_directory</mat-icon>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="(objData.data.today.total_sales||0) > 1">{{ objData.data.today.total_sales }}</span>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.today.total_sales === 0"
                              translate>DASHBOARD.TODAY_SALES_COUNT_0</span>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.today.total_sales === 1"
                              translate>DASHBOARD.TODAY_SALES_COUNT_1</span>
                        <span class="tw-text-l tw-ml-1" *ngIf="(objData.data.today.total_sales||0) > 1" translate>DASHBOARD.TODAY_SALES_COUNT_MORE</span>
                    </div>
                    <div class="tw-flex">
                        <button mat-icon-button aria-label="Best products" (click)="openBestProducts()" *ngIf="show_best_products && bestItems">
                            <mat-icon>list_alt</mat-icon>
                        </button>
                        <button mat-icon-button aria-label="Open Details" (click)="openDetails()">
                            <mat-icon>zoom_in</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="tw-flex tw-justify-end tw-m-7">
                    <!-- <span class="tw-font-bold tw-text-4xl">0,00 €</span> -->
                    <span class="tw-font-bold tw-text-4xl">{{objData.data.today.total_amount || 0 | tilbyCurrency}}</span>
                </div>
            </mat-card>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5 tw-pr-5 tw-h-[300px] min-[960px]:tw-h-[300px]">
            <mat-card *skeleton="isLoading" class="tw-h-[300px] min-[960px]:tw-h-[300px] tw-overflow-y-auto">
                <div
                    class="tw-flex tw-justify-between tw-mt-4 tw-mr-4 tw-ml-4 tw-mb-3 tw-border-0 tw-border-b-2 tw-border-solid">
                    <div>
                        <mat-icon class="tw-align-sub">outbox</mat-icon>
                        <span class="tw-font-bold tw-text-l tw-align-baseline tw-pl-2"
                              translate>DASHBOARD.TOTAL_DRAWER</span>
                    </div>
                    <div>
                        <span
                            class="tw-font-bold tw-leading-[1.7rem]">{{objData.data.today.current_cash || 0 | tilbyCurrency}}</span>
                    </div>
                </div>
                <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2"
                     *ngFor="let payment of objData.data.today.payments">
                    <span>{{payment.name}}</span>
                    <span>{{payment.amount || 0 | tilbyCurrency}}</span>
                </div>
            </mat-card>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5 tw-pr-5 tw-h-[300px] min-[960px]:tw-h-[300px]">
            <mat-card *skeleton="isLoading" class="tw-h-[300px] min-[960px]:tw-h-[300px]">
                <div>
                    <div class="tw-flex tw-justify-between tw-mt-4 tw-mr-4 tw-ml-4 tw-mb-0">
                        <span class="tw-font-bold tw-text-l tw-align-baseline tw-leading-[1.7rem]" translate>DASHBOARD.FISCAL_RECEIPTS</span>
                        <span class="tw-font-bold tw-leading-[1.7rem]">{{this.objData.data.today.fiscal_receipts?.total?.amount ?? 0 | tilbyCurrency}}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mt-1 tw-mr-4 tw-ml-4 tw-mb-0"
                         *ngIf="false && this.objData.data.last_closing !== null">
                        <span class="tw-font-bold tw-text-l" translate>DASHBOARD.LAST_CLOSING</span>
                        <span class="tw-font-bold tw-text-l">{{this.objData.data.last_closing}}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mt-2 tw-mr-4 tw-ml-4 tw-mb-2" *ngIf="this.objData.data.missing_sends > 0">
                        <span class="tw-text-red-500" *ngIf="this.objData.data.missing_sends === 1" translate>DASHBOARD.ONE_CLOSING_TO_SEND</span>
                        <span class="tw-text-red-500" *ngIf="this.objData.data.missing_sends > 1">
                            {{'DASHBOARD.MULTIPLE_CLOSING_TO_SEND' | translate:{'value': this.objData.data.missing_sends} }}
                        </span>
                    </div>
                </div>
                <div class="tw-flex tw-justify-between tw-pt-2 tw-mr-4 tw-ml-4 tw-mb-1 tw-border-0 tw-border-t-2 tw-border-solid">
                    <span class="tw-font-bold tw-text-l tw-align-baseline tw-leading-[1.7rem]" translate>DASHBOARD.INVOICES</span>
                    <span class="tw-font-bold tw-leading-[1.7rem]">{{this.objData.data.today.invoices?.total?.amount || 0 | tilbyCurrency}}</span>
                </div>
                <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2" *ngIf="this.objData.country === 'IT'">
                    <div>
                        <span class="tw-text-l tw-align-baseline">{{objData.data.e_invoices.pending || 0 }}</span>
                        <span class="tw-text-l tw-align-baseline tw-ml-1" translate>DASHBOARD.INVOICES_PENDING_LABEL</span>
                    </div>
                </div>
                <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2" *ngIf="this.objData.country === 'IT'">
                    <div>
                        <span class="tw-text-l tw-align-baseline">{{objData.data.e_invoices.error || 0}}</span>
                        <span class="tw-text-l tw-align-baseline tw-ml-1" translate>DASHBOARD.INVOICES_ERROR_LABEL</span>
                    </div>
                </div>
                <div *ngIf="!consumptionInvoices && this.objData.country === 'IT'">
                    <div class="tw-flex tw-justify-between tw-pt-2 tw-mt-2 tw-mr-4 tw-ml-4 tw-mb-1 tw-border-0 tw-border-t-2 tw-border-solid">
                        <span class="tw-font-bold tw-text-l tw-align-baseline tw-leading-[1.7rem]" translate>DASHBOARD.SUMMARY_INVOICES</span>
                        <span class="tw-font-bold tw-leading-[1.7rem]">{{this.objData.data.today.summary_invoices?.total?.amount || 0 | tilbyCurrency}}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2">
                        <span class="tw-text-l tw-align-baseline" translate>DASHBOARD.SUMMARY_RC_INVOICES</span>
                        <span class="tw-text-l tw-align-baseline">{{this.objData.data.today.summary_invoices?.claimed?.amount || 0 | tilbyCurrency}}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2">
                        <span class="tw-text-l tw-align-baseline" translate>DASHBOARD.SUMMARY_NRC_INVOICES</span>
                        <span class="tw-text-l tw-align-baseline">{{this.objData.data.today.summary_invoices?.unclaimed?.amount || 0 | tilbyCurrency}}</span>
                    </div>
                </div>

                @if (consumptionInvoices) {
                    <div class="tw-flex tw-justify-between tw-pt-2 tw-mr-4 tw-ml-4 tw-mb-1 tw-border-0 tw-border-t-2 tw-border-solid">
                        <span class="tw-font-bold tw-text-l tw-align-baseline tw-leading-[1.7rem]" translate>DASHBOARD.INVOICES_CONSUMPTION</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2">
                        <span class="tw-text-l tw-align-baseline" translate>DASHBOARD.PURCHASED_INVOICES</span>
                        <span class="tw-text-l tw-align-baseline">{{ consumptionInvoices.acquistati || '-' }}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2">
                        <span class="tw-text-l tw-align-baseline" translate>DASHBOARD.INVOICES_USED</span>
                        <span class="tw-text-l tw-align-baseline">{{ consumptionInvoices.utilizzati || '-' }}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2" [ngClass]="getRemainingInvoicesClass()">
                        <span class="tw-text-l tw-align-baseline" translate>DASHBOARD.REMAINING_INVOICES</span>
                        <span class="tw-text-l tw-align-baseline">{{ getRemainingInvoices() || '-' }}</span>
                    </div>
                    <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-2">
                        <span class="tw-text-l tw-align-baseline" translate>DASHBOARD.CREDIT_EXPIRATION</span>
                        <span class="tw-text-l tw-align-baseline">{{ consumptionInvoices.dataScadenza || '-' }}</span>
                    </div>
                }
            </mat-card>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5 tw-pr-5 tw-h-[250px] min-[960px]:tw-h-[250px] min-[600px]:tw-h-[300px]">
            <mat-card *skeleton="isLoading" class="tw-h-[250px] min-[960px]:tw-h-[250px] min-[600px]:tw-h-[300px]">
                <div class="tw-flex tw-justify-between tw-mt-4 tw-mr-4 tw-ml-4 tw-mb-2 tw-pb-3 tw-border-0 tw-border-b-2 tw-border-solid">
                    <div>
                        <mat-icon class="tw-align-sub">widgets</mat-icon>
                        <span class="tw-font-bold tw-text-xl tw-align-baseline tw-pl-2" translate>DASHBOARD.STOCKS</span>
                    </div>
                    <mat-icon class="tw-align-sub tw-leading-[1.9rem]" *ngIf="objData.canOpenStocks" (click)="openStocks()">zoom_in</mat-icon>
                </div>
                <div class="tw-flex tw-mr-4 tw-ml-4 tw-mt-2">
                    <mat-icon class="tw-text-yellow-300">circle</mat-icon>
                    <div>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.stocks.alert > 1">{{objData.data.stocks.alert}}</span>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.stocks.alert === 0" translate>DASHBOARD.UNDER_STOCK_0</span>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.stocks.alert === 1" translate> DASHBOARD.UNDER_STOCK_1</span>
                        <span class="tw-text-l tw-ml-1" *ngIf="objData.data.stocks.alert > 1" translate>DASHBOARD.UNDER_STOCK_MORE</span>
                    </div>
                </div>
                <div class="tw-flex tw-mr-4 tw-ml-4 tw-mt-3">
                    <mat-icon class="tw-text-red-500">circle</mat-icon>
                    <div>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.stocks.unavailable > 1">{{objData.data.stocks.unavailable}}</span>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.stocks.unavailable === 0"
                              translate>DASHBOARD.UNAVAILABLE_STOCK_0</span>
                        <span class="tw-text-l tw-ml-1 tw-align-baseline tw-leading-[1.7rem]"
                              *ngIf="objData.data.stocks.unavailable === 1"
                              translate> DASHBOARD.UNAVAILABLE_STOCK_1</span>
                        <span class="tw-text-l tw-ml-1" *ngIf="objData.data.stocks.unavailable > 1" translate>DASHBOARD.UNAVAILABLE_STOCK_MORE</span>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5 tw-pr-5 tw-h-[250px] min-[960px]:tw-h-[250px]">
            <mat-card *skeleton="isLoading" class="tw-h-[250px] min-[960px]:tw-h-[250px]">
                <div class="tw-flex tw-flex-col tw-h-[250px] min-[960px]:tw-h-[250px] tw-justify-between">
                    <div>
                        <div class="tw-flex tw-mt-4 tw-mr-4 tw-ml-4 tw-mb-2 tw-pb-3 tw-border-0 tw-border-b-2 tw-border-solid">
                            <mat-icon class="tw-align-sub">{{objData.data.selectedNews.icon}} event</mat-icon>
                            <span class="tw-font-bold tw-text-l tw-align-baseline tw-leading-[1.7rem] tw-pl-2">{{objData.data.selectedNews.title}}</span>
                        </div>
                        <div class="tw-flex tw-justify-between tw-mr-4 tw-ml-4 tw-mb-1 tw-mt-3">
                            <span class="tw-text-l">{{objData.data.selectedNews.content}}</span>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-end tw-mb-4 tw-mr-6" *ngIf="objData.data.selectedNews.url">
                        <button mat-button class="tw-uppercase" aria-label="Approfondisci" (click)="openUrl(objData.data.selectedNews.url)" >{{"DASHBOARD.DETAILS"|translate}}</button>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="tw-w-full min-[960px]:tw-basis-2/6 min-[600px]:tw-basis-3/6 tw-mb-5 tw-pr-5 tw-h-[250px] min-[960px]:tw-h-[250px]">
            <mat-card *skeleton="isLoading" class="tw-h-[250px] min-[960px]:tw-h-[250px]">
                <div class="tw-flex tw-mr-4 tw-ml-4 tw-mb-3 tw-mt-3">
                    <span class="tw-text-l" translate>DASHBOARD.FAST_LINKS</span>
                </div>
                <div class="tw-flex tw-mr-4 tw-ml-4 tw-pl-2 tw-pt-2 tw-pb-2 tw-border-0 tw-border-b-2 tw-border-solid hover:tw-bg-gray-300 tw-transition tw-duration-700"
                    (click)="openUrl('https://analytics.tilby.com')">
                    <mat-icon svgIcon="analytics"></mat-icon>
                    <span class="tw-text-l tw-pl-2 tw-align-baseline tw-leading-[1.7rem]">Analytics</span>
                </div>
                <div class="tw-flex tw-mr-4 tw-ml-4 tw-pl-2 tw-pt-2 tw-pb-2 tw-border-0 tw-border-b-2 tw-border-solid hover:tw-bg-gray-200 tw-transition tw-duration-700"
                    (click)="openUrl('https://tilby.com/integrazioni')">
                    <mat-icon>device_hub</mat-icon>
                    <span class="tw-text-l tw-pl-2 tw-align-baseline tw-leading-[1.7rem]" translate>DASHBOARD.INTEGRATIONS</span>
                </div>
                <div class="tw-flex tw-mr-4 tw-ml-4 tw-pl-2 tw-pt-2 tw-pb-2 hover:tw-bg-gray-200 tw-transition tw-duration-700"
                    (click)="openUrl('https://support.tilby.com')">
                    <mat-icon>headset_mic</mat-icon>
                    <span class="tw-text-l tw-pl-2 tw-align-baseline tw-leading-[1.7rem]" translate>DASHBOARD.SUPPORT</span>
                </div>
            </mat-card>
        </div>
        <div class="tw-w-full tw-h-[10px]"></div>
    </div>
</div>


