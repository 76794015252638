import { Injectable, inject } from '@angular/core';
import {
    errorsLogger,
    restManager,
} from "app/ajs-upgraded-providers";
import { catchError, retry, Subject, defer, firstValueFrom } from 'rxjs';
import { v4 as generateUuid, validate as validateUuid } from 'uuid';
import { QueryPagination } from "../../../shared/model/model";

import {
    Entity,
    EntityHook,
    EntityName,
    OfflineFirstEntity,
    PaginatedResponse
} from './entity.model';

import { EntitySchema, EntitySchemaValue } from 'tilby-models';

import {
    ApplicationReportsEntityHook,
    ConnectionService,
    SaleTransactionsEntityHook,
    SalesEntityHook,
    StartupQueriesService,
    StorageManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';

type SyncResult = {
    updatedCollection: (number | string)[],
    deletedCollection: (number | string)[],
    failedCollection: { id: (number | string), reason: string }[]
}

type FetchCollectionOnlineOptions = {
    perPage?: number;
    paginatedFetch?: boolean;
    onPageFetchSuccess?: Function;
    onPageFetchError?: Function;
}

type EntityUpdateAction = ('CREATED' | 'UPDATED' | 'DELETED' | 'CLOSED');

export type EntityUpdateInfo = {
    action: EntityUpdateAction;
    id: (string | number);
    entity?: Entity;
    entityName: EntityName;
    externalClient?: boolean;
    noOrigin?: boolean;
}

type ExternalEntityUpdateInfo = Pick<EntityUpdateInfo, ('action' | 'externalClient' | 'noOrigin')>

export type OfflineFirstCompletionInfo = {
    entity: Entity;
    entityName: EntityName;
    method: 'POST' | 'PUT';
    uuid?: string;
}

@Injectable({
    providedIn: 'root'
})
export class EntityHooksService {
    private readonly application_reports = inject(ApplicationReportsEntityHook);
    private readonly sales = inject(SalesEntityHook);
    private readonly sale_transactions = inject(SaleTransactionsEntityHook);

    public getEntityHook(entityName: EntityName): EntityHook | null {
        switch (entityName) {
            case 'application_reports':
                return this.application_reports;
            case 'sales':
                return this.sales;
            case 'sale_transactions':
                return this.sale_transactions;
            default:
                return null;
        }
    }
}

export class EntityBase<EntityT extends Entity> {
    //Injections
    private connectionService = inject(ConnectionService);
    private restManagerService = inject(restManager);
    private errorsLoggerService = inject(errorsLogger);
    private entityHooksService = inject(EntityHooksService);
    private storageManagerService = inject(StorageManagerService);
    private startupQueriesService = inject(StartupQueriesService);
    private userActiveSessionManagerService = inject(UserActiveSessionManagerService);

    //Static properties
    private static readonly entitiesWithId = new Set([
        'channels',
        'payment_methods',
        'shop_preferences',
        'device_preferences',
        'shop_settings',
        'user_preferences',
        'user_settings',
        'vat'
    ]);

    private static readonly entitiesToDiscardAfterSend = new Set([
        'application_reports'
    ]);

    private static readonly entitiesWithoutSchema = new Set([
        'application_reports',
        'device_preferences',
        'slave_devices'
    ]);

    private static readonly entitiesWithOnlyUuid = new Set([
        'slave_devices'
    ]);

    private static readonly offlineFirstEntities = new Set([
        "application_reports",
        "cash_movements",
        "customers",
        "daily_closings",
        "dgfe",
        "orders",
        "sales",
        "sale_transactions",
        "shop_preferences",
        'device_preferences',
        "slave_devices",
        "tickets",
        "user_preferences"
    ]);

    private static readonly allowsNullValues = new Set([
        "sale_transactions"
    ]);

    //Subjects/Observables
    private static offlineFirstCompletionSubject = new Subject<OfflineFirstCompletionInfo>(); // Signals that an offline-first operation has completed for an entity
    private static entityUpdatesSubject = new Subject<EntityUpdateInfo>(); // Used to signal external updates for an entity (e.g. from IOT or in case EntityBase notices a change/deletion not present in the local DB)

    public static offlineFirstCompletion$ = EntityBase.offlineFirstCompletionSubject.asObservable();
    public static entityUpdates$ = EntityBase.entityUpdatesSubject.asObservable();

    //Instance properties
    private readonly needsEntityId: boolean;
    private readonly discardAfterSend: boolean;
    private readonly hasSchema: boolean;
    private readonly hasOnlyUuid: boolean;
    private readonly allowsNullValues: boolean;
    public readonly isOfflineFirst: boolean;

    private reloadPromise: Promise<EntityT[]> | null = null;

    constructor(private entityName: EntityName) {
        this.needsEntityId = EntityBase.entitiesWithId.has(this.entityName);
        this.discardAfterSend = EntityBase.entitiesToDiscardAfterSend.has(this.entityName);
        this.hasSchema = !EntityBase.entitiesWithoutSchema.has(this.entityName);
        this.hasOnlyUuid = EntityBase.entitiesWithOnlyUuid.has(this.entityName);
        this.isOfflineFirst = EntityBase.offlineFirstEntities.has(this.entityName);
        this.allowsNullValues = EntityBase.allowsNullValues.has(this.entityName);
    }

    private logEvent(level: string, ...args: any[]) {
        this.errorsLoggerService[level](`[ EntityBase ] [ ${this.entityName} ]`, ...args);
    }

    /**
     * Sends an entity update with the specified action, entityId, and optional entity, externalClient, and noOrigin parameters.
     *
     * @param {EntityUpdateAction} action - The action performed on the entity.
     * @param {string | number} entityId - The ID of the entity.
     * @param {Entity} [entity] - The entity object.
     * @param {boolean} [externalClient] - Indicates whether the update is from an external client.
     * @param {boolean} [noOrigin] - Indicates whether the update has an origin.
     */
    private sendEntityUpdate(action: EntityUpdateAction, entityId: (string | number), entity?: Entity, externalClient?: boolean, noOrigin?: boolean) {
        EntityBase.entityUpdatesSubject.next({
            action,
            entity,
            entityName: this.entityName,
            id: entityId,
            externalClient: !!externalClient,
            noOrigin: !!noOrigin
        });
    }

    /**
     * Determines if the local entity has been changed compared to the API entity.
     *
     * @param {Entity} localEntity - The local entity to compare.
     * @param {Entity} apiEntity - The API entity to compare.
     * @return {boolean} True if the local entity has been changed, false otherwise.
     */
    private static isLocalEntityChanged(localEntity: Entity, apiEntity: Entity): boolean {
        if (localEntity && apiEntity && 'lastupdate_at' in localEntity && 'lastupdate_at' in apiEntity) {
            return (apiEntity.lastupdate_at! < localEntity.lastupdate_at!);
        }

        return false;
    }

    /**
     * Cleanups the entity by removing empty parameters and offline management flags
     *
     * @param {any} obj - The object to cleanup
     * @return {any} - The cleaned object
     */
    private static cleanupEntity(obj: any, fieldsToRemove: string[] = [], options?: { nested?: boolean, allowNullValues?: boolean }): any {
        if (typeof obj !== 'object' || obj == null) {
            return obj;
        }

        return Object.keys(obj).reduce((acc: any, key: string) => {
            // Remove offline management flags and other fields (root level only)
            if (!options?.nested) {
                if (['deleted', 'dirty', 'synchronizing', ...fieldsToRemove].includes(key)) {
                    return acc;
                }
            }

            const value = EntityBase.cleanupEntity(obj[key], fieldsToRemove, { ...options, nested: true });

            // Insert the property only if it is not null/undefined
            if (value != null || (options?.allowNullValues && value !== undefined)) {
                acc[key] = value;
            }

            return acc;
        }, Array.isArray(obj) ? [] : {});
    }

    /**
     * Prepares an entity for API send by cleaning up its fields based on the entity schema.
     *
     * @param {EntityT} entity - The entity to be prepared for sending.
     * @return {Promise<EntityT>} - The prepared entity.
     */
    private async prepareEntityForSend<EntityT>(entity: EntityT): Promise<EntityT> {
        let schema: EntitySchemaValue | undefined;
        let fieldsToRemove: string[] = [];

        //Get entity schema if available
        if (this.hasSchema) {
            //Get entity schema from local storage
            let result = await this.storageManagerService.getOne('entity_schemas', this.entityName) as EntitySchema;

            if (result) {
                schema = result.value!;
            } else {
                //Try updating schemas from cloud
                try {
                    const collection = await this.restManagerService.getList('entity_schemas') as EntitySchema[];
                    await this.storageManagerService.saveCollection('entity_schemas', collection);

                    schema = collection.find(schema => schema.id === this.entityName)?.value;
                } catch (error) {
                    this.logEvent('err', `[ prepareEntityForSend ] cannot fetch schemas from remote`);
                    throw error;
                }
            }

            if (!schema) {
                this.logEvent('err', `[ prepareEntityForSend ] cannot find schema`);
                throw 'SCHEMA_NOT_FOUND';
            }

            //Create blacklist of fields to remove (read-only fields in the root level)
            for (const fieldName in schema.properties) {
                if (!['id'].includes(fieldName) && schema.properties[fieldName as keyof typeof schema.properties]?.readOnly) {
                    fieldsToRemove.push(fieldName);
                }
            }
        }

        return EntityBase.cleanupEntity(JSON.parse(JSON.stringify(entity)), fieldsToRemove, { allowNullValues: this.allowsNullValues });
    };

    /**
     * A private function that performs the online part of the offline-first POST.
     *
     * @param {OfflineFirstEntity<EntityT>} entity - The entity to send.
     * @return {Promise<EntityT>} The created entity.
     */
    private async customizedPostOfflineFirst(entity: OfflineFirstEntity<EntityT>) {
        const entityLocal = structuredClone(entity);
        const entityId = entityLocal.id!;
        const hasUuidKey = validateUuid(String(entityId));

        let entityUuid;

        if (hasUuidKey) {
            entityUuid = entityLocal.id as string;
        } else if ('uuid' in entityLocal) {
            entityUuid = entityLocal.uuid;
        }

        try {
            //Handle before create hook
            let hookResult;

            const entityHook = this.entityHooksService.getEntityHook(this.entityName);

            if (entityHook?.createOfflineFirstBeforeApi) {
                hookResult = await entityHook.createOfflineFirstBeforeApi(entityId, entityLocal);
            }

            if (hookResult?.updateStorage) {
                await this.storageManagerService.saveOne(this.entityName, entityLocal, {
                    dirty: !(hookResult.skipApiCreate),
                    synchronizing: !(hookResult.skipApiCreate)
                });
            }

            if (hookResult?.skipApiCreate) {
                return entityLocal;
            }

            const entityToSend = await this.prepareEntityForSend(entityLocal);

            if (hasUuidKey) {
                Object.assign(entityToSend, { id: undefined });
            }

            const result = await this.restManagerService.post(this.entityName, entityToSend) as EntityT;

            if (!this.discardAfterSend) {
                let entityToSave = result;
                let saveAsDirty = false;

                if (hasUuidKey) {
                    //Check if the local entity has not changed in the meantime
                    let oldEntity = await this.storageManagerService.getOne(this.entityName, entityUuid as string);

                    if (EntityBase.isLocalEntityChanged(oldEntity, result)) {
                        //Local entity has a more recent lastupdate_at, that means the entity has been changed in the meantime:
                        //keep the local entity, but assign the API id
                        oldEntity.id = result.id;
                        entityToSave = oldEntity;
                        saveAsDirty = true;
                    }
                }

                await this.storageManagerService.saveOne(this.entityName, entityToSave, { dirty: saveAsDirty });
            }

            if (hasUuidKey) {
                //Delete the old uuid local entity, as we don't need it anymore
                await this.storageManagerService.deleteOne(this.entityName, entityUuid as string);
            }

            EntityBase.offlineFirstCompletionSubject.next({
                entityName: this.entityName,
                entity: result,
                uuid: entityUuid,
                method: 'POST'
            });

            return result;
        } catch (error) {
            await this.storageManagerService.updateOne(this.entityName, entityId, { synchronizing: null });
            throw error;
        }
    };

    /**
     * A private function that performs the online part of the offline-first PUT.
     *
     * @param {OfflineFirstEntity<EntityT>} entity - The entity to send.
     * @return {Promise<EntityT>} The updated entity.
     */
    private async customizedPutOfflineFirst(entity: OfflineFirstEntity<EntityT>) {
        const entityLocal = structuredClone(entity);
        const entityID = entityLocal.id!;

        if (validateUuid(String(entityID)) && !this.hasOnlyUuid) {
            this.logEvent('warn', '[ putOneOfflineFirst ] aborting PUT because entity id is an UUID');
            throw 'UUID_NOT_ALLOWED';
        }

        try {
            const entityToSend = await this.prepareEntityForSend(entityLocal);
            const result = await this.restManagerService.put(this.entityName, entityID, entityToSend) as EntityT;

            //Check if the local entity has not changed in the meantime
            const oldEntity = await this.storageManagerService.getOne(this.entityName, entityID) as OfflineFirstEntity<EntityT>;

            if (!EntityBase.isLocalEntityChanged(oldEntity, result)) {
                //Local entity hasn't been changed in the meantime or is older than the result, so we can safely overwrite
                try {
                    await this.storageManagerService.saveOne(this.entityName, result, { dirty: false });
                } catch (error) {
                    this.logEvent('err', '[ putOneOfflineFirst ] cannot save entity after successful PUT');
                }
            } else {
                //Local entity has been changed in the meantime, so unlock it so it can be synchronized
                await this.storageManagerService.updateOne(this.entityName, entityID, { synchronizing: null });
            }

            //Send an event that the entity has been updated in the backend
            EntityBase.offlineFirstCompletionSubject.next({
                entityName: this.entityName,
                entity: structuredClone(result),
                method: 'PUT'
            });

            //If the backend has a newer lastupdate_at, send an event that the entity has been updated
            if ('lastupdate_at' in result && 'lastupdate_at' in oldEntity && result.lastupdate_at && result.lastupdate_at > oldEntity.lastupdate_at!) {
                this.sendEntityUpdate('UPDATED', entityID, structuredClone(result), false, false);
            }

            return result;
        } catch (error) {
            await this.storageManagerService.updateOne(this.entityName, entityID, { synchronizing: null });
            throw error;
        }
    };

    /**
     * Fetches one entity from the API and optionally saves it to storage.
     *
     * @param {(string | number)} id - The ID of the entity to fetch.
     * @param {boolean} [saveToStorage] - Whether to save the entity to storage or not.
     * @return {Promise<EntityT>} The fetched entity, or undefined if not found.
     */
    public async fetchOneOnline(id: (string | number), saveToStorage?: boolean, options?: { notification?: ExternalEntityUpdateInfo }) {
        if (!id) {
            this.logEvent('err', '[ fetchOneOnline ] id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        //Fetch the entity from the API
        const result = await this.restManagerService.getOne(this.entityName, id) as EntityT;

        //If the entity is not found on the API, delete it from storage
        if (!result) {
            await this.deleteOneOffline(id, { notification: { action: 'DELETED' } });

            return undefined;
        }

        // Save the entity to storage if requested
        if (saveToStorage) {
            await this.saveOneOffline(result, { notification: options?.notification });
        }

        return result;
    };

    /**
     * Fetches a collection online based on the given query, saves it to storage (optional), and returns the collection.
     *
     * @param {any & QueryPagination} query - The query to fetch the collection.
     * @param {boolean | Object} [saveToStorage] - Whether to save the collection to storage.
     * @param {FetchCollectionOnlineOptions} [options] - Additional options for fetching the collection.
     * @returns {Promise<EntityT[]>} The fetched collection.
     */
    public async fetchCollectionOnline(query?: any & QueryPagination, saveToStorage?: boolean | Object, options?: FetchCollectionOnlineOptions) {
        const queryLocal = query ? structuredClone(query) : {};

        queryLocal.pagination = !!queryLocal.pagination;

        if (queryLocal.pagination) {
            saveToStorage = false;
        }

        if (!options) {
            options = {};
        }

        //Normal fetch
        if (!options.paginatedFetch) {
            const collection = await this.restManagerService.getList(this.entityName, queryLocal) as (EntityT[] | PaginatedResponse<EntityT>);

            if (saveToStorage && !('per_page' in collection)) {
                await this.storageManagerService.saveCollection(this.entityName, collection);
            }

            return collection;
        }

        //Paginated fetch
        if (!options.perPage) {
            options.perPage = 5000;
        }

        let currentPage = 0;
        let pages: number | null = null;

        const results: EntityT[] = [];
        const savePromises = [];

        do {
            const pagedQuery = Object.assign(structuredClone(queryLocal), {
                page: currentPage,
                per_page: options.perPage,
                pagination: true
            });

            //Fetch the entity from the API
            const dataSet = await firstValueFrom(defer(() => this.restManagerService.getList(this.entityName, pagedQuery) as Promise<PaginatedResponse<EntityT>>).pipe(
                //If an attempt to fetch the page failed, retry and call the onPageFetchError if provided
                catchError((err) => {
                    if (typeof options!.onPageFetchError === 'function') {
                        options!.onPageFetchError();
                    }

                    throw err;
                }),
                retry({ count: 10, delay: 5000 })
            ));

            if (Array.isArray(dataSet.results)) {
                if (!pages) {
                    pages = dataSet.pages;
                }

                if (saveToStorage) {
                    savePromises.push(this.storageManagerService.saveCollection(this.entityName, dataSet.results));
                }

                results.push(...dataSet.results);
            } else {
                pages = 0;
            }

            currentPage++;

            if (typeof options.onPageFetchSuccess === 'function') {
                options.onPageFetchSuccess({ pages: pages, currentPage: currentPage });
            }
        } while (currentPage < (pages || 0));

        //Wait for the local save to complete
        await Promise.all(savePromises);

        //Return the results
        return results;
    };

    /**
     * Sends a POST request to the API to create a new entity.
     * If the request is successful, the response is saved on the local storage.
     *
     * @param {EntityT} entity - The entity to send.
     * @param {Object} [options] - An optional options object.
     * @param {boolean} [options.skipStorage] - Whether to skip storing the entity locally.
     * @return {Promise<EntityT>} The API response with the created entity.
     */
    public async postOneOnline(entity: EntityT, options?: { skipStorage?: boolean }) {
        if (typeof entity !== 'object') {
            this.logEvent('err', '[ postOneOnline ] entity is not an object');
            throw 'NOT_AN_OBJECT';
        }

        if (!this.needsEntityId && entity.id) {
            this.logEvent('err', '[ postOneOnline ] entity.id is defined, has to be undefined');
            throw 'ID_IS_DEFINED';
        }

        const entityToSend = await this.prepareEntityForSend(entity);

        //Send the entity to the API
        const result = await this.restManagerService.post(this.entityName, entityToSend) as EntityT;

        //Save the entity locally
        if (!options?.skipStorage) {
            await this.storageManagerService.saveOne(this.entityName, result);
        }

        return result;
    };

    /**
     * Sends a PUT request to the API to update an existing entity.
     * If the request is successful, the response is saved on the local storage.
     *
     * @param {EntityT} entity - The entity to send.
     * @param {Object} [options] - An optional options object.
     * @param {boolean} [options.skipStorage] - Whether to skip storing the entity locally.
     * @return {Promise<EntityT>} The API response with the created entity.
     */
    public async putOneOnline(entity: EntityT, options?: { skipStorage?: boolean }) {
        if (typeof entity !== 'object') {
            this.logEvent('err', '[ putOneOnline ] entity is not an object');
            throw 'NOT_AN_OBJECT';
        }

        if (!entity.id) {
            this.logEvent('err', '[ putOneOnline ] entity.id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        const entityID = entity.id;
        const entityToSend = await this.prepareEntityForSend(entity);

        //Send the entity to the API
        const result = await this.restManagerService.put(this.entityName, entityID, entityToSend) as EntityT;

        //Save the entity locally
        if (!options?.skipStorage) {
            await this.storageManagerService.saveOne(this.entityName, result);
        }

        return result;
    };

    /**
     * Deletes an entity from the API and locally.
     *
     * @param {string | number} id - The ID of the entity to delete.
     * @param {any} params - (Optional) Additional parameters to pass to the API for the deletion.
     * @return {Promise<string | number>} The ID of the deleted entity.
     */
    public async deleteOneOnline(id: string | number, params?: any) {
        if (!id) {
            this.logEvent('err', '[ deleteOneOnline ] id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        //Delete the entity from the API
        await this.restManagerService.deleteOne(this.entityName, id, params);

        //Delete the entity locally
        await this.storageManagerService.deleteOne(this.entityName, id);

        return id;
    };

    /**
     * Deletes a collection of entities from the API and locally.
     *
     * @param {Array.<string|number>} idList - The list of IDs to delete.
     * @param {Object} params - (Optional) Additional parameters to pass to the API for the deletion.
     * @return {Promise.<Array.<string|number>>} - The list of deleted IDs.
     */
    public async deleteCollectionOnline(idList: (string | number)[], params?: any) {
        if (!Array.isArray(idList)) {
            this.logEvent('err', '[ deleteCollectionOnline ] idList is not an array');
            throw 'INVALID_ID_LIST';
        }

        //Delete the entities from the API
        await this.restManagerService.deleteList(this.entityName, idList, params);

        //Delete the entities locally
        await this.storageManagerService.deleteCollection(this.entityName, idList);

        return idList;
    };

    /**
     * Deletes one item from the local storage.
     *
     * @param {string | number} id - The ID of the item to delete.
     * @return {Promise<string | number | null>} - A promise that resolves when the item is deleted.
     */
    public async deleteOneOffline(id: (string | number), options?: { notification: ExternalEntityUpdateInfo }) {
        if (id == null) {
            this.logEvent('err', '[ deleteOneOffline ] id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        const returnedId = await this.storageManagerService.deleteOne(this.entityName, id);

        //Send an event that the entity has been updated (if requested)
        const notification = options?.notification;

        if (notification) {
            this.sendEntityUpdate(notification.action || 'DELETED', id, undefined, notification.externalClient, notification.noOrigin);
        }

        return returnedId;
    };

    /**
     * Clears the local storage for the entity.
     *
     * @return {Promise<void>} A promise that resolves when the entity storage is cleared.
     */
    public async clearCollectionOffline() {
        return this.storageManagerService.clearCollection(this.entityName);
    };

    /**
     * Deletes a collection from the local storage using an index value as the identifier.
     *
     * @param {string | number} id - The value of the index to search.
     * @param {string} index - The index of the collection to use for the deletion.
     * @return {Promise} - A promise that resolves when the collection is successfully deleted.
     */
    public async deleteCollectionByIndexOffline(id: (string | number), index: string) {
        if (!id) {
            this.logEvent('err', '[ deleteCollectionByIndexOffline ] id is defined');
            throw 'ID_NOT_DEFINED';
        }

        return this.storageManagerService.deleteCollectionByIndex(this.entityName, id, index);
    };

    /**
     * Fetches one entity using only the local storage.
     *
     * @param {string | number} id - The ID of the entity to fetch.
     * @return {Promise<EntityT | undefined>} The fetched entity.
     */
    public async fetchOneOffline(id: (string | number)) {
        if (!id) {
            this.logEvent('err', '[ fetchOneOffline ] id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        // Get existing entity from storage
        const entity = await this.storageManagerService.getOne(this.entityName, id) as (EntityT | undefined);

        return entity;
    };

    /**
     * Fetches a collection of entities using only the local storage.
     *
     * @param {any} query - An optional query object to filter the collection.
     * @return {Promise<EntityT[]>} A promise that resolves to the collection of entities.
     */
    public async fetchCollectionOffline(query?: any) {
        return await this.storageManagerService.getCollection(this.entityName, { ...(query || {}), deleted: false }) as EntityT[];
    };


    /**
     * Fetches a map of entities from the local storage based on the provided array of IDs.
     *
     * @param {(string | number)[]} ids - An array of IDs to fetch entities for.
     * @return {Record<number | string, EntityT>} The map of fetched entities with IDs as keys.
     */
    public async fetchMapFromIdsOffline(ids: (string | number)[]) {
        const itemsMap: Record<number | string, EntityT> = {};

        for (const id of new Set(ids)) {
            if (id == null) {
                continue;
            }

            // Get existing entity from storage
            const item = await this.fetchOneOffline(id);

            if (item) {
                itemsMap[id] = item;
            }
        }

        return itemsMap;
    }

    /**
     * Fetches one item using the offline-first approach.
     *
     * @param {string | number} id - The ID of the item to fetch.
     * @return {Promise<EntityT | undefined>} - A promise that resolves to the fetched item, or undefined if not found.
     */
    public async fetchOneOfflineFirst(id: (string | number)) {
        if (!id) {
            this.logEvent('err', '[ fetchOneOfflineFirst ] id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        const result = await this.storageManagerService.getOne(this.entityName, id) as EntityT;

        if (result) {
            return result;
        }

        return this.fetchOneOnline(id, true);
    };

    /**
     * This function updates an entity in offline-first mode.
     * The returned promise resolves when the entity is saved locally,
     * the cloud update is notified using the offlineFirstCompletion$ observable,
     *
     * @param {EntityT} entity - The entity to update.
     * @param {Object} [options] - An optional options object.
     * @param {boolean} [options.awaitOnline] - Whether to wait for the entity to be updated on the cloud. In case of online issues, the local entity will be returned.
     * @return {Promise} A promise that resolves as soon as the entity is updated locally (unless awaitOnline is set).
     */
    async putOneOfflineFirst(entity: EntityT, options?: { awaitOnline?: boolean }) {
        if (typeof entity !== 'object') {
            this.logEvent('err', '[ putOneOfflineFirst ] entity is not an object');
            throw 'NOT_AN_OBJECT';
        }

        if (!entity.id) {
            this.logEvent('err', '[ putOneOfflineFirst ] entity.id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        const entityId = entity.id;

        const entityLocal = Object.assign(structuredClone(entity), {
            lastupdate_at: new Date().toISOString(),
            lastupdate_by: this.userActiveSessionManagerService.getSession()?.id!
        });

        if (!('uuid' in entityLocal) || !entityLocal.uuid) {
            Object.assign(entityLocal, { uuid: generateUuid() })
        }

        //Attempt to retrieve existing entity on storage
        let oldEntity = await this.storageManagerService.getOne(this.entityName, entityId) as OfflineFirstEntity<EntityT>;

        //If the entity isn't found and the id is a uuid, try to retrieve it from storage using the uuid field
        if (!oldEntity && validateUuid(String(entityId))) {
            const results = await this.storageManagerService.getCollection(this.entityName, { uuid: entityId }) as OfflineFirstEntity<EntityT>[];

            if (results.length) {
                oldEntity = results[0];

                //Update the entity id with the one of the found entity and make sure the id is properly compiled
                Object.assign(entityLocal, {
                    id: oldEntity.id,
                    uuid: entityId
                });
            }
        }

        //If the entity isn't found, abort update
        if (!oldEntity) {
            this.logEvent('err', '[ putOneOfflineFirst ] entity not found');
            throw 'NOT_FOUND';
        }

        // The entity is being synchronized to the API, so overwrite it locally with the dirty flag enabled.
        // The customizedPutOfflineFirst function will handle the resolution
        if (oldEntity.synchronizing) {
            return this.storageManagerService.saveOne(this.entityName, entityLocal, { dirty: true, synchronizing: true }) as Promise<OfflineFirstEntity<EntityT>>;
        }

        //Save the entity locally
        const result = await this.storageManagerService.saveOne(this.entityName, entityLocal, {
            dirty: true,
            synchronizing: !validateUuid(String(entityLocal.id))
        }) as OfflineFirstEntity<EntityT>;

        if (options?.awaitOnline) {
            return this.customizedPutOfflineFirst(result).catch(() => result);
        }

        //Return to the caller and then proceed to save the entity on the cloud
        return new Promise<OfflineFirstEntity<EntityT>>(async (resolve) => {
            resolve(structuredClone(result));

            await this.customizedPutOfflineFirst(result);
        });
    };

    /**
     * This function creates an entity in offline-first mode.
     * The returned promise resolves when the entity is saved locally,
     * the cloud update is notified using the offlineFirstCompletion$ observable,
     *
     * @param {EntityT} entity - The entity to create.
     * @param {Object} [options] - An optional options object.
     * @param {boolean} [options.awaitOnline] - Whether to wait for the entity to be created on the cloud. In case of online issues, the local entity will be returned.
     * @return {Promise} A promise that resolves as soon as the entity is saved locally (unless awaitOnline is set).
     */
    public async postOneOfflineFirst(entity: EntityT, options?: { awaitOnline?: boolean }) {
        if (typeof entity !== 'object') {
            this.logEvent('err', '[ postOneOfflineFirst ] entity is not an object');
            throw 'NOT_AN_OBJECT';
        }

        const entityLocal = structuredClone(entity);

        let assignedIds = {};

        if (validateUuid(String(entityLocal.id))) {
            //id is an UUID, so compile the uuid field with it
            assignedIds = { uuid: entityLocal.id };
        } else if (entityLocal.id == null) {
            if ('uuid' in entityLocal && entityLocal.uuid) {
                //id is missing and uuid is defined, so use the uuid as a temporary local id
                assignedIds = { id: entityLocal.uuid };
            } else if (!this.needsEntityId) {
                //Both id and uuid are missing, so generate a uuid and use it as a temporary local id
                const uuid = generateUuid();
                assignedIds = { uuid: uuid, id: uuid };
            }
        }

        //Assign the ids and lastupdate_at/lastupdate_by
        Object.assign(entityLocal, assignedIds, {
            lastupdate_at: new Date().toISOString(),
            lastupdate_by: this.userActiveSessionManagerService.getSession()?.id!
        });

        // Save the entity locally as dirty
        const resultSave = await this.storageManagerService.saveOne(this.entityName, entityLocal, {
            dirty: true,
            synchronizing: true
        }) as OfflineFirstEntity<EntityT>;

        //Run post-save entity hooks
        const entityHook = this.entityHooksService.getEntityHook(this.entityName);

        if (entityHook?.createOfflineFirstAfterStorage) {
            await entityHook.createOfflineFirstAfterStorage(resultSave.id!, resultSave);
        }

        if(options?.awaitOnline) {
            return this.customizedPostOfflineFirst(resultSave).catch(() => resultSave);
        }

        //Return to the caller and then proceed to save the entity on the cloud
        return new Promise<EntityT>((resolve) => {
            resolve(resultSave);

            this.customizedPostOfflineFirst(resultSave);
        });
    };

    /**
     * Deletes one entity in offline-first mode.
     *
     * @param {string | number} entityId - The ID of the entity to delete.
     * @return {Promise} A promise that resolves with the ID of the deleted entity.
     */
    public async deleteOneOfflineFirst(entityId: (string | number)) {
        if (!entityId) {
            this.logEvent('err', '[ deleteOneOfflineFirst ] id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        const entity = await this.storageManagerService.getOne(this.entityName, entityId) as OfflineFirstEntity<EntityT>;

        if (!entity) {
            this.logEvent('err', '[ deleteOneOfflineFirst ] entity not found');
            throw 'NOT_FOUND';
        }

        entity.deleted = 1;

        await this.storageManagerService.saveOne(this.entityName, entity, {
            dirty: true,
            synchronizing: true
        });

        //Return immediately, then proceed to delete the entity
        return new Promise(async (resolve) => {
            resolve(entityId);

            if (entity.synchronizing) {
                await this.storageManagerService.updateOne(this.entityName, entityId, { deleted: 1 });
                return;
            }

            try {
                let remoteId = entityId;

                if (entity.dirty) {
                    const result = await this.putOneOnline(entity, { skipStorage: true }) as EntityT;
                    remoteId = result.id!;
                }

                await this.restManagerService.deleteOne(this.entityName, remoteId);
                await this.storageManagerService.deleteOne(this.entityName, entityId);
            } catch (error) {
                this.storageManagerService.updateOne(this.entityName, entityId, { synchronizing: null });
            }
        });
    };

    /**
     * Saves a single entity to the local storage.
     *
     * @param {EntityT} entity - The entity to be saved.
     * @param {Object} options - Optional. Additional options for saving the entity.
     * @property {boolean} dirty - Indicates whether the entity should be saved as dirty.
     * @return {Promise<EntityT>} The saved entity.
     */
    public async saveOneOffline(entity: EntityT, options?: { dirty?: boolean, notification?: ExternalEntityUpdateInfo }) {
        if (typeof entity !== 'object') {
            this.logEvent('err', '[ saveOneOffline ] entity is not an object');
            throw 'NOT_AN_OBJECT';
        }

        if (!entity.id) {
            this.logEvent('err', '[ saveOneOffline ] entity.id is not defined');
            throw 'ID_NOT_DEFINED';
        }

        // Get entity hooks
        const entityHook = this.entityHooksService.getEntityHook(this.entityName);

        //Run pre-save entity hooks
        if (entityHook?.createOfflineBeforeStorage) {
            const res = await entityHook.createOfflineBeforeStorage(entity.id, entity);

            //If the hook returns an override value, use it and stop the save
            if(res?.overrideReturnValue) {
                return res.overrideReturnValue;
            }
        }

        //Save the entity
        const savedEntity = await this.storageManagerService.saveOne(this.entityName, entity, {
            dirty: !!options?.dirty
        }) as EntityT;

        //Run post-save entity hooks
        if (entityHook?.createOfflineAfterStorage) {
            await entityHook.createOfflineAfterStorage(savedEntity.id!, savedEntity);
        }

        // Send notification
        const notification = options?.notification;

        if (notification) {
            this.sendEntityUpdate(notification.action || 'CREATED', entity.id, structuredClone(savedEntity), notification.externalClient, notification.noOrigin);
        }

        return savedEntity;
    };

    /**
     * Synchronizes the entity collection.
     *
     * @return {SyncResult} The result of the synchronization.
     */
    public async syncEntityCollection(query?: any) {
        const syncResult: SyncResult = {
            updatedCollection: [],
            deletedCollection: [],
            failedCollection: []
        };

        if (this.connectionService.isOffline()) {
            this.logEvent('warn', `[ syncEntityCollection ] application currently offline`);
            throw syncResult;
        }

        if(!query) {
            query = {};
        }

        try {
            const results = await this.storageManagerService.getCollection(this.entityName, Object.assign({}, query, { dirty: true })) as OfflineFirstEntity<EntityT>[];

            for (const entity of results) {
                const [entityId, isDeleted] = [entity.id!, entity.deleted];

                //Skip entity if synchronizing flag is active
                if (entity.synchronizing) {
                    this.logEvent('info', `[ syncEntityCollection ] synchronization in progress for entity [ ${entity.id} ]`);

                    syncResult.failedCollection.push({
                        id: entityId,
                        reason: "IS_SYNCHRONIZING"
                    });

                    continue;
                }

                try {
                    //Set synchronizing flag
                    await this.storageManagerService.updateOne(this.entityName, entityId, { synchronizing: 1 });

                    try {
                        let result;

                        if (validateUuid(String(entityId)) && !this.hasOnlyUuid) {
                            result = await this.customizedPostOfflineFirst(entity);
                        } else {
                            result = await this.customizedPutOfflineFirst(entity);
                        }

                        //Delete the entity from the API if the deleted flag is set
                        if (isDeleted) {
                            const resId = result.id!;

                            try {
                                await this.deleteOneOnline(resId);

                                syncResult.deletedCollection.push(resId);
                            } catch (err) {
                                syncResult.failedCollection.push({
                                    id: resId,
                                    reason: "Item deletion failed"
                                });
                            }
                        } else {
                            syncResult.updatedCollection.push(entityId);
                        }
                    } catch (error: any) {
                        if (error?.status === 404) {
                            await this.deleteOneOffline(entityId);
                            syncResult.deletedCollection.push(entityId);

                            this.sendEntityUpdate('DELETED', entityId);
                        } else {
                            syncResult.failedCollection.push({
                                id: entityId,
                                reason: `Item ${validateUuid(String(entityId)) ? 'creation' : 'update'} failed`
                            });
                        }
                    }
                } catch (e) {
                    this.logEvent('info', `[ syncEntityCollection ] couldn't lock entity [${entity.id}]`);

                    syncResult.failedCollection.push({
                        id: entityId,
                        reason: "LOCK_FAILED"
                    });
                }
            }
        } catch (e) {
            throw syncResult;
        }

        if (syncResult.failedCollection.length) {
            throw syncResult;
        }

        return syncResult;
    };

    /**
     * Resets the sync status of the entity.
     *
     * @return {Promise<void>} A promise that resolves when the sync status is reset.
     */
    public async resetSyncStatus() {
        const results = await this.storageManagerService.getCollection(this.entityName, { synchronizing: 1 }) as OfflineFirstEntity<EntityT>[];

        for (const entity of results) {
            await this.storageManagerService.updateOne(this.entityName, entity.id!, { synchronizing: null });
        }
    };

    /**
     * Reloads the entity collection asynchronously.
     *
     * @param {Object} options - An optional object containing additional options.
     * @param {boolean} options.avoidSync - If true, avoids synchronizing the entity collection before reloading.
     * @param {EntityT[]} options.collection - An optional array of entities to use as the collection instead of fetching it.
     * @return {Promise<EntityT[]>} A promise that resolves with the new collection.
     */
    public async reloadEntityCollection(options?: { avoidSync?: boolean, collection?: EntityT[] }) {
        if (this.reloadPromise) {
            return this.reloadPromise;
        }

        if (this.connectionService.isOffline()) {
            this.logEvent('warn', `[ reloadEntityCollection ] application currently offline`);
            throw 'OFFLINE';
        }

        this.reloadPromise = new Promise(async (resolve, reject) => {
            try {
                const startupQuery = this.startupQueriesService.getStartupQuery(this.entityName);
                const paginationInfo = this.startupQueriesService.getPaginationInfo(this.entityName);

                //Send pending changes if needed
                if (!options?.avoidSync) {
                    await this.syncEntityCollection();
                }

                //Fetch new collection
                const newCollection = options?.collection || await this.fetchCollectionOnline(startupQuery, false, { paginatedFetch: !!(paginationInfo), perPage: paginationInfo }) as EntityT[];

                //Save new collection to storage
                await this.clearCollectionOffline();
                await this.storageManagerService.saveCollection(this.entityName, newCollection);

                resolve(newCollection);
            } catch (error) {
                reject(error);
            }
        });

        try {
            await this.reloadPromise;
        } catch (error) {
            throw 'OFFLINE';
        } finally {
            this.reloadPromise = null;
        }
    };

    /**
     * Cleans up the entity store from the entries that match the predicate.
     *
     * @param {function} predicate - The predicate function used to determine which entries remove.
     * @return {Promise<void>} A Promise that resolves when the cleanup is complete.
     */
    public async cleanupEntity(predicate: (entity: EntityT) => boolean) {
        const results = await this.storageManagerService.getCollection(this.entityName, { dirty: false, deleted: false }) as OfflineFirstEntity<EntityT>[];

        const idList = results.filter((entity) =>
            !entity.synchronizing && //Entity is not being synchronized
            !validateUuid(String(entity.id)) && //Entity id is not an uuid
            predicate(entity) //Entity is ok for cleanup
        ).map((entity) => entity.id!);

        return this.storageManagerService.deleteCollection(this.entityName, idList);
    };

    /**
     * Deletes all synced entities.
     *
     * @return {Promise<void>} Promise that resolves when the deletion is complete.
     */
    public async deleteAllSynced() {
        const results = await this.storageManagerService.getCollection(this.entityName, { dirty: false, deleted: false }) as OfflineFirstEntity<EntityT>[];
        const idList = [];

        for (const entity of results) {
            if (entity.synchronizing) {
                this.logEvent('info', `[ deleteAllSynced ] synchronization in progress for ${entity.id}`);
                continue;
            }

            idList.push(entity.id!);
        }

        return this.storageManagerService.deleteCollection(this.entityName, idList);
    };
}
