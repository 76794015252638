<form *ngIf="prepaidMovementForm" autocomplete="off" [formGroup]="prepaidMovementForm"
      (ngSubmit)="submit(prepaidMovementForm)"
      class="form">

    <div class="tw-flex tw-flex-wrap tw-max-w-[500px]">
        <div class="tw-w-1/2 tw-flex">
            <mat-form-field color="primary" class="items tw-flex-1 tw-m-3">
                <mat-label translate>PREPAID.ADD_SHOW_MOVEMENT.DATE_AND_TIME</mat-label>
                <input matInput type="datetime-local" formControlName="valid_from" step="60" required>
            </mat-form-field>
        </div>
        <div class="tw-w-1/2 tw-flex">
            <mat-form-field color="primary" class="items tw-flex-1 tw-m-3">
                <mat-label translate>PREPAID.ADD_SHOW_MOVEMENT.MOVEMENT_TYPE</mat-label>
                <input *ngIf="viewMode && movement_type && movement_type.value" matInput [value]="$any(types)[movement_type.value]" disabled>
                <mat-select *ngIf="addMode" formControlName="movement_type" required>
                    <mat-option *ngFor="let type of (types | keyvalue)" [value]="type.key">{{type.value}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="tw-w-1/2 tw-flex" *ngIf="addMode">
            <mat-form-field *ngIf="addMode" color="primary" class="items tw-flex-1 tw-m-3">
                <mat-label translate>PREPAID.ADD_SHOW_MOVEMENT.CREDIT_TYPE</mat-label>
                <mat-select formControlName="is_unclaimed" required>
                    <mat-option [value]="0">{{'PREPAID.ADD_SHOW_MOVEMENT.PREPAID' | translate}}</mat-option>
                    <mat-option [value]="1">{{'PREPAID.ADD_SHOW_MOVEMENT.FOOD_STAMPS' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="tw-w-1/2 tw-flex" *ngIf="viewMode">
            <mat-form-field color="primary" class="items tw-flex-1 tw-m-3">
                <mat-label translate>PREPAID.ADD_SHOW_MOVEMENT.SHOP</mat-label>
                <input matInput formControlName="shop_uuid" [value]="shop_uuid?.value">
            </mat-form-field>
        </div>
        <div class="tw-w-1/2 tw-flex">
            <mat-form-field color="primary" class="items tw-flex-1 tw-m-3"
                        *ngIf="movement_type?.value!=='cancel'">
                <mat-label
                    translate>{{movement_type?.value === 'setup' ? 'PREPAID.ADD_SHOW_MOVEMENT.PREPAID_CREDIT' :
                    'PREPAID.ADD_SHOW_MOVEMENT.PREPAID_AMOUNT' }}</mat-label>
                <input matInput type="number" formControlName="amount" required>
            </mat-form-field>
        </div>
        <div class="tw-w-1/2 tw-flex" *ngIf="viewMode && movement_type?.value !== 'cancel'">
            <mat-form-field color="primary" class="items tw-flex-1 tw-m-3"
                            *ngIf="viewMode && movement_type?.value !== 'cancel'">
                <mat-label
                    translate>{{movement_type?.value === 'setup' ? 'PREPAID.ADD_SHOW_MOVEMENT.MEAL_VOUCHER_CREDIT' :
                    'PREPAID.ADD_SHOW_MOVEMENT.MEAL_VOUCHER_AMOUNT' }}</mat-label>
                <input matInput type="number" formControlName="ticket_amount" [maxlength]="validators.notes" required>
            </mat-form-field>
        </div>
        <div class="tw-w-full tw-p-3">
            <mat-form-field color="primary" class="items" style="display: contents;">
                <mat-label translate>PREPAID.ADD_SHOW_MOVEMENT.NOTE</mat-label>
                <input matInput formControlName="notes" [maxlength]="validators.notes">
                <mat-error *ngIf="notes?.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
                <mat-error *ngIf="notes?.hasError('maxLength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                    (max. {{validators.notes}})
                </mat-error>
            </mat-form-field>
        </div>
    </div>

</form>
