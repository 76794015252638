import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('tables').directive('sclTable', ["$state", "$timeout", "$translate", "connection", "durationFilter", "confirmDialog", "checkManager", "entityManager", "alertDialog", function($state, $timeout, $translate, connection, durationFilter, confirmDialog, checkManager, entityManager, alertDialog) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            table: '=',
            room: '=',
            orders: '=',
            mode: '=',
            savePositionTable: '&',
            selectTable: '&'
        },
        template: require('./table.html'),
        link: function(scope, element, attr) {
            //Init table position and orders
            var originalX = scope.table.xpos;
            var originalY = scope.table.ypos;

            var originOffsetY = 30;
            var originOffsetX = 5;

            var xpos = originalX + originOffsetX;
            var ypos = originalY + originOffsetY;

            var roomX = scope.room.width;
            var roomY = scope.room.height;

            var startX = xpos;
            var startY = ypos;

            var file_name;

            var tableOrders = scope.orders[scope.table.id] || [];
            var activeOrder = _.head(tableOrders);

            _.assign(scope, {
                canEdit: false,
                isOnline: connection.isOnline(),
                showTableTimer: (scope.table.shape !== "chair1x1"),
                showTableCovers: (scope.table.shape !== "chair1x1"),
                showTableTotal: (scope.table.shape !== "chair1x1"),
                showTableState: (scope.table.shape !== "chair1x1")
            });

            scope.table.edit_state = 'unselected';

            var updateTableImage = function() {
                element.css({
                    backgroundImage: 'url(' + 'assets/images/tables/' + file_name + '.svg' + ')'
                });
            };

            var performTableOperation = function(target, options) {
                if(connection.isOnline()) {
                    $timeout(function() {
                        $state.go(target, options);
                    });
                } else {
                    confirmDialog.show($translate.instant('TABLES.TABLE.OFFLINE_CONFIRM')).then(function(answer) {
                        if(answer) {
                            $timeout(function() {
                                $state.go(target, options);
                            });
                        }
                    });
                }
            };

            var goToTableView = function() {
                performTableOperation('app.tables.table-view', {
                    table_id: scope.table.id,
                    room_id: scope.table.room_id
                });
            };

            scope.tableTap = function() {
                if (scope.canEdit) {
                    scope.table.edit_state = scope.table.edit_state === 'unselected' ? 'selected' : 'unselected';
                    scope.selectTable();
                    file_name = scope.mode + '_table_' + scope.table.edit_state + '_' + scope.table.shape;

                    updateTableImage();
                } else if (scope.mode === 'view') {
                    switch (scope.table.state) {
                        case 'available':
                        case 'busy':
                            switch(scope.table.order_type) {
                                case 'single':
                                    if (tableOrders.length <= 1) {
                                        performTableOperation('app.orders.content', {
                                            type: 'normal',
                                            orderId: _.get(activeOrder, 'id'),
                                            table: scope.table,
                                            room: scope.room
                                        });
                                    } else {
                                        goToTableView();
                                    }
                                    break;
                                case 'multiple':
                                    goToTableView();
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 'checkout':
                            if (scope.table.order_type === 'single') {
                                if (checkManager.isModuleEnabled('cashregister')) {
                                    entityManager.sales.fetchCollectionOffline({ order_id: activeOrder.id, status: 'open' }).then(function(results) {
                                        if (results.length) {
                                            performTableOperation('app.cashregister.content.showcase', {
                                                action: 'open-sale-id',
                                                id: _.head(results).id
                                            });
                                        } else {
                                            confirmDialog.show($translate.instant('TABLES.TABLE.FREE_CONFIRM')).then(function(answer) {
                                                if (answer) {
                                                    activeOrder.status = "closed";
                                                    entityManager.orders.putOneOfflineFirst(activeOrder).then(function() {
                                                        $state.reload('app.tables');
                                                    });
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    alertDialog.show($translate.instant('TABLES.TABLE.CASHREGISTER_NOT_ENABLED'));
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
            };

            scope.tablePanStart = function() {
                if (scope.canEdit) {
                    startX = (event.pageX || event.changedTouches[0].pageX) - xpos;
                    startY = (event.pageY || event.changedTouches[0].pageY) - ypos;
                }
            };

            scope.tablePanEnd = function() {
                if (scope.canEdit) {
                    scope.table.xpos = _.toInteger(xpos - originOffsetX);
                    scope.table.ypos = _.toInteger(ypos - originOffsetY);
                    scope.savePositionTable().then(function(result) {
                        originalX = scope.table.xpos;
                        originalY = scope.table.ypos;
                    }, function(error) {
                        scope.table.xpos = originalX;
                        scope.table.ypos = originalY;
                        xpos = scope.table.xpos + originOffsetX;
                        ypos = scope.table.ypos + originOffsetY;
                        element.css({
                            top: ypos + 'px',
                            left: xpos + 'px'
                        });
                    });
                }
            };

            scope.tablePanMove = function() {
                if (scope.canEdit) {
                    var newX = (event.pageX || event.changedTouches[0].pageX) - startX;
                    var newY = (event.pageY || event.changedTouches[0].pageY) - startY;

                    if (((newY + scope.table.height) < roomY) &&
                        ((newX + scope.table.width) < roomX) &&
                        (newY > originOffsetY) && (newX > originOffsetX)) {
                        ypos = newY;
                        xpos = newX;

                        element.css({
                            top: ypos + 'px',
                            left: xpos + 'px'
                        });
                    }
                }
            };

            var updateTableDuration = function() {
                if(!scope.$$destroyed) {
                    if (_.get(activeOrder, "open_at") !== undefined) {
                        scope.duration = durationFilter(activeOrder.open_at);
                    }
                    if(_.get(activeOrder, 'lastupdate_at') && scope.hangingAlarm) {
                        scope.tableHanging = ((moment() - moment(activeOrder.lastupdate_at)) / 1000 > (scope.hangingAlarm * 60));
                    }

                    $timeout(updateTableDuration, 10000);
                }
            };

            var toggleTableExits = function() {
                if(!scope.$$destroyed) {
                    scope.showTableTotal = scope.showTableExits;
                    scope.showTableExits = !scope.showTableTotal;

                    $timeout(toggleTableExits, 2000);
                }
            };

            //Determine table status
            if (scope.table.order_type === 'single') {
                switch (_.get(activeOrder, 'status')) {
                    case 'open':
                        scope.table.state = 'busy';
                        scope.covers = activeOrder.covers;
                    break;
                    case 'checkout':
                        scope.table.state = 'checkout';
                        scope.covers = activeOrder.covers;
                    break;
                    case 'closed':
                    /* falls through */
                    default:
                        scope.table.state = 'available';
                        scope.covers = scope.table.covers;
                    break;
                }

                if (_.includes(['busy', 'checkout'], scope.table.state)) {
                    if(scope.table.state === 'busy') {
                        var filledExits = _(activeOrder.order_items).map('exit').uniq().filter(function(exit) { return !_.isNil(exit); }).value();

                        if(!_.isEmpty(filledExits)) {
                            var sentExits = _.get(activeOrder, ['previous_order', 'sentExits'], 0);
                            var evadedExits = _.filter(filledExits, function(exit) { return sentExits & Math.pow(2, exit); });

                            _.assign(scope, {
                                evadedExits: evadedExits.length,
                                totalExits: filledExits.length
                            });

                            toggleTableExits();
                        }

                        scope.hangingAlarm = (_.toInteger(checkManager.getPreference('tables.hanging_alarm_after'))) || 60;
                    }

                    scope.amount = activeOrder.amount;
                    updateTableDuration();
                }
            } else if (scope.table.order_type === 'multiple') {
                var hasOpenOrder = _.find(tableOrders, function(order) {
                    return _.includes(['open', 'checkout'], order.status);
                });

                if(hasOpenOrder) {
                    scope.table.state = 'busy';
                    scope.covers = _.sumBy(tableOrders, 'covers');
                } else {
                    scope.table.state = 'available';
                    scope.covers = scope.table.covers;
                }
            }

            if (scope.mode === 'view') {
                file_name = scope.mode + '_table_' + scope.table.state + '_' + scope.table.shape;
            } else if (scope.mode === 'edit') {
                file_name = scope.mode + '_table_' + scope.table.edit_state + '_' + scope.table.shape;

                if (scope.table.state !== 'available') {
                    file_name += "_blocked";
                } else {
                    scope.canEdit = true;
                }
            }

            element.css({
                position: 'absolute',
                cursor: 'pointer',
                backgroundRepeat: 'no-repeat',
                top: ypos + 'px',
                left: xpos + 'px',
            });

            updateTableImage();
        }
    };
}]);