import {Component, inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TilbyMagicFormComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { OrderPrinterSelectFormDialogData } from './order-printer-select-dialog.service';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-order-printer-select-dialog',
  standalone: true,
  imports: [CommonModule, TilbyMagicFormComponent],
  templateUrl: './order-printer-select-dialog.component.html',
  styleUrls: ['./order-printer-select-dialog.component.scss']
})
export class OrderPrinterSelectDialogComponent {
  protected readonly data:OrderPrinterSelectFormDialogData = inject(MAT_DIALOG_DATA);

}
