import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').component('keypadCashregister', {
    bindings: {
        field: '=',
        onKeypadChange: '&'
    },
    template: require('./keypad-cashregister.html'),
    controller: ["$rootScope", function($rootScope) {
        this.updateField = function(elabField) {
            var whole_part = _.toInteger(elabField.slice(0, elabField.length - $rootScope.currentCurrency.decimal_digits));
            if($rootScope.currentCurrency.decimal_digits) {
                var decimal_part = elabField.slice(elabField.length - $rootScope.currentCurrency.decimal_digits, elabField.length);
                this.field = whole_part + _.get($rootScope, ['currentCurrency', 'decimal_separator'], '.') + decimal_part;
            } else {
                this.field = _.toString(whole_part);
            }
        };

        this.pressKey = function(key) {
            var elab_field = _.replace(this.field, _.get($rootScope, ['currentCurrency', 'decimal_separator'], '.'), '');

            if (key === 'back') {
                if (_.toNumber(elab_field) !== 0) {
                    elab_field = elab_field.slice(0, elab_field.length - 1);
                }
            } else {
                if((elab_field.length + key.length) <= 9) {
                    elab_field += key;
                }
            }

            this.updateField(elab_field);            
        };

        this.clear = function() {
            this.updateField("000");
        };
    }]
});
