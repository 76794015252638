import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('settings').factory('logoEditor', ["$mdDialog", function($mdDialog) {
    logoEditorController.$inject = ["$scope", "$mdDialog", "$q", "$rootScope", "$http", "logo", "restManager", "FiscalPrinters", "toast", "util", "ImagesManager"];
    function logoEditorController($scope, $mdDialog, $q, $rootScope, $http, logo, restManager, FiscalPrinters, toast, util, ImagesManager) {
        $scope.isLoading = false;
        $scope.errorMessage = null;
        $scope.isValid = false;

        $scope.widths = ["150","200","250","300","350","400","450"];
        $scope.brightnesses = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16"];
        
        var getEmptyImage = function() {
            return {
                data: null,
                size:{
                    w: 0,
                    h: 0
                },
                result: null,
                edited_logo: null,
                final_logo: null,
                final_brightness: "1",
                final_width: "400"
            };
        };

        $scope.image = getEmptyImage();

        $scope.resetEdits = function() {
            _.assign($scope, {
                currentLogo: null,
                errorMessage: null,
                image: getEmptyImage(),
                isLoading: false,
                isValid: false
            });
        };

        if(logo !== null) {
            $http.get(logo)
            .then(function() {
                $scope.currentLogo = logo;
            })
            .catch(function(){
                $scope.currentLogo = null;
            });
        }

        var uploadLogo = function(imageData, dataUri) {
            $scope.errorMessage = null;
            $scope.isLoading = true;
            $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled:  true });

            restManager.post("tools/epson_logo", { data_uri: dataUri }, null,  { 'debug': true, 'logo-width': imageData.final_width, brightness: imageData.final_brightness }).then(function(result) {
                if(!_.isNil(result)) {
                    if(result.final_logo && result.edited_logo) {
                        $scope.image = {
                            data: null,
                            size: { w: 0, h: 0 },
                            result: imageData.result,
                            edited_logo: util.base64ToDataURL('image/png', result.edited_logo),
                            final_logo: util.base64ToDataURL('image/bin', result.final_logo),
                            final_brightness: imageData.final_brightness,
                            final_width: imageData.final_width,
                            final_original: imageData.result
                        };
                        $scope.isValid = true;
                        $scope.isLoading = false;
                        $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled: false });
                    }
                }
            }).catch(function(error){
                $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled: false });
                if(error.data && error.data.error){
                    if(error.data.error.code){
                        switch(error.data.error.code){
                            case 607:
                                $scope.errorMessage = "Immagine troppo grande, riduci le dimensioni cancellando gli spazi bianchi, oppure modifica i parametri di larghezza e densita";
                                break;
                            default:
                                $scope.errorMessage = error.data.error.message;
                                break;                            
                        }
                        $scope.isLoading = false;
                    }
                }
            });
        };

        $scope.testLogo = function() {
            if($scope.ip_printer !== null && $scope.ip_printer !== "" && $scope.image.final_logo !== null) {
                $scope.isLoading = true;
                $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled:  true });
                
                var data = util.dataURLToBase64($scope.image.final_logo);
                
                return $q.all([
                    FiscalPrinters.printLogo(data, $scope.ip_printer, $scope.type_printer)
                ])
                .then(function(){
                    $scope.isLoading = false;
                    $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled: false });
                })
                .catch(function(error) {
                    $scope.errorMessage = "Stampa di test non riuscita";
                    $scope.isLoading = false;
                    $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled: false });
                });
            }
        };

        $scope.createLogo = function() {
            var imageData = _.cloneDeep($scope.image);
            uploadLogo(imageData, imageData.result);
        };

        $scope.editLogo = function() {
            var imageData = _.cloneDeep($scope.image);
            uploadLogo(imageData, imageData.edited_logo);
        };

        $scope.deleteLogo = function(){
            var images = [
                { deleted: true, image_url: "logo_small.bin" },
                { deleted: true, image_url: "logo_small.png" },
                { deleted: true, image_url: "logo_original.png" }
            ];
    
            ImagesManager.saveAllImages(images)
            .then(function(result){
                $scope.image = getEmptyImage();
                $scope.errorMessage = null;
                $scope.isLoading = false;
                $scope.currentLogo = null;
            });
        };

        $scope.confirm = function() {
            $scope.isValid = false;
            var imageData = _.cloneDeep($scope.image);
            console.log(imageData.final_original);
            $scope.errorMessage = null;
            $scope.isLoading = true;
            $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled:  true });
            var images = [
                { uploaded: false, name: "logo_small.bin" , data: util.dataURLToBlob(imageData.final_logo) },
                { uploaded: false, name: "logo_small.png" , data: util.dataURLToBlob(imageData.edited_logo) },
                { uploaded: false, name: "logo_original.png" , data: util.dataURLToBlob(imageData.final_original) }
            ];

            ImagesManager.saveAllImages(images)
            .then(function(result){
                toast.show({ message: "Logo salvato correttamente" });
                $mdDialog.hide(true);
            })
            .catch(function(error){
                $scope.isValid = true;
                $scope.errorMessage = "Errore durante il salvataggio del logo. Verifica la connessione ad internet e riprova";
                $scope.isLoading = false;
                $rootScope.$broadcast("loader:changeStatus", "logoEditorLoader", { enabled:  false });
            });
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    var logoEditor = {
        show: function(logo, params) {
            return $mdDialog.show({
                controller: logoEditorController,
                template: require('./logo-editor.html'),
                locals: {
                    logo: logo,
                    params: params
                }
            })
            .then(function(answer) {
                return answer;
            });
        }
    };

    return logoEditor;
}]);
