import {NgxSkeletonLoaderComponent, NgxSkeletonLoaderConfigTheme} from "ngx-skeleton-loader";
import {Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef} from "@angular/core";
import {NgxSkeletonLoaderConfig} from "ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types";

@Directive({
    selector: '[skeleton]',
    standalone: true
})
export class SkeletonDirective {
    @Input('skeleton') isLoading = false;
    @Input('skeletonAppearance') appearance: NgxSkeletonLoaderConfig['appearance'] = 'line';
    @Input('skeletonTheme') theme: NgxSkeletonLoaderConfigTheme = {
        width: '100%',
        height: '100%',
    };

    constructor(private tpl: TemplateRef<any>,
                private vcr: ViewContainerRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isLoading) {
            this.vcr.clear();

            if (changes.isLoading.currentValue) {
                const ref = this.vcr.createComponent(NgxSkeletonLoaderComponent);
                Object.assign(ref.instance, <NgxSkeletonLoaderComponent>{
                    appearance: this.appearance,
                    theme: this.theme
                })
            } else {
                this.vcr.createEmbeddedView(this.tpl);
            }
        }
    }
}
