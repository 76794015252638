import { Injectable, Injector, inject, runInInjectionContext } from "@angular/core";
import { EntityName } from "./entity.model";
import { util } from "app/ajs-upgraded-providers";
import { ConfigurationManagerService, EntityManagerService } from "src/app/core";

@Injectable({
    providedIn: 'root'
})
export class StartupQueriesService {
    private injector = inject(Injector);

    /**
     * Retrieves the startup query for the provided entity name.
     *
     * @param {EntityName} entityName - The name of the entity.
     * @return {object} The startup query object.
     */
    public getStartupQuery(entityName: EntityName | keyof EntityManagerService) {
        switch (entityName) {
            case 'items':
                return { on_sale: true };
            case 'orders':
                return runInInjectionContext(this.injector, () => {
                    const configurationManager = this.injector.get(ConfigurationManagerService);
                    const utilService = this.injector.get(util);

                    const dayStartTime = utilService.getDayStartTime().toISOString();

                    const query = configurationManager.getPreference('orders.load_old_orders') ?
                        { x_query: `OR(status=open,AND(status=checkout,open_at_since=${dayStartTime}))` } :
                        { x_query: `AND(OR(open_at_since=${dayStartTime},deliver_at_since=${dayStartTime}),OR(status=open,status=checkout))` };

                    return query;
                });
            case 'sales':
                return { status: 'open' };
            case 'sale_transactions': case 'saleTransactions':
                return { 'sale.status': 'open' };
            case 'stock':
                return { item_on_sale: true };
            default:
                return {};
        }
    }

    /**
     * Retrieves the default pagination information.
     *
     * @param {EntityName} entityName - The name of the entity.
     * @return {object} The pagination information for the entity.
     */
    public getPaginationInfo(entityName: EntityName | keyof EntityManagerService) {
        switch (entityName) {
            case 'items':
                return 10000;
            case 'customers':
                return 5000;
            case 'stock':
                return 10000;
            default:
                break;
        }
    }
}