import {Inject, Injectable} from '@angular/core';
import {$state} from '../../app/ajs-upgraded-providers';
import {BehaviorSubject} from 'rxjs';
import { Transition } from 'angular-ui-router';
import { DevLogger } from './shared/dev-logger';

@Injectable({
    providedIn: 'root'
})
export class DualService {
    currentRoute$ = new BehaviorSubject<string>('');
    previousRoute$ = new BehaviorSubject<string>('');
    nextRoute$ = new BehaviorSubject<string>('');

    constructor(@Inject($state) private state: any) {

    }

    public init() {
        this.state.router.transitionService.onSuccess({},
            (transition: Transition&any) => {
            DevLogger.debug('CURRENT',transition._targetState._identifier)
            DevLogger.debug('BEFORE',transition.from().name)
                this.currentRoute$.next(transition._targetState._identifier||'');
                this.previousRoute$.next(transition.from().name||'');
            }
        );

        this.state.router.transitionService.onExit({},
            (transition: Transition&any) => {
            DevLogger.debug('NEXT',transition._targetState._identifier)
                this.nextRoute$.next(transition._targetState._identifier);
                document.getElementsByTagName('body')[0].classList.remove('grid-columns-movable')
            }
        );
    }
}
