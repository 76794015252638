<mat-toolbar color="primary" class=" sm:tw-p-4 tw-justify-between">
    <div class="tw-flex tw-items-center">
        <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        <span class="title tw-flex tw-whitespace-pre-wrap tw-ml-2" translate>DASHBOARD.DIALOG.TITLE</span>
    </div>
    <div>
        <button mat-icon-button *ngIf="canShare()" (click)="shareDetails()"><mat-icon>share</mat-icon></button>
        <button mat-icon-button (click)="printDetails()"><mat-icon>print</mat-icon></button>
        <button mat-icon-button (click)="emailDetails()"><mat-icon>email</mat-icon></button>
        <button mat-icon-button *ngIf="canDownloadFiles()" (click)="downloadDetails()"><mat-icon>download</mat-icon></button>
    </div>
</mat-toolbar>
<mat-dialog-content class=" tw-max-h-full tw-p-0 sm:tw-max-h-none">
    <app-fiscal-grid [rowData]="data.alignedPrinters"></app-fiscal-grid>
</mat-dialog-content>
