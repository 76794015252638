import * as angular from 'angular';
import * as _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';
import { AngularJSObservableHelper } from 'app/libs/angularjs-observable-helper';

angular.module('cashregister', ['application']);

angular.module('cashregister').config(["$stateProvider", "$mdThemingProvider", "$translatePartialLoaderProvider", function($stateProvider, $mdThemingProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('cashregister');
    var parseItemsCollection = function(items) {
        var result = _.chain(items)
            .groupBy("category_id")
            .value();

        result.favorite = _.filter(items, {favorite: true});

        if(!result.null) {
            result.null = [];
        }

        return result;
    };

    $stateProvider.state('app.cashregister', {
        url: '/old_cashregister',
        redirectTo: 'app.cashregister.content.showcase',
        resolve: {
            items: ["entityManager", function(entityManager) {
                return entityManager.items.fetchCollectionOffline({on_sale: true}).then(function(items) {
                    return parseItemsCollection(items);
                });
            }],
            stockDictionary: ["entityManager", async (entityManager) => {
                const result = new SclDictionary();
                const fields = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];
                const stockCollection = await entityManager.stock.fetchCollectionOffline();

                for(let stock of stockCollection) {
                    if (stock.combination_id) {
                        result.set(`combination_id_${stock.combination_id}`, _.pick(stock, fields));
                    } else if (stock.item_id) {
                        result.set(`item_id_${stock.item_id}`, _.pick(stock, fields));
                    }
                }

                return result;
            }],
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOffline();
            }],
            departments: ["entityManager", function(entityManager) {
                return entityManager.departments.fetchCollectionOffline();
            }],
            deliveryChannels: ["entityManager", function(entityManager) {
                return entityManager.channels.fetchCollectionOffline().then(function(channels) {
                    return _.reject(channels, { id: 'pos' });
                });
            }]
        },
        views: {
            "appContent@app": {
                template: require('./partial/cashregister/cashregister.html'),
                controller: ["$scope", "screenOrientation", "ActiveSale", "checkManager", "entityManager", "items", "departments", "stockDictionary", function($scope, screenOrientation, ActiveSale, checkManager, entityManager, items, departments, stockDictionary) {
                    $scope.viewOptions = {
                        portrait: _.startsWith(screenOrientation.getOrientation(), 'portrait'),
                        hideSale: false
                    };

                    $scope.getContentClass = function() {
                        const classes = [];

                        if(ActiveSale.paymentInProgress || ActiveSale.printDocumentInProgress) {
                            classes.push('lock-actions');
                        }

                        if($scope.viewOptions.portrait) {
                            classes.push($scope.viewOptions.hideSale ? 'flex' : 'flex-50');
                        } else {
                            classes.push('flex-66');
                        }

                        return classes;
                    };

                    $scope.getActiveSaleClass = function() {
                        const classes = [];

                        if(ActiveSale.paymentInProgress || ActiveSale.printDocumentInProgress) {
                            classes.push('lock-actions');
                        }

                        if($scope.viewOptions.portrait) {
                            if(!$scope.viewOptions.hideSale) {
                                classes.push('flex-50');
                            }
                        } else {
                            classes.push('flex-33');
                        }

                        return classes;
                    };

                    AngularJSObservableHelper.attachObservableToScope($scope, screenOrientation.getOrientationObservable(), (orientation) => {
                        $scope.viewOptions.portrait = _.startsWith(orientation, 'portrait');
                    });

                    $scope.$on("storage-updated:departments", async (event, data) => {
                        const newDepartments = await entityManager.departments.fetchCollectionOffline();
                        angular.copy(newDepartments, departments);

                        $scope.$broadcast("cashregister-showcase:update-departments");
                    });

                    $scope.$on("storage-updated:items", function(event, data) {
                        if (!data.id) {
                            if(data.action !== 'DELETED') { //DELETED happens when reloading the items, so wait for the UPDATED event instead
                                entityManager.items.fetchCollectionOffline({ on_sale: true }).then(function(result) {
                                    _.forIn(items, function(val, key) { delete items[key]; });
                                    _.assign(items, parseItemsCollection(result));

                                    $scope.$broadcast("cashregister-showcase:update-items");
                                });
                            }
                        } else {
                            switch (data.action) {
                                case 'UPDATED': case 'CREATED':
                                    entityManager.items.fetchOneOffline(data.id).then(function(src) {
                                        if (src) {
                                            if (!src.favorite) {
                                                _.remove(items.favorite, {
                                                    id: src.id
                                                });
                                            }

                                            var catToScan = _.clone(items);
                                            delete catToScan.favorite;

                                            var dst;
                                            _.find(catToScan, function(itemsInCategory, categoryId) {
                                                var srcCatId = src.category_id ? src.category_id.toString() : "null";

                                                if (srcCatId !== categoryId) {
                                                    _.remove(itemsInCategory, { id: src.id });
                                                    return false;
                                                }

                                                var foundInCategory = _.find(itemsInCategory, { id: data.id });

                                                if (foundInCategory) {
                                                    dst = foundInCategory;
                                                } else {
                                                    if (srcCatId === categoryId) {
                                                        itemsInCategory.push(src);
                                                    }
                                                }
                                                return foundInCategory;
                                            });

                                            if (dst) {
                                                if (src !== dst) {
                                                    angular.copy(src, dst);
                                                }
                                            }
                                            if (src.favorite) {
                                                var isAlreadyFavorite = _.find(items.favorite, {
                                                    id: src.id
                                                });

                                                if (!isAlreadyFavorite) {
                                                    items.favorite.push(dst || src);
                                                }
                                            }
                                        }
                                        $scope.$broadcast("cashregister-showcase:update-items");
                                    });
                                    break;
                                case 'DELETED':
                                    _.each(items, function(itemsInCategory) {
                                        _.remove(itemsInCategory, {
                                            id: data.id
                                        });
                                    });
                                    $scope.$broadcast("cashregister-showcase:update-items");
                                    break;

                                default:
                                    $scope.$broadcast("cashregister-showcase:update-items");
                                    break;
                            }
                        }
                    });

                    $scope.$on("stock-updated", function(event, stockMessage) {
                        if (!!checkManager.getPreference('cashregister.check_stock')) {
                            var dictIndex;
                            var fields = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];

                            if (stockMessage.combination_id) {
                                dictIndex = 'combination_id_' + stockMessage.combination_id;
                            } else if (stockMessage.item_id) {
                                dictIndex = 'item_id_' + stockMessage.item_id;
                            } else {
                                return;
                            }

                            var prevStock = stockDictionary.get(dictIndex);
                            stockDictionary.set(dictIndex, _.pick(stockMessage, fields));

                            if (_.get(prevStock, 'available') !== stockMessage.available) {
                                $scope.$broadcast("cashregister-showcase:notify-stock", stockMessage);
                            }
                        }
                        $scope.$broadcast('activeSale:updateSaleStock');
                    });
                }]
            }
        },
        params: {
            action: { type: 'string', value: null, dynamic: true },
            advancedpayments: { type: 'bool', value: false, dynamic: true },
            autohide: { type: 'bool', value: false,  dynamic: true },
            from: null,
            id: null,
            saleType: null,
            room: null,
            table: null,
            booking: null
        }
    });

    $stateProvider.state('app.cashregister.sale', {
        url: '/sale/:id?advancedpayments&autohide',
        params: {
            id: { type: 'int', value: null },
            advancedpayments: { type: 'bool', value: false,  dynamic: true },
            autohide: { type: 'bool', value: false, dynamic: true }
        },
        redirectTo: function(transition) {
            return transition.router.stateService.target('app.cashregister.content.showcase', _.assign({}, _.pick(transition.params(), ['id', 'autohide', 'advancedpayments']), { action: 'open-sale-id' }), { reload: 'app.cashregister' });
        }
    });

    $stateProvider.state('app.cashregister.daily-closing', {
        url: '/dailyclosing?autohide',
        params: {
            id: { type: 'int', value: null },
            autohide: { type: 'bool', value: false }
        },
        redirectTo: function(transition) {
            return transition.router.stateService.target('app.cashregister.content.showcase', _.assign({}, _.pick(transition.params(), ['autohide']), { action: 'daily-closing' }), { reload: 'app.cashregister' });
        }
    });

    $stateProvider.state('app.cashregister.content', {
        abstract: true,
        views: {
            "active-sale": {
                controller: 'ActiveSaleCtrl',
                template: require('./partial/active-sale/active-sale.html'),
            }
        },

    });

    $stateProvider.state('app.cashregister.content.showcase', {
        url: '/showcase',
        views: {
            "content@app.cashregister": {
                controller: 'CashregisterShowcaseCtrl',
                template: require('./partial/cashregister-showcase/cashregister-showcase.html')
            }
        },
    });

    $stateProvider.state('app.cashregister.content.payments', {
        url: '/payments',
        onEnter: ["$state", "ActiveSale", function($state, ActiveSale) {
            if (!ActiveSale.isActiveSale()) {
                $state.go('app.cashregister.content.showcase');
            }
        }],
        views: {
            "content@app.cashregister": {
                controller: 'PaymentsCtrl',
                template: require('./partial/payments/payments.html')
            }
        },
        resolve: {
            paymentMethods: ["entityManager", function(entityManager) {
                return entityManager.paymentMethods.fetchCollectionOffline();
            }],
            ticketsCollection: ["entityManager", function(entityManager) {
                return entityManager.tickets.fetchCollectionOffline();
            }]
        }
    });

    /* Add New States Above */
    $mdThemingProvider.theme('satispayTheme')
        .primaryPalette('red', {
            'default': '500'
        })
        .accentPalette('red', {
            'default': '50'
        });

    $mdThemingProvider.theme('eatsreadyTheme')
        .primaryPalette('red', {
            'default': 'A200'
        })
        .accentPalette('red', {
            'default': '50'
        });

    $mdThemingProvider.theme('spiaggeItTheme')
        .primaryPalette('amber', {
            'default': '500'
        })
        .accentPalette('amber', {
            'default': '50'
        });
}]);
