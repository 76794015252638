import {Component} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import {IHeaderParams} from 'ag-grid-community';
import {MatCheckboxChange} from '@angular/material/checkbox';

export interface ICustomHeaderParams {
}

@Component({
    selector: 'app-custom-grid-checkbox-all',
    templateUrl: './custom-grid-checkbox-all.component.html',
    styleUrls: ['./custom-grid-checkbox-all.component.scss'],
    host: {style: 'position: absolute;left: 0.3rem;'}
})
export class CustomGridCheckboxAllComponent implements IHeaderAngularComp {

    constructor() { }

    agInit(params: IHeaderParams & ICustomHeaderParams): void {
    }

    refresh(params: IHeaderParams): boolean {
        return false;
    }


    checkboxChanged($event: MatCheckboxChange) {
        $event.checked
    }
}
