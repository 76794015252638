import {CustomForm } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Suppliers } from "tilby-models";
import { ProvValidator } from "@tilby/tilby-ui-lib/utilities";

export interface SuppliersAddressValidator extends ProvValidator {
    streetMaxLength: number;
    numberMaxLength: number;
    zipMaxLength: number;
    cityMaxLength: number;
    provMinLength: number;
    provMaxLength: number;
    countryMaxLength: number;
}
export interface SuppliersValidators {
    nameMaxLength: number;
    phoneMaxLength: number;
    faxMaxLength: number;
    emailMaxLength: number;
    websiteMaxLength: number;
    address: SuppliersAddressValidator
}

interface SuppliersFieldsFe {
    conto_vendita?: boolean
    isNew?: boolean;
}
export type SuppliersFields = Suppliers & SuppliersFieldsFe;

export type SuppliersFieldsNullable = { [prop in string & keyof SuppliersFields]: SuppliersFields[prop] | null };
export type SuppliersForm = Partial<CustomForm<SuppliersFieldsNullable>>;

export class SupplierDefault implements SuppliersFields {
    address_city = '';
    address_number = '';
    address_prov = '';
    address_street = '';
    address_zip = '';
    conto_vendita = false;
    country = '';
    created_at = '';
    email1 = '';
    email2 = '';
    email_pec = '';
    fax = '';
    id?: number;
    name = '';
    notes = '';
    phone1 = '';
    phone2 = '';
    updated_at = '';
    vat_code = '';
    website = '';
    isNew = true;
}
