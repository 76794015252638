import { Subject } from "rxjs";
import { TilbyTcpSocket, TilbyTcpSocketConnectOptions, TilbyTcpSocketUtils } from "./tilby-tcp-socket";

interface ChromeTcpSocketObject {
	socketId: number,
}

interface ChromeTcpSocketInfo {
	socketId: number,
	persistent: boolean,
	name: string,
	paused: boolean,
	connected: boolean,
	localAddress: string,
	localPort: number,
	peerAddress: string,
	peerPort: number
}

interface ChromeTcpReceiveInfo {
	data: ArrayBuffer,
	socketId: number
}

interface ChromeTcpReceiveErrorInfo {
	resultCode: number,
	socketId: number
}

export class ChromeTcpSocket implements TilbyTcpSocket {
	private socketInfo: ChromeTcpSocketObject | null | undefined = undefined; //undefined: socket not yet used. null: socket ended and not usable anymore

	private onDataSubject = new Subject<Uint8Array>();
	private onErrorSubject = new Subject<number>();

	public onData = this.onDataSubject.asObservable();
	public onError = this.onErrorSubject.asObservable();

	private static logEvent(...message: any[]) {
		console.debug('[ ChromeTcpSocket ]', ...message);
	}

	private onReceiveCallback = (info: ChromeTcpReceiveInfo) => {
		if (this.socketInfo?.socketId === info.socketId) {
			const byteArray = new Uint8Array(info.data);

			ChromeTcpSocket.logEvent(`Received packet from socket ${info.socketId}:`, TilbyTcpSocketUtils.ab2Hex(byteArray));
			this.onDataSubject.next(byteArray);
		}
	}

	private onErrorCallback = (info: ChromeTcpReceiveErrorInfo) => {
		if (this.socketInfo?.socketId === info.socketId) {
			ChromeTcpSocket.logEvent(`Received error from socket ${info.socketId}:`, info.resultCode);
			this.onErrorSubject.next(info.resultCode);
		}
	}

	private getChromeSocketInfo(): Promise<ChromeTcpSocketInfo> {
		return new Promise((resolve) => {
			window.chrome.sockets.tcp.getInfo(this.socketInfo?.socketId, (socketInfo: ChromeTcpSocketInfo) => {
				resolve(socketInfo);
			});
		});
	}

	public connect(ipAddress: string, port: number, options: TilbyTcpSocketConnectOptions): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (this.socketInfo === null) {
				throw 'SOCKET_NOT_OPEN';
			}

			if (!this.socketInfo) {
				this.socketInfo = await new Promise((resolve) => {
					window.chrome.sockets.tcp.create((socketInfo: ChromeTcpSocketInfo) => {
						window.chrome.sockets.tcp.onReceive.addListener(this.onReceiveCallback);
						window.chrome.sockets.tcp.onReceiveError.addListener(this.onErrorCallback);

						resolve(socketInfo);
					});
				});
			}

			let currentSocketInfo = await this.getChromeSocketInfo();

			if (currentSocketInfo.connected) {
				throw 'SOCKET_ALREADY_CONNECTED';
			}

			let timeout = options?.timeout || 10000;
			let timedOut = false;

			let timeoutFunc = async () => {
				let currentSocketInfo = await this.getChromeSocketInfo();

				if (currentSocketInfo?.connected === false) {
					reject('CONNECTION_TIMEOUT');
					timedOut = true;
				}
			};

			// Connect
			window.chrome.sockets.tcp.connect(this.socketInfo!.socketId, ipAddress, port, (result: number) => {
				clearTimeout(timeoutHandle);

				if (timedOut) {
					ChromeTcpSocket.logEvent(`Socket ${this.socketInfo?.socketId} connected to ${ipAddress}:${port} after timeout expired. Disconnecting...`);
					this.disconnect();
				} else if (result === 0) {
					ChromeTcpSocket.logEvent(`Socket ${this.socketInfo?.socketId} connected to ${ipAddress}:${port}`);
					resolve()
				} else {
					reject(result);
				}
			});

			let timeoutHandle = setTimeout(timeoutFunc, timeout);
		});
	}

	public send(data: Uint8Array): Promise<void> {
		return new Promise(async (resolve) => {
			if (!this.socketInfo) {
				throw 'SOCKET_NOT_OPEN';
			}

			window.chrome.sockets.tcp.send(this.socketInfo.socketId, data.buffer, () => {
				ChromeTcpSocket.logEvent(`Sent packet to socket ${this.socketInfo?.socketId}:`, TilbyTcpSocketUtils.ab2Hex(data));
				resolve();
			});
		});
	}

	public disconnect(): Promise<void> {
		return new Promise(async (resolve) => {
			if (!this.socketInfo) {
				throw 'SOCKET_NOT_OPEN';
			}

			let currentSocketInfo = await this.getChromeSocketInfo();

			if (currentSocketInfo.connected) {
				window.chrome.sockets.tcp.disconnect(this.socketInfo.socketId, () => {
					ChromeTcpSocket.logEvent(`Socket ${this.socketInfo?.socketId} disconnected`);
					resolve();
				});
			}
		});
	}

	public close(): Promise<void> {
		return new Promise(async (resolve) => {
			if (!this.socketInfo) {
				throw 'SOCKET_NOT_OPEN';
			}

			//Disconnect if the socket is still connected
			let currentSocketInfo = await this.getChromeSocketInfo();

			if (currentSocketInfo.connected) {
				await this.disconnect();
			}

			//Complete subjects
			this.onDataSubject.complete();
			this.onErrorSubject.complete();

			window.chrome.sockets.tcp.close(this.socketInfo.socketId, () => {
				//Remove event listeners
				window.chrome.sockets.tcp.onReceive.removeListener(this.onReceiveCallback);
				window.chrome.sockets.tcp.onReceiveError.removeListener(this.onErrorCallback);

				ChromeTcpSocket.logEvent(`Socket ${this.socketInfo?.socketId} closed`);

				//Cleanup and lock socket from future use
				this.socketInfo = null;

				resolve();
			});
		});
	}
}
