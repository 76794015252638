import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    inject
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    first,
    from,
    Observable,
    tap
} from "rxjs";
import {
    $state,
    $stateParams,
    restManager
} from 'app/ajs-upgraded-providers';
import {
    CustomerFidelityPointsComponent,
    CustomerPrepaidCreditComponent,
    CustomersFields,
    CustomersFormGroups,
    PriceList,
} from "./tab-customer";
import * as _ from "lodash";
import { ShopChain } from 'src/app/models';
import { TranslateService } from "@ngx-translate/core";
import { ToolbarEventsService } from "src/app/core/services/toolbar-events/toolbar-events.service";
import { countryToCode } from "src/utilities";
import { GridDownloadDialogService, OpenDialogsService } from "src/app/dialogs";
import { DataConfirmDialog } from "src/app/dialogs/dialog.model";
import {
    FidelityMovementsFields,
    POST,
    PrepaidMovementsFields
} from "../../customers.model";
import { autobind } from "src/app/models/decorators.model";
import { PrepaidMovementFormFields } from "src/app/dialogs/prepaid_credit-movement-details-dialog/movement-form.model";
import { DataExport } from 'src/app/shared/model/model';
import { GridService } from "src/app/shared/components/grid/grid.service";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";
import { validate as validateUuid } from 'uuid';
import { EntityManagerService } from "src/app/core/services/entity/entity-manager.service";
import { ConfigurationManagerService } from 'src/app/core/services/configuration-manager/configuration-manager.service';
import {
    ChainCampaigns,
    ChainFidelitiesMovements,
    ChainPrepaidMovementsRead,
    Customers,
    FidelitiesPoints
} from 'tilby-models';
import { ToolbarEventsContextService } from "../../../../core/services/toolbar-events/toolbar-events-context.service";
import { CustomerFormService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ConnectionService,
    UserActiveSessionManagerService
} from 'src/app/core';
import { removeFieldsWithPrefix } from 'src/app/shared/string-utils';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';

@Component({
    selector: 'app-customers-details',
    templateUrl: './customers-details.component.html',
    styleUrls: ['./customers-details.component.scss'],
    providers: [OnDestroyService]
})
export class CustomersDetailsComponent implements OnInit, OnDestroy {
    private readonly gridService = inject(GridService);
    private readonly translate = inject(TranslateService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly restManagerService = inject(restManager);
    protected readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly state = inject($state);
    private readonly stateParams = inject($stateParams);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);
    private readonly customerFormService = inject(CustomerFormService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly _snackBar = inject(MatSnackBar);
    private readonly connection = inject(ConnectionService);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    fidelityLabel = this.configurationManagerService.getPreference('fidelity.fidelity_label') || this.translate.instant("CUSTOMERS.DETAILS.FIDELITY_CARD");
    filteredOptions = new Observable();
    validators= this.customerFormService.validators;
    @ViewChild('fidelityPointsComponent') fidelityPointsComponent!: CustomerFidelityPointsComponent;
    @ViewChild('prepaidCreditComponent') prepaidCreditComponent!: CustomerPrepaidCreditComponent;
    @Input() customer?: CustomersFields;
    @Input() paymentMethods?: any[];
    @Input() campaigns?: ChainCampaigns[];
    phoneNumber: string = ''

    isNew: boolean = false;
    priceLists?: PriceList[];
    points?: FidelitiesPoints[] = [];
    credit?: number;

    customTypes = this.configurationManagerService.getPreference("customers.custom_types") ? this.configurationManagerService.getPreference("customers.custom_types")?.split('\n') : [];
    customFields: { idx: number, name?: string }[] = [];
    customersForm?: FormGroup<CustomersFormGroups>;
    country = this.configurationManagerService.getShopCountry();
    tabPanel?: string;
    chainShops?: ShopChain[];
    private currentShopChain?: ShopChain;
    selectedCampaign?: ChainCampaigns;
    activeCampaign?: ChainCampaigns;

    set selectedCampaignId(id) {
        this.selectedCampaign = this.campaigns?.find(c => c.id === id)
    };

    get selectedCampaignId() {
        return this.selectedCampaign?.id
    }

    set activeCampaignId(id) {
        this.activeCampaign = this.campaigns?.find(c => c.id === id);
    };

    get activeCampaignId() {
        return this.activeCampaign?.id || null
    }

    user = this.userActiveSession.getSession()!;

    ngOnInit(): void {
        this.phoneNumber = this.stateParams.phoneNumber;

        if (!_.isEmpty(this.customer) && !validateUuid(_.get(this.customer, 'uuid') || '')) {
            alert('Cliente con Uuid non valido');
            this.state.go("app.new.customers");
        }
        this.isNew = this.state.$current.name.split('.').pop() === 'new';
        this.priceLists=this.customerFormService.priceLists;
        this.setupCustomFields();
        this.setupPoints();
        this.setupCredit();
        this.initCampaigns();
        this.customersForm = this.customerFormService.createForm(this.customer);
        //-------- NEW GENERIC TOOLBAR - START ----///

        /**
         * Create buttons for the toolbar
         */
        const createToolbarButtons = () => {
            this.toolbarEventsContextService.backButton$.next({
                isIt: () => true,
                name: 'arrow_back',
                icon: () => 'arrow_back',
                click: () => this.back()
            });
            this.toolbarEventsService.buttons$.next({
                barButtons: [
                    {
                        isIt: () => this.connection.isOffline(),
                        name: 'cloud_off',
                        icon: () => 'cloud_off',
                        click: _ => null
                    }
                ],
                panelButtons: [
                    {
                        isIt: () => this.hasToolbarMenu(),
                        name: 'show_json_customer',
                        icon: () => 'data_object',
                        label: () => 'CUSTOMERS.TOPBAR.ACTIONS.SHOW_JSON_CUSTOMER',
                        click: _ => this.openCustomerJsonDetail()

                    }
                ]
            });
            this.toolbarEventsContextService.buttons$.next({
                barButtons: [
                    {
                        isIt: () => this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.CREDIT') && this.hasModulePrepaid(),
                        name: 'add_new_prepaid_movement',
                        icon: () => 'playlist_add',
                        click: async _ => await this.openDialogsService.openPrepaidMovementDetailsDialog({data: {movement: <PrepaidMovementFormFields>{}, params: {customer: this.customer || {}},}}).then(res=>!!res&&this.addPrepaidCreditCallback(res))
                    },
                    {
                        isIt: () => this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.CREDIT') && this.hasModulePrepaid(),
                        name: 'export_prepaid_credit',
                        icon: () => 'download',
                        click: async _ => {
                            const {selectedShop: {uuid: shop_uuid}, customerPrepaidCreditFormComponent: {prepaidCreditForm: {value = {}} = {}} = {}} = this.prepaidCreditComponent || {};
                            // @ts-ignore
                            const {date_since: valid_from_since, date_max: valid_from_max} = value.customersPrepaidCreditForm;
                            const extraQueryParams = {
                                customer_uuid: this.customer?.uuid!,
                                ...(shop_uuid && {shop_uuid}),
                                ...(valid_from_since && {valid_from_since: TilbyDatePipe.shopDateStart({date: valid_from_since})}),
                                ...(valid_from_max && {valid_from_max: TilbyDatePipe.shopDateEnd({date: valid_from_max})}),
                            };
                            const res = await this.gridDownloadDialogService.openDialog('prepaid', {
                                data: this.prepaidCreditComponent.tableData.map(tableData => ({
                                    ...tableData,
                                    extraQueryParams,
                                    sortFilterQuery: this.prepaidCreditComponent.gridRef.getSortFilterQuery(),
                                    countSelectedRows: this.prepaidCreditComponent.gridRef.gridApi?.getSelectedRows().length
                                }))
                            }, 'prepaid_movements');
                            if (!!res) this.onExportPrepaidCredit(res, 'prepaid_movements', extraQueryParams);
                        }
                    },
                    {
                        isIt: () => this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.CREDIT'),
                        name: 'email_prepaid_movements',
                        icon: () => 'email',
                        click: async _ => await this.openDialogsService.openEmailExportDialog({data: {messageLabel: 'PREPAID.DETAILS.SEND_FIDELITY_REPORT.TITLE', confirmLabel:'DIALOG.EMAIL.ACTION.OK', cancelLabel:'DIALOG.EMAIL.ACTION.CANCEL'}})
                            .then(res=>!!res&&this.prepaidCreditComponent.sendPrepaidCreditReport(res.email))
                    },
                    {
                        isIt: () => this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.POINTS') && this.hasModuleFidelity(),
                        name: 'add_new_fidelity_movement',
                        icon: () => 'playlist_add',
                        click: async _ => await this.openDialogsService.openFidelityMovementDetailsDialog({data: {movement: <FidelityMovementsFields>{}, params: {campaigns: this.campaigns || [], selectedCampaign: this.selectedCampaign || null, customer: this.customer || {}, currentShopChain: this.currentShopChain || null}}})
                            .then(res=>!!res&&this.addFidelityPointsCallback(res))
                    },
                    {
                        isIt: () => this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.POINTS') && this.hasModuleFidelity(),
                        name: 'export_fidelity_points',
                        icon: () => 'download',
                        click: async _ => {
                            const { customerFidelityPointsFormComponent: {pointsForm: {value = {}} = {}} = {}} = this.fidelityPointsComponent || {};
                            // @ts-ignore
                            const {date_since, date_max, db_name} = value.customersPointsForm;
                            const extraQueryParams = {
                                fidelity: this.customer?.fidelity!,
                                campaign_id: this.activeCampaignId || -1,
                                ...((db_name && db_name !== "-1") && {db_name}),
                                ...(date_since && {date_since: TilbyDatePipe.shopDateStart({date: date_since})}),
                                ...(date_max && {date_max: TilbyDatePipe.shopDateEnd({date: date_max})}),
                            };
                            const res = await this.gridDownloadDialogService.openDialog('fidelity', {
                                data: this.fidelityPointsComponent.tableData.map(tableData => ({
                                    ...tableData,
                                    extraQueryParams,
                                    sortFilterQuery: this.fidelityPointsComponent.gridRef.getSortFilterQuery(),
                                    countSelectedRows: this.fidelityPointsComponent.gridRef.gridApi?.getSelectedRows().length
                                }))
                            }, 'fidelities_movements')
                            if (!!res) this.onExportFidelityPoints(res, 'fidelities_movements', {
                                fidelity: this.customer?.fidelity!,
                                campaign_id: this.activeCampaignId || -1
                            });
                        }
                    },
                    {
                        isIt: () => !!((this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.REGISTRY') || this.isNew) || this.customersForm?.disabled),
                        name: 'save',
                        icon: () => 'check',
                        click: _ => this.onSubmit(this.customersForm)
                    },
                    {
                        isIt: () => (this.tabPanel === this.translate.instant('CUSTOMERS.DETAILS.TABS.REGISTRY') && !this.isNew),
                        name: 'delete',
                        icon: () => 'delete',
                        click: _ => this.openDeleteDialog()
                    },
                ],
                panelButtons: []
            });
        }

        this.restManagerService.getList("chain_shops", {pagination: false})
            .then((chainShops: any) => {
                this.chainShops = chainShops;
                this.currentShopChain = _.find(this.chainShops, {db_name: this.user.shop.name});
                this.chainShops?.unshift({
                    id: null,
                    uuid: "",
                    'db_name': '-1',
                    'shop_name': this.translate.instant("CUSTOMERS.DETAILS.ALL_SHOPS")
            });
            console.debug('CURRENT_SHOP_CHAIN', this.currentShopChain, this.chainShops, this.user.shop);
        });

        createToolbarButtons();

//-------- NEW GENERIC TOOLBAR - END ----///

        this.contextToolbarLabelChange();
    }

    hasToolbarMenu() {
        return this.configurationManagerService.isUserPermitted("show_developer_options");
    }

    async openCustomerJsonDetail() {
        await this.openDialogsService.openGenericJsonDetailDialog({ data: { title:'CUSTOMERS.DETAILS.TITLE_OPEN_JSON_DETAIL', nameFile: 'customer_json_data', json: removeFieldsWithPrefix(this.customer || {}, '$') } });
    }

    ngOnDestroy(): void {
    }

    contextToolbarLabelChange() {
        const {company_name, first_name, last_name} = this.customer || {};
        this.toolbarEventsContextService.label = this.isNew
            ? 'CUSTOMERS.DETAILS.FORM.NEW_CUSTOMER'
            : company_name || `${first_name} ${last_name}`;
    }

    @autobind
    onExportFidelityPoints(dataExports: DataExport,tableToCall?:string,extraQueryParams?:Pick<ChainFidelitiesMovements,'fidelity'|'campaign_id'>) {
        this.fidelityPointsComponent.gridRef?.onBtnExport(dataExports,tableToCall,extraQueryParams)
    }

    @autobind
    onExportPrepaidCredit(dataExports: DataExport,tableToCall?:string,extraQueryParams?:Pick<ChainPrepaidMovementsRead,'customer_uuid'>) {
        this.prepaidCreditComponent.gridRef?.onBtnExport(dataExports,tableToCall,extraQueryParams)
    }

    private setupCustomFields() {
        for(let i = 1; i <= 9; i++) {
            const fieldName = this.configurationManagerService.getSetting(`customers.custom_field_${i}`);

            if(fieldName) {
                this.customFields.push({
                    idx: i,
                    name: fieldName
                });
            }
        }
    }

    private async setupPoints() {
        this.points = [];

        const fidelity = this.customer?.fidelity;

        if(fidelity) {
            const fidelitiesPoints = await this.entityManager.fidelitiesPoints.fetchCollectionOnline({ fidelity: fidelity });

            if(Array.isArray(fidelitiesPoints)) {
                this.points = fidelitiesPoints;
            }
        }
    }


    private initCampaigns() {
        this.selectedCampaign = _.find(this.campaigns, {isValid: true});
        this.activeCampaign = _.find(this.campaigns, {isValid: true});
    }

    private setupCredit() {

        const queryCredit = {
            pagination: false,
            customer_uuid: this.customer?.uuid,
            valid_to: 'null'
        };

        this.restManagerService.getList("prepaid_movements", queryCredit).then((result: any[]) => {
            this.credit = result.length !== 0 ? (result[0]?.credit + result[0]?.ticket_credit) : '0.00';
        });
    }

    onSubmit(form?: FormGroup<CustomersFormGroups>) {
        if (this.customersForm?.invalid) {
            Object.values(this.customersForm.controls)
                .forEach(control => control.controls && Object.values(control.controls).forEach(control => {
                control.markAllAsTouched();
                control.updateValueAndValidity();
            }));
        }
        if (form?.valid) {
            const labels: DataConfirmDialog = {
                messageLabel: 'APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE',
                confirmLabel: 'APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE_YES',
                cancelLabel: 'APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE_NO'
            };
            const lotteryCheckDone = (old: string, current: string) => (!!current && current !== old)
                ? this.openDialogsService.openConfirmDialog({data: labels}).then(res=>res&&this.postPutOnDb(form.value, (this.customer||{})))
                : true;
            if (lotteryCheckDone(this.customer?.lottery_code || '', form.value.generalForm?.lottery_code || '')) {
                this.postPutOnDb(form.value, (this.customer || {}));
            }

        }
    }

    postPutOnDb(formValue: any, customer: CustomersFields) {
        const {
            generalForm,
            addressesForm: {
                billingForm,
                shippingForm,
                shippingForm_1,
                shippingForm_2,
                shippingForm_3,
                shippingForm_4,
                shippingForm_5,
                shippingForm_6,
                shippingForm_7,
                shippingForm_8,
                shippingForm_9
            },
            optionsForm
        } = formValue;
        let customerFormFlat = {...customer, ...generalForm, ...billingForm, ...shippingForm, ...shippingForm_1, ...shippingForm_2, ...shippingForm_3, ...shippingForm_4, ...shippingForm_5, ...shippingForm_6, ...shippingForm_7, ...shippingForm_8, ...shippingForm_9, ...optionsForm};
        // Set the Country Name To CountryCode
        customerFormFlat = Object.entries(customerFormFlat).reduce((obj, [k, v]: [string, any]) => ({
            ...obj,
            [k]: countryToCode(k, v) || v
        }), <CustomersFields>{});
        // Transform payment_allowed
        customerFormFlat.payments_allowed = JSON.stringify(customerFormFlat.payments_allowed);

        const callback = (r:Customers) => {
            this.gridService.tableProperties.gridIstances?.customers.api.refreshServerSide({purge: false});
            this.contextToolbarLabelChange();
            this.state.go(`app.new.customers.details`, {customerId: r.id, isNew:true});
            this._snackBar.open(this.translate.instant('CUSTOMERS.DETAILS.SAVED'), undefined, {duration: 3000,horizontalPosition:'start',verticalPosition:"bottom",})
        };

        if (customerFormFlat.id) {
            from<Promise<Customers>>(this.entityManager.customers.putOneOnline(customerFormFlat)).pipe(tap(results => this.customer = results))
                .subscribe(
                    r => {
                        callback(r);
                    },
                    err => this.openDialogsService.openAlertDialog({data: {messageLabel: 'CUSTOMERS.DETAILS.ERROR_IN_EDIT'}})
                )
        } else {
            from<Promise<Customers>>(this.entityManager.customers.postOneOnline(customerFormFlat)).pipe(tap(results => this.customer = {
                    ...results,
                    isNew: true
                }))
                .subscribe(
                    r => {
                        this.isNew = false;
                        callback(r);
                    },
                    err => this.openDialogsService.openAlertDialog({data: {messageLabel: 'CUSTOMERS.DETAILS.ERROR_IN_CREATION'}})
                )
        }
    }

    deleteRecord() {
        this.state.go('app.new.customers', {remove: this.customer?.id});

        // from<Promise<Customers>>(this.entityManager.customers.deleteOneOnline(this.customer.id).then(({results}) => results)).subscribe(r => this.state.go(`app.new.customers`));
    }

    // -------- CALLBACK FOR DIALOGS -----------
    @autobind
    addFidelityPointsCallback(movement: POST.FidelityMovement) {
        this.restManagerService.post("fidelities_movements", movement)
            .then((movement: FidelityMovementsFields) => {
                this.fidelityPointsComponent.gridRef?.agGrid.api.refreshServerSide({purge: false});
                this.setupPoints();
                // FLASHING_ROW
                this.gridService.fidelity$.data.pipe(first(),tap(()=>this.gridService.fidelity$.flash.next({...movement, isNew: true}))).subscribe();
            });
    }

    @autobind
    addPrepaidCreditCallback(movement: POST.PrepaidMovement) {
        this.restManagerService.post("prepaid_movements", movement)
            .then((movement: PrepaidMovementsFields) => {
                this.prepaidCreditComponent.gridRef?.agGrid.api.refreshServerSide({purge: false});
                this.setupCredit();
                // FLASHING_ROW
                this.gridService.prepaid$.data.pipe(first(),tap(()=>this.gridService.prepaid$.flash.next({...movement, isNew: true}))).subscribe();
            });
    }

    async openDeleteDialog() {
        const res = await this.openDialogsService.openConfirmDialog({data: {
                messageLabel: 'DIALOG.CUSTOMER_DELETE.TITLE',
                confirmLabel: 'DIALOG.CUSTOMER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.CUSTOMER_DELETE.CANCEL'
            }});
        if(res) this.deleteRecord();
    }

    back() {
        if (this.customer) this.gridService.customers$.flash.next(this.customer);
        this.state.go(`app.new.customers`);
        this.toolbarEventsContextService.buttons$.next({panelButtons: [], barButtons: []});
    }

    hasModulePrepaid() {
        return this.configurationManagerService.isModuleEnabled('prepaid');
    }

    hasModuleFidelity() {
        return this.configurationManagerService.isModuleEnabled('fidelity') && !!this.customer?.fidelity;
    }

}
