import {
    Component,
    Input,
    signal
} from '@angular/core';
import { CommonModule } from "@angular/common";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { slideAnimation } from './slide.animation';

export type CarouselSlide={image:string};

@Component({
  selector: 'app-carousel-images-slider',
  standalone: true,
  imports: [CommonModule, TilbyGesturesDirective],
  animations: [slideAnimation],
  templateUrl: './carousel-images-slider.component.html',
  styleUrl: './carousel-images-slider.component.scss'
})
export class CarouselImagesSliderComponent {
    currentIndex = signal(0);
    @Input({required:true}) slides:CarouselSlide[] = [];

    constructor() {
        this.preloadImages();
    }

    preloadImages() {
        this.slides.forEach((slide) => {
            new Image().src = slide.image;
        });
        console.log('slides: ', this.slides);
    }

    setCurrentSlideIndex(index: number) {
        this.currentIndex.set(index);
    }

    isCurrentSlideIndex(index: number) {
        return this.currentIndex() === index;
    }

    prevSlide() {
        this.currentIndex.update(prev=>this.currentIndex() < this.slides.length - 1 ? ++prev : 0);
    }

    nextSlide() {
        this.currentIndex.update(prev=>this.currentIndex() > 0 ? --prev : this.slides.length - 1);
    }
}
