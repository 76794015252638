<h2 mat-dialog-title translate>{{data.titleLabel || 'Title'}}</h2>
<mat-dialog-content class="">
    <mat-selection-list *ngIf="!!printers.length; else noPrinters" [color]="'primary'" [multiple]="false" (selectionChange)="selectionChange($event)">
        <div *ngFor="let printer of printers">
            <mat-list-option [value]="printer" color="primary">
                <mat-icon matListItemIcon>print</mat-icon>
                <div matListItemTitle>{{printer.name}}</div>
            </mat-list-option>
            <mat-divider></mat-divider>
        </div>
    </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="center" class="tw-px-4 tw-pb-2">
    <button mat-button [mat-dialog-close]="false">{{'DIALOG.EMAIL.ACTION.CANCEL'|translate}}</button>
</mat-dialog-actions>
    <ng-template #noPrinters>
        <div class="general-document-printer-select-no-items-placeholder">
            <div class="general-document-printer-select-no-items-placeholder-content">
                <img [src]="'assets/images/print-error.png'" alt="" />
                <div class="general-document-printer-select-no-items-placeholder-message" translate>APPLICATION.ORDER_PRINTER.NO_PRINTER</div>
            </div>
        </div>
    </ng-template>
