import { CommonModule } from "@angular/common";
import { Component, ElementRef, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { DevLogger } from "src/app/shared/dev-logger";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { LotteryCodeForm } from "./lottery-code-dialog.state.service";

@Component({
    selector: 'app-lottery-code-dialog',
    standalone: true,
      imports: [CommonModule, TilbyMagicFormComponent, MatButtonModule, TranslateModule],
    templateUrl: './lottery-code-dialog.component.html',
    styleUrls: ['./lottery-code-dialog.component.scss']
  })
  export class LotteryCodeDialogComponent {

    protected readonly data:InputItemDataMagicForm<LotteryCodeForm> = inject(MAT_DIALOG_DATA);

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const input = this.el.nativeElement.querySelector('#input-lottery-code input');
            if (input) { input.focus(); }
        },200)
    }

    protected log(...args: any[]) {
        DevLogger.log('LOTTERY_CODE_DIALOG_COMPONENT', ...args);
    }

  }
