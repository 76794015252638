import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DataAlertDialog} from "../dialog.model";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  host:{class:'tw-p-0'}
})
export class AlertDialogComponent {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DataAlertDialog,
  ) { }

}
