import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilteredCountryPipe} from "./filtered-country.pipe";
import {CodeToCountryPipe} from "./codeToCountry.pipe";
import {FidelityPointsGetPointsSignPipe, FidelityPointsGetTypePipe} from "./fidelity_points-get-type.pipe";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import {TilbyNumberPipe} from "@tilby/tilby-ui-lib/pipes/tilby-number";


const pipes = [FilteredCountryPipe,CodeToCountryPipe,FidelityPointsGetTypePipe, FidelityPointsGetPointsSignPipe];
const pipesStandalone = [ TilbyDatePipe, TilbyCurrencyPipe, TilbyNumberPipe];

@NgModule({
    declarations: pipes,
    imports: [
        CommonModule,
        ...pipesStandalone
    ],
    providers:[...pipes,...pipesStandalone],
    exports: [...pipes,...pipesStandalone]
})
export class PipesModule {
}
