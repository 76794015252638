import * as angular from 'angular';
import * as _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';

angular.module('stock', ['application']);

angular.module('stock').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    
    $translatePartialLoaderProvider.addPart('stock');

    $stateProvider.state('app.stock', {
        url: '/stock',
        resolve: {
            stockLocations: ["restManager", "$translate", async (restManager, $translate) => {
                const stockLocations = await restManager.getList('stock_locations');

                for(let location of stockLocations) {
                    if(location.id <= 100) {
                        location.name = $translate.instant(`STOCK.LOCATIONS.${location.name}`);
                    }
                }

                return stockLocations;
            }]
        }
    });

    $stateProvider.state('app.stock.management', {
        url: '/management',
        views: {
            "appContent@app": {
                template: require('./partial/stock-management/stock-management.html'),
                controller: 'StockManagementCtrl'
            }
        }
    });

    $stateProvider.state('app.stock.movements', {
        url: '/movements',
        views: {
            "appContent@app": {
                template: require('./partial/stock-movements/stock-movements.html'),
                controller: 'StockMovementsCtrl'
            }
        }
    });

    $stateProvider.state('app.stock.new-movement', {
        url: '/new-movement',
        views: {
            "appContent@app": {
                template: require('./partial/stock-new-movement/stock-new-movement.html'),
                controller: 'StockNewMovementCtrl'
            }
        },
        resolve: {
            items: ["entityManager", (entityManager) => entityManager.items.fetchCollectionOffline()],
            suppliers: ["entityManager", (entityManager) => entityManager.suppliers.fetchCollectionOffline()],
            rawMaterials: ["entityManager", (entityManager) => entityManager.rawMaterials.fetchCollectionOffline()],
            stocksByLocation: ["entityManager", async (entityManager) => {
                const stockCollection = await entityManager.stock.fetchCollectionOnline({ stock_location_id: 'notnull' });
                const stocksByLocation = _.groupBy(stockCollection, 'stock_location_id');

                const result = {};

                for(let stockLocationId in stocksByLocation) {
                    let locationDictionary = new SclDictionary();

                    for(let stock of stocksByLocation[stockLocationId]) {
                        if (stock.combination_id) {
                            locationDictionary.set(`combination_id_${stock.combination_id}`, stock);
                        } else if (stock.item_id) {
                            locationDictionary.set(`item_id_${stock.item_id}`, stock);
                        } else if (stock.raw_material_id) {
                            locationDictionary.set(`raw_material_id_${stock.raw_material_id}`, stock);
                        }
                    }

                    result[stockLocationId] = locationDictionary;
                }

                return result;
            }]
        },
        params: {
            mode: null,
            movements: null,
            stock_location_id: null
        }
    });
    /* Add New States Above */
}]);
