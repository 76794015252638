import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('newRoom', newRoom);

newRoom.$inject = ["$mdDialog", "$state", "$translate", "checkManager", "entityManager"];

function newRoom($mdDialog, $state, $translate, checkManager, entityManager) {
    newRoomController.$inject = ["$scope", "$mdDialog", "floors"];

    function newRoomController($scope, $mdDialog, floors) {
        Object.assign($scope, {
            priceLists: []
        });

        //Setup pricelists
        for(let idx = 1; idx <= 10; idx++) {
            $scope.priceLists.push({
                id: idx,
                name: checkManager.getPreference(`price_list_${idx}_name`) || `${$translate.instant('ITEMS.DETAILS.PRICE_LIST_START')} ${idx}`
            });
        }

        $scope.priceLists.unshift({
            id: null,
            name: $translate.instant("CUSTOMERS.DETAILS.NONE")
        });

        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function() {
            $mdDialog.hide($scope.data);
        };

        var lengths = _.range(3,51);

        $scope.heights = lengths;
        $scope.widths = lengths;

        $scope.floor = _.cloneDeep(floors);

        $scope.data = {
            default_pricelist: null,
            floor: $scope.floor[0].name,
            width: 10,
            height: 10
        };
    }

    var newRoom = {
        show: function() {
            $mdDialog.show({
                    controller: newRoomController,
                    template: require('./new-room.html'),
                })
                .then(function(answer) { //TODO: handle entityManager errors
                    entityManager.rooms.postOneOnline(answer).then(function(room) {
                        $state.go('app.tables.rooms-view', {
                            id: room.id
                        }, {
                            reload: "app.tables"
                        });
                    });
                });
        }
    };

    return newRoom;
}
