<form *ngIf="fidelityMovementForm" autocomplete="off" [formGroup]="fidelityMovementForm"
      (ngSubmit)="submit(fidelityMovementForm)"
      class="form">
    <div class="sm:tw-flex tw-justify-between tw-gap-4">
        <tilby-datetime-picker
            formControlName="date"
            label="FIDELITY.ADD_SHOW_MOVEMENT.DATE"
        />
        <mat-form-field color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.TYPE</mat-label>
            <input *ngIf="viewMode && type && type.value" matInput [value]="$any(types)[type.value]" disabled>
            <mat-select *ngIf="addMode" formControlName="type" required>
                <mat-option *ngFor="let type of (types | keyvalue)" [value]="type.key">{{type.value}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="tw-flex tw-justify-between" *ngIf="viewMode">
        <mat-form-field color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.SHOP</mat-label>
            <input matInput formControlName="shop_name">
        </mat-form-field>
        <mat-form-field color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.SALE</mat-label>
            <input matInput formControlName="sale_name">
        </mat-form-field>
    </div>
    <div class="tw-flex tw-justify-between">
        <mat-form-field color="primary" class="items" [ngClass]="{ 'sm:tw-min-w-[250px]': !viewMode }">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.CAMPAIGN</mat-label>
            <input *ngIf="viewMode" matInput formControlName="campaign_name">
            <mat-select *ngIf="addMode" formControlName="campaign" required>
                <mat-option *ngFor="let campaign of data.params?.campaigns"
                            [value]="campaign">{{campaign.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.POINTS</mat-label>
            <input matInput type="number" formControlName="points" required>
        </mat-form-field>
    </div>
    <div class="tw-flex tw-justify-between tw-gap-4" *ngIf="viewMode">
        <mat-form-field *ngIf="rule_name?.value" color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.RULE</mat-label>
            <input matInput formControlName="rule_name">
        </mat-form-field>
        <mat-form-field *ngIf="item_name?.value" color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.ITEM_NAME</mat-label>
            <input matInput formControlName="item_name">
        </mat-form-field>
        <mat-form-field *ngIf="item_sku?.value" color="primary" class="items">
            <mat-label translate>FIDELITY.ADD_SHOW_MOVEMENT.SKU</mat-label>
            <input matInput formControlName="item_sku">
        </mat-form-field>
    </div>
    <div class="tw-flex tw-w-full">
        <mat-form-field color="primary" class="tw-flex tw-w-full items" style="display: contents;">
            <mat-label translate>PREPAID.ADD_SHOW_MOVEMENT.NOTE</mat-label>
            <input matInput formControlName="notes" [maxlength]="validators.notes">
            <mat-error *ngIf="notes?.hasError('maxLength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                (max. {{validators.notes}})
            </mat-error>
        </mat-form-field>
    </div>

</form>



