<div class="first-section-wrapper">
    <div class="tw-flex tw-gap-x-10">
        <ngx-skeleton-loader class="tw-w-1/2" [theme]="{height:'40px', background:'gainsboro'}"></ngx-skeleton-loader>
    </div>
</div>
<div class="second-section-wrapper">
    <ngx-skeleton-loader
        *ngFor="let x of [1,2,3,4,5,6]"
        [theme]="{
            width: '90%',
            'border-radius': '0',
            height: '15px',
            'margin-bottom': '10px',
            background:'gainsboro'
        }">
    </ngx-skeleton-loader>
</div>


