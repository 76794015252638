import * as angular from 'angular';

angular.module('printers').component('printersList', {
    bindings: {
        printers: '<',
        goToPrinter: '<',
        currentPrinter: '<',
        newPrinter: '<',
        generalSettings: '<',
    },
    template: require('./printers-list.html'),
    controller: ["$translate", function($translate) {
        this.getPrinterType = function(printer) {
            switch(printer.type) {
                case 'fiscal':
                case 'rt':
                case 'nonfiscal':
                case 'receipt':
                case 'kds':
                    return $translate.instant(`PRINTERS.LIST.${printer.type.toUpperCase()}`);
                default:
                    return $translate.instant('PRINTERS.LIST.UNKNOWN');
            }
        };

        this.isSelected = function(printer) {
            return printer === this.currentPrinter;
        };
    }]
});