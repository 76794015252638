import {Component, Injectable, inject} from '@angular/core';
import {MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { lastValueFrom } from 'rxjs';
import {
    BaseDialogService, NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CommonModule } from '@angular/common';
import { TilbyMagicFormComponent } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { MatButtonModule } from '@angular/material/button';
import { ConfigurationManagerService } from 'src/app/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-select-type-daily-closing-dialog.',
  standalone: true,
  imports: [CommonModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent, TilbyDialogToolbarComponent, MatDialogModule, TilbyMagicFormComponent, MatButtonModule, TranslateModule],
  templateUrl: './select-type-daily-closing-dialog.component.html',
  styleUrls: ['./select-type-daily-closing-dialog.component.scss']
})
export class SelectTypeDailyClosingDialogComponent {

    private readonly matDialogRef = inject(MatDialogRef);
    private readonly configurationManagerService = inject(ConfigurationManagerService);

    protected isClosingDailyPermitted = this.configurationManagerService.isUserPermitted("cashregister.enable_dailyclosure");

    action(type: string) {
        this.matDialogRef.close(type);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SelectTypeDailyClosingDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: any) {
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions({ height: '150px', width: '400px' }), disableClose:true, data };
        return lastValueFrom(this.dialogRef.open(SelectTypeDailyClosingDialogComponent,config).afterClosed()).then((res : string | undefined) => {
            return {
                typeAction : res
            }
        });
    }
}
