import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import { OpenDialogsService } from 'src/app/dialogs';
import {GridComponent} from '../grid.component';
import {GridService} from '../grid.service';

@Component({
    selector: 'app-grid-clickable-button-static-token',
    templateUrl: './grid-clickable-button-static-token.component.html',
    styleUrls: ['./grid-clickable-button-static-token.component.scss'],
})
export class GridClickableButtonStaticTokenComponent implements ICellRendererAngularComp {
    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        if (this.gridService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id)) > -1) this.hide = true;
    }

    constructor(private gridService: GridService, public openDialogsService: OpenDialogsService) {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    openDeleteDialog($event: any) {
        $event.stopPropagation();
        this.openDialogsService.openConfirmDialog({data: {
            messageLabel: 'SETTINGS.USERS_MANAGEMENT.CONFIRM_DELETE_TOKEN',
            confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
            cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
        }}).then((res) => res && this.onRemoveSelected());
    }


    onRemoveSelected() {
        (<GridComponent>this.params.context.componentParent).clickableButton.emit({
            method: 'delete',
            id: this.params.data.token_id
        });
    }

    preventSelection($event: MouseEvent) {
        // if(!!this.params.context.componentParent.gridApi.getSelectedRows().length)
        $event.stopPropagation();
    }


}
