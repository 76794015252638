export const CARD_CIRCUITS = [
    {
        "id": 1,
        "name": "Visa",
        "icon": "assets/images/sales/card-circuits/visa.png"
    }, {
        "id": 2,
        "name": "Visa Electron",
        "icon": "assets/images/sales/card-circuits/visa-electron.png"
    }, {
        "id": 3,
        "name": "Mastercard",
        "icon": "assets/images/sales/card-circuits/mastercard.png"
    }, {
        "id": 4,
        "name": "American Express",
        "icon": "assets/images/sales/card-circuits/american-express.png"
    }, {
        "id": 5,
        "name": "Diners",
        "icon": "assets/images/sales/card-circuits/diners.png"
    }, {
        "id": 6,
        "name": "Cirrus",
        "icon": "assets/images/sales/card-circuits/cirrus.png"
    }, {
        "id": 7,
        "name": "Maestro",
        "icon": "assets/images/sales/card-circuits/maestro.png"
    }, {
        "id": 8,
        "name": "VPay",
        "icon": "assets/images/sales/card-circuits/vpay.png"
    }, {
        "id": 9,
        "name": "PagoBancomat",
        "icon": "assets/images/sales/card-circuits/pagobancomat.png"
    }, {
        "id": 10,
        "name": "Postamat",
        "icon": "assets/images/sales/card-circuits/postamat.png"
    }
];
