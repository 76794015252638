import {Pipe, PipeTransform} from '@angular/core';
import {CountryCode} from "../models";
import {countryCodesShort} from "../../utilities";

@Pipe({
    name: 'filteredCountry',
})
export class FilteredCountryPipe implements PipeTransform {

    private _filter(value=''): CountryCode[] {
        if(!value){
            return countryCodesShort;
        }
        const filterValue = value.toLowerCase();
        return countryCodesShort.filter(({
            code,
            name
        }) => code.toLowerCase().includes(filterValue) || name.toLowerCase().includes(filterValue));

    }

    transform(country:string|null|undefined): CountryCode[]|undefined {
        if(country) {return this._filter(country);}
    }

}
