import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fileImporter').controller('FileImporterFieldsCtrl', ["$scope", "$state", "$translate", "$filter", "alertDialog", "confirmDialog", "fileSchema", "fileConfig", "util", function($scope, $state, $translate, $filter, alertDialog, confirmDialog, fileSchema, fileConfig, util) {
    _.assign($scope, {
        topbar_context: {
            title: $translate.instant('FILE_IMPORTER.FIELDS.TITLE'),
            canGoBack: function() { return true; },
            goBack: function() {
                confirmDialog.show($translate.instant('FILE_IMPORTER.BACK_CONFIRM')).then(function(answer) {
                    if(answer) {
                        $state.go('app.file_importer.showcase');
                    }
                });
            },
            canConfirm: function() { return true; },
        },
        fileConfig: _.cloneDeep(fileConfig),
        typeInsert: _.toLower(fileConfig.typeInsert) + (_.endsWith(fileConfig.typeInsert, 's') ? '' : 's')
    });

    var primaryKeys = {
        items: [
            {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.ID'),
                value: 'id'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.SKU'),
                value: 'sku'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.BARCODE'),
                value: 'barcode'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.OPTION1_VALUE'),
                value: 'option1_value'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.ITEMS.OPTION2_VALUE'),
                value: 'option2_value'
            }
        ],
        customers: [
            { 
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.ID"),
                value: 'id'
            }, { 
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.FIDELITY"),
                value: 'fidelity'
            }, { 
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.EXTERNAL_ID"),
                value: 'external_id'
            }, { 
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.CUSTOM_1"),
                value: 'custom_1'
            }, { 
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.CUSTOM_2"),
                value: 'custom_2'
            }, {
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.EMAIL"),
                value: 'email'
            }, {
                name: $translate.instant("FILE_IMPORTER.FIELDS.PRIMARY_KEYS.CUSTOMERS.PHONE"),
                value: 'phone'
            }
        ],
        suppliers: [
            {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.SUPPLIERS.ID'),
                value: 'id'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.SUPPLIERS.VAT_CODE'),
                value: 'vat_code'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.SUPPLIERS.EMAIL'),
                value: 'email'
            }
        ],
        stock_movements: [
            {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.STOCK_MOVEMENTS.ID'),
                value: 'id'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.STOCK_MOVEMENTS.SKU'),
                value: 'sku'
            }, {
                name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.STOCK_MOVEMENTS.BARCODE'),
                value: 'barcode'
            }
        ]
    };

    var filterPrimaryKeys = function() {
        var mappedFields = {};

        var noPrimaryKey = {
            name: $translate.instant('FILE_IMPORTER.FIELDS.PRIMARY_KEYS.NONE'),
            value: 'none'
        };
        
        _.forEach($scope.exampleRow, function(row) {
            if(_.isObject(row.dbField)) {
                mappedFields[row.dbField.id] = true;
            }
        });

        $scope.primaryKeys = _.filter(primaryKeys[$scope.typeInsert], function(primaryKey) {
            return mappedFields[primaryKey.value];
        });

        $scope.primaryKeys.unshift(noPrimaryKey);

        var isCurrentKeyValid = _.some($scope.primaryKeys, function(key) {
            return key.value === $scope.fileConfig.primaryKey;
        });

        if(!isCurrentKeyValid) {
            $scope.fileConfig.primaryKey = 'none';
        }
    };

    $scope.overwriteMethods = [
        {
            name: $translate.instant('FILE_IMPORTER.FIELDS.FULL_OVERWRITE'),
            value: 'overwrite'
        }, {
            name: $translate.instant('FILE_IMPORTER.FIELDS.PARTIAL_OVERWRITE'),
            value: 'onlyFields'
        }, {
            name: $translate.instant('FILE_IMPORTER.FIELDS.SKIP_EXISTING'),
            value: 'pass'
        }
    ];

    $scope.exampleRow = _.map(fileSchema.rawFirst, function(val, key) { return { keyCsv: key, value: val }; });

    $scope.endpointColumns = _(fileSchema.listDbColumns).map(function(column) {
        return {
            id: column.name,
            name: $translate.instant('FILE_IMPORTER.FIELDS.' + _.toUpper($scope.typeInsert) + '.' + _.toUpper(column.name))
        };
    }).sortBy('name').value();

    $scope.filterFields = function(searchText) {
        return $filter('filter')($scope.endpointColumns, searchText);
    };

    $scope.onFieldBlur = function(field) {
        if(_.isNil(field.dbField)) {
            delete field.search;
        }
    };

    $scope.onFieldChange = function(field) {
        filterPrimaryKeys();
    };

    $scope.getHelpText = function() {
        return $translate.instant('FILE_IMPORTER.FIELDS.HELP.' + _.toUpper($scope.typeInsert)); 
    };

    $scope.visitHelpPage = function() {
        util.openExternalLink("https://support.tilby.com/hc/it/articles/360017268218");
    };

    var checkFields = function(data) {
        var fieldsSet = {};

        var hasDuplicate = _.some(data.schemaImporting, function(row) {
            if(!fieldsSet[row.keyDb]) {
                fieldsSet[row.keyDb] = true;
            } else {
                return true;
            }
        });

        if(hasDuplicate) {
            return 'DUPLICATED_FIELDS';
        }
    };

    $scope.topbar_context.confirm = function() {
        if($scope.fileImporterFieldsForm.$valid) {
            var dataToSend = _.cloneDeep($scope.fileConfig);
        
            dataToSend.schemaImporting = _($scope.exampleRow).filter('dbField').map(function(row) {
                return {
                    keyCsv: row.keyCsv,
                    keyDb: row.dbField.id
                };
            }).value();

            var error = checkFields(dataToSend);
            
            if(_.isEmpty(error)) {
                $state.go('app.file_importer.schedule', {
                    fileConfig: dataToSend,
                    id: fileConfig.id
                });
            } else {
                alertDialog.show($translate.instant('FILE_IMPORTER.FIELDS.' + error));
            }
        } else {
            alertDialog.show($translate.instant($scope.fileImporterFieldsForm.$error.required ? 'FORM_ERRORS.INSERT_REQUIRED_DATA' : 'FORM_ERRORS.THERE_ARE_ERRORS'));
        }
    };

    //Parse existing settings
    if(!$scope.fileConfig.primaryKey) {
        switch($scope.typeInsert) {
            case 'items':
                $scope.fileConfig.primaryKey = 'sku';
            break;
            default: break;
        }
    }

    var columnsById = _.keyBy($scope.endpointColumns, 'id');

    if(!_.isEmpty($scope.fileConfig.schemaImporting)) {
        var exampleRowByCsvId = _.keyBy($scope.exampleRow, 'keyCsv');

        _.forEach($scope.fileConfig.schemaImporting, function(row) {
            if(exampleRowByCsvId[row.keyCsv] && columnsById[row.keyDb]) {
                _.assign(exampleRowByCsvId[row.keyCsv], {
                    dbField: columnsById[row.keyDb],
                    search: columnsById[row.keyDb].name
                });
            }
        });
    } else {
        _.forEach($scope.exampleRow, function(row) {
            if(columnsById[row.keyCsv]) {
                row.dbField = columnsById[row.keyCsv];
            }
        });
    }

    filterPrimaryKeys();
}]);