import {
    Socket,
    Server
} from "net";

import {
    Subject
} from "rxjs";

import {
    TilbyTcpSocket,
    TilbyTcpSocketConnectOptions,
    TilbyTcpSocketServer,
    TilbyTcpSocketUtils
} from "./tilby-tcp-socket";

export class NodeTcpSocketServer implements TilbyTcpSocketServer {
    private server?: Server;
    private port?: number;
    private connectionSubject = new Subject<TilbyTcpSocket>();

    public onConnection = this.connectionSubject.asObservable();

    private onErrorSubject = new Subject<string>();
    public onError = this.onErrorSubject.asObservable();

    constructor() {
        const { Server } = window.require('net');
        this.server = new Server() as Server;
        this.server.on('connection', (socket) => this.connectionSubject.next(new NodeTcpSocket(socket)));
    }

    private static logEvent(...message: any[]) {
        console.debug('[ NodeTcpSocketServer ]', ...message);
    }

    public listen(port: number) {
        if (!this.server) {
            throw 'SERVER_NOT_OPEN';
        }

        this.port = port;
        this.server.listen(port);

        this.server.once('listening', () => {
            NodeTcpSocketServer.logEvent(`Listening on port ${port}`);
        });

        this.server.on('error', (error) => {
            this.onErrorSubject.next(error.name);
        });
    }

    public close() {
        if (!this.server) {
            throw 'SERVER_NOT_OPEN';
        }

        //Complete subjects
        this.connectionSubject.complete();
        this.server.close(() => {
            NodeTcpSocketServer.logEvent(`Server on port ${this.port} closed`);
        });
    }
}



export class NodeTcpSocket implements TilbyTcpSocket {
    private socket?: Socket;
    private socketId: number;

    private onDataSubject = new Subject<Uint8Array>();
    private onErrorSubject = new Subject<number>();

    public onData = this.onDataSubject.asObservable();
    public onError = this.onErrorSubject.asObservable();

    private static socketCounter = 1;

    private static logEvent(...message: any[]) {
        console.debug('[ NodeTcpSocket ]', ...message);
    }

    constructor(socket?: Socket) {
        if (socket) {
            this.socket = socket;
        } else {
            const { Socket } = window.require('net');
            this.socket = new Socket() as Socket;
        }

        this.socketId = NodeTcpSocket.socketCounter++;

        this.socket.on('data', (buffer) => {
            let packet = Uint8Array.from(buffer);

            NodeTcpSocket.logEvent(`Received packet from socket ${this.socketId}:`, TilbyTcpSocketUtils.ab2Hex(packet));
            this.onDataSubject.next(packet);
        });

        this.socket.on('error', (error) => {
            NodeTcpSocket.logEvent(`Received error from socket ${this.socketId}:`, error);
            this.onErrorSubject.next(-1);
        });
    }

    public connect(ipAddress: string, port: number, options: TilbyTcpSocketConnectOptions): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                throw 'SOCKET_NOT_OPEN';
            }

            if (this.socket.remoteAddress && !this.socket.destroyed) {
                throw 'SOCKET_ALREADY_CONNECTED';
            }

            let timeout = options?.timeout || 10000;
            let timedOut = false;

            let timeoutFunc = () => {
                if (!this.socket?.remoteAddress) {
                    reject('CONNECTION_TIMEOUT');
                    timedOut = true;
                }
            }

            let onConnectionSuccess = () => {
                clearTimeout(timeoutHandle);

                if (timedOut) {
                    NodeTcpSocket.logEvent(`Socket ${this.socketId} connected to ${ipAddress}:${port} after timeout expired. Disconnecting...`);
                    this.disconnect();
                } else {
                    if (this.socket) {
                        NodeTcpSocket.logEvent(`Socket ${this.socketId} connected to ${ipAddress}:${port}`);
                        this.socket.removeListener("error", onConnectionError);
                        resolve();
                    }
                }
            }

            let onConnectionError = () => {
                clearTimeout(timeoutHandle);

                this.socket?.removeListener("connect", onConnectionSuccess);
                reject(-1);
            }

            this.socket.once("connect", onConnectionSuccess);
            this.socket.once("error", onConnectionError);

            this.socket.connect({
                host: ipAddress,
                port: port,
            });

            let timeoutHandle = setTimeout(timeoutFunc, timeout);
        })
    }

    public send(data: Uint8Array): Promise<void> {
        return new Promise((resolve) => {
            if (!this.socket) {
                throw 'SOCKET_NOT_OPEN';
            }

            this.socket.write(data, () => {
                NodeTcpSocket.logEvent(`Sent packet to socket ${this.socketId}:`, TilbyTcpSocketUtils.ab2Hex(data));
                resolve();
            })
        })
    }

    public async disconnect(): Promise<void> {
        return new Promise((resolve) => {
            if (!this.socket) {
                throw 'SOCKET_NOT_OPEN';
            }

            if (!this.socket?.remoteAddress || this.socket?.destroyed) {
                return resolve();
            }

            this.socket.end(() => {
                NodeTcpSocket.logEvent(`Socket ${this.socketId} disconnected`);
                resolve();
            });
        });
    }

    public async close(): Promise<void> {
        if (!this.socket) {
            throw 'SOCKET_NOT_OPEN';
        }

        //Disconnect if the socket is still connected
        if (this.socket.remoteAddress) {
            await this.disconnect();
        }

        //Complete subjects
        this.onDataSubject.complete();
        this.onErrorSubject.complete();

        //Destroy Socket
        this.socket.destroy();
        NodeTcpSocket.logEvent(`Socket ${this.socketId} closed`);

        //Cleanup
        this.socket = undefined;
    }
}
