import {
    Injectable,
    inject,
} from "@angular/core";

import {
    v4 as generateUuid
} from 'uuid';

import {
    ExitPrintRecord,
    OrderSendStatus,
    PendingPrint,
    SalesCashregister,
    VariationPrintRecord,
    VariationSale,
    VariationSaleItem,
} from "src/app/shared/model/cashregister.model";

import {
    Printers,
    SaleTransactions,
    Sales,
    SalesItems
} from "tilby-models";

import {
    ActiveSaleStoreService,
    SaleTransactionUtilsService
} from "src/app/features/cashregister";

import {
    documentPrinter,
    noFiscalPrinters,
    operatorManager,
} from "app/ajs-upgraded-providers";

import { OperatorManagerService } from "app/modules/core/service/operator-manager/operator-manager";

import {
    ConfigurationManagerService,
    EntityManagerService,
    StorageManagerService
} from "src/app/core";

import {
    groupBy,
    keyBy
} from "src/app/shared/utils";

import {
    TilbyDatePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-date";

@Injectable({
    providedIn: "root"
})
export class SalePrintingUtilsService {
    private readonly activeSaleStore = inject(ActiveSaleStoreService);
    private readonly saleTransactionUtils = inject(SaleTransactionUtilsService);
    private readonly entityManagerService = inject(EntityManagerService)
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly documentPrinter = inject(documentPrinter);
    private readonly NoFiscalPrinters = inject(noFiscalPrinters);
    private readonly operatorManagerService: OperatorManagerService = inject(operatorManager);
    private readonly storageManagerService = inject(StorageManagerService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);

    public getPrintErrors (results: OrderSendStatus[]) {
        return results.filter(res => !['PRINTED', 'EMPTY_ORDER'].includes(res.result));
    }

    private async saveFailedOrderSends (results: OrderSendStatus[], record: (ExitPrintRecord | VariationPrintRecord)) {
        const channels = await this.entityManagerService.channels.fetchCollectionOffline();
        const channelsById = keyBy(channels, (c) => c.id);

        //Find failed sends
        const failedSends = this.getPrintErrors(results).filter(res => ['nonfiscal', 'receipt'].includes(res.printer.type));
        const now = new Date().toISOString();

        const recordsToAdd: PendingPrint[] = [];

        //Map failed sends
        for(const res of failedSends) {
            //Get clean printer
            const printer = await this.entityManagerService.printers.fetchOneOffline(res.printer.id!);
            let recordName = record.sale.name;

            if(record.sale.table_id) {
                recordName = `${record.sale.room_name} - ${record.sale.table_name}`;
            } else if (['take_away', 'delivery'].includes(record.sale.order_type || '')) {
                recordName = `${(channelsById[record.sale.channel!]?.name || '')} - ${this.tilbyDatePipe.transform(record.sale.deliver_at)} (#${record.sale.external_id})`;
            }
            
            recordsToAdd.push({
                ...record,
                attempts: 1,
                first_error_at: now,
                id: generateUuid(),
                name: recordName,
                last_error_at: now,
                printer: printer || res.printer,
            });
        }

        // Return if there are no failed sends
        if(!recordsToAdd.length) {
            return;
        }

        //Save failed sends
        await this.storageManagerService.saveCollection('pending_prints', recordsToAdd);
    }

    public async retryPendingPrint(pendingRecord: PendingPrint, alternativePrinter?: Printers) {
        const record = structuredClone(pendingRecord);
        const targetPrinter = structuredClone(pendingRecord.printer);
        
        if(alternativePrinter) {
            //Assign connection and driver data to target printer
            const { name, driver, ip_address, port, connection_type, ssl } = alternativePrinter;

            Object.assign(targetPrinter, { name, driver, ip_address, port, connection_type, ssl });
        }

        let results: OrderSendStatus[] = [];

        const reprintInfo = {
            reprint_source: record.printer.name,
            reprint_new: targetPrinter.name
        };

        if('variation' in record && record.variation) {
            Object.assign(record.variation, { reprint_info: reprintInfo });
        }

        Object.assign(record.sale, { reprint_info: reprintInfo });

        switch(record.print_type) {
            case 'exit':
                results = await this.sendExitToPrinterCore(record.sale, record.exit, [ targetPrinter ]);
                break;
            case 'variation':
                results = await this.sendTransactionsCore(record.sale, record.variation, [ targetPrinter ], record.options);
                break;
        }

        if(this.getPrintErrors(results).length) {
            this.storageManagerService.updateOne('pending_prints', record.id,  {
                attempts: record.attempts + 1,
                last_error_at: new Date().toISOString()
            });
        } else {
            this.storageManagerService.deleteOne('pending_prints', record.id);
        }
    }

    private async sendTransactionsCore(sale: Sales, variationSale: VariationSale | null, printers?: Printers[], options?: any): Promise<OrderSendStatus[]> {
        let results: OrderSendStatus[] = [];

        if (variationSale?.variation) {
            //Send the variation sale to the printers
            results = await this.NoFiscalPrinters.printVariationSale(variationSale, sale, printers);
        } else {
            results = await this.NoFiscalPrinters.printSale(sale, options, printers);
        }

        return results;
    }

    public async sendSaleAsOrder(sale: Sales, options?: any, printers?: Printers[]): Promise<OrderSendStatus[]> {
        const saleToSend = structuredClone(sale);

        Object.assign(saleToSend, { printed_at: new Date().toISOString() });
        await this.applyItemsInfo(saleToSend);

        const results = await this.NoFiscalPrinters.printSale(saleToSend, options, printers);

        // Add errors to the print errors store
        await this.saveFailedOrderSends(results, { print_type: 'variation', sale: saleToSend, variation: null, options: options });

        return results;
    }

    /**
     * Applies items information that are not present in sale_items or variation_items (e.g. order_name) to the target (target is modified in place)
     * 
     * @param {Sales | VariationSale} target
     */
    private async applyItemsInfo(target: Sales | VariationSale) {
        let targetArray: SalesItems[] | VariationSaleItem[] = [];

        if ('variation_items' in target) {
            targetArray = target.variation_items || [];
        } else if ('sale_items' in target) {
            targetArray = target.sale_items || [];
        }

        const itemIds = targetArray.filter(i => i.item_id).map(i => i.item_id!);

        if (!itemIds.length) {
            return;
        }

        const itemsMap = await this.entityManagerService.items.fetchMapFromIdsOffline(itemIds);

        for (const item of targetArray) {
            if (item.item_id) {
                const itemInfo = itemsMap[item.item_id];

                if (itemInfo) {
                    Object.assign(item, {
                        order_name: itemInfo.order_name,
                    });
                }
            }
        }
    }

    /**
     * Sends the sale transactions to the order printers
     * 
     * @param {string} saleUuid the uuid of the sale
     * @return {OrderSendStatus[]} the results of the order sending
     */
    public async sendTransactions(saleUuid: string): Promise<OrderSendStatus[]> {
        const [saleTransactions, sale] = await this.activeSaleStore.loadSale(saleUuid, { returnBuiltSale: true, returnRawBuildSale: true });

        if (!saleTransactions || !sale) {
            return [];
        }

        const variationSale = this.saleTransactionUtils.buildVariationSalesFromTransactions(sale, saleTransactions, { mergeTransactions: true })[0];

        // mark the transactions as printed
        await this.saleTransactionUtils.bulkUpdatePendingTransactions(saleTransactions, { printed: true, skip_printing: false });

        if(!variationSale?.variation_items?.length) {
            return [];
        }

        //Prepare and cleanup the sale (must be done after variationSale building)
        this.saleTransactionUtils.prepareSale(sale);
        await this.applyItemsInfo(sale);
        await this.applyItemsInfo(variationSale);

        const now = new Date().toISOString();

        Object.assign(variationSale, { printed_at: now });
        Object.assign(sale, { printed_at: now });

        // Send the variation/sale to the printers
        const results = await this.sendTransactionsCore(sale, variationSale);

        // Add errors to the print errors store
        await this.saveFailedOrderSends(results, { print_type: 'variation', sale: sale, variation: variationSale });

        return results;
    }

    private async updateSale(sale: Sales, updateData: Partial<Sales>, transactionOptions?: Pick<SaleTransactions, 'printed' | 'skip_printing'>) {
        const opData = this.operatorManagerService.getOperatorData();

        //Create update transaction
        const saleTransaction = this.saleTransactionUtils.createSaleTransaction(sale, opData);
        saleTransaction.sale_details = updateData;

        //Save transaction
        await this.saleTransactionUtils.saveTransaction({ ...saleTransaction, ...transactionOptions });
    }


    private async sendExitToPrinterCore(sale: Sales, exit: number, printers?: Printers[], isReprint: boolean = false): Promise<OrderSendStatus[]> {
        //Create a copy of the order for the print function
        const { seller_id, seller_name, sale_items, order_type, ...orderToSend } = structuredClone(sale);

        Object.assign(orderToSend, {
            operator_id: seller_id,
            operator_name: seller_name,
            order_items: sale_items,
            reprint: isReprint,
            type: order_type,
        });

        //Send the order to the printer
        const results = await this.NoFiscalPrinters.printGoExit(orderToSend, exit, printers) as OrderSendStatus[];

        return results;
    }

    public async sendExitToPrinter(sale: Sales, exits: number | number[], options?: { batchMode?: boolean }): Promise<OrderSendStatus[]> {
        if (!sale || !exits) {
            return [];
        }

        //If exits is a single number, convert it to an array
        if(!Array.isArray(exits)) {
            exits = [exits];
        }

        //Create a copy of the order for the print function
        const saleToSend = Object.assign(structuredClone(sale), { printed_at: new Date().toISOString() });
        await this.applyItemsInfo(saleToSend);

        const sentExits = saleToSend.sent_exits || {};
        const finalResults: OrderSendStatus[] = [];

        const itemsByExit = groupBy(saleToSend.sale_items || [], si => si.exit);
        let saleNeedsUpdate = false;

        //In batch mode, send all unsent exits that have items
        if(options?.batchMode && !exits.length) {
            exits = Object.keys(itemsByExit).map(exit => parseInt(exit)).filter(exit => exit);
        }

        //Deduplicate exits
        exits = [...new Set(exits)];

        //In batch mode, if all items are on exit 1, send the whole order instead
        if(
            options?.batchMode &&
            exits.length === 1 &&
            exits[0] === 1 &&
            !itemsByExit['null'] &&
            !sentExits[1]
        ) {
            const results = await this.sendSaleAsOrder(saleToSend);
            finalResults.push(...results);

            sentExits[1] = true;
            saleNeedsUpdate = true;
            exits = []; //Disables the next for loop
        }

        for(const exit of exits) {
            const previousExitSendStatus = !!sentExits[exit];

            //If we are in batch mode, skip if the exit has already been sent or if there are no items for this exit
            if(options?.batchMode) {
                if(previousExitSendStatus || !itemsByExit[exit].length) {
                    continue;
                }
            }

            //Call core function
            const results = await this.sendExitToPrinterCore(saleToSend, exit, undefined, previousExitSendStatus);
            const currentExitSendStatus = this.getPrintErrors(results).length === 0;

            //If the printing was successful or we are in batch mode, mark the exit as sent if it was not sent before
            if(!previousExitSendStatus && (options?.batchMode || currentExitSendStatus)) {
                sentExits[exit] = true;
                saleNeedsUpdate = true;
            }

            // Add errors to the print errors store
            await this.saveFailedOrderSends(results, { print_type: 'exit', sale: saleToSend, exit: exit, isReprint: previousExitSendStatus });

            finalResults.push(...results);
        }

        if(options?.batchMode) {
            await this.saleTransactionUtils.bulkUpdateSalePendingTransactions(saleToSend.uuid!, { printed: true });
        }

        //If the exit send was successful, mark it as sent (only if it wasn't sent before)
        if (saleNeedsUpdate) {
            this.updateSale(saleToSend, { sent_exits: sentExits }, { printed: true });
        }

        return finalResults;
    }

    public async printNonFiscalSale(saleToPrint: Sales, printer?: number | Printers) {
        let targetPrinter: Printers | undefined;

        if (typeof printer === 'number') {
            targetPrinter = await this.entityManagerService.printers.fetchOneOffline(printer);
        } else {
            targetPrinter = printer;
        }

        if (!targetPrinter) {
            const defaultPrinterId = parseInt(this.configurationManagerService.getPreference('cashregister.nonfiscalsale_default_printer') || '');

            if (defaultPrinterId) {
                targetPrinter = await this.entityManagerService.printers.fetchOneOffline(defaultPrinterId);
            }
        }

        if (!targetPrinter) {
            return;
        }

        await this.documentPrinter.printNonFiscalSale(saleToPrint, targetPrinter);

        const updateData: Partial<SalesCashregister> = {
            printed_prebills: (saleToPrint.printed_prebills || 0) + 1
        }

        if (this.configurationManagerService.getPreference('cashregister.lock_sale_after_prebill') && !saleToPrint.bill_lock) {
            updateData.bill_lock = true;
        }

        this.updateSale(saleToPrint, updateData, { printed: true });
    }
}