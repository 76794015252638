<ng-container *ngIf="!hide">
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)" class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downloadFile()">
            <span>{{ 'FILE_EXPORTER.SHOWCASE.DOWNLOAD_FILE' | translate }}</span>
        </button>
        <button mat-menu-item (click)="deleteFile()">
            <span>{{ 'FILE_EXPORTER.SHOWCASE.DELETE_FILE' | translate }}</span>
        </button>
    </mat-menu>
</ng-container>