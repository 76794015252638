<tilby-dialog-toolbar mat-dialog-title
    title="DIALOG.SELECT_THEME_DIALOG.TITLE"
    [hideConfirm]="true"></tilby-dialog-toolbar>
<mat-dialog-content>
    <mat-action-list>
        <button mat-list-item *ngFor="let theme of themes" (click)="changeTheme(theme)" [activated]="theme === selectedTheme">
            <span translate>DIALOG.SELECT_THEME_DIALOG.{{theme}}_THEME</span>
        </button>
    </mat-action-list>
</mat-dialog-content>
