
<div class="tw-min-h-full tw-flex tw-flex-col tw-justify-between">
    <div class="sidenav-itemlist tw-flex tw-flex-col tw-items-start">
        <section class="tw-w-11/12 tw-mx-auto">
            @if(!isConnectionOffline()) {
                @if(showLink('dashboard')) {
                    <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('dashboard')}" (click)="goTo.emit(showNewFeature('dashboard') ? 'new.dashboard' : 'dashboard')">
                        <mat-icon alt="">store</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.DASHBOARD</span>
                    </button>
                }
                @if(showLink('bookings')) {
                    <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('bookings')}" (click)="goTo.emit('bookings.showcase')">
                        <mat-icon>event</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.BOOKINGS</span>
                    </button>
                }
                @if(showLink('kiosk')) {
                    <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('kiosk')}" (click)="goToKiosk()">
                        <mat-icon alt="">accessibility_new</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.KIOSK</span>
                    </button>
                }
            }
            @if(showLink('tables')) {
                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('tables')}" (click)="goTo.emit(showNewFeature('tables_and_cashregister') ? 'new.tables' : 'tables.rooms-view')">
                    <mat-icon>view_quilt</mat-icon>
                    <span class="sidebar-text" translate>SIDENAV.MODULES.TABLES</span>
                </button>
            }
            @if(showLink('orders') && !showNewFeature('tables_and_cashregister')) {
                <button mat-button class="mat-button-sidebar full-size" [ngClass]="{'nav-button-active':isCurrentRoute('orders.content')}" (click)="goTo.emit('orders.content')">
                    <mat-icon>event_note</mat-icon>
                    <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
                        <span class="sidebar-text" translate>SIDENAV.MODULES.ORDERS</span>
                        @if(ordersMan.getExternalOrdersCount()) {
                            <div class="sidenav-orders-badge">{{ordersMan.getExternalOrdersCount()}}</div>
                        }
                    </div>
                </button>
            }
            @if(showLink('cashregister')) {
                <button mat-button class="mat-button-sidebar full-size" [ngClass]="{'nav-button-active':isCurrentRoute('cashregister')}" (click)="goTo.emit(showNewFeature('tables_and_cashregister') ? 'new.cashregister.content.showcase' : 'cashregister.content.showcase')">
                    <mat-icon>keyboard</mat-icon>
                    <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
                        <span class="sidebar-text" translate>SIDENAV.MODULES.CASHREGISTER</span>
                        @if(salesMan.getExternalSalesCount()) {
                            <div class="sidenav-sales-badge">{{salesMan.getExternalSalesCount()}}</div>
                        }
                    </div>
                </button>
            }
            @if(!isConnectionOffline()) {
                @if(showLink('history')) {
                    <button #history mat-button class="mat-button-sidebar" (click)="toggleSubmenu($any(history),'history')">
                        <mat-icon>settings_backup_restore</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.HISTORY_MENU</span>
                    </button>
                    @if(submenus.history) {
                        <div class="mat-sub-button-sidebar tw-flex tw-flex-col tw-items-start">
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('history.sales')}"
                                    (click)="goTo.emit('new.history.sales')">
                                <mat-icon>receipt</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.HISTORY.SALES</span>
                            </button>
                            @if(country === 'IT') {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('history.e_invoices')}" (click)="goTo.emit('history.e_invoices')">
                                    <mat-icon>file_copy</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.HISTORY.EINVOICES</span>
                                </button>
                            }
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('new.history.cash-movements')}" (click)="goTo.emit('new.history.cash-movements')">
                                <mat-icon>swap_vertical_circle</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.HISTORY.CASH_MOVEMENTS</span>
                            </button>
                        </div>
                    }
                }
                @if(showLink('stock') || showLink('suppliers')) {
                    <button #warehouse mat-button class="mat-button-sidebar" (click)="toggleSubmenu($any(warehouse),'stock')">
                        <mat-icon>widgets</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.WAREHOUSE_MENU</span>
                    </button>
                    @if(submenus.stock) {
                        <div class="mat-sub-button-sidebar tw-flex tw-flex-col tw-items-start">
                            @if(showLink('stock')) {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('stock.management')}" (click)="goTo.emit('stock.management')">
                                    <mat-icon>content_paste</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.WAREHOUSE.STOCK</span>
                                </button>
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('stock.movements')}" (click)="goTo.emit('stock.movements')">
                                    <mat-icon>import_export</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.WAREHOUSE.MOVEMENTS</span>
                                </button>
                            }
                            @if(showLink('suppliers')) {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('suppliers')}"
                                        (click)="goTo.emit('new.suppliers')">
                                    <mat-icon>local_shipping</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.WAREHOUSE.SUPPLIERS</span>
                                </button>
                            }
                            @if(country === 'IT') {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('history.e_invoices_passive')}" (click)="openPassiveInvoicePortal()">
                                    <mat-icon>receipt</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.WAREHOUSE.PASSIVE_EINVOICES</span>
                                </button>
                            }
                        </div>
                    }
                }
                @if(showLink('items')) {
                    <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('items')}" (click)="goTo.emit('items.showcase')">
                        <mat-icon>layers</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.ITEMS</span>
                    </button>
                }
                @if(showLink('customers')) {
                    <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('customers')}"
                            (click)="goTo.emit('new.customers.showcase')">
                        <mat-icon>group</mat-icon>
                        <span class="sidebar-text" translate>SIDENAV.MODULES.CUSTOMERS</span>
                    </button>
                }
                <button #marketing mat-button class="mat-button-sidebar"(click)="toggleSubmenu($any(marketing),'marketing')">
                    <mat-icon>rocket_launch</mat-icon>
                    <span class="sidebar-text" translate>SIDENAV.MODULES.MARKETING_MENU</span>
                </button>
                @if(submenus.marketing) {
                    <div class="mat-sub-button-sidebar tw-flex tw-flex-col tw-items-start">
                        <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('fidelity')}" (click)="openPromotedModule('fidelity')">
                            <mat-icon>card_giftcard</mat-icon>
                            <span class="sidebar-text" translate>SIDENAV.MODULES.FIDELITY</span>
                        </button>
                        @if(showLink('promotions')) {
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('promotions')}" (click)="openPromotedModule('promotions')">
                                <mat-icon>stars</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.PROMOTIONS</span>
                            </button>
                        }
                        <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('giftcard')}" (click)="openPromotedModule('giftcard')">
                            <mat-icon>card_membership</mat-icon>
                            <span class="sidebar-text" translate>SIDENAV.MODULES.GIFTCARD</span>
                        </button>
                    </div>
                }

                <button #settings mat-button class="mat-button-sidebar" (click)="toggleSubmenu($any(settings),'settings')">
                    <mat-icon>settings</mat-icon>
                    <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS_MENU</span>
                </button>
                @if(submenus.settings) {
                    <div class="mat-sub-button-sidebar tw-flex tw-flex-col tw-items-start">
                        @if(showLinkUserEnable('settings.enabled')) {
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.general')}" (click)="goTo.emit('settings.general')">
                                <mat-icon>settings_applications</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.GENERAL</span>
                            </button>
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.channels_management')}"
                                (click)="goTo.emit('new.settings.channels_management')">
                                <mat-icon>merge_type</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.CHANNELS_MANAGEMENT</span>
                            </button>
                        }
                        @if(gloryPaymentMethod){
                            <button mat-button class="mat-button-sidebar" (click)="execGloryApp()">
                                <mat-icon>payments</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.GLORY_SETTINGS</span>
                            </button>
                        }
                        @if(paymentMethodsCashmatic.length > 0){
                            <button mat-button class="mat-button-sidebar" (click)="openCashmaticBackoffice()">
                                <mat-icon>payments</mat-icon>
                                <span class="sidebar-text" translate>Cashmatic</span>
                            </button>
                        }
                        @if(showLink('file_importer')) {
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('file_importer')}" (click)="goTo.emit(showNewFeature('settings.file_importer') ? 'new.file_importer.showcase' : 'file_importer.showcase')">
                                <mat-icon>file_upload</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.FILE_IMPORTER</span>
                            </button>
                        }
                        <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('file_exporter')}" (click)="goTo.emit('new.file_exporter.showcase')">
                            <mat-icon>file_download</mat-icon>
                            <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.FILE_EXPORTER</span>
                        </button>
                        <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.my_subscription')}" (click)="goTo.emit(showNewFeature('settings.my_subscription') ? 'new.settings.my_subscription' : 'settings.my_subscription')">
                            <mat-icon>monetization_on</mat-icon>
                            <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.MY_SUBSCRIPTION</span>
                        </button>
                        <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.users_management')}" (click)="goTo.emit('new.settings.users_management')">
                            <mat-icon>people</mat-icon>
                            <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.USERS_MANAGEMENT</span>
                        </button>
                        @if(showLink('printers')) {
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('printers')}" (click)="goTo.emit('printers')">
                                <mat-icon>print</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.PRINTERS</span>
                            </button>
                        }
                        @if(cashlogyPaymentMethod) {
                            <button mat-button class="mat-button-sidebar" (click)="openCashlogyBackoffice()">
                                <mat-icon>local_atm</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.CASHLOGY</span>
                            </button>
                        }
                        @if(cimaPaymentMethod) {
                            <button mat-button class="mat-button-sidebar" (click)="openCimaBackoffice()">
                                <mat-icon>local_atm</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.CIMA</span>
                            </button>
                        }
                        @if(vnePaymentMethod) {
                            <button mat-button class="mat-button-sidebar" (click)="openVneBackoffice()">
                                <mat-icon>payments</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.VNE</span>
                            </button>
                        }
                        <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.batch')}" (click)="goTo.emit('settings.batch')">
                            <mat-icon>low_priority</mat-icon>
                            <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.BATCH</span>
                        </button>
                        @if(showLinkUserEnable('settings.enabled')) {
                            @if(showLink('kiosk')) {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.kiosk')}" (click)="goTo.emit(showNewFeature('settings.kiosk') ? 'new.settings.kiosk' : 'settings.kiosk')">
                                    <mat-icon>aod</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.KIOSK_SETTINGS</span>
                                </button>
                            }
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.shifts')}" (click)="goTo.emit(showNewFeature('settings.shifts') ? 'new.settings.shifts' : 'settings.shifts')">
                                <mat-icon>date_range</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.SHIFTS</span>
                            </button>
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.label_panel')}" (click)="goTo.emit('new.settings.label_panel')">
                                <mat-icon>style</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.LABEL_PANEL</span>
                            </button>
                            <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.model_document')}" (click)="goTo.emit('new.settings.model_document')">
                                <mat-icon>style</mat-icon>
                                <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.MODEL_DOCUMENT</span>
                            </button>
                            @if(showLink('c_sito_base')) {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.e_commerce')}" (click)="goToEcommerceSettings()">
                                    <mat-icon>web</mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.E_COMMERCE_SETTINGS</span>
                                </button>
                            }
                            @if(showLink('alvolo_menu')) {
                                <button mat-button class="mat-button-sidebar" [ngClass]="{'nav-button-active':isCurrentRoute('settings.alvolo')}" (click)="goTo.emit('new.settings.alvolo')">
                                    <mat-icon svgIcon="{{ themeModeService.isDarkTheme ? 'alvolo-dark' : 'alvolo' }}"></mat-icon>
                                    <span class="sidebar-text" translate>SIDENAV.MODULES.SETTINGS.ALVOLO</span>
                                </button>
                            }
                        }
                    </div>
                }
                <mat-divider class="tw-w-full"/>
                @if(showSupport()) {
                    <button mat-button class="mat-button-sidebar" (click)="openSupport()">
                        <mat-icon>help</mat-icon>
                        <span class="sidebar-text after:tw-font-materialIcons after:tw-content-['launch'] after:tw-ml-3 after:tw-text-lg after:tw-align-sub" translate>SIDENAV.HELP_SUPPORT</span>
                    </button>
                }
                @if(isAnalyticsEnabled()) {
                    <button mat-button class="mat-button-sidebar" (click)="openAnalytics()">
                        <mat-icon>show_chart</mat-icon>
                        <span class="sidebar-text after:tw-font-materialIcons after:tw-content-['launch'] after:tw-ml-3 after:tw-text-lg after:tw-align-sub" translate>SIDENAV.ANALYTICS</span>
                    </button>
                }
                @if(showPurchaseInfo()) {
                    <button mat-button class="mat-button-sidebar" (click)="openPurchaseInfo()">
                        <mat-icon>sentiment_satisfied_alt</mat-icon>
                        <span class="sidebar-text after:tw-font-materialIcons after:tw-content-['launch'] after:tw-ml-3 after:tw-text-lg after:tw-align-sub" translate>SIDENAV.PURCHASE_INFO</span>
                    </button>
                }
            }
        </section>
    </div>
</div>

