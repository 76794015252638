import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('items').controller('ComponentsCtrl', ["$scope", "$translate", "$filter", "user", "components", "categories", "entityManager", "confirmDialog", "alertDialog", function($scope, $translate, $filter, user, components, categories, entityManager, confirmDialog, alertDialog) {
    $scope.user = user;

    $scope.topbar_context = {
        showcaseView: 'crudviewmode',
        back_label: $translate.instant('ITEMS.COMPONENTS.PRODUCTS'),
        current_label: $translate.instant('ITEMS.COMPONENTS.COMPONENTS')
    };

    $scope.saveInProgress = false;

    $scope.components = components;
    $scope.pristineComponent = null;
    $scope.searchText = '';

    $scope.canSave = function() {
        return !angular.equals($scope.component, $scope.pristineComponent);
    };

    var parseComponentCategories = function() {
        $scope.pristineComponent._categories = [];

        var componentCategoriesById = $scope.pristineComponent.categories ? _.keyBy($scope.pristineComponent.categories, 'id') : {};

        _.forEach(categories, function(category) {
            var c = _.assign(_.pick(category, ['id', 'name']), {
                _enabled: !_.isNil(componentCategoriesById[category.id])
            });
            
            $scope.pristineComponent._categories.push(c);
        });

        $scope.pristineComponent._allCategories = _.every($scope.pristineComponent._categories,  { _enabled: true });
    };

    $scope.afterSelectComponent = function() {
        parseComponentCategories();
        $scope.component = _.cloneDeep($scope.pristineComponent);
    };

    // The first component is selected by default, can be changed to undefined and {}
    var selectDefaultComponent = function() {
        $scope.pristineComponent = _.isEmpty($scope.components) ? {} : _.head($scope.components);

        $scope.afterSelectComponent();
    };

    selectDefaultComponent();

    $scope.selectAllCategories = function() {
        _.forEach($scope.component._categories, function(category) {
            category._enabled = $scope.component._allCategories;
        });
    };

    $scope.deleteComponent = function() {
        if (!$scope.component.id) {
            return;
        }

        confirmDialog.show($translate.instant('ITEMS.COMPONENTS.WANT_TO_DELETE')).then(function(answer) {
            if (answer) {
                entityManager.components.deleteOneOnline($scope.component.id, true).then(function(success) {
                    _.remove($scope.components, {
                        id: success
                    });

                    selectDefaultComponent();
                }, function(error) {
                    alertDialog.show($translate.instant('ITEMS.COMPONENTS.ERROR_WHILE_DELETING'));
                });
            }
        });
    };

    $scope.clearSearchText = function() {
        $scope.searchText = '';
        $scope.components = components;
    };

    $scope.filterComponents = function() {
        var searchWords = _.words($scope.searchText);
        $scope.components = components;
        _.forEach(searchWords,function(word) {
            $scope.components = $filter('filter')($scope.components, word);
        });
    };

    $scope.addNewComponent = function() {
        $scope.pristineComponent = {};
        $scope.afterSelectComponent();
    };

    $scope.saveEdit = function() {
        if (!$scope.saveInProgress) {
            var result = _.find($scope.components, function(component) {
                return component.name === $scope.component.name && component.id !== $scope.component.id;
            });

            if (result) {
                alertDialog.show($translate.instant('ITEMS.COMPONENTS.COMPONENT_ALREAD_EXISTING'));
                return;
            }

            // Send to the BE
            var entityToBE = _.clone($scope.component);

            entityToBE.categories = _.filter(entityToBE._categories, { _enabled: true });

            if (entityToBE.id) {
                // Do a PUT
                $scope.saveInProgress = true;

                entityManager.components.putOneOnline(entityToBE).then(function(success) {
                    angular.copy(success, $scope.pristineComponent);
                    $scope.afterSelectComponent();
                }, function(error) {
                    // Show error dialog
                    alertDialog.show($translate.instant('ITEMS.COMPONENTS.ERROR_TRY_LATER'));
                }).finally(function() {
                    $scope.saveInProgress = false;
                });
            } else {
                // Do a POST
                $scope.saveInProgress = true;

                entityManager.components.postOneOnline(entityToBE).then(function(success) {
                    $scope.components.push(success);
                    $scope.pristineComponent = success;
                    $scope.afterSelectComponent();
                }, function(error) {
                    // Show error dialog
                    alertDialog.show($translate.instant('ITEMS.COMPONENTS.ERROR_TRY_LATER'));
                }).finally(function() {
                    $scope.saveInProgress = false;
                });
            }
        }
    };

    $scope.abortEdit = function() {
        if ($scope.component.id) {
            // Update the (form) view
            $scope.afterSelectComponent();
        } else {
            selectDefaultComponent();
        }
    };
}]);
