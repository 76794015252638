import { OnInit, Input, Output, EventEmitter, Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import {ShopChain, User} from "src/app/models";
import { CustomersFields } from "../../customer-form/customers-form.model";
import { CustomersPrepaidCreditForm, CustomersPrepaidCreditFormGroups } from "./custumer-prepaid-credit-form.model";
import {EntityManagerService} from "src/app/core/services/entity/entity-manager.service";
import { restManager } from "app/ajs-upgraded-providers";

@Component({
    selector: 'app-customer-prepaid-credit-form',
    templateUrl: './customer-prepaid-credit-form.component.html',
    styleUrls: ['./customer-prepaid-credit-form.component.scss'],
    host: {style: 'width:100%'},
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerPrepaidCreditFormComponent implements OnInit {

    prepaidCreditForm?: FormGroup<CustomersPrepaidCreditFormGroups>;

    @Input() user?: User;
    @Input() customer?: CustomersFields;
    @Input() credit = 0;
    @Input() chainShops?: ShopChain[];

    selectedCampaign = null;

    @Output() submit = new EventEmitter<FormGroup<CustomersPrepaidCreditFormGroups>>();

    constructor(private entityManager: EntityManagerService,
                @Inject(restManager) private restManager: any) {
    }

    ngOnInit(): void {
        this.createForm();
        this.initChainShops();
    }

    createForm() {
        this.prepaidCreditForm = new FormGroup<CustomersPrepaidCreditFormGroups>({
            customersPrepaidCreditForm: new FormGroup<CustomersPrepaidCreditForm>({
                date_since: new FormControl(null),
                date_max: new FormControl(null),
                db_name: new FormControl(null)
            })
        })
    }

    initChainShops() {
            this.prepaidCreditForm?.patchValue({customersPrepaidCreditForm: {'db_name': '-1'}});
    }

    onCancelDateRange() {
        this.prepaidCreditForm?.controls['customersPrepaidCreditForm'].controls['date_since']?.setValue(null);
        this.prepaidCreditForm?.controls['customersPrepaidCreditForm'].controls['date_max']?.setValue(null);
        this.submit.emit(this.prepaidCreditForm);
    }
}
