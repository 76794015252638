import {Inject, Injectable} from "@angular/core";
import {RootScope} from "app/ajs-upgraded-providers";
import { User } from "src/app/models";

@Injectable({
    providedIn: 'root'
})
export class UserActiveSessionManagerService {
    private activeSession?: User;

    constructor(
        @Inject(RootScope) public rootScope: any
    ) {
    }

    /**
     * Sets the active session
     * @param session The session to set as active
     */
    public setSession (session: User) {
        this.activeSession = session;
        //Set the session in the rootScope for backward compatibility
        this.rootScope.userActiveSession = session;
    }

    /**
     * Unsets the active session
     */
    public unsetSession () {
        this.activeSession = undefined;
        //Set the session in the rootScope for backward compatibility
        this.rootScope.userActiveSession = undefined;
    }

    /**
     * Get the active session
     * @returns The active session or undefined if none is set.
     */
    public getSession (): User | undefined {
        //Return a copy of session
        return this.activeSession ? structuredClone(this.activeSession) : undefined;
    }
}

// End migration AngularJs to Angular replace or delete global/globalUserActiveSession
