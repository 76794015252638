import * as angular from 'angular';
import {EntityManagerService} from "../../../src/app/core/services";

angular.module('dashboardNew', ['application']);

angular.module('dashboardNew').config(["$stateProvider", function($stateProvider: any) {

    $stateProvider.state('app.new.dashboard', {
        url: '/dashboard',
        template: '<dashboard [deliverychannels]="$resolve.deliveryChannels"></dashboard>',
        params: {
          remove: {dynamic: true, value: null}
        },
        resolve: {
            deliveryChannels: ["entityManager", function(entityManagerService: EntityManagerService) {
                return entityManagerService.channels.fetchCollectionOffline();
            }]
        }
    });
}]);
