import * as angular from 'angular';
import * as _ from 'lodash';
import { validate as validateUuid, v4 as generateUuid } from 'uuid';
import {SalesPayments} from "tilby-models";

type nexiMPOSResponse = {
    amount: Number,
    acquirerId: string,
    acquirerName: string
    actionCode: string,
    authorizationNumber: string,
    callerTrxId: string,
    cardTypeCVM: string,
    operationNumber: string,
    operationType: string,
    pan: string,
    result: Number,
    resultDescription: string,
    stan: string,
    terminalId: string,
    timestamp: string,
    transactionType: string,
}

type salePayment = {
    acquirer_id: string
    acquirer_name: string
    card_circuit_name: string
    payment_data: string
    tail: string
    unclaimed: Boolean
}

class NexiMobilePos {
    constructor(
        private $rootScope: any,
        private $translate: any,
        private entityManager: any,
        private waitDialog: any
    ) {
    }

    private nexiMPOSStatusCodes: Record<string, string> = {
        "0": "TRANSACTION_APPROVED",
        "9": "POS_NOT_CONNECTED",
        "20": "TRANSACTION_CANCELED",
        "21": "TRANSACTION_DENIED",
        "-1": "INVALID_INPUT_PARAMETERS",
        "-2": "DEVICE_NOT_SECURE",
        "-3": "BLUETOOTH_OFF",
        "-4": "SERVICES_NOT_AVAILABLE",
        "-5": "DEPRECATED_APP",
        "-6": "LOGIN_REQUIRED",
        "-7": "TERMINALID_SUSPENDED",
        "-8": "TERMINALID_ABSENT",
        "-9": "TERMINALID_NOT_CONFIGURED_ON_POS",
        "-10": "POS_CONNECTION_TIMEOUT",
        "-11": "TRANSACTION_NOT_REVERSIBLE"
    }

    private handleNexiMPOSError(error: any): string {
        switch (typeof error) {
            case 'string':
                if(error === "CANCELED") {
                    error = "TRANSACTION_CANCELED";
                }

                return this.$translate.instant(`DIGITAL_PAYMENTS.NEXI_MPOS.${error}`);
            case 'object':
                let errorCode = this.nexiMPOSStatusCodes?.[error.result];
                let errorDescription = error.resultDescription;

                return errorCode ? this.$translate.instant(`DIGITAL_PAYMENTS.NEXI_MPOS.${errorCode}`) + (errorDescription ? ` (${errorDescription})` : ""): (errorDescription || 'UNKNOWN_ERROR');
            default:
                return 'UNKNOWN_ERROR';
        }
    }

    private getPaymentTail(transactionResponse: nexiMPOSResponse): string {
        const rowSize = 32;
        const tailFields: (keyof nexiMPOSResponse)[] = ['operationType', "transactionType", "timestamp", "acquirerId", "stan", "pan", "operationNumber", "actionCode", "authorizationNumber", "acquirerName"];
        let tailRows: string[] = [];

        for(let field of tailFields) {
            let rowField = _.chain(field).upperCase().value();
            let fieldNameSize = _.chain(rowField).size().value();

            tailRows.push(`${rowField}${transactionResponse[field].toString().padStart(rowSize - fieldNameSize)}`);
        }

        return tailRows.join('\n');
    };

    private getPaymentFromTransaction(transactionResponse: nexiMPOSResponse) : salePayment {
        return {
            acquirer_id: transactionResponse.acquirerId,
            acquirer_name: transactionResponse.acquirerName,
            card_circuit_name: "",
            payment_data: JSON.stringify(transactionResponse),
            tail: this.getPaymentTail(transactionResponse),
            unclaimed: false
        }
    }

    public async payment(amount: number, options: any) : Promise<salePayment> {
        let currentSale = options.sale;

        try {
            let transactionData = {
                amount: Math.round(amount * 100),
                callerTrxId: generateUuid(),
                addInfo1: this.$rootScope.userActiveSession.shop.name,
                addInfo2: currentSale.id ? currentSale.id.toString() : null
            };

            let transactionPromise = new Promise((resolve, reject) => {
                window.NexiMPOSPlugin.performPayment(transactionData, (result: any) => resolve(result), (error: any) => reject(error));
            });

            let transactionResponse: nexiMPOSResponse = await this.waitDialog.show({ message: this.$translate.instant('DIGITAL_PAYMENTS.NEXI_MPOS.COMPLETE_ON_APP'), promise: transactionPromise, cancelAction: {
                label: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CANCEL_PAYMENT')
            }, });

            if(transactionResponse.result !== 0) {
                throw transactionResponse;
            }

            return this.getPaymentFromTransaction(transactionResponse);
        } catch (error) {
            throw this.handleNexiMPOSError(error);
        } finally {
            window.NexiMPOSPlugin.cleanup();
        }
    }

    public async refund(amount: number, options: any) : Promise<salePayment> {
        let currentSale = options.sale;

        if(!currentSale) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let parentSaleId = currentSale.sale_parent_id || currentSale.sale_parent_uuid;

        if(!parentSaleId) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let parentSale;

        if(validateUuid(parentSaleId)) {
            let results = await this.entityManager.sales.fetchCollectionOnline({ uuid: parentSaleId });
            parentSale = results[0];
        } else {
            parentSale = await this.entityManager.sales.fetchOneOfflineFirst(parentSaleId);
        }

        if(!parentSale) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let nexiMPOSPayment = parentSale.payments?.find((salePayment: SalesPayments) => (salePayment.payment_method_type_id === 30));

        if(!nexiMPOSPayment) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let originalPayment: nexiMPOSResponse = JSON.parse(nexiMPOSPayment.payment_data);

        try {
            let transactionData = {
                amount: originalPayment.amount,
                callerTrxId: generateUuid(),
                timestamp: originalPayment.timestamp,
                terminalId: originalPayment.terminalId,
            };

            let transactionPromise = new Promise((resolve, reject) => {
                window.NexiMPOSPlugin.performReversal(transactionData, (result: any) => resolve(result), (error: any) => reject(error));
            });

            let transactionResponse: nexiMPOSResponse = await this.waitDialog.show({ message: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.COMPLETE_ON_APP'), promise: transactionPromise, cancelAction: {
                label: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CANCEL_PAYMENT')
            }, });

            if(transactionResponse.result !== 0) {
                throw transactionResponse;
            }

            return this.getPaymentFromTransaction(transactionResponse);
        } catch (error) {
            throw this.handleNexiMPOSError(error);
        } finally {
            window.NexiMPOSPlugin.cleanup();
        }
    }
}

NexiMobilePos.$inject = ["$rootScope", "$translate", "entityManager", "waitDialog"];

angular.module('digitalPayments').service('NexiMobilePos', NexiMobilePos);
