import * as angular from "angular";
import { CashdrawerPaymentDialog } from "../dialog/cashdrawer-payment-dialog/cashdrawer-payment-dialog";
import { VneCashdrawerDriver } from "./vne-cashdrawer-driver";
import { DigitalPaymentHandler } from "src/app/shared/model/digital-payments.model";
import _ from "lodash";
import { DocumentPrintHook, DocumentPrinterOptions } from "src/app/shared/model/document-printer.model";
import { Sales } from "tilby-models";
class VneCashdrawer implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private $translate: any,
        private $filter: any,
        private CashdrawerPaymentDialog: CashdrawerPaymentDialog,
        private VneCashdrawerDriver: VneCashdrawerDriver,
        private entityManager: any
    ) {
    }

    public async payment(amount: number, options: any) {
        let paymentMethod = options.paymentMethod;

        if (!paymentMethod.schema_name) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.SCHEMA_NAME_NOT_FOUND');
        }

        let response = await this.CashdrawerPaymentDialog.show(amount, options.paymentMethod, this.VneCashdrawerDriver, "Cassetto Vne");

        const transaction = JSON.parse(response.payment_data);

        let receiptTail: string[] = [];

        receiptTail.push("");
        receiptTail.push("Dettaglio pagamento");
        receiptTail.push(`ID transazione ${_.padStart((transaction.idTransaction), 20, ' ')}`);
        receiptTail.push(`Pagato ${_.padStart(this.$filter('sclCurrency')(transaction.paid), 25, ' ')}`);
        receiptTail.push(`Resto erogato ${_.padStart(this.$filter('sclCurrency')(transaction.changeGiven), 18, ' ')}`);
        receiptTail.push(`Resto non erogato ${_.padStart(this.$filter('sclCurrency')(transaction.changeNotGiven), 14, ' ')}`);

        response.tail = receiptTail.join('\n');

        return response;
    }

    public isEnabled(): boolean {
        return true;
    }

    public async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {
        const payment = sale.payments?.find((payment) => payment.payment_method_type_id == 38);

        if(!payment) {
            return;
        }

        const paymentMethod = await this.entityManager.paymentMethods.fetchOneOffline(payment.payment_method_id);
        const baseUrl = `https://${paymentMethod?.schema_name}/selfcashapi`;

        try {
            await this.VneCashdrawerDriver.rollbackPayment(baseUrl);
        } catch(err) {
            //TODO: return error code to notify missed rollback
        }
    }
}

VneCashdrawer.$inject = ["$translate", "$filter", "CashdrawerPaymentDialog", "VneCashdrawerDriver", "entityManager"];

angular.module('digitalPayments').service('VneCashdrawer', VneCashdrawer);
