import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { Calendar } from '@fullcalendar/core';
import fcTimeGrid from '@fullcalendar/timegrid';
import fcList from '@fullcalendar/list';
import fcInteraction from '@fullcalendar/interaction';
import fcResourceTimeline from '@fullcalendar/resource-timeline';

const { licenseKeys } = require('app/tilby.properties.json');

angular.module('bookings').controller('bookingsShowcaseCtrl', ['$scope', '$stateParams', '$window', '$timeout', '$translate', 'user', 'entityManager', 'checkManager', 'util', 'rooms', 'newBookingDialog', 'leanPMS', function($scope, $stateParams, $window, $timeout, $translate, user, entityManager, checkManager, util, rooms, newBookingDialog, leanPMS) {
    $scope.topbar_context = {
        defaultView: 'timeGridWeek'
    };

    $scope.eventSources = [];
    $scope.actualDay = new Date();
    var calendar;
    var daysOfWeekMap = { 'Su': 0, 'Mo': 1, 'Tu': 2, 'We': 3, 'Th': 4, 'Fr': 5, 'Sa': 6 };
    var bookingsByShift = {};
    var tablesById = {};
    var shiftTotals = {};
    var slotDuration = _.toInteger(checkManager.getPreference('bookings.slot_duration') || 30);
    entityManager.bookingShifts.fetchCollectionOffline().then(function(bookingShifts) {
        $scope.bookingShifts = bookingShifts || [];
    });

    const isLeanPmsIsEnabled = leanPMS.isEnabled();

    rooms.forEach((room) => {
        room.tables.forEach((table) => {
            if(!table.name.startsWith('ph_')) {
                tablesById[table.id] = {
                    table_name: table.name,
                    table_id: table.id,
                    room_name: room.name,
                    room_id: room.id,
                    covers: table.covers,
                    type: table.order_type
                };
            }
        });
    });

    var getBookings = async function() {
        const bookings = await entityManager.bookings.fetchCollectionOffline();
        if(bookings) {
            $scope.events = [];
    
            for (const booking of bookings) {
                let customerCaption = null;
                if (isLeanPmsIsEnabled && booking.pms_reservation_id) {
                    customerCaption = booking.pms_reservation_name;
                } else {
                    customerCaption = util.getCustomerCaption(booking.booking_customer);
                }
    
                const eventColor = getEventColor(booking);
                const bookingTemplate = {
                    id: booking.id,
                    title: String(booking.people) + (customerCaption ? ` - ${customerCaption}` : '') + ($scope.topbar_context.defaultView === 'listDay' ? getTablesCaption(booking) : ''),
                    backgroundColor: eventColor,
                    borderColor: eventColor,
                    start: booking.booked_for,
                    end: moment(booking.booked_for).add(booking.duration, 'minutes').toDate(),
                };
    
                $scope.events.push(bookingTemplate);
            }
            filterByDay();
        };
    }

    var filterByDay = function() {    
        deleteShiftLabels();
        $scope.filter_events = [];
        $scope.groupedEventsByShift = [];
        
        $scope.events.filter((event) => {
            if(!$scope.actualDay) $scope.actualDay = calendar.view.currentStart;
            $scope.actualDay.setHours(0, 0, 0, 0);
            var start = new Date(event.start);

            if(start.getDate() === $scope.actualDay.getDate() && start.getMonth() === $scope.actualDay.getMonth() && start.getFullYear() === $scope.actualDay.getFullYear()) {
                const inputDate = event.start;
                const date = new Date(inputDate);
                event.start = date;
                
                $scope.filter_events.push(event);
            }
        });

        $scope.bookingShifts.sort((a, b) => a.start_time.localeCompare(b.start_time));
        $scope.filter_events.sort((a, b) => a.start - b.start);

        for(var i = 0; i < $scope.filter_events.length; i++) {
            var eventStart = moment.utc($scope.filter_events[i].start).local().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            var eventDateStart = new Date(eventStart);
            var event_start = getTime(eventDateStart);

            var eventEnd = moment.utc($scope.filter_events[i].end).local().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            var eventDateEnd = new Date(eventEnd);
            var event_end = getTime(eventDateEnd);

            for(var j = 0; j < $scope.bookingShifts.length; j++) {
                if(isRangeInRange(event_start, event_end,$scope.bookingShifts[j].start_time, $scope.bookingShifts[j].end_time)){
                    $scope.groupedEventsByShift[$scope.bookingShifts[j].name] = $scope.groupedEventsByShift[$scope.bookingShifts[j].name] || [];
                    $scope.groupedEventsByShift[$scope.bookingShifts[j].name].push($scope.filter_events[i]);
                    break;
                }
            }
        };

        const sortedItems = Object.entries($scope.groupedEventsByShift).sort((a, b) => {
            const aIndex = $scope.bookingShifts.findIndex(shift => shift.name === a[0]);
            const bIndex = $scope.bookingShifts.findIndex(shift => shift.name === b[0]);
            return aIndex - bIndex;
        });

        $scope.groupedEventsByShift = Object.fromEntries(sortedItems);
    }

    var isRangeInRange = function(rangeStart, rangeEnd, containerStart, containerEnd) {

        if(rangeStart >= containerStart && rangeEnd <= (containerEnd === "00:00:00" ? "23:59:59" : containerEnd)){
            return true;
        } else {
            return false;
        }
    }

    var getTime = function(date) {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}:00`;
    }

    var deleteShiftLabels = function() {
        var shifts = document.querySelectorAll('[id^="shift-"]');
        
        if(shifts.lenght === 0) return;

        shifts.forEach((shift) => {
            shift.parentNode.removeChild(shift);
        });
    }

    var getTablesCaption = function(booking) {
        var caption = [];

        _.forEach(booking.tables, function(table) {
            var tableData = tablesById[table.table_id];

            if(tableData) {
                caption.push([tableData.room_name, tableData.table_name].join(', '));
            }
        });

        if(!_.isEmpty(caption)) {
            return ' - ' + _.toString(caption.join(' - '));
        } else {
            return '';
        }
    };

    var onEventClick = function(eventInfo) {
        switch($scope.topbar_context.defaultView) {
            case 'timeGridWeek':
                $scope.topbar_context.defaultView = 'resourceTimeline';
                $scope.topbar_context.changeView(eventInfo.event.start);
            break;
            case 'resourceTimeline':
            case 'listDay':
                var bookingID = _.toInteger(eventInfo.event.id);

                entityManager.bookings.fetchOneOffline(bookingID).then(function(booking) {
                    if(booking) {
                        newBookingDialog.show({ booking: booking });
                    }
                });
            break;
        }
    };

    var onDateClick = function(dateInfo) {
        switch($scope.topbar_context.defaultView) {
            case 'timeGridWeek':
                break;
            case 'listDay':
                $scope.actualDay = dateInfo.date;
                deleteShiftLabels();
                filterByDay();
                break;
            case 'resourceTimeline':
                var options = {};

                if(dateInfo.start) { //Date Range
                    options.dateStart = dateInfo.start;

                    if(moment.duration(moment(dateInfo.end).diff(dateInfo.start)).asMinutes() > slotDuration) {
                        options.dateEnd = dateInfo.end;
                    }
                }

                if(!_.isEmpty(options)) {
                    if(dateInfo.resource) {
                        options.tableId = _.toInteger(dateInfo.resource.id);
                    }

                    $scope.addBooking(options);
                }
            break;
        }
    };

    var onEventMount = function(info) {
        switch($scope.topbar_context.defaultView) {
            case 'timeGridWeek':
                var shiftId = _.toInteger(info.event.id);
                var shiftBookings = bookingsByShift[shiftId];
                var shiftStart = moment(info.event.start);
                var shiftEnd = moment(info.event.end);
                var busyTables = {};
                var busySeats = 0;

                _.forEach(shiftBookings, function(booking) {
                    var bookingStart = moment(booking.booked_for);

                    if(bookingStart.isBetween(shiftStart, shiftEnd, undefined, '[)')) {
                        _.forEach(booking.tables, function(table) {
                            busyTables[table.table_id] = true;
                        });

                        busySeats += booking.people;
                    }
                });

                var totalSeats = _.get(shiftTotals, [shiftId, 'seats']);
                var totalTables = _.get(shiftTotals, [shiftId, 'tables']);

                var tablesRow = $translate.instant('BOOKINGS.SHOWCASE.TABLES') + (_.size(busyTables)) + (totalTables ? (" | " + totalTables + " [" + (Math.round((_.size(busyTables)/totalTables) * 100)) + "%]") : '');
                var peoplesRow = $translate.instant('BOOKINGS.SHOWCASE.SEATS') + (busySeats) + (totalSeats ? (" | " + totalSeats + " [" + (Math.round((busySeats/totalSeats) * 100)) + "%]") : '');

                $(info.el).find('.fc-event-title-container').append("<div>"+ tablesRow + "</div>").append("<div>" + peoplesRow + "</div>");
            break;
            case 'resourceTimeline':
            break;
            case 'listDay':
                $timeout(() => {
                    if($scope.firstTime) { 
                        if(Object.keys($scope.groupedEventsByShift).length === 0) return;
                        deleteShiftLabels();
                        
                        const totalEventGroupedByShift = Object.values($scope.groupedEventsByShift).reduce((acc, val) => acc + val.length, 0);

                        var filter_event_index = 0;

                        if($scope.filter_events.length > totalEventGroupedByShift) { 
                            $scope.filter_events.forEach((filter_event, index) => {
                                Object.values($scope.groupedEventsByShift).forEach((event_shift) => {
                                    if(filter_event.id === event_shift[0].id) filter_event_index = index;
                                });
                            });
                        }

                        var tr = document.createElement('tr');
                        var td = document.createElement('td');

                        tr.appendChild(td);
                        tr.setAttribute('id', `shift-0`);

                        var textNode = document.createTextNode(Object.keys($scope.groupedEventsByShift)[0]);

                        td.appendChild(textNode);
                        td.style.height = '35px';
                        td.style.padding = '8px 14px';
                        td.setAttribute('colspan', '3');
                        td.classList.add('tw-font-bold');
    
                        var $el = document.getElementsByClassName('fc-event fc-event-start fc-event-end fc-list-event')[filter_event_index];
                        var parentEl = $el.parentNode;
                        parentEl.insertBefore(tr, $el);

                        var labelPosition = 0;

                        if(Object.keys($scope.groupedEventsByShift).length > 1) {
                            for(var i = 1; i < Object.keys($scope.groupedEventsByShift).length; i++) {
                                var tr = document.createElement('tr');
                                var td = document.createElement('td');

                                tr.appendChild(td);
                                tr.setAttribute('id', `shift-${i}`);

                                textNode = document.createTextNode(Object.keys($scope.groupedEventsByShift)[i]);

                                td.appendChild(textNode);
                                td.style.height = '35px';
                                td.style.padding = '8px 14px';
                                td.setAttribute('colspan', '3');
                                td.classList.add('tw-font-bold');
                                
                                var position = $scope.groupedEventsByShift[Object.keys($scope.groupedEventsByShift)[i-1]].length;
                                labelPosition = labelPosition + position;

                                $el = document.getElementsByClassName('fc-event fc-event-start fc-event-end fc-list-event')[labelPosition];
                                parentEl = $el.parentNode;
                                parentEl.insertBefore(tr, $el);    
                            }
                        }
                        $scope.firstTime = false;
                    }
                }, 1);
            break;
        }
    };

    var getEventColor = function(booking) {
        switch(booking.status) {
            case 'provisional':
                return '#F9A825';
            case 'confirmed':
                return '#4CAF50';
            case 'arrived':
            case 'seated':
                return '#D50000';
            case 'departed':
            case 'noshow':
            default:
                return '#BDBDBD';
        }
    };

    var fetchResources = function(fetchInfo, successCallback, failureCallback) {
        entityManager.rooms.fetchCollectionOffline().then(function(rooms) {
            var resources = [];

            resources.push({
                id: 0,
                title: $translate.instant('BOOKINGS.SHOWCASE.NO_RESOURCE')
            });

            rooms.forEach((room) => {
                room.tables.forEach((table) => {
                    if(!table.name.startsWith('ph_')) {
                        resources.push({
                            id: _.padStart(table.id, 8, '0'),
                            room: room.name,
                            title: table.name
                        });
                    }
                });
            });

            successCallback(resources);
        });
    };

    var fetchEvents = function(fetchInfo, successCallback, failureCallback) {
        entityManager.bookings.fetchCollectionOffline().then((bookings) => {
            var events = [];

            switch($scope.topbar_context.defaultView) {
                case 'timeGridWeek':
                    bookingsByShift = _.groupBy(bookings, 'shift_id');

                    entityManager.bookingShifts.fetchCollectionOffline().then(function(bookingShifts) {
                        shiftTotals = {};

                        events = _.map(bookingShifts, function(shift) {
                            //MAP WEEKDAYS
                            var daysOfWeek = [];

                            _.forEach(shift.weekdays_period, function(day) {
                                daysOfWeek.push(daysOfWeekMap[day]);
                            });

                            //GET SHIFT CAPACTITY
                            var shiftTotal = {
                                seats: 0,
                                tables: 0
                            };

                            var shiftRoomsBList = {};

                            _.forEach(shift.room_restrictions, function(room) {
                                shiftRoomsBList[room.room_id] = true;
                            });

                            _.forEach(rooms, function(room) {
                                if(!shiftRoomsBList[room.id]) {
                                    shiftTotal.tables += _.size(room.tables);
                                    shiftTotal.seats += _.sumBy(room.tables, 'covers');
                                }
                            });

                            if(!_.isNil(shift.instore_seats_limit)) {
                                shiftTotal.seats = shift.instore_seats_limit;
                            }

                            shiftTotals[shift.id] = shiftTotal;

                            return {
                                id: shift.id,
                                groupId: _.toString(shift.id),
                                title: shift.name,
                                startTime: shift.start_time,
                                endTime: shift.end_time,
                                startRecur: moment(shift.start_period),
                                endRecur: moment(shift.end_period).add(1, 'day'),
                                daysOfWeek: daysOfWeek
                            };
                        });

                        successCallback(events);
                    });
                break;
                case 'listDay':
                case 'resourceTimeline':
                    for (const booking of bookings) {
                        let customerCaption = null;
                        if (isLeanPmsIsEnabled && booking.pms_reservation_id) {
                            customerCaption = booking.pms_reservation_name;
                        } else {
                            customerCaption = util.getCustomerCaption(booking.booking_customer);
                        }

                        const eventColor = getEventColor(booking);
                        const bookingTemplate = {
                            id: booking.id,
                            title: String(booking.people) + (customerCaption ? ` - ${customerCaption}` : '') + ($scope.topbar_context.defaultView === 'listDay' ? getTablesCaption(booking) : ''),
                            backgroundColor: eventColor,
                            borderColor: eventColor,
                            start: booking.booked_for,
                            end: moment(booking.booked_for).add(booking.duration, 'minutes').toDate()
                        };

                        if ($scope.topbar_context.defaultView === 'listDay') {
                            events.push(bookingTemplate);
                        }

                        if ($scope.topbar_context.defaultView === 'resourceTimeline') {
                            if(booking.tables && booking.tables.length) {
                                booking.tables.forEach((table) => {
                                    events.push({...bookingTemplate, resourceId: _.padStart(table.table_id, 8, '0') });
                                });
                            } else {
                                events.push({...bookingTemplate, resourceId: 0 });
                            }
                        }
                    }

                    successCallback(events);
                break;
                default:
                    successCallback(events);
                break;
            }
        });
    };

    var prepareView = function() {
        var calendarConfig = {
            plugins: [fcTimeGrid, fcInteraction, fcResourceTimeline, fcList],
            initialView: $scope.topbar_context.defaultView,
            schedulerLicenseKey: licenseKeys.fullCalendar,
            dateClick: onDateClick,
            eventClick: onEventClick,
            eventDidMount: onEventMount,
            events: fetchEvents,
            headerToolbar: false,
            height: '100%',
            locale: moment.locale(),
            firstDay: 1,
            allDayText: $translate.instant('BOOKINGS.SHOWCASE.CALENDAR_ALL_DAY_TEXT'),
            nowIndicator: true,
            select: onDateClick,
            resources: fetchResources,
            slotDuration: slotDuration * 60000,
            views: {
                resourceTimeline: {
                    selectable: true,
                    resourceGroupField: 'room',
                    resourceAreaHeaderContent: $translate.instant('BOOKINGS.SHOWCASE.RESOURCES'),
                    titleFormat: {
                        day: 'numeric',
                        weekday: 'long',
                        month: 'long',
                        year: 'numeric'
                    }
                }
            }
        };

        switch($scope.topbar_context.defaultView) {
            case 'timeGridWeek':
            break;
            case 'listDay':
            break;
            case 'resourceTimeline':
            break;
            default:
            break;
        }

        if(calendar) {
            calendar.destroy();
        }

        $window.bookingCalendar = calendar = new Calendar(angular.element('#ui-calendar')[0], calendarConfig);

        calendar.render();

        if ($stateParams.customer) {
            var options = {};
            options.customer = $stateParams.customer;
            $scope.addBooking(options);
        }
    };

    $scope.topbar_context.today = function() {
        if(calendar) {
            if (calendar.view.currentStart.getTime() !== $scope.actualDay.getTime()) {
                filterByDay();
            }
            calendar.today();
        }
    };

    $scope.topbar_context.previous = function() {
        if(calendar) {
            filterByDay();
            calendar.prev();
        }
    };

    $scope.topbar_context.next = function() {
        if(calendar) {
            filterByDay();
            calendar.next();
        }
    };

    $scope.topbar_context.getCalendarInfo = function() {
        if(calendar) {
            $scope.actualDay = calendar.view.currentStart;
            return calendar.view.title;
        }
    };

    $scope.topbar_context.getCurrentDate = function() {
        if(calendar) {
            $scope.actualDay = calendar.view.currentStart;
            return calendar.view.currentStart;
        }
    };

    $scope.topbar_context.changeView = function(date) {
        switch($scope.topbar_context.defaultView) {
            case 'timeGridWeek':
            break;
            case 'resourceTimeline':
            break;
            case 'listDay':
                getBookings();
                $scope.firstTime = true;
                $scope.actualDay = date;
                filterByDay();
            break;
        }

        calendar.changeView($scope.topbar_context.defaultView, date);
        if(date) {
            calendar.scrollToTime(moment(date).format('HH:mm:ss'));
        }
        calendar.refetchEvents();
    };

    $scope.addBooking = function(options) {
        newBookingDialog.show(options);
    };

    $scope.$on('storage-updated:bookings', function(event, data) {
        if(calendar) {
            getBookings();
            $scope.firstTime = true;
            $scope.actualDay = calendar.view.currentStart;
            filterByDay();
            calendar.refetchEvents();
        }
    });

    $scope.$on('storage-updated:rooms', function(event, data) {
        if(calendar) {
            getBookings();
            $scope.firstTime = true;
            $scope.actualDay = calendar.view.currentStart;
            filterByDay();
            calendar.refetchResources();
        }
    });

    $scope.user = user;
    getBookings();
    $timeout(prepareView);
}]);
