import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import { $state } from 'app/ajs-upgraded-providers';
import { GridService } from 'src/app/shared/components/grid/grid.service';
import { Customers } from 'tilby-models';

const tabCount = 4;
@Component({
    selector: 'app-tab-customer',
    templateUrl: './tab-customer.component.html',
    styleUrls: ['./tab-customer.component.scss'],
})
export class TabCustomerComponent {
    @Input() customer? : Customers;
    @Output() tabPanelChange = new EventEmitter<string>();
    demo1TabIndex = 0;

    constructor(private gridService: GridService,
        @Inject($state) private state: any,
        ) {
    }

    swipeRight() {
        if (this.demo1TabIndex)
        this.demo1TabIndex -=1;
    }

    swipeLeft() {
        if (this.demo1TabIndex<(tabCount-1)){
            this.demo1TabIndex+=1;
        }
    }
}
