import * as angular from 'angular';

angular.module('history').directive('topbarHistoryEInvoices', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
		template: require('./topbar-history-e-invoices.html'),
        controller: ["$scope", "$element", "$attrs", "$timeout", "$mdSidenav", "connection", "offlineDialog", "alertDialog", function($scope, $element, $attrs, $timeout, $mdSidenav, connection, offlineDialog, alertDialog) {

            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.onBarcodeScanned = function(result) {
                $scope.context.onSubmitBarcode(result);
            };

            $scope.updateDateRange = function() {
                $scope.context.selected_card = null;
                if($scope.context.filters.dateFilter.from && $scope.context.filters.dateFilter.to){
                    if($scope.context.filters.dateFilter.from <= $scope.context.filters.dateFilter.to) {
                        $scope.context.changeQueryStatus();
                    } else {
                        alertDialog.show("Intervallo di date non valido");
                    }
                }else{
                    $scope.context.changeQueryStatus();
                }
            };

            $scope.updateCompanyName = function() {
                $scope.context.selected_card = null;
                $scope.context.changeQueryStatus();
            };

            $scope.updateAmountRange = function() {
                $scope.context.selected_card = null;
                $scope.context.changeQueryStatus();
            };

            $scope.updateStatus = function() {
                $scope.context.selected_card = null;
                $scope.context.changeQueryStatus();
            };


            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };


            $scope.applyFilter = function() {
                $scope.context.mode = "showcase";
                $scope.context.selected_card = null;
                $scope.context.changeQueryStatus();
            };

            $scope.changeResultsPerPage = function(eInvoicesPerPage) {
                if($scope.context.eInvoicesPerPage !== eInvoicesPerPage) {
                    $scope.context.eInvoicesPerPage = eInvoicesPerPage;
                    $scope.context.changeQueryStatus();
                }
            };
        }]
    };
});