import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {GridTraductions} from "src/app/models/grid-traductions.model";

@Injectable()
export class GridTraductionsService {

    constructor(protected translate: TranslateService) {
    }

    getTraduction(): GridTraductions{
        return {
            // Set Filter
            selectAll: this.translate.instant("GRID.SELECT_ALL"), // '(Seleziona tutti)',
            selectAllSearchResults: this.translate.instant("GRID.SELECT_ALL_SEARCH_RESULTS"), // '(Seleziona tutti i risultati ricercati)',
            searchOoo: this.translate.instant("GRID.SEARCH_OOO"), // 'Cerca...',
            blanks: this.translate.instant("GRID.BLANKS"), // '(Spazi vuoti)',
            noMatches: this.translate.instant("GRID.NO_MATCHES"), // 'Nessuna corrispondenza',

            // Number Filter & Text Filter
            filterOoo: this.translate.instant("GRID.FILTER_OOO"), // 'Filtro...',
            equals: this.translate.instant("GRID.EQUALS"), // 'Uguale',
            notEqual: this.translate.instant("GRID.NOT_EQUAL"), // 'Non uguale',
            blank: this.translate.instant("GRID.BLANK"), // 'Vuoto',
            notBlank: this.translate.instant("GRID.NOT_BLANK"), // 'Non vuoto',
            empty: this.translate.instant("GRID.EMPTY"), // 'Scegli uno',

            // Number Filter
            lessThan: this.translate.instant("GRID.LESS_THAN"), // 'Meno di',
            greaterThan: this.translate.instant("GRID.GREATER_THAN"), // 'Più grande di',
            lessThanOrEqual: this.translate.instant("GRID.LESS_THAN_OR_EQUAL"), // 'Minore o uguale',
            greaterThanOrEqual: this.translate.instant("GRID.GREATER_THAN_OR_EQUAL"), // 'Maggiore o uguale',
            inRange: this.translate.instant("GRID.IN_RANGE"), // 'Nel raggio',
            inRangeStart: this.translate.instant("GRID.IN_RANGE_START"), // 'da',
            inRangeEnd: this.translate.instant("GRID.IN_RANGE_END"), // 'a',

            // Text Filter
            contains: this.translate.instant("GRID.CONTAINS"), // 'Contiene',
            notContains: this.translate.instant("GRID.NOT_CONTAINS"), // 'Non contiene',
            startsWith: this.translate.instant("GRID.STARTS_WITH"), // 'Inizia con',
            endsWith: this.translate.instant("GRID.ENDS_WITH"), // 'Finisce con',

            // Date Filter
            dateFormatOoo: this.translate.instant("GRID.DATE_FORMAT_OOO"), // 'yyyy-mm-dd',

            // Filter Conditions
            andCondition: this.translate.instant("GRID.AND_CONDITION"), // 'E',
            orCondition: this.translate.instant("GRID.OR_CONDITION"), // 'O',

            // Filter Buttons
            applyFilter: this.translate.instant("GRID.APPLY_FILTER"), // 'Applica',
            resetFilter: this.translate.instant("GRID.RESET_FILTER"), // 'Azzera',
            clearFilter: this.translate.instant("GRID.CLEAR_FILTER"), // 'Svuota',
            cancelFilter: this.translate.instant("GRID.CANCEL_FILTER"), // 'Cancella',

            // Filter Titles
            textFilter: this.translate.instant("GRID.TEXT_FILTER"), // 'Filtro di testo',
            numberFilter: this.translate.instant("GRID.NUMBER_FILTER"), // 'Filtro numerico',
            dateFilter: this.translate.instant("GRID.DATE_FILTER"), // 'Filtro data',
            setFilter: this.translate.instant("GRID.SET_FILTER"), // 'Imposta filtro',

            // Side Bar
            columns: this.translate.instant("GRID.COLUMNS"), // 'Colonne',
            filters: this.translate.instant("GRID.FILTERS"), // 'Filtri',

            // columns tool panel
            pivotMode: this.translate.instant("GRID.PIVOT_MODE"), // 'Modalità Pivot',
            groups: this.translate.instant("GRID.GROUPS"), // 'Gruppi di righe',
            rowGroupColumnsEmptyMessage: this.translate.instant("GRID.ROW_GROUP_COLUMNS_EMPTY_MESSAGE"), // 'Trascina qui per impostare i gruppi di righe',
            values: this.translate.instant("GRID.VALUES"), // 'Valori',
            valueColumnsEmptyMessage: this.translate.instant("GRID.VALUE_COLUMNS_EMPTY_MESSAGE"), // 'Trascina qui per aggregare',
            pivots: this.translate.instant("GRID.PIVOTS"), // 'Etichette di colonna',
            pivotColumnsEmptyMessage: this.translate.instant("GRID.PIVOT_COLUMNS_EMPTY_MESSAGE"), // 'Trascina qui per impostare le etichette delle colonne',

            // Header of the Default Group Column
            group: this.translate.instant("GRID.GROUP"), // 'Gruppo',

            // Row Drag
            rowDragRows: this.translate.instant("GRID.ROW_DRAG_ROWS"), // 'Righe',

            // Other
            loadingOoo: this.translate.instant("GRID.LOADING_OOO"), // 'Caricamento...',
            noRowsToShow: this.translate.instant("GRID.NO_ROWS_TO_SHOW"), // 'Nessuna riga da visualizzare',
            enabled: this.translate.instant("GRID.ENABLED"), // 'Abilitata',

            // Menu
            pinColumn: this.translate.instant("GRID.PIN_COLUMN"), // 'Pin Colonna',
            pinLeft: this.translate.instant("GRID.PIN_LEFT"), // 'Pin Sinistra',
            pinRight: this.translate.instant("GRID.PIN_RIGHT"), // 'Pin Destra',
            noPin: this.translate.instant("GRID.NO_PIN"), // 'No Pin',
            valueAggregation: this.translate.instant("GRID.VALUE_AGGREGATION"), // 'Valori di aggregazione',
            autosizeThiscolumn: this.translate.instant("GRID.AUTOSIZE_THIS_COLUMN"), // 'Auto-ridimensiona questa colonna',
            autosizeAllColumns: this.translate.instant("GRID.AUTOSIZE_ALL_COLUMNS"), // 'Auto-ridimensiona tutte le colonne',
            groupBy: this.translate.instant("GRID.GROUP_BY"), // 'Raggruppa per',
            ungroupBy: this.translate.instant("GRID.UNGROUP_BY"), // 'Annulla raggruppamento per',
            addToValues: this.translate.instant("GRID.ADD_TO_VALUES"), // 'Aggiungi ${variable} ai valori',
            removeFromValues: this.translate.instant("GRID.REMOVE_FROM_VALUES"), // 'Rimuovi ${variable} dai valori',
            addToLabels: this.translate.instant("GRID.ADD_TO_LABELS"), // 'Aggiungi ${variable} alle etichette',
            removeFromLabels: this.translate.instant("GRID.REMOVE_FROM_LABELS"), // 'Rimuovi ${variable} dalle etichette',
            resetColumns: this.translate.instant("GRID.RESET_COLUMNS"), // 'Reimposta colonne',
            expandAll: this.translate.instant("GRID.EXPAND_ALL"), // 'Espandi tutto',
            collapseAll: this.translate.instant("GRID.COLLAPSE_ALL"), // 'Chiudi tutto',
            copy: this.translate.instant("GRID.COPY"), // 'Copia',
            ctrlC: this.translate.instant("GRID.CTRL_C"), // 'Ctrl+C',
            copyWithHeaders: this.translate.instant("GRID.COPY_WITH_HEADERS"), // 'Copia Con Intestazioni',
            copyWithHeaderGroups: this.translate.instant("GRID.COPY_WITH_HEADER_GROUPS"), // 'Copia Con Gruppi di Intestazione',
            copyWithGroupHeaders: this.translate.instant("GRID.COPY_WITH_GROUP_HEADERS"), // 'Copia Con Gruppi di Intestazione',
            paste: this.translate.instant("GRID.PASTE"), // 'Incolla',
            ctrlV: this.translate.instant("GRID.CTRL_V"), // 'Ctrl+V',
            export: this.translate.instant("GRID.EXPORT"), // 'Esporta',
            csvExport: this.translate.instant("GRID.CSV_EXPORT"), // 'Esporta CSV',
            excelExport: this.translate.instant("GRID.EXCEL_EXPORT"), // 'Esporta Excel',

            // Enterprise Menu Aggregation and Status Bar
            sum: this.translate.instant("GRID.SUM"), // 'Somma',
            min: this.translate.instant("GRID.MIN"), // 'Minimo',
            max: this.translate.instant("GRID.MAX"), // 'Massimo',
            none: this.translate.instant("GRID.NONE"), // 'Nessuno',
            count: this.translate.instant("GRID.COUNT"), // 'Conteggio',
            avg: this.translate.instant("GRID.AVG"), // 'Media',
            filteredRows: this.translate.instant("GRID.FILTERED_ROWS"), // 'Filtrato',
            selectedRows: this.translate.instant("GRID.SELECTED_ROWS"), // 'Selezionato',
            totalRows: this.translate.instant("GRID.TOTAL_ROWS"), // 'Righe Totali',
            totalAndFilteredRows: this.translate.instant("GRID.TOTAL_AND_FILTERED_ROWS"), // 'Righe',
            more: this.translate.instant("GRID.MORE"), // 'Oltre',
            to: this.translate.instant("GRID.TO"), // 'a',
            of: this.translate.instant("GRID.OF"), // 'di',
            page: this.translate.instant("GRID.PAGE"), // 'Pagina',
            nextPage: this.translate.instant("GRID.NEXT_PAGE"), // 'Pagina Successiva',
            lastPage: this.translate.instant("GRID.LAST_PAGE"), // 'Ultima Pagina',
            firstPage: this.translate.instant("GRID.FIRST_PAGE"), // 'Prima Pagina',
            previousPage: this.translate.instant("GRID.PREVIOUS_PAGE"), // 'Pagina Precedente',

            // Pivoting
            pivotColumnGroupTotals: this.translate.instant("GRID.PIVOT_COLUMN_GROUP_TOTALS"), // 'Totale',

            // Enterprise Menu (Charts)
            pivotChartAndPivotMode: this.translate.instant("GRID.PIVOT_CHART_AND_PIVOT_MODE"), // 'Grafico Pivot & Modalità Pivot',
            pivotChart: this.translate.instant("GRID.PIVOT_CHART"), // 'Grafico Pivot',
            chartRange: this.translate.instant("GRID.CHART_RANGE"), // 'Intervallo Grafico',

            columnChart: this.translate.instant("GRID.COLUMN_CHART"), // 'Colonna',
            groupedColumn: this.translate.instant("GRID.GROUPED_COLUMN"), // 'Raggruppato',
            stackedColumn: this.translate.instant("GRID.STACKED_COLUMN"), // 'Impilato',
            normalizedColumn: this.translate.instant("GRID.NORMALIZED_COLUMN"), // '100% Impilato',

            barChart: this.translate.instant("GRID.BAR_CHART"), // 'Barre',
            groupedBar: this.translate.instant("GRID.GROUPED_BAR"), // 'Raggruppato',
            stackedBar: this.translate.instant("GRID.STACKED_BAR"), // 'Impilato',
            normalizedBar: this.translate.instant("GRID.NORMALIZED_BAR"), // '100% Impilando',

            pieChart: this.translate.instant("GRID.PIE_CHART"), // 'Torta',
            pie: this.translate.instant("GRID.PIE"), // 'Torta',
            doughnut: this.translate.instant("GRID.DOUGHNUT"), // 'Ciambella',

            line: this.translate.instant("GRID.LINE"), // 'Linea',

            xyChart: this.translate.instant("GRID.XY_CHART"), // 'X Y (Dispersione)',
            scatter: this.translate.instant("GRID.SCATTER"), // 'Dispersione',
            bubble: this.translate.instant("GRID.BUBBLE"), // 'Bolla',

            areaChart: this.translate.instant("GRID.AREA_CHART"), // 'Area',
            area: this.translate.instant("GRID.AREA"), // 'Area',
            stackedArea: this.translate.instant("GRID.STACKED_AREA"), // 'Impilato',
            normalizedArea: this.translate.instant("GRID.NORMALIZED_AREA"), // '100% Impilato',

            histogramChart: this.translate.instant("GRID.HISTOGRAM_CHART"), // 'Istogramma',

            combinationChart: this.translate.instant("GRID.COMBINATION_CHART"), // 'Combinazione',
            columnLineCombo: this.translate.instant("GRID.COLUMN_LINE_COMBO"), // 'Colonna & Linea',
            AreaColumnCombo: this.translate.instant("GRID.AREA_COLUMN_COMBO"), // 'Area & Colonna',

            // Charts
            pivotChartTitle: this.translate.instant("GRID.PIVOT_CHART_TITLE"), // 'Gafico Pivot',
            rangeChartTitle: this.translate.instant("GRID.RANGE_CHART_TITLE"), // 'Grafico a Intervalli',
            settings: this.translate.instant("GRID.SETTINGS"), // 'Impostazioni',
            data: this.translate.instant("GRID.DATA"), // 'Dati',
            format: this.translate.instant("GRID.FORMAT"), // 'Formato',
            categories: this.translate.instant("GRID.CATEGORIES"), // 'Categorie',
            defaultCategory: this.translate.instant("GRID.DEFAULT_CATEGORY"), // '(Nessuno)',
            series: this.translate.instant("GRID.SERIES"), // 'Serie',
            xyValues: this.translate.instant("GRID.XY_VALUES"), // 'Valori X Y',
            paired: this.translate.instant("GRID.PAIRED"), // 'Modalità Accoppiata',
            axis: this.translate.instant("GRID.AXIS"), // 'Asse',
            navigator: this.translate.instant("GRID.NAVIGATOR"), // 'Navigatore',
            color: this.translate.instant("GRID.COLOR"), // 'Colore',
            thickness: this.translate.instant("GRID.THICKNESS"), // 'Spessore',
            xType: this.translate.instant("GRID.X_TYPE"), // 'Tipo X',
            automatic: this.translate.instant("GRID.AUTOMATIC"), // 'Automatico',
            category: this.translate.instant("GRID.CATEGORY"), // 'Categoria',
            number: this.translate.instant("GRID.NUMBER"), // 'Numero',
            time: this.translate.instant("GRID.TIME"), // 'Tempo',
            xRotation: this.translate.instant("GRID.X_ROTATION"), // 'Rotazione X',
            yRotation: this.translate.instant("GRID.Y_ROTATION"), // 'Rotatione Y',
            ticks: this.translate.instant("GRID.TICKS"), // 'Spunte',
            width: this.translate.instant("GRID.WIDTH"), // 'Larghezza',
            height: this.translate.instant("GRID.HEIGHT"), // 'Altezza',
            length: this.translate.instant("GRID.LENGTH"), // 'Lunghezza',
            padding: this.translate.instant("GRID.PADDING"), // 'Padding',
            spacing: this.translate.instant("GRID.SPACING"), // 'Spaziatura',
            chart: this.translate.instant("GRID.CHART"), // 'Grafico',
            title: this.translate.instant("GRID.TITLE"), // 'Titolo',
            titlePlaceholder: this.translate.instant("GRID.TITLE_PLACEHOLDER"), // 'Titolo grafico - doppio click per modificare',
            background: this.translate.instant("GRID.BACKGROUND"), // 'Sfondo',
            font: this.translate.instant("GRID.FONT"), // 'Font',
            top: this.translate.instant("GRID.TOP"), // 'In alto',
            right: this.translate.instant("GRID.RIGHT"), // 'Destra',
            bottom: this.translate.instant("GRID.BOTTOM"), // 'In basso',
            left: this.translate.instant("GRID.LEFT"), // 'Sinistra',
            labels: this.translate.instant("GRID.LABELS"), // 'Etichette',
            size: this.translate.instant("GRID.SIZE"), // 'Dimensione',
            minSize: this.translate.instant("GRID.MIN_SIZE"), // 'Dimensione minima',
            maxSize: this.translate.instant("GRID.MAX_SIZE"), // 'Dimensione massima',
            legend: this.translate.instant("GRID.LEGEND"), // 'Legenda',
            position: this.translate.instant("GRID.POSITION"), // 'Posizione',
            markerSize: this.translate.instant("GRID.MARKER_SIZE"), // 'Dimensione Marker',
            markerStroke: this.translate.instant("GRID.MARKER_STROKE"), // 'Tratto Marker',
            markerPadding: this.translate.instant("GRID.MARKER_PADDING"), // 'Padding Marker',
            itemSpacing: this.translate.instant("GRID.ITEM_SPACING"), // 'Spaziatura Elemento',
            itemPaddingX: this.translate.instant("GRID.ITEM_PADDING_X"), // 'Elemento - Padding X',
            itemPaddingY: this.translate.instant("GRID.ITEM_PADDING_Y"), // 'Elemento - Padding Y',
            layoutHorizontalSpacing: this.translate.instant("GRID.LAYOUT_HORIZONTAL_SPACING"), // 'Spaziatura Orizzontale',
            layoutVerticalSpacing: this.translate.instant("GRID.LAYOUT_VERTICAL_SPACING"), // 'Spaziatura Verticale',
            strokeWidth: this.translate.instant("GRID.STROKE_WIDTH"), // 'Larghezza Tratto',
            offset: this.translate.instant("GRID.OFFSET"), // 'Offset',
            offsets: this.translate.instant("GRID.OFFSETS"), // 'Offsets',
            tooltips: this.translate.instant("GRID.TOOLTIPS"), // 'Tooltips',
            callout: this.translate.instant("GRID.CALLOUT"), // 'Callout',
            markers: this.translate.instant("GRID.MARKERS"), // 'Markers',
            shadow: this.translate.instant("GRID.SHADOW"), // 'Ombra',
            blur: this.translate.instant("GRID.BLUR"), // 'Blur',
            xOffset: this.translate.instant("GRID.X_OFFSET"), // 'X Offset',
            yOffset: this.translate.instant("GRID.Y_OFFSET"), // 'Y Offset',
            lineWidth: this.translate.instant("GRID.LINE_WIDTH"), // 'Larghezza della Linea',
            normal: this.translate.instant("GRID.NORMAL"), // 'Normale',
            bold: this.translate.instant("GRID.BOLD"), // 'Grassetto',
            italic:this.translate.instant("GRID.ITALIC"), // 'Corsivo',
            boldItalic: this.translate.instant("GRID.BOLD_ITALIC"), // 'Grassetto Corsivo',
            predefined: this.translate.instant("GRID.PREDEFINED"), // 'Predefinito',
            fillOpacity: this.translate.instant("GRID.FILL_OPACITY"), // 'Riempi Opacità',
            strokeOpacity: this.translate.instant("GRID.STROKE_OPACITY"), // 'Opacità di Linea',
            histogramBinCount: this.translate.instant("GRID.HISTOGRAM_BIN_COUNT"), // 'Bin count',
            columnGroup: this.translate.instant("GRID.COLUMN_GROUP"), // 'Colonna',
            barGroup: this.translate.instant("GRID.BAR_GROUP"), // 'Bar',
            pieGroup: this.translate.instant("GRID.PIE_GROUP"), // 'Torta',
            lineGroup: this.translate.instant("GRID.LINE_GROUP"), // 'Linea',
            scatterGroup: this.translate.instant("GRID.SCATTER_GROUP"), // 'X Y (Dispersione)',
            areaGroup: this.translate.instant("GRID.AREA_GROUP"), // 'Area',
            histogramGroup: this.translate.instant("GRID.HISTOGRAM_GROUP"), // 'Istogramma',
            combinationGroup: this.translate.instant("GRID.COMBINATION_GROUP"), // 'Combinazione',
            groupedColumnTooltip: this.translate.instant("GRID.GROUPED_COLUMN_TOOLTIP"), // 'Raggruppato',
            stackedColumnTooltip: this.translate.instant("GRID.STACKED_COLUMN_TOOLTIP"), // 'Impilato',
            normalizedColumnTooltip: this.translate.instant("GRID.NORMALIZED_COLUMN_TOOLTIP"), // '100% Impilato',
            groupedBarTooltip: this.translate.instant("GRID.GROUPED_BAR_TOOLTIP"), // 'Raggruppato',
            stackedBarTooltip: this.translate.instant("GRID.STACKED_BAR_TOOLTIP"), // 'Impilato',
            normalizedBarTooltip: this.translate.instant("GRID.NORMALIZED_BAR_TOOLTIP"), // '100% Impilato',
            pieTooltip: this.translate.instant("GRID.PIE_TOOLTIP"), // 'Torta',
            doughnutTooltip: this.translate.instant("GRID.DOUGHNUT_TOOLTIP"), // 'Ciambella',
            lineTooltip: this.translate.instant("GRID.LINE_TOOLTIP"), // 'Linea',
            groupedAreaTooltip: this.translate.instant("GRID.GROUPED_AREA_TOOLTIP"), // 'Area',
            stackedAreaTooltip: this.translate.instant("GRID.STACKED_AREA_TOOLTIP"), // 'Impilato',
            normalizedAreaTooltip: this.translate.instant("GRID.NORMALIZED_AREA_TOOLTIP"), // '100% Impilato',
            scatterTooltip: this.translate.instant("GRID.SCATTER_TOOLTIP"), // 'Dispersione',
            bubbleTooltip: this.translate.instant("GRID.BUBBLE_TOOLTIP"), // 'Bolla',
            histogramTooltip: this.translate.instant("GRID.HISTOGRAM_TOOLTIP"), // 'Istogramma',
            columnLineComboTooltip: this.translate.instant("GRID.COLUMN_LINE_COMBO_TOOLTIP"), // 'Colonna & Linea',
            areaColumnComboTooltip: this.translate.instant("GRID.AREA_COLUMN_COMBO_TOOLTIP"), // 'Area & Colonna',
            customComboTooltip: this.translate.instant("GRID.CUSTOM_COMBO_TOOLTIP"), // 'Combinazione Personalizzata',
            noDataToChart: this.translate.instant("GRID.NO_DATA_TO_CHART"), // 'Nessun dato disponibile per essere tacciato.',
            pivotChartRequiresPivotMode: this.translate.instant("GRID.PIVOT_CHART_REQUIRES_PIVOT_MODE"), // 'Il Grafico Pivot richiede la Modalità Pivot abilitata.',
            chartSettingsToolbarTooltip: this.translate.instant("GRID.CHART_SETTINGS_TOOLBAR_TOOLTIP"), // 'Menu',
            chartLinkToolbarTooltip: this.translate.instant("GRID.CHART_LINK_TOOLBAR_TOOLTIP"), // 'Collegato alla Griglia',
            chartUnlinkToolbarTooltip: this.translate.instant("GRID.CHART_UNLINK_TOOLBAR_TOOLTIP"), // 'Scollegato dalla Griglia',
            chartDownloadToolbarTooltip: this.translate.instant("GRID.CHART_DOWNLOAD_TOOLBAR_TOOLTIP"), // 'Scarica Grafico',
            seriesChartType: this.translate.instant("GRID.SERIES_CHART_TYPE"), // 'Tipo Grafico di Serie',
            seriesType: this.translate.instant("GRID.SERIES_TYPE"), // 'Tipo di Serie',
            secondaryAxis: this.translate.instant("GRID.SECONDARY_AXIS"), // 'Asse Secondario',

            // ARIA
            ariaChecked: this.translate.instant("GRID.ARIA_CHECKED"), // 'selezionato',
            ariaColumn: this.translate.instant("GRID.ARIA_COLUMN"), // 'Colonna',
            ariaColumnGroup: this.translate.instant("GRID.ARIA_COLUMN_GROUP"), // 'Gruppo di Colonne',
            ariaColumnList: this.translate.instant("GRID.ARIA_COLUMN_LIST"), // 'Lista Colonne',
            ariaColumnSelectAll: this.translate.instant("GRID.ARIA_COLUMN_SELECT_ALL"), // 'Attiva/Disattiva Selezione Tutte le Colonne',
            ariaDateFilterInput: this.translate.instant("GRID.ARIA_DATE_FILTER_INPUT"), // 'Input Filtro Data',
            ariaDefaultListName: this.translate.instant("GRID.ARIA_DEFAULT_LIST_NAME"), // 'Lista',
            ariaFilterColumnsInput: this.translate.instant("GRID.ARIA_FILTER_COLUMNS_INPUT"), // 'Filtra Colonne in Input',
            ariaFilterFromValue: this.translate.instant("GRID.ARIA_FILTER_FROM_VALUE"), // 'Filtra dal valore',
            ariaFilterInput: this.translate.instant("GRID.ARIA_FILTER_INPUT"), // 'Filtro Input',
            ariaFilterList: this.translate.instant("GRID.ARIA_FILTER_LIST"), // 'Filtro Lista',
            ariaFilterToValue: this.translate.instant("GRID.ARIA_FILTER_TO_VALUE"), // 'Filtra per valore',
            ariaFilterValue: this.translate.instant("GRID.ARIA_FILTER_VALUE"), // 'Valore Filtro',
            ariaFilteringOperator: this.translate.instant("GRID.ARIA_FILTERING_OPERATOR"), // 'Filtra per Operatore',
            ariaHidden: this.translate.instant("GRID.ARIA_HIDDEN"), // 'nascosto',
            ariaIndeterminate: this.translate.instant("GRID.ARIA_INDETERMINATE"), // 'indeterminato',
            ariaInputEditor: this.translate.instant("GRID.ARIA_INPUT_EDITOR"), // 'Input Editor',
            ariaMenuColumn: this.translate.instant("GRID.ARIA_MENU_COLUMN"), // 'Premi CTRL INVIO per aprire il menu delle colonne.',
            ariaRowDeselect: this.translate.instant("GRID.ARIA_ROW_DESELECT"), // 'Premi SPAZIO per deselezionare questa riga',
            ariaRowSelectAll: this.translate.instant("GRID.ARIA_ROW_SELECT_ALL"), // 'Premi Spazio per alternare la selezione di tutte le righe',
            ariaRowToggleSelection: this.translate.instant("GRID.ARIA_ROW_TOGGLE_SELECTION"), // 'Premi Spazio per alternare la selezione delle righe',
            ariaRowSelect: this.translate.instant("GRID.ARIA_ROW_SELECT"), // 'Premi SPAZIO per selezionare questa riga',
            ariaSearch: this.translate.instant("GRID.ARIA_SEARCH"), // 'Cerca',
            ariaSortableColumn: this.translate.instant("GRID.ARIA_SORTABLE_COLUMN"), // 'Premi INVIO per ordinare',
            ariaToggleVisibility: this.translate.instant("GRID.ARIA_TOGGLE_VISIBILITY"), // 'Premi SPAZIO per attivare/disattivare la visibilità',
            ariaUnchecked: this.translate.instant("GRID.ARIA_UNCHECKED"), // 'deselezionato',
            ariaVisible: this.translate.instant("GRID.ARIA_VISIBLE"), // 'visibile',
            ariaSearchFilterValues: this.translate.instant("GRID.ARIA_SEARCH_FILTER_VALUES"), // 'Cerca i valori del filtro',

            // ARIA Labels for Drop Zones

            ariaRowGroupDropZonePanelLabel: this.translate.instant("GRID.ARIA_ROW_GROUP_DROP_ZONE_PANEL_LABEL"), // 'Gruppi di Righe',
            ariaValuesDropZonePanelLabel: this.translate.instant("GRID.ARIA_VALUES_DROP_ZONE_PANEL_LABEL"), // 'Valori',
            ariaPivotDropZonePanelLabel: this.translate.instant("GRID.ARIA_PIVOT_DROP_ZONE_PANEL_LABEL"), // 'Etichette di Colonna',
            ariaDropZoneColumnComponentDescription: this.translate.instant("GRID.ARIA_DROP_ZONE_COLUMN_COMPONENT_DESCRIPTION"), // 'Premi DELETE per rimuovere',
            ariaDropZoneColumnValueItemDescription: this.translate.instant("GRID.ARIA_DROP_ZONE_COLUMN_VALUE_ITEM_DESCRIPTION"), // 'Premi INVIO per modificare il tipo di aggregazione',

            // ARIA Labels for Dialogs
            ariaLabelColumnMenu: this.translate.instant("GRID.ARIA_LABEL_COLUMN_MENU"), // 'Menu Colonna',
            ariaLabelCellEditor: this.translate.instant("GRID.ARIA_LABEL_CELL_EDITOR"), // 'Editor della Cella',
            ariaLabelDialog: this.translate.instant("GRID.ARIA_LABEL_DIALOG"), // 'Dialog',
            ariaLabelSelectField: this.translate.instant("GRID.ARIA_LABEL_SELECT_FIELD"), // 'Seleziona Campo',
            ariaLabelTooltip: this.translate.instant("GRID.ARIA_LABEL_TOOLTIP"), // 'Tooltip',
            ariaLabelContextMenu: this.translate.instant("GRID.ARIA_LABEL_CONTEXT_MENU"), // 'Menu Contestuale',
            ariaLabelSubMenu: this.translate.instant("GRID.ARIA_LABEL_SUB_MENU"), // 'Sottomenù',
            ariaLabelAggregationFunction: this.translate.instant("GRID.ARIA_LABEL_AGGREGATION_FUNCTION"), // 'Funzione di aggregazione',

            // Number Format (Status Bar, Pagination Panel)
            thousandSeparator: this.translate.instant("GRID.THOUSAND_SEPARATOR"), // '.',
            decimalSeparator: this.translate.instant("GRID.DECIMAL_SEPARATOR") // ',',
        };
    }
}
