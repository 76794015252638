import {Component, Injectable, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { lastValueFrom } from 'rxjs';
import {
    BaseDialogService, NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogTabsComponent, TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Validators } from '@angular/forms';
import { errorsLogger, restManager } from 'app/ajs-upgraded-providers';
import { ServerlessUser } from 'tilby-models';

export type EditFirstNameDialogData = {first_name?:string, last_name?:string, pin?:string|null};


@Component({
  selector: 'app-edit-firstname-dialog',
  standalone: true,
  imports: [TilbyDialogTabsComponent, TilbyDialogProgressBarComponent, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent, TilbyDialogToolbarComponent, MatDialogModule, TilbyMagicFormComponent],
  templateUrl: './edit-firstname-dialog.component.html',
  styleUrls: ['./edit-firstname-dialog.component.scss']
})
export class EditFirstNameDialogComponent {
    private readonly data: EditFirstNameDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly restManagerService = inject(restManager);
    private readonly errorsLoggerService = inject(errorsLogger);

    editFirstNameForm!: CustomFormGroup<CustomForm<EditFirstNameDialogData>>;
    inProgress: boolean;

    constructor() {
        this.inProgress = false;
        this.editFirstNameForm = new CustomFormGroup<CustomForm<EditFirstNameDialogData>>({
            first_name: new CustomFormControl(this.data.first_name, { validators: [Validators.required] }, {
                ...new CustomFormControlProps(),
                label: 'SETTINGS.EDIT_USER_ACEESS_DIALOG.FIRSTNAME',
                inputType: 'text',
            })
        });
    }

    confirm(): void {
        this.inProgress = true;
        const payload: EditFirstNameDialogData = {
            first_name: this.firstNameController?.value,
            last_name: this.data.last_name,
            pin: this.data.pin
        }
        this.restManagerService.post('sessions/current_user', payload).then((res: ServerlessUser) => {
            if(res) {
                this.matDialogRef.close(res);
            }
        }).catch((err: any) => {
            this.errorsLoggerService.logError(err, 'Failed to edit first name');
        }).finally(() => {
            this.inProgress = false;
        });
    }

    get firstNameController(){
        return this.editFirstNameForm.controls.first_name;
    }
}

@Injectable({
    providedIn: 'root'
})
export class EditFirstNameDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: EditFirstNameDialogData){
        if(!data) {
            data={first_name:''};
        }
        const config:NonNullableConfigData<EditFirstNameDialogData>={...this.switchMobileDesktopDimensions(), disableClose:true, data};
        return lastValueFrom(this.dialogRef.open(EditFirstNameDialogComponent,config).afterClosed());
    }
}
