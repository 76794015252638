<div class="tw-h-full tw-flex tw-flex-col tw-items-center tw-p-5">
    <section id="logo_alvolo">
        <img ngSrc="assets/images/alvolo/logo_alvolo.svg" width="600" height="150">
    </section>
    <section id="description" class="tw-text-center tw-p-7">
        <p translate="SETTINGS.ALVOLO.ACTIVATE.DESCRIPTION"></p>
        <p translate="SETTINGS.ALVOLO.ACTIVATE.ACTIVATE_ALVOLO"></p>
    </section>
    <form [formGroup]="formGroupSettings" (ngSubmit)="onSubmit()">
        <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-flex tw-flex-col">
            <mat-label class="tw-text-gray-400" for="alias">
                <span translate="SETTINGS.ALVOLO.ACTIVATE.CHOOSE_ALIAS"></span>
            </mat-label>
            <div class="tw-flex">
                <p for="alias">https://webapp.alvolo.app/</p>
                <mat-form-field color="primary" class="the-dense-zone">
                    <input matInput id="alias" formControlName="alias" placeholder="Alias"/>
                </mat-form-field>
            </div>
        </section>
        <div class="tw-px-8">
            <mat-error *ngIf="formGroupSettings.get('alias')?.hasError('required') && formGroupSettings.get('alias')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.ALIAS_MANDATORY">
            </mat-error>
            <mat-error *ngIf="formGroupSettings.get('alias')?.hasError('minlength') && formGroupSettings.get('alias')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.MAX_FOUR_CHARACTERS">
            </mat-error>
            <mat-error *ngIf="!formGroupSettings.get('alias')?.hasError('minlength') && formGroupSettings.get('alias')?.hasError('pattern') && formGroupSettings.get('alias')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.NO_SPECIAL_CHARACTERS">
            </mat-error>
        </div>
        <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
            <div class=" tw-flex tw-flex-row tw-justify-between tw-items-center">
                <mat-label class="tw-text-gray-400" translate="SETTINGS.ALVOLO.ACTIVATE.CHOOSE_LIST"></mat-label>
                <mat-form-field class="the-dense-zone">
                    <mat-select formControlName="list">
                        <mat-option  *ngFor="let list of priceLists" [value]="list">{{list.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </section>
        <div class="tw-px-8">
            <mat-error *ngIf="formGroupSettings.get('list')?.hasError('required') && formGroupSettings.get('list')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.LIST_MANDATORY">
            </mat-error>
        </div>
        <section *ngIf="showSubmitButton" class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-text-center">
            <button mat-button class="tw-w-1/2" style="border: 1px solid lightgrey;" type="submit" 
            [disabled]="formGroupSettings.invalid" translate="SETTINGS.ALVOLO.ACTIVATE.NEXT"></button>
        </section>
        <section *ngIf="!showSubmitButton">
            <mat-spinner class="primary tw-mx-auto" value="60"></mat-spinner>
        </section>
    </form>
</div>