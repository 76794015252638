import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('settings').controller('ShiftsSettingsCtrl', ["$scope", "$translate", "checkManager", "confirmDialog", "alertDialog", "entityManager", "rooms", "shiftsList", function($scope, $translate, checkManager, confirmDialog, alertDialog, entityManager, rooms, shiftsList) {
    _.assign($scope, {
        isSaving: false,
        priceLists: [],
        rooms: rooms,
        shiftsList: shiftsList,
        weekDays: _.zipWith(moment.localeData('en').weekdaysMin(false), moment.weekdays(false), function(id, name) {
            return {
                id: id,
                name: _.upperFirst(name)
            };
        }),
        pristineShift: {},
        selectedShift: {}
    });

    //Setup pricelists
    _.times(10, function(idx) {
        $scope.priceLists.push({
            index: (idx + 1),
            fieldName: 'price' + (idx + 1),
            value: checkManager.getPreference("price_list_" + (idx + 1) + "_name") || $translate.instant('SETTINGS.SHIFTS.PRICE_LIST_START') + ' ' + (idx + 1),
            hidden: checkManager.getPreference("price_list_"  + (idx + 1) +  "_hide") ? true : false
        });
    });

    var init = function() {
        $scope.selectFirstAvailableShift();
    };

    $scope.unsaveShift = function(shift) {
        confirmDialog.show($translate.instant('SETTINGS.SHIFTS.EDITS_NOT_SAVED')).then(function(result) {
            if(result) {
                $scope.addShiftForm.$setPristine();
                $scope.selectShift(shift, false);
            }
        });
    };

    $scope.selectShift = function(shift, isFormDirty) {
        if(isFormDirty) {
            $scope.unsaveShift(shift);
        } else {
            $scope.pristineShift = shift;
            $scope.selectedShift = _.cloneDeep($scope.pristineShift);

            $scope.selectedShift.start_time = shift.start_time ? moment(shift.start_time, 'HH:mm:ss').seconds(0).toDate() : moment('00:00', 'HH:mm').toDate();
            $scope.selectedShift.end_time = shift.end_time ? moment(shift.end_time, 'HH:mm:ss').seconds(0).toDate() : moment('23:59', 'HH:mm').toDate();

            $scope.selectedShift.room_restrictions= _.map(shift.room_restrictions, function(el) {
                return el.room_id;
            });
        }
    };

    $scope.selectFirstAvailableShift = function() {
        if(!_.isEmpty($scope.shiftsList)) {
            $scope.selectShift(_.head($scope.shiftsList), false);
        } else {
            $scope.addNewShift();
        }
    };

    $scope.addNewShift = function() {
        $scope.selectShift({
            enabled: true,
            _new: true
        }, false);
    };

    $scope.isSelected = function(shift) {
        return shift === $scope.pristineShift;
    };

    var formattedPeriod = function(period) {
        return period ? moment(period).format('YYYY-MM-DD') : null;
    };

    var formattedTime = function(time) {
        return time ? moment(time, 'Hmm').format("HH:mm") : "12:00";
    };

    var lockSave = function() {
        $scope.isSaving = true;
    };

    var unlockSave = function() {
        $scope.isSaving = false;
    };

    $scope.saveShift = function() {
        lockSave();

        var cloneSelectedShift = _.cloneDeep($scope.selectedShift);
        // formatted period
        cloneSelectedShift.start_period = formattedPeriod(cloneSelectedShift.start_period);
        cloneSelectedShift.end_period = formattedPeriod(cloneSelectedShift.end_period);
        // formatted time
        cloneSelectedShift.start_time = formattedTime(cloneSelectedShift.start_time);
        cloneSelectedShift.end_time = formattedTime(cloneSelectedShift.end_time);

        cloneSelectedShift.room_restrictions = _.map($scope.selectedShift.room_restrictions, function(roomID) {
            return { room_id: roomID };
        });

        var shiftToSave = $scope.pristineShift;
        var saveFunction = $scope.selectedShift._new ? 'postOneOnline' : 'putOneOnline';

        entityManager.bookingShifts[saveFunction](cloneSelectedShift)
            .then(function(result) {
                    if($scope.pristineShift._new) {
                        $scope.shiftsList.push(result);
                        $scope.selectShift(result, false);
                    } else {
                        angular.copy(result, shiftToSave);
                        // $scope.selectedShift = _.cloneDeep(shiftToSave);
                        $scope.selectShift(shiftToSave, false);
                    }
                    $scope.addShiftForm.$setPristine();
                    unlockSave();
                },
                function(error) {
                    alertDialog.show($translate.instant('SETTINGS.SHIFTS.SAVE_FAILED'));
                    unlockSave();
                }
            )
            .catch(function() {
                unlockSave();
            });
    };

    var lockDelete = function() {
        $scope.isSaving = true;
    };

    var unlockDelete = function() {
        $scope.isSaving = false;
    };

    $scope.deleteShift = function() {
        lockDelete();
        confirmDialog
            .show($translate.instant('SETTINGS.SHIFTS.DELETE_CONFIRM'))
            .then(function(answer) {
                if(answer) {
                    var shiftToDelete = $scope.pristineShift;

                    entityManager.bookingShifts.deleteOneOnline(shiftToDelete.id)
                    .then(function(result) {
                        _.remove($scope.shiftsList, { id: $scope.pristineShift.id });
                        $scope.selectFirstAvailableShift();
                        unlockDelete();
                    }, function(error) {
                        alertDialog.show($translate.instant('SETTINGS.SHIFTS.DELETE_FAILED'));
                        unlockDelete();
                    }).catch(function() {
                        unlockDelete();
                    });
                } else {
                    unlockDelete();
                }
            })
            .catch(function() {
                unlockDelete();
            });
    };

    $scope.clearStartPeriod = function() {
        $scope.selectedShift.start_period = null;
    };

    $scope.clearEndPeriod = function() {
        $scope.selectedShift.end_period = null;
    };

    $scope.getWeekdaysPeriod = function(weekdays_period) {
        var out = '';
        if (weekdays_period.length === 0 || weekdays_period.length === 7) {
            out = $translate.instant('SETTINGS.SHIFTS.ALL_DAYS');
        } else {
            out = [];
            weekdays_period.forEach(function(el) {
                switch (el) {
                    case 'Mo': out.push(moment.weekdaysShort(true, 0)); break;
                    case 'Tu': out.push(moment.weekdaysShort(true, 1)); break;
                    case 'We': out.push(moment.weekdaysShort(true, 2)); break;
                    case 'Th': out.push(moment.weekdaysShort(true, 3)); break;
                    case 'Fr': out.push(moment.weekdaysShort(true, 4)); break;
                    case 'Sa': out.push(moment.weekdaysShort(true, 5)); break;
                    case 'Su': out.push(moment.weekdaysShort(true, 6)); break;
                }
            });
            out = out.join(', ');
        }
        return out;
    };

    var removeSeconds = function(time) {
        var tmp = time.split(':');
        return tmp[0] + ':' + tmp[1];
    };

    $scope.getTime = function(shift) {
        return removeSeconds(shift.start_time) + '-' + removeSeconds(shift.end_time);
    };

    $scope.topbar_context = {
        view: 'home',
        title: $translate.instant('SETTINGS.TOPBAR_SETTINGS.SHIFTS_SETTINGS'),
        deleteButton: {
            label: $translate.instant('SETTINGS.TOPBAR_SETTINGS.DELETE'),
            disabled: function() {
                return false;
            },
            visible: function() {
                return true;
            },
            action: $scope.deleteShift
        },
        saveButton: {
            label: $translate.instant('SETTINGS.TOPBAR_SETTINGS.SAVE'),
            disabled: function() {
                return !$scope.addShiftForm.$valid;
            },
            visible: function() {
                return true;
            },
            action: $scope.saveShift
        }
    };

    init();
}]);
