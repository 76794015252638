import { Injectable, inject } from "@angular/core";
import { $urlService } from "app/ajs-upgraded-providers";
import { EnvironmentInfoService } from "src/app/core";
import { CustomersCallService } from "./customers-call.service";

@Injectable({
    providedIn: 'root'
})
export class AppToAppService {
    private $urlService = inject($urlService);
    private environmentInfoService = inject(EnvironmentInfoService);
    private customersCallService = inject(CustomersCallService);

    /**
     * Handles the given app-to-app URL.
     *
     * @param {string} url - The app-to-app URL to handle.
     */
    private handleAppToAppUrl(url: string) {
        //Temporary workaround for cashregister route
        if(url.startsWith('tilby://cashregister')) {
            url = url.replace('/cashregister', '/old_cashregister');
        }

        if(url.startsWith('tilby://customer/incomingcall')) {
            this.customersCallService.callCustomer(url.substring(url.lastIndexOf('/') + 1));
            return;
        }

        this.$urlService.url(url.replace('tilby://', '/'));
    }

    /**
     * Initializes the service.
     */
    public init() {
        if(this.environmentInfoService.isElectronApp()) {
            //Initializes the Electron protocol handler
            window.require('electron').ipcRenderer.on('electron-url-call', (event: any, url: string) => {
                this.handleAppToAppUrl(url);
            });

            //Checks for app-to-app URL on startup
            const protocolCall = window.process.argv.find((arg) => arg.startsWith('tilby://'));

            if(protocolCall) {
                this.handleAppToAppUrl(protocolCall);
            }
        }
    }
}
