import {ErrorHandler, Injectable, inject} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {GlobalErrorService} from "./global-error.service";

//from zone
interface UncaughtPromiseError extends Error {
    zone: any; //Zone;
    task: any; //Task;
    promise: Promise<any>;
    rejection: any;
    throwOriginal?: boolean;
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
    private globalErrorService = inject(GlobalErrorService);

    constructor() {
    }

    handleError(error: Error | HttpErrorResponse | UncaughtPromiseError) {
        if (error && typeof error == 'object' && "promise" in error && error.rejection) {
            // Promise rejection wrapped by zone.js
            error = error.rejection;
        }

        if((<any>error)?.config?.url?.indexOf("/validate_vat/")>-1){
            this.globalErrorService.printError((<any>error)?.data?.error?.message?.error?.MSG.ERRORDESC || 'SERVER ERROR');
            return;
        }

        throw error;
    }
}
