import { Pipe, PipeTransform } from '@angular/core';
import { CustomizedNonFiscalDocument } from 'src/app/models/non-fiscal-document.model';

@Pipe({
    name: 'matchesModelDocument'
})
export class MatchesModelDocumentPipe implements PipeTransform {
    transform(list: Array<CustomizedNonFiscalDocument>, name: string): Array<CustomizedNonFiscalDocument> {
        name = name.trim().toLowerCase();
        return [...list.filter(item => item.name && item.name.toLowerCase().includes(name))];
    }
}
