import * as angular from 'angular';
import * as _ from 'lodash';
import { DigitalPaymentHandler, DigitalPaymentHandlerOptions } from 'src/app/shared/model/digital-payments.model';
import { v4 as generateUuid } from 'uuid';

type SumUpResponse = {
    "smp-message": string,
    "smp-tx-code": string
};

export class SumUpPayment implements DigitalPaymentHandler {
    constructor(
        private $translate: any,
        private waitDialog: any
    ) {
    }

    private handleSumUpError(error: any): string {
        switch (typeof error) {
            case 'string':
                return this.$translate.instant(`DIGITAL_PAYMENTS.SUMUP.ERROR_${error}`);
            default:
                return 'UNKNOWN_ERROR';
        }
    }

    private getPaymentTail(transactionResponse: SumUpResponse): string {
        const rowSize = 32;
        const tailFields = ['smp-tx-code'] as Array<keyof SumUpResponse>;

        let tailRows: string[] = [];

        for(let field of tailFields) {
            let fieldNameSize = _.chain(field).size().value();

            tailRows.push(`${field}${transactionResponse[field].toString().padStart(rowSize - fieldNameSize)}`);
        }

        return tailRows.join('\n');
    };

    public async payment(amount: number, options: DigitalPaymentHandlerOptions) : Promise<any> {
        const currentSale = options.sale;

        try {
            let transactionData = {
                amount: amount.toString(),
                currency: currentSale.currency,
                transactionId: generateUuid()
            };

            let transactionPromise = new Promise((resolve, reject) => {
                window.SumUpPlugin.performPayment(transactionData, (result: any) => resolve(result), (error: any) => reject(error));
            });

            let response: SumUpResponse = await this.waitDialog.show({ message: this.$translate.instant('DIGITAL_PAYMENTS.SUMUP.COMPLETE_ON_APP'), promise: transactionPromise, cancelAction: {
                label: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CANCEL_PAYMENT')
            }, });

            return {
                payment_data: JSON.stringify(response),
                tail: this.getPaymentTail(response),
                unclaimed: false
            }
        } catch (error) {
            throw this.handleSumUpError(error);
        } finally {
            window.SumUpPlugin.cleanup();
        }
    }
}

SumUpPayment.$inject = ["$translate", "waitDialog"];

angular.module('digitalPayments').service('sumup', SumUpPayment);