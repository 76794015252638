@if(sale?.payments?.length){
    <app-payments-list [sale]="sale" [getPaymentDetail]="getPaymentDetail.bind(this)" (removeSalesPayment)="removeSalesPayment($event)">
        <ng-container Header *ngTemplateOutlet="header"/>
    </app-payments-list>
} @else {
    <ng-container *ngTemplateOutlet="header"/>
    <div class="tw-flex tw-flex-1 tw-px-5 tw-justify-center tw-items-center tw-text-center tw-text-2xl" translate>CASHREGISTER.ACTIVE_SALE.SELECT_PAYMENT_TYPE</div>
}

<ng-template #header>
    <ng-content select="[Header]"/>
</ng-template>
