import * as angular from 'angular';

angular.module("tables").constant("floors", [
    {
        "id": 1,
        "name": "parquet",
        "imageUrl": "parquet.png"
    },
    {
        "id": 2,
        "name": "parquet_light",
        "imageUrl": "parquet_light.png"
    },
    {
        "id": 3,
        "name": "resilient",
        "imageUrl": "resilient.png"
    }
]);