import * as angular from 'angular';
import {Transition} from "angular-ui-router";

angular
    .module('historyNew', ['application'])
    .config(["$stateProvider", function ($stateProvider: any) {

        $stateProvider.state('app.new.history', {
            url: '/history',
            redirectTo: 'app.new.history.sales'
        });

        $stateProvider.state('app.new.history.sales', {
            url: '/sales',
            params: {
                id: { type: 'int', value: null }
            },
            component: 'historySalesShowcase'
        });

        $stateProvider.state('app.new.history.sale', {
            url: '/sale/:id',
            params: {
                id: { type: 'int', value: null },
            },
            redirectTo: function (transition: Transition) {
                return transition.router.stateService.target('app.new.history.sales', transition.params(), { reload: 'app.new.history' });
            }
        });

        $stateProvider.state('app.new.history.customer', {
            url: '/sale/customer',
            params: {
                customer: null,
                salesToBeInvoicedCustomer: null
            },
            component: 'historySalesShowcase'
        });

        $stateProvider.state('app.new.history.cash-movements', {
            url: '/cash-movements',
            component: 'historyCashMovements'
        });

}]);
