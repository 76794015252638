import * as angular from 'angular';

angular.module('application').directive('sclNgModelFile', function() {
    return {
        require: 'ngModel',
        link: function postLink(scope, elem, attrs, ngModel) {
            elem.on("change", function(e) {
                var files = elem[0].files;
                ngModel.$setViewValue(files);
            });
        }
    };
});