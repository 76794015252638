<div class="settings-kiosk-new">
    <mat-tab-group>
    @for (tab of tabs; track tab) {
        <mat-tab>
            <ng-template mat-tab-label>
                {{tab.label}}
                @if (tab.formInvalid()) {
                    <mat-icon class="tw-ml-2 tw-text-red-600">error</mat-icon>
                }
            </ng-template>
            <div class="tw-p-4 tw-h-[calc(100vh-144px)] tw-overflow-auto">
                <tilby-magic-form [form]="tab.form"/>
                @if (tab.name === 'APPARANCE') {
                    <tilby-magic-form [form]="appearenceFormImages" />
                }
                @if (tab.name === 'CATALOG') {
                    <tilby-magic-form [form]="catalogFormImages" />
                }
            </div>
        </mat-tab>
    }
    </mat-tab-group>
</div>
