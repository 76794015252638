<ng-container *ngIf="{
                searchValue: toolbarEventsService.searchBarValue$|async
              } as data">
    <mat-form-field [color]="'primary'" class="tw-text-base tw-w-full frame-search-bar the-dense-zone"
                    [class.open-search]="openSearch && isMobile" [class.focus]="openSearch" appearance="outline" id="search-bar">
        <mat-icon matIconPrefix class="tw-py-1" [class.focus]="openSearch">search</mat-icon>
        <input matInput
               [class.w-mobile]="isMobile"
               [class.open-search]="openSearch && isMobile"
               [value]="data.searchValue"
               type="text"
               #quickFilter
               [placeholder]="openSearch?'':('CUSTOMERS.SEARCH.SEARCH' | translate)"
               autocomplete="off"
               (focusin)="openSearch=true"
               (focusout)="openSearch=false"
               (keyup.enter)="quickFilter.blur()"
        />
            <mat-icon matIconSuffix class="tw-py-1 tw-cursor-pointer"
                *ngIf="icon !== ''"
                (click)="$event.stopPropagation(); clickAction();"
                [class.focus]="openSearch"
                fontSet="{{ icon === 'barcode' ? 'material-symbols-outlined' : undefined }}"
                >{{icon}}
            </mat-icon>
            <input *ngIf="isAppleMobile()" type="text" class="search-barcode-input" [(ngModel)]="searchBarcodeInput" placeholder="searchBarcodeInput" id={{idSelector}} (keyup.enter)="barcodeSubmitted()"/>
    </mat-form-field>
</ng-container>

