<div class="max-height my-subscription" layout="column">
    <div *ngIf="showForm"class="tw-flex tw-flex-row tw-h-full">
        <div class="tw-w-full">
            <mat-tab-group mat-stretch-tabs="true" [(selectedIndex)]="selectedTabIndex" color="primary" class="tw-h-full"
                (selectedTabChange)="changeTab($event)" tilbyGestures (swipeLeft)="swipeLeft()" (swipeRight)="swipeRight()">
                <mat-tab label="{{'SETTINGS.MY_SUBSCRIPTION.DASHBOARD' | translate}}">
                    <ng-container Riepilogo [ngTemplateOutlet]="dashboard"></ng-container>
                </mat-tab>
                <mat-tab label="{{'SETTINGS.MY_SUBSCRIPTION.BILLING_DATA' | translate}}">
                    <ng-container DatiDiFatturazione [ngTemplateOutlet]="billing_data"></ng-container>
                </mat-tab> 
            </mat-tab-group>
        </div>
    </div>
    <div *ngIf="showError" class="tw-flex tw-justify-center tw-items-center tw-h-[calc(100%-162px)] tw-text-center tw-p-[50px]" layout="row" layout-align="center center">
        <div class="tw-w-1/2" layout="column">
            <span>{{ 'SETTINGS.MY_SUBSCRIPTION.ERROR_NOT_AUTHORIZED_1' | translate : billingData }}</span><br>
            <span><b>{{billingData?.owner_first_name}} {{billingData?.owner_last_name}} ({{billingData?.username}})</b></span><br>
            <span><b>{{billingData?.email_address}}</b></span><br>
            <span><b>{{billingData?.phone_number}}</b></span><br>
            <span>{{ 'SETTINGS.MY_SUBSCRIPTION.ERROR_NOT_AUTHORIZED_2' | translate }}</span>
        </div>
    </div>
    <ng-template #dashboard>
        <div class="dashboard tw-h-max">
            <div class="dashboard-subheader" layout-gt-xs="row" layout-xs="column" layout-align="space-between center">
                <div layout="row" layout-align="start center" class="tw-flex tw-items-center tw-gap-4">
                    <div *ngIf="thumbnail !== null">
                        <img class="dashboard-avatar" src="{{ thumbnail }}" class="tw-w-[50px] tw-rounded-3xl">
                    </div>
                    <div *ngIf="thumbnail === null">
                        <img class="dashboard-avatar" src="assets/images/sidenav-user.png" class="tw-w-[50px] tw-rounded-3xl">
                    </div>
                    <h1 class="dashboard-title">{{'SETTINGS.MY_SUBSCRIPTION.HELLO_THERE' | translate}} {{ userFirstName }}</h1>
                </div>
            </div>
            <div class="dashboard-cards tw-flex tw-flex-col tw-gap-3 md:tw-flex-row" layout="row" layout-xs="column" layout-wrap>
                <div class="mat-elevation-z2 tw-w-full 2xl:tw-w-1/2 lg:tw-w-1/3" flex-xs="100" flex-sm="50" flex="33" layout="column">
                    <mat-card>
                        <mat-card-content>
                            <div layout="column" class="tw-flex tw-justify-between">
                                <div flex layout="row" layout-align="space-between center">
                                    <div layout="row">
                                        <mat-icon>account_balance</mat-icon>
                                        <span class="md-headline dashboard-card-headline" translate translate-values="{value:  data.today.total_sales }" translate-interpolation="messageformat">SETTINGS.MY_SUBSCRIPTION.YOUR_BALANCE</span>
                                    </div>
                                </div>
                                <span class="today_total" style="margin-top:30px;" layout="row" layout-align="end center">{{remoteBillingData?.balance | tilbyCurrency}}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="mat-elevation-z2 tw-w-full 2xl:tw-w-1/2 lg:tw-w-1/3" flex-xs="100" flex-sm="50" flex="33" layout="column">
                    <mat-card layout-align="space-between stretch">
                        <mat-card-content>
                            <div layout="column">
                                <div flex layout="row" layout-align="space-between center">
                                    <div layout="row">
                                        <mat-icon>account_balance</mat-icon>
                                        <span class="md-headline dashboard-card-headline" translate translate-values="{value:  data.today.total_sales }" translate-interpolation="messageformat">SETTINGS.MY_SUBSCRIPTION.YOUR_DATA</span>
                                    </div>
                                </div>
                                <div class="card-row tw-flex tw-justify-between" layout="row" layout-align="space-between center">
                                    <span flex class="card-amount-column-title" translate>SETTINGS.MY_SUBSCRIPTION.NAME</span>
                                    <span class="card-amount-column">{{remoteBillingData?.company_name}}</span>
                                </div>
                                <div class="card-row tw-flex tw-justify-between" layout="row" layout-align="space-between center">
                                    <span flex class="card-amount-column-title" translate>SETTINGS.MY_SUBSCRIPTION.OWNER_FIRST_NAME</span>
                                    <span class="card-amount-column">{{remoteBillingData?.owner_first_name}}</span>
                                </div>
                                <div class="card-row tw-flex tw-justify-between" layout="row" layout-align="space-between center">
                                    <span flex class="card-amount-column-title" translate>SETTINGS.MY_SUBSCRIPTION.OWNER_LAST_NAME</span>
                                    <span class="card-amount-column">{{remoteBillingData?.owner_last_name}}</span>
                                </div>
                                <div class="card-row tw-flex tw-justify-between" layout="row" layout-align="space-between center">
                                    <span flex class="card-amount-column-title" translate>SETTINGS.MY_SUBSCRIPTION.TAX_CODE</span>
                                    <span class="card-amount-column">{{remoteBillingData?.tax_code}}</span>
                                </div>
                                <div class="card-row tw-flex tw-justify-between" layout="row" layout-align="space-between center">
                                    <span flex class="card-amount-column-title" translate>SETTINGS.MY_SUBSCRIPTION.VAT_CODE</span>
                                    <span class="card-amount-column">{{remoteBillingData?.vat_code}}</span>
                                </div>
                                <div class="card-row tw-flex tw-justify-between" layout="row" layout-align="space-between center">
                                    <span flex class="card-amount-column-title" translate>SETTINGS.MY_SUBSCRIPTION.IBAN</span>
                                    <span class="card-amount-column">{{remoteBillingData?.iban_code}}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #billing_data>
        <div class="tw-h-max tw-p-5 tw-mb-8">
            @if(billingForm) {
                <p class="tw-m-0 tw-py-3 tw-text-[20px]" translate="SETTINGS.MY_SUBSCRIPTION.INFO"></p>
                <tilby-magic-form [form]="billingForm"/> 
            }
        </div>
    </ng-template>
</div>