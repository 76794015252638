<tilby-dialog-toolbar mat-dialog-title
    [title]="'CASHREGISTER.ADD_NEW_ITEM_TO_SALE.TITLE'"
    (confirm)="confirm()"
    [hideConfirm]="operationInProgress() || !!_form?.invalid"
/>
@if(operationInProgress()) {
    <tilby-dialog-progress-bar />
}
<tilby-dialog-content class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-8 " [class.tw-pt-8]="mobileCheck">
    @if(_form) {
        <tilby-magic-form [form]="_form"/>
    }
</tilby-dialog-content>