
<button mat-icon-button (click)="closeDialogOnCancel()">
    <mat-icon class="tw-font-medium">close</mat-icon>
</button>
<tilby-dialog-content class="tw-flex tw-flex-col tw-items-center tw-py-0 tw-overflow-x-hidden">
        <h2 class="tw-text-center" [translate]="'DIALOG.TIP.TITLE'"></h2>
        @if(showKeypad()){
            <div class="tw-h-1/2 tw-w-full tw-flex tw-flex-col tw-justify-end tw-max-w-[16rem] tw-py-2">
                <app-tilby-keypad-display [form]="totalValueForm"/>
                <app-cashregister-keypad class="tw-h-2/3 tw-min-h-[200px] tw-w-full tw-flex-1" [field]="cashValue" [integerMultiply]="true" (fieldChange)="onFieldChange($event)"/>
            </div>
        }
        <div class="tw-flex">
            @if(showKeypad()){<h1 class="tw-pr-1" [translate]="'DIALOG.TIP.TOTAL'"></h1>}
            <h1>{{ totalAmount() | tilbyCurrency }}</h1>
        </div>
        <h5 class="tw-my-2">{{ ('DIALOG.TIP.TOTAL_PLUS_TIP' | translate: {total_plus_tip: totalPlusTip()})}}</h5>
        @if(!showKeypad()){
            <section class="tw-grid sm:tw-grid-flow-col sm:tw-auto-cols-fr tw-justify-center sm:tw-justify-between sm:tw-items-center tw-gap-x-4 tw-gap-y-4 tw-py-2 tw-overflow-y-auto tw-flex-1">
                @for (tipKeyValue of (tipsChoices|keyvalue:keyValueNoSort); track $index) {
                    @if(tipKeyValue.value; as tip){
                        <div [class.tilby-body-background-primary-color]="tip.key==tipSelected().key" class="tip-choice tw-text-base xl:tw-text-xl tw-col-span-1 tw-min-h-16 tw-min-w-fit tw-px-2 tw-py-1 tw-rounded-xl" (click)="selectTip(tip)">
                            <div class="tw-w-full tw-text-wrap tw-text-truncate tw-text-center">{{ tip.key | translate }}</div>
                            @if (tip.value && !tip.isCustom) {
                                <div class="tw-whitespace-nowrap">{{ $any(tip.value) | tilbyCurrency }}</div>
                            }
                        </div>
                    }

                }
            </section>
        }
</tilby-dialog-content>
<tilby-dialog-action-buttons align="center" [buttons]="buttons" class="tw-py-4 lg:tw-py-6"/>

