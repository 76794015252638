import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataWaitDialog} from '../dialog.model';
import {RootScope} from 'app/ajs-upgraded-providers';
import * as _ from 'lodash';
import {OpenDialogsService} from '../services';
import { IAngularEvent } from 'angular';

@Component({
    selector: 'app-wait-dialog',
    templateUrl: './wait-dialog.component.html',
    styleUrls: ['./wait-dialog.component.scss'],
    host: {class: 'tw-min-w-[40vw] tw-flex tw-justify-center tw-items-center tw-h-full tw-flex-col tw-px-6 tw-py-6'}
})
export class WaitDialogComponent implements OnDestroy {
    timeoutProgress = 0;
    timeoutTarget = 0;
    intervalId?: NodeJS.Timer;
    message = this.data.message;
    onWaitDialogUpdateState: any;
    constructor(
        private dialogRef: MatDialogRef<OpenDialogsService>,
        @Inject(MAT_DIALOG_DATA) public data: DataWaitDialog,
        @Inject(RootScope) public rootScope: any,
    ) {
        this.updateState(data);
        this.promiseResolved(data);
        this.timerInit();
        this.onWaitDialogUpdateState = this.rootScope.$on("wait-dialog:update-state", (_event: IAngularEvent, state: any) => {
            this.updateState(state);
            this.rootScope.$applyAsync();
        });
    }

    ngOnDestroy(): void {
        this.onWaitDialogUpdateState();
    }

    timerInit() {
        if (this.data.timeout) {
            this.timeoutTarget = (this.data.timeout.target) * 1000;

            const progressUpdate = () => {
                this.timeoutProgress += 100;

                if (this.timeoutProgress >= this.timeoutTarget) {
                    clearInterval(this.intervalId);
                    this.dialogRef.close(true);
                }
            };
            this.intervalId = setInterval(progressUpdate, 100);
        }
    }

    updateState(state:DataWaitDialog) {
        this.message = state.message || this.message;

        if(state.cancelAction) {
            _.assign(this, {
                cancelAction: state.cancelAction,
                customActions: null,
            });
        } else if(state.customActions) {
            _.assign(this, {
                cancelAction: null,
                customActions: state.customActions
            });
        }
    };

    promiseResolved(state:DataWaitDialog) {
        state.promise?.then((result) => {
            this.dialogRef.close(result || true);
        }, (error) => {
            this.dialogRef.close({error});
        });
    }
}
