import * as angular from 'angular';
import * as _ from 'lodash';
import * as pdfMake from 'pdfmake';

angular.module('dashboard').factory("fiscalPrinterDashboardDialog", fiscalPrinterDashboardDialog);

fiscalPrinterDashboardDialog.$inject = ["$mdDialog"];

function fiscalPrinterDashboardDialog($mdDialog) {
    const fiscalPrinterDashboardDialogController = function($scope, $translate, checkManager, restManager, data, printersData, alignedPrintersData, vats, documentPrinter, printerErrorFiscal, alertDialog, dashboardReportGenerator, dashboardDataManager, promptDialog, util, generalDocumentPrinterSelect, environmentInfo) {
        Object.assign($scope, {
            alignedPrintersData: alignedPrintersData,
            data: data,
            email: checkManager.getPreference('stock.export.default_email') || '',
            printersData: printersData,
            sumAmounts: dashboardReportGenerator.sumAmounts,
            sumDiscounts: dashboardReportGenerator.sumDiscounts,
            sumSurcharges: dashboardReportGenerator.sumSurcharges
        });

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.showPrinter = function(printerName) {
            if(printersData.length === 2) {
                let foundPrinter = _.find(printersData, { name: printerName });

                return !_.get(foundPrinter, ['isTotal'], false);
            }
            return true;
        };

        $scope.sumCash = (cash_movements) => cash_movements.sales || 0;

        $scope.sumCashTickets = (cash_movements) => {
            if(_.get(cash_movements, ['tickets'], undefined) !== undefined) {
                return _.reduce(cash_movements.tickets, function(prev, ticket) {
                    if(ticket.amount) {
                        prev.amount = prev.amount + ticket.amount;
                    }
                    if(ticket.count) {
                        prev.count = prev.count + ticket.count;
                    }
                    return prev;
                }, {count: 0, amount: 0});
            }
            return {count: 0, amount: 0};
        };

        $scope.sumDrawer = function() {
            let total = (_.get(data, ['today', 'cash_movements', 'total', 'income', 'amount'], 0) + _.get(data, ['today', 'cash_movements', 'total', 'outcome', 'amount'], 0));

            return _.reduce(printersData, (prev, printer) => {
                if(!_.get(printer, ['isTotal'], false)) {
                    return prev + ($scope.sumCashTickets(printer.cash_movements).amount || 0);
                }

                return prev;
            }, total);
        };

        $scope.sumTickets = (payments) => _.sumBy(payments, (payment) => _.isNil(payment.ticket_name) ? 0 : payment.amount);
        $scope.getVatValue = (vatName) => dashboardReportGenerator.getVatValue(vats, vatName);
        $scope.hasNoResults = () => _.isEmpty($scope.printersData);

        const getDashboardReport = async () => {
            let reportData;
            
            if(checkManager.getPreference('cashregister.daily_report_per_seller')) {
                let sellersData = await dashboardDataManager.getTodaySellersData();
                const sellersMap = sellersData.sellers_map;
                sellersData = _.chain(sellersData).get('sellers').mapKeys((val, key) => sellersMap[key] || key).value();
                reportData = dashboardDataManager.getMergedResources(sellersData);
            } else {
                reportData = $scope.printersData;
            }

            return dashboardReportGenerator.generateReport(reportData, data);
        };

        $scope.printDetails = async() => {
            if (!checkManager.isModuleEnabled('cashregister')) {
                alertDialog.show($translate.instant("DASHBOARD.DIALOG.NO_CASHREGISTER_MODULE"), { multiple: true });
                throw 'NO_CASHREGISTER_MODULE';
            }

            if (!$scope.printInProgress) {
                let document = await getDashboardReport();
                let printer = await generalDocumentPrinterSelect.show();

                if(printer) {
                    let printerId = printer?.id;

                    if(_.isNil(printerId)) {
                        alertDialog.show($translate.instant("DASHBOARD.DIALOG.NO_PRINTER"), { multiple: true });
                        throw 'NO_PRINTER';
                    }

                    $scope.printInProgress = true;

                    try {
                        await documentPrinter.printFreeNonFiscal(document, printerId);
                    } catch(error) {
                        printerErrorFiscal.show(error, { multiple: true, printerId: printerId });
                    }

                    $scope.printInProgress = false;
                }
            }
        };

        const getPdfDocument = async () => {
            let document = await getDashboardReport();

            let pdfDocument = {
                content: [document],
                defaultStyle: {
                    font: 'Monospace',
                    fontSize: 10
                  }
            };

            return pdfDocument;
        };

        $scope.emailDetails = async () => {
            let pdfDocument = await getPdfDocument();
            let pdfDocGenerator = pdfMake.createPdf(pdfDocument);

            pdfDocGenerator.getBuffer(async (blob) => {
                let mailAddress = await promptDialog.show({ title: $translate.instant('DASHBOARD.EXPORT.SEND_VIA_EMAIL'), label: $translate.instant('DASHBOARD.EXPORT.EMAIL'), type: "email", defaultValue: checkManager.getPreference('stock.export.default_email') });

                if(mailAddress) {
                    restManager.postOne("utilities/send_document_via_email", blob, {
                        email: mailAddress,
                        filename: "Riepilogo",
                    }, {"content-type": "application/pdf"});
                }
            });
        };

        $scope.downloadDetails = async () => {
            let pdfDocument = await getPdfDocument();
            let filename = 'Riepilogo';

            pdfMake.createPdf(pdfDocument).download(filename);
        };

        $scope.canShare = () => environmentInfo.canShare();
        $scope.canDownloadFiles = () => environmentInfo.canDownloadFiles();

        $scope.shareDetails = async () => {
            const filename = 'Riepilogo';

            if($scope.canShare()) {
                let pdfDocument = await getPdfDocument();

                pdfMake.createPdf(pdfDocument).getBase64(function(dataUri) {
                    window.plugins.socialsharing.shareWithOptions({
                        message: filename, // not supported on some apps (Facebook, Instagram)
                        subject: filename, // fi. for email
                        files: ["data:application/pdf;base64," + dataUri], // an array of filenames either locally or remotely
                      }, function() {

                    }, function(error) {

                    });
                });
            }
        };

        $scope.openAnalytics = function(){
            util.openExternalLink('https://analytics.tilby.com?utm_source=scloby-app&utm_medium=dashboard&utm_campaign=dashboard_button');
        };

        $scope.openedSections = {};

        $scope.toggleSection = (path) => {
            let wasOpen = _.get($scope.openedSections, path, false);
            _.set($scope.openedSections, path, !wasOpen);
        };

        $scope.tabSelected = 0;

        $scope.tabClicked = function(tab) {
            switch (tab) {
                case 0:
                    $scope.tabSelected = 0;
                    break;
                case 1:
                    $scope.tabSelected = 0;
                    $scope.openAnalytics();
                    break;
            }
        };
    };

    fiscalPrinterDashboardDialogController.$inject = ["$scope", "$translate", "checkManager", "restManager", "data", "printersData", "alignedPrintersData", "vats", "documentPrinter", "printerErrorFiscal", "alertDialog", "dashboardReportGenerator", "dashboardDataManager", "promptDialog", "util", "generalDocumentPrinterSelect", "environmentInfo"];

    const fiscalPrinterDashboardDialog = {
        show: function(data, printersData, alignedPrintersData) {
            return $mdDialog.show({
                controller: fiscalPrinterDashboardDialogController,
                template: require('./fiscal-printer-dashboard-dialog.html'),
                locals: {
                    data: data,
                    printersData: printersData,
                    alignedPrintersData: alignedPrintersData,
                },
                resolve: {
                    vats: ['entityManager', (entityManager) => entityManager.vat.fetchCollectionOffline()]
                }
            });
        }
    };

    return fiscalPrinterDashboardDialog;
}