import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('customers').factory('customerUtils', ["restManager", function(restManager) {
    var customerUtils = {
        getVatInfo: function(vatCode) {
            return restManager.getOne("validate_vat", vatCode).then(function(result) {
                if (result) {
                    return _.pickBy({
                        type: 'company',
                        company_name: result.name,
                        country: _.truncate(result.address_country, { length: 2, omission: '' }),
                        billing_street: result.address_street,
                        billing_number: _.truncate(result.address_number, { length: 25, omission: '' }),
                        billing_city: result.address_city,
                        billing_prov: _.truncate(result.address_prov, { length: 10, omission: '' }),
                        billing_zip: _.truncate(result.address_zip, { length: 10, omission: '' }),
                        billing_country: _.truncate(result.address_country, { length: 2, omission: '' }),
                        email_pec: _.toLower(result.email_pec),
                        tax_code: _.truncate(result.tax_number, { length: 16, omission: '' }),
                        vat_code: result.vat_number
                    }, _.identity);
                } else {
                    return null;
                }
            });
        }
    };

    return customerUtils;
}]);