import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.orders");
    }

  ngOnInit(): void {
  }

}
