import {Injectable, InjectionToken} from '@angular/core';
import {Subject} from 'rxjs';
import {ComponentPortal} from '@angular/cdk/portal';
import {
    HistorySaleDetailComponent
} from '../../features/history/history-sales/components/history-sale-detail/history-sale-detail.component';

export type RightSidenavServiceComponent = HistorySaleDetailComponent;
export const RIGHT_SIDENAV_PORTAL_DATA_TOKEN = new InjectionToken<string>('portal-data');

@Injectable({
    providedIn: 'root'
})
export class RightSidenavService<T> {
    isOpen = false;
    componentPortal$= new Subject<ComponentPortal<T>>();

    constructor() { }
}
