import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('orders').factory('ordersTableView', ordersTableView);

ordersTableView.$inject = ["$mdDialog", "$filter", "$translate", "entityManager", "checkManager"];

function ordersTableView($mdDialog, $filter, $translate, entityManager, checkManager) {
    ordersTableViewController.$inject = ["$scope", "$mdDialog", "rooms", "TablesFactory", "$mdMedia", "$state"];

    function ordersTableViewController($scope, $mdDialog, rooms, TablesFactory, $mdMedia, $state) {
        Object.assign($scope, {
            isTablesModuleEnabled: checkManager.isModuleEnabled('tables'),
            ordersByTable: {},
            rooms: rooms,
            selectedRoomIndex: TablesFactory.lastRoom ? _.findIndex(rooms, { id: TablesFactory.lastRoom }) : -1,
            showNewOrderButton: true,
            tableOrders: [],
            view: 'tables',
        });

        var ordersByType = {
            delivery: [],
            take_away: []
        };

        if ($scope.selectedRoomIndex !== -1) {
            $scope.currentRoom = rooms[$scope.selectedRoomIndex];
        } else {
            $scope.selectedRoomIndex = 0;
            $scope.currentRoom = _.head(rooms);
        }

        $scope.selectedRoom = $scope.currentRoom;

        $scope.isPortrait = () => $mdMedia('xs');

        const buildOrdersByTable = function(ords) {
            $scope.ordersByTable = _(ords)
                .filter((ord) => ['open', 'checkout'].includes(ord.status))
                .orderBy(['open_at', 'created_at'], ['desc', 'desc'])
                .groupBy('table_id')
                .value();
        };

        $scope.goToRoom = function(room) {
            $scope.view = 'tables';
            $scope.currentRoom = room;
            TablesFactory.lastRoom = room.id;
        };

        $scope.goToTablesView = function() {
            $scope.tableOrders = [];
            delete $scope.currentTable;
            $scope.view = 'tables';
        };

        $scope.goToTables = function() {
            $mdDialog.cancel();
            $state.go("app.tables.rooms-view");
        };

        $scope.getTableDuration = (table) => _.head($scope.ordersByTable[table.id])?.open_at;

        $scope.getTableCovers = function(table) {
            var covers;

            if (table.order_type === 'single') {
                var orderToCheck = _.head($scope.ordersByTable[table.id]);
                covers = _.get(orderToCheck, 'covers', table.covers);
            } else {
                covers = _.isEmpty($scope.ordersByTable[table.id]) ? table.covers : _.sumBy($scope.ordersByTable[table.id], 'covers');
            }

            return $translate.instant('ORDERS.ORDERS_TABLE_VIEW.COVERS_COUNT', { covers: covers }, 'messageformat');
        };

        $scope.showTakeAway = function() {
            $scope.view = 'takeAway';
            $scope.tableOrders = ordersByType['take_away'];
        };

        $scope.showDelivery = function() {
            $scope.view = 'delivery';
            $scope.tableOrders = ordersByType['delivery'];
        };

        $scope.getTableOrdersCount = function(table) {
            if (_.isArray($scope.ordersByTable[table.id])) {
                let ordersCount = $scope.ordersByTable[table.id].length;
                return $translate.instant('ORDERS.ORDERS_TABLE_VIEW.ORDERS_COUNT', { orders: ordersCount }, 'messageformat');
            }
        };

        $scope.getTableAmount = function(table) {
            let orderToCheck = _.head($scope.ordersByTable[table.id]);

            return orderToCheck ? $filter('sclCurrency')(orderToCheck.amount) : undefined;
        };

        $scope.getTableStatus = function(table) {
            switch (table.order_type) {
                case "single":
                    var orderToCheck = _.head($scope.ordersByTable[table.id]);
                    if (orderToCheck) {
                        switch (orderToCheck.status) {
                            case "open":
                                return "busy";
                            case "checkout":
                                return "checkout";
                        }
                    } else {
                        return "available";
                    }
                    break;
                case "multiple":
                    if (_.isEmpty($scope.ordersByTable[table.id])) {
                        return "available";
                    } else {
                        return "busy";
                    }
                    break;
            }
        };

        $scope.selectTable = async (table) => {
            const goToOrdersView = function() {
                $scope.tableOrders = $scope.ordersByTable[table.id];
                $scope.view = "orders";
                $scope.currentTable = table;
                $scope.showNewOrderButton = table.order_type === "multiple";
            };

            switch (table.order_type) {
                case "single":
                    let tableOrders = $scope.ordersByTable[table.id];

                    if (_.isEmpty(tableOrders)) {
                        $mdDialog.hide({
                            table: table,
                            room: $scope.currentRoom
                        });
                    } else if(tableOrders.length === 1) {
                        $mdDialog.hide({
                            order: _.head($scope.ordersByTable[table.id])
                        });
                    } else {
                        if(checkManager.getPreference("orders.single_order_autofix") !== false) {
                            let slaveOrders = _.tail(tableOrders);

                            for(let order of slaveOrders) {
                                if(_.isEmpty(order.order_items)) {
                                    try {
                                        await entityManager.orders.deleteOneOfflineFirst(order.id);
                                    } catch(err) {
                                        //Nothing to do
                                    } finally {
                                        _.remove(tableOrders, { id: order.id });
                                    }
                                }
                            }

                            if(tableOrders.length === 1) {
                                $mdDialog.hide({
                                    order: _.head(tableOrders)
                                });
                            } else {
                                goToOrdersView();
                            }
                        } else {
                            goToOrdersView();
                        }
                    }
                    break;
                case "multiple":
                    if (_.isEmpty($scope.ordersByTable[table.id])) {
                        $mdDialog.hide({
                            table: table,
                            room: $scope.currentRoom
                        });
                    } else {
                        goToOrdersView();
                    }
                    break;
            }
        };

        $scope.addNewOrder = function() {
            switch($scope.view) {
                case 'tables':
                case 'orders':
                    $mdDialog.hide({
                        table: $scope.currentTable,
                        room: $scope.currentRoom
                    });
                break;
                case 'takeAway':
                    $mdDialog.hide({ type: 'take_away' });
                break;
                case 'delivery':
                    $mdDialog.hide({ type: 'delivery' });
                break;
            }
        };

        $scope.currentRoomHasTables = () => !_.isEmpty($scope.currentRoom.tables);

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.selectOrder = function(order) {
            delete order._timeString;
            delete order._dateString;

            $mdDialog.hide({
                order: order
            });
        };

        const refreshOrders = async () => {
            const newOrders = await entityManager.orders.fetchCollectionOffline({ status_in: ['open', 'checkout'] });

            buildOrdersByTable(newOrders);

            for(let order of newOrders) {
                switch(order.type) {
                    case 'take_away':
                    case 'delivery':
                        let now = moment();
                        let deliverAt = moment(order.deliver_at);

                        order._timeString = deliverAt.format('LT');

                        if(deliverAt.dayOfYear() !== now.dayOfYear() || deliverAt.year() !== now.year()) {
                            order._dateString = _.chain(deliverAt.fromNow()).upperFirst().replace('In un giorno', 'Domani').value();
                        }
                    break;
                    default:
                    break;
                }
            }

            for(let type of ['take_away', 'delivery']) {
                ordersByType[type] = _(newOrders).filter({ type: type }).orderBy('deliver_at').value();
            }

            if ($scope.currentTable) {
                $scope.tableOrders = $scope.ordersByTable[$scope.currentTable.id];
            }
        };

        refreshOrders();

        $scope.$on("storage-updated:orders", refreshOrders);

        $scope.$on("storage-updated:rooms", async (event, data) => {
            let newRooms = await entityManager.rooms.fetchCollectionOffline();
            angular.copy(newRooms, rooms);

            $scope.selectedRoomIndex = _.findIndex(rooms, { id: $scope.currentRoom.id });

            if ($scope.selectedRoomIndex === -1) {
                $scope.selectedRoomIndex = 1;
                $scope.currentRoom = _.head(rooms);
            } else {
                $scope.currentRoom = rooms[$scope.selectedRoomIndex];
            }

            if ($scope.currentTable) {
                $scope.currentTable = _.find($scope.currentRoom.tables, { id: $scope.currentTable.id });

                if (!$scope.currentTable) {
                    $scope.goToTablesView();
                }
            }
        });
    }

    var ordersTableView = {
        show: function() {
            return $mdDialog.show({
                controller: ordersTableViewController,
                template: require('./orders-table-view.html'),
                resolve: {
                    rooms: ["entityManager", function(entityManager) {
                        return entityManager.rooms.fetchCollectionOffline();
                    }]
                }
            });
        }
    };

    return ordersTableView;
}