import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.bookings");
    }

  ngOnInit(): void {
  }

}
