<tilby-dialog-toolbar mat-dialog-title
title="PROMOTIONS.LABELS_GENERATOR.TITLE"
[disabled]="form.valid || inProgress"
[customActions]="createActionButtons"
/>
@if (inProgress) {
    <tilby-dialog-progress-bar/>
}
<tilby-dialog-content>
    <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-1">
        <div class="tw-flex tw-justify-between">
            <tilby-magic-form [form]="form" class="tw-flex-nowrap"></tilby-magic-form>
            <div class="tw-flex tw-items-center">
                <button mat-button color="primary" (click)="getFromStock()">{{ 'PROMOTIONS.LABELS_GENERATOR.GET_FROM_STOCK' | translate }}</button>
                <button mat-button color="primary" (click)="resetQuantities()">{{ 'PROMOTIONS.LABELS_GENERATOR.RESET' | translate }}</button>
            </div>
        </div>
        <div class="labels-item-container tw-flex-1">
            @for (item of items; track item) {
                <div class="tw-flex tw-flex-row tw-items-center labels-item-row">
                    <span class="tw-flex-1">{{item.sku || item.item_sku}}{{item.name ? ' - ' + item.name : ''}}</span>
                    <button mat-button (click)="decreaseQuantity(item)">
                        <mat-icon>remove</mat-icon>
                    </button>
                    <span class="tw-flex tw-items-baseline">{{item._quantity}}</span>
                    <button mat-button (click)="increaseQuantity(item)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            }
        </div>
        <div>
            <h4 class="tw-mb-0" translate>PROMOTIONS.LABELS_GENERATOR.PREVIEW</h4>
            <div id="editorPreview" class="tw-p-1" #editorPreview></div>
        </div>
    </div>
</tilby-dialog-content>
