<mat-toolbar class="tw-bg-white">
    <div class="tw-flex tw-truncate">
        <p class="tw-text-md tw-truncate">{{ 'DIALOG.INSERT_DEVICE_WAITER.TITLE' | translate}}</p>
    </div>
</mat-toolbar>
<mat-dialog-content>
    <form *ngIf="insertNewDeviceForm" autocomplete="off" [formGroup]="insertNewDeviceForm">
        <mat-form-field color="primary" class="tw-w-full">
            <mat-label>{{ 'DIALOG.INSERT_DEVICE_WAITER.NAME_DEVICE' | translate}}</mat-label>
            <input formControlName="name" class="tw-w-full tw-mt-2" type="string" matInput #nameField>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="tw-flex tw-justify-end">
    <button mat-button [mat-dialog-close]="null"><span>{{ 'DIALOG.INSERT_DEVICE_WAITER.CANCEL' | translate}}</span></button>
    <button mat-raised-button color="primary" (click)="submit(insertNewDeviceForm)" [disabled]="insertNewDeviceForm?.invalid"><span>{{ 'DIALOG.INSERT_DEVICE_WAITER.CONFIRM' | translate}}</span></button>
</mat-dialog-actions>
