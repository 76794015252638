import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-history-e-invoices',
  templateUrl: './history-e-invoices.component.html',
  styleUrls: ['./history-e-invoices.component.scss']
})
export class HistoryEInvoicesComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.history.e_invoices");
    }

  ngOnInit(): void {
  }

}
