import { Injectable, Injector, inject, runInInjectionContext } from "@angular/core";
import { CustomForm, CustomFormArray, CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps, tilbyErrors } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { MatDialogRef } from "@angular/material/dialog";
import { DevLogger } from "src/app/shared/dev-logger";
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { NonNullableConfigData } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import { AddTimeSlotDialogComponent } from "./add-time-slot-dialog.component";
import { AddTimeSlotDialogModel } from "./add-time-slod-dialog-model";
import { AddressType, DistanceBasedType, GeneralFormFields, HiddenCategoriesType, HiddenProductsType, OperationMode, OperativeModeType, PriceType, TimeTableTypeAPI, TimeTableTypeModel } from "src/app/features/settings/settings-alvolo/model/general-form-fields";
import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { KeyValue } from "@angular/common";
import { Categories, Items } from "tilby-models";
import { EntityManagerService } from "src/app/core";
import { SettingsAlvoloStateService } from "src/app/features/settings/settings-alvolo/service/settings-alvolo.state.service";
import { subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";

export type AddTimeSlotFormValue = TimeTableTypeModel;
export type AddTimeSlotForm = CustomFormGroup<CustomForm<AddTimeSlotFormValue>>;
export type AddTimeSlotParams = AddTimeSlotDialogModel;
export type AddTimeSlotOptionDialogData = InputItemDataMagicForm<AddTimeSlotFormValue>;

@Injectable({
    providedIn: 'root'
})
export class AddTimeSlotDialogStateService {
    private readonly entityManagerService = inject(EntityManagerService);    
    private readonly settingsAlvoloStateService = inject(SettingsAlvoloStateService);     
    private readonly injector = inject(Injector);    
    private readonly openDialogsService = inject(OpenDialogsService);
    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<AddTimeSlotDialogComponent>>
    private _form?: AddTimeSlotForm;
    input_choices_categories_types: KeyValue<string, string>[] = [];
    input_choices_products_types: KeyValue<string, string>[] = [];
    products: Items[] = [];
    shop_preference!: GeneralFormFields<AddressType[]>;
    time_slot_index!: number;
    weekdays: string[] = ['SETTINGS.ALVOLO.HOURS.WEEK_DAY.MONDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.TUESDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.WEDNESDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.THURSDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.FRIDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.SATURDAY', 'SETTINGS.ALVOLO.HOURS.WEEK_DAY.SUNDAY'];
    hourly_fractions: string[] = ['SETTINGS.ALVOLO.HOURS.FORM.HOURLY_FRACTIONS_OPTION', '0','5','10','15','20','25','30','35','40','45','50','55','60'];
    isDisabled: boolean = false;
    
    private log(...args: any[]) {
        DevLogger.log('ITEM_LABELS_DIALOG_STATE_SERVICE', ...args);
    }

    // OPEN DIALOG
    public async openDialog(config:NonNullableConfigData<AddTimeSlotParams>) {
        this.isDisabled = false;
        const {title, timeSlot, index} = config.data;
        this.time_slot_index = index !== undefined ? index : -1;
        await this.init();

        if(this.time_slot_index !== -1){
            let hidden_categories_values = 0;
            timeSlot?.hidden_categories && Object.values(timeSlot?.hidden_categories).forEach(value => {hidden_categories_values += value.length;});

            let hidden_products_values = 0;
            timeSlot?.hidden_products && Object.values(timeSlot?.hidden_products).forEach(value => {hidden_products_values += value.length;});

            this.createForm(index !== undefined, Object.keys(timeSlot?.delivery_options.distance_based_delivery_price!).length, hidden_categories_values, hidden_products_values);
        } else {
            this.createForm();
        }

        timeSlot && this._form?.patchValue(this.mappingFormValueEdit(timeSlot));

        // await this.resolveEntities();
        // this.resetInitialValues();        

        const configPortal: NonNullablePortalConfigData<AddTimeSlotOptionDialogData, AddTimeSlotDialogComponent> = {
            data: {
                component: AddTimeSlotDialogComponent,
                titleLabel: title,
                form: this._form!,
                confirmDisabled: () => this.isDisabled,
                dontCloseDialogOnConfirm$: new BehaviorSubject<boolean>(true),
            }
        };
        this.dialogRef = this.openDialogsService.openPortalDialogRef<AddTimeSlotDialogComponent, PortalConfigData<AddTimeSlotOptionDialogData, AddTimeSlotDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '800px' }),
            ...configPortal
        });

        runInInjectionContext(
            this.injector,
            ()=>{
                subscribeInComponent(
                    this._form?.controls.distance_based?.valueChanges as Observable<any>,
                    () => {
                        this._form?.controls.distance_based.value.forEach((element, index) => {
                            if (((element.distance_based_delivery_km === null || !element.distance_based_delivery_km) && (element.distance_based_delivery_price === null || !element.distance_based_delivery_price))) {
                                
                                if ((element.distance_based_delivery_km === null || !element.distance_based_delivery_km) || (element.distance_based_delivery_price === null || !element.distance_based_delivery_price)) {
                                    if (this._form?.controls.distance_based.controls[index].controls.distance_based_delivery_price) {
                                        const control = this._form?.controls.distance_based.controls[index].controls.distance_based_delivery_price;
                                        if (control.hasError('required')) {
                                            delete control.errors!['required'];
                                            control.updateValueAndValidity();
                                        }
                                    }

                                    if (this._form?.controls.distance_based.controls[index].controls.distance_based_delivery_km) {
                                        const control = this._form?.controls.distance_based.controls[index].controls.distance_based_delivery_km;
                                        if (control.hasError('required')) {
                                            delete control.errors!['required'];
                                            control.updateValueAndValidity();
                                        }
                                    }
                                }
                            }
                        })

                        this._form?.updateValueAndValidity();
                    }
                )
            }
        );

        return lastValueFrom(this.dialogRef.afterClosed()).then((res: PortalConfigData<InputItemDataMagicForm<AddTimeSlotFormValue>, AddTimeSlotDialogComponent>) => res ? this._form?.valid && this._form.value : null);
    }

    public async confirm(formValue: Partial<AddTimeSlotFormValue>) {
        this.checkFormValue(formValue);
        if(this._form?.valid) {
            this.isDisabled = true;
            this.mappingFormValueAPI(formValue);
            this.settingsAlvoloStateService.updateExistingShop(this.shop_preference).then(() => this.dialogRef!.close(this.shop_preference));
        } else {
            this._form?.markAllAsTouched();
            !this._form?.controls.operative_mode.valid && this._form?.controls.operative_mode.markAsDirty();
        }
    }

    checkFormValue(formValue: Partial<AddTimeSlotFormValue>) {
        if(formValue.distance_based !== undefined){
            formValue.distance_based.forEach((element, index) => {
                if (((element.distance_based_delivery_km === null || !element.distance_based_delivery_km) && element.distance_based_delivery_price)) {
                    this._form?.controls.distance_based.controls[index].controls.distance_based_delivery_km.setErrors({ required: true });
                } else if(element.distance_based_delivery_km && (element.distance_based_delivery_price === null || !element.distance_based_delivery_price)) {
                    this._form?.controls.distance_based.controls[index].controls.distance_based_delivery_price.setErrors({ required: true });
                }
            });
        }

        if (formValue.hidden_categories_group !== undefined) {
            formValue.hidden_categories_group.forEach((element, index) => {
                if ((element.categories === null || !element.categories) && (element.services === "delivery" || element.services === "pickup" || element.services === "takeaway")) {
                    this._form?.controls.hidden_categories_group.controls[index].controls.categories.setErrors({ required: true });
                } 
                
                if ((element.services !== "delivery" && element.services !== "pickup" && element.services !== "takeaway") && element.categories) {
                    this._form?.controls.hidden_categories_group.controls[index].controls.services.setErrors({ required: true });
                }
            });
        }

        if (formValue.hidden_products_group !== undefined) {
            formValue.hidden_products_group.forEach((element, index) => {
                if ((element.products === null || !element.products) && (element.services === "delivery" || element.services === "pickup" || element.services === "takeaway")) {
                    this._form?.controls.hidden_products_group.controls[index].controls.products.setErrors({ required: true });    
                }
                
                if ((element.services !== "delivery" && element.services !== "pickup" && element.services !== "takeaway") && element.products) {
                    this._form?.controls.hidden_products_group.controls[index].controls.services.setErrors({ required: true });
                }
            });
        }
    }

    mappingFormValueAPI(formValue: Partial<AddTimeSlotFormValue>) {
        this.shop_preference.service_time?.forEach((service_time, index) => { (!service_time.hasOwnProperty('name') || !service_time.name) && this.shop_preference.service_time?.splice(index, 1)});
        let distance_based_delivery_price_temp: {[prop in string]: PriceType} = {};
        formValue.distance_based?.forEach((distance: DistanceBasedType) => {
            (distance.distance_based_delivery_km && distance.distance_based_delivery_price) && (
            distance_based_delivery_price_temp[distance.distance_based_delivery_km] = {
                    pricelist_id: "unico",
                    price: +distance.distance_based_delivery_price!,
                    vat_price: 0,
                    vat: 0
            })
        });

        this.formatDate(formValue.date);

        if(formValue.hidden_categories_group && formValue.hidden_categories_group.length > 0)  {
            formValue.hidden_categories_group?.forEach((element, index) => {
                if(element.categories === null || (element.services !== "delivery" && element.services !== "pickup" && element.services !== "takeaway")) {
                    formValue.hidden_categories_group?.splice(index, 1);
                }
            })
        }

        if (formValue.hidden_products_group && formValue.hidden_products_group.length > 0) { 
            formValue.hidden_products_group?.forEach((element, index) => {
                if(element.products === null || element.products === undefined || (element.services !== "delivery" && element.services !== "pickup" && element.services !== "takeaway")) {
                    formValue.hidden_products_group?.splice(index, 1);
                }
            })
        }

        const timeSlotMapped: TimeTableTypeAPI = {
            name: formValue.name,
            date: formValue.date ? this.formatDate(formValue.date) : null,
            week_day: formValue.week_day ? this.mapWeekDays(+formValue.week_day, false)! : '*',
            start: formValue.start ? formValue.start : '00:00',
            end: formValue.end ? formValue.end! : '23:59',
            capacity: formValue.capacity ? +formValue.capacity : null,
            single_fraction_capacity: formValue.single_fraction_capacity ? +formValue.single_fraction_capacity : null,
            single_order_capacity: formValue.single_order_capacity ? +formValue.single_order_capacity : null,
            single_order_money_capacity: formValue.single_order_money_capacity ? +formValue.single_order_money_capacity : null,
            single_order_min_money_capacity: formValue.single_order_min_money_capacity ? +formValue.single_order_min_money_capacity : null,
            operative_mode: formValue.operative_mode ? formValue.operative_mode : [],
            hourly_fractions: formValue.hourly_fractions === null ? null : formValue.hourly_fractions === "" ? "" : +formValue.hourly_fractions!,
            max_orders_per_fraction: formValue.max_orders_per_fraction ? +formValue.max_orders_per_fraction : null,
            max_distance: formValue.max_distance ? formValue.max_distance : null,
            delivery_options: {
                delivery_price: {
                    pricelist_id: "unico",
                    price: !formValue.delivery_price ? null : +formValue.delivery_price!,
                    vat_price: 0,
                    vat: 0
                },
                delivery_free_from: formValue.delivery_free_from!,
                distance_based_delivery_price: distance_based_delivery_price_temp
            },
            hidden_categories: formValue.hidden_categories_group !== undefined && formValue.hidden_categories_group.length > 0 ? {
                ...formValue.hidden_categories_group.reduce((key, value: HiddenCategoriesType) => {
                    key[value.services] = key[value.services] || [];
                    key[value.services].push(value.categories);
                    return key;
                }, Object.create(null))
            } : null,
            hidden_products: formValue.hidden_products_group !== undefined && formValue.hidden_products_group.length > 0 ? {
                ...formValue.hidden_products_group?.reduce((key, value: HiddenProductsType) => {
                    key[value.services] = key[value.services] || [];
                    key[value.services].push(this.products.find(product => product.name === value.products)?.uuid);
                    return key;
                }, Object.create(null))
            } : null
        }
        this.time_slot_index !== -1 ? this.shop_preference.service_time![this.time_slot_index] = timeSlotMapped : this.shop_preference.service_time?.push(timeSlotMapped);
    }

    mappingFormValueEdit(formValue: TimeTableTypeAPI): TimeTableTypeModel {
        const distance_based_delivery_price_temp: DistanceBasedType[] = [];
        const distance_based_delivery_price_keys = Object.keys(formValue.delivery_options.distance_based_delivery_price).every(key => !!key) ? Object.keys(formValue.delivery_options.distance_based_delivery_price) : [];
        const distance_based_delivery_price_values = Object.values(formValue.delivery_options.distance_based_delivery_price).every(value => !!value) ? Object.values(formValue.delivery_options.distance_based_delivery_price) : [];
        if(distance_based_delivery_price_keys.length > 0 && distance_based_delivery_price_values.length > 0){
            distance_based_delivery_price_values.forEach((value, index) => {
                distance_based_delivery_price_temp.push({distance_based_delivery_km: distance_based_delivery_price_keys[index], distance_based_delivery_price: value.price})
            })
        }

        const hidden_categories_temp: HiddenCategoriesType[] = [];
        const hidden_categories_keys = formValue.hidden_categories ? Object.keys(formValue.hidden_categories) : [];
        const hidden_categories_values = formValue.hidden_categories ? Object.values(formValue.hidden_categories) : [];
        hidden_categories_keys.forEach((key, index_key) => {  
            hidden_categories_values[index_key].forEach((value, index_value) => {
                hidden_categories_temp.push({services: key as OperationMode, categories: value})
            })
        })

        const hidden_products_temp: HiddenProductsType[] = [];
        const hidden_products_keys = formValue.hidden_products ? Object.keys(formValue.hidden_products) : [];
        const hidden_products_values = formValue.hidden_products ? Object.values(formValue.hidden_products) : [];
        hidden_products_keys.forEach((key, index_key) => {  
            hidden_products_values[index_key].forEach((value, index_value) => {
                hidden_products_temp.push({services: key as OperationMode, products: this.products.find(product => product.uuid === value)?.name})
            })
        })

        const timeSlotMapped: TimeTableTypeModel = {
            name: formValue.name!,
            date: formValue.date ? new Date(+formValue.date!.split('/')[2], +formValue.date!.split('/')[1]-1, +formValue.date!.split('/')[0]) : null,
            week_day: formValue.week_day && this.mapWeekDays(+formValue.week_day, true)!,
            start: formValue.start,
            end: formValue.end,
            capacity: formValue.capacity ? formValue.capacity : null,
            single_fraction_capacity: formValue.single_fraction_capacity ? formValue.single_fraction_capacity : null,
            single_order_capacity: formValue.single_order_capacity ? formValue.single_order_capacity : null,
            single_order_money_capacity: formValue.single_order_money_capacity ? formValue.single_order_money_capacity : null,
            single_order_min_money_capacity: formValue.single_order_min_money_capacity ? formValue.single_order_min_money_capacity : null,
            operative_mode: {
                delivery: formValue.operative_mode.includes("delivery"),
                takeaway: formValue.operative_mode.includes("takeaway"),
                pickup: formValue.operative_mode.includes("pickup"),
            },
            hourly_fractions: formValue.hourly_fractions === null ? null : formValue.hourly_fractions === "" ? "" : formValue.hourly_fractions!.toString(),
            max_orders_per_fraction: formValue.max_orders_per_fraction ? formValue.max_orders_per_fraction : null,
            max_distance: formValue.max_distance ? formValue.max_distance : null,
            delivery_price: formValue.delivery_options.delivery_price.price ? formValue.delivery_options.delivery_price.price : undefined,
            delivery_free_from: formValue.delivery_options.delivery_free_from ? formValue.delivery_options.delivery_free_from : null,
            distance_based:[
                ...distance_based_delivery_price_temp
            ],
            hidden_categories_group: [
                ...hidden_categories_temp
            ],
            hidden_products_group: [
                ...hidden_products_temp
            ]   
        }
        return timeSlotMapped;
    }

    mapWeekDays(day: number, isEdit: boolean) {
        let week_day;
        switch (day) {
            //monday
            case 0:
                isEdit ? week_day = "6" : week_day = "1";
                break;
            //tuesday
            case 1:
                isEdit ? week_day = "0" : week_day = "2";
                break;
            //wednesday
            case 2:
                isEdit ? week_day = "1" : week_day = "3";
                break;
            //thursday
            case 3:
                isEdit ? week_day = "2" : week_day = "4";
                break;
            //friday
            case 4:
                isEdit ? week_day = "3" : week_day = "5";
                break;
            //saturday
            case 5:
                isEdit ? week_day = "4" : week_day = "6";
                break;
            //sunday
            case 6:
                isEdit ? week_day = "5" : week_day = "0";
                break;
        }
        return week_day;
    }

    // FUNCTIONS IN OPEN DIALOG
    private async resolveEntities(){}
    private resetInitialValues() {}
    private async init() {
        await this.entityManagerService.categories.fetchCollectionOffline().then((categories: Categories[]) => {
            categories && (this.input_choices_categories_types = categories.map(category => ({ key: category.name, value: category.id ? category.id.toString() : '' })));
        })

        await this.entityManagerService.items.fetchCollectionOffline().then((products: Items[]) => {
            if(products) { 
                this.products = products;
                this.input_choices_products_types = products.map(product => ({ key: product.name, value: product.uuid ? product.uuid.toString() : '' }));
            }
        })

        this.shop_preference = this.settingsAlvoloStateService.shop_preference!;
    }

    input_choices_operative_types: KeyValue<string, string>[]  = [ "delivery", "takeaway", "pickup" ].map(operative_mode => ({ key: operative_mode, value: operative_mode }));

    private createForm(isEdit?: boolean, delivery_options_lenght?: number, hidden_categories_lenght?: number, hidden_products_lenght?: number) {
        const days_of_weeks: KeyValue<string, string>[] = this.weekdays.map((day, i) => ({ key: day, value: i.toString()}));
        const hourly_fractions_choices: KeyValue<string, string>[] = this.hourly_fractions.map((fraction, i) => ({ key: fraction, value: i === 0 ? '' : fraction}));
        
        let default_distance_based: CustomFormGroup<CustomForm<DistanceBasedType>>[] = [];
        let default_hidden_categories: CustomFormGroup<CustomForm<HiddenCategoriesType>>[] = [];
        let default_hidden_products: CustomFormGroup<CustomForm<HiddenProductsType>>[] = [];

        if(isEdit){
            if (delivery_options_lenght !== undefined && delivery_options_lenght > 0) {
                for(let i = 0; i < delivery_options_lenght; i++){
                    default_distance_based.push(new CustomFormGroup<CustomForm<DistanceBasedType>>({
                        distance_based_delivery_km: new CustomFormControl(0 || null, {validators: [Validators.min(0)]}, {...new CustomFormControlProps(), inputType:'text' ,label:'Km', id:'input-distance_based_delivery_km', class:"tw-w-1/3"}),
                        distance_based_delivery_price: new CustomFormControl(0 || null, {validators: [Validators.min(0)]}, {...new CustomFormControlProps(), inputType:'text' ,label:'€', id:'input-distance_based_delivery_price', class:"tw-w-1/3"}),
                    }))
                }
            } else {
                default_distance_based.push(new CustomFormGroup<CustomForm<DistanceBasedType>>({
                    distance_based_delivery_km: new CustomFormControl(0 || null, {validators: [Validators.min(0)]}, {...new CustomFormControlProps(), inputType:'text' ,label:'Km', id:'input-distance_based_delivery_km', class:"tw-w-1/3"}),
                    distance_based_delivery_price: new CustomFormControl(0 || null, {validators: [Validators.min(0)]}, {...new CustomFormControlProps(), inputType:'text' ,label:'€', id:'input-distance_based_delivery_price', class:"tw-w-1/3"}),
                }))
            }

            if(hidden_categories_lenght !== undefined && hidden_categories_lenght > 0) {
                for(let i = 0; i < hidden_categories_lenght; i++){
                    default_hidden_categories.push(new CustomFormGroup<CustomForm<HiddenCategoriesType>>({
                        services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:"tw-w-1/3"}),
                        categories: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.CATEGORY', inputChoices: this.input_choices_categories_types, id:'input-hidden_categories', class:'tw-w-1/3 '}),
                    }))
                }
            } else {
                default_hidden_categories.push(new CustomFormGroup<CustomForm<HiddenCategoriesType>>({
                    services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:"tw-w-1/3"}),
                    categories: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.CATEGORY', inputChoices: this.input_choices_categories_types, id:'input-hidden_categories', class:'tw-w-1/3 '}),
                }))
            }

            if(hidden_products_lenght !== undefined && hidden_products_lenght > 0) {
                for(let i = 0; i < hidden_products_lenght; i++){
                    default_hidden_products.push(new CustomFormGroup<CustomForm<HiddenProductsType>>({
                        services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:'tw-w-1/3'}),
                        products: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.HOURS.FORM.PRODUCT', id:'input-hidden_categories_products', class:'tw-w-1/3'}),
                    }))
                }
            } else {
                default_hidden_products.push(new CustomFormGroup<CustomForm<HiddenProductsType>>({
                    services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:'tw-w-1/3'}),
                    products: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.HOURS.FORM.PRODUCT', id:'input-hidden_categories_products', class:'tw-w-1/3'}),
                }))
            }

        } else{
            default_distance_based.push(new CustomFormGroup<CustomForm<DistanceBasedType>>({
                distance_based_delivery_km: new CustomFormControl(0 || null, {validators: [Validators.min(0)]}, {...new CustomFormControlProps(), inputType:'text' ,label:'Km', id:'input-distance_based_delivery_km', class:"tw-w-1/3"}),
                distance_based_delivery_price: new CustomFormControl(0 || null, {validators: [Validators.min(0)]}, {...new CustomFormControlProps(), inputType:'text' ,label:'€', id:'input-distance_based_delivery_price', class:"tw-w-1/3"}),
            }))

            default_hidden_categories.push(new CustomFormGroup<CustomForm<HiddenCategoriesType>>({
                services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:"tw-w-1/3"}),
                categories: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.CATEGORY', inputChoices: this.input_choices_categories_types, id:'input-hidden_categories', class:'tw-w-1/3 '}),
            }))
    
            default_hidden_products.push(new CustomFormGroup<CustomForm<HiddenProductsType>>({
                services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:'tw-w-1/3'}),
                products: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.HOURS.FORM.PRODUCT', id:'input-hidden_categories_products', class:'tw-w-1/3'}),
            }))
        }

        const operative_mode_form = new CustomFormGroup<CustomForm<Pick<OperativeModeType, 'delivery' | 'takeaway' | 'pickup'>| any>>({
            delivery: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'checkbox' ,label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.DELIVERY', id:'operative_mode_delivery', focusInitial: true, class:"tw-py-2"}, ),
            takeaway: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'checkbox' ,label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.TAKEAWAY', id:'input-operative_mode_takeaway', focusInitial: true, class:"tw-py-2"}, ),
            pickup: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'checkbox' ,label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.AT_THE_TABLE', id:'input-operative_mode_pickup', focusInitial: true, class:"tw-py-2"}, ),
        }, {validators: [Validators.required, this.checkOperativeModeCheckbox]}, {...new CustomFormGroupProps(), errors:[...tilbyErrors, {key: 'atLeastOneCheckboxShouldBeChecked', value: 'SETTINGS.ALVOLO.GENERALS.AT_LEAST_ONE_OPERATIVE_MODE'}], matErrorClass:'tw-w-full tw-pb-2', label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"})
    
        this._form = new CustomFormGroup<CustomForm<AddTimeSlotFormValue>>({
            name: new CustomFormControl('', { validators: [ Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.HOURS.FORM.NAME', id:'input-name', class:'tw-w-1/3'}),
            operative_mode: operative_mode_form,
            week_day: new CustomFormControl('' || null, {validators: [Validators.min(0), Validators.max(6)]}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.WEEK_DAY', inputChoices: days_of_weeks, customActions: {suffix: {icon: 'close', callback: () => this._form?.controls.week_day?.reset()}}, id:'input-week_day',class:'tw-w-1/3'}),
            date: new CustomFormControl('' || null, {}, {...new CustomFormControlProps(), inputType:'date', label:'SETTINGS.ALVOLO.HOURS.FORM.DATE', id:'input-date', class:'tw-w-1/3'}) ,
            start: new CustomFormControl('' || null, {}, {...new CustomFormControlProps(), inputType:'time', label:'SETTINGS.ALVOLO.HOURS.FORM.START', id:'input-start', class:'tw-w-1/3'}) ,
            end: new CustomFormControl('' || null, {}, {...new CustomFormControlProps(), inputType:'time', label:'SETTINGS.ALVOLO.HOURS.FORM.END', id:'input-start', class:'tw-w-1/3'}) ,
            hourly_fractions: new CustomFormControl('' || null, {}, {...new CustomFormControlProps(), inputType:'select', inputChoices: hourly_fractions_choices, label:'SETTINGS.ALVOLO.HOURS.FORM.HOURLY_FRACTIONS', id:'input-hourly_fractions', class:'tw-w-1/3'}),
            max_orders_per_fraction: new CustomFormControl('' || null, {validators: [Validators.min(1)]}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.MAX_ORDERS_PER_FRACTIONS', id:'input-max_orders_per_fraction', class:'tw-w-1/3'}),
            single_fraction_capacity: new CustomFormControl('' || null, {validators: [Validators.min(0), Validators.max(999)]}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.SINGLE_FRACTION_CAPACITY', id:'input-single_fraction_capacity', class:'tw-w-1/3'}),
            single_order_capacity: new CustomFormControl('' || null, {validators: [Validators.min(0), Validators.max(999)]}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.SINGLE_ORDER_CAPACITY', id:'input-single_order_capacity', class:'tw-w-1/3'}),
            single_order_money_capacity: new CustomFormControl('' || null, {validators: [Validators.min(0), Validators.max(999.99)]}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.SINGLE_ORDER_MONEY_CAPACITY', id:'input-single_order_money_capacity', class:'tw-w-1/3'}),
            max_distance: new CustomFormControl('' || null, {validators: [Validators.min(0), Validators.max(999)]}, {...new CustomFormControlProps(), inputType:'number', label:'SETTINGS.ALVOLO.HOURS.FORM.MAX_DISTANCE', id:'input-max_distance', class:'tw-w-1/3'}),
            delivery_price: new CustomFormControl(0 || null, {validators: Validators.min(0)}, {...new CustomFormControlProps(), inputType:'text' ,label:'SETTINGS.ALVOLO.HOURS.FORM.DELIVERY_PRICE', id:'input-delivery_price', class:"tw-w-1/3"}),
            delivery_free_from: new CustomFormControl(0 || null, {validators: Validators.min(0)}, {...new CustomFormControlProps(), inputType:'number' ,label:'SETTINGS.ALVOLO.HOURS.FORM.DELIVERY_FREE_FROM', id:'input-delivery_free_from', class:"tw-w-1/3"}),
            distance_based: new CustomFormArray<CustomFormGroup<CustomForm<DistanceBasedType>>>(default_distance_based,
                {}, {...new CustomFormGroupProps(), label:'SETTINGS.ALVOLO.HOURS.FORM.DELIVERY_PRICE_KM', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full tw-hidden"}),
            hidden_categories_group: new CustomFormArray<CustomFormGroup<CustomForm<HiddenCategoriesType>>>(default_hidden_categories,
                {}, {...new CustomFormGroupProps(), label:'SETTINGS.ALVOLO.HOURS.FORM.HIDDEN_CATEGORIES', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full tw-hidden"}),
            hidden_products_group:  new CustomFormArray<CustomFormGroup<CustomForm<HiddenProductsType>>>(default_hidden_products,
                {}, {...new CustomFormGroupProps(), label:'SETTINGS.ALVOLO.HOURS.FORM.HIDDEN_PRODUCTS', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full tw-hidden"}),
            capacity: new CustomFormControl(0 || null, {validators: [Validators.min(0), Validators.max(999)]}, {...new CustomFormControlProps(), inputType:'number' ,label:"SETTINGS.ALVOLO.HOURS.FORM.CAPACITY", id:'input-capacity', class:"tw-w-1/3"}),
            single_order_min_money_capacity: new CustomFormControl(0 || null, {validators: [Validators.min(0), Validators.max(999.99)]}, {...new CustomFormControlProps(), inputType:'number' ,label:"SETTINGS.ALVOLO.HOURS.FORM.SINGLE_ORDER_MIN_MONEY_CAPACITY", id:'input-single_order_min_money_capacity', class:"tw-w-1/3"}),
        });
    }

    addDeliveryPriceFormGroup() {
        this._form?.controls.distance_based.push(new CustomFormGroup<CustomForm<DistanceBasedType>>({
            distance_based_delivery_km: new CustomFormControl(0 || null, {}, {...new CustomFormControlProps(), inputType:'text' ,label:'Km', id:'input-distance_based_delivery_km', class:"tw-w-1/3"}),
            distance_based_delivery_price: new CustomFormControl(0 || null, {}, {...new CustomFormControlProps(), inputType:'text' ,label:'€', id:'input-distance_based_delivery_price', class:"tw-w-1/3"}),
        }))
    }

    removeDeliveryPriceFormGroup(index: number){
        this._form?.controls.distance_based.removeAt(index);
    }

    addHiddenCategoryFormGroup() {
        this._form?.controls.hidden_categories_group.push(new CustomFormGroup<CustomForm<HiddenCategoriesType>>({
            services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:"tw-w-1/3"}),
            categories: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.CATEGORY', inputChoices: this.input_choices_categories_types, id:'input-hidden_categories', class:'tw-w-1/3 '}),
        }));
    }

    removeHiddenCategoryFormGroup(index: number){
        this._form?.controls.hidden_categories_group.removeAt(index);
    }

    addHiddenProductsFormGroup() {
        this._form?.controls.hidden_products_group.push(new CustomFormGroup<CustomForm<HiddenProductsType>>({
            services: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.ALVOLO.HOURS.FORM.SERVICE', inputChoices: this.input_choices_operative_types, id:'input-hidden_categories_services', class:'tw-w-1/3'}),
            products: new CustomFormControl(undefined, {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.HOURS.FORM.PRODUCT', id:'input-hidden_categories_products', class:'tw-w-1/3'}),
        }));
    }

    removeHiddenProductsFormGroup(index: number){
        this._form?.controls.hidden_products_group.removeAt(index);
    }

    editProductSelected(index: number, product: Items) {
        this._form?.controls.hidden_products_group.controls[index].controls.products.setValue(product.name);
    }

    checkOperativeModeCheckbox(control: AbstractControl): ValidationErrors | null {
        return control.value.delivery || control.value.takeaway || control.value.pickup ? null : { atLeastOneCheckboxShouldBeChecked: true } as ValidationErrors;
    }

    formatDate(someDateTimeStamp: any) {
        if(!someDateTimeStamp) return '';
        let dt = new Date(someDateTimeStamp);     
        return dt.getDate()+'/' + (dt.getMonth()+1) + '/'+dt.getFullYear();
    }

    get name() {
        return this._form!.controls.name;
    }

    get operativeMode() {
        return this._form!.controls.operative_mode;
    }

    get weekDay() {
        return this._form!.controls.week_day;
    }

    get date() {
        return this._form!.controls.date;
    }

    get start() {
        return this._form!.controls.start;
    }

    get end() {
        return this._form!.controls.end;
    }

    get hourlyFractions() {
        return this._form!.controls.hourly_fractions;
    }

    get maxOrdersPerFraction() {
        return this._form!.controls.max_orders_per_fraction;
    }

    get maxDistance() {
        return this._form!.controls.max_distance;
    }

    get deliveryPrice() {
        return this._form!.controls.delivery_price;
    }

    get deliveryFreeFrom() {
        return this._form!.controls.delivery_free_from;
    }

    get distanceBased() {
        return this._form!.controls.distance_based;
    }

    get singleOrderMinMoneyCapacity() {
        return this._form!.controls.single_order_min_money_capacity;
    }

    get capacity() {
        return this._form!.controls.capacity;
    }

    get hiddenCategoriesGroup() {
        return this._form!.controls.hidden_categories_group;
    }

    get hiddenProductsGroup() {
        return this._form!.controls.hidden_products_group;
    }
}