import { Printers } from "tilby-models"

export type FilterStartingWith<Set, Needle extends string> = Set extends `${Needle}${infer _X}` ? Set : never;
export type RemoveFirstWord<Set, Needle extends string> = Set extends `${Needle}${infer X}` ? X : never;
export type PrinterDocumentTypeId = RemoveFirstWord<PrinterDocumentTypeCheck, 'print_'>;
export type PrinterDocumentTypeCheck = FilterStartingWith<keyof Printers, 'print_'>;

export type PrinterDocumentType = {
	id: PrinterDocumentTypeId | 'credit_note' | 'expense_report',
	check?: PrinterDocumentTypeCheck,
	printers: ('fiscal' | 'rt' | 'receipt')[]
}

export const documentTypes: PrinterDocumentType[] = [
	{
		"id": "fiscal_receipt",
		"check": "print_fiscal_receipt",
		"printers": ["fiscal", "rt"]
	}, {
		"id": "e_invoice",
		"check": "print_e_invoice",
		"printers": ["fiscal", "rt", "receipt"]
	}, {
		"id": "receipt",
		"check": "print_receipt",
		"printers": []
	}, {
		"id": "invoice",
		"check": "print_invoice",
		"printers": ["fiscal", "rt"]
	}, {
		"id": "receipt_invoice",
		"check": "print_receipt_invoice",
		"printers": ["fiscal"]
	}, {
		"id": "summary_invoice",
		"check": "print_summary_invoice",
		"printers": ["fiscal"]
	}, {
		"id": "summary_e_rc",
		"check": "print_summary_e_rc",
		"printers": ["fiscal", "rt", "receipt"]
	}, {
		"id": "summary_e_nrc",
		"check": "print_summary_e_nrc",
		"printers": ["fiscal", "rt", "receipt"]
	}, {
		"id": "shipping_invoice",
		"check": "print_shipping_invoice",
		"printers": ["fiscal"]
	}, {
		"id": "generic_receipt",
		"check": "print_generic_receipt",
		"printers": ["receipt"]
	}, {
		"id": "generic_invoice",
		"check": "print_generic_invoice",
		"printers": ["receipt"]
	}, {
		"id": "generic_document",
		"check": "print_generic_document",
		"printers": ["receipt"]
	}
]