import { OnInit, Input, Output, EventEmitter, Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { restManager } from "app/ajs-upgraded-providers";
import { ShopChain, User} from "src/app/models";
import { CustomersFields } from "../../customer-form/customers-form.model";
import {
    CustomersPointsFormGroups,
    CustomersPointsForm,
} from "./custumer-fidelity-points-form.model";
import _ from "lodash";
import {EntityManagerService} from "src/app/core/services/entity/entity-manager.service";
import { ChainCampaigns, FidelitiesPoints } from "tilby-models";

@Component({
    selector: 'app-customer-fidelity-points-form',
    templateUrl: './customer-fidelity-points-form.component.html',
    styleUrls: ['./customer-fidelity-points-form.component.scss'],
    host: {style: 'width:100%'},
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerFidelityPointsFormComponent implements OnInit {

    pointsForm?: FormGroup<CustomersPointsFormGroups>;

    @Input() user?: User;
    @Input() customer?: CustomersFields;
    @Input() chainShops?: ShopChain[];
    @Input() campaigns?: ChainCampaigns[];
    _selectedCampaign?: ChainCampaigns;
    @Input() set selectedCampaignId(selectedCampaignId: number | undefined | null) {
        this._selectedCampaign = this.campaigns?.find(c => c.id === selectedCampaignId)
        if (this._points?.length) this.onCampaignChanged();
    };
    private _points: (FidelitiesPoints[] | undefined) = [];
    selectedPointsToShow = 0;
    @Input() set points(pointsArray: (FidelitiesPoints[] | undefined)){
        this._points = pointsArray;
        if(this._selectedCampaign) this.onCampaignChanged();
    }


    @Output() submit = new EventEmitter<FormGroup<CustomersPointsFormGroups>>();

    constructor(private entityManager: EntityManagerService,
                @Inject(restManager) private restManager: any) {
    }

    onCampaignChanged(){
            this.selectedPointsToShow = this._points?.find(p => p.campaign_id === this._selectedCampaign?.id)?.points || 0;
    }

    ngOnInit(): void {
        this.createForm();
        this.initChainShops();
    }

    createForm() {
        this.pointsForm = new FormGroup<CustomersPointsFormGroups>({
            customersPointsForm: new FormGroup<CustomersPointsForm>({
                date_since: new FormControl(null),
                date_max: new FormControl(null),
                campaign_id: new FormControl(this._selectedCampaign?.id||null),
                db_name: new FormControl(null)
            })
        })
    }

    initChainShops() {
        this.pointsForm?.patchValue({customersPointsForm: {'db_name': '-1'}});
    }

    currentShop(): string {
        return this.user?.shop?.name || '-1';
    }

    get campaign_id() {
        return this.pointsForm?.controls.customersPointsForm.controls.campaign_id;
    }

    onCancelDateRange() {
        this.pointsForm?.controls.customersPointsForm.controls.date_since?.setValue(null);
        this.pointsForm?.controls.customersPointsForm.controls.date_max?.setValue(null);
        this.submit.emit(this.pointsForm);
    }

    dateChange($event: any) {
        this.submit.emit(this.pointsForm);
    }
}
