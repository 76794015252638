<ng-container class="tw-flex tw-flex-col">
    <form class="tw-flex tw-flex-col tw-gap-2 the-dense-zone" [formGroup]="currencyConverterForm">
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <span>{{data.final_amount | tilbyCurrency}}</span>
            <span class="tw-flex">{{'CASHREGISTER.CURRENCY_CONVERTER.IN' | translate}}</span>
            <div>
                <span class="tw-mr-2">{{secondaryFinalAmount | tilbyCurrency : '' : secondaryDigits }}</span>
                <mat-form-field>
                    <mat-select required formControlName="secondary_currency">
                        <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="tw-flex tw-items-center">
            <span>{{ 1 | tilbyCurrency : secondaryCurrencyCode : secondaryDigits }} =</span>
            <mat-form-field required class="tw-mx-2">
                <input matInput type="number" min="0" formControlName="secondary_exchange_rate" />
            </mat-form-field>
            <span>{{data.currency}}</span>
        </div>
    </form>
</ng-container>