import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, KeyValue } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: "app-settings-alvolo-options-list",
    templateUrl: "./settings-alvolo-options-list.component.html",
    styleUrls: ["./settings-alvolo-options-list.component.scss"],
    standalone: true,
    imports: [CommonModule, ScrollingModule, TranslateModule, MatButtonModule, MatButtonToggleModule]
})
export class SettingsAlvoloOptionsComponent {
    selectedValue: string = "SETTINGS.ALVOLO.OPTIONS_LIST.SUMMARY";
    options: Record<number, string> = {
        0: "SETTINGS.ALVOLO.OPTIONS_LIST.SUMMARY",
        1: "SETTINGS.ALVOLO.OPTIONS_LIST.GENERALS",
        2: "SETTINGS.ALVOLO.OPTIONS_LIST.HOURS",
        3: "SETTINGS.ALVOLO.OPTIONS_LIST.MANAGEMENT",
        4: "SETTINGS.ALVOLO.OPTIONS_LIST.PAYMENTS",
        6: "SETTINGS.ALVOLO.OPTIONS_LIST.SELF_ORDERING",
        7: "SETTINGS.ALVOLO.OPTIONS_LIST.ROOM_TABLES",
    };
    @Output() optionEmitter: EventEmitter<{ key: number; value: string }> = new EventEmitter();

    onItemClick(item: KeyValue<string, string>) {
        this.optionEmitter.emit({ key: +item.key, value: item.value });
    }
}
