<mat-progress-bar mode="query" *ngIf="isProcessing"></mat-progress-bar>
<app-grid-server-side
    #appGridComponent
    (areRowsSelected)="isSelected=$event"
    (clickableAction)="actions($event)"
    [fixedButtons]="fixedButtons"
    [cacheBlockSize]="query.per_page"
    [datasource]="datasource"
    [tableData]="tableData[0]"
    (updateChange)="updateItems($event)"
    [exportFormatterOptions]="exportFormatterOptions"
    feature="giftcards"
></app-grid-server-side>
<div class="container-canvas"></div>
