import { Inject, Injectable, inject } from "@angular/core";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../../services";
import { MatDialogRef } from "@angular/material/dialog";
import { SatispayDialogModel } from "./satispay-dialog.model";
import { MagicPortalDialogComponent } from "../../magic-portal-dialog";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { SatispayDialogComponent } from "./satispay-dialog.component";
import { DevLogger } from "src/app/shared/dev-logger";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { Validators } from "@angular/forms";
import { restManager } from "app/ajs-upgraded-providers";

type SatispayDialogFormValue = { token: string };
export type SatispayDialogForm = CustomFormGroup<CustomForm<SatispayDialogFormValue>>;
export type SatispayDialogParams = SatispayDialogModel;
export type SatispayDialogData = InputItemDataMagicForm<SatispayDialogFormValue>;
@Injectable({
    providedIn: 'root'
})
export class SatispayDialogService {
    private readonly openDialogsService = inject(OpenDialogsService);
    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<SatispayDialogComponent>>;
    private _form?: SatispayDialogForm;

    constructor(
        @Inject(restManager) private restManager: any,
    ){}

    private log(...args: any[]) {
        DevLogger.log("ITEM_LABELS_DIALOG_STATE_SERVICE", ...args);
    }

    // OPEN DIALOG
    public async openDialog(config: NonNullableConfigData<SatispayDialogParams>) {
        const {title} = config.data;
        // await this.resolveEntities();
        // this.resetInitialValues();
        // this.init();
        this.createForm();

        const configPortal: NonNullablePortalConfigData<SatispayDialogData,SatispayDialogComponent> = {
            ...config,
            data: {
                component: SatispayDialogComponent,
                titleLabel: title,
                form: this._form!,
                confirmDisabled: () => !this._form?.valid,
                dontCloseDialogOnConfirm$: new BehaviorSubject<boolean>(true),
            },
        };
        this.dialogRef = this.openDialogsService.openPortalDialogRef<SatispayDialogComponent,PortalConfigData<SatispayDialogData, SatispayDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({
                width: "800px",
            }),
            ...configPortal,
        });
        return lastValueFrom(this.dialogRef.afterClosed()).then((res: PortalConfigData<InputItemDataMagicForm<SatispayDialogFormValue>, SatispayDialogComponent>) => res ? this._form?.valid && this._form.value : null);
    }

    // FUNCTIONS IN OPEN DIALOG
    private async resolveEntities() {}
    private resetInitialValues() {}
    private init() {}
    private createForm() {
        this._form = new CustomFormGroup<CustomForm<SatispayDialogFormValue>>({
            token: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'Token', id:'input-token', class:'tw-w-1/3 '}),
        })
    }

    public confirm(formValue: Partial<SatispayDialogFormValue>) {
        this.restManager.post('alvolo/api/satispay/config', {payload: formValue})
        .then((result:any) => {
            if(result) {
                this.openDialogsService.openAlertDialog({ data: { messageLabel: `SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.SAVED` } })
                this.dialogRef?.close(formValue)
            }
        })
        .catch((error:any) => {
            error.data.error.error.message.includes('Unable to update the satispay configuration of the shop')
            && this.openDialogsService.openAlertDialog({ data: { messageLabel: `SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.ERROR` }});
        })
    }

    get token() {
        return this._form!.controls.token;
    }

    // FUNTIONS IN CREATE FORM
}
