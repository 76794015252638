<div class="tw-w-max tw-flex tw-flex-wrap">
    <span class="tw-text-sm tw-mr-8">{{ data.title | translate: data.titleParams}}</span>
    <span>
        <span class="tw-p-0 tw-leading-[0] tw-text-sm tw-mr-4" mat-button (click)="dismiss()">
            {{data.actionDismiss| translate}}
        </span>
        <span class="tw-p-0 tw-leading-[0] tw-text-sm tw-text-[#448AFF]" mat-button (click)="dismissWithAction()">
            {{data.actionDismissWithAction| translate}}
        </span>
    </span>
</div>
