import * as angular from 'angular';

angular.module('promotions').directive('topbarPromotions', ["$mdSidenav", "connection", "offlineDialog", "labelsEditorDialog", function($mdSidenav, connection, offlineDialog, labelsEditorDialog) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-promotions.html'),
        controller: ["$scope", "$element", "$attrs", function($scope, $element, $attrs) {
            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openTemplateEditor = function() {
                labelsEditorDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            $scope.onBarcodeScanned = function(result) {
                $scope.context.onSubmitBarcode(result);
            };

            $scope.goBack = function() {
                $scope.context.goBack();
            };
        }]
    };
}]);