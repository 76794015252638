import * as angular from 'angular';
import * as _ from 'lodash';
import { Orders, Printers } from 'tilby-models';

export class KDSManager {
    constructor(
        private $http: angular.IHttpService,
        $rootScope: angular.IRootScopeService,
        private entityManager: any
    ) {

        //When an order is deleted, delete it from the KDS
        $rootScope.$on('storage-updated:orders', async (event, data: any) => {
            if (data.action == 'DELETED' && data.entity?.deleted) {
                const KDSToSend = await entityManager.printers.fetchCollectionOffline({ type: 'kds' }) as Printers[];

                for (const kds of KDSToSend) {
                    try {
                        await this.deleteOrder(kds, data.entity.uuid);
                    } catch (err) { }
                }
            }
        });
    }

    private cleanupOrder(order: Orders): Orders {
        return _.chain(order).omit(['previous_order']).cloneDeep().value();
    }

    public async deleteOrder(kds: Printers, orderUuid: string): Promise<void> {
        switch (kds.driver) {
            case 'http':
            default:
                await this.$http({
                    method: 'DELETE',
                    timeout: 10000,
                    url: `http://${kds.ip_address}/orders/${orderUuid}`
                });
                break;
        }
    }

    public async sendOrders(kds: Printers, orders: Orders[]): Promise<void> {
        switch (kds.driver) {
            case 'http':
            default:
                const payload = orders.map(order => this.cleanupOrder(order));

                await this.$http({
                    data: payload,
                    method: 'POST',
                    timeout: 10000,
                    url: `http://${kds.ip_address}/orders`
                });
                break;
        }
    }

    /**
     * Send all open orders to KDS
     */
    public async init() {
        const kdsList = await this.entityManager.printers.fetchCollectionOffline({ type: 'kds' }) as Printers[];
        const orders = await this.entityManager.orders.fetchCollectionOffline({ status: 'open' }) as Orders[];

        for (const kds of kdsList) {
            try {
                await this.sendOrders(kds, orders);
            } catch (err) { }
        }
    }
}

KDSManager.$inject = ['$http', '$rootScope', 'entityManager'];

angular.module('printers').service('KDSManager', KDSManager);