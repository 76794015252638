import * as angular from 'angular';
import * as _ from 'lodash';
import {map, Subject} from 'rxjs';
import { CashdrawerDriver, CashdrawerDriverCommand, CashdrawerDriverController } from 'src/app/shared/model/cash-drawer.model';

type CimaCashdrawerErrorDescription = (
    'genericError' | //Generic error
    'noError' | //No error occurred
    'busy' | //If the device is used by another user
    'userMandatory' | //Not authorized, login required
    'commandFailed' | //Command has failed
    'machineNotReady' | //Application not ready, please try again in a while
    'requestedDispensationNotAvailable' | //The requested dispensation is not available, check the device availability
    'minimumKitDisabled' | //The minimum kit functionality has not been enabled
    'minimumKitNotConfigured' | //The minimum kit has not been configured
    'exception' | //Software exception
    'onlyServiceAllowed' | //Only service allowed to do requests
    'loginFailed' | //Login failed
    'outputTrayOccupied' | //Banknotes module output tray occupied
    'dispensedMoreThanRequested' | //The device has dispensed more than the requested quantities due to a temporary error
    'notFound' | //Nothing found
    'invalidParameters' //Invalid parameters
    )

type CimaCashdrawerHttpError = {
    status: number,
    data: CimaCashdrawerRequestError
}

type CimaCashdrawerRequestError = {
    errorDescription: CimaCashdrawerErrorDescription,
    exception?: String
}

type CimaCashdrawerPaymentRequest = {
    amount: number
    currency: string
}

type CimaCashdrawerLoginRequest = {
    appId: string
    username: string
}

type CimaCashdrawerLoginResponse = {
    token: string
}

type CimaCashdrawerDenomination = {
    currency: string
    value: number
    cashtype: 'coin' | 'banknote'
}

type CimaCashdrawerCountingInfo = {
    denomination: CimaCashdrawerDenomination,
    counting: number
}

type CimaCashdrawerTotalInfo = {
    currency: string,
    amount: number
}

type CimaCashdrawerStatusResponse = {
    depositCounting?: CimaCashdrawerCountingInfo[]
    depositTotal: (undefined | CimaCashdrawerTotalInfo)[]
    dispenseCounting?: CimaCashdrawerCountingInfo[]
    dispenseTotal: (undefined | CimaCashdrawerTotalInfo)[]
    errorDescription: CimaCashdrawerErrorDescription
    missingAmount: CimaCashdrawerTotalInfo
    paymentStatus: (
        'depositRunning' | //deposit in progress
        'depositJammed' | //a jam occurred during the deposit phase
        'depositDone' | //Deposit completed with success
        'depositNotesIn' | //the user should insert again cash
        'depositRemoveNotesOut' | //the user should remove the rejected cash from the output tray
        'dispenseRunning' | //dispensation in progress
        'dispenseJammed' | //a jam occurred during the dispense phase
        'dispenseDone' | //dispensation completed
        'removeTranche' | //the user should remove the dispensed cash from the output tray
        'notAvailable' | //not available
        'faulted' | //payment faulted
        'unknown' //status unknown
        )
    status: (
        'unknown' | //status unknown
        'idle' | //not yet started
        'starting' | //initializing the operation
        'running' | //operation in progress
        'faulted' | //operation ended with an error
        'completed' //operation completed successfully
        )
}

type CimaCashdrawerStatus = CimaCashdrawerStatusResponse & {
    transactionCanceled: boolean
}

export class CimaCashdrawerDriver implements CashdrawerDriver {
    constructor(
        private $rootScope: any,
        private $http: any
    ) {
    }

    private handleHttpError(error: CimaCashdrawerHttpError) {
        if(error.status === -1) {
            return {errorDescription: 'CONNECTION_ERROR'};
        }

        return error.data;
    }

    private async sendRequest(baseUrl: string, endpoint: string, method: string, accessToken?: string, payload?: any): Promise<any> {
        let requestConfig = {
            data: payload,
            method: method,
            timeout: 10000,
            url: `${baseUrl}${endpoint}`
        };

        if (accessToken) {
            Object.assign(requestConfig, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }

        return this.$http(requestConfig);
    }


    private async performLogin(baseUrl: string): Promise<string> {
        let loginPayload: CimaCashdrawerLoginRequest = {
            appId: 'Tilby',
            username: this.$rootScope.userActiveSession.username
        };

        try {
            let answer: { data: CimaCashdrawerLoginResponse } = await this.sendRequest(baseUrl, '/Auth/login', 'POST', undefined, loginPayload);

            return answer.data.token;
        } catch (error: any) {
            throw this.handleHttpError(error);
        }
    }

    private async requestPayment(amount: number, baseUrl: string, accessToken: string) {
        let paymentPayload: CimaCashdrawerPaymentRequest = {
            currency: 'EUR',
            amount: amount
        }

        try {
            await this.sendRequest(baseUrl, '/operation/Payment/start', 'POST', accessToken, paymentPayload);
        } catch (error: any) {
            throw this.handleHttpError(error);
        }
    }

    private async transactionStatusLoop(baseUrl: string, accessToken: string, statusSubject: Subject<CimaCashdrawerStatus>, controllerSubject: Subject<CashdrawerDriverCommand>) {
        let data: CimaCashdrawerStatus | null = null;
        let transactionStatus: ('ACTIVE' | 'ABORTING' | 'ABORTED') = 'ACTIVE';

        controllerSubject.subscribe({
            next: async (command) => {
                switch (command) {
                    case 'ABORT_TRANSACTION':
                        if (transactionStatus === 'ACTIVE') {
                            transactionStatus = 'ABORTING';

                            try {
                                await this.sendRequest(baseUrl, '/operation/Payment/end', 'POST', accessToken, {rollback: true})
                            } catch (err) {
                                transactionStatus = 'ACTIVE';
                            }

                            transactionStatus = 'ABORTED';
                        }

                        break;
                }
            }
        })

        //Ask transaction status until the transaction ends with "completed" or "faulted"
        do {
            //Wait 1 second if this isn't the first status request
            if (data) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

            try {
                let answer: { data: CimaCashdrawerStatusResponse } = await this.sendRequest(baseUrl, '/operation/Payment/status', 'GET', accessToken);

                data = Object.assign(answer.data, {
                    transactionCanceled: ['ABORTED', 'ABORTING'].includes(transactionStatus)
                });

                statusSubject.next(data);
            } catch (error: any) {
                throw this.handleHttpError(error);
            }
        } while (data && !['completed', 'faulted'].includes(data.status));

        //Throw the error if is a critical one (some errors don't compromise the transaction outcome)
        if (data && data.status === 'faulted' && !['requestedDispensationNotAvailable'].includes(data.errorDescription)) {
            throw data;
        }
    }

    private async startTransaction(amount: number, baseUrl: string, statusSubject: Subject<CimaCashdrawerStatus>, controllerSubject: Subject<CashdrawerDriverCommand>) {
        try {
            //Login and obtain session token
            const accessToken = await this.performLogin(baseUrl);

            //Send payment request
            await this.requestPayment(amount, baseUrl, accessToken);

            //Start transaction loop
            await this.transactionStatusLoop(baseUrl, accessToken, statusSubject, controllerSubject);

            //Send completion event
            statusSubject.complete();
        } catch (error: any) {
            if (error?.errorDescription) {
                error = `CIMA_CASHDRAWER.${_.chain(error.errorDescription).snakeCase().toUpper().value()}`;
            }

            statusSubject.error(error);
        } finally {
            controllerSubject.complete();
        }
    }

    public performPayment(amount: number, ipAddress: string): CashdrawerDriverController {
        const baseUrl = `https://${ipAddress}/api/v1`;

        const statusSubject = new Subject<CimaCashdrawerStatus>();
        const controllerSubject = new Subject<CashdrawerDriverCommand>();

        this.startTransaction(amount, baseUrl, statusSubject, controllerSubject);

        const transactionStatus = statusSubject.asObservable().pipe(
            map((value: CimaCashdrawerStatus) => {
                let response = {
                    toPay: amount,
                    paid: _.toFinite(value.depositTotal[0]?.amount),
                    changeGiven: _.toFinite(value.dispenseTotal[0]?.amount),
                    changeNotGiven: 0,
                    message: `DIGITAL_PAYMENTS.CIMA_CASHDRAWER.PAYMENT_STATUSES.${_.chain(value.paymentStatus).snakeCase().toUpper().value()}`,
                    canceled: value.transactionCanceled
                }

                if (value.errorDescription === 'requestedDispensationNotAvailable') {
                    response.changeNotGiven = (response.paid - response.toPay) - response.changeGiven;
                }

                return response;
            })
        );

        return {
            transactionStatus: transactionStatus,
            driverController: controllerSubject
        }
    }
}

CimaCashdrawerDriver.$inject = ['$rootScope', '$http'];

angular.module('digitalPayments').service('CimaCashdrawerDriver', CimaCashdrawerDriver);
