import * as angular from 'angular';
import { StateParams, StateProvider } from 'angular-ui-router';
import * as _ from 'lodash';
import { ConfigurationManagerService } from 'src/app/core';

angular.module('fileImporterNew', ['application']);

angular.module('fileImporterNew').config(["$stateProvider", function($stateProvider: StateProvider) {

    $stateProvider.state('app.new.file_importer', {
        url: '/file-importer',
        redirectTo: 'app.new.file_importer.showcase',
    });

    $stateProvider.state('app.new.file_importer.showcase', {
        url: '/showcase',
        component:"appFileImporter",
    });

    $stateProvider.state('app.new.file_importer.add', {
        params: {
            fileConfig: null
        },
        url: '/add',
        template: "<app-file-importer-add [file-config]='$resolve.fileConfig'></app-file-importer-add>",
        resolve: {
            fileConfig: ["$stateParams", "checkManager", function($stateParams: StateParams, checkManager: ConfigurationManagerService) {
                return _.defaults($stateParams.fileConfig || {}, {
                    upload: {
                        mode: 'file',
                        url: null
                    },
                    type: 'xls',
                    typeInsert: 'items',
                    decimalSeparator: checkManager.getPreference('file_importer.decimal_separator') || null,
                    delimitatorColumn:  checkManager.getPreference('file_importer.delimitator_column') || null,
                    separationFixed: checkManager.getPreference('file_importer.separation_fixed') || null,
                    delimitatorString:  checkManager.getPreference('file_importer.delimitator_string') || null,
                    sheetNumber:1 
                });
            }]
        }
    });

    $stateProvider.state('app.new.file_importer.fields', {
        url: '/fields/:id',
        params: {
            id: null,
            fileConfig: null,
        },
        template: "<app-file-importer-fields [file-config]='$resolve.fileConfig' [file-schema]='$resolve.fileSchema'></app-file-importer-fields>",
        resolve: {
            fileSchema: ["restManager", "$stateParams", function(restManager: any, $stateParams: StateParams) {
                return restManager.getOne('importing', 'schema', { id: $stateParams.id });
            }],
            fileConfig: ["$stateParams", function($stateParams: StateParams) {
                return _.defaults($stateParams.fileConfig || {}, {
                    id: _.toInteger($stateParams.id),
                    primaryKey: null,
                    overwriteMethod: 'onlyFields',
                });
            }]
        }
    });

    $stateProvider.state('app.new.file_importer.schedule', {
        url: '/schedule/:id',
        params: {
            id: null,
            fileConfig: null,
        },
        template: "<app-file-importer-schedule [file-config]='$resolve.fileConfig'></app-file-importer-schedule>",
        resolve: {
            fileConfig: ["$stateParams", function($stateParams: StateParams) {
                return _.defaults($stateParams.fileConfig || {}, {
                    id: _.toInteger($stateParams.id),
                    name: null,
                    email: null,
                    executionTime: null,
                    executionTimePeriods: []
                });
            }]
        }
    });
}]);