import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('orders').controller('OrdersShowcaseCtrl', OrdersShowcaseCtrl);

OrdersShowcaseCtrl.$inject = ["$scope", "$rootScope", "$state", "$timeout", "$mdMedia", "$filter", "$translate", "connection", "entityManager", "checkManager", "restManager", "stockDictionary", "toast", "util", "showOrderItem", "items", "categories", "ActiveOrder", "addNewItemToOrder", "alertDialog", "confirmDialog"];

function OrdersShowcaseCtrl($scope, $rootScope, $state, $timeout, $mdMedia, $filter, $translate, connection, entityManager, checkManager, restManager, stockDictionary, toast, util, showOrderItem, items, categories, ActiveOrder, addNewItemToOrder, alertDialog, confirmDialog) {
    const visibleCategories = _(categories).sortBy('index').filter({ display: true }).value();
    const hideEmptyCategories = checkManager.getPreference('showcase_hide_empty_categories');

    Object.assign($scope, {
        canAddNewItem: checkManager.isUserPermitted("cashregister.add_new_item"),
        itemsFavorite: items.favorite,
        visibleCategories: visibleCategories,
        visibleItems: [],
        searchResults: {},
        searchText: "",
        showStockOnShowcase: !!checkManager.getPreference('cashregister.show_stock_on_showcase'),
        topbar_context: {
            categoryView: checkManager.getPreference("showcase_category_view") || 'tab', // tab grid
            order_by: checkManager.getPreference("showcase_order_by") || '+name', // +name +price -price
            showcaseMode: checkManager.getPreference("showcase_mode") || 'photoGrid', //photoGrid noPhotoGrid list
            showUnavailable: checkManager.getPreference("showcase_show_unavailable") === false ? false : true,
            displayMode: checkManager.getPreference("showcase_display_mode") || 'normal',
            priceList: util.getCurrentPriceList(),
            showSearch: false,
            isShowcaseModeEnabled: function(mode) {
                return mode === 'list' || _.size($scope.visibleItems) < 500;
            }
        }
    });

    $scope.showcaseView = $scope.topbar_context.categoryView === 'tab' ? 'categoryTab' : 'categories';

    var stockToast = null;
    var countNoStockAsUnavailable = checkManager.getPreference('cashregister.count_nostock_as_unavailable');

    if(!_.isEmpty(items.null) || _.isEmpty($scope.visibleCategories)) {
        $scope.visibleCategories.push({
            name: $translate.instant('ORDERS.SHOWCASE.NO_CATEGORY'),
            id: "null"
        });
    }

    var categoriesById = _.keyBy($scope.visibleCategories, 'id');

    $scope.getItemStockStatus = function(item) {
        if ($scope.showStockOnShowcase) {
            var itemStock;
            if (!_.isEmpty(item.stock_type) && (itemStock = stockDictionary.get('item_id_' + item.id))) {
                switch (itemStock.available) {
                    case 'available': case 'producible':
                        return 'stock-info-available';
                    case 'unavailable':
                        return 'stock-info-unavailable';
                    case 'alert':
                        return 'stock-info-alert';
                    default:
                        return 'stock-info-nostock';
                }
            } else {
                return 'stock-info-nostock';
            }
        } else {
            return 'stock-disabled';
        }
    };

    var priceListStr = 'price' + $scope.topbar_context.priceList;

    const itemFilterFunction = (item) => {
        let stockCheck = $scope.topbar_context.showUnavailable ? true : (countNoStockAsUnavailable ? !_.includes(['stock-info-nostock', 'stock-info-unavailable'], $scope.getItemStockStatus(item)) : ($scope.getItemStockStatus(item) !== 'stock-info-unavailable'));
        return stockCheck && Number.isFinite(item[priceListStr]);
    };

    var filterVisibleItems = function() {
        var orderBy = {
            field: _.startsWith($scope.topbar_context.order_by, 'price', 1) ? 'price' + $scope.topbar_context.priceList : _.trim($scope.topbar_context.order_by, '+-'),
            order: $scope.topbar_context.order_by[0] === '-' ? "desc" : "asc"
        };

        $scope.visibleItems = _($scope.itemsCategory).filter(itemFilterFunction).orderBy([orderBy.field], [orderBy.order]).value();
    };

    const filterVisibleCategories = () => {
        $scope.visibleCategories = visibleCategories.filter((category) => {
            if(Array.isArray(items[category.id])) {
                const itemsInCategory = items[category.id].filter(itemFilterFunction);

                return itemsInCategory.length > 0;
            }
        });

        updateCategoryBarHeight();

        if($scope.selectedCategory) {
            if(!$scope.visibleCategories.includes($scope.selectedCategory) && $scope.visibleCategories[0]) {
                $scope.selectCategory($scope.visibleCategories[0]);
            } else {
                $scope.selectCategory($scope.selectedCategory);
            }
        } else {
            filterVisibleItems();
        }
    };

    $scope.$watch('topbar_context.priceList', function(newPriceList, oldPriceList) {
        if(newPriceList !== oldPriceList) {
            priceListStr = 'price' + newPriceList;
            if(hideEmptyCategories) {
                filterVisibleCategories();
            } else {
                filterVisibleItems();
            }
        }
    });

    ActiveOrder.getOrderPricelist().then((pricelist) => {
        if(pricelist) {
            $scope.topbar_context.priceList = pricelist;
        }
    });

    //Triggered by manual priceList changes
    $scope.$on("orders:pricelist-changed", function() {
        if(!ActiveOrder.isEmpty()) {
            confirmDialog.show($translate.instant('ORDERS.SHOWCASE.PRICELIST_APPLY_CONFIRM')).then(function(answer) {
                if(answer) {
                    ActiveOrder.applyPriceList($scope.topbar_context.priceList);
                }
            });
        }
    });

    $scope.$watch('topbar_context.order_by', function(newOrder, oldOrder) {
        filterVisibleItems();
    });

    $scope.$watch('topbar_context.showUnavailable', function(newSetting, oldSetting) {
        if(hideEmptyCategories) {
            filterVisibleCategories();
        } else {
            filterVisibleItems();
        }
    });

    var updateCategoryBarHeight = function() {
        var categoriesPerRow = $scope.bigTablet ? 7 : 5;
        var visibleCategoriesLen = $scope.visibleCategories.length;

        var rows = _.ceil((visibleCategoriesLen) / categoriesPerRow);
        if (rows > 3) {
            rows = 3;
        }
        $scope.categoryBarHeight = {
            'min-height': (rows * 50) + 'px',
            'max-height': (rows * 50) + 'px'
        };
    };

    $scope.$watch(function() {
        return $mdMedia('(min-width: 1024px)');
    }, function(big) {
        $scope.bigTablet = big;
        updateCategoryBarHeight();
    });
    $scope.bigTablet = $mdMedia('(min-width: 1024px)');
    updateCategoryBarHeight();

    $scope.isCategoryTabMode = function() {
        return $scope.topbar_context.categoryView === 'tab';
    };

    $scope.isCategoryGridMode = function() {
        return $scope.topbar_context.categoryView === 'grid';
    };

    $scope.isDisplayModeNormal = function() {
        return $scope.topbar_context.displayMode === 'normal';
    };

    $scope.isDisplayModeCompact = function() {
        return $scope.topbar_context.displayMode === 'compact';
    };

    $scope.getItemPriceList = function(item) {
        return item[priceListStr];
    };

    $scope.hasVisibleItems = function() {
        return (!_.isEmpty($scope.visibleItems));
    };

    $scope.goToSection = function(section) {
        $scope.topbar_context.showcaseView = section;
        switch (section) {
            case 'favorites':
                $scope.goToCategory('favorite');
                break;
            case 'categories':
                var currentCategory = $scope.selectedCategory ? $scope.selectedCategory.id : 'null';
                $scope.goToCategory(currentCategory);
                $timeout(function() {
                    const currentCategoryElement = document.getElementById(`cat_${currentCategory}`);

                    if(currentCategoryElement) {
                        currentCategoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                });
                break;
            default:
                break;
        }
    };

    $scope.selectCategory = function(category) {
        $scope.selectedCategory = category;
        $timeout(function() {
            $scope.goToCategory(category.id);
        });
    };

    $scope.isSelectedCategory = function(category) {
        return $scope.selectedCategory === category;
    };

    $scope.goToCategoryTab = function(category) {
        $scope.topbar_context.showcaseView = 'categoryTab';
        $scope.selectCategory(category);
    };

    $scope.goToCategory = function(id) {
        $scope.itemsCategory = [];

        switch (id) {
            case 'favorite':
                $scope.itemsCategory = $scope.itemsFavorite;
                break;
            default:
                $scope.itemsCategory = items[id] || [];
                break;
        }

        filterVisibleItems();

        $scope.topbar_context.showcaseMode = _.size($scope.visibleItems) < 500 ? (checkManager.getPreference("showcase_mode") || 'photoGrid') : 'list';

        $timeout(function() {
            $(".md-virtual-repeat-scroller").scrollTop(0);
            $(".items-grid-list").scrollTop(0);
        });
    };

    $scope.showItem = function(item) {
        showOrderItem.show(item).then(function() {
            $scope.addItemToOrder(item);
        });
    };

    const checkItemBeforeAdd = async (item) => {
        if (!item.vat_perc && !item.department) {
            alertDialog.show($translate.instant('ORDERS.SHOWCASE.ITEM_VAT_NOT_FOUND'));
            throw 'ITEM_VAT_NOT_FOUND';
        }

        if(item.stock_type === 'bom' && item.manual_production && connection.isOnline()) {
            let itemStock = stockDictionary.get(`item_id_${item.id}`);

            if(_.isFinite(itemStock?.stock_quantity) && item.quantity_alert && itemStock.stock_quantity < 1) {
                let itemStockBackup = _.cloneDeep(itemStock);
                let multiplier = item.quantity_alert || 1;
                let loadQuantity = Math.ceil(Math.abs(itemStock.stock_quantity) / multiplier) * multiplier;

                let productionMovement = {
                    entry_type: "item",
                    type: "load",
                    quantity: loadQuantity,
                    load_cause: "bom_production",
                    date: new Date().toISOString(),
                    name: item.name,
                    item_id: item.id
                };

                if(checkManager.getPreference('cashregister.disable_manual_production_confirm') !== true) {
                    let answer = await confirmDialog.show($translate.instant('CASHREGISTER.SHOWCASE.CONFIRM_MANUAL_PRODUCTION'));

                    if(!answer) {
                        throw 'CANCELED';
                    }
                }

                _.assign(itemStock, {
                    available: 'available',
                    stock_quantity: itemStock.stock_quantity + loadQuantity
                });

                try {
                    await restManager.post('stock_movements', productionMovement);
                } catch(error) {
                    angular.copy(itemStockBackup, itemStock);
                }
            }
        }
    };

    $scope.addItemToOrder = async (item) => {
        await checkItemBeforeAdd(item);
        ActiveOrder.addItemToOrder(item, $scope.topbar_context.priceList);
    };

    $scope.addNewItem = function() {
        ActiveOrder.awaitOrder().then(function() {
            addNewItemToOrder.show().then(function(item) {
                $scope.addItemToOrder(item);
                if (item.id) {
                    var catId = item.category_id;
                    if (catId && items[catId]) {
                        items[catId].push(item);
                    } else {
                        items.null.push(item);
                    }
                }
            });
        });
    };

    $scope.exitSearch = function() {
        $scope.clearSearchText();
        $scope.topbar_context.showSearch = false;
    };

    $scope.clearSearchText = function() {
        $scope.searchText = "";
        $scope.searchResults = {};
    };

    $scope.searchItems = function(options) {
        if(!_.isObject(options)) {
            options = {};
        }

        if ($scope.searchText.length < 3) {
            $scope.searchResults = {};
        } else {
            var searchTarget;
            var searchAllCategories;

            if(options.allCategories || !_.includes(['categories', 'categoryTab'], $scope.topbar_context.showcaseView)) {
                searchTarget = _.pickBy(items, function(itemsInCategory, categoryId) { return !_.isNil(categoriesById[categoryId]); });
                searchAllCategories = true;
            } else {
                searchTarget = $scope.selectedCategory && items[$scope.selectedCategory.id] ? _.pick(items, [$scope.selectedCategory.id]) : items;
                searchAllCategories = false;
            }

            var searchableItems = _(searchTarget).values().sumBy(_.size);

            if(searchableItems <= 10000 || options.forceSearch) {
                $rootScope.$broadcast("loader:changeStatus", "itemSearchLoader", { enabled: true });
    
                $timeout(function() {
                    $scope.searchResults = performSearch(searchTarget, $scope.searchText, {
                        allCategories: searchAllCategories,
                        priceList: priceListStr,
                        showUnavailable: $scope.topbar_context.showUnavailable
                    });

                    $timeout(function() {
                        $rootScope.$broadcast("loader:changeStatus", "itemSearchLoader", { enabled: false });
                    });
                });    
            } else {
                $scope.searchResults = {
                    needsEnter: true
                };
            }
        }
    };

    var performSearch = function(searchTarget, searchText, options) {
        var unavailableStockStatuses = ['stock-info-unavailable'];

        if(countNoStockAsUnavailable) {
            unavailableStockStatuses.push('stock-info-nostock');
        }

        var itemFilter = function(item) {
            if(!_.isFinite(item[options.priceList || 'price1'])) { //Pricelist check
                return false;
            }

            if(!options.showUnavailable) { //Stock check
                if(_.includes(unavailableStockStatuses, $scope.getItemStockStatus(item))) {
                    return false;
                }
            }

            if(_.isEmpty($filter('filter')([_.omit(item, ['thumbnail', 'images'])], searchText))) {
                return false;
            }

            return true;
        };

        var resultsByCategory = _(searchTarget).mapValues(function(itemsInCategory) {
            return _.filter(itemsInCategory, itemFilter);
        }).pickBy(function(itemsInCategory) {
            return !_.isEmpty(itemsInCategory);
        }).value();

        var favorites = resultsByCategory.favorite;
        delete resultsByCategory.favorite;

        var results = _(resultsByCategory).mapValues(function(itemsInCategory, categoryId) {
            return {
                id: categoryId,
                name: _.get(categoriesById, [categoryId, 'name']),
                items: itemsInCategory
            };
        }).values().value();

        if(favorites) {
            results.unshift({
                id: 'favorite',
                name: $translate.instant('ORDERS.SHOWCASE.FAVORITES_TAB'),
                items: favorites
            });
        }

        return {
            allCategories: options.allCategories,
            hasResults: _.isEmpty(results),
            results: results
        };
    };

    $scope.topbar_context.openTable = function(data) {
        if (data.order) {
            switch (data.order.status) {
                case 'open':
                    ActiveOrder.switchOrdersInParking(data.order);
                    break;
                case 'checkout':
                    if (checkManager.isModuleEnabled('cashregister')) {
                        entityManager.sales.fetchCollectionOffline({ order_id: data.order.id }).then(function(results) {
                            if (!_.isEmpty(results)) {
                                $state.go('app.cashregister.content.showcase', {
                                    action: 'open-sale-id',
                                    id: _.head(results).id
                                });
                            }
                        });
                    }
                    break;
            }
        } else if (data.table) {
            $rootScope.$broadcast("createOrderFromTable", {
                table: data.table,
                room: data.room,
                type: 'normal'
            });
        } else if (_.includes(['take_away', 'delivery'], data.type)) {
            $rootScope.$broadcast("createOrderFromTable", {
                type: data.type
            });
        }
    };

    $scope.$on("storage-updated:items", function(event, data) {
        if (!data.id) {
            if(data.action !== 'DELETED') { //DELETED happens when reloading the items, so wait for the UPDATED event instead
                entityManager.items.fetchCollectionOffline({ on_sale: true }).then(function(result) {
                    var res = _.chain(result)
                        .groupBy("category_id")
                        .value();

                    res.favorite = _.filter(result, { favorite: true });

                    if (!res.null) {
                        res.null = [];
                    }

                    _.forIn(items, function(val, key) { delete items[key]; });
                    _.assign(items, res);

                    $scope.itemsFavorite = items.favorite;
                    switch ($scope.topbar_context.showcaseView) {
                        case ('categories', 'categoryTab'):
                            $scope.selectCategory($scope.selectedCategory);
                            break;
                        case 'favorites':
                            $scope.itemsCategory = $scope.itemsFavorite;
                            break;
                        default:
                            break;
                    }
                    filterVisibleItems();
                });
            }
        } else {
            switch (data.action) {
                case 'UPDATED': case 'CREATED':
                    entityManager.items.fetchOneOffline(data.id).then(function(src) {
                        if (src) {
                            if (!src.favorite) {
                                _.remove($scope.itemsFavorite, {
                                    id: src.id
                                });
                            }

                            var catToScan = _.clone(items);
                            delete catToScan.favorite;

                            var dst;
                            _.find(catToScan, function(itemsInCategory, categoryId) {
                                var srcCatId = src.category_id ? src.category_id.toString() : "null";

                                if (srcCatId !== categoryId) {
                                    _.remove(itemsInCategory, { id: src.id });
                                    return false;
                                }

                                var foundInCategory = _.find(itemsInCategory, { id: data.id });

                                if (foundInCategory) {
                                    dst = foundInCategory;
                                } else {
                                    if (srcCatId === categoryId) {
                                        itemsInCategory.push(src);
                                    }
                                }
                                return foundInCategory;
                            });

                            if (dst) {
                                if (src !== dst) {
                                    angular.copy(src, dst);
                                }
                            }
                            if (src.favorite) {
                                var isAlreadyFavorite = _.find($scope.itemsFavorite, {
                                    id: src.id
                                });

                                if (!isAlreadyFavorite) {
                                    $scope.itemsFavorite.push(dst || src);
                                }
                            }
                        }
                        filterVisibleItems();
                    });
                    break;
                case 'DELETED':
                    _.each(items, function(itemsInCategory) {
                        _.remove(itemsInCategory, {
                            id: data.id
                        });
                    });
                    filterVisibleItems();
                    break;

                default:
                    filterVisibleItems();
                    break;
            }
        }
    });

    if($scope.itemsFavorite.length) {
        $scope.goToSection('favorites');
    } else {
        $scope.selectedCategory = $scope.visibleCategories[0];
        $scope.goToSection('categories');
    }


    $scope.$on("stock-updated", function(event, messageStock) {
        if (!!checkManager.getPreference('cashregister.check_stock')) {
            var dictIndex;
            if (messageStock.combination_id) {
                dictIndex = 'combination_id_' + messageStock.combination_id;
            } else if (messageStock.item_id) {
                dictIndex = 'item_id_' + messageStock.item_id;
            } else {
                return;
            }

            var prevStock = stockDictionary.get(dictIndex);
            stockDictionary.set('item_id_' + messageStock.item_id, _.pick(messageStock, ['item_id', 'combination_id', 'available', 'stock_quantity']));

            if (prevStock && prevStock.available !== messageStock.available) {
                var toastMessage;
                switch (messageStock.available) {
                    case 'available': case 'producible':
                        break;
                    case 'alert':
                        toastMessage = $translate.instant('ORDERS.SHOWCASE.STOCK_ALERT_MESSAGE', { itemName: messageStock.name });
                        break;
                    case 'unavailable':
                        toastMessage = $translate.instant('ORDERS.SHOWCASE.STOCK_UNAVAILABLE_MESSAGE', { itemName: messageStock.name });
                        break;
                    default:
                        break;
                }
                if (toastMessage) {
                    toast.show({ message: toastMessage, hideDelay: 0 });
                }
            }
        }
        filterVisibleItems();
    });

    var destroyStockToast = function() {
        if (stockToast) {
            toast.hide();
            stockToast = null;
        }
    };

    $scope.$on("$destroy", destroyStockToast);

    $scope.$on("connection:changed", function(event, data) {
        switch(data.status) {
            case 'offline':
                if (!!checkManager.getPreference('cashregister.check_stock')) {
                    stockToast = toast.show({
                        message: $translate.instant('ORDERS.SHOWCASE.OFFLINE_STOCK_WARNING'),
                        actions: [{
                            text: 'ok',
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                }
            break;
            case 'online':
                destroyStockToast();
            break;
        }
    });

    $scope.$on('orders:addNewItem', function() {
        $scope.addNewItem();
    });

    $scope.isPortrait = function() {
        return $mdMedia('xs');
    };

    if($scope.isPortrait()) {
        if ($scope.topbar_context.showcaseMode !== 'list') {
            $scope.topbar_context.showcaseMode = 'list';
            checkManager.setUserPreference("showcase_mode", 'list');
        }
    }
}