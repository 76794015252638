<ng-container *ngIf="{
            isDisabled: confirmDisabled$|async,
            showProgressBar: data.showProgressBar$|async
} as sub">
    <tilby-dialog-toolbar *ngIf="!data.hideToolbar" mat-dialog-title [title]="data.titleLabel"
                          [class.tw-p-0]="mobileCheck"
                          [titleParams]="data.titleParams" [disabled]="!!(data.confirmDisabled?.()||sub.isDisabled)"
                          [confirmLabel]="data.confirmLabel" [hideConfirm]="!!data.hideConfirm"
                          [hideCancel]="!!data.hideCancel" [customActions]="data.actionButtons||[]"
                          (confirm)="confirm()"/>
    <mat-tab-group *ngIf="data.tabs?.titles as tabTitles" class="tw-bg-[inherit]" (selectedTabChange)="data.tabs?.changed$?.next($event.index)">
        <mat-tab *ngFor="let tabTitle of tabTitles">
            <ng-template mat-tab-label>{{ tabTitle | translate }}</ng-template>
        </mat-tab>
    </mat-tab-group>

    <mat-progress-bar *ngIf="sub.showProgressBar" mode="indeterminate"></mat-progress-bar>
    <div mat-dialog-content *ngIf="!!selectedPortal" [class.tw-p-0]="data.hideToolbar" class="tw-bg-[inherit] tw-h-full tw-flex-1 tw-justify-self-start tw-p-0" [ngClass]="data.classContent||''" [class.tw-max-h-full]="mobileCheck" [class.pointer-events-none-for-all-children]="sub.showProgressBar">
        <ng-template [cdkPortalOutlet]="selectedPortal||null"></ng-template>
    </div>

    <mat-dialog-actions class="tw-px-6" *ngIf="!!data.bottomActionButtons?.length && data.bottomActionButtons as btns" align="end">
        <button *ngFor="let btn of btns" mat-button [disabled]="!!btn.isDisable?.()" (click)="btn.click()" class="tw-text-base tw-font-medium">{{btn.name|translate}}</button>
    </mat-dialog-actions>
</ng-container>
