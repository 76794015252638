<h1 mat-dialog-title>{{ 'DIALOG.EDIT_ROWS.TITLE' | translate}}</h1>
<div mat-dialog-content style="max-height: 75vh">
    <p>{{ 'DIALOG.EDIT_ROWS.COLUMN.TITLE' | translate}}</p>
    <section class="tw-flex tw-justify-between tw-items-center tw-flex-wrap sm:tw-flex-nowrap" *ngFor="let col of
    data?.itemData">
        <mat-checkbox [(ngModel)]="col.isChecked" color="primary" class="edit-row">
            {{(((data.headerTranslate.get(col.colField))||'') | translate)||col.colField}}
        </mat-checkbox>

    <mat-form-field class="tw-basis-2/3" >
        <mat-label>{{ 'DIALOG.EDIT_ROWS.COLUMN.PLACEHOLDER' | translate}}</mat-label>
        <input matInput type="text" [(ngModel)]="col.newValue" (keyup.enter)="setNewValue()" [disabled]="!col.isChecked">
    </mat-form-field>
    </section>
</div>
<div mat-dialog-actions class="tw-justify-between">
    <button mat-button (click)="dialogRef.close()">{{ 'DIALOG.EDIT_ROWS.ACTION.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary" (click)="setNewValue()" cdkFocusInitial>{{ 'DIALOG.EDIT_ROWS.ACTION.EDIT' | translate}}</button>
</div>
