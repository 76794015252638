import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('duplicateTable', ["$mdDialog", "$state", "entityManager", "alertDialog", "$translate", function($mdDialog, $state, entityManager, alertDialog, $translate) {
    let itemPerRow = 1, itemPerColumn = 1;
    const multiplicationFactorRoom = 80;
    const marginTop = 50;
    const marginLeft = 20;
    duplicateRoomController.$inject = ["$scope", "$mdDialog", "dialog", "tableData", "room"];
    function duplicateRoomController($scope, $mdDialog, dialog, tableData, room) {
        $scope.data = _.pick(tableData, ['covers', 'order_type', 'shape', 'xpos', 'ypos', 'name', 'room_id', 'width', 'height']);

        const roomWidth = room.width * multiplicationFactorRoom;
        const roomHeight = room.height * multiplicationFactorRoom;

        const itemsAvailablePerRow = Math.trunc((roomWidth - tableData.xpos) / (tableData.width + marginLeft));
        const itemsAvailablePerColumn = Math.trunc((roomHeight - tableData.ypos) / (tableData.height + marginTop));

        const itemsPerRowLengths = _.range(1, itemsAvailablePerRow >= 1 ? itemsAvailablePerRow + 1 : 2);
        const itemsPerColumnLengths = _.range(1, itemsAvailablePerColumn >= 1 ? itemsAvailablePerColumn + 1 : 2);

        $scope.itemsPerRow = itemsPerRowLengths;
        $scope.itemsPerColumn = itemsPerColumnLengths;

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            $mdDialog.hide($scope.data);
        };

        $scope.changeRow = () => {
            itemPerRow = _.toNumber($scope.itemPerRow);
        };

        $scope.changeCol = () => {
            itemPerColumn = _.toNumber($scope.itemPerColumn);
        };
    }

    const checkSpace = (table, room) => {
        const { xpos, ypos, width, height } = table;
        const roomWidth = room.width * multiplicationFactorRoom;
        const roomHeight = room.height * multiplicationFactorRoom;

        const restSpaceX = roomWidth - xpos;
        const restSpaceY = roomHeight - ypos;

        const itemsX = restSpaceX / (width + marginLeft);
        const itemsY = restSpaceY / (height + marginTop);

        let testX = true, testY = true;
        if(itemPerRow > 1) {
            testX = itemsX >= itemPerRow;
        }
        if(itemPerColumn > 1) {
            testY = itemsY >= itemPerColumn;
        }
        return testX && testY;
    };

    const duplicateRoom = {
        reShowDialog: (table, room, dialog) => {
            if(dialog) {
               dialog.show(table, room, dialog);
           }
        },
        show: (tableData, currentRoom, dialog) => {
            $mdDialog.show({
                controller: duplicateRoomController,
                template: require('./duplicate-table.html'),
                locals: {
                    dialog: dialog,
                    tableData: tableData,
                    room: currentRoom
                }
            }).then(table => { //TODO: handle entityManager errors
                const tmpRoom = _.cloneDeep(currentRoom);
                if(checkSpace(table, tmpRoom)) {
                    // k is the increment of table in suffix
                    let k = (itemPerColumn === 1 && itemPerRow === 1) ? 0 : 2;
                    let _table = _.cloneDeep(table);
                    for(let i = 0; i < itemPerColumn; i++) {
                        let offset = (i !== 0) ? marginTop : 0;
                        let ypos = table.ypos  + i * (table.height + offset);
                        for(let j = 0; j < itemPerRow; j++) {
                            let isFirstItem = i === 0 && j === 0;
                            _table.xpos = table.xpos + j * (table.width + marginLeft);
                            _table.ypos = ypos;
                            let suffix = (k === 0 || isFirstItem) ? '' : _.toString(' ' + k) ;
                            _table.name = _.toString(table.name + suffix);
                            if (k !== 0 && !isFirstItem) {
                                k++;
                            }
                            if (!isFirstItem) {
                                tmpRoom.tables.push(_table);
                            }
                            _table = _.cloneDeep(table);
                        }
                    }
                    entityManager.rooms.putOneOnline(tmpRoom).then(function() {
                        $state.reload("app.tables");
                    });
                } else {
                    alertDialog.show($translate.instant('TABLES.DUPLICATE_TABLE.NOTICE')).finally(() => duplicateRoom.reShowDialog(table, currentRoom, dialog) );
                }
            });
        }
    };

    return duplicateRoom;
}]);
