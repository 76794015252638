import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { documentTypes } from 'src/app/core/constants';
import { keyBy } from 'src/app/shared/utils';

angular.module('printers').controller('PrintersDetailCtrl', PrintersDetailCtrl);

PrintersDetailCtrl.$inject = ["$scope", "$rootScope", "$state", "$filter", "$translate", "billingData", "printer", "printers", "items", "confirmDialog", "alertDialog", "authRequestDialog", "entityManager", "restManager", "checkManager", "categories", "EscPosEncoder", "FiscalPrinters", "environmentInfo", "dgfeResult", "options", "departments", "vat", "rooms", "paymentMethods", "printerErrorFiscal", "printerUpgradeDialog", "dailyClosingDialog"];

function PrintersDetailCtrl($scope, $rootScope, $state, $filter, $translate, billingData, printer, printers, items, confirmDialog, alertDialog, authRequestDialog, entityManager, restManager, checkManager, categories, EscPosEncoder, FiscalPrinters, environmentInfo, dgfeResult, options, departments, vat, rooms, paymentMethods, printerErrorFiscal, printerUpgradeDialog, dailyClosingDialog) {
    const getDocumentTypeName = (type) => {
        switch(type.id) {
            case "fiscal_receipt":
                return printer.type === 'rt' ? $translate.instant('PRINTERS.DETAIL.COMMERCIAL_DOC') : $translate.instant('PRINTERS.DETAIL.FISCAL_RECEIPT');
            case "receipt_invoice":
                return $translate.instant('PRINTERS.DETAIL.PRINT_RECEIPT_INVOICE');
            case "invoice":
                return $translate.instant('PRINTERS.DETAIL.PRINT_INVOICE');
            case "e_invoice":
                return $translate.instant('PRINTERS.DETAIL.PRINT_E_INVOICE');
            case "summary_e_nrc":
                return $translate.instant('PRINTERS.DETAIL.PRINT_SUMMARY_E_NRC');
            case "summary_e_rc":
                return $translate.instant('PRINTERS.DETAIL.PRINT_SUMMARY_E_RC');
            case "summary_invoice":
                return $translate.instant('PRINTERS.DETAIL.PRINT_SUMMARY_INVOICE');
            case "shipping_invoice":
                return $translate.instant('PRINTERS.DETAIL.PRINT_SHIPPING_INVOICE');
            case "receipt": case "generic_receipt":
                return $translate.instant('PRINTERS.DETAIL.PRINT_RECEIPT');
            case "generic_invoice":
                return $translate.instant('PRINTERS.DETAIL.PRINT_GENERIC_INVOICE');
            case "generic_document":
                return $translate.instant('PRINTERS.DETAIL.PRINT_GENERIC_DOCUMENT');
            default: return '';
        }
    };

    Object.assign($scope, {
        cash: {},
        categories: categories,
        cashregisters: [],
        codepageMappings: EscPosEncoder.getAvailableCodepageMappings(),
        departments: departments,
        dgfe: {},
        documentTypes: _(documentTypes).filter((docType) => docType.printers.includes(printer.type)).map((docType) => ({ id: docType.id, check: docType.check, name: getDocumentTypeName(docType) })).value(),
        fiscalMem: {},
        headerLineNumbers: _.range(1, 14),
        invoiceFooterLineNumbers: _.range(1, 7),
        items: items,
        isValid: () => $scope.settingsForm?.$valid || false,
        options: options,
        otherPrinters: [],
        patternIP: /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/,
        patternMAC: /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/,
        paymentMethods: paymentMethods,
        printer: _.cloneDeep(printer),
        rooms: [],
        showFirmwareUpdateBox: _.isMatch(printer, { driver: 'epson', type: 'rt' }) && (checkManager.isFunctionEnabledOptin('printers.epson_fw_upgrade') || _.isNil(billingData.reseller_scloby_id)),
        vat: vat,
        fontSizes: [{
            label: $translate.instant('PRINTERS.DETAIL.SMALL'),
            value: 0
        }, {
            label: $translate.instant('PRINTERS.DETAIL.MEDIUM'),
            value: 16
        }, {
            label: $translate.instant('PRINTERS.DETAIL.BIG'),
            value: 17
        }],
        headerSizes: [{
            label: $translate.instant('PRINTERS.DETAIL.DISABLED'),
            value: -1
        }, {
            label: $translate.instant('PRINTERS.DETAIL.SMALL'),
            value: 0
        }, {
            label: $translate.instant('PRINTERS.DETAIL.MEDIUM'),
            value: 16
        }, {
            label: $translate.instant('PRINTERS.DETAIL.BIG'),
            value: 17
        }],
        lineSpacings: [{
            label: $translate.instant('PRINTERS.DETAIL.NONE'),
            value: 0
        }, {
            label: $translate.instant('PRINTERS.DETAIL.ONE_ROW'),
            value: 80
        }],
        itemSpacings: [{
            label: $translate.instant('PRINTERS.DETAIL.NONE'),
            value: 0
        }, {
            label: $translate.instant('PRINTERS.DETAIL.ONE_ROW'),
            value: 1
        }],
        itemsAligns: [{
            label: $translate.instant('PRINTERS.DETAIL.LEFT'),
            value: 'left'
        }, {
            label: $translate.instant('PRINTERS.DETAIL.CENTER'),
            value: 'center'
        }, {
            label: $translate.instant('PRINTERS.DETAIL.RIGHT'),
            value: 'right'
        }],
        itemsGroupBy: [{
            label: $translate.instant('PRINTERS.DETAIL.EXIT'),
            value: 'exit'
        }, {
            label: $translate.instant('PRINTERS.DETAIL.CATEGORY'),
            value: 'category_id'
        }]
    });

    const defaultConfigs = {
        nonfiscal: {
            add_prices_to_order: false,
            codepage_mapping: 'epson',
            footer_printer_categories: -1,
            footer_order_number: -1,
            footer_operator_name: -1,
            footer_print_type: -1,
            footer_date: -1,
            footer_time: -1,
            footer_room_name: -1,
            footer_table_name: -1,
            footer_covers: -1,
            footer_delivery_info: -1,
            footer_order_name: -1,
            footer_customer_info: -1,
            footer_order_notes: -1,
            footer_total_pieces: -1,
            header_printer_categories: -1,
            header_order_number: 16,
            header_operator_name: 16,
            header_print_type: 16,
            header_date: 16,
            header_time: 16,
            header_room_name: 16,
            header_table_name: 16,
            header_covers: 16,
            header_delivery_info: 16,
            header_order_name: 0,
            header_customer_info: 16,
            header_order_notes: 0,
            header_total_pieces: -1,
            item_font_size: 16,
            item_spacing: 1,
            item_hide_border: false,
            item_hide_brackets: false,
            items_align: 'center',
            items_group_by: 'exit',
            line_spacing: 80,
            split_orders_by_exit: false,
            variation_font_size: 16,
        },
        receipt: {
            codepage_mapping: 'epson'
        }
    };

    const loadPrinterConfiguration = () => {
        //Non Fiscal Config
        $scope._nonFiscalConfiguration = Object.assign({}, defaultConfigs[printer.type]);

        if (printer.configuration) {
            try {
                let configuration = JSON.parse(printer.configuration);

                if(configuration.header_font_size_1) { //Migrate legacy header font size if exists
                    _.forIn($scope._nonFiscalConfiguration, function(val, key) {
                        if(_.startsWith('header_', key)) {
                            $scope._nonFiscalConfiguration[key] = configuration.header_font_size_1;
                        }
                    });
                }

                _.assign($scope._nonFiscalConfiguration, configuration);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const parseNonFiscalConfiguration = () => {
        //Load existing config
        loadPrinterConfiguration();

        //Initialize config structures
        $scope.rooms = rooms.map((p) => ({ id: p.id, name: p.name, _enabled: false }));

        $scope.rooms.unshift(
            { id: 'take_away', name: $translate.instant('PRINTERS.DETAIL.TAKE_AWAY'), _enabled: false },
            { id: 'delivery', name: $translate.instant('PRINTERS.DETAIL.DELIVERY'), _enabled: false },
            { id: 'no_rooms', name: $translate.instant('PRINTERS.DETAIL.NO_ROOMS'), _enabled: false } 
        );

        const categoriesNew = categories.sort((a, b) => a.index - b.index).map((c) => ({
            id: c.id,
            name: c.name,
            toPrint: false
        }));

        $scope.cashregisters = printers
            .filter((p) => (['fiscal', 'rt', 'receipt'].includes(p.type) && p.id !== printer.id))
            .map((p) => ({ id: p.id, name: p.name, _enabled: false }));

        $scope.otherPrinters = printers
            .filter((p) => (p.type === 'nonfiscal' && p.id !== printer.id))
            .map((p) => ({ id: p.id, name: p.name, _enabled: false }));

        //Create dictionaries
        const categoriesNewById = keyBy(categoriesNew, (c) => c.id);
        const roomsById = keyBy($scope.rooms, (p) => p.id);
        const cashregistersById = keyBy($scope.cashregisters, (c) => c.id);
        const otherPrintersById = keyBy($scope.otherPrinters, (p) => p.id);

        //Parse printer configuration
        const nonfiscalConfig = $scope._nonFiscalConfiguration;

        for(const category of printer.categories || []) {
            if(categoriesNewById[category.id]) {
                categoriesNewById[category.id].toPrint = true;
            }
        }

        for(const printerid of nonfiscalConfig.other_printers || []) {
            if(otherPrintersById[printerid]) {
                otherPrintersById[printerid]._enabled = true;
            }
        }

        for(const roomId of nonfiscalConfig.rooms || []) {
            if(roomsById[roomId]) {
                roomsById[roomId]._enabled = true;
            }
        }

        for(const cashregisterId of nonfiscalConfig.cashregisters || []) {
            if(cashregistersById[cashregisterId]) {
                cashregistersById[cashregisterId]._enabled = true;
            }
        }

        Object.assign($scope.printer, {
            categories: categoriesNew,
            _allCategories: categoriesNew.every((c) => c.toPrint),
            _enableAllCashregisters: $scope.cashregisters.every((c) => c._enabled),
            _enableAllOtherPrinters: $scope.otherPrinters.every((c) => c._enabled),
            _enableAllRooms: $scope.rooms.every((c) => c._enabled)
        });
    };

    $scope.savePrinter = async () => {
        const proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_SAVE'));

        if (!proceed) {
            return;
        }

        let printerToSave;

        $scope.$parent.showPrintersLoader();

        switch($scope.printer.type) {
            case 'nonfiscal':
                printerToSave = cleanUpCategories();

                Object.assign($scope._nonFiscalConfiguration, {
                    cashregisters: $scope.cashregisters.filter((p) => p._enabled).map((p) => p.id),
                    other_printers: $scope.otherPrinters.filter((p) => p._enabled).map((p) => p.id),
                    rooms: $scope.printer._noRooms ? [] :  $scope.rooms.filter((r) => r._enabled).map((r) => r.id),
                });
            break;
            case 'fiscal':
            case 'receipt':
            case 'rt':
            case 'kds':
                printerToSave = _.cloneDeep($scope.printer);

                _.forEach(printerToSave, (value, key) => {
                    if(['ip_address', 'subnet_mask', 'gateway', 'mac_address_bt'].includes(key) && value === "") {
                        printerToSave[key] = null;
                    }
                });
            break;
            default: break;
        }

        if(['nonfiscal', 'receipt'].includes(printerToSave.type)) {
            printerToSave.configuration = JSON.stringify(_.omit($scope._nonFiscalConfiguration, ['header_font_size_1']));
        }

        await entityManager.printers.putOneOnline(printerToSave);

        $state.reload("app.printers");
    };

    $scope.configure = async() => {
        if ($scope.printer.connection_type === 'bt' && !environmentInfo.isMobileApp()) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.BT_ONLY_MOBILE'));
        } else {
            let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_CONFIG'));

            if (proceed) {
                $scope.configuringPrinter = true;
                showPrinterOperationLoader();

                try {
                    await FiscalPrinters.configurePrinter($scope.printer, options);
                    alertDialog.show($translate.instant('PRINTERS.DETAIL.CONFIGURATION_COMPLETED'));
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: $scope.printer.id });
                    //new modal is ready to replace the old one
                }

                hidePrinterOperationLoader();
                $scope.configuringPrinter = false;
            }
        }
    };

    const allSelector = (targetArr, flag, value) => {
        for (const el of targetArr) {
            el[flag] = value;
        }
    };

    $scope.selectAllCategories = () => allSelector($scope.printer.categories, 'toPrint', $scope.printer._allCategories);
    $scope.selectAllOtherPrinters = () => allSelector($scope.otherPrinters, '_enabled', $scope.printer._enableAllOtherPrinters);
    $scope.selectAllRooms = () => allSelector($scope.rooms, '_enabled', $scope.printer._enableAllRooms);
    $scope.selectAllCashregisters = () => allSelector($scope.cashregisters, '_enabled', $scope.printer._enableAllCashregisters);

    $scope.deletePrinter = async () => {
        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_REMOVE'));

        if (proceed) {
            $scope.$parent.showPrintersLoader();

            try {
                await entityManager.printers.deleteOneOnline($scope.printer.id, true);
                $scope.$parent.goTo('printers.general', null, true);
            } catch(err) {
                $scope.$parent.hidePrintersLoader();
            }
        }
    };

    const showPrinterOperationLoader = () => $rootScope.$broadcast("loader:changeStatus", "operationWithPrinterLoader", { enabled: true });
    const hidePrinterOperationLoader = () => $rootScope.$broadcast("loader:changeStatus", "operationWithPrinterLoader", { enabled: false });

    $scope.dailyRead = async () => {
        let printerDocumentData = { printer: { id: $scope.printer.id } };

        await checkSettingsDirtyAndRequisites();

        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_READ_DAILY'));

        if (proceed) {
            showPrinterOperationLoader();

            try {
                await FiscalPrinters.dailyRead(printerDocumentData);
                alertDialog.show($translate.instant('PRINTERS.DETAIL.DAILY_READ_COMPLETED'));
            } catch (error) {
                printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
            }

            hidePrinterOperationLoader();
        }
    };

    $scope.dailyClosing = async () => {
        await checkSettingsDirtyAndRequisites();

        await dailyClosingDialog.show(_.cloneDeep($scope.printer));
    };

    $scope.deposit = async () => {
        let printerDocumentData = { printer: { id: $scope.printer.id } };

        await checkSettingsDirtyAndRequisites();

        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_DEPOSIT'));

        if (proceed) {
            showPrinterOperationLoader();

            try {
                await FiscalPrinters.deposit(printerDocumentData, $scope.cash.amount);
                alertDialog.show($translate.instant('PRINTERS.DETAIL.DEPOSIT_COMPLETED'));

                Object.assign($scope.cash,  {
                    type: false,
                    amount: null
                });
            } catch (error) {
                printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
            }

            hidePrinterOperationLoader();
        }
    };

    $scope.withdrawal = async() => {
        let printerDocumentData = { printer: { id: $scope.printer.id } };

        await checkSettingsDirtyAndRequisites();

        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_WITHDRAWAL'));

        if (proceed) {
            showPrinterOperationLoader();

            try {
                await FiscalPrinters.withdrawal(printerDocumentData, $scope.cash.amount);
                alertDialog.show($translate.instant('PRINTERS.DETAIL.WITHDRAWAL_COMPLETED'));

                Object.assign($scope.cash,  {
                    type: false,
                    amount: null
                });
            } catch (error) {
                printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
            }

            hidePrinterOperationLoader();
        }
    };

    $scope.printFiscalMemory = async() => {
        let printerDocumentData = { printer: { id: $scope.printer.id } };

        await checkSettingsDirtyAndRequisites();

        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_PRINT'));

        if (proceed) {
            showPrinterOperationLoader();
            try {
                await FiscalPrinters.printFiscalMemory(printerDocumentData);
                alertDialog.show($translate.instant('PRINTERS.DETAIL.PRINT_COMPLETED'));
            } catch(error) {
                printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
            }

            hidePrinterOperationLoader();
        }
    };

    $scope.printFiscalMemoryBetween = async() => {
        let corrispettivi = $scope.fiscalMem.corrispettivi || false;

        if ($scope.fiscalMem.from > $scope.fiscalMem.to) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.DATE_ERROR'));
        } else {
            await checkSettingsDirtyAndRequisites();

            let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_PRINT'));

            if (proceed) {
                showPrinterOperationLoader();

                let printerDocumentData = { printer: { id: $scope.printer.id } };
                let from = convertDateToDDMMYY($scope.fiscalMem.from);
                let to = convertDateToDDMMYY($scope.fiscalMem.to);

                try {
                    await FiscalPrinters.printFiscalMemoryBetween(printerDocumentData, corrispettivi, from, to);
                    alertDialog.show($translate.instant('PRINTERS.DETAIL.PRINT_COMPLETED'));
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
                }

                hidePrinterOperationLoader();
            }
        }
    };

    $scope.readDgfe = async() => {
        if ($scope.printer.connection_type === 'bt') {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.READ_DGFE_UNAVAILABLE'));
        } else {
            await checkSettingsDirtyAndRequisites();

            let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_READ_DGFE'));

            if (proceed) {
                let printerId = $scope.printer.id;

                showPrinterOperationLoader();

                try {
                    let result = await FiscalPrinters.readDgfe({ id: printerId });
                    dgfeResult.show(null, null, result);
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: printerId });
                }

                hidePrinterOperationLoader();
            }
        }
    };

    $scope.readDgfeBetween = async () => {
        if ($scope.dgfe.from > $scope.dgfe.to) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.DATE_ERROR'));
        } else if ($scope.printer.connection_type === 'bt') {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.READ_DGFE_UNAVAILABLE'));
        } else {
            await checkSettingsDirtyAndRequisites();

            let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.WANT_TO_READ_DGFE'));

            if (proceed) {
                let printerId = $scope.printer.id;
                let from = convertDateToDDMMYY($scope.dgfe.from);
                let to = convertDateToDDMMYY($scope.dgfe.to);

                showPrinterOperationLoader();

                try {
                    let result = await FiscalPrinters.readDgfeBetween({ id: printerId }, from, to);
                    dgfeResult.show($scope.dgfe.from.toLocaleDateString(), $scope.dgfe.to.toLocaleDateString(), result);
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: printerId });
                }

                hidePrinterOperationLoader();
            }
        }
    };

    $scope.printDgfe = async () => {
        await checkSettingsDirtyAndRequisites();

        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.PRINT_ALL_DGFE'));

        if (proceed) {
            showPrinterOperationLoader();

            let printerDocumentData = { printer: { id: $scope.printer.id } };

            try {
                await FiscalPrinters.printDgfe(printerDocumentData);
                alertDialog.show($translate.instant('PRINTERS.DETAIL.DGFE_PRINTED'));
            } catch(error) {
                printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
            }

            hidePrinterOperationLoader();
        }
    };

    $scope.printDgfeBetween = async () => {
        if ($scope.dgfe.from > $scope.dgfe.to) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.DATE_ERROR'));
        } else {
            await checkSettingsDirtyAndRequisites();

            let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.PRINT_DGFE'));

            if (proceed) {
                showPrinterOperationLoader();

                let printerDocumentData = { printer: { id: $scope.printer.id } };
                let from = convertDateToDDMMYY($scope.dgfe.from);
                let to = convertDateToDDMMYY($scope.dgfe.to);

                try {
                    await FiscalPrinters.printDgfeBetween(printerDocumentData, from, to);
                    alertDialog.show($translate.instant('PRINTERS.DETAIL.PRINT_COMPLETED'));
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: printerDocumentData.printer.id });
                }

                hidePrinterOperationLoader();
            }
        }
    };

    $scope.saveDgfe = async() => {
        if (environmentInfo.isMobileApp()) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.SAVE_ONLY_ON_BROWSERS'));
        } else if ($scope.printer.connection_type === 'bt') {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.READ_DGFE_UNAVAILABLE'));
        } else {
            await checkSettingsDirtyAndRequisites();

            let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.SAVE_DGFE'));

            if (proceed) {
                showPrinterOperationLoader();

                let printerId = $scope.printer.id;

                try {
                    let result = await FiscalPrinters.readDgfe({ id: printerId });
                    let file = new Blob([result], { type: 'text/plain' });
                    $scope.downloadAllLink = (window.URL || window.webkitURL).createObjectURL(file);
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: printerId });
                }

                hidePrinterOperationLoader();
            }
        }
    };

    $scope.saveDgfeBetween = async () => {
        if (environmentInfo.isMobileApp()) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.SAVE_ONLY_ON_BROWSERS'));
        } else if ($scope.dgfe.from > $scope.dgfe.to) {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.DATE_ERROR'));
        } else if ($scope.printer.connection_type === 'bt') {
            alertDialog.show($translate.instant('PRINTERS.DETAIL.READ_DGFE_UNAVAILABLE'));
        } else {
            await checkSettingsDirtyAndRequisites();

            let result = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.PRINT_DGFE'));

            if (result) {
                showPrinterOperationLoader();

                let from = convertDateToDDMMYY($scope.dgfe.from);
                let to = convertDateToDDMMYY($scope.dgfe.to);
                let printerId = $scope.printer.id;

                try {
                    let result = await FiscalPrinters.readDgfeBetween({ id: printerId }, from, to);
                    let file = new Blob([result], { type: 'text/plain' });

                    $scope.downloadSelectionLink = (window.URL || window.webkitURL).createObjectURL(file);
                } catch(error) {
                    printerErrorFiscal.show(error, { printerId: printerId });
                }

                hidePrinterOperationLoader();
            }
        }
    };

    $scope.checkFirmwareUpdates = async() => {
        if(checkManager.isFunctionEnabledOptin('printers.epson_fw_upgrade')) {
            printerUpgradeDialog.show(_.cloneDeep($scope.printer));
        } else {
            try {
                let billingData = await restManager.getOne('billing_data');

                if(billingData) {
                    try {
                        let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.EPSON_FW_MODULE_COST'), {
                            yesLabel: $translate.instant('PRINTERS.DETAIL.EPSON_FW_MODULE_ACCEPT'),
                            noLabel: $translate.instant('PRINTERS.DETAIL.EPSON_FW_MODULE_CANCEL')
                        });

                        if(proceed) {
                            let success = await authRequestDialog.show({ skipAuth: true, billingData: billingData });

                            if(success) {
                                try {
                                    await restManager.post('enable_modules', { module_name : 'printers.epson_fw_upgrade' });
                                    printerUpgradeDialog.show(_.cloneDeep($scope.printer));
                                } catch(error) {
                                    alertDialog.show($translate.instant('PRINTERS.DETAIL.EPSON_FW_MODULE_ENABLE_ERROR'));
                                }
                            }
                        }
                    } catch(err) {}
                }
            } catch(error) {
                alertDialog.show($translate.instant('PRINTERS.DETAIL.EPSON_FW_MODULE_OWNER_REQUIRED'));
            }
        }
    };

    const convertDateToDDMMYY = (date) => $filter('sclDate')(date, 'DDMMYY');

    const checkSettingsDirtyAndRequisites = async () => {
        let error;

        if ($scope.printer.connection_type === 'bt' && !environmentInfo.isMobileApp()) {
            error = 'MUST_USE_MOBILE_APP';
        } else if ($scope.settingsForm.$dirty) {
            error = 'SAVE_AND_RETRY';
        }

        if(error) {
            alertDialog.show($translate.instant(`PRINTERS.DETAIL.${error}`));
            throw error;
        }
    };

    // for nonfiscal
    const cleanUpCategories = () => {
        let printerClone = _.cloneDeep($scope.printer);

        _.remove(printerClone.categories, { toPrint: false });

        return printerClone;
    };

    const getSaveButtonConfig = (tabName) => {
        switch (tabName) {
            case 'operations':
                // fiscal
                return {
                    label: '',
                    action: undefined,
                    enabled: () => false
                };
            case 'categories':
            case 'otherPrinters':
            case 'rooms':
            case 'cashregisters':
                // nonfiscal
                return {
                    label: $translate.instant('PRINTERS.DETAIL.SAVE'),
                    action: $scope.savePrinter,
                    enabled: () => true
                };
            case 'settings':
                if (['fiscal', 'rt'].includes($scope.printer.type)) {
                    return {
                        label: $translate.instant('PRINTERS.DETAIL.SAVE'),
                        action: $scope.savePrinter,
                        enabled: () => $scope.settingsForm.$valid
                    };
                } else {
                    return {
                        label: $translate.instant('PRINTERS.DETAIL.SAVE'),
                        action: $scope.savePrinter,
                        enabled: () => $scope.settingsForm.$valid
                    };
                }
            break;
            case 'documents':
                // fiscal
                return {
                    label: $translate.instant('PRINTERS.DETAIL.SAVE'),
                    action: $scope.savePrinter,
                    enabled: () => true
                };
            case 'configuration':
                // fiscal
                return {
                    label: $translate.instant('PRINTERS.DETAIL.CONFIGURE'),
                    action: $scope.configure,
                    enabled: () => !$scope.configuringPrinter
                };
            default:
            break;
        }
    };

    $scope.onTabLeave = async(sectionName) => {
        let targetForm = $scope[`${sectionName}Form`];

        if(targetForm?.$dirty) {
            try {
                let proceed = await confirmDialog.show($translate.instant('PRINTERS.DETAIL.CONTINUE_WITHOUT_SAVE'));

                if (proceed) {
                    // restore printer
                    $scope.printer = _.cloneDeep(printer);

                    if (['nonfiscal', 'receipt'].includes(printer.type)) {
                        // restore categories to be viualized
                        parseNonFiscalConfiguration();
                    }

                    targetForm?.$setPristine();
                } else {
                    throw true;
                }
            } catch(err) {
                $scope.selectedTab = sectionName;
            }
        }
    };

    $scope.openTab = async (tabName) => {
        $scope.selectedTab = tabName;

        if($scope.$parent) {
            $scope.$parent.configured_at = $scope.printer.configured_at ? moment($scope.printer.configured_at).format("DD/MM/YYYY HH:mm") : null;
            $scope.$parent.topbar_context.saveButton = getSaveButtonConfig(tabName);
        }
    };

    $scope.$parent.topbar_context.unsaved = () => ($scope.categoriesForm?.$dirty || $scope.settingsForm?.$dirty || $scope.documentsForm?.$dirty);

    if (!printer) {
        $state.go('app.printers.general', {}, {
            reload: "app.printers"
        });
    } else if (['nonfiscal', 'receipt'].includes(printer.type)) {
        parseNonFiscalConfiguration();
    }

    $scope.$parent.hidePrintersLoader();
}
