import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').directive('loader', loader);

loader.$inject = ['checkManager'];

function loader(checkManager) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            loaderName: "="
        },
        template: require('./loader.html'),
        link: function(scope, element, attrs, fn) {
            scope.mainMessage = "";
            scope.otherMessage = "";

            if(scope.loaderName) {
                scope.enabled = false;

                scope.$on("loader:changeStatus", function(event, name, options) {
                    if (name === scope.loaderName) {
                        if (options) {
                            scope.mode = options.mode || "indeterminate";
    
                            if (_.includes(['buffer', 'determinate'], scope.mode)) {
                                scope.value = _.toInteger(options.value) || scope.value;
                            }
    
                            _.assign(scope, {
                                mainMessage: _.isUndefined(options.mainMessage) ? scope.mainMessage : options.mainMessage,
                                otherMessage: _.isUndefined(options.otherMessage) ? scope.otherMessage : options.otherMessage,
                                enabled: _.isUndefined(options.enabled) ? scope.enabled : options.enabled,
                                showLogo: _.isUndefined(options.showLogo) ? scope.showLogo : options.showLogo,
                                updateMode: _.isUndefined(options.updateMode) ? scope.updateMode : options.updateMode
                            });

                            if(scope.showLogo) {
                                if(checkManager.getSetting('startup.show_pc_cash_logo')) {
                                    scope.logoUrl = 'pc_cash_air.png';
                                } else {
                                    scope.logoUrl = (scope.updateMode || checkManager.getShopCountry()) !== 'IT' ? 'logo.png' : 'morph.gif';
                                }
                            }
                        }
                    }

                    scope.$applyAsync();
                });
            } else {
                scope.enabled = true;
            }
        }
    };
}