import * as angular from 'angular';

angular.module('stock').factory("stockExportDialog", ["$mdDialog", function($mdDialog) {
    var stockExportDialogController = function($scope, checkManager) {
        $scope.email = checkManager.getPreference('stock.export.default_email') || '';
        $scope.date = new Date();
        $scope.currentDate = $scope.date.toISOString();

        $scope.exportAtDate = checkManager.isFunctionEnabledOptin("stock.show_export_at_date") ? true : false;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            var answer = {
                email: $scope.email
            };

            var dateISO = $scope.date.toISOString();
            if($scope.exportAtDate && dateISO !== $scope.currentDate) {
                $scope.date.setHours(23);
                $scope.date.setMinutes(59);
                $scope.date.setSeconds(59);
                $scope.date.setMilliseconds(999);
                answer.date = $scope.date.toISOString();
            }

            $mdDialog.hide(answer);
        };
    };
    stockExportDialogController.$inject = ["$scope", "checkManager"];

    var stockExportDialog = {
        show: function() {
            return $mdDialog.show({
                controller: stockExportDialogController,
                template: require('./stock-export-dialog.html')
            });
        }
    };

    return stockExportDialog;
}]);