<mat-progress-bar mode="query" *ngIf="inProgress"></mat-progress-bar>
<tilby-dialog-toolbar mat-dialog-title
    title="SETTINGS.EDIT_USER_ACEESS_DIALOG.TITLE_PHONE"
    [disabled]="!editPhoneDialogForm.valid || inProgress"
    (confirm)="confirm()"></tilby-dialog-toolbar>
<mat-dialog-content>
    <form autocomplete="off" [formGroup]="editPhoneDialogForm" class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-row">
            <mat-form-field appearance="fill" class="mobile-prefix tw-w-1/4">
                <mat-label translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.PREFIX</mat-label>
                <mat-select formControlName="mobilePrefix">
                    <mat-option *ngFor="let prefix of mobilePrefixes" [value]="prefix.value">
                        {{prefix.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="phone tw-w-3/4">
                <mat-label translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.PHONE</mat-label>
                <input matInput placeholder="3331234567" formControlName="phone" type="text" required [readonly]="waitingCode" cdkFocusInitial>
                <mat-error *ngIf="phone?.hasError('required')">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-error *ngIf="phone?.hasError('phone')">{{'SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_PHONE' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="mat-mdc-form-field-error tw-px-4" *ngIf="errorPhone">{{errorPhoneMessage}}</div>

        <div *ngIf="waitingCode" class="tw-flex tw-flex-col">
            <p translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.TEXT_WAIT_CODE_PHONE</p>
            <mat-form-field appearance="fill" class="tw-flex-auto">
                <mat-label translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.VERIFICATION_CODE</mat-label>
                <input matInput placeholder="123456" formControlName="verification_code" type="text" required>
                <mat-error *ngIf="verification_code?.hasError('required')">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
            </mat-form-field>
            <div class="mat-mdc-form-field-error tw-px-4" *ngIf="verificationCodeError">{{verificationCodeErrorMessage}}</div>
        </div>
    </form>
</mat-dialog-content>
