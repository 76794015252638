import * as angular from 'angular';
import { IotConnectionManager } from './iot-connection-manager'
import { IotMessageRecoveryManager } from './iot-message-recovery-manager';
import { EVENTS_IOT, MessagingConfig } from './iot-models';
import { asyncScheduler, Subscription } from 'rxjs';
import { EnvironmentInfoService } from "src/app/core/services";

class IotManager {
    messagingConfig?: MessagingConfig;
    nRetryConnection: number = 0;
    iotStatusScheduler: Subscription | undefined;
    
    constructor(
        private $rootScope: any,
        private errorsLogger: any,
        private iotConnectionManager: IotConnectionManager,
        private iotMessageRecoveryManager: IotMessageRecoveryManager,
        private environmentInfo: EnvironmentInfoService
    ) {
    }

    private logger(...args: (string | boolean)[]) {
        this.errorsLogger.debug("[IotManager]: ", ...args);
    }

    private re_connect() {
        asyncScheduler.schedule(() => this.iotConnectionManager.close_and_reopen_connection(true, 5000), 10000);
    }

    private first_connect() {
        this.iotConnectionManager.connect(false).then(res => {
            this.logger(res);
            this.iotMessageRecoveryManager.bootstrap();

        }).catch(err => {
            // if connection failer, iotConnectionManager has already launched an event to retry connecting.
            this.logger(err);
            this.iotMessageRecoveryManager.bootstrap();
        });
    }

    bootstrap = () => {
        this.logger("bootstrap connection iot");
        let session = this.$rootScope.userActiveSession;
        this.messagingConfig = {
            userId: session.id,
            client: this.environmentInfo.getSessionId(),
            shop: session.shop.name
        };
        if (session.shop?.iot_enabled === 1) {
            this.iotConnectionManager.bootstrap(this.messagingConfig);
            // set events
            this.$rootScope.$on(EVENTS_IOT.resume, (message: any) => {
                this.logger("resume");
            });
            this.$rootScope.$on(EVENTS_IOT.iot_delay_next_id, (message: any) => {
                this.logger(message);
                this.iotMessageRecoveryManager.callCheckLastId();
            });
            this.$rootScope.$on(EVENTS_IOT.error, (message: any) => { });
            this.$rootScope.$on(EVENTS_IOT.disconnect, (message: any) => this.logger("Disconnected"));
            // this could be launched when first iot login or refresh iot login failed
            this.$rootScope.$on(EVENTS_IOT.iot_need_login, (message: any) => {
                this.logger(`${message}`);
                this.re_connect();
            });
            this.first_connect();
            this.$rootScope.$on('connection:changed', (event: any, data: any) => {
                if (data.status === 'offline') {
                    this.iotMessageRecoveryManager.stopCheckLastId();
                } else {
                    this.logger("recovering History")
                    this.iotMessageRecoveryManager.recoverHistory().then(z => {
                        if(this.environmentInfo.isAppleMobile()){
                            this.iotConnectionManager.close_and_reopen_connection(true, 2000);
                        }
                        return "resolved"; 
                    }).catch(err=>{
                        if(this.environmentInfo.isAppleMobile()){
                            this.iotConnectionManager.close_and_reopen_connection(true, 2000);
                        }
                        return "unresolved"
                    });
                }
            });
        }
    };
}

IotManager.$inject = ["$rootScope", "errorsLogger", "IotConnectionManager", "IotMessageRecoveryManager", "environmentInfo"];
angular.module('core').service('IotManager', IotManager);
