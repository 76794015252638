<div class="channels-new">
    <mat-progress-bar mode="query" *ngIf="isProcessing"></mat-progress-bar>
    <div class="channels-content tw-flex tw-flex-row">
        <!-- left -->
        <section class="channels-left-column tw-flex tw-flex-col left-section">
            <div class="wrapper-sticky">
                <div class="tw-flex tw-justify-end">
                    <button mat-icon-button aria-label="Filter channels" (click)="filterChannels()" [color]="filterToggle ? 'primary' : ''">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Add new channels" (click)="addNewChannels()" [disabled]="isNewEntryInProgress || channelForm?.dirty">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-form-field class="tw-flex tw-mt-1.5" appearance="fill" *ngIf="filterToggle">
                    <mat-label>{{'SETTINGS.CHANNELS_MANAGEMENT.SEARCH'|translate}}</mat-label>
                    <mat-icon matIconPrefix>search</mat-icon>
                    <input matInput name="search" [(ngModel)]="filterList">
                    <button *ngIf="filterList" matSuffix mat-icon-button aria-label="Clear" (click)="filterList=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="channels-list-container" *ngIf="channelsList && channelsList.length > 0">
                <mat-nav-list dense>
                    <mat-list-item disabled>
                        <div class="tw-flex tw-flex-row tw-items-center">
                            <img matListAvatar class="tw-flex avatar till" src="assets/images/channels/pos.png" alt="{{ 'SETTINGS.CHANNELS_MANAGEMENT.CASHREGISTER' | translate }}">
                            <div mat-line class="tw-flex tw-truncate tw-pl-2" translate>
                                SETTINGS.CHANNELS_MANAGEMENT.CASHREGISTER
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item *ngFor="let channel of channelsList | matchesChannel:filterList;" (click)="selectChannel(channel, channelForm?.dirty)" [activated]="isSelected(channel)">
                        <div class="tw-flex tw-flex-row tw-items-center">
                            <img matListAvatar class="tw-flex avatar" src="{{channel._imgUrl}}" onerror="this.src='assets/images/channels/pos.png'" alt="{{ channel.name }}">
                            <div mat-line class="tw-flex tw-truncate tw-pl-2">
                                {{channel.name}}
                            </div>
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </section>
        <!-- right -->
        <section class="tw-flex tw-flex-col right-section" [ngClass]="{'tw-flex-wrap tw-content-center tw-justify-center' : !selected}">
            <div class="wrapper-sticky wrapper-icon-border tw-flex tw-flex-row tw-justify-end" *ngIf="selected">
                <button mat-icon-button aria-label="Save button" (click)="save()" [disabled]="channelForm?.invalid">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete button" (click)="delete()" [disabled]="firstEntry">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

            <!-- magic form -->
            <div class="tw-flex tw-flex-col tw-h-[calc(100vh-113px)] wrapper-magic-form" *ngIf="selected && channelForm">
                <tilby-magic-form [form]="channelForm"></tilby-magic-form>
            </div>
            <div *ngIf="!selected" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh-113px)]">
                <mat-icon style="transform: scale(7);" class="tw-mb-20">
                    <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
                <div class="tw-pl-10 tw-pr-10 tw-text-center">{{'SETTINGS.CHANNELS_MANAGEMENT.NOT_SELECTED_MSG'|translate}}</div>
            </div>
        </section>
    </div>
</div>
