import {InjectionToken} from "@angular/core";
import { LeanPMSPayment } from "./modules/digital-payments/service/lean-pms/lean-pms";

/* -----UPGRADE START----- */
export const $state = new InjectionToken<any>("$state");
export const $stateParams = new InjectionToken<any>("$stateParams");
export const $translate = new InjectionToken<any>("$translate");
export const $urlService = new InjectionToken<any>("$urlService");
export const DigitalPaymentsManager = new InjectionToken<any>("DigitalPaymentsManager");
export const ExternalOrdersManager = new InjectionToken<any>("ExternalOrdersManager");
export const ExternalSalesManager = new InjectionToken<any>("ExternalSalesManager");
export const FiscalPrinters = new InjectionToken<any>("FiscalPrinters");
export const FiscalProviders = new InjectionToken<any>("FiscalProviders");
export const RetailForceProvider = new InjectionToken<any>("RetailForceProvider");
export const RootScope = new InjectionToken<any>("RootScope");
export const activeSiteDialog = new InjectionToken<any>("activeSiteDialog");
export const addSelectCustomerDialog = new InjectionToken<any>("addSelectCustomer");
export const authRequestDialog = new InjectionToken<any>("authRequestDialog");
export const cashlogyDriver = new InjectionToken<any>("cashlogyDriver");
export const customerUtils = new InjectionToken<any>("customerUtils");
export const customerDisplayManager = new InjectionToken<any>("CustomerDisplayManager");
export const dashboardDataManager = new InjectionToken<any>("dashboardDataManager");
export const deviceInfoDialog = new InjectionToken<any>("deviceInfoDialog");
export const digitalPaymentsManager = new InjectionToken<any>("DigitalPaymentsManager");
export const documentPrinter = new InjectionToken<any>("documentPrinter");
export const eInvoicePlugin = new InjectionToken<any>("eInvoicePlugin");
export const errorsLogger = new InjectionToken<any>("errorsLogger");
export const fiscalUtils = new InjectionToken<any>("fiscalUtils");
export const generalDocumentPrinterSelect = new InjectionToken<any>("generalDocumentPrinterSelect");
export const giftCardPrinter = new InjectionToken<any>("GiftCardPrinter");
export const itemLabelsGenerator = new InjectionToken<any>("itemLabelsGenerator");
export const itemListSelector = new InjectionToken<any>("itemListSelector");
export const itemSelector = new InjectionToken<any>("itemSelector");
export const kioskUtils = new InjectionToken<any>("kioskUtils");
export const mdSidenav = new InjectionToken<any>("$mdSidenav");
export const noFiscalPrinters = new InjectionToken<any>("NoFiscalPrinters");
export const operatorManager = new InjectionToken<any>("OperatorManager");
export const optionsDialog = new InjectionToken<any>("optionsDialog");
export const prepaidSale = new InjectionToken<any>("prepaidSale");
export const printerErrorFiscal = new InjectionToken<any>("printerErrorFiscal");
export const promptDialog = new InjectionToken<any>("promptDialog");
export const radioListSelector = new InjectionToken<any>("radioListSelector");
export const restManager = new InjectionToken<any>("restManager");
export const saleItemManager = new InjectionToken<any>("orderItemManager");
export const saleUtils = new InjectionToken<any>("saleUtils");
export const savedSalesDialog = new InjectionToken<any>("savedSales");
export const selectSaleItemCombination = new InjectionToken<any>("selectSaleItemCombination");
export const sessionManager = new InjectionToken<any>("sessionManager");
export const showSaleItemDialog = new InjectionToken<any>("showSaleItem");
export const splitSaleDialog = new InjectionToken<any>("splitSaleDialog");
export const util = new InjectionToken<any>("util");
export const leanPMS = new InjectionToken<LeanPMSPayment>("LeanPMSPayment");
export const campgestPMS = new InjectionToken<any>("CampgestPMSPayment");
export const wellbyPMS = new InjectionToken<any>("WellbyPMSPayment");


export const DigitalPaymentsManagerServiceFactory = (i: any) => i.get('DigitalPaymentsManager');
export const ExternalOrdersManagerServiceFactory = (i: any) => i.get('ExternalOrdersManager');
export const ExternalSalesManagerServiceFactory = (i: any) => i.get('ExternalSalesManager');
export const FiscalPrintersServiceFactory = (i: any) => i.get('FiscalPrinters');
export const FiscalProvidersServiceFactory = (i: any) => i.get('FiscalProviders');
export const RetailForceProviderServiceFactory = (i: any) => i.get('RetailForceProvider');
export const activeSiteDialogServiceFactory = (i: any) => i.get('activeSiteDialog');
export const addSelectCustomerDialogServiceFactory = (i: any) => i.get('addSelectCustomer');
export const authRequestDialogServiceFactory = (i: any) => i.get('authRequestDialog');
export const cashlogyDriverServiceFactory = (i: any) => i.get('cashlogyDriver');
export const customerUtilsServiceFactory = (i: any) => i.get('customerUtils');
export const customerDisplayManagerServiceFactory = (i: any) => i.get('CustomerDisplayManager');
export const dashboardDataManagerServiceFactory = (i: any) => i.get('dashboardDataManager');
export const deviceInfoDialogServiceFactory = (i: any) => i.get('deviceInfoDialog');
export const digitalPaymentsManagerServiceFactory = (i: any) => i.get('DigitalPaymentsManager');
export const documentPrinterServiceFactory = (i: any) => i.get('documentPrinter');
export const eInvoicePluginServiceFactory = (i: any) => i.get('eInvoicePlugin');
export const errorsLoggerServiceFactory = (i: any) => i.get('errorsLogger');
export const fiscalUtilsServiceFactory = (i: any) => i.get('fiscalUtils');
export const generalDocumentPrinterSelectServiceFactory = (i: any) => i.get('generalDocumentPrinterSelect');
export const giftCardPrinterServiceFactory = (i: any) => i.get('GiftCardPrinter');
export const itemLabelsGeneratorServiceFactory = (i: any) => i.get('itemLabelsGenerator');
export const itemListSelectorServiceFactory = (i: any) => i.get('itemListSelector');
export const itemSelectorServiceFactory = (i: any) => i.get('itemSelector');
export const kioskUtilsServiceFactory = (i: any) => i.get('kioskUtils');
export const mdSidenavServiceFactory = (i: any) => i.get('$mdSidenav');
export const optionsDialogServiceFactory = (i: any) => i.get('optionsDialog');
export const prepaidSaleServiceFactory = (i: any) => i.get('prepaidSale');
export const printerErrorFiscalServiceFactory = (i: any) => i.get('printerErrorFiscal');
export const promptDialogServiceFactory = (i: any) => i.get('promptDialog');
export const radioListSelectorServiceFactory = (i: any) => i.get('radioListSelector');
export const restManagerServiceFactory = (i: any) => i.get('restManager');
export const noFiscalPrintersServiceFactory = (i: any) => i.get('NoFiscalPrinters');
export const operatorManagerServiceFactory = (i: any) => i.get('OperatorManager');
export const rootScopeServiceFactory = (i: any) => i.get('$rootScope');
export const saleItemManagerServiceFactory = (i: any) => i.get('saleItemManager');
export const saleUtilServiceFactory = (i: any) => i.get('saleUtils');
export const savedSalesDialogServiceFactory = (i: any) => i.get('savedSales');
export const selectSaleItemCombinationServiceFactory = (i: any) => i.get('selectSaleItemCombination');
export const sessionManagerServiceFactory = (i: any) => i.get('sessionManager');
export const showSaleItemDialogServiceFactory = (i: any) => i.get('showSaleItem');
export const splitSaleDialogServiceFactory = (i: any) => i.get('splitSaleDialog');
export const stateParamsServiceFactory = (i: any) => i.get('$stateParams');
export const stateServiceFactory = (i: any) => i.get('$state');
export const translateServiceFactory = (i: any) => i.get('$translate');
export const urlServiceFactory = (i: any) => i.get('$urlService');
export const utilServiceFactory = (i: any) => i.get('util');
export const leanPMSServiceFactory = (i: any) => i.get('leanPMS');
export const campgestPMSServiceFactory = (i: any) => i.get('campgestPMS');
export const wellbyPMSServiceFactory = (i: any) => i.get('wellbyPMS');

export const DigitalPaymentsManagerServiceProvider = {provide: DigitalPaymentsManager, useFactory: DigitalPaymentsManagerServiceFactory, deps: ['$injector']};
export const ExternalOrdersManagerServiceProvider = {provide: ExternalOrdersManager, useFactory: ExternalOrdersManagerServiceFactory, deps: ['$injector']};
export const ExternalSalesManagerServiceProvider = {provide: ExternalSalesManager, useFactory: ExternalSalesManagerServiceFactory, deps: ['$injector']};
export const FiscalPrintersServiceProvider = {provide: FiscalPrinters, useFactory: FiscalPrintersServiceFactory, deps: ['$injector']};
export const FiscalProvidersServiceProvider = {provide: FiscalProviders, useFactory: FiscalProvidersServiceFactory, deps: ['$injector']};
export const RetailForceProviderServiceProvider = {provide: RetailForceProvider, useFactory: RetailForceProviderServiceFactory, deps: ['$injector']};
export const activeSiteDialogServiceProvider = {provide: activeSiteDialog, useFactory: activeSiteDialogServiceFactory, deps: ['$injector']};
export const addSelectCustomerDialogServiceProvider = {provide: addSelectCustomerDialog, useFactory: addSelectCustomerDialogServiceFactory, deps: ['$injector']};
export const authRequestDialogServiceProvider = {provide: authRequestDialog, useFactory: authRequestDialogServiceFactory, deps: ['$injector']};
export const cashlogyDriverServiceProvider = {provide: cashlogyDriver, useFactory: cashlogyDriverServiceFactory, deps: ['$injector']};
export const customerUtilsServiceProvider = {provide: customerUtils, useFactory: customerUtilsServiceFactory, deps: ['$injector']};
export const customerDisplayManagerServiceProvider = {provide: customerDisplayManager, useFactory: customerDisplayManagerServiceFactory, deps: ['$injector']};
export const dashboardDataManagerServiceProvider = {provide: dashboardDataManager, useFactory: dashboardDataManagerServiceFactory, deps: ['$injector']};
export const deviceInfoDialogServiceProvider = {provide: deviceInfoDialog, useFactory: deviceInfoDialogServiceFactory, deps: ['$injector']};
export const digitalPaymentsManagerServiceProvider = {provide: digitalPaymentsManager, useFactory: digitalPaymentsManagerServiceFactory, deps: ['$injector']};
export const documentPrinterServiceProvider = {provide: documentPrinter, useFactory: documentPrinterServiceFactory, deps: ['$injector']};
export const eInvoicePluginServiceProvider = {provide: eInvoicePlugin, useFactory: eInvoicePluginServiceFactory, deps: ['$injector']};
export const errorsLoggerServiceProvider = {provide: errorsLogger, useFactory: errorsLoggerServiceFactory, deps: ['$injector']};
export const fiscalUtilsServiceProvider = {provide: fiscalUtils, useFactory: fiscalUtilsServiceFactory, deps: ['$injector']};
export const generalDocumentPrinterSelectServiceProvider = {provide: generalDocumentPrinterSelect, useFactory: generalDocumentPrinterSelectServiceFactory, deps: ['$injector']};
export const giftCardPrinterServiceProvider = {provide: giftCardPrinter, useFactory: giftCardPrinterServiceFactory, deps: ['$injector']};
export const itemLabelsGeneratorServiceProvider = {provide: itemLabelsGenerator, useFactory: itemLabelsGeneratorServiceFactory, deps: ['$injector']};
export const itemListSelectorServiceProvider = {provide: itemListSelector, useFactory: itemListSelectorServiceFactory, deps: ['$injector']};
export const itemSelectorServiceProvider = {provide: itemSelector, useFactory: itemSelectorServiceFactory, deps: ['$injector']};
export const kioskUtilsServiceProvider = {provide: kioskUtils, useFactory: kioskUtilsServiceFactory, deps: ['$injector']}
export const mdSidenavServiceProvider = {provide: mdSidenav, useFactory: mdSidenavServiceFactory, deps: ['$injector']};
export const optionsDialogServiceProvider = {provide: optionsDialog, useFactory: optionsDialogServiceFactory, deps: ['$injector']};
export const prepaidSaleServiceProvider = {provide: prepaidSale, useFactory: prepaidSaleServiceFactory, deps: ['$injector']};
export const printerErrorFiscalServiceProvider = {provide: printerErrorFiscal, useFactory: printerErrorFiscalServiceFactory, deps: ['$injector']};
export const promptDialogServiceProvider = {provide: promptDialog, useFactory: promptDialogServiceFactory, deps: ['$injector']};
export const radioListSelectorServiceProvider = {provide: radioListSelector, useFactory: radioListSelectorServiceFactory, deps: ['$injector']};
export const restManagerServiceProvider = {provide: restManager, useFactory: restManagerServiceFactory, deps: ['$injector']};
export const noFiscalPrintersServiceProvider = {provide: noFiscalPrinters, useFactory: noFiscalPrintersServiceFactory, deps: ['$injector']};
export const operatorManagerServiceProvider = {provide: operatorManager, useFactory: operatorManagerServiceFactory, deps: ['$injector']};
export const rootScopeServiceProvider = {provide: RootScope, useFactory: rootScopeServiceFactory, deps: ['$injector']};
export const saleItemManagerServiceProvider = {provide: saleItemManager, useFactory: saleItemManagerServiceFactory, deps: ['$injector']};
export const saleUtilsServiceProvider = {provide: saleUtils, useFactory: saleUtilServiceFactory, deps: ['$injector']};
export const savedSalesDialogServiceProvider = {provide: savedSalesDialog, useFactory: savedSalesDialogServiceFactory, deps: ['$injector']};
export const selectSaleItemCombinationServiceProvider = {provide: selectSaleItemCombination, useFactory: selectSaleItemCombinationServiceFactory, deps: ['$injector']};
export const sessionManagerServiceProvider = {provide: sessionManager, useFactory: sessionManagerServiceFactory, deps: ['$injector']};
export const showSaleItemDialogServiceProvider = {provide: showSaleItemDialog, useFactory: showSaleItemDialogServiceFactory, deps: ['$injector']};
export const splitSaleDialogServiceProvider = {provide: splitSaleDialog, useFactory: splitSaleDialogServiceFactory, deps: ['$injector']};
export const stateParamsServiceProvider = {provide: $stateParams, useFactory: stateParamsServiceFactory, deps: ['$injector']};
export const stateServiceProvider = {provide: $state, useFactory: stateServiceFactory, deps: ['$injector']};
export const translateServiceProvider = {provide: $translate, useFactory: translateServiceFactory, deps: ['$injector']};
export const urlServiceProvider = {provide: $urlService, useFactory: urlServiceFactory, deps: ['$injector']};
export const utilServiceProvider = {provide: util, useFactory: utilServiceFactory, deps: ['$injector']};
export const leanPMSServiceProvider = {provide: leanPMS, useFactory: leanPMSServiceFactory, deps: ['$injector']};
export const campgestPMSServiceProvider = {provide: campgestPMS, useFactory: campgestPMSServiceFactory, deps: ['$injector']};
export const wellbyPMSServiceProvider = {provide: wellbyPMS, useFactory: wellbyPMSServiceFactory, deps: ['$injector']};

/* -----UPGRADE END----- */
