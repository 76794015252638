import * as angular from 'angular';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment-timezone';
import * as Papa from 'papaparse';

angular.module('bookings').factory('downloadBookingDialog', ["$mdDialog", "$translate", "$state", "entityManager", "toast", "util", function($mdDialog, $translate, $state, entityManager, toast, util) {
    downloadBookingDialogController.$inject = ["$scope", "currentDate", "bookings", "rooms"];
    function downloadBookingDialogController($scope, currentDate, bookings, rooms) {
        $scope.data = {
            format: null
        };
        $scope.currentDate = currentDate;

        bookings = bookings.sort((a,b) => moment(a.booked_for).diff(b.booked_for));

        const bookingToday = _.filter(bookings, (b) => {
            if (moment(currentDate).format('L') === moment(b.booked_for).format('L')) {
                return b;
            }
        });

        const tablesById = {};
        _.forEach(rooms, function(room) {
            _.forEach(room.tables, function(table) {
                tablesById[table.id] = {
                    table_name: table.name,
                    table_id: table.id,
                    room_name: room.name,
                    room_id: room.id,
                    covers: table.covers,
                    type: table.order_type
                };
            });
        });

        const getTablesCaption = (booking) => {
            const caption = [];

            _.forEach(booking.tables, function(table) {
                const tableData = tablesById[table.table_id];

                if(tableData) {
                    caption.push([tableData.room_name, tableData.table_name].join(', '));
                }
            });

            return !_.isEmpty(caption) ? _.toString(caption.join(' - ')) : '';
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.confirm = function() {
            if($scope.data.format) {
                switch($scope.data.format) {
                    case 'pdf': $scope.downloadPDF(); break;
                    case 'xls': $scope.downloadXLS(); break;
                }
            }
        };

        const getStatus = (status) => {
            switch(status) {
                case 'provisional':
                    return $translate.instant('BOOKINGS.STATUSES.PROVISIONAL');
                case 'confirmed':
                    return $translate.instant('BOOKINGS.STATUSES.CONFIRMED');
                case 'arrived':
                    return $translate.instant('BOOKINGS.STATUSES.ARRIVED');
                case 'seated':
                    return $translate.instant('BOOKINGS.STATUSES.SEATED');
                case 'departed':
                    return $translate.instant('BOOKINGS.STATUSES.DEPARTED');
                case 'noshow':
                    return $translate.instant('BOOKINGS.STATUSES.NOSHOW');
                default:
                    return '';
            }
        };

        const generateTable = (caption) => {
            const tableStart = '<table style="width:100%;border-collapse: collapse;">';
            const tableCaption = `<caption>${caption}</caption>`;
            const tableEnd = '</table>';
            const tableHead = `<tr style="border:1px solid black;padding: 10px;">
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.START')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.END')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.DURATION')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.COVERS')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.CUSTOMER')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.TABLE')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.STATUS')}</th>
                <th style="padding: 10px;">${$translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.NOTES')}</th>
            </tr>`;
            let tableBody = '';
            for(let b of bookingToday) {
                const start = moment(b.booked_for).format('LT');
                const end = moment(b.booked_for).add(b.duration, 'm').format('LT');
                const companyName = b.booking_customer.company_name;
                const customerName = `${b.booking_customer.first_name} ${b.booking_customer.last_name}`;
                const customer = companyName && companyName !== "" ? companyName: customerName;

                tableBody += `<tr style="border:1px solid #000;text-align: center;padding: 10px;">
                    <td style="padding: 10px;">${start}</td>
                    <td style="padding: 10px;">${end}</td>
                    <td style="padding: 10px;">${b.duration}</td>
                    <td style="padding: 10px;">${b.people}</td>
                    <td style="padding: 10px;">${customer}</td>
                    <td style="padding: 10px;">${getTablesCaption(b)}</td>
                    <td style="padding: 10px;">${getStatus(b.status)}</td>
                    <td style="padding: 10px;">${b.notes ? b.notes : ''}</td>
                </tr>`;
            }

            return tableStart + tableCaption + tableHead + tableBody + tableEnd;
        };

        $scope.downloadPDF = () => {
            const prefixFileName = $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.PREFIX_FILE_NAME');
            const currentDate = new Date($scope.currentDate);
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            const suffixFileName = `${day}_${month}_${year}`;
            const tableCaption = `${prefixFileName} ${day}/${month}/${year}`;
            const opt = {
                margin: 1,
                filename: `${prefixFileName}_${suffixFileName}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1, ignoreElements: (el) => (el.nodeName === 'HEAD' || el.id === 'content') },
                jsPDF: { unit: 'cm', format: 'letter', orientation: 'landscape' },
                pagebreak: { mode: ['css'], after: '.break-page' }
            };

            const contentBoxes = generateTable(tableCaption);
            html2pdf().set(opt).from(contentBoxes).save().catch((err) => {
                toast.show({
                    message: $translate.instant('SETTINGS.E_COMMERCE.SERVICES.ERROR_CREATE_PDF'),
                    actions: [{
                        text: $translate.instant('MISC.OK'),
                        action: 'dismiss',
                        highlight: true
                    }],
                    hideDelay: 0
                });
            });
            // close dialog
            $mdDialog.cancel();
        };

        $scope.downloadXLS = () => {
            var fieldsBooking = [
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.START'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.END'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.DURATION'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.COVERS'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.CUSTOMER'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.TABLE'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.STATUS'),
                $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.NOTES'),
            ];

            const bookingForCSV = _.map(bookingToday, function(b) {
                let result = {};
                const start = moment(b.booked_for).format('LT');
                const end = moment(b.booked_for).add(b.duration, 'm').format('LT');
                const companyName = b.booking_customer.company_name;
                const customerName = `${b.booking_customer.first_name} ${b.booking_customer.last_name}`;
                const customer = companyName && companyName !== "" ? companyName: customerName;

                Object.assign(result, {
                    start: start,
                    end: end,
                    duration: b.duration,
                    people: b.people,
                    customer: customer,
                    table: getTablesCaption(b),
                    status: getStatus(b.status),
                    notes: b.notes ? b.notes : ''
                });

                return result;
            });

            const head = fieldsBooking.join(';');
            const csv = head + '\r\n' + Papa.unparse(bookingForCSV, {
                columns: ['start', 'end', 'duration', 'people', 'customer', 'table', 'status', 'notes'],
                delimiter: ";",
                header: false,
            });

            const prefixFileName = $translate.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.PREFIX_FILE_NAME');
            const currentDate = new Date($scope.currentDate);
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            const suffixFileName = `${day}_${month}_${year}`;
            util.downloadFile(csv, `${prefixFileName}_${suffixFileName}.csv`, 'text/plain;charset=utf-8;');

            // close dialog
            $mdDialog.cancel();
        };
    }

    var downloadBookingDialog = {
        show: function(currentDate) {
            return $mdDialog.show({
                controller: downloadBookingDialogController,
                template: require('./download-booking-dialog.html'),
                multiple: true,
                locals: {
                    currentDate: currentDate
                },
                resolve: {
                    bookings: function() {
                        return entityManager.bookings.fetchCollectionOffline();
                    },
                    rooms: function() {
                        return entityManager.rooms.fetchCollectionOffline();
                    }
                }
            });
        }
    };

    return downloadBookingDialog;
}]);
