import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { v4 as generateUuid } from 'uuid';

angular.module('orders').factory('orderUtils', orderUtils);

orderUtils.$inject = ["$translate", "progressivesManager", "checkManager", "connection", "entityManager", "OperatorManager", "util"];

function orderUtils($translate, progressivesManager, checkManager, connection, entityManager, OperatorManager, util) {
    const orderUtils = {
        getOrderTemplate: async (params) => {
            const progressive = await progressivesManager.getProgressives();
            const opData = OperatorManager.getOperatorData();
            const isOnline = connection.isOnline();

            let orderNumber = _.toInteger(isOnline ? progressive.order_number : progressive.order_number_offline) + 1;
            let orderName;

            if(!isOnline) {

                if (params?.room?.name && params?.table?.name) {
                    orderName = ["*", params.room.name, params.table.name].join(' ');
                } else {
                    const nameString = (opData.first_name && opData.last_name) ? [opData.first_name[0], opData.last_name[0]].join('') : opData.username.substr(0, 3);

                    orderName = $translate.instant('ORDERS.NEW_ORDER.OFFLINE_ORDER', { orderNumber: orderNumber, nameString: nameString });
                }
            } else {
                orderName = $translate.instant('ORDERS.NEW_ORDER.ONLINE_ORDER', { orderNumber: orderNumber });
            }

            const orderCustomer = params?.customer || null;

            if(orderCustomer) {
                orderCustomer.customer_id = orderCustomer.customer_id || orderCustomer.id;
                delete orderCustomer.id;
            }

            const order = {
                amount: 0,
                booking_id: params?.booking?.id,
                channel: params?.channel || 'pos',
                covers: Number.isInteger(params?.covers) ? params.covers : (params?.table?.covers || 1),
                name: _.truncate(params?.name || orderName, { length: 30, omission: '' }),
                external_id: params?.external_id,
                net_amount: 0,
                open_at: new Date().toISOString(),
                operator_id: opData.id,
                operator_name: opData.full_name,
                order_customer: orderCustomer,
                order_number: orderNumber,
                order_items: [],
                paid: params?.paid || false,
                room_id: params?.room?.id,
                room_name: params?.room?.name,
                status: 'open',
                table_id: params?.table?.id,
                table_name: params?.table?.name,
                type: params?.type || 'normal',
                uuid: generateUuid()
            };

            if (['take_away', 'delivery'].includes(order.type)) {
                delete order.covers;
                order.deliver_at = _.isDate(params?.deliver_at) ? params.deliver_at.toISOString() : moment().milliseconds(0).seconds(0).toISOString();

                if(order.type === 'take_away') {
                    delete order.external_id;
                }
            }

            return order;
        },
        calculateOrderPrices: function calculateOrderPrices(targetOrder) {
            var considerIngredientsRemoval = checkManager.getPreference("orders.ingredients_removal_affects_price");
            var halfPortionPref = _.toNumber(checkManager.getPreference("orders.half_portion_discount_value"));
            var halfPortionValue = _.isFinite(halfPortionPref) ? util.round(halfPortionPref / 100) : 0.5;

            _.assign(targetOrder, {
                amount: 0,
                net_amount: 0
            });

            _.forEach(targetOrder.order_items, function(orderItem) {
                var ingredientsSum = _.sumBy(orderItem.ingredients, function(ingredient) {
                    if (ingredient.price_difference) {
                        switch(ingredient.type) {
                            case 'added':
                                return ingredient.price_difference * (ingredient.quantity || 1);
                            case 'removed':
                              if(considerIngredientsRemoval) {
                                  return -ingredient.price_difference;
                                }
                            break;
                        }
                    }
                });

                var variationsSum = _.sumBy(orderItem.variations, 'price_difference');

                var price = (orderItem.half_portion) ? util.round(orderItem.price * (1 - halfPortionValue)) : orderItem.price;

                orderItem.final_price = util.round(orderItem.quantity * (price + _.toFinite(ingredientsSum) + _.toFinite(variationsSum)));

                if(orderItem.final_price < 0) {
                    orderItem.final_price = 0;
                }

                orderItem.final_net_price = util.round(_.toFinite(orderItem.final_price / (1 + orderItem.vat_perc / 100)));

                targetOrder.amount += orderItem.final_price;
                targetOrder.net_amount += orderItem.final_net_price;
            });

            _.assign(targetOrder, {
                amount: util.round(targetOrder.amount),
                net_amount: util.round(targetOrder.net_amount)
            });
        },
        mergeOrders: function mergeOrders(orders, tableInfo) {
            let masterOrder = _.chain(orders).head().omit(['id', 'previous_order', 'order_customer.id']).cloneDeep().value();
            let slaveOrders = _.tail(orders);

            if(!_.isObject(masterOrder)) {
                return null;
            }

            //Assign table information if present
            if(tableInfo) {
                Object.assign(masterOrder, {
                    table_id: tableInfo.table_id,
                    table_name: tableInfo.table_name,
                    room_id: tableInfo.room_id,
                    room_name: tableInfo.room_name
                });
            }

            //Add slave orders' order items to the master order
            _.forEach(slaveOrders, function(order) {
                _.forEach(order.order_items, function(orderItem) {
                    masterOrder.order_items.push(_.cloneDeep(orderItem));
                });
            });

            //Cleanup ids from order items
            _.forEach(masterOrder.order_items, function(orderItem) {
                delete orderItem.id;

                orderItem.uuid = generateUuid();

                _.forEach(orderItem.variations, function(variation) {
                    delete variation.id;
                });

                _.forEach(orderItem.ingredients, function(ingredient) {
                    delete ingredient.id;
                });
            });

            this.calculateOrderPrices(masterOrder);

            return masterOrder;
        },
        getCleanOrderItems: function(order) {
            return (order.order_items || []).map((orderItem) => ({
                category_id: orderItem.category_id ?? null,
                exit: orderItem.exit || 0,
                half_portion: !!orderItem.half_portion,
                ingredients: (orderItem.ingredients || []).map((ingredient) => ({
                    ingredient_id: ingredient.ingredient_id,
                    name: ingredient.name,
                    price_difference: ingredient.price_difference ?? null,
                    quantity: ingredient.quantity ?? null,
                    type: ingredient.type ?? null
                })),
                item_id: orderItem.item_id ?? null,
                name: orderItem.name,
                notes: orderItem.notes ?? null,
                order_name: orderItem.order_name ?? null,
                price: orderItem.price,
                quantity: orderItem.quantity,
                uuid: orderItem.uuid,
                variations: (orderItem.variations || []).map((variation) => ({
                    name: variation.name,
                    price_difference: variation.price_difference ?? null,
                    value: variation.value,
                    variation_id: variation.variation_id,
                    variation_value_id: variation.variation_value_id
                }))
            }));
        },
        getOrderCaption: function(order, deliveryCircuits) {
            switch(order.type) {
                case 'normal':
                    if (order.room_name && order.table_name) {
                        return order.room_name + ' - ' + order.table_name;
                    } else {
                        return '';
                    }
                break;
                case 'delivery':
                    if((order.channel && order.channel !== 'pos') || order.external_id) {
                        var channelName;

                        if(order.channel) {
                            channelName = _.get(deliveryCircuits, [order.channel, 'name'], order.channel);
                        }

                        return (channelName ? channelName + ' ' : '') + (order.external_id ? '#' + order.external_id : '');
                    } else {
                        return '';
                    }
                break;
                default:
                    return '';
            }
        },
        getOrderCoverConfig: async () => {
            let coverConfig = {};

            if(checkManager.getPreference('orders.automated_add_cover')) {
                try {
                    switch(checkManager.getPreference('orders.automated_add_cover.type')) {
                        case 'id':
                            let idCover = _.toInteger(checkManager.getPreference('orders.automated_add_cover.value'));
                            let coverItem = await entityManager.items.fetchOneOfflineFirst(idCover);

                            if (coverItem) {
                                Object.assign(coverConfig, { type: 'item', data: coverItem });
                            } else {
                                throw 'MISSING_COVER_ITEM';
                            }
                        break;
                        case 'perc':
                            let coverPriceChange = {
                                type: "surcharge_perc",
                                description: $translate.instant('ORDERS.ACTIVE_ORDER.COVER_SURCHARGE'),
                                value: _.toInteger(checkManager.getPreference('orders.automated_add_cover.value')),
                            };

                            Object.assign(coverConfig, { type: 'price_change', data: coverPriceChange });
                        break;
                        default:
                            throw 'UNKNOWN_COVER_TYPE';
                    }
                } catch(err) {
                    Object.assign(coverConfig, { type: 'error' });
                }
            } else {
                Object.assign(coverConfig, { type: 'none' });
            }

            return coverConfig;
        },
        getCoverSaleItem: (order, coverItem) => {
            const timeNow = new Date().toISOString();
            const opData = OperatorManager.getOperatorData();
            let priceListToUse = [order.order_customer?.default_pricelist, util.getCurrentPriceList(), 1].find((priceList) => !_.isNil(priceList) && !_.isNil(coverItem[`price${priceList}`]));

            let saleItem = {
                added_at: timeNow,
                category_id: coverItem.category?.id,
                category_name: coverItem.category?.name,
                cost: coverItem.cost,
                department_id: coverItem.department?.id,
                department_name: coverItem.department?.name,
                item_id: coverItem.id,
                lastupdate_at: timeNow,
                lastupdate_by: opData.id,
                name: coverItem.name,
                notes: null,
                price_changes: [],
                price: util.round(coverItem[`price${priceListToUse}`]),
                quantity: order.covers || 1,
                seller_id: opData.id,
                seller_name: opData.full_name,
                sku: coverItem.sku,
                type: "sale",
                uuid: generateUuid(),
                vat_perc: coverItem.department?.vat?.value ?? coverItem.vat_perc,
            };

            if (!_.isEmpty(coverItem.barcodes)) {
                saleItem.barcode = _.head(coverItem.barcodes).barcode;
            }

            return saleItem;
        },
        getCoverValue: (order, coverConfig) => {
            let result = 0;

            switch(coverConfig.type) {
                case 'item':
                    const coverItem = coverConfig.data;
                    const priceLists = [order.order_customer?.default_pricelist, util.getCurrentPriceList(), 1];
                    let priceListToUse = priceLists.find((priceList) => !_.isNil(priceList) && !_.isNil(coverConfig.data[`price${priceList}`]));

                    result = util.round(coverItem[`price${priceListToUse}`] * (order.covers || 1));
                break;
                case 'price_change':
                    const coverPc = coverConfig.data;
                    result = util.round(order.amount * (coverPc.value / 100));
                break;
            }

            return result;
        }
    };

    return orderUtils;
}
