import {CustomForm } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Giftcards } from 'tilby-models';

interface GiftcardsFieldsFe {
    isNew?:boolean;
}

export type GiftcardsFields = Giftcards & GiftcardsFieldsFe;

export class GiftcardDefault implements GiftcardsFields {
    code: string = '';
    initial_value: number = -1;
    last_value: number = -1;
    is_divisible: boolean = false;
}

export type GiftcardsFieldsNullable = { [prop in string & keyof GiftcardsFields]:GiftcardsFields[prop]|null }
export type GiftcardsForm = Partial<CustomForm<GiftcardsFieldsNullable>>;
