import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OpenDialogsService } from "../../services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { restManager } from "app/ajs-upgraded-providers";

@Component({
    selector: 'app-edit-id-badge-dialog.component',
    templateUrl: './edit-id-badge-dialog.component.html',
    styleUrls: ['./edit-id-badge-dialog.component.scss']
})
export class EditIdBadgeDialogComponent implements OnInit {

    // data: any = {};
    errorEditIdBadge : boolean = false;
    errorEditIdBadgeMessage : string = '';
    editIdBadgeDialogForm!: FormGroup;

    constructor(public dialogRef: MatDialogRef<OpenDialogsService>,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        @Inject(restManager) private restManagerService: any,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.editIdBadgeDialogForm = this.formBuilder.group({
            id_badge: ['', Validators.required]
          });
    }

    submit() {
        if (this.editIdBadgeDialogForm.valid) {
            const formData = this.editIdBadgeDialogForm.value;
            this.updateBadge();
        } else {
             this.editIdBadgeDialogForm.markAllAsTouched();
        }

    }

    async updateBadge() {
        const values = this.editIdBadgeDialogForm.value;
        const endPoint = `shop_users/${this.data.userId}/badge_id`;
        const payload = { badge_id: values.id_badge };

        await this.restManagerService.put(endPoint, null, payload).then((res : any) => {
                if(res) {
                    this.dialogRef.close(this.editIdBadgeDialogForm.value);
                }
            }).catch((res : any) => {
                console.error(`PUT ${endPoint}`, res);
                if (res && res.status === 500) {
                    this.errorEditIdBadgeMessage = this.translate.instant('SETTINGS.EDIT_ID_BADGE_DIALOG.ERROR');
                    this.errorEditIdBadge = true;
                }
            });
    }

    isFormInvalid(): boolean {
        return this.editIdBadgeDialogForm.invalid;
    }
}
