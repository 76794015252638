import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fidelity', ['application']);

angular.module('fidelity').config(["$stateProvider", "$urlRouterProvider", "$translatePartialLoaderProvider", function($stateProvider, $urlRouterProvider, $translatePartialLoaderProvider) {

    $translatePartialLoaderProvider.addPart('fidelity');

    $stateProvider.state('app.fidelity', {
        url: '/fidelity'
    });

    $stateProvider.state('app.fidelity.search', {
        url: '/search',
        views: {
            "appContent@app": {
                template: require('./partial/fidelity-search/fidelity-search.html'),
                controller: 'FidelitySearchCtrl'
            }
        }
    });

    $stateProvider.state('app.fidelity.details', {
        url: '/details',
        views: {
            "appContent@app": {
                template: require('./partial/fidelity-details/fidelity-details.html'),
                controller: 'FidelityDetailsCtrl'
            }
        },
        params: {
            customer: null
        },
        resolve: {
            customer: ["$stateParams", function($stateParams) {
                return $stateParams.customer;
            }]
        }
    });

    $stateProvider.state('app.fidelity.setup', {
        url: "/setup",
        views: {
            "appContent@app": {
                template: require('./partial/fidelity-setup/fidelity-setup.html'),
                controller: 'FidelitySetupCtrl'
            }
        }
    });

    $stateProvider.state('app.fidelity.setup.campaigns', {
        url: '/campaigns',
        template: require('./partial/fidelity-campaigns/fidelity-campaigns.html'),
        controller: 'FidelityCampaignsCtrl',
        resolve: {
            items: ["entityManager", function(entityManager) {
                return entityManager.items.fetchCollectionOffline().then(function(items) {
                    return _(items).filter('sku').sortBy('name').value();
                });
            }]
        }
    });

    $stateProvider.state('app.fidelity.setup.rules', {
        url: '/rules',
        template: require('./partial/fidelity-rules/fidelity-rules.html'),
        controller: 'FidelityRulesCtrl'
    });

    $stateProvider.state('app.fidelity.setup.prizes', {
        url: '/prizes',
        template: require('./partial/fidelity-prizes/fidelity-prizes.html'),
        controller: 'FidelityPrizesCtrl'
    });

    $urlRouterProvider.when("/fidelity","/fidelity/search");

    /* Add New States Above */
}]);