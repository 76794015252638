import { Injectable, Injector, inject } from '@angular/core';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { filter } from 'rxjs';
import { EntityManagerService, UserActiveSessionManagerService, StorageManagerService } from 'src/app/core';
import { groupBy, pickBy } from 'src/app/shared/utils';
import { ChainPromotions, Sales, SalesItemsPriceChanges } from 'tilby-models';
import { SaleUtilsService } from './sale-utils.service';

@Injectable({
    providedIn: 'root'
})
export class PromotionEngineService {
    private readonly entityManager = inject(EntityManagerService);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly injector = inject(Injector);

    private promotionsBySku: Record<string, ChainPromotions[]> = {};
    private itemPricesCache: Record<string, Record<string, number>> = {};

    private isInitialized = false;

    /**
     * Initializes the function by rebuilding the engine cache and subscribing to storage updates if has not been done yet.
     *
     * @return {Promise<void>} - A Promise that resolves when the initialization is complete.
     */
    public async init() {
        await this.rebuildEngineCache();

        //Rebuild engine cache on promotions and items updates (subscribe only once)
        if (!this.isInitialized) {
            StorageManagerService.storageUpdates$.pipe(
                filter(data => ['promotions', 'items'].includes(data.entityName))
            ).subscribe(() => {
                this.rebuildEngineCache();
            });

            this.isInitialized = true;
        }
    }

    /**
     * Rebuilds the engine cache by fetching all promotions for the current shop and initializing the promotions dictionary and item prices cache.
     * 
     * @private
     * @async
     * @return {Promise<void>} - Returns a Promise that resolves when the engine cache has been successfully rebuilt.
     */
    private async rebuildEngineCache() {
        const shopName = this.userActiveSession.getSession()!.shop.name;

        //Get all promotions for the current shop
        const promotions = await this.entityManager.promotions.fetchCollectionOffline({ active: true }).then(promotions => 
            promotions.filter(promotion => !promotion.shops?.length || promotion.shops.includes(shopName))
        );

        //Init promotions dictionary
        this.promotionsBySku = {};

        for (const promotion of promotions) {
            for (const item of (promotion.items || [])) {
                if (!Array.isArray(this.promotionsBySku[item.item_sku])) {
                    this.promotionsBySku[item.item_sku] = [];
                }

                this.promotionsBySku[item.item_sku].push(promotion);
            }
        }

        //Init item prices cache
        this.itemPricesCache = {};

        for (const promotion of promotions) {
            if (promotion.base_pricelist) {
                for (const item of (promotion.items || [])) {
                    this.itemPricesCache[item.item_sku] = {};
                }
            }
        }

        const items = await this.entityManager.items.fetchCollectionOffline({ sku_in: Object.keys(this.itemPricesCache) });

        for (const item of items) {
            if (item.sku && this.itemPricesCache[item.sku]) {
                Object.assign(this.itemPricesCache[item.sku], pickBy(item, (val, key) => key.startsWith('price')));
            }
        }
    }

    /**
     * Apply promotions to a sale after removing previously applied promotions.
     * Mutates the sale object in-place.
     * @param {Sales} sale - the sale object to apply promotions to
     */
    public applyPromotions(sale: Sales) {
        sale.sale_items = sale.sale_items || [];

        //STEP 1: Remove previously applied promotions
        for (const saleItem of sale.sale_items) {
            saleItem.price_changes = saleItem.price_changes?.filter((priceChange) => !priceChange.promotion_id) || [];
        }

        //Abort if this is a summary sale
        if (sale.is_summary) {
            return;
        }

        //Abort if there are no promotions
        if (!Object.keys(this.promotionsBySku).length) {
            return;
        }

        const now = new Date();
        const nowISOString = now.toISOString();
        const currentDate = this.tilbyDatePipe.transform(now, 'YYYY-MM-dd');
        const currentDayOfWeek = this.tilbyDatePipe.transform(now, 'EEEEEE', undefined, 'en-US');

        const fidelityRules = [
            'none',
            sale.sale_customer?.fidelity ? 'only_fidelity' : 'no_fidelity', //TODO: check fidelity prefix?
        ];

        const itemsBySku = groupBy(sale.sale_items
            .filter((saleItem) => saleItem.sku && !saleItem.sale_item_parent_uuid && !['gift', 'refund'].includes(saleItem.type))
            .sort((a, b) => b.quantity - a.quantity)
            , (saleItem) => saleItem.sku);

        const quantitiesBySku: Record<string, number> = {};
        const promotionCandidates: ChainPromotions[] = [];

        for (const sku in itemsBySku) {
            quantitiesBySku[sku] = itemsBySku[sku].reduce((sum, item) => sum + (item.quantity || 0), 0);

            promotionCandidates.push(...this.promotionsBySku[sku] || []);
        }

        //STEP 2: find eligible promotions
        const eligiblePromotions = promotionCandidates
            .filter((p, index, self) => self.indexOf(p) === index) //remove duplicates
            .sort((a, b) => a.name > b.name ? 1 : -1) //sort by name
            .filter((promotion) => {
                //Check weekdays_period
                if (promotion.weekdays_period?.length && !promotion.weekdays_period.includes(currentDayOfWeek)) {
                    return false;
                }

                //Check date range
                if (promotion.from_date && currentDate < promotion.from_date) {
                    return false;
                }

                if (promotion.to_date && currentDate > promotion.to_date) {
                    return false;
                }

                //Check time range
                const timeStart = TilbyDatePipe.date({ date: `${currentDate}T${promotion.start_time || '00:00:00'}` });
                let timeEnd = TilbyDatePipe.date({ date: `${currentDate}T${promotion.end_time || '00:00:00'}` });

                if (timeStart >= timeEnd) {
                    let nextDay = new Date(timeEnd);
                    nextDay.setDate(nextDay.getDate() + 1);
                    timeEnd = nextDay.toISOString();
                }

                if (nowISOString < timeStart || nowISOString > timeEnd) {
                    return false;
                }

                //Check allowed fidelity rules
                if (!fidelityRules.includes(promotion.fidelity_rule || '')) {
                    return false;
                }

                //Check channels
                if (promotion.channels?.length && !promotion.channels.includes(sale.channel!)) {
                    return false;
                }

                //Check customer type
                if (promotion.customer_type && sale.sale_customer?.custom_type !== promotion.customer_type) {
                    return false;
                }

                //Check order type
                if (promotion.order_types?.length && !promotion.order_types.includes(sale.order_type!)) {
                    return false;
                }

                //Check if the sale has the required items
                if (promotion.required_items?.length) {
                    if (promotion.require_all_items) {
                        if (promotion.required_items.some((item) => ((quantitiesBySku[item.item_sku] || 0) < (item.quantity || 0)))) {
                            return false;
                        }
                    } else {
                        if (promotion.required_items.every((item) => ((quantitiesBySku[item.item_sku] || 0) < (item.quantity || 0)))) {
                            return false;
                        }
                    }
                }

                return true;
            });

        //STEP 3: apply the eligible promotions
        if (!eligiblePromotions.length) {
            return;
        }

        //Inject sale utils service
        const saleUtils = this.injector.get(SaleUtilsService);

        for (const promotion of eligiblePromotions) {
            if (promotion.perc_discount != null) { //Perc discount
                for (const item of (promotion.items || [])) {
                    for (const saleItem of (itemsBySku[item.item_sku] || [])) {
                        saleItem.price_changes = [
                            ...(saleItem.price_changes || []),
                            saleUtils.getGenericPriceChange(saleItem, 'discount_perc', promotion.perc_discount, promotion.name, undefined, { promotion_id: promotion.id }) as SalesItemsPriceChanges
                        ];
                    }
                }
            } else { //Fixed discount
                const basePricelist = `price${promotion.base_pricelist}`;

                for(const item of (promotion.items || [])) {
                    const itemSku = item.item_sku;

                    //Check if the sale has the item of the promotion
                    if (!itemsBySku[itemSku]) {
                        continue;
                    }

                    //Check if we already applied a promotion for this item sku
                    if (promotion.exclude_other_promotions && itemsBySku[itemSku].some(itemRow => itemRow.price_changes?.some(priceChange => priceChange.promotion_id))) {
                        continue;
                    }

                    //Check if the sale has enough items for the promotion to apply
                    if(promotion.quantity_threshold && quantitiesBySku[itemSku] < promotion.quantity_threshold) {
                        continue;
                    }

                    const quantityThreshold = (promotion.quantity_threshold && promotion.quantity_threshold >= 1) ? promotion.quantity_threshold : 1;
                    const quantityMultiplier = promotion.multiples_only ? Math.floor(quantitiesBySku[itemSku] / quantityThreshold) : 1;

                    let quantityApply = promotion.multiples_only ? quantityMultiplier * quantityThreshold : quantitiesBySku[itemSku];

                    if (!promotion.apply_below_threshold) {
                        quantityApply -= ((quantityThreshold * quantityMultiplier) - quantityMultiplier);
                    }

                    const itemsByPricelist = groupBy(itemsBySku[itemSku], (item) =>  item.price === this.itemPricesCache[itemSku][basePricelist] ? 'same' : 'other');
                    const discountAmount = this.itemPricesCache[itemSku][basePricelist] - (item.discounted_price || 0);

                    for(const saleItem of (itemsByPricelist.same || [])) {
                        const discountMultiplier = quantityApply > saleItem.quantity ? saleItem.quantity : quantityApply;

                        saleItem.price_changes = [
                            ...(saleItem.price_changes || []),
                            saleUtils.getGenericPriceChange(saleItem, 'discount_fix', discountAmount * discountMultiplier, promotion.name, undefined, { promotion_id: promotion.id }) as SalesItemsPriceChanges
                        ];

                        quantityApply -= discountMultiplier;

                        if (quantityApply <= 0) {
                            break;
                        }
                    }

                    if (promotion.force_pricelist_apply) {
                        const priceToApply = this.itemPricesCache[itemSku][basePricelist];

                        for (const saleItem of (itemsByPricelist.other || [])) {
                            const discountMultiplier = quantityApply > saleItem.quantity ? saleItem.quantity : quantityApply;

                            saleItem.price = priceToApply;
                            saleItem.price_changes = [
                                ...(saleItem.price_changes || []),
                                saleUtils.getGenericPriceChange(saleItem, 'discount_fix', discountAmount * discountMultiplier, promotion.name, undefined, { promotion_id: promotion.id }) as SalesItemsPriceChanges
                            ];

                            quantityApply -= discountMultiplier;

                            if (quantityApply <= 0) {
                                break;
                            }
                        }
                    }

                    if (promotion.exclude_other_promotions) {
                        delete itemsBySku[itemSku];
                    }
                }
            }
        }
    }
}