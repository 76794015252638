<div class="tw-min-h-[160px]">
    <div class="tw-flex tw-justify-between">
        <div class="tw-flex">
            <span class="tw-mt-[22px] tw-ml-[30px]" (click)="cancel()"><mat-icon>close</mat-icon></span>
            <h2 mat-dialog-title class="tw-mt-0">{{'DIALOG.CHECK_STOCK.TITLE'|translate}}</h2>
        </div>
        <button class="tw-mt-5 tw-mr-6" mat-button color="primary" (click)="search()" [disabled]="isButtonDisabled">{{'DIALOG.CHECK_STOCK.ACTION.SEARCH'|translate}}</button>
    </div>
    <div class="tw-flex">
        <div mat-dialog-content class="mat-typography tw-pt-3">
            <tilby-magic-form [form]="data.form"></tilby-magic-form>
        </div>
        <app-barcode-scanner id="barcode-scanner"
            (onBarcodeScannedChange)="barcodeScanned($event)"
        ></app-barcode-scanner>
    </div>
    <div *ngIf="searchInProgress" class="tw-flex tw-justify-center tw-items-center tw-mb-5">
        <mat-spinner color="primary tw-h-[60px]" ></mat-spinner>
    </div>
    <div [hidden]="!hasRunSearch || !stockDictionaryFilterResult.length" *ngIf="!searchInProgress">
        <cdk-virtual-scroll-viewport itemSize="48" class="tw-min-h-[200px] tw-max-h-[350px] tw-w-[calc(100%-41px)] tw-ml-4 tw-mb-4 tw-overflow-auto">
            <table mat-table [dataSource]="dataSource" class="example-table" [multiTemplateDataRows]="true">

                <ng-container matColumnDef="shop_id">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.SHOP_ID</th>
                    <td mat-cell *matCellDef="let row" id="cell_shop_id">{{row.shop_id ? row.shop_id : '-'}}</td>
                  </ng-container>

                <ng-container matColumnDef="shop_name">
                  <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.SHOP_NAME</th>
                  <td mat-cell *matCellDef="let row" id="cell_shop_name">{{row.shop_name ? row.shop_name : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.CODE</th>
                  <td mat-cell *matCellDef="let row" id="cell_code">{{row.code ? row.code : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.SKU</th>
                    <td mat-cell *matCellDef="let row" id="cell_shop_id">{{row.sku ? row.sku : '-'}}</td>
                  </ng-container>

                <ng-container matColumnDef="barcode">
                  <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.BARCODE</th>
                  <td mat-cell *matCellDef="let row" class="tw-text-blue-600" id="cell_barcode">{{row.barcode ? row.barcode : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.NAME</th>
                    <td mat-cell *matCellDef="let row" id="cell_name">{{row.name ? row.name : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="combination_code">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.COMBINATION_CODE</th>
                    <td mat-cell *matCellDef="let row" id="cell_combination_code">{{row.combination_code ? row.combination_code : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="variation_1">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.VARIATION_1</th>
                    <td mat-cell *matCellDef="let row" id="cell_variation_1">{{row.variation_1 ? row.variation_1 : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="variation_2">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.VARIATION_2</th>
                    <td mat-cell *matCellDef="let row" id="cell_variation_2">{{row.variation_2 ? row.variation_2 : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="variation_3">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.VARIATION_3</th>
                    <td mat-cell *matCellDef="let row" id="cell_variation_3">{{row.variation_3 ? row.variation_3 : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="stock_location">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.STOCK_LOCATION</th>
                    <td mat-cell *matCellDef="let row" id="cell_stock_location">{{row.stock_location ? row.stock_location : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.QTA</th>
                    <td mat-cell *matCellDef="let row" id="cell_quantity">{{row.quantity ? row.quantity : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="details">
                  <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.DETAILS</th>
                  <td mat-cell *matCellDef="let row" id="cell_details">{{getDetails(row)}}</td>
                </ng-container>

                <ng-container matColumnDef="stock_quantity">
                  <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400" translate>CASHREGISTER.CHECK_STOCK.QTA</th>
                  <td mat-cell *matCellDef="let row" class="tw-text-blue-600" id="cell_stock_quantity">{{getQuantity(row)}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr [style.height.px]="placeholderHeight" mat-row *matRowDef="let row; let index = index; columns: []; when: placeholderWhen"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
          </cdk-virtual-scroll-viewport>

          <!-- Never viewport -->

          <!-- <div class="example-table-container">
            <table mat-table [dataSource]="dataSource" class="example-table">
              <ng-container matColumnDef="shop_name">
                <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400 tw-text-sm">Nome store</th>
                <td mat-cell *matCellDef="let row">{{row.shop_name}}</td>
              </ng-container>

              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400 tw-text-sm">Codice</th>
                <td mat-cell *matCellDef="let row">{{row.code}}</td>
              </ng-container>

              <ng-container matColumnDef="barcode">
                <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400 tw-text-sm">Barcode</th>
                <td mat-cell *matCellDef="let row" class="tw-text-blue-600">{{row.barcode ? row.barcode : '-'}}</td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400 tw-text-sm">Name</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
              </ng-container>

              <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400 tw-text-sm">Details</th>
                <td mat-cell *matCellDef="let row">{{row.details}}</td>
              </ng-container>

              <ng-container matColumnDef="stock_quantity">
                <th mat-header-cell *matHeaderCellDef class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400 tw-text-sm">Q</th>
                <td mat-cell *matCellDef="let row" class="tw-text-blue-600">{{row.stock_quantity ? row.stock_quantity : '0'}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div> -->

    </div>
    <div class="tw-ml-7 tw-mb-4" *ngIf="!stockDictionaryFilterResult.length && queryValue && !searchInProgress && !hasSearchError">
        <div translate>CASHREGISTER.CHECK_STOCK.NOT_ALL_RESULT</div>
    </div>
    <div class="tw-ml-7 tw-mb-4" *ngIf="!stockDictionaryFilterResult.length && queryValue && !searchInProgress && hasSearchError">
        <div translate>CASHREGISTER.CHECK_STOCK.ERROR_LABEL</div>
        <div class="tw-text-sm">{{searchError}}</div>
    </div>
</div>
