import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('dashboard').controller('DashboardCtrl', dashboardCtrl);

dashboardCtrl.$inject = ["$scope", "$http", "$state", "$translate", "restManager", "deliveryChannels", "waitDialog", "checkManager", "dashboardDataManager", "fiscalPrinterDashboardDialog", "user", "util", "promptDialog"];

function dashboardCtrl($scope, $http, $state, $translate, restManager, deliveryChannels, waitDialog, checkManager, dashboardDataManager, fiscalPrinterDashboardDialog, user, util, promptDialog) {
    let deliveryChannelsById = _.keyBy(deliveryChannels, 'id');
    _.set(deliveryChannelsById, ['null'], {id: null, name: $translate.instant('DASHBOARD.CASHREGISTER') });

    const getChannelName = (channelId) => _.get(deliveryChannelsById, [channelId, 'name'], channelId);

    Object.assign($scope, {
        canOpenStocks: checkManager.isModuleEnabled('stock'),
        country: checkManager.getShopCountry(),
        data: {
            date: new Date(),
            today: {},
            printers: {},
            stocks: {
                alert: 0,
                unavailable: 0,
                available:  0
            },
            e_invoices: {
                error: 0,
                pending: 0
            },
            closings: {
                last_closing: null
            },
            news: [],
            selectedNews: {
                title: null,
                content: null,
                url: null,
                icon: null
            },
            missing_sends: 0
        },
        loadCompleted: false,
        userFirstName: user.first_name || "",
        thumbnail: user.thumbnail || null,
        topbar_context: {}
    });

    $scope.selectPreviousNews = () => {
        if($scope.data.selectedNewsIndex > 0) {
            $scope.data.selectedNews = $scope.data.news[--$scope.data.selectedNewsIndex];
        }
    };

    $scope.selectNextNews = () => {
        if($scope.data.selectedNewsIndex + 1 < $scope.data.news.length) {
            $scope.data.selectedNews = $scope.data.news[++$scope.data.selectedNewsIndex];
        }
    };

    $scope.openDetails = async () => {
        if($scope.topbar_context.reloading) {
            await waitDialog.show({ message: $translate.instant("DASHBOARD.WAIT_UPDATING_DATA"), cancelAction: { label: $translate.instant("DASHBOARD.WAIT_UPDATING_DATA_CANCEL") }, promise: $scope.topbar_context.reloading });
        }

        fiscalPrinterDashboardDialog.show($scope.data, $scope.data.printers, $scope.data.alignedPrinters);
    };

    $scope.openStocks = () => $state.go('app.stock.management');

    $scope.openCashMovements = () => $state.go('app.history.cash-movements');

    $scope.openUrl = (url) => {
        util.openExternalLink(`${url}?utm_source=scloby-app&utm_medium=dashboard&utm_campaign=dashboard_button`);
    };

    const checkEInvoices = async () => {
        let result = await restManager.getOne('sales/e_invoices', 'sync_status');

        _.keys($scope.data.e_invoices).forEach((value) => {
            $scope.data.e_invoices[value] = _.get(result, ['status', value], 0);
        });
    };

    checkEInvoices();

    const downloadNews = async() => {
        let result = await $http.get('https://internalcrm.scloby.com/news/');
        let countryData = result.data[$scope.country] || result.data['DEFAULT'];

        if(typeof countryData !== "object") {
            countryData = { content: null, title: null, url: null };
        }

        if(!Array.isArray(countryData)) {
            countryData = [countryData];
        }

        //SVG icon -> icon font conversion
        const iconConvertRegex = /.*\/ic_(.*)_24px\.svg/;

        for(let news of countryData) {
            if(typeof news.icon === "string") {
                const iconConvertResult = news.icon.match(iconConvertRegex);
    
                if(iconConvertResult) {
                    news.icon = iconConvertResult[1];
                }
            }
        }

        Object.assign($scope.data, {
            news: countryData,
            selectedNews: _.head(countryData),
            selectedNewsIndex: 0
        });
    };

    downloadNews();

    const alignDataPrinters = (inputData) => {
        let depsValues = {};
        let paymentsValues = {};
        let refundsValues = {};
        let voidsValues = {};
        let claimedPaymentsValues = {};
        let unclaimedPaymentsValues = {};
        let ticketsPaymentsValues = {};
        let fiscalReceiptsValues = {};
        let invoicesValues = {};
        let invoicesRCValues = {};
        let invoicesNRCValues = {};
        let ticketsCashMovementsValues = {};
        let channelValues = {};
        let alignedPrinters = {};

        _.forEach(inputData, (singlePrinter) => {
            _.forEach(singlePrinter.departments, (dep) => {
                _.set(depsValues, [dep.name], true);
            });

            singlePrinter.payments = _.map(singlePrinter.payments, (payment) => {
                if(!_.isNil(payment.ticket_name)){
                    payment.name = payment.ticket_name;
                }
                return payment;
            });

            _.forEach(singlePrinter.payments, (payment) => {
                if(_.isNil(payment.ticket_name)) {
                    _.set(paymentsValues, [payment.name], true);
                    _.set(payment.unclaimed ? unclaimedPaymentsValues : claimedPaymentsValues, [payment.name], true);
                } else {
                    _.set(ticketsPaymentsValues, [payment.name], true);
                }
            });

            _.forIn(singlePrinter.channels, (channel, channelName) => {
                _.set(channelValues, channelName, true);
            });

            if(_.isArray(_.get(singlePrinter, ['fiscal_receipts', 'vats']))) {
                _.forEach(singlePrinter.fiscal_receipts.vats, (receipt) => {
                    _.set(fiscalReceiptsValues, [receipt.name], true);
                });
            }

            if(_.isArray(_.get(singlePrinter, ['invoices', 'vats']))) {
                _.forEach(singlePrinter.invoices.vats, (invoice) => {
                    _.set(invoicesValues, [invoice.name], true);
                });
            }

            if(_.isArray(_.get(singlePrinter, ['summary_rc_invoices', 'vats']))) {
                _.forEach(singlePrinter.summary_rc_invoices.vats, (invoice) => {
                    _.set(invoicesRCValues, [invoice.name], true);
                });
            }

            if(_.isArray(_.get(singlePrinter, ['summary_nrc_invoices', 'vats']))) {
                _.forEach(singlePrinter.summary_nrc_invoices.vats, (invoice) => {
                    _.set(invoicesNRCValues, [invoice.name], true);
                });
            }

            if(_.isArray(_.get(singlePrinter, ['cash_movements', 'tickets']))) {
                _.forEach(singlePrinter.cash_movements.tickets, (ticket) => {
                    _.set(ticketsCashMovementsValues, [ticket.name], true);
                });
            }

            _.set(alignedPrinters, [singlePrinter.name, 'refunds'], singlePrinter.refunds);
            _.set(alignedPrinters, [singlePrinter.name, 'voids'], singlePrinter.voids);
            _.set(alignedPrinters, [singlePrinter.name, 'deps'], singlePrinter.departments);
            _.set(alignedPrinters, [singlePrinter.name, 'payments'], singlePrinter.payments);
            _.set(alignedPrinters, [singlePrinter.name, 'fiscal_receipts'], singlePrinter.fiscal_receipts);
            _.set(alignedPrinters, [singlePrinter.name, 'invoices'], singlePrinter.invoices);
            _.set(alignedPrinters, [singlePrinter.name, 'summary_rc_invoices'], singlePrinter.summary_rc_invoices);
            _.set(alignedPrinters, [singlePrinter.name, 'summary_nrc_invoices'], singlePrinter.summary_nrc_invoices);
            _.set(alignedPrinters, [singlePrinter.name, 'cash_movements'], singlePrinter.cash_movements);
            _.set(alignedPrinters, [singlePrinter.name, 'channels'], singlePrinter.channels);
        });

        depsValues = _.keys(depsValues);
        paymentsValues = _.keys(paymentsValues);
        claimedPaymentsValues = _.keys(claimedPaymentsValues);
        unclaimedPaymentsValues = _.keys(unclaimedPaymentsValues);
        ticketsPaymentsValues = _.keys(ticketsPaymentsValues);
        fiscalReceiptsValues = _.keys(fiscalReceiptsValues);
        invoicesValues = _.keys(invoicesValues);
        invoicesRCValues = _.keys(invoicesRCValues);
        invoicesNRCValues = _.keys(invoicesNRCValues);
        ticketsCashMovementsValues = _.keys(ticketsCashMovementsValues);
        channelValues = _(channelValues).keys().map(getChannelName).value();

        let refunds = {};
        let voids = {};
        let departments = {};
        let payments = {};
        let fiscal_receipts = {};
        let invoices = {};
        let summary_rc_invoices = {};
        let summary_nrc_invoices = {};
        let cash_movements = {};
        let channels = {};

        _.forEach(alignedPrinters, (singlePrinter, index) => {
            refunds[index] = {
                amount: _.get(singlePrinter, ['refunds', 'amount'], 0),
                count: _.get(singlePrinter, ['refunds', 'count'], 0)
            };

            voids[index] = {
                amount: _.get(singlePrinter, ['voids', 'amount'], 0),
                count: _.get(singlePrinter, ['voids', 'count'], 0)
            };

            departments[index] = {};
            payments[index] = {};
            fiscal_receipts[index] = {};
            invoices[index] = {};
            summary_rc_invoices[index] = {};
            summary_nrc_invoices[index] = {};
            cash_movements[index] = {};
            channels[index] = {};

            _.forEach(depsValues, (key) => {
                let found = _.find(singlePrinter.deps, { name: key });

                _.set(departments, [index, _.toString(key)], _.isUndefined(found) ? 0 : found.amount);
            });

            _.forEach(paymentsValues, (key) => {
                let found = _.find(singlePrinter.payments, { name: key });

                _.set(payments, [index, _.toString(key)], _.isUndefined(found) ? 0 : found.amount);
            });

            _.forEach(ticketsPaymentsValues, (key) => {
                let found = _.find(singlePrinter.payments, {name: key});

                _.set(payments, [index, 'tickets', _.toString(key)], _.isUndefined(found) ? 0 : found.amount);
            });

            _.forEach(fiscalReceiptsValues, (key) => {
                let found;

                if(!_.isUndefined(_.get(singlePrinter, ['fiscal_receipts', 'vats'], undefined))) {
                    found = _.find(singlePrinter.fiscal_receipts.vats, { name: _.toInteger(key) });
                }

                _.set(fiscal_receipts, [index, key], _.isUndefined(found) ? { amount: 0, net_amount:0, vat_amount:0 } : { amount: found.amount, net_amount: found.net_amount, vat_amount: found.vat_amount });
            });

            let channelsById = {};

            _.forIn(singlePrinter.channels, (channel, channelId) => {
                channelsById[getChannelName(channelId)] = channel;
            });

            _.forEach(channelValues, (val) => {
                let targetChannel = channelsById[val] || val;

                _.set(channels, [index, val], targetChannel ? {amount: targetChannel.amount, count: targetChannel.count} : {amount: 0, count: 0});
            });

            _.forEach(invoicesValues, (key) => {
                let found;

                if(!_.isUndefined(_.get(singlePrinter, ['invoices', 'vats'], undefined))) {
                    found = _.find(singlePrinter.invoices.vats, { name: _.toInteger(key) });
                }

                _.set(invoices, [index, key], _.isUndefined(found) ? { amount: 0, net_amount:0, vat_amount:0 } : { amount: found.amount, net_amount: found.net_amount, vat_amount: found.vat_amount });
            });

            _.forEach(invoicesRCValues, (key) => {
                let found;

                if(!_.isUndefined(_.get(singlePrinter, ['summary_rc_invoices', 'vats'], undefined))) {
                    found = _.find(singlePrinter.summary_rc_invoices.vats, { name: _.toInteger(key) });
                }

                _.set(summary_rc_invoices, [index, key], _.isUndefined(found) ? { amount: 0, net_amount:0, vat_amount:0 } : { amount: found.amount, net_amount: found.net_amount, vat_amount: found.vat_amount });
            });

            _.forEach(invoicesNRCValues, (key) => {
                let found;

                if(!_.isUndefined(_.get(singlePrinter, ['summary_nrc_invoices', 'vats'], undefined))) {
                    found = _.find(singlePrinter.summary_nrc_invoices.vats, { name: _.toInteger(key) });
                }

                _.set(summary_nrc_invoices, [index, key], _.isUndefined(found) ? { amount: 0, net_amount:0, vat_amount:0 } : { amount: found.amount, net_amount: found.net_amount, vat_amount: found.vat_amount });
            });

            if(!_.isUndefined(_.get(singlePrinter, ['cash_movements', 'sales'], undefined))) {
                _.set(cash_movements, [index, 'sales'], singlePrinter.cash_movements.sales);
            } else {
                _.set(cash_movements, [index, 'sales'], 0);
            }

            _.forEach(ticketsCashMovementsValues, (key) => {
                let found;

                _.set(cash_movements, [index, 'tickets', _.toString(key)], _.isUndefined(found) ? {count:0, amount:0} : {count: found.count, amount: found.amount});

                found = undefined;
                if(!_.isUndefined(_.get(singlePrinter, ['cash_movements', 'tickets'], undefined))) {
                    found = _.find(singlePrinter.cash_movements.tickets, { name: key });
                }

                _.set(cash_movements, [index, 'tickets', _.toString(key)], _.isUndefined(found) ? {count:0, amount:0} : {count: found.count, amount: found.amount});
            });

        });

        return {
            refunds: {
                printers: refunds
            },
            voids: {
                printers: voids
            },
            departments: {
                allNames: depsValues,
                printers: departments
            },
            payments: {
                allNames: paymentsValues,
                claimedNames: claimedPaymentsValues,
                unclaimedNames: unclaimedPaymentsValues,
                ticketsNames: ticketsPaymentsValues,
                printers: payments
            },
            fiscal_receipts: {
                allNames: fiscalReceiptsValues,
                printers: fiscal_receipts
            },
            invoices: {
                allNames: invoicesValues,
                printers: invoices
            },
            summary_rc_invoices: {
                allNames: invoicesRCValues,
                printers: summary_rc_invoices
            },
            summary_nrc_invoices: {
                allNames: invoicesNRCValues,
                printers: summary_nrc_invoices
            },
            cash_movements: {
                allNames: ticketsCashMovementsValues,
                printers: cash_movements
            },
            channels: {
                allNames: channelValues,
                printers: channels
            }
        };
    };

    $scope.refreshView = (dashboardData) => {
        let current_cash_movements = dashboardData.today_dashboard.cash_movements || { amount: 0 };
        let current_cash_in_register = current_cash_movements.total.income.amount + current_cash_movements.total.outcome.amount;

        let code = _.toInteger(_.get(dashboardData.today_weather, 'status_code'));
        let weather_icon;

        switch(code){
            case 1:
            case 2:
                weather_icon = 'weather-clear';
                break;
            case 3:
            case 4:
                weather_icon = 'weather-few-clouds';
                break;
            case 8:
                weather_icon = 'weather-clouds';
                break;
            case 5:
            case 6:
            case 7:
                weather_icon = 'weather-drizzle-day';
                break;
            case 9:
                weather_icon = 'weather-rain-day';
                break;
            case 10:
                weather_icon = 'weather-showers-day';
                break;
            case 11:
                weather_icon = 'weather-snow';
                break;
            case 12:
                weather_icon = 'weather-snow-rain';
                break;
            case 13:
                weather_icon = 'weather-storm';
                break;
            case 14:
            case 15:
                weather_icon = 'weather-mist';
                break;
            case 16:
                weather_icon = 'weather-storm-day';
                break;
            case 17:
                weather_icon = 'weather-hail';
                break;
            case 18:
                weather_icon = 'weather-snow-scattered-day';
                break;
            default:
                weather_icon = 'weather-none-available';
                break;
        }

        weather_icon = weather_icon + '.svg';

        let paymentsPrepaid = [];
        let payments = [];

        _.forEach(dashboardData.today_payments.payments, (payment) => {
            if(payment.name === 'OTHER_CHANGES') {
                payment.name = $translate.instant('DASHBOARD.OTHER_CHANGES');
            }

            if(payment.type_id === 16) {
                paymentsPrepaid.push(payment);
            } else {
                payments.push(payment);
            }
        });

        if(paymentsPrepaid.length > 0){
            payments.push({
                type_id: 16,
                name: $translate.instant('DASHBOARD.PREPAID_CARDS'),
                amount: _.sumBy(paymentsPrepaid, 'amount')
            });
        }

        $scope.data.today = {
            total_amount: util.round(dashboardData.today_dashboard.items_sold.closed_sales.amount - dashboardData.today_dashboard.refunds.closed_sales.amount),
            total_sales: dashboardData.today_dashboard.closed_sales.total.count,
            payments: payments,
            cash_movements: current_cash_movements,
            current_cash: current_cash_in_register,
            invoices: dashboardData.today_dashboard.invoices,
            fiscal_receipts: dashboardData.today_dashboard.fiscal_receipts,
            summary_invoices: dashboardData.today_dashboard.summary_invoices,
            weather_icon: weather_icon,
            weather: dashboardData.today_weather,
            waste: dashboardData.today_dashboard.waste,
            deleted_orders: dashboardData.today_dashboard.deleted_orders
        };

        $scope.data.today.credit_notes = {
            count: _.get(dashboardData.today_dashboard.not_rt_credit_notes, 'count', 0),
            amount: _.get(dashboardData.today_dashboard.not_rt_credit_notes, 'amount', 0)
        };

        const goal = parseFloat(checkManager.getPreference('daily_goal')) || 1;

        $scope.data.progress = {
            current: $scope.data.today.total_amount,
            target: goal,
            percentage: _.min([100 * ($scope.data.today.total_amount || 0) / goal], 100),
            remainder: _.max([util.round($scope.data.today.total_amount - goal), 0]) || null
        };

        let lastClosing = _.reduce(dashboardData.printers_status, (prev, value) => {
            let date = new Date(value.lastUpdate);
            
            if(_.isNil(prev) || prev > date) {
                return date;
            }

            return prev;
        }, null);

        $scope.data.last_closing = (lastClosing === null) ? null : moment(lastClosing).format('DD/MM/YYYY HH:mm');

        $scope.data.printers = dashboardDataManager.getMergedResources(dashboardData.today_printers);

        $scope.data.alignedPrinters = alignDataPrinters($scope.data.printers);

        _.reduce(dashboardData.printers_status, (prev, value) => (prev + (_.toInteger(value.rtOldFileToSend) || 0)), 0);

        $scope.data.stocks = {
            alert: _.filter(dashboardData.stocks, { available: 'alert' }).length || 0,
            unavailable: _.filter(dashboardData.stocks, { available: 'unavailable' }).length || 0,
            available: _.filter(dashboardData.stocks, { available: 'available' }).length || 0
        };

        $scope.loadCompleted = true;
    };

    $scope.reloadData = async () => {
        if($scope.topbar_context.reloading) {
            return;
        }

        $scope.topbar_context.reloading = new Promise(async (resolve, reject) => {
            try {
                let dashboardData = await dashboardDataManager.fetchData();
                $scope.refreshView(dashboardData);
            } catch(err) {}

            $scope.topbar_context.reloading = false;
            resolve();
        });
    };

    $scope.settings = async () => {
        let result = await promptDialog.show({
            title: $translate.instant('DASHBOARD.DIALOG_GOAL.TITLE'),
            label: $translate.instant('DASHBOARD.DIALOG_GOAL.LABEL'),
            type: 'number',
            min: 0,
            defaultValue: _.toNumber(checkManager.getPreference('daily_goal')) || 0
        });

        if (_.isNumber(result)) {
            checkManager.setShopPreference('daily_goal', result);
            $scope.data.progress.target = result;
        }
    };

    const onInit = () => {
        let dataCache = dashboardDataManager.getDataCache();
    
        if(!_.isEmpty(dataCache)) {
            $scope.refreshView(dataCache);
        }
    
        $scope.reloadData();
    };

    onInit();

    $scope.topbar_context.reload = () => $scope.reloadData();
    $scope.topbar_context.settings = $scope.settings;
}
