import {AfterViewInit, ChangeDetectorRef, Component, Injector, OnInit, ViewChild, inject, effect} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, tap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {$state, RootScope} from 'app/ajs-upgraded-providers';
import {SkeletonLoaderService} from 'src/app/core/services/skeleton-loader.service';
import {mobileCheck} from 'src/utilities';
import {
    CentralToolbarComponent,
    ConfigurationManagerService,
    ToolbarEventsService,
    RightSidenavService,
    RightSidenavServiceComponent,
    CoreStateService,
    ScreenOrientationService
} from 'src/app/core/services';
import {DualService} from 'src/app/dual.service';
import {ToolbarEventsContextService} from "src/app/core/services/toolbar-events/toolbar-events-context.service";
import { ComponentPortal } from '@angular/cdk/portal';
import {SidenavMenuComponent} from "./sidenav-menu";
import { DevLogger } from 'src/app/shared/dev-logger';
import { SearchBarComponent } from '../generalized-toolbar';
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

const SIDENAV_TRANSITION_TIME = 250;
//TOOLBAR
const MODULES_WITH_SEARCHBAR = ['customers','suppliers','history','cashregister', 'file_importer.showcase'];
const MODULES_WITH_CONTEXT_TOOLBAR = ['customers.details','suppliers.details','customers.new','suppliers.new', 'file_importer_add', 'file_importer_schedule'];
const MODULES_WITH_CONTEXT_TOOLBAR_DESKTOP_AND_MODILE_LANDSCAPE_ONLY = ['cashregister.content.payments'];
const MODULES_WITHOUT_TOOLBAR_BUTTONS = ['alvolo']
//
const CONTEXT_TABS_ROUTES = ['customers.details'];

@Component({
    selector: 'app-new-material-sidenav',
    templateUrl: './new-material-sidenav.component.html',
    styleUrls: ['./new-material-sidenav.component.scss'],
    providers: [OnDestroyService]
})
export class NewMaterialSidenavComponent implements OnInit, AfterViewInit {
    public injector= inject(Injector);
    private breakpointObserver= inject(BreakpointObserver);
    private cd= inject(ChangeDetectorRef);
    private onDestroyService= inject(OnDestroyService);
    public rightSidenavService= inject(RightSidenavService<RightSidenavServiceComponent>);
    public toolbarEventsService= inject(ToolbarEventsService);
    public toolbarEventsContextService= inject(ToolbarEventsContextService);
    public skeletonLoaderService= inject(SkeletonLoaderService);
    private $rootScope= inject(RootScope);
    public configurationManager = inject(ConfigurationManagerService);
    private state= inject($state);
    private dualService= inject(DualService);
    protected lockUIActions = CoreStateService.lockUIActions;

    //componentOutlet
    centralToolbarComponent$:Observable<ComponentPortal<CentralToolbarComponent>|null>= this.toolbarEventsService.centralToolbarComponent$.pipe(map(component=>component?new ComponentPortal<any>(component):null));
    //
    SIDENAV = {
        OPEN: true,
        CLOSE: false
    };
    @ViewChild('sidenavMenu') sidenavMenu?: MatSidenav;
    @ViewChild('sidenavFixed') sidenavFixed?: MatSidenav;
    @ViewChild('sidenavDetail') sidenavDetail?: MatSidenav;
    @ViewChild('appSidenavMenu') appSidenavMenu?: SidenavMenuComponent;

    currentRoute = '';
    isContextTabs = false;
    isRouteChanged$ = this.dualService.nextRoute$
        .pipe(
            tap(()=>this.rightSidenavService.isOpen = false),
            this.onDestroyService.takeUntilDestroy
        ).subscribe();
    currentModule?: string;
    oldTheme?: string;
    currentTheme?: string;
    isSidenavOpen = false;
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
                                          .pipe(
                                              map(result => result.matches),
                                              shareReplay()
                                          );
    isMobile: boolean = mobileCheck();

    constructor(
          ) {
        subscribeInComponent(
            this.dualService.currentRoute$,
            (currentRoute)=>{
                const {label} = this.toolbarEventsContextService;
                this.currentRoute=(<any>currentRoute).name||currentRoute;
                this.log('CURRENT_ROUTE',currentRoute);
                this.isContextTabs=CONTEXT_TABS_ROUTES.some(route=>(this.currentRoute).includes(route));
                this.setToolbars(this.currentRoute);
            }
        );
        //@ts-ignore
        effect(() => this.showContextToolbar(this.currentRoute.name||this.currentRoute)); // TRIGGERED BY DEVICE_ORIENTATION_CHANGE
    }

    //Component Init
    ngOnInit() {
    }

    ngAfterViewInit() {
        if (!this.isMobile) {
            this.sidenavFixed?.open();
            this.cd.detectChanges();
        }
    }

    ngOnDestroy(){
    }

    private log(...args:any[]){
        DevLogger.log('NEW_MATERIAL_SIDENAV',args);
    }

    private setToolbars(currentRouteName:string){
        // TOOLBAR
        this.setSearchBarOnToolbar(currentRouteName);
        this.showToolbarButtons(currentRouteName);
        // CONTEXT-TOOLBAR
        this.showContextToolbar(currentRouteName);
        this.resetContextToolbar();
    }

    //START TOOLBAR METHODS

    private setSearchBarOnToolbar(currentRouteName:string){
        if(MODULES_WITH_SEARCHBAR.some(route=>currentRouteName.includes(route))){
            this.toolbarEventsService.centralToolbarComponent$.next(SearchBarComponent);
        }
        else this.toolbarEventsService.centralToolbarComponent$.next(undefined);
    }
    private showToolbarButtons(currentRouteName:string){
        if(MODULES_WITHOUT_TOOLBAR_BUTTONS.some(route=>currentRouteName.includes(route))){
            this.toolbarEventsService.showButtonsBar$.next(false);
        }
        else this.toolbarEventsService.showButtonsBar$.next(true);
    }

    private showContextToolbar(currentRouteName:string){
        const isMobilePotrait = this.injector.get(ScreenOrientationService).getOrientation().includes('portrait');
        const modulesWithContextToolbar=[...MODULES_WITH_CONTEXT_TOOLBAR,...(this.isMobile && isMobilePotrait?[]:MODULES_WITH_CONTEXT_TOOLBAR_DESKTOP_AND_MODILE_LANDSCAPE_ONLY)];
        if(modulesWithContextToolbar.some(route=>currentRouteName.includes(route))){
            this.toolbarEventsContextService.showToolbarContext$.next(true);
        }
        else this.toolbarEventsContextService.showToolbarContext$.next(false);
    }

    private resetContextToolbar(){
        this.toolbarEventsContextService.label = '';
        this.toolbarEventsContextService.buttons$.next({panelButtons: [], barButtons: []});
    }
    //END TOOLBAR METHODS

    closeMenuSidenav() {
        this.toggle(false);
    }

    toggle(status?: boolean) {
        this.isSidenavOpen = status ?? !this.isSidenavOpen;
        this.cd.detectChanges();

        //Toggle the sidenav
        this.sidenavMenu?.toggle(this.isSidenavOpen);

        //Reopen the fixed sidenav if the menu is being closed
        if(!this.isSidenavOpen) {
            this.sidenavFixed?.open();
        }
    }

    toggleSubMenuSettings(){
        this.toggle();
        this.appSidenavMenu?.navList?.invokeToggleSubmenuSettings();
    }

    toggleAutomaticPrintSettings(){
        this.toggle();
        this.appSidenavMenu?.navList?.invokeToggleSubmenuAutomaticPrinter();
    }

    goTo(destination: string) {
        this.closeMenuSidenav();

        setTimeout(()=>{
            if (this.currentModule !== 'app.' + destination) {
                this.$rootScope.showAppLoader();
                // setTimeout(() => this.router.navigate([destination]));
                setTimeout(() => this.state.go('app.' + destination));
            }
        },SIDENAV_TRANSITION_TIME);
    }
}
