import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    Input,
    Renderer2,
    ViewChild
} from '@angular/core';
import {mobileCheck} from "src/utilities";
import {ToolbarEventsService} from "src/app/core/services/toolbar-events/toolbar-events.service";
import {
    debounceTime,
    distinctUntilChanged,
    fromEvent,
    skip,
    Subject,
    takeUntil
} from "rxjs";
import { EnvironmentInfoService } from 'src/app/core';
import { autobind } from 'src/app/models/decorators.model';
import {
    BarcodeManagerService
} from 'src/app/core';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    host:{class:'tw-w-1/2 sm:tw-flex-[0.5_1_0%]'}
})
export class SearchBarComponent implements AfterViewInit {
    public readonly toolbarEventsService= inject(ToolbarEventsService);
    private readonly renderer= inject(Renderer2);
    private readonly environmentInfoService= inject(EnvironmentInfoService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);

    private readonly onDestroy$ = new Subject<void>();
    icon = 'tune';
    iconFilled = '';
    isMobile = mobileCheck();
    openSearch = false;
    @Input() idSelector: string = 'searchBarcodeInput';
    searchBarcodeInput: string =  "";
    @ViewChild('quickFilter') quickFilter!:ElementRef<HTMLInputElement>;

    ngAfterViewInit(): void {
        // @ts-ignore
        fromEvent<InputEvent>(this.quickFilter.nativeElement,'input').pipe(distinctUntilChanged(),debounceTime(300)).subscribe(({target})=> this.inputChangedHandler(target?.value));
        this.toolbarEventsService.searchBarIconFilled$.pipe(skip(1),takeUntil(this.onDestroy$)).subscribe((icon)=> this.icon=icon);
        this.toolbarEventsService.searchBarIcon$.pipe(takeUntil(this.onDestroy$),debounceTime(50)).subscribe((icon)=> this.icon=icon);
    }

    ngOnDestroy(){
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    inputChangedHandler(quickFilterValue: string){
        this.icon = quickFilterValue ? this.toolbarEventsService.searchBarIconFilled$.getValue() : this.toolbarEventsService.searchBarIcon$.getValue();
        this.toolbarEventsService.searchBarValue$.next(quickFilterValue);
        this.toolbarEventsService.events.next({search:quickFilterValue})
    }

    async clickAction() {
        if (this.isAppleMobile() && this.icon==='barcode') {
            this.focusBarcodeInput();
        } else if (this.isCameraBarcodeSupported() && this.icon === 'photo_camera') {
            await this.openBarcodeAndSearch();
        } else {
            let deafultAction = { openToolPanel: 'filters' };
            let action = !this.quickFilter.nativeElement.value ? { ...this.toolbarEventsService.searchBarAction$.getValue() } || deafultAction : { ...this.toolbarEventsService.searchBarActionFilled$.getValue() } || deafultAction;
            // @ts-ignore
            this.toolbarEventsService.events.next(action);
        }
    }

    // ANDROID
    @autobind
    private async openBarcodeAndSearch() {
        const barcode = await this.barcodeManagerService.openCameraBarcode();

        if (barcode) {
            this.toolbarEventsService.events.next({searchBarcode: barcode})
        }
    }

    protected isCameraBarcodeSupported() {
        return this.environmentInfoService.isCameraBarcodeSupported();
    }

    // APPLE WORKAROUND
    protected isAppleMobile() {
        return this.environmentInfoService.isAppleMobile();
    }

    protected barcodeSubmitted() {
        if(this.searchBarcodeInput.length) {
            this.icon = this.toolbarEventsService.searchBarIconFilled$.getValue();
            this.toolbarEventsService.searchBarValue$.next(this.searchBarcodeInput);
            this.toolbarEventsService.events.next({searchBarcode: this.searchBarcodeInput})
            this.searchBarcodeInput = "";
        }
    };
    private focusBarcodeInput() {
        const barcodeInput = this.renderer.selectRootElement('#'+this.idSelector);
        barcodeInput.focus();
        barcodeInput.value = '';
    };
}
