import { $state } from 'app/ajs-upgraded-providers';
import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OpenDialogsService } from 'src/app/dialogs';
import { CashMovements } from 'tilby-models';
import { CashMovementDialogStateService } from 'src/app/dialogs/cash-movement/cash-movement.dialog.service';
export interface CliccableActionCashMovementscard {action: 'details' | 'go-to-sale'; data: CashMovements | undefined}

@Component({
    selector: 'app-grid-clickable-cash-movements-button',
    templateUrl: './grid-clickable-button-cash-movements.component.html',
    styleUrls: ['./grid-clickable-button-cash-movements.component.scss']
})
export class GridClickableCashMovementsButtonComponent implements ICellRendererAngularComp {
    params!: ICellRendererParams<CashMovements>;

    constructor(
        @Inject($state) private state: any,
        public openDialogsService: OpenDialogsService,
        private cashMovementDialogStateService: CashMovementDialogStateService,
    ) {}

    agInit(params: ICellRendererParams<CashMovements>): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    async openDetailsDialog($event: any) {
        await this.cashMovementDialogStateService.openDialog({
            data: {
                title: "DIALOG.CASH_MOVEMENT.TITLE",
                cash_movement: this.params.data!
            },
        });
    }

    goToSale($event: any) {
        this.params.data!.sale_id && this.state.go("app.new.history.sale", {id: this.params.data!.sale_id});
    }

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }
}
