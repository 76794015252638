import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { CashMovementDialog } from "./cash-movement-dialog.model";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
@Component({
    selector: "app-cash-movement-dialog",
    templateUrl: "cash-movement.dialog.component.html",
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        MatButtonModule,
        TilbyCurrencyPipe
    ],
})
export class CashMovementDialogComponent implements OnInit {
    title: string = "";

    constructor(
        public dialogRef: MatDialogRef<CashMovementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data:CashMovementDialog,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        console.log(this.data)
    }

    get historyObj() {
        return this.data;
    }

    cancel() {
        this.dialogRef.close({ cancel: true });
    }

    getMovementType = (movement_type: string) => {
        switch(movement_type) {
            case 'income' :
                return this.translate.instant('HISTORY.CASH_MOVEMENTS.INCOME');
            case 'outcome' :
                return this.translate.instant('HISTORY.CASH_MOVEMENTS.OUTCOME');
        }
    };
}
