

//Fields from Be Response
import {MovementType} from "../../models";
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";

export class PrepaidMovementsFields {
    amount=0;
    credit=0;
    customer_uuid='customer_uuid';
    id=0;
    movement_type='movement_type';
    notes?: any;
    previous_id?: any;
    previous_movement?: any;
    sale_uuid='sale_uuid';
    shop_uuid='shop_uuid';
    ticket_amount=0;
    ticket_credit=0;
    valid_from: string='';
    valid_to?: any;
}

export class PrepaidMovementsFe {
    valid_from: Date = TilbyDatePipe.date({outputFormat:'date'});
    amount=0; // new AmountPrepaidFilter();
    credit=0;
    shop_uuid='shop_uuid';
}

//Fields from Be Response
export class FidelityMovementsFields {
    campaign_id=0;
    campaign_name='campaign_name';
    date: string='';
    db_name='db_name';
    fidelity='fidelity';
    id=0;
    item_name?: any;
    item_sku?: any;
    notes?: any;
    points=0;
    prize_id?: any;
    rule_id?: any;
    rule_name?: any;
    sale_id?: any;
    sale_name?: any;
    shop_name='shop_name';
}

export declare namespace POST{
    interface FidelityMovement {
        fidelity?: string | null;
        db_name: string;
        shop_name: string;
        type: MovementType;
        points: number;
        campaign_id?: number|null;
        campaign_name?: string;
        date: string | null;
        notes?: string;
    }

    interface PrepaidMovement {
        is_unclaimed: number|null|undefined;
        customer_uuid?: string;
        movement_type?: string | null;
        amount: number;
        notes?: string;
    }
}


export class FidelityMovementsFe {
    date: Date = TilbyDatePipe.date({outputFormat:'date'});
    points = 0;
    rule_name = '';
    shop_name = '';
    campaign_name = '';
}
