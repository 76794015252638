<h1 mat-dialog-title class="tw-mb-2" translate>SETTINGS.ADD_NEW_STATIC_TOKEN_DIALOG.TITLE</h1>
<div mat-dialog-content style="max-height: 75vh">
    <form [formGroup]="addNewStaticTokenDialogForm">
        <div class="tw-flex tw-w-full">
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_STATIC_TOKEN_DIALOG.DESCRIPTION</mat-label>
                <input matInput id="description" formControlName="description" type="text" class="form-control" required>
            </mat-form-field>
        </div>
        <mat-label translate>SETTINGS.ADD_NEW_STATIC_TOKEN_DIALOG.PERMISSIONS</mat-label>
        <div class="tw-mt-2">
            <mat-radio-group class="tw-flex tw-flex-col tw-m-2" formControlName="client_id">
                <mat-radio-button class="tw-m-2" *ngFor="let permission of permissions" [value]="permission.value">
                    <span><strong>{{ permission.title }}</strong><br>{{ permission.description }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </form>
</div>
<div mat-dialog-actions class="tw-justify-end">
    <button mat-button (click)="dialogRef.close()"><span translate>SETTINGS.ADD_NEW_STATIC_TOKEN_DIALOG.CANCEL</span></button>
    <button mat-raised-button color="primary" (click)="submit()" cdkFocusInitial [disabled]="isFormInvalid()"><span translate>SETTINGS.ADD_NEW_STATIC_TOKEN_DIALOG.CREATE</span></button>
</div>
