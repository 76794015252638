import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('fidelity').controller('FidelityPrizesCtrl', ["$scope", "$q", "$filter", "$translate", "entityManager", "confirmDialog", "alertDialog", function($scope, $q, $filter, $translate, entityManager, confirmDialog, alertDialog) {

    var pristinePrize = null; //contains the prize before the editing happens

    $scope.prizeTypes = {
        discount_fix: $translate.instant('FIDELITY.SETUP.PRIZES.DISCOUNT_FIX'),
        discount_perc: $translate.instant('FIDELITY.SETUP.PRIZES.DISCOUNT_PERC'),
        gift: $translate.instant('FIDELITY.SETUP.PRIZES.GIFT'),
    };

    $scope.topbar_context.view = "prizes";
    $scope.topbar_context.selectedTab = 2;

    $scope.topbar_context.checkBeforeExit = function() {
        var d = $q.defer();
        var openPrize = _.find($scope.prizes, { _show: true });

        if (openPrize) {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.PRIZES.EXIT_CONFIRM')).then(function(answer) {
                if (answer) {
                    if (openPrize.id) { //We were editing a prize, so restore it to pristine state
                        angular.copy(pristinePrize, openPrize);
                    } else { //We were creating a prize, so remove it from the prizes array
                        _.pullAt($scope.prizes, 0);
                    }
                    d.resolve();
                } else {
                    $scope.topbar_context.selectedTab = 2;
                    d.reject();
                }
            }, function() {
                $scope.topbar_context.selectedTab = 2;
                d.reject();
            });
        } else {
            d.resolve();
        }

        return d.promise;
    };

    $scope.addPrize = function() {
        var isAlreadyAdding = _.find($scope.prizes, { _show: true });

        if (isAlreadyAdding) {
            alertDialog.show($translate.instant('FIDELITY.SETUP.PRIZES.CLOSE_PRIZE_CREATE'));
        } else {
            $scope.prizes.unshift({ _show: true, type: 'discount_fix' });
        }
    };

    $scope.switchFidelityPrizeEdit = function(prize, dirty) {
        if (prize._show) {
            if (dirty) {
                confirmDialog.show($translate.instant('FIDELITY.SETUP.PRIZES.CONFIRM_CLOSE')).then(function(answer) {
                    if (answer) {
                        if (prize.id) {
                            angular.copy(pristinePrize, prize);
                            pristinePrize = null;
                        } else {
                            _.pull($scope.prizes, prize);
                        }
                    }
                });
            } else {
                if (!prize.id) {
                    _.pull($scope.prizes, prize);
                } else {
                    delete prize._show;
                }
            }
        } else {
            var alreadyOpenPrize = _.find($scope.prizes, { _show: true });

            if (alreadyOpenPrize) {
                alertDialog.show($translate.instant('FIDELITY.SETUP.PRIZES.CLOSE_PRIZE'));
            } else {
                if(prize.item_sku) {
                    prize._item = $scope.itemsMap[prize.item_sku];
                }

                if(prize.valid_from) {
                    prize._validFrom = moment(prize.valid_from).toDate();
                }

                if(prize.valid_to) {
                    prize._validTo = moment(prize.valid_to).toDate();
                }

                pristinePrize = _.cloneDeep(prize);
                prize._show = true;
            }
        }
    };

    $scope.savePrize = function(prize) {
        var prizeToSend = _.cloneDeep(prize);
        var operation = prizeToSend.id ? 'putOneOnline' : 'postOneOnline';

        _.assign(prizeToSend, {
            valid_from: _.isDate(prizeToSend._validFrom) ? prizeToSend._validFrom.toISOString() : null,
            valid_to: _.isDate(prizeToSend._validTo) ? prizeToSend._validTo.toISOString() : null
        });

        if(prizeToSend.type === 'gift') {
            prizeToSend.item_sku = _.get(prize, ['_item', 'sku']);
            prizeToSend.discount_amount = null;
        } else {
            prizeToSend.item_sku = null;
        }

        delete prizeToSend._show;
        delete prizeToSend._item;
        delete prizeToSend._typeString;

        entityManager.prizes[operation](prizeToSend).then(function(result) {
            angular.copy(result, prize);
            prize._typeString = $scope.getPrizeType(prize);
            $scope.prizesMap[prize.id] = prize;
            pristinePrize = null;
        }, function(error) {
            alertDialog.show($translate.instant('FIDELITY.SETUP.PRIZES.SAVE_ERROR'));
        });
    };

    $scope.filterItems = function(searchText) {
        return $filter('filter')($scope.items, searchText);
    };

    $scope.deletePrize = function(prize) {
        confirmDialog.show($translate.instant('FIDELITY.SETUP.PRIZES.DELETE_CONFIRM')).then(function(answer) {
            if (answer) {
                if (prize.id) {
                    entityManager.prizes.deleteOneOnline(prize.id).then(function() {
                        _.pull($scope.prizes, prize);
                        delete $scope.prizesMap[prize.id];
                    });
                } else {
                    _.pull($scope.prizes, prize);
                }
            }
        });
    };
}]);
