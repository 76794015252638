import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationManagerService } from 'src/app/core';
import { UiLanguage, uiLanguages } from 'src/app/core/constants/ui-languages';

type EditUiLanguageInput = {
    uiLanguage: UiLanguage;
}

@Component({
  selector: 'app-edit-ui-language-dialog',
  templateUrl: './edit-ui-language-dialog.component.html',
})
export class EditUiLanguageDialogComponent implements OnInit {
    inProgress: boolean;
    data: EditUiLanguageInput;
    uiLanguages: Array<UiLanguage>;

    constructor(
        public dialogRef: MatDialogRef<EditUiLanguageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: EditUiLanguageInput,
        protected configurationManager: ConfigurationManagerService,
    ) {
        this.inProgress = false;
        this.data = _data;
        this.uiLanguages = [];
    }
    ngOnInit(): void {
        this.uiLanguages = Object.values(uiLanguages).filter((uiLang: UiLanguage) => !uiLang?.ui_hide);
    }
    changeLanguage(uiLanguage: UiLanguage) {
        this.inProgress = true;
        this.data.uiLanguage = uiLanguage;
        this.configurationManager.setUserPreference('general.ui_language', uiLanguage.id);
        setTimeout(() => {
            this.inProgress = false;
            this.dialogRef.close(uiLanguage);
        }, 1000);
    }
}
