import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('poyntSubscriptionDialog', ["$mdDialog", "$q", "$window", "$translate", "$rootScope", function($mdDialog, $q, $window, $translate, $rootScope) {
    poyntSubscriptionDialogController.$inject = ["$scope", "plans"];
    function poyntSubscriptionDialogController($scope, plans) {
        $scope.plans = plans.list;
        $scope.chosenPlan = _.head($scope.plans);

        $scope.confirm = function() {
            $mdDialog.hide($scope.chosenPlan);
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    var poyntSubscriptionDialog = {
        show: function() {
            $rootScope.hideAppLoader();

            var d = $q.defer();

            $window.Poynt.billing.getPlans(function(plansStr) {
                try {
                    var plans = JSON.parse(plansStr);
                    var plansCount = _.toInteger(plans.total);
                    if(plansCount === 0) {
                        d.reject($translate.instant('APPLICATION.POYNT_DIALOG.NO_PLANS_AVAILABLE'));
                    } else if(plansCount === 1) {
                        d.resolve(_.head(plans.list));
                    } else {
                        $mdDialog.show({
                            controller: poyntSubscriptionDialogController,
                            template: require('./poynt-subscription-dialog.html'),
                            locals: {
                                plans: plans
                            }
                        }).then(d.resolve, d.reject);
                    }
                } catch(e) {
                    d.reject();
                }
            }, d.reject);

            return d.promise;
        }
    };

    return poyntSubscriptionDialog;
}]);