import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('promotions', ['application']);

angular.module('promotions').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('promotions');

    $stateProvider.state('app.promotions', {
        url: '/promotions',
        redirectTo: 'app.promotions.showcase',
    });

    $stateProvider.state('app.promotions.showcase', {
        url: '/showcase',
        views: {
            "appContent@app": {
                template: require('./partial/promotions-showcase/promotions-showcase.html'),
                controller: 'PromotionsShowcaseCtrl'
            }
        }
    });

    $stateProvider.state('app.promotions.details', {
        url: '/details/:promotionId',
        views: {
            "appContent@app": {
                template: require('./partial/promotions-details/promotions-details.html'),
                controller: 'PromotionDetailsCtrl'
            }
        },
        resolve: {
            items: ["entityManager", function(entityManager) {
                return entityManager.items.fetchCollectionOffline().then(function(items) { 
                    return _.filter(items, 'sku');
                });
            }],
            suppliers: ["entityManager", function(entityManager) {
                return entityManager.suppliers.fetchCollectionOffline();
            }],
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOffline();
            }],
            channels: ["entityManager", function(entityManager) {
                return entityManager.channels.fetchCollectionOffline();
            }],
            chainShops: ["restManager", function(restManager) {
                return restManager.getList("chain_shops", { pagination: false });
            }],
            promotion: ["entityManager", "$stateParams", function(entityManager, $stateParams) {
                return entityManager.promotions.fetchOneOnline($stateParams.promotionId);
            }]
        }
    });

    $stateProvider.state('app.promotions.new', {
        url: '/new/',
        views: {
            "appContent@app": {
                template: require('./partial/promotions-details/promotions-details.html'),
                controller: 'PromotionDetailsCtrl'
            }
        },
        resolve: {
            items: ["entityManager", function(entityManager) {
                return entityManager.items.fetchCollectionOffline().then(function(items) { 
                    return _.filter(items, 'sku');
                });
            }],
            suppliers: ["entityManager", function(entityManager) {
                return entityManager.suppliers.fetchCollectionOffline();
            }],
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOffline();
            }],
            channels: ["entityManager", function(entityManager) {
                return entityManager.channels.fetchCollectionOffline();
            }],
            chainShops: ["restManager", function(restManager) {
                return restManager.getList("chain_shops", { pagination: false });
            }],
            promotion: function() {
                return {
                    active: true,
                    exclude_other_promotions: true,
                    fidelity_rule: 'none',
                    require_all_items: true
                };
            }
        }
    });
    /* Add New States Above */
}]);