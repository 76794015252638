import {
    Component,
    computed,
    effect,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    customSort,
    mobileCheck,
    subscribeInComponent
} from '@tilby/tilby-ui-lib/utilities';
import {
    ConfigurationManagerService,
    StorageManagerService
} from 'src/app/core';
import {
    Categories,
    Departments,
    Items
} from 'tilby-models';
import {
    CashregisterStateService,
    CategoryView,
    EventsSettings,
    ItemView,
    SaleUtilsService
} from '../../services';

import { CategoryTabComponent } from '../category-tab/category-tab.component';
import { CategoryGridComponent } from '../category-grid/category-grid.component';
import { ShowcaseGridComponent } from '../showcase-grid/showcase-grid.component';
import { ShowcaseListComponent } from '../showcase-list';
import {
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { MediaMatcher } from '@angular/cdk/layout';
import {
    distinctUntilChanged,
    filter,
} from 'rxjs';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { OpenDialogsService } from 'src/app/dialogs';
import {
    ItemsByCategory,
    StockDictionary
} from '../../cashregister.component';
import { MatListModule } from '@angular/material/list';
import { CashregisterSearchItemsComponent } from '../cashregister-search-items/cashregister-search-items.component';
import { CashregisterSearchItemsWrapperComponent } from '../cashregister-search-items-wrapper/cashregister-search-items-wrapper.component';
import {
    CdkDrag,
    CdkDragHandle
} from "@angular/cdk/drag-drop";
import { MatIconModule } from "@angular/material/icon";
import { TilbyDraggableBottomSheetComponent } from "./tilby-draggable-bottom-sheet/tilby-draggable-bottom-sheet.component";
import {
    CashregisterKeyboardComponent,
    NewItemType
} from '../cashregister-keyboard';
import { ActiveSaleService } from 'src/app/features/cashregister/services';
import { autobind } from 'src/app/models/decorators.model';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { CashregisterItemDialogService } from 'src/app/dialogs/cashregister';
import { DevLogger } from 'src/app/shared/dev-logger';

export type OverridesType={type?:NewItemType|'sale',price?:number,quantity?:number,exit?:number};

export type Section = 'favorites' | 'categories';

@Component({
  selector: 'app-cashregister-showcase',
  standalone: true,
  imports: [CommonModule, CategoryTabComponent, CategoryGridComponent, ShowcaseGridComponent, ShowcaseListComponent, TranslateModule, TilbyGesturesDirective, CashregisterKeyboardComponent, CdkDrag, CdkDragHandle,MatListModule, CashregisterSearchItemsComponent, CashregisterSearchItemsWrapperComponent, MatIconModule,TilbyDraggableBottomSheetComponent],
  templateUrl: './cashregister-showcase.component.html',
  styleUrls: ['./cashregister-showcase.component.scss'],
  providers: [OnDestroyService],
  host: {
        class: 'tw-h-full tw-relative tw-block'
    }
})
export class CashregisterShowcaseComponent implements OnInit,OnChanges {

    private readonly activeSaleService = inject(ActiveSaleService);
    protected readonly cashregisterStateService= inject(CashregisterStateService);
    private readonly configurationManagerService= inject(ConfigurationManagerService);
    private readonly mediaMatcher= inject(MediaMatcher);
    private readonly onDestroyService= inject(OnDestroyService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly cashregisterSaleItemDialogService = inject(CashregisterItemDialogService); // OLD DIALOG UPGRADED
    private readonly translateService= inject(TranslateService);
    private readonly saleUtilsService= inject(SaleUtilsService);

    private configuredCouponsHeight=(Math.ceil(this.saleUtilsService.getConfiguredQuickCoupons().length/6)*52)/16;
    @HostBinding('style.--custom-height-open-keyboard.rem') customHeightOpenKeyboard=17.40+this.configuredCouponsHeight;
    protected customClassOpenKeyboard='margin-bottom-keyboard';
    protected customClassClosedKeyboard="tw-mb-7";
    protected keyboardIsOpen$=this.cashregisterStateService.keyboardIsOpen$;

    // PREFERENCE
    countNoStockAsUnavailable = this.configurationManagerService.getPreference('cashregister.count_nostock_as_unavailable');
    hideEmptyCategories = this.configurationManagerService.getPreference('showcase_hide_empty_categories');
    showStockOnShowcase = !!this.configurationManagerService.getPreference('cashregister.show_stock_on_showcase');
    showUnavailable = this.configurationManagerService.getPreference("showcase_show_unavailable") !== false;
    searchInAllCategories = this.configurationManagerService.getPreference("search_in_all_categories") !== false;
    showStockQuantity = !!this.configurationManagerService.getPreference('cashregister.show_stock_quantity_on_showcase');

    categoryView: CategoryView = this.configurationManagerService.getPreference("showcase_category_view") || 'tab';
    categoryGridRows = parseInt(this.configurationManagerService.getPreference("showcase_category_grid_rows") || "3", 10);
    showcaseMode:ItemView = this.configurationManagerService.getPreference("showcase_mode") || 'photoGrid'; //photoGrid noPhotoGrid list
    itemGridColumnsSelected = parseInt(this.configurationManagerService.getPreference("showcase_item_grid_columns") || "-1", 10);
    displayMode = this.configurationManagerService.getPreference("showcase_display_mode") || 'normal';
    order_by = this.configurationManagerService.getPreference("showcase_order_by") || '+name';

    showKeyboard = window.localStorage.getItem('cashregister::showKeyboard') === 'true';

    // CHECK MOBILE AND VERTICAL PORTRAIT
    @Input() isMobilePortrait=false;

    // CATEGORIES, ITEMS, DEPARTMENTS, STOCK DICTIONARY
    categories: Categories[]  = [];
    items: ItemsByCategory;
    departments: Departments[] = [];
    stockDictionary : StockDictionary | undefined;

    // CATEGORIES

    showcaseView = this.categoryView === 'tab' ? 'categoryTab' : 'categories';
    originalVisibleCategories : Categories[] = [];
    visibleCategories : Categories[] = [];
    selectedCategory : Categories | undefined;
    itemsCategory : Items[] = [];
    bigTablet : boolean = false;
    categoryBarHeight = {height: '0px'};
    itemsBarHeight = {height: '0px'};

    // ITEMS

    visibleItems: Items[] = [];
    itemsFavorite : Items[] = [];
    favoriteSelected : boolean = true;

    itemGridColumns : number = 0;

    // PRICE_LIST
    priceList = this.cashregisterStateService.priceList;
    priceListStr = computed(() => `price${this.priceList()}`);

    // SEARCH

    isShowSearch$ = this.cashregisterStateService.isShowSearch$;

    @ViewChild(CategoryTabComponent) categoryTabComponent!: CategoryTabComponent;
    @ViewChild(CategoryGridComponent) categoryGridComponent!: CategoryGridComponent;
    @ViewChild(CashregisterSearchItemsWrapperComponent) searchComponent!: CashregisterSearchItemsWrapperComponent;

    private log(...args: any[]) {
        DevLogger.log('CASHREGISTER_SHOWCASE_COMPONENT', ...args);
    }

    constructor(
    ) {
        // CHECK ORIENTATION
        this.categories = this.cashregisterStateService.categories;
        this.items = this.cashregisterStateService.items || {};
        this.departments = this.cashregisterStateService.departments;
        this.stockDictionary = this.cashregisterStateService.stockDictionary;

        effect(() => {
            if(this.hideEmptyCategories) {
                this.filterVisibleCategories();
            } else {
                this.filterVisibleItems();
            }
        })

        subscribeInComponent(
            this.cashregisterStateService.addNewItemToItems$,
            () => this.goToSection('categories')
        );

        subscribeInComponent(
            StorageManagerService.storageUpdates$.pipe(filter((data) => data.entityName === 'items')),
            (data) => this.updateItem()
        );
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if(simpleChanges.isMobilePortrait) {
            this.getColumnsXRows();
        }
    }

    ngOnInit(): void {
        this.cashregisterStateService.isShowSearch$.next({isSearch: false, searchValue: ''});
        this.cashregisterStateService.eventsSettings.pipe(distinctUntilChanged(),this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackSettingsClick(e))

        this.resetVisibleCategories();

        // this.bigTablet = this.mediaMatcher.matchMedia('(min-width: 1024px)').matches;
        this.getColumnsXRows();
        this.updateCategoryBarHeight();

        this.itemsFavorite = this.items.favorite;

        if(this.hideEmptyCategories) {
            this.filterVisibleCategories();
        } else {
            this.filterVisibleItems();
        }

        if(this.itemsFavorite.length) {
            this.selectedCategory = undefined;
            this.goToSection('favorites');
        } else {
            this.selectedCategory = this.visibleCategories[0];
            this.goToSection('categories');
        }

        this.cashregisterStateService.pricelistChangedEvent.pipe(this.onDestroyService.takeUntilDestroy).subscribe(async () => {
            if (!this.activeSaleService.isEmpty()) {
                const answer = await this.openDialogsService.openConfirmDialog({data: {
                    messageLabel: 'CASHREGISTER.SHOWCASE.APPLY_NEW_PRICELIST',
                    confirmLabel: 'CASHREGISTER.SHOWCASE.CONFIRM',
                    cancelLabel: 'CASHREGISTER.SHOWCASE.CANCEL'
                }});

                if(answer) {
                    this.activeSaleService.applyPriceList(this.priceList());
                }
            }
        })
    }

    private resetVisibleCategories(){
        this.visibleCategories = this.originalVisibleCategories = this.categories
            .sort(customSort)
            .filter((c) => c.display);

        if(this.items.null?.length) {
            this.visibleCategories.push({
                name: this.translateService.instant('CASHREGISTER.SHOWCASE.NO_CATEGORY'),
                id: "null" as unknown as number
            });
        }
    }

    getColumnsXRows() {
        this.bigTablet = this.mediaMatcher.matchMedia('(min-width: 1024px)').matches;
        if (this.itemGridColumnsSelected === -1) {
            this.itemGridColumns = this.bigTablet ? 6 : 4;
        } else {
            this.itemGridColumns = this.itemGridColumnsSelected as number;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.getColumnsXRows();
    }

    callbackSettingsClick(event : EventsSettings) {
        if ("category" in event) {
            this.categoryView = event.category;
            this.configurationManagerService.setUserPreference("showcase_category_view", this.categoryView);
            let index = this.visibleCategories.indexOf(this.selectedCategory as Categories);
            this.updateCategoryBarHeight();
            setTimeout(() => {
                if(this.categoryTabComponent && !this.favoriteSelected) {
                    this.categoryTabComponent.tabGroup.selectedIndex = this.itemsFavorite.length ? index + 1 : index;
                }
                if (this.categoryGridComponent && !this.favoriteSelected) {
                    this.categoryGridComponent.emitCategorySelection(this.selectedCategory!);
                }
            },1);
        } else if ("category_grid_rows" in event) {
            this.categoryGridRows = event.category_grid_rows;
            this.updateCategoryBarHeight();
            this.configurationManagerService.setUserPreference("showcase_category_grid_rows", this.categoryGridRows);
        } else if ("item" in event) {
            this.showcaseMode = event.item;
            this.configurationManagerService.setUserPreference("showcase_mode", this.showcaseMode);
        } else if ("item_grid_columns" in event) {
            this.itemGridColumnsSelected = event.item_grid_columns;
            this.updateCategoryBarHeight();
            this.getColumnsXRows();
            this.configurationManagerService.setUserPreference("showcase_item_grid_columns", this.itemGridColumnsSelected);
        } else if ("view" in event) {
            this.displayMode = event.view;
            this.configurationManagerService.setUserPreference("showcase_display_mode", this.displayMode);
        } else if ("order" in event) {
            this.order_by = event.order;
            this.filterVisibleItems();
            this.configurationManagerService.setUserPreference("showcase_order_by", this.order_by);
        } else if ("availability" in event) {
            this.showUnavailable = event.availability;
            if(this.hideEmptyCategories) {
                this.filterVisibleCategories();
            } else {
                this.filterVisibleItems();
            }
            this.configurationManagerService.setUserPreference("showcase_show_unavailable", this.showUnavailable);
        } else if ("search_in_all_categories" in event) {
            this.searchInAllCategories = event.search_in_all_categories;
            this.configurationManagerService.setUserPreference("search_in_all_categories", this.searchInAllCategories);
        } else if ("show_stock_quantity" in event) {
            this.showStockQuantity = event.show_stock_quantity;
            this.configurationManagerService.setUserPreference("cashregister.show_stock_quantity_on_showcase", this.showStockQuantity);
        }
    }

    private async updateItem() {
        this.items = await this.cashregisterStateService.getItems();
        this.filterVisibleCategories();
        this.itemsFavorite = this.items.favorite;
        this.goToCategory(this.selectedCategory?.id?'category':'favorite');
        this.log('UPDATE',{
            items:this.items,
            visibleCategories:this.visibleCategories,
            visibleItems:this.visibleItems});
    }

    // CATEGORIES METHODS

    isCategoryGridMode() { return this.categoryView === 'grid'; }

    isCategoryTabMode() { return this.categoryView === 'tab'; }

    hasVisibleItems() { return (this.visibleItems?.length) }

    goToCategory(id : number | string) {
        let targetCategory : Items[];

        switch (id) {
            case 'favorite':
                this.favoriteSelected = true;
                this.cashregisterStateService.setFavoriteSelectedReorder(true);
                this.showcaseView = 'favorites';
                targetCategory = this.itemsFavorite;
                break;
            default:
                this.favoriteSelected = false;
                this.cashregisterStateService.setFavoriteSelectedReorder(false);
                switch(this.categoryView) {
                    case 'grid':
                        this.showcaseView = 'categories';
                    break;
                    case 'tab':
                        this.showcaseView = `category_${id}`;
                    break;
                }

                targetCategory = this.items[id] || [];
                break;
        }

        this.itemsCategory = targetCategory;

        this.filterVisibleItems();

        this.showcaseMode = this.visibleItems.length < 500 ? (this.configurationManagerService.getPreference("showcase_mode") || 'photoGrid') : 'list';

        // $timeout(function() {
        // TODO : Da valutare se ancora utile
        // setTimeout(() => {
        //     $(".md-virtual-repeat-scroller").scrollTop(0);
        //     $(".items-grid-list").scrollTop(0);
        // });
    };

    async goToSection(section : Section) {
        switch (section) {
            case 'favorites':
                this.selectedCategory = undefined;
                this.goToCategory('favorite');
            break;
            case 'categories':
                let currentCategory = this.selectedCategory ? this.selectedCategory.id : 'null';
                this.goToCategory(currentCategory as string | number);

                // TODO : Da valutare se ancora utile
                // $timeout(function() {
                // setTimeout(() => {
                //     const currentCategoryElement = document.getElementById(`cat_${currentCategory}`);

                //     if(currentCategoryElement) {
                //         currentCategoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                //     }
                // });
            break;
            default:
            break;
        }
    };

    selectCategory(category : Categories) {
        this.selectedCategory = category;

        setTimeout(() => {
            this.goToSection('categories');
        });
    };

    filterVisibleItems() {
        const orderBy = {
            field: this.order_by.startsWith('price', 1) ? this.priceListStr() : this.order_by.replace(/[+-]/g, '').trim(),
            order: (this.order_by)[0] === '-' ? "desc" : "asc"
        };
        const filteredItems = this.itemsCategory.filter(this.itemFilterFunction);
        filteredItems.sort((a, b) => {
                const a1 = a[orderBy.field as keyof Items];
                const b1 = b[orderBy.field as keyof Items];
                return typeof a1 === 'number' && typeof b1 === 'number' ? (orderBy.order === 'asc' ? a1 - b1 : b1 - a1) : (orderBy.order === 'asc' ? String(a1).localeCompare(String(b1)) : -String(a1).localeCompare(String(b1)));
        });
        this.visibleItems = filteredItems;
        this.cashregisterStateService.setItemVisibleReorder(this.visibleItems);
    };

    itemFilterFunction = (item : Items) => {
        let stockCheck = this.showUnavailable ? true : (this.countNoStockAsUnavailable ? !['stock-info-nostock', 'stock-info-unavailable'].includes(this.getItemStockStatus(item).status) : (this.getItemStockStatus(item).status !== 'stock-info-unavailable'));
        return stockCheck && Number.isFinite(item[this.priceListStr() as keyof Items]);
    };

    filterVisibleCategories() {
        this.visibleCategories = this.originalVisibleCategories.filter((category : Categories) => {
            if(category.id && Array.isArray(this.items[category.id])) {
                const itemsInCategory = this.items[category.id].filter(this.itemFilterFunction);

                return itemsInCategory.length > 0;
            }
        });

        this.updateCategoryBarHeight();

        if(this.selectedCategory) {
            if(!this.visibleCategories.includes(this.selectedCategory) && this.visibleCategories[0]) {
                this.selectCategory(this.visibleCategories[0]);
            } else{
                this.selectCategory(this.selectedCategory);
            }
        } else {
            this.filterVisibleItems();
        }
    };

    @autobind
    getItemStockStatus(item : Items) {
        if (this.showStockOnShowcase) {
            const {available} = this.stockDictionary?.get(`item_id_${item.id||0}`) || {};
            const {stock_quantity} = this.showStockQuantity ?this.stockDictionary?.get(`item_id_${item.id||0}`) || {} : {stock_quantity: undefined};
            if (item.stock_type) {
                switch (available) {
                    case 'available':
                    case 'producible':
                        return { status: 'stock-info-available', stock_quantity: stock_quantity };
                    case 'unavailable':
                        return { status: 'stock-info-unavailable', stock_quantity: stock_quantity };
                    case 'alert':
                        return { status: 'stock-info-alert', stock_quantity: stock_quantity };
                    default:
                        return { status: 'stock-info-nostock', stock_quantity: stock_quantity };
                }
            } else {
                return { status: 'stock-info-nostock', stock_quantity: stock_quantity };
            }
        } else {
            return { status: 'stock-disabled', stock_quantity: undefined };
        }
    };

    updateCategoryBarHeight() {
        const categoriesPerRow = this.bigTablet ? 6 : 4;
        const visibleCategoriesLen = this.visibleCategories.length;

        const rows = this.categoryGridRows as number > Math.ceil((visibleCategoriesLen) / categoriesPerRow) ? Math.ceil((visibleCategoriesLen) / categoriesPerRow): this.categoryGridRows as number;
        this.categoryBarHeight = {
            height: this.getCategoryBarHeight(rows) + 'px',
        };

        this.itemsBarHeight = this.isCategoryGridMode() ? { 'height': 'calc(100% - ' + (this.getCategoryBarHeight(rows)) + 'px'} : {'height': 'calc(100% - 60px'};
    };

    private getCategoryBarHeight(rows:number){
        if(mobileCheck()){
            rows=2;
        }
        const gap = 2;
        const padding = 0;
        const rowHeight = 52;
        return (rows * (rowHeight+gap))+padding;
    }

    async showItem(item: Items) {
        await this.cashregisterSaleItemDialogService.openDialog({data:{item,stockDictionary:this.stockDictionary,departments:this.departments, categories:this.categories}});
    }

    protected addItemToSale(item:Items){
        this.cashregisterStateService.addItemToSale$.next(item);
    }


    @autobind
    getItemPriceList(item: any) {
        return item[this.priceListStr()];
    }

}
