import { CommonModule } from "@angular/common";
import { Component, Injectable, inject } from "@angular/core";
import { Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { lastValueFrom } from "rxjs";
import { OpenDialogsService } from "../services";

type TilbyDialogData = { title: string, content: string, subContent?: string };

@Component({
    selector: 'app-interval-between-two-dates-dialog',
    templateUrl: './interval-between-two-dates-dialog.components.html',
    styleUrls: ['./interval-between-two-dates-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, TilbyDialogToolbarComponent, TilbyDialogContentComponent, TilbyMagicFormComponent]
})
export class IntervalBetweenTwoDatesDialogComponent {
    protected readonly data: TilbyDialogData = inject(MAT_DIALOG_DATA);
    protected readonly openDialogsService = inject(OpenDialogsService);
    private readonly matDialogRef = inject(MatDialogRef);
    datePickerForm = new CustomFormGroup<CustomForm<any>>({
        start_date: new CustomFormControl('', {validators: [Validators.required]}, {...new CustomFormControlProps(), inputType:'date', label:'DIALOG.RESEND_SALES_TO_MAGO_ERP.DATE', id:'start_date', class: "tw-w-full sm:tw-w-1/3"}),
    })

    protected confirm() {
        if(this.datePickerForm.valid) {
            let start_date = new Date(new Date(this.datePickerForm.controls.start_date.value).setHours(24, 59, 59, 999)).toISOString().split('T')[0];
            let end_date = new Date(new Date(this.datePickerForm.controls.start_date.value).setHours(24, 59, 59, 999)).toISOString().split('T')[0];

            start_date = start_date + "T00:00:00.000Z";
            end_date = end_date + "T23:59:59.000Z";

            this.matDialogRef.close({start_date, end_date});
        } else {
            this.datePickerForm.markAllAsTouched();
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class IntervalBetweenTwoDatesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(title: string, content: string, subContent?: string) {
        const data: TilbyDialogData = { title, content, subContent };
        const config: NonNullableConfigData<TilbyDialogData> = {
            ...this.switchMobileDesktopDimensions({width: '50%'}, {fullScreenForMobile: true}),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(IntervalBetweenTwoDatesDialogComponent, config).afterClosed()
        ).then((res: any) => {return {start_date: res.start_date, end_date: res.end_date}})
    }
}



