import { Inject, Injectable, NgZone } from '@angular/core';
import { Customers } from 'tilby-models';
import {
    EntityDictionariesStoreService,
    EntityManagerService
} from 'src/app/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericToastComponent } from 'src/app/shared/components/toast/generic-toast.component';
import { $state } from 'app/ajs-upgraded-providers';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomersCallService {
    constructor(
        private entityDictionaries: EntityDictionariesStoreService,
        private entityManager: EntityManagerService,
        protected _snackBar: MatSnackBar,
        private zone: NgZone,
        public translate: TranslateService,
        @Inject($state) private state: any) {
    }

    /**
     * Calls the customer with the given phone number.
     *
     * @param {string} phoneNumber - The phone number to call.
     */
    async callCustomer(phoneNumber: string) {
        let customer: Customers | undefined;
        const customerId = this.entityDictionaries.getCustomerByPhone(phoneNumber);

        if (customerId) {
            customer = await this.entityManager.customers.fetchOneOffline(customerId);
        }

        const identify = { identify: (customer ? customer.first_name && customer.last_name ? customer.first_name + ' ' + customer.last_name : customer.company_name : phoneNumber)};

        this.zone.run(() => {
            const toastStatus = this._snackBar.openFromComponent(GenericToastComponent, {
                data: { title: 'CUSTOMERS.CALL.CALL_BY', titleParams: identify, actionDismiss: 'CUSTOMERS.CALL.CANCEL', actionDismissWithAction: 'CUSTOMERS.CALL.ACTION'},
                verticalPosition: "bottom",
                horizontalPosition: "left",
                panelClass:['mat-snack-bar-tilby']
            });

            toastStatus.onAction().subscribe(() => {
                customer ? this.state.go(`app.new.customers.details`, {customerId: customer.id}) : this.state.go("app.new.customers.new", {phoneNumber: phoneNumber});
            });
        });
    }
}
