import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { ConfigurationManagerService } from "src/app/core";

export class CampgestApiError extends Error {
    constructor(public readonly message: string) {
      super(message);
    }
}

export type CheckLoginCredentialsRequestType = {
    baseUrl: string,
    aUserName: string,
    aPassword: string
}

export type CheckCardRequestType = {
    aCardCode: string
}

export type NewTransactionRequestType = {
    aShopCode: string,
    aCardCode: string | number
}

export type OperationRequestType = {
    aTrID : number,
    aCardCode: string | number,
    AnAmount: number | undefined,
    ACause: string,
    ATransactionType: string,
    AComments: string,
}

export type CloseTransactionRequestType = {
    aTrID : number
}

@Injectable({
    providedIn: 'root'
})
export class CampgestPmsApiService {

    private readonly configurationManagerService = inject(ConfigurationManagerService);

    private apiUrl: string = this.configurationManagerService.getPreference('digital_payments.campgest.base_url') || '';
    private username: string = this.configurationManagerService.getPreference('digital_payments.campgest.username') || '';
    private password: string = this.configurationManagerService.getPreference('digital_payments.campgest.password') || '';

    constructor(
        private httpClientService: HttpClient
    ) {}

    refreshConnectionPreferences() {
        this.apiUrl = this.configurationManagerService.getPreference('digital_payments.campgest.base_url') || '';
        this.username = this.configurationManagerService.getPreference('digital_payments.campgest.username') || '';
        this.password = this.configurationManagerService.getPreference('digital_payments.campgest.password') || '';
    }

    async sendApiRequest(method: string, url: string, data: any, params: any): Promise<any> {
        const options = {
          headers: new HttpHeaders({
            'Authorization': `Basic ${btoa(`${this.username}:${this.password}`)}`
          }),
          params: new HttpParams({ fromObject: params }),
          timeout: 5000
        };

        try {
          const response = await firstValueFrom(this.httpClientService.request<any>(method, this.apiUrl + url, {
            body: data,
            ...options
          }));

          return response;
        } catch (error : any) {
            // Only Connection error, others in json response (err_code, err_msg)
            throw error;
        }
    }

    public async checkLoginCredentials(body: CheckLoginCredentialsRequestType) {
        const options = {
            timeout: 5000
        };
        try {
            const response = await firstValueFrom(this.httpClientService.request<any>('POST', body.baseUrl + 'LoginCredentials', {
                body: body,
                ...options
            }));

            return response;
        } catch (error : any) {
            return { Result: false };
        }
    }

    public async checkCard(body: CheckCardRequestType) {
        const response = await this.sendApiRequest('POST', 'PosCardCheck', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }

    public async openNewTransaction(body: NewTransactionRequestType) {
        const response = await this.sendApiRequest('POST', 'PosNewTrID', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }

    public async callOperation(body: OperationRequestType) {
        const response = await this.sendApiRequest('POST', 'PosOperationTr', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }

    public async closeTransaction(body: CloseTransactionRequestType) {
        const response = await this.sendApiRequest('POST', 'PosConfirmTrId', body, null);

        if (response.error_code) {
            throw new CampgestApiError(response.error_msg);
        }

        return response.Result;
    }
}
