import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fidelity').factory('fidelitySalePoints', fidelitySalePoints);

fidelitySalePoints.$inject = ["$translate", "entityManager", "restManager", "checkManager", "connection"];

function fidelitySalePoints($translate, entityManager, restManager, checkManager, connection) {
    const fidelitySalePoints = {
        //  online
        isEnabled: function () {
            return !!checkManager.getPreference("cashregister.print_fidelity_points");
        },
        getPointsForSale: async (sale) => {
            if (connection.isOffline()) {
                throw "CONNECTION_NOT_AVAILABLE";
            }

            return restManager.post('calculate_points', sale);
        },
        getFidelityInfo: async (sale) => {
            if (!sale.sale_customer?.fidelity) {
                return;
            }

            let campaigns = await entityManager.campaigns.fetchCollectionOffline({ isValid: true });

            if (_.isEmpty(campaigns)) {
                return;
            }

            let pointsData = {};
            let activeCampaign = campaigns[0];
            let customerPoints = await entityManager.fidelitiesPoints.fetchCollectionOffline({ fidelity: sale.sale_customer.fidelity, campaign_id: activeCampaign.id });

            pointsData.oldTotal = _.toInteger(customerPoints[0]?.points);

            try {
                let salePoints = await fidelitySalePoints.getPointsForSale(sale);

                Object.assign(pointsData, {
                    salePoints: salePoints.points,
                    newTotal: Math.round(pointsData.oldTotal + salePoints.points)
                });
            } catch (err) {
                //Nothing to do
            }

            return pointsData;
        },
        getData: async (sale, printerDocumentData) => {
            let fidelityInfo = await fidelitySalePoints.getFidelityInfo(sale);

            if (!fidelityInfo) {
                return;
            }

            let rowsDesc = [];
            let rowsData = [];

            if (_.isFinite(fidelityInfo.oldTotal)) {
                rowsDesc.push(checkManager.getPreference("fidelity.prevtotal_message") || $translate.instant('FIDELITY.SALE_POINTS.PREVIOUS_AMOUNT'));
                rowsData.push(fidelityInfo.oldTotal);
            }

            if (_.isFinite(fidelityInfo.salePoints)) {
                rowsDesc.push(checkManager.getPreference("fidelity.current_message") || $translate.instant('FIDELITY.SALE_POINTS.TRANSACTION_AMOUNT'));
                rowsData.push(fidelityInfo.salePoints);
            }

            if (_.isFinite(fidelityInfo.newTotal)) {
                rowsDesc.push(checkManager.getPreference("fidelity.total_message") || $translate.instant('FIDELITY.SALE_POINTS.CURRENT_AMOUNT'));
                rowsData.push(fidelityInfo.newTotal);
            }

            const maxRowLength = _.chain(rowsDesc).maxBy('length').size().value();

            let rowsToSend = rowsDesc.map((row, idx) => `${_.padEnd(row, maxRowLength, " ")} ${rowsData[idx]}`);

            return {
                tail: rowsToSend
            };
        }
    };

    return fidelitySalePoints;
}