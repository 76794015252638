import {
    Component,
    Injector,
    OnInit,
    Renderer2,
    ViewChild,
    inject,
    signal
} from '@angular/core';

import {
    $state,
    $stateParams,
    restManager,
    util
} from 'app/ajs-upgraded-providers';

import {
    BarcodeManagerService,
    ConfigurationManagerService,
    ConfigurationPreferences,
    ConnectionService,
    EntityManagerService,
    EnvironmentInfoService,
    ModuleEvents,
    PaginatedResponse,
    RIGHT_SIDENAV_PORTAL_DATA_TOKEN,
    RightSidenavService,
    ToolbarEventsService
} from 'src/app/core/services';

import {FilterChangedEvent, IServerSideDatasource, RowClickedEvent, SortChangedEvent} from 'ag-grid-community';
import {DataExport, QueryPaginationHistorySales, SalesFe, TableData} from 'src/app/shared/model/model';
import {from} from 'rxjs';
import {GridServerSideComponent} from 'src/app/shared/components/grid-server-side/grid-server-side.component';
import {
    GridClickableHistorySalesButtonComponent,
    GridFixedButtons,
    headersTranslate,
    ImplementServerSideGrid
} from 'src/app/shared/components/grid';
import {
    GridDownloadDialogService,
    IntervalBetweenTwoDatesDialogService,
    OpenDialogsService
} from 'src/app/dialogs';
import {autobind} from 'src/app/models/decorators.model';
import {GridCellFormatterService} from 'src/app/shared/components/grid/utils/grid-cell-formatter.service';
import {GridCellExportFormatterService} from 'src/app/shared/components/grid/utils/grid-cell-export-formatter.service';
import {ComponentPortal} from '@angular/cdk/portal';
import {HistorySaleDetailComponent} from '../history-sale-detail/history-sale-detail.component';
import {Customers, Sales} from 'tilby-models';
import {HistorySalesService} from '../../services/history-sales.service';
import {GridService} from '../../../../../shared/components/grid/grid.service';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { HistorySummaryInvoiceViewingUiService } from 'src/app/dialogs/history-summary-invoice-viewing-ui-dialog';
import { SalesToBeInvoiced } from 'src/app/dialogs/history-summary-invoice-viewing-ui-dialog/models/sales_to_be_invoiced.model';
import { TranslateService } from "@ngx-translate/core";
import { DevLogger } from 'src/app/shared/dev-logger';

@Component({
    selector: 'app-history-sales',
    templateUrl: './history_sales-showcase.component.html',
    styleUrls: ['./history_sales-showcase.component.scss'],
    providers: [OnDestroyService]
})
export class HistorySalesShowcaseComponent implements OnInit, ImplementServerSideGrid {
    private readonly state= inject($state);
    private readonly stateParams= inject($stateParams);
    private readonly utilService= inject(util);
    private readonly environmentInfoService= inject(EnvironmentInfoService);
    private readonly entityManager= inject(EntityManagerService);
    private readonly toolbarEventsService= inject(ToolbarEventsService);
    private readonly onDestroyService= inject(OnDestroyService);
    public  readonly openDialogsService= inject(OpenDialogsService);
    private readonly rightSidenavService= inject(RightSidenavService<HistorySaleDetailComponent>);
    private readonly gridCellFormatterService= inject(GridCellFormatterService);
    private readonly gridCellExportFormatterService= inject(GridCellExportFormatterService);
    private readonly historySalesService= inject(HistorySalesService);
    private readonly gridService= inject(GridService);
    private readonly renderer= inject(Renderer2);
    private readonly connection= inject(ConnectionService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);
    private readonly historySummaryInvoiceViewingUiService = inject(HistorySummaryInvoiceViewingUiService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly restManagerService = inject(restManager);
    private readonly translateService = inject(TranslateService);
    private readonly intervalBetweenTwoDatesDialogService = inject(IntervalBetweenTwoDatesDialogService);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    isTimerExpired: boolean = false;
    timeDifferenceMinutes!: number;
    private movColumnsSwitch = false;
    private isAdvancedFilterActive = false;
    @ViewChild('appGridComponent', {static: true}) gridRef!: GridServerSideComponent;
    isSelected = false;
    selectRowUIEnabled: boolean = true;
    exportFormatterOptions?: (...any: any[])=> any;
    fixedButtons: GridFixedButtons = {
        checkbox: {visible: true, lockPosition: 'left'},
        customButton: {visible: true, cellRenderer: GridClickableHistorySalesButtonComponent, lockPosition: 'right'}
    };
    private sales?: Sales[];
    readonly query: Readonly<QueryPaginationHistorySales> = {
        page: 0,
        pagination: true,
        per_page: 50,
        status: 'closed',
    };
    datasource: IServerSideDatasource = {
        getRows: (params) => this.gridRef.getRowsForServersideDatasource(params, [this.query], (a, b?) => from(this.entityManager.sales.fetchCollectionOnline(a) as Promise<PaginatedResponse<Sales>>), {
                sort_type: 'desc',
                sort_col: 'closed_at'
            },
            'HistorySalesNormalizedData')
    };

    private readonly country = this.configurationManagerService.getShopCountry();

    protected expenseReportConfig = {
        template_id: parseInt(this.configurationManagerService.getPreference('cashregister.expense_report.template_id') || '')
    };

    tableData: TableData[] = [
        {
            rowData$: null,
            dataType: new SalesFe(),
            headersTranslate: headersTranslate.history_sales_showcase,
            columnsFormatter: this.gridCellFormatterService.history_sales,
    // @ts-ignore
            isCheckable: this.isCheckable.bind(this),
        }
    ];

    get isCountryItaly(){
        return this.country === 'IT';
    }

    constructor() {
        this.gridService.history_sales$.data.subscribe(data => {
            if(this.gridRef.agGrid.gridOptions) {this.gridRef.agGrid.gridOptions.rowClass = 'ag-row-background__unselected';}
            this.sales = data;
        });
        this.gridService.history_sales$.closeDetail.subscribe(() => {
            if (this.gridRef.agGrid.gridOptions) {
                this.gridRef.resetUIRows();
            }
        });
    }

    isCheckable(data: SalesFe) {
        return !this.isCountryItaly || this.historySalesService.canCheckSale(this.sales?.find(sale => sale.uuid === data.uuid));
    }

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.toolbarEventsService.searchBarValue$.next('');
        this.toolbarEventsService.searchBarIcon$.next('tune');
        this.toolbarEventsService.searchBarIconFilled$.next('tune');
        this.toolbarEventsService.searchBarAction$.next({ openToolPanel: 'filters' });
        this.toolbarEventsService.searchBarActionFilled$.next({ openToolPanel: 'filters' });
        this.exportFormatterOptions = this.gridCellExportFormatterService.historySalesFormatter;
    }

    onGridReady() {
        // checkbox all - REMOVE 2023-06-12
        // this.gridRef.columnDefs[0] = {...this.gridRef.columnDefs[0], headerComponent: CustomGridCheckboxAllComponent};
        // this.gridRef.gridApi?.setColumnDefs(this.gridRef.columnDefs);

        // checkbox validation
        this.gridRef.agGrid.selectionChanged.subscribe(row => {
            this.historySalesService.selectedSales = row.api.getSelectedRows();
            if(this.isCountryItaly){
                const paymentsFirstSaleSelected = this.historySalesService.selectedSales[0]?.payments;
                this.historySalesService.isSelectingUnclaimed = !!paymentsFirstSaleSelected?.every((p) => p.unclaimed);
                this.historySalesService.isSelectingClaimed = !!paymentsFirstSaleSelected?.every((p) => !p.unclaimed);
                // @ts-ignore
                this.gridRef.gridApi.forEachNode(node => node.setRowSelectable(!!this.historySalesService.canCheckSale(node.data)));
            }
        });
    }

    // INFO: getChildFilterInstance(0) for multi instance (filters)
    async onFirstDataRendered() {
        switch (this.state.router.globals.$current.name) {
            case 'app.new.history.customer':
                const customer = this.stateParams.customer as Customers;
                const salesToBeInvoiced = this.stateParams.salesToBeInvoicedCustomer as SalesToBeInvoiced;

                this.gridRef.gridApi?.getFilterInstance('customer_name')?.setModel({
                    type: 'contains',
                    filter: this.getCustomerName(customer)
                });

                this.gridRef.gridApi?.onFilterChanged();

                if (salesToBeInvoiced) {
                    const invoiceData = await this.historySummaryInvoiceViewingUiService.openDialog(salesToBeInvoiced);

                    if (invoiceData) {
                        await this.createCustomerSummarySale(invoiceData);
                    }
                }
                break;
            case 'app.new.history.sales': // APP-TO-APP --- tilby://history/sale/32
                if (this.stateParams.id) {
                    this.loadSaleFromId(this.stateParams.id);
                }
                break;
            default:
                // handle default case if needed
                break;
        }
    }

    getCustomerName(customer: Customers) : string{
        return customer ?
        ((customer.first_name ? customer.first_name + ' ' : '')
        + (customer.last_name ? customer.last_name + ' ' : '')
        + (((customer.first_name || customer.last_name) && customer.company_name) ? ' - ' : '')
        + (customer.company_name ? customer.company_name : '')).trim() : '';
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("HISTORY-SALES");
    }

    /**
     * Create buttons for the toolbar
     */
    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.connection.isOffline(),
                    name: 'cloud_off',
                    icon: signal('cloud_off'),
                    click: _ => null
                },
                {
                    isIt: () => this.gridRef.gridApi?.getSelectedRows()?.length === 0 && this.environmentInfoService.isAppleMobile(),
                    name: 'search_sale_by_terminal_barcode',
                    iconType: 'symbols',
                    icon: signal('barcode'),
                    click: _ => this.onBarcodeScannedAppleMobile()
                },
                {
                    isIt: () => this.gridRef.gridApi?.getSelectedRows()?.length === 0 && this.environmentInfoService.isCameraBarcodeSupported(),
                    name: 'search_sale_by_barcode',
                    icon: signal('photo_camera'),
                    click: async _ => await this.openBarcodeAndSearch()
                },
            ],
            panelButtons: [
                {
                    isIt: signal(true),
                    name: 'columns_panel',
                    icon: signal('view_week'),
                    label: signal('TOPBAR.ACTIONS.COLUMN_SELECTED'),
                    click: _ => this.toolbarEventsService.events.next({openToolPanel: 'columns'})
                },
                {
                    isIt: signal(true),
                    name: 'movable_columns',
                    icon: signal('drag_indicator'),
                    label: () => this.movColumnsSwitch ? 'TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT': 'TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT',
                    click: _ => this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = !this.movColumnsSwitch})
                },
                {
                    isIt: signal(true),
                    name: 'save_columns_position',
                    icon: signal('save'),
                    label: signal('TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE'),
                    click: _ => this.toolbarEventsService.events.next({save: true})
                },
                {
                    isIt: signal(true),
                    name: 'advanced_filter',
                    icon: () => this.isAdvancedFilterActive ? 'filter_alt_off' : 'filter_list_alt',
                    label: () => this.isAdvancedFilterActive ? 'TOPBAR.ACTIONS.ADVANCED_FILTERS_DISABLED' : 'TOPBAR.ACTIONS.ADVANCED_FILTERS_ENABLED',
                    click: _ => this.toolbarEventsService.events.next({type: 'advFiltersSwitch', advFiltersSwitch: this.isAdvancedFilterActive = !this.isAdvancedFilterActive})
                },
                {
                    isIt: signal(true),
                    name: 'show_sales_to_be_invoiced',
                    icon: signal('summarize'),
                    label: signal('TOPBAR.ACTIONS.SHOW_SALES_TO_BE_INVOICED'),
                    click: _ => this.historySummaryInvoiceViewingUiService.openDialog().then((invoiceData) => this.createCustomerSummarySale(invoiceData))
                },
                {
                    isIt: () => this.isMagoEnabled() !== null,
                    name: 'send_to_mago_erp',
                    icon: signal('cloud_upload'),
                    label: signal('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP'),
                    click: _ => {
                        if(this.gridRef.gridApi?.getSelectedRows()?.length! > 0) {
                            this.checkTimer();
                            if(!this.isTimerExpired) {
                                this.openDialogsService.openAlertDialog({data: {messageLabel: `${this.translateService.instant('TOPBAR.ACTIONS.MAGO_MAX_MINUTES')} ${this.timeDifferenceMinutes} ${this.translateService.instant(this.timeDifferenceMinutes === 1 ?'TOPBAR.ACTIONS.MAGO_ONE_MINUTE' : 'TOPBAR.ACTIONS.MAGO_MORE_MINUTES')}`}})
                            } else {
                                this.openDialogsService.openConfirmDialog({data: {messageLabel: `${this.translateService.instant('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_MSG_FIRST')} ${this.gridRef.gridApi?.getSelectedRows()?.length} ${this.gridRef.gridApi?.getSelectedRows()?.length === 1 ? this.translateService.instant('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_MSG_SINGLE') : this.translateService.instant('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_MSG_MULTIPLE')} ${this.translateService.instant('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_MSG_SECOND')}`}})
                                .then(res => {
                                    if(res) {
                                        const saleIds = this.gridRef.gridApi?.getSelectedRows().map((sale) => {return sale.id})

                                        let url = 'mago/send_sale?id=';
                                        saleIds?.forEach((saleId, index) => {
                                            url += saleId
                                            if(index < saleIds.length - 1) {
                                                url += ','
                                            }
                                        })

                                        this.restManagerService.getOne(url)
                                        .then((response: any) => {
                                            if(response.message === 'ok') {
                                                this.openDialogsService.openSnackBarTilby('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_SUCCESS', 'MISC.OK', { duration: 3000 });
                                                this.startTimer();
                                            } else {
                                                this.openDialogsService.openSnackBarTilby('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_ERROR', 'MISC.OK', { duration: 3000 });
                                            }
                                        })
                                        .catch((error: any) => {
                                            DevLogger.log(error)
                                        })
                                    }
                                });
                            }
                        } else {
                            this.checkTimer();
                            if(!this.isTimerExpired) {
                                this.openDialogsService.openAlertDialog({data: {messageLabel: `${this.translateService.instant('TOPBAR.ACTIONS.MAGO_MAX_MINUTES')} ${this.timeDifferenceMinutes} ${this.translateService.instant(this.timeDifferenceMinutes === 1 ?'TOPBAR.ACTIONS.MAGO_ONE_MINUTE' : 'TOPBAR.ACTIONS.MAGO_MORE_MINUTES')}`}})
                            } else {
                                this.intervalBetweenTwoDatesDialogService.openDialog('TOPBAR.ACTIONS.RE_SEND_SALES_TO_MAGO_ERP_TITLE', 'TOPBAR.ACTIONS.RE_SEND_SALES_TO_MAGO_ERP_MSG_FIRST', 'TOPBAR.ACTIONS.RE_SEND_SALES_TO_MAGO_ERP_MSG_SECOND')
                                .then((res) => {
                                    if(res.start_date && res.end_date) {
                                        this.restManagerService.getOne(`mago/send_sale?closed_at_since=${res.start_date}&closed_at_max=${res.end_date}`)
                                        .then((response: any) => {
                                            if(response.message === 'ok') {
                                                this.openDialogsService.openSnackBarTilby('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_SUCCESS', 'MISC.OK', { duration: 3000 });
                                                this.startTimer();
                                            } else {
                                                this.openDialogsService.openSnackBarTilby('TOPBAR.ACTIONS.SEND_TO_MAGO_ERP_ERROR', 'MISC.OK', { duration: 3000 });
                                            }
                                        })
                                        .catch((error: any) => {
                                            DevLogger.log(error)
                                        })
                                    }
                                })
                            }
                        }
                    }
                },
                {
                    isIt: () => (this.gridRef.gridApi?.getSelectedRows()?.length || 0) > 0,
                    name: 'summary_invoice',
                    icon: signal('receipt'),
                    label: signal('TOPBAR.ACTIONS.SUMMARY_INVOICE'),
                    click: async _ => await this.historySalesService.oldSummaryInvoiceDialog() && this.historySalesService.createSummarySale()
                },
                {
                    isIt: signal(true),
                    name: 'export',
                    icon: signal('download'),
                    label: signal('TOPBAR.ACTIONS.EXPORT'),
                    click: _ => this.toolbarEventsService.events.next({export: true})
                },
            ]
        });
    }

    startTimer() {
        const timer = new Date(Date.now() + 5 * 60 * 1000);
        localStorage.setItem('timerFiveMinutes', timer.getTime().toString());
        this.isTimerExpired = false;
    }

    checkTimer() {
        const timestamp = localStorage.getItem('timerFiveMinutes');
        const timestampInt = timestamp ? parseInt(timestamp) : 0;
        const timer = new Date(timestampInt);

        if(Date.now() >= timer.getTime()){
            this.isTimerExpired = true;
            localStorage.removeItem('timerFiveMinutes');
        } else {
            this.timeDifferenceMinutes = Math.floor((timer.getTime() - Date.now()) / (1000 * 60))+1;
        }
    }

    isMagoEnabled = () => {
        const integrations_mago_publish_sales = this.configurationManagerService.getPreference("integrations.mago.publish_sales" as keyof ConfigurationPreferences);
        const integrations_mago_publish_customers = this.configurationManagerService.getPreference('integrations.mago.publish_customers' as keyof ConfigurationPreferences);

        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    async createCustomerSummarySale(invoiceData?: SalesToBeInvoiced) {
        if(!invoiceData?.sales?.length) {
            return;
        }

        const saleIds = invoiceData.sales.map(sale => sale.id);
        const response = await this.entityManager.sales.fetchCollectionOnline({ id_in: saleIds.join(','), pagination: false });

        if(Array.isArray(response)) {
            this.historySalesService.createSummarySale(response, invoiceData.collected ? 'summary_e_rc' : 'summary_e_nrc')
        }
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents>={}) {
        if ("search" in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("export" in event) await this.gridDownloadDialogService.openDialog('history_sales',{data: this.tableData.map(tableData => ({...tableData,extraQueryParams:{status:'closed'},sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length}))},'sales')
            .then(res=>!!res&&this.onExport(res,'sales',{status:'closed'}));
        else if ("openToolPanel" in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
        else if ("type" in event && event.type === 'advFiltersSwitch') { this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);}
        else if ("type" in event && event.type === 'movColumnsSwitch') this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        else if ("save" in event) await this.gridRef.openDialogSaveColumnsPreference();
    }

//-------- NEW GENERIC TOOLBAR - END ----///

    rowClicked({data, node}: RowClickedEvent) {
        this.openDetail(data);
    }

    openDetail(data: Sales|undefined) {
        const portalInjector = Injector.create({
            providers: [{
                provide: RIGHT_SIDENAV_PORTAL_DATA_TOKEN,
                useValue: {sale: data, gridRef: this.gridRef}
            }],
        });
        this.rightSidenavService.componentPortal$.next(new ComponentPortal(HistorySaleDetailComponent, null, portalInjector));
        this.rightSidenavService.isOpen = true;
    }

    onSortChangedEmitter($event:SortChangedEvent) {
        this.rightSidenavService.isOpen = false;
    }

    onFilterChangedEmitter($event:FilterChangedEvent) {
        this.rightSidenavService.isOpen = false;
    }

    actions({action, data, node}: any) {
        switch (action) {
            case 'detail': {
                this.openDetail(data);
                this.gridRef.onRowOrExternalClicked(node);
                break;
            }
            case 'history-sale-credit-note-cancel': {
                this.historySalesService.refundVoid(data, this.gridRef);
                break;
            }
            case 'history-sale-credit-print-courtesy-receipt': {
                this.historySalesService.printCourtesyReceipt(data);
                break;
            }
            case 'duplicate-sale': {
                this.historySalesService.duplicateSale(data);
                break;
            }
            case 'history-sale-expense-report': {
                if(this.expenseReportConfig.template_id) {
                    this.historySalesService.createExpenseReport(data, this.expenseReportConfig.template_id, this.gridRef);
                }
                break;
            }
        }
    }

    @autobind
    onExport(dataExport: DataExport,tableToCall?:string,extraQueryParams?:Pick<Sales,'status'>) {
        this.gridRef?.onBtnExport(dataExport,tableToCall,extraQueryParams);
    }

    @autobind
    private async openBarcodeAndSearch() {
        const barcodeAsSaleID = await this.barcodeManagerService.openCameraBarcode();
        this.loadSaleFromId(barcodeAsSaleID);

    }

    onBarcodeScannedAppleMobile() {
        if (this.environmentInfoService.isAppleMobile()) {
            const barcodeInput = this.renderer.selectRootElement('#barcodeInput');
            barcodeInput.focus();
            barcodeInput.value = '';
        }
    }

    onBarcodeScanned = (barcode: string) => {
        if (document.querySelectorAll('.barcode-reader').length > 1) {
            return;
        }
        this.loadSaleFromId(barcode);
    }

    private async loadSaleFromId(saleId: string) {
        const targetID = parseInt(saleId) || 0;

        let targetSale: Sales | undefined;

        //Search the sale in the sales loaded inside the grid
        if(this.sales) {
            targetSale = this.sales.find(sale => sale.id === targetID);
        }

        //Search the sale via the API
        if(!targetSale) {
            targetSale = await this.entityManager.sales.fetchOneOnline(targetID);
        }

        //Open the sale if possible
        if (targetSale && (targetSale.status === 'closed' || this.utilService.isUserAdmin())) {
            this.openDetail(targetSale);
        } else {
            this.openDialogsService.openAlertDialog({data: {messageLabel: 'HISTORY.SALES.NOT_FOUND'}});
        }
    };
}
