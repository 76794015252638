import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').controller('TablesCtrl', ["$scope", "$state", "$location", "newTable", "addTable", "rooms", "user", function($scope, $state, $location, newTable, addTable, rooms, user) {
    $scope.parentobj = {};
    $scope.topbar_context = {};
    $scope.user = user;

    $scope.rooms = rooms;

    $scope.hasRooms = function() {
        return !_.isEmpty(rooms);
    };

    $scope.goToRoom = function(id) {
        $scope.parentobj.goToRoom(id);
    };

    $scope.showAddTableAction = function() {
        return !_.isEmpty($scope.rooms) && _.isEmpty($scope.topbar_context.selected_tables);
    };

    $scope.addTable = function() {
        newTable.show().then(function(tableData) {
            addTable.show(tableData, $scope.rooms[$scope.parentobj.currentTab]);
        });
    };

    $scope.$on("storage-updated:orders", function(event, data) {
        $state.reload('app.tables');
    });

    $scope.$on("storage-updated:rooms", function(event, data) {
        $state.reload('app.tables');
    });

    if ($location.$$path === "/rooms") {
        $state.go('app.tables.rooms-view'); //$location.path('/tables/rooms/view/');
    }
}]);
