import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DataFidelityMovementDetailsDialog} from "../dialog.model";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import { MovementTypeLabels} from "../../models";
import {FidelityMovementForm, FidelityMovementValidators} from "./fidelity_points-movement-form.model";
import {POST} from "../../features/customers/customers.model";
import {FidelityPointsGetPointsSignPipe, FidelityPointsGetTypePipe} from "../../pipes";
import {EntityManagerService} from "src/app/core/services/entity/entity-manager.service";
import {TilbyDatePipe} from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { ChainCampaigns } from 'tilby-models';

@Component({
    selector: 'app-fidelity_points-movement-details-dialog',
    templateUrl: './fidelity_points-movement-details-dialog.component.html',
    styleUrls: ['./fidelity_points-movement-details-dialog.component.scss'],
    providers: [OnDestroyService]

})
export class Fidelity_pointsMovementDetailsDialogComponent {
    private readonly onDestroyService= inject(OnDestroyService);
    private readonly dialogRef= inject(MatDialogRef<MagicPortalDialogComponent<Fidelity_pointsMovementDetailsDialogComponent>>);
    public readonly data: DataFidelityMovementDetailsDialog= inject(MAT_DIALOG_DATA);
    private readonly translate= inject(TranslateService);
    private readonly entityManager= inject(EntityManagerService);
    private readonly fidelityPointsGetTypePipe= inject(FidelityPointsGetTypePipe);
    private readonly fidelityPointsGetPointsSignPipe= inject(FidelityPointsGetPointsSignPipe);

    fidelityMovementForm?: FormGroup<FidelityMovementForm>;
    validators: FidelityMovementValidators = {
        notes: 9999
    };
    types: MovementTypeLabels = {
        load: this.translate.instant('FIDELITY.ADD_SHOW_MOVEMENT.LOAD'),
        unload: this.translate.instant('FIDELITY.ADD_SHOW_MOVEMENT.UNLOAD')
    };
    viewMode = !!this.data.movement?.id;
    addMode = !this.data.movement?.id;
    appearance: MatFormFieldAppearance | 'legacy' | 'none' = this.addMode ? 'legacy' : 'none';
    private campaigns?: ChainCampaigns[];

    constructor() {
        MagicPortalDialogComponent.confirm$.pipe(this.onDestroyService.takeUntilDestroy).subscribe(res=>!!res&&this.submit(this.fidelityMovementForm));
    }

    ngOnInit() {
        const {params: {campaigns = {} as ChainCampaigns[]} = {}} = this.data;
        if (this.addMode) this.setCampaigns(campaigns);
        this.createForm();
    }

    private setCampaigns(campaigns: ChainCampaigns[]) {
        if (campaigns && campaigns.length) {
            this.campaigns = campaigns;
        } else {
            this.entityManager.campaigns.fetchCollectionOnline().then(result => {
                if(Array.isArray(result)) {
                    this.campaigns = result;
                }
            });
        }
    }

    createForm() {
        const {movement: m = null, params: p = null} = this.data;
        this.fidelityMovementForm = new FormGroup<FidelityMovementForm>({
            date: new FormControl(this.addMode ? TilbyDatePipe.date() : TilbyDatePipe.date({date:m?.date || ''}) || null, [Validators.required]),
            type: new FormControl(this.addMode ? null : this.fidelityPointsGetTypePipe.transform(m?.points || 0) || null, [Validators.required]),
            shop_name: new FormControl(m?.shop_name || m?.db_name || p?.currentShopChain?.shop_name || null, []),
            sale_name: new FormControl(m?.sale_name || null, [Validators.required]),
            campaign: new FormControl(p?.selectedCampaign || null, [Validators.required]),
            campaign_name: new FormControl(m?.campaign_name || null, [Validators.required]),
            fidelity: new FormControl(p?.customer?.fidelity || null, [Validators.required]),
            db_name: new FormControl(p?.currentShopChain?.db_name || null),
            points: new FormControl(Math.abs(m?.points || 0) || null, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]),
            rule_name: new FormControl(m?.rule_name || null, [Validators.required]),
            item_name: new FormControl(m?.item_name || null, [Validators.required]),
            item_sku: new FormControl(m?.item_sku || null, [Validators.required]),
            notes: new FormControl(m?.notes || null, [Validators.maxLength(this.validators.notes)])

        });

        this.shop_name?.disable();
        this.sale_name?.disable();
        this.campaign_name?.disable();
        this.rule_name?.disable();
        this.item_name?.disable();
        this.item_sku?.disable();

        if (this.viewMode) {
            this.fidelityMovementForm.disable();
        }

        MagicPortalDialogComponent.setStatusValidity(this.fidelityMovementForm.status);
        this.fidelityMovementForm.statusChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe(status=>MagicPortalDialogComponent.setStatusValidity(status));

    }

    submit(movementForm?: FormGroup<FidelityMovementForm>) {
        const {fidelity, type, campaign, db_name, date, points, notes} = movementForm?.value || {};
        const movementToPost: POST.FidelityMovement = {
            campaign_id: campaign?.id,
            campaign_name: campaign?.name,
            date: date ? TilbyDatePipe.date({date}) : null,
            db_name: db_name || '',
            points: (points || 0) * this.fidelityPointsGetPointsSignPipe.transform(type!),
            shop_name: this.shop_name?.value || '',
            type: type!,
            notes,
            fidelity
        };
        this.dialogRef.close(movementToPost);
    }

    get date() {
        return this.fidelityMovementForm?.controls.date;
    }

    get type() {
        return this.fidelityMovementForm?.controls.type;
    }

    get shop_name() {
        return this.fidelityMovementForm?.controls.shop_name;
    }

    get sale_name() {
        return this.fidelityMovementForm?.controls.sale_name;
    }

    get campaign_name() {
        return this.fidelityMovementForm?.controls.campaign_name;
    }

    get rule_name() {
        return this.fidelityMovementForm?.controls.rule_name;
    }

    get item_name() {
        return this.fidelityMovementForm?.controls.item_name;
    }

    get item_sku() {
        return this.fidelityMovementForm?.controls.item_sku;
    }

    get notes() {
        return this.fidelityMovementForm?.controls.notes;
    }

}
