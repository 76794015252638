import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').factory('ExternalSalesManager', ExternalSalesManager);

ExternalSalesManager.$inject = ["$rootScope", "$translate", "checkManager", "entityManager", "$state", "toast"];

function ExternalSalesManager($rootScope, $translate, checkManager, entityManager, $state, toast) {
    var externalSalesFilter = function(sale) {
        return sale.seller_id === 0;
    };

    var moduleData = {
        count: 0
    };

    var salesUpdateNotifier = function(event, data) {
        if(checkManager.isModuleAngular('tables_and_cashregister')) {
            return;
        }

        entityManager.sales.fetchCollectionOffline({ status: 'open' }).then(function(sales) {
            moduleData.count = sales ? _.filter(sales, externalSalesFilter).length : 0;

            var message = '';
            var waitingCheckout = _.filter(sales, function(sale) {
                return (!_.isNil(sale.order_id) && _.isNil(sale.assigned_id));
            });

            var justSentCheckout = _.some(waitingCheckout, { id: data.id });

            if (justSentCheckout) {
                var count = waitingCheckout.length;

                if (count > 1) {
                    message = count + $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.ASK_RECEIPT');
                } else if (count === 1) {
                    message = count + $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.ASK_RECEIPT');
                }

                toast.show({
                    message: message,
                    actions: [
                        { text: $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.CANCEL'), action: 'cancel', highlight: false },
                        { text: $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.OPEN'), action: 'open', highlight: true }
                    ],
                    hideDelay: 0
                }).then(function(action) {
                    if (action === 'open') {
                        $state.go('app.cashregister.content.showcase', {
                            action: 'open-saved-sale'
                        }, {
                            inherit: false
                        });
                    }
                });
            } else {
                if(data.externalClient && !checkManager.getPreference("cashregister.disable_external_sales_notification")) {
                    var sale = _.find(sales, { id: data.id });

                    if(!externalSalesManager.isExternalSalesPrintEnabled() || _.get(sale, 'seller_id') !== 0) {
                        switch(data.action) {
                            case "CREATED":
                                message = $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.NEW_SALE_ARRIVED');
                                break;
                            case "UPDATED":
                                message = $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.SALE_UPDATED');
                                break;
                            default:
                                break;
                        }

                        if(message && !$state.current.name.includes('kiosk')) {
                            toast.show({
                                message: message,
                                actions: [
                                    { text: $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.HIDE'), action: 'cancel', highlight: false },
                                    { text: $translate.instant('CASHREGISTER.EXTERNAL_SALES_MANAGER.OPEN'), action: 'open', highlight: true }
                                ],
                                hideDelay: 0
                            }).then(function(action) {
                                if (action === 'open') {
                                    $state.go('app.cashregister.sale', { id: data.id }, { inherit: false });
                                }
                            });
                        }
                    }
                }
            }
        });
    };

    var updateExternalSalesCount = function() {
        entityManager.sales.fetchCollectionOffline({ status: 'open' }).then(function(sales) {
            moduleData.count = sales ? _.filter(sales, externalSalesFilter).length : 0;
        });
    };

    const externalSalesManager = {
        init: function() {
            if(checkManager.isModuleEnabled('cashregister')) {
                updateExternalSalesCount();
                $rootScope.$on("entity-updated:sales", salesUpdateNotifier);
                $rootScope.$on("storage-updated:sales", updateExternalSalesCount);
            }
        },
        isExternalSalesPrintEnabled: function() {
            return window.localStorage.getItem('deviceSettings::printExternalSales') === 'true';
        },
        getExternalSalesCount: function() {
            return moduleData.count;
        },
        getExternalSalesFilter: function() {
            return externalSalesFilter;
        },
        isExternal: function(sale) {
            return externalSalesFilter(sale);
        }
    };

    return externalSalesManager;
}
