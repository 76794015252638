import {
    computed,
    inject,
    Injectable,
    Injector,
    signal
} from "@angular/core";
import { DevLogger } from "src/app/shared/dev-logger";
import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    KeyValueIconImage
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CashregisterStockFormFields } from "../models/cashregister-stock-model";
import { TranslateService } from "@ngx-translate/core";
import {
    CASHREGISTER_AVAILABILITY,
    CASHREGISTER_CATEGORIES,
    CASHREGISTER_ITEMS,
    CASHREGISTER_ORDER,
    CASHREGISTER_SEARCH_IN_ALL_CATEGORIES,
    CASHREGISTER_VIEW
} from "src/app/core/constants/cashregister-settings";
import {
    ConfigurationManagerService,
    EntityManagerService,
    FilterStartingWith,
    ScreenOrientationService,
    ThemeModeService
} from "src/app/core";
import {ItemsByCategory, StockDictionary} from "../cashregister.component";
import {
    Categories,
    Channels,
    Customers,
    Departments,
    Items,
    SalesCustomer
} from "tilby-models";
import {BehaviorSubject, distinctUntilChanged, Subject, takeUntil} from "rxjs";
import {Exit} from "@tilby/tilby-ui-lib/components/tilby-order";
import { isTablet, mobileCheck } from "@tilby/tilby-ui-lib/utilities";
import { ActiveSaleStoreService } from "./active-sale.store.service";
import {
    $state,
    addSelectCustomerDialog,
    eInvoicePlugin,
    radioListSelector,
    util
} from "app/ajs-upgraded-providers";
import { KeyValue } from "@angular/common";
import { ActiveSaleService } from "./active-sale.service";
import { DocumentPrintersManagerDialogStateService, OpenDialogsService } from "src/app/dialogs";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { parseItemsCollection } from "../utils";

export type CashregisterTopbarSettingsFields = {
    categories: {category: string, category_grid_rows: number},
    items: {item: string, item_grid_columns: number},
    view: {view: string},
    order: {order: string},
    availability: {availability: string},
    search_in_all_category: {searchCategory: string},
    show_stock_level: {showStock: boolean}
}

type Address = Partial<{ [prop in FilterStartingWith<keyof SalesCustomer, "shipping_">]: SalesCustomer[prop] | null } & Record<'shipping_address_id', number | null>>;

export type CashRegisterStockFormFieldsValue =
    {[prop in keyof Omit<CashregisterStockFormFields,'url'>]:CashregisterStockFormFields[prop]}
    &{[prop in keyof Pick<CashregisterStockFormFields,'url'>]?:CashregisterStockFormFields[prop]}
    &{ shop_id: string };

const DEFAULT_TOTAL_EXITS_NUMBER = 5;

export type CategoryView = 'tab' | 'grid';
export type ItemView = 'photoGrid' | 'noPhotoGrid' | 'list';
export type View = 'normal' | 'compact';
export type Order = '+name' | '+index' | '+price' | '-price';

export type EventsSettings =
  | { category: CategoryView }
  | { item: ItemView }
  | { view: View }
  | { order: Order }
  | { availability: boolean }
  | { search_in_all_categories: boolean }
  | { show_stock_quantity: boolean }
  | { category_grid_rows: number }
  | { item_grid_columns: number }

@Injectable({
    providedIn: 'root'
})
export class CashregisterStateService {
    private readonly injector = inject(Injector);
    private readonly onDestroy$=new Subject<void>();
    private readonly translateService = inject(TranslateService);
    private readonly configurationManagerService= inject(ConfigurationManagerService);
    private readonly screenOrientationService= inject(ScreenOrientationService);
    private readonly activeSaleStoreService = inject(ActiveSaleStoreService);
    private readonly themeModeService=inject(ThemeModeService);
    private readonly stateService=inject($state);

    // CHECK MOBILE AND VERTICAL PORTRAIT
    public keyboardTotalValue= signal(0);
    private getIsMobilePotrait(orientation = this.screenOrientationService.getOrientation()) {
        return mobileCheck() && (orientation === 'portrait-primary' || orientation === 'portrait-secondary');
    }
    public isMobilePortrait = computed(() => this.getIsMobilePotrait(this.screenOrientationService.getOrientation()));
    public isMobile = computed(() => this.screenOrientationService.getOrientation() && mobileCheck());
    private cashregisterStockForm!:CustomFormGroup<CustomForm<any>>;
    private cashregisterTopbarSettingsForm!:CustomFormGroup<CustomForm<CashregisterTopbarSettingsFields>>;

    public deliveryChannels: Channels[]=[];
    public categories: Categories[]=[];
    public departments: Departments[]=[];
    public stockDictionary?: StockDictionary;
    public items?: ItemsByCategory;

    public exitsArray$ = new BehaviorSubject<Exit[]>([]);
    private totalExitsNumber$=new BehaviorSubject<number>(DEFAULT_TOTAL_EXITS_NUMBER);
    public lastUuidChanged$ = new BehaviorSubject<string|undefined>(undefined);
    public lastUuidSelected$ = new BehaviorSubject<string|undefined>(undefined);
    public resizeSplitView$ = new BehaviorSubject<number>(50);
    public addItemToSale$ = new Subject<Items|undefined>();
    public addNewItemToSale$ = new Subject<void>();
    public addNewItemToItems$ = new Subject<void>();

    public sideKeyboardPreference = signal(this.configurationManagerService.getPreference("cashregister.side_keyboard")||false);
    public sideKeyboard = computed(()=>this.sideKeyboardPreference() && this.screenOrientationService.getOrientation() && !mobileCheck());

    eventsSettings = new Subject<EventsSettings>();
    priceList = signal<number>(1, { equal: (a: number, b: number) => a === b });
    pricelistChangedEvent = new Subject<void>();

    // CASHREGISTER SEARCH
    public isShowSearch$ = new BehaviorSubject<{isSearch:boolean,searchValue:string}>({isSearch:false,searchValue:''});
    public keyboardIsOpen$ = new BehaviorSubject<boolean>(window.localStorage.getItem('cashregister::showKeyboard') === 'true');
    public keyboardIsOpenBySelection$ = new BehaviorSubject<boolean>(false);

    public isTablet=isTablet;
    public ticketViewActive = signal(false);

    public static notifyStockSubject = new Subject<any>();
    public static notifyStock$ = CashregisterStateService.notifyStockSubject.asObservable();

    public itemVisibleReorder?: Items[];
    public favoriteSelectedReorder = false;

    constructor(
    ) {
        ActiveSaleStoreService.saleUpdates$.subscribe(() => {
            const updatedExits = this.updateSentExits(this.exitsArray$.getValue());
            this.exitsArray$.next(updatedExits);
        });
    }

    private log(...args:any[]){
        DevLogger.log('CASHREGISTER_STATE_SERVICE',...args);
    }

    initState(param: { deliveryChannels: Channels[]; categories: Categories[]; departments: Departments[]; stockDictionary: StockDictionary; items: ItemsByCategory }) {
        this.deliveryChannels = param.deliveryChannels;
        this.categories = param.categories;
        this.departments = param.departments;
        this.stockDictionary = param.stockDictionary;
        this.items = param.items;

        this.totalExitsNumber$.next(+((this.configurationManagerService.getPreference('orders.exits')) || DEFAULT_TOTAL_EXITS_NUMBER));

        const exits = this.exitArrayCreation(this.totalExitsNumber$.getValue());
        this.exitsArray$.next(this.updateSentExits(exits));
    }

    // START - TILBY_SHOP_CART
    private exitArrayCreation(totalExits: number): Exit[] {
        const exitArray: Exit[] = [];

        for (let i = 0; i <= totalExits; i++) {
            exitArray.push({
                name: i ? 'DIALOGS.EXIT_DIALOG.EXIT' : 'DIALOGS.EXIT_DIALOG.NO_EXIT_LABEL',
                nameValue: i ? i : undefined,
                isSelected: !i
            });
        }

        return exitArray;
    }
    public resetExitArray(){
        this.exitsArray$.next(this.updateSentExits(this.exitArrayCreation(this.totalExitsNumber$.getValue())));
    }

    private updateSentExits(exits: Exit[]): Exit[] {
        const sentExits = this.activeSaleStoreService.currentSale?.sent_exits || {};

        return exits.map(exit => {
            if (!exit.nameValue) {
                return exit;
            }

            return {
                ...exit,
                status: sentExits[exit.nameValue] ? 'sent' : undefined
            }
        });
    }
    // END - TILBY_SHOP_CART

    // START - FORM CREATIONS

    // FORM CASHREGISTER_STOCK_FORM
    createCashregisterStockForm(cashregisterStock:Partial<CashregisterStockFormFields> = new CashregisterStockFormFields(), hiddenFields?:Array<keyof CashregisterStockFormFields>) {
        const {sku,name,url,code,barcode}=cashregisterStock;
        let isCheckExternalStockEnabled = this.configurationManagerService.getPreference('cashregister.check_external_stock');

        this.cashregisterStockForm = new CustomFormGroup<CustomForm<CashRegisterStockFormFieldsValue>>({
            ...(isCheckExternalStockEnabled && {url: new CustomFormControl('I' || null,{},{...new CustomFormControlProps(),inputType:'radio',label:'', inputChoices:[{value:"I",key:'CASHREGISTER.CHECK_STOCK.INTERNAL'},{value:"E",key:'CASHREGISTER.CHECK_STOCK.EXTERNAL'}],class:'tw-w-full tw-flex-none tw-py-2.5 tw-flex-nowrap tw-gap-x-12'})}),
            shop_id: new CustomFormControl('' || null, {}, {...new CustomFormControlProps(), label:'CASHREGISTER.CHECK_STOCK.SHOP_ID', id:'input-shop_id'}),
            sku: new CustomFormControl(sku || null, {}, {...new CustomFormControlProps(), label:'CASHREGISTER.CHECK_STOCK.SKU', id:'input-sku'}),
            barcode: new CustomFormControl(barcode || null, {}, {...new CustomFormControlProps(), label:'CASHREGISTER.CHECK_STOCK.BARCODE', id:'input-barcode', focusInitial: true}),
            code: new CustomFormControl(code || null, {}, {...new CustomFormControlProps(), label:'CASHREGISTER.CHECK_STOCK.CODE', id:'input-code'}),
            name: new CustomFormControl(name || null, {}, {...new CustomFormControlProps(), label:'CASHREGISTER.CHECK_STOCK.NAME', id:'input-name'}),
        });

        return this.cashregisterStockForm;
    }

    get url() {return this.cashregisterStockForm.controls.url; }
    get shop_id() {return this.cashregisterStockForm.controls.shop_id; }
    get sku() {return this.cashregisterStockForm.controls.sku; }
    get barcode() {return this.cashregisterStockForm.controls.barcode; }
    get code() {return this.cashregisterStockForm.controls.code; }
    get name() {return this.cashregisterStockForm?.controls.name; }

    private getImageName(name: string) {
        return this.themeModeService.isDarkTheme ? name.replace(".svg", "-dark.svg") : name;
    }

    // FORM CASHREGISTER_TOPBAR_SETTINGS_FORM
    createCashregisterTopbarSettingsForm() {
        const categoryView = this.configurationManagerService.getPreference("showcase_category_view") || 'tab'; // 'tab' | 'grid'
        const categoryGridRows = parseInt(this.configurationManagerService.getPreference("showcase_category_grid_rows") || "3", 10);
        const showcaseMode = this.configurationManagerService.getPreference("showcase_mode") || 'photoGrid'; //photoGrid noPhotoGrid list
        const itemGridColumns = parseInt(this.configurationManagerService.getPreference("showcase_item_grid_columns") || "-1", 10);
        const displayMode = this.configurationManagerService.getPreference("showcase_display_mode") || 'normal';
        const orderBy = this.configurationManagerService.getPreference("showcase_order_by") || '+name';
        const showUnavailable = this.configurationManagerService.getPreference("showcase_show_unavailable") === false ? false : true;
        const searchInAllCategories = !Boolean(this.configurationManagerService.getPreference("search_in_all_categories")) ? false : true;
        const showStockQuantity = !Boolean(this.configurationManagerService.getPreference("cashregister.show_stock_quantity_on_showcase")) ? false : true;

        const categoriesChoises: KeyValueIconImage<string>[] = CASHREGISTER_CATEGORIES.map((categories, _i) => ({
            key: `CASHREGISTER.TOPBAR.SETTINGS.CATEGORIES.${categories.name.toUpperCase()}`,
            value: categories.id,
            image: `assets/images/cashregister/settings/categories/${this.getImageName(categories.imageUrl)}`
        }))

        const itemsChoises: KeyValueIconImage<string>[] = CASHREGISTER_ITEMS.map((items, _i) => ({
            key: `CASHREGISTER.TOPBAR.SETTINGS.ITEMS.${items.name.toUpperCase()}`,
            value: items.id,
            image: `assets/images/cashregister/settings/items/${this.getImageName(items.imageUrl)}`
        }))

        const viewChoises: KeyValueIconImage<string>[] = CASHREGISTER_VIEW.map((view, _i) => ({
            key: `CASHREGISTER.TOPBAR.SETTINGS.VIEW.${view.name.toUpperCase()}`,
            value: view.id,
            image: `assets/images/cashregister/settings/view/${this.getImageName(view.imageUrl)}`
        }))

        const orderChoises: KeyValueIconImage<string>[] = CASHREGISTER_ORDER.map((order, _i) => ({
            key: `CASHREGISTER.TOPBAR.SETTINGS.ORDER.${order.name.toUpperCase()}`,
            value: order.id
        }))

        const availabilityChoises: KeyValueIconImage<string>[] = CASHREGISTER_AVAILABILITY.map((availability, _i) => ({
            key: `CASHREGISTER.TOPBAR.SETTINGS.AVAILABILITY.${availability.name.toUpperCase()}`,
            value: availability.id,
        }))

        const searchCategoriesChoises: KeyValueIconImage<string>[] = CASHREGISTER_SEARCH_IN_ALL_CATEGORIES.map((searchCategories, _i) => ({
            key: `CASHREGISTER.TOPBAR.SETTINGS.SEARCH_CATEGORIES.${searchCategories.name.toUpperCase()}`,
            value: searchCategories.id + '',
        }))

        const categoryGridRowsOptions: KeyValue<string, number>[] = [
            ...Array.from({ length: 6 }, (_, i) => ({
                key: i+1+'',
                value: i+1
            }))
        ];

        const itemGridColumnsOptions: KeyValue<string, number>[] = [
            {
                key: 'CASHREGISTER.TOPBAR.SETTINGS.ITEMS.COLUMNS_SELECTED_DEFAULT',
                value: -1,
            },
            ...Array.from({ length: 4 }, (_, i) => ({
                key: i+3+'',
                value: i+3
            }))
        ];

        this.cashregisterTopbarSettingsForm = new CustomFormGroup<CustomForm<CashregisterTopbarSettingsFields>>({
            categories: new CustomFormGroup<CustomForm<{category: string, category_grid_rows: number}>>({
                category: new CustomFormControl(categoryView, {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.CATEGORIES.TITLE',
                    inputType: 'radio',
                    class: `magic-radio-list magic-radio-list__with-image tw-m-0`,
                    inputChoices: categoriesChoises
                }),
                category_grid_rows: new CustomFormControl(categoryGridRows, {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.CATEGORIES.ROWS_SELECTED',
                    inputType: 'select',
                    class: 'tw-max-w-[12rem] p-[5px] tw-px-3',
                    inputChoices: categoryGridRowsOptions
                }),
            }),
            items: new CustomFormGroup<CustomForm<{item: string, item_grid_columns: number}>>({
                item: new CustomFormControl(showcaseMode, {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.ITEMS.TITLE',
                    inputType: 'radio',
                    class: 'magic-radio-list magic-radio-list__with-image tw-border-0 tw-border-t tw-border-solid tw-border-gray-300 tw-m-0',
                    inputChoices: itemsChoises
                }),
                item_grid_columns: new CustomFormControl(itemGridColumns, {}, {
                    ...new CustomFormControlProps(),
                    label: `CASHREGISTER.TOPBAR.SETTINGS.ITEMS.COLUMNS_SELECTED`,
                    inputType: 'select',
                    class: 'tw-max-w-[12rem] p-[5px] tw-px-3',
                    inputChoices: itemGridColumnsOptions
                }),
            }),
            view: new CustomFormGroup<CustomForm<{view: string}>>({
                view: new CustomFormControl(displayMode, {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.VIEW.TITLE',
                    inputType: 'radio',
                    class: 'magic-radio-list magic-radio-list__with-image tw-border-0 tw-border-b tw-border-t tw-border-solid tw-border-gray-300 tw-m-0',
                    inputChoices: viewChoises
                })
            }),
            order: new CustomFormGroup<CustomForm<{order: string}>>({
                order: new CustomFormControl(orderBy, {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.ORDER.TITLE',
                    inputType: 'radio',
                    class: 'magic-radio-list tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-m-0',
                    inputChoices: orderChoises,
                    id: 'item-order-id'
                })
            }),
            availability: new CustomFormGroup<CustomForm<{availability: string}>>({
                availability: new CustomFormControl(String(!!showUnavailable), {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.AVAILABILITY.TITLE',
                    inputType: 'radio',
                    class: 'magic-radio-list tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-m-0 ',
                    inputChoices: availabilityChoises
                })
            }),
            search_in_all_category: new CustomFormGroup<CustomForm<{searchCategory: string}>>({
                searchCategory: new CustomFormControl(String(!!searchInAllCategories), {}, {
                    ...new CustomFormControlProps(),
                    label: 'CASHREGISTER.TOPBAR.SETTINGS.SEARCH_CATEGORIES.TITLE',
                    inputType: 'radio',
                    class: 'magic-radio-list tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-m-0 ',
                    inputChoices: searchCategoriesChoises
                })
            }),
            show_stock_level: new CustomFormGroup<CustomForm<{showStock: boolean}>>({
                showStock: new CustomFormControl(!!showStockQuantity, {}, {
                        ...new CustomFormControlProps(),
                        hint: () => ``,
                        label: 'CASHREGISTER.TOPBAR.SETTINGS.SHOW_STOCK_LEVEL.TITLE',
                        inputType: 'checkbox',
                        class: 'tw-w-full tw-mb-2 tw-pl-4 tw-pt-3 tw-text-[12px]'
                })
            })
        });

        const changesMobilePortrait = () => {
            if (this.isMobilePortrait()) {
                this.category.customProps.inputType = 'hidden';
                this.item.customProps.inputType = 'hidden';
                this.view.customProps.inputType = 'hidden';
            }
        }

        const changesFunction = (value : string) => {
            this.category_grid_rows.customProps.inputType = value === 'grid' ? 'select' : 'hidden';
        }

        const changesItemsGridColumns = (value : string) => {
            switch (value) {
                case 'photoGrid':
                case 'noPhotoGrid':
                    this.item_grid_columns.customProps.inputType = 'select';
                break;
                default:
                    this.item_grid_columns.customProps.inputType = 'hidden';
                break;
            }
        }

        this.category?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((value: string) => {
            changesFunction(value);
        });

        this.item?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((value: string) => {
            changesItemsGridColumns(value);
        });

        changesMobilePortrait();
        changesFunction(categoryView);
        changesItemsGridColumns(showcaseMode);

        return this.cashregisterTopbarSettingsForm;

    }

    get category() {
        return this.cashregisterTopbarSettingsForm?.controls.categories.controls.category;
    }

    get category_grid_rows() {
        return this.cashregisterTopbarSettingsForm?.controls.categories.controls.category_grid_rows;
    }

    get item() {
        return this.cashregisterTopbarSettingsForm?.controls.items.controls.item;
    };

    get item_grid_columns() {
        return this.cashregisterTopbarSettingsForm?.controls.items.controls.item_grid_columns;
    }

    get view() {
        return this.cashregisterTopbarSettingsForm?.controls.view.controls.view;
    };

    public setExit(exitSelected: Exit | undefined) {
        const newValue = this.exitsArray$.getValue().map(exit => ({ ...exit, isSelected: exit.name == exitSelected?.name && exit.nameValue == exitSelected?.nameValue }))
        this.log('EXIT_CHANGED', newValue);
        this.exitsArray$.next(newValue);
    }

    // END - FORM CREATIONS

    // START - MOBILE
    public setActionBarHeight(screenOrientation:OrientationType='portrait-primary',keyboardOffset=0){
        const actionBar=106;
        const topbar= this.isTablet?64:56;
        const margin = screenOrientation.startsWith('portrait')?4:16;
        return ((actionBar+margin+keyboardOffset)/(screen.height-topbar))*100;
    }
    public back() {
        if(this.ticketViewActive()){
            this.ticketViewActive.set(false);
        }
        else {
            this.stateService.go('app.new.cashregister.content.showcase',{},{inherit: false});
        }
    }
    // END - MOBILE

    public async addCustomer(customer: Customers | SalesCustomer) {
        const radioListSelectorService = this.injector.get(radioListSelector);
        const activeSaleService = this.injector.get(ActiveSaleService);
        const openDialogsService = this.injector.get(OpenDialogsService);
        const entityManagerService = this.injector.get(EntityManagerService);

        const saleCustomer = await activeSaleService.addCustomer(customer);

        //Show customer notes if present
        if (customer.notes) {
            try {
                await openDialogsService.openAlertDialog({ data: { messageLabel: customer.notes } });
            } catch (err) { }
        }

        //Ask to enable invoices if the customer has a valid billing address and the current printer supports them
        if (!this.configurationManagerService.getPreference('cashregister.disable_customer_invoice_prompt') && this.injector.get(eInvoicePlugin).isCustomerValid(saleCustomer)) {
            let currentDocData = activeSaleService.printerDocumentData;

            if ((typeof currentDocData == 'object') && !['e_invoice', 'summary_e_rc', 'summary_e_nrc', 'generic_invoice'].includes(currentDocData?.document_type?.id || '')) {
                try {
                    const docData = await this.injector.get(DocumentPrintersManagerDialogStateService).getPrinterDocumentData(currentDocData?.printer?.id || 'default', ['e_invoice', 'generic_invoice'], currentDocData?.options);
                    const answer = await openDialogsService.openConfirmDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE_MODEL.WANT_TO_ENABLE_INVOICE', confirmLabel: 'DIALOG.CONFIRM.YES', cancelLabel: 'DIALOG.CONFIRM.NO' } });

                    if (answer) {
                        activeSaleService.setPrinterDocumentData(docData);
                    }
                } catch (err) { }
            }
        }

        //Check if the customer has multiple shipping addresses and, if that's the case, ask which one is to use
        if (this.configurationManagerService.getPreference('cashregister.ask_shipping_address')) {
            let shippingAddressesCount = 0;
            const shippingAddresses: {
                address: Address;
                name: string;
                idx?: number;
                $disabled?: boolean;
            }[] = [{
                address: {
                    shipping_prov: null,
                    shipping_street: null,
                    shipping_number: null,
                    shipping_zip: null,
                    shipping_city: null,
                    shipping_country: null,
                    shipping_address_id: null
                },
                name: this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_DELIVERY'),
                idx: -1
            }];

            for (let idx = 0; idx < 10; idx++) {
                const slot = idx === 0 ? "" : "_" + (idx);

                if (saleCustomer[`shipping_street${slot}` as keyof typeof saleCustomer]) {
                    shippingAddressesCount++;

                    if (idx === 0) { //Set shipping_address_id to 0 in case the user cancels the selection afterwards
                        saleCustomer.shipping_address_id = 0;
                    }

                    const addressObj: Address = {
                        shipping_prov: saleCustomer[`shipping_prov${slot}` as keyof Pick<SalesCustomer, "shipping_prov">],
                        shipping_street: saleCustomer[`shipping_street${slot}` as keyof Pick<SalesCustomer, "shipping_street">],
                        shipping_number: saleCustomer[`shipping_number_${slot}` as keyof Pick<SalesCustomer, "shipping_number">],
                        shipping_zip: saleCustomer[`shipping_zip${slot}` as keyof Pick<SalesCustomer, "shipping_zip">],
                        shipping_city: saleCustomer[`shipping_city${slot}` as keyof Pick<SalesCustomer, "shipping_city">],
                        shipping_country: saleCustomer[`shipping_country${slot}` as keyof Pick<SalesCustomer, "shipping_country">],
                        shipping_address_id: idx
                    };

                    if (idx) {
                        delete saleCustomer[`shipping_prov${slot}` as keyof Pick<SalesCustomer, "shipping_prov">];
                        delete saleCustomer[`shipping_street${slot}` as keyof Pick<SalesCustomer, "shipping_street">];
                        delete saleCustomer[`shipping_number${slot}` as keyof Pick<SalesCustomer, "shipping_number">];
                        delete saleCustomer[`shipping_zip${slot}` as keyof Pick<SalesCustomer, "shipping_zip">];
                        delete saleCustomer[`shipping_city${slot}` as keyof Pick<SalesCustomer, "shipping_city">];
                        delete saleCustomer[`shipping_country${slot}` as keyof Pick<SalesCustomer, "shipping_country">];
                    }

                    let addressStr = addressObj.shipping_street + " " + (addressObj.shipping_number || "") + " " + (addressObj.shipping_zip || "") + " " + (addressObj.shipping_city || "") + " " + (addressObj.shipping_prov ? "(" + addressObj.shipping_prov + ")" : "") + " ";
                    const isDisabled = this.configurationManagerService.getPreference('orders.allow_street_only_shipping_addresses') ? !addressObj.shipping_street : !addressObj.shipping_street || !addressObj.shipping_city;

                    if (isDisabled) {
                        addressStr += " (Incompleto)";
                    }

                    shippingAddresses.push({
                        address: addressObj,
                        name: addressStr,
                        $disabled: isDisabled
                    });
                }
            }

            if (shippingAddressesCount >= 1) {
                const addAction = {
                    callback: () => {
                        this.injector.get(addSelectCustomerDialog).show(activeSaleService.currentSale.sale_customer, { editMode: true })
                            .then((customer: Customers) => this.addCustomer(customer));
                    }
                };

                const sAddr = await radioListSelectorService.show(shippingAddresses, { label: this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SELECT_SHIPPING_ADDRESS'), default: 0, addAction: addAction, hideCancel: true });
                Object.assign(saleCustomer, sAddr.address);
            }
        }

        if (saleCustomer.fidelity) {
            const campaigns = await entityManagerService.campaigns.fetchCollectionOffline({ isValid: true });

            if (campaigns.length) {
                const activeCampaign = campaigns[0];

                const customerPointsArray = await entityManagerService.fidelitiesPoints.fetchCollectionOffline({ fidelity: saleCustomer.fidelity, campaign_id: activeCampaign?.id });

                if (customerPointsArray?.length) {
                    const customerPoints = customerPointsArray[0];

                    const prizes = await entityManagerService.prizes.fetchCollectionOffline({ campaign_id: activeCampaign.id });
                    const now = TilbyDatePipe.date();

                    const giftPrizes = prizes.filter((prize) => prize.type === 'gift');
                    const giftsMap: Partial<Record<string, Items>> = {};

                    //Load prize items
                    for (let giftPrize of giftPrizes) {
                        const items = await entityManagerService.items.fetchCollectionOffline({ sku: giftPrize.item_sku });

                        if (items.length) {
                            giftsMap[giftPrize.item_sku || ''] = items[0];
                        }
                    }

                    const eligiblePrizes = prizes.filter((prize) => (((customerPoints.points || 0) >= prize.points) && (!prize.valid_from || TilbyDatePipe.compareDates(prize.valid_from.toString(), now) !== '>' && (!prize.valid_to || TilbyDatePipe.compareDates(prize.valid_to.toString(), now) !== '<'))));

                    if (eligiblePrizes?.length) {
                        const radioListPrizes = eligiblePrizes.map((prize) => {
                            const prizeName = [
                                this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.PRIZE_POINTS', { points: prize.points }),
                                prize.name
                            ];

                            switch (prize.type) {
                                case 'discount_fix':
                                    prizeName.push(this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: this.injector.get(TilbyCurrencyPipe).transform(prize.discount_amount || 0) }));
                                    break;
                                case 'discount_perc':
                                    prizeName.push(this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: this.injector.get(util).round(prize.discount_amount) + '%' }));
                                    break;
                                case 'gift':
                                    prizeName.push(this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.GIFT', { value: giftsMap?.[prize.item_sku || '']?.name ?? '' }));
                                    break;
                                default: break;
                            }

                            return {
                                name: prizeName.join(' - '),
                                prize: prize
                            };
                        });

                        try {
                            const selectedOption = await radioListSelectorService.show(radioListPrizes, { label: this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.TITLE', { points: customerPoints.points }) });
                            await activeSaleService.addPrize(selectedOption.prize);
                        } catch (err) { }
                    }
                }
            }
        }

        if (saleCustomer.default_pricelist) {
            ActiveSaleService.activeSaleEvents$.next({ event: 'use-pricelist', data: { priceList: saleCustomer.default_pricelist } });
        }
    }

    public async getItems() {
        this.items = await this.injector.get(EntityManagerService).items.fetchCollectionOffline({on_sale: true}).then(items=>parseItemsCollection(items));
        return this.items;
    }

    setItemVisibleReorder(itemVisibleReorder: Items[]) {
        this.itemVisibleReorder = itemVisibleReorder;
    }

    setFavoriteSelectedReorder(favoriteSelectedReorder: boolean) {
        this.favoriteSelectedReorder = favoriteSelectedReorder;
    }
}
