<ng-container>
    <app-grid-client-side
        #appGridComponent
        [tableData]="tableData[0]"
        [fixedButtons]="fixedButtons"
        [idField]="'token_id'"
        (deleteChange)="deleteTokenStatic($event)"
        (clickableButton)="crud($event)"
        feature="settings_um_static_token"
    ></app-grid-client-side>
</ng-container>
