import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('settings').factory('authRequestDialog', ["$mdDialog", "$translate", "restManager", function($mdDialog, $translate, restManager) {
    authRequestDialogController.$inject = ["$scope", "$mdDialog", "options"];
    
    function authRequestDialogController($scope, $mdDialog, options) {
        var requestCode = "";

        _.assign($scope, {
            invalidSmsCode: false,
            loading: false,
            mode: 'request-auth',
            phone: options.billingData.phone_number,
            smsToken: null
        });

        var makeRandom = function(length) {
            return  _(Array(length))
                .map(function() {
                    return _.toString(_.random(0, 9));
                })
            .join('');
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.confirm = function() {
            if($scope.smsToken === requestCode) {
                $mdDialog.hide(true);
            } else {
                $scope.invalidSmsCode = true;
            }
        };

        $scope.requestSMS = function() {
            var spaces = _.repeat(' ', _.random(0, 10));

            requestCode = makeRandom(5);

            _.assign($scope, {
                mode: 'sms-confirm',
                loading: true
            });

            restManager.post('tools/send_sms', { phone: "39" + options.billingData.phone_number, text: $translate.instant('SETTINGS.AUTH_REQUEST_DIALOG.SMS_TEXT', { spaces: spaces, value: requestCode }) });
        };

        if(options.skipAuth) {
            $scope.requestSMS();
        }
    }

    var authRequestDialog = {
        show: function(options) {
            return $mdDialog.show({
                controller: authRequestDialogController,
                template: require('./auth-request-dialog.html'),
                locals: {
                    options: options
                }
            });
        }
    };

    return authRequestDialog;
}]);
