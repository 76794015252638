<mat-toolbar class="tw-flex tw-flex-row tw-text-base tw-pt-2 tw-w-full tw-h-max" [attr.border-color]="'primary'">
    <form *ngIf="pointsForm" autocomplete="off" [formGroup]="pointsForm" class="form tw-flex tw-flex-col sm:tw-flex-row" style="width: 100%;" (ngSubmit)="submit.emit(pointsForm)">
        <ng-container formGroupName="customersPointsForm">
            <div class="tw-basis-4/12 tw-text-3xl tw-p-3 sm:tw-ml-5">{{ _selectedCampaign ? (selectedPointsToShow || 0) + ' ' + ('CUSTOMERS.DETAILS.POINTS.POINTS' | translate) : '' }}</div>
            <div class="tw-basis-2/12">
                <mat-form-field color="primary" class="items tw-flex" >
                    <mat-label>{{'CUSTOMERS.DETAILS.POINTS.FROM' | translate}}-{{'CUSTOMERS.DETAILS.POINTS.TO' | translate}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="date_since" (dateChange)="dateChange($event)" placeholder="{{'CUSTOMERS.DETAILS.POINTS.PLACEHOLDER_FROM' | translate}}">
                        <input matEndDate formControlName="date_max" (dateChange)="dateChange($event)" placeholder="{{'CUSTOMERS.DETAILS.POINTS.PLACEHOLDER_TO' | translate}}">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker>
                        <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>{{'CUSTOMERS.DETAILS.POINTS.CANCEL' | translate}}</button>
                            <button mat-button matDateRangePickerCancel (click)="onCancelDateRange()">{{'CUSTOMERS.DETAILS.POINTS.RESET' | translate}}</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply>{{'CUSTOMERS.DETAILS.POINTS.APPLY' | translate}}</button>
                          </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="tw-basis-2/12 sm:tw-ml-3">
                <mat-form-field color="primary" class="items tw-flex">
                    <mat-label>{{'CUSTOMERS.DETAILS.POINTS.CAMPAIGN' | translate}}</mat-label>
                    <mat-select formControlName="campaign_id" (selectionChange)="submit.emit(pointsForm); ">
                        <mat-option *ngFor="let c of campaigns" [value]="c.id">{{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="tw-basis-2/12 sm:tw-ml-3">
                <mat-form-field color="primary" class="items tw-flex">
                    <mat-label>{{'CUSTOMERS.DETAILS.POINTS.SHOP' | translate}}</mat-label>
                    <mat-select formControlName="db_name" (selectionChange)="submit.emit(pointsForm)">
                        <mat-option *ngFor="let s of chainShops" [value]="s.db_name">{{s.shop_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </form>
</mat-toolbar>
