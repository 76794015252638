<div id="container" class="tw-flex">
    <div [ngClass]="{'border-separator': isLandscape(), 'list': isLandscape(), 'list-full': !isLandscape() && !hasBack}">
        <!-- <div *ngIf="isLandscape() || !hasBack">
            <div class="tw-flex tw-justify-end" *ngIf="enabledPlusButton">
                <button mat-icon-button (click)="filter()">
                    <mat-icon>filter_list</mat-icon>
                </button>
                <button mat-icon-button (click)="open()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div *ngIf="openFilter" style="height: 70px;" class="tw-flex tw-justify-center">
                <mat-form-field style="max-width: 200px;">
                    <mat-label>Filter</mat-label>
                    <input matInput placeholder="filter..." value="" type="text" [(ngModel)]="filterText" (input)="applyFilter()">
                </mat-form-field>
            </div>
            <cdk-virtual-scroll-viewport itemSize="1" class="viewport" [ngStyle]="{'height': !openFilter ? 'calc(100vh - 160px)' : 'calc(100vh - 230px)'}">
                <div *cdkVirtualFor="let device of devices" class="item" (click)="onDeviceClick(device)">
                    <div class="tw-flex tw-flex-row item">
                        <div class="tw-mt-6 tw-ml-4">
                            <mat-icon [ngStyle]="{ 'color': device === deviceSelected ? 'black' : 'gray' }" style="transform: scale(2)">smartphone</mat-icon>
                        </div>
                        <div>
                            <div class="tw-mt-4 tw-ml-4">{{device.name}}</div>
                            <div class="tw-text-sm tw-ml-4">{{getDateDifference(device.updated_at)}}</div>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div> -->
        <app-settings-um-list *ngIf="isLandscape() || !hasBack"
            [enabledQrCodeGenerator]="enabledQrCodeGenerator"
            [buttons]="customButtons"
            [items]="devices"
            [allItems]="allDevices"
            [itemsIcon]="itemsIcon"
            (itemSelected)="onSmartphoneSelected($event)"
            [fieldBuilderLegend1]="buildFieldLegend1"
            [fieldBuilderLegend2]="buildFieldLegend2"
            [filterFunction]="customFilterFunction"
            [isLoading]="isLoading"
        ></app-settings-um-list>
    </div>

    <div id="section" class="tw-w-full" *ngIf="(isLandscape() || (!isLandscape() && hasBack))">
        <div *ngIf="!deviceSelected || !devices.length" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh-113px)] tw-w-full">
            <mat-icon style="transform: scale(7);" class="tw-mb-20">
                <span class="material-symbols-outlined">psychology_alt</span>
            </mat-icon>
            <div class="tw-pl-10 tw-pr-10" translate>SETTINGS.USERS_MANAGEMENT.DEVICE_NOT_SELECTED_MSG</div>
        </div>
        <div class="tw-flex" *ngIf="devices.length" [ngClass]="{'tw-justify-between': !isLandscape(), 'tw-justify-end': isLandscape()}">
            <button mat-icon-button (click)="backFromList()" *ngIf="!this.isLandscape()">
                <mat-icon>
                    <span class="material-symbols-outlined">arrow_back</span>
                </mat-icon>
            </button>
            <div>
                <button mat-icon-button (click)="save()" [disabled]="deviceForm.invalid || deviceForm.pristine">
                    <mat-icon>
                        <span class="material-symbols-outlined">check</span>
                    </mat-icon>
                </button>
                <button mat-icon-button (click)="delete()">
                    <mat-icon>
                        <span class="material-symbols-outlined">delete</span>
                    </mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="devices.length" class="tw-pt-5 tw-pl-5 tw-pr-5">
            <mat-form-field class="tw-w-full">
                <mat-label>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.NAME_DEVICE' | translate}}</mat-label>
                <input matInput type="text" [(ngModel)]="value" [disabled]="!enabledQrCodeGenerator">
                <button *ngIf="hasChangeDeviceName()" matSuffix mat-icon-button aria-label="Clear" (click)="putNameInDevice()">
                    <mat-icon>check</mat-icon>
                </button>
            </mat-form-field>
            <tilby-magic-form [form]="deviceForm" />
        </div>
        <div *ngIf="devices.length" class="tw-flex tw-items-center tw-justify-center tw-flex-col">
              <div class="container tw-relative">
                  <div [ngClass]="{'tw-opacity-50': hasSessionToken()}">
                    <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                  </div>
                  <div *ngIf="hasSessionToken()" class="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0">
                    <div class="tw-flex tw-justify-center tw-items-center tw-h-full">
                        <div class="tw-h-[80px] tw-w-[200px] tw-bg-white tw-flex tw-justify-center tw-items-center tw-opacity-80">
                            <p class="tw-text-black tw-font-bold tw-text-center tw-flex tw-items-center">{{'SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.CODE_ALREADY_USED' | translate}}</p>
                        </div>
                    </div>
                  </div>
              </div>
            <div class="tw-flex tw-mt-4" *ngIf="enabledQrCodeGenerator">
                <button mat-stroked-button color="primary" (click)="regenerate()">
                    <mat-icon>refresh</mat-icon>
                    {{ 'SETTINGS.USERS_MANAGEMENT.DEVICE_WAITER.REGENERATE' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
