import * as angular from 'angular';

angular.module('bookings').directive('topbarBookingsShowcase', ["$mdSidenav", function($mdSidenav) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
		template: require('./topbar-bookings-showcase.html'),
        controller: ["$scope", "$element", "$attrs", "downloadBookingDialog", "environmentInfo", function($scope, $element, $attrs, downloadBookingDialog, environmentInfo) {
            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            $scope.today = function() {
                $scope.context.today();
                $scope.filterDate.SelectFromDate = $scope.context.getCurrentDate();
            };

            $scope.previous = function() {
                $scope.context.previous();
                $scope.filterDate.SelectFromDate = $scope.context.getCurrentDate();
            };

            $scope.next = function() {
                $scope.context.next();
                $scope.filterDate.SelectFromDate = $scope.context.getCurrentDate();
            };

            $scope.getCalendarInfo = function() {
                return $scope.context.getCalendarInfo();
            };

            $scope.changeView = function(view) {
                $scope.context.defaultView = view;
                $scope.context.changeView();
            };

            $scope.download = function() {
                const currentDate = $scope.context.getCurrentDate();
                downloadBookingDialog.show(currentDate);
            };

            $scope.canDownloadFiles = () => environmentInfo.canDownloadFiles();

            $scope.filterDate = {};

            $scope.$watch('filterDate', function(newDate) {
                if(angular.equals(newDate, {})) return;
                if(newDate) $scope.context.changeView($scope.filterDate.SelectFromDate);
            },true);
        }]
    };
}]);
