    <tilby-dialog-toolbar [hideConfirm]="true" [title]="data.item.name" [customActions]="customActions" [isBack]="isEditMode()" (backClick)="isEditMode.set(false)"/>
    @if(savingLoader()){<tilby-dialog-progress-bar/>}
    <tilby-dialog-content>
        @if({hasInfo: carouselImages.length || (isItemInStock && quantity()) || data.item.season || data.item.sku || data.item.weight || data.item.code || supplierName || data.item.brand || (this.optionNames[1] && data.item.option1_value) || (this.optionNames[2] && data.item.option2_value) || (this.optionNames[3] && data.item.option3_value) || (this.optionNames[4] && data.item.option4_value) || data.item.description };as itemData){
            @if(isEditMode()){
                <tilby-magic-form [form]="itemForm"/>
            } @else if(itemData.hasInfo) {
                @if(carouselImages.length){<app-carousel-images-slider [slides]="carouselImages"/>}
                <section class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-x-6 tw-py-6">
                    @if(isItemInStock && quantity()){<div class="tw-col-span-1 tw-flex tw-gap-1" ><div translate="DIALOG.ITEM.QUANTITY" [translateParams]="{quantity: quantity()}"></div>@if(data.item.sku && isCheckStockEnabled){<button translate="DIALOG.ITEM.OTHER" (click)="openStockDialog()"></button>}</div>}
                    @if(data.item.season){<div class="tw-col-span-1" translate="DIALOG.ITEM.SEASON" [translateParams]="{season: data.item.season}"></div>}
                    @if(data.item.sku){<div class="tw-col-span-1" translate="DIALOG.ITEM.SKU" [translateParams]="{sku: data.item.sku}"></div>}
                    @if(data.item.weight){<div class="tw-col-span-1" translate="DIALOG.ITEM.WEIGHT" [translateParams]="{weight: data.item.weight}"></div>}
                    @if(data.item.code){<div class="tw-col-span-1" translate="DIALOG.ITEM.CODE" [translateParams]="{code: data.item.code}"></div>}
                    @if(supplierName){<div class="tw-col-span-1" translate="DIALOG.ITEM.DEFAULT_SUPPLIER" [translateParams]="{default_supplier: supplierName}"></div>}
                    @if(data.item.brand){<div class="tw-col-span-1" translate="DIALOG.ITEM.BRAND" [translateParams]="{brand: data.item.brand}"></div>}
                    @if(this.optionNames[1] && data.item.option1_value){<div class="tw-col-span-1">{{this.optionNames[1]}}: {{data.item.option1_value}}</div>}
                    @if(this.optionNames[2] && data.item.option2_value){<div class="tw-col-span-1">{{this.optionNames[2]}}: {{data.item.option2_value}}</div>}
                    @if(this.optionNames[3] && data.item.option3_value){<div class="tw-col-span-1">{{this.optionNames[3]}}: {{data.item.option3_value}}</div>}
                    @if(this.optionNames[4] && data.item.option4_value){<div class="tw-col-span-1">{{this.optionNames[4]}}: {{data.item.option4_value}}</div>}
                </section>
                @if(data.item.allergens?.length){
                    <section>
                        <span translate="DIALOG.ITEM.ALLERGENS"></span>
                        @for(allergen of data.item.allergens; track allergen.id){<span>@if($index){,} {{allergen.name}}</span>}
                    </section>

                }
                @if(data.item.components?.length){
                    <section>
                        <span translate="DIALOG.ITEM.INGREDIENTS"></span>
                        @for(component of data.item.components; track component.id){<span>@if($index){,} {{component.name}}</span>}
                    </section>

                }
                <br/>
                @if(data.item.description){
                    <section>
                        <div translate="DIALOG.ITEM.DESCRIPTION"> </div>
                        <div>{{data.item.description}}</div>
                    </section>

                }
            } @else {
                <div class="tw-text-lg tw-font-medium tw-px-4 tw-pb-6" translate="DIALOG.ITEM.NO_DETAILS"></div>
            }
        }
    </tilby-dialog-content>
