import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('iconListDialog', ["$mdDialog", function($mdDialog) {

    iconListDialogController.$inject = ["$scope", "items", "options"];
    function iconListDialogController($scope, items, options) {
        if(!_.isObject(options)) {
            options = {};
        }
        $scope.title = options.title || "Seleziona";
        $scope.items = _.cloneDeep(items);

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.selectItem = function(item) {
            $mdDialog.hide(item);
        };
    }

    var iconListDialog = {
        show: function(items, options) {
            return $mdDialog.show({
                controller: iconListDialogController,
                template: require('./icon-list-dialog.html'),
                locals: {
                    items: items,
                    options: options
                }
            });
        }
    };

    return iconListDialog;
}]);
