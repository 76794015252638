import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('printers').factory('NoFiscalPrinters', NoFiscalPrinters);
NoFiscalPrinters.$inject = ['entityManager', '$filter', '$translate', '$rootScope', 'checkManager', 'OperatorManager', 'restManager', 'NonFiscalDriver', 'environmentInfo', 'FiscalProviders', 'dashboardReportGenerator', 'NonFiscalDocumentHook', 'util'];

function NoFiscalPrinters(entityManager, $filter, $translate, $rootScope, checkManager, OperatorManager, restManager, NonFiscalDriver, environmentInfo , FiscalProviders, dashboardReportGenerator, NonFiscalDocumentHook, util) {
    const getOrdersPrinters = async (printers, options) => {
        const printerDriver = checkManager.getPreference('nofiscalprinter.driver') || 'escpos';

        // If we can't use tcp sockets, we can't use non-fiscal esc/pos printers
        // TODO: remove preference and filter printer based on the environment support
        if(printerDriver === 'escpos' && !environmentInfo.canUseTcpSockets()) {
            return [];
        }

        if(!printers) {
            printers = await entityManager.printers.fetchCollectionOffline();
        }

        let ordersPrinters = printers
            .filter((printer) => (printer.type === 'nonfiscal' && printer.driver === printerDriver) || printer.type === 'kds')
            .map(getPrinterWithConfig);
        
        if(!options?.isAutoPrint) {
            // If this is a manual print, filter out order printers that allow only cashregisters prints
            ordersPrinters = ordersPrinters.filter((printer) => !printer.from_cashregisters_only);   
        } else if(options?.sourceCashregister) {
            // If this is an auto print and we have a source cashregister specified, only use order printers that allow this cashregister
            ordersPrinters = ordersPrinters.filter((printer) => (printer.cashregisters || []).includes(options.sourceCashregister));
        }

        return ordersPrinters;
    };

    const getPrinterWithConfig = (printer) => {
        try {
            const { configuration, ...cleanupPrinter } = printer;

            return {
                ...cleanupPrinter,
                ...JSON.parse(configuration),
            };
        } catch (err) {
            return printer;
        }
    };

    const getShopHeader = () => {
        let header = [];

        for(let idx = 1; idx <= 13; idx++) {
            header.push(checkManager.getPreference(`fiscalprinter.printer_header_${idx}`) || "");
        }

        return _.compact(header);
    };

    const sendDocumentToFiscalProvider = async (sale, printerDocumentData, optionsPrint) => {
        const fiscalProvider = FiscalProviders.getFiscalProvider(printerDocumentData.printer.fiscal_provider);
        const documentType = FiscalProviders.getProviderDocumentType(printerDocumentData.document_type?.id);

        if (!fiscalProvider || documentType === 'DONT_SEND') {
            return [];
        }

        let saleDocuments = [];
        let providerAction;

        if (sale.final_amount < 0) {
            //Check if we are doing a void or a refund
            let hasVoid = false;
            let hasRefund = false;

            sale.sale_items = sale.sale_items || [];

            for (const saleItem of sale.sale_items) {
                if (saleItem.refund_cause_id === 6) {
                    hasVoid = true;
                } else {
                    hasRefund = true;
                }
            }

            if (hasRefund && hasVoid) {
                throw 'MIXED_REFUND_CAUSES';
            } else {
                if (hasVoid) {
                    if (typeof fiscalProvider.voidFiscalDocument === 'function') {
                        providerAction = fiscalProvider.voidFiscalDocument(sale, printerDocumentData.reference_fiscal_document, optionsPrint);
                    } else {
                        throw 'PROVIDER_DOES_NOT_SUPPORT_VOID';
                    }
                } else {
                    if (typeof fiscalProvider.refundFiscalDocument === 'function') {
                        providerAction = fiscalProvider.refundFiscalDocument(sale, printerDocumentData.reference_fiscal_document, optionsPrint);
                    } else {
                        throw 'PROVIDER_DOES_NOT_SUPPORT_REFUND';
                    }
                }
            }
        } else {
            providerAction = fiscalProvider.storeFiscalDocument(sale, documentType, optionsPrint);
        }

        try {
            saleDocuments = await providerAction;
        } catch (error) {
            if (fiscalProvider.getProviderError) {
                throw fiscalProvider.getProviderError(error);
            }

            throw error;
        }

        if (typeof fiscalProvider.getReceiptTail === 'function') {
            const documentTail = fiscalProvider.getReceiptTail(saleDocuments, {
                columns: printerDocumentData.printer.columns,
                document_type: printerDocumentData?.document_type?.id
            });

            Object.assign(optionsPrint, {
                tail: _.chain([_.toString(optionsPrint.tail), documentTail.tailRows]).compact().join('\n').value(),
                tail_qr_code: documentTail.tailQrCode,
                tail_qr_code_size: documentTail.tailQrCodeSize
            });
        }

        return saleDocuments;
    };

    const NoFiscalPrinters = {
        printOrder: async (order) => {
            let ordersPrinters = await getOrdersPrinters();

            if (_.isEmpty(ordersPrinters)) {
                return [];
            }

            NonFiscalDriver.setup(ordersPrinters);

            return new Promise((resolve, reject) => {
                let afterPrint = function(results) {
                    $rootScope.$broadcast('order:printed', { results: results, printType: 'order', order: order, reprint: false });
                    resolve(results);
                };

                if (!order.previous_order) {
                    NonFiscalDriver.smartPrintOrder(order, false, afterPrint);
                } else {
                    NonFiscalDriver.smartPrintDifferences(order, afterPrint);
                }
            });
        },
        reprintOrder: async (order) => {
            let ordersPrinters = await getOrdersPrinters();

            if (_.isEmpty(ordersPrinters)) {
                return [];
            }

            NonFiscalDriver.setup(ordersPrinters);

            return new Promise((resolve, reject) => {
                NonFiscalDriver.smartPrintOrder(order, true, function(results) {
                    $rootScope.$broadcast('order:printed', { results: results, printType: 'order', order: order, reprint: true });
                    resolve(results);
                });
            });
        },
        printGoExit: async (order, exit, overridePrinters) => {
            const ordersPrinters = await getOrdersPrinters(overridePrinters);

            if(!ordersPrinters.length) {
                return [];
            }

            NonFiscalDriver.setup(ordersPrinters, {
                goExit_skip_content: checkManager.getPreference('nofiscalprinter.goExit_skip_content')
            });

            const results = await new Promise((resolve) => NonFiscalDriver.smartGoExit(order, exit, resolve));

            $rootScope.$broadcast('order:printed', { results, printType: 'order', order, reprint: false });
            return results;
        },
        printSale: async (sale, options, overridePrinters) => {
            let ordersPrinters = await getOrdersPrinters(overridePrinters, options);

            if(!ordersPrinters.length) {
                return [];
            }

            NonFiscalDriver.setup(ordersPrinters);
            const results = await new Promise((resolve) => NonFiscalDriver.smartPrintSale(sale, options?.reprint, resolve));

            $rootScope.$broadcast('order:printed', { results: results, printType: 'sale', order: sale, reprint: options?.reprint ? true : false });
            return results;
        },
        printVariationSale: async function printVariationSale(saleVariation, sale, overridePrinters) {
            const ordersPrinters = await getOrdersPrinters(overridePrinters);

            if (!ordersPrinters.length) {
                return [];
            }

            NonFiscalDriver.setup(ordersPrinters);

            const results = await NonFiscalDriver.smartPrintVariationSale(saleVariation, sale);

            $rootScope.$broadcast('order:printed', { results: results, printType: 'order', order: sale, reprint: false });

            return results;
        },
        printNonFiscalSale: async (sale, printer) => {
            if(!_.isFunction(NonFiscalDriver.printNonFiscalSale)) {
                throw 'UNSUPPORTED_METHOD';
            }

            let options = {};

            if(!checkManager.getPreference('nofiscalprinter.disable_nonfiscal_sale_header')) {
                options.header = getShopHeader();
            }

            let documentTemplate;
            let progressiveConfig = {};
            let printerWithConfig = getPrinterWithConfig(printer);

            try {
                let documentTemplates = await entityManager.nonfiscalDocuments.fetchCollectionOffline({ type: 'preliminary_receipt' });

                if(documentTemplates.length) {
                    documentTemplate = documentTemplates[0];

                    const result = await NonFiscalDocumentHook.manageProgressive({ document_template: documentTemplate, printer: printer }, 'query');
                    documentTemplate.progressive = result?.progressive;

                    Object.assign(progressiveConfig, {
                        progressive: documentTemplate.progressive,
                        progressive_prefix: documentTemplate.progressive_prefix,
                        printer_prefix: printerWithConfig.progressive_prefix
                    });
                }
            } catch(err) {
                //Nothing to do
            }

            const fiscalProvider = FiscalProviders.getFiscalProvider(printer.fiscal_provider);

            if(fiscalProvider) {
                let saleDocuments = [];

                try {
                    saleDocuments = await fiscalProvider.storeFiscalDocument(sale, 'PRELIMINARY_RECEIPT', progressiveConfig);
                } catch(error) {
                    if(fiscalProvider.getProviderError) {
                        throw fiscalProvider.getProviderError(error);
                    }

                    throw error;
                }

                if(typeof fiscalProvider.getReceiptTail === 'function') {
                    let documentTail = fiscalProvider.getReceiptTail(saleDocuments, {
                        columns: printer.columns
                    });

                    Object.assign(options, {
                        tail: _.chain([_.toString(options.tail), documentTail.tailRows]).compact().join('\n').value(),
                        tail_qr_code: documentTail.tailQrCode,
                        tail_qr_code_size: documentTail.tailQrCodeSize
                    });
                }
            }

            //Use custom template if has rules, otherwise fallback to standard non-fiscal document
            if (documentTemplate?.rules.length) {
                const itemsMap = await util.getItemsFromIds(sale.sale_items);

                NonFiscalDriver.setup([structuredClone(printerWithConfig)]);
                let [success] = await NonFiscalDriver.printDocument(sale, { document_template: documentTemplate, printer: printerWithConfig, itemsMap: itemsMap }, { columns: printerWithConfig.columns, ...options });

                if (!success) {
                    throw 'CONNECTION_ERROR';
                }
            } else {
                await new Promise((resolve, reject) => NonFiscalDriver.printNonFiscalSale(sale, printerWithConfig, options, (res) => {
                    if (res === 'PRINTED') {
                        resolve();
                    } else {
                        reject(res);
                    }
                }));
            }

            if (documentTemplate) {
                NonFiscalDocumentHook.manageProgressive({ document_template: documentTemplate, printer: printer }, 'increment');
            }
        },
        printFreeNonFiscal: async (documentToPrint, printer, options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            let printOptions = {
                barcode: options.barcode,
                printCopyHeader: options.printCopyHeader,
                printLogo: options.printLogo
            };

            if(options.printHeader) {
                printOptions.header = getShopHeader();
            }

            NonFiscalDriver.setup([getPrinterWithConfig(printer)]);

            await NonFiscalDriver.printFreeNonFiscal(documentToPrint, printOptions);
        },
        discoverPrinters: async (driverType) => new Promise((resolve, reject) => {
            NonFiscalDriver.discoverPrinters(driverType, function(result) {
                resolve(result);
            });
        }),
        printDocument: async (saleToPrint, printerDocumentData, printerData, optionsPrint) => {
            const printerWithConfig = getPrinterWithConfig(printerDocumentData.printer);

            NonFiscalDriver.setup([structuredClone(printerWithConfig)]);

            Object.assign(optionsPrint, {
                progressive: printerDocumentData.document_template?.progressive,
                progressive_prefix: printerDocumentData.document_template?.progressive_prefix,
                printer_prefix: printerWithConfig.progressive_prefix
            });

            let providerDocuments = await sendDocumentToFiscalProvider(saleToPrint, printerDocumentData, optionsPrint);
            let [success, driverDocuments] = await NonFiscalDriver.printDocument(saleToPrint, printerDocumentData, optionsPrint);

            return [success, _(providerDocuments).concat(driverDocuments).compact().value()];
        },
        reprintDocument: async (documentToReprint, referenceSale, printer) => {
            let fiscalProviderDocument = _.find(referenceSale.sale_documents, { document_type: 'fiscal_provider' });
            let originalDocumentType = _.chain(referenceSale.sale_documents).find((saleDocument) => saleDocument.document_type !== 'fiscal_provider').get('document_type').value();

            let needsRebuild = false;
            let optionsPrint = {
                isReprint: true
            };

            if(fiscalProviderDocument) {
                const fiscalProvider = FiscalProviders.getFiscalProvider(fiscalProviderDocument.meta?.fiscal_provider);

                if(fiscalProvider?.reprintDocument) {
                    await NoFiscalPrinters.isReachable(printer);

                    try {
                        const FPReprintResult = await fiscalProvider.reprintDocument(referenceSale);

                        if (FPReprintResult && fiscalProvider.getReceiptTail) {
                            const documentTail = fiscalProvider.getReceiptTail(FPReprintResult, {
                                columns: printer.columns,
                                document_type: originalDocumentType
                            });

                            Object.assign(optionsPrint, {
                                tail: _.chain([_.toString(optionsPrint.tail), documentTail.tailRows]).compact().join('\n').value(),
                                tail_qr_code: documentTail.tailQrCode,
                                tail_qr_code_size: documentTail.tailQrCodeSize
                            });

                            needsRebuild = true;
                        }
                    } catch(error) {
                        if(fiscalProvider.getProviderError) {
                            throw fiscalProvider.getProviderError(error);
                        }

                        throw error;
                    }
                }
            }

            if(needsRebuild) {
                let documentTemplates = await entityManager.nonfiscalDocuments.fetchCollectionOffline({ type: documentToReprint.document_type });

                if(documentTemplates.length) {
                    NonFiscalDriver.setup([printer]);

                    const [success, printedDocuments] = await NonFiscalDriver.printDocument(referenceSale, {
                        document_template: documentTemplates[0],
                        document_type: { id: documentToReprint.document_type },
                        printer: printer,
                    }, optionsPrint);

                    if(!success) {
                        throw 'CONNECTION_ERROR';
                    }

                    return printedDocuments[0];
                } else {
                    throw 'CANNOT_FIND_DOCUMENT_TEMPLATE';
                }
            } else {
                await NoFiscalPrinters.printFreeNonFiscal(documentToReprint.document_content, printer, { printHeader: true, printCopyHeader: !['IT', 'MT'].includes(checkManager.getShopCountry()) });

                return { document_content: documentToReprint.document_content };
            }
        },
        isReachable: function isReachable(printer) {
            NonFiscalDriver.setup([printer]);
            return NonFiscalDriver.isReachable();
        },
        openCashDrawer: function openCashDrawer(printerDocumentData) {
            NonFiscalDriver.setup([printerDocumentData.printer]);
            return NonFiscalDriver.openCashDrawer();
        },
        displayText: async (printer, textLines) => {
            return NonFiscalDriver.displayText(getPrinterWithConfig(printer), textLines);
        },
        dailyClosing: async(printer, data) => {
            if(!_.isObject(data)) {
                data = {};
            }

            const printerId = printer.id;
            let status, errors = [], dailyClosingDocument;

            try {
                let fiscalProvider = FiscalProviders.getFiscalProvider(printer.fiscal_provider);

                if(fiscalProvider) {
                    dailyClosingDocument = await fiscalProvider.dailyClosing();
                } else {
                    try {
                        await restManager.getOne('nonfiscal_progressive', 'reset', { printer_id: printerId });
                    } catch(error) {
                        throw "PROGRESSIVE_RESET_FAILED";
                    }
                }

                if(!dailyClosingDocument) {
                    dailyClosingDocument = {};
                }

                const dailyClosingType = checkManager.getPreference('cashregister.daily_report_per_seller') ? 'seller' : 'printer';

                //Generate printer Report
                //Get last daily closing for this printer in order to calculate the date range
                let lastDailyClosing;

                if(dailyClosingType === 'printer') {
                    lastDailyClosing = await entityManager.dailyClosings.fetchCollectionOnline({ printer_id: printerId, orderby_desc: 'date', pagination: true, per_page: 1 });
                    lastDailyClosing = _.head(lastDailyClosing.results);
                }

                //The start of the report is the date of the last daily closing, or the current day start time no daily closing is found
                let dateStart = lastDailyClosing ? lastDailyClosing.date : util.getDayStartTime().toISOString();
                let dateEnd = moment().toISOString();

                Object.assign(data, {
                    from_date: dateStart,
                    to_date: dateEnd
                });
                
                const printerColumns = printer.columns || 42;
                let padHeader = (row) => _.pad(row, printerColumns);

                //Build Daily Closing Document
                let nfClosingDocument = [
                    $translate.instant(dailyClosingType === 'printer' ? 'PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_DAILY_CLOSING_TITLE' : 'PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_DAILY_CLOSING_PER_USER_TITLE'),
                    ''
                ].map(padHeader);

                nfClosingDocument.push(...getShopHeader().map(padHeader));

                const printerLabel = $translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_DAILY_CLOSING_PRINTER');
                const operatorLabel = $translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_DAILY_CLOSING_OPERATOR');
                const shopLabel = $translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_DAILY_CLOSING_SHOP');

                let dailyClosingHeader = [
                    '',
                    $filter('sclDate')(dateEnd),
                    dailyClosingType === 'printer' ? (printerLabel + _.padStart(printer.name, printerColumns - printerLabel.length)) : '',
                    (operatorLabel + _.padStart(OperatorManager.getSellerData().full_name, printerColumns - operatorLabel.length)),
                    (shopLabel + _.padStart($rootScope.userActiveSession.shop.name, printerColumns - shopLabel.length)),
                    ''
                ];

                if(Number.isFinite(data.cash_verification)) {
                    const cashVerificationLabel = $translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_DAILY_CLOSING_CASH_VERIFICATION');
                    dailyClosingHeader.push((cashVerificationLabel + _.padStart($filter('sclCurrency')(data.cash_verification), printerColumns - cashVerificationLabel.length)), '');
                }

                nfClosingDocument.push(...dailyClosingHeader.map(padHeader));

                let closingSourceData;

                switch(dailyClosingType) {
                    case 'seller':
                        closingSourceData = await restManager.analyticsGet('sellers', { date_since: dateStart, date_max: dateEnd, seller_id: $rootScope.userActiveSession.id });
                    break;
                    case 'printer':
                    default:
                        closingSourceData = await restManager.analyticsGet('printers', { date_since: dateStart, date_max: dateEnd, printer_id: printerId });
                    break;
                }

                let vats = await entityManager.vat.fetchCollectionOffline();
                let printerReport = await dashboardReportGenerator.getPrinterReport(closingSourceData, { printerColumns: printerColumns, vats: vats });
    
                nfClosingDocument.push(...printerReport);
                nfClosingDocument = nfClosingDocument.join('\n');

                let paymentsTotal = _(closingSourceData.payments).groupBy('unclaimed').mapValues((block) => _.sumBy(block, 'amount')).value();

                Object.assign(dailyClosingDocument, {
                    date: dateEnd,
                    document_content: nfClosingDocument,
                    total: util.round(_.toFinite(paymentsTotal[0]) + _.toFinite(paymentsTotal[1])),
                    unclaimed: _.toFinite(paymentsTotal[1])
                });

                let vatsById = _.keyBy(vats, 'id');
                let vatsData = _.chain(closingSourceData).get('fiscal_receipts.vats').groupBy('name').value();
                let invoicesVatsData = _.chain(closingSourceData).get('invoices.vats').groupBy('name').value();

                let finalVatsData = _({})
                    .mergeWith(vatsData, invoicesVatsData, (obj, src) => _.concat(obj, src))
                    .mapValues(_.compact)
                    .mapValues((vatData, id) => _.map(vatData, (vatRow) => _.assign(vatRow, { value: vatsById[id]?.value })))
                    .values()
                    .flatten()
                    .groupBy('value')
                    .mapValues((block) => ({
                        amount: _.sumBy(block, 'amount'),
                        net_amount: _.sumBy(block, 'net_amount'),
                        vat_amount: _.sumBy(block, 'vat_amount')
                    }))
                    .toPairs()
                    .value();

                for(let i = 0; i <= 4; i++) {
                    if(_.isArray(finalVatsData[i])) {
                        let vatVal = _.head(finalVatsData[i]);
                        let vatData = _.chain(finalVatsData[i]).tail().head().value();
                        dailyClosingDocument[`vat_value_${i + 1}`] = _.toFinite(vatVal);
                        dailyClosingDocument[`vat_amount_${i + 1}`] = util.round(vatData.vat_amount);
                        dailyClosingDocument[`vat_taxable_${i + 1}`] = util.round(vatData.net_amount);
                        dailyClosingDocument[`vat_tot_${i + 1}`] = util.round(vatData.amount);
                    }
                }

                //Save daily closing
                entityManager.dailyClosings.postOneOfflineFirst({
                    ...dailyClosingDocument,
                    printer_id: printerId,
                    printer_name: printer.name
                });

                try {
                    await NoFiscalPrinters.printFreeNonFiscal(nfClosingDocument, printer);
                } catch(err) {}

                status = 'COMPLETED';
            } catch(err) {
                status = 'FAILED';
                errors.push(err);
            }

            return { status, errors };
        }
    };

    return NoFiscalPrinters;
}