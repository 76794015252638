import * as angular from 'angular';
import * as _ from 'lodash';

const lastRoom = ($stateParams, rooms, TablesFactory) => {
    if(!_.isEmpty(rooms)) {
        let id = TablesFactory.lastRoom || rooms[0].id;

        if (_.isEmpty($stateParams.id)) {
            _.assign($stateParams, {
                id: id
            });
        } else {
            TablesFactory.lastRoom = _.toInteger($stateParams.id);
        }
    }
};

angular.module('tables', ['application'])

.factory('TablesFactory', function() {
    return {
        lastRoom: null
    };
})

.config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('tables');

    $stateProvider.state('app.tables', {
        url: '/old_rooms',
        views: {
            "appContent@app": {
                template: require('./partial/tables/tables.html'),
                controller: 'TablesCtrl'
            }
        },
        resolve: {
            rooms: ["entityManager", (entityManager) => entityManager.rooms.fetchCollectionOffline() ],
            orders: ["checkManager", "entityManager", "orderUtils", async (checkManager, entityManager, orderUtils) => {
                const applyCoverToTotal = checkManager.getPreference('orders.apply_cover_to_total');
                const coverConfig = applyCoverToTotal ? await orderUtils.getOrderCoverConfig() : {};

                let orders = await entityManager.orders.fetchCollectionOffline({ status_in: ['open', 'checkout'] });

                let allOrders = _.chain(orders)
                    .filter((order) => order.table_id)
                    .orderBy(['open_at', 'created_at'], ['desc', 'desc'])
                    .value();

                for(let order of allOrders) {
                    orderUtils.calculateOrderPrices(order);
                    order.amount += orderUtils.getCoverValue(order, coverConfig);
                }

                return {
                    all: allOrders,
                    byTable: _.groupBy(allOrders, 'table_id')
                };
            }]
        }
    });

    $stateProvider.state('app.tables.rooms-view', {
        url: '/:id/view',
        template: require('./partial/rooms/rooms-view/rooms-view.html'),
        controller: 'RoomsViewCtrl',
        params: {
            id: null
        },
        resolve: {
            preload: ["$stateParams", "rooms", "TablesFactory", lastRoom]
        }
    });

    $stateProvider.state('app.tables.rooms-edit', {
        url: '/:id/edit',
        template: require('./partial/rooms/rooms-edit/rooms-edit.html'),
        controller: 'RoomsEditCtrl',
        params: {
            id: null
        },
        resolve: {
            preload: ["$stateParams", "rooms", "TablesFactory", lastRoom]
        }
    });

    $stateProvider.state('app.tables.table-view', {
        url: '/:room_id/table/:table_id',
        template: require('./partial/multiple-order/multiple-order.html'),
        controller: 'MultipleOrderCtrl',
        params: {
            room_id: null,
            table_id: null
        }
    });
    /* Add New States Above */
}]);
