import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('kiosk').controller('KioskConfirmationCtrl', ["$scope", "$state", "$timeout", "kioskSettings", "kioskUtils", function($scope, $state, $timeout, kioskSettings, kioskUtils) {
    var moduleIsEnabled = kioskSettings['confirmation.enabled'];

    var returnToStandby = function() {
        $state.go('app.kiosk.standby');
    };

    if(!moduleIsEnabled) {
        returnToStandby();
    } else {
        var timeout = (_.toInteger(kioskSettings['confirmation.timeout']) || 5) * 1000;
        $scope.mediaUrl = _.toString(kioskSettings['confirmation.media_url']);
        $scope.mediaType = kioskSettings['confirmation.media_type'] || kioskUtils.getMediaType($scope.mediaUrl);

        switch($scope.mediaType) {
            case 'video/ogg':
            case 'video/mp4':
            case 'video/webm':
                $timeout(function() {
                    $('#confirmationVideo')[0].addEventListener('ended', returnToStandby, false);
                });
            break;
            case 'image':
                $timeout(returnToStandby, timeout);
            break;
            default: returnToStandby();
        }
    }
}]);
