import * as angular from 'angular';
import * as _ from 'lodash';

type ItemListSelectorElement = {
    id: any,
    name: string
}

type ItemListSelectorOptions = {
    disableSetAsDefault?: boolean,
    multiple?: boolean,
    defaultElement?: any,
    itemIcon?: string
};

class ItemListSelectorController {
    constructor(
        private $scope: any,
        private $mdDialog: any,
        message: string,
        items: ItemListSelectorElement[],
        options: ItemListSelectorOptions
    ) {
        this.$scope.defaultElement = options?.defaultElement

        Object.assign(this.$scope, {
            message: message,
            items: items,
            itemIcon: options?.itemIcon,
            canSetAsDefault: options?.disableSetAsDefault || options?.multiple ? false : true,
            multiple: options?.multiple ? true : false,
            selectedItems: {},
            setAsDefault: false
        });

        this.$scope.cancel = () => {
            this.$mdDialog.cancel();
        };

        this.$scope.confirm = (element: ItemListSelectorElement) => {
            if(this.$scope.canConfirm) {
                if(this.$scope.multiple) {
                    $scope.selectedItems[element.id] = !$scope.selectedItems[element.id];
                } else {
                    $mdDialog.hide({
                        element: element,
                        setAsDefault: this.$scope.setAsDefault
                    });
                }
            }
        };

        this.$scope.confirmMultiple = () => {
            const selectedItems = $scope.items.filter((item: ItemListSelectorElement) => $scope.selectedItems[item.id]);

            if(selectedItems.length) {
                $mdDialog.hide({
                    elements: selectedItems
                });
            }
        }
    }
}

ItemListSelectorController.$inject = ["$scope", "$mdDialog", "message", "items", "options"];

export class ItemListSelector {
    constructor(
        private $mdDialog: any
    ) {
    }

    public show(message: string, items: ItemListSelectorElement, options: ItemListSelectorOptions) {
        return this.$mdDialog.show({
            controller: ItemListSelectorController,
            template: require('./item-list-selector.html'),
            locals: {
                message: message,
                items: items,
                options: options
            },
            onComplete: (scope: any) => {
                setTimeout(() => {
                    scope.canConfirm = true;
                }, 500);
            }
        });
    }
}

ItemListSelector.$inject = ["$mdDialog"];

angular.module('application').service('itemListSelector', ItemListSelector);
