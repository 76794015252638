import {
    Injectable,
    inject
} from "@angular/core";

import {
    TranslateService
} from "@ngx-translate/core";

import {
    $state
} from "app/ajs-upgraded-providers";

import {
    filter
} from "rxjs";

import {
    ConfigurationManagerService,
    EntityBase,
    EntityManagerService,
    EntityUpdateInfo,
    PrinterDocumentTypeId,
} from "src/app/core";

import {
    DocumentPrintersManagerDialogStateService,
    OpenDialogsService,
    PrinterErrorFiscalDialogService
} from "src/app/dialogs";

import {
    ActiveSaleService,
    SalePaymentService,
    SalePrintingUtilsService,
    SaleUtilsService
} from "src/app/features/cashregister";

import {
    GenericToastService
} from "src/app/shared/components/toast/generic-toast.component";

import {
    DevLogger
} from "src/app/shared/dev-logger";

import {
    Sales
} from "tilby-models";

@Injectable({
    providedIn: "root",
})
export class SaleBatchPrintService {
    private readonly $state = inject($state);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly documentPrintersManager = inject(DocumentPrintersManagerDialogStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly printerErrorFiscal = inject(PrinterErrorFiscalDialogService);
    private readonly salePayment = inject(SalePaymentService);
    private readonly saleUtilsService = inject(SaleUtilsService);
    private readonly salePrintingUtils = inject(SalePrintingUtilsService);
    private readonly translateService = inject(TranslateService);
    private readonly snackBar = inject(GenericToastService)

    public init() {
        //Start the auto print routine after 10 seconds
        setTimeout(() => this.autoPrintRoutine(), 10000);

        if(!this.configurationManagerService.getPreference("cashregister.disable_external_sales_notification")) {
            EntityBase.entityUpdates$.pipe(
                filter(data => !!data.externalClient && data.entityName === 'sales' && ['CREATED', 'UPDATED'].includes(data.action))
            ).subscribe((data) => this.onSaleUpdate(data));
        }
    }

    private async onSaleUpdate(data: EntityUpdateInfo) {
        // Abort if new cashregister is disabled
        if(
            !this.configurationManagerService.isModuleAngular('tables_and_cashregister') ||
            !data.entity
        ) {
            return;
        }

        const sale = structuredClone(data.entity) as Sales;

        //Abort if sale is emittable and external sales printing is enabled
        if(this.isExternalSalesPrintEnabled() && this.isSaleEmittable(sale)) {
            return;
        }

        if(!this.$state.current.name.includes('kiosk')) {
            const message = data.action === "CREATED"
                ? 'CASHREGISTER.EXTERNAL_SALES_MANAGER.NEW_SALE_ARRIVED'
                : 'CASHREGISTER.EXTERNAL_SALES_MANAGER.SALE_UPDATED';

            const snackBarRef = this.snackBar.show(message, {}, 'CASHREGISTER.EXTERNAL_SALES_MANAGER.HIDE', 'CASHREGISTER.EXTERNAL_SALES_MANAGER.OPEN', { duration: 0 });

            snackBarRef.onAction().subscribe(() => {
                this.$state.go('app.new.cashregister.sale', { id: sale.id }, { inherit: false });
            });
        }
    }

    private async autoPrintRoutine() {
        const restartTimeout = parseInt(this.configurationManagerService.getPreference('cashregister.batch_sale_print.check_interval') || '10') || 10;

        try {
            await this.processPrintableSales();
        } finally {
            //Retart the auto print routine after the timeout
            setTimeout(() => this.autoPrintRoutine(), restartTimeout * 1000);
        }
    }

    public isExternalOrdersPrintEnabled() {
        return window.localStorage.getItem('deviceSettings::printExternalOrders') === 'true';
    }

    public isExternalSalesPrintEnabled() {
        return window.localStorage.getItem('deviceSettings::printExternalSales') === 'true';
    }

    public setExternalOrdersPrint(value: boolean) {
        window.localStorage.setItem('deviceSettings::printExternalOrders', value.toString());
    }

    public setExternalSalesPrint(value: boolean) {
        window.localStorage.setItem('deviceSettings::printExternalSales', value.toString());
    }

    private logEvent(...args: any[]) {
        DevLogger.log('[ SaleBatchPrint ]', ...args);
    }

    /**
     * Checks if a sale is emittable based on the presence of payments and sale items.
     * The sale is considered emittable if it has any payments or sale items and the paid payments cover the final amount.
     * This method mutates the provided sale object, removing any unpaid payments and calculating the sale prices.
     *
     * @param {Sales} sale - the sale object to be checked
     * @return {boolean} true if the sale is emittable, false otherwise
     */
    private isSaleEmittable(sale: Sales) {
        //Remove unpaid payments
        sale.payments = sale.payments?.filter((payment) => payment.paid) || [];
        sale.sale_items = sale.sale_items || [];

        //Skip the sale if there are no payments or sale items
        if (!sale.payments.length || !sale.sale_items.length) {
            return false;
        }

        //Calculate prices
        this.saleUtilsService.calculateSalePrices(sale);

        //Skip the sale if is a refund/void or there is an amount to pay left
        if (sale.final_amount! < 0 || this.salePayment.getToPay(sale) > 0) {
            return false;
        }

        return true;
    }

    private async printSale(saleToPrint: Sales, printerId: number, documentId: PrinterDocumentTypeId | number, options?: { batchOrderMode?: boolean }) {
        if(!this.isSaleEmittable(saleToPrint)) {
            return;
        }

        const docType = Object.keys(saleToPrint.e_invoice || {}).length ? 'e_invoice' : documentId;
        const useEReceipt = !!(saleToPrint.sale_customer?.email && !saleToPrint.sale_customer?.disable_mail_receipts);

        this.logEvent(`Printing sale ${saleToPrint.uuid}`);
        let documentData = await this.documentPrintersManager.getPrinterDocumentData(printerId, docType, { eReceipt: useEReceipt });

        await this.salePayment.processPayments(saleToPrint, documentData);
        await this.salePayment.processSaleChange(saleToPrint);

        const result = await this.activeSaleService.emitDocument(saleToPrint, documentData, { batchOrderMode: options?.batchOrderMode });

        if ('printError' in result) {
            throw result.printError;
        }

        return result;
    }

    private async processPrintableSales() {
        //Disable for old cashregister
        if (!this.configurationManagerService.isModuleAngular('tables_and_cashregister')) {
            return;
        }

        const isExternalSalesPrintEnabled = this.isExternalSalesPrintEnabled();
        const isExternalOrdersPrintEnabled = this.isExternalOrdersPrintEnabled();

        //Skip if external sales are not enabled
        if (!isExternalSalesPrintEnabled && !isExternalOrdersPrintEnabled) {
            return;
        }

        const defaultPrinterId = parseInt(this.configurationManagerService.getPreference('cashregister.batch_sale_print.default_printer_id') || '');
        const defaultDocumentPref = this.configurationManagerService.getPreference('cashregister.batch_sale_print.default_document_id') || '';
        const defaultDocumentId = parseInt(defaultDocumentPref) || defaultDocumentPref as PrinterDocumentTypeId | number;

        const sales = await this.entityManagerService.sales.fetchCollectionOffline({ status: "open" });

        for (const saleToPrint of sales) {
            //Skip the sale if it's open in the cashregister
            if (this.activeSaleService.currentSale?.uuid === saleToPrint.uuid) {
                continue;
            }

            if (isExternalSalesPrintEnabled && defaultPrinterId && defaultDocumentId) {
                try {
                    const result = await this.printSale(saleToPrint, defaultPrinterId, defaultDocumentId, { batchOrderMode: isExternalOrdersPrintEnabled });

                    //Sale was printed, skip the order processing as it's already handled by the active sale service
                    if (result) {
                        continue;
                    }
                } catch (error) {
                    this.logEvent(`Error printing sale ${saleToPrint.uuid}`, error);
                    this.openDialogsService.openSnackBarTilby(this.printerErrorFiscal.translateError(error), this.translateService.instant('MISC.OK'));
                    //Skip to the next sale as there was an error and we don't want to print the exits
                    continue;
                }
            }

            if (isExternalOrdersPrintEnabled && saleToPrint.auto_print_order) {
                this.logEvent(`Sending exits for sale ${saleToPrint.uuid}`);
                let results = await this.salePrintingUtils.sendExitToPrinter(saleToPrint, [], { batchMode: true });

                if(results.length) {
                    this.logEvent(`Exits processed for sale ${saleToPrint.uuid}`);
                } else {
                    this.logEvent(`No exits processed for sale ${saleToPrint.uuid}`);
                }
            }
        }
    }
}
