import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { validate as validateUuid, v4 as generateUuid } from 'uuid';
import { paymentMethodTypes } from 'src/app/core/constants';

angular.module('cashregister').factory('ActiveSale', ActiveSale);

ActiveSale.$inject = ["$rootScope", "$filter", "$state", "$stateParams", "$translate", "entityManager", "checkManager", "OperatorManager", "confirmDialog", "alertDialog", "util", "saleUtils", "newSaleUtils", "fiscalUtils", "documentPrintersManager", "splitSaleDialog", "documentPrinter", "restManager", "DigitalPaymentsManager", "connection", "prepaidSale", "NoFiscalPrinters", "printerErrorFiscal", "toast", "FiscalProviders", "GiftCardPrinter", "openDialogsService","roomsStateService", "salePayment", "ExternalSalesManager"];

function ActiveSale($rootScope, $filter, $state, $stateParams, $translate, entityManager, checkManager, OperatorManager, confirmDialog, alertDialog, util, saleUtils, newSaleUtils, fiscalUtils, documentPrintersManager, splitSaleDialog, documentPrinter, restManager, DigitalPaymentsManager, connection, prepaidSale, NoFiscalPrinters, printerErrorFiscal, toast, FiscalProviders, GiftCardPrinter, openDialogsService, roomsStateService, salePayment, ExternalSalesManager) {
    let pristineSale = {};
    let currentSaleItem;

    let isCreatingSale;

    var creatingSale = false;


    const updateGroupItemsLinks = () => {
        const saleItems = activeSale.currentSale.sale_items;

        if(saleItems) {
            let menuItemsUuids = _.chain(saleItems).filter({ is_group_item: true }).map('uuid').map((val, idx) => ({ value: val, index: (idx + 1) })).keyBy('value').value();

            for(let saleItem of saleItems) {
                let menuRef = menuItemsUuids[saleItem.uuid] || menuItemsUuids[saleItem.sale_item_parent_uuid];

                saleItem.$menuIndex = menuRef?.index;
            }
        }
    };

    const isEInvoiceDocument = (documentTypeId) => ['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(documentTypeId);

    const saveSaleToStorage = (sale) => {
        if(sale.id) {
            return entityManager.sales.putOneOfflineFirst(sale);
        } else {
            return entityManager.sales.postOneOfflineFirst(sale);
        }
    };

    const awaitSale = () => new Promise((resolve, reject) => {
        if(activeSale.isActiveSale()) {
            resolve();
        } else if(isCreatingSale) {
            isCreatingSale.then(resolve);
        } else {
            activeSale.newSale().then(resolve);
        }
    });

    const closeTable = async (sale) => {
        if(sale.order_uuid) {
            let sales = await entityManager.sales.fetchCollectionOffline({ status: 'open' });
            let areRelatedSalesForOrder = _.some(sales, (locSale) => ((locSale.uuid !== sale.uuid) && (locSale.order_uuid === sale.order_uuid)));

            //if there are no other sales related to the order of this sale (in case of a split sale, for example)
            if(!areRelatedSalesForOrder) {
                let order;

                if(sale.order_id) {
                    order = await entityManager.orders.fetchOneOfflineFirst(sale.order_id);
                } else {
                    let orders = await entityManager.orders.fetchCollectionOffline({ uuid: sale.order_uuid });
                    order = _.head(orders);
                }

                if(order) {
                    Object.assign(order, {
                        status: 'closed',
                        closed_at: new Date().toISOString()
                    });

                    try {
                        await entityManager.orders.putOneOfflineFirst(order);
                    } catch(err) {}

                    if(checkManager.getPreference('cashregister.open_tables_after_send')) {
                        if(checkManager.isModuleEnabled('tables')) {
                            $state.go("app.tables.rooms-view");
                        } else if(checkManager.isModuleEnabled('orders')) {
                            $state.go("app.orders.content");
                        }
                    }
                }
            }
        }
    };

    const loadParentSaleIfPresent = (sale) => {
        let splitNumRegEx = /^.*-\s(\d+)$/;
        let index;

        let match = sale.name.match(splitNumRegEx);

        if(match) {
            index = _.toInteger(match[1]);
        }

        let isChildSale = sale.final_amount >= 0 && sale.sale_parent_uuid && sale.uuid !== sale.sale_parent_uuid;

        if (isChildSale) {
            activeSale.loadSale(sale.sale_parent_id || sale.sale_parent_uuid).then(function(sale) {
                if (sale?.id) {
                    activeSale.splitSale({ type: 'by_items', splitIndex: (index || 1) + 1 });
                }
            });
        }

        return isChildSale;
    };

    const finishSaleClosing = (sale, options) => {
        let isChildSale;

        if(!_.isObject(options)) {
            options = {};
        }

        if(!activeSale.isActiveSale() || activeSale.currentSale.uuid === sale.uuid) {
            activeSale.resetDocumentData();
        }

        if(activeSale.isActiveSale() && activeSale.currentSale.uuid === sale.uuid) {
            if(options.closeActiveSale !== false) {
                doOpenSale(null, { skipResetDocumentData: true });
            }

            if(!options.skipParentCheck) {
                isChildSale = loadParentSaleIfPresent(sale);
            }

            if($stateParams.autohide && !isChildSale) {
                $state.go('.', { autohide: null });
                util.minimizeApp();
            }
        }

        //checks if an order has to be closed
        closeTable(sale);
    };

    const performStore = async (saleToStore, options) => {
        if(!_.isObject(options)) {
            options = {};
        }

        const opData = OperatorManager.getSellerData();

        Object.assign(saleToStore, {
            status: 'stored',
            closed_at: new Date().toISOString(),
            seller_id: opData.id,
            seller_name: opData.full_name
        });

        if(options.reason) {
            saleToStore.notes = options.reason;
        }

        await saleUtils.calculateSaleItemsCosts(saleToStore);
        let sale = await saveSaleToStorage(saleToStore);

        finishSaleClosing(sale, options);
    };

    const checkNotDiscountableSaleItem = (saleItem) => {
        if(activeSale.hasNotDiscountableItems()) { //Sale has already a not discountable item, if this sale item is discountable apply the sale price changes on it
            if(!saleItem.not_discountable) {
                applySalePriceChangesOnItems([saleItem]);
            }
        } else if(saleItem.not_discountable) { //No "Not discountable" item in sale -> Not discountable item in sale
            //Remove sale discount_fix and change discount_perc to disc_perc_nd
            const oldPriceChanges = activeSale.currentSale.price_changes || [];
            const newPriceChanges = structuredClone(oldPriceChanges).filter((priceChange) => {
                switch(priceChange.type) {
                    case 'discount_fix':
                        return false;
                    case 'discount_perc':
                        priceChange.type = 'disc_perc_nd';
                        priceChange.index += 100;
                        break;
                }

                return true;
            });

            //Apply new price changes if we made any changes
            if(newPriceChanges.length !== oldPriceChanges.length || oldPriceChanges.some((priceChange) => priceChange.type === 'discount_perc')) {
                activeSale.updateSaleDetails({ price_changes: newPriceChanges });
            }

            applySalePriceChangesOnItems();
        }
    };

    const applySalePriceChangesOnItems = (items, priceChanges) => {
        const priceChangesToApply = priceChanges || activeSale.currentSale?.price_changes?.filter((priceChange) => priceChange.type === 'disc_perc_nd') || [];
        const targetItems = items || activeSale.currentSale?.sale_items || [];

        for(const saleItem of targetItems.filter((i) => (!i.not_discountable && i.type === 'sale'))) {
            for(const priceChange of priceChangesToApply) {
                addGenericPriceChange(saleItem, 'discount_perc', priceChange.value, priceChange.description, priceChange.index);
            }
        }
    };

    const addGenericPriceChange = (target, type, value, description, index, overrides) => {
        const targetType = target.sale_items ? 'sale' : 'sale_item';
        const priceChange = newSaleUtils.getGenericPriceChange(target, type, value, description, index, overrides);

        if(targetType === 'sale_item') {
            activeSale.editSaleItem(target, { price_changes: [...(target.price_changes || []), priceChange] });
        } else {
            activeSale.updateSaleDetails({
                price_changes: [...(activeSale.currentSale.price_changes || []), priceChange]
            });
        }

        if(type !== 'disc_perc_nd') {
            $rootScope.$broadcast("activeSale:priceChange-added", { target: target, priceChange: priceChange, targetType: target.sale_items ? 'sale' : 'sale_item' });
        }

        return priceChange;
    };

    const searchDeletedOrderItems = async (sale) => {
        if(sale && checkManager.getPreference('cashregister.show_deleted_order_items') && (sale.order_id || sale.order_uuid)) {
            const searchParams = {
                deleted_at: 'notnull',
                show_deleted: true
            };

            //Search by id if set, otherwise by uuid
            if(sale.order_id) {
                searchParams.order_parent_id = sale.order_id;
            } else {
                searchParams.order_parent_uuid = sale.order_uuid;
            }

            try {
                //Fetch deleted order items related to the current sale
                const deletedOrders = await entityManager.orders.fetchCollectionOnline(searchParams);
                const deletedItems = [];

                for(let order of deletedOrders) {
                    for(let item of order.order_items) {
                        const cleanOrderItem = Object.assign(saleUtils.getCleanSaleItem(item, true), {
                            added_at: order.deleted_at,
                            type: 'sale',
                            uuid: generateUuid()
                        });

                        deletedItems.push(cleanOrderItem);
                    }
                }

                activeSale.deletedOrderItems = deletedItems;
            } catch(err) {
                activeSale.deletedOrderItems = null;
            }
        } else {
            activeSale.deletedOrderItems = [];
        }
    };

    const doOpenSale = (sale, options) => {
        let hasSetPriceList = false;
        let needsSave = false;

        //Reset status variables
        activeSale.reprintSaleToOrderPrinters = false;
        activeSale.resetGroupItemUuid();
        currentSaleItem = undefined;

        //Emit sale-parked event if we are unloading a current open sale
        if(activeSale.isActiveSale() && !['closed', 'stored'].includes(activeSale.currentSale.status)) {
            $rootScope.$broadcast("activeSale:sale-parked", activeSale.currentSale);
        }

        activeSale.currentSale = sale || {};

        if(sale) {
            if(sale.sale_customer?.default_pricelist) {
                $rootScope.$broadcast("activeSale:use-pricelist", { priceList: sale.sale_customer.default_pricelist, skipNotify: true });
                hasSetPriceList = true;
            }

            activeSale.hasPaidPayments = _.some(sale.payments, { paid: true });

            //Assign current operator if the sale is from an order and not yet assigned
            if (!sale.assigned_id && (sale.order_id || sale.order_uuid)) {
                const opData = OperatorManager.getSellerData();

                Object.assign(sale, {
                    assigned_id: opData.id,
                    assigned_name: opData.full_name,
                    seller_id: opData.id,
                    seller_name: opData.full_name
                });

                needsSave = true;
            }
        } else {
            if(checkManager.getPreference("cashregister.reset_pricelist_on_close")) {
                $rootScope.$broadcast("cashregister:reset-pricelist");
                hasSetPriceList = true;
            }
        }

        searchDeletedOrderItems(sale);

        //Properly compile sale prices and other info
        activeSale.calculateAllSalePrices();

        //Create a pristine reference to check if we changed the sale during it's usage
        pristineSale = angular.copy(activeSale.currentSale);

        if(!hasSetPriceList) {
            $rootScope.$broadcast("activeSale:use-pricelist", { priceList: 'default', skipNotify: true });
        }

        $rootScope.$broadcast("activeSale:sale-opened", sale);

        if(!activeSale.printerDocumentData && options?.skipResetDocumentData !== true) {
            activeSale.resetDocumentData();
        }

        if(needsSave) {
            activeSale.saveSale();
        }

        return (sale || null);
    };

    const askSellerIfRequired = async () => {
        if(checkManager.getShopPreference('cashregister.ask_seller_for_each_sale')) {
            await OperatorManager.changeSeller();
        }
    };

    const getFastPaymentAmount = (sale, paymentMethod) => {
        let amount, inputObject = {};

        if([1].includes(paymentMethod.payment_method_type_id) && fiscalUtils.isPaymentRoundingEnabled() && !sale.is_summary) {
            amount = util.round(Math.round(sale.final_amount * 20) / 20);

            if(amount !== sale.final_amount) {
                inputObject.payment_data = JSON.stringify({ rounding: true, original_amount: sale.final_amount });
            }
        } else {
            amount = sale.final_amount;
        }

        return [amount, inputObject];
    };

    const activeSale = {
        currentSale: {},
        deletedOrderItems: [],
        printerDocumentData: null,
        paymentInProgress: false,
        printDocumentInProgress: false,
        sendEInvoiceInProgress: false,
        lockPaymentButtons: false,
        hasPaidPayments: false,
        groupItemUuid: null,
        reprintSaleToOrderPrinters: false,
        resetGroupItemUuid: () => activeSale.groupItemUuid = null,
        isPristine: () => angular.equals(activeSale.currentSale, pristineSale),
        loadSale: async (saleId, options) => {
            let sale;

            if(saleId) {
                //Wait for operator change if necessary
                await askSellerIfRequired();
            }

            //Save current open sale if necessary
            if (options?.skipStore !== true && activeSale.isActiveSale() && !activeSale.isPristine()) {
                await saveSaleToStorage(angular.copy(activeSale.currentSale));
            }

            if(saleId) {
                if(validateUuid(saleId)) {
                    //Try fetching the sale with the uuid as primary from the device storage, then try looking for a sale with that uuid as last resort
                    sale = await entityManager.sales.fetchOneOffline(saleId);

                    if(!sale) {
                        let sales = await entityManager.sales.fetchCollectionOffline({ uuid: saleId });
                        sale = sales[0];
                    }
                } else {
                    //Fetch the sale using the numerical id in offline-first mode (device storage, then API if not found)
                    sale = await entityManager.sales.fetchOneOfflineFirst(saleId);
                }
            }

            return doOpenSale((sale?.status == 'open') ? sale : null, options);
        },
        createNewSale: async (params) => {
            if (creatingSale) {
                throw 'SALE_CREATION_PENDING';
            }

            creatingSale = true;
            let sales = await entityManager.sales.fetchCollectionOffline({ status: 'open' });
            let deliveryChannels = await entityManager.channels.fetchCollectionOffline().then(function(channels) {
                return _.reject(channels, {id: 'pos'});
            });
            let rooms = await entityManager.rooms.fetchCollectionOffline();

            let tables = [];
            _.forEach(rooms, function(room) {
                _.forEach(room.tables, function(table) {
                    tables.push(_.assign({
                        room: room
                    }, table));
                });
            });
            let salesByTable = _.groupBy(sales, 'table_id');
            tables = _.filter(tables, function(table) {
                return table.order_type === 'multiple' || _.isNil(salesByTable[table.id]);
            });

            const opData = OperatorManager.getOperatorData();
            let assigned_id = null;
            let assigned_name = null;
            if (checkManager.isModuleEnabled('cashregister')) {
                assigned_id = opData.id;
                assigned_name = opData.full_name;
            }

            try {
                const res = await openDialogsService.openMagicFormDialog({data: {
                    title:'TABLES_NEW.DIALOGS.SALE.TITLE',
                    form: roomsStateService.createOrOpenSaleForm(params, deliveryChannels, tables),
                    buttonCancelLabel:'TABLES_NEW.DIALOGS.SALE.BUTTON_CANCEL',
                    buttonConfirmLabel:'TABLES_NEW.DIALOGS.SALE.BUTTON_CONFIRM'
                }});
                roomsStateService.ngOnDestroy();
                if (res) {
                    let table = tables.find((table) => table.id === res.table_id);

                    // TODO: è da richiedere? Arriva in options?
                    // if(!options?.dontSaveSale) {
                    //     await askSellerIfRequired();
                    // }

                    let sale = await saleUtils.getSaleTemplate();
                    sale = { ...sale, "name": res.name, "order_type": res.type,"assigned_id": assigned_id,"assigned_name": assigned_name};

                    switch (params.saleType) {
                        case 'normal':
                            sale.table_id = table.id;
                            sale.table_name = table.name;
                            sale.room_id = table.room.id;
                            sale.room_name = table.room.name;
                            sale.covers = res.covers;
                            sale.booking_id = res.booking_id || null;
                            break;
                        case 'take_away':
                            sale.deliver_at = res.delivery_at;
                            break;
                        case 'delivery':
                            sale.deliver_at = res.delivery_at;
                            sale.external_id = res.order_id;
                            sale.channel = res.channels;
                            break;
                    }

                    // TODO: è da salvare? Arriva in options?
                    // if(!options?.dontSaveSale) { // TODO: Riportiamo questa option?
                    sale = await saveSaleToStorage(sale);
                    // }

                    doOpenSale(sale);
                }
            } finally {
                creatingSale = false;
            }

        },
        newSale: async(options) => {
            return isCreatingSale = new Promise(async (resolve, reject) => {
                try {
                    if (activeSale.isActiveSale() && activeSale.currentSale.id) {
                        saveSaleToStorage(angular.copy(activeSale.currentSale));
                    }

                    //Not-so-clean workaround to avoid choosing the seller in kiosk mode
                    if(!options?.dontSaveSale) {
                        await askSellerIfRequired();
                    }
                    let sale = await saleUtils.getSaleTemplate();

                    if(!options?.dontSaveSale) {
                        sale = await saveSaleToStorage(sale);
                    }

                    doOpenSale(sale);

                    resolve(sale);
                } catch(error) {
                    reject(error);
                } finally {
                    isCreatingSale = null;
                }
            });
        },
        saveSale: function saveSale(newName, closeAfterSave) {
            if(newName && newName !== activeSale.currentSale.name) {
                activeSale.updateSaleDetails({ name: newName });
            }

            return saveSaleToStorage(angular.copy(activeSale.currentSale)).then(function(sale) {
                if(closeAfterSave) {
                    doOpenSale(null);
                    loadParentSaleIfPresent(sale);
                } else {
                    angular.copy(sale, pristineSale);
                }
            });
        },
        storeSale: async (options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            let saleToSend = angular.copy(activeSale.currentSale);

            if(!_.isEmpty(saleToSend)) {
                let saleToPrint = angular.copy(saleToSend);

                if(saleToSend.final_amount > 0) {
                    let cashDrawerMethods = await entityManager.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: [19, 21] });
                    let cashDrawerMethod = _.find(cashDrawerMethods, (paymentMethod) => DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(paymentMethod.payment_method_type_id));

                    if(cashDrawerMethod) {
                        await DigitalPaymentsManager.digitalPayment(saleToSend.final_amount, cashDrawerMethod.id, { sale: _.cloneDeep(saleToSend) });
                    }
                }

                await performStore(saleToSend, options);

                if (checkManager.getPreference('cashregister.print_nonfiscal_sale_on_store')) {
                    NoFiscalPrinters.printSale(saleToPrint, { reprint: activeSale.reprintSaleToOrderPrinters });
                }
            }
        },
        deleteSale: async(options) => {
            if(options?.reason) {
                activeSale.updateSaleDetails({ notes: options.reason });
            }

            let clonedCurrentSale = angular.copy(activeSale.currentSale);

            //Save sale if has been edited
            if(!activeSale.isPristine()) {
                await entityManager.sales.saveOneOffline(clonedCurrentSale, { dirty: true });
            }

            //Delete sale from the fiscal provider if necessary
            let fiscalProviderDocument = _.find(clonedCurrentSale.sale_documents, { document_type: 'fiscal_provider' });

            if(fiscalProviderDocument) {
                let fiscalProviderName = _.get(fiscalProviderDocument, 'meta.fiscal_provider');
                let fiscalProvider = FiscalProviders.getFiscalProvider(fiscalProviderName);

                if(fiscalProvider && _.isFunction(fiscalProvider.cancelFiscalDocument)) {
                    try {
                        await fiscalProvider.cancelFiscalDocument(clonedCurrentSale);
                    } catch(error) {
                        if(fiscalProvider.getProviderError) {
                            throw fiscalProvider.getProviderError(error);
                        }

                        throw error;
                    }
                }
            }

            try {
                await entityManager.sales.deleteOneOfflineFirst(activeSale.currentSale.id);
            } catch(err) {}

            finishSaleClosing(clonedCurrentSale);
        },
        addItemToSale: async (item, priceList, combinationId, quantity, barcode, overrides, options) => {
            await awaitSale();

            //Get target price
            let targetPrice;

            const menuMode = activeSale.groupItemUuid && !item.is_group_item;

            if(menuMode) {
                targetPrice = 0;
            } else if(Number.isFinite(overrides?.price)) {
                targetPrice = overrides.price;
            } else if(combinationId) {
                let combination = _.find(item.combinations, { id: combinationId });
                targetPrice = combination[`price${priceList}`];
            } else {
                targetPrice = item[`price${priceList}`];
            }

            if(!checkManager.getPreference("cashregister.add_item_as_new") && !options?.addAsNew && _.isEmpty(overrides) && _.isUndefined(quantity) && !item.is_group_item  && !item.split_group_components) {
                //Check if the item is already in the sale
                let saleItem = activeSale.currentSale.sale_items.find((saleItem) => (
                    saleItem.type === 'sale' &&
                    saleItem.item_id === item.id &&
                    saleItem.sale_item_parent_uuid == activeSale.groupItemUuid &&
                    saleItem.combination_id == combinationId &&
                    saleItem.price === targetPrice &&
                    _.isEmpty(saleItem.variations) &&
                    _.isEmpty(saleItem.ingredients)
                ));

                if (saleItem?.item_id) {
                    return activeSale.editSaleItem(saleItem, { quantity: (saleItem.quantity === -1) ? 1 : (saleItem.quantity + 1) });
                }
            }

            const saleItem = await saleUtils.getSaleItemTemplate(item, priceList, combinationId, quantity, barcode, Object.assign(_.omit(overrides, 'price'), {
                price: targetPrice,
                sale_item_parent_uuid: menuMode ? (activeSale.groupItemUuid || null) : null
            }));

            if(!saleItem?.quantity) {
                return;
            }

            checkNotDiscountableSaleItem(saleItem);

            const itemsToAdd = [];

            if(item.is_group_item || item.split_group_components) {
                activeSale.groupItemUuid = saleItem.uuid;
            }

            //Handle menus with components splitting
            if(item.split_group_components) {
                //Find the items linked to the menu and add the resulting order items
                let variationsWithItems = _.remove(saleItem.variations, 'linked_item_uuid');

                for(let variation of variationsWithItems) {
                    const linkedItemSearch = await entityManager.items.fetchCollectionOffline({ uuid: variation.linked_item_uuid });

                    if(linkedItemSearch.length) {
                        let itemToAdd = await saleUtils.getSaleItemTemplate(linkedItemSearch[0], priceList, null, 1, null, { price: 0, sale_item_parent_uuid: activeSale.groupItemUuid });

                        if(itemToAdd?.quantity) {
                            itemsToAdd.push(itemToAdd);
                        }
                    }
                }

                activeSale.resetGroupItemUuid();
            }

            itemsToAdd.unshift(saleItem);

            activeSale.currentSale.sale_items.push(...itemsToAdd);

            activeSale.calculateAllSalePrices();
            $rootScope.$broadcast('activeSale:item-added', saleItem);

            return saleItem;
        },
        cloneSaleItem: function cloneSaleItem(saleItem, numClones) {
            const now = new Date().toISOString();
            const opData = OperatorManager.getSellerData();

            if(!saleItem) {
                saleItem = currentSaleItem;
            }

            const newSaleItem = angular.copy(saleItem);
            const saleItemIdx = (_.indexOf(activeSale.currentSale.sale_items, saleItem) + 1) || activeSale.currentSale.sale_items.length;

            if(_.isNil(numClones)) {
                numClones = 1;
            }

            delete newSaleItem.id;

            Object.assign(newSaleItem,  {
                added_at: now,
                ingredients: saleUtils.getCleanSubEntity(newSaleItem.ingredients),
                lastupdate_at: now,
                lastupdate_by: opData.id,
                price_changes: saleUtils.getCleanSubEntity(newSaleItem.price_changes),
                seller_id: opData.id,
                seller_name: opData.full_name,
                variations: saleUtils.getCleanSubEntity(newSaleItem.variations)
            });

            for(let i = 0; i < numClones; i++) {
                const saleItemToInsert = _.cloneDeep(newSaleItem);
                saleItemToInsert.uuid = generateUuid();
                activeSale.currentSale.sale_items.splice(saleItemIdx, 0, saleItemToInsert);
            }

            activeSale.calculateAllSalePrices();
        },
        canChangeSaleItemQuantity: (saleItem) => (!saleItem.prize_id && !['deposit_cancellation', 'coupon'].includes(saleItem.type)),
        changeSaleItemQuantity: function(saleItem, newQuantity) {
            if(_.isFinite(newQuantity) && activeSale.canChangeSaleItemQuantity(saleItem)) {
                if(!saleItem) {
                    saleItem = currentSaleItem;
                }

                activeSale.editSaleItem(saleItem, {
                    quantity: newQuantity,
                    type: "sale",
                    refund_cause_description: null,
                    refund_cause_id: null
                });
            }
        },
        editSaleItem: (saleItem, newData, options) => {
            const opData = OperatorManager.getSellerData();
            let previousSaleItem = _.clone(saleItem);

            if(!_.isObject(newData)) {
                newData = {};
            }

            if(options?.replace) {
                //Full replace
                angular.copy(newData, saleItem);
            } else {
                //Incremental update
                let fieldsToOmit = [];

                if(!options?.overrideMenuChecks) {
                    let isItemASelectedMenu = (activeSale.groupItemUuid && saleItem.uuid === activeSale.groupItemUuid);
                    let isItemUnderUnselectedMenu = (saleItem.sale_item_parent_uuid && saleItem.sale_item_parent_uuid !== activeSale.groupItemUuid);

                    if(isItemASelectedMenu || isItemUnderUnselectedMenu) {
                        fieldsToOmit.push('quantity');
                    }

                    if(saleItem.sale_item_parent_uuid) {
                        fieldsToOmit.push('price');
                    }
                }

                let updateData = _.omit(newData, fieldsToOmit);

                if(_.isEmpty(updateData)) {
                    return;
                }

                Object.assign(saleItem, updateData);

                //If we changed the quantity of a menu, change all menu items quantity accordingly
                if(saleItem.is_group_item && updateData.quantity) {
                    let multiplierFactor = (saleItem.quantity / previousSaleItem.quantity);
                    let itemsToChange = activeSale.currentSale.sale_items.filter((si) => si.sale_item_parent_uuid === saleItem.uuid);

                    for(let si of itemsToChange) {
                        activeSale.editSaleItem(si, { quantity: Math.ceil(si.quantity * multiplierFactor) }, { overrideMenuChecks: true });
                    }
                }
            }

            //Remove the item if the quantity is 0
            if(!saleItem.quantity) {
                return activeSale.removeSaleItem(saleItem);
            }

            //Update lastupdate_at/lastupdate_by properly
            Object.assign(saleItem, {
                lastupdate_at: moment().toISOString(),
                lastupdate_by: opData.id
            });

            activeSale.calculateAllSalePrices();
            $rootScope.$broadcast("activeSale:item-changed", { currentSaleItem: saleItem, previousSaleItem: previousSaleItem } );
        },
        updateSaleDetails: (saleDetails) => {
            if(!activeSale.isActiveSale()) {
                return;
            }

            Object.assign(activeSale.currentSale, saleDetails);
            activeSale.calculateAllSalePrices();
        },
        removeSaleItem: function removeSaleItem(saleItem) {
            //TODO: use removeSaleItem from activeSale store
            const saleUpdateData = {
                sale_items: activeSale.currentSale.sale_items?.filter((si) => (si !== saleItem && si.sale_item_parent_uuid !== saleItem.uuid)) || []
            };

            //Revert not-discountable price changes in sale if there are no more not-discountable items
            if(activeSale.currentSale.price_changes?.some((pc) => pc.type === "disc_perc_nd") && saleUpdateData.sale_items.every((si) => !si.not_discountable)) {
                const updatedSalePriceChanges = structuredClone(activeSale.currentSale.price_changes || []);

                for(const priceChange of updatedSalePriceChanges.filter((pc) => pc.type === "disc_perc_nd")) {
                    for(const saleItem of saleUpdateData.sale_items) {
                        const newSalePriceChanges = saleItem.price_changes?.filter((pc) => pc.index !== priceChange.index) || [];

                        if(newSalePriceChanges.length !== saleItem.price_changes?.length) {
                            saleItem.price_changes = newSalePriceChanges;
                        }
                    }

                    priceChange.type = 'discount_perc';
                    priceChange.index -= 100;
                }

                saleUpdateData.price_changes = updatedSalePriceChanges;
            }

            activeSale.updateSaleDetails(saleUpdateData);
            
            if(saleItem.uuid === activeSale.groupItemUuid) {
                activeSale.resetGroupItemUuid();
            }

            if(saleItem === currentSaleItem) {
                currentSaleItem = undefined;
            }

            $rootScope.$broadcast("activeSale:item-removed", saleItem);
        },
        refundSaleItem: (saleItem, refundCause) => {
            return activeSale.editSaleItem(saleItem, {
                quantity: -(Math.abs(saleItem.quantity)),
                type: 'refund',
                refund_cause_id: refundCause.id,
                refund_cause_description: $translate.instant(refundCause.translation_id),
                price_changes: []
            });
        },
        cancelRefundOnSaleItem: (saleItem) => {
            return activeSale.editSaleItem(saleItem, {
                quantity: Math.abs(saleItem.quantity),
                type: 'sale',
                refund_cause_id: null,
                refund_cause_description: null,
            });
        },
        removePriceChangeFromSale: function removePriceChangeFromSale(priceChange) {
            activeSale.updateSaleDetails({
                price_changes: activeSale.currentSale.price_changes?.filter((pc) => pc !== priceChange) || []
            });

            $rootScope.$broadcast("activeSale:priceChange-removed", { target: activeSale.currentSale, priceChange: priceChange, targetType: 'sale' });
        },
        removePriceChangeFromSaleItem: async (saleItem, priceChange) => {
            activeSale.editSaleItem(saleItem, {
                price_changes: saleItem.price_changes?.filter((pc) => pc !== priceChange) || []
            });

            if(activeSale.hasNotDiscountableItems() && priceChange.index >= 100) {
                for(const saleItem of activeSale.currentSale.sale_items) {
                    const newSiPriceChanges = saleItem.price_changes?.filter((pc) => pc.index !== priceChange.index) || [];

                    if(newSiPriceChanges.length !== saleItem.price_changes?.length) {
                        activeSale.editSaleItem(saleItem, { price_changes: newSiPriceChanges });
                    }
                }

                const newSalePriceChanges = activeSale.currentSale.price_changes?.filter((pc) => (pc.type !== 'disc_perc_nd' || pc.index !== priceChange.index)) || [];

                if(newSalePriceChanges.length !== activeSale.currentSale.price_changes?.length) {
                    activeSale.updateSaleDetails({ price_changes: newSalePriceChanges });
                }
            }

            //Restore default item department if the discount is a gift
            if(priceChange.type === 'gift' && saleItem.item_id) {
                try {
                    let originalItem = await entityManager.items.fetchOneOffline(saleItem.item_id);

                    if(originalItem) {
                        activeSale.editSaleItem(saleItem, {
                            department: originalItem.department,
                            department_id: originalItem.department?.id,
                            department_name: originalItem.department?.name,
                            vat_perc: originalItem.department?.vat.value
                        });
                    }
                } catch(err) {
                    //Nothing to do
                }
            }

            $rootScope.$broadcast("activeSale:priceChange-removed", { target: saleItem, priceChange: priceChange, targetType: 'sale_item' });
        },
        removeCustomerPriceChangeFromSale: function removePriceChangeFromSale() {
            const targetDescription = $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CLIENT_DISCOUNT');

            activeSale.updateSaleDetails({
                price_changes: activeSale.currentSale.price_changes?.filter((pc) => pc.description !== targetDescription && !pc.prize_id) || []
            });

            for(const saleItem of activeSale.currentSale.sale_items) {
                if(saleItem.prize_id) {
                    activeSale.removeSaleItem(saleItem);
                }
            }
        },
        addDynamicItemToSale: async (department, price, overrides) => {
            if(!_.isObject(overrides)) {
                overrides = {};
            }

            await awaitSale();

            let saleItem = saleUtils.getDynamicSaleItemTemplate(department, price);
            Object.assign(saleItem, overrides);

            checkNotDiscountableSaleItem(saleItem);

            activeSale.currentSale.sale_items.push(saleItem);
            activeSale.calculateAllSalePrices();

            $rootScope.$broadcast('activeSale:item-added', saleItem);
            return saleItem;
        },
        applyPriceList: async(priceList) => {
            const targetDepartment = _.toInteger(priceList) === 1 ? `department`: `department${priceList}`;
            let itemsMap = await util.getItemsFromIds(activeSale.currentSale.sale_items);

            for(let saleItem of activeSale.currentSale.sale_items) {
                if(saleItem.item_id && !saleItem.sale_item_parent_uuid) {
                    let item = itemsMap[saleItem.item_id];

                    if(item) {
                        let newPrice;

                        if(saleItem.combination_id) {
                            let combinationToUse = _.find(item.combinations, { id: saleItem.combination_id });
                            newPrice = combinationToUse?.[`price${priceList}`];
                        } else {
                            newPrice = item[`price${priceList}`];
                        }

                        if(_.isFinite(newPrice)) {
                            let department = item[targetDepartment] || item['department'];

                            activeSale.editSaleItem(saleItem, {
                                department: department,
                                department_id: department?.id,
                                department_name: department?.name,
                                price: newPrice
                            });
                        }
                    }
                }
            }
        },
        isActiveSale: function isActiveSale() {
            return activeSale.currentSale.uuid || false;
        },
        isEmpty: function isEmpty() {
            return _.isEmpty(activeSale.currentSale.sale_items);
        },
        addCustomer: async(customer) => {
            //Prepare sale customer
            let saleCustomer = _.clone(customer);

            if (validateUuid(saleCustomer.id)) {
                Object.assign(saleCustomer, {
                    uuid: saleCustomer.id,
                    customer_id: null
                });
            } else {
                saleCustomer.customer_id = saleCustomer.id;
            }

            delete saleCustomer.id;

            await awaitSale();

            //Add customer to sale
            activeSale.updateSaleDetails({
                customer_tax_code: null,
                lottery_code: customer.lottery_code || activeSale.currentSale.lottery_code,
                sale_customer: saleCustomer
            });

            //Manage customer discounts
            activeSale.removeCustomerPriceChangeFromSale();

            if (saleCustomer.discount_perc) {
                activeSale.addSaleDiscountPerc(saleCustomer.discount_perc, $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CLIENT_DISCOUNT'));
            }

            $rootScope.$broadcast('activeSale:customer-added', saleCustomer);

            return saleCustomer;
        },
        removeCustomer: function removeCustomer() {
            if(activeSale.currentSale?.sale_customer?.default_pricelist) {
                $rootScope.$broadcast("activeSale:use-pricelist", { priceList: 'default' });
            }

            activeSale.updateSaleDetails({ 
                customer_tax_code: null,
                sale_customer: null
            });

            activeSale.removeCustomerPriceChangeFromSale();
        },
        addTaxCode: async (taxCode) => {
            await awaitSale();

            activeSale.updateSaleDetails({
                sale_customer: null,
                customer_tax_code: `${taxCode}`.toUpperCase()
            });
        },
        hasActiveSaleItem: function hasActiveSaleItem() {
            return !_.isUndefined(currentSaleItem);
        },
        isActiveSaleItem: function isActiveSaleItem(saleItem) {
            if (saleItem) {
                return currentSaleItem === saleItem;
            } else if (currentSaleItem) {
                if (activeSale.currentSale.sale_items && activeSale.currentSale.sale_items.includes(currentSaleItem)) {
                    return currentSaleItem.id || currentSaleItem.quantity > 0;
                } else {
                    currentSaleItem = undefined;
                    return false;
                }
            } else {
                return false;
            }
        },
        getActiveSaleItem: function getActiveSaleItem() {
            return currentSaleItem;
        },
        hasNotDiscountableItems: function hasNotDiscountableItems() {
            return _.some(activeSale.currentSale.sale_items, { not_discountable: true });
        },
        selectActiveSaleItem: function selectActiveSaleItem(saleItem) {
            //Disable selection on sale items that are part of a menu
            if(saleItem.sale_item_parent_uuid) {
                return;
            }

            currentSaleItem = currentSaleItem === saleItem ? undefined : saleItem;
            return currentSaleItem;
        },
        addSaleSurchargeFix: function addSaleSurchargeFix(value, description) {
            addGenericPriceChange(activeSale.currentSale, 'surcharge_fix', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
        },
        addSaleDiscountFix: function addSaleDiscountFix(value, description) {
            if(value > activeSale.currentSale.final_amount) {
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT_TOO_HIGH'));
            } else if(activeSale.hasNotDiscountableItems()) {
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.ITEMS_NOT_DISCOUNTABLE'));
            } else {
                addGenericPriceChange(activeSale.currentSale, 'discount_fix', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
            }
        },
        addSaleSurchargePerc: function addSaleSurchargePerc(value, description) {
            addGenericPriceChange(activeSale.currentSale, 'surcharge_perc', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
        },
        addSaleDiscountPerc: function addSaleDiscountPerc(value, description) {
            if(value > 100) {
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT_OVER_100'));
            } else if(activeSale.hasNotDiscountableItems()) {
                var priceChange = addGenericPriceChange(activeSale.currentSale, 'disc_perc_nd', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
                applySalePriceChangesOnItems(null, [priceChange]);
            } else {
                addGenericPriceChange(activeSale.currentSale, 'discount_perc', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
            }
        },
        addSaleItemSurchargeFix: function addSaleItemSurchargeFix(value, description) {
            if(currentSaleItem) {
                addGenericPriceChange(currentSaleItem, 'surcharge_fix', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
            }
        },
        addSaleItemDiscountFix: function addSaleItemDiscountFix(value, description) {
            if(currentSaleItem) {
                if (currentSaleItem.final_price === 0) {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_DISCOUNT_WITH_FREE_ITEM'));
                } else if (value > (currentSaleItem.final_price * currentSaleItem.quantity)) {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_DISCOUNT_HIGHER_THEN_AMOUNT'));
                } else if(currentSaleItem.not_discountable) {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.ITEM_NOT_DISCOUNTABLE'));
                } else {
                    addGenericPriceChange(currentSaleItem, 'discount_fix', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
                }
            }
        },
        addSaleItemSurchargePerc: function addSaleItemSurchargePerc(value, description) {
            if(currentSaleItem) {
                addGenericPriceChange(currentSaleItem, 'surcharge_perc', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SURCHARGE'));
            }
        },
        addSaleItemDiscountPerc: function addSaleItemDiscountPerc(value, description) {
            if(currentSaleItem) {
                if(value > 100) {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT_OVER_100'));
                } else if(currentSaleItem.final_price === 0) {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_DISCOUNT_WITH_FREE_ITEM'));
                } else if(currentSaleItem.not_discountable && !(checkManager.getSetting("cashregister.allow_gift_not_discountable") && value === 100)) {
                    alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.ITEM_NOT_DISCOUNTABLE'));
                } else {
                    addGenericPriceChange(currentSaleItem, 'discount_perc', value, description || $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.DISCOUNT'));
                }
            }
        },
        addPrize: async (prize) => {
            switch(prize.type) {
                case 'discount_fix':
                case 'discount_perc':
                    addGenericPriceChange(activeSale.currentSale, prize.type, prize.discount_amount, prize.name, undefined, { prize_id: prize.id });
                break;
                case 'gift':
                    let items = await entityManager.items.fetchCollectionOffline({ sku: prize.item_sku });
                    let saleItem = await activeSale.addItemToSale(_.head(items), 1, null, 1, null, { prize_id: prize.id });
                    activeSale.giftSaleItem(saleItem);
                break;
            }
        },
        giftSaleItem: function giftSaleItem(saleItem, description, newDepartment) {
            if(!saleItem) {
                saleItem = currentSaleItem;
            }

            if(!saleItem) {
                return;
            }

            if(saleItem.not_discountable && !checkManager.getSetting("cashregister.allow_gift_not_discountable")) {
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.ITEM_NOT_DISCOUNTABLE'));
                throw 'ITEM_NOT_DISCOUNTABLE';
            }

            const itemUpdateData = {
                price_changes: [
                    newSaleUtils.getGenericPriceChange(saleItem, 'gift', 100, description || $translate.instant('CASHREGISTER.ACTIVE_SALE.GIFT'))
                ]
            };

            if(newDepartment) {
                Object.assign(itemUpdateData, {
                    department: newDepartment,
                    department_id: newDepartment.id,
                    department_name: newDepartment.name,
                    vat_perc: newDepartment.vat.value
                });
            }

            activeSale.editSaleItem(saleItem, itemUpdateData);
        },
        calculateAllSalePrices: () => {
            if(!_.isEmpty(activeSale.currentSale)) {
                const saleItems = activeSale.currentSale.sale_items;

                //Drop price changes if we are in credit note mode
                if (activeSale.isCreditNote()) {
                    if(activeSale.currentSale?.price_changes?.length) {
                        activeSale.updateSaleDetails({ price_changes: [] });
                    }

                    for (const saleItem of saleItems) {
                        if(saleItem.price_changes?.length) {
                            activeSale.editSaleItem(saleItem, { price_changes: [] });
                        }
                    }
                }

                //Move sale items right below the parent item
                for(let [index, saleItem] of saleItems.entries()) {
                    if(saleItem.is_group_item) {
                        let groupItems = _.remove(saleItems, { sale_item_parent_uuid: saleItem.uuid });

                        if(groupItems.length) {
                            saleItems.splice(index + 1, 0, ...groupItems);
                        }
                    }
                }

                saleUtils.calculateSalePrices(activeSale.currentSale);

                if (!!checkManager.getPreference('cashregister.check_stock')) {
                    $rootScope.$broadcast("activeSale:updateSaleStock");
                }
            }

            updateGroupItemsLinks();
            $rootScope.$broadcast("activeSale:sale-updated", activeSale.currentSale);
        },
        checkSale: function checkSale() { //Checks the sale before printing a document
            var errors = {};
            var warnings = {};

            var hasNegativeAmount = activeSale.currentSale.final_amount < 0;

            if(_.isEmpty(activeSale.currentSale.sale_items)) {
                _.set(errors, "SALE_EMPTY", true);
            } else {
                _.forEach(activeSale.currentSale.sale_items, function(saleItem) {
                    if(saleItem.quantity > 0) {
                        if(hasNegativeAmount) {
                            _.set(errors, "NEG_AMOUNT_WITH_SALES", true);
                        }
                        if(saleItem.$partialAmount < 0) {
                            _.set(errors, "NEG_SALE_ROWS", true);
                        }
                    }
                    if (!saleItem.price && saleItem.type === 'sale' && !saleItem.sale_item_parent_uuid) {
                        if(!checkManager.getPreference('cashregister.hide_zero_price_warnings')) {
                            _.set(warnings, "ZERO_PRICE_ITEMS", true);
                        }
                    }
                });
            }

            return {
                isValid: _.isEmpty(errors),
                hasWarnings: !_.isEmpty(warnings),
                errors: _(errors).pickBy(_.isBoolean).keys().value(),
                warnings: _(warnings).pickBy(_.isBoolean).keys().value()
            };
        },
        setPrinterDocumentData: async (data) => {
            activeSale.printerDocumentData = data;

            if(activeSale.isActiveSale() && !_.isNil(activeSale.currentSale.e_invoice) && !isEInvoiceDocument(data?.document_type?.id)) {
                try {
                    let answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE.REMOVE_SALE_E_INVOICE_PROMPT'));

                    if(answer) {
                        activeSale.updateSaleDetails({ e_invoice: null });
                    } else {
                        let newDocumentData = await documentPrintersManager.getPrinterDocumentData(_.get(data, "printer.id") || 'default', ['e_invoice'], data.options);
                        activeSale.printerDocumentData = newDocumentData;
                    }
                } catch(error) {}
            }
        },
        resetDocumentData: async() => {
            try {
                let defaultDocumentData = await documentPrintersManager.getPrinterDocumentData('default', 'default');
                await activeSale.setPrinterDocumentData(defaultDocumentData);
            } catch(error) {
                activeSale.printerDocumentData = null;
                throw error;
            }
        },
        setEInvoiceData: function setEInvoiceData(eInvoiceData) {
            activeSale.updateSaleDetails({ e_invoice: eInvoiceData });
        },
        verifyPrinterDocumentDataAndSelect: async() => {
            try {
                let pDocData = activeSale.printerDocumentData;

                if (pDocData?.printer && pDocData?.document_type) {
                    await documentPrintersManager.getPrinterDocumentData(pDocData.printer.id, (pDocData?.document_template?.id || pDocData.document_type.id), (pDocData.options || {}) );
                } else {
                    await activeSale.resetDocumentData();
                }
            } catch(err) {
                let printerDocumentData = await documentPrintersManager.openDialog();
                activeSale.setPrinterDocumentData(printerDocumentData);
            }
        },
        cashPayment: async() => {
            const paymentMethods = await entityManager.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 1 });

            if(!paymentMethods.length) {
                throw 'CASHREGISTER.ACTIVE_SALE.NO_CASH_PAYMENT_AVAILABLE';
            }

            const time = new Date();
            const paymentMethod = paymentMethods[0];

            const [amount, paymentInputObject] = getFastPaymentAmount(activeSale.currentSale, paymentMethod);

            activeSale.updateSaleDetails({
                payments: [Object.assign(paymentInputObject, {
                    amount: amount,
                    date: time.toISOString(),
                    payment_method_id: paymentMethod.id,
                    payment_method_name: paymentMethod.name,
                    payment_method_type_id: 1,
                    payment_method_type_name: $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.CASH'),
                    unclaimed: false,
                })]
            });

            return activeSale.processPayments();
        },
        fastPayment: async (paymentMethod) => {
            let [amount, paymentInputObject] = getFastPaymentAmount(activeSale.currentSale, paymentMethod);

            activeSale.addGenericPayment(paymentMethod, amount, paymentInputObject, { clearPreviousPayments: true });

            return activeSale.processPayments();
        },
        addGenericPayment: async (paymentMethod, amount, paymentInputObject, options) => {
            if (!paymentInputObject) {
                paymentInputObject = {};
            }

            const availableType = paymentMethodTypes.find((type) => type.id === paymentMethod.payment_method_type_id);

            if(!availableType) {
                alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_TYPE_FOR_PAYMENT_METHOD'));
                throw 'NO_TYPE_FOR_PAYMENT_METHOD';
            }

            const time = new Date();

            const payment = Object.assign({}, paymentInputObject, {
                amount: amount || activeSale.currentSale.final_amount,
                date: time.toISOString(),
                payment_method_id: paymentMethod.id,
                payment_method_name: paymentMethod.name,
                payment_method_type_id: availableType.id,
                payment_method_type_name: availableType.name,
                unclaimed: fiscalUtils.isMethodUnclaimed(availableType.id)
            });

            const currentPayments = Array.isArray(activeSale.currentSale?.payments) && !options?.clearPreviousPayments ? activeSale.currentSale.payments.filter((p) => p.amount) : [];

            activeSale.updateSaleDetails({
                payments: [...currentPayments, payment]
            });
        },
        printNonFiscalSale: async(printer) => {
            if(!printer) {
                printer = activeSale.printerDocumentData?.printer;
            }

            if(!printer) {
                throw 'NO_PRINTER_SELECTED';
            }

            try {
                const saleToPrint = structuredClone(activeSale.currentSale);

                activeSale.printDocumentInProgress = true;
                await documentPrinter.printNonFiscalSale(saleToPrint, printer);

                //Check if the sale has changed in the meantime
                if(activeSale.currentSale.uuid === saleToPrint.uuid) {
                    activeSale.updateSaleDetails({ printed_non_fiscal_sales: (activeSale.currentSale.printed_non_fiscal_sales || 0) + 1 });
                    activeSale.saveSale();
                }
            } finally {
                activeSale.printDocumentInProgress = false;
            }
        },
        emitDocument: async (targetSale, printerDocumentData) => {
            let result = {};

            const opData = OperatorManager.getSellerData();
            let isActiveSale = _.isNil(targetSale) || targetSale === activeSale.currentSale;
            let saleToEmit = _.cloneDeep(_.isObject(targetSale) ? targetSale : activeSale.currentSale);
            let docData = _.cloneDeep(_.isObject(printerDocumentData) ? printerDocumentData : activeSale.printerDocumentData);
            let isKioskSale = saleToEmit.channel === 'kiosk' && (_.startsWith('app.kiosk', $state.current.name) || checkManager.getPreference('kiosk.payments.send_sale_to_order_printers'));
            let printerId = docData.printer.id;
            let docId = docData.document_type.id;

            if(isActiveSale) {
                if(docId === "e_invoice") {
                    activeSale.sendEInvoiceInProgress = true;
                }

                activeSale.printDocumentInProgress = true;
            }

            Object.assign(saleToEmit, {
                seller_name: opData.full_name,
                seller_id: opData.id
            });

            try {
                //Convert quick coupons in tickets
                await salePayment.quickCouponsToTickets(saleToEmit);

                let printedDocuments = await documentPrinter.printDocument(saleToEmit, docData);

                if(isEInvoiceDocument(docId)) {
                    finishSaleClosing(printedDocuments);

                    result.emittedSale = printedDocuments;
                } else {
                    try {
                        let notes = await activeSale.closeSale(saleToEmit);

                        Object.assign(result, {
                            emittedSale: saleToEmit,
                            notes: notes
                        });
                    } catch(error) {
                        result.error = $translate.instant('CASHREGISTER.ACTIVE_SALE.ERROR_WHILE_SAVING_AFTER_PRINT');
                    }
                }
            } catch(msg_error) {
                Object.assign(result, {
                    printError: msg_error,
                    printerId: printerId
                });

                if(isActiveSale && !isEInvoiceDocument(docId)) {
                    saveSaleToStorage(_.cloneDeep(activeSale.currentSale));
                }
            }

            if(isActiveSale) {
                activeSale.printDocumentInProgress = false;
                activeSale.sendEInvoiceInProgress = false;

                if(activeSale.printerDocumentData) {
                    activeSale.printerDocumentData.tail = undefined;
                }
            }

            if(!result.printError) {
                let printNFSaleOptions = { reprint: activeSale.reprintSaleToOrderPrinters, isAutoPrint: true };

                //Set the source cashregister if this sale is not from a kiosk and the auto order print is not enabled. The order will be printed only on the order printers that are linked to the printer that emitted the sale
                if(!isKioskSale && !checkManager.getPreference('cashregister.print_orders_on_sale_close')) {
                    printNFSaleOptions.sourceCashregister = printerId;
                }

                NoFiscalPrinters.printSale(result.emittedSale, printNFSaleOptions);
            }

            return result;
        },
        closeSale: async (saleToClose) => {
            let notes = [];

            if(_.isEmpty(saleToClose)) {
                return notes;
            }

            let now = moment();
            let documentDate = saleToClose.sale_documents?.[0]?.date;

            if(documentDate) {
                documentDate = moment(documentDate);

                if(!documentDate.isBetween(moment(now).subtract(10, 'minutes'), moment(now).add(10, 'minutes'))) {
                    notes.push("CHECK_PRINTER_CLOCK");
                }
            }

            if(saleToClose.is_summary && !saleToClose.sale_documents.find((saleDocument) => ['summary_invoice', 'summary_e_rc', 'summary_e_nrc', 'generic_invoice'].includes(saleDocument.document_type))) {
                saleToClose.is_summary = false;
            }

            Object.assign(saleToClose, {
                status: 'closed',
                closed_at: now.toISOString(),
                change: activeSale.getChange(saleToClose),
                secondary_final_amount: saleToClose.secondary_exchange_rate ? (saleToClose.final_amount * saleToClose.secondary_exchange_rate) : null
            });

            if(_.isEmpty(saleToClose.channel) || saleToClose.channel === 'pos') {
                var deliveryChannel = _.find(saleToClose.payments, { payment_method_type_id: 17 });

                if(deliveryChannel && !_.isEmpty(deliveryChannel.payment_data)) {
                    saleToClose.channel = deliveryChannel.payment_data;
                }
            }

            const opData = OperatorManager.getSellerData();

            let sellerData = {
                seller_id: opData.id,
                seller_name: opData.full_name
            };

            if(!saleToClose.seller_id) {
                Object.assign(saleToClose, sellerData);
            }

            _.forEach(saleToClose.sale_items, function(saleItem) {
                if(!saleItem.seller_id) {
                    Object.assign(saleItem, sellerData);
                }
            });

            await saleUtils.calculateSaleItemsCosts(saleToClose);
            let sale = await saveSaleToStorage(angular.copy(saleToClose));

            $rootScope.$broadcast('activeSale:sale-closed', angular.copy(saleToClose));
            finishSaleClosing(sale);

            return notes;
        },
        isCreditNote: () => (!_.isEmpty(activeSale.currentSale.sale_items) && _.every(activeSale.currentSale.sale_items, { type: 'refund' })),
        splitSale: async (options) => {
            if (typeof options !== 'object') {
                options = {};
            }

            if (!activeSale.currentSale.sale_items?.length) {
                return alertDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_ITEM_IN_SALE'));
            }

            activeSale.lockPaymentButtons = true;

            //If we split by amount or covers, will contain the original sale and will be stored
            let saleToStore;

            try {
                const splitResult = await splitSaleDialog.show(activeSale.currentSale, options);

                if (splitResult.storeCurrentSale) {
                    saleToStore = _.chain(activeSale.currentSale).cloneDeep().assign({ notes: "Conto separato" }).value();
                } else {
                    //Sync id changes between the original sale and the current sale
                    Object.assign(splitResult.originalSale, {
                        id: activeSale.currentSale.id,
                        sale_parent_id: activeSale.currentSale.sale_parent_id
                    });

                    const originSaleItemsByUuid = _.keyBy(activeSale.currentSale.sale_items, 'uuid');

                    for (let saleItem of splitResult.originalSale.sale_items) {
                        const originSaleItem = originSaleItemsByUuid[saleItem.uuid];

                        if (originSaleItem) {
                            saleItem.id = originSaleItem.id;
                        }
                    }

                    if (splitResult.splitSale && Number.isFinite(splitResult.originalSale.id)) {
                        splitResult.splitSale.sale_parent_id = splitResult.originalSale.id;
                    }
                }

                //Save the original sale and the split sale to storage. If the split sale doesn't exist, the original sale will be open
                let saleToOpen = await saveSaleToStorage(splitResult.originalSale);

                if (splitResult.splitSale) {
                    saleToOpen = await saveSaleToStorage(splitResult.splitSale);
                }

                doOpenSale(saleToOpen);
            } catch (err) {

            } finally {
                activeSale.lockPaymentButtons = false;

                if (saleToStore) {
                    performStore(saleToStore, { skipParentCheck: true, closeActiveSale: false });
                }
            }
        },
        isPaymentsOnSaleEmpty: function isPaymentsOnSaleEmpty() {
            return !(activeSale.currentSale?.payments?.length);
        },
        removePaymentFromSale: function removePaymentFromSale(payment) {
            activeSale.updateSaleDetails({
                payments: activeSale.currentSale?.payments?.filter(p => p !== payment) || []
            });

            activeSale.hasPaidPayments = activeSale.currentSale.payments.some(p => p.paid);
        },
        cleanPaymentsFromSale: function cleanPaymentsFromSale() {
            const currentPayments = activeSale.currentSale?.payments || [];
            const newPayments = currentPayments.filter(p => p.paid);

            if(newPayments.length !== currentPayments.length) {
                activeSale.updateSaleDetails({
                    payments: newPayments
                });
            }
        },
        getToPay: function getToPay(targetSale) {
            if(!_.isObject(targetSale)) {
                targetSale = activeSale.currentSale;
            }

            return salePayment.getToPay(targetSale);
        },
        getPaid: function getPaid() {
            return salePayment.getPaid(activeSale.currentSale);
        },
        getChange: function getChange(targetSale) {
            if(!_.isObject(targetSale)) {
                targetSale = activeSale.currentSale;
            }

            return salePayment.getSaleChange(targetSale);
        },
        processSaleChange: async (targetSale) => {
            if(!_.isObject(targetSale)) {
                targetSale = activeSale.currentSale;
            }

            salePayment.processSaleChange(targetSale);

            //If the sale doesn't have change or we are not processing the current sale, do nothing
            if(!targetSale.change || targetSale !== activeSale.currentSale) {
                return;
            }

            //Process left change
            const departments = await entityManager.departments.fetchCollectionOffline();

            //Prepaid change check
            if(connection.isOnline() && prepaidSale.isEnabled() && validateUuid(targetSale.sale_customer?.uuid) && targetSale.sale_customer.fidelity) {
                const rechargeDepartmentId = parseInt(checkManager.getPreference('prepaid.recharge_department_id'));
                const rechargeDepartment = rechargeDepartmentId ? departments.find(department => department.id === rechargeDepartmentId) : undefined;

                if(rechargeDepartment) {
                    const answer = await confirmDialog.show($translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.WANT_TO_RELOAD_WITH_CHANGE', { value: $filter('sclCurrency')(targetSale.change) }));

                    if(answer) {
                        await activeSale.addDynamicItemToSale(rechargeDepartment, targetSale.change);

                        delete targetSale.change_type;
                        delete targetSale.change;

                        return;
                    }
                }
            }

            if(targetSale.change_type === 'ticket' && checkManager.getPreference('fiscalprinter.ticket_change')) {
                const ticketDepartment = departments.find(department => department.vat?.code === 'N2');

                if(ticketDepartment) {
                    await activeSale.addDynamicItemToSale(ticketDepartment, targetSale.change, { name: $translate.instant('CASHREGISTER.ACTIVE_SALE.TICKET_CHANGE_ITEM_NAME'), not_discountable: true });

                    return;
                } else {
                    throw 'MISSING_TICKET_CHANGE_DEPARTMENT';
                }
            }

            if(targetSale.change_type === 'ticket') {
                throw 'UNHANDLED_TICKET_CHANGE';
            }
        },
        processPayments: async (targetSale, pDocData) => {
            if(!_.isObject(targetSale)) {
                targetSale = activeSale.currentSale;
            }

            if(!_.isObject(pDocData)) {
                pDocData = activeSale.printerDocumentData;
            }

            $rootScope.$broadcast('activeSale:payment-in-progress');

            return salePayment.processPayments(targetSale, pDocData);
        },
        hasOnlineDigitalPayment: () => salePayment.hasOnlineDigitalPayment(activeSale.currentSale),
        getCustomerPrepaidInfo: async() => {
            let customer_uuid = activeSale.currentSale.sale_customer?.uuid;
            let customer_fidelity = activeSale.currentSale.sale_customer?.uuid;
            let result;

            if(customer_uuid && customer_fidelity) {
                let movements = await restManager.getList('prepaid_movements', { customer_uuid: customer_uuid, valid_to: 'null', pagination: 'false' });

                if(!_.isEmpty(movements)) {
                    let lastMovement = _.head(movements);

                    result = {
                        credit: lastMovement.credit,
                        ticket_credit: lastMovement.ticket_credit,
                        total_credit: lastMovement.credit + lastMovement.ticket_credit
                    };
                } else {
                    result = {
                        credit: 0,
                        ticket_credit: 0,
                        total_credit: 0
                    };
                }
            }

            return result;
        },
        checkPrepaidPaymentStatus: async(prepaidCardMethod) => {
            prepaidCardMethod.$disabled = true;

            let result = {
                $disabled: true
            };

            let customer_uuid = activeSale.currentSale.sale_customer?.uuid;
            let customer_fidelity = activeSale.currentSale.sale_customer?.uuid;

            if(customer_uuid && customer_fidelity) {
                result.customer_uuid = customer_uuid;

                prepaidCardMethod.$info = $translate.instant('CASHREGISTER.PAYMENTS.UPDATE_CREDIT');

                try {
                    let creditStatus = await activeSale.getCustomerPrepaidInfo();

                    if(creditStatus.total_credit) {
                        result.$disabled = false;
                    }

                    Object.assign(prepaidCardMethod, {
                        $disabled: result.$disabled,
                        $info: $translate.instant('CASHREGISTER.PAYMENTS.CREDIT', {value: $filter('sclCurrency')(creditStatus.total_credit)})
                    });
                } catch(err) {
                    prepaidCardMethod.$info = $translate.instant('CASHREGISTER.PAYMENTS.CONNECTION_ERROR');
                }
            } else {
                prepaidCardMethod.$info = $translate.instant('CASHREGISTER.PAYMENTS.CUSTOMER_NOT_SELECTED');
            }

            return result;
        }
    };

    const updateSaleItemIDs = (source, target) => {
        if(!Array.isArray(target.sale_items) || !Array.isArray(source.sale_items)) {
            return;
        }

        //Align source sale items id with target sale items id, if an uuid has already been used, regenerate id and uuid
        const usedUuids = {};
        const sourceItemsByUuid = _.keyBy(source.sale_items, 'uuid');

        for(let saleItem of target.sale_items) {
            if(usedUuids[saleItem.uuid]) {
                delete saleItem.id;
                saleItem.uuid = generateUuid();
            } else {
                usedUuids[saleItem.uuid] = true;

                const sourceItem = sourceItemsByUuid[saleItem.uuid];

                if(sourceItem) {
                    saleItem.id = sourceItem.id;
                }
            }
        }
    };

    const updateSaleIDs = (source, target) => {
        if(target.id) {
            if(source.uuid === target.id) {
                target.id = source.id;
                updateSaleItemIDs(source, target);
            } else if(source.id === target.id) {
                updateSaleItemIDs(source, target);
            } else if(source.uuid && source.uuid === target.sale_parent_uuid) {
                target.sale_parent_id = source.id;
            }
        }
    };

    $rootScope.$on("OfflineFirst-sales:completed", async (event, data) => {
        //Disable if new cashregister module is active
        if(checkManager.isModuleAngular('tables_and_cashregister')) {
            return;
        }

        const sale = data.entity;

        updateSaleIDs(sale, activeSale.currentSale);

        if(["closed", "stored"].includes(sale.status)) {
            let departments = await entityManager.departments.fetchCollectionOffline();
            let departmentGiftCardsMap = _.chain(departments).filter('giftcard_type_uuid').keyBy('id').value();

            //Find an item that is linked to a giftcard
            let hasGiftCard = !!sale.sale_items.find((saleItem) => (departmentGiftCardsMap[saleItem.department_id]));

            if(hasGiftCard) {
                let targetPrinter = _.chain(sale.sale_documents).head().get('printer_id').value();

                if(targetPrinter) {
                    GiftCardPrinter.printSaleGiftCards(sale.uuid, targetPrinter);
                }
            }
        }
    });

    $rootScope.$on("entity-updated:sales", async (event, data) => {
        //Disable if new cashregister module is active
        if(checkManager.isModuleAngular('tables_and_cashregister')) {
            return;
        }

        let newSale = await entityManager.sales.fetchOneOffline(data.id);

        //Concurrency handling section
        if (activeSale.isActiveSale() && data.id === activeSale.currentSale.id && !activeSale.sendEInvoiceInProgress) {
            switch(data.action) {
                case 'UPDATED':
                    let oldSale = activeSale.currentSale;
                    _.remove(oldSale.sale_items, function(saleItem) { return saleItem.id; });
                    newSale.sale_items = _(newSale.sale_items).unionBy(oldSale.sale_items, 'uuid').sortBy('added_at').value();
                    doOpenSale(newSale, { skipResetDocumentData: true });
                break;
                case 'DELETED':
                case 'CLOSED':
                    activeSale.loadSale(null, { skipStore: true });
                    $rootScope.$broadcast("activeSale:changed", data.noOrigin ? null : _.toLower(data.action));
                break;
                default: break;
            }
        }

        if(ExternalSalesManager.isExternalSalesPrintEnabled() && /*data.externalClient &&*/ _.get(newSale, 'seller_id') === 0) {
            const saleToPrint = (activeSale.isActiveSale() && data.id === activeSale.currentSale.id) ? activeSale.currentSale : newSale;

            if(saleToPrint !== activeSale.currentSale) {
                saleUtils.calculateSalePrices(saleToPrint);
            }

            //Remove unpaid payments and check if the sale is paid
            //TODO: this check shouldn't modify newSale
            _.remove(newSale.payments, { paid: false });

            if(activeSale.getToPay(saleToPrint) <= 0) {
                const docType = !_.isEmpty(saleToPrint.e_invoice) ? 'e_invoice' : 'default';
                const useEReceipt = (saleToPrint.sale_customer?.email && !saleToPrint.sale_customer?.disable_mail_receipts);

                try {
                    let documentData = await documentPrintersManager.getPrinterDocumentData('default', docType, { eReceipt: useEReceipt });

                    await activeSale.processPayments(saleToPrint, documentData);
                    await activeSale.processSaleChange(saleToPrint);

                    let result = await  activeSale.emitDocument(saleToPrint, documentData);

                    if(result.printError) {
                        throw result.printError;
                    }
                } catch(error) {
                    toast.show({
                        message: printerErrorFiscal.translateError(error),
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                }
            }
        }
    });

    $rootScope.$on("storage-updated:printers", function(event, data) {
        //Disable if new cashregister module is active
        if(checkManager.isModuleAngular('tables_and_cashregister')) {
            return;
        }

        if(((data.id && _.get(activeSale, ['printerDocumentData', 'printer', 'id']) === data.id)) || !activeSale.printerDocumentData) {
            activeSale.resetDocumentData();
        }
    });

    return activeSale;
}
