import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('kiosk').controller('KioskServiceSelectionCtrl', KioskServiceSelectionCtrl);

KioskServiceSelectionCtrl.$inject = ["$scope", "$state", "$mdDialog", "kioskSettings", "kioskUtils", "numberInputDialog"];

function KioskServiceSelectionCtrl($scope, $state, $mdDialog, kioskSettings, kioskUtils, numberInputDialog) {
    if(kioskSettings['service_selection.enabled'] === false) {
        kioskUtils.skipModule();
    } else {
        var buttonTextColor = kioskSettings['general.base_button_text_color'] || '#fff';
        var buttonBackgroundColor = kioskSettings['general.base_button_bgcolor'] || "#666";
        var baseTextColor = kioskSettings['general.base_text_color'] || '#000';
        var zoomLevel = kioskSettings['general.zoom_level'] || 1.5;

        Object.assign($scope, {
            headerParams: {
                bannerUrl: kioskSettings['service_selection.banner_url'],
                //bannerUrl: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4',
                bannerBgColor: kioskSettings['general.header_background_color'],
                bannerHeight: kioskSettings['general.header_height'],
                zoomLevel: zoomLevel,
                logoUrl: _.toString(kioskSettings['general.media_url_logo']),
            },
            customerAssistantEnabled: kioskSettings['general.customer_assistant'],
            title: kioskUtils.getLabelText(kioskSettings, 'service_selection.title_label', 'KIOSK.SERVICE_SELECTION.TITLE'),
            serviceSelectionStyle: {
                'zoom': zoomLevel,
                color: baseTextColor
            },
            kioskFooterStyle: { 'zoom': zoomLevel },
            takeAwayLabel: kioskUtils.getLabelText(kioskSettings, 'service_selection.takeaway_label', 'KIOSK.SERVICE_SELECTION.TAKE_AWAY'),
            onSiteLabel: kioskUtils.getLabelText(kioskSettings, 'service_selection.onsite_label', 'KIOSK.SERVICE_SELECTION.ON_SITE'),
            takeAwayImage: kioskSettings['service_selection.takeaway_image_url'] || 'assets/images/kiosk/service_takeaway.jpg',
            onSiteImage: kioskSettings['service_selection.onsite_image_url'] || 'assets/images/kiosk/service_onsite.jpg',
            backButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.back_button_label', 'KIOSK.BACK'),
            nextButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.next_button_label', 'KIOSK.NEXT'),
            totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
            buttonTextColor: kioskSettings['general.base_button_text_color'],
            iconStyle: { color: buttonTextColor },
            buttonStyle: {
                color: buttonTextColor,
                'background-color': buttonBackgroundColor
            },
            backgroundUrl: kioskSettings['service_selection.background_url']
        });

        if($scope.backgroundUrl) {
            $scope.mediaType = kioskUtils.getMediaType($scope.backgroundUrl);
        }

        $scope.next = async (type) => {
            let tableNumber = null;

            if(type === 'on_site' && kioskSettings['service_selection.ask_table_number']) {
                tableNumber = await numberInputDialog.show(kioskUtils.getLabelText(kioskSettings, "service_selection.table_number_prompt_label", 'KIOSK.SERVICE_SELECTION.INSERT_TABLE_NUMBER'), {
                    confirmText: kioskUtils.getLabelText(kioskSettings, null, 'KIOSK.SERVICE_SELECTION.INSERT_TABLE_NUMBER_CONFIRM'),
                });
            }

            kioskUtils.setKioskSaleData({
                'type': type,
                'tableNumber': tableNumber
            });

            $scope.$parent.enableTimeouts();
            $state.go(kioskUtils.getNextModule());
        };

        $scope.back = function() {
            $scope.$parent.enableTimeouts();
            $state.go(kioskUtils.getPreviousModule());
        };

        $scope.help = () => {
            // @toDO
            console.debug('AIUTO Da completare');
        };

        $scope.$on('kiosk:standby', function(event) {
            $mdDialog.cancel();
        });
    }
}