import { CommonModule } from '@angular/common';
import { Component, Injectable, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatActionList, MatListItem } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { lastValueFrom } from 'rxjs';

type dialogData = {
    theme: string;
}

@Component({
  selector: 'app-select-theme-dialog',
  templateUrl: './select-theme-dialog.component.html',
  standalone: true,
  imports: [
       CommonModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent,
       TilbyDialogToolbarComponent, MatDialogModule, MatActionList, MatListItem, TranslateModule
  ]
})
export class SelectThemeDialogComponent implements OnInit {

    protected readonly data: dialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);

    themes : string[] = ['LIGHT', 'DARK'];
    selectedTheme = 'LIGHT';

    ngOnInit() {
        this.selectedTheme = this.data.theme;
    }

    changeTheme(theme: string) {
        this.selectedTheme = theme;
        this.switchThemeAndLoad();
    }

    private switchThemeAndLoad() {
        this.matDialogRef?.close({theme: this.selectedTheme});
    }
}

@Injectable({
    providedIn: 'root'
})
export class SelectThemeDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data: any) {

        const config: NonNullableConfigData<any> = {
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data
        };

        return lastValueFrom(this.dialogRef.open(SelectThemeDialogComponent, config).afterClosed()).then((theme: any) => {
            return theme;
        });
    }
}
