import * as angular from 'angular';

angular.module("application").constant("taxRegimes", [
    {
        id: 'RF01',
        name: 'Ordinario'
    },{
        id: 'RF02',
        name: 'Contribuenti minimi'
    },{
        id: 'RF04',
        name: 'Agricoltura e attività connesse e pesca'
    },{
        id: 'RF05',
        name: 'Vendita sali e tabacchi'
    },{
        id: 'RF06',
        name: 'Commercio dei fiammiferi'
    },{
        id: 'RF07',
        name: 'Editoria'
    },{
        id: 'RF08',
        name: 'Gestione di servizi di telefonia pubblica'
    },{
        id: 'RF09',
        name: 'Rivendita di documenti di trasporto pubblico e di sosta'
    },{
        id: 'RF10',
        name: 'Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al D.P.R. n. 640/72'
    },{
        id: 'RF11',
        name: 'Agenzie di viaggi e turismo'
    },{
        id: 'RF12',
        name: 'Agriturismo'
    },{
        id: 'RF13',
        name: 'Vendite a domicilio'
    },{
        id: 'RF14',
        name: 'Rivendita di beni usati, di oggetti d’arte, d’antiquariato o da collezione'
    },{
        id: 'RF15',
        name: 'Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione'
    },{
        id: 'RF16',
        name: 'IVA per cassa P.A.'
    },{
        id: 'RF17',
        name: 'IVA per cassa'
    },{
        id: 'RF18',
        name: 'Altro'
    },{
        id: 'RF19',
        name: 'Forfettario'
    }
]);