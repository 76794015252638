import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ObjDataData} from "../../features/dashboard/models/dashboard.model";
import {DashboardReportGeneratorService} from "./dashboard-dialog-report-generator.service";

@Component({
    selector: 'app-fiscal-printer-dashboard-dialog',
    templateUrl: './fiscal-printer-dashboard-dialog.component.html',
    styleUrls: ['./fiscal-printer-dashboard-dialog.component.scss']
})
export class FiscalPrinterDashboardDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<FiscalPrinterDashboardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ObjDataData,
        private dashboardReportGeneratorService:DashboardReportGeneratorService
    ) {
    }

    get saleObj(){
        return this.data;
    }

    cancel() {
        this.dialogRef.close({cancel: true});
    };

    canShare(){
        return this.dashboardReportGeneratorService.canShare();
    }
    canDownloadFiles(){
        return this.dashboardReportGeneratorService.canDownloadFiles();
    }

    shareDetails(){
        return this.dashboardReportGeneratorService.shareDetails(this.data);
    }
    printDetails(){
        return this.dashboardReportGeneratorService.printDetails(this.data);
    }
    emailDetails(){
        return this.dashboardReportGeneratorService.emailDetails(this.data);
    }
    downloadDetails(){
        return this.dashboardReportGeneratorService.downloadDetails(this.data);
    }

}
