import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').component('showcaseGrid', {
    bindings: {
        itemsSet: '<',
        priceList: '<',
        onItemPress: '<',
        onItemTap: '<', 
        getStockStatus: '<',
        gridCols: '<',
    },
    template: require('./showcase-grid.html'),
    controller: ["checkManager", function(checkManager) {
        this.$onInit = () => {
            this.nameStyle = {
                'font-size': _.toInteger(checkManager.getPreference('showcase_grid.name_size') || 16) + 'px'
            };
        };

        this.$onChanges = (changes) => {
            if(changes.priceList) {
                this.priceIdentifier = `price${changes.priceList.currentValue}`;
            }
        };
    }]
});