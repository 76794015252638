<mat-progress-bar mode="query" *ngIf="inProgress"></mat-progress-bar>
<div mat-dialog-title class="tw-flex tw-flex-row tw-items-center tw-pt-4">
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
    <h3 class="tw-flex-1" translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.TITLE_PIN</h3>
    <button mat-button color="primary" (click)="confirm()" [disabled]="!editPinDialogForm.valid || inProgress">
        <span translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.CONFIRM</span>
    </button>
</div>
<mat-dialog-content>
    <form autocomplete="off" [formGroup]="editPinDialogForm" class="tw-flex tw-flex-col">
        <mat-form-field appearance="fill" class="tw-flex-auto">
            <mat-label translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.PIN</mat-label>
            <input matInput formControlName="pin" type="password" minlength="4" maxlength="4" cdkFocusInitial>
            <mat-error *ngIf="pin?.hasError('minlength')">{{'NG_MESSAGES.VALUE_TOO_SHORT' | translate}}</mat-error>
            <mat-error *ngIf="pin?.hasError('maxlength')">{{'NG_MESSAGES.VALUE_TOO_LONG' | translate}}</mat-error>
        </mat-form-field>
        <div class="mat-mdc-form-field-error tw-px-4" *ngIf="errorPin">{{errorPinMessage}}</div>
    </form>
</mat-dialog-content>
