import {
    Component,
    Injectable,
    OnInit,
    inject,
    signal
} from '@angular/core';

import {
    CommonModule
} from '@angular/common';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';

import {
    TranslateModule
} from '@ngx-translate/core';

import {
    MathUtils,
    mobileCheck
} from "@tilby/tilby-ui-lib/utilities";

import {
    Categories,
    Departments,
    Items
} from 'tilby-models';

import {
    lastValueFrom
} from 'rxjs';

import {
    BarcodeManagerService,
    ConfigurationManagerService,
    ConnectionService,
    EntityManagerService,
    EnvironmentInfoService
} from 'src/app/core';

import {
    OpenDialogsService
} from 'src/app/dialogs/services';

import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    TilbyMagicFormComponent
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';

import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { CashregisterStateService } from 'src/app/features';

type AddNewItemToSaleFormValue = {
    name: string;
    barcode: string;
    quantity: number;
    price: number;
    categoryId: number;
    departmentId: number;
    addToInventory: boolean;
}

type AddNewItemToSaleForm = CustomFormGroup<CustomForm<AddNewItemToSaleFormValue>>;

export type AddNewItemToSaleType = "sale" | "deposit_cancellation" | "coupon";

type AddNewItemToSaleParams = {
    type: AddNewItemToSaleType,
    barcode?: any,
    price?: any,
};

type AddNewItemToSaleDialogOutput = { item: Items, quantity: number }

@Component({
    selector: 'app-add-new-item-to-sale-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogToolbarComponent,
        TilbyMagicFormComponent,
        TranslateModule
    ],
    templateUrl: './add-new-item-to-sale-dialog.component.html',
    styleUrls: ['./add-new-item-to-sale-dialog.component.scss'],
    providers: []
})
class AddNewItemToSaleDialogComponent implements OnInit {
    protected readonly data: AddNewItemToSaleParams = inject(MAT_DIALOG_DATA);
    protected readonly mobileCheck = mobileCheck();

    private readonly barcodeManagerService = inject(BarcodeManagerService);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly connectionService = inject(ConnectionService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);

    private categories: Categories[] = [];
    private departments: Departments[] = [];

    protected operationInProgress = signal(false);

    private _type: AddNewItemToSaleType = 'sale';
    protected _form?: AddNewItemToSaleForm;

    public async ngOnInit() {
        this.categories = await this.entityManagerService.categories.fetchCollectionOffline();
        this.departments = await this.entityManagerService.departments.fetchCollectionOffline();
        this._type = this.data.type || 'sale';

        const isTypeDepositCancellation = ['deposit_cancellation'].includes(this._type);
        const isTypeCoupon = ['coupon'].includes(this._type);

        const name = (isTypeDepositCancellation || isTypeCoupon)
            ? this.translateService.instant(`CASHREGISTER.ADD_NEW_ITEM_TO_SALE.${this._type.toUpperCase()}`)
            : '';

        this.departments = isTypeDepositCancellation
            ? this.departments.filter(department => department.sales_type == 'goods')
            : this.departments;

        this._form = new CustomFormGroup<CustomForm<AddNewItemToSaleFormValue>>({
            name: new CustomFormControl(
                name,
                { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(255)] },
                {
                    ...new CustomFormControlProps(),
                    label: this._type == "sale" ? "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.NAME" : "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.DESCRIPTION",
                    class: "tw-w-5/12"
                }
            ),
            barcode: new CustomFormControl(
                this.data.barcode,
                { validators: [Validators.minLength(1), Validators.maxLength(255)] },
                {
                    ...new CustomFormControlProps(),
                    label: "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.BARCODE",
                    class: "tw-w-5/12",
                    ...(this.environmentInfoService.isCameraBarcodeSupported() && { customActions: { suffix: { icon: "photo_camera", callback: () => this.openCameraBarcode() } } })
                }
            ),
            quantity: new CustomFormControl(
                1,
                { validators: [Validators.required, Validators.min(1)] },
                {
                    ...new CustomFormControlProps(),
                    label: "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.QUANTITY",
                    class: "tw-w-5/12",
                    inputType: "number"
                }
            ),
            price: new CustomFormControl(
                this.data.price,
                { validators: [Validators.required, Validators.min(0)] },
                {
                    ...new CustomFormControlProps(),
                    label: "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.PRICE",
                    class: "tw-w-5/12",
                    inputType: "number"
                }
            ),
            categoryId: new CustomFormControl(
                undefined,
                {},
                {
                    ...new CustomFormControlProps(),
                    label: "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.CATEGORY",
                    class: "tw-w-5/12",
                    inputType: 'select',
                    inputChoices: [
                        { key: 'CASHREGISTER.ADD_NEW_ITEM_TO_SALE.NO_CATEGORY', value: -1 },
                        ...this.categories.map((c) => ({ key: c.name, value: c.id || -1 }))
                    ]
                }
            ),
            departmentId: new CustomFormControl(
                undefined,
                { validators: [Validators.required] },
                {
                    ...new CustomFormControlProps(),
                    label: "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.DEPARTMENT",
                    class: "tw-w-5/12",
                    inputType: 'select',
                    inputChoices: this.departments.map((d) => ({ key: d.name, value: d.id || -1, disableTranslate: true }))
                }
            ),
            addToInventory: new CustomFormControl(
                false,
                {},
                {
                    ...new CustomFormControlProps(),
                    label: "CASHREGISTER.ADD_NEW_ITEM_TO_SALE.SAVE_TO_CATALOG",
                    class: "",
                    inputType: (this._type === 'sale' && this.connectionService.isOnline() && this.configurationManagerService.isModuleEnabled("items"))
                        ? 'slideToggle'
                        : 'hidden'
                }
            ),
        });
    }

    public async confirm() {
        if (this.operationInProgress() || !this._form?.valid) {
            return;
        }

        //Get item data from form
        const { name, barcode, price, categoryId, departmentId, addToInventory, quantity } = this._form.value;

        const chosenDepartment = this.departments.find((department) => department.id == departmentId);
        const chosenCategory = this.categories.find((category) => category.id == categoryId);

        const dataToSend: Items = {
            name: name!,
            barcodes: barcode ? [{ barcode: barcode }] : [],
            category_id: categoryId && categoryId > 0 ? categoryId : undefined,
            department_id: departmentId,
            net_price: MathUtils.round((price || 0) / (1 + (chosenDepartment?.vat?.value || 0) / 100)),
            price1: price || 0
        };

        const currentPriceList = this.cashregisterStateService.priceList();

        if (currentPriceList !== 1) {
            dataToSend[`price${currentPriceList}` as "price1"] = price || 0;
        }

        let result: Items | null = null;

        if (addToInventory && this.connectionService.isOnline()) {
            //if we want to save the item, send it to the API and use the response
            try {
                this.operationInProgress.set(true);
                this.openDialogsService.openSnackBarTilby('CASHREGISTER.ADD_NEW_ITEM_TO_SALE.ADD_TO_INVENTORY', 'TOAST.SAVED.ACTION', { duration: 3000 });

                result = await this.entityManagerService.items.postOneOnline(dataToSend)
            } catch (err: any) {
                this.openDialogsService.openSnackBarTilby('CASHREGISTER.ADD_NEW_ITEM_TO_SALE.ADD_TO_INVENTORY_FAILED', 'TOAST.SAVED.ACTION');
            } finally {
                this.operationInProgress.set(false);
            }
        } else {
            // if we don't want to save the item, add the department/category data and just return it to the caller
            result = {
                ...dataToSend,
                category: chosenCategory,
                department: chosenDepartment
            };
        }

        if (result) {
            const finalQuantity = ['deposit_cancellation', 'coupon'].includes(this._type) ? -1 : quantity || 0;

            this.matDialogRef.close({ item: result, quantity: finalQuantity });
        }
    }

    // FUNTIONS IN CREATE FORM
    private async openCameraBarcode() {
        let lotteryCode = await this.barcodeManagerService.openCameraBarcode();
        this._form?.patchValue({ barcode: lotteryCode });
    }
}

@Injectable({
    providedIn: 'root',
})
export class AddNewItemToSaleDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public async openDialog(config: NonNullableConfigData<AddNewItemToSaleParams>): Promise<AddNewItemToSaleDialogOutput | null> {
        const dialogConfig: NonNullableConfigData<AddNewItemToSaleParams> = {
            ...this.switchMobileDesktopDimensions({ width: '800px' }),
            ...config
        };

        return lastValueFrom(this.dialogRef.open(AddNewItemToSaleDialogComponent, dialogConfig).afterClosed());
    }
}