import {Component, EventEmitter} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {GridService} from "../grid.service";

@Component({
    selector: 'app-grid-clickable-type-button',
    templateUrl: './grid-clickable-type-button.component.html',
    styleUrls: ['./grid-clickable-type-button.component.scss'],
})
export class GridClickableTypeButtonComponent implements ICellRendererAngularComp {
    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        // if (this.crudService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id))>-1) this.hide=true;
    }

    constructor(private gridService:GridService) {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    // click($event: MouseEvent){
    //     this.preventSelection($event);
    //     (<GridComponent>this.params.context.componentParent).clickableButton.emit({
    //         method: 'read',
    //         id: this.params.data.id
    //     })
    // }
    //
    // preventSelection($event: MouseEvent) {
    //     console.log('child', $event)
    //     $event.stopPropagation();
    //
    // }



}
