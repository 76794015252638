<!--TOPBAR    -->
<!--3 COLUMNS-->
<!--article--><!--cards--><!--asides-->
<app-skeleton-topbar></app-skeleton-topbar>
<article class="tw-grid tw-grid-cols-4 tw-gap-12 tw-mx-12 tw-p-7">
    <section class="tw-col-span-1 tw-flex tw-flex-col tw-gap-3.5" ><app-skeleton-article></app-skeleton-article></section>
    <section class="tw-col-span-2 tw-flex tw-flex-col tw-gap-3.5" ><app-skeleton-card *ngFor="let x of [1,2,3,4]"></app-skeleton-card></section>
    <section class="tw-col-span-1 tw-flex tw-flex-col tw-gap-3.5" ><app-skeleton-contact *ngFor="let x of [1,2]"></app-skeleton-contact></section>
</article>

