import { Component, Inject, inject } from "@angular/core";
import { AddBookingsTimeDialogStateService, AddBookingsTimeFormDialogData, AddBookingsTimeFormValue } from "./add-bookings-time-dialog.state.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { PortalConfigData } from "../services";
import { MagicPortalDialogComponent } from '../magic-portal-dialog';
import { SettingsAlvoloContentComponent } from "src/app/features";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { TilbyInputComponent, TilbyMagicFormComponent, TilbySelectInputComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { itemSelector } from "app/ajs-upgraded-providers";

@Component({
    selector: 'app-bookings-time-dialog',
    templateUrl: './add-bookings-time-dialog.component.html',
    styleUrls: ['./add-bookings-time-dialog.component.scss'],
    standalone: true,
    imports: [
        TilbyMagicFormComponent,
        TilbySelectInputComponent,
        TilbyInputComponent,
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
    ],
    providers:[OnDestroyService]
})
export class AddBookingsTimeDialogComponent {
    protected readonly addBookingsTimeDialogStateService = inject(AddBookingsTimeDialogStateService);
    protected readonly onDestroyService = inject(OnDestroyService);
    protected readonly itemSelectorService = inject(itemSelector);
    timeSlotFormGroup: any;

    constructor(
        public dialogRef: MatDialogRef<SettingsAlvoloContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputItemDataMagicForm<AddBookingsTimeFormValue>,
    ){
        this.subscriptions();
    }

    cancel() {
        this.dialogRef.close({ cancel: true });
    }

    private subscriptions(){
        MagicPortalDialogComponent.confirm$
        .pipe(this.onDestroyService.takeUntilDestroy)
        .subscribe((res: PortalConfigData<AddBookingsTimeFormDialogData, AddBookingsTimeDialogComponent>) => this.addBookingsTimeDialogStateService.confirm(res.form.value));
    }
}