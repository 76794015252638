import { KeyValue } from "@angular/common";
import { Component, Injectable, Injector, inject, runInInjectionContext } from "@angular/core";
import { Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogProgressBarComponent, TilbyDialogTabsComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps, TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { lastValueFrom } from "rxjs";
import { ConfigurationManagerService, PrinterDocumentType } from "src/app/core";
import { NonFiscalDocuments, Printers } from "tilby-models";
import { DocumentPrintersManagerDialogStateService } from "../document-printers-manager-dialog";
import { subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import { OpenDialogsService } from "../services";
import { TranslateModule } from "@ngx-translate/core";

type AvailableDocuments = { document_type: PrinterDocumentType, printer: Printers, document_template?: NonFiscalDocuments }
type FE_Printers = Printers & { availableDocuments?: AvailableDocuments[] };

type AutomaticPrintSettingsDialogData = { documentPrinters: FE_Printers[], nonFiscalDocuments: NonFiscalDocuments[] };

type AutomaticPrintSettingsDialogFormFields = {
    default_printer: string,
    default_document: string
};

@Component({
    selector: "app-automatic-print-settings-dialog",
    templateUrl: "./automatic-print-settings-dialog.component.html",
    styleUrls: ["./automatic-print-settings-dialog.component.scss"],
    standalone: true,
    imports: [
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        TilbyMagicFormComponent,
        MatDialogModule,
        TranslateModule
    ]
})
export class AutomaticPrintSettingsDialogComponent {
    protected readonly data: AutomaticPrintSettingsDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly documentPrintersManager = inject(DocumentPrintersManagerDialogStateService);
    private readonly injector = inject(Injector);
    private readonly openDialogsService = inject(OpenDialogsService);

    default_receipt_printer_data: KeyValue<string, number>[] = [];
    default_document_receipts_data: KeyValue<string, string>[] = [];
    defaultSalePrinter?: FE_Printers;
    defaultSaleDocument?: AvailableDocuments;
    automaticPrintSettingsForm!:CustomFormGroup<CustomForm<AutomaticPrintSettingsDialogFormFields>>;
    documentPrinters = this.data.documentPrinters;
    nonFiscalDocuments: NonFiscalDocuments[] = [];
    dialogJustOpened = true;
    

    constructor() {
        this.documentPrintersManager.scanPrintersCapabilities(this.data.documentPrinters, this.data.nonFiscalDocuments, {});

        for(const printer of this.documentPrinters) {
            printer.availableDocuments = printer.availableDocuments?.filter(doc => !['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(doc.document_type.id)) || [];
        }

        const defaultPrinterId = parseInt(this.configurationManagerService.getPreference('cashregister.batch_sale_print.default_printer_id') || '');

        if(defaultPrinterId) {
            this.defaultSalePrinter = this.documentPrinters.find(printer => printer.id === defaultPrinterId)!;

            let defaultDocumentId = this.configurationManagerService.getPreference('cashregister.batch_sale_print.default_document_id');

            if(defaultDocumentId) {
                if(parseInt(defaultDocumentId)) {
                    this.defaultSaleDocument = (this.defaultSalePrinter && this.defaultSalePrinter.availableDocuments) && this.defaultSalePrinter.availableDocuments.find(doc => doc.document_template?.id === parseInt(defaultDocumentId!));
                } else {
                    this.defaultSaleDocument = (this.defaultSalePrinter && this.defaultSalePrinter.availableDocuments) && this.defaultSalePrinter.availableDocuments.find(doc => doc.document_type.id === defaultDocumentId);
                }
            }
        }
        
        this.default_receipt_printer_data = this.data.documentPrinters.map((printer) => ({ key: printer.name, value: printer.id || 0 }));
        
        this.automaticPrintSettingsForm = new CustomFormGroup<CustomForm<AutomaticPrintSettingsDialogFormFields>>({
            default_printer: new CustomFormControl(this.defaultSalePrinter ? this.defaultSalePrinter.id : null, {validators: [Validators.required]}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.SETTINGS.DEFAULT_PRINTER', inputChoices: this.default_receipt_printer_data, id:'default_document_receipts', class:'tw-w-full'}),
            default_document: new CustomFormControl(this.defaultSaleDocument?.document_template?.id || this.defaultSaleDocument?.document_type.id, {validators: [Validators.required]}, {...new CustomFormControlProps(), inputType:'select', label:'SETTINGS.SETTINGS.DEFAULT_DOCUMENT', inputChoices: this.default_document_receipts_data, id:'default_receipt_printer', class:'tw-w-full'}),
        }, {}, {...new CustomFormGroupProps(), class:"tw-w-full tw-flex tw-flex-col"});

        runInInjectionContext(this.injector, 
            () => {
            subscribeInComponent(
                this.automaticPrintSettingsForm.controls.default_printer.valueChanges,
                () => {
                    if(this.automaticPrintSettingsForm.controls.default_printer.value) {
                        const printer = this.documentPrinters.find(printer => printer.id === this.automaticPrintSettingsForm.controls.default_printer.value);
                        if(printer){
                            this.default_document_receipts_data = [];
                            this.default_document_receipts_data = printer.availableDocuments!.map((doc: any) => ({ key: this.getDocumentTypeName(doc), value: doc.document_template?.id || doc.document_type?.id}));
                            this.automaticPrintSettingsForm.controls.default_document.customProps.inputChoices = this.default_document_receipts_data;

                            !this.dialogJustOpened && this.automaticPrintSettingsForm.controls.default_document.setValue(null);
                            this.automaticPrintSettingsForm.controls.default_document.markAsUntouched();

                            this.dialogJustOpened = false;
                        }
                    }
                }
            )
        });
    }

    getDocumentTypeName(document: AvailableDocuments) {
        return this.documentPrintersManager.getDocumentTypeName(document, 'CASHREGISTER.DOCUMENTS_MANAGER') || '';
    }

    protected confirm() {
        if(this.automaticPrintSettingsForm.invalid) {
            this.automaticPrintSettingsForm.controls.default_printer.hasError('required') && this.automaticPrintSettingsForm.controls.default_printer.markAsTouched();
            this.automaticPrintSettingsForm.controls.default_document.hasError('required') && this.automaticPrintSettingsForm.controls.default_document.markAsTouched();
            return;
        }

        // set preferences
        this.configurationManagerService.setShopPreference('cashregister.batch_sale_print.default_printer_id', this.automaticPrintSettingsForm.controls.default_printer.value);
        this.configurationManagerService.setShopPreference('cashregister.batch_sale_print.default_document_id',
        this.automaticPrintSettingsForm.controls.default_document.value)

        // notify user
        this.openDialogsService.openSnackBarTilby('SETTINGS.SETTINGS.SAVE_EDIT_SUCCESSFUL', 'MISC.OK', { duration: 3000 });

        // close dialog
        this.matDialogRef.close({
            ...this.automaticPrintSettingsForm.value
        });
    };
}

@Injectable({
    providedIn: "root",
})
export class AutomaticPrintSettingsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<AutomaticPrintSettingsDialogData>) {
        const data: AutomaticPrintSettingsDialogData = config?.data||{ documentPrinters: [], nonFiscalDocuments: [] };
        const configEdited: NonNullableConfigData<AutomaticPrintSettingsDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(AutomaticPrintSettingsDialogComponent, configEdited).afterClosed()
        );
    }
}