import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('orders').factory('newOrder', ["$mdDialog", "$translate", "checkManager", "connection", "orderUtils", "progressivesManager", "entityManager", "addSelectCustomer", "util", function($mdDialog, $translate, checkManager, connection, orderUtils, progressivesManager, entityManager, addSelectCustomer, util) {
    newOrderController.$inject = ["$scope", "params", "deliveryChannels", "orderTemplate", "rooms", "orders"];
    function newOrderController($scope, params, deliveryChannels, orderTemplate, rooms, orders) {
        if(!_.isObject(params)) {
            params = {};
        }

        Object.assign($scope, {
            coverNumbers: _.range(0, 51),
            data: _.pick(orderTemplate, ['name', 'order_number', 'type', 'covers']),
            now: moment().milliseconds(0).seconds(0).toDate(),
            requireTableSelection: checkManager.getPreference('orders.require_table_selection'),
            showOrderType: (params.covers) ? false : true
        });

        $scope.customer = params.customer;
        $scope.data._customerName = util.getCustomerCaption(params.customer);

        $scope.tables = [];
        $scope.selectedTable = null;

        _.forEach(rooms, function(room) {
            _.forEach(room.tables, function(table) {
                $scope.tables.push(_.assign({
                    room: room
                }, table));
            });
        });

        var ordersByTable = _.groupBy(orders, 'table_id');

        $scope.tables = _.filter($scope.tables, function(table) {
            return table.order_type === 'multiple' || _.isNil(ordersByTable[table.id]);
        });

        if(params.table) {
            $scope.selectedTable = _.find($scope.tables, { id: params.table.id });
        }

        $scope.data.deliver_at = moment($scope.now).toDate();

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            if($scope.data.channel !== 'pos') {
                var usedChannel = _.find(deliveryChannels, { id: $scope.data.channel });

                if(usedChannel && usedChannel.force_paid) {
                    $scope.data.paid = true;
                }
            }

            if($scope.selectedTable && $scope.data.type === 'normal') {
                _.assign($scope.data, {
                    table: $scope.selectedTable,
                    room: $scope.selectedTable.room
                });
            }

            if (!$scope.data.customer) {
                $scope.data.customer = $scope.customer;
            }

            $mdDialog.hide($scope.data);
        };

        $scope.clearNameField = function() {
            $scope.data.name = "";
            angular.element("#newOrderName").trigger('focus');
        };

        $scope.types = [{
            name: $translate.instant('ORDERS.NEW_ORDER.NORMAL'),
            value: "normal"
        },{
            name: $translate.instant('ORDERS.NEW_ORDER.TAKE_AWAY'),
            value: "take_away"
        },{
            name: $translate.instant('ORDERS.NEW_ORDER.DELIVERY'),
            value: "delivery"
        }];

        $scope.deliveryCircuits = _.map(deliveryChannels, function(channel) {
            var ch = _.clone(channel);

            if(!ch.image_url) {
                ch.image_url = "assets/images/channels/" + channel.id + ".png";
            }

            return ch;
        });

        $scope.addEditCustomer = function() {
            addSelectCustomer.show({ customer_id: params.customer.id }).then(function(customer) {
                _.assign($scope.data, {
                    customer_id: customer.id,
                    _customerName: util.getCustomerCaption(customer),
                    customer: customer,
                    order_customer: _.pick(customer, ['customer_id', 'first_name', 'last_name', 'company_name'])
                });
            });
        };
    }

    var finalizeOrder = function(data) {
        return orderUtils.getOrderTemplate(data).then(function(order) {
            if (connection.isOffline()) {
                progressivesManager.updateProgressives({ order_number_offline: order.order_number });
                delete order.order_number;
            }

            return order;
        });
    };

    var newOrder = {
        show: function(params) {
            return $mdDialog.show({
                controller: newOrderController,
                template: require('./new-order.html'),
                locals: {
                    params: params
                },
                resolve: {
                    deliveryChannels: function() {
                        return entityManager.channels.fetchCollectionOffline().then(function(channels) {
                            return _.reject(channels, {id: 'pos'});
                        });
                    },
                    orderTemplate: function() {
                        return orderUtils.getOrderTemplate(params);
                    },
                    rooms: function() {
                        return entityManager.rooms.fetchCollectionOffline();
                    },
                    orders: ["entityManager", function(entityManager) {
                        return entityManager.orders.fetchCollectionOffline().then(function(orders) {
                            return _.filter(orders, function(order) {
                                return _.includes(['open','checkout'], order.status) && !_.isNil(order.table_id);
                            });
                        });
                    }]
                }
            }).then(function(answer) {
                return finalizeOrder(answer);
            });
        },
        create: function(params) {
            return finalizeOrder(params);
        }
    };

    return newOrder;
}]);
