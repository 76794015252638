import * as angular from 'angular';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions 
} from 'src/app/shared/model/digital-payments.model';

import {
    DocumentPrintHook,
    DocumentPrinterOptions
} from 'src/app/shared/model/document-printer.model';

import {
    BezzleCommonService 
} from './bedzzle-common';

import { validate as validateUuid } from 'uuid';

import {
    ConfigurationManagerService,
    EntityManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';

import { BeddyApiService } from 'app/modules/application/service/beddy-api/beddy-api';
import { Sales } from 'tilby-models';

export class BedzzleService implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private configurationManagerService: ConfigurationManagerService,
        private bedzzlePayments: any,
        private bedzzleApi: BeddyApiService,
        private bedzzleCommon: BezzleCommonService,
        private entityManagerService: EntityManagerService,
        private userActiveSession: UserActiveSessionManagerService
    ) {
    }

    public async payment (amount: number, options: DigitalPaymentHandlerOptions) {
        const currentSale = options.sale;
        let folio;

        try {
            folio = await this.bedzzlePayments.show({ amount: amount });
        } catch(error) {
            throw (typeof error === 'object' ? 'CANCELED' : error);
        }

        if (!folio) {
            throw 'MISSING_FOLIO';
        }

        const transaction = await this.bedzzleCommon.buildTransaction(folio, currentSale);

        try {
            const response = await this.bedzzleApi.createTransaction(transaction);

            if(response.data.folioId) {
                folio.folioId = response.data.folioId;
                transaction.folioId = response.data.folioId;
            }

            let responseString = '';

            try {
                responseString = JSON.stringify(transaction);
            } catch(err) {
                //Nothing to do
            } finally {
                return {
                    acquirer_name: 'Bedzzle',
                    payment_data: responseString,
                    tail: this.bedzzleCommon.buildTail(folio, currentSale, 'bedzzle'),
                    unclaimed: true
                };
            }
        } catch(error: any) {
            switch (error.status) {
                case -1:
                    throw "BEDZZLE_OFFLINE";
                case 400:
                case 401:
                    throw "BEDZZLE_UNABLE_TO_COMPLETE_TRANSACTION";
                default:
                    throw "UNKNOWN_ERROR";
            }
        }
    }

    public async refund(amount: number, options: DigitalPaymentHandlerOptions) {
        const currentSale = options.sale;
        const shop = this.userActiveSession.getSession()?.shop;
        let refundResult = {};

        if(currentSale) {
            //Make sure that the referenced sale had a bedzzle payment and in that case perform the rollback
            const parentSaleId = currentSale.sale_parent_id || currentSale.sale_parent_uuid;

            if(parentSaleId) {
                let parentSale;

                if(validateUuid(parentSaleId.toString())) {
                    let results = await this.entityManagerService.sales.fetchCollectionOnline({ uuid: parentSaleId }) as Sales[];
                    parentSale = results[0];
                } else {
                    parentSale = await this.entityManagerService.sales.fetchOneOfflineFirst(parentSaleId);
                }

                if(parentSale) {
                    const bedzzlePayment = parentSale.payments?.find((payment) => payment.payment_method_type_id === 22);

                    if(bedzzlePayment) {
                        try {
                            refundResult = await this.bedzzleApi.rollbackTransaction(String(shop?.id), parentSale.uuid!);
                        } catch(err: any) {
                            refundResult = err?.data?.error?.message;
                        }
                    }
                }
            }
        }

        return {
            acquirer_name: 'Bedzzle',
            payment_data: JSON.stringify(refundResult),
            unclaimed: true
        };
    }

    public isEnabled(): boolean {
        return !!this.configurationManagerService.getSetting('bedzzle.property_id');
    }

    public async printFailHook (sale: Sales, printerDocumentData: DocumentPrinterOptions) {
        const shop = this.userActiveSession.getSession()?.shop;
        const hasBedzzlePayment = sale.payments?.some((payment) => payment.payment_method_type_id === 22);

        if(!hasBedzzlePayment) {
            return;
        }

        let result = 'KO';

        try {
            await this.bedzzleApi.rollbackTransaction(String(shop?.id), sale.uuid!);
            result = 'OK';
        } catch (err) {
            //Nothing to do
        } finally {
            throw `BEDZZLE_ROLLBACK_${result}`;
        }
    }

    public async postPrintHook (sale: Sales, printerDocumentData: DocumentPrinterOptions) {
        /*
            1. Sending other sales to Bedzzle must be enabled
            2. The sale must not have a Bedzzle payment
        */
        if(
            !this.configurationManagerService.getPreference('zucchetti_pms.send_other_sales') ||
            sale.payments?.some((payment) => payment.payment_method_type_id == 22)
        ) {
            return;
        }

        this.bedzzleCommon.buildExtTransaction({ folioId: null }, sale).then((transaction) => this.bedzzleApi.createTransaction(transaction));
    }
}

BedzzleService.$inject = ["checkManager", "bedzzlePayments", "bedzzleApi", "bedzzleCommon", "entityManager", "userActiveSession"];

angular.module('digitalPayments').service('bedzzle', BedzzleService);