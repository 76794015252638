import {
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    GridComponent,
    GridOrderBy,
    GridServerSide,
    GridTraductionsService,
    queryFilteredOrdered_FEtoBEConverter
} from '../grid';
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {
    FilteredQuery,
    KeysOfUnion,
    QueryPagination,
    RowModelType,
    TableRow
} from '../../model/model';
import {
    DateFilterModel,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    NumberFilterModel,
    TextFilterModel
} from 'ag-grid-community';
import { PaginatedResponse, SkeletonGridRowComponent, ToolbarEventsService} from 'src/app/core';
import {OpenDialogsService} from 'src/app/dialogs';
import {Observable, Subject} from 'rxjs';
import {GridService} from '../grid/grid.service';
import { Crud, FeatureFields } from '../../../models';
import {ConfigurationManagerService} from 'src/app/core/services/configuration-manager/configuration-manager.service';
import {SetFilterModel} from 'ag-grid-community/dist/lib/interfaces/iSetFilter';
import * as grid_normalized from '../grid/utils/grid-normalized-data-utils';

@Component({
    selector: 'app-grid-server-side',
    templateUrl: '../grid/grid.component.html',
    styleUrls: ['../grid/grid.component.scss'],
    providers: [OnDestroyService]
})
export class GridServerSideComponent extends GridComponent implements OnInit, GridServerSide, OnDestroy {
    protected readonly toolbarEventsService= inject(ToolbarEventsService);
    protected readonly translateService= inject(TranslateService);
    protected readonly configurationManager= inject(ConfigurationManagerService);
    protected readonly _snackBar= inject(MatSnackBar);
    protected readonly gridTraductionsService= inject(GridTraductionsService);
    protected readonly onDestroyService= inject(OnDestroyService);
    protected readonly gridService= inject(GridService);
    protected readonly openDialogsService= inject(OpenDialogsService);

    rowModelType: RowModelType = 'serverSide';
    @Input() datasource?: IServerSideDatasource;
    @Input() serverSideInfiniteScroll?: boolean = true;
    @Input() cacheBlockSize: any;
    public loadingCellRenderer = SkeletonGridRowComponent;
    private totalResults = 0;

    ngOnInit(): void {
        this.defaultColDef.filter = 'agTextColumnFilter';
        this.initTable();
        super.ngOnInit();
    }

    onGridReady(params: GridReadyEvent) {
        super.onGridReady(params);
        if (this.datasource) {
            this.gridApi?.setServerSideDatasource(this.datasource);
        }
        this.setColumnsPreferences();
        this.gridReady(itemsRowAdded => this.gridApi?.['serverSideRowModel'].nodeManager.rowNodes?.[itemsRowAdded.id] || this.gridApi?.getRowNode(`${itemsRowAdded.id}`));
    }

    refreshGrid() {
        this.gridApi?.refreshServerSide({purge: true});
    }

    clickableButtonClicked({method,id}:{method:Crud,id:number}) {
        this.onRemoveSelected(id);
    }

    onRemoveSelected(id?: number) {
        const selectedRows = this.gridApi?.getSelectedRows();
        const ids = id ? [id] : selectedRows?.map(r => r.id);
        ids?.forEach(id => this.gridApi?.forEachNode(rowNode => {
            if ((id).toString().indexOf(rowNode.data.id) >= 0) {
                this.gridService.tableProperties.idsDeleting = [...this.gridService.tableProperties.idsDeleting, id];
                rowNode.setData(rowNode.data); /*fa scattare la change detection della grid*/
                // @ts-ignore
                rowNode.setRowSelectable(false);
            }
        }));

        this.deleteChange.emit(ids);
    }

    getRowsForServersideDatasource = <T extends PaginatedResponse<FeatureFields>>(
        params: IServerSideGetRowsParams<any>,
        args: [string, Readonly<QueryPagination>] | [Readonly<QueryPagination>],
        collectionToFetch: (a: any, b?: any) => Observable<T>,
        {sort_type = 'asc', sort_col = null}: GridOrderBy,
        classDataNormalized?: string
    ) => {
        const query = [...args].pop() as Readonly<QueryPagination>;
        const sort = params?.request?.sortModel[0];
        let queryFilteredOrdered: QueryPagination | FilteredQuery = {...query};

        // START - SEARCHBAR & FILTERS
        if(!!Object.entries(params?.request?.filterModel).length){
            this.toolbarEventsService.searchBarValue$.next('')
        }
        const searchBarValue = this.toolbarEventsService.searchBarValue$.getValue();

        if(searchBarValue && this.feature && !!this.toolbarEventsService.searchBarXQuery[this.feature]){
            const x_queryArray =searchBarValue.split(/ +/g).map((value: string)=> (<string[]>this.toolbarEventsService.searchBarXQuery[this.feature!])?.reduce((s,a,i)=>`${s}${!!i?',':''}${a}_like=${value}`,'OR(')+')');
            const x_query = `AND(${x_queryArray.join(',')})`;
            queryFilteredOrdered={...queryFilteredOrdered,x_query}
        }
        else{
            // for filtering serveside-deleting
            Object.entries<(TextFilterModel | NumberFilterModel | DateFilterModel | SetFilterModel)>(params?.request?.filterModel)?.forEach(
                ([columnName, f]) => queryFilteredOrdered = queryFilteredOrdered_FEtoBEConverter(queryFilteredOrdered, columnName as KeysOfUnion<TableRow>, f, this.tableData.dataType)
            );
        }
        // END - SEARCHBAR & FILTERS

        // for ordering serveside-deleting
        queryFilteredOrdered = {
            ...queryFilteredOrdered,
            page: ((params.request.endRow || 0) / query.per_page) - 1,
            ...((sort?.colId || sort_col) && {[`orderby_${sort?.sort || sort_type}`]: sort?.colId || sort_col}),
        };
        const apiCall = args.length === 1
            ? collectionToFetch(queryFilteredOrdered)
            : collectionToFetch(args[0], queryFilteredOrdered);
        apiCall.subscribe({
            next: res => {
                //after serveside-deleting we have to unselect rows
                if (this.totalResults != res?.total) this.gridApi?.deselectAll();
                (this.gridService[`${this.feature!}$`].data as Subject<FeatureFields[]>).next(res?.results || []);
                if (classDataNormalized && res.results) { // @ts-ignore
                    res = grid_normalized[classDataNormalized].normalized(res);
                }
                const totalResults = res?.total || undefined
                params.success({
                    rowData: res?.results || [],
                    rowCount: totalResults
                });
                this.totalResults = totalResults || 0;
                if (!!this.gridService.tableProperties.selectedRows.length) {
                    setTimeout(() => this.selectionMemory());
                }
            },
            error: error => {
                console.error(error, 'NOT FOUND');
                params.fail();
            }
        });
    }


    selectionMemory() {
        const {api: gridOptionApi} = this.agGrid;
        const selectedIndexes = this.gridService.tableProperties.selectedRows.map(row => row.id.toString())
        gridOptionApi?.forEachNode(node => {
            if (selectedIndexes.includes(node.id?.toString())) {
                node.setSelected(true)
            }
        });
        this.gridService.tableProperties.selectedRows = [];
    }

}
