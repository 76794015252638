<cdk-virtual-scroll-viewport class="tw-h-full tw-w-full" [itemSize]="52" [minBufferPx]="screenHeight" [maxBufferPx]="screenHeight">
    <div *cdkVirtualFor="let item of itemsSet; trackBy: trackByFunction; let last = last" class="tw-py-[1px]" [ngClass]="last?customClass||'':''" >
        <!-- <div class="tw-cursor-pointer tw-border tw-border-solid tw-border-gray-300 tw-rounded-lg tw-flex tw-flex-row tw-h-12 tw-font-medium tw-items-center tw-overflow-hidden" tilbyGestures (click)="itemTap(item)" (longPress)="itemPress(item)">
            <div class="tw-w-3 tw-h-full tw-flex-shrink-0" [ngStyle]="{ 'background-color': '#' + (item.color || 'BBDEFB') }"></div>
            @if(showUnavailableStock || getStockStatus(item) !== 'stock-info-nostock') {
                <div class="item-stock-status tw-flex-shrink-0" [ngClass]="getStockStatus(item)"></div>
            }
            <div class="tw-flex-auto tw-text-sm tw-ml-2">{{ item.name }}</div>
            @if (showThumbnail && item.thumbnail) {
                <img class="tw-rounded-md tw-h-10 tw-w-10 tw-object-cover tw-ml-1 tw-pointer-events-none" [src]="item.thumbnail"/>
            }
            <div [ngClass]="truncatePriceWithDots ? 'truncate' : 'truncate-no-dots'" class=" tw-text-sm tw-mx-2 tw-text-right tw-flex-shrink-0">{{ $any(item[priceIdentifier]) | tilbyCurrency : (priceWithCurrency ? undefined : '') }}</div>
        </div> -->
        <tilby-item-element
            [item]="item"
            [showThumbnail]="showThumbnail"
            [showUnavailableStock]="showUnavailableStock"
            [priceIdentifier]="priceIdentifier"
            [priceWithCurrency]="priceWithCurrency"
            [truncatePriceWithDots]="truncatePriceWithDots"
            [getStockStatus]="getStockStatus"
            (itemTapped)="itemTap($event)"
            (itemLongPressed)="itemPress($event)"
        >
        </tilby-item-element>
    </div>
</cdk-virtual-scroll-viewport>
