export type UiLanguage = {
    id: string,
    value: string,
    decimal_separator: string,
    thousands_separator: string,
    moment_locale: string,
    territories: string[],
    ui_hide?: boolean
}

export const uiLanguages : Record<string, UiLanguage> = {
    'da': {
        id: 'da',
        value: "Danish",
        decimal_separator: ',',
        thousands_separator: '.',
        moment_locale: 'da',
        territories: ['da_DK']
    },
    'de': {
        id: 'de',
        value: "Deutsch",
        decimal_separator: ',',
        thousands_separator: '.',
        moment_locale: 'de',
        territories: ['de_*']
    },
    'en_GB': {
        id: 'en_GB',
        value: "English (GB)",
        decimal_separator: '.',
        thousands_separator: ',',
        moment_locale: 'en-gb',
        territories: ['en_GB']
    },
    'es': {
        id: 'es',
        value: "Español",
        decimal_separator: ',',
        thousands_separator: ' ',
        moment_locale: 'es',
        territories: ['es_*']
    },
    'fr': {
        id: 'fr',
        value: "French",
        decimal_separator: ',',
        thousands_separator: ' ',
        moment_locale: 'fr',
        territories: ['fr_*']
    },
    'it': {
        id: 'it',
        value: "Italiano",
        decimal_separator: ',',
        thousands_separator: '.',
        moment_locale: 'it',
        territories: ['it_*']
    },
    'zh': {
        id: 'zh',
        value: "汉语 (Simplified Chinese)",
        decimal_separator: '.',
        thousands_separator: ',',
        moment_locale: 'zh-cn',
        territories: ['zh_*']
    }
}
