<h1 mat-dialog-title class="tw-mb-2" translate>SETTINGS.EDIT_ID_BADGE_DIALOG.TITLE</h1>
<div mat-dialog-content style="max-height: 75vh">
    <form [formGroup]="editIdBadgeDialogForm">
        <div class="tw-flex tw-w-full">
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.EDIT_ID_BADGE_DIALOG.IDENTIFIER</mat-label>
                <input matInput id="id_badge" formControlName="id_badge" type="text" class="form-control" required>
                <mat-error *ngIf="editIdBadgeDialogForm.get('id_badge')!.invalid" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
            </mat-form-field>
        </div>
        <div class="tw-w-full" *ngIf="errorEditIdBadge">
            <p class="tw-text-center tw-text-red-700">{{errorEditIdBadgeMessage}}</p>
        </div>
    </form>
</div>

<div mat-dialog-actions class="tw-justify-end">
    <button mat-button (click)="dialogRef.close()"><span translate>SETTINGS.EDIT_ID_BADGE_DIALOG.ACTION.CANCEL</span></button>
    <button mat-raised-button color="primary" (click)="submit()" cdkFocusInitial [disabled]="isFormInvalid()"><span translate>SETTINGS.EDIT_ID_BADGE_DIALOG.ACTION.SUBMIT</span></button>
</div>
