import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('kiosk').controller('KioskPaymentSelectionCtrl', KioskPaymentSelectionCtrl);

KioskPaymentSelectionCtrl.$inject = ["$scope", "$state", "kioskSettings", "kioskUtils", "paymentMethods", "DigitalPaymentsManager", "alertDialog", "printerErrorFiscal", "$translate", "migrationHelper"];

function KioskPaymentSelectionCtrl($scope, $state, kioskSettings, kioskUtils, paymentMethods, DigitalPaymentsManager, alertDialog, printerErrorFiscal, $translate, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();

    var buttonTextColor = kioskSettings['general.base_button_text_color'] || '#fff';
    var buttonBackgroundColor = kioskSettings['general.base_button_bgcolor'] || '#666';
    var baseTextColor = kioskSettings['general.base_text_color'] || '#000';
    var zoomLevel = kioskSettings['general.zoom_level'] || 1.5;

    Object.assign($scope, {
        cardPayment: null,
        cashPayment: null,
        headerParams: {
            bannerUrl: kioskSettings['payment_selection.banner_url'],
            bannerBgColor: kioskSettings['general.header_background_color'],
            bannerHeight: kioskSettings['general.header_height'],
            logoUrl: _.toString(kioskSettings['general.media_url_logo']),
            zoomLevel: zoomLevel,
        },
        customerAssistantEnabled: kioskSettings['general.customer_assistant'],
        title: kioskUtils.getLabelText(kioskSettings, 'payment_selection.title_label','KIOSK.PAYMENT_SELECTION.TITLE'),
        totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
        backButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.back_button_label', 'KIOSK.BACK'),
        iconStyle: { color: buttonTextColor },
        buttonStyle: {
            color: buttonTextColor,
            'background-color': buttonBackgroundColor
        },
        paymentSelectionStyle: {
            'zoom': zoomLevel,
            color: baseTextColor
        },
        backgroundUrl: kioskSettings['payment_selection.background_url']
    });

    if($scope.backgroundUrl) {
        $scope.mediaType = kioskUtils.getMediaType($scope.backgroundUrl);
    }

    $scope.help = () => {
        // @toDO
        console.debug('AIUTO Da completare');
    };

    $scope.back = function() {
        $scope.$parent.enableTimeouts();
        $state.go(kioskUtils.getPreviousModule());
    };

    $scope.next = function() {
        $scope.$parent.enableTimeouts();
        $state.go(kioskUtils.getNextModule());
    };

    if(kioskSettings['payment_selection.default_cash_payment_id'] !== 'none') {
        $scope.cashPayment = kioskUtils.getAvailableCashPayments(paymentMethods, _.toInteger(kioskSettings['payment_selection.default_cash_payment_id']))[0];
    }

    if(kioskSettings['payment_selection.default_card_payment_id'] !== 'none') {
        $scope.cardPayment = kioskUtils.getAvailableCardPayments(paymentMethods, _.toInteger(kioskSettings['payment_selection.default_card_payment_id']))[0];
    }

    $scope.satispayPayment =  DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(18) ? _.find(paymentMethods, { payment_method_type_id: 18 }) : null;
    $scope.prepaidPayment = _.find(paymentMethods, { payment_method_type_id: 16 });

    $scope.prepaidDisabled = false;
    $scope.prepaidVisible = false;
    $scope.payOnCashdeskEnabled = kioskSettings['payment_selection.enable_pay_on_cashdesk'] ? true : false;

    if($scope.prepaidPayment) {
        ActiveSale.checkPrepaidPaymentStatus($scope.prepaidPayment).then(function(result) {
            $scope.prepaidDisabled = result.$disabled;

            if(!result.$disabled) {
                var unclaimedPrepaid = _.find(paymentMethods, { payment_method_type_id: 16, unclaimed: true });
                var claimedPrepaid = _.find(paymentMethods, { payment_method_type_id: 16, unclaimed: false });

                $scope.prepaidVisible = unclaimedPrepaid && claimedPrepaid;
            } else {
                if(!result.customer_uuid) {
                    $scope.prepaidVisible = false;
                }
            }
        });
    }

    var handleTransactionError = async (error) => {
        alertDialog.show(printerErrorFiscal.translateError(error || 'CANCELED')).finally(() => {
            $state.go('app.kiosk.standby');
        });
    };

    const performPayment = async (paymentMethod) => {
        $scope.$parent.paymentInProgress = true;
        $scope.$parent.disableTimeouts();

        ActiveSale.cleanPaymentsFromSale();

        try {
            await ActiveSale.addGenericPayment(paymentMethod);
            await ActiveSale.processPayments();
            await ActiveSale.processSaleChange();

            let result = await ActiveSale.emitDocument();

            if(result.printError) {
                throw (_.isArray(result.printError) ? _.head(result.printError) : result.printError);
            } else if(result.emittedSale) {
                $scope.next();
            }
        } catch(error) {
            handleTransactionError(error);
        } finally {
            $scope.$parent.paymentInProgress = false;
        }
    };

    $scope.payOnCashdesk = async () => {
        $scope.$parent.paymentInProgress = true;
        $scope.$parent.disableTimeouts();

        try {
            await ActiveSale.printNonFiscalSale();
            await ActiveSale.saveSale(null, true);

            $scope.next();
        } catch(error) {
            handleTransactionError(error);
        }

        $scope.$parent.paymentInProgress = false;
    };

    $scope.performDigitalPayment = function(paymentMethod) {
        performPayment(paymentMethod);
    };

    $scope.performPrepaidPayment = function() {
        if($scope.prepaidPayment) {
            $scope.performDigitalPayment($scope.prepaidPayment);
        }
    };

    $scope.payments = [
        {
            icons: ['assets/images/kiosk/payments_cash.svg'],
            label: $translate.instant('KIOSK.PAYMENT_SELECTION.CASH'),
            ariaLabel: 'Button cash payment',
            action: function() {
                $scope.performDigitalPayment($scope.cashPayment);
            },
            visible: $scope.cashPayment ? true : false
        },
        {
            icons: [
                'assets/images/kiosk/payments_card.svg',
                'assets/images/kiosk/contactless.svg'
            ],
            label: $translate.instant('KIOSK.PAYMENT_SELECTION.CREDIT_CARD'),
            ariaLabel: 'Button card payment',
            action: function() {
                $scope.performDigitalPayment($scope.cardPayment);
            },
            visible: $scope.cardPayment ? true : false
        },
        {
            icons: ['assets/images/kiosk/payments_satispay.svg'],
            label: $translate.instant('KIOSK.PAYMENT_SELECTION.SATISPAY'),
            ariaLabel: 'Button satispay payment',
            action: function() {
                $scope.performDigitalPayment($scope.satispayPayment);
            },
            visible: $scope.satispayPayment ? true : false
        },
        {
            icons: ['assets/images/kiosk/payments_prepaid.svg'],
            label: $translate.instant('KIOSK.PAYMENT_SELECTION.PREPAID'),
            ariaLabel: 'Button prepaid payment',
            action: function() {
                $scope.performPrepaidPayment();
            },
            get disabled() { return $scope.prepaidDisabled; },
            get visible() { return $scope.prepaidVisible; }
        },
        {
            icons: ['assets/images/kiosk/person.svg', 'assets/images/kiosk/payments_cashdesk.svg'],
            label: $translate.instant('KIOSK.PAYMENT_SELECTION.CASHDESK'),
            ariaLabel: 'Button pay on cashdesk payment',
            action: function() {
                $scope.payOnCashdesk();
            },
            visible: $scope.payOnCashdeskEnabled ? true : false
        }
    ];
}