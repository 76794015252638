<div class="tw-h-full tw-w-full">
    <mat-list class="tw-w-full tw-h-full tw-overflow-y-auto tw-p-0">
        <ng-content select="[Header]"/>
        @for(payment of sale?.payments;track payment.id){
            <mat-list-item #item class="tw-flex tw-justify-between tw-cursor-pointer tw-items-center tw-w-full tw-py-2 tw-h-[unset]">
                <div class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-py-2" tilbyGestures (swipeLeft)="swipeLeftHandler(payment)">
                    <div class="tw-font-semibold"><span>{{$any(payment.payment_method_name ||'').toTitleCase()}}</span></div>
                    <div class="tw-font-semibold tw-flex tw-justify-end">{{(payment.amount||0)| tilbyCurrency}}</div>
                </div>
                <div class="tw-text-xs">{{getPaymentDetail!(payment)}}</div>
            </mat-list-item>
            <mat-divider class="tw-mx-2"></mat-divider>
        }
    </mat-list>
</div>
