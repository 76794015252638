import * as angular from 'angular';
import { EntityManagerService } from 'src/app/core';
import { DocumentPrintHook, DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';
import { NonFiscalDocuments, Sales } from 'tilby-models';

export class NonFiscalDocumentHook implements DocumentPrintHook {

    constructor(
        private entityManager: EntityManagerService,
        private restManager: any
    ) {
    }

    public isEnabled(): boolean {
        return true; //We don't need checks here
    }

    public async manageProgressive(printerDocumentData: DocumentPrinterOptions, action: ('increment' | 'query')): Promise<any> {
        if (!printerDocumentData.document_template?.has_progressive) {
            return;
        }

        return this.restManager.getOne('nonfiscal_progressive', action, { document_type_id: printerDocumentData.document_template.id, printer_id: printerDocumentData.printer.id });
    }

    public async prePrintHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<void> {
        //Try to use the credit_note document type if the amount is negative
        if (sale.final_amount! < 0) {
            const creditNoteTemplates = await this.entityManager.nonfiscalDocuments.fetchCollectionOffline({ type: 'credit_note' }) as NonFiscalDocuments[];

            if (creditNoteTemplates[0]) {
                printerDocumentData.document_template = creditNoteTemplates[0];
                printerDocumentData.document_type = { id: 'credit_note' };
            }
        }

        if (!printerDocumentData.document_template?.has_progressive) {
            return;
        }

        try {
            const result = await this.manageProgressive(printerDocumentData, 'query');
            printerDocumentData.document_template.progressive = result?.progressive;
        } catch (err) {
            throw "PROGRESSIVE_FETCH_FAILED";
        }
    }

    public async postPrintHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<void> {
        try {
            await this.manageProgressive(printerDocumentData, 'increment');
        } catch (err) {
            //Nothing to do
        }
    }
}

NonFiscalDocumentHook.$inject = ["entityManager", "restManager"];

angular.module('printers').factory('NonFiscalDocumentHook', NonFiscalDocumentHook);