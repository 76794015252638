import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrls: ['./fidelity.component.scss']
})
export class FidelityComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.fidelity");
    }
  ngOnInit(): void {
  }

}
