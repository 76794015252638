@if(sideKeyboard() && principalActionButton && toggleMainKeyboardButton){
    <app-cashregister-side-keyboard
        [form]="form"
        [buttons]="buttons"
        [integerMultiply]="integerMultiply"
        [toggleMainKeyboardButton]="toggleMainKeyboardButton"
        [principalActionButton]="principalActionButton"
        [fastPaymentsButtons]="fastPaymentsButtons"
        [sale]="sale"
        [cashValue]="cashValue"
        (onFieldChange)="onFieldChange($event)"
    />
}
@else {
    <app-cashregister-keypad class="tw-col-span-2 tw-h-full" [field]="cashValue" (fieldChange)="onFieldChange($event)" [integerMultiply]="integerMultiply"/>
    <app-tilby-sale-keypad class="tw-col-span-3" [form]="form" [buttons]="buttons" [isMobile]="isMobile()" (activateSideKeyboard)="activateSideKeyboard.emit($event)"/>
}
