import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('printers').factory('EPosDriver', ["$http", "util", "epsonUtils", function($http, util, epsonUtils) {
    function EPosDriver(ipAddress, port, configuration) {
        this.networkSettings = {
            ipAddress: ipAddress,
            port: port || 8008
        };
        //Default style settings
        this.printSettings = {
            dw: false,
            dh: false,
            reverse: false,
            height: 1,
            width: 1,
            ul: false,
            em: false,
            linespc: configuration?.line_spacing ?? 80,
            color: EPosDriver.prototype.COLOR_1,
            align: EPosDriver.prototype.ALIGN_LEFT
        };

        this.lastPrintSettings = {
            ...this.printSettings,
            linespc: null
        };

        this.ePosDoc = null;
        this.root = null;
        this.eReceiptMode = !!(configuration?.eReceiptMode);
    }

    var calculateDelta = function(object, base) {
        return _.transform(object, function(result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = _.isObject(value) && _.isObject(base[key]) ? _.difference(value, base[key]) : value;
            }
        });
    };

    var getPrinterEndpoint = function(target) {
        return "http://" + target.ipAddress + "/cgi-bin/epos/service.cgi?devid=local_printer";
    };

    var addSoapEnvelope = function(command) {
        return "<?xml version=\"1.0\" encoding=\"utf-8\"?><s:Envelope xmlns:s=\"http://schemas.xmlsoap.org/soap/envelope/\"><s:Body>" + command + "</s:Body></s:Envelope>";
    };

    var sendPostRequest = function(url, data, options, successFunction, errorFunction) {
        if(!_.isObject(options)) {
            options = {};
        }

        _.merge(options, {
            headers: {'Content-Type': 'application/xml'}
        });

        $http.post(url, data, options).then(function(responseSuccess) {
            var response = epsonUtils.parseEpsonXml(responseSuccess);

            if(_.get(response, 'result.success') === true) {
                successFunction(responseSuccess);
            } else {
                errorFunction(response.status);
            }
        }, function(responseError) {
            errorFunction(responseError.status);
        });
    };

    var sendRequest = function(target, command, callback) {
        if(target.ipAddress === '0.0.0.0') {
            callback('PRINTED');
        } else {
            var printerEndpoint = getPrinterEndpoint(target);
            command = addSoapEnvelope(command);

            sendPostRequest(printerEndpoint, command, {timeout: 30000}, function(xhr) {
                callback("PRINTED");
            }, function(error) {
                callback("CONNECTION_ERROR");
            });
        }
    };

    EPosDriver.prototype.FONT_A = 'font_a';
    EPosDriver.prototype.FONT_B = 'font_b';
    EPosDriver.prototype.FONT_C = 'font_c';
    EPosDriver.prototype.FONT_SPECIAL_A = 'special_a';
    EPosDriver.prototype.FONT_SPECIAL_B = 'special_b';
    EPosDriver.prototype.ALIGN_CENTER = "center";
    EPosDriver.prototype.ALIGN_LEFT = "left";
    EPosDriver.prototype.ALIGN_RIGHT = "right";
    EPosDriver.prototype.COLOR_NONE = "color_none";
    EPosDriver.prototype.COLOR_1 = "color_1";
    EPosDriver.prototype.COLOR_2 = "color_2";
    EPosDriver.prototype.COLOR_3 = "color_3";
    EPosDriver.prototype.COLOR_4 = "color_4";
    EPosDriver.prototype.BARCODE_UPC_A = 'upc_a';
    EPosDriver.prototype.BARCODE_UPC_E = 'upc_e';
    EPosDriver.prototype.BARCODE_EAN13 = 'ean13';
    EPosDriver.prototype.BARCODE_JAN13 = 'jan13';
    EPosDriver.prototype.BARCODE_EAN8 = 'ean8';
    EPosDriver.prototype.BARCODE_JAN8 = 'jan8';
    EPosDriver.prototype.BARCODE_CODE39 = 'code39';
    EPosDriver.prototype.BARCODE_ITF = 'itf';
    EPosDriver.prototype.BARCODE_CODABAR = 'codabar';
    EPosDriver.prototype.BARCODE_CODE93 = 'code93';
    EPosDriver.prototype.BARCODE_CODE128 = 'code128';
    EPosDriver.prototype.BARCODE_GS1_128 = 'gs1_128';
    EPosDriver.prototype.BARCODE_GS1_DATABAR_OMNIDIRECTIONAL = 'gs1_databar_omnidirectional';
    EPosDriver.prototype.BARCODE_GS1_DATABAR_TRUNCATED = 'gs1_databar_truncated';
    EPosDriver.prototype.BARCODE_GS1_DATABAR_LIMITED = 'gs1_databar_limited';
    EPosDriver.prototype.BARCODE_GS1_DATABAR_EXPANDED = 'gs1_databar_expanded';
    EPosDriver.prototype.HRI_NONE = 'none';
    EPosDriver.prototype.HRI_ABOVE = 'above';
    EPosDriver.prototype.HRI_BELOW = 'below';
    EPosDriver.prototype.HRI_BOTH = 'both';
    EPosDriver.prototype.CUT_NO_FEED = "no_feed";
    EPosDriver.prototype.CUT_FEED = "feed";
    EPosDriver.prototype.CUT_RESERVE = "reserve";
    EPosDriver.prototype.DRAWER_1 = "drawer_1";
    EPosDriver.prototype.DRAWER_2 = "drawer_2";
    EPosDriver.prototype.LEVEL_0 = "level_0";
    EPosDriver.prototype.LEVEL_1 = "level_1";
    EPosDriver.prototype.LEVEL_2 = "level_2";
    EPosDriver.prototype.LEVEL_3 = "level_3";
    EPosDriver.prototype.LEVEL_4 = "level_4";
    EPosDriver.prototype.LEVEL_5 = "level_5";
    EPosDriver.prototype.LEVEL_6 = "level_6";
    EPosDriver.prototype.LEVEL_7 = "level_7";
    EPosDriver.prototype.LEVEL_8 = "level_8";
    EPosDriver.prototype.LEVEL_L = "level_l";
    EPosDriver.prototype.LEVEL_M = "level_m";
    EPosDriver.prototype.LEVEL_Q = "level_q";
    EPosDriver.prototype.LEVEL_H = "level_h";
    EPosDriver.prototype.LEVEL_DEFAULT = "default";
    EPosDriver.prototype.PULSE_100 = "pulse_100";
    EPosDriver.prototype.PULSE_200 = "pulse_200";
    EPosDriver.prototype.PULSE_300 = "pulse_300";
    EPosDriver.prototype.PULSE_400 = "pulse_400";
    EPosDriver.prototype.PULSE_500 = "pulse_500";
    EPosDriver.prototype.SYMBOL_PDF417_STANDARD = "pdf417_standard";
    EPosDriver.prototype.SYMBOL_PDF417_TRUNCATED = "pdf417_truncated";
    EPosDriver.prototype.SYMBOL_QRCODE_MODEL_1 = "qrcode_model_1";
    EPosDriver.prototype.SYMBOL_QRCODE_MODEL_2 = "qrcode_model_2";
    EPosDriver.prototype.SYMBOL_QRCODE_MICRO = "qrcode_micro";
    EPosDriver.prototype.SYMBOL_MAXICODE_MODE_2 = "maxicode_mode_2";
    EPosDriver.prototype.SYMBOL_MAXICODE_MODE_3 = "maxicode_mode_3";
    EPosDriver.prototype.SYMBOL_MAXICODE_MODE_4 = "maxicode_mode_4";
    EPosDriver.prototype.SYMBOL_MAXICODE_MODE_5 = "maxicode_mode_5";
    EPosDriver.prototype.SYMBOL_MAXICODE_MODE_6 = "maxicode_mode_6";
    EPosDriver.prototype.SYMBOL_GS1_DATABAR_STACKED = "gs1_databar_stacked";
    EPosDriver.prototype.SYMBOL_GS1_DATABAR_STACKED_OMNIDIRECTIONAL = "gs1_databar_stacked_omnidirectional";
    EPosDriver.prototype.SYMBOL_GS1_DATABAR_EXPANDED_STACKED = "gs1_databar_expanded_stacked";
    EPosDriver.prototype.SYMBOL_AZTECCODE_FULLRANGE = "azteccode_fullrange";
    EPosDriver.prototype.SYMBOL_AZTECCODE_COMPACT = "azteccode_compact";
    EPosDriver.prototype.SYMBOL_DATAMATRIX_SQUARE = "datamatrix_square";
    EPosDriver.prototype.SYMBOL_DATAMATRIX_RECTANGLE_8 = "datamatrix_rectangle_8";
    EPosDriver.prototype.SYMBOL_DATAMATRIX_RECTANGLE_12 = "datamatrix_rectangle_12";
    EPosDriver.prototype.SYMBOL_DATAMATRIX_RECTANGLE_16 = "datamatrix_rectangle_16";

    EPosDriver.prototype.initDocument = function() {
        this.root = util.createXMLNode();

        var doc = util.createXMLNode("epos-print", {
            xmlns: "http://www.epson-pos.com/schemas/2011/03/epos-print"
        });

        this.root.appendChild(doc);
        this.ePosDoc = doc;
    };

    EPosDriver.prototype.addText = function(text) {
        if(this.eReceiptMode) {
            return;
        }

        if(!this.ePosDoc) {
            this.initDocument();
        }

        this.ePosDoc.appendChild(util.createXMLNode("text", calculateDelta(this.printSettings, this.lastPrintSettings), text));
        this.lastPrintSettings = _.clone(this.printSettings);
    };

    EPosDriver.prototype.addTextDouble = function(dw, dh) {
        this.printSettings.dw = _.isBoolean(dw) ? dw : this.printSettings.dw;
        this.printSettings.dh = _.isBoolean(dh) ? dh : this.printSettings.dh;
    };

    EPosDriver.prototype.addTextSize = function(w, h) {
        this.printSettings.width = _.isInteger(w) && _.inRange(w, 1, 9) ? w : this.printSettings.width;
        this.printSettings.height = _.isInteger(h) && _.inRange(h, 1, 9) ? h : this.printSettings.height;
    };

    EPosDriver.prototype.addTextAlign = function(align) {
        if(_.includes([EPosDriver.prototype.ALIGN_LEFT, EPosDriver.prototype.ALIGN_CENTER, EPosDriver.prototype.ALIGN_RIGHT], align)) {
            this.printSettings.align = align;
        }
    };

    EPosDriver.prototype.addTextLineSpace = function(linespc) {
        if(!this.ePosDoc) {
            this.initDocument();
        }

        this.printSettings.linespc = linespc;
    };

    EPosDriver.prototype.addTextStyle = function(reverse, ul, em, color) {
        this.printSettings.reverse = _.isBoolean(reverse) ? reverse : this.printSettings.reverse;
        this.printSettings.ul = _.isBoolean(ul) ? ul : this.printSettings.ul;
        this.printSettings.em = _.isBoolean(em) ? em : this.printSettings.em;
        this.printSettings.color = _.includes([EPosDriver.prototype.COLOR_1, EPosDriver.prototype.COLOR_2, EPosDriver.prototype.COLOR_3, EPosDriver.prototype.COLOR_4, EPosDriver.prototype.COLOR_NONE], color) ? color : EPosDriver.prototype.COLOR_1;
    };

    EPosDriver.prototype.addLogo = function(key1, key2) {
        if(this.eReceiptMode) {
            return;
        }

        if(!this.ePosDoc) {
            this.initDocument();
        }

        this.ePosDoc.appendChild(util.createXMLNode("logo", {
            key1: _.inRange(key1, 0, 256) ? key1 : 32,
            key2: _.inRange(key2, 0, 256) ? key2 : 32
        }));
    };

    EPosDriver.prototype.addBarcode = function(data, type, hri, font, width, height) {
        if(this.eReceiptMode) {
            return;
        }

        this.ePosDoc.appendChild(util.createXMLNode("barcode", { type: type, hri: hri, font: font, width: width, height: height }, data));
    };

    EPosDriver.prototype.addSymbol = function(data, type, level, width, height, size) {
        if(this.eReceiptMode) {
            return;
        }

        if(!this.ePosDoc) {
            this.initDocument();
        }

        this.ePosDoc.appendChild(util.createXMLNode("symbol", {
            type: type,
            level: level,
            width: width,
            height: height,
            size: size
        }, data));
    };

    EPosDriver.prototype.addCut = function(type) {
        if(this.eReceiptMode) {
            return;
        }

        if(!this.ePosDoc) {
            this.initDocument();
        }

        this.ePosDoc.appendChild(util.createXMLNode("text",null,'\r\n'));

        this.ePosDoc.appendChild(util.createXMLNode("cut", {
            type: _.includes([EPosDriver.prototype.CUT_FEED, EPosDriver.prototype.CUT_NO_FEED, EPosDriver.prototype.CUT_RESERVE], type) ? type : EPosDriver.prototype.CUT_FEED 
        }));
    };

    EPosDriver.prototype.addPulse = function(drawer, time) {
        if(!this.ePosDoc) {
            this.initDocument();
        }

        this.ePosDoc.appendChild(util.createXMLNode("pulse", {
            drawer: _.includes([EPosDriver.prototype.DRAWER_1, EPosDriver.prototype.DRAWER_2], drawer) ? drawer : EPosDriver.prototype.DRAWER_1,
            time: _.includes([EPosDriver.prototype.PULSE_100, EPosDriver.prototype.PULSE_200, EPosDriver.prototype.PULSE_300, EPosDriver.prototype.PULSE_400, EPosDriver.prototype.PULSE_500], time) ? time : EPosDriver.prototype.PULSE_100
        }));
    };

    EPosDriver.prototype.send = function(callback) {
        if(!this.ePosDoc) {
            this.initDocument();
        }
    
        sendRequest(this.networkSettings, this.root.toString(), (result) => {
            callback(this.eReceiptMode ? 'PRINTED' : result);
        });
    };

    EPosDriver.connectAttempt = function(ip, port, callback) {
        if(ip === '0.0.0.0') {
            callback({ ip: ip, connected: true });
        } else {
            var testDocument = addSoapEnvelope("<epos-print xmlns=\"http://www.epson-pos.com/schemas/2011/03/epos-print\"></epos-print>");
            var endpoint = getPrinterEndpoint({ ipAddress: ip, port: port });

            sendPostRequest(endpoint, testDocument, {timeout: 1000}, function(xhr) {
                callback({ ip: ip, connected: true });
            }, function(error) {
                callback({ ip: ip, connected: false });
            });
        }
    };

    return EPosDriver;
}]);
