const base64Marker = ';base64,';

/**
 * Converts a blob to a data url.
 * @param blob The blob to convert.
 * @returns The data url.
 */
export const blobToDataURL = async (blob: Blob, type?: string): Promise<string> => {
    let resultDataUrl = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }) as string;

    //Adjust data url type if doesn't match what we wanted
    if(type && blob.type != type) {
        let base64 = dataURLToBase64(resultDataUrl);
        resultDataUrl = base64ToDataUrl(type, base64);
    }

    return resultDataUrl;
};

/**
 * Converts a data url to a base64 string.
 * @param dataUrl The data url to convert.
 * @returns The base64 string.
 */
export const dataURLToBase64 = (dataUrl: string): string => {
    const parts = dataUrl.split(base64Marker);
    return parts[1];
};

/**
 * Converts a data url to a blob.
 * @param dataUrl The data url to convert.
 * @returns The blob.
 */
export const dataURLToBlob = (dataUrl: string): Blob => {
    const parts = dataUrl.split(base64Marker);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
};

/**
 * Converts a base64 string to a data url.
 * @param type The content type of the data url.
 * @param base64 The base64 string to convert.
 * @returns The data url.
 */
export const base64ToDataUrl = (type: string, base64: string): string => {
    return `data:${type};base64,${base64}`;
};

/**
 * Starts a download of a file.
 * @param data The file data to download.
 * @param fileName The name of the file.
 * @param mimeType The mime type of the file.
 */
export const downloadFile = (data: Blob | string, fileName: string, mimeType: string): void => {
    const windowURL = window.URL || window.webkitURL;
    //Create a blob from the data
    const file = new Blob([data], { type: mimeType });
    const fileURL = windowURL.createObjectURL(file);
    //Create a link to the file
    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = fileName;
    //Start the download
    anchor.click();
    //Clean up
    windowURL.revokeObjectURL(fileURL);
    anchor.remove();
};
