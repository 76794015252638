import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OpenDialogsService} from '../services';
import {InputItemDataMagicForm} from "../../shared/model/model";

@Component({
    selector: 'app-edit-rows-dialog-with-magic-form',
    templateUrl: './edit-rows-dialog-with-magic-form.component.html',
    styleUrls: ['./edit-rows-dialog-with-magic-form.component.scss']
})
export class EditRowsDialogWithMagicFormComponent implements OnInit {
    allWithCheckbox = true;

    constructor(
        public dialogRef: MatDialogRef<OpenDialogsService>,
        @Inject(MAT_DIALOG_DATA) public data: InputItemDataMagicForm<Record<string, any>>,
    ) {
    }

    ngOnInit(): void {
    }

    // setNewValue() {
    //     this.data.form.markAllAsTouched();
    //     if (this.data.form.valid) {
    //         this.dialogRef.close(this.data.form.value)
    //     }
    // }
}
