import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss']
})
export class LockScreenComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.lockScreen");
    }

  ngOnInit(): void {
  }

}
