<ng-container *ngIf="{totalsArray: totalsArray$|async} as data">
    <div class="tw-flex tw-flex-col tw-max-w-[225px] tw-min-w-[225px] tw-text-xs sm:tw-max-w-sm sm:tw-text-sm">
        <div *ngFor="let total of data.totalsArray" class="tw-flex tw-justify-between tw-items-center">
            <span class="tw-text-right tw-w-1/4 sm:tw-w-1/3" translate>{{total.label}}</span>
            <span class="tw-pl-1 tw-text-left tw-w-1/3">{{ total.price | tilbyCurrency}}</span>
            <span class="tw-text-left tw-w-1/3 tw-flex tw-items-center">
                    <mat-icon class="material-symbols-outlined">person_filled</mat-icon>
                    <span class="tw-align-middle">{{ total.people.covers }}/{{ total.people.totalCovers }}</span>
                </span>
        </div>
    </div>
</ng-container>
