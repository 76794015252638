import { Component, Injectable, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';

type dialogOptions = {
    title: string;
}

@Component({
    selector: 'app-buzzer-input-dialog',
    templateUrl: './buzzer-input-dialog.component.html',
    styleUrls: ['./buzzer-input-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        TranslateModule,
        MatDialogModule,
        MatRadioModule,
        MatIconModule,
        MatButtonModule,
        MatFormField,
        MatLabel,
        MatInputModule,
        FormsModule,
        TilbyGesturesDirective
    ],
    providers: [
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: {
            subscriptSizing: 'dynamic'
          }
        }
    ],
})
export class BuzzerInputDialogComponent implements OnInit {
    protected readonly data: dialogOptions = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    protected readonly dialogTitle = this.data.title;
    pager: string | null = null;

    ngOnInit() {
        this.pager = null;
    }

    addPager(suffix: string) {
        this.pager = this.pager ? this.pager + suffix : suffix;
    }

    removePager() {
        this.pager = this.pager ? this.pager.slice(0, -1) : null;
    }

    clear() {
        this.pager = null;
    }

    protected confirm() {
        this.matDialogRef.close({pager: this.pager});
    }
}

@Injectable({
    providedIn: 'root'
})
export class BuzzerInputDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    public openDialog(options?: NonNullableConfigData<dialogOptions>): Promise<{ pager: string | null } | undefined> {
        const config: NonNullableConfigData<dialogOptions> = {
            ...this.switchMobileDesktopDimensions({ height: '70vh', width: '500px' }, { fullScreenForMobile: true }),
            disableClose: true,
            data: {
                title: options?.data?.title || 'Associa pager alla vendita',
            },
            ...options
        };
        return lastValueFrom(this.dialogRef.open(BuzzerInputDialogComponent, config).afterClosed());
    }
}
