<div style="height: 100%;">
    <!-- <app-tab-settings-um (tabPanelChange)="tabPanel=$any($event)"> -->
    <app-tab-settings-um (tabPanelChange)="changeTab($event)" [getTabChange]="tabChange">
        <ng-container Users [ngTemplateOutlet]="users" ></ng-container>
        <ng-container QrCode_Waiter [ngTemplateOutlet]="qrcode_waiter" style="height: 100%;"></ng-container>
        <ng-container StaticToken [ngTemplateOutlet]="static_token"></ng-container>
    </app-tab-settings-um>
</div>


<ng-template #users>
    <app-settings-um-users></app-settings-um-users>
</ng-template>

<ng-template #qrcode_waiter>
    <app-settings-um-qrcode-waiter></app-settings-um-qrcode-waiter>
</ng-template>

<ng-template #static_token>
    <app-settings-um-static-token #staticTokenComponent></app-settings-um-static-token>
</ng-template>

<!-- <ng-template #customersTempForm let-shop_country="country">
    <app-customer-form
        [userCountry]="shop_country"
        [filteredOptions]="filteredOptions"
        [fidelityLabel]="fidelityLabel"
        [paymentMethods]="paymentMethods"
        [priceLists]="priceLists"
        [customersForm]="customersForm"
        [customer]="customer"
        [customTypes]="customTypes"
        [customFields]="customFields"
        [validators]="validators"
        (delete)="deleteRecord()"
        (submit)="onSubmit($event)"
    ></app-customer-form>
</ng-template>

<ng-template #prepaid>

        <app-customer-prepaid-credit
            #prepaidCreditComponent
            *ngIf="chainShops"
            [customer]="customer"
            [chainShops]="chainShops"
            [credit]="credit">
        </app-customer-prepaid-credit>
</ng-template>

<ng-template #fidelity>
        <app-customer-fidelity-points
            #fidelityPointsComponent
            *ngIf="chainShops"
            [customer]="customer"
            [campaigns]="campaigns"
            [(selectedCampaignId)]="activeCampaignId"
            [chainShops]="chainShops"
            [user]="$rootScope?.userActiveSession"
            [(points)]="points"
        ></app-customer-fidelity-points>
</ng-template>

<ng-template #default>
    <button mat-flat-button color="primary" class="tw-absolute tw-top-[50%] tw-left-[50%] -tw-translate-x-[50%] -tw-translate-y-[50%] tw-text-2xl tw-p-7">Richiedi attivazione Modulo</button>
</ng-template> -->

