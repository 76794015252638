import { Component, ViewEncapsulation, OnInit, inject } from "@angular/core";
import { ConfigurationManagerService, ToolbarEventsService } from "src/app/core";
import { SettingsAlvoloInitializedComponent, SettingsAlvoloNotInitializedComponent } from "./components";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { SettingsAlvoloStateService } from "./service/settings-alvolo.state.service";
import { restManager } from "app/ajs-upgraded-providers";
import { DevLogger } from "src/app/shared/dev-logger";

@Component({
    selector: "app-settings-alvolo",
    templateUrl: "./settings-alvolo.component.html",
    styleUrls: ["./settings-alvolo.component.scss"],
    standalone: true,
    imports: [MatButtonModule, CommonModule, SettingsAlvoloInitializedComponent, SettingsAlvoloNotInitializedComponent],
})
export class SettingsAlvoloComponent implements OnInit {
    private restManagerService = inject(restManager);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    readonly settingsAlvoloService = inject(SettingsAlvoloStateService);
    configurationManagerService = inject(ConfigurationManagerService);
    encapsulation!: ViewEncapsulation.None;

    ngOnInit(): void {
        this.toolbarEventsService.moduleTitle.next("ALVOLO_SETTINGS");
    }
}