import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('offlineDialog', ["$mdDialog", "connection", "durationFilter", function($mdDialog, connection, durationFilter) {
    offlineDialogController.$inject = ["$scope", "$mdDialog"];

    function offlineDialogController($scope, $mdDialog) {
        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function(answer) {
            $mdDialog.hide(answer);
        };

        $scope.getOfflineDuration = function(){
            return durationFilter(connection.offlineAt());
        };
    }

    var offlineDialog = {
        show: function(options) {

            var defaultOptions = {
                controller: offlineDialogController,
                template: require('./offline-dialog.html'),
            };

            if (options) {
                _.merge(options, defaultOptions);
            } else {
                options = defaultOptions;
            }

            return $mdDialog.show(options);
        }
    };

    return offlineDialog;
}]);
