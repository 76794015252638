import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('printers').factory('rchUtils', ["$q", "fiscalUtils", "util", "errorsLogger", "checkManager", function($q, fiscalUtils, util, errorsLogger, checkManager) {
    var paymentsChecked = {};

    var idleStates = {
        0: 'INACTIVE', // ok
        1: 'PRINT_IN_PROGRESS', // ~ok
        2: 'PAYMENT_IN_PROGRESS', // ~ok
        3: 'OPEN_DOCUMENT', // ~ok
        4: 'ACTIVE_FIDELITY' // ok
    };

    var removeTicketFromCheck = function(row) {
        return _.omit(row, 'enableTicket');
    };

    function dateToString(date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    var rchUtils = {
        cleanUpSpecialChars: function(str) {
            return _.deburr(str)
                .replace('\u001B', '')
                .replace(/\'/, "")
                .replace(/\"/, "")
                .replace("€", "EUR0")
                .replace("TOTALE", "T0TALE")
                .replace(String.fromCharCode(160), ' ');
        },
        addTextLine: function(text) {
            return ["=\"",  "(" + rchUtils.cleanUpSpecialChars(text) + ")"];
        },
        addBorder: function(char, columns) {
            return ["=\"",  "(" + _.repeat(rchUtils.cleanUpSpecialChars(char), columns || 48)  + ")"];
        },
        linesToCommands: function(lines) {
            if (typeof lines === 'string') {
                lines = lines.split("\n");
            }

            const linesCommands = [];

            if (Array.isArray(lines)) {
                for (const line of lines) {
                    linesCommands.push([`="`, `(${rchUtils.cleanUpSpecialChars(line)})`]);
                }
            }

            return [['=K'], ['=o'], ...linesCommands, ['=o']];    
        },
        /**
         *  checkStatus
         *  @param printer
         *  @return INACTIVE | PRINT_IN_PROGRESS | PAYMENT_IN_PROGRESS | OPEN_DOCUMENT | ACTIVE_FIDELITY
         */
        checkStatus: function(printer, callback) {
            rchUtils.sendCommands(_.assign({}, printer, { timeout: 3000 }), rchUtils.cmdToXml([['<<','?s']])).then(function(response) {
                var idleIndex = $(response).find("idleState").text();
                callback(idleStates[idleIndex], response);
            }, function(error) {
                callback(error);
            });
        },
        isAvailable: function(printer) {
            var d = $q.defer();

            rchUtils.checkStatus(printer, function(message, response) {
                if (message === "CONNECTION_ERROR") {
                    d.reject(message);
                } else {
                    d.resolve(response);
                }
            });

            return d.promise;
        },
        fixIssues: function(printer, error) {
            var d = $q.defer();

            // Check stampante
            if (_.startsWith(error, 'RCH.ERROR_')) {
                rchUtils.checkStatus(printer, function(status) {
                    errorsLogger.debug("STATUS:" + status);

                    var commandsToSend;
                    var finalStatus;

                    var returnStatus = function() {
                        if(_.includes(['OK'], finalStatus)) {
                            d.resolve();
                        } else {
                            d.reject(finalStatus);
                        }
                    };

                    switch(status) {
                        case 'CONNECTION_ERROR':
                        case 'BUSY':
                            finalStatus = status;
                        break;
                        case 'INACTIVE':
                        case 'PRINT_IN_PROGRESS':
                            // ANNULLA SCONTRINO
                            finalStatus = 'CANCELED';
                            commandsToSend = [['=k']];
                        break;
                        case 'PAYMENT_IN_PROGRESS':
                            finalStatus = "OK";
                            // Forza completamento pagamento e invia nuovo scontrino
                            commandsToSend = [
                                ['=T'],
                                ['=\"', '()'],
                                ['=\"', '(###############################################)'],
                                ['=\"', '(####                                      #####)'],
                                ['=\"', '(####   ATTENZIONE: VERIFICARE PAGAMENTO   #####)'],
                                ['=\"', '(####                                      #####)'],
                                ['=\"', '(###############################################)'],
                                ['=\"', '()'],
                                ['=c']
                            ];
                        break;
                        case 'OPEN_DOCUMENT':
                        case 'ACTIVE_FIDELITY':
                            finalStatus = 'OK';
                            commandsToSend = [['=c']];
                        break;
                        default: break;
                    }

                    if(!_.isEmpty(commandsToSend)) {
                        rchUtils.sendCommands(printer, rchUtils.cmdToXml(commandsToSend)).then(function() {
                            returnStatus();
                        }, d.reject);
                    } else {
                        returnStatus();
                    }
                });
            } else {
                d.reject(error);
            }

            return d.promise;
        },
        cmdToXml: function(commands) {
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>";
            var root = util.createXMLNode();
            var cmdXml = util.createXMLNode("Service");
            root.appendChild(cmdXml);

            for (var i in commands) {
                if(_.isArray(commands[i])) {
                    cmdXml.appendChild(util.createXMLNode('cmd', null, commands[i].join('/')));
                } else {
                    cmdXml.appendChild(util.createXMLNode('cmd', null, commands[i]));
                }
            }

            return header + root.toString();
        },
        displaySaleTotal: function(printer, sale) {
            var displayXml = rchUtils.cmdToXml([
                ['=D1', '(T0TALE)'],
                ['=D2', '(EURO ' + sale.final_amount.toFixed(2).replace(".", ",") + ')']
            ]);

            return rchUtils.sendCommands(printer, displayXml);
        },
        getPaymentsConfig: function(options) {
            if(!_.isObject(options)) {
                options = {};
            }

            return [
                { id: 1,    name: "Contanti",               enableChange: 1,    enableSum: 1,   unclaimed: 0,                           enableTicket: 0 },
                { id: 2,    name: "Assegni",                enableChange: 0,    enableSum: 0,   unclaimed: 0,                           enableTicket: 0 },
                { id: 3,    name: "Pagam. elettronico",  enableChange: 0,    enableSum: 0,   unclaimed: 0,                           enableTicket: 0 },
                { id: 4,    name: "Ticket",                 enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 0 : 1,    enableTicket: 1 },
                { id: 5,    name: "Non riscosso Beni",    enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 1 : 1,    enableTicket: 0 },
                { id: 6,    name: "Non riscosso Servizi", enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 2 : 1,    enableTicket: 0 },
                { id: 7,    name: "Fattura differita",      enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 3 : 1,    enableTicket: 0 },
                { id: 8,    name: "Non riscosso SSN",       enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 4 : 1,    enableTicket: 0 },
                { id: 9,    name: "Buono multiuso",         enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 5 : 1,    enableTicket: 0 },
                { id: 10,   name: "Sconto a pagare",        enableChange: 0,    enableSum: 0,   unclaimed: options.xml7Mode ? 5 : 1,    enableTicket: 0 }
            ];
        },
        /**
         *  sendCommands
         *  @param printer
         *  @param command
         *  @return INACTIVE | PRINT_IN_PROGRESS | PAYMENT_IN_PROGRESS | OPEN_DOCUMENT | ACTIVE_FIDELITY
        */
        sendCommands: function(printer, command) {
            var strURL = "http://" + printer.ip_address + "/service.cgi";
            var timeout = printer.timeout || undefined;
            var reqHeaders = { 'Content-Type': 'application/xml' };

            return fiscalUtils.sendRequest(strURL, { data: command, method: 'POST', timeout: timeout, headers: reqHeaders }).then(function(responseObj) {
                var error;
                var response = responseObj.data;
                var responseJq = $(response);

                if (responseJq.find("paperEnd").text() !== '0') {
                    error = "PAPER_END";
                } else if (responseJq.find("coverOpen").text() !== '0') {
                    error = "COVER_OPEN";
                } else if (responseJq.find("busy").text() !== '0') {
                    error = "BUSY";
                } else {
                    var errorCode = responseJq.find("errorCode").text();
                    var printerError = responseJq.find("printerError").text();

                    if(errorCode !== '0') {
                        switch(errorCode) {
                            case '93':
                                error = 'FISCAL_CLOSING_NEEDED';
                                break;
                            default:
                                error = "RCH.ERROR_" + errorCode;
                            break;
                        }
                    } else if(printerError !== '0') {
                        error = "RCH.ERROR_" + responseJq.find("printerError").text();
                    }
                }

                if(error) {
                    throw error;
                }

                return response;
            }, function(error) {
                throw 'CONNECTION_ERROR';
            });
        },
        checkPaymentsTable: function(printer, printerInfo, options) {
            var d = $q.defer();
            var printerSerial = printerInfo.printer_serial;

            if(!_.isObject(options)) {
                options = {};
            }

            if(paymentsChecked[printerSerial] || checkManager.getSetting('fiscalprinters.override_payments_check')) {
                d.resolve();
            } else {
                rchUtils.sendCommands(printer, rchUtils.cmdToXml([['<<', '?C']])).then(function(response) {
                    var referenceTable = rchUtils.getPaymentsConfig(options);

                    try {
                        var xml = $.parseXML(response);
                        var xmlDoc = $(xml);
                        var payments = xmlDoc.find('Payment');

                        var currentTable = _.map(payments, function(payment) {
                            var paymentObj = $(payment);

                            return {
                                id: _.toNumber(_.get(paymentObj, [0, 'id'])),
                                name: _.get(paymentObj.find('txt'), [0, 'textContent']),
                                enableChange: _.toNumber(_.get(paymentObj.find('change enabled'), [0, 'textContent'])),
                                enableSum: _.toNumber(_.get(paymentObj.find('cash enabled'), [0, 'textContent'])),
                                unclaimed: _.toNumber(_.get(paymentObj.find(options.xml7Mode ? 'creditType value' : 'credit enabled'), [0, 'textContent'])),
                                enableTicket: _.toNumber(_.get(paymentObj.find('ticket enabled'), [0, 'textContent']))
                            };
                        });

                        if(!options.xml7Mode) {
                            currentTable = _.map(currentTable, removeTicketFromCheck);
                            referenceTable = _.map(referenceTable, removeTicketFromCheck);
                        }

                        var currentPayments = _.keyBy(currentTable, 'id');

                        var currentTableOk = _.every(referenceTable, function(payment) {
                            return _.isMatch(currentPayments[payment.id], payment);
                        });

                        if(currentTableOk) {
                            paymentsChecked[printerSerial] = true;

                            d.resolve();
                        } else {
                            d.reject('CONFIGURATION_NEEDED');
                        }
                    } catch(e) {
                        d.resolve();
                    }
                }, d.reject);
            }

            return d.promise;
        },
        extractPayments: function extractPayments(sale, resources, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            if(!_.isObject(resources)) {
                resources = {};
            }

            var xml7Mode = options.xml7Mode;
            var payments = fiscalUtils.extractPayments(sale);
            var servicesToPay = 0;
            var goodsToPay = 0;

            if(xml7Mode && _.isArray(resources.departments)) {
                const departmentsByCode = _.keyBy(resources.departments, 'printer_code');
                const itemsBySalesType = _.groupBy(sale.sale_items, (saleItem) => departmentsByCode[saleItem.department_id]?.sales_type);

                goodsToPay = util.round((itemsBySalesType.goods || []).reduce((amount, saleItem) => amount + saleItem.final_price * saleItem.quantity, 0) || 0);
                servicesToPay = util.round((itemsBySalesType.services || []).reduce((amount, saleItem) => amount + saleItem.final_price * saleItem.quantity, 0) || 0);
            }

            var getUnclaimedAmount = function(payment) {
                if(xml7Mode) { //In XML7 mode, split between goods and services
                    var servicesAmount = _.min([servicesToPay, payment.amount]);
                    var goodsAmount = _.max([0, util.round(payment.amount - servicesAmount)]);

                    servicesToPay = util.round(servicesToPay - servicesAmount);
                    goodsToPay = util.round(goodsToPay - goodsAmount);

                    return _([
                        _(payment).thru(_.clone).assign({ amount: util.round(servicesAmount), payment_type: 6 }).value(),
                        _(payment).thru(_.clone).assign({ amount: util.round(goodsAmount), payment_type: 5 }).value()
                    ]).reject({ amount: 0 }).value();
                } else {
                    return _.assign(payment, { payment_type: 6 });
                }
            };

            return _(payments).map(function(payment) {
                //Assign payment type
                switch(payment.method_type_id) {
                    case 1: case 19: case 21: case 32: case 38: case 39: case 40:
                        if(_.get(payment, 'payment_data.rounding')) {
                            payment.amount = _.get(payment, 'payment_data.original_amount', payment.amount);
                        }

                        payment.payment_type = 1; //Cash
                    break;
                    case 3:
                        payment.payment_type = 2; //Cheque
                    break;
                    case 4: case 5: case 8: case 11: case 13: case 14: case 15: case 17: case 18: case 27: case 30: case 31: case 35: case 37:
                        payment.payment_type = 3; //Credit / Credit card
                    break;
                    case 6: case 34:
                        payment.payment_type = 4; //Ticket
                    break;
                    case 2: //Unclaimed
                    case 20:
                        return getUnclaimedAmount(payment);
                    case 10: case 33:
                        payment.payment_type = 9;
                    break;
                    case 22: case 23: case 24: case 28: case 29: case 36: case 41:
                        payment.payment_type = 7;
                    break;
                    case 25:
                        payment.payment_type = 8;
                    break;
                    case 26:
                        if(_.get(payment, 'payment_data.rounding')) {
                            return null;
                        } else {
                            payment.payment_type = 10;
                        }
                    break;
                    case 16:
                        //(Ticket if unclaimed, digital if otherwise)
                        if(payment.unclaimed) {
                            return getUnclaimedAmount(payment);
                        } else {
                            payment.payment_type = 3;
                        }
                    break;
                    default:
                        payment.payment_type = 1;
                    break;
                }

                return payment;
            }).flatten().compact().value();
        },
        getPriceChangeCommand: function(priceChange, amount, options) {
            if(typeof options !== 'object') {
                options = {};
            }

            const pcAmount = Math.abs(Math.round(amount * 100));

            let command;

            switch(priceChange.type) {
                case 'discount_fix':
                case 'discount_perc':
                    command = ['=V-', '$' + pcAmount];
                break;
                case 'surcharge_fix':
                case 'surcharge_perc':
                    command = ['=V+', '$' + pcAmount];
                break;
                case 'gift':
                    if(!options.xml7Mode) {
                        command = ['=V-', '$' + pcAmount];
                    }
                break;
                default:
                    return;
            }

            if (command && priceChange.description) {
                command.push(`(${priceChange.description})`);
            }

            return command;
        },
        applyPriceChanges: (priceChanges, partialPrice, options) => {
            const commands = [];

            _(priceChanges).sortBy('index').forEach((pc) => {
                const pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

                if(pcAmount != null) {
                    partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

                    const pcCommand = rchUtils.getPriceChangeCommand(pc, pcAmount, options);

                    if(pcCommand) {
                        commands.push(pcCommand);
                    }
                }
            });

            return commands;
        },
        saleItemToCommands: function(saleItem, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            var commands = [];
            var cmd = ['=R' + saleItem.department_id, '$' + Math.round(saleItem.price * 100), '*' + Math.abs(saleItem.quantity)];

            if(options.isRefundingRT) {
                commands.push(cmd);
            } else {
                if(options.xml7Mode) {
                    switch(saleItem.type) {
                        case 'deposit_cancellation':
                            cmd.push('&2');
                        break;
                        case 'coupon':
                            cmd.push('&3');
                        break;
                        case 'sale':
                        default:
                            if(_.find(saleItem.price_changes, { type: 'gift' })) {
                                cmd.push('&1');
                            }
                        break;
                    }
                } else if(saleItem.type === 'refund') {
                    commands.push('=r');
                }

                // name (or department_name), quantity, price
                cmd.push('(' + rchUtils.cleanUpSpecialChars(saleItem.name || saleItem.department_name) + ')');

                commands.push(cmd);

                // Check printNotes
                var printNotes = (options.printNotes === false) ? false :true;

                // Item barcode (if print_notes)
                if (printNotes && saleItem.barcode) {
                    if (saleItem.barcode.toLowerCase().indexOf('p') < 0 && saleItem.barcode.toLowerCase().indexOf('q') < 0) {
                        commands.push(['=\"', '(# + ' + _.trim(saleItem.barcode) + ' )']);
                    }
                }

                // Notes
                if (printNotes && saleItem.notes) {
                    var notesLines = saleItem.notes.split("\n");
                    _.forEach(notesLines, function(nl) {
                        if (nl.trim()) {
                            commands.push(['=\"', '(# ' + rchUtils.cleanUpSpecialChars(nl.trim()) + ' )']);
                        }
                    });
                }

                if (saleItem.quantity < 0 && saleItem.refund_cause_description) { // Refund cause

                    commands.push(['=\"', '(# ' + rchUtils.cleanUpSpecialChars(saleItem.refund_cause_description) + ' )']);
                } else if (saleItem.quantity > 0) { //Discount / Surcharges
                    const priceChangeCommands = rchUtils.applyPriceChanges(saleItem.price_changes, fiscalUtils.roundDecimals(saleItem.price * saleItem.quantity), options);

                    Array.prototype.push.apply(commands, priceChangeCommands);
                }
            }

            return commands;
        },
        orderToCommands: function(order, printerColumns) {
            var commands = [];

            if(_.isNil(printerColumns)) {
                printerColumns = 48;
            }

            // Send CL
            commands.push("=K");
            // Open non fiscal receipt
            commands.push("=o");

            // Border
            commands.push(rchUtils.addBorder('=', printerColumns));

            // Alert
            if (printerColumns === 48) {
                commands.push(rchUtils.addTextLine("ATTENZIONE: Si e' verificato un problema di"));
                commands.push(rchUtils.addTextLine("connettivita' al cameriere: " + order.operator_name));
                commands.push(rchUtils.addTextLine("Si prega di inserire manualmente i dati della"));
                commands.push(rchUtils.addTextLine("comanda sulla schermata di cassa."));
            }
            if (printerColumns === 32) {
                commands.push(rchUtils.addTextLine("ATTENZIONE: Si e' verificato un"));
                commands.push(rchUtils.addTextLine("problema di connettivita' al"));
                commands.push(rchUtils.addTextLine("cam: " + order.operator_name));
                commands.push(rchUtils.addTextLine("Si prega di inserire manualmente"));
                commands.push(rchUtils.addTextLine("i dati della comanda sulla "));
                commands.push(rchUtils.addTextLine("schermata di cassa."));
            }

            // Border
            commands.push(rchUtils.addBorder('=', printerColumns));

            // Ordername
            if (order.name) {
                commands.push(rchUtils.addTextLine(order.name));
            }

            // Date/Time and #
            var dateNumber = (order.open_at ? dateToString(order.open_at) : "") + (order.order_number ? (" #" + order.order_number) : "");
            commands.push(rchUtils.addTextLine(dateNumber));

            // Delivery Date/Time
            if (order.deliver_at) {
                commands.push(rchUtils.addTextLine("Da consegnare: " + dateToString(order.deliver_at)));
            }

            // Room name & Table name
            if (order.room_name && order.table_name) {
                commands.push(rchUtils.addTextLine(order.room_name + " " + order.table_name));
            }

            // Covers
            if (order.covers) {
                commands.push(rchUtils.addTextLine("Numero di coperti: " + order.covers));
            }

            // Customer
            if (order.order_customer) {
                var customerName = util.getCustomerCaption(order.order_customer);

                if(customerName) {
                    commands.push(rchUtils.addTextLine("Cliente: " + customerName));
                }
            }

            // Border
            commands.push(rchUtils.addBorder('=', printerColumns));

            var itemsHeader = "Q.TA' / DESCRIZIONE";

            var max = printerColumns - itemsHeader.length - 4;

            for (var i = 0; i < max; i++) {
                itemsHeader += " ";
            }
            itemsHeader += 'EURO';

            commands.push(rchUtils.addTextLine(itemsHeader));
            commands.push(rchUtils.addTextLine(''));

            // Order_items
            _.forEach(order.order_items, function(oi) {
                var itemLine = oi.quantity + "x " + oi.name;
                var rowPrice = (Math.round((oi.price * oi.quantity) * 100) / 100).toFixed(2).replace(".", ",");

                for (var k = itemLine.length; k < (printerColumns - rowPrice.length); k++) {
                    itemLine += " ";
                }

                itemLine += rowPrice;
                commands.push(rchUtils.addTextLine(itemLine));

                // Ingredients
                _.forEach(oi.ingredients, function(i) {
                    var ingredientRow = '';

                    if (i.type === 'added') {
                        ingredientRow += "  + ";
                    } else if (i.type === 'removed') {
                        ingredientRow += "  - ";
                    }

                    ingredientRow += rchUtils.cleanUpSpecialChars(i.name);

                    if (i.price_difference) {
                        var iPrice = (Math.round((i.price_difference * oi.quantity) * 100) / 100).toFixed(2).replace(".", ",");
                        for (var k = ingredientRow.length; k < (printerColumns - iPrice.length); k++) {
                            ingredientRow += " ";
                        }
                        ingredientRow += iPrice;
                    }
                    commands.push(rchUtils.addTextLine(rchUtils.cleanUpSpecialChars(ingredientRow)));
                });

                // Variations
                _.forEach(oi.variations, function(i) {
                    var variationRow = '';
                    variationRow += '  ' + i.name + ": " + i.value;
                    if (i.price_difference) {
                        var iPrice = (Math.round((i.price_difference * oi.quantity) * 100) / 100).toFixed(2).replace(".", ",");
                        for (var k = variationRow.length; k < (printerColumns - iPrice.length); k++) {
                            variationRow += " ";
                        }
                        variationRow += iPrice;
                    }
                    commands.push(rchUtils.addTextLine(rchUtils.cleanUpSpecialChars(variationRow)));
                });
            });

            // Border
            commands.push(rchUtils.addBorder('-', printerColumns));

            // Amount
            var totString = 'T0TALE EURO';
            var totAmount = (Math.round(order.amount * 100) / 100).toFixed(2).replace(".", ",");

            for (var k = totString.length; k < (printerColumns - (totAmount.length)); k++) {
                totString += ' ';
            }

            totString += totAmount;
            commands.push(rchUtils.addTextLine(totString));

            commands.push("=o");
            return commands;
        },
        saleToCourtesyReceiptCommands: function(sale) {
            var commands = [];

            // Send CL
            commands.push(['=K']);
            commands.push(['=o']);

            commands.push(rchUtils.addTextLine('SCONTRINO DI CORTESIA'));
            commands.push(rchUtils.addTextLine());

            // Riga non fiscale con l'asid
            commands.push(rchUtils.addTextLine(sale.name));
            commands.push(rchUtils.addTextLine());

            // Items
            _.forEach(sale.sale_items, function(si) {
                commands.push(rchUtils.addTextLine(_.padEnd(si.quantity + "x " + rchUtils.cleanUpSpecialChars(si.name || si.department_name), 48, ' ')));
            });

            if (sale.id) {
                commands.push(['=\"', '$4', '(' + sale.id.toString() + ')']);
            }

            commands.push(["=o"]);

            return commands;
        }
    };

    return rchUtils;
}]);
