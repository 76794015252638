import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { OpenDialogsService } from '../services/open-dialogs.service';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<OpenDialogsService>,
      @Inject(MAT_DIALOG_DATA) public data:{searchedValue:string},
  ) { }

  ngOnInit(): void {
      this.dialogRef.beforeClosed().subscribe(
         _=> this.dialogRef.close(this.data.searchedValue)
      )
  }

}
