import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fileImporter').controller('FileImporterShowcaseCtrl', ["$scope", "$state", "$translate", "$filter", "$timeout", "restManager", "alertDialog", "util", "checkManager", "errorsLogger", "confirmDialog", function($scope, $state, $translate, $filter, $timeout, restManager, alertDialog, util, checkManager, errorsLogger, confirmDialog) {
    _.assign($scope, {
        importFiles: [],
        topbar_context: {
            title: $translate.instant('FILE_IMPORTER.SHOWCASE.TITLE')
        },
        isTabacchiEnabled: checkManager.isModuleEnabled('tobacco_updates'),
        isUveEnabled: checkManager.getPreference('uve.enabled') || false
    });

    $scope.getFileType = function(file) {
        return $translate.instant('FILE_IMPORTER.IMPORT_TYPES.' + _.toUpper(file.typeInsert) + (_.endsWith(file.typeInsert, 's') ? '' : 'S'));
    };

    $scope.getProcessDate = function(file) {
        if(file.daysToProcess) {

        } else {
            return $filter('sclDate')(file.dateToProcess);
        }
    };

    $scope.newFile = function() {
        $state.go('app.file_importer.add');
    };

    $scope.cloneImport = function(file) {
        var fileConfig = {
            name: file.name,
            primaryKey: file.primaryKey,
            schemaImporting: file.columns,
            type: file.type,
            typeInsert: _.toLower(file.typeInsert) + (_.endsWith(file.typeInsert, 's') ? '' : 's')
        };

        _.assign(fileConfig, file.modelOptions, file.optionsIntestation);

        $state.go('app.file_importer.add', {
            fileConfig: fileConfig
        });
    };

    $scope.hasNoResults = function() {
        return _.isEmpty($scope.importFiles);
    };

    $scope.downloadFile = function(file) {
        restManager.getOne('importing/file', 'original', { id: file.id }).then(function(result) {
            util.openExternalLink(result.url);
        });
    };

    $scope.downloadErrors = function(file) {
        if(file.totalErrors) {
            restManager.getOne('importing/file', 'error', { id: file.id, idStatus: file.statusId }).then(function(result) {
                util.openExternalLink(result.url);
            });
        }
    };

    $scope.isUserAdmin = function() {
        return util.isUserAdmin();
    };

    $scope.downloadDetailedErrors = function(file) {
        if(file.totalErrors) {
            restManager.getOne('importing/file', 'error', { id: file.id, idStatus: file.statusId, detail: true }).then(function(result) {
                util.openExternalLink(result.url);
            });
        }
    };

    var reloadTimeout;

    var loadFiles = function() {
        if(reloadTimeout) {
            $timeout.cancel(reloadTimeout);
        }

        return restManager.getList('importing/files').then(function(result) {
            $scope.importFiles = _.orderBy(result.files, ['createdAt'], ['desc']);

            var hasPendingFile = _.some($scope.importFiles, function(file) {
                return _.includes(['loading', 'processed', 'created'], file.status);
            });

            if(hasPendingFile) {
                reloadTimeout = $timeout(loadFiles, 10000);
            }
        });
    };

    $scope.topbar_context.reload = function() {
        if(!$scope.loadingInProgress) {
            $scope.loadingInProgress = true;
            $scope.importFiles = [];

            loadFiles().finally(function() {
                $scope.loadingInProgress = false;
            });
        }
    };

    $scope.topbar_context.reload();

    $scope.getTabacchi = async () => {
        try {
            const result = await restManager.getList('importing/files');
            const importFiles = _.orderBy(result.files, ['createdAt'], ['desc']);

            let pendingFile = importFiles.find((file) => (file.name.includes("Tabacchi") && file.status !== "completed"));

            if(pendingFile) {
                alertDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.ERROR_TABACCHI'));
            } else {
                let res = await restManager.post('importing/file/tabacchi');

                if(res.message === 'ok') {
                    alertDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.SUCCESS_TABACCHI'));
                }
            }
        } catch(err) {
            errorsLogger.debug(err);
            alertDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.GENERIC_ERROR'));
        }
    };

    $scope.getUveImporting = async () => {
        let answer = await confirmDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.ANSWER_UVE'));

        if(answer) {
            try {
                const result = await restManager.getList('importing/files');
                const importFiles = _.orderBy(result.files, ['createdAt'], ['desc']);

                let pendingFile = importFiles.find((file) => (file.name.includes("UVE") && file.status !== "completed"));

                if(pendingFile) {
                    alertDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.ERROR_UVE'));
                } else {
                    let res = await restManager.getOne('importing/uve');

                    if(res.message === 'ok') {
                        alertDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.SUCCESS_UVE'));
                    }
                }

            } catch(err) {
                errorsLogger.debug(err);
                alertDialog.show($translate.instant('FILE_IMPORTER.SHOWCASE.GENERIC_ERROR'));
            }
        }
    };

}]);
