import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').directive('topbarTables', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-tables.html'),
        controller: ["$scope", "$element", "$attrs", "$transclude", "$mdSidenav", "$state", "$stateParams", "$translate", "connection", "entityManager", "checkManager", "newRoom", "editRoom", "moveTable", "editTable", "confirmDialog", "alertDialog", "offlineDialog", "duplicateTable", function($scope, $element, $attrs, $transclude, $mdSidenav, $state, $stateParams, $translate, connection, entityManager, checkManager, newRoom, editRoom, moveTable, editTable, confirmDialog, alertDialog, offlineDialog, duplicateTable) {

            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            $scope.goTo = function(destination) {
                $mdSidenav('left').close();
                $state.go("app." + destination, {
                    id: $stateParams.id
                });
            };

            $scope.goToView = function() {
                $state.go('app.tables.rooms-view', {
                    id: $stateParams.id
                });
            };

            $scope.goToViewRoom = function(room_id) {
                $state.go('app.tables.rooms-view', {
                    id: room_id
                });
            };

            $scope.isUserPermitted = function(permissionName) {
                return checkManager.isUserPermitted(permissionName);
            };

            $scope.openNewRoom = function() {
                newRoom.show();
            };

            $scope.openEditRoom = function() {
                editRoom.show($scope.context.currentRoom, $scope.context.orders, editRoom);
            };

            $scope.deleteRoom = function() {
                var pendingOrder = _.find($scope.context.orders, {
                    room_id: $scope.context.currentRoom.id,
                    status: 'closed'
                });

                if (pendingOrder) {
                    alertDialog.show($translate.instant('TABLES.DELETE_ROOM.BUSY_TABLES'));
                    return;
                }

                if(!_.isEmpty($scope.context.currentRoom.tables)) {
                    alertDialog.show($translate.instant('TABLES.DELETE_ROOM.HAS_TABLES'));
                    return;
                }

                confirmDialog.show($translate.instant('TABLES.DELETE_ROOM.CONFIRM', { roomName: $scope.context.currentRoom.name })).then(function(answer) {
                    if (answer) {
                        entityManager.rooms.deleteOneOnline($scope.context.currentRoom.id).then(function() {
                            $state.reload('app.tables');
                        });
                    }
                });
            };

            $scope.removeTable = function() {
                confirmDialog.show($translate.instant('TABLES.DELETE_TABLES.CONFIRM')).then(function(answer) {
                    if (answer) {
                        var tmpRoom = _.cloneDeep($scope.context.currentRoom);
                        _.each($scope.context.selected_tables, function(table) {
                            _.remove(tmpRoom.tables, {
                                id: table.id
                            });
                        });
                        entityManager.rooms.putOneOnline(tmpRoom).then(function() {
                            $state.reload("app.tables");
                        });
                    }
                });
            };

            $scope.showRoomTotals = function() {
                return checkManager.isFunctionUserEnabledOptin("tables.show_room_totals");
            };

            $scope.moveTable = function() {
                moveTable.show($scope.context.selected_tables, $scope.context.rooms, $scope.context.currentRoom);
            };

            $scope.editTable = function() {
                var dialog = editTable;
                dialog.show($scope.context.selected_tables[0], $scope.context.currentRoom, editTable);
            };

            $scope.duplicateTable = () => {
                var dialog = duplicateTable;
                dialog.show($scope.context.selected_tables[0], $scope.context.currentRoom, duplicateTable);
            };

            $scope.showRemoveTableAction = function() {
                return $scope.context.editmode && !_.isEmpty($scope.context.selected_tables);
            };

            $scope.showMoveTableAction = function() {
                return $scope.context.editmode && !_.isEmpty($scope.context.selected_tables);
            };

            $scope.showEditTableAction = function() {
                return $scope.context.editmode && $scope.context.selected_tables.length === 1;
            };

            $scope.canZoomOut = function() {
                return $scope.context.mapZoom > 0.50;
            };

            $scope.canZoomIn = function() {
                return $scope.context.mapZoom < 2;
            };

            $scope.zoomOut = function() {
                $scope.context.mapZoom -= 0.25;
                checkManager.setUserPreference("rooms.zoom_factor", $scope.context.mapZoom);
            };

            $scope.zoomIn = function() {
                $scope.context.mapZoom += 0.25;
                checkManager.setUserPreference("rooms.zoom_factor", $scope.context.mapZoom);
            };

            $scope.grandTotal = 0;
            $scope.roomTotal = 0;

            $scope.showCashRegisterShortcut = checkManager.getPreference('ui.show_cashregister_shortcut');

            $scope.goToCashRegister = function() {
                $state.go("app.cashregister");
            };

            var roomId = _.get($scope.context, ["currentRoom", "id"]);
            $scope.generalCovers = 0;
            $scope.saleCovers = 0;
            if($scope.context.orders) {
                _.forEach($scope.context.orders, function(order) {
                    $scope.grandTotal += order.amount;
                    $scope.generalCovers += order.covers;
                    if(order.room_id === roomId) {
                        $scope.roomTotal += order.amount;
                        $scope.saleCovers += order.covers;
                    }
                });
            }

            // trovo il totale dei coperti della sala
            $scope.saleTotalCovers = 0;
            if($scope.context.currentRoom?.tables) {
                _.forEach($scope.context.currentRoom.tables, function(table) {
                    $scope.saleTotalCovers += table.covers;
                });
            }

            // trovo il totale di tutti i coperti delle sale
            $scope.generalTotalCovers = 0;
            if($scope.context.rooms) {
                _.forEach($scope.context.rooms, function(room) {
                    if(room.tables) {
                        _.forEach(room.tables, function(table) {
                            $scope.generalTotalCovers += table.covers;
                        });
                    }
                });
            }
        }]
    };
});
