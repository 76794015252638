import { EnvironmentConfig } from 'src/environments/environment-config';

export class DevLogger {
    private static enabled = ['unstable', 'local', 'beta'].includes(EnvironmentConfig.environment);

    static debug(...args: any[]) {
        if(this.enabled) {
            console.debug(...args);
        }
    }

    static info(...args: any[]) {
        if(this.enabled) {
            console.info(...args);
        }
    }

    static warn(...args: any[]) {
        if(this.enabled) {
            console.warn(...args);
        }
    }

    static error(...args: any[]) {
        if(this.enabled) {
            console.error(...args);
        }
    }

    static log(...args: any[]) {
        if(this.enabled) {
            console.log(...args);
        }
    }
}
