import Dexie from 'dexie';
import { Injectable, inject } from '@angular/core';
import { errorsLogger } from 'app/ajs-upgraded-providers';
import { CoreStateService } from './core.state.service';
import { chunk } from 'src/app/shared/utils';

@Injectable({
    providedIn: "root"
})
export class IndexeddbManagerService {
    // Injectables
    private readonly errorsLogger = inject(errorsLogger);
    private readonly coreStateService = inject(CoreStateService);

    // Private
    private static readonly dbName = 'scloby';
    private dbHandle: Dexie | null = null;

    constructor(
    ) {
        this.dbHandle = new Dexie(IndexeddbManagerService.dbName);
        this.initDb(this.dbHandle);
    }

    /**
     * Creates the schemas of the database
     * @param dbHandle the Dexie DB instance
     */
    private initDb(dbHandle: Dexie) {
        dbHandle.version(1).stores({
            entity_schemas: "id",
            //Api Entities
            rooms: "id,dirty,deleted",
            orders: "id,dirty,deleted",
            shop_settings: "id,dirty,deleted",
            user_settings: "id_compound,user_id,dirty,deleted",
            user_preferences: "id_compound,user_id,dirty,deleted",
            shop_preferences: "id,dirty,deleted",
            static_resources: "id,dirty,deleted",
            items: "id,dirty,deleted",
            categories: "id,dirty,deleted",
            departments: "id,dirty,deleted",
            vat: "id,dirty,deleted",
            customers: "id,dirty,deleted",
            components: "id,dirty,deleted",
            printers: "id,dirty,deleted",
            sales: "id,dirty,deleted",
            payment_methods: "id,dirty,deleted",
            allergens: "id,dirty,deleted",
            //media is not an entity stored
            daily_closings: "id,dirty,deleted",
            tickets: "id,dirty,deleted",

            //and others here
            shop_session: "id",
            user_sessions: "id,active"
        })

        dbHandle.version(2).stores({
            suppliers: "id,dirty,deleted",
            stock: "id,dirty,deleted",
            raw_materials: "id,dirty,deleted"
        });

        dbHandle.version(3).stores({
            dgfe: "id,dirty,deleted"
        });

        dbHandle.version(4).stores({
            fidelities_points: "id,dirty,deleted",
            campaigns: "id,dirty,deleted"
        });

        dbHandle.version(5).stores({
            cash_movements: "id,dirty,deleted"
        });

        dbHandle.version(6).stores({
            static_resources: null
        });

        dbHandle.version(7).stores({
            application_reports: "id, dirty, deleted"
        });

        dbHandle.version(8).stores({
            nonfiscal_documents: "id, dirty, deleted"
        });

        dbHandle.version(9).stores({
            channels: "id,dirty,deleted"
        });

        dbHandle.version(10).stores({
            bookings: "id,dirty,deleted",
            booking_shifts: "id,dirty,deleted",
        });

        dbHandle.version(11).stores({
            prizes: "id,dirty,deleted"
        });

        dbHandle.version(12).stores({
            promotions: "id,dirty,deleted"
        });

        dbHandle.version(13).stores({
            activity_codes: "id,dirty,deleted"
        });

        dbHandle.version(14).stores({
            api_backup: "id,dirty"
        });

        dbHandle.version(15).stores({
            slave_devices: "id,uuid,session_token,dirty,deleted"
        });

        dbHandle.version(16).stores({
            api_backup: null,
            sale_transactions: "id,uuid,dirty,deleted"
        });

        dbHandle.version(17).stores({
            pending_prints: 'id'
        });

        dbHandle.version(18).stores({
            device_preferences: "id,dirty,deleted"
        });
    }

    /**
     * Fetches the collection using a paginated in order to avoid issues with indexedDB memory limits
     * @param dbCollection The Dexie collection object
     * @returns A promise with the array of the collection items
     */
    private async paginatedFetch(dbCollection: Dexie.Collection) {
        const results = [];
        const perPage = 20000;

        let lastResult;
        let currentOffset = 0;

        //Fetch all the results one page at a time. Stop when the last page doesn't fill the page limit
        do {
            lastResult = await dbCollection.clone().offset(currentOffset).limit(perPage).toArray();

            currentOffset += perPage;

            results.push(...lastResult);
        } while (lastResult.length >= perPage);

        return results;
    }

    /**
     * Forces an application restart due to an unrecoverable error
     */
    private unrecoverableError() {
        this.dbHandle = null;
        this.errorsLogger.sendReport({ type: 'idb_error', content: "Restarting application..." }, { skipStorage: true });
        this.coreStateService.coreError$.next({ type: 'STORAGE_ERROR', needsRestart: true });
    }

    /**
     * Sends a report to the API
     * @param content The content of the report
     */
    private sendReport(content: any) {
        this.errorsLogger.sendReport({ type: 'idb_error', content }, { skipStorage: true });
    }

    /**
     * Logs an event to the console
     * @param level the log level (err, warn, info, debug)
     * @param args the arguments to log
     */
    private logEvent(level: string, ...args: any[]) {
        this.errorsLogger[level]('[ IndexedDBManager ]', ...args);
    }

    private iDBErrorCatcher(error: any) {
        this.logEvent('err', error);
        this.sendReport({ error_message: error.message, error_type: error.name });

        if (error instanceof Dexie.DexieError) {
            switch (error.name) {
                case Dexie.errnames.DatabaseClosed: //Database closed: attempt reopening
                    this.dbHandle = new Dexie(IndexeddbManagerService.dbName);
                    this.initDb(this.dbHandle);
                    break;
                case Dexie.errnames.Abort:
                    if (error.message.includes("An internal error was encountered in the Indexed Database server")) {
                        this.unrecoverableError();
                    }
                    break;
                case Dexie.errnames.InvalidState:
                    if (error.message.includes("The database connection is closing")) {
                        this.unrecoverableError();
                    }
                    break;
                case Dexie.errnames.Unknown:
                    if (error.message.includes("Refresh the page to try again")) {
                        this.unrecoverableError();
                    }
                    break;
            }
        }

        return error;
    };

    /**
     * Gets the Dexie table object for the given entity name
     * @param entityName The name of the entity to get the table object for
     * @returns The Dexie table object for the given entity name
     */
    private getTable(entityName: string) {
        const entityTable = this.dbHandle?.table(entityName);

        if (!entityTable) {
            this.logEvent('err', `[ ${entityName} ] table does not exist.`);
            throw 'INVALID_TABLE';
        }

        return entityTable;
    };

    /**
     * Gets the entity primary key name
     * @param entityName The name of the entity table to get the primary key name for
     * @returns The entity primary key name
     */
    public getPrimaryKeyName(entityName: string) {
        return this.getTable(entityName).schema.primKey.name;
    };

    /**
     * Gets the indexes for the given entity
     * @param entityName The name of the entity table to get the indexes for
     * @returns A map of the indexes by name
     */
    public getIndexesByName(entityName: string) {
        return this.getTable(entityName).schema.idxByName;
    };

    /**
     * Gets an instance of an existing entity using the id
     * @param entityName The name of the entity store
     * @param id The id of the entity to get
     * @returns The entity with the given id, or undefined if it does not exist
     * @throws INVALID_TABLE if the table does not exist
     */
    public async getOne(entityName: string, id: number | string) {
        const table = this.getTable(entityName);

        try {
            const result = await table.get(id);

            if (!result || result.deleted) {
                this.logEvent('debug', `[ getOne ] [ ${entityName} ] entry not found.`);
            }

            return result;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    }

    /**
     * Saves an entity instance to the database
     * @param entityName The name of the entity store
     * @param entity The entity to save to the database
     * @returns the saved entity
     */
    public async saveOne(entityName: string, entity: any) {
        const table = this.getTable(entityName);

        try {
            await table.put(entity);

            return entity;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Deletes an entity from the database
     * @param entityName The name of the entity store
     * @param id The id of the entity to delete
     * @returns the deleted entity id
     */
    public async deleteOne(entityName: string, id: number | string) {
        const table = this.getTable(entityName);

        try {
            await table.delete(id);

            return id;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Updates an entity in the database
     * @param entityName The name of the entity store
     * @param id The id of the entity to update
     * @param fieldObj An object containing the fields to update
     * @returns If the entity update has been successful
     */
    public async updateOne(entityName: string, id: number | string, fieldObj: any) {
        const table = this.getTable(entityName);

        try {
            let updated = await table.update(id, fieldObj);

            return !!(updated);
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Deletes multiple entities from the database
     * @param entityName The name of the entity store
     * @param idList The list of the ids to delete from the store
     * @returns The idList of the deleted entities
     */
    public async deleteCollection(entityName: string, idList: (number | string)[]) {
        const table = this.getTable(entityName);

        try {
            await table.bulkDelete(idList);

            return idList;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Deletes all entities from a table
     * @param entityName The name of the entity store
     */
    public async clearCollection(entityName: string) {
        const table = this.getTable(entityName);

        try {
            await table.clear();
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Finds all entities with an index matching the given value
     * @param entityName The name of the entity store
     * @param value The value to search for in the index
     * @param index The name of the index to use to find the entity
     * @returns An array of all entities with an index matching the given value
     */
    public async findAllByIndex(entityName: string, value: any, index: string) {
        const table = this.getTable(entityName);

        try {
            const results = await this.paginatedFetch(table.where(index).equals(value));

            if (!results.length) {
                this.logEvent('debug', `[ findAllByIndex ] [ ${entityName} ] no results.`);
            }

            return results;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Deletes all entities with an index matching the given value
     * @param entityName The name of the entity store
     * @param value The value to search for in the index
     * @param index The name of the index to use to find the entity
     * @returns An array of the ids of the deleted entities
     */
    public async deleteCollectionByIndex(entityName: string, value: any, index: string) {
        const table = this.getTable(entityName);

        try {
            const collection = table.where(index).equals(value);
            const ids = await collection.primaryKeys();

            await collection.delete();

            return ids;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    }

    /**
     * Returns every entity present in the entity store
     * @param entityName The name of the entity store
     * @returns An array of every entity present in the entity store
     */
    public async getCollection(entityName: string): Promise<any[]> {
        const table = this.getTable(entityName);

        try {
            return this.paginatedFetch(table.toCollection());
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Save a collection of entities to the entity store
     * @param entityName The name of the entity store
     * @param collection The collection of entities to save to the entity store
     * @returns The collection of entities saved to the entity store
     */
    public async saveCollection(entityName: string, collection: any[]) {
        const chunkSize = 200;
        const table = this.getTable(entityName);

        try {
            //Split the collection into chunks of 200 entities
            const chunkedCollection = chunk(collection, chunkSize);

            //Save each chunk
            for (const chunk of chunkedCollection) {
                await table.bulkPut(chunk);
            }

            return collection;
        } catch (error: any) {
            throw this.iDBErrorCatcher(error);
        }
    };

    /**
     * Returns the table schema for the given entity
     * @param entityName The name of the entity table to get the schema for 
     * @returns The table schema for the given entity
     */
    public getTableInfo(entityName: string) {
        return this.getTable(entityName).schema;
    }

    /**
     * Clears every table in the database
     */
    public async destroyStorage() {
        const TablesToClear = this.dbHandle?.tables || [];

        for (const table of TablesToClear) {
            try {
                await table.clear();
            } catch (error: any) {
                this.logEvent('err', `[ destroyStorage ] Couldn't clear table ${table.name}`);
            }
        }
    }
}