<h1 mat-dialog-title class="tw-mb-4" translate>SETTINGS.ADD_NEW_STATIC_TOKEN_DIALOG.TITLE</h1>
<div mat-dialog-content style="max-height: 75vh">

    <mat-label translate class="tw-font-bold tw-mb-10">SETTINGS.COPY_NEW_STATIC_TOKEN_DIALOG.TITLE</mat-label>

    <p class="tw-mb-8 tw-mt-8" translate>
        SETTINGS.COPY_NEW_STATIC_TOKEN_DIALOG.PARAGRAPH_1
    </p>
    <p class="tw-mb-8" translate>
        SETTINGS.COPY_NEW_STATIC_TOKEN_DIALOG.PARAGRAPH_2
    </p>

    <mat-form-field class="tw-w-full">
        <mat-label translate>Static Token</mat-label>
        <input matInput id="staticToken" type="text" class="form-control" [value]="data.token" readonly>
    </mat-form-field>
</div>
<div mat-dialog-actions class="tw-justify-end">
    <button mat-button (click)="dialogRef.close()"><span translate>SETTINGS.COPY_NEW_STATIC_TOKEN_DIALOG.CLOSE</span></button>
</div>
