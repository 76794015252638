import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('generalDocumentPrinterSelect', ["$mdDialog", "entityManager", function($mdDialog, entityManager) {
    generalDocumentPrinterSelectController.$inject = ["$scope", "$mdDialog"];

    function generalDocumentPrinterSelectController($scope, $mdDialog) {
        $scope.printers = [];

        entityManager.printers.fetchCollectionOffline({ type: 'fiscal' })
        .then(function(printers) {
            $scope.printers = $scope.printers.concat(printers);
            return entityManager.printers.fetchCollectionOffline({ type: 'rt' });
        })
        .then(function(printers) {
            $scope.printers = $scope.printers.concat(printers);
            return entityManager.printers.fetchCollectionOffline({ type: 'receipt' });
        })
        .then(function(printers) {
            $scope.printers = $scope.printers.concat(printers);
        });

        $scope.cancel = function() {
            $mdDialog.hide(undefined);
        };

        $scope.selectPrinter = function(printer) {
            $mdDialog.hide(printer);
        };
    }

    var generalDocumentPrinterSelect = {
        show: function(options) {
            var defaultOptions = {
                controller: generalDocumentPrinterSelectController,
                template: require('./general-document-printer-select.html'),
                multiple: true
            };

            if (_.isObject(options)) {
                _.merge(options, defaultOptions);

                if (options.blocking) {
                    options.clickOutsideToClose = false;
                    options.escapeToClose = false;
                }
            } else {
                options = defaultOptions;
            }
            return $mdDialog.show(options);
        }
    };

    return generalDocumentPrinterSelect;
}]);