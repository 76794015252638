
    <tilby-dialog-toolbar mat-dialog-title
    title="SETTINGS.EDIT_USER_ACEESS_DIALOG.TITLE_FIRSTNAME"
    [disabled]="!editFirstNameForm.valid || inProgress"
    (confirm)="confirm()" />
    @if (inProgress) {
        <tilby-dialog-progress-bar/>
    }
    <tilby-dialog-content>
        <tilby-magic-form [form]="editFirstNameForm"/>
    </tilby-dialog-content>

