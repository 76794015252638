<!--<mat-checkbox [aria-label]="'select_all'"></mat-checkbox>-->
<app-grid-server-side
    #appGridComponent
    (areRowsSelected)="isSelected=$event"
    (clickableAction)="actions($event)"
    (rowClicked)="rowClicked($event)"
    (gridReadyEmitter)="onGridReady()"
    [cacheBlockSize]="query.per_page"
    [datasource]="datasource"
    [tableData]="tableData[0]"
    [fixedButtons]="fixedButtons"
    [exportFormatterOptions]="exportFormatterOptions"
    feature="history_sales"
    (firstDataRenderedEmitter)="onFirstDataRendered()"
    (sortChangedEmitter)="onSortChangedEmitter($event)"
    (filterChangedEmitter)="onFilterChangedEmitter($event)"
    [selectRowUIEnabled]="selectRowUIEnabled"
></app-grid-server-side>
<app-barcode [hideButton]="true" (onSubmitBarcode)="onBarcodeScanned($event)"></app-barcode>