import {Component} from '@angular/core';


@Component({
    selector: 'app-generalized-toolbar',
    templateUrl: './generalized-toolbar.component.html',
    styleUrls: ['./generalized-toolbar.component.scss'],
    host: {
        style: 'width:100%',
        class: 'tw-flex tw-justify-between tw-items-center'
    }
})
export class GeneralizedToolbarComponent{}
