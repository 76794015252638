import {Component, Inject, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {IServerSideDatasource, RowClickedEvent} from 'ag-grid-community';
import {GridServerSideComponent} from 'src/app/shared/components/grid-server-side/grid-server-side.component';
import {QueryPaginationPrepaid, TableData} from 'src/app/shared/model/model';
import { DateFilter, ShopChain} from 'src/app/models';
import {
    GridClickableTypeButtonComponent,
    GridFixedButtons,
    headersTranslate,
    ImplementServerSideGrid
} from 'src/app/shared/components/grid';
import {restManager, RootScope} from 'app/ajs-upgraded-providers';
import {CustomersFields} from '../customer-form/customers-form.model';
import {from} from 'rxjs';
import {PrepaidMovementsFe, PrepaidMovementsFields} from '../../../../customers.model';
import {OpenDialogsService} from 'src/app/dialogs';
import {GridCellFormatterService} from 'src/app/shared/components/grid/utils/grid-cell-formatter.service';
import {CustomersPrepaidCreditFormGroups} from './customer-prepaid-credit-form/custumer-prepaid-credit-form.model';
import {FormGroup} from '@angular/forms';
import {autobind} from '../../../../../../models/decorators.model';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {
    CustomerPrepaidCreditFormComponent
} from './customer-prepaid-credit-form/customer-prepaid-credit-form.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModuleEvents, PaginatedResponse, ToolbarEventsService} from "src/app/core/services";
import {filtersMenuDisabled} from 'src/app/shared/components/grid/utils/grid-headers-filter';
import {sortingsMenuDisabled} from 'src/app/shared/components/grid/utils/grid-headers-sorting';
import { TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { GridCellExportFormatterService } from 'src/app/shared/components/grid/utils/grid-cell-export-formatter.service';
import { IAngularEvent } from 'angular';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';


@Component({
    selector: 'app-customer-prepaid-credit',
    templateUrl: './customer-prepaid-credit.component.html',
    styleUrls: ['./customer-prepaid-credit.component.scss'],
    providers: [OnDestroyService],
    host: {class: 'tw-block tw-h-full tw-w-full'}
})
export class CustomerPrepaidCreditComponent implements OnInit, OnDestroy, ImplementServerSideGrid {
    @Input() customer?: CustomersFields;
    @Input() credit?: number;
    @Input() chainShops?: ShopChain[];

    fixedButtons: GridFixedButtons = {
        checkbox: {visible: false, lockPosition: 'left'},
        customButton: {visible: true, cellRenderer: GridClickableTypeButtonComponent, lockPosition: 'left'}
    }
    exportFormatterOptions?: (...any:any[])=>any;
    @ViewChild('appGridComponent', {static: true}) gridRef!: GridServerSideComponent;
    @ViewChild('appCustomerPrepaidCreditForm', {static: true}) customerPrepaidCreditFormComponent!: CustomerPrepaidCreditFormComponent;
    query!: Readonly<QueryPaginationPrepaid>;
    dateFilter: DateFilter = {
        from: TilbyDatePipe.shopDateStart({plus: {years: -2}})||'',
        to: TilbyDatePipe.shopDateEnd()||''
    }

    datasource: IServerSideDatasource = {
        getRows: (params) => this.gridRef.getRowsForServersideDatasource(params, ['prepaid_movements', this.query], (a, b?) => from(this.restManagerService.getList(a, b) as Promise<PaginatedResponse<PrepaidMovementsFe>>), {
            sort_type: 'desc',
            sort_col: 'valid_from'
        })
    };

    tableData: TableData[] = [
        {
            rowData$: null,
            dataType: new PrepaidMovementsFe(),
            headersTranslate: headersTranslate.customers_prepaid,
            columnsFormatter: this.gridCellFormatterService.customers_prepaid,
            filtersMenuDisabled: filtersMenuDisabled.customers_prepaid,
            sortingsMenuDisabled: sortingsMenuDisabled.customers_prepaid,
        }
    ];
    public selectedShop: Partial<ShopChain> = {};
    onPrepaidUpdated: any;


    constructor(
        @Inject(restManager) private restManagerService: any,
        @Inject(RootScope) private $rootScope: any,
        private openDialogsService: OpenDialogsService,
        private translate: TranslateService,
        private _snackBar: MatSnackBar,
        private toolbarEventsService: ToolbarEventsService,
        private onDestroyService: OnDestroyService,
        private gridCellFormatterService: GridCellFormatterService,
        private gridCellExportFormatterService: GridCellExportFormatterService
    ) {

        this.onPrepaidUpdated = this.$rootScope.$on('prepaid-updated', (event: IAngularEvent, prepaidInfo: PrepaidMovementsFields) => {
            if (prepaidInfo.customer_uuid === this.customer?.uuid) {
                _.set(this, ['prepaidInfo', 'credit'], prepaidInfo.credit);
                _.set(this, ['prepaidInfo', 'ticket_credit'], prepaidInfo.ticket_credit);
                this._snackBar.open(this.translate.instant('PREPAID.DETAILS.NEW_MOVEMENTS'), this.translate.instant('PREPAID.DETAILS.RELOAD'), {duration: 0})
                    .afterDismissed().subscribe(answer => {
                    if (answer.dismissedByAction && this.customerPrepaidCreditFormComponent?.prepaidCreditForm) {
                        this.refresh(this.customerPrepaidCreditFormComponent.prepaidCreditForm);
                    }
                });
            }
        });

    }

    ngOnInit(): void {
        this.query = {
            pagination: true,
            per_page: 50,
            valid_from_since: this.dateFilter.from,
            valid_from_max: this.dateFilter.to,
            shop_uuid: null,
            customer_uuid: this.customer?.uuid
        };
        this.exportFormatterOptions = this.gridCellExportFormatterService.prepaidExportFormatter;
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e))
    }

    ngOnDestroy(): void {
        this.onPrepaidUpdated();
    }

    private callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {
        if ("search" in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("openToolPanel" in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
    }

    async rowClicked($event: RowClickedEvent) {
        await this.openDialogsService.openPrepaidMovementDetailsDialog({data: {movement: $event.data, params: {customer: this.customer || {}}}});
    }

    refresh(prepaidCreditForm: FormGroup<CustomersPrepaidCreditFormGroups>) {
        const {date_since, date_max, db_name} = prepaidCreditForm.value.customersPrepaidCreditForm || {};
        this.selectedShop = {
            uuid: (db_name && db_name !== '-1')
                ? db_name
                : null
        };
        this.query = {
            pagination: true,
            per_page: 50,
            valid_from_since: date_since ? TilbyDatePipe.shopDateStart({date:date_since}) : null,
            valid_from_max: date_max ? TilbyDatePipe.shopDateEnd({date:date_max}) : null,
            shop_uuid: this.selectedShop?.uuid,
            customer_uuid: this.customer?.uuid
            // db_name: ($event.value.customersPointsForm?.db_name && $event.value.customersPointsForm?.db_name !== "-1") ? $event.value.customersPointsForm.db_name : null
        }

        this.datasource = {
            getRows: (params) => this.gridRef.getRowsForServersideDatasource(params, ['prepaid_movements', this.query], (a, b?) => from(this.restManagerService.getList(a, b) as Promise<PaginatedResponse<PrepaidMovementsFe>>), {
                sort_type: 'desc',
                sort_col: 'date'
            })
        };

        this.gridRef.refreshGrid();
    }

    @autobind
    sendPrepaidCreditReport(email: string) {
        this.restManagerService.getOne('prepaid_movements', 'export', {
            email: email,
            customer_uuid: this.customer?.uuid,
            shop_uuid: this.selectedShop?.uuid || undefined,
            valid_from_since: this.dateFilter.from,
            valid_from_max: this.dateFilter.to
        }).then(() => this.openDialogsService.openAlertDialog({data: {messageLabel: this.translate.instant('PREPAID.DETAILS.SEND_FIDELITY_REPORT.CONFIRM')}}),
            (error: string) => {
                let errMsg;
                switch (_.get(error, ['data', 'error', 'message'])) {
                    case 'email param not found':
                        errMsg = this.translate.instant('PREPAID.DETAILS.SEND_FIDELITY_REPORT.INVALID_EMAIL');
                        break;
                    default:
                        errMsg = this.translate.instant('PREPAID.DETAILS.SEND_FIDELITY_REPORT.ERROR_SENDING');
                        break;
                }
                this.openDialogsService.openAlertDialog({data: {messageLabel: errMsg}});
            });
    }


}
