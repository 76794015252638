import * as angular from 'angular';
import * as _ from 'lodash';
import { EnvironmentConfig } from 'src/environments/environment-config';

const { tilbyVersion } = require('app/tilby.properties.json');

angular.module('core').factory('restManager', restManager);

restManager.$inject = ["$http", "$state", "$timeout", "$injector", "connection", "environmentInfo"];

function restManager($http, $state, $timeout, $injector, connection, environmentInfo) {
    /*
        * Behaviour specifications for REST methods:
        * getOne
        *       Success: resolve (Object)
        *       Failed (404): resolve (null)
        *       Failed (offline): reject (error) + trigger offline mode (handled by the interceptor)
        *       Failed (other backend error): reject (error)
        * getList
        *       Success: resolve (Array)
        *       Failed (404): resolve (empty Array)
        *       Failed (offline): reject (error) + trigger offline mode (handled by the interceptor)
        *       Failed (other backend error): reject (error)
        * post/put/deleteOne
        *       Success: resolve (response)
        *       Failed (offline): reject (error) + trigger offline mode (handled by the interceptor)
        *       Failed (backend error): reject (error)
    */
    const coreEndpoint = EnvironmentConfig.apiUrl || 'https://api.scloby.com/v2';
    const analyticsEndpoint = EnvironmentConfig.analyticsUrl || 'https://analytics-api.scloby.com/v1';

    const getBaseUrl = (entityName) => {
        return `${coreEndpoint}/${entityName}`;
    };

    const getAnalyticsReq = (endpoint, params) => ({
        method: 'GET',
        url: `${analyticsEndpoint}/${_.toString(endpoint)}`,
        params: params,
        headers: {
            'Authorization': `Bearer ${restManager.getAccessToken()}`
        }
    });

    let currentHeaders = null;
    let oauthData = {};

    const emergencyLogout = (targetState) => {
        if (targetState !== 'deactivated') {
            oauthData = {};
        }

        if(targetState) {
            $state.go(targetState);
        } else {
            $injector.get("sessionManager").logoutActiveUserSession(false);
        }
    };

    let refreshPromise = null;

    const refreshToken = async () => {
        //Keep the promise for 1 minute
        $timeout(() => {
            refreshPromise = null;
        }, 60000, false);

        try {
            let newOauthData = await $injector.get("sessionManager").refreshTokenActiveSession();
            await restManager.getUserSession(newOauthData.access_token);

            restManager.setUser(newOauthData);
            return newOauthData;
        } catch(error) {
            emergencyLogout();
            throw error;
        }
    };

    const getHeadersTemplate = (accessToken) => ({
        "Authorization": `Bearer ${accessToken}`,
        "session-origin": environmentInfo.getSessionId(),
        "Client-Version": tilbyVersion
    });

    const sendHttpRequest = async (httpConfig) => {
        let isOwnRequest = _.get(currentHeaders, ['Authorization']) === _.get(httpConfig, ['headers', 'Authorization']);

        try {
            let response = await $http(httpConfig);

            return new Promise((resolve, reject) => {
                resolve(response);

                if(isOwnRequest && response.headers('Scl-Shop-Deactivated') === 'true') {
                    emergencyLogout('deactivated');
                }
            });
        } catch(error) {
            switch(error.status) {
                case -1: //Offline case: check for online using isOnlinePing (don't go offline directly as -1 can also be used for timed out requests)
                    connection.isOnlinePing();
                break;
                case 401: //Unauthorized case
                    if(isOwnRequest) {
                        let errCode = _.get(error, ["data", "error", "code"]);

                        switch(errCode) {
                            case 150: //Invalid token
                                if(!refreshPromise) {
                                    refreshPromise = refreshToken();
                                }

                                try {
                                    let newOauthData = await refreshPromise;
                                    angular.copy(getHeadersTemplate(newOauthData.access_token), error.config.headers);
    
                                    // Repeat the request and then call the handlers the usual way.
                                    return $http(error.config);
                                } catch(error) {}
                            break;
                            case 155: //Shop deactivated
                                emergencyLogout('deactivated');
                            break;
                            default:
                            break;
                        }
                    }
                    break;
                default:
                break;
            }

            //Throw the error in case we didn't manage to recover the request (e.g. refresh token)
            throw error;
        }
    };

    const getRequestConfig = (entityName, entityID, method, headers, payload, params, options) => {
        let requestUrl = getBaseUrl(entityName);

        if(!_.isNil(entityID)) {
            requestUrl += '/' + entityID;
        }

        let requestConfig = {
            headers: _.clone(headers),
            method: method,
            timeout: 60000,
            url: requestUrl
        };

        if(!_.isNil(payload)) {
            requestConfig.data = payload;
        }

        if(_.isObject(params)) {
            requestConfig.params = params;
        }

        _.merge(requestConfig, options);

        return requestConfig;
    };

    const sendAPIRequest = async (entityName, entityID, method, headers, payload, params, options) => {
        if(!_.isObject(options)) {
            options = {};
        }

        if(_.isNil(headers)) {
            headers = currentHeaders;
        }

        if(_.isEmpty(oauthData)) {
            throw 'MISSING_OAUTH_DATA';
        }

        let requestConfig = getRequestConfig(entityName, entityID, method, headers, payload, params, options);

        try {
            let response = await sendHttpRequest(requestConfig);
            
            if (_.isString(response.data)) {
                response.data = { message: response.data };
            }

            return response;
        } catch(error) {
            switch(error.status) {
                case 404:
                    switch (_.get(error, ["config", "method"])) {
                        case 'PUT': case 'POST':
                        break;
                        default:
                            if (_.get(error.data, ["error", "code"]) === 50) {
                                if (_.isNil(entityID)) {
                                    error.data = [];
                                } else {
                                    error.data = null;
                                }
                            }

                            return error;
                    }
                    break;
                default:
                break;
            }

            throw error;
        }
    };

    const setHeaders = (accessToken) => {
        currentHeaders = getHeadersTemplate(accessToken);
    };

    const restManager = {
        getAccessToken: () => oauthData.access_token,
        setAccessToken: (accessToken) => {
            oauthData.access_token = accessToken;
            setHeaders(accessToken);
        },
        setUser: (oData) => {
            oauthData = _.clone(oData);
            setHeaders(oauthData.access_token);
        },

        getUserSession: (accessToken) => $http({ method: 'GET', url: getBaseUrl('sessions/me'), headers: getHeadersTemplate(accessToken) }).then((response) => response.data),

        getOne: (entityName, entityID, params) => sendAPIRequest(entityName, entityID, 'GET', null, null, params).then((response) => response.data),

        downloadOne: (entityName, entityID, params) => sendAPIRequest(entityName, entityID, 'GET', null, null, params, { responseType: 'blob' }),

        getList: (entityName, params) => sendAPIRequest(entityName, null, 'GET', null, null, params).then((response) => response.data),

        post: (entityName, entityData, queryParams, headers) => sendAPIRequest(entityName, null, 'POST', null, _.cloneDeep(entityData), queryParams, { headers: headers }).then((response) => response.data),

        postOne: (entityName, entityData, queryParams, headers) => sendAPIRequest(entityName, null, 'POST', null, _.cloneDeep(entityData), queryParams, { headers: headers, transformRequest: angular.identity }).then((response) => response.data),

        put: (entityName, entityID, entityData, queryParams) => sendAPIRequest(entityName, entityID, 'PUT', null, _.cloneDeep(entityData), queryParams).then((response) => response.data),

        deleteOne: (entityName, entityID, params) => {
            if (!_.isObject(params)) {
                params = null;
            }

            return sendAPIRequest(entityName, entityID, 'DELETE', null, null, params).then((response) => response.data);
        },
        deleteList: (entityName, entityIDs, params) => {
            if (!_.isObject(params)) {
                params = null;
            }

            return sendAPIRequest(entityName, 'bulk_delete', 'DELETE', null, { ids: entityIDs }, params, { headers: { 'Content-Type': 'application/json' } }).then((response) => response.data );
        },
        sendDelegatedRequest: (entityName, method, entityID, accessToken, entityData) => sendAPIRequest(entityName, entityID, method, getHeadersTemplate(accessToken), entityData),
        invoicePassive: (entityName, entityID, params, method) => {
            //"params" can be body or queryString, if it's a GET => it is a query string, otherwise it's body
            if (method === "GET") {
                return sendAPIRequest(entityName, entityID, method, null, null, params).then((response) => response.data);
            }

            return sendAPIRequest(entityName, entityID, method, null, params, null).then((response) => response.data);
        },
        analyticsGet: (endpointName, params) => $http(getAnalyticsReq(endpointName, params)).then(response => response.data)
    };

    return restManager;
}
