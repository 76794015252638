import * as angular from 'angular';
import * as hmacSHA256 from 'crypto-js/hmac-sha256';
import * as Hex from 'crypto-js/enc-hex';
import * as _ from 'lodash';

angular.module('digitalPayments').factory('cashmaticDriver', cashmaticDriver);

cashmaticDriver.$inject = ["fiscalUtils"];

function cashmaticDriver(fiscalUtils) {
    let self = {
        pendingSocketCallbacks: [],
        currentOperations: 0
    };

    const cashmaticCommands = {
        SYNC: 0x21,
        START_TRANSACTION: 0x22,
        CANCEL_TRANSACTION: 0x23,
        START_REFIL: 0x24,
        STOP_REFIL: 0x25,
        START_WITHDRAWAL: 0x26,
        COIN_PARTIAL_WITHDRAWAL: 0x27,
        COIN_TOTAL_WITHDRAWAL: 0x28,
        NOTE_PARTIAL_TRANSFER: 0x29,
        NOTE_TOTAL_TRANSFER: 0x2A,
        NOTE_TOTAL_WITHDRAWAL: 0x2B,
        TRANSACTION_STATUS: 0x2C,
        GET_COINS_LEVEL: 0x2D,
        GET_NOTES_LEVEL: 0x2E,
        GET_STAKER_LEVEL: 0x2F,
        SET_COINS_FLOAT_LEVEL: 0x30,
        GET_COINS_FLOAT_LEVEL: 0x31,
        SET_NOTES_FLOAT_LEVEL: 0x32,
        GET_NOTES_FLOAT_LEVEL: 0x33,
        SET_NOTES_MAX_LEVEL: 0x34,
        GET_NOTES_MAX_LEVEL: 0x35,
        COIN_PAY_BY_DENOMINATION: 0x36,
        NOTE_PAY_BY_DENOMINATION: 0x37,
        SET_TCP_KEY: 0x38
    };

    const cashmaticResposes = {
        0xF0: 'OK',
        0xF2: 'UNKNOWN',
        0xF3: 'WRONG_NO_PARAMETERS',
        0xF4: 'PARAMETER_OUT_OF_RANGE',
        0xF5: 'COMMAND_CANNOT_BE_PROCESSED',
        0xF7: 'RESPONSE_CHECKSUM_ERROR'
    };

    const hexConvTable = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F'];

    const eventLog = (message) => console.debug("[Cashmatic]", message);

    const packetToArrayBuffer = (packet) =>  {
        let buffer = new ArrayBuffer(packet.length);
        let bufView = new Uint8Array(buffer);

        for (let i = 0; i < packet.length; i++) {
            bufView[i] = packet[i];
        }

        return buffer;
    };

    const getPacket = function (data, index) {
        let packet = [
            0x02, //STX
            0x32, //ADDRESS
            index, //MSG_ID,
            (0x20 + data.length), //LENGTH
            ...data //DATA
        ];

        //HMAC
        //Create message string (in Hex format) with all bytes until this point, excluding STX
        let messageToHash = '';

        for(let byte of packet.slice(1)) {
            messageToHash += byte.toString(16);
        }

        let hash = hmacSHA256(Hex.parse(messageToHash), self.hmacKey).toString(Hex);
        let last8Bytes = hash.substr(48, 16).split('');

        while(!_.isEmpty(last8Bytes)) {
            packet.push(_.toUpper(last8Bytes.shift()).charCodeAt(0));
        }

        //CRC
        let crc = 0x55;

        for(let i = 1; i < packet.length; i++) {
            crc ^= packet[i];
        }

        packet.push(hexConvTable[crc >> 4 & 0x0F].charCodeAt(0)); //CRCH
        packet.push(hexConvTable[crc & 0x0F].charCodeAt(0)); //CRCL

        packet.push(0x03); //ETX

        return packetToArrayBuffer(packet);
    };
    
    const parseResponse = function(resp) {
        //TODO: considering we have crc in the response, it would be nice to have some sort of response check
        let response = {};

        if(resp.length >= 5) {
            response.statusCode = resp[4];
            response.status = cashmaticResposes[response.statusCode];

            if(response.status === 'OK') {
                let dataLength = resp[3] - 0x20;
                let data = [];

                for(let i = 5; i < (4 + dataLength); i++) { //Copy all response data, ignoring status byte (we already put that in statusCode)
                    data.push(resp[i]);
                }

                response.data = data;
            }
        } else {
            response.status = 'INVALID_RESPONSE';
        }

        return response;
    };

    const parseTrStatusResponse = function(responseData) {
        let result = {};
        let resp = _.cloneDeep(responseData);
        let transactionStatus = resp.shift();
        let machineStatus = resp.shift();
        
        //Transaction status (1st byte)
        switch(transactionStatus) {
            case 0x50:
                result.transactionStatus = 'OPEN';
                break;
            case 0x51:
                result.transactionStatus = 'CLOSED';
                break;
            default:
                result.transactionStatus = 'UNKNOWN_TRANSACTION_STATUS';
                break;
        }

        switch(machineStatus) {
            case 0xAA:
                result.machineStatus = 'OK';
                break;
            case 0xAB:
                result.machineStatus = 'BILL_MODULE_ERROR';
                break;
            case 0xAC:
                result.machineStatus = 'COIN_MODULE_ERROR';
                break;
            default:
                result.machineStatus = 'UNKNOWN_MACHINE_STATUS';
                break;
        }

        let respStr = ab2str(resp);

        Object.assign(result, {
            toPay: fiscalUtils.roundDecimals(_.toNumber(respStr.substr(0, 8)) / 100),
            paid: fiscalUtils.roundDecimals(_.toNumber(respStr.substr(8, 8)) / 100),
            changeGiven: fiscalUtils.roundDecimals(_.toNumber(respStr.substr(16, 8)) / 100),
            changeNotGiven: fiscalUtils.roundDecimals(_.toNumber(respStr.substr(24, 8)) / 100),
            currency: resp[32] === 0x3A ? 'EUR' : '???',
            documentNumber: respStr.substr(33, 4)
        });

        return result;
    };

    /** ab2str **/
    const ab2str = (buf) => String.fromCharCode.apply(null, buf);

    const getConnectionSocket = function(successFunction, errorFunction) {
        if(_.isObject(self.socket)) {
            self.currentOperations++;
            successFunction(self.socket);
        } else if(self.socket === 'creating') {
            self.pendingSocketCallbacks.push({ success: successFunction, error: errorFunction });
        } else {
            self.pendingSocketCallbacks.push({ success: successFunction, error: errorFunction });
            self.socket = 'creating';
            let timeoutHandle = null;

            let cb;

            window.chrome.sockets.tcp.create(function(socketInfo) {
                let timeoutConn = function () {
                    window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                        if (!info.connected) {
                            closeSocket();
                            
                            while(!_.isEmpty(self.pendingSocketCallbacks)) {
                                cb = self.pendingSocketCallbacks.pop();
                                cb.error('CONN_TIMEOUT');
                            }
                        }
                    });
                };

                timeoutHandle = setTimeout(timeoutConn, 10000);

                window.chrome.sockets.tcp.connect(socketInfo.socketId, self.ipAddress, 50100, function(result) {
                    clearTimeout(timeoutHandle);
                    eventLog(`Socket connected to ${self.ipAddress}`);

                    if(result === 0) {
                        self.socket = socketInfo;

                        while(!_.isEmpty(self.pendingSocketCallbacks)) {
                            self.currentOperations++;
                            cb = self.pendingSocketCallbacks.pop();
                            cb.success(socketInfo);
                        }
                    } else {
                        closeSocket();

                        while(!_.isEmpty(self.pendingSocketCallbacks)) {
                            cb = self.pendingSocketCallbacks.pop();
                            cb.error('CONN_TIMEOUT');
                        }
                    }
                });
            });
        }
    };

    const closeSocket = function (callback) {
        self.currentOperations = 0;

        let tmpSocket = self.socket;
        self.socket = null;

        if(_.isObject(tmpSocket) && _.isInteger(tmpSocket.socketId)) {
            window.chrome.sockets.tcp.disconnect(tmpSocket.socketId, function() {
                eventLog("Socket disconnected");
    
                window.chrome.sockets.tcp.close(tmpSocket.socketId);
    
                if(_.isFunction(callback)) {
                    callback();
                }
            });
        }
    };

    const startOperation = function() {
        self.currentOperations++;
    };

    const endOperation = function() {
        self.currentOperations--;

        if(self.currentOperations <= 0) {
            closeSocket(self.socket);
        }
    };

    /** send **/
    const sendAndWaitResponse = function (message, callback) {
        if(!_.isFunction(callback)) {
            callback = _.noop;
        }

        getConnectionSocket(function() {
            let reqTimeout = null;
            self.msgIdx = _.clamp(self.msgIdx % 0x100, 0x20, 0xFF);

            let packet = getPacket(message, self.msgIdx++);
    
            const listen = function(info) {
                if (info.socketId === self.socket.socketId) {
                    clearTimeout(reqTimeout);
                    window.chrome.sockets.tcp.onReceive.removeListener(listen);
                    callback(parseResponse(new Uint8Array(info.data)));
                }
            };
    
            const timeoutFunc = function () {
                window.chrome.sockets.tcp.onReceive.removeListener(listen);
                callback({ status: "REQ_TIMEOUT" });
            };
    
            window.chrome.sockets.tcp.onReceive.addListener(listen);
    
            window.chrome.sockets.tcp.send(self.socket.socketId, packet, _.noop);
            reqTimeout = setTimeout(timeoutFunc, 10000);
        }, function() {
            callback({ status: "CONN_TIMEOUT" });
        });
    };

    const getTransactionId = function() {
        //Transaction id
        let trId = "";

        for(let i = 0; i < 4; i++) {
            trId += String.fromCharCode(_.random(0x30, 0x39));
        }

        return trId;
    };

    const getPaymentCommands = function(amount, trId) {
        let paymentCommands = [(amount > 0 ? cashmaticCommands.START_TRANSACTION : cashmaticCommands.START_WITHDRAWAL)];
        
        //AMOUNT
        let toPay = _.padStart(_.round(Math.abs(amount) * 100).toString(), 8, ' ');

        for(let i = 0; i < toPay.length; i++) {
            paymentCommands.push(toPay.charCodeAt(i));
        }

        paymentCommands.push(0x3A); //CURRENCY (EUR)

        if(amount > 0) {
            for(let i = 0; i < trId.length; i++) {
                paymentCommands.push(trId.charCodeAt(i));
            }
        }

        return paymentCommands;
    };

    /** pay **/
    const pay = function (amount, successFunction, errorFunction) {
        let trId = getTransactionId();
        let paymentData = getPaymentCommands(amount, trId);
        startOperation();

        // SEND PAY MESSAGE
        sendAndWaitResponse(paymentData, function(message) {
            if(message.status === 'OK') {
                //REQUEST STATUS UPDATE
                const requestTransactionStatus = function() {
                    sendAndWaitResponse([cashmaticCommands.TRANSACTION_STATUS], function(trMsg) {
                        switch(trMsg.status) {
                            case 'OK':
                                let trSt = parseTrStatusResponse(trMsg.data);

                                if(amount < 0 || trSt.documentNumber === trId) {
                                    if(_.isFunction(self.options.updateCallback)) {
                                        try {
                                            self.options.updateCallback(_.cloneDeep(trSt));
                                        } catch(e) {}
                                    }
    
                                    if(trSt.transactionStatus === 'OPEN' && trSt.machineStatus === 'OK') { //we are still in payment
                                        setTimeout(requestTransactionStatus, 500);
                                    } else { //the payment is closed or there is something wrong
                                        if(trSt.machineStatus !== 'OK') {
                                            errorFunction(trSt.machineStatus);
                                        } else if(trSt.transactionStatus !== 'CLOSED') { //unknown transaction status (unlikely to happen)
                                            errorFunction(trSt.transactionStatus);
                                        } else { //Transaction closed, check if the payment was successful based on the amounts
                                            if(amount < 0 || trSt.paid >= trSt.toPay) { //Payment successful
                                                successFunction(trSt);
                                            } else { //Payment not successful (Aborted)
                                                errorFunction('CANCELED');
                                            }
                                        }
                                        endOperation();
                                    }
                                } else {
                                    errorFunction('TRANSACTION_ID_MISMATCH');
                                    endOperation();
                                }
                            break;
                            case 'COMMAND_CANNOT_BE_PROCESSED':
                                errorFunction(trMsg.status);
                                endOperation();
                            break;
                            default:
                                errorFunction(trMsg.status);
                                endOperation();
                            break;
                        }
                    });
                };

                setTimeout(requestTransactionStatus, 500);
            } else if(message.status === 'COMMAND_CANNOT_BE_PROCESSED') {
                sendAndWaitResponse([cashmaticCommands.TRANSACTION_STATUS], function(trMsg) { //Check if there is another open transaction
                    switch(trMsg.status) {
                        case 'OK':
                            let trSt = parseTrStatusResponse(trMsg.data);
                            
                            if(trSt.transactionStatus === 'OPEN') {
                                self.options.updateCallback({ transactionStatus: 'BUSY' });
                                
                                const checkifBusy = function() { //Wait for the transaction to end
                                    if(self.socket) {
                                        sendAndWaitResponse([cashmaticCommands.TRANSACTION_STATUS], function(trMsg) {
                                            switch(trMsg.status) {
                                                case 'OK':
                                                    let trSt = parseTrStatusResponse(trMsg.data);
    
                                                    if(trSt.transactionStatus === 'CLOSED') {
                                                        setTimeout(function() {
                                                            pay(amount, successFunction, errorFunction); //Retry our transaction (wait 1.5 seconds to allow the other device to receive the transaction correctly)
                                                        }, 1500);
                                                    } else {
                                                        setTimeout(checkifBusy, 500);
                                                    }
                                                break;
                                                case 'COMMAND_CANNOT_BE_PROCESSED':
                                                    errorFunction(trMsg.status);
                                                    endOperation();
                                                break;
                                                default:
                                                    errorFunction(trMsg.status);
                                                    endOperation();
                                                break;
                                            }
                                        });
                                    }
                                };

                                setTimeout(checkifBusy, 500);
                            } else {
                                errorFunction(message.status);
                                endOperation();
                            }
                        break;
                        default:
                            errorFunction(message.status);
                            endOperation();
                        break;
                    }                    
                });
            } else {
                errorFunction(message.status);
                endOperation();
            }
        });
    };

    const abort = function(successFunction, errorFunction) {
        startOperation();
        sendAndWaitResponse([cashmaticCommands.CANCEL_TRANSACTION], function(message) {
            if(message.status === 'OK') {
                successFunction(message.status);
            } else {
                errorFunction(message.status);
            }
            endOperation();
        });
    };

    const changeHmacKey = function(newHmac, successFunction, errorFunction) {
        if(_.isString(newHmac) && newHmac.length === 24) {
            startOperation();

            let data = [cashmaticCommands.SET_TCP_KEY];

            for(let i = 0; i < newHmac.length; i++) {
                data.push(newHmac.charCodeAt(i));
            }

            sendAndWaitResponse(data, function(message) {
                if(message.status === 'OK') {
                    self.hmacKey = newHmac;
                    successFunction(message.status);
                } else {
                    errorFunction(message.status);
                }
                endOperation();
            });
        } else {
            errorFunction("INVALID_HMAC_KEY");
        }
    };

    /** public methods **/
    return {
        startTransaction: function(ipAddress, hmacKey, options) {
            if (!_.isObject(options)) {
                options = {};
            }

            Object.assign(self, {
                ipAddress: ipAddress,
                hmacKey: hmacKey || 'password_di_24_caratteri',
                options: options,
                msgIdx: 0x20, //Idx: 32 -> 255
                socket: null
            });
        },
        endTransaction: function() {
            if(self.socket) {
                closeSocket();
            }

            self = {
                pendingSocketCallbacks: [],
                currentOperations: 0
            };
        },
        performPayment: function (amount, successFunction, errorFunction) {
            successFunction = _.isFunction(successFunction) ? successFunction : _.noop;
            errorFunction = _.isFunction(errorFunction) ? errorFunction : _.noop;
            pay(amount, successFunction, errorFunction);
        },
        abortPayment: function(successFunction, errorFunction) {
            successFunction = _.isFunction(successFunction) ? successFunction : _.noop;
            errorFunction = _.isFunction(errorFunction) ? errorFunction : _.noop;
            abort(successFunction, errorFunction);
        },
        changeKey: function(newHmac, successFunction, errorFunction) {
            successFunction = _.isFunction(successFunction) ? successFunction : _.noop;
            errorFunction = _.isFunction(errorFunction) ? errorFunction : _.noop;
            changeHmacKey(newHmac, successFunction, errorFunction);
        }
    };
}