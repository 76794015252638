<!-- <button mat-icon-button color="primary" aria-label="Type Button" [ngSwitch]="params.data.movement_type || params.data.points >= 0 ? 'load' : 'unload'">
    <mat-icon *ngSwitchCase="'load'" svgIcon="file_download"></mat-icon>
    <mat-icon *ngSwitchCase="'unload'" svgIcon="file_upload"></mat-icon>
    <mat-icon *ngSwitchCase="'setup'" svgIcon="update"></mat-icon>
    <mat-icon *ngSwitchCase="'cancel'" svgIcon="highlight_off"></mat-icon>
    <mat-icon *ngSwitchCase="'bonus'" svgIcon="grade"></mat-icon>
</button> -->
<!-- {{params.data.movement_type || params.data.points >= 0}} -->
<div [ngSwitch]="params.data.movement_type === 'load' || params.data.points >= 0 ? 'load' : 'unload'">
    <span *ngSwitchCase="'load'" class="tw-font-bold">Carico</span>
    <span *ngSwitchCase="'unload'" class="tw-font-bold">Scarico</span>
</div>
