
import { CommonModule } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ChangeDetectionStrategy, Component, Injectable, inject } from "@angular/core";
import { SaleItemsTransactions, SaleTransactions, Sales, SalesItemsIngredients, SalesItemsVariations } from "tilby-models";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogProgressBarComponent, TilbyDialogTabsComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { lastValueFrom } from "rxjs";
import { MatDividerModule } from "@angular/material/divider";

export type HistoricalChangesDialogData = {title: string, transactions: SaleTransactions[], sale: Sales};
@Component({
    selector: "app-edit-history-dialog",
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent
    ],
    templateUrl: "./historical-changes-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoricalChangesDialogComponent {
    protected readonly data: HistoricalChangesDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);

    items: Record<string, string | number> = {};
    ingredients_items: Record<string, SalesItemsIngredients[]> = {};
    variations: Record<string, SalesItemsVariations[]> = {};
    notes: Record<string, string> = {};
    half_portion: Record<string, string> = {};
    item_half_portion: Record<string, boolean> = {};
    items_removed: Record<string, boolean> = {};
    items_quantities_to_remove: Record<string, number> = {};
    fromTransactionStart = this.data.transactions[1]?.sale_items_transactions!.length > 0 ? 2 : 3

    constructor() {
        if(!this.data.transactions) return;

        this.data.transactions  = this.data.transactions.sort((a,b) => TilbyDatePipe.toUtcDateMillis(a.created_at)-TilbyDatePipe.toUtcDateMillis(b.created_at))

        this.data.transactions.forEach((transaction) => {
            transaction.sale_items_transactions?.length && transaction.sale_items_transactions?.forEach((item) => {
                if (item.sale_item_uuid && item.sale_item_details.name && item.quantity_difference > 0) {
                    this.items[item.sale_item_uuid] = item.sale_item_details.name;
                }

                if (item.sale_item_uuid) {
                    item.sale_item_details.variations && (this.variations[item.sale_item_uuid] = item.sale_item_details.variations);
                    item.sale_item_details.notes && (this.notes[item.sale_item_uuid] = item.sale_item_details.notes)
                }

                if(item.quantity_difference) {
                    !this.items_quantities_to_remove[item.sale_item_uuid] && (this.items_quantities_to_remove[item.sale_item_uuid] = 0);
                    item.quantity_difference > 0 && (this.items_quantities_to_remove[item.sale_item_uuid] += item.quantity_difference); 
                } 

                if (typeof item.sale_item_details.ingredients !== "undefined" && item.sale_item_details.ingredients.length > 0) {
                    !this.ingredients_items[item.sale_item_uuid] && (this.ingredients_items[item.sale_item_uuid] = item.sale_item_details.ingredients);
                }
            });
        });
    }

    removeQuantity(uuid: string | number, quantityToRemove: number) {
        if(this.items_quantities_to_remove[uuid] !== 0 && quantityToRemove < 0) {   
            this.items_quantities_to_remove[uuid] += quantityToRemove;
            if(this.items_quantities_to_remove[uuid] === 0){
                this.items_removed[uuid] = true;
                return 'tw-line-through';
            } else {
                this.items_removed[uuid] = false;
                return '';
            }
        }
    }

    hasHalfPortion(item: SaleItemsTransactions) {
        if(item.sale_item_details.half_portion) {
            this.half_portion[item.sale_item_uuid] = item.sale_item_details.half_portion;
            this.item_half_portion[item.sale_item_uuid] = true;
            return "1/2";
        } else {
            return "";
        }
    }

    manageIngredients(ingredient: SalesItemsIngredients, uuid: string | number) {
        !this.ingredients_items[uuid] && (this.ingredients_items[uuid] = []);

        if(ingredient.quantity === 0) {
            this.ingredients_items[uuid] = this.ingredients_items[uuid].filter(ing => ing.id !== ingredient.id);
        } else if (ingredient.quantity && ingredient.quantity > 0){
            if(this.ingredients_items[uuid].findIndex(ing => ing.id === ingredient.id) === -1) {
                this.ingredients_items[uuid].push(ingredient);
            }
        }
    }

    protected confirm() {
        this.matDialogRef.close(this.data);
    }
}

@Injectable({
    providedIn: "root",
})
export class HistoricalChangesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    data!: HistoricalChangesDialogData;

    public openDialog(config?: NonNullableConfigData<HistoricalChangesDialogData>) {
        const data: HistoricalChangesDialogData = config!.data;
        const configEdited: NonNullableConfigData<HistoricalChangesDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({width: "800px"}),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(HistoricalChangesDialogComponent, configEdited).afterClosed()
        );
    }
}