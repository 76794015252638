<ng-container>
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"
        aria-label="Example icon-button with a menu" class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDetailsDialog($event)">
            <label translate="DIALOG.CASH_MOVEMENT.ACTION.DETAILS"></label>
        </button>
        <button mat-menu-item (click)="goToSale($event)">
            <label translate="DIALOG.CASH_MOVEMENT.ACTION.GO_TO_SALE"></label>
        </button>
    </mat-menu>
</ng-container>
