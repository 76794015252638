
<tilby-dialog-toolbar mat-dialog-title
title=""
[hideConfirm]="true"
/>
<tilby-dialog-content>
    <div class="tw-flex tw-justify-between">
        <div class="tw-pr-1">
            <button class="tw-h-16" mat-button mat-flat-button color="primary" (click)="action('LG')">
                <span>{{'DIALOG.SELECT_TYPE_DAILY_CLOSING.DAILY_READING' | translate}}</span>
            </button>
        </div>
        <div class="tw-pl-1">
            <button class="tw-h-16" mat-button mat-flat-button color="primary" (click)="action('CG')" [disabled]="!isClosingDailyPermitted">
                <span>{{'DIALOG.SELECT_TYPE_DAILY_CLOSING.DAILY_CLOSURE' | translate}}</span>
            </button>
        </div>
    </div>
</tilby-dialog-content>
