import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('orders').factory('parkedOrders', ["$mdDialog", "$rootScope", "$translate", "$mdMedia", "ActiveOrder", "ExternalOrdersManager", "entityManager", "checkManager", "orderUtils", "$timeout", function($mdDialog, $rootScope, $translate, $mdMedia, ActiveOrder, ExternalOrdersManager, entityManager, checkManager, orderUtils, $timeout) {

    parkedOrdersController.$inject = ["$scope", "deliveryChannels"];
    function parkedOrdersController($scope, deliveryChannels) {
        $scope.allOrders = [];
        $scope.myOrders = [];
        $scope.extOrders = [];
        $scope.takeAwayOrders = [];
        $scope.extOrdersMan = ExternalOrdersManager;
        $scope.extOrdersLabel = checkManager.getPreference("orders.external_orders_label") || $translate.instant('ORDERS.PARKED_ORDERS.EXTERNAL_ORDERS');
        $scope.deliveryCircuits = {};
        $scope.viewportMode = $mdMedia('min-width: 640px') ? 'landscape' : 'portrait';
        $scope.showFilter = false;
        $scope.filter = {};
        $scope.searchPlaceholder = $translate.instant('ORDERS.PARKED_ORDERS.SEARCH');

        _.forEach(deliveryChannels, function(channel) {
            var ch = _.clone(channel);

            if(!ch.image_url) {
                ch.image_url = "assets/images/channels/" + channel.id + ".png";
            }

            $scope.deliveryCircuits[channel.id] = ch;
        });

        $scope.orders = $scope.myOrders;

        $scope.selectTab = 'myOrders';

        if (ActiveOrder.currentOrder.id) {
            $scope.selectedOrder = ActiveOrder.currentOrder.id;
        }

        var assignOrdersView = function() {
            switch ($scope.selectTab) {
                case 'myOrders':
                    $scope.orders = $scope.myOrders;
                    break;
                case 'allOrders':
                    $scope.orders = $scope.allOrders;
                    break;
                case 'extOrders':
                    $scope.orders = $scope.extOrders;
                    break;
                case 'takeAwayOrders':
                    $scope.orders = $scope.takeAwayOrders;
                    break;
                case 'deliveryOrders':
                    $scope.orders = $scope.deliveryOrders;
                    break;
                default:
                    break;
            }
        };

        $scope.getOrderIcon = function(order) {
            return $scope.extOrdersMan.isExternal(order) ? "move_to_inbox" : "event_note";
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.selectOrder = function(order) {
            delete order._timeString;
            delete order._dateString;
            $mdDialog.hide(order);
        };

        $scope.goToTab = function(name) {
            $scope.selectTab = name;
            assignOrdersView();
            if ($scope.showFilter && $scope.filter.text && $scope.filter.text !== '') {
                $scope.searchParkedOrders();
            }
        };

        $scope.getOrderCaption = function(order) {
            var caption = orderUtils.getOrderCaption(order, $scope.deliveryCircuits);
            return caption ? ' - ' + caption : '';
        };

        var filterOrders = function(orders) {
            var todayOrders = [];
            var nextDaysOrders = [];

            _.forEach(orders, function(order) {
                switch(order.type) {
                    case 'take_away':
                    case 'delivery':
                        var now = moment();
                        var deliverAt = moment(order.deliver_at);

                        order._timeString = deliverAt.format('LT');

                        if(deliverAt.dayOfYear() !== now.dayOfYear() || deliverAt.year() !== now.year()) {
                            order._dateString = _.upperFirst(deliverAt.fromNow()).replace('In un giorno', 'Domani');
                        }
                    break;
                    default:
                    break;
                }

                if(order._dateString) {
                    nextDaysOrders.push(order);
                } else {
                    todayOrders.push(order);
                }
            });

            todayOrders = _.orderBy(todayOrders, 'order_number', 'desc');
            nextDaysOrders = _.orderBy(nextDaysOrders, 'deliver_at');

            $scope.allOrders = _.concat(todayOrders, nextDaysOrders);
            $scope.extOrders = _.filter($scope.allOrders, ExternalOrdersManager.getExternalOrdersFilter());
            $scope.myOrders = _.filter($scope.allOrders, { operator_id: $rootScope.userActiveSession.id });
            $scope.takeAwayOrders = _($scope.allOrders).filter({ type: 'take_away'}).orderBy('deliver_at').value();
            $scope.deliveryOrders = _($scope.allOrders).filter({ type: 'delivery'}).orderBy('deliver_at').value();

            assignOrdersView();
        };

        var fetchOrders = function() {
            return entityManager.orders.fetchCollectionOffline({ status: 'open' }).then(filterOrders);
        };

        $scope.toggleShowFilter = () => {
            $scope.filter.text = '';
            $scope.showFilter = !$scope.showFilter;
            if($scope.showFilter) {
                $timeout(function() {
                    angular.element('#filterText').trigger('focus');
                });
            } else {
                assignOrdersView();
            }
        };

        $scope.searchParkedOrders = function() {
            let source = [];
            switch ($scope.selectTab) {
                case 'myOrders':
                    source = _.cloneDeep($scope.myOrders);
                    break;
                case 'allOrders':
                    source = _.cloneDeep($scope.allOrders);
                    break;
                case 'extOrders':
                    source = _.cloneDeep($scope.extOrders);
                    break;
                case 'takeAwayOrders':
                    source = _.cloneDeep($scope.takeAwayOrders);
                    break;
                case 'deliveryOrders':
                    source = _.cloneDeep($scope.deliveryOrders);
                    break;
                default:
                    break;
            }
            const filteredOrders = _.filter(source, function(o) {
                const text = $scope.filter.text;
                const name = o.name ? o.name.includes(text) : false;
                const external_id = o.external_id ? o.external_id.includes(text) : false;
                const channel = o.channel ? o.channel.includes(text.toLowerCase()) : false;
                let first_name = false;
                let last_name = false;
                let company_name = false;
                let shipping_street = false;
                if (o.order_customer) {
                    first_name = o.order_customer.first_name ? o.order_customer.first_name.includes(text) : false;
                    last_name = o.order_customer.last_name ? o.order_customer.last_name.includes(text) : false;
                    company_name = o.order_customer.company_name ? o.order_customer.company_name.includes(text) : false;
                    shipping_street = o.order_customer.shipping_street ? o.order_customer.shipping_street.includes(text) : false;
                }
                return name || external_id || channel || first_name || last_name || company_name || shipping_street;
            });
            if (filteredOrders && filteredOrders.length > 0) {
                $scope.orders = filteredOrders;
            } else {
                $scope.orders = [];
            }
        };

        $scope.$on("storage-updated:orders", fetchOrders);

        fetchOrders();

        $scope.$watch(function() { return $mdMedia('min-width: 640px'); }, function(full) {
            $scope.viewportMode = full ? 'landscape' : 'portrait';
        });
    }

    var parkedOrders = {
        show: function() {
            return $mdDialog.show({
                controller: parkedOrdersController,
                template: require('./parked-orders.html'),
                resolve: {
                    deliveryChannels: function() {
                        return entityManager.channels.fetchCollectionOffline();
                    }
                }
            });
        }
    };

    return parkedOrders;
}]);
