<ng-container *ngIf="{isOffline: isOffline$ | async} as data">
    <section *ngIf="menuIcon">
        <button
            [color]="'primary'"
            class="frame-button"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="menuClick($event)"
        >
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
    </section>
    <section class="tw-flex tw-items-center" *ngIf="!isMobile">
        <img [src]="themeModeService.isDarkTheme?logoDark:logoLight" alt="logo"
             class="sm:tw-mx-0 tw-h-12 tw-pl-0"/>
        <span class="frame-sidenav color tw-text-[1.8em] tw-ml-4">{{(moduleTitle || '')|translate}}</span>
    </section>
    <section *ngIf="isMobile">
        <mat-icon *ngIf="data.isOffline" class="tw-pr-3 tw-cursor-pointer" (click)="openOfflineDialog()">cloud_off</mat-icon>
    </section>
</ng-container>
