import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('cashregister').factory('satispayPayments', ["$mdDialog", "$timeout", "$translate", "satispayApi", "util", function($mdDialog, $timeout, $translate, satispayApi, util) {
    satispayPaymentsController.$inject = ["$scope", "options"];
    var dialogHasShown = false;

    function satispayPaymentsController($scope, options) {
        $scope.payments = [];
        $scope.paymentsByUuid = {};
        $scope.needsSetup = false;
        $scope.activationCode = '';
        $scope.operationInProgress = false;
        $scope.isOffline = false;

        if(options.amount) {
            $scope.amountToPay = util.round(options.amount / 100);
        }

        $scope.showLoader = function() {
            return _.isEmpty($scope.payments) && dialogHasShown && !$scope.needsSetup;
        };

        $scope.$on("connection:changed", function(event, data) {
            $scope.isOffline = data.status === 'offline';
        });

        $scope.pairShop = function() {
            if(!_.isEmpty($scope.activationCode)) {
                $scope.operationInProgress = true;
                $scope.message = $translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.ENABLING');
                satispayApi.setupShop($scope.activationCode).then(function(result) {
                    $scope.message = $translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.ENABLE_SUCCESSFUL');
                    $scope.needsSetup = false;
                    $timeout(function() {
                        $scope.deleteMessage();
                        fetchPayments();
                    }, 1500);
                }, function(error) {
                    $('#SatispayCodeInput').trigger('focus');
                    switch(_.get(error, 'status')) {
                        case -1:
                            $scope.message = '';
                            break;
                        case 404: case 403:
                            $scope.message = $translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.INVALID_CODE');
                            break;
                        default:
                            $scope.message = $translate.instant('CASHREGISTER.SATISPAY_PAYMENTS.ENABLE_FAILED');
                            break;
                    }
                }).finally(function() {
                    $scope.operationInProgress = false;
                });
            }
        };

        $scope.deleteMessage = function() {
            $scope.message = '';
        };

        var fetchPayments = function() {
            if(!$scope.$$destroyed) {
                satispayApi.getPendingPayments().then(function(response) {
                    if (_.get(response, 'data')) {
                        _.forEach(response.data, function(p) {
                            var payment = _.pick(p, ['id', 'amount_unit', 'expired', 'insert_date']);
                            payment.original_data = p;

                            payment.customer_amount = util.round(payment.amount_unit / 100);

                            if(payment.expired || (options.amount && payment.amount_unit !== options.amount)) {
                                payment.disabled = true;
                            }

                            if(p.sender) {
                                payment.customer_name = p.sender.name;
                                const profilePictures = p.sender.profile_pictures?.data;

                                if(Array.isArray(profilePictures) && profilePictures.length) {
                                    payment.customer_image = (profilePictures.find((pic) => pic.is_original) || profilePictures[0]).url;
                                }
                            }

                            $scope.paymentsByUuid[p.id] = payment;
                        });

                        var pDict = _.keyBy(response.data, 'id');

                        _.forIn($scope.paymentsByUuid, function(p, pUuid) {
                            if(!pDict[pUuid]) { //this payment is no longer in the current list, so mark it as removed
                                _.assign(p, {
                                    disabled: true,
                                    removed: true
                                });
                            }
                        });

                        $scope.payments = _($scope.paymentsByUuid).sortBy(function(p) {
                            return (p.expired || (options.amount && p.amount_unit !== options.amount)) ? Infinity : _.toInteger(new Date(p.insert_date));
                        }).value();
                    }
                    $timeout(fetchPayments, 2000);
                }, function(error) {
                    if(_.isObject(error)) {
                        switch(error.status) {
                            case -1:
                                $timeout(fetchPayments, 1000);
                            break;
                            case 403:
                                $scope.needsSetup = true;
                            break;
                            default:
                                $mdDialog.cancel('UNKNOWN_ERROR');
                            break;
                        }
                    } else {
                        $mdDialog.cancel('UNKNOWN_ERROR');
                    }
                });
            }
        };

        $scope.removePayment = function(payment) {
            $scope.operationInProgress = true;
            satispayApi.cancelPayment(payment).then(function() {
                $scope.operationInProgress = false;
                delete $scope.paymentsByUuid[payment.id];
                _.pull($scope.payments, payment);
            });
        };

        if (!_.isObject(options)) {
            options = {};
        }

        $scope.cancel = function() {
            $mdDialog.cancel('CANCELED');
        };

        $scope.selectPayment = function(payment) {
            if (!payment.disabled) {
                $mdDialog.hide(payment);
            }
        };

        $timeout(function() {
            fetchPayments();
        });
    }

    var satispayPaymentsDialog = {
        show: function(options) {
            return $mdDialog.show({
                controller: satispayPaymentsController,
                template: require('./satispay-payments.html'),
                locals: {
                    options: options
                },
                onShowing: function() { dialogHasShown = false; },
                onComplete: function() { dialogHasShown = true; },
                onRemoving: function() { dialogHasShown = false; }
            });
        }
    };

    return satispayPaymentsDialog;
}]);
