
import { Injectable } from "@angular/core";
import {
    GiftcardsTypesDefault,
    GiftcardsTypesFields,
    GiftcardsTypesFormGroup
} from "../components";
import { Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup, CustomFormGroupProps
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

@Injectable({
    providedIn: 'root'
})
export class GiftcardTypeFormService {
    giftcardTypeForm!:CustomFormGroup<GiftcardsTypesFormGroup>;

    constructor(
        private translate: TranslateService,
    ) {
    }

    createForm(giftcardType?: GiftcardsTypesFields, hiddenFields?:Array<keyof GiftcardsTypesFields>): CustomFormGroup<GiftcardsTypesFormGroup> {
        if(!giftcardType) {
            giftcardType = new GiftcardsTypesDefault();
        }

        this.giftcardTypeForm = new CustomFormGroup<GiftcardsTypesFormGroup>({
            general: new CustomFormGroup({
                name: new CustomFormControl(giftcardType.name || '', { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.TYPE_NAME'),
                    inputType: 'text',
                    customStyle: { width: '100%', margin: '0 0 10px 0' }
                }),
                description: new CustomFormControl(giftcardType.description || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.TYPE_DESCRIPTION'),
                    inputType: 'textarea',
                    customStyle: { width: '100%', margin: '0 0 10px 0' }
                }),
                expire_after: new CustomFormControl(giftcardType.expire_after || 365, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.EXPIRE_AFTER'),
                    inputType: 'number'
                }),
                is_divisible: new CustomFormControl(giftcardType.is_divisible || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.IS_DIVISIBLE'),
                    inputType: 'slideToggle'
                }),
                origin_environment_only: new CustomFormControl(giftcardType.origin_environment_only || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.ORIGIN_ENVIRONMENT_ONLY'),
                    inputType: 'slideToggle'
                })
            }, {}, {...new CustomFormGroupProps()}),
            print_options: new CustomFormGroup({
                print_divisible:new CustomFormControl(giftcardType.print_options?.print_divisible || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_DIVISIBLE'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                }),
                print_logo:new CustomFormControl(giftcardType.print_options?.print_logo || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_LOGO'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                }),
                print_timestamp:new CustomFormControl(giftcardType.print_options?.print_timestamp || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_TIMESTAMP'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                }),
                print_validity: new CustomFormControl(giftcardType.print_options?.print_validity || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_VALIDITY'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                }),
                print_value: new CustomFormControl(giftcardType.print_options?.print_value || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_VALUE'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                }),
                print_environment: new CustomFormControl(giftcardType.print_options?.print_environment || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_ENVIRONMENT'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                }),
                print_giftcard_uuid: new CustomFormControl(giftcardType.print_options?.print_giftcard_uuid || false, {}, {
                    ...new CustomFormControlProps(),
                    label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.PRINT_GIFTCARD_UUID'),
                    inputType:'slideToggle',
                    customStyle: { width: '100%', margin: '10px' }
                })
            }, {}, {
                ...new CustomFormGroupProps(),
                label: this.translate.instant('GIFTCARD.TYPES_PANEL.FORM.PRINT_OPTIONS.LABEL'),
                classLabel: 'tw-text-2xl tw-m-4 tw-uppercase'
            })
        });

        return this.giftcardTypeForm;
    }

    updateForm(giftcardType: GiftcardsTypesFields): void {
        this.giftcardTypeForm?.controls.general.reset(giftcardType);
        this.giftcardTypeForm?.controls.print_options.reset(giftcardType.print_options);
    }

}
