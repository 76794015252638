import {Component, Inject, Injectable, inject} from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { DataSettingsDailyGoalDialog, SettingsDailyGoalForm } from "./settings-daily-goal.model";
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { lastValueFrom } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { MatToolbarModule } from "@angular/material/toolbar";

@Component({
    selector: 'app-settings-daily-goal-dialog-dialog',
    templateUrl: './settings-daily-goal-dialog.component.html',
    styleUrls: ['./settings-daily-goal-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, TilbyDialogContentComponent, FormsModule, ReactiveFormsModule, MatToolbarModule, MatDialogModule, MatFormFieldModule, MatButtonModule, MatIconModule, MatInputModule, TranslateModule]
})
export class SettingsDailyGoalDialogComponent {

    settingsDailyGoalForm?: FormGroup<SettingsDailyGoalForm>;

    constructor(
        public dialogRef: MatDialogRef<SettingsDailyGoalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataSettingsDailyGoalDialog,
    ) {}

    ngOnInit() {
        this.createForm();
    }

    createForm(){
        this.settingsDailyGoalForm = new FormGroup<SettingsDailyGoalForm>({
            amount: new FormControl(this.data.settings.amount ,[Validators.required]),
        });
    }

    submit(settingsDailyGoalForm?: FormGroup<SettingsDailyGoalForm>) {
        const {amount} = settingsDailyGoalForm?.value || {};
        const settingsDailyGoalData = amount || 0;
        this.dialogRef.close({amount: settingsDailyGoalData});
    }
}

@Injectable({
    providedIn: 'root'
})
export class SettingsDailyGoalDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config:NonNullableConfigData<DataSettingsDailyGoalDialog>){
        const configEdited:NonNullableConfigData<DataSettingsDailyGoalDialog>={...this.switchMobileDesktopDimensions(), ...config};
        return lastValueFrom(this.dialogRef.open(SettingsDailyGoalDialogComponent,configEdited).afterClosed()).then(res=>(typeof res == 'object')&&res);
    }
}