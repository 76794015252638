import { Form } from 'src/app/models';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { BookingShifts } from 'tilby-models';

interface BookingShiftsFieldsFe {
    isNew?:boolean;
}

export type BookingShiftsFields = BookingShifts & BookingShiftsFieldsFe;

export class BookingShiftsDefault implements BookingShiftsFields {
    start_time: string;
    end_time: string;

    constructor(public name: string) {
        this.start_time = '00:00:00';
        this.end_time = '23:59:00';
    }
}

export type BookingShiftsFieldsNullable = { [prop in string & keyof BookingShiftsFields]:BookingShiftsFields[prop]|null }
export type BookingShiftsForm = Partial<Form<BookingShiftsFieldsNullable>>;

export type BookingShiftsFormGroup = {
    generalForm: CustomFormGroup<any>
}

export interface PriceList {
    index: number;
    fieldName: string;
    value: string;
    hidden: boolean;
}
