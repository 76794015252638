import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').factory('addNewItemToOrder', addNewItemToOrder);

addNewItemToOrder.$inject = ["$mdDialog", "$translate", "ActiveOrder", "checkManager", "connection", "entityManager", "util"];

function addNewItemToOrder($mdDialog, $translate, ActiveOrder, checkManager, connection, entityManager, util) {
    addNewItemToOrderController.$inject = ["$scope", "$mdDialog", "categories", "departments"];

    function addNewItemToOrderController($scope, $mdDialog, categories, departments) {
        Object.assign($scope, {
            addToInventory: false,
            canAddToItems: () => (connection.isOnline() && $scope.isItemsEnabled),
            cancel: $mdDialog.cancel,
            categories: categories,
            deleteMessage: () => { $scope.message = null; },
            departments: departments,
            integerval: /^\d*$/,
            isItemsEnabled: checkManager.isModuleEnabled("items"),
            message: null,
            operationInProgress: false,
        });

        $scope.answer = async () => {
            if($scope.operationInProgress) {
                return;
            }

            let dataToSend = _.cloneDeep($scope.data);

            Object.assign(dataToSend, {
                category_id: dataToSend.category?.id || null,
                department_id: dataToSend.department.id,
                price1: dataToSend.price
            });

            let priceList = await ActiveOrder.getOrderPricelist();

            if(!priceList) {
                priceList = util.getCurrentPriceList();
            }

            if(priceList !== 1) {
                dataToSend[`price${priceList}`] = dataToSend.price;
            }

            delete dataToSend.price;

            let result;

            if($scope.addToInventory && connection.isOnline()) {
                try {
                    $scope.operationInProgress = true;
                    $scope.message = $translate.instant("ORDERS.ADD_NEW_ITEM_TO_ORDER.SAVING_TO_INVENTORY");
                    result = await entityManager.items.postOneOnline(dataToSend);
                } catch(err) {
                    $scope.message = $translate.instant("ORDERS.ADD_NEW_ITEM_TO_ORDER.SAVING_FAILED");
                } finally {
                    $scope.operationInProgress = false;
                }
            } else {
                result = dataToSend;
            }

            if(result) {
                $mdDialog.hide(result);
            }
        };
    }

    const addNewItemToOrder = {
        show: function() {
            return $mdDialog.show({
                controller: addNewItemToOrderController,
                template: require('./add-new-item-to-order.html'),
                resolve: {
                    categories: () => entityManager.categories.fetchCollectionOffline(),
                    departments: () => entityManager.departments.fetchCollectionOffline()
                }
            });
        }
    };

    return addNewItemToOrder;
}