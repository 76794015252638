import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {ToolbarButtons} from "./toolbar-events.model";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";

@Injectable({
    providedIn: 'root'
})
export class ToolbarEventsContextService {
    label='';
    showToolbarContext$ = new BehaviorSubject<boolean>(false);
    buttons$ = new BehaviorSubject<ToolbarButtons>({panelButtons: [], barButtons: []});
    backButton$= new Subject<GeneralInputButton>();

    constructor() {
    }
}
