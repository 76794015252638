import * as angular from 'angular';
import * as _ from 'lodash';
import { v4 as generateUuid } from 'uuid';

angular.module('cashregister').factory('saleUtils', saleUtils);

saleUtils.$inject = ["$translate", "$injector", "$rootScope", "$state", "progressivesManager", "checkManager", "entityManager", "OperatorManager", "FiscalProviders", "util", "connection", "saleItemManager", "addVariationsDialog", "newSaleUtils", "saleItemManagerDialog"];

function saleUtils($translate, $injector, $rootScope, $state, progressivesManager, checkManager, entityManager, OperatorManager, FiscalProviders, util, connection, saleItemManager, addVariationsDialog, newSaleUtils, saleItemManagerDialog) {
    var quantityOverridesMap = null;

    var initQuantityOverrides = function() {
        //Load default quantity ovverrides
        var quantityOverrides = checkManager.getPreference("cashregister.item_quantity_overrides");
        quantityOverridesMap = {};

        if (!_.isEmpty(quantityOverrides)) {
            //Generate items code map
            _.each(quantityOverrides.split('\n'), function(row) {
                var parsedRow = _.split(row, '|');
                if (parsedRow.length === 2) {
                    quantityOverridesMap[_.toInteger(parsedRow[0])] = _.toInteger(parsedRow[1]);
                }
            });
        }
    };

    const isKioskMode = () => _.startsWith($state.current.name, 'app.kiosk');

    const saleUtils = {
        getSaleTemplate: async (options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            let progressive = await progressivesManager.getProgressives();
            let isOnline = connection.isOnline();
            let saleNumber = (isOnline ? progressive.sale_number : progressive.sale_number_offline) + 1;

            if (!_.isInteger(saleNumber)) {
                saleNumber = 1;
            }

            let saleName = $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + saleNumber;

            if(!isOnline) {
                let nameString;

                if ($rootScope.userActiveSession.first_name && $rootScope.userActiveSession.last_name) {
                    nameString = $rootScope.userActiveSession.first_name[0] + $rootScope.userActiveSession.last_name[0];
                } else {
                    nameString = $rootScope.userActiveSession.username.substr(0, 3);
                }

                saleName += $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.OFFLINE') + nameString;

                if(!options.skipUpdateProgressive) {
                    progressivesManager.updateProgressives({ sale_number_offline: saleNumber });
                }

                saleNumber = undefined;
            }

            if(isKioskMode()) {
                const kioskSaleData = $injector.get('kioskUtils').getKioskSaleData();
                saleName = kioskSaleData.tableNumber ? _.chain($translate.instant('KIOSK.CATALOG.TABLE_SALE_NAME')).concat(kioskSaleData.tableNumber).join(' ').truncate({ length: 30, omission: '' }).value() : saleName;
            }

            const opData = OperatorManager.getSellerData();

            let sale = {
                name: saleName,
                status: 'open',
                channel: isKioskMode() ? 'kiosk' : 'pos',
                createdby_id: opData.id,
                seller_id: opData.id,
                seller_name: opData.full_name,
                currency: $rootScope.currentCurrency.code,
                sale_number: saleNumber,
                order_type: "normal",
                open_at: new Date().toISOString(),
                payments: [],
                price_changes: [],
                sale_documents: [],
                sale_items: [],
                uuid: generateUuid()
            };

            if(!options.skipFiscalProvider) {
                try {
                    let printerDocumentData;

                    try {
                        printerDocumentData = await $injector.get('documentPrintersManager').getPrinterDocumentData('default', 'default');
                    } catch(err) {
                        //Nothing to handle
                    } finally {
                        const fiscalProvider = FiscalProviders.getFiscalProvider(printerDocumentData?.printer?.fiscal_provider);

                        if(_.isFunction(fiscalProvider?.openFiscalDocument)) {
                            try {
                                let result = await fiscalProvider.openFiscalDocument();

                                sale.sale_documents.push(Object.assign(result, {
                                    printer_id: printerDocumentData.printer.id,
                                    printer_name: printerDocumentData.printer.name
                                }));
                            } catch(error) {
                                if(fiscalProvider.getProviderError) {
                                    throw fiscalProvider.getProviderError(error);
                                }

                                throw error;
                            }
                        }
                    }
                } catch(err) {
                    let error = 'CASHREGISTER.ACTIVE_SALE.FISCAL_PROVIDER_DOCUMENT_OPEN_FAILED';
                    $rootScope.$broadcast('activeSale:sale-creation-failed', error);

                    throw error;
                }
            }

            return sale;
        },
        getSaleItemTemplate: async (sourceItem, priceList, combinationId, quantity, barcode, overrides) => {
            if(_.isNull(quantityOverridesMap)) {
                initQuantityOverrides();
            }

            const opData = OperatorManager.getSellerData();
            const targetPrice = `price${priceList}`;
            const targetDepartment = _.toInteger(priceList) === 1 ? `department`: `department${priceList}`;
            const department = sourceItem[targetDepartment] || sourceItem['department'];
            const now = new Date().toISOString();

            const saleItem = {
                added_at: now,
                barcode: barcode || sourceItem.barcodes?.[0]?.barcode,
                category_id: sourceItem.category?.id || null,
                category_name: sourceItem.category?.name || null,
                cost: sourceItem.cost,
                department: department,
                department_id: department?.id || null,
                department_name: department?.name || null,
                half_portion: false,
                ingredients: [],
                is_group_item: sourceItem.is_group_item || sourceItem.split_group_components,
                item_id: sourceItem.id,
                lastupdate_at: now,
                lastupdate_by: opData.id,
                name: sourceItem.name,
                not_discountable: sourceItem.not_discountable,
                price: sourceItem[targetPrice],
                price_changes: [],
                quantity: quantity || quantityOverridesMap[sourceItem.category_id] || 1,
                request_weighing: sourceItem.request_weighing && quantity == null,
                seller_id: opData.id,
                seller_name: opData.full_name,
                sku: sourceItem.sku,
                type: "sale",
                uuid: generateUuid(),
                variations: [],
                vat_perc: department?.vat?.value ?? sourceItem.vat_perc,
            };

            if(typeof overrides === 'object') {
                Object.assign(saleItem, overrides);
            }

            let combination = combinationId ? _.find(sourceItem.combinations, { id: combinationId }) : null;

            const variations = [...(structuredClone(sourceItem.variations || []))];

            if(sourceItem.category_id) {
                const category = await entityManager.categories.fetchOneOffline(sourceItem.category_id);
                variations.push(...(structuredClone(category?.variations || [])));
            }

            if(combination) {
                Object.assign(saleItem, {
                    barcode: barcode || combination.barcodes?.[0]?.barcode || saleItem.barcode,
                    combination_id: combination.id,
                    combination_sku: combination.sku,
                    price: combination[targetPrice] ?? saleItem.price,
                    notes: combination ? _.map(combination.combination_values, 'variation_value').join(', ') : null
                });
            } else if (variations.length) {
                if(isKioskMode()) {
                    const result = await addVariationsDialog.show({
                        name: sourceItem.name,
                        variations: variations
                    });

                    saleItem.variations = result?.variations || [];
                } else {
                    //Check if the saleItem has a required variation and show saleItemManager if that's the case
                    const hasRequiredVariation = checkManager.getPreference('cashregister.disable_required_variations') !== true && variations.some((v) => v.required);

                    if(hasRequiredVariation) {

                        let result;

                        if (checkManager.isModuleAngular('tables_and_cashregister')) {
                            result = await saleItemManagerDialog.openDialog({rowItem: saleItem});

                            if (!result) {
                                return;
                            }
                        } else {
                            result = await saleItemManager.show(saleItem, { disableUnbundle: true });
                        }

                        if(result) {
                            result.rowItem.request_weighing = saleItem.request_weighing;
                            angular.copy(result.rowItem, saleItem);
                        }
                    } else {
                        const defaultVariations = [];

                        for(const variation of variations) {
                            variation.variation_values = variation.variation_values || [];

                            const found = variation.variation_values.find((v) => v.default_value);

                            if (found) {
                                defaultVariations.push({
                                    name: variation.name,
                                    value: found.value,
                                    price_difference: found.price_difference,
                                    linked_item_uuid: found.linked_item_uuid,
                                    variation_id: variation.id,
                                    variation_value_id: found.id
                                });
                            }
                        }

                        saleItem.variations = defaultVariations;
                    }
                }
            }

            return saleItem;
        },
        getDynamicSaleItemTemplate: function(department, price) {
            const time = new Date();
            const opData = OperatorManager.getSellerData();

            return {
                added_at: time.toISOString(),
                department: department,
                department_id: department.id,
                department_name: department.name,
                half_portion: false,
                ingredients: [],
                name: department.name,
                not_discountable: department.not_discountable,
                price: price,
                price_changes: [],
                quantity: 1,
                seller_id: opData.id,
                seller_name: opData.full_name,
                type: 'sale',
                uuid: generateUuid(),
                variations: [],
                vat_perc: department.vat.value
            };
        },
        calculateSalePrices: (targetSale) => newSaleUtils.calculateSalePrices(targetSale),
        calculateSaleItemsCosts: async (targetSale) => {
            let itemsUuids = _(targetSale.sale_items).map('variations').map((variation) => _.map(variation, 'linked_item_uuid')).flatten().reject(_.isNil).uniq().value();

            if(!_.isEmpty(itemsUuids)) {
                let items = await entityManager.items.fetchCollectionOffline({ uuid_in: itemsUuids });
                let itemsByUuid = _.keyBy(items, 'uuid');

                _.forEach(targetSale.sale_items, (saleItem) => {
                    let saleItemVariationCost = _(saleItem.variations)
                        .filter('linked_item_uuid')
                        .sumBy((variation) => _.chain(itemsByUuid).get([variation.linked_item_uuid, 'cost']).toFinite().value());

                    if(!_.isNil(saleItem.cost) || saleItemVariationCost) {
                        saleItem.cost = util.round(_.toFinite(saleItem.cost) + saleItemVariationCost);
                    }
                });
            }
        },
        getCleanSaleItem: (saleItem, keepPriceChanges) => {
            const cleanSaleItem = _.omit(saleItem, ['id', 'order_id', 'sale_id', 'created_at', 'updated_at', 'deleted_at']);

            //Keep price changes if asked, otherwise remove item price changes and use the final price as the base price
            if(keepPriceChanges) {
                Object.assign(cleanSaleItem, {
                    ingredients: saleUtils.getCleanSubEntity(cleanSaleItem.ingredients),
                    price_changes: saleUtils.getCleanSubEntity(cleanSaleItem.price_changes),
                    variations: saleUtils.getCleanSubEntity(cleanSaleItem.variations),
                });
            } else {
                Object.assign(cleanSaleItem, {
                    half_portion: null,
                    ingredients: [],
                    price_changes: [],
                    price: cleanSaleItem.final_price,
                    variations: [],
                });
            }

            return cleanSaleItem;
        },
        getCleanSubEntity: (subEntity) => _.map(subEntity, (row) => _.omit(row, ['id', 'order_id', 'order_item_id', 'sale_id', 'sale_item_id', 'created_at', 'updated_at', 'deleted_at']))
    };

    return saleUtils;
}
