import {Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'status-component',
  template: `<div class="tw-pt-4 tw-pb-4">
    <span *ngIf="rowSelected > 0"translate>{{rowSelectedLabel}} : </span>
    <span *ngIf="rowSelected > 0" class="tw-mr-8">{{rowSelected | tilbyNumber}}</span>
    <span>{{rowCountLabel}} : </span>
    <span>{{rowCount | tilbyNumber}}</span>
  </div>`,
})
export class GridStatusBarComponent implements IStatusPanelAngularComp {

    private params!: IStatusPanelParams;
    protected rowSelectedLabel: string = '';
    protected rowSelected: number = 0;
    protected rowCountLabel: string = '';
    protected rowCount: number = 0;

    constructor(protected translateService: TranslateService) {
        this.rowSelectedLabel = this.translateService.instant('GRID_PANEL_STATUS.SELECTED_ROWS');
        this.rowCountLabel = this.translateService.instant('GRID_PANEL_STATUS.TOTAL_ROWS');
    }

    agInit(params: IStatusPanelParams): void {
        this.params = params;
        params.api.addEventListener('firstDataRendered', this.updateRowCount.bind(this));
        params.api.addEventListener('modelUpdated', this.updateRowCount.bind(this));
        params.api.addEventListener('selectionChanged', this.updateRowSelected.bind(this));
    }

    updateRowSelected() {
        this.rowSelected = this.params.api.getSelectedRows().length;
    }

    updateRowCount() {
        this.rowCount = this.params.api.getModel().getRowCount();
    }
}
