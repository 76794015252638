import * as angular from 'angular';
import * as _ from 'lodash';

class NumberInputDialogController {
    constructor(
        private $mdDialog: any,
        private $scope: any,
        message: string,
        options: any
    ) {
        this.$mdDialog = $mdDialog;
        this.$scope = $scope;

        Object.assign(this.$scope, {
            inputNumber: '',
            minDigits: options?.minDigits || 1,
            maxDigits: options?.maxDigits || 4,
            message: message,
            confirmText: options?.confirmText || 'OK',
            confirm: this.confirm
        })
    }

    public confirm = () => {
        if(this.$scope.inputNumber.length >= this.$scope.minDigits) {
            this.$mdDialog.hide(this.$scope.inputNumber);
        }
    }
}

NumberInputDialogController.$inject = ["$mdDialog", "$scope", "message", "options"];

class NumberInputDialog {
    constructor(
        private $mdDialog: any
    ) {
    }

    public show(message:string, options: any): Promise<string> {
        return this.$mdDialog.show({
            controller: NumberInputDialogController,
            template: require('./number-input-dialog.html'),
            locals: {
                message: message,
                options: options
            },
            multiple: true,
            clickOutsideToClose: true
        });
    }
}

NumberInputDialog.$inject = ["$mdDialog"];

angular.module('application').service('numberInputDialog', NumberInputDialog);
