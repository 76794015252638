import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').controller('PaymentsCtrl', PaymentsCtrl);

PaymentsCtrl.$inject = ["$scope", "$rootScope", "$filter", "$mdMedia", "$translate", "ActiveSale", "paymentMethods", "cardCircuits", "iconListDialog", "ticketsCollection", "promptDialog", "entityManager", "restManager", "DigitalPaymentsManager", "alertDialog", "barcodeManager", "confirmDialog", "deliveryChannels"];

function PaymentsCtrl($scope, $rootScope, $filter, $mdMedia, $translate, ActiveSale, paymentMethods, cardCircuits, iconListDialog, ticketsCollection, promptDialog, entityManager, restManager, DigitalPaymentsManager, alertDialog, barcodeManager, confirmDialog, deliveryChannels) {
    $scope.sale = ActiveSale;

    $scope.topbar_context = {
        paymentsviewmode: true,
        back_label: $translate.instant('CASHREGISTER.PAYMENTS.TITLE')
    };

    $scope.paymentMethods = _.reject(paymentMethods, { hidden: true });
    $scope.keypadDynamicValue = $filter('sclCurrency')(0, '');
    $scope.modeview = 'payments';
    $scope.topbar_context.modeview = $scope.modeview;

    //Setup tickets
    var ticketPaymentMethod = _.find($scope.paymentMethods, { payment_method_type_id: 6 });

    if(ticketPaymentMethod) {
        if(!_.isArray(ticketsCollection)) {
            ticketsCollection = [];
        }

        _.forEach(ticketsCollection, function(ticket) {
            ticket.icon = ticket.image_url;
        });

        $scope.ticketsCollection = ticketsCollection;
        $scope.selectedTicketCircuit = _.head($scope.ticketsCollection) || {};
    }

    //Check Prepaid Card status
    var prepaidCardMethod = _.find($scope.paymentMethods, { payment_method_type_id: 16 });

    if(prepaidCardMethod) {
        ActiveSale.checkPrepaidPaymentStatus(prepaidCardMethod).then(function(result) {
            if(result.customer_uuid) {
                $scope.$on('prepaid-updated', function(event, prepaidInfo) {
                    if(prepaidInfo.customer_uuid === result.customer_uuid) {
                        _.set(prepaidCardMethod, '$info', $translate.instant('CASHREGISTER.PAYMENTS.CREDIT', { value: $filter('sclCurrency')(prepaidInfo.credit + prepaidInfo.ticket_credit) }));
                    }
                });
            }
        });
    }

    const isCashDrawer = (paymentMethod) => [19, 21, 32, 38, 40].includes(paymentMethod.payment_method_type_id);
    const isPMS = (paymentMethod) => [22, 23, 28, 29, 36, 39].includes(paymentMethod.payment_method_type_id);

    $scope.getToPay = () => $scope.sale.getToPay();

    $scope.showAddToPayButton = () => $mdMedia('gt-sm');

    $scope.getPaid = () => $scope.sale.getPaid();

    $scope.getChange = () => $scope.sale.getChange();

    $scope.addCashToPayToSale = function() {
        $scope.keypadDynamicValue = $filter('sclCurrency')($scope.getToPay(), '');
    };

    $scope.selectTicketCircuit = function(ticket) {
        $scope.selectedTicketCircuit = ticket;
    };

    $scope.isTicketSelected = (ticket) => ($scope.selectedTicketCircuit === ticket);

    $scope.selectTicketValue = function(ticket_value) {
        var paymentInputObject = {
            ticket_circuit: $scope.selectedTicketCircuit.circuit,
            ticket_id: $scope.selectedTicketCircuit.id,
            ticket_name: $scope.selectedTicketCircuit.name
        };

        $scope.addPaymentToSale(ticketPaymentMethod, { amount: ticket_value.value, paymentInputObject: paymentInputObject });
    };

    $scope.addNewTicket = function() {
        promptDialog.show({ type: 'number', title: $translate.instant('CASHREGISTER.ADD_NEW_TICKET.TITLE'), label: $translate.instant('CASHREGISTER.ADD_NEW_TICKET.AMOUNT') }).then(function(newTicketPrice) {
            var ticket = _.cloneDeep($scope.selectedTicketCircuit);

            var ticketValue = {
                value: newTicketPrice
            };

            ticket.ticket_values.push(ticketValue);
            $scope.selectTicketValue(ticketValue);

            entityManager.tickets.putOneOfflineFirst(ticket).then(function() {
                angular.copy(ticket, $scope.selectedTicketCircuit);
            });
        });
    };

    $scope.removeTicketValue = function(ticketValue) {
        var ticketCircuit = $scope.selectedTicketCircuit;

        confirmDialog.show($translate.instant('CASHREGISTER.PAYMENTS.WANT_TO_REMOVE_FROM_CIRCUIT_TICKET')).then(function(answer) {
            if(answer) {
                var ticket = _.cloneDeep(ticketCircuit);

                _.remove(ticket.ticket_values, function(v) {
                    return v.value === ticketValue.value;
                });

                entityManager.tickets.putOneOfflineFirst(ticket).then(function() {
                    angular.copy(ticket, ticketCircuit);
                });
            }
        });
    };

    $scope.topbar_context.onSubmitBarcode = function(barcodeInput) {
        if (barcodeManager.isTicket(barcodeInput)) {
            if(ticketPaymentMethod) {
                var ticketValue = barcodeManager.parseTicket(barcodeInput);

                $scope.addPaymentToSale(ticketPaymentMethod, { amount: ticketValue, code: barcodeInput });
            } else {
                alertDialog.show($translate.instant('CASHREGISTER.PAYMENTS.NO_TICKET_CONFIGURED'));
            }
        }
    };

    $scope.addPaymentToSale = async(paymentMethod, options) => {
        if(!_.isObject(options)) {
            options = {};
        }

        let paymentAmount = options.amount || _.toNumber($scope.keypadDynamicValue.replace(',', '.'));
        $scope.keypadDynamicValue = $filter('sclCurrency')(0, '');

        if(paymentAmount === 0) {
            if (paymentMethod.payment_method_type_id === 6) { // Ticket view
                $scope.modeview = 'tickets';
                $scope.topbar_context.modeview = $scope.modeview;
                return;
            } else {
                paymentAmount = $scope.getToPay();
            }
        }

        if (($scope.getToPay() > 0 || isPMS(paymentMethod)) && !(isCashDrawer(paymentMethod) && paymentAmount > $scope.getToPay())) {
            switch(paymentMethod.payment_method_type_id) {
                case 4: //Credit Cards
                    let circuit = await iconListDialog.show(_.cloneDeep(cardCircuits), { title: $translate.instant('CASHREGISTER.PAYMENTS.SELECT_CIRCUIT_CARD') });
                    let paymentInputObject = {
                        card_circuit_id: circuit.id,
                        card_circuit_name: circuit.name
                    };

                    $scope.sale.addGenericPayment(paymentMethod, paymentAmount, paymentInputObject);
                break;
                case 6: //Ticket
                    if(options.paymentInputObject) {
                        $scope.sale.addGenericPayment(paymentMethod, paymentAmount, options.paymentInputObject);
                    } else {
                        let ticket = await iconListDialog.show(ticketsCollection, { title: $translate.instant('CASHREGISTER.PAYMENTS.SELECT_CIRCUIT_TICKET') });
                        let paymentInputObject = {
                            ticket_circuit: ticket.circuit,
                            ticket_id: ticket.id,
                            ticket_name: ticket.name,
                            code: options.code
                        };

                        $scope.sale.addGenericPayment(paymentMethod, paymentAmount, paymentInputObject);
                    }
                break;
                case 17: //Delivery
                    if($scope.sale.currentSale.channel && $scope.sale.currentSale.channel !== 'pos') {
                        let paymentInputObject = {
                            payment_data: $scope.sale.currentSale.channel
                        };

                        $scope.sale.addGenericPayment(paymentMethod, paymentAmount, paymentInputObject);
                    } else {
                        let channels = _.map(deliveryChannels, (channel) => {
                            let ch = _.clone(channel);
                            ch.icon = ch.image_url || `assets/images/channels/${channel.id}.png`;

                            return ch;
                        });

                        let circuit = await iconListDialog.show(channels, { title:  $translate.instant('CASHREGISTER.PAYMENTS.SELECT_CIRCUIT_DELIVERY') });
                        let paymentInputObject = {
                            payment_data: circuit.id
                        };

                        $scope.sale.addGenericPayment(paymentMethod, paymentAmount, paymentInputObject);
                    }
                break;
                case 22: case 23: case 28: case 29: case 36: case 39: case 41://Bedzzle/Beddy/Lean PMS/Spiagge.it
                    if(!_.isEmpty($scope.sale.currentSale.payments)) {
                        alertDialog.show($translate.instant('CASHREGISTER.PAYMENTS.MIXED_PAYMENTS_NOT_SUPPORTED'));
                    } else if(_.some($scope.sale.currentSale.sale_items, function(saleItem) { return saleItem.quantity < 0; })) {
                        alertDialog.show($translate.instant('CASHREGISTER.PAYMENTS.REFUNDS_NOT_ALLOWED'));
                    } else {
                        $scope.sale.addGenericPayment(paymentMethod, $scope.getToPay());
                    }
                break;
                case 33: //Gift cards
                    let giftCardCode = await promptDialog.show({
                        title: $translate.instant("GIFT_CARD.PAYMENT_DIALOG.TITLE"),
                        label: $translate.instant("GIFT_CARD.PAYMENT_DIALOG.LABEL"),
                        validationFunction: async (value) => {
                            let results = [];

                            value = _.toString(value);

                            if(!value.length) {
                                return { valid: false };
                            }

                            try {
                                results = await restManager.getList('giftcards', { code: value });
                            } catch(err) {
                                //Nothing to do
                            }

                            let message;

                            if(!results.length) {
                                return { valid: false, message: $translate.instant("GIFT_CARD.PAYMENT_DIALOG.NOT_FOUND") };
                            }

                            let giftCard = results[0];

                            if(!giftCard.active) {
                                return { valid: false, message: $translate.instant("GIFT_CARD.PAYMENT_DIALOG.GIFTCARD_DISABLED") };
                            }

                            if(giftCard.origin_environment_only && $rootScope.userActiveSession.shop.name !== giftCard.origin_environment) {
                                return { valid: false, message: $translate.instant("GIFT_CARD.PAYMENT_DIALOG.ORIGIN_ENVIRONMENT_ONLY") };
                            }

                            let messageParts = [
                                $translate.instant("GIFT_CARD.PAYMENT_DIALOG.AVAILABLE_CREDIT", { value: $filter('sclCurrency')( giftCard.last_value ?? giftCard.initial_value ) })
                            ];

                            if(giftCard.valid_until) {
                                messageParts.push($translate.instant("GIFT_CARD.PAYMENT_DIALOG.EXPIRE_DATE", { date: $filter('sclDate')(giftCard.valid_until) }));
                            }

                            message = messageParts.join(' - ');

                            return { valid: true, message: message };
                        }
                    });

                    let giftCards = await restManager.getList('giftcards', { code: giftCardCode });

                    if(!giftCards.length) {
                        return alertDialog.show($translate.instant("GIFT_CARD.PAYMENT_DIALOG.NOT_FOUND"));
                    }

                    let giftCard = giftCards[0];

                    if(!giftCard.active) {
                        return alertDialog.show($translate.instant("GIFT_CARD.PAYMENT_DIALOG.GIFTCARD_DISABLED"));
                    }

                    if(giftCard.origin_environment_only && $rootScope.userActiveSession.shop.name !== giftCard.origin_environment) {
                        return alertDialog.show($translate.instant("GIFT_CARD.PAYMENT_DIALOG.ORIGIN_ENVIRONMENT_ONLY"));
                    }

                    let credit = giftCard.last_value ?? giftCard.initial_value;

                    _.remove($scope.sale.currentSale.payments, { payment_method_type_id: 33, code: giftCard.code });

                    $scope.sale.addGenericPayment(paymentMethod, _.min([paymentAmount, $scope.getToPay(), credit]), {
                        code: giftCard.code,
                        payment_data: JSON.stringify({ giftcard_uuid: giftCard.uuid })
                    });

                break;
                default:
                    if(DigitalPaymentsManager.isPaymentDigital(paymentMethod.payment_method_type_id)) { // Digital payments
                        if (DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(paymentMethod.payment_method_type_id)) {
                            let digitalPayment = _.find($scope.sale.currentSale.payments, (payment) => DigitalPaymentsManager.isPaymentDigital(payment.payment_method_type_id));

                            if(digitalPayment) {
                                alertDialog.show($translate.instant('CASHREGISTER.PAYMENTS.POS_PAYMENT_PRESENT'));
                            } else {
                                $scope.sale.addGenericPayment(paymentMethod, paymentAmount);
                            }
                        } else {
                            alertDialog.show($translate.instant('CASHREGISTER.PAYMENTS.METHOD_NOT_AVAILABLE_ON_THIS_DEVICE'));
                        }
                    } else {
                        $scope.sale.addGenericPayment(paymentMethod, paymentAmount);
                    }
                break;
            }
        } else {
            alertDialog.show($translate.instant('CASHREGISTER.PAYMENTS.AMOUNT_NOT_NECESSARY'));
        }
    };

    $scope.$watch(function() {
        return $scope.topbar_context.modeview;
    }, function(newValue) {
        $scope.modeview = newValue;
        if ($scope.modeview === 'tickets') {
            $scope.topbar_context.back_label = 'Ticket';
        }
    });
}
