import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigurationManagerService } from 'src/app/core';
import { SettingsAlvoloStateService } from '../../../service/settings-alvolo.state.service';
import { DevLogger } from 'src/app/shared/dev-logger';
import { restManager } from 'app/ajs-upgraded-providers';
import { AlvoloGenericResponse } from 'tilby-models';
import { OpenDialogsService } from 'src/app/dialogs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-settings-alvolo-activate',
    templateUrl: './settings-alvolo-activate.component.html',
    styleUrls: ['./settings-alvolo-activate.component.scss'],
    standalone: true,
    imports: [ CommonModule, NgOptimizedImage, TranslateModule, FormsModule, ReactiveFormsModule, MatOptionModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatProgressSpinnerModule ],
})
export class SettingsAlvoloActivateComponent implements OnInit{
    @Output() eventEmitterNextStep = new EventEmitter();
    private formBuilder = inject(FormBuilder);
    private configurationManagerService = inject(ConfigurationManagerService);
    private readonly openDialogService = inject(OpenDialogsService);
    private translate = inject(TranslateService);
    private settingsAlvoloStateService = inject(SettingsAlvoloStateService);
    priceLists: { index: number; name: string | true }[] = [];
    showSubmitButton: boolean = true;
    formGroupSettings = this.formBuilder.group({
        alias: [ "",[ Validators.required, Validators.minLength(4), Validators.pattern("([A-Za-z0-9\-]+)")]],
        list: [null, [Validators.required]],
    });

    constructor(
        @Inject(restManager) private restManagerService: any
    ){}

    ngOnInit(): void {
        for (let idx = 1; idx <= 10; idx++) {
            if (!this.configurationManagerService.getPreference(`price_list_${idx}_hide`)
            ) {
                this.priceLists.push({
                    index: idx,
                    name:this.configurationManagerService.getPreference(`price_list_${idx}_name`) ||this.translate.instant("CASHREGISTER.TOPBAR.PRICE_LIST_START") +" " +idx,});
            }
        }
    }

    get alias() {
        return this.formGroupSettings.get("alias");
    }

    get list() {
        return this.formGroupSettings.get("list");
    }

    async onSubmit(){
        if(this.formGroupSettings.valid){
            this.showSubmitButton = false;

            const shop = {
                payload: {
                    shop: {
                        alias: this.formGroupSettings.value.alias!
                    }
                }
            }

            this.settingsAlvoloStateService.alias = this.formGroupSettings.value.alias!;
            const priceList: {index: number, name: string} = this.formGroupSettings.value.list!;

            await this.restManagerService.post("alvolo/init", shop)
            .then((response:AlvoloGenericResponse) => {
                if(response){
                    this.configurationManagerService.setShopPreference("alvolo.pricelist_id", priceList.index);
                    this.settingsAlvoloStateService.alias = shop.payload.shop.alias;
                    this.settingsAlvoloStateService.alvoloInitHandler(this.formGroupSettings.value.alias!, this.formGroupSettings.value.list!);
                    this.eventEmitterNextStep.emit();
                }
            })
            .catch((error: any) => {
                if(error.data.error.error){
                    if(error.data.error.error.message.includes("is already in use.")){
                        this.openDialogService.openAlertDialog({data: {messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.ALREADY_EXISTS'}});
                    } else if(error.data.error.error.message.includes("is not in a valid format")){
                        this.openDialogService.openAlertDialog({data: {messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.FORMAT_NOT_VALID'}});
                    } else if(error.data.error.error.message.includes('Unable to find available configuration')) {
                        this.openDialogService.openAlertDialog({data: {messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.ERROR_GENERATE_ALIAS'}});
                    } else if(error.data.error.error.message.includes('Password already initialized')) {
                        this.openDialogService.openAlertDialog({data: {messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.ERROR_CONTACT_ADMIN'}});
                    }
                } else {
                    this.openDialogService.openAlertDialog({data: {messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.ERROR_GENERATE_ALIAS'}});
                }
            })
            
            this.showSubmitButton = true;
        }
    }
}