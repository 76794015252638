<button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"
        aria-label="Example icon-button with a menu"
        class="horizontal-menu-element">
    <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onOpenDetail($event)">
        <span translate="HISTORY.SALES.SHOWCASE.ACTIONS.DETAIL"></span>
    </button>
    @if(!isCreditNote) {
        <button mat-menu-item (click)="onRefundVoid($event)">
            @if(isEInvoice) {
                <span translate="HISTORY.SALES.SHOWCASE.ACTIONS.CREDIT_NOTE"></span>
            } @else {
                <span translate="HISTORY.SALES.SHOWCASE.ACTIONS.CANCEL"></span>
            }
        </button>
    }
    @if(!isEInvoice) {
        <button mat-menu-item (click)="onCourtesyReceipt($event)"> <!-- TODO : Print in progress? -->
            <span translate="HISTORY.SALES.SHOWCASE.ACTIONS.COURTESY_RECEIPT"></span>
        </button>
    }
    @if(!isCreditNote) {
        <button mat-menu-item (click)="onDuplicateSale($event)">
            <span translate="HISTORY.SALES.SHOWCASE.ACTIONS.DUPLICATE_SALE"></span>
        </button>
    }

    @if(canUseExpenseReport) {
        <button mat-menu-item (click)="onCreateExpenseReport($event)">
            <span translate="HISTORY.EXPENSE_REPORT.TITLE"></span>
        </button>
    }
</mat-menu>