import {
	Component,
	Injectable,
	OnInit,
	inject
} from '@angular/core';

import { CommonModule } from '@angular/common';

import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef
} from '@angular/material/dialog';

import {
	CustomForm,
	CustomFormArray,
	CustomFormArrayProps,
	CustomFormControl,
	CustomFormControlProps,
	CustomFormGroup,
	CustomFormGroupProps,
	TilbyMagicFormComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

import {
	BaseDialogService,
	NonNullableConfigData,
	TilbyDialogContentComponent,
	TilbyDialogToolbarComponent
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
	SalesEInvoice,
	SalesEInvoicePurchaseOrder
} from 'tilby-models';

import {
	DEDUCTION_CAUSES,
	DEDUCTION_TYPES,
	DeductionCausesCode,
	DeductionTypesCode,
	PAYMENT_CONDITIONS,
	PaymentConditionsCode,
	VAT_RECEIVABILITIES
} from './e-invoice-dialog.constants';

import { FORM_GROUP_VALIDATORS } from '@tilby/tilby-ui-lib/utilities';

import { Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { DevLogger } from 'src/app/shared/dev-logger';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';


type PurchaseOrderData = {
	documentId: string,
	date: string,
	numItem: string,
	commissionCode: string,
	cupCode: string,
	cigCode: string,
}

type Document = {
	invoiceCause: string,
	administrativeRef: string,
}

// EInvoiceDialogFormValue types
type PurchaseOrder = {
	purchaseOrderData: PurchaseOrderData,
	document: Document
}

type Payment = {
	paymentConditionCode: PaymentConditionsCode,
	vatReceivabilityCode: any,
}

type Withholding = {
	deductionCauseCode: DeductionCausesCode,
	deductionVat: number,
	deductionAmount: number,
	deductionTypeCode: DeductionTypesCode,
}

type Other = {
	stampDutyAmount: number,
	invoiceArt73: boolean,
}

type EInvoiceDialogFormValue = {
	purchaseOrders: PurchaseOrder[],
	payment: Payment,
	withholding: Withholding,
	other: Other
}

type EInvoiceDialogForm = CustomFormGroup<CustomForm<EInvoiceDialogFormValue>>;

type EInvoiceDialogParams = {
	e_invoice: SalesEInvoice;
};

@Component({
	selector: 'app-e-invoice-dialog',
	standalone: true,
	imports: [
		CommonModule,
		TilbyMagicFormComponent,
		TilbyDatePipe,
		TilbyDialogContentComponent,
		TilbyDialogToolbarComponent,
		TilbyMagicFormComponent
	],
	templateUrl: './e-invoice-dialog.component.html',
	styleUrls: ['./e-invoice-dialog.component.scss']
})
export class EInvoiceDialogComponent implements OnInit {
	private readonly matDialogRef = inject(MatDialogRef);
	protected readonly data: EInvoiceDialogParams = inject(MAT_DIALOG_DATA);

	protected _form?: EInvoiceDialogForm;

	private log(...args: any[]) {
		DevLogger.log('E_INVOICE_DIALOG_STATE_SERVICE', ...args);
	}

	private createForm() {
		const eInvoice = this.data.e_invoice;
		const currentPurchaseOrders = eInvoice?.purchase_order?.[0] ? eInvoice.purchase_order : [{}] as SalesEInvoicePurchaseOrder[];

		const purchaseOrders = currentPurchaseOrders.map((purchaseOrder) => new CustomFormGroup<CustomForm<PurchaseOrder>>({
			purchaseOrderData: new CustomFormGroup<CustomForm<PurchaseOrderData>>(
				{
					documentId: new CustomFormControl<string | undefined>(purchaseOrder?.document_id, { validators: [Validators.maxLength(20)] }, {
						...new CustomFormControlProps(),
						label: "Numero dell'ordine",
						inputConstraint: { maxLength: 20 },
						class: 'tw-w-full'
					}),
					//@ts-ignore
					date: new CustomFormControl<string | undefined>(purchaseOrder?.date || TilbyDatePipe.date(), {}, {
						...new CustomFormControlProps(),
						label: "Data dell’ordine",
						inputType: "date",
						class: 'tw-w-full'
					}),
					numItem: new CustomFormControl<string | undefined>(purchaseOrder?.num_item, { validators: [Validators.maxLength(20)] }, {
						...new CustomFormControlProps(),
						label: "Identificativo della singola voce all'interno dell’ordine",
						inputConstraint: { maxLength: 20 },
						class: 'tw-w-full'
					}),
					commissionCode: new CustomFormControl<string | undefined>(purchaseOrder?.commission_code, { validators: [Validators.maxLength(100)] }, {
						...new CustomFormControlProps(),
						label: "Codice della commessa o della convenzione",
						inputConstraint: { maxLength: 100 },
						class: 'tw-w-full'
					}),
					cupCode: new CustomFormControl<string | undefined>(purchaseOrder?.cup_code, { validators: [Validators.maxLength(15)] }, {
						...new CustomFormControlProps(),
						label: "Codice Unitario Progetto (CUP)",
						inputConstraint: { maxLength: 15 },
						class: 'tw-w-full'
					}),
					cigCode: new CustomFormControl<string | undefined>(purchaseOrder?.cig_code, { validators: [Validators.maxLength(15)] }, {
						...new CustomFormControlProps(),
						label: "Codice Identificativo della Gara (CIG)",
						inputConstraint: { maxLength: 15 },
						class: 'tw-w-full'
					}),
				}, { validators: [FORM_GROUP_VALIDATORS.requiredValidator(['documentId'], '||', ['numItem', 'commissionCode', 'cupCode', 'cigCode'])] }, {
				...new CustomFormGroupProps(),
				label: "Dati ordine d'acquisto",
				classLabel: 'tw-font-semibold tw-my-2'
			}),
			document: new CustomFormGroup<CustomForm<Document>>({
				invoiceCause: new CustomFormControl<string | undefined>(eInvoice?.invoice_cause, {
					validators: [Validators.maxLength(
						2000)]
				}, {
					...new CustomFormControlProps(),
					label: "Causale Documento",
					inputConstraint: { maxLength: 2000 },
					inputType: 'textarea',
					class: 'tw-w-full'
				}),
				administrativeRef: new CustomFormControl<string | undefined>(eInvoice?.administrative_ref, { validators: [Validators.maxLength(20)] }, { ...new CustomFormControlProps(), label: "Riferimento Amministrativo", inputConstraint: { maxLength: 20 }, inputType: 'textarea', class: 'tw-w-full' }),
			}, {}, {
				...new CustomFormGroupProps(),
				label: "Documento",
				classLabel: 'tw-font-semibold tw-my-2'
			}),
		}, {}, {
			...new CustomFormGroupProps(),
			classLabel: 'tw-font-semibold tw-my-2'
		}));

		const payment = new CustomFormGroup<CustomForm<Payment>>({
			paymentConditionCode: new CustomFormControl<PaymentConditionsCode>('TP02', {}, {
				...new CustomFormControlProps(),
				label: "Condizioni di Pagamento",
				inputType: 'select',
				inputChoices: PAYMENT_CONDITIONS.map(({ name, code }) => ({ key: name, value: code })),
				class: 'tw-w-full'
			}),
			vatReceivabilityCode: new CustomFormControl<SalesEInvoice.VatReceivabilityEnum>(eInvoice?.vat_receivability || 'I', {}, {
				...new CustomFormControlProps(),
				label: "Esigibilità IVA",
				inputType: 'select',
				inputChoices: VAT_RECEIVABILITIES.map(({ name, code }) => ({ key: name, value: code })),
				class: 'tw-w-full'
			}),
		}, {}, { ...new CustomFormGroupProps(), label: "Pagamento", classLabel: 'tw-font-semibold tw-my-2' });

		const withholding = new CustomFormGroup<CustomForm<Withholding>>({
			deductionCauseCode: new CustomFormControl<DeductionCausesCode | undefined>(DEDUCTION_CAUSES.find(({ name }) => eInvoice?.deduction_cause == name)?.code, { validators: [] }, { ...new CustomFormControlProps(), label: 'Causale Ritenuta', inputType: 'select', inputChoices: DEDUCTION_CAUSES.map(({ name, code }) => ({ key: name, value: code })), class: 'tw-w-5/12' }),
			deductionVat: new CustomFormControl<number | undefined>(eInvoice?.deduction_vat, { validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9]*(\.[0-9]{0,2})?$/)] }, { ...new CustomFormControlProps(), label: 'Aliquota Ritenuta', inputType: 'number', hint: () => 'esempio: 7, 10.1, 99.99', inputConstraint: { min: 0, max: 100, pattern: /^[0-9]*(\.[0-9]{0,2})?$/ }, class: 'tw-w-5/12' }),
			deductionAmount: new CustomFormControl<number | undefined>(eInvoice?.deduction_amount, { validators: [Validators.min(0), Validators.pattern(/^[0-9]*(\.[0-9]{0,2})?$/)] }, { ...new CustomFormControlProps(), label: 'Importo Ritenuta', inputType: 'number', hint: () => 'esempio: 7, 10.1, 99.99', inputConstraint: { min: 0, pattern: /^[0-9]*(\.[0-9]{0,2})?$/ }, class: 'tw-w-5/12' }),
			deductionTypeCode: new CustomFormControl<DeductionTypesCode | undefined>(eInvoice?.deduction_type as DeductionTypesCode, { validators: [] }, { ...new CustomFormControlProps(), label: 'Tipo Ritenuta', inputType: 'select', inputChoices: DEDUCTION_TYPES.map(({ name, code }) => ({ key: name, value: code })), class: 'tw-w-5/12' }),
		}, { validators: [FORM_GROUP_VALIDATORS.requiredValidator(['deductionAmount', 'deductionTypeCode', 'deductionVat', 'deductionCauseCode'], '||')] }, { ...new CustomFormGroupProps(), label: "Ritenuta", classLabel: 'tw-font-semibold tw-my-2' });

		const other = new CustomFormGroup<CustomForm<Other>>({
			stampDutyAmount: new CustomFormControl<number | undefined>(eInvoice?.stamp_amount, { validators: [Validators.min(0)] }, {
				...new CustomFormControlProps(),
				class: 'tw-w-5/12',
				label: 'Importo Bollo',
				inputType: 'number',
				inputConstraint: { min: 0 },
			}),
			invoiceArt73: new CustomFormControl<boolean | undefined>(eInvoice?.invoice_art_73, {}, {
				...new CustomFormControlProps(),
				class: 'tw-w-5/12',
				label: 'Art. 73',
				inputType: 'slideToggle'
			}),
		}, {}, { ...new CustomFormGroupProps(), label: "Altro", classLabel: 'tw-font-semibold tw-my-2' });

		this._form = new CustomFormGroup<CustomForm<EInvoiceDialogFormValue>>({
			purchaseOrders: new CustomFormArray(
				[...purchaseOrders],
				{},
				{ ...new CustomFormArrayProps() }
			),
			payment,
			withholding,
			other
		});
	}

	ngOnInit() {
		this.createForm();
	}

	protected confirm() {
		if (!this._form?.valid) {
			return;
		}

		const formValue = this._form?.value as EInvoiceDialogFormValue;

		if (!formValue) {
			return;
		}

		const removeNullProperties = (obj: any) => Object.keys(obj).reduce((acc, key) => (obj[key] !== null && obj[key] !== undefined ? { ...acc, [key]: obj[key] } : acc), {});

		// Handle purchase orders
		const purchaseOrders: SalesEInvoicePurchaseOrder[] = [];

		for (const pOrder of formValue.purchaseOrders) {
			//Exclude date from compilation check as it's compiled by default
			const { date, ...purchaseOrderData } = pOrder.purchaseOrderData;

			if (Object.values(purchaseOrderData).filter(value => !!value).length) {
				purchaseOrders.push({
					document_id: purchaseOrderData.documentId,
					// @ts-ignore
					date: date,
					num_item: purchaseOrderData.numItem,
					commission_code: purchaseOrderData.commissionCode,
					cup_code: purchaseOrderData.cupCode,
					cig_code: purchaseOrderData.cigCode
				});
			}
		}

		const dataToSend: SalesEInvoice = {
			purchase_order: purchaseOrders,
			deduction_type: formValue.withholding.deductionTypeCode,
			deduction_amount: formValue.withholding.deductionAmount,
			deduction_vat: formValue.withholding.deductionVat,
			deduction_cause: formValue.withholding.deductionCauseCode,
			vat_receivability: formValue.payment.vatReceivabilityCode,
			stamp_amount: formValue.other.stampDutyAmount,
			invoice_cause: formValue.purchaseOrders[0]?.document.invoiceCause,
			administrative_ref: formValue.purchaseOrders[0]?.document.administrativeRef,
			invoice_art_73: formValue.other.invoiceArt73,
			payment_condition: formValue.payment.paymentConditionCode,
		};

		const cleanDataToSend: Partial<SalesEInvoice & { payment_condition: PaymentConditionsCode }> = removeNullProperties(dataToSend);

		if (!cleanDataToSend.purchase_order?.length) {
			// @ts-ignore
			cleanDataToSend.purchase_order = null;
		}

		this.log('CONFIRM', { formValue, dataToSend, cleanDataToSend });

		this.matDialogRef.close(cleanDataToSend);
	}
}


@Injectable({
	providedIn: 'root'
})
export class EInvoiceDialogService extends BaseDialogService {
	private readonly dialogRef = inject(MatDialog);

	public openDialog(config: NonNullableConfigData<EInvoiceDialogParams>) {
		const dialogConfig: NonNullableConfigData<EInvoiceDialogParams> = {
			...this.switchMobileDesktopDimensions({ width: '800px' }),
			disableClose: true,
			data: config.data,
		};

		return lastValueFrom(this.dialogRef.open(EInvoiceDialogComponent, dialogConfig).afterClosed());
	}
}
