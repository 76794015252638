import {Component, Input, OnInit} from '@angular/core';
import {mobileCheck} from '../../../../../utilities';
import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import { TilbyGeneralInputButtonComponent } from '@tilby/tilby-ui-lib/components/tilby-buttons';
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-left-context-toolbar',
    standalone:true,
    templateUrl: './left-context-toolbar.component.html',
    styleUrls: ['./left-context-toolbar.component.scss'],
    imports: [TilbyGeneralInputButtonComponent, NgIf],
    host: {class: 'tw-flex tw-items-center'}
})
export class LeftContextToolbarComponent implements OnInit {
    isMobile = mobileCheck();
    @Input() btn?: GeneralInputButton;
    @Input() label='';

    ngOnInit(): void {
    }
}
