import { Injectable, inject } from '@angular/core';
import { restManager } from 'app/ajs-upgraded-providers';
import { HttpClient } from '@angular/common/http';
import { v4 as generateUuid } from 'uuid';
import { DevLogger } from 'src/app/shared/dev-logger';
import { lastValueFrom } from 'rxjs';
import { CustomFormControl } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { OpenDialogsService } from 'src/app/dialogs';

export type ItemThumbnailType = {
    name?: string;
    extension?: string;
    data?: any;
    uploaded?: boolean;
    image_url?: string|undefined|ArrayBuffer|null;
    deleted?: boolean;
};

@Injectable({
    providedIn: 'root'
})
export class ImagesManagerService {
    private readonly restManagerService = inject(restManager);
    private readonly http = inject(HttpClient);
    private readonly openDialogsService = inject(OpenDialogsService);

    private maxImageWidth = 1280;
    private maxImageHeight = 720;

    public static getExtension(url: string) {
        return '.' + url.split('.').pop();
    }

    private static checkIsAnImage(fileHandle: File) {
        switch (fileHandle.type) {
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
            case 'image/bmp':
                return true;
            default:
                return false;
        }
    }

    private static openImageAsURL(fileHandle: File): Promise<string|undefined|ArrayBuffer|null> {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = (e) => resolve(e?.target?.result);
            fr.onerror = (e) => reject('error opening file');
            fr.readAsDataURL(fileHandle);
        });
    }

    private static resizeImage(dataAsURL: any, maxImageWidth: number, maxImageHeight: number) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = () => {
                if (img.height < maxImageHeight && img.width < maxImageWidth) {
                    resolve({ data: dataAsURL });
                }

                const scaleDownFactor = Math.max(img.height / maxImageHeight, img.width / maxImageWidth);

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width / scaleDownFactor;
                canvas.height = img.height / scaleDownFactor;

                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
                const imageScaled = canvas.toDataURL();

                resolve({ data: imageScaled, newExtension: '.png' });
            };

            img.onerror = () => reject('error opening image');

            img.src = dataAsURL;
        });
    }

    private static convertToUint8(data: any) {
        const BASE64_MARKER = ';base64,';
        const base64Index = data.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = data.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return array;
    }

    private static checkIsVideo(fileHandle: File) {
        switch (fileHandle.type) {
            case 'video/mp4':
                return true;
            default:
                return false;
        }
    }

    public static localLoadNewImage(fileHandle: File, maxImageWidth: number, maxImageHeight: number):Promise<ItemThumbnailType|string> {
        return new Promise(async (resolve, reject) => {
            if (!this.checkIsAnImage(fileHandle) && !this.checkIsVideo(fileHandle)) {
                reject('APPLICATION.INVALID_IMAGE');
            } else {
                const imageNewName = generateUuid();
                const arr = fileHandle.name.split('.');
                const lastEl = arr.length - 1;
                const extension = '.' + arr[lastEl];
                try {
                    const dataAsURL = await this.openImageAsURL(fileHandle);

                    if(this.checkIsAnImage(fileHandle)) {
                        this.resizeImage(dataAsURL, maxImageWidth, maxImageHeight).then(
                            (resized: any) => {
                                resolve({
                                    name: imageNewName + (resized.newExtension || extension),
                                    image_url: resized.data,
                                    data: this.convertToUint8(resized.data),
                                    uploaded: false,
                                    extension: resized.newExtension || extension
                                });
                            },
                            (error) => reject('APPLICATION.IMAGE_COMPRESSION_FAILED')
                        );
                    } else {
                        resolve({
                            name: imageNewName + extension,
                            image_url: dataAsURL,
                            data: this.convertToUint8(dataAsURL),
                            uploaded: false,
                            extension
                        });
                    }
                } catch(err) {
                    reject('APPLICATION.PROBLEM_OPENING_IMAGE');
                }
            }
        })
    }

    private static getMediaType(extension: string) {
        switch (extension) {
            case '.jpg':
            case '.jpeg':
                return 'image/jpeg';
            case '.png':
                return 'image/png';
            case '.gif':
                return 'image/gif';
            case '.bmp':
                return 'image/bmp';
            case '.mp4':
                return 'image/mp4';
            default:
                return 'image/png';
        }
    }

    public static markToDelete(image: ItemThumbnailType) {
        image.deleted = true;
    }

    public async getImage(name: string) {
        try {
            const res = await this.restManagerService.getOne('media', name);

            if (res) {
                return res.destinationUrl;
            } else {
                throw new Error('IMAGE_MEDIA_CALL_FAILED');
            }
        } catch (err) {
            throw new Error('IMAGE_MEDIA_CALL_FAILED');
        }
    }

    public async uploadImage(image: ItemThumbnailType) {
        try {
            const resultAPI = await this.restManagerService.getOne('media', image.name);

            if (resultAPI) {
                const url = resultAPI.presignedUrl;
                const data = new Blob([image.data], { type: image.extension ? ImagesManagerService.getMediaType(image.extension) : 'image/png' });

                const options = {
                    headers: {
                        'Content-Type': image.extension ? ImagesManagerService.getMediaType(image.extension) : 'image/png'
                    }
                }
                try {
                    await lastValueFrom(this.http.put(url, data, options));
                    return Promise.resolve(resultAPI.destinationUrl);
                } catch(err) {
                    return Promise.reject('APPLICATION.IMAGE_UPLOAD_FAILED');
                }
            } else {
                throw new Error('no result');
            }
        } catch (err: any) {
            return Promise.reject('APPLICATION.IMAGE_URL_NOT_FOUND');
        }
    }

    public async saveAllImages(images: Array<ItemThumbnailType>) {
        for(let image of images) {
            if(!image.uploaded) {
                try {
                    await this.uploadImage(image);
                } catch (err) {
                    return Promise.reject();
                }
            }
        }
        return Promise.resolve(images);
    }

    public async handleNewFileLoaded(formControl: CustomFormControl, fileHandle: File, maxImageWidth = this.maxImageWidth, maxImageHeight = this.maxImageHeight) {
        try {
            const image: ItemThumbnailType | string = await ImagesManagerService.localLoadNewImage(fileHandle, maxImageWidth, maxImageHeight);
            this.log('FILE_FIELD_CHANGE', image);
            if (image && typeof image == 'object' && 'image_url' in image) {
                const uploadedImage = await this.uploadImage(image);
                formControl?.setValue(uploadedImage);
            }
        } catch (messageLabel: any) {
            this.log('reason', messageLabel);
            if (typeof messageLabel != 'string') messageLabel = 'APPLICATION.PROBLEM_OPENING_IMAGE';
            this.openDialogsService.openAlertDialog({data: {messageLabel}});
        }
    }

    private log(...args: any[]) {
        DevLogger.log('IMAGES MANAGER SERVICE', ...args);
    }
}
