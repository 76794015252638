import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('history').factory('HistoryUtils', HistoryUtils);

HistoryUtils.$inject = ["checkManager", "entityManager", "saleUtils", "$state", "$translate"];

function HistoryUtils(checkManager, entityManager, saleUtils, $state, $translate) {
    return {
        createSale: async (saleData, options) => {
            if(!_.isObject(options)) {
                options = {};
            }

            let sale = await saleUtils.getSaleTemplate();
            let isItalianFiscalization = ['IT', 'MT'].includes(checkManager.getShopCountry());

            if(saleData.e_invoice){
                sale.e_invoice = _.omit(options.e_invoice, ['id', 'sale_id', 'invoice_progressive', 'invoice_yearly_progressive', 'send_progressive', 'date', 'lastcreate_at', 'external_id', 'external_message', 'external_status', 'intermediary_name', 'last_status_update', 'status']);
            }

            if(saleData.price_changes) {
                sale.price_changes = saleUtils.getCleanSubEntity(saleData.price_changes);
                options.keepPriceChanges = true;
            }

            if(Array.isArray(saleData.sale_items)) {
                sale.sale_items = saleData.sale_items.map((saleItem) => {
                    const newSaleItem = saleUtils.getCleanSaleItem(saleItem, options.keepPriceChanges);

                    for(let priceChange of newSaleItem.price_changes) {
                        delete priceChange.promotion_id;
                    }

                    return newSaleItem;
                });
            }

            sale.payments = saleUtils.getCleanSubEntity(saleData.payments);

            if (_.isObject(saleData.sale_customer)) {
                sale.sale_customer = _.chain(saleData.sale_customer).omit('id').cloneDeep().value();
            } else {
                sale.sale_customer = null;

                if(_.isString(saleData.sale_customer)) {
                    sale.customer_tax_code = saleData.sale_customer;
                }
            }

            switch(options.saleType) {
                case 'credit_note': case 'e_credit_note':
                    sale.name = _.truncate(saleData.name, { length: 27, omission: '' }) + " NC";
                break;
                case 'refund_doc':
                    sale.name = _.truncate(saleData.name, { length: 27, omission: '' }) + " RS";
                break;
                case 'void_doc':
                    sale.name = _.truncate(saleData.name, { length: 27, omission: '' }) + " AN";
                break;
                case 'summary':
                    sale.name = $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + sale.sale_number + " (R)";
                    sale.is_summary = true;
                break;
                case 'summary_e_rc': case 'summary_e_nrc':
                    sale.name = $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + sale.sale_number + " (RE)";
                    sale.is_summary = true;
                break;
                default:
                    sale.name = $translate.instant('CASHREGISTER.ACTIVE_SALE_MODEL.SALE') + sale.sale_number;
                break;
            }

            if (checkManager.isModuleEnabled('cashregister')) {
                sale.assigned_id = sale.seller_id;
                sale.assigned_name = sale.seller_name;
            }

            if(['summary_e_rc', 'summary_e_nrc'].includes(options.saleType) && isItalianFiscalization) {
                let scontrini = {};

                _.forEach(sale.sale_items, (item) => {
                    let seqNum = item.reference_sequential_number || item.reference_text;

                    scontrini[seqNum] = {
                        date: moment(item.reference_date).format('DD/MM/YYYY HH:mm'),
                        type: _.isString(seqNum) && /[0-9]{4}-[0-9]{4}/.test(seqNum) ? "Doc.Com" : "Scontrino"
                    };
                });

                let cause = [];

                _.forIn(scontrini, function(data, number) {
                    cause.push(`Rif. ${data.type} n. ${number} del ${data.date}`);
                });

                sale.e_invoice = {
                    invoice_cause: cause.join('\n')
                };
            }

            if(_.isObject(saleData.overrides)) {
                Object.assign(sale, saleData.overrides);
            }

            saleUtils.calculateSalePrices(sale);

            return new Promise(async (resolve, reject) => {
                resolve(sale);

                if(!options.returnOnly) {
                    let saleType = options.saleType;

                    if(!isItalianFiscalization) {
                        saleType = sale.is_summary ? 'generic_invoice' : 'generic_receipt';
                    }

                    await entityManager.sales.postOneOfflineFirst(sale);

                    if (checkManager.isModuleEnabled('cashregister')) {
                        const targetState = checkManager.isModuleAngular('tables_and_cashregister') ? 'app.new.cashregister.content.showcase' : 'app.cashregister.content.showcase';

                        $state.go(targetState, {
                            action: 'open-sale-id',
                            saleType: saleType,
                            id: sale.uuid
                        });
                    }
                }
            });
        }
    };
}
