<app-grid-server-side
    #appGridComponent
    (areRowsSelected)="isSelected=$event"
    (clickableButton)="crud($event)"
    (rowClicked)="rowClicked($event)"
    [cacheBlockSize]="query.per_page"
    [datasource]="datasource"
    [hidden]="!gridPath"
    [tableData]="tableData[0]"
    (updateChange)="updateItems($event)"
    (deleteChange)="deleteCustomers($event)"
    [exportFormatterOptions]="exportFormatterOptions"
    feature="customers"
></app-grid-server-side>

<div appUpgradeUiView *ngIf="!gridPath"></div>
