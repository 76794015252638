import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { errorsLogger, restManager } from 'app/ajs-upgraded-providers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Form } from 'src/app/models';

type EditPinFormFields = {
    pin: string | null;
}
type EditPinFormFieldsNullable = {
    [prop in string & keyof EditPinFormFields]:EditPinFormFields[prop]|null
}
type EditPinForm = Partial<Form<EditPinFormFieldsNullable>>;

type EditPinPayload = {
    first_name: string;
    last_name: string;
    pin: string | null;
}

@Component({
  selector: 'app-edit-pin-dialog',
  templateUrl: './edit-pin-dialog.component.html',
})
export class EditPinDialogComponent implements OnInit {
    editPinDialogForm: FormGroup<{pin?:FormControl<string|null>}>;
    errorPin: boolean;
    errorPinMessage: string;
    data: EditPinPayload;
    inProgress: boolean;

    constructor(
        public dialogRef: MatDialogRef<EditPinDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: EditPinPayload,
        @Inject(restManager) private restManagerService: any,
        @Inject(errorsLogger) private errorsLoggerService: any,
        private translate: TranslateService,
    ) {
        this.data = _data;
        this.editPinDialogForm = new FormGroup<EditPinForm>({
            pin: new FormControl(this.data.pin || null, [Validators.minLength(4), Validators.maxLength(4)])
        });
        this.errorPin = false;
        this.errorPinMessage = '';
        this.inProgress = false;
    }
    ngOnInit(): void {

    }
    get pin(){
        return this.editPinDialogForm.controls.pin;
    }

    confirm(): void {
        this.inProgress = true;
        this.errorPin = false;
        const payload: EditPinPayload = this.data;
        payload.pin = this.pin?.value || null;
        this.restManagerService.post('sessions/current_user', payload).then((res: any) => {
            if(res) {
                this.dialogRef.close(res);
            }
        }).catch((res: any) => {
            this.errorsLoggerService.err('POST sessions/current_user', res);
            if (res && res.status === 422) {
                this.errorPinMessage = res.data.message;
                this.errorPin = true;
                if(res.data.type === 'input') {
                    this.errorPinMessage = this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_PIN_INPUT');
                }
            }
        }).finally(() => {
            this.inProgress = false;
        });
    }
}
