import * as angular from 'angular';
import * as _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';

angular.module('items').controller('RawMaterialsCtrl', ["$scope", "$filter", "$translate", "user", "rawMaterials", "suppliers", "entityManager", "confirmDialog", "alertDialog", "checkManager", function($scope, $filter, $translate, user, rawMaterials, suppliers, entityManager, confirmDialog, alertDialog, checkManager) {
    $scope.user = user;
    $scope.itemsQuery = {};

    $scope.topbar_context = {
        showcaseView: 'crudviewmode',
        back_label: $translate.instant('ITEMS.RAW_MATERIALS.PRODUCTS'),
        current_label: $translate.instant('ITEMS.RAW_MATERIALS.RAW_MATERIALS')
    };

    var rawMaterialTemplate = {
        stock_type: 'simple',
        auto_unload: true
    };

    $scope.saveInProgress = false;

    $scope.rawMaterials = _.orderBy(rawMaterials, ['name'], ['asc']);

    $scope.pristineRawMaterial = null;

    var suppliersDict = new SclDictionary();

    _.forEach(suppliers, function(supplier) {
        suppliersDict.set(supplier.id, supplier);
    });

    $scope.canSave = function() {
        return !_.isEqual($scope.rawMaterial, $scope.pristineRawMaterial);
    };

    //Prepares the work copy of raw material
    $scope.afterSelectRawMaterial = function() {
        $scope.pristineRawMaterial.$supplier = $scope.pristineRawMaterial.default_supplier_id ? suppliersDict.get($scope.pristineRawMaterial.default_supplier_id) : null;
        $scope.rawMaterial = _.clone($scope.pristineRawMaterial);
    };

    // The first raw material is selected by default, can be changed to undefined and {}
    var selectDefaultRawMaterial = function() {
        $scope.pristineRawMaterial = _.isEmpty($scope.rawMaterials) ? {} : _.head($scope.rawMaterials);

        $scope.afterSelectRawMaterial();
    };

    selectDefaultRawMaterial();

    $scope.filterSuppliers = function(searchText) {
        return $filter('filter')(suppliers, searchText);
    };

    const isMagoEnabled = () => {
        const integrations_mago_publish_sales = checkManager.getPreference('integrations.mago.publish_sales');
        const integrations_mago_publish_customers = checkManager.getPreference('integrations.mago.publish_customers');
        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    $scope.deleteRawMaterial = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if (!$scope.rawMaterial.id) {
            return;
        }

        confirmDialog.show($translate.instant('ITEMS.RAW_MATERIALS.WANT_TO_DELETE')).then(function(answer) {
            if (answer) {
                entityManager.rawMaterials.deleteOneOnline($scope.rawMaterial.id, true).then(function(success) {
                    _.remove($scope.rawMaterials, {
                        id: success
                    });

                    selectDefaultRawMaterial();
                }, function(error) {
                    alertDialog.show($translate.instant('ITEMS.RAW_MATERIALS.ERROR_WHILE_DELETING'));
                });
            }
        });
    };

    $scope.addNewRawMaterial = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }
        // Fields are now blank
        $scope.pristineRawMaterial = _.clone(rawMaterialTemplate);
        $scope.rawMaterial = _.clone(rawMaterialTemplate);
    };

    $scope.saveEdit = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if (!$scope.saveInProgress) {
            var result = _.find($scope.rawMaterials, function(rawMaterial) {
                return rawMaterial.name === $scope.rawMaterial.name && rawMaterial.id !== $scope.rawMaterial.id;
            });

            if (result) {
                alertDialog.show($translate.instant('ITEMS.RAW_MATERIALS.RAW_MATERIAL_ALREAD_EXISTING'));
                return;
            }
            // Send to the BE
            var entityToBE = _.clone($scope.rawMaterial);

            //Compile Supplier ID
            entityToBE.default_supplier_id = _.get(entityToBE, ["$supplier", "id"]);
            delete entityToBE.$supplier;

            if (entityToBE.id) {
                // Do a PUT
                $scope.saveInProgress = true;
                entityManager.rawMaterials.putOneOnline(entityToBE).then(function(success) {
                    angular.copy(success, $scope.pristineRawMaterial);
                    $scope.afterSelectRawMaterial();
                }, function(error) {
                    // Show error dialog
                    alertDialog.show($translate.instant('ITEMS.RAW_MATERIALS.ERROR_TRY_LATER'));
                }).finally(function() {
                    $scope.saveInProgress = false;
                });
            } else {
                // Do a POST (after deleting rawMaterial.id)

                $scope.saveInProgress = true;
                entityManager.rawMaterials.postOneOnline(entityToBE).then(function(success) {
                    // Update the (button) view
                    $scope.rawMaterials.push(success);
                    // Update the (form) view
                    $scope.pristineRawMaterial = success;
                    $scope.afterSelectRawMaterial();
                }, function(error) {
                    // Show error dialog
                    alertDialog.show($translate.instant('ITEMS.RAW_MATERIALS.ERROR_TRY_LATER'));
                }).finally(function() {
                    $scope.saveInProgress = false;
                });
            }
        }
    };

    $scope.abortEdit = function() {
        if ($scope.rawMaterial.id) {
            // Update the (form) view
            $scope.afterSelectRawMaterial();
        } else {
            selectDefaultRawMaterial();
        }
    };

    var initRawMaterial = () => {
        $scope.rawMaterials = _.orderBy(rawMaterials, ['name'], ['asc']);
    };

    $scope.clearFilterText = function() {
        $scope.itemsQuery.filter_text = '';
        initRawMaterial();
    };

    $scope.onFilterUpdate = function() {
        if($scope.itemsQuery.filter_text && $scope.itemsQuery.filter_text !== '') {
            $scope.rawMaterials = _.filter($scope.rawMaterials, function(o) {
                const name = o.name.toLowerCase();
                const filter = $scope.itemsQuery.filter_text.toLowerCase();
                return _.includes(name, filter);
            });
        } else {
            initRawMaterial();
        }
    };
}]);
