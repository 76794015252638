import {
    Component,
    computed,
    effect,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    untracked,
    ViewChild,
} from '@angular/core';

import {
    CommonModule
} from '@angular/common';

import {
    CdkDrag,
    CdkDragEnd,
    CdkDragStart,
    Point
} from "@angular/cdk/drag-drop";

import {
    RoomsStateService
} from "src/app/features/tables/rooms.state.service";

import {
    RoomsTables,
    Sales
} from 'tilby-models';

import { DevLogger } from "src/app/shared/dev-logger";
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { TilbyTableElementComponent, Color } from '@tilby/tilby-ui-lib/components/tilby-table-element';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";

@Component({
    selector: 'app-room-element',
    standalone: true,
    imports: [CommonModule, CdkDrag, TilbyTableElementComponent, TilbyGesturesDirective],
    providers: [OnDestroyService],
    templateUrl: './room-element.component.html',
    styleUrls: ['./room-element.component.scss']
})
export class RoomElementComponent implements OnInit, OnDestroy {
    private readonly roomsStateService = inject(RoomsStateService);

    private isDragging = false;
    private interval?: NodeJS.Timer;

    protected billLockForEditMode = computed(() => (this.colorInNormalMode() !== 'green') && this.isEditMode());
    protected colorInNormalMode = signal<Color | undefined>(undefined);
    protected defaultDragPosition: Point = { x: 0, y: 0 };
    protected exitSents: number = 0;
    protected isEditMode = this.roomsStateService.isEditMode;
    protected isTableMultiple = false;
    protected maxExitsNumber: number = 0;
    protected tableHanging = false;
    protected tableMultipleBusyCovers = 0;

    public color = signal<Color | undefined>(undefined);
    public sale0?: Sales;
    public minutes = "0'";

    @ViewChild('tableElement', { read: TilbyTableElementComponent }) tableRef!: TilbyTableElementComponent;

    @Input() hangingAlarm = 60;
    @Input() sales: Sales[] = [];
    @Input({ required: true }) table!: RoomsTables;
    @Input() tableBookingWarning = false;
    @Input() useLastupdateTimer = false;
    @Input() disabledToggleShowAmount = false;

    @Output() tableClick = new EventEmitter<RoomsTables>();
    @Output() tableLongPress = new EventEmitter<RoomsTables>();
    @Output() tableMoved = new EventEmitter<RoomsTables>();

    private initializeSalesInfo() {
        const deduplicatedExits = new Set();

        for (const sale of this.sales) {
            for (const si of sale.sale_items || []) {
                if (si.exit) {
                    deduplicatedExits.add(si.exit);
                }
            }
        }

        this.sale0 = this.sales[0];
        this.maxExitsNumber = deduplicatedExits.size;
        this.exitSents = Object.keys(this.sale0?.sent_exits || {}).length || 0;
        this.isTableMultiple = this.table.order_type === 'multiple' || this.sales.length > 1;
        this.tableMultipleBusyCovers = this.isTableMultiple ? this.sales.reduce((sum, a) => sum + (a.covers || 0), 0) : 0;
    }

    private log(...args: any[]) {
        DevLogger.log('RoomElement', ...args);
    }

    constructor() {
        effect(() => this.color.set(this.isEditMode() ? 'grey' : (untracked(this.colorInNormalMode) || untracked(this.color))), { allowSignalWrites: true });
        effect(() => this.colorInNormalMode.set((['grey', 'blue'].indexOf(this.color() || '') < 0) ? this.color() : (untracked<Color | undefined>(this.colorInNormalMode))), { allowSignalWrites: true });
    }

    ngOnInit() {
        this.defaultDragPosition = { x: this.table.xpos || 0, y: this.table.ypos || 0 };
        this.initializeSalesInfo();
        this.determineTableState();
    }

    onDragStart(_$event: CdkDragStart) {
        this.log('DRAG_START');
        this.isDragging = true;
    }

    onDragEnd($event: CdkDragEnd) {
        const { x, y } = $event.source.getFreeDragPosition();
        this.tableMoved.emit(<RoomsTables>{ ...this.table, xpos: x, ypos: y });
    }

    onClick($event: MouseEvent) {
        $event.stopPropagation(); // So that if you click in deselection (in the parent) doesn't occur
        this.log('CLICK', this.table);
        if (this.isEditMode() && !this.isDragging) {
            this.color.update(color => color == 'blue' ? 'grey' : 'blue');
        }
        this.tableClick.emit(this.table);
        this.isDragging = false;
    }
    onLongPress() {
        this.log('LONG_PRESS', this.table);
        if (!this.isEditMode()) this.tableLongPress.emit(this.table);
    }

    private determineTableState() {
        let tableColor: Color = 'green';

        if (this.sale0) {
            switch (this.table.order_type) {
                case 'single':
                    tableColor = (this.sales.length === 1 && this.sale0.bill_lock) ? 'yellow' : 'red';
                    this.updateTableDuration();
                    this.interval = setInterval(() => this.updateTableDuration(), 10000);
                    break;
                case 'multiple':
                    tableColor = 'red';
                    break;
            }
        }

        this.colorInNormalMode.set(tableColor);
    }

    private updateTableDuration() {
        if (!this.sale0) {
            return;
        }

        let tableHanging = false;

        if (this.sale0 && this.hangingAlarm) {
            tableHanging = (new Date(this.sale0.lastupdate_at!).valueOf() - new Date().valueOf()) > (this.hangingAlarm * 60 * 1000);
        }

        this.tableHanging = tableHanging;
        //@ts-expect-error
        this.minutes = TilbyDatePipe.durationBetweenDates(this.lastUpdateDate ? this.sale0.lastupdate_at : this.sale0.open_at);
    }


    ngOnDestroy() {
        clearInterval(this.interval);
    }
}
