<div class="file-importer-fields tw-flex tw-flex-col tw-h-full" layout="column">
    <div *ngIf="toolbarHelperIsVisible" class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-text-white tw-bg-sky-500" layout="row">
        <a translate class="tw-flex tw-ml-4" (click)="visitHelpPage()">{{'FILE_IMPORTER.FIELDS.HELP.' + this.fileConfig.typeInsert?.toUpperCase()}}</a>
        <button mat-button (click)="closeHelper()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <form [formGroup]="fileImporterFieldsForm" class="tw-max-h-[calc(100%-150px)] tw-overflow-y-auto">
        <table mat-table [dataSource]="exampleRow" class="tw-h-full">
            <ng-container matColumnDef="index_column">
                <th mat-header-cell *matHeaderCellDef translate="FILE_IMPORTER.FIELDS.HEADER.COLUMN_INDEX"></th>
                <td mat-cell *matCellDef="let element; let i = index" class="tw-w-[10%]"> {{i+1}} </td>
            </ng-container>
            <ng-container matColumnDef="name_column">
                <th mat-header-cell *matHeaderCellDef translate="FILE_IMPORTER.FIELDS.HEADER.COLUMN_NAME"></th>
                <td mat-cell *matCellDef="let element" class="tw-w-[30%]"> {{element.keyCsv}} </td>
            </ng-container>
            <ng-container matColumnDef="example">
                <th mat-header-cell *matHeaderCellDef translate="FILE_IMPORTER.FIELDS.HEADER.EXAMPLE"></th>
                <td mat-cell *matCellDef="let element" class="tw-w-[30%]" style="overflow: hidden; max-width: 400px;">
                    <span class="ellipsis">
                        {{element.value}}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="tilby_value">
                    <th mat-header-cell *matHeaderCellDef translate="FILE_IMPORTER.FIELDS.HEADER.VALUE"></th>
                    <td mat-cell *matCellDef="let element" class="tw-w-[30%]"> 
                        <tilby-text-input-autocomplete [formControlLabel]="element.keyCsv"/>
                    </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter</td>
            </tr>
        </table>
    </form>
    <div class="tw-flex tw-flex-col">
        <tilby-magic-form [form]="fileImporterFieldsFormMandatoryFields"/>
    </div>
</div>