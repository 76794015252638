import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { errorsLogger, restManager } from 'app/ajs-upgraded-providers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Form } from 'src/app/models';
import { MobilePrefixType, mobilePrefixes } from 'src/app/core/constants';
import { ConfigurationManagerService } from 'src/app/core';

type EditPhoneFormFields = {
    mobilePrefix: string;
    phone: string;
    verification_code: string;
}
type EditPhoneFormFieldsNullable = {
    [prop in string & keyof EditPhoneFormFields]:EditPhoneFormFields[prop]|null
}
type EditPhoneForm = Partial<Form<EditPhoneFormFieldsNullable>>;

type EditPhonePayload = {
    phone?: string | null;
    temp_phone_code?: string | null;
}

@Component({
  selector: 'app-edit-phone-dialog',
  templateUrl: './edit-phone-dialog.component.html',
  styleUrls: ['./edit-phone-dialog.component.scss']
})
export class EditPhoneDialogComponent implements OnInit {
    editPhoneDialogForm: FormGroup<{
        mobilePrefix?: FormControl<string|null>,
        phone?: FormControl<string|null>,
        verification_code?: FormControl<string|null>
    }>;
    waitingCode: boolean;
    errorPhone: boolean;
    errorPhoneMessage: string;
    verificationCodeError: boolean;
    verificationCodeErrorMessage: string;
    data: EditPhonePayload;
    inProgress: boolean;
    mobilePrefixes: MobilePrefixType[];

    constructor(
        public dialogRef: MatDialogRef<EditPhoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: { phone: string },
        @Inject(restManager) private restManagerService: any,
        @Inject(errorsLogger) private errorsLoggerService: any,
        private translate: TranslateService,
        protected configurationManager: ConfigurationManagerService,
    ) {
        this.data = _data;
        this.editPhoneDialogForm = new FormGroup<EditPhoneForm>({});
        this.waitingCode = false;
        this.errorPhone = false;
        this.errorPhoneMessage = '';
        this.verificationCodeError = false;
        this.verificationCodeErrorMessage = '';
        this.inProgress = false;
        this.mobilePrefixes = [];

    }
    ngOnInit(): void {
        this.mobilePrefixes = mobilePrefixes.sort((a, b) => a.name.localeCompare(b.name));
        const country = this.configurationManager.getShopCountry();
        const mobilePrefixInit = this.mobilePrefixes.find(prefix => prefix.country === country)?.value;
        this.editPhoneDialogForm = new FormGroup<EditPhoneForm>({
            mobilePrefix: new FormControl(mobilePrefixInit || null, Validators.required),
            phone: new FormControl(this.data.phone?.trim() || null, Validators.required),
            verification_code: new FormControl('1234', Validators.required)
        });
    }
    get mobilePrefix(){
        return this.editPhoneDialogForm.controls.mobilePrefix;
    }
    get phone(){
        return this.editPhoneDialogForm.controls.phone;
    }
    get verification_code(){
        return this.editPhoneDialogForm.controls.verification_code;
    }

    confirm(): void {
        let payload: EditPhonePayload = {};
        this.inProgress = true;
        this.errorPhone = false;
        if (!this.waitingCode) {
            const _mobilePrefix = this.mobilePrefix?.value ? this.mobilePrefix?.value.toString() : '';
            const _phone = this.phone?.value ? this.phone?.value.toString().trim() : '';
            payload.phone = _mobilePrefix + _phone;
            this.restManagerService.post('sessions/current_user/phone', payload).then((res: any) => {
                if(res) {
                    this.waitingCode = true;
                    this.verification_code?.setValue(null);
                }
            }).catch((res: any) => {
                this.errorsLoggerService.err('POST sessions/current_user/phone', res);
                if (res && res.status === 422) {
                    console.log('res', res);
                    this.errorPhoneMessage = res.data.message;
                    this.errorPhone = true;
                    switch(res.data.type) {
                        case 'input':
                            this.errorPhoneMessage = this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_PHONE_INPUT');
                            break;
                        case 'sms':
                            this.errorPhoneMessage = this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_PHONE_SMS');
                            break;
                    }
                }
            }).finally(() => {
                this.inProgress = false;
            });
        } else {
            this.verificationCodeError = false;
            payload.temp_phone_code = this.verification_code?.value;
            this.restManagerService.post('sessions/current_user/phone/confirm', payload).then((res: any) => {
                this.dialogRef.close(res);
            }).catch((res: any) => {
                this.errorsLoggerService.err('POST sessions/current_user/phone/confirm', res);
                if(res && res.status === 422) {
                    this.verificationCodeErrorMessage = res.data.message;
                    this.verificationCodeError = true;
                    if(res.data.type === 'input') {
                        this.verificationCodeErrorMessage = this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.INVALID_VERIFICATION_CODE');
                    }
                }
            }).finally(() => {
                this.inProgress = false;
            });
        }
    }
}
