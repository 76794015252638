@if({
    keyboardIsOpen: keyboardIsOpen$ | async,
    isShowSearch: isShowSearch$ | async
}; as data){
    @if(!isMobilePortrait){<!--    !MOBILE POTRAIT - DESKTOP,TABLET,MOBILE LANDSCAPE VIEWS -->
        @if(!data.isShowSearch?.isSearch){
            <div [ngClass]="{'tw-contents': !data.isShowSearch?.isSearch}">
                <div class="tw-h-full tw-flex tw-flex-col tw-gap-2">
                    @if(isCategoryTabMode()){
                        <app-category-tab [categoriesSet]="visibleCategories"
                                    [itemsFavorite]="!!itemsFavorite.length" [isCategoryGridMode]="isCategoryGridMode()"
                                    [isCategoryTabMode]="isCategoryTabMode()" (categorySelected)="selectCategory($event)"
                                    [initCategorySelected]="selectedCategory"
                                    (goToSection)="goToSection($event)"/>
                    } @else if (isCategoryGridMode()){
                        <div class="tw-overflow-x-hidden" [style.height]="categoryBarHeight.height">
                            <app-category-grid class="tw-h-full tw-overflow-y-auto" [categoriesSet]="visibleCategories" [itemsFavorite]="!!itemsFavorite.length"
                                            [gridCols]="bigTablet ? 6 : 4" (categorySelected)="selectCategory($event)" [initCategorySelected]="selectedCategory"
                                            (goToSection)="goToSection($event)"/>
                        </div>
                    }
                @if(hasVisibleItems()) {
                    <div class="tw-overflow-auto" [style.height]="itemsBarHeight.height">
                        @switch (showcaseMode){
                            @case ('noPhotoGrid'){
                                <app-showcase-grid
                                    [customClass]="data.keyboardIsOpen?customClassOpenKeyboard:customClassClosedKeyboard"
                                    [itemsSet]="visibleItems" [displayMode]="displayMode" [showThumbnail]="false"
                                    [priceList]="priceList()" [gridCols]="itemGridColumns"
                                    [getStockStatus]="getItemStockStatus" (onItemTap)="addItemToSale($event)"
                                    (onItemPress)="showItem($event)"/>
                            }
                            @case ('photoGrid'){
                                <app-showcase-grid
                                    [customClass]="data.keyboardIsOpen?customClassOpenKeyboard:customClassClosedKeyboard"
                                    [itemsSet]="visibleItems" [displayMode]="displayMode" [showThumbnail]="true"
                                    [priceList]="priceList()" [gridCols]="itemGridColumns"
                                    [getStockStatus]="getItemStockStatus" (onItemTap)="addItemToSale($event)"
                                    (onItemPress)="showItem($event)"/>
                            }
                            @case ('list'){
                                <app-showcase-list
                                    class="tw-flex tw-flex-col tw-h-full"
                                    [customClass]="data.keyboardIsOpen?customClassOpenKeyboard:customClassClosedKeyboard"
                                    [itemsSet]="visibleItems"
                                    [priceList]="priceList()" [getStockStatus]="getItemStockStatus"
                                    (onItemTap)="addItemToSale($event)"
                                    (onItemPress)="showItem($event)"/>
                                }
                            @default {
                                <div>ERRORE DI VISUALIZZAZIONE</div>
                            }
                        }
                    </div>
                } @else {
                    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[calc(100%-162px)]">
                        <img style="width:30%" src="assets/images/items/no-items.png" alt=""/>
                        <div>{{ 'CASHREGISTER.SHOWCASE.NO_ITEM_AVAILABLE' | translate }}</div>
                    </div>
                }
                </div>
            </div>
        }
        @else if (data.isShowSearch?.isSearch){
            <div [ngClass]="{'adjust-search': data.isShowSearch?.isSearch}">
                <app-cashregister-search-items-wrapper [searchValue]="data.isShowSearch?.searchValue || ''" [items]="items" [showcaseView]="showcaseView" [visibleCategories]="visibleCategories" [selectedCategory]="selectedCategory" [priceListStr]="priceListStr()" [showUnavailable]="showUnavailable" [countNoStockAsUnavailable]="countNoStockAsUnavailable"
                                                       [getItemStockStatus]="getItemStockStatus" (showItem)="showItem($event)" (addItemToSale)="addItemToSale($event)" [getItemPriceList]="getItemPriceList"/>
            </div>
        }
    }
    @else { <!--    MOBILE POTRAIT - MOBILE POTRAIT VIEW    -->
        @if(!data.isShowSearch?.isSearch){
            <div [ngClass]="{'tw-grid tw-h-full tw-gap-1 tw-grid-cols-12 adjust': !data.isShowSearch?.isSearch}">
                <app-category-grid class="tw-col-span-4 tw-h-full tw-overflow-y-auto" [categoriesSet]="visibleCategories" [itemsFavorite]="!!itemsFavorite.length"
                                   [customClass]="data.keyboardIsOpen?customClassOpenKeyboard:customClassClosedKeyboard"
                                   [gridCols]="1" (categorySelected)="selectCategory($event)"
                                   (goToSection)="goToSection($event)"/>
                <div class="tw-block tw-col-span-8 tw-h-full">
                    <app-showcase-list class="tw-flex tw-flex-col tw-h-full"
                                       [customClass]="data.keyboardIsOpen?customClassOpenKeyboard:customClassClosedKeyboard"
                                       [showThumbnail]="false"
                                       [showUnavailableStock]="false"
                                       [truncatePriceWithDots]="false"
                                       [priceWithCurrency]="false"
                                       [itemsSet]="visibleItems"
                                       [priceList]="priceList()"
                                       [getStockStatus]="getItemStockStatus"
                                       (onItemTap)="addItemToSale($event)"
                                       (onItemPress)="showItem($event)"
                    />
                </div>
            </div>
        }
        @else if(data.isShowSearch?.isSearch){
            <div class="tw-block tw-h-full">
                <app-cashregister-search-items-wrapper [searchValue]="data.isShowSearch?.searchValue || ''" [items]="items" [showcaseView]="showcaseView" [visibleCategories]="visibleCategories" [selectedCategory]="selectedCategory" [priceListStr]="priceListStr()" [showUnavailable]="showUnavailable" [countNoStockAsUnavailable]="countNoStockAsUnavailable"
                                                       [getItemStockStatus]="getItemStockStatus" (showItem)="showItem($event)" (addItemToSale)="addItemToSale($event)" [getItemPriceList]="getItemPriceList"></app-cashregister-search-items-wrapper>
            </div>
        }
    }
}
