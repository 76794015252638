import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('addTable', ["$mdDialog", "$state", "entityManager", function($mdDialog, $state, entityManager) {

    addTableController.$inject = ["$scope", "$mdDialog", "tableData"];
    function addTableController($scope, $mdDialog, tableData) {

        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function() {
            _.merge($scope.data,_.pick($scope.size, ['shape', 'width', 'height']));
            $scope.data.covers = _.toInteger($scope.data.covers);
            $mdDialog.hide($scope.data);
        };

        $scope.table = tableData;
        $scope.covers = _.range(1,51);
        $scope.data = {
            order_type: "single",
            xpos: 0,
            ypos: 0
        };
    }

    var addTable = {
        show: function(tableData, currentRoom) {
            $mdDialog.show({
                    controller: addTableController,
                    template: require('./add-table.html'),
                    locals: {
                        tableData: tableData,
                        room: currentRoom
                    }
                }).then(function(answer) {
                    var tmpRoom = _.cloneDeep(currentRoom);
                    tmpRoom.tables.push(answer);
                    entityManager.rooms.putOneOnline(tmpRoom).then(function() {
                        $state.reload("app.tables");
                    });
                });
        }
    };

    return addTable;
}]);

