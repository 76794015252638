import {Inject, Injectable, OnDestroy} from "@angular/core";
import {
    AddressesFormGroups, CustomerDefault,
    CustomersFields,
    CustomersForm,
    CustomersFormGroups,
    CustomerValidators, PriceList
} from "../components";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {codeToCountry, countryCodesShort} from "../../../../utilities";
import {MyCustomerNameValidator, RequireMatch} from "@tilby/tilby-ui-lib/utilities";
import {CountryCode} from "../../../models";
import _ from "lodash";
import {ConfigurationManagerService, EntityManagerService} from "../../../core";
import {fiscalUtils, util} from "../../../../../app/ajs-upgraded-providers";
import { TranslateService } from "@ngx-translate/core";
import {Subject, distinctUntilChanged, takeUntil} from "rxjs";
import {
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    hideFields,
    labeling
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {headersTranslate} from "../../../shared/components";

@Injectable()
export class CustomerFormService implements OnDestroy{
    private readonly onDestroy$=new Subject<void>();
    customersForm!:CustomFormGroup<CustomersFormGroups>;
    shippingAddressRange = _.range(1, 11);
    priceLists?: PriceList[];
    paymentMethods?: any[];
    validators: CustomerValidators = {
        companyNameMaxLength: 255,
        firstNameMaxLength: 255,
        lastNameMaxLength: 255,
        taxCodeMaxLength: 16,
        sdiCodeMinLength: 6,
        sdiCodeMaxLength: 7,
        lotteryCodeMinLength: 8,
        lotteryCodeMaxLength: 8,
        phoneMaxLength: 15,
        mobileMaxLength: 255,
        emailMaxLength: 255,
        emailPecMaxLength: 255,
        socialMaxLength: 255,
        fidelityMaxLength: 255,
        discountPercMin: 0,
        discountPercMax: 100,
        streetMaxLength: 255,
        numberMaxLength: 25,
        zipMaxLength: 10,
        cityMaxLength: 255,
        provMinLength: 0,
        provMaxLength: 255,
        provMaxLengthArray: new Array(10).fill(2)
    };
    constructor(
        protected configurationManagerService: ConfigurationManagerService,
        private translate: TranslateService,
        @Inject(util) private utilService: any,
        private entityManagerService: EntityManagerService,
        @Inject(fiscalUtils) public fiscalUtilsService: any,
    ) {
        this.setupPricelists();
        this.setupPaymentMethods();

        //Set province validators
        const country = this.configurationManagerService.getPreference('general.address_country') || 'IT';
        [this.validators.provMinLength, this.validators.provMaxLength] = country.toUpperCase() === 'IT' ? [2, 2] : [0, 255];
    }

    private setupPricelists() {
        const priceListStart = this.translate.instant('ITEMS.DETAILS.PRICE_LIST_START');
        this.priceLists = [...Array(11).keys()]
            .map((id) => ({
                id,
                name: this.configurationManagerService.getPreference(`price_list_${id}_name`) || `${priceListStart} ${id}`
            }));

        this.priceLists[0] = {
            id: null,
            name: this.translate.instant("CUSTOMERS.DETAILS.NONE")
        };
    }

    private async setupPaymentMethods() {
        this.paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline();
    }

    createForm(customer: CustomersFields = new CustomerDefault(),hiddenFields?:Array<keyof CustomersFields>) {
        // Set the Country Code To CountryName
        const c: CustomersFields = Object.entries(customer).reduce((obj, [k, v]) => ({
            ...obj,
            [k]: codeToCountry(k, v) || v
        }), <CustomersFields>{});

        this.customersForm = new CustomFormGroup<CustomersFormGroups>({
            generalForm: new CustomFormGroup<CustomersForm>({
                gender: new CustomFormControl(c.gender || null,{},{...new CustomFormControlProps(),inputType:'radio',inputChoices:[{value:"U",key:'CUSTOMERS.DETAILS.FORM.UNDEFINED'},{value:"F",key:'CUSTOMERS.DETAILS.FEMALE'},{value:"M",key:'CUSTOMERS.DETAILS.MALE'}],class:'tw-flex-none tw-items-center tw-py-2.5 tw-flex-nowrap tw-gap-x-12'}),
                company_name: new CustomFormControl(c.company_name || null, {validators:Validators.maxLength(this.validators.companyNameMaxLength)},{...new CustomFormControlProps(),hint:()=>`${this.company_name?.value?.length || 0}/ ${this.validators.companyNameMaxLength}`}),
                first_name: new CustomFormControl(c.first_name || null, {validators:Validators.maxLength(this.validators.firstNameMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.first_name?.value?.length || 0}/ ${this.validators.firstNameMaxLength}`}),
                last_name: new CustomFormControl(c.last_name || null, {validators:Validators.maxLength(this.validators.lastNameMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.last_name?.value?.length || 0}/ ${this.validators.lastNameMaxLength}`}),
                birthdate: new CustomFormControl(c.birthdate || null, {validators:Validators.pattern('.*')},{...new CustomFormControlProps(),inputType:'date'}),
                tax_code: new CustomFormControl(c.tax_code || null, {validators:this.taxCodeValidators(c.company_name||'')}, {...new CustomFormControlProps(),hint:()=>!!this.company_name?.value?'':`${this.tax_code?.value?.length || 0}/ ${this.validators.taxCodeMaxLength}`} ),
                vat_code: new CustomFormControl(c.vat_code || null, {validators:Validators.pattern(this.utilService.getVatRegexp())} ),
                sdi_code: new CustomFormControl(c.sdi_code || null, {validators:[Validators.minLength(this.validators.sdiCodeMinLength), Validators.maxLength(this.validators.sdiCodeMaxLength)]}, {...new CustomFormControlProps(),hint:()=>`${this.sdi_code?.value?.length || 0}/ ${this.validators.sdiCodeMaxLength}`} ),
                lottery_code: new CustomFormControl(c.lottery_code || null, {validators:[Validators.minLength(this.validators.lotteryCodeMinLength), Validators.maxLength(this.validators.lotteryCodeMaxLength), Validators.pattern(/^[a-zA-Z0-9_]*$/)]}, {...new CustomFormControlProps(),hint:()=>`${this.lottery_code?.value?.length || 0}/ ${this.validators.lotteryCodeMaxLength}`} ),
                notes: new CustomFormControl(c.notes || null),
                country: new CustomFormControl(c.country || null, {validators:RequireMatch<CountryCode>(countryCodesShort)},{...new CustomFormControlProps(),inputType:'autocomplete',inputChoices:countryCodesShort.map(({code,name})=>({key:code,value: name}))}),
                phone: new CustomFormControl(c.phone || null, {validators:Validators.maxLength(this.validators.phoneMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.phone?.value?.length || 0}/ ${this.validators.phoneMaxLength}`} ),
                mobile: new CustomFormControl(c.mobile || null, {validators:Validators.maxLength(this.validators.mobileMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.mobile?.value?.length || 0}/ ${this.validators.mobileMaxLength}`} ),
                email: new CustomFormControl(c.email || null, {validators:[Validators.email,Validators.maxLength(this.validators.emailMaxLength)]}, {...new CustomFormControlProps(),hint:()=>`${this.email?.value?.length || 0}/ ${this.validators.emailMaxLength}`} ),
                email_pec: new CustomFormControl(c.email_pec || null, {validators:[Validators.email,Validators.maxLength(this.validators.emailPecMaxLength)]}, {...new CustomFormControlProps(),hint:()=>`${this.email_pec?.value?.length || 0}/ ${this.validators.emailPecMaxLength}`} ),
                facebook: new CustomFormControl(c.facebook || null, {validators:Validators.maxLength(this.validators.socialMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.facebook?.value?.length || 0}/ ${this.validators.socialMaxLength}`} ),
                twitter: new CustomFormControl(c.twitter || null, {validators:Validators.maxLength(this.validators.socialMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.twitter?.value?.length || 0}/ ${this.validators.socialMaxLength}`} ),
                linkedin: new CustomFormControl(c.linkedin || null, {validators:Validators.maxLength(this.validators.socialMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.linkedin?.value?.length || 0}/ ${this.validators.socialMaxLength}`} ),
                instagram: new CustomFormControl(c.instagram || null, {validators:Validators.maxLength(this.validators.socialMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.instagram?.value?.length || 0}/ ${this.validators.socialMaxLength}`} ),
                fidelity: new CustomFormControl(c.fidelity || null, {validators:Validators.maxLength(this.validators.fidelityMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.fidelity?.value?.length || 0}/ ${this.validators.fidelityMaxLength}`} ),
                discount_perc: new CustomFormControl(c.discount_perc || null, {validators:[Validators.min(this.validators.discountPercMin), Validators.max(this.validators.discountPercMax)]}, {...new CustomFormControlProps(),inputType:'number'}),
            }, {validators: [Validators.nullValidator, MyCustomerNameValidator]}),
            addressesForm: new CustomFormGroup<AddressesFormGroups & { currentShippingAddress: any }>({
                billingForm: new CustomFormGroup<CustomersForm>({
                        billing_street: new CustomFormControl(c.billing_street || null, {validators:Validators.maxLength(this.validators.streetMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.billing_street?.value?.length || 0}/ ${this.validators.streetMaxLength}`} ),
                        billing_number: new CustomFormControl(c.billing_number || null, {validators:Validators.maxLength(this.validators.numberMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.billing_number?.value?.length || 0}/ ${this.validators.numberMaxLength}`} ),
                        billing_zip: new CustomFormControl(c.billing_zip || null, {validators:Validators.maxLength(this.validators.zipMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.billing_zip?.value?.length || 0}/ ${this.validators.zipMaxLength}`} ),
                        billing_city: new CustomFormControl(c.billing_city || null, {validators:Validators.maxLength(this.validators.cityMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.billing_city?.value?.length || 0}/ ${this.validators.cityMaxLength}`} ),
                        billing_prov: new CustomFormControl(c.billing_prov || null,{validators:[Validators.minLength(this.validators.provMinLength), Validators.maxLength(this.validators.provMaxLength)]}, {...new CustomFormControlProps(),hint:()=>`${this.billing_prov?.value?.length || 0}/ ${this.validators.provMaxLength}`} ),
                        billing_country: new CustomFormControl(c.billing_country || null, {validators:[RequireMatch<CountryCode>(countryCodesShort)]},{...new CustomFormControlProps(),inputType:'autocomplete',inputChoices:countryCodesShort.map(({code,name})=>({key:code,value: name}))}),
                    },
                    {/*validators: MyProvFormValidator(this.validators)*/}
                ),
                currentShippingAddress: new CustomFormControl('1',{},{...new CustomFormControlProps(),inputType:'select',inputChoices:this.shippingAddressRange.map((i)=>({key: `${i}`,value: `${i}`}))}),
            }),
            optionsForm: new CustomFormGroup<CustomersForm & { get_source: any }>({
                payments_allowed: new CustomFormControl(!!c.payments_allowed ? JSON.parse(c.payments_allowed) : null,{}, {...new CustomFormControlProps(),inputType:'selectMultiple',inputChoices:this.paymentMethods?.map(({id,name})=>({value: `${id}`,key: name}))||[]}),
                default_pricelist: new CustomFormControl(c.default_pricelist || null,{}, {...new CustomFormControlProps(),inputType:'select',inputChoices:this.priceLists?.map(({id,name})=>({value: `${id}`,key: name}))||[]}),
                get_source: new CustomFormControl({
                    value: ((c.source_store || "") + (c.source_channel ? " (" + c.source_channel + ")" : "")) || null,
                    disabled: true
                }),
                external_id: new CustomFormControl({value: c.external_id || null, disabled: true}),
                payment_token: new CustomFormControl({value: c.payment_token || "Presente", disabled: true}),
                send_commercials: new CustomFormControl(c.send_commercials || null,{}, {...new CustomFormControlProps(),inputType:'checkbox'}),
                disable_mail_receipts: new CustomFormControl(c.disable_mail_receipts || null,{}, {...new CustomFormControlProps(),inputType:'checkbox'}),
            }),
            // avg_spent: new CustomFormControl(c.avg_spent || null, {validators:Validators.maxLength(this.validators.avg_spent)}),
            // created_at: new CustomFormControl(c.created_at || null, {validators:Validators.maxLength(this.validators.created_at)}),
            // credit: new CustomFormControl(c.credit || null, {validators:Validators.maxLength(this.validators.credit)}),
            // custom_type: new CustomFormControl(c.custom_type || null, {validators:Validators.maxLength(this.validators.custom_type)}),
            // id: new CustomFormControl(c.id || null, {validators:Validators.maxLength(this.validators.id)}),
            // image: new CustomFormControl(c.image || null, {validators:Validators.maxLength(this.validators.image)}),
            // isNew: new CustomFormControl(c.isNew || null, {validators:Validators.maxLength(this.validators.isNew)}),
            // lottery_code_last_update_at: new CustomFormControl(c.lottery_code_last_update_at || null, {validators:Validators.maxLength(this.validators.lottery_code_last_update_at)}),
            // lottery_code_last_update_by: new CustomFormControl(c.lottery_code_last_update_by || null, {validators:Validators.maxLength(this.validators.lottery_code_last_update_by)}),
            // sales_count: new CustomFormControl(c.sales_count || null, {validators:Validators.maxLength(this.validators.sales_count)}),
            // sclobypass_id: new CustomFormControl(c.sclobypass_id || null, {validators:Validators.maxLength(this.validators.sclobypass_id)}),
            // source_channel: new CustomFormControl(c.source_channel || null, {validators:Validators.maxLength(this.validators.source_channel)}),
            // source_store: new CustomFormControl(c.source_store || null, {validators:Validators.maxLength(this.validators.source_store)}),
            // tot_spent: new CustomFormControl(c.tot_spent || null, {validators:Validators.maxLength(this.validators.tot_spent)}),
            // updated_at: new CustomFormControl(c.updated_at || null, {validators:Validators.maxLength(this.validators.updated_at)}),
            // uuid: new CustomFormControl(c.uuid || null, {validators:Validators.maxLength(this.validators.uuid)}),
        });

        const createShippingAddress = (val: number): FormGroup<CustomersForm> => {
            const ss= `shipping_street${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sn = `shipping_number${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sz = `shipping_zip${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sct = `shipping_city${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sp = `shipping_prov${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sc = `shipping_country${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            let x = new FormGroup<CustomersForm>({}, {/*validators: MyProvFormValidator(this.validators, val)*/});
            x.addControl(ss, new CustomFormControl(c[ss] || null, {validators:Validators.maxLength(this.validators.streetMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.shipping_street(val)?.value?.length || 0}/ ${this.validators.streetMaxLength}`}));
            x.addControl(sn, new CustomFormControl(c[sn] || null, {validators:Validators.maxLength(this.validators.numberMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.shipping_number(val)?.value?.length || 0}/ ${this.validators.numberMaxLength}`}));
            x.addControl(sz, new CustomFormControl(c[sz] || null, {validators:Validators.maxLength(this.validators.zipMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.shipping_zip(val)?.value?.length || 0}/ ${this.validators.zipMaxLength}`}));
            x.addControl(sct, new CustomFormControl(c[sct] || null, {validators:Validators.maxLength(this.validators.cityMaxLength)}, {...new CustomFormControlProps(),hint:()=>`${this.shipping_city(val)?.value?.length || 0}/ ${this.validators.cityMaxLength}`}));
            x.addControl(sp, new CustomFormControl(c[sp] || null,{validators:[Validators.minLength(this.validators.provMinLength), Validators.maxLength(this.validators.provMaxLength)]}, {...new CustomFormControlProps(),hint:()=>`${this.shipping_prov(val)?.value?.length || 0}/ ${this.validators.provMaxLength}`}));
            x.addControl(sc, new CustomFormControl(c[sc] || null, {validators:RequireMatch<CountryCode>(countryCodesShort)}, {...new CustomFormControlProps(),inputType:'autocomplete',inputChoices:countryCodesShort.map(({code,name})=>({key:code,value: name}))}));
            return x;
        };
        _.range(1, 11).forEach(n =>
            this.customersForm?.controls.addressesForm.addControl('shippingForm' + [(n - 1 ? '_' + (n - 1) : '')] as any, createShippingAddress(n))
        );


        //CAMPI CUSTOM
        this.addControlForCustomTypes(c);

        labeling<CustomersFormGroups>(this.customersForm?.controls,headersTranslate.customers_showcase); //NON SPOSTARE LA FUNZIONE

        this.addControlForCustomFields(c);

        if(hiddenFields){ hideFields<CustomersFormGroups>(this.customersForm?.controls,hiddenFields)}
        this.listenCurrentShipAddress();

        this.company_name?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(companyNameValue=> {
            this.tax_code?.setValidators(this.taxCodeValidators(companyNameValue));
            this.tax_code?.updateValueAndValidity();
        });

        return this.customersForm;
    }

    private taxCodeValidators(companyNameValue:string) {
        return !!companyNameValue?null:[Validators.pattern(/^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/)]
    }

    private addControlForCustomTypes(c: CustomersFields){
        const custom_types = this.configurationManagerService.getPreference(`customers.custom_types`)?.split('\n');

        if(custom_types) {
            this.customersForm?.controls.generalForm.addControl('custom_type', new CustomFormControl(c.custom_type,{},{... new CustomFormControlProps(),inputChoices:custom_types.map(type=> ({key:type,value:type})),inputType:'select'}))
        }
    }

    private addControlForCustomFields(c: CustomersFields){
        _.range(1, 10).forEach(n =>{
            const custom_field = this.configurationManagerService.getSetting(`customers.custom_field_${n}`);
            if(custom_field){
                this.customersForm?.controls.generalForm.addControl(<any>('custom_' + n), new CustomFormControl(c[('custom_' + n) as keyof CustomersFields] || null,{},{... new CustomFormControlProps(),label: custom_field}))
            }
        });
    }

    private listenCurrentShipAddress() {
        this.currentShippingAddress.valueChanges.pipe(distinctUntilChanged(),takeUntil(this.onDestroy$)).subscribe((x:string)=>{
            const slot = +x-1;
            // @ts-ignore
            Object.entries<FormGroup>(this.customersForm?.controls.addressesForm.controls).forEach(([k,f]) =>{
                if('controls' in f && k!=='billingForm'){
                    // @ts-ignore
                    Object.values<CustomFormControl>(f.controls).forEach(v=>{
                        k.indexOf(`${slot}`)>-1 || (slot===0&&k==='shippingForm') ? ((v.customProps.inputType=v.customProps.inputTypeInitial!)&&v.enable()) : v.customProps.inputType='hidden';
                    });
                }


            });
        })
    }

    get generalForm() {
        return this.customersForm?.controls.generalForm;
    }

    get addressesForm() {
        return this.customersForm?.controls.addressesForm;
    }

    get optionsForm() {
        return this.customersForm?.controls.optionsForm;
    }

    get addressesBillingForm() {
        return this.addressesForm?.controls.billingForm;
    }

    addressesShippingForm(n = 1) {
        return this.addressesForm?.get('shippingForm' + (n - 1 ? '_' + (n - 1) : '')) as AddressesFormGroups['shippingForm'] | undefined;
    }

    get company_name() {
        return this.generalForm?.controls.company_name as CustomFormControl<string>;
    }

    get first_name() {
        return this.generalForm?.controls.first_name;
    }

    get last_name() {
        return this.generalForm?.controls.last_name;
    }

    get birthdate() {
        return this.generalForm?.controls.birthdate;
    }

    get tax_code() {
        return this.generalForm?.controls.tax_code;
    }

    get vat_code() {
        return this.generalForm?.controls.vat_code;
    }

    get sdi_code() {
        return this.generalForm?.controls.sdi_code;
    }

    get lottery_code() {
        return this.generalForm?.controls.lottery_code;
    }

    get country() {
        return this.generalForm?.controls.country;
    }

    get phone() {
        return this.generalForm?.controls.phone;
    }

    get mobile() {
        return this.generalForm?.controls.mobile;
    }

    get email() {
        return this.generalForm?.controls.email;
    }

    get email_pec() {
        return this.generalForm?.controls.email_pec;
    }

    get facebook() {
        return this.generalForm?.controls.facebook;
    }

    get twitter() {
        return this.generalForm?.controls.twitter;
    }

    get linkedin() {
        return this.generalForm?.controls.linkedin;
    }

    get instagram() {
        return this.generalForm?.controls.instagram;
    }

    get fidelity() {
        return this.generalForm?.controls.fidelity;
    }

    get discount_perc() {
        return this.generalForm?.controls.discount_perc;
    }

    get billing_street() {
        return this.addressesBillingForm?.controls.billing_street;
    }

    get billing_number() {
        return this.addressesBillingForm?.controls.billing_number;
    }

    get billing_zip() {
        return this.addressesBillingForm?.controls.billing_zip;
    }

    get billing_city() {
        return this.addressesBillingForm?.controls.billing_city;
    }

    get billing_prov() {
        return this.addressesBillingForm?.controls.billing_prov;
    }

    get billing_country() {
        return this.addressesBillingForm?.controls.billing_country;
    }

    get currentShippingAddress() {
        return this.addressesForm?.controls.currentShippingAddress;
    }

    shipping_street(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_street' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_number(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_number' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_zip(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_zip' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_city(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_city' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_prov(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_prov' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_country(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_country' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    ngOnDestroy(){
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
