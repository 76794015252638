<mat-grid-list class="category-grid" [ngClass]="customClass||''" [cols]="gridCols" [gutterSize]="'6px'" [rowHeight]="rowHeight">
    @if(itemsFavorite) {
        <!-- <mat-grid-tile class="tw-cursor-pointer tw-rounded-md tw-text-sm" [ngStyle]="{'background-color': _favoriteSelected ? 'black' : '#b2dfdb', 'color': _favoriteSelected ? 'white' : 'black' }" (click)="emitCategorySelection({ id: -1, name: 'favorite' })">
            {{'CASHREGISTER.SHOWCASE.FAVOURITES' | translate }}
        </mat-grid-tile> -->
        <mat-grid-tile class="tw-cursor-pointer tw-rounded-md tw-text-sm"
            [ngClass]="{
                'category-grid__selected': _favoriteSelected,
                'not-favorite-selected': !_favoriteSelected
            }"
            (click)="emitCategorySelection({ id: -1, name: 'favorite' })">
            {{'CASHREGISTER.SHOWCASE.FAVOURITES' | translate }}
        </mat-grid-tile>
    }
    @for(category of categoriesSet; track trackByFunction) {
        <!-- <mat-grid-tile [attr.id]="'cat_' + category.id" class="tw-cursor-pointer tw-rounded-md tw-text-sm" tilbyGestures (click)="emitCategorySelection(category)" [ngStyle]="{'background-color': category.id === _categorySelected?.id ? 'black' : '#' + (category.color || 'b2dfdb'), 'color': category.id === _categorySelected?.id ? 'white' : 'black' }">
            {{ category.name }}
        </mat-grid-tile> -->
        <mat-grid-tile
            [attr.id]="'cat_' + category.id"
            class="tw-cursor-pointer tw-rounded-md tw-text-sm"
            tilbyGestures
            (click)="emitCategorySelection(category)"
            [style.--category-bg-color]="'#' + (category.color || 'b2dfdb')"
            [ngClass]="{
                'category-grid__selected': category.id === _categorySelected?.id,
                'custom-bg-color': category.id !== _categorySelected?.id
            }">
            {{ category.name }}
        </mat-grid-tile>
    }
</mat-grid-list>
