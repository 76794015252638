import * as angular from 'angular';
import * as _ from 'lodash';
import { OperatorManagerService } from 'app/modules/core/service/operator-manager/operator-manager';
import { DigitalPaymentHandler, DigitalPaymentHandlerOptions } from 'src/app/shared/model/digital-payments.model';

type NexoPOSResponse = {
    cashierReceipt?: string,
    customerReceipt?: string
};

export class NexoPOS implements DigitalPaymentHandler {
    constructor(
        private $rootScope: any,
        private $translate: any,
        private checkManager: any,
        private confirmDialog: any,
        private waitDialog: any,
        private operatorManager: OperatorManagerService
    ) {
    }

    private logEvent(...message: any[]) {
		console.debug('[ NexoPOS ]', ...message);
	};

    private getPaymentTail(transactionResponse: NexoPOSResponse): string {
        return transactionResponse.customerReceipt?.split('|').join('\n') || '';
    };

    public async payment(amount: number, options: DigitalPaymentHandlerOptions) : Promise<any> {
        const currentSale = options.sale;

        const netConfig = options.paymentMethod.schema_name?.split(':') || [];
        const ipAddress = netConfig[0];
        const port = netConfig[1] || "2018";

        let transactionEnded = false;

        if (!ipAddress) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.SCHEMA_NAME_NOT_FOUND');
        }

        try {
            let transactionData = {
                amount: amount.toString(),
                ipAddress: ipAddress,
                port: port,
                saleId: Number.isInteger(currentSale.id) ? currentSale.id?.toString() : "00000",
                operatorId: this.operatorManager.getSellerData().id.toString(),
                transactionId: new Date().getTime().toString(),
            };

            let transactionPromise = new Promise((resolve, reject) => {
                window.NexoPOS.performPayment(transactionData, (result: any) => resolve(result), (error: any) => reject(error));
            });

            const paymentTimeout = Number.parseInt(this.checkManager.getPreference('cashregister.nexo_pos.payment_timeout')) || 80000;
            const transactionStart = new Date();

            const updateDialog = () => {
                if(transactionEnded) {
                    return;
                }

                const remainingTime = Math.round((transactionStart.getTime() + paymentTimeout - new Date().getTime()) / 1000);

                this.$rootScope.$broadcast("wait-dialog:update-state", {
                    cancelAction: {
                        disabled: remainingTime > 0,
                        label: (remainingTime > 0) ? this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.WAIT', { time: remainingTime }) : this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CANCEL_PAYMENT')
                    }
                });

                if(remainingTime > 0) {
                    window.setTimeout(updateDialog, 1000);
                }
            };

            window.setTimeout(updateDialog, 1000);

            let response: NexoPOSResponse = await this.waitDialog.show({ message: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.COMPLETE_ON_POS'), promise: transactionPromise });

            return {
                payment_data: JSON.stringify(response),
                tail: this.getPaymentTail(response),
                unclaimed: false
            }
        } catch (error: any) {
            if(error?.errorCode === 'PARSE') {
                try {
                    const answer = await this.confirmDialog.show(this.$translate.instant('DIGITAL_PAYMENTS.NEXO.CONFIRM_TRANSACTION'));

                    if(!answer) {
                        throw error.errorCode;
                    }
                } catch(e) {
                    throw this.$translate.instant(`DIGITAL_PAYMENTS.INGENICO_17.ERROR_DURING_PAYMENTS`, { value: error });
                }
            } else {
                this.logEvent('Error during payment: ', JSON.stringify(error));
                throw this.$translate.instant(`DIGITAL_PAYMENTS.INGENICO_17.ERROR_DURING_PAYMENTS`, { value: JSON.stringify(error) });
            }
        } finally {
            transactionEnded = true;
        }
    }
}

NexoPOS.$inject = ["$rootScope", "$translate", "checkManager", "confirmDialog", "waitDialog", "OperatorManager"];

angular.module('digitalPayments').service('NexoPOS', NexoPOS);