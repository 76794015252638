import { AfterViewInit, Component, ElementRef, Injectable, Renderer2, ViewChild, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { OnDestroyService } from "src/app/core/services/on-destroy.service";
import { ConfigurationManagerService, EntityManagerService, EnvironmentInfoService } from "src/app/core";
import { errorsLogger, itemLabelsGenerator, util } from "app/ajs-upgraded-providers";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ChainPromotions, Items, PromotionsItems, Stock } from "tilby-models";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { Subject, lastValueFrom } from "rxjs";
import { DevLogger } from "src/app/shared/dev-logger";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogProgressBarComponent, TilbyDialogTabsComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { FormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { NgFor } from "@angular/common";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { OpenDialogsService } from "../services";
import { keyBy } from "src/app/shared/utils";
import { ItemLabelsTemplate } from "src/app/shared/model/item-labels-template.model";
import { Content } from "pdfmake/interfaces";

type PromotionData = Partial<ChainPromotions & {
    _fromDate?: string;
    _toDate?: string;
    _startTime?: string;
    _endTime?: string;
    _weekDays?: string;
}>;

type ItemLabelsFE = PromotionsItems & Items & { _quantity: number }
type GeneratorPreviewData = {
    previewData: string;
    template: ItemLabelsTemplate;
}

type ItemLabelsFormValue = {
    templateId: string;
    priceListId: number;
};
export type ItemLabelsDialogData = {
    promotion: Partial<ChainPromotions>;
    items: Items[];
};

type ItemLabelsForm = CustomFormGroup<CustomForm<ItemLabelsFormValue>>;

const MAX_LABEL_LIMIT = 300;

@Component({
    selector: "item-labels-dialog",
    templateUrl: "./item-labels-dialog.component.html",
    styleUrls: ["./item-labels-dialog.component.scss"],
    standalone: true,
    imports: [
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        TilbyMagicFormComponent,
        TranslateModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        NgFor
    ],
    providers: [OnDestroyService, { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }]
})
export class ItemLabelsDialogComponent implements AfterViewInit {
    protected readonly data: ItemLabelsDialogData = inject(MAT_DIALOG_DATA);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly itemLabelsGeneratorService = inject(itemLabelsGenerator);
    private readonly translate = inject(TranslateService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly utilService = inject(util);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly renderer = inject(Renderer2);
    private readonly openDialogsService = inject(OpenDialogsService);

    @ViewChild('editorPreview', { static: true }) editorPreviewElement!: ElementRef;
    inProgress: boolean;
    protected form: ItemLabelsForm;
    protected items: Array<ItemLabelsFE> = [];
    protected stock: Record<number, Stock> = {};
    protected promotion: PromotionData;
    protected templates: ItemLabelsTemplate[] | null = null;
    protected priceLists: Array<{ name: string, id: number }> = [];
    protected createActionButtons = [
        {
            isIt: () => this.canPrint(),
            isDisable: () => !!this.form?.invalid || this.totalQuantity > MAX_LABEL_LIMIT || this.totalQuantity === 0,
            name: '',
            icon: () => 'print',
            click: (...any: any[]) => this.printLabels(),
        },
        {
            isIt: () => this.canShare(),
            isDisable: () => !!this.form?.invalid || this.totalQuantity > MAX_LABEL_LIMIT || this.totalQuantity === 0,
            name: '',
            icon: () => 'share',
            click: (...any: any[]) => this.shareLabels(),
        },
        {
            isIt: () => this.canDownload(),
            isDisable: () => !!this.form?.invalid || this.totalQuantity > MAX_LABEL_LIMIT || this.totalQuantity === 0,
            name: '',
            icon: () => 'file_download',
            click: (...any: any[]) => this.downloadLabels(),
        }
    ];
    private totalQuantity: number = 0;

    private generatorPreview$ = new Subject<GeneratorPreviewData>();

    constructor() {
        this.inProgress = false;
        this.promotion = this.data.promotion;
        this.log('_promotion', this.promotion);
        this.init(this.promotion);
        this.form = this.createForm(this.templates, this.priceLists);
    }

    ngAfterViewInit(): void {
        this.generatorPreview$.asObservable().pipe(this.onDestroyService.takeUntilDestroy).subscribe(preview => {
            this.log('preview', preview);
            try {
                const editorPreview = this.renderer.selectRootElement('#editorPreview');
                this.renderer.setProperty(editorPreview, 'innerHTML', preview.previewData);
                this.renderer.setStyle(editorPreview, 'border', '1px solid');
                this.renderer.setStyle(editorPreview, 'height', preview.template.data.height);
                this.renderer.setStyle(editorPreview, 'width', preview.template.data.width);
            } catch (e) {
                this.errorsLoggerService.err(e);
                setTimeout(() => this.generatePreview({ templateId: preview.template.id }), 0);
            }
        });
        this.subscriptions();
    }

    private init(promotion: PromotionData) {
        this.resolveEntities(promotion.items || []);
        this.priceLists = this.setupPriceLists();
        this.templates = this.setupTemplates();
    }

    private setupPriceLists() {
        //Setup pricelists
        const _priceLists: Array<{ id: number, name: string }> = [];

        Array.from({ length: 10 }).map((_, idx) => {
            let _priceListName = this.configurationManagerService.getPreference(`price_list_${idx + 1}_name`);

            if (!_priceListName) {
                _priceListName = `${this.translate.instant('PROMOTIONS.LABELS_GENERATOR.PRICE_LIST_START')} ${idx + 1}`;
            }

            _priceLists?.push({
                id: idx + 1,
                name: _priceListName as string
            });
        });

        return _priceLists;
    }

    private setupTemplates() {
        //Setup model labels
        let _templates: ItemLabelsTemplate[] | null = null;
        const promotions_label_templates = this.configurationManagerService.getPreference('promotions.label_templates') as string;
        if (promotions_label_templates) {
            try {
                _templates = JSON.parse(promotions_label_templates);
            } catch (e) {
                this.errorsLoggerService.err(e);
                _templates = null;
            }
        }

        if (_templates === null) {
            _templates = this.itemLabelsGeneratorService.getDefaultTemplates();
        }

        this.log('_templates', _templates);
        return _templates;
    }

    private async resolveEntities(promotionItems: PromotionsItems[]) {
        this.log('promotionItems', promotionItems);
        this.items = await this.resolveItems(promotionItems);
        this.log('resolveItems', this.items);
        this.items?.forEach(item => {
            item._quantity = item._quantity || 1;
            this.totalQuantity += item._quantity;
        });
        this.checkMaxTotalQuantity();
        this.stock = await this.resolveStock() || {};
    }

    private async resolveItems(promotionItems: PromotionsItems[]): Promise<ItemLabelsFE[]> {
        promotionItems = structuredClone(promotionItems);
        const itemsBySku = keyBy(promotionItems, (item) => item.item_sku);
        this.log('itemsBySku', itemsBySku);

        const itemsToSearch: Record<string, true> = {};

        for (const itemSku in itemsBySku) {
            itemsToSearch[itemSku] = true;
        }

        this.log('itemsToSearch', itemsToSearch);

        if (typeof this.data.items == 'object') {
            return (Array.isArray(this.data.items) ? this.data.items : [this.data.items]) as ItemLabelsFE[];
        }

        const resItems = await this.entityManagerService.items.fetchCollectionOffline();

        resItems.some((item) => {
            if (item.sku && itemsToSearch[item.sku]) {
                Object.assign(itemsBySku[item.sku], item);
                delete itemsToSearch[item.sku];
            }

            if (!Object.keys(itemsToSearch).length) {
                return true;
            }
        });

        return promotionItems as ItemLabelsFE[];
    }

    private async resolveStock() {
        const stocks = await this.entityManagerService.stock.fetchCollectionOffline();

        return keyBy(stocks.filter((s) => s.item_id), (s) => s.item_id);
    }

    private createForm(templates: ItemLabelsTemplate[] | null, pricelists: Array<{ name: string, id: number }>) {
        const templatesChoices = templates ? [...templates.map((template) => ({ key: template.name, value: template.id }))] : [];
        const priceListsChoices = pricelists ? [...pricelists.map((pricelist) => ({ key: pricelist.name, value: pricelist.id }))] : [];
        const bestPriceListId = pricelists?.find((pricelist) => pricelist.id === (this.promotion.base_pricelist || this.utilService.getCurrentPriceList()))?.id;
        const initPriceListValue = priceListsChoices.find((choice) => choice.value === bestPriceListId) || priceListsChoices[0];

        return new CustomFormGroup<CustomForm<ItemLabelsFormValue>>({
            templateId: new CustomFormControl<string>(templatesChoices[0].value, { validators: Validators.required }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.LABELS_GENERATOR.MODEL',
                inputType: 'select',
                inputChoices: templatesChoices,
                class: 'tw-flex-none'
            }),
            priceListId: new CustomFormControl<number>(initPriceListValue.value, { validators: Validators.required }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.LABELS_GENERATOR.PRICELIST',
                inputType: 'select',
                inputChoices: priceListsChoices,
                class: 'tw-flex-none'
            }),
        });
    }

    // CHOOSEN OBJECTS
    private getTemplate(templateId: string = this.form?.value.templateId) {
        return this.templates?.find(template => template.id === templateId);
    }
    private getPriceList(priceListId: number = this.form?.value.priceListId) {
        return this.priceLists?.find(priceList => priceList.id === priceListId);
    }
    private get templateIdControl() {
        return this.form!.controls.templateId;
    }
    private get priceListIdControl() {
        return this.form!.controls.priceListId;
    }
    private subscriptions() {
        this.templateIdControl.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe((templateId) => this.generatePreview({ templateId }));
        this.priceListIdControl.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe((priceListId) => this.generatePreview({ priceListId }));
    }

    // FUNTIONS IN DIALOGS HTML
    public getFromStock() {
        this.totalQuantity = 0;

        for (const item of this.items || []) {
            const stockQuantity = Math.floor(this.stock[item.id!]?.stock_quantity || item._quantity || 0);
            item._quantity = stockQuantity >= 0 ? stockQuantity : 0;
            this.totalQuantity += item._quantity;
        }

        this.checkMaxTotalQuantity();
    }
    public increaseQuantity(item: ItemLabelsFE) {
        item._quantity++;
        this.totalQuantity++;
        this.checkMaxTotalQuantity();
    }
    public decreaseQuantity(item: ItemLabelsFE) {
        if (item._quantity >= 1) {
            item._quantity--;
            this.totalQuantity--;
        }
    }
    public resetQuantities() {
        this.log('resetQuantities');
        this.items?.forEach(item => {
            item._quantity = 0;
        });
        this.totalQuantity = 0;
    }
    private checkMaxTotalQuantity() {
        if (this.totalQuantity >= MAX_LABEL_LIMIT) {
            this.openDialogsService.openAlertDialog({ data: { messageLabel: 'DIALOG.ITEMS_LABELS.MSG_MAX_LABEL_LIMIT' } });
        }
    }
    private log(...args: any[]) {
        DevLogger.log('ITEM_LABELS_DIALOG_COMPONENT', ...args);
    }

    //FUNCTIONS IN CREATE ANCTION BUTTONS
    private canShare() {
        return this.environmentInfoService.canShare();
    }
    private canPrint() {
        return this.environmentInfoService.canPrint();
    }
    private canDownload() {
        return this.environmentInfoService.canDownloadFiles();
    }
    protected downloadLabels() {
        this.log('downloadLabels pressed');
        const document = this.generateLabels();

        if (!document) {
            return;
        }

        document.download(this.promotion.name || 'labels');
    }
    protected printLabels() {
        this.log('printLabels pressed');
        const document = this.generateLabels();

        if (!document) {
            return;
        }

        document.getBlob((blob: Blob) => this.utilService.printFile(blob, 'application/pdf'));
    }
    protected shareLabels() {
        if (!this.canShare()) {
            return;
        }

        const document = this.generateLabels();

        if (!document) {
            return;
        }

        document.getBase64((dataUri: string) => {
            window.plugins.socialsharing.shareWithOptions({
                message: this.promotion.name, // not supported on some apps (Facebook, Instagram)
                subject: this.promotion.name, // fi. for email
                files: ["data:application/pdf;base64," + dataUri], // an array of filenames either locally or remotely
            }, () => {

            }, (error: any) => {
                this.errorsLoggerService.err(error);
            });
        });
    }
    public generatePreview(data: { templateId?: string; priceListId?: number; }) {
        const { templateId, priceListId } = data;
        const template = this.getTemplate(templateId);

        if (!template) {
            return;
        }

        const priceList = this.getPriceList(priceListId);
        const previewData = this.itemLabelsGeneratorService.generateLabel(template.data, this.promotion, priceList?.id || 1, this.items[0]);

        this.generatorPreview$.next({ previewData, template });
    }
    private generateLabels() {
        const documentContent: Content[] = [];
        const labelTemplate = this.getTemplate()?.data;
        this.log('labelTemplate', labelTemplate);

        if (!labelTemplate) {
            return;
        }

        const priceList = this.getPriceList();
        const itemsToProcess = this.items.filter((item) => item._quantity);

        const numCols = labelTemplate.per_row || 1;
        const numRows = labelTemplate.per_column || 1;

        let currentRow = 0;
        let currentCol = 0;

        itemsToProcess.forEach(item => {
            const label = this.itemLabelsGeneratorService.generateLabel(labelTemplate, this.promotion, priceList?.id || 1, item);

            Array.from({ length: item._quantity || 0 }).map((_, n) => {
                const content: Content = {
                    'svg': label,
                    absolutePosition: { x: labelTemplate.width * currentCol, y: labelTemplate.height * currentRow }
                };

                currentCol++;

                if (currentCol >= numCols) {
                    currentRow++;
                    currentCol = 0;
                }

                if (currentRow >= numRows && !(item === itemsToProcess[itemsToProcess.length - 1] && (item._quantity === n + 1))) {
                    content.pageBreak = 'after';
                    currentRow = 0;
                }

                documentContent.push(content);
            })
        });

        return pdfMake.createPdf({
            pageSize: {
                height: labelTemplate.height * numRows,
                width: labelTemplate.width * numCols
            },
            pageMargins: [0, 0, 0, 0],
            content: documentContent
        });
    };

}

@Injectable({
    providedIn: 'root'
})
export class ItemLabelsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<ItemLabelsDialogData>) {
        const _config: NonNullableConfigData<ItemLabelsDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({ height: '70vh', width: '800px' }),
            disableClose: true
        };
        return lastValueFrom(this.dialogRef.open(ItemLabelsDialogComponent, _config).afterClosed());
    }
}
