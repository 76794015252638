const customers_showcase = new Map<string, string>([
    ["avg_spent", "CUSTOMERS.SHOWCASE.AVG_SPENT"],
    ["billing_city", "CUSTOMERS.SHOWCASE.BILLING_CITY"],
    ["billing_country", "CUSTOMERS.SHOWCASE.BILLING_COUNTRY"],
    ["billing_number", "CUSTOMERS.SHOWCASE.BILLING_NUMBER"],
    ["billing_prov", "CUSTOMERS.SHOWCASE.BILLING_PROV"],
    ["billing_street", "CUSTOMERS.SHOWCASE.BILLING_STREET"],
    ["billing_zip", "CUSTOMERS.SHOWCASE.BILLING_ZIP"],
    ["birthdate", "CUSTOMERS.SHOWCASE.BIRTHDATE"],
    ["company_name", "CUSTOMERS.SHOWCASE.COMPANY_NAME"],
    ["created_at", "CUSTOMERS.SHOWCASE.CREATED_AT"],
    ["createdby_id", "CUSTOMERS.SHOWCASE.CREATEDBY_ID"],
    ["credit", "CUSTOMERS.SHOWCASE.CREDIT"],
    ["custom_1", "-s customers.custom_field_1"],
    ["custom_2", "-s customers.custom_field_2"],
    ["custom_3", "-s customers.custom_field_3"],
    ["custom_4", "-s customers.custom_field_4"],
    ["custom_5", "-s customers.custom_field_5"],
    ["custom_6", "-s customers.custom_field_6"],
    ["custom_7", "-s customers.custom_field_7"],
    ["custom_8", "-s customers.custom_field_8"],
    ["custom_9", "-s customers.custom_field_9"],
    ["custom_type", "CUSTOMERS.SHOWCASE.CUSTOM_TYPE"],
    ["default_pricelist", "CUSTOMERS.SHOWCASE.DEFAULT_PRICELIST"],
    ["disable_mail_receipts", "CUSTOMERS.SHOWCASE.DISABLE_MAIL_RECEIPTS"],
    ["discount_perc", "CUSTOMERS.SHOWCASE.DISCOUNT_PERC"],
    ["email", "CUSTOMERS.SHOWCASE.EMAIL"],
    ["email_pec", "CUSTOMERS.SHOWCASE.EMAIL_PEC"],
    ["external_id", "CUSTOMERS.SHOWCASE.EXTERNAL_ID"],
    ["facebook", "CUSTOMERS.SHOWCASE.FACEBOOK"],
    ["fidelity", "CUSTOMERS.SHOWCASE.FIDELITY"],
    ["first_name", "CUSTOMERS.SHOWCASE.FIRST_NAME"],
    ["gender", "CUSTOMERS.SHOWCASE.GENDER"],
    ["id", "CUSTOMERS.SHOWCASE.ID"],
    ["instagram", "CUSTOMERS.SHOWCASE.INSTAGRAM"],
    ["last_name", "CUSTOMERS.SHOWCASE.LAST_NAME"],
    ["linkedin", "CUSTOMERS.SHOWCASE.LINKEDIN"],
    ["lottery_code", "CUSTOMERS.SHOWCASE.LOTTERY_CODE"],
    ["mobile", "CUSTOMERS.SHOWCASE.MOBILE"],
    ["notes", "CUSTOMERS.SHOWCASE.NOTES"],
    ["payments_allowed", "CUSTOMERS.SHOWCASE.PAYMENTS_ALLOWED"],
    ["phone", "CUSTOMERS.SHOWCASE.PHONE"],
    ["sales_count", "CUSTOMERS.SHOWCASE.SALES_COUNT"],
    ["sdi_code", "CUSTOMERS.SHOWCASE.SDI_CODE"],
    ["send_commercials", "CUSTOMERS.SHOWCASE.SEND_COMMERCIALS"],
    ["shipping_city", "CUSTOMERS.SHOWCASE.SHIPPING_CITY"],
    ["shipping_country", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY"],
    ["shipping_number", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER"],
    ["shipping_prov", "CUSTOMERS.SHOWCASE.SHIPPING_PROV"],
    ["shipping_street", "CUSTOMERS.SHOWCASE.SHIPPING_STREET"],
    ["shipping_zip", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP"],
    ["shipping_city_1", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_1"],
    ["shipping_country_1", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_1"],
    ["shipping_number_1", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_1"],
    ["shipping_prov_1", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_1"],
    ["shipping_street_1", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_1"],
    ["shipping_zip_1", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_1"],
    ["shipping_city_2", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_2"],
    ["shipping_country_2", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_2"],
    ["shipping_number_2", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_2"],
    ["shipping_prov_2", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_2"],
    ["shipping_street_2", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_2"],
    ["shipping_zip_2", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_2"],
    ["shipping_city_3", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_3"],
    ["shipping_country_3", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_3"],
    ["shipping_number_3", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_3"],
    ["shipping_prov_3", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_3"],
    ["shipping_street_3", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_3"],
    ["shipping_zip_3", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_3"],
    ["shipping_city_4", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_4"],
    ["shipping_country_4", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_4"],
    ["shipping_number_4", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_4"],
    ["shipping_prov_4", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_4"],
    ["shipping_street_4", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_4"],
    ["shipping_zip_4", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_4"],
    ["shipping_city_5", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_5"],
    ["shipping_country_5", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_5"],
    ["shipping_number_5", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_5"],
    ["shipping_prov_5", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_5"],
    ["shipping_street_5", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_5"],
    ["shipping_zip_5", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_5"],
    ["shipping_city_6", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_6"],
    ["shipping_country_6", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_6"],
    ["shipping_number_6", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_6"],
    ["shipping_prov_6", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_6"],
    ["shipping_street_6", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_6"],
    ["shipping_zip_6", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_6"],
    ["shipping_city_7", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_7"],
    ["shipping_country_7", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_7"],
    ["shipping_number_7", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_7"],
    ["shipping_prov_7", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_7"],
    ["shipping_street_7", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_7"],
    ["shipping_zip_7", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_7"],
    ["shipping_city_8", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_8"],
    ["shipping_country_8", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_8"],
    ["shipping_number_8", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_8"],
    ["shipping_prov_8", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_8"],
    ["shipping_street_8", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_8"],
    ["shipping_zip_8", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_8"],
    ["shipping_city_9", "CUSTOMERS.SHOWCASE.SHIPPING_CITY_9"],
    ["shipping_country_9", "CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY_9"],
    ["shipping_number_9", "CUSTOMERS.SHOWCASE.SHIPPING_NUMBER_9"],
    ["shipping_prov_9", "CUSTOMERS.SHOWCASE.SHIPPING_PROV_9"],
    ["shipping_street_9", "CUSTOMERS.SHOWCASE.SHIPPING_STREET_9"],
    ["shipping_zip_9", "CUSTOMERS.SHOWCASE.SHIPPING_ZIP_9"],
    ["source_channel", "CUSTOMERS.SHOWCASE.SOURCE_CHANNEL"],
    ["source_store", "CUSTOMERS.SHOWCASE.SOURCE_STORE"],
    ["tax_code", "CUSTOMERS.SHOWCASE.TAX_CODE"],
    ["tot_spent", "CUSTOMERS.SHOWCASE.TOT_SPENT"],
    ["twitter", "CUSTOMERS.SHOWCASE.TWITTER"],
    ["updated_at", "CUSTOMERS.SHOWCASE.UPDATED_AT"],
    ["updatedby_id", "CUSTOMERS.SHOWCASE.UPDATEDBY_ID"],
    ["uuid", "CUSTOMERS.SHOWCASE.UUID"],
    ["vat_code", "CUSTOMERS.SHOWCASE.VAT_CODE"],
    ["Actions", "CUSTOMERS.SHOWCASE.ACTIONS.TITLE"]
]);

const customers_prepaid = new Map<string, string>([
    ["Actions", "CUSTOMERS.DETAILS.CREDIT.GRID.TYPE"],
    ['valid_from','CUSTOMERS.DETAILS.CREDIT.GRID.DATE'],
    ['amount','CUSTOMERS.DETAILS.CREDIT.GRID.AMOUNT'],
    ['credit','CUSTOMERS.DETAILS.CREDIT.GRID.BALANCE'],
    ['shop_uuid','CUSTOMERS.DETAILS.CREDIT.GRID.SHOP'],
]);

const customers_fidelity = new Map<string, string>([
    ["Actions", "CUSTOMERS.DETAILS.POINTS.GRID.TYPE"],
    ["date", "CUSTOMERS.DETAILS.POINTS.GRID.DATE"],
    ["points","CUSTOMERS.DETAILS.POINTS.GRID.POINTS"],
    ["rule_name","CUSTOMERS.DETAILS.POINTS.GRID.RULE"],
    ["shop_name", "CUSTOMERS.DETAILS.POINTS.GRID.SHOP"],
    ["campaign_name", "CUSTOMERS.DETAILS.POINTS.GRID.CAMPAIGN"],
]);

const suppliers_showcase = new Map<string, string>([
    ["name", "SUPPLIERS.SHOWCASE.COMPANY_NAME"],
    ["address_street", "SUPPLIERS.SHOWCASE.STREET_NAME"],
    ["address_number", "SUPPLIERS.SHOWCASE.STREET_NUMBER"],
    ["address_city", "SUPPLIERS.SHOWCASE.CITY"],
    ["address_zip", "SUPPLIERS.SHOWCASE.ZIP_CODE"],
    ["address_prov", "SUPPLIERS.SHOWCASE.COUNTY"],
    ["country", "SUPPLIERS.SHOWCASE.COUNTRY"],
    ["conto_vendita", "SUPPLIERS.DETAILS.CONSIGNMENT"],
    ["phone1", "SUPPLIERS.SHOWCASE.TELEPHONE_1"],
    ["phone2", "SUPPLIERS.SHOWCASE.TELEPHONE_2"],
    ["fax", "SUPPLIERS.SHOWCASE.FAX"],
    ["email1", "SUPPLIERS.SHOWCASE.EMAIL_1"],
    ["email2", "SUPPLIERS.SHOWCASE.EMAIL_2"],
    ["email_pec", "SUPPLIERS.SHOWCASE.EMAIL_PEC"],
    ["website", "SUPPLIERS.SHOWCASE.WEBSITE"],
    ["vat_code", "SUPPLIERS.SHOWCASE.VAT_CODE_NEW"],
    ["notes", "SUPPLIERS.SHOWCASE.NOTE"],
    ["id", "SUPPLIERS.SHOWCASE.ID"],
    ["created_at", "SUPPLIERS.SHOWCASE.CREATED_AT"],
    ["createdby_id", "SUPPLIERS.SHOWCASE.CREATEDBY_ID"],
    ["updated_at", "SUPPLIERS.SHOWCASE.UPDATED_AT"],
    ["updatedby_id", "SUPPLIERS.SHOWCASE.UPDATEDBY_ID"],
    ["Actions", "SUPPLIERS.SHOWCASE.ACTIONS.TITLE"]
]);

const history_sales_showcase = new Map<string, string>([
    ["closed_at", "HISTORY.SALES.SHOWCASE.CLOSED_AT"],
    ["sale_number", "HISTORY.SALES.SHOWCASE.SALE_NUMBER"],
    ["is_summary", "HISTORY.SALES.SHOWCASE.IS_SUMMARY"],
    ["summary_emitted", "HISTORY.SALES.SHOWCASE.SUMMARY_EMITTED"],
    ["final_amount", "HISTORY.SALES.SHOWCASE.FINAL_AMOUNT"],
    ["channel", "HISTORY.SALES.SHOWCASE.CHANNEL"],
    ["seller_name", "HISTORY.SALES.SHOWCASE.SELLER_NAME"],
    ["customer_name", "HISTORY.SALES.SHOWCASE.CUSTOMER_NAME"],
    ["change", "HISTORY.SALES.SHOWCASE.CHANGE"],
    ["covers", "HISTORY.SALES.SHOWCASE.COVERS"],
    ["external_id", "HISTORY.SALES.SHOWCASE.EXTERNAL_ID"],
    ["name", "HISTORY.SALES.SHOWCASE.NAME"],
    ["notes", "HISTORY.SALES.SHOWCASE.NOTES"],
    ["open_at", "HISTORY.SALES.SHOWCASE.OPEN_AT"],
    ["printed_prebills", "HISTORY.SALES.SHOWCASE.PRINTED_PREBILLS"],
    ["sale_parent_uuid", "HISTORY.SALES.SHOWCASE.SALE_PARENT_UUID"],
    ["seller_id", "HISTORY.SALES.SHOWCASE.SELLER_ID"],
    ["type_document", "HISTORY.SALES.SHOWCASE.TYPE_DOCUMENT"],
    ["printer", "HISTORY.SALES.SHOWCASE.PRINTER"],
    ["uuid", "HISTORY.SALES.SHOWCASE.UUID"],
    ["payment_type", "HISTORY.SALES.SHOWCASE.PAYMENT_TYPE"],
    ["sequential_number", "HISTORY.SALES.SHOWCASE.SEQUENTIAL_NUMBER"],
    ['room_name', "HISTORY.SALES.SHOWCASE.ROOM_NAME"],
    ['table_name', 'HISTORY.SALES.SHOWCASE.TABLE_NAME'],
    ["Actions", "HISTORY.SALES.SHOWCASE.ACTIONS.TITLE"]
]);

const giftcard_showcase = new Map<string, string>([
    ["uuid", "GIFTCARD.SHOWCASE.UUID"],
    ["type_uuid", "GIFTCARD.SHOWCASE.TYPE_UUID"],
    ["type_name", "GIFTCARD.SHOWCASE.TYPE_NAME"],
    ["type_description", "GIFTCARD.SHOWCASE.TYPE_DESCRIPTION"],
    ["currency", "GIFTCARD.SHOWCASE.CURRENCY"],
    ["notes", "GIFTCARD.SHOWCASE.NOTES"],
    ["origin_environment", "GIFTCARD.SHOWCASE.ORIGIN_ENVIRONMENT"],
    ["origin_sale", "GIFTCARD.SHOWCASE.ORIGIN_SALE"],
    ["created_by", "GIFTCARD.SHOWCASE.CREATED_BY"],
    ["created_at", "GIFTCARD.SHOWCASE.CREATED_AT"],
    ["updated_by", "GIFTCARD.SHOWCASE.UPDATED_BY"],
    ["updated_at", "GIFTCARD.SHOWCASE.UPDATED_AT"],
    ["deleted_by", "GIFTCARD.SHOWCASE.DELETED_BY"],
    ["deleted_at", "GIFTCARD.SHOWCASE.DELETED_AT"],
    ["code", "GIFTCARD.SHOWCASE.CODE"],
    ["initial_value", "GIFTCARD.SHOWCASE.INITIAL_VALUE"],
    ["last_value", "GIFTCARD.SHOWCASE.LAST_VALUE"],
    ["is_divisible", "GIFTCARD.SHOWCASE.IS_DIVISIBLE"],
    ["valid_since", "GIFTCARD.SHOWCASE.VALID_SINCE"],
    ["valid_until", "GIFTCARD.SHOWCASE.VALID_UNTIL"],
    ["active", "GIFTCARD.SHOWCASE.ACTIVE"],
    ["Actions", "GIFTCARD.SHOWCASE.ACTIONS"]
]);

const settings_um_static_token = new Map<string, string>([
    ['trunc_token','SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.TOKEN'],
    ['description','SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.DESCRIPTION'],
    ['client_id','SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.PERMISSIONS'],
    ['created_at','SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.CREATION_DATE'],
    ['username','SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.CREATED_BY'],
    ["Actions", "SETTINGS.USERS_MANAGEMENT.TABLE_STATIC_TOKEN.ACTIONS.TITLE"]
]);

const history_cash_movements = new Map<string, string>([
    ['id', "HISTORY.CASH_MOVEMENTS.ID"],
    ['description', "HISTORY.CASH_MOVEMENTS.DESCRIPTION"],
    ['movement_type', "HISTORY.CASH_MOVEMENTS.TYPE"],
    ['date', "HISTORY.CASH_MOVEMENTS.DATE"],
    ['amount', "HISTORY.CASH_MOVEMENTS.AMOUNT"],
    ['movement_account', "HISTORY.CASH_MOVEMENTS.ACCOUNT"],
    ['payment_method_name', "HISTORY.CASH_MOVEMENTS.PAYMENT_METHOD_NAME"],
    ['sale_id', "HISTORY.CASH_MOVEMENTS.SALE_ID"],
    ["Actions", "HISTORY.CASH_MOVEMENTS.ACTIONS.TITLE"]
]);

const file_importer = new Map<string, string>([
    ['status', "FILE_IMPORTER.SHOWCASE.STATUS"],
    ['name', "FILE_IMPORTER.SHOWCASE.NAME"],
    ['dateToProcess', "FILE_IMPORTER.SHOWCASE.DATE_TIME"],
    ['typeInsert', "FILE_IMPORTER.SHOWCASE.TYPE"],
    ['totalRows', "FILE_IMPORTER.SHOWCASE.ELEMENTS"],
    ['rowsProcessed', "FILE_IMPORTER.SHOWCASE.IMPORTED"],
    ['totalErrors', "FILE_IMPORTER.SHOWCASE.ERRORS"],
    ["Actions", "HISTORY.CASH_MOVEMENTS.ACTIONS.TITLE"]
])

const file_exporter = new Map<string, string>([
    ["status", "FILE_EXPORTER.SHOWCASE.STATUS"],
    ["created_at", "FILE_EXPORTER.SHOWCASE.EXECUTION"],
    ["table", "FILE_EXPORTER.SHOWCASE.TYPE"],
    ["Actions", "HISTORY.CASH_MOVEMENTS.ACTIONS.TITLE"]
])

export const headersTranslate = {
    customers_showcase,
    customers_prepaid,
    customers_fidelity,
    suppliers_showcase,
    history_sales_showcase,
    giftcard_showcase,
    settings_um_static_token,
    history_cash_movements,
    file_importer,
    file_exporter
};
