import {Component, EventEmitter, Input, Output, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralInputButton} from "@tilby/tilby-ui-lib/models";
import {CustomForm, CustomFormGroup, TilbyInputComponent} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {TilbyGeneralInputButtonComponent} from "@tilby/tilby-ui-lib/components/tilby-buttons";
import {ReactiveFormsModule} from "@angular/forms";
import {TilbyKeypadDisplayComponent} from "../tilby-keypad-display";
import {MatIcon} from "@angular/material/icon";

export type TotalFormValue = {
    cashValue:string
}
@Component({
    selector: 'app-tilby-sale-keypad',
    standalone: true,
    imports: [CommonModule, TilbyInputComponent, TilbyGeneralInputButtonComponent, ReactiveFormsModule, TilbyKeypadDisplayComponent, MatIcon],
    templateUrl: './tilby-sale-keypad.component.html',
    styleUrls: ['./tilby-sale-keypad.component.scss']
})
export class TilbySaleKeypadComponent {

    isMobile=input(false);
    @Input({required: true}) form!: CustomFormGroup<CustomForm<TotalFormValue>>;
    @Input({required: true}) buttons!: GeneralInputButton[];
    @Output() activateSideKeyboard= new EventEmitter<boolean>();

}
