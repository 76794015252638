import * as angular from 'angular';
import * as _ from 'lodash';
import * as QRCode from 'qrcode';

angular.module('cashregister').factory('requestOnlinePaymentDialog', requestOnlinePaymentDialog);

requestOnlinePaymentDialog.$inject = ["$mdDialog", "checkManager", "$timeout", "environmentInfo", "errorsLogger"];

function requestOnlinePaymentDialog($mdDialog, checkManager, $timeout, environmentInfo, errorsLogger) {
    requestOnlinePaymentDialogController.$inject = ["$scope", "$mdDialog", "$translate", "sale"];

    function requestOnlinePaymentDialogController($scope, $mdDialog, $translate, sale) {
        $scope.errorCreateQRcode = false;
        const hostname = checkManager.getPreference('ecommerce.hostname');
        const saleUUID = sale.currentSale.uuid;
        const url = `https://${hostname}.tilby.it/fs?c=${saleUUID}`;
        const shopname = checkManager.getPreference('general.shopname');

        const createQRcode = async () => {
            const optQRcode = {
                errorCorrectionLevel: 'M',
                scale: 1,
                width: 200
            };

            try {
                const canvasQRCode = await QRCode.toCanvas(url, optQRcode);
                const container = angular.element('#containerQRcode')[0];

                container.appendChild(canvasQRCode);
            } catch (err) {
                $scope.errorCreateQRcode = true;
                errorsLogger.err(err);
            }
        };

        $timeout( () => createQRcode(), 500);

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.canShare = () => environmentInfo.canShare();

        $scope.sharePayment = () => {
            if($scope.canShare()) {
                const prefixText = $translate.instant('CASHREGISTER.REQUEST_ONLINE_PAYMENT_DIALOG.PREFIX');
                const suffixText = $translate.instant('CASHREGISTER.REQUEST_ONLINE_PAYMENT_DIALOG.SUFFIX');
                const textToShare = `${prefixText} ${shopname} ${suffixText}`;
                const optShare = {
                    message: textToShare,
                    subject: textToShare,
                    url: url
                };
                window.plugins.socialsharing.shareWithOptions(
                    optShare,
                    () => {},
                    (err) => errorsLogger.err(err)
                );
            }
        };
    }

    const requestOnlinePaymentDialog = {
        show: function(sale) {
            return $mdDialog.show({
                controller: requestOnlinePaymentDialogController,
                template: require('./request-online-payment-dialog.html'),
                locals: {
                    sale: sale
                },
            });
        }
    };

    return requestOnlinePaymentDialog;
}
