import {
    Component,
    inject,
    OnInit,
    ViewChild
} from '@angular/core';
import { IServerSideDatasource } from 'ag-grid-community';
import { TilbyToolbar } from 'src/app/models';
import { headersTranslate, GridFixedButtons, GridServerSideComponent, ImplementServerSideGrid, GridClickableGiftcardButtonComponent, CliccableActionGiftcard } from 'src/app/shared/components';
import { from } from 'rxjs';
import { DataExport, GiftcardsFe, QueryPagination, RowModelType, TableData } from 'src/app/shared/model/model';
import { ConfigurationManagerService, EnvironmentInfoService, ToolbarEventsService } from 'src/app/core/services';
import { errorsLogger, restManager, $state } from 'app/ajs-upgraded-providers';
import { ModuleEvents } from 'src/app/core/services/toolbar-events/toolbar-events.model';
import { autobind } from 'src/app/models/decorators.model';
import {
    GridDownloadDialogService,
    OpenDialogsService
} from 'src/app/dialogs';
import { GridCellExportFormatterService, GridCellFormatterService } from 'src/app/shared/components/grid/utils';
import { GiftcardFormService } from '../../services';
import { GiftcardsFields } from '../giftcard-form/giftcard-form.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { sortingsMenuDisabled } from 'src/app/shared/components/grid/utils/grid-headers-sorting';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import * as moment from 'moment-timezone';
import * as QRCode from 'qrcode';
import * as JsBarcode from 'jsbarcode';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { PaginatedResponse, UserActiveSessionManagerService } from 'src/app/core';
import { GiftcardsBody } from 'tilby-models';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';


@Component({
  selector: 'app-giftcard-showcase',
  templateUrl: './giftcard-showcase.component.html',
  styleUrls: ['./giftcard-showcase.component.scss'],
  providers: [OnDestroyService]
})
export class GiftcardShowcaseComponent implements OnInit, TilbyToolbar, ImplementServerSideGrid {
    public readonly configurationManager= inject(ConfigurationManagerService);
    private readonly environmentInfoService= inject(EnvironmentInfoService);
    private readonly errorsLoggerService= inject(errorsLogger);
    private readonly giftcardFormService= inject(GiftcardFormService);
    private readonly gridCellFormatterService= inject(GridCellFormatterService);
    private readonly gridCellExportFormatterService= inject(GridCellExportFormatterService);
    private readonly userActiveSession= inject(UserActiveSessionManagerService);
    private readonly restManagerService= inject(restManager);
    private readonly onDestroyService= inject(OnDestroyService);
    public readonly openDialogsService= inject(OpenDialogsService);
    private readonly _snackBar= inject(MatSnackBar);
    private readonly tilbyCurrencyPipe= inject(TilbyCurrencyPipe);
    private readonly toolbarEventsService= inject(ToolbarEventsService);
    private readonly translate= inject(TranslateService);
    private readonly state= inject($state);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    @ViewChild('appGridComponent', {static: true}) gridRef!: GridServerSideComponent;
    rowModelType: RowModelType = 'serverSide';
    isSelected = false;
    hostName: string;
    ecommerceHomeImageUrl: string | undefined;
    isProcessing: boolean;

    private movColumnsSwitch = false;
    exportFormatterOptions?: (...any:any[])=>any;
    fixedButtons: GridFixedButtons = {
        checkbox: { visible: true, lockPosition: 'left' },
        customButton: { visible: true, cellRenderer: GridClickableGiftcardButtonComponent, lockPosition: 'left' }
    };

    constructor() {
        this.hostName = this.configurationManager.getPreference('general.shopname') || this.userActiveSession.getSession()?.shop.name!;
        this.ecommerceHomeImageUrl = this.configurationManager.getPreference('ecommerce.home.image_url') || '';
        this.isProcessing = false;
     }

    readonly query: Readonly<QueryPagination> = {
        pagination: true,
        per_page: 30,
        page: 0,
    };

    datasource: IServerSideDatasource = {
        getRows: (params) => this.gridRef.getRowsForServersideDatasource(params, ['giftcards', this.query],
            (a, b?) => from(this.restManagerService.getList(a, b) as Promise<PaginatedResponse<GiftcardsBody>>), {
            sort_type: 'desc',
            sort_col: null
        })
    };

    tableData: TableData[] = [{
        rowData$: null,
        dataType: new GiftcardsFe(),
        headersTranslate: headersTranslate.giftcard_showcase,
        columnsFormatter: this.gridCellFormatterService.giftcards,
        filtersMenuDisabled: true,
        sortingsMenuDisabled: sortingsMenuDisabled.giftcard,
    }];

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.isSelected,
                    name: 'edit',
                    icon: () => 'edit',
                    click: _ => this.toolbarEventsService.events.next({edit: true})
                },
            ],
            panelButtons: [
                {
                    isIt: () => true,
                    name: 'columns_panel',
                    icon: () => 'view_week',
                    label: () => 'TOPBAR.ACTIONS.COLUMN_SELECTED',
                    click: _ => this.toolbarEventsService.events.next({openToolPanel: 'columns'})
                },
                {
                    isIt: () => true,
                    name: 'movable_columns',
                    icon: () => 'drag_indicator',
                    label: () => this.movColumnsSwitch ? 'TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT': 'TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT',
                    click: _ => this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = !this.movColumnsSwitch})
                },
                {
                    isIt: () => true,
                    name: 'save_columns_position',
                    icon: () => 'save',
                    label: () => 'TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE',
                    click: _ => this.toolbarEventsService.events.next({save: true})
                },
                {
                    isIt: () => true,
                    name: 'open_giftcard_types',
                    icon: () => '',
                    label: () => 'TOPBAR.ACTIONS.GIFTCARD_TYPES',
                    click: _ => this.toolbarEventsService.events.next({type: 'open_giftcard_types'})
                },
                {
                    isIt: () => true,
                    name: 'export',
                    icon: () => 'download',
                    label: () => 'TOPBAR.ACTIONS.EXPORT',
                    click: _ => this.toolbarEventsService.events.next({export: true})
                },
            ]
        });
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {
        if ('search' in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ('openToolPanel' in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
        else if ('export' in event) await this.gridDownloadDialogService.openDialog('giftcards',{data: this.tableData.map(tableData => ({...tableData,sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length}))}).then(res=>!!res&&this.onExport(res));
        else if ('edit' in event) {
            await this.openDialogsService.openEditRowsDialog<Partial<GiftcardsFields>>({data: {form:this.giftcardFormService.createForm()}}).then(res=>!!res&&this.gridRef.updateItems(res));
        }
        else if ('type' in event && event.type === 'movColumnsSwitch') this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        else if ('type' in event && event.type === 'open_giftcard_types') this.state.go("app.new.giftcard.types-panel");
        else if ('save' in event) await this.gridRef.openDialogSaveColumnsPreference();
    }

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.exportFormatterOptions = this.gridCellExportFormatterService.giftcardsFormatter;
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('GIFTCARD');
    }

    actions({action, data}: CliccableActionGiftcard) {
        switch (action) {
            case 'deactivate': {
                this.deactivateGiftcard(data);
                break;
            }
            case 'share-image': {
                this.shareImageGiftcard(data);
                break;
            }
            case 'download-pdf': {
                this.downloadPdfGiftcard(data);
                break;
            }
        }
    }

    shareImageGiftcard(giftcard: GiftcardsFields) {
        setTimeout(() => {
            this.isProcessing = true;
            this.shareImage(giftcard)
            .catch(err => this.errorsLoggerService.err(err))
            .finally(() => this.isProcessing = false);
        }, 1000);
    }

    async shareImage(giftcard: GiftcardsFields) {
        this.isProcessing = true;
        await this.generateImageToShare(giftcard).then(imageOut => {
            if (this.canShare()) {
                const shareLabel = 'Giftcard ' + this.hostName;
                const optShare = {
                    message: shareLabel,
                    subject: shareLabel,
                    files: [imageOut]
                };
                window.plugins.socialsharing.shareWithOptions(
                    optShare,
                    () => { },
                    (err: any) => {
                        this.errorsLoggerService.err(err);
                        this._snackBar.open(
                            this.translate.instant('GIFTCARD.SHOWCASE.ERROR_SHARE_IMAGE'), undefined,
                            { duration: 5000, horizontalPosition: 'left', verticalPosition: 'top' }
                        );
                    }
                );
            }
        });
    }

    async generateImageToShare(giftcard: GiftcardsFields) {
        const _validSince = giftcard.valid_since ? moment(giftcard.valid_since, 'YYYY-MM-DD').format('l') : '-';
        const _validUntil = giftcard.valid_until ? moment(giftcard.valid_until, 'YYYY-MM-DD').format('l') : '-';
        let giftcards_types;
        try {
            giftcards_types = await this.restManagerService.getOne('giftcards_types', null, { uuid: giftcard.type_uuid });
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
        const giftcardTypeName = giftcard.type_name ? giftcard.type_name : giftcards_types[0]?.name;
        const giftcardInitialValue = this.tilbyCurrencyPipe.transform(giftcard.initial_value);
        let giftcardValidity;
        if (giftcard.valid_until) {
            giftcardValidity = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_2', { valid_since: _validSince, valid_until: _validUntil });
        } else {
            giftcardValidity = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_1', { valid_since: _validSince });
        }
        const giftcardDivisible = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_DIVISIBLE');
        const giftcardNoDivisible_1 = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_1');
        const giftcardNoDivisible_2 = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_2');
        const giftcardRegulation = this.translate.instant('GIFTCARD.SHOWCASE.PDF.REGULATION');

        const divContainerCanvas = document.querySelector('.container-canvas') as HTMLElement;
        const getCanvas = document.querySelector('#imgToShare') as HTMLCanvasElement;
        if (getCanvas) {
            divContainerCanvas.removeChild(getCanvas);
        }
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.setAttribute('id', 'imgToShare');
        canvas.setAttribute('width', '800');
        canvas.setAttribute('height', '800');
        canvas.style.visibility = 'hidden';
        divContainerCanvas.appendChild(canvas);

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.direction = 'ltr';
        if (this.ecommerceHomeImageUrl) {
            let logo = new Image(200);
            logo.crossOrigin = 'anonymous';
            logo.onload = () => {
                ctx.drawImage(logo, 300, 20, 200, 200);
            };
            logo.src = this.ecommerceHomeImageUrl;
        } else {
            ctx.font = 'bold 40px monospace';
            ctx.fillText(this.hostName, 400, 135);
        }

        ctx.font = 'bold 44px monospace';
        ctx.fillText(giftcardTypeName, 400, 270);
        ctx.font = 'bold 72px monospace';
        ctx.fillText(giftcardInitialValue, 400, 350);
        ctx.font = 'normal 22px monospace';
        ctx.fillText(giftcardValidity, 400, 430);
        if (giftcard.is_divisible) {
            ctx.fillText(giftcardDivisible, 400, 480);
            ctx.fillText(giftcardRegulation, 400, 580);
        } else {
            ctx.fillText(giftcardNoDivisible_1, 400, 480);
            ctx.fillText(giftcardNoDivisible_2, 400, 530);
            ctx.fillText(giftcardRegulation, 400, 580);
        }

        const optQRcode: QRCode.QRCodeRenderersOptions = {
            errorCorrectionLevel: 'M',
            scale: 1,
            width: 200
        };
        return QRCode.toCanvas(giftcard.code, optQRcode).then((canvasQR: HTMLCanvasElement) => {
            let qrCodeImage = new Image();
            qrCodeImage.src = canvasQR.toDataURL('image/png');
            const fnLoad = qrCodeImage.onload = () => {
                ctx.drawImage(qrCodeImage, 300, 600, 200, 200);
                return new Promise((resolve, _reject) => {
                    setTimeout(() => {
                        resolve(canvas.toDataURL('image/png'));
                    }, 1000);
                });
            };
            return fnLoad();
        }).catch(err => {
            this.errorsLoggerService.err(err);
        });
    }

    downloadPdfGiftcard(giftcard: GiftcardsFields) {
        setTimeout(() => {
            this.generatePDF(giftcard)
            .catch(err => {
                this.errorsLoggerService.err(err);
                this._snackBar.open(
                    this.translate.instant('GIFTCARD.SHOWCASE.ERROR_CREATE_PDF'),
                    undefined,
                    { duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom' }
                );
            })
            .finally(() => this.isProcessing = false);
        }, 1000);
    }

    async generatePDF(giftcard: GiftcardsFields) {
        this.isProcessing = true;
        const _validSince = giftcard.valid_since ? moment(giftcard.valid_since, 'YYYY-MM-DD').format('l') : "-";
        const _validUntil = giftcard.valid_until ? moment(giftcard.valid_until, 'YYYY-MM-DD').format('l') : "-";
        let giftcards_types;
        try {
            giftcards_types = await this.restManagerService.getOne('giftcards_types', null, { uuid: giftcard.type_uuid });
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
        const giftcardTypeName = giftcard.type_name ? giftcard.type_name : giftcards_types[0]?.name;
        const giftcardValue = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALUE');
        const giftcardInitialValue = this.tilbyCurrencyPipe.transform(giftcard.initial_value);
        let giftcardValidity;
        if(giftcard.valid_until) {
            giftcardValidity = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_2', {valid_since: _validSince, valid_until: _validUntil});
        } else {
            giftcardValidity = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_VALIDITY_1', {valid_since: _validSince});
        }
        const giftcardDivisible = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_DIVISIBLE');
        const giftcardNoDivisible_1 = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_1');
        const giftcardNoDivisible_2 = this.translate.instant('GIFTCARD.SHOWCASE.PDF.TEXT_NO_DIVISIBLE_2');
        const giftcardRegulation = Array.isArray(giftcards_types) ? `${giftcards_types[0].description}` : this.translate.instant('GIFTCARD.SHOWCASE.PDF.REGULATION');
        const giftcardOriginEnvUUID = `${giftcard.origin_environment} - ${giftcard.uuid}`;
        const barcode = this.getBarcode(giftcard);
        const pdfDocument: TDocumentDefinitions = {
            content: [
                {
                    text: `\n\n${giftcardTypeName}\n\n\n`,
                    style: 'header'
                },
                {
                    text: `${giftcardValue}\n\n`,
                    style: 'subHeader'
                },
                {
                    text: `${giftcardInitialValue}\n\n`,
                    style: 'bigText'
                },
                {
                    text: `${giftcardValidity}\n\n`,
                    style: 'subHeader'
                },
                {
                    text: giftcard.is_divisible ? `${giftcardDivisible}\n\n` : `${giftcardNoDivisible_1}\n${giftcardNoDivisible_2}\n\n`,
                    style: 'normalText'
                },
                {
                    text: `\n\n${giftcardRegulation}\n\n\n`,
                    style: 'normalText'
                },
                {
                    image: barcode
                }
            ],
            footer: [
                {
                    text: `${giftcardOriginEnvUUID}`,
                    style: 'smallText'
                }
            ],
            styles: {
                header: {
                    fontSize: 28,
                    bold: true
                },
                subHeader: {
                    fontSize: 24,
                    bold: false
                },
                bigText: {
                    fontSize: 56,
                    bold: true
                },
                normalText: {
                    fontSize: 18,
                    bold: false
                },
                smallText: {
                    fontSize: 12,
                    bold: false
                }
            },
            defaultStyle: {
                font: 'Monospace',
                fontSize: 10,
                alignment: 'center'
            }
        };
        const filename =`${this.hostName}-${giftcard.uuid}.pdf`;
        pdfMake.createPdf(pdfDocument).download(filename);
    }

    getBarcode = (giftcard: GiftcardsFields) => {
        let barcodeElement = document.createElement('CANVAS') as HTMLCanvasElement;
        const currentBarcode = giftcard.code;
        const barcodeSettings = { format: "EAN13", fontSize: 14 };
        try {
            JsBarcode(barcodeElement, currentBarcode, barcodeSettings);
        } catch(err) {
            this.errorsLoggerService.err(err);
        }

        return barcodeElement.toDataURL();
    }

    canShare() {
        return this.environmentInfoService.canShare();
    }

    async deactivateGiftcard(data: GiftcardsFields) {
        const result = await this.restManagerService.deleteOne('giftcards', data.uuid);
        if(result?.message === 'ok') {
            this.gridRef?.refreshGrid();
            this._snackBar.open(
                this.translate.instant('GIFTCARD.SHOWCASE.SUCCESS_DEACTIVATE'), undefined,
                {duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom'}
            );
        }
    }

    @autobind
    onExport(dataExport: DataExport,tableToCall?:string) {
        this.gridRef?.onBtnExport(dataExport,tableToCall);
    }

    @autobind
    updateItems(updatedRows: any[]) {
        (updatedRows as GiftcardsFields[]).forEach(updatedRow => this.updateGiftcard(updatedRow));
    }

    async updateGiftcard(updatedRow: GiftcardsFields) {
        if(!updatedRow?.deleted_at) {
            let data: any = {
                is_divisible: updatedRow.is_divisible
            };

            if (updatedRow.valid_until) {
                data.valid_until = updatedRow.valid_until;
            }

            const result = await this.restManagerService.put('giftcards/' + updatedRow.uuid, null, data);
            if(result && result?.uuid === updatedRow?.uuid) {
                this._snackBar.open(
                    this.translate.instant('GIFTCARD.SHOWCASE.SUCCESS_MODIFY'),
                    undefined,
                    { duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom' }
                );
            }
        } else {
            this._snackBar.open(
                this.translate.instant('GIFTCARD.SHOWCASE.ERROR_MODIFY', {code: updatedRow.code}),
                undefined,
                { duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom' }
            );
        }
    }

}
