<mat-button-toggle-group [hideSingleSelectionIndicator]="true" appearance="standard" class="tw-h-[6rem] tw-w-[96%] tw-m-2">
    <mat-button-toggle
        *ngFor="let btn of btnsHeader; let i = index"
        [matMenuTriggerFor]="i==1? menu:null"
        id="header"
        (click)="btn.callback()"
        [value]="btn.label"
        [ngClass]="{'tw-w-full custom-interline': i===0}"
        [disabled]="i===0"
        class="frame-button-toggle tw-flex tw-items-center tw-whitespace-pre-line"
        style="font-size:13px; padding-bottom: 0px !important;"
    >
        <span *ngIf="btn.icon() && btn.show" class="material-symbols-rounded tw-pl-5 tw-pr-5">
            {{btn.icon()}}
        </span>
        <div *ngIf="btn.label" class="tw-text-black tw-p-6">
            <span class="tw-text-[0.9rem] tw-font-bold">{{data.sale.name}}</span>
            <div class="tw-text-[0.6rem]">{{data.sale.closed_at | tilbyDate:'dd/MM/yyyy HH:mm'}}</div>
        </div>
    </mat-button-toggle>
</mat-button-toggle-group>
<mat-menu #menu="matMenu">
    <button mat-menu-item  (click)="openSaleJsonDetail()">
        <mat-icon>data_object</mat-icon>
        <span>Open Json</span>
    </button>
    <button mat-menu-item (click)="openEditHistoryDetail()">
        <mat-icon>list</mat-icon>
        <span translate="HISTORY.TRANSACTIONS_VIEWER.TITLE"></span>
    </button>
</mat-menu>

<div [ngClass]="{ 'tw-flex-col': isLoadingReviews, 'tw-flex tw-justify-between': !isLoadingReviews }" class="tw-w-[96%] tw-mb-2">
    @if(!isLoadingReviews) {        
        @if(reviewsResponse.length === 0) {
            <div class="" translate="HISTORY.SALE.NO_REVIEW"></div>
        } @else if(reviewsResponse[0].rating){
            <div class="tw-flex tw-h-5">
                <div *ngFor="let rating of fullStarRating; let i = index">
                    <mat-icon>star</mat-icon>
                </div>
                <div *ngFor="let rating of emptyStarRating; let i = index">
                    <mat-icon>star_border</mat-icon>
                </div>
            </div>
        }
    }
    <div>
        <div class="tw-text-right tw-font-bold">TOT: {{ data.sale.final_amount || 0 | tilbyCurrency }}</div>
        <div *ngIf="data.sale.sale_customer || data.sale.customer_tax_code"
        class="tw-text-right tw-text-base">{{getCustomerCaption()}}</div>
        <!--        <div class="historymodule-header-total">tot: {{ sale.final_amount || '0' | sclCurrency: sale.$currency : sale.$digits }}</div>-->
    </div>
</div>


<app-history-sale-detail-toolbar-content
    class="custom-content tw-h-full tw-overflow-y-auto tw-w-[96%]"
    [sale]="data.sale"
></app-history-sale-detail-toolbar-content>

<mat-button-toggle-group [hideSingleSelectionIndicator]="true" appearance="standard" class="tw-h-[8rem] tw-w-[96%] tw-m-2">
    <mat-button-toggle *ngFor="let btn of btnsFooter"
                       (click)="btn.callback()"
                       [value]="btn.label"
                       class="tw-truncate tw-w-full">
        <div class="tw-flex tw-flex-col">
            <span class="material-symbols-rounded tw-mt-4">
                {{btn.icon()}}
            </span>
            <span class="tw-ml-2 tw-mr-2 tw-truncate tw-w-[calc(100% -1rem)]">{{(btn.label|translate).replace('\<\/br\>', ' ')}}</span>
        </div>
    </mat-button-toggle>
</mat-button-toggle-group>
