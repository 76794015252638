import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('fidelity').controller('FidelityDetailsCtrl', FidelityDetailsCtrl);

FidelityDetailsCtrl.$inject = ["$scope", "$rootScope", "$state", "$translate", "toast", "restManager", "entityManager", "user", "customer", "fidelityAddShowMovement"];

function FidelityDetailsCtrl($scope, $rootScope, $state, $translate, toast, restManager, entityManager, user, customer, fidelityAddShowMovement) {
    var currentShop = null;

    Object.assign($scope, {
        currentPage: 0,
        loadingInProgress: false,
        loggedShopName: "",
        Math: Math,
        movementsData: {},
        movementsPerPage: 50,
        points: [],
        topbar_context: {
            campaignPoints: {},
            campaigns: [],
            chainShops: [],
            dateFilter: {
                from: moment().subtract('2', 'years').toDate(),
                to: moment().toDate()
            },
            mode: "view",
            selectedCampaign: null,
            selectedShop: null,
            view: "details"
        },
        user: user
    });

    $scope.hasNoResults = function() {
        // when result of get is 404
        if ($scope.movementsData === false) {
            return true;
        }
        return $scope.movementsData.length === 0;
    };

    $scope.decrementPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage--;
            getMovementsPage($scope.movementsPerPage, $scope.currentPage);
        }
    };

    $scope.incrementPage = function() {
        if ($scope.currentPage < $scope.movementsData.pages - 1) {
            $scope.currentPage++;
            getMovementsPage($scope.movementsPerPage, $scope.currentPage);
        }
    };

    $scope.goToFirstPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage = 0;
            getMovementsPage($scope.movementsPerPage, $scope.currentPage);
        }
    };

    $scope.goToLastPage = function() {
        if ($scope.currentPage < $scope.movementsData.pages - 1) {
            $scope.currentPage = $scope.movementsData.pages - 1;
            getMovementsPage($scope.movementsPerPage, $scope.currentPage);
        }
    };

    $scope.topbar_context.changeQueryStatus = function() {
        $scope.currentPage = 0;
        getMovementsPage($scope.movementsPerPage, 0);
        $scope.topbar_context.campaignPoints = _.find($scope.points, { campaign_id: $scope.topbar_context.selectedCampaign.id });
    };

    const parseMovement = (movement) => {
        Object.assign(movement, {
            $type: movement.points >= 0 ? 'load' : 'unload',
            $shopName: movement.shop_name || movement.db_name
        });
    };

    const getMovementsPage = async (movementsPerPage, pageNumber) => {
        $rootScope.$broadcast("loader:changeStatus", "fidelityMovementsLoader", { enabled: true });
        $scope.loadingInProgress = true;

        $scope.movementsData.results = [];

        let query = {
            pagination: true,
            per_page: movementsPerPage,
            page: pageNumber,
            orderby_desc: 'date',
            date_since: moment($scope.topbar_context.dateFilter.from).startOf('day').toISOString(),
            date_max: moment($scope.topbar_context.dateFilter.to).endOf('day').toISOString(),
            campaign_id: $scope.topbar_context.selectedCampaign ? $scope.topbar_context.selectedCampaign.id : null,
            db_name: $scope.topbar_context.selectedShop ? $scope.topbar_context.selectedShop.db_name : null,
            fidelity: customer.fidelity
        };

        $scope.barcodeInput = null;

        try {
            let result = await restManager.getList("fidelities_movements", query);
            $scope.movementsData = result;
            
            _.forEach(result.results, parseMovement);
        } catch(err) {}

        $rootScope.$broadcast("loader:changeStatus", "fidelityMovementsLoader", { enabled: false });
        $scope.loadingInProgress = false;
    };


    if (!customer) {
        $state.go("app.fidelity.search");
    } else {
        restManager.getList("chain_shops", { pagination: false }).then(function(chainShops) {
            $scope.topbar_context.chainShops = chainShops;
            currentShop = _.find(chainShops, { db_name: user.shop.name });
            if(currentShop) {
                $scope.loggedShopName = currentShop.shop_name || currentShop.db_name;
            }
            entityManager.campaigns.fetchCollectionOnline({}).then(function(campaigns) {
                $scope.topbar_context.campaigns = campaigns;
                $scope.topbar_context.selectedCampaign = _.find(campaigns, { isValid: true });
            }).finally(function() {
                entityManager.fidelitiesPoints.fetchCollectionOnline({ fidelity: customer.fidelity }).then(function(fidelitiesPoints) {
                    $scope.points = fidelitiesPoints;
                    $scope.topbar_context.campaignPoints = _.find(fidelitiesPoints, { campaign_id: $scope.topbar_context.selectedCampaign.id });
                    getMovementsPage($scope.movementsPerPage, $scope.pageNumber);
                });
            });
        });

        $scope.customer = customer;
        $scope.topbar_context.customer = customer;
    }

    $scope.showMovement = function(movement) {
        fidelityAddShowMovement.show(movement);
    };

    $scope.addMovement = function() {
        fidelityAddShowMovement.show(null, { campaigns: $scope.topbar_context.campaigns, selectedCampaign: $scope.topbar_context.selectedCampaign, customer: $scope.customer, shop: currentShop }).then(function(movement) {
            restManager.post("fidelities_movements", movement).then(function(result) {
                $scope.topbar_context.changeQueryStatus();
            });
        });
    };

    $scope.$on("fidelity-updated", function(event, fidelityPoints) {
        if (fidelityPoints.fidelity === $scope.customer.fidelity) {
            var totalToUpdate = _.find($scope.points, { id: fidelityPoints.id });
            if (totalToUpdate) {
                _.assign(totalToUpdate, fidelityPoints);
            } else {
                $scope.points.push(fidelityPoints);
                if (fidelityPoints.campaign_id === $scope.topbar_context.selectedCampaign.id) {
                    $scope.topbar_context.campaignPoints = fidelityPoints;
                }
            }
            toast.show({message: $translate.instant('FIDELITY.DETAILS.NEW_MOVEMENTS'), actions: [{text: $translate.instant('FIDELITY.DETAILS.RELOAD'), action: 'reload', highlight: true}], hideDelay: false}).then(function(answer) {
                if(answer === 'reload') {
                    $scope.topbar_context.changeQueryStatus();
                }
            });
        }
    });

    $scope.$on("storage-updated:customers", async (event, data) => {
        if(data.action === "UPDATED" && data.id === customer.id) {
            const newCustomer = await entityManager.customers.fetchOneOffline(customer.id);

            if(newCustomer) {
                angular.copy(newCustomer, customer);
            }
        }
    });
}
