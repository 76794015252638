import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {ComponentPortal, ComponentType, PortalModule} from "@angular/cdk/portal";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {BehaviorSubject, Subject} from "rxjs";
import {DialogsComponents, PortalConfigData} from "../services";
import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import {FormControlStatus} from "@angular/forms";
import {mobileCheck} from "@tilby/tilby-ui-lib/utilities";
import {MatTabsModule} from "@angular/material/tabs";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TilbyDialogToolbarComponent} from "@tilby/tilby-ui-lib/components/tilby-dialog";

type TabIndex = number;

interface PrivateMagicPortalDialogData<Component extends DialogsComponents>{
    titleLabel: string;
    titleParams?: { [key: string]: string };
    component: ComponentType<Component>,
    confirmDisabled?:()=>boolean,
    confirmLabel?:string,
    hideConfirm?:boolean,
    hideCancel?:boolean,
    hideToolbar?:boolean,
    classContent?:string,
    actionButtons?:GeneralInputButton[],
    bottomActionButtons?:GeneralInputButton[],
    dontCloseDialogOnConfirm$?: BehaviorSubject<boolean>;
    tabs?:{titles:string[],changed$:BehaviorSubject<TabIndex>}
    showProgressBar$?:BehaviorSubject<boolean>;
}
export type MagicPortalDialogData<Component extends DialogsComponents> = PrivateMagicPortalDialogData<Component>
type Data<BaseData extends Record<string, any>|unknown,Component extends DialogsComponents> = PortalConfigData<BaseData, Component>;

@Component({
    selector: 'app-magic-portal-dialog',
    templateUrl: './magic-portal-dialog.component.html',
    styleUrls: ['./magic-portal-dialog.component.scss'],
    imports: [
        TranslateModule,
        MatDialogModule,
        MatButtonModule,
        PortalModule,
        NgIf,
        TilbyDialogToolbarComponent,
        AsyncPipe,
        NgForOf,
        NgClass,
        MatTabsModule,
        MatProgressBarModule
    ],
    standalone: true
})
export class MagicPortalDialogComponent<DialogComponent extends DialogsComponents>{
    private readonly dialogRef = inject(MatDialogRef<MagicPortalDialogComponent<DialogComponent>>);
    protected readonly data: Data<unknown,DialogComponent>= inject(MAT_DIALOG_DATA);
    protected readonly mobileCheck = mobileCheck();

    protected selectedPortal=this.data.component?new ComponentPortal(this.data.component):undefined;
    private static confirmSubject$ = new Subject<Data<any,DialogsComponents>>();
    public static confirm$ = MagicPortalDialogComponent.confirmSubject$.asObservable();
    public static confirmDisabledSubject$ = new Subject<boolean>();
    protected confirmDisabled$ = MagicPortalDialogComponent.confirmDisabledSubject$.asObservable();

    constructor(){
        MagicPortalDialogComponent.confirmDisabledSubject$.next(false);
    }

    protected confirm(){
      if(this.data.dontCloseDialogOnConfirm$?.getValue()) MagicPortalDialogComponent.confirmSubject$.next(this.data);
      else this.dialogRef.close(this.data);
    }

    public static setStatusValidity(status:FormControlStatus){
        MagicPortalDialogComponent.confirmDisabledSubject$.next(status!=='VALID')
    }


}
