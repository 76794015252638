<h1 mat-dialog-title class="tw-mb-2" translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.TITLE</h1>
<div mat-dialog-content style="max-height: 75vh">

    <div *ngIf="!isDeliverectEnabled">
        <p class="tw-mb-8 tw-mt-8" translate>
            SETTINGS.LINK_TO_DELIVERECT_DIALOG.CONTENT_TEXT_1
        </p>
        <p class="tw-mb-8" translate>
            SETTINGS.LINK_TO_DELIVERECT_DIALOG.CONTENT_TEXT_2
        </p>
        <form [formGroup]="linkToDeliverectDialogForm">
            <div class="tw-flex">
                <mat-form-field class="tw-w-full">
                    <mat-label translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.ACCOUNT_ID</mat-label>
                    <input matInput id="account_id" formControlName="account_id" type="text" class="form-control" required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="tw-w-full">
                    <mat-label translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.LOCATION_ID</mat-label>
                    <input matInput id="location_id" formControlName="location_id" type="text" class="form-control" required>
                </mat-form-field>
            </div>

            <div *ngIf="errorShow">
                <p class="tw-text-center tw-text-red-700">{{errorMessage}}</p>
            </div>
        </form>
    </div>


    <div *ngIf="isDeliverectEnabled">
        <p translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.CONTENT_TEXT_3</p>
        <mat-form-field class="tw-w-full">
            <mat-label translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.SYNC_PRODUCTS</mat-label>
            <input matInput type="text" class="form-control" [value]="this.syncProducts" readonly>
        </mat-form-field>
        <mat-form-field class="tw-w-full">
            <mat-label translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.WEBHOOK_ORDERS</mat-label>
            <input matInput type="text" class="form-control" [value]="this.webhookOrders" readonly>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="tw-justify-end">
        <button mat-button (click)="dialogRef.close()"><span translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.CLOSE</span></button>
        <button *ngIf="!isDeliverectEnabled"mat-raised-button color="primary" (click)="proceed()" cdkFocusInitial [disabled]="isFormInvalid()"><span translate>SETTINGS.LINK_TO_DELIVERECT_DIALOG.BTN_ACTIVE</span></button>
    </div>

</div>
