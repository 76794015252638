import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('printers').factory('RchMFDriver', ["$q", "$window", "fiscalUtils", "rchUtils", "util", "errorsLogger", function($q, $window, fiscalUtils, rchUtils, util, errorsLogger) {
    /**
     *  Tilby RCH Driver for Print!F and mini Print!F
     *  
     *  Usage:
     *  RchMFDriver.setup() and after call public methods:
     *  
     *  - autoConfigurePrinter
     *  - printReceiptInvoice
     *  - printFiscalReceipt
     *  - printInvoice
     *  - printCourtesyReceipt
     *  - printNonFiscal
     *  - printOrder
     *  - openCashDrawer
     *  - dailyClosing
     *  - dailyRead
     *  - deposit
     *  - withdrawal
     *  - readDgfe (read/print)
     *  - readDgfeBetween (read/print)
     *  - printFiscalMemory
     *  - printFiscalMemoryBetween
     *  
     *  - printFreeNonFiscal
     *  - printSummaryInvoice
     *  
     *  Specific for this printer
     *  - configureLanBT
     *  - discoverPairedBT
     */
    
    var scope = {};

    /**     
     *  getDate
     */
    function getDate() {
        var date = new Date();
        var str = date.getDate() + "/" + parseInt(date.getMonth() + 1) + "/" + date.getFullYear();
        return str;
    }

    var sendCommandsWS = rchUtils.sendCommands;

    /**
     *  sendCommandsBT
     */
    function sendCommandsBT(printer, commands, successFunction, errorFunction) {
        // Check if phonegap bt plugin is enabled            
        if (window.$window.bluetoothSerial !== undefined) {
            // Check if bluetooth is enabled
            $window.bluetoothSerial.isEnabled(function() {
                errorsLogger.debug("Bluetooth is enabled!");

                // Check if connected
                $window.bluetoothSerial.isConnected(
                    // Success
                    function() {
                        errorsLogger.debug("Already connected.");
                        // Invia scontrino
                        asyncForEach(commands, sendFiscalData, function(result) {
                            successFunction(result);
                        }, function(error) {
                            errorFunction(error);
                        });
                    },
                    // Failure
                    function() {
                        if (printer.mac_address_bt) {
                            errorsLogger.debug("Connecting...");
                            $window.bluetoothSerial.connectInsecure(printer.mac_address_bt,
                                // onconnect
                                function() {
                                    errorsLogger.debug("Connected.");
                                    // Invia scontrino
                                    asyncForEach(commands, sendFiscalData, function(result) {
                                        successFunction(result);
                                    }, function(error) {
                                        errorFunction(error);
                                    });
                                },
                                // ondisconnect
                                function(reason) {
                                    disconnectBluetooth();
                                    if (reason !== "OK") {
                                        errorsLogger.debug(reason);
                                        errorFunction("BT_CONNECTION_ERROR");
                                    }
                                }
                            );
                        } else {
                            errorsLogger.debug("BT MAC not found");
                            errorFunction("BT_MAC_MISSING");
                        }
                    });
                },
                function() {
                    errorsLogger.debug("ERROR: Bluetooth is not enabled");
                    errorFunction("BT_DISABLED");
                }
            );
        } else {
            errorFunction("PHONEGAP_ERROR");
        }
    }


    /**     
     *  disconnectBluetooth
     */
    function disconnectBluetooth(event) {
        if (event) {
            event.preventDefault();
        }

        errorsLogger.debug("Disconnecting...");
        //$window.bluetoothSerial.disconnect(this.ondisconnect);
        $window.bluetoothSerial.disconnect(function() {
            errorsLogger.debug("Disconnect OK");
        },
        function() {
            errorsLogger.debug("Disconnect failure.");
        });
    }


    /**     
     *  sendFiscalData
     */
    function sendFiscalData(cmd, success) {
        errorsLogger.debug("COMANDO:" + cmd);
        var stx = String.fromCharCode(2);
        var etx = String.fromCharCode(3);
        var adds = "01";
        var prot = "N";

        // ====== sendData =====
        function sendData(msg, success) {
            errorsLogger.debug("Sending message:" + msg);
            $window.bluetoothSerial.write(msg, success);
            return false;
        }

        // === stringToBytes ===
        function stringToBytes(str) {
            var ch, st, re = [];
            for (var i = 0; i < str.length; i++) {
                ch = str.charCodeAt(i); // get char 
                st = []; // set up "stack"
                do {
                    st.push(ch & 0xFF); // push byte to stack
                    ch = ch >> 8; // shift value down by 1 byte
                }
                while (ch);
                // add stack contents to result
                // done because chars have "wrong" endianness
                re = re.concat(st.reverse());
            }
            // return an array of bytes
            return re;
        }

        // ===== CalcolaBcc =====
        function calcolaBcc(string) {
            var ByteSend = stringToBytes(string);

            var i;
            var bcc = 0;
            var bInizio;
            var bCalcola;

            bInizio = 2;
            bCalcola = false;

            for (i = 0; i <= (ByteSend.length); i++) {
                if (ByteSend[i] === bInizio) {
                    bCalcola = true;
                }
                if (ByteSend[i] === String.fromCharCode(3)) {
                    break;
                }
                if (bCalcola) {
                    bcc = bcc ^ ByteSend[i];
                }
            }
            return bcc.toString(16);
        }

        if (_.isUndefined(scope.id_pack)) {
            scope.id_pack = 0;
        }

        var length = cmd.length;

        var zeroadds;

        //errorsLogger.debug((""+length+"").length);
        for (zeroadds = ("" + length + "").length; zeroadds < 3; zeroadds++) {
            length = "0" + length + "";
        }

        var packet = stx + adds + length + prot + cmd + scope.id_pack;

        var chk = calcolaBcc(packet);

        for (zeroadds = ("" + chk + "").length; zeroadds < 2; zeroadds++) {
            chk = "0" + chk + "";
        }

        //errorsLogger.debug(chk);

        packet += chk + etx;
        //alert(packet);

        //errorsLogger.debug("Sending: " + cmd + " ...");
        sendData(packet, success);
        scope.id_pack++;
        if (scope.id_pack > 9) {
            scope.id_pack = 0;
        }

        return false;
    }


    /**     
     *  asyncForEach
     */
    function asyncForEach(array, fn, successFunction, errorFunction) {
        array = array.slice(0);
        function processOne() {

            var item = array.shift();
            errorsLogger.debug("ITEM:" + item);
            setTimeout(function() {
                fn(item, function(result) {
                    errorsLogger.debug("Sent: [" + result + "]");

                    var intvl = setInterval(function() {
                        $window.bluetoothSerial.readUntil(String.fromCharCode(3), function(buffer) {
                            if (buffer) {
                                errorsLogger.debug("Buffer: [" + buffer + "]");
                                // Status commands
                                if (buffer.indexOf("Ns") >= 0) {
                                    // ex. 01013Ns000000RE0022922      
                                    //callback(buffer.substring(7, buffer.length));
                                    successFunction(buffer.substring(7, buffer.length));
                                }

                                // errore
                                else if (buffer.indexOf("NE") >= 0) {
                                    if (buffer.indexOf("G") >= 0) {
                                        errorFunction("RCH.ERROR_" + buffer);
                                    }
                                    if (buffer.indexOf("S") >= 0) {
                                        errorFunction("COVER_OPEN");
                                    }
                                    if (buffer.indexOf("P") >= 0) {
                                        errorFunction("PAPER_END");
                                    }

                                    errorsLogger.debug('Errore: [' + buffer + ']');
                                } else {
                                    if (array.length > 0) {
                                        setTimeout(processOne(), 0);
                                    } else {
                                        successFunction("OK");
                                    }
                                }
                                clearInterval(intvl);
                            }
                        }, function() {
                            errorsLogger.debug("Error!");
                        }); // Read
                    }, 0);
                });
            }, 0);
        }

        if (array.length > 0) {
            setTimeout(processOne(), 0);
        } else {
            successFunction("OK");
        }
    }

    /** 
     * @description saleToInvoiceCommands
     * NB: dynamic footer is not available
     *
     * @param sale (sale.sale_customer is required)
     *
     * @param options from printInvoice
     */
    function saleToInvoiceCommands(sale, options) {
        errorsLogger.debug(sale);
        errorsLogger.debug(options);
        var commands = ['=K', '=C1'];

        // Sale Name
        var printName = true;
        if (scope.options.print_name === false) {
            printName = false;
        }
        if (printName && sale.name) {
            commands.push("=\"/(# " + sale.name + " ##)");
        }

        // Customer (required!)        
        if ((sale.sale_customer.first_name && sale.sale_customer.last_name) || (sale.sale_customer.company_name)) {
            commands.push("=A/$1/(" + util.getCustomerCaption(sale.sale_customer) + ")");
            commands.push("=A/$2/(" + sale.sale_customer.billing_street + " " + sale.sale_customer.billing_number + ")");
            commands.push("=A/$3/(" + sale.sale_customer.billing_zip + " " + sale.sale_customer.billing_city + " " + sale.sale_customer.billing_prov + ")");

            if (sale.sale_customer.tax_code) {
                commands.push("=A/$4/(C.F. " + sale.sale_customer.tax_code + ")");
            } else {
                commands.push("=A/$4/()");
            }
            if (sale.sale_customer.vat_code) {
                commands.push("=A/$5/(P.IVA " + sale.sale_customer.vat_code + ")");
            } else {
                commands.push("=A/$5/()");
            }
        }

        commands.push("=F/*4");

        var printDetails = (options.print_details === false) ? false : true;

        // Sale items
        if (printDetails) {
            for(let saleItem of fiscalUtils.extractSaleItems(sale)) {
                Array.prototype.push.apply(commands, rchUtils.saleItemToCommands(saleItem, {
                    printNotes: scope.options.print_notes
                }));
            }
        } else {
            var departmentTotals = fiscalUtils.extractDepartments(sale);
            for (var i in departmentTotals) {
                var cmd = "=R" + departmentTotals[i].id + "/$" + Math.round(departmentTotals[i].amount * 100);
                commands.push(cmd);
            }
        }

        // Print subtotal            
        commands.push("=S");

        // Apply discount/surcharges on subtotal
        if (sale.final_amount >= 0 && printDetails) {
            const salePcCommands = rchUtils.applyPriceChanges(sale.price_changes, fiscalUtils.roundDecimals(sale.amount));

            if(!_.isEmpty(salePcCommands)) {
                Array.prototype.push.apply(commands, salePcCommands);
                commands.push("=S");
            }
        }


        // Payments
        _(rchUtils.extractPayments(sale, scope.options.resources)).forEach(function(payment) {
            commands.push([
                '=T' + _.toString(payment.payment_type),
                '$' + _.toString(Math.round(payment.amount * 100)),
                '(' + _.truncate(payment.method_name, { length: 20, omission: '' }) + ')'
            ]);
        });

        commands.push("=c");

        /* Payment tail */
        if (options.tail) {
            var tailCommands = [];

            // Add Payment Tail 
            var tail = options.tail;
            var tails = tail.split("\n");
            if (tails.length) {
                tailCommands.push("=o");
            }
            for (var t in tails) {
                tailCommands.push("=\"/( " + rchUtils.cleanUpSpecialChars(tails[t]) + ")");
            }
            if (tails.length) {
                tailCommands.push("=o");
            }
            _.forEach(tailCommands, function(t) {
                commands.push(t);
            });
            // Another copy for the sign
            if (tail.indexOf('FIRMA') > -1) {
                _.forEach(tailCommands, function(t) {
                    commands.push(t);
                });
            }
        }

        return commands;
    }

    /**     
     *  saleToReceiptCommands
     */
    function saleToReceiptCommands(sale, fiscal, options) {
        errorsLogger.debug(sale);
        var commands = ['=K', '=C1'];

        // Sale Name
        var printName = (scope.options.print_name === false) ? false : true;

        if(printName) {
            for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                commands.push(`="/(# ${row} ##)`);
            }
        }

        // Preconto
        if (!fiscal) {
            commands.push("=F");
        }

        var printDetails = (options.print_details === false && sale.final_amount >= 0) ? false : true;

        // Sale items
        if (printDetails) {
            for(let saleItem of fiscalUtils.extractSaleItems(sale)) {
                Array.prototype.push.apply(commands, rchUtils.saleItemToCommands(saleItem, {
                    printNotes: scope.options.print_notes
                }));
            }
        }
        // Hide details
        else {
            var departmentTotals = fiscalUtils.extractDepartments(sale);
            for (var i in departmentTotals) {
                var cmd = "=R" + departmentTotals[i].id + "/$" + Math.round(departmentTotals[i].amount * 100);
                commands.push(cmd);
            }
        }

        // Print subtotal            
        commands.push("=S");

        // Apply discount/surcharges on subtotal
        if (sale.final_amount >= 0 && printDetails) {
            const salePcCommands = rchUtils.applyPriceChanges(sale.price_changes, fiscalUtils.roundDecimals(sale.amount));

            if(!_.isEmpty(salePcCommands)) {
                Array.prototype.push.apply(commands, salePcCommands);
                commands.push("=S");
            }
        }

        // Payments
        if (fiscal && sale.final_amount >= 0) {
            _(rchUtils.extractPayments(sale, scope.options.resources)).forEach(function(payment) {
                commands.push([
                    '=T' + _.toString(payment.payment_type),
                    '$' + _.toString(Math.round(payment.amount * 100)),
                    '(' + _.truncate(payment.method_name, { length: 20, omission: '' }) + ')'
                ]);
            });
        } else {
            // If 'preconto'
            commands.push("=T");
        }

        // Barcode
        var printBarcodeId = true;

        if (scope.options.print_barcode_id === false) {
            printBarcodeId = false;
        }
        if (printBarcodeId && fiscal && _.isInteger(sale.id)) {
            commands.push("=\"/$4/(" + sale.id.toString() + ")");
        }

        // Ticket change
        var ticketChange = (scope.options.ticket_change === false) ? false : true;

        if (ticketChange && sale.change > 0 && sale.change_type === 'ticket') {
            var ticketChangeAmount = util.round(sale.change);

            commands.push("=\"/(################ RESTO TICKET #################)");
            commands.push("=\"/(                                              #)");
            commands.push("=\"/( QUESTO COUPON VALE:               " + ticketChangeAmount + " EURO #)");
            commands.push("=\"/(                                              #)");
            commands.push("=\"/( Presenta alla cassa prima del pagamento questo)");
            commands.push("=\"/( coupon per avere riconosciuto il credito.    #)");
            commands.push("=\"/( Valido 30 giorni dalla data di emissione.    #)");
            commands.push("=\"/( Non convertibile in denaro.                  #)");
            commands.push("=\"/(                                              #)");
            commands.push("=\"/(###############################################)");
            commands.push("=\"/$4/(" + (1212000000000 + Math.round(ticketChangeAmount * 100)) + ")");
        }

        // Add Tail (tail for this sale + general tail)
        var tail = options.tail || '';

        if (scope.options.tail) {
            tail += "\n" + scope.options.tail;
        }

        if (tail) {
            //var tails = tail.match(/.{1,24}/g);
            var tails = tail.split("\n");
            //var tails = tail.match(/.{1,24}/g);
            _.forEach(tails, function(tRow) {
                commands.push("=\"/( " + rchUtils.cleanUpSpecialChars(tRow) + ")");
            });
        }

        var printCustomerDetail = (scope.options.print_customer_detail === false) ? false : true;

        // CF
        if (printCustomerDetail && fiscal && ((sale.sale_customer && sale.sale_customer.tax_code) || sale.customer_tax_code)) {

            var taxCode = sale.customer_tax_code;
            if (sale.sale_customer && sale.sale_customer.tax_code) {
                taxCode = sale.sale_customer.tax_code;
            }

            commands.push("=\"/?C/(" + taxCode.toUpperCase() + ")");
        }

        // Customer
        if (printCustomerDetail && sale.sale_customer) {
            var saleCustomer = sale.sale_customer;

            var addCustomerLine = function(line) {
                commands.push('=\"/(' + rchUtils.cleanUpSpecialChars(line) + ')');
            };

            var customerInfo = fiscalUtils.getCustomerInfo(saleCustomer);

            _.forEach(customerInfo, addCustomerLine);
        }

        // Print Seller
        var printSeller = (scope.options.print_seller === false) ? false : true;

        if (printSeller && sale.seller_name) {
            var sellerPrefix = scope.options.seller_prefix || "Ti ha servito";
            commands.push("=\"/( " + sellerPrefix + " " + sale.seller_name.trim().split(" ")[0] + ")");
        }

        commands.push("=c");

        // Payment tail */
        if (options.tail && options.tail.indexOf('FIRMA') > -1) {

            // Add Payment Tail 
            var pTail = options.tail.split("\n");
            if (pTail.length) {
                commands.push("=o");

                _.forEach(pTail, function(tRow) {
                    commands.push("=\"/( " + rchUtils.cleanUpSpecialChars(tRow) + ")");
                });

                commands.push("=o");
            }
        }

        return commands;
    }

    /**  
     * saletoFreeInvoice (F6)
     */
    function saleToFreeInvoice(sale, options, refReceipt) {
        var commands = [];

        var printerColumns = 48;
        if (scope.printer.columns) {
            printerColumns = scope.printer.columns;
        }

        function border(car) {
            var str = '';
            if (!car) {
                car = "=";
            }
            for (var i = 0; i < printerColumns; i++) {
                str += car;
            }
            return str;
        }

        // Send CL
        commands.push("=K");

        // Add customer
        var customer = sale.sale_customer;

        commands.push("=A/$1/(" + util.getCustomerCaption(customer) + ")");
        commands.push("=A/$2/(" + (customer.billing_street || "") + " " + (customer.billing_number || "") + ")");
        commands.push("=A/$3/(" + (customer.billing_zip || "") + " " + (customer.billing_city || "") + ")");

        if (customer.tax_code) {
            commands.push("=A/$4/(C.F. " + customer.tax_code + ")");
        } else {
            commands.push("=A/$4/()");
        }

        if (customer.vat_code) {
            commands.push("=A/$5/(P.IVA " + customer.vat_code + ")");
        } else {
            commands.push("=A/$5/()");
        }

        commands.push("=\"/()");
        commands.push("=F/*6");

        commands.push("=\"/(Fattura Riepilogativa)/*2");
        if (refReceipt) {
            commands.push("=\"/(Rif.Scontrino " + refReceipt + " del " + getDate() + ")");
        }

        commands.push("=\"/()");

        // 48 car
        //commands.push("=\"/(Qta Articolo         IVA Esc. PrezzoAAAAAAAAAAAB)");

        if (printerColumns === 48) {
            commands.push("=\"/(Q.TA' / Descrizione             IVA      Importo)");
        } else if (printerColumns === 32) {
            commands.push("=\"/(Q.TA' / Desc.  IVA       Importo)");
        } else {
            commands.push("=\"/(Q.TA' / Descrizione             IVA      Importo)");
        }


        var printDetails = (options.print_details === false) ? false : true;

        if (printDetails) {
            var head = (printerColumns === 32) ? "Rif.Sc. " : "Rif.Scontrino ";

            var groupedSaleItems = _.groupBy(sale.sale_items, function(si) {
                if (si.reference_sequential_number && si.reference_date) {
                    return head + si.reference_sequential_number + " del " + moment(si.reference_date).format('DD/MM/YYYY');
                } else {
                    return null;
                }
            });

            var firstGroup = true;
            var lastGroup;
            if (groupedSaleItems['null']) {
                lastGroup = _.cloneDeep(groupedSaleItems['null']);
            }
            if (lastGroup) {
                delete groupedSaleItems['null'];
                groupedSaleItems['null'] = lastGroup;
            }

            _.forEach(groupedSaleItems, function(group, ref) {
                if (ref !== 'null') {
                    if (firstGroup) {
                        firstGroup = false;
                    }

                    // Calculate reference receipt total
                    errorsLogger.debug(ref);
                    errorsLogger.debug(group);
                    errorsLogger.debug("=======");

                    var total = _.reduce(group, function(total, si) {
                        //return Math.round((total + Math.round(si.final_price * si.quantity * 100) / 100 * 100)) / 100;
                        return si.final_price * si.quantity + total;
                    }, 0);

                    var data = ref;
                    var b = printerColumns - ref.length - total.toFixed(2).length;
                    for (var i = 0; i < b; i++) {
                        data += " ";
                    }
                    data += total.toFixed(2).replace(".", ",");
                    commands.push("=\"/(" + data + ")");
                } else {
                    if (!firstGroup) {
                        commands.push("=\"/()");
                    }
                    commands.push("=\"/(" + border("-") + ")");
                }

                _.forEach(group, function(si) {
                    if (ref === 'null') {
                        // Quantity, name/department, vat, rowPrice
                        var row = si.quantity + "x " + rchUtils.cleanUpSpecialChars(si.name || si.department_name);

                        if (printerColumns === 32) {
                            row = row.substring(0, 16);
                        } else {
                            row = row.substring(0, 29);
                        }

                        var data = "=\"/(" + row;

                        var rowPrice = (Math.round(si.price * si.quantity * 100) / 100); //.toFixed(2).replace(".",",");

                        var blanks = printerColumns - 16 - parseInt(row.length); // - parseInt(price.length) - parseInt(netprice.length);

                        for (var l = 0; l < blanks; l++) {
                            data = data + " ";
                        }

                        if (si.vat_perc < 10) {
                            data += " ";
                        }
                        data += si.vat_perc + "      ";

                        if(rowPrice < 10) {
                            data += + "    ";
                        } else if(rowPrice >= 10 && rowPrice < 100) {
                            data += + "   ";
                        } else if(rowPrice >= 100 && rowPrice < 1000) {
                            data += + "  ";
                        } else if(rowPrice >= 1000 && rowPrice < 10000) {
                            data += + " ";
                        }

                        data = data + rowPrice.toFixed(2).replace(".", ",") + ")";
                        commands.push(data);

                        // Notes
                        if (si.notes) {
                            var notesLines = si.notes.split("\n");
                            _.forEach(notesLines, function(nl) {
                                if (nl.trim()) {
                                    commands.push("=\"/(# " + rchUtils.cleanUpSpecialChars(nl.trim()) + " )");
                                }
                            });
                        }

                        // Price Changes
                        _.forEach(si.price_changes, function(pc) {
                            var pcString;

                            if (_.includes(['discount_perc', 'discount_fix'], pc.type)) {
                                pcString = pc.description || 'SCONTO';
                            } else if (_.includes(['surcharge_perc', 'surcharge_fix'], pc.type)) {
                                pcString = pc.description || 'MAGGIORAZIONE';
                            } else {
                                return;
                            }

                            var blanks = printerColumns - 2 - parseInt(pcString.length) - parseInt(pc.value.toFixed(2).length);

                            for (var l = 0; l < blanks; l++) {
                                pcString += " ";
                            }

                            if (pc.type.indexOf("_perc") === -1) {
                                pcString += "  ";
                            }

                            pcString += pc.value.toFixed(2).replace(".", ",");

                            if (pc.type.indexOf("_perc") > -1) {
                                pcString += " %";
                            }

                            commands.push("=\"/(" + pcString + ")");
                        });
                    }
                });
            });
        }
        // Hide details
        else {
            var departmentTotals = fiscalUtils.extractDepartments(sale);
            commands.push("=\"/(" + border('-') + ")");

            _.forEach(departmentTotals, function(depTotal) {
                // Quantity, name/department, vat, rowPrice
                var data = "=\"/(";
                var content = depTotal.name;
                content = content.substring(0, 33);
                var rowPrice = (Math.round(depTotal.amount * 100) / 100); //.toFixed(2).replace(".",",");
    
                var blanks = printerColumns - parseInt(content.length) - rowPrice.toFixed(2).length;
    
                for (var l = 0; l < blanks; l++) {
                    content = content + " ";
                }
    
                content = content + rowPrice.toFixed(2).replace(".", ",");
                commands.push(data + content + ")");
            });
        }

        var blanks;
        var l;

        if (printDetails) {
            commands.push("=\"/(" + border('-') + ")");
            var subTot = 'SUB-T0TALE';
            blanks = printerColumns - 10 - parseInt(sale.amount.toFixed(2).length);
            for ( l = 0; l < blanks; l++) {
                subTot += " ";
            }
            subTot += sale.amount.toFixed(2).replace(".", ",");

            commands.push("=\"/(" + subTot + ")");

            // Price Changes on subtotal
            _.forEach(sale.price_changes, function(pc) {
                var pcString;

                if (_.includes(['discount_perc', 'discount_fix'], pc.type)) {
                    pcString = pc.description || 'SCONTO';
                } else if (_.includes(['surcharge_perc', 'surcharge_fix'], pc.type)) {
                    pcString = pc.description || 'MAGGIORAZIONE';
                } else {
                    return;
                }

                var blanks = printerColumns - 2 - parseInt(pcString.length) - parseInt(pc.value.toFixed(2).length);

                for (var l = 0; l < blanks; l++) {
                    pcString += " ";
                }

                if (pc.type.indexOf("_perc") === -1) {
                    pcString += "  ";
                }
                pcString += pc.value.toFixed(2).replace(".", ",");

                if (pc.type.indexOf("_perc") > -1) {
                    pcString += " %";
                }

                commands.push("=\"/(" + pcString + ")");
            });

        }

        commands.push("=\"/(" + border('-') + ")");
        var tot = 'T0TALE EURO';
        blanks = (printerColumns - 11) - parseInt(sale.final_amount.toFixed(2).length);

        for (l = 0; l < blanks; l++) {
            tot += " ";
        }
        tot += sale.final_amount.toFixed(2).replace(".", ",");

        commands.push("=\"/(" + tot + ")");


        // Add IVA ESC.
        var net = sale.final_net_amount.toFixed(2).toString().replace(".", ",");

        var netString = "T0TALE IVA ESC. EURO";

        blanks = printerColumns - parseInt(netString.length) - parseInt(net.toString().length);

        for (l = 0; l < blanks; l++) {
            netString = netString + " ";
        }

        netString = netString + net;

        commands.push("=\"/(" + netString + ")");

        var aggregateTaxes = fiscalUtils.extractTax(sale);
        commands.push("=\"/()");

        // Add Taxable
		_.forEach(aggregateTaxes, function(aggrTax, taxVal) {
			if (aggrTax.taxable > 0) {
                var data = "=\"/(";
				data += "IMPONIBILE IVA " + _.toInteger(taxVal) + "%";
				data += _.padStart(fiscalUtils.decimalToString(aggrTax.taxable), printerColumns - data.length, ' ') + ')';
				commands.push(data);
			}
		});

		// Add IVA Aggregates
		_.forEach(aggregateTaxes, function(aggrTax, taxVal) {
			if (aggrTax.taxable  > 0) {
                var data = "=\"/(";
				data += "IVA " + _.toInteger(taxVal) + "%";
				data += _.padStart(fiscalUtils.decimalToString(aggrTax.tax), printerColumns - data.length, ' ') + ')';
				commands.push(data);
			}
		});

        commands.push("=c");

        return commands;
    }

    /**     
     *  lastReceipt
     */
    function lastReceipt(printer, successFunction, errorFunction) {
        var checkXml = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C453</cmd></Service>";

        var successLastReceipt = function(response) {
            var receipt;
            if ($(response) && $(response).eq(2) && $(response).eq(2).text()) {
                receipt = $(response).eq(2).text();
            }

            // SET REG MODE
            setModReg(printer).then(function(status) {
                // SEND RECEIPT BACK
                successFunction(receipt);
            }, function(error) {
                // Retry
                errorsLogger.debug("Retry SETMOD REG...");
                setTimeout(function() {
                    setModReg(printer).then(function(status) {
                        // SEND RECEIPT BACK
                        successFunction(receipt);
                    }, errorFunction);
                }, 2000);
            });
        };

        var getLastReceipt = function(status) {
            sendCommandsWS(printer, checkXml).then(successLastReceipt, function(error) {
                errorsLogger.debug("Retry GET RECEIPT...");
                setTimeout(function() {
                    sendCommandsWS(printer, checkXml).then(successLastReceipt, errorFunction);
                }, 2000);
            });
        };

        setModX(printer).then(getLastReceipt, function(error) {
            errorsLogger.debug("Retry SET MODE X...");
            setTimeout(function() {
                setModX(printer).then(getLastReceipt, errorFunction);
            }, 2000);
        });
    }

    /**
     * @description getConfiguration     
     */
    function getConfiguration(printer, successFunction, errorFunction) {
        // SET X MODE
        setModX(printer).then(function(status) {
            var checkXml = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>&lt;&lt;/?C</cmd></Service>";
            // SEND LAST RECEIPT COMMAND
            sendCommandsWS(printer, checkXml).then(function(configuration) {
                // SET REG MODE
                setModReg(printer).then(function(status) {
                    // SEND CONFIGURATION BACK
                    successFunction(configuration);
                }, errorFunction);
            }, errorFunction);
        }, errorFunction);
    }


    /**     
     *  lastReceiptNumberBT
     */
    function lastReceiptNumberBT(printer, callback) {
        var commands = [];
        commands.push("<</?s");
        sendCommandsBT(printer, commands, function(result) {
            var lastReceiptNumber = parseInt(result.substring(10, 14));
            callback(lastReceiptNumber || undefined);
        });
    }

    /**     
     *  lastReceiptNumber
     */
    function lastReceiptNumber(printer, successFunction, errorFunction) {
        return lastReceipt(printer, function(receipt) {
            successFunction(parseReceiptNumber(receipt));
        }, function(error) {
            errorFunction(error);
        });
    }

    /**     
     *  parseReceiptNumber
     */
    function parseReceiptNumber(receipt) {
        var res = receipt.match(/NR.\d+/g);
        var number;
        if (!_.isNull(res)) {
            number = parseInt(res.toString().replace("NR.", ""));
            if (_.isNaN(number)) {
                return undefined;
            } else {
                return number;
            }

        } else {
            res = receipt.match(/SF.\d+/g);
            if (!_.isNull(res)) {
                number = parseInt(res.toString().replace("SF.", ""));
                if (_.isNaN(number)) {
                    return undefined;
                } else {
                    return number;
                }
            } else {
                return (undefined);
            }

        }
    }

    /**
     *  parseInvoiceNumber
     */
    function parseInvoiceNumber(receipt) {
        var res = receipt.match(/FATTURA N.\s+\d+/g);
        if (!_.isNull(res)) {
            var number = parseInt(res.toString().replace("FATTURA N. ", ""));
            if (isNaN(number)) {
                return undefined;
            } else {
                return number;
            }
        } else {
            return (undefined);
        }
    }

    /**     
     *  parseCreditNoteNumber
     */
    function parseCreditNoteNumber(receipt) {
        var res = receipt.match(/NOTA DI CREDITO NR.\d+/g);
        if (!_.isNull(res)) {
            return (parseInt(res.toString().replace("NOTA DI CREDITO NR.", "")));
        } else {
            return (undefined);
        }
    }

    /**     
     *  parseReceiptPrinterSerial
     */
    function parseReceiptPrinterSerial(receipt) {
        var res = receipt.match(/\*\*\*\s{1}(?!\s{1}).+/g);
        if (!_.isNull(res)) {
            return res.toString().replace("*** ", "").trim();
        } else {
            return (undefined);
        }
    }


    /**     
     *  parseReceiptPrinterSerial
     */
    function parseInvoicePrinterSerial(receipt) {

        // FATTURA N. 503  /  U1 72013281                  
        // FATTURA N. 504/A  U1 72013281                   

        var res = receipt.match(/FATTURA N\. \d+\s*\/\w*\s+.+/);
        if (!_.isNull(res)) {
            return res.toString().replace(/FATTURA N\. \d+\s*\/\w*\s+/, "").trim();
        } else {
            return (undefined);
        }
    }

    /**
     * parseUnclaimed
     */
    function parseUnclaimed(closing) {
        const resCn = closing.match(/CORR\.NON\s{1}RISCOSSO\s+(\s*?([\d\.]+(\,\d{1,2})?|\,\d{1,2}))/g);

        if(!resCn) {
            return;
        }

        return parseFloat(resCn[0].replace("CORR.NON RISCOSSO", "").replace(".", "").replace(",", ".").trim());
    }

    /**     
     *  stripDgfeHeader
     */
    function stripDgfeHeader(receipt) {
        if (receipt) {
            return receipt.substring(16);
        } else {
            return undefined;
        }
    }

    /**
     * isFiscalDocument
     */
    function containsOnlyDgfeHeader(receipt) {
        var r = receipt;

        var header = r.replace(/[\n\r]+/g, '');
        return (header === '000010');
    }

    /**     
     *  parseReceiptDate
     */
    function parseReceiptDate(receipt) {
        var res = receipt.match(/\.\d+\s+\d{2}\/\d{2}\/\d{2}\s+\d{2}\:\d{2}/g);
        if (!_.isNull(res)) {
            var dateString = res.toString().match(/\d{2}\/\d{2}\/\d{2}/g);
            var timeString = res.toString().match(/\d{2}\:\d{2}/g);
            dateString = dateString.toString();
            timeString = timeString.toString();
            var year = 2000 + parseInt(dateString.substring(6, 8));
            var month = parseInt(dateString.substring(3, 5)) - 1;
            var day = parseInt(dateString.substring(0, 2));
            var hours = parseInt(timeString.substring(0, 2));
            var mins = timeString.substring(3, 5);
            // Assuming over 2000            
            var dt = new Date(year, month, day, hours, mins, 0);
            return (isNaN(dt.getTime())) ? undefined : dt;
        } else {
            return (undefined);
        }
    }

    /**     
     *  parseInvoiceDate
     */
    function parseInvoiceDate(receipt) {
        var res = receipt.match(/\d{2}\/\d{2}\/\d{2}\s+\d{2}\:\d{2}/);
        if (!_.isNull(res)) {
            var dateString = res.toString().match(/\d{2}\/\d{2}\/\d{2}/);
            var timeString = res.toString().match(/\d{2}\:\d{2}/);
            dateString = dateString.toString();
            timeString = timeString.toString();
            var year = 2000 + parseInt(dateString.substring(6, 8));
            var month = parseInt(dateString.substring(3, 5)) - 1;
            var day = parseInt(dateString.substring(0, 2));
            var hours = parseInt(timeString.substring(0, 2));
            var mins = timeString.substring(3, 5);
            // Assuming over 2000            
            var dt = new Date(year, month, day, hours, mins, 0);
            return (isNaN(dt.getTime())) ? undefined : dt;
        } else {
            return (undefined);
        }
    }

    /**     
     *  Set X Mode (C2)
     */
    function setModX(printer) {
        return sendCommandsWS(printer, "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C2</cmd></Service>");
    }

    /**     
     *  Set Z Mode (C)
     */
    function setModZ(printer) {
        return sendCommandsWS(printer, "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C3</cmd></Service>");
    }

    /**     
     *  Set REG mode (C1)
     */
    function setModReg(printer) {
        return sendCommandsWS(printer, "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C1</cmd></Service>");
    }

    /**     
     *  configurationToCommands
     *  @param like autoConfigurePrinter parameters
     */
    function configurationToCommands(options) {
        return fiscalUtils.getPrinterConfigurationResources().then(function(resources) {
            var commands = [];

            // Vat setup
            commands.push("=C4");
            _.forEach(resources.vats, function(v) {
                if(_.inRange(v.id, 1, 8)) {
                    commands.push(">>/?V/$" + v.id + "/*" + (v.value * 100));
                } else {
                    errorsLogger.debug("Backend has IVA id:" + v.id + ", skipped");
                }
            });

            var fallbackZeroVat = _.find(resources.vats, function(v) {
                return v.value === 0 && _.inRange(v.id, 1, 8);
            });

            // Departments setup
            _.forEach(resources.departments, function(d) {
                // Default price | vat.id | name | maximum price=0 | minimum price=0 | auto_close=0 | group_code =1
                if(_.inRange(d.vat.id, 1, 8) || (d.vat.value === 0 && fallbackZeroVat)) {
                    var vatId = _.inRange(d.vat.id, 1, 8) ? d.vat.id : fallbackZeroVat.id;
                    commands.push(">R" + d.printer_code + "/?A/$0/*" + vatId + "/(" + d.name + ")/&0/[0/]0/_1");
                }
            });

            // Payment methods
            _.forEach(rchUtils.getPaymentsConfig(), function(paymentMethod) {
                commands.push([
                    '>T' + paymentMethod.id,
                    '?A',
                    '$' + _.toInteger(paymentMethod.enableChange),
                    '&' + _.toInteger(paymentMethod.enableSum),
                    '[' + _.toInteger(paymentMethod.unclaimed),
                    ']0', //Open cashdrawer (disabled on the printer, handled by the driver)
                    '^0', //Amount required (disabled)
                    '_' + _.toInteger(paymentMethod.enableTicket),
                    '(' + _.truncate(paymentMethod.name, { length: 20, omission: '' }) + ')'
                ]);
            });

            //Setup Headers
            _.times(13, function(i) {
                var lineNumber = (i + 1);
                commands.push(['>>', '?H', '$' + lineNumber, '(' + _.toString(options['header' + lineNumber]) + ')']);
            });

            //Setup invoice footer
            _.times(6, function(i) {
                var lineNumber = (i + 1);
                commands.push(['>C918', '*1', '$' + lineNumber, '(' + _.toString(options['invoice_footer' + lineNumber]) + ')']);
            });

            // Printer identifier
            if (scope.printer.printer_number) {
                commands.push(">C132/$1/*" + scope.printer.printer_number);
            }

            // Display message
            if (options.display_message) {
                commands.push('>>/?h/(' + options.display_message + ')');
            }

            /* Daily stats */
            // /$operatori /*reparti /&finanziari /[fasce orarie /]IVA /_dettagliogruppi /@totale gruppi
            commands.push(">C117/$1/*1/&1/[1/]1/_0/@0");

            /* Invoice setup: comments in italian because of printer's official manual and menus
               - stampa su 1 o 2 righe (0=stampa su una riga, 1=stampa su due righe), 
               - stampa Fattura interna(0=No,1=Si),
               - abilita funzione subtotale (0=No, 1=Si)
               - disabilita controllo dati CLIENTE (0=NO 1=SI) per fattura semplificata
               - Identificatore max. 5 (invoice_prefix)
            */

            commands.push(">C917/*1/&1/]1/_1/(" + (scope.printer.invoice_prefix ? scope.printer.invoice_prefix : "") + ")");

            /* Stampa bufferizzata */
            commands.push(">C932/$1");
            /* Opzione fidelity */
            commands.push(">C933/$1");
            /* Abilita preconto da tastiera */
            commands.push(">C934/$1");
            /* DGFE Ultimo scontrino */
            commands.push(">C934/$1");
            /* Abilita Nota di credito */
            commands.push(">C159/$1");

            // Back to reg
            commands.push("=C1");

            return commands;
        });
    }

    /**     
     *  Read Daily VAT
     */
    function readDailyVAT(printer, successFunction, errorFunction) {
        setModX(printer).then(function() {
            var vatXml = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C508/$0</cmd></Service>";
            sendCommandsWS(printer, vatXml).then(function(response) {
                var r = $.parseXML(response);
                var rxml = $(r);
                var stats = rxml.find("Stat").children();
                var vatStat = {};
                var total = 0;
                stats.each(function(index, node) {
                    if (index > 0 && index < 5) {
                        var vatNode = $(this).text().trim();
                        var vats = vatNode.split("\n");
                        vatStat['vat_value_' + index] = parseInt(vats[0]);
                        vatStat['vat_tot_' + index] = parseInt(vats[1]) / 100;
                        vatStat['vat_taxable_' + index] = parseInt(vats[2]) / 100;
                        vatStat['vat_amount_' + index] = parseInt(vats[3]) / 100;
                        total += vatStat['vat_tot_' + index];
                    }
                });
                vatStat.total = Math.round(total * 100) / 100;
                setModReg(printer).then(function() {
                    successFunction(vatStat);
                }, function(error) {
                    errorFunction(error);
                });
            }, errorFunction);
        }, errorFunction);
    }


    /**     
     *  Public methods     
     */
    return {
        /**
         * @desc setup RCH Print!F printer
         *
         * @param printer, the tilby fiscal printer (must be an RCH Print!F obviously)
         * @param options.print_notes: boolean (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
         * @param options.print_name: boolean (default true), if true prints the sale.name on top
         * @param options.print_barcode_id: boolean (default true), if true, prints a barcode on bottom that contains the sale.id
         * @param options.ticket_change: boolean (default true), if true enables ticket change
         * @param options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
         * @param options.print_seller: boolean (default true), if true print seller
         * @param options.seller_prefix: string, customize seller phrase
         * @param options.tail: string, general tail for the receipts
         * 
         * @param printer.id: integer, the printer id
         * @param printer.name: string, the printer name
         * @param printer.driver: string, must be 'rch in this case'
         * @param printer.connection_type: string, can be 'ws' (for webservice mode) or 'bt' (for bluetooth mode)
         * @param printer.ip_address: string, printer IP (if using webservice mode)
         * @param printer.mac_address_bt: string, printer MAC ADDRESS BLUETOOTH (if using bluetooth mode)         
         */
        setup: function(printer, options) {
            if (!printer) {
                throw "Printer is undefined";
            } else if (printer.driver !== 'rch') {
                throw "Wrong driver";
            }  else if (!printer.connection_type) {
                throw "Missing connection_type";
            }  else if (printer.connection_type === 'ws' && !printer.ip_address) {
                throw "Missing ip_address";
            }  else if (printer.connection_type === 'bt' && !printer.mac_address_bt) {
                throw "Missing macaddress_bt";
            }

            _.assign(scope, {
                printer: printer,
                options: options
            });
        },

        /**
         *  @desc automagically configure RCH Print!F
         *
         *  ( /printers )
         *  @param printer, the printer you want to configure
         *  @param printer.invoice_prefix (from printer)
         *  @param printer.printer_number (from printer)
         *
         *  ( /shop_preferences )
         *
         *  @param options.header1 (max 48 chars - receipt + invoice)
         *  @param options.header2 (max 48 chars - receipt + invoice))
         *  @param options.header3 (max 48 chars - receipt + invoice))
         *  @param options.header4 (max 48 chars - receipt + invoice))
         *  @param options.header5 (max 48 chars - receipt + invoice))
         *  @param options.header6 (max 48 chars - receipt + invoice))
         *
         *  @param options.header7 (max 48 chars - invoice only)
         *  @param options.header8 (max 48 chars - invoice only)
         *  @param options.header9 (max 48 chars - invoice only)
         *  @param options.header10 (max 48 chars - invoice only)
         *  @param options.header11 (max 48 chars - invoice only)
         *  @param options.header12 (max 48 chars - invoice only)
         *  @param options.header13 (max 48 chars - invoice only)
         *  
         *  @param options.invoice_footer1 (max 48 chars)
         *  @param options.invoice_footer2 (max 48 chars)
         *  @param options.invoice_footer3 (max 48 chars)
         *  @param options.invoice_footer4 (max 48 chars)
         *  @param options.invoice_footer5 (max 48 chars)
         *  @param options.invoice_footer6 (max 48 chars)
         *              
         *  @param options.display_message
         * 
         */
        autoConfigurePrinter: function(printer, options) {
            var d = $q.defer();

            configurationToCommands(options).then(function(commands) {
                // Convert commands to xml
                var setupXml = rchUtils.cmdToXml(commands);
                errorsLogger.debug(setupXml);

                // Configuring via WEB SERVICE
                if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                    rchUtils.isAvailable(printer).then(function() {
                        errorsLogger.debug("Autoconfiguring " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");

                        sendCommandsWS(scope.printer, setupXml).then(function() {
                            getConfiguration(scope.printer, d.resolve, d.reject);
                        }, d.reject);
                    }, d.reject);
                }

                // Printing via BT
                else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                    errorsLogger.debug("Autoconfiguring " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");

                    sendCommandsBT(printer, commands, d.resolve, d.reject);
                } else {
                    d.reject("PRINTER_CONFIGURATION_ERROR");
                }
            }, d.reject);

            return d.promise;
        },

        /**
         * @desc print a fiscal receipt + ref. invoice
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer: (default true) true if user has permission, false otherwise         
         * @param options.print_details: if false, print only department totals and hide single items and discount details
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printReceiptInvoice: function(sale, options, successFunction, errorFunction) {
            // Build protocol commands
            var commands = saleToReceiptCommands(sale, true, options);

            const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

            // Open cash drawer
            if (openCashdrawer) {
                commands.push("=C86");
            }

            // Convert commands to xml
            var saleXML = rchUtils.cmdToXml(commands);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    errorsLogger.debug(saleXML);

                    // FiscalReceipt
                    sendCommandsWS(scope.printer, saleXML).then(function() {
                        lastReceipt(scope.printer, function(receipt) {
                            var documentNumber = parseReceiptNumber(receipt);

                            if (!containsOnlyDgfeHeader(receipt)) {
                                // ReceiptDocument
                                var receiptDocument = {
                                    sequential_number: documentNumber,
                                    date: (parseReceiptDate(receipt) ? parseReceiptDate(receipt).toISOString() : undefined),
                                    document_type: 'fiscal_receipt',
                                    printer_serial: parseReceiptPrinterSerial(receipt),
                                    document_content: stripDgfeHeader(receipt)
                                };
                            } else {
                                // Not fiscalized printer, receiptDocument cannot be retrieved
                                errorsLogger.debug("Not fiscalized printer, receiptDocument cannot be retrieved");
                                documentNumber = 0;
                            }

                            var invoiceCommands = saleToFreeInvoice(sale, options, documentNumber);
                            var invoiceXml = rchUtils.cmdToXml(invoiceCommands);

                            // Invoice
                            sendCommandsWS(scope.printer, invoiceXml).then(function() {
                                lastReceipt(scope.printer, function(invoice) {
                                    rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                        if (!containsOnlyDgfeHeader(invoice)) {
                                            // ReceiptDocument
                                            var invoiceDocument = {
                                                sequential_number: parseInvoiceNumber(invoice),
                                                date: (parseInvoiceDate(invoice) ? parseInvoiceDate(invoice).toISOString() : undefined),
                                                document_type: 'receipt_invoice',
                                                printer_serial: parseInvoicePrinterSerial(invoice),
                                                document_content: stripDgfeHeader(invoice)
                                            };

                                            successFunction([receiptDocument, invoiceDocument]);
                                        } else {
                                            // Not fiscalized printer, invoiceDocument cannot be retrieved
                                            errorsLogger.debug("Not fiscalized printer, invoiceDocument cannot be retrieved");
                                            successFunction([]);
                                        }
                                    });
                                }, function(error) {
                                    errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");

                                    rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                        successFunction([]);
                                    });
                                });
                            }, errorFunction);
                        }, function(error) {
                            //errorFunction(error);
                            errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                            successFunction([]);
                        });
                    }, function(error) {
                        rchUtils.fixIssues(scope.printer, error).then(function(message) {
                            lastReceipt(scope.printer, function(receipt) {
                                if (!containsOnlyDgfeHeader(receipt)) {
                                    var documentNumber = parseReceiptNumber(receipt);
                                    errorsLogger.debug(receipt);
                                    successFunction([{
                                        sequential_number: documentNumber,
                                        date: (parseReceiptDate(receipt) ? parseReceiptDate(receipt).toISOString() : undefined),
                                        document_type: 'fiscal_receipt',
                                        printer_serial: parseReceiptPrinterSerial(receipt),
                                        document_content: stripDgfeHeader(receipt)
                                    }]);
                                } else {
                                    // Not fiscalized printer, receipt cannot be retrieved
                                    errorsLogger.debug("Not fiscalized printer, receipt cannot be retrieved");
                                    successFunction([]);
                                }
                            }, function(error) {
                                //errorFunction(error);
                                errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                successFunction([]);
                            });
                        }, function(err) {
                            errorFunction(error);
                        });
                    });
                }, function(error) {
                    errorFunction(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");

                sendCommandsBT(scope.printer, commands, function(result) {
                    lastReceiptNumberBT(scope.printer, function(number) {
                        var invoiceCommands = saleToFreeInvoice(sale, options, number);
                        sendCommandsBT(scope.printer, invoiceCommands, function(res) {
                            successFunction([{
                                sequential_number: number,
                                date: undefined,
                                document_type: 'fiscal_receipt',
                                printer_serial: undefined,
                                document_content: undefined
                            },{
                                sequential_number: undefined,
                                date: undefined,
                                document_type: 'receipt_invoice',
                                printer_serial: undefined,
                                document_content: undefined
                            }]);
                        }, function(error) {
                            errorFunction(error);
                        });
                    });
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @desc print a fiscal receipt (also known as 'scontrino fiscale', for italians)
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer - true if user has permission, false otherwise
         * @param options.tail: string, print this string at the end of receipt
         * @param options.print_details: if false, print only department totals and hide single items and discount details
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printFiscalReceipt: function(sale, options, successFunction, errorFunction) {
            // Build protocol commands
            var commands = saleToReceiptCommands(sale, true, options);

            // Open cash drawer
            var openCashdrawer = (options.can_open_cash_drawer === false) ? false :true;

            if (openCashdrawer) {
                commands.push("=C86");
            }

            // Convert commands to xml
            var saleXML = rchUtils.cmdToXml(commands);

            var returnDocumentData = function(receipt) {
                if (!containsOnlyDgfeHeader(receipt)) {
                    successFunction([{
                        sequential_number: sale.final_amount < 0 ? parseCreditNoteNumber(receipt) : parseReceiptNumber(receipt),
                        date: (parseReceiptDate(receipt) ? parseReceiptDate(receipt).toISOString() : undefined),
                        document_type: sale.final_amount < 0 ? 'credit_note' : 'fiscal_receipt',
                        printer_serial: parseReceiptPrinterSerial(receipt),
                        document_content: stripDgfeHeader(receipt)
                    }]);
                } else {
                    errorsLogger.debug("Not fiscalized printer, receipt or credit note cannot be retrieved");
                    successFunction([]);
                }
            };

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    errorsLogger.debug(saleXML);

                    // Check last receipt number
                    lastReceiptNumber(scope.printer, function(previousReceiptNumber) {
                        errorsLogger.debug("Previous Receipt Number:" + previousReceiptNumber);

                        // Print Fiscal Receipt
                        sendCommandsWS(scope.printer, saleXML).then(function() {
                            lastReceipt(scope.printer, function(receipt) {
                                rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                    returnDocumentData(receipt);
                                });
                            }, function(error) {
                                //errorFunction(error);
                                errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                successFunction([]);
                            });
                        }, function(error) {
                            rchUtils.fixIssues(scope.printer, error).then(function(message) {
                                lastReceipt(scope.printer, function(receipt) {
                                    rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                        returnDocumentData(receipt);
                                    });
                                }, function(error) {
                                    //errorFunction(error);
                                    errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                    successFunction([]);
                                });
                            }, function(err) {
                                errorFunction(error);
                            });
                        });
                    }, function(error) {
                        errorFunction(error);
                    });
                }, function(error) {
                    errorFunction(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");

                var documentType = 'fiscal_receipt';
                if (sale.final_amount < 0) {
                    documentType = 'credit_note';
                }

                var documentNumber;

                sendCommandsBT(scope.printer, commands, function(result) {

                    lastReceiptNumberBT(scope.printer, function(number) {

                        if (documentType === 'fiscal_receipt') {
                            documentNumber = number;
                        }

                        successFunction([{
                            sequential_number: documentNumber,
                            date: undefined,
                            document_type: documentType,
                            printer_serial: undefined,
                            document_content: undefined
                        }]);
                    });
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @desc print an Invoice (also known as 'Fattura', for italians)
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer - true if user has permission, false otherwise
         * @param options.tail: string, print this string at the end of receipt
         * @param options.print_details - true if user has permission, false otherwise        
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printInvoice: function(sale, options, successFunction, errorFunction) {
            var returnDocumentData = function(receipt) {
                if (!containsOnlyDgfeHeader(receipt)) {
                    successFunction([{
                        sequential_number: parseInvoiceNumber(receipt),
                        date: (parseInvoiceDate(receipt) ? parseInvoiceDate(receipt).toISOString() : undefined),
                        document_type: 'invoice',
                        printer_serial: parseInvoicePrinterSerial(receipt),
                        document_content: stripDgfeHeader(receipt)
                    }]);
                } else {
                    errorsLogger.debug("Not fiscalized printer, invoice cannot be retrieved");
                    successFunction([]);
                }
            };

            // Check for customer infos
            if (!sale.sale_customer) {
                errorFunction("CUSTOMER_MISSING");
            } else if ((!sale.sale_customer.company_name && !sale.sale_customer.first_name) || (!sale.sale_customer.company_name && !sale.sale_customer.last_name)) {
                errorFunction("CUSTOMER_MISSING_NAME");
            } else if (!sale.sale_customer.billing_street || !sale.sale_customer.billing_number || !sale.sale_customer.billing_zip || !sale.sale_customer.billing_city || !sale.sale_customer.billing_prov) {
                errorFunction("MISSING_BILLING_ADDRESS");
            } else if (!sale.sale_customer.tax_code && !sale.sale_customer.vat_code) {
                errorFunction("MISSING_TAX_VAT_CODE");
            } else {
                // Build protocol commands
                var commands = saleToInvoiceCommands(sale, options);

                const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

                // Open cash drawer
                if (openCashdrawer) {
                    commands.push("=C86");
                }

                // Convert commands to xml
                var saleXML = rchUtils.cmdToXml(commands);

                // Printing via WEB SERVICE
                if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                    rchUtils.isAvailable(scope.printer).then(function() {
                        errorsLogger.debug("Print invoice on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                        errorsLogger.debug(saleXML);

                        sendCommandsWS(scope.printer, saleXML).then(function() {
                            lastReceipt(scope.printer, function(receipt) {
                                rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                    returnDocumentData(receipt);
                                });
                            }, function(error) {
                                errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                successFunction([]);
                            });
                        }, function(error) {
                            rchUtils.fixIssues(scope.printer, error).then(function(message) {
                                lastReceipt(scope.printer, function(receipt) {
                                    rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                        returnDocumentData(receipt);
                                    });
                                }, function(error) {
                                    errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                    successFunction([]);
                                });
                            }, function(error) {
                                errorFunction(error);
                            });
                        });
                    }, function(error) {
                        errorFunction(error);
                    });
                }

                // Printing via BT
                else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                    errorsLogger.debug("Print invoice on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                    sendCommandsBT(scope.printer, commands, function(result) {
                        successFunction([{
                            sequential_number: undefined,
                            date: undefined,
                            document_type: 'invoice',
                            printer_serial: undefined,
                            document_content: undefined
                        }]);
                    }, function(error) {
                        errorFunction(error);
                    });
                } else {
                    errorFunction("PRINTER_CONFIGURATION_ERROR");
                }
            }
        },


        /**
         * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
         *
         * @param sale - the tilby sale you want to print
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printCourtesyReceipt: function(sale, options, successFunction, errorFunction) {
            // Build protocol commands
            var commands = rchUtils.saleToCourtesyReceiptCommands(sale);

            // Convert commands to xml
            var courtesyXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(courtesyXML);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print courtesy receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    sendCommandsWS(scope.printer, courtesyXML).then(function() {
                        successFunction("OK");
                    }, errorFunction);
                }, errorFunction);
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Print courtesy receipt on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                sendCommandsBT(scope.printer, commands, function(result) {
                    successFunction("OK");
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @desc print a non fiscal receipt (also known as 'preconto', for italians)
         *
         * @param sale - the tilby sale you want to print         
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printNonFiscal: function(sale, options, successFunction, errorFunction) {
            // Build protocol commands
            var commands = saleToReceiptCommands(sale, false, options);

            // Convert commands to xml
            var saleXML = rchUtils.cmdToXml(commands);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    sendCommandsWS(scope.printer, saleXML).then(function() {
                        successFunction("OK");
                    }, errorFunction);
                }, errorFunction);
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                sendCommandsBT(scope.printer, commands, function(result) {
                    successFunction("OK");
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @desc print an order (also known as 'comanda', for italians)
         *
         * @param order - the tilby sale you want to print         
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printOrder: function(order, successFunction, errorFunction) {
            // Build protocol commands
            var commands = rchUtils.orderToCommands(order, scope.printer.columns);

            // Convert commands to xml
            var orderXML = rchUtils.cmdToXml(commands);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Printing order on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    sendCommandsWS(scope.printer, orderXML).then(function() {
                        successFunction("OK");
                    }, errorFunction);
                }, function(error) {
                    errorFunction(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Printing order on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                sendCommandsBT(scope.printer, commands, function(result) {
                    successFunction("OK");
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @desc open cash drawer
         * 
         * @return successFunction if drawer is correctly opened or errorFunction with errors
         */
        openCashDrawer: function(successFunction, errorFunction) {
            var commands = ['=K', '=C86'];

            // Convert commands to xml
            var drawerXML = rchUtils.cmdToXml(commands);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Opening cash drawer on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    sendCommandsWS(scope.printer, drawerXML).then(function() {
                        successFunction("OK");
                    }, errorFunction);
                }, function(error) {
                    errorFunction(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Opening cash drawer on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                sendCommandsBT(scope.printer, commands, function(result) {
                    successFunction("OK");
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },


        /**
         * @desc do a daily closing
         * 
         * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
         */
        dailyClosing: function(successFunction, errorFunction) {
            var commands = ['=K', '=C3', '=C10', '=C1'];
            // Convert commands to xml
            var closingXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(closingXML);
            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    readDailyVAT(scope.printer, function(vatStats) {
                        errorsLogger.debug("Daily closing on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                        sendCommandsWS(scope.printer, closingXML).then(function() {
                            lastReceipt(scope.printer, function(receipt) {
                                if (!containsOnlyDgfeHeader(receipt)) {
                                    var dailyClosing = {
                                        sequential_number: parseReceiptNumber(receipt),
                                        date: (parseReceiptDate(receipt) ? parseReceiptDate(receipt).toISOString() : undefined),
                                        printer_serial: parseReceiptPrinterSerial(receipt),
                                        document_content: stripDgfeHeader(receipt)
                                    };

                                    dailyClosing = _.merge(dailyClosing, vatStats);
                                    dailyClosing.unclaimed = parseUnclaimed(receipt) || 0;

                                    errorsLogger.debug(dailyClosing);
                                    successFunction(dailyClosing);
                                } else {
                                    errorsLogger.debug("Not fiscalized printer, daily Closing cannot be retrieved");
                                    successFunction();
                                }
                            }, function(error) {
                                // errorFunction(error);
                                errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                successFunction();
                            });
                        }, errorFunction);
                    }, errorFunction);


                }, function(error) {
                    errorFunction(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Daily closing on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                sendCommandsBT(scope.printer, commands, function(result) {
                    successFunction({
                        sequential_number: 0,
                        date: new Date().toISOString(),
                        printer_serial: "NOT AVALABLE",
                        document_content: "BLUETOOTH PRINTER: CONTENT UNAVAILABLE"
                    });
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @desc do a daily read
         * 
         * @return promise
         */
        dailyRead: function() {
            var d = $q.defer();
            var commands = ['=K', '=C2', '=C10', '=C1'];

            // Convert commands to xml
            var readXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(readXML);
            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Daily read on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");

                    sendCommandsWS(scope.printer, readXML).then(function() {
                        d.resolve();
                    }, d.reject);
                }, d.reject);
            } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                errorsLogger.debug("Daily read on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");

                sendCommandsBT(scope.printer, commands, function(result) {
                    d.resolve();
                }, d.reject);
            } else {
                d.reject("PRINTER_CONFIGURATION_ERROR");
            }

            return d.promise;
        },

        /**
         * @desc do a cash deposit
         * @param {number} cash to deposit (euro)
         * @return promise
         */
        deposit: function(cash) {
            var d = $q.defer();
            var cashXml = Math.round(cash * 100);
            var commands = ['=K', '=C1', '=e/$' + cashXml];

            // Convert commands to xml
            var depositXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(depositXML);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Deposit on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");

                    sendCommandsWS(scope.printer, depositXML).then(function() {
                        d.resolve();
                    }, d.reject);
                }, d.reject);
            } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                errorsLogger.debug("Deposit on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");

                sendCommandsBT(scope.printer, commands, function(result) {
                    d.resolve();
                }, d.reject);
            } else {
                d.reject("PRINTER_CONFIGURATION_ERROR");
            }

            return d.promise;
        },

        /**
         * @desc do a cash withdrawal
         * 
         * @return promise
         */
        withdrawal: function(cash) {
            var d = $q.defer();
            var cashXml = Math.round(cash * 100);
            var commands = ['=K', '=C1', '=p/$' + cashXml];

            // Convert commands to xml
            var withdrawalXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(withdrawalXML);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Withdrawal on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");

                    sendCommandsWS(scope.printer, withdrawalXML).then(function() {
                        d.resolve();
                    }, d.reject);
                }, d.reject);
            } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                errorsLogger.debug("Withdrawal on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");

                sendCommandsBT(scope.printer, commands, function(result) {
                    d.resolve();
                }, d.reject);
            } else {
                d.reject("PRINTER_CONFIGURATION_ERROR");
            }

            return d.promise;
        },

        /**
         * @desc readDgfe
         * 
         * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
         * @return successFunction with dgfe value or 'OK' or errorFunction with errors
         */
        readDgfe: function(mode) {
            var d = $q.defer();
            var modeCode;

            if (mode === 'read') {
                modeCode = '0';
            } else if (mode === 'print') {
                modeCode = '1';
            }

            if (!_.isNil(modeCode)) {
                var commands = ['=C450/$' + modeCode];

                // Convert commands to xml
                var dgfeXML = rchUtils.cmdToXml(commands);
                errorsLogger.debug(dgfeXML);

                // Printing via WEB SERVICE
                if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                    rchUtils.isAvailable(scope.printer).then(function() {
                        errorsLogger.debug("Read DGFE on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                        // C3
                        setModZ(scope.printer).then(function() {
                            // Send DGFE
                            sendCommandsWS(scope.printer, dgfeXML).then(function(response) {
                                switch(mode) {
                                    case 'read':
                                        var dgfe = $(response).eq(2).text();
                                        d.resolve(stripDgfeHeader(dgfe));

                                        setModReg(scope.printer).then(function(status) {
                                            errorsLogger.debug("Successfully back to C1");
                                        }, function(error) {
                                            errorsLogger.debug("Error back to C1");
                                        });
                                    break;
                                    case 'print':
                                        d.resolve();
                                    break;
                                    default:
                                    break;
                                }
                            }, d.reject);
                        }, d.reject);
                    }, d.reject);
                } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                    if (mode === 'read') {
                        d.reject("INVALID_MODE");
                    } else {
                        errorsLogger.debug("Print DGFE on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                        commands.unshift("=C3");

                        sendCommandsBT(scope.printer, commands, function(result) {
                            d.resolve();
                        }, d.reject);
                    }
                } else {
                    d.reject("PRINTER_CONFIGURATION_ERROR");
                }
            } else {
                d.reject("INVALID_MODE");
            }

            return d.promise;
        },

        /**
         * @desc readDgfe
         * 
         * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
         * @param from: start date, must be string in format DDMMAA
         * @param to: end date, must be string in format DDMMAA
         *
         * @return promise
         */
        readDgfeBetween: function(mode, from, to) {
            var d = $q.defer();
            var modeCode;

            if (mode === 'read') {
                modeCode = '0';
            } else if (mode === 'print') {
                modeCode = '1';
            }

            if (!_.isNil(modeCode)) {
                var commands = ['=C451/$' + modeCode + "/&" + from + "/[" + to];

                // Convert commands to xml
                var dgfeXML = rchUtils.cmdToXml(commands);
                errorsLogger.debug(dgfeXML);

                // Printing via WEB SERVICE
                if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                    rchUtils.isAvailable(scope.printer).then(function() {
                        errorsLogger.debug("Read DGFE (between) on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                        // C3
                        setModZ(scope.printer).then(function() {
                            // Send DGFE
                            sendCommandsWS(scope.printer, dgfeXML).then(function(response) {
                                switch(mode) {
                                    case 'read':
                                        var dgfe = $(response).eq(2).text();
                                        d.resolve(stripDgfeHeader(dgfe));
    
                                        setModReg(scope.printer).then(function(status) {
                                            errorsLogger.debug("Successfully back to C1");
                                        }, function(error) {
                                            errorsLogger.debug("Error back to C1");
                                        });
                                    break;
                                    case 'print':
                                        d.resolve();
                                    break;
                                    default:
                                        //Shouldn't happen
                                    break;
                                }
                            }, d.reject);
                        }, d.reject);
                    }, d.reject);
                } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                    if (mode === 'read') {
                        d.reject("INVALID_MODE");
                    } else {
                        errorsLogger.debug("Print DGFE (between) on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                        commands.unshift("=C3");
                        sendCommandsBT(scope.printer, commands, function(result) {
                            d.resolve();
                        }, d.reject);
                    }
                } else {
                    d.reject("PRINTER_CONFIGURATION_ERROR");
                }
            } else {
                d.reject("INVALID_MODE");
            }

            return d.promise;
        },

        /**
         * @desc printFiscalMemory
         * 
         * @return promise
         */
        printFiscalMemory: function() {
            var d = $q.defer();
            var commands = ['=C400'];

            // Convert commands to xml
            var memoryXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(memoryXML);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print fiscal memory on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    setModZ(scope.printer).then(function(message) {
                        sendCommandsWS(scope.printer, memoryXML).then(function() {
                            d.resolve();
                        }, d.reject);
                    }, d.reject);
                }, d.reject);
            } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                errorsLogger.debug("Print fiscal memory on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                commands.unshift("=C3");
                commands.push("=C1");

                sendCommandsBT(scope.printer, commands, function(result) {
                    d.resolve();
                }, d.reject);
            } else {
                d.reject("PRINTER_CONFIGURATION_ERROR");
            }

            return d.promise;
        },
        /**
         * @desc printFiscalMemoryBeetween
         *
         * @param corrispettivi: boolean, if true prints 'corrispettivi' 
         * @param from: start date, must be string in format DDMMAA
         * @param to: end date, must be string in format DDMMAA
         *
         * @return promise
         */
        printFiscalMemoryBetween: function(corrispettivi, from, to) {
            var d = $q.defer();
            // C401 comando stampa memoria fiscale tra data (/&ggmmaa) e data(/[ggmmaa)
            var type = '=C401';

            if (corrispettivi === true) {
                // C403 comando stampa totale corrispettivi della memoria fiscale da data (/&ggmmaa) a data (/[ggmmaa)
                type = '=C403';
            }

            var commands = [type + "/&" + from + "/[" + to];

            // Convert commands to xml
            var memoryXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(memoryXML);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print fiscal memory (between) on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    setModZ(scope.printer).then(function(message) {
                        sendCommandsWS(scope.printer, memoryXML).then(function() {
                            d.resolve();
                            // CANNOT TURN BACK ON C1 VIA WS...
                        }, d.reject);
                    }, d.reject);
                }, d.reject);
            } else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) { // Printing via BT
                errorsLogger.debug("Print fiscal memory (between) on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                commands.unshift("=C3");
                commands.push("=C1");
                sendCommandsBT(scope.printer, commands, function(result) {
                    d.resolve();
                }, d.reject);
            } else {
                d.reject("PRINTER_CONFIGURATION_ERROR");
            }

            return d.promise;
        },

        /**
         * @description configure LAN parameters using BT
         * @param {object} printer, must contain mac_address_bt, ip_address, subnet_mask, gateway
         * @return {successFunction} with message 'OK' or
         * @return {errorFunction} with specific message from bluetooth         
         */
        configureLanBT: function(printer, successFunction, errorFunction) {

            errorsLogger.debug("Configuring LAN using BT:" + printer.mac_address_bt + " via bt...");

            if (printer.mac_address_bt && printer.ip_address && printer.subnet_mask && printer.gateway) {

                var commands = [];
                commands.push("=C4");
                commands.push(">C941/*3/$1/(" + printer.ip_address + ")");
                commands.push(">C941/*3/$2/(" + printer.subnet_mask + ")");
                commands.push(">C941/*3/$3/(" + printer.gateway + ")");
                commands.push(">C941/*3/$4/(&23)");
                commands.push("=C1");

                sendCommandsBT(printer, commands, function(result) {
                    errorsLogger.debug("Printer IP configured.");
                    successFunction("OK");

                }, function(error) {
                    errorsLogger.debug("Error in configuring LAN printer:" + error);
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        },

        /**
         * @description discover RCH Printers in bluetooth range
         * @return successFunction with an array of discovered printers
         */
        discoverPairedBT: function(successFunction, errorFunction) {

            // Check if phonegap bt plugin is enabled            
            if (window.$window.bluetoothSerial !== undefined) {

                // Check if bluetooth is enabled
                $window.bluetoothSerial.isEnabled(function() {
                        errorsLogger.debug("Bluetooth is enabled!");
                        $window.bluetoothSerial.list(function(list) {
                            var printfs = _.filter(list, function(device) {
                                // "00:0B:CE" is the manufacturer identification = RCH
                                return _.startsWith(device.id, "00:0B:CE");
                            });
                            successFunction(printfs);
                        }, function(error) {
                            errorFunction(error);
                        });
                    },
                    function() {
                        errorsLogger.debug("ERROR: Bluetooth is not enabled");
                        errorFunction("BT_DISABLED");
                    });
            } else {
                errorFunction("PHONEGAP_ERROR");
            }
        },

        /**
         * @description printFreeNonFiscal
         */
        printFreeNonFiscal: (lines, options) => new Promise((resolve, reject) => {
            var freeCommands = rchUtils.linesToCommands(lines);

            // Convert commands to xml
            var freeXML = rchUtils.cmdToXml(freeCommands);
            errorsLogger.debug(freeXML);

            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug(`Print free non-fiscal on a ${scope.printer.name} ${scope.printer.ip_address} via ws...`);

                    sendCommandsWS(scope.printer, freeXML).then(function() {
                        resolve();
                    }, function(error) {
                        reject(error);
                    });
                }, function(error) {
                    reject(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug(`Print free non-fiscal on a ${scope.printer.name} ${scope.printer.ip_address} via bt...`);

                sendCommandsBT(scope.printer, freeCommands, function(result) {
                    resolve();
                }, function(error) {
                    reject(error);
                });
            } else {
                reject("PRINTER_CONFIGURATION_ERROR");
            }
        }),

        /**
         * isReachable
         */
        isReachable: function(successFunction, errorFunction) {
            return rchUtils.isAvailable(scope.printer).then(successFunction, errorFunction);
        },

        /**
         * @desc print a ref. invoice
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer: (default true) true if user has permission, false otherwise         
         * @param options.print_details: if false, print only department totals and hide single items and discount details
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printSummaryInvoice: function(sale, options, successFunction, errorFunction) {
            // Printing via WEB SERVICE
            if (scope.printer.connection_type === 'ws' && scope.printer.ip_address) {
                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Print summary invoice on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    var invoiceCommands = saleToFreeInvoice(sale, options, 0);
                    var invoiceXml = rchUtils.cmdToXml(invoiceCommands);

                    var returnDocumentData = function(invoice) {
                        if (!containsOnlyDgfeHeader(invoice)) {
                            successFunction([{
                                sequential_number: parseInvoiceNumber(invoice),
                                date: (parseInvoiceDate(invoice) ? parseInvoiceDate(invoice).toISOString() : undefined),
                                document_type: 'summary_invoice',
                                printer_serial: parseInvoicePrinterSerial(invoice),
                                document_content: stripDgfeHeader(invoice)
                            }]);
                        } else {
                            errorsLogger.debug("Not fiscalized printer, invoice cannot be retrieved");
                            successFunction([]);
                        }
                    };

                    // Invoice
                    sendCommandsWS(scope.printer, invoiceXml).then(function() {
                        lastReceipt(scope.printer, function(receipt) {
                            rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                returnDocumentData(receipt);
                            });
                        }, function(error) {
                            errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                            successFunction([]);
                        });
                    }, function(error) {
                        rchUtils.fixIssues(scope.printer, error).then(function(message) {
                            lastReceipt(scope.printer, function(receipt) {
                                returnDocumentData(receipt);
                            }, function(error) {
                                errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                successFunction([]);
                            });
                        }, function(err) {
                            errorFunction(error);
                        });
                    });
                }, function(error) {
                    errorFunction(error);
                });
            }

            // Printing via BT
            else if (scope.printer.connection_type === 'bt' && scope.printer.mac_address_bt) {
                errorsLogger.debug("Print summary invoice on a " + scope.printer.name + " " + scope.printer.mac_address_bt + " via bt...");
                var invoiceCommands = saleToFreeInvoice(sale, options, 0);
                sendCommandsBT(scope.printer, invoiceCommands, function(result) {
                    successFunction([{
                        sequential_number: undefined,
                        date: undefined,
                        document_type: 'summary_invoice',
                        printer_serial: undefined,
                        document_content: undefined
                    }]);
                }, function(error) {
                    errorFunction(error);
                });
            } else {
                errorFunction("PRINTER_CONFIGURATION_ERROR");
            }
        }
    };
}]);