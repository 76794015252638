<tilby-dialog-content class="tw-p-0">
    <mat-toolbar class="tw-bg-white">
        <div class="tw-flex tw-truncate">
            <p class="tw-text-md tw-truncate" translate>DASHBOARD.DIALOG_GOAL.TITLE</p>
        </div>
    </mat-toolbar>
    <mat-dialog-content>
        <form *ngIf="settingsDailyGoalForm" autocomplete="off" [formGroup]="settingsDailyGoalForm">
            <mat-form-field color="primary" class="tw-w-full">
                <mat-label  translate>DASHBOARD.DIALOG_GOAL.LABEL</mat-label>
                <input formControlName="amount" class="tw-w-full tw-mt-2" type="number" [step]="1" [value]="0" matInput>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="'Cancel'"><span translate>DASHBOARD.DIALOG_GOAL.ACTION.CANCEL</span></button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="submit(settingsDailyGoalForm)" [disabled]="settingsDailyGoalForm?.invalid"><span translate>DASHBOARD.DIALOG_GOAL.ACTION.CONFIRM</span></button>
    </mat-dialog-actions>
</tilby-dialog-content>
