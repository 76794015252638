import * as angular from 'angular';
import * as _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';
import { AngularJSObservableHelper } from 'app/libs/angularjs-observable-helper';

angular.module('orders', ['application']);

angular.module('orders').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('orders');

    $stateProvider.state('app.orders', {
        url: '/orders',
        redirectTo: 'app.orders.content',
        views: {
            "appContent@app": {
                template: require('./partial/orders/orders.html'),
                controller: ["$scope", "screenOrientation", function($scope, screenOrientation) {
                    $scope.viewOptions = {
                        portrait: _.startsWith(screenOrientation.getOrientation(), 'portrait'),
                        hideOrder: true
                    };

                    $scope.getContentClass = function() {
                        if($scope.viewOptions.portrait) {
                            return $scope.viewOptions.hideOrder ? 'flex' : 'flex-50';
                        } else {
                            return 'flex-66';
                        }
                    };

                    $scope.getActiveOrderClass = function() {
                        if($scope.viewOptions.portrait) {
                            return $scope.viewOptions.hideOrder ? '' : 'flex-50';
                        } else {
                            return 'flex-33';
                        }
                    };

                    AngularJSObservableHelper.attachObservableToScope($scope, screenOrientation.getOrientationObservable(), (orientation) => {
                        $scope.viewOptions.portrait = _.startsWith(orientation, 'portrait');
                    });
                }]
            }
        }
    });

    $stateProvider.state('app.orders.order', {
        url: '/order/:id?',
        params: {
            id: { type: 'int', value: null },
        },
        redirectTo: function(transition) {
            return transition.router.stateService.target('app.orders.content', { orderId: transition.params().id }, { reload: 'app.orders' });
        }
    });

    $stateProvider.state('app.orders.content', {
        url: '/content',
        params: {
            type: null,
            table: null,
            room: null,
            orderId: null,
            customer: null
        },
        views: {
            "orders-showcase": {
                controller: 'OrdersShowcaseCtrl',
                template: require('./partial/orders-showcase/orders-showcase.html')
            },
            "active-order": {
                controller: 'ActiveOrderCtrl',
                template: require('./partial/active-order/active-order.html')
            },
        },
        resolve: {
            items: ["entityManager", async (entityManager) => {
                let items = await entityManager.items.fetchCollectionOffline({ on_sale: true });
                let result = _.chain(items)
                    .groupBy("category_id")
                    .value();

                result.favorite = _.filter(items, { favorite: true });

                if (!result.null) {
                    result.null = [];
                }

                return result;
            }],
            stockDictionary: ["entityManager", async (entityManager) => {
                let result = new SclDictionary();
                let stockCollection = await entityManager.stock.fetchCollectionOffline();

                for(let stock of stockCollection) {
                    if (stock.combination_id) {
                        result.set(`combination_id_${stock.combination_id}`, _.pick(stock, ['combination_id', 'available', 'stock_quantity']));
                    } else if (stock.item_id) {
                        result.set(`item_id_${stock.item_id}`, _.pick(stock, ['item_id', 'available', 'stock_quantity']));
                    }
                }

                return result;
            }],
            categories: ["entityManager", (entityManager) => entityManager.categories.fetchCollectionOffline() ],
            coverConfig: ["orderUtils", (orderUtils) => orderUtils.getOrderCoverConfig() ],
            deliveryChannels: ["entityManager", (entityManager) => entityManager.channels.fetchCollectionOffline().then((channels) => _.reject(channels, { id: 'pos' })) ]
        }
    });

    /* Add New States Above */
}]);
