import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('fidelity').factory('fidelityAddShowMovement', ["$mdDialog", function($mdDialog) {

    fidelityAddShowMovementController.$inject = ["$scope", "$mdDialog", "$translate", "movement", "params", "entityManager"];
    function fidelityAddShowMovementController($scope, $mdDialog, $translate, movement, params, entityManager) {

        $scope.campaigns = [];

        $scope.types = {
            load: $translate.instant('FIDELITY.ADD_SHOW_MOVEMENT.LOAD'),
            unload: $translate.instant('FIDELITY.ADD_SHOW_MOVEMENT.UNLOAD')
        };

        if (!params) {
            params = {};
        }

        var transformMovementData = function() {
            $scope.movement.$date = new Date($scope.movement.date);

            $scope.movement.$type = $scope.movement.points >= 0 ? "load" : "unload";
            $scope.movement.$shopName = $scope.movement.shop_name || $scope.movement.db_name; //It should already be compiled by the movements list
            $scope.movement.points = Math.abs($scope.movement.points);
        };


        if (!movement || _.isEmpty(movement)) {
            $scope.dialogMode = 'add';
            $scope.movement = {};

            $scope.movement.$date = new Date();
            $scope.movement.$date.setSeconds(0);
            $scope.movement.$date.setMilliseconds(0);

            if(params.campaigns) {
                $scope.campaigns = params.campaigns;
            } else {
                entityManager.campaigns.fetchCollectionOnline({}).then(function(result) {
                    $scope.campaigns = result;
                });
            }
            if(params.selectedCampaign) {
                $scope.movement.$campaign = params.selectedCampaign;
            }
            if(params.customer) {
                $scope.movement.fidelity = params.customer.fidelity;
            } else {
                //TODO: This shouldn't happen, in case show an error message or something
            }
            if(params.shop) {
                $scope.movement.db_name = params.shop.db_name;
                $scope.movement.shop_name = params.shop.shop_name;
            } else {

            }
        } else {
            $scope.dialogMode = 'view';
            $scope.movement = _.cloneDeep(movement);
            transformMovementData();
        }

        $scope.isViewMode = function() {
            return $scope.dialogMode === 'view';
        };

        $scope.isAddMode = function() {
            return $scope.dialogMode === 'add';
        };

        var cleanupMovement = function(movement) {
            delete movement.$date;
            delete movement.$type;
            delete movement.$shopName;
            delete movement.$campaign;
        };

        $scope.confirm = function() {
            var movementToSend;
            var movementDate;

            if ($scope.isViewMode()) {
                $mdDialog.hide(movement);
            } else if ($scope.isAddMode()) {
                movementToSend = _.cloneDeep($scope.movement);

                if (movementToSend.type === 'unload') {
                    movementToSend.points *= -1;
                }

                movementToSend.campaign_id = movementToSend.$campaign.id;
                movementToSend.campaign_name = movementToSend.$campaign.name;

                movementDate = new Date(movementToSend.$date);
                movementToSend.date = movementDate.toISOString();

                cleanupMovement(movementToSend);
                $mdDialog.hide(movementToSend);
            }
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

    }

    var fidelityAddShowMovement = {
        show: function(movement, params) {
            return $mdDialog.show({
                    controller: fidelityAddShowMovementController,
                    template: require('./fidelity-add-show-movement.html'),
                    locals: {
                        movement: movement,
                        params: params
                    }
                });
        }
    };

    return fidelityAddShowMovement;
}]);
