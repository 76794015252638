import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {DevLogger} from 'src/app/shared/dev-logger';
import {ShopCartWrapperComponent} from "./shop-cart-wrapper";
import {ActiveSaleActionButtonsWrapperComponent} from "./active-sale-action-buttons-wrapper";
import {ActiveSaleBase} from './active-sale-base/active-sale-base.component';
import {ActiveSaleHeaderInfoWrapperComponent} from "./active-sale-header-info-wrapper/active-sale-header-info-wrapper.component";
import { PaymentsActionButtonsWrapperComponent } from '../cashregister-payments/payments-action-buttons-wrapper/payments-action-buttons-wrapper.component';
import { PaymentsListWrapperComponent } from '../cashregister-payments/payments-list-wrapper/payments-list-wrapper.component';
import { TilbyActiveSaleHeaderInfoComponent } from '@tilby/tilby-ui-lib/components/tilby-order';

@Component({
    selector: 'app-active-sale',
    standalone: true,
    templateUrl: './active-sale.component.html',
    styleUrls: ['./active-sale.component.scss'],
    imports: [CommonModule, TilbyActiveSaleHeaderInfoComponent, TranslateModule, ShopCartWrapperComponent, ActiveSaleActionButtonsWrapperComponent, ActiveSaleHeaderInfoWrapperComponent, PaymentsActionButtonsWrapperComponent, PaymentsListWrapperComponent],
    host: {
        class: 'tw-flex tw-flex-col tw-gap-3 tw-h-full'
    }
})
export class ActiveSaleComponent extends ActiveSaleBase implements OnInit,OnDestroy {
    protected sideKeyboard = this.cashregisterStateService.sideKeyboard;

    public log(...arg: any[]) {
        DevLogger.debug('ACTIVE_SALE: ', ...arg)
    }

    // START - LIFECYCLE
    async ngOnInit() {
        await super.ngOnInit();
      }

    ngOnDestroy() {
       super.ngOnDestroy();
    }
    // END - LIFECYCLE

}
