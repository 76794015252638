<tilby-dialog-toolbar
    (confirm)="confirm()"
    [hideConfirm]="!!data.signatureRequired && !hasDrawnSignature"
    [title]="data.dialogTitle || 'CASHREGISTER.SIGNATURE_PAD_DIALOG.TITLE'"
/>
<tilby-dialog-content>
    <canvas
        class="tw-bg-gray-200 tw-border tw-border-grey tw-border-solid tw-touch-none tw-w-full tw-h-full tw-block"
        #signatureCanvas
        (pointerdown)="onPointerDown($event)"
        (pointermove)="onPointerMove($event)"
        (pointerup)="onPointerUp($event)">
    </canvas>
</tilby-dialog-content>
