import * as angular from 'angular';

angular.module('printers').directive('topbarPrinters', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-printers.html'),
        controller: ["$scope", "$element", "$attrs", "$state", "$mdSidenav", "connection", "offlineDialog", function($scope, $element, $attrs, $state, $mdSidenav, connection, offlineDialog) {
            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };
        }]
    };
});
