import * as angular from 'angular';
import * as async from 'async';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('digitalPayments').factory('verifoneOciusDriver', ["$q", "$rootScope", "$window", "checkManager", "errorsLogger", "ActiveSale", "documentPrinter", function($q, $rootScope, $window, checkManager, errorsLogger, ActiveSale, documentPrinter) {
    let scope = {};
    let responseBuffers = {};

    const tailColumns = 32;

    const getTailLine = (name, value, printEmptyLine) => {
        return _.isEmpty(value) && !printEmptyLine ? null : name + ':' + _.padStart(value, tailColumns - _.size(name), ' ');
    };

    /**
     * ab2str
     */
    function ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    }

    /**
     * str2ab
     */
    function str2ab(str) {
        let buf = new ArrayBuffer(str.length);
        let bufView = new Uint8Array(buf);

        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }

        return buf;
    }

    const returnError = function(errorMsg) {
        if(scope.deferred) {
            scope.deferred.reject({ response: _.isError(errorMsg) ? errorMsg.message : errorMsg, receiptData: scope.customerReceipt });
        }

        cleanup();
    };

    const completeTransaction = function(responseObj) {
        if(scope.deferred) {
            scope.deferred.resolve({ response: responseObj, receiptData: scope.customerReceipt });
        }

        cleanup();
    };

    const establishPortConnection = function(port, socketName) {
        let d = $q.defer();
        let timeoutHandle = null;

        $window.chrome.sockets.tcp.create(function(socketInfo) {
            timeoutHandle = setTimeout(getTimeoutFunction(socketInfo, d), 10000);

            $window.chrome.sockets.tcp.connect(socketInfo.socketId, scope.ipAddress, port, function(result) {
                errorsLogger.debug(`Socket ${socketInfo.socketId} connected to ${scope.ipAddress}: ${port}`);

                clearTimeout(timeoutHandle);

                if (result === 0) {
                    scope[socketName] = socketInfo;
                    responseBuffers[socketName] = "";
                    d.resolve(socketInfo);
                } else {
                    d.reject('CONNECTION_ERROR');
                }
            });
        });

        return d.promise;
    };
 
    const closeConnection = function(socketName) {
        let d = $q.defer();
        let socketToClose = scope[socketName];

        if(socketToClose) {
            scope.socketName = null;

            $window.chrome.sockets.tcp.disconnect(socketToClose.socketId, function() {
                errorsLogger.debug(`Socket ${socketToClose.socketId} disconnected`);
    
                $window.chrome.sockets.tcp.close(socketToClose.socketId, function() {
                    d.resolve();
                });
            });
        } else {
            d.resolve();
        }

        return d.promise;
    };

    const cleanup = function() {
        $window.chrome.sockets.tcp.onReceiveError.removeListener(errorListener);
        $window.chrome.sockets.tcp.onReceive.removeListener(responseHandler);

        if(scope.statusSocket) {
            closeConnection('statusSocket');
        }

        if(scope.integrationSocket) {
            closeConnection('integrationSocket');
        }

        responseBuffers = {};

        _.assign(scope, {
            pluginInfo: { status: 'IDLE' },
            customerReceipt: null,
            deferred: null,
            integrationSocket: null,
            merchantReceipt: null,
            statusSocket: null
        });
    };

    const errorListener = function(info) {
        switch(info.socketId) {
            case scope.integrationSocket.socketId:
                if(!scope.integrationSocket.loopEnded) { //Ignore socket errors if this socket has completed its purpose
                    returnError("CONNECTION_ERROR");
                }
            break;
            case scope.statusSocket.socketId:
                returnError("CONNECTION_ERROR");
            break;
        }
    };

    const getTimeoutFunction = function(socketInfo, deferred) {
        return function() {
            $window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                if (!info.connected) {
                    deferred.reject("CONN_TIMEDOUT");
                }
            });
        };
    };

    const sendCommand = function(command) {
       $window.chrome.sockets.tcp.send(scope.integrationSocket.socketId, str2ab(command + '\r\n'), _.noop);
    };


    const getPaymentMessage = function(amount) {
        return [
            'T', //Message Type
            '', //Account Number
            amount >= 0 ? '01' : '02', //Transaction Type (
            '0000', //Modifier
            '', //PoS Routing / Bill ID
            '', //PAN / Track 2
            '', //CSC
            '', //Expiry Date
            '', //Issue No
            '', //Start Date
            Math.abs(amount).toFixed(2), //Txn Value
            '', //Cash Back Value
            '', //Bank Acc No
            '', //Sort Code
            '', //Cheque No
            '', //Cheque Type
            '', //Cardholder Name
            '', //Cardholder Billing Address
            '', //EFTSN
            '', //Auth Source
            '', //Auth Code
            '', //Txn Date Time
            [$rootScope.userActiveSession.shop.name].join('/'), //Reference
            '', //Account ID
            '', //Gratuity
            '', //NDI Value
            '0', //Register For Account On File
            '', //Token ID
            '0', //Suppress Charitable Donation
            '0', //Suppress Gratuity Prompt
            '', //CoF Sequence
            '', //CoF Agreement Type
            '', //CoF SRD
            '', //DOB
            '', //Surname
            '', //Account Number
            '', //Partial Post
        ].join(',');
    };

    const paramsParserRegex = /([\w ]+)=(<\?.*>|[^;\n]*)[;\n]*/g;

    const getStatusInfo = function(data) {
        let statusArr = data.split(',');
        let paramsResult = _.isEmpty(statusArr[4]) ? [] : Array.from(statusArr[4].replace(/\r\n/g, '').matchAll(paramsParserRegex));

        let statusObj = {
            resultCode: _.toInteger(statusArr[0]),
            statusCode: _.toInteger(statusArr[2]),
            statusName: statusArr[3],
            params: {}
        };

        _.forEach(paramsResult, function(param) {
            statusObj.params[param[1]] = param[2];
        });

        return statusObj;
    };


    const parseReceipt = function(xmlText, receiptType) {
        let receiptObj = {};

        try {
            let xmlObj = $.parseXML(xmlText); //Parse XML in JQuery Object
            let voucherDetails = $(xmlObj).find('VoucherDetails'); //Pick voucherDetails node

            voucherDetails.children().each(function(idx, item) {
                receiptObj[item.nodeName] = item.textContent;
            });
        } catch(error) {}

        let receiptData = [];
        let printEmptyLine = false;
        
        if(!_.isEmpty(receiptObj)) {
            receiptData.push.apply(receiptData, [
                _.pad(`*** ${receiptType} COPY ***`, tailColumns, ' '),
                _.repeat(' ', tailColumns),
                _.padEnd(receiptObj.CardScheme, tailColumns, ' '),
                _.padEnd(receiptObj.PAN, tailColumns, ' '),
                getTailLine('Expiry', receiptObj.ExpiryDate, printEmptyLine),
                _.padEnd(receiptObj.TxnType, tailColumns, ' '),
                _.pad(receiptObj.CreditDebitMessage, tailColumns, ' '),
                getTailLine('Amount', receiptObj.Amount, printEmptyLine),
                getTailLine('TOTAL', receiptObj.Total, printEmptyLine),
                _.repeat(' ', tailColumns),
                _.pad(receiptObj.CVM, tailColumns, ' '),
                _.pad(receiptObj.KeepText1, tailColumns, ' '),
                _.pad(receiptObj.KeepText2, tailColumns, ' '),
                getTailLine('PTID', receiptObj.PTID, printEmptyLine),
                getTailLine('MID', receiptObj.MID, printEmptyLine),
                getTailLine('TID', receiptObj.TID, printEmptyLine),
                getTailLine('Date', moment(receiptObj.TxnDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), printEmptyLine),
                getTailLine('Time', moment(receiptObj.TxnDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss'), printEmptyLine),
                getTailLine('EFTSN', receiptObj.EFTSN, printEmptyLine),
                _.repeat('-', tailColumns),
            ]);

            if(receiptObj.AccountOnFile === 'true') {
                receiptData.push.apply(receiptData, [
                    _.padEnd("Account On File", tailColumns, ' '),
                    _.padEnd("Registration Details", tailColumns, ' '),
                    getTailLine('Result', receiptObj.TokenRegistrationResult, printEmptyLine),
                    getTailLine('Token ID', receiptObj.TokenID, printEmptyLine),
                    _.repeat('-', tailColumns),
                ]);
            }

            receiptData.push.apply(receiptData, [
                getTailLine('AuthCode', receiptObj.AuthCode, printEmptyLine),
                getTailLine('Ref', receiptObj.Reference, printEmptyLine),
                getTailLine('AID', receiptObj.AID, printEmptyLine),
                getTailLine('App Eff', receiptObj.AppEff, printEmptyLine),
                getTailLine('App Seq', receiptObj.AppSeq, printEmptyLine),
                _.repeat('-', tailColumns),
            ]);

            if(checkManager.getPreference('verifone.enable_extended_receipt')) {
                printEmptyLine = true;

                receiptData.push.apply(receiptData, [
                    _.pad(' Extended Voucher', tailColumns, '-'),
                    getTailLine('AppExp', receiptObj.AppExp, printEmptyLine),
                    getTailLine('TxnType', receiptObj.TxnType, printEmptyLine),
                    getTailLine('CID', receiptObj.CID, printEmptyLine),
                    getTailLine('CardHolder', receiptObj.CardHolder, printEmptyLine),
                    getTailLine('CardAVN', receiptObj.CardAVN, printEmptyLine),
                    getTailLine('CVMR', receiptObj.CVMR, printEmptyLine),
                    getTailLine('FloorLimit', receiptObj.FloorLimit, printEmptyLine),
                    getTailLine('TSI', receiptObj.TSI, printEmptyLine),
                    getTailLine('TVR', receiptObj.TVR, printEmptyLine),
                    getTailLine('IAC Den', receiptObj.IACDen, printEmptyLine),
                    getTailLine('IAC Onl', receiptObj.IACOnl, printEmptyLine),
                    getTailLine('IAC Def', receiptObj.IACDef, printEmptyLine),
                    getTailLine('AC', receiptObj.AC, printEmptyLine),
                    getTailLine('CryptoTxnType', receiptObj.CryptoTxnType, printEmptyLine),
                    getTailLine('AIP', receiptObj.AIP, printEmptyLine),
                    getTailLine('TAC Den', receiptObj.TACDen, printEmptyLine),
                    getTailLine('TAC Onl', receiptObj.TACOnl, printEmptyLine),
                    getTailLine('TAC Def', receiptObj.TACDef, printEmptyLine),
                    getTailLine('TDOL', receiptObj.TDOL, printEmptyLine),
                    getTailLine('DDOL', receiptObj.DDOL, printEmptyLine),
                    getTailLine('MCC', receiptObj.MCC, printEmptyLine),
                    getTailLine('CAPK', receiptObj.CAPK, printEmptyLine),
                    getTailLine('IAD', receiptObj.IAD, printEmptyLine),
                    getTailLine('ATC', receiptObj.ATC, printEmptyLine),
                    getTailLine('UN', receiptObj.UN, printEmptyLine),
                    getTailLine('TCtry', receiptObj.TCTry, printEmptyLine),
                    getTailLine('AmtO', receiptObj.AmtO, printEmptyLine),
                    getTailLine('TransactionCurrencyCode', receiptObj.TransactionCurrencyCode, printEmptyLine),
                    _.repeat('-', tailColumns),
                ]);

            }

            if(receiptObj.CVM === 'Please Sign Below') {
                receiptData.push.apply(receiptData, [
                    _.pad('Customer Signature', tailColumns),
                    _.repeat(' ', tailColumns),
                    _.repeat('_', tailColumns)
                ]);
            }
        }

        return _(receiptData).compact().join('\n');
    };

    const parseResponseData = function(response) {
        let responseArr = response.split(',');

        let responseObj =  {
            resultCode: _.toInteger(responseArr[0]),
            terminateLoop: _.toInteger(responseArr[1]),
            transationValue: responseArr[2],
            cashbackValue: responseArr[3],
            gratuityValue: responseArr[4],
            cardPAN: responseArr[5],
            cardExpiryDate: responseArr[6],
            cardIssueNo: responseArr[7],
            cardStartdate: responseArr[8],
            transactionDate: responseArr[9],
            merchantNo: responseArr[10],
            terminalID: responseArr[11],
            schemeName: responseArr[12],
            floorLimit: responseArr[13],
            EFTSeqNo: responseArr[14],
            authorizationCode: responseArr[15],
            referralPhoneNo: responseArr[16],
            statusMessage: responseArr[17],
            captureMethod: responseArr[18],
            transactionCurrencyCode: responseArr[19],
            originalTransactionValue: responseArr[20],
            originalCashbackValue: responseArr[21],
            originalGratuityValue: responseArr[22],
            originalTransactionCurrencyCode: responseArr[23],
            accountOnFileRegistrationResult: responseArr[26],
            tokenId: responseArr[27],
            avsPostCodeResult: responseArr[28],
            avsHouseNumberResult: responseArr[29],
            cscResult: responseArr[30],
            cardNumberHash: responseArr[31],
            vgisReference: responseArr[32],
            track1DiscretionaryData: responseArr[33],
            charityDonationValue: responseArr[34],
            charityDonationMerchantNumber: responseArr[35],
            originalCharityDonationValue: responseArr[36],
            transactionId: responseArr[37],
            authorisationServerName: responseArr[38],
            cardSchemeId: responseArr[39],
            PTID: responseArr[40],
            serialNumber: responseArr[41],
            schemeReferenceData: responseArr[44]
        };

        return responseObj;
    };

    const sendLoginInfo = function() {
        if(scope.loginInfo) {
            sendCommand(['L2', scope.loginInfo].join(','));
        } else {
            returnError('MISSING_LOGIN_INFO');
        }
    };

    const sendYesAnswer = function() {
        switch(scope.pluginInfo.statusCode) {
            case 18:
                sendContinueRecord(3);
            break;
            default:
            break;
        }
    };

    const sendNoAnswer = function() {
        switch(scope.pluginInfo.statusCode) {
            case 18:
                sendContinueRecord(4);
            break;
            default:
            break;
        }
    };

    const printMerchantReceipt = function(duplicateCopy) {
        let printer = _.get(ActiveSale, 'printerDocumentData.printer');

        if(printer && scope.merchantReceipt) {
            let receiptToPrint = scope.merchantReceipt;

            if(duplicateCopy) {
                receiptToPrint = [
                    _.pad(`*Duplicate Copy*`, tailColumns, ' '),
                    _.repeat(' ', tailColumns)
                ].join('\n') + '\n' + receiptToPrint;
            }

            documentPrinter.printFreeNonFiscal(receiptToPrint, printer.id, { printHeader: true });
        }
    };

    const sendContinueRecord = function(code, params) {
        let command = ['CONTTXN', code];

        if(!_.isEmpty(params)) {
            command.push(_.map(params, function(val, key) { return [key, val].join('='); }).join(';'));
        }

        sendCommand(command.join(','));
    };

    const handleStatusChange = function(data) {
        if(!_.isEmpty(data)) {
            let statusInfo = getStatusInfo(data);
            scope.pluginInfo.readableStatus = statusInfo.statusName;

            switch(statusInfo.statusCode) {
                case 18: //Signature confirmation
                    //Handled by the user
                break;
                case 19: //Continue Required
                    sendContinueRecord(2);
                break;
                case 45: //Login Required
                    sendLoginInfo();
                break;
                case 46: //Ready
                    switch(scope.pluginInfo.status) {
                        case 'INIT':
                            //We don't need the login, send payment immediately
                            sendCommand(getPaymentMessage(scope.amount));
                        break;
                        case 'LOGIN_REQUIRED':
                            //We logged in successfully, the payment command will be handled by the integration socket restart
                        default:
                        break;
                    }
                break;
                case 49: //Transaction Canceled
                    returnError('USER_CANCELED');
                break;
                case 63: //Unexpected Login
                    sendContinueRecord(34, { 'MGRPIN': 12345 });
                break;
                case 76: //Server connection failed
                    sendContinueRecord(40);
                break;
                case 170: //Merchant Receipt
                    scope.merchantReceipt = parseReceipt(statusInfo.params['XML'], 'MERCHANT');
                    printMerchantReceipt();
                break;
                case 256: //Print Customer Receipt Confirmation
                    sendContinueRecord(74);
                break;
                case 530: //Customer Receipt
                    scope.customerReceipt = parseReceipt(statusInfo.params['XML'], 'CARDHOLDER');
                break;
                default:
                break;
            }

            _.assign(scope.pluginInfo, {
                status: _(statusInfo.statusName).thru(_.snakeCase).toUpper(),
                statusCode: statusInfo.statusCode,
                requiresConfirmation: _.includes([18], statusInfo.statusCode)
            });

            errorsLogger.debug(statusInfo);
        }
    };

    const handleResponse = function(data) {
        if(!_.isEmpty(data)) {
            let responseObj = parseResponseData(data);

            if(responseObj.terminateLoop) {
                switch(responseObj.resultCode) {
                    case 0: //Completed
                        if(_.includes(responseObj.statusMessage, 'Login Successful')) {
                            closeConnection('integrationSocket').then(function() {
                                establishPortConnection(25000, 'integrationSocket').then(function() {
                                    sendCommand(getPaymentMessage(scope.amount));
                                }, returnError);
                            });
                        } else {
                            completeTransaction(responseObj);
                        }
                    break;
                    case 2: //Referred
                    case 6: //Authorised
                    break;
                    case 5: //Declined
                    case 7: //Declined/Rejected/Reversed
                    case 8: //Communications failure
                    default: //Unhandled/Error
                        returnError(responseObj.statusMessage);
                    break;
                }
            }
        }
    };

    const checkBuffer = function(info, socketName, callback) {
        responseBuffers[socketName] += ab2str(info.data);

        if(_.endsWith(responseBuffers[socketName], '\n')) {
            callback(responseBuffers[socketName]);
            responseBuffers[socketName] = "";
        }
    };

    const responseHandler = function(info) {
        switch(info.socketId) {
            case scope.statusSocket.socketId:
                checkBuffer(info, 'statusSocket', handleStatusChange);
            break;
            case scope.integrationSocket.socketId:
                checkBuffer(info, 'integrationSocket', handleResponse);
            break;
            default:
            break;
        }
    };

    const startTransactionLoop = function() {
        $window.chrome.sockets.tcp.onReceive.addListener(responseHandler);
        $window.chrome.sockets.tcp.onReceiveError.addListener(errorListener);

        scope.pluginInfo.status = 'INIT';

        let connections = [
            { port: 25000, socketName: 'integrationSocket' },
            { port: 25001, socketName: 'statusSocket' }
        ];

        async.eachSeries(connections, async.asyncify(function(connection) {
            return establishPortConnection(connection.port, connection.socketName);
        }), function(err, res) {
            if(_.isNil(err)) {
                _.assign(scope.pluginInfo, {
                    connectedAt: new Date()
                });
            } else {
                returnError(err);
            }
        });
    };

    /**
     * public methods
     */

    let verifoneOciusDriver = {
        /**
         * init
         * @param  {string} loginInfo
         * @param  {string} ipAddress
         */
        init: function(loginInfo, ipAddress, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            scope = {
                loginInfo: loginInfo,
                ipAddress: ipAddress,
                log: checkManager.getPreference('verifone_ocius.log_data'),
                options: options,
                pluginInfo: {
                    status: 'IDLE',
                    connectedAt: null
                }
            };
        },
        getStatus: function() {
            return _.clone(scope.pluginInfo);
        },

        /**
         * [performPayment description]
         * @param  {[number]} amount ex. 2.50
         */
        performPayment: function(amount) {
            let d = $q.defer();

            errorsLogger.debug("Start paying " + amount + "...");

            _.assign(scope, {
                amount: amount,
                deferred: d,
            });

            startTransactionLoop();

            return d.promise;
        },
        sendYesAnswer: function() {
            if(scope.integrationSocket) {
                sendYesAnswer();
            }
        },
        sendNoAnswer: function() {
            if(scope.integrationSocket) {
                sendNoAnswer();
            }
        },
        reprintMerchantReceipt: function() {
            if(scope.integrationSocket && scope.merchantReceipt) {
                printMerchantReceipt(true);
            }
        },
        cancelPayment: function() {
            if(scope.integrationSocket) {
                sendContinueRecord(12);
            }
        }
    };

    return verifoneOciusDriver;
}]);