import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {TutorialInAppService} from '../../../core/services';
import {ShepherdService} from 'angular-shepherd';

@Injectable()
export class TutorialInAppSuppliersService extends TutorialInAppService {

    constructor(protected translate: TranslateService, protected shepherdService: ShepherdService) {
        super(translate, shepherdService);
    }

    protected generateSteps() {

        this.steps = [
            {
              attachTo: {
                element: '.first-element',
                on: 'bottom'
              },
              buttons: [
                this.builtInButtons.cancel,
                this.builtInButtons.next
              ],
              classes: 'custom-class-name-1 custom-class-name-2',
              id: 'intro',
              title: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_1.TITLE"),
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_1.TEXT")
            },
            {
              attachTo: {
                element: '.module-name',
                on: 'bottom'
              },
              buttons: [
                this.builtInButtons.cancel,
                this.builtInButtons.back,
                this.builtInButtons.next
              ],
              classes: 'custom-class-name-1 custom-class-name-2',
              id: 'intro',
              title: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_2.TITLE"),
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_2.TEXT")
            },
            {
              attachTo: {
                element: '.full-search-element',
                on: 'bottom'
              },
              buttons: [
                this.builtInButtons.cancel,
                this.builtInButtons.back,
                this.builtInButtons.next
              ],
              classes: 'custom-class-name-1 custom-class-name-2',
              id: 'installation',
              title: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_3.TITLE"),
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_3.TEXT")
            },
            {
              attachTo: {
                element: '.vertical-menu-element',
                on: 'bottom'
              },
              buttons: [
                this.builtInButtons.cancel,
                this.builtInButtons.back,
                this.builtInButtons.next
              ],
              classes: 'custom-class-name-1 custom-class-name-2',
              id: 'usage',
              title: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_4.TITLE"),
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_4.TEXT")
            },
            {
              attachTo: {
                element: '.horizontal-menu-element',
                on: 'bottom'
              },
              buttons: [
                this.builtInButtons.cancel,
                this.builtInButtons.back,
                this.builtInButtons.next
              ],
              classes: 'custom-class-name-1 custom-class-name-2',
              id: 'menu_orizzontale',
              title: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_5.TITLE"),
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_5.TEXT")
            },
            {
              buttons: [
                this.builtInButtons.cancel,
                this.builtInButtons.back
              ],
              id: 'noAttachTo',
              classes: 'custom-class-name-1 custom-class-name-2',
              title: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_6.TITLE"),
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.STEPS.STEP_6.TEXT")
            }
          ];
    }
}
