import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { DigitalPaymentHandler, DigitalPaymentHandlerOptions, DigitalPaymentHandlerResult } from 'src/app/shared/model/digital-payments.model';
import { validate as validateUuid } from 'uuid';
import { SalesPayments } from 'tilby-models';
import { stringToLines } from 'src/app/shared/string-utils';

type RedsysProtocolVersion = '5.1' | '6.1' | '8.1';

type RedsysConfig = {
    devMode: boolean,
    merchantId: string,
    terminalId: string,
    signature: string,
    port: string,
    version: RedsysProtocolVersion
};

type RedsysParams = {
    cComercio: string,
    cTerminal: string,
    cClaveFirma: string,
    cConfPuerto: string,
    cVersion: RedsysProtocolVersion,
    cTipoOper: 'PAGO' | 'DEVOLUCION',
    cImporte: string,
    cFactura: string,
    cNumPedido?: string,
    cRTSOriginal?: string,
}

type RedsysTransactionParams = Pick<RedsysParams, 'cImporte' | 'cTipoOper' | 'cNumPedido' | 'cRTSOriginal'>

type RedsysOperationError = {
    codigo: string,
    mensaje: string
    descripcion?: string
}

type RedsysOperationResult = {
    tipoPago: string,
    importe: string,
    moneda: string,
    tarjetaClienteRecibo: string,
    tarjetaComercioRecibo: string,
    marcaTarjeta: string,
    caducidad: string,
    comercio: string,
    terminal: string,
    pedido: string,
    tipoTasaAplicada: string,
    identificadorRTS: string,
    factura: string,
    fechaOperacion: string,
    estado: string,
    resultado: string,
    codigoRespuesta: string,
    firma: string,
    operacionemv: string,
    resverificacion: string,
    conttrans: string,
    sectarjeta: string,
    idapp: string,
    DDFName: string,
    etiquetaApp: string,
    operContactLess: string,
    Literales: {
        autenticadoPorPin: string,
    }
    ReciboSoloCliente: string
}

export class RedsysPOS implements DigitalPaymentHandler {
    constructor(
        private $translate: any,
        private entityManager: any,
        private errorsLogger: any,
        private waitDialog: any,
        private util: any
    ) {
    }

    private xmlNodeToJSON(node: Element | ChildNode): any {
        const obj: any = {};
        const children = node.childNodes;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.nodeType === 1) {
                if (child.childNodes.length === 1 && child.childNodes[0].nodeType === 3) {
                    obj[child.nodeName] = child.childNodes[0].nodeValue;
                } else {
                    obj[child.nodeName] = this.xmlNodeToJSON(child);
                }
            }
        }
        return obj;
    }

    private parseResult(result: string) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(result, "text/xml");

        //Check if result is a successful operation (payment)
        const paymentResultNode = xmlDoc.getElementsByTagName("resultadoOperacion")[0];

        if (paymentResultNode) {
            const operationResult = this.xmlNodeToJSON(paymentResultNode) as RedsysOperationResult;

            if (operationResult.estado != 'F' || operationResult.resultado != 'Autorizada') {
                throw 'TRANSACTION_DENIED';
            }

            return operationResult;
        }

        //Check if result is a successful operation (refund)
        const refundResultNode = xmlDoc.getElementsByTagName('comunicacionContable')[0];

        if (refundResultNode && refundResultNode.getAttribute('tipo') === 'DEVOLUCION') {
            const operationResult = this.xmlNodeToJSON(refundResultNode).resultadoComunicacion as RedsysOperationResult;

            if (operationResult.estado != 'F' || operationResult.resultado != 'Autorizada') {
                throw 'TRANSACTION_DENIED';
            }

            return operationResult;
        }

        //Check if result is an error
        const operationErrorNode = xmlDoc.getElementsByTagName("Error")[0];

        if (operationErrorNode) {
            const operationError = this.xmlNodeToJSON(operationErrorNode) as RedsysOperationError;
            throw operationError;
        }

        //If no parsable document is found, throw result as an error
        throw result || 'UNKNOWN_ERROR';
    }

    private getPaymentTail(operationResult: RedsysOperationResult, operationType: string): string {
        const receiptLines: String[] = [
            'COPIA PARA EL CLIENTE',
            '',
            ...(operationResult.operContactLess === 'TRUE' ?
                ['CONTACTLESS', ''] :
                []
            ),
            'PRUEBAS REDSYS',
            `Comercio: ${operationResult['comercio']}`,
            `Terminal: ${operationResult['terminal']}`,
            '',
            ...(operationType === 'PAGO' ?
                [
                    `${operationResult['tarjetaClienteRecibo']}`,
                    '',
                    `CAD: ${operationResult['caducidad']}`,
                    ''
                ] :
                []
            ),
            (operationType === 'PAGO' ? 'VENTA' : 'DEVOLUCION'),
            '',
            `Aut: ${operationResult['codigoRespuesta'] || ''}`,
            ...(operationType === 'PAGO' ?
                ['CRED'] :
                []
            ),
            `Pedido: ${operationResult['pedido']}`,
            `Fecha: ${moment(operationResult['fechaOperacion']).format('YYYY-MM-DD')}}`,
            `Hora: ${moment(operationResult['fechaOperacion']).format('HH:mm:ss')}`,
            '',
            `${operationResult['resultado']}`,
            '',
            ...(operationType === 'PAGO' ?
                [
                    `${operationResult['etiquetaApp']}`,
                    '',
                    `Aplicación: ${operationResult['idapp']}`,
                    `N. Trans: ${operationResult['conttrans']}`,
                    `TVR: ${operationResult['resverificacion']}`,
                    ''
                ] :
                []
            ),
            `${operationResult['importe']} EUR`
        ];

        if (operationResult.Literales) {
            receiptLines.push('');

            for (let row of Object.values(operationResult.Literales)) {
                receiptLines.push(...stringToLines(row, 32));
            }
        }

        return receiptLines.join('\n');
    }

    public async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        if (!window.require) {
            throw 'INVALID_ENVIRONMENT';
        }

        const transactionParams: RedsysTransactionParams = {
            cImporte: Math.abs(amount).toFixed(2),
            cTipoOper: 'PAGO',
        };

        return this.performTransaction(transactionParams, options);
    }

    public async refund(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        if (!window.require) {
            throw 'INVALID_ENVIRONMENT';
        }

        let currentSale = options.sale;

        if (!currentSale) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let parentSaleId = currentSale.sale_parent_id || currentSale.sale_parent_uuid;

        if (!parentSaleId) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let parentSale;

        if (validateUuid(parentSaleId.toString())) {
            let results = await this.entityManager.sales.fetchCollectionOnline({ uuid: parentSaleId });
            parentSale = results[0];
        } else {
            parentSale = await this.entityManager.sales.fetchOneOfflineFirst(parentSaleId);
        }

        if (!parentSale) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let redsysPOSPayment = parentSale.payments?.find((salePayment: SalesPayments) => (salePayment.payment_method_type_id === 37));

        if (!redsysPOSPayment) {
            throw 'CANNOT_FIND_ORIGINAL_PAYMENT';
        }

        let originalPayment: RedsysOperationResult = JSON.parse(redsysPOSPayment.payment_data);

        const transactionParams: RedsysTransactionParams = {
            cImporte: Math.abs(amount).toFixed(2),
            cTipoOper: 'DEVOLUCION',
            cNumPedido: originalPayment.pedido,
            cRTSOriginal: originalPayment.identificadorRTS
        }

        return this.performTransaction(transactionParams, options);
    }

    public async performTransaction(transactionParams: RedsysTransactionParams, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        if (!window.require) {
            throw 'INVALID_ENVIRONMENT';
        }

        //Prepare the payment
        const { ipcRenderer } = window.require('electron');

        try {
            const paymentConfiguration = (options.paymentMethod.configuration || {}) as RedsysConfig;

            const callParams: RedsysParams = Object.assign({
                cComercio: paymentConfiguration.merchantId,
                cTerminal: paymentConfiguration.terminalId,
                cClaveFirma: paymentConfiguration.signature,
                cConfPuerto: paymentConfiguration.port,
                cVersion: paymentConfiguration.version,
                cFactura: options.sale.uuid!
            }, transactionParams);

            const paramNames = Object.keys(callParams) as Array<keyof RedsysParams>;

            for (let key of paramNames) {
                if (!callParams[key]) {
                    throw `ERROR_MISSING_${key.toUpperCase()}`;
                }
            }

            //Start the transaction
            const transaction = ipcRenderer.invoke('redsys:start-payment', { params: callParams, devMode: paymentConfiguration.devMode });

            let transactionResult = await this.waitDialog.show({ message: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.COMPLETE_ON_POS'), promise: transaction });

            const transactionResponse = this.parseResult(transactionResult);

            return {
                payment_data: JSON.stringify(transactionResponse),
                tail: this.getPaymentTail(transactionResponse, transactionParams.cTipoOper),
                unclaimed: false
            }
        } catch (err: any) {
            this.errorsLogger.sendReport({
                type: 'RedsysPOSError',
                content: JSON.stringify(err)
            });

            if (err?.mensaje) {
                throw err.mensaje;
            } else if (typeof err == 'string') {
                throw `DIGITAL_PAYMENTS.REDSYS.${err.replace(/[\r\n-]/g, '').toUpperCase()}`;
            } else {
                throw 'UNKNOWN_ERROR';
            }
        }
    }
}

RedsysPOS.$inject = ['$translate', 'entityManager', 'errorsLogger', 'waitDialog', 'util'];

angular.module('digitalPayments').service('RedsysPOS', RedsysPOS);