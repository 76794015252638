import * as angular from 'angular';
import * as _ from 'lodash';
import { validate as validateUuid } from 'uuid';

angular.module('digitalPayments').factory('poynt', poynt);

poynt.$inject = ["$q", "$window", "$translate", "$rootScope"];

function poynt($q, $window, $translate, $rootScope) {
    var resetRemainingOfflinePeriod = function() {
        $window.localStorage.setItem("poyntRemainingOfflinePeriod", _.toString(7200));
    };

    var getRemainingOfflinePeriod = function() {
        return _.toInteger($window.localStorage.getItem("poyntRemainingOfflinePeriod"));
    };

    var decreaseRemainingOfflinePeriod = function(value) {
        var currentRemainingPeriod = $window.localStorage.getItem("poyntRemainingOfflinePeriod");
        $window.localStorage.setItem("poyntRemainingOfflinePeriod", _.toString(currentRemainingPeriod - value));
    };

    var disableRemainingOfflinePeriod = function(value) {
        $window.localStorage.setItem("poyntRemainingOfflinePeriod");
    };

    var poynt = {
        payment: function(amount, options) {
            var d = $q.defer();
            var saleId = options.sale.id;
            var calltrx = $rootScope.userActiveSession.shop.name + '-' + (validateUuid(saleId) ? saleId.substring(0,8): saleId);

            $window.Poynt.launchPayment(_.round(amount * 100, 0), calltrx, function(result) {
                d.resolve({
                    acquirer_id : result.acquirer_id,
                    acquirer_name : result.acquirer_name,
                    card_circuit_name: result.acquirer_name,
                    payment_data: "",
                    tail: "",
                    unclaimed: false
                });
            }, function(error) {
                if(_.get(error, 'status')) {
                    d.reject(error.status);
                } else {
                    d.reject(error);
                }
            });

            return d.promise;
        },
        checkSubscriptionStatus: function(context) {
            var amountToDecrease = context === 'startup' ? 500 : 1;
            var subD = $q.defer();

            var checkOfflinePeriod = function() {
                var remainingOfflinePeriod = getRemainingOfflinePeriod();
                if(remainingOfflinePeriod > 0) {
                    decreaseRemainingOfflinePeriod(amountToDecrease);
                    subD.resolve();
                } else {
                    //Cannot check subscriptions
                    subD.reject(poynt.billingStatusCheckError);
                }
            };

            $window.Poynt.billing.checkSubscription(function(subscriptions) {
                try {
                    var subscr = JSON.parse(subscriptions);
                    if(!_.isEmpty(subscr.list) && _.some(subscr.list, { status: 'ACTIVE' })) {
                        resetRemainingOfflinePeriod();
                        subD.resolve();
                    } else {
                        disableRemainingOfflinePeriod();
                        subD.reject(poynt.missingSubscriptionError);
                    }
                } catch(e) {
                    checkOfflinePeriod();
                }
            }, checkOfflinePeriod);

            return subD.promise;
        },
        get billingStatusCheckError() { return $translate.instant('DIGITAL_PAYMENTS.POYNT.BILLING_STATUS_CHECK_ERROR'); },
        get missingSubscriptionError() { return $translate.instant('DIGITAL_PAYMENTS.POYNT.MISSING_SUBSCRIPTION_ERROR'); },
        get subscriptionProcessError() { return $translate.instant('DIGITAL_PAYMENTS.POYNT.SUBSCRIPTION_PROCESS_ERROR'); }
    };

    return poynt;
}