import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { Categories, Items } from 'tilby-models';
import { TilbyItemElementComponent } from "@tilby/tilby-ui-lib/components/tilby-item-element";

@Component({
  selector: 'app-cashregister-search-items',
  templateUrl: './cashregister-search-items.component.html',
  styleUrls: ['./cashregister-search-items.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatListModule, TilbyGesturesDirective, ScrollingModule, TilbyCurrencyPipe, MatProgressSpinnerModule, MatSelectModule, TilbyItemElementComponent]
})
export class CashregisterSearchItemsComponent implements OnInit {

    protected screenHeight=screen.height;

    @Input() getItemStockStatus : Function = () => {};
    @Output() showItem = new EventEmitter<Items>();
    @Output() addItemToSale = new EventEmitter<Items>();
    @Input() priceList : string = 'price1';
    @Output() searchItems = new EventEmitter<{ forceSearch: boolean, allCategories: boolean, categoryFilter?: string | number, typeFilter?: string, stockFilter?: string }>();
    @Input() showUnavailableStock: boolean = true;
    @Input() showThumbnail: boolean = true;
    @Input() truncatePriceWithDots: boolean = true;
    @Input() showUnavailable: boolean = true;
    @Input() visibleCategories : Categories[] = [];
    @Input() selectedCategory : Categories | undefined;

    @Input() isLoading : boolean = false;
    @Input() firstCategoryBorderRounded: boolean = false;

    categoryFilter : number | string | undefined = "ALL" ;
    typeFilter : string | undefined = "ALL";
    stockFilter : string | undefined = "ALL";

    @Input() searchResults: {hasResults: boolean, allCategories: boolean | undefined, results: {id: string, name: string, items: Items[]}[]} = {
        "allCategories": false,
        "hasResults": false,
        "results": []
    };

    ngOnInit(): void {
        this.categoryFilter = this.selectedCategory?.id || "ALL" ;
        this.stockFilter = this.showUnavailable ? "ALL" : "ONLY_STOCK";
    }

    trackByFunction(index: number, item: Items): number {
        return item.id!;
    }
}
