import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SkeletonLoaderService {
    isLoading = new Subject<boolean>();
    constructor() {}
}
