<ng-container *ngIf="!hide">
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downloadFile()">
            <span>{{ 'FILE_IMPORTER.SHOWCASE.DOWNLOAD_ORIGINAL' | translate }}</span>
        </button>
        <button *ngIf="showDownloadDetailedErrors" mat-menu-item (click)="downloadErrors()">
            <span>{{ 'FILE_IMPORTER.SHOWCASE.DOWNLOAD_ERRORS' | translate }}</span>
        </button>
        <button  *ngIf="showDownloadDetailedErrors" mat-menu-item (click)="downloadDetailedErrors()">
            <span>{{ 'FILE_IMPORTER.SHOWCASE.DOWNLOAD_DETAILED_ERRORS' | translate }}</span>
        </button>
        <button mat-menu-item (click)="cloneImport()">
            <span>{{ 'FILE_IMPORTER.SHOWCASE.CLONE' | translate }}</span>
        </button>
    </mat-menu>
</ng-container>
