import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

angular.module('fileImporter').controller('FileImporterScheduleCtrl', ["$rootScope", "$scope", "$state", "$translate", "restManager", "alertDialog", "fileConfig", function($rootScope, $scope, $state, $translate, restManager, alertDialog, fileConfig) {
    _.assign($scope, {
        topbar_context: {
            title: $translate.instant('FILE_IMPORTER.SCHEDULE.TITLE'),
            canGoBack: function() { return true; },
            goBack: function() {
                $state.go('app.file_importer.fields', {
                    fileConfig: $scope.fileConfig,
                    id: $scope.fileConfig.id
                });
            },
            canConfirm: function() { return true; }
        },
        weekDays: _.zipWith(moment.localeData('en').weekdaysMin(false), moment.weekdays(false), function(id, name) {
            return {
                id: id,
                name: _.upperFirst(name)
            };
        })
    });

    $scope.scheduleType = 'now';
    $scope.scheduleDateTime = moment().milliseconds(0).seconds(0).toDate();
    $scope.fileConfig = _.cloneDeep(fileConfig);

    $scope.topbar_context.confirm = function() {
        if($scope.fileImporterScheduleForm.$valid) {
            if(!$scope.isProcessing) {
                $scope.isProcessing = true;

                var dataToSend = _.cloneDeep($scope.fileConfig);
    
                switch($scope.scheduleType) {
                    case 'now':
                        _.assign(dataToSend, {
                            executionTime: 'now',
                            executionTimePeriods: null
                        });
                    break;
                    case 'at_time':
                        _.assign(dataToSend, {
                            executionTime: moment($scope.scheduleDateTime).toISOString(),
                            executionTimePeriods: null
                        });
                    break;
                    case 'repeatedly':
                        _.assign(dataToSend, {
                            executionTime: moment($scope.scheduleDateTime).toISOString(),
                        });
                    break;
                }
    
                restManager.post('importing/token', { token: "Bearer " + $rootScope.userActiveSession.oauthdata.access_token }).then(function() {
                    return restManager.put('importing/file', 'config', dataToSend);
                }).then(function() {
                    $state.go('app.file_importer.showcase');
                }).catch(function(error) {
                    alertDialog.show($translate.instant('FILE_IMPORTER.SCHEDULE.FILE_SAVE_ERROR'));
                }).finally(function() {
                    $scope.isProcessing = false;
                });
            }
        } else {
            alertDialog.show($translate.instant($scope.fileImporterScheduleForm.$error.required ? 'FORM_ERRORS.INSERT_REQUIRED_DATA' : 'FORM_ERRORS.THERE_ARE_ERRORS'));
        }
    };
}]);