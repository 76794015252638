import 'src/prototype_chaining';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule} from '@angular/core';

import {UpgradeModule} from "@angular/upgrade/static";
import {
    activeSiteDialogServiceProvider,
    cashlogyDriverServiceProvider,
    customerUtilsServiceProvider,
    customerDisplayManagerServiceProvider,
    dashboardDataManagerServiceProvider,
    deviceInfoDialogServiceProvider,
    digitalPaymentsManagerServiceProvider,
    documentPrinterServiceProvider,
    errorsLoggerServiceProvider,
    ExternalOrdersManagerServiceProvider,
    ExternalSalesManagerServiceProvider,
    FiscalPrintersServiceProvider,
    FiscalProvidersServiceProvider,
    fiscalUtilsServiceProvider,
    generalDocumentPrinterSelectServiceProvider,
    mdSidenavServiceProvider,
    optionsDialogServiceProvider,
    printerErrorFiscalServiceProvider,
    promptDialogServiceProvider,
    radioListSelectorServiceProvider,
    restManagerServiceProvider,
    operatorManagerServiceProvider,
    rootScopeServiceProvider,
    saleUtilsServiceProvider,
    sessionManagerServiceProvider,
    stateParamsServiceProvider,
    stateServiceProvider,
    utilServiceProvider,
    RetailForceProviderServiceProvider,
    itemLabelsGeneratorServiceProvider,
    translateServiceProvider,
    urlServiceProvider,
    kioskUtilsServiceProvider,
    addSelectCustomerDialogServiceProvider,
    savedSalesDialogServiceProvider,
    saleItemManagerServiceProvider,
    DigitalPaymentsManagerServiceProvider,
    showSaleItemDialogServiceProvider,
    selectSaleItemCombinationServiceProvider,
    itemListSelectorServiceProvider,
    giftCardPrinterServiceProvider,
    prepaidSaleServiceProvider,
    noFiscalPrintersServiceProvider,
    splitSaleDialogServiceProvider,
    eInvoicePluginServiceProvider,
    itemSelectorServiceProvider,
    authRequestDialogServiceProvider,
    leanPMSServiceProvider,
    campgestPMSServiceProvider,
    wellbyPMSServiceProvider
} from 'app/ajs-upgraded-providers';
import {MatIconRegistry} from "@angular/material/icon";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {NewMaterialSidenavComponent} from "./core/components/new-material-sidenav/new-material-sidenav.component";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {IModuleTranslationOptions, ModuleTranslateLoader} from "@larscom/ngx-translate-module-loader";
import {AppComponent} from "./app.component";
import {
    BookingsComponent,
    CashregisterComponent,
    FidelityComponent,
    FileImporterComponent,
    GiftcardShowcaseComponent,
    GiftcardTypesPanelComponent,
    HistoryCashMovementsComponent,
    HistoryComponent,
    HistoryEInvoicesComponent,
    HistoryEInvoicesPassiveComponent,
    HistorySalesShowcaseComponent,
    ItemsComponent,
    KioskComponent,
    LockScreenComponent,
    OrdersComponent,
    PrepaidComponent,
    PrintersComponent,
    PromotionsComponent, RoomComponent, RoomElementComponent,
    SettingsBatchComponent,
    SettingsChannelsComponent,
    SettingsComponent,
    SettingsECommerceComponent,
    SettingsGeneralComponent,
    SettingsKioskComponent,
    SettingsLabelPanelComponent,
    SettingsModelDocumentComponent,
    SettingsMySubscriptionComponent,
    SettingsShiftsComponent,
    SettingsUmStaticTokenComponent,
    SettingsUserComponent,
    SettingsUsersManagementComponent,
    StockComponent,
    StockManagementComponent,
    StockMovementsComponent,
    TablesComponent,
    SettingsAlvoloInitializedComponent,
    SettingsAlvoloNotInitializedComponent,
    SettingsAlvoloOptionsComponent,
    SettingsAlvoloContentComponent,
    SettingsAlvoloComponent,
    SettingsFileExporterShowcaseComponent
} from "./features";

import {DialogsModule} from "./dialogs/dialogs.module";
import {
    GlobalErrorHandler,
    LoaderService,
    UserActiveSessionManagerService
} from './core/services';
import {
    SuppliersShowcaseComponent
} from './features/suppliers/components/suppliers-showcase/suppliers-showcase.component';
import {SuppliersDetailsComponent} from './features/suppliers/components/suppliers-details/suppliers-details.component';
import {SharedModule} from "./shared/shared.module";
import {
    CustomersShowcaseComponent
} from './features/customers/components/customers-showcase/customers-showcase.component';
import {PipesModule} from "./pipes/pipes.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CustomersDetailsComponent} from "./features/customers/components/customers-details/customers-details.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

import {ShepherdService} from "angular-shepherd";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {
    SkeletonArticleComponent,
    SkeletonCardComponent,
    SkeletonContactComponent,
    SkeletonGridRowComponent,
    SkeletonPageComponent,
    SkeletonTopbarComponent,
} from "./core/components/skeleton-loaders";
import {UpgradeUiViewDirective} from "../Ajs<--->A14";
import {FixedNavListComponent} from "./core/components/new-material-sidenav/fixed-nav-list/fixed-nav-list.component";
import {GeneralizedToolbarComponent} from "./core/components/generalized-toolbar/generalized-toolbar.component";
import {LeftToolbarComponent} from './core/components/generalized-toolbar/left-toolbar/left-toolbar.component';
import {TutorialInAppSuppliersService} from './features/suppliers/services/tutorial-in-app.suppliers.service';
import {SearchBarComponent} from './core/components/generalized-toolbar/search-bar/search-bar.component';
import {ButtonsBarComponent} from './core/components/generalized-toolbar/buttons-bar/buttons-bar.component';
import {
    CustomerFormComponent
} from "./features/customers/components/customers-details/tab-customer/customer-form/customer-form.component";
import {
    TabCustomerComponent
} from "./features/customers/components/customers-details/tab-customer/tab-customer.component";
import {
    CustomerSummaryComponent
} from "./features/customers/components/customers-details/tab-customer/customer-summary/customer-summary.component";
import {GridTraductionsService} from "./shared/components/grid";
import {
    CustomerPrepaidCreditComponent
} from './features/customers/components/customers-details/tab-customer/customer-prepaid-credit/customer-prepaid-credit.component';
import {
    CustomerFidelityPointsComponent
} from './features/customers/components/customers-details/tab-customer/customer-fidelity-points/customer-fidelity-points.component';
import {
    CustomerFidelityPointsFormComponent
} from './features/customers/components/customers-details/tab-customer/customer-fidelity-points/customer-fidelity-points-form/customer-fidelity-points-form.component';
import {GridCellFormatterService} from './shared/components/grid/utils/grid-cell-formatter.service';
import {
    CustomerPrepaidCreditFormComponent
} from './features/customers/components/customers-details/tab-customer/customer-prepaid-credit/customer-prepaid-credit-form/customer-prepaid-credit-form.component';
import {CurrencyPipe, DatePipe, DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData, NgIf} from '@angular/common';
import {GridCellExportFormatterService} from './shared/components/grid/utils/grid-cell-export-formatter.service';
import {DataTestDirective} from './directives/data-test.directive';
import {GridCellExportStyleService} from './shared/components/grid/utils/grid-cell-export-style.service';
import {PortalModule} from '@angular/cdk/portal';
import {
    HistorySaleDetailComponent
} from './features/history/history-sales/components/history-sale-detail/history-sale-detail.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatListModule} from '@angular/material/list';
import {
    HistorySaleDetailToolbarComponent
} from './features/history/history-sales/components/history-sale-detail/history-sale-detail-toolbar/history-sale-detail-toolbar.component';
import {
    HistorySaleDetailToolbarContentComponent
} from './features/history/history-sales/components/history-sale-detail/history-sale-detail-toolbar-content/history-sale-detail-toolbar-content.component';
import {HistoryUtilsService} from './features/history/history-sales/services/history-utils.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {
    LeftContextToolbarComponent
} from "./core/components/generalized-toolbar/left-context-toolbar/left-context-toolbar.component";
import {DashboardComponent} from './features/dashboard/components/dashboard/dashboard.component';
import {
    SkeletonDashboardComponent
} from './core/components/skeleton-loaders/skeleton-dashboard/skeleton-dashboard.component';
import {SkeletonDirective} from "./directives/skeleton.directive";
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localeDa from '@angular/common/locales/da';
import localeZh from '@angular/common/locales/zh';
import { LocaleProvider } from './core/services/locale/locale.provider';
import { TabSettingsUmComponent } from './features/settings/settings-users-management/components/settings-users-management/tab-settings-um/tab-settings-um.component';
import { SettingsUmQrcodeWaiterComponent } from './features/settings/settings-users-management/components/settings-users-management/tab-settings-um/settings-um-qrcode-waiter/settings-um-qrcode-waiter.component';
import { SettingsUmUsersComponent } from './features/settings/settings-users-management/components/settings-users-management/tab-settings-um/settings-um-users/settings-um-users.component';
import { QRCodeModule } from 'angularx-qrcode';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { SettingsUmListComponent } from './features/settings/settings-users-management/components/settings-users-management/settings-um-list/settings-um-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SettingsUsersManagementService } from './features/settings/settings-users-management/services/settings-users-management.services';
import { MatchesLabelPanelPipe, MatchesModelDocumentPipe, MatchesShiftPipe, MatchesChannelPipe } from './pipes';
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {TilbyMagicFormComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { TopbarTablesTotalComponent } from './features/tables/components/topbar-tables-total';
import {LOADER_SERVICE_TOKEN, TilbyPullToRequestComponent} from "@tilby/tilby-ui-lib/components/tilby-pull-to-request";
import {TilbyInfoPanelComponent} from "@tilby/tilby-ui-lib/components/tilby-info-panel";
import {MatDividerModule} from "@angular/material/divider";
import {SidenavMenuComponent} from "./core";
import {TilbyShopCartComponent} from "@tilby/tilby-ui-lib/components/tilby-order";
import {TilbyGeneralInputButtonComponent,TilbyGeneralInputButtonPanelComponent} from "@tilby/tilby-ui-lib/components/tilby-buttons";

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDa, 'da');
registerLocaleData(localeZh, 'zh');

export function ModuleHttpLoaderFactory(http: HttpClient) {
    const baseTranslateUrl = './l10n';
    const pathTemplate = '{baseTranslateUrl}/{language}/{moduleName}';
    const options: IModuleTranslationOptions = {
        disableNamespace: true,
        version: Date.now(),
        translateError: (error, path) => {
            console.log('Ops! an error occurred: ', { error, path });
        },
        modules: [
            // final url: ./assets/i18n/en.json
            // { baseTranslateUrl },
            // final url: ./assets/i18n/feature1/en.json
            { baseTranslateUrl, moduleName: 'application', pathTemplate },
            { baseTranslateUrl, moduleName: 'tables', pathTemplate },
            { baseTranslateUrl, moduleName: 'orders', pathTemplate },
            { baseTranslateUrl, moduleName: 'printers', pathTemplate },
            { baseTranslateUrl, moduleName: 'cashregister', pathTemplate },
            { baseTranslateUrl, moduleName: 'items', pathTemplate },
            { baseTranslateUrl, moduleName: 'history', pathTemplate },
            { baseTranslateUrl, moduleName: 'customers', pathTemplate },
            { baseTranslateUrl, moduleName: 'digital_payments', pathTemplate },
            { baseTranslateUrl, moduleName: 'settings', pathTemplate },
            { baseTranslateUrl, moduleName: 'suppliers', pathTemplate },
            { baseTranslateUrl, moduleName: 'stock', pathTemplate },
            { baseTranslateUrl, moduleName: 'fidelity', pathTemplate },
            { baseTranslateUrl, moduleName: 'prepaid', pathTemplate },
            { baseTranslateUrl, moduleName: 'dashboard', pathTemplate },
            { baseTranslateUrl, moduleName: 'bookings', pathTemplate },
            { baseTranslateUrl, moduleName: 'kiosk', pathTemplate },
            { baseTranslateUrl, moduleName: 'promotions', pathTemplate },
            { baseTranslateUrl, moduleName: 'file_importer', pathTemplate },
            { baseTranslateUrl, moduleName: 'file_exporter', pathTemplate },
            { baseTranslateUrl, moduleName: 'dialog', pathTemplate },
            { baseTranslateUrl, moduleName: 'giftcard', pathTemplate },
            { baseTranslateUrl, moduleName: 'grid', pathTemplate }
        ],
    };
    return new ModuleTranslateLoader(http, options);
}

const components = [
    FixedNavListComponent,
    GeneralizedToolbarComponent,
    LeftToolbarComponent,
    SearchBarComponent,
    NewMaterialSidenavComponent,
    BookingsComponent,
    DashboardComponent,
    FidelityComponent,
    FileImporterComponent,
    SettingsFileExporterShowcaseComponent,
    HistoryComponent,
    HistoryCashMovementsComponent,
    HistoryEInvoicesComponent,
    HistoryEInvoicesPassiveComponent,
    ItemsComponent,
    LockScreenComponent,
    KioskComponent,
    OrdersComponent,
    PrepaidComponent,
    PrintersComponent,
    PromotionsComponent,
    SettingsComponent,
    SettingsGeneralComponent,
    SettingsChannelsComponent,
    SettingsUsersManagementComponent,
    SettingsBatchComponent,
    SettingsKioskComponent,
    SettingsShiftsComponent,
    SettingsLabelPanelComponent,
    SettingsModelDocumentComponent,
    SettingsUserComponent,
    SettingsECommerceComponent,
    StockManagementComponent,
    StockMovementsComponent,
    StockComponent,
    SuppliersShowcaseComponent,
    DashboardComponent,
    SuppliersDetailsComponent,
    TabCustomerComponent,
    CustomersShowcaseComponent,
    CustomersDetailsComponent,
    TabCustomerComponent,
    CustomerFormComponent,
    CustomerSummaryComponent,
    SkeletonDashboardComponent,
    SkeletonPageComponent,
    SkeletonGridRowComponent,
    SkeletonCardComponent,
    SkeletonTopbarComponent,
    SkeletonArticleComponent,
    SkeletonContactComponent,
    CustomerPrepaidCreditComponent,
    CustomerFidelityPointsComponent,
    CustomerFidelityPointsFormComponent,
    CustomerPrepaidCreditFormComponent,
    HistorySalesShowcaseComponent,
    TabSettingsUmComponent,
    SettingsUmStaticTokenComponent,
    SettingsUmQrcodeWaiterComponent,
    SettingsUmUsersComponent,
    SettingsUmListComponent,
    GiftcardShowcaseComponent,
    GiftcardTypesPanelComponent,
    HistorySaleDetailComponent,
    HistorySaleDetailToolbarComponent,
    HistorySaleDetailToolbarContentComponent
];
const standaloneComponents = [
    ButtonsBarComponent,
    TilbyGesturesDirective,
    TilbyMagicFormComponent,
    RoomComponent,
    RoomElementComponent,
    TablesComponent,
    TopbarTablesTotalComponent,
    CashregisterComponent,
    UpgradeUiViewDirective,
    SettingsAlvoloComponent,
    SettingsAlvoloContentComponent,
    SettingsAlvoloInitializedComponent,
    SettingsAlvoloNotInitializedComponent,
    SettingsAlvoloOptionsComponent,
    TilbyPullToRequestComponent,
    TilbyInfoPanelComponent,
    SidenavMenuComponent,
    TilbyShopCartComponent,
    SkeletonDirective,
    TilbyGeneralInputButtonComponent,
    TilbyGeneralInputButtonPanelComponent,
    LeftContextToolbarComponent,
    SettingsMySubscriptionComponent
];

const pipes = [
    MatchesLabelPanelPipe,
    MatchesModelDocumentPipe,
    MatchesShiftPipe,
    MatchesChannelPipe
];

const directives = [
    DataTestDirective,
];

@NgModule({
    imports: [
        //AppRoutingModule,
        PipesModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        BrowserModule,
        DialogsModule,
        FormsModule,
        HttpClientModule,
        UpgradeModule,
        SharedModule,
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            useDefaultLang: true,
            isolate: true,
            loader: {
                provide: TranslateLoader,
                useFactory: ModuleHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        NgxSkeletonLoaderModule.forRoot(),
        // HammerModule,
        PortalModule,
        MatButtonToggleModule,
        QRCodeModule,
        ScrollingModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatFormFieldModule,
        MatSelectModule,
        MatExpansionModule,
        NgIf,
        MatDividerModule,
        ...standaloneComponents,
    ],
    declarations: [AppComponent, ...components, ...pipes, ...directives],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
        ExternalOrdersManagerServiceProvider,
        ExternalSalesManagerServiceProvider,
        MatIconRegistry,
        activeSiteDialogServiceProvider,
        cashlogyDriverServiceProvider,
        deviceInfoDialogServiceProvider,
        errorsLoggerServiceProvider,
        restManagerServiceProvider,
        operatorManagerServiceProvider,
        mdSidenavServiceProvider,
        rootScopeServiceProvider,
        sessionManagerServiceProvider,
        stateServiceProvider,
        stateParamsServiceProvider,
        urlServiceProvider,
        kioskUtilsServiceProvider,
        utilServiceProvider,
        leanPMSServiceProvider,
        campgestPMSServiceProvider,
        wellbyPMSServiceProvider,
        fiscalUtilsServiceProvider,
        customerUtilsServiceProvider,
        customerDisplayManagerServiceProvider,
        eInvoicePluginServiceProvider,
        radioListSelectorServiceProvider,
        FiscalProvidersServiceProvider,
        FiscalPrintersServiceProvider,
        digitalPaymentsManagerServiceProvider,
        documentPrinterServiceProvider,
        printerErrorFiscalServiceProvider,
        optionsDialogServiceProvider,
        saleItemManagerServiceProvider,
        saleUtilsServiceProvider,
        dashboardDataManagerServiceProvider,
        generalDocumentPrinterSelectServiceProvider,
        giftCardPrinterServiceProvider,
        promptDialogServiceProvider,
        DigitalPaymentsManagerServiceProvider,
        RetailForceProviderServiceProvider,
        itemLabelsGeneratorServiceProvider,
        itemListSelectorServiceProvider,
        itemSelectorServiceProvider,
        noFiscalPrintersServiceProvider,
        prepaidSaleServiceProvider,
        savedSalesDialogServiceProvider,
        selectSaleItemCombinationServiceProvider,
        splitSaleDialogServiceProvider,
        addSelectCustomerDialogServiceProvider,
        authRequestDialogServiceProvider,
        showSaleItemDialogServiceProvider,
        translateServiceProvider,
        LocaleProvider,
        // { provide: LOCALE_ID, useValue: 'it-IT' },
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        // TimezoneProvider,
        { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { format: 'medium', timezone: 'utc'} },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        // { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig},
        ShepherdService,
        TutorialInAppSuppliersService,
        GridTraductionsService,
        GridCellFormatterService,
        GridCellExportFormatterService,
        GridCellExportStyleService,
        UserActiveSessionManagerService,
        DatePipe,
        CurrencyPipe,
        SettingsUsersManagementService,
        HistoryUtilsService,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: LOADER_SERVICE_TOKEN,useClass:LoaderService},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        console.log("Bootstrapping in Hybrid mode with Angular & AngularJS");
    }
}
