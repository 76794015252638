export enum WEBSERVER_METHODS {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export type ENTITIES_ALLOWED = "sales" | "sale_transactions" | "departments" | "items" | "categories" | "rooms" | "status" | "login" | "callerid" | "shop_preferences" | "device_preferences";

export type entities_rest_allowed = "sales" | "saleTransactions"| "items" | "categories" | "rooms" | "departments" | "shopPreferences" | "slaveDevices" | "devicePreferences";
export interface WebServerRequestHeaders {
    authorization?: string
    waiter_version: string
}
export interface WebServerRequest {
    method: WEBSERVER_METHODS
    entityName: ENTITIES_ALLOWED,
    entityId?: string,
    headers: WebServerRequestHeaders,
    uuidRequest: string,
    body?: string | any
    queryString?: any
}
export interface ResultApi {
    totals?: number,
    results: any [] | WebServerResponseLogin,
    per_page?: number,
    pages?: number,
    page?: number
}
export interface WebServerResponse {
    uuidRequest: string,
    body: ResultApi
    code: number
}
export interface WebServerResponseStatus {
    isAppServerOnline: boolean,
    appServerVersion: string,
    user: any,
    shop: string
}
export class GeneralError {
    public message: string = "";
    public code: number;
    constructor(message: string, code: number){
        this.message = message;
        this.code = code;
    }
}
export class WebserverNotFoundError extends GeneralError{

    constructor(message: string){
        super(message, 404);
    }
    
}
export class WebserverWaiterVersionError extends GeneralError{

    constructor(){
        super("A server operator has received a legal demand to deny access to a resource or to a set of resources that includes the requested resource.", 451);
    }
    
}
export class WebserverConflict extends GeneralError{

    constructor(message: string){
        super(message, 409);
    }
    
}
export interface WebServerResponseLogin {
    session_token: string,
    expire_at: number,
    shop: string
}
export interface WebServerBodyLogin {
    server: string,
    device_ip: string,
    client_version: string,
    code: string,
    last_login_at: string
}

export interface SlaveDevices{
    /**
     * @description uuid univoco del device
     */
    id: string, 
    /**
     * @description uuid univoco del device
     */
    uuid: string, 
    /**
     * @description  Nome descrittivo del device. Ad esempio “Smartphone pippo“
     */
    name: string, 
    /**
     * @description  Token di accesso
     */
    session_token?: string | null, 
    /**
     * @description  Datetime scadenza token
     */
    token_expire_at?: number | null, 
    /**
     * @description  Code temporaneo di accesso
     */
    code: string,
    /**
     * @description  Datetime scadenza code
     */
    code_expire_at: number, 
    /**
     * @description  IP dell’ultimo server che ha generato il token
     */
    server_ip: string, 
    /**
     * @description  Datetime creazione
     */
    created_at?: string | null, 
    /**
     * @description  Utente creazione
     */
    createdby_id?: number | null, 
    /**
     * @description  Datetime cancellazione
     */
    deleted_at?: string | null, 
    /**
     * @description  Utente cancellazione
     */
    deletedby_id?: number | null, 
    /**
     * @description  Datetime ultima modifica
     */
    updated_at: string, 
    /**
     * @description  Utente ultima modifica
     */
    updatedby_id: number, 
    /**
     * @description  Datetime ultimo accesso (ovvero ultima generazione token, non code)
     */
    last_login_at?: string | null, 
    /**
     * @description Datetime ultima chiamata API ricevuta dal device (non serve memorizzarla nel cloud, utile solo localmente)
     */
    last_activity_at?: string | null  
}