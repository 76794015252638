<tilby-dialog-toolbar
    [title]="data.dialogTitle || ''"
    [hideConfirm]="true"
    [customActions]="data.customActions || []"
/>
<tilby-dialog-content>
    <qrcode
        [qrdata]="qrCode.data"
        [width]="qrCode.width"
        [errorCorrectionLevel]="qrCode.errorCorrectionLevel">
    </qrcode>
</tilby-dialog-content>