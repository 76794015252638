import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').factory('ExternalOrdersManager', ExternalOrdersManager);

ExternalOrdersManager.$inject = ["$rootScope", "$timeout", "$translate", "$window", "checkManager", "entityManager", "environmentInfo", "$state", "toast"];

function ExternalOrdersManager($rootScope, $timeout, $translate, $window, checkManager, entityManager, environmentInfo, $state, toast) {
    var externalOrdersFilter = function(order) {
        return order.operator_id === 0;
    };

    var moduleData = {
        count: 0
    };

    var ordersUpdateNotifier = function(event, data) {
        if(checkManager.isModuleAngular('tables_and_cashregister')) {
            return;
        }

        if(data.externalClient) {
            var canPrint = environmentInfo.canUseTcpSockets() || checkManager.getPreference("nofiscalprinter.driver") === 'epos';

            entityManager.orders.fetchOneOffline(data.id).then(function(order) {
                if(!externalOrdersManager.isExternalOrdersPrintEnabled() || !canPrint || _.get(order, 'operator_id') !== 0) {
                    var message = null;
                    switch(data.action) {
                        case "CREATED":
                            message = 'NEW_ORDER';
                            break;
                        case "UPDATED":
                            message = 'UPDATED_ORDER';
                            break;
                        default:
                            break;
                    }

                    if(message) {
                        toast.show({
                            message: $translate.instant('ORDERS.EXTERNAL_ORDERS_MANAGER.' + message),
                            actions: [
                                { text: $translate.instant('ORDERS.EXTERNAL_ORDERS_MANAGER.HIDE'), action: 'cancel', highlight: false },
                                { text: $translate.instant('ORDERS.EXTERNAL_ORDERS_MANAGER.OPEN'), action: 'open', highlight: true }
                            ],
                            hideDelay: 0
                        }).then(function(action) {
                            if (action === 'open') {
                                $timeout(function() {
                                    $state.go('app.orders.content', {
                                        orderId: data.id
                                    }, {
                                        inherit: false
                                    });
                                });
                            }
                        });
                    }
                }
            });
        }
    };

    var updateExternalOrdersCount = function() {
        entityManager.orders.fetchCollectionOffline({ status: 'open' }).then(function(orders) {
            moduleData.count = orders ? _.filter(orders, externalOrdersFilter).length : 0;
        });
    };

    const externalOrdersManager = {
        init: function() {
            if(checkManager.isModuleEnabled('orders')) {
                updateExternalOrdersCount();
                $rootScope.$on("storage-updated:orders", updateExternalOrdersCount);

                if(checkManager.isFunctionEnabledOptout("orders.notify_external")) {
                    $rootScope.$on("entity-updated:orders", ordersUpdateNotifier);
                }
            }
        },
        isExternalOrdersPrintEnabled: function() {
            return ($window.localStorage.getItem('deviceSettings::printExternalOrders') === 'true');
        },
        getExternalOrdersCount: function() {
            return moduleData.count;
        },
        getExternalOrdersFilter: function() {
            return externalOrdersFilter;
        },
        isExternal: function(order) {
            return externalOrdersFilter(order);
        }
    };

    return externalOrdersManager;
}