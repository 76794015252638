import { Form } from 'src/app/models';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { GiftcardsTypes } from 'tilby-models';

interface GiftcardsTypesFieldsFe {
    isNew?:boolean;
}

interface GiftcardPrintOptions {
    print_divisible?:boolean;
    print_logo?:boolean;
    print_timestamp?:boolean;
    print_validity?:boolean;
    print_value?:boolean;
    print_environment?:boolean;
    print_giftcard_uuid?:boolean;
}

export type GiftcardsTypesFields = GiftcardsTypes & GiftcardsTypesFieldsFe;

export class GiftcardsTypesDefault implements GiftcardsTypesFields {
    name: string = '';
    description?: string | undefined = '';
    is_divisible: boolean = false;
    expire_after: number = -1;
    print_options?: GiftcardPrintOptions;
    origin_environment_only?: boolean = false;
}

export type GiftcardsTypesFieldsNullable = {
    [prop in string & keyof GiftcardsTypesFields]:GiftcardsTypesFields[prop]|null
}
export type GiftcardsPrintOptionsFieldsNullable = {
    [prop in string & keyof GiftcardPrintOptions]:GiftcardPrintOptions[prop]|null
}

export type GiftcardsGeneralForm = Partial<Form<GiftcardsTypesFieldsNullable>>;
export type GiftcardPrintOptionsForm = Partial<Form<GiftcardsPrintOptionsFieldsNullable>>;

export type GiftcardsTypesFormGroup = {
    general: CustomFormGroup<any>,
    print_options: CustomFormGroup<any>
}
