<mat-progress-spinner *ngIf="addTimeSlotDialogStateService.isDisabled" class="loading-spinner" mode="indeterminate" value="40"></mat-progress-spinner>
<div *ngIf="addTimeSlotDialogStateService.isDisabled" id="overlay"></div>

<tilby-magic-form [form]="data.form"/>
<form [formGroup]="data.form">
    <ng-container *ngIf="data.form.controls.distance_based" formArrayName="distance_based">
        <div class="sass tw-w-[100%] tw-text-xl tw-py-2 tw-ml-2" translate="SETTINGS.ALVOLO.HOURS.FORM.DELIVERY_PRICE_KM"></div>
        <ng-container *ngFor="let item of data.form.controls.distance_based.controls; let $index = index">        
            <div [formGroupName]="$index" class="sass" style="align-items: start !important;">    
                <tilby-input formControlLabel="distance_based_delivery_km" class="sass tw-w-1/3"></tilby-input>
                <tilby-input formControlLabel="distance_based_delivery_price" class="sass tw-w-1/3"></tilby-input>
                <button mat-icon-button *ngIf="data.form.controls.distance_based.controls.length > 1" (click)="removeDeliveryPrice($index)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </ng-container>
        <div class="tw-text-center">
            <button mat-icon-button (click)="addDeliveryPrice()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="data.form.controls.hidden_categories_group" formArrayName="hidden_categories_group">
        <div class="sass tw-w-[100%] tw-text-xl tw-py-2 tw-ml-2" translate="SETTINGS.ALVOLO.HOURS.FORM.HIDDEN_CATEGORIES"></div>
        <ng-container *ngFor="let item of data.form.controls.hidden_categories_group.controls; let $index = index">        
            <div [formGroupName]="$index" class="sass" style="align-items: start !important;">    
                <tilby-select-input formControlLabel="services" class="sass tw-w-1/3"></tilby-select-input>
                <tilby-select-input formControlLabel="categories" class="sass tw-w-1/3"></tilby-select-input>
                <button mat-icon-button (click)="removeHiddenCategory($index)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </ng-container>
        <div class="tw-text-center">
            <button mat-icon-button (click)="addHiddenCategory()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="data.form.controls.hidden_products_group" formArrayName="hidden_products_group">
        <div class="sass tw-w-[100%] tw-text-xl tw-py-2 tw-ml-2" translate="SETTINGS.ALVOLO.HOURS.FORM.HIDDEN_PRODUCTS"></div>
        <ng-container *ngFor="let item of data.form.controls.hidden_products_group.controls; let $index = index">        
            <div [formGroupName]="$index" class="sass" style="align-items: start !important;">    
                <tilby-select-input formControlLabel="services" class="sass tw-w-1/3"></tilby-select-input>
                <tilby-input formControlLabel="products" (click)="checkProduct($index)" class="sass tw-w-1/3"></tilby-input>
                <button mat-icon-button (click)="removeHiddenProducts($index)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </ng-container>
        <div class="tw-text-center">
            <button mat-icon-button (click)="addHiddenProducts()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>
</form>