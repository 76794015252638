import {
    Component,
    inject,
    OnInit
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {
    ConfigurationManagerService,
    ConfigurationPreferences,
    ToolbarEventsService
} from 'src/app/core';
import { ImagesManagerService } from 'src/app/core/services/images-manager';
import { OpenDialogsService } from 'src/app/dialogs';
import { DevLogger } from 'src/app/shared/dev-logger';

type GeneralFormValue = {
    standby_timeout: number;
    customer_assistant_timeout: number;
    confirmation_timeout: number;
    customer_assistant: boolean;
    document_selection_enabled: boolean;
    customer_selection_enabled: boolean;
    customer_selection_mandatory: boolean;
    confirmation_enabled: boolean;
};
type GeneralForm = CustomFormGroup<CustomForm<GeneralFormValue>>;

type ApparanceFormValue = {
    show_button_standby: boolean;
    zoom_level: number;
    text_font: string;
    header_height: number;
    header_background_color: string;
    base_text_color: string;
    base_button_text_color: string;
    base_button_bgcolor: string;
    next_button_color: string;
    next_button_bgcolor: string;
    back_button_color: string;
    back_button_bgcolor: string;
    active_text_color: string;
    active_text_bgcolor: string;
    catalog_item_border_color: string;
    catalog_item_border_size: number;
    catalog_item_margin_size: number;
};
type ApparanceForm = CustomFormGroup<CustomForm<ApparanceFormValue>>;

type ServiceFormValue = {
    service_selection_enabled: boolean;
    service_selection_ask_table_number: boolean;
    service_selection_table_number_prompt_label: string;
};
type ServiceForm = CustomFormGroup<CustomForm<ServiceFormValue>>;

type CatalogFormValue = {
    catalog_enable_categories: boolean;
    catalog_show_out_of_stock: boolean;
    catalog_sell_out_of_stock: boolean;
    catalog_show_prices: boolean;
    catalog_show_names: boolean;
    catalog_show_description: boolean;
    catalog_show_item_image: boolean;
    catalog_item_image_cover: boolean;
    catalog_single_product_checkout: boolean;
    catalog_show_view_mode: boolean;
    cart_skip_cart: boolean;
    catalog_default_view_mode: string;
    catalog_items_per_row: number;
    catalog_grid_text_alignment: string;
    catalog_default_pricelist: number;
    catalog_grid_order: string;
    catalog_default_discount_type: string;
    catalog_default_discount_value: number;
};
type CatalogForm = CustomFormGroup<CustomForm<CatalogFormValue>>;

type PaymentsFormValue = {
    enable_pay_on_cashdesk: boolean;
}
type PaymentsForm = CustomFormGroup<CustomForm<PaymentsFormValue>>;

type AdvancedFormValue = {
    customer_assistant_button_label: string;
    customer_assistant_timeout_message: string;
    customer_assistant_message: string;
    next_button_label: string;
    back_button_label: string;
    cart_total_label: string;
    service_selection_title_label: string;
    service_selection_takeaway_label: string;
    service_selection_onsite_label: string;
    catalog_title_label: string;
    cart_title_label: string;
    document_selection_title_label: string;
    payment_selection_title_label: string;
    customer_selection_main_text: string;
    customer_selection_main_text_invoice: string;
    customer_selection_guest_label: string;
    customer_selection_create_label: string;
};
type AdvancedForm = CustomFormGroup<CustomForm<AdvancedFormValue>>;

type TabsSettings = {
    name: string;
    label: string;
    form: GeneralForm | ApparanceForm | ServiceForm | CatalogForm | PaymentsForm | AdvancedForm;
    formInvalid(): boolean;
}

export type SettingKioskImagePreference =
    'kiosk.standby.media_url' |
    'kiosk.confirmation.media_url' |
    'kiosk.general.media_url_logo' |
    'kiosk.service_selection.background_url' |
    'kiosk.service_selection.banner_url' |
    'kiosk.catalog.background_url' |
    'kiosk.catalog.banner_url' |
    'kiosk.cart.background_url' |
    'kiosk.cart.banner_url' |
    'kiosk.document_selection.background_url' |
    'kiosk.document_selection.banner_url' |
    'kiosk.customer_selection.background_url' |
    'kiosk.customer_selection.banner_url' |
    'kiosk.payment_selection.background_url' |
    'kiosk.payment_selection.banner_url' |
    'kiosk.catalog.item_image_placeholder';

type LabelPreference = {
    label: string;
    preference: SettingKioskImagePreference;
}

@Component({
    selector: 'app-settings-kiosk',
    templateUrl: './settings-kiosk.component.html',
    styleUrls: ['./settings-kiosk.component.scss']
})
export class SettingsKioskComponent implements OnInit {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    public readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translateService = inject(TranslateService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly imagesManagerService = inject(ImagesManagerService);
    private _snackBar = inject(MatSnackBar);

    protected generalForm: GeneralForm;
    protected apparanceForm: ApparanceForm;
    protected serviceForm: ServiceForm;
    protected catalogForm: CatalogForm;
    protected paymentsForm: PaymentsForm;
    protected advancedForm: AdvancedForm;
    protected tabs: Array<TabsSettings> = [];
    protected appearenceFormImages = new CustomFormGroup<CustomForm<Partial<{[prop in SettingKioskImagePreference]:string}>>>({});
    protected catalogFormImages = new CustomFormGroup<CustomForm<Partial<{[prop in SettingKioskImagePreference]:string}>>>({});

    constructor() {
        this.generalForm = this.createFormGeneral();
        this.apparanceForm = this.createFormApparance();
        this.serviceForm = this.createFormService();
        this.catalogForm = this.createFormCatalog();
        this.paymentsForm = this.createFormPayments();
        this.advancedForm = this.createFormAdvanced();
        this.tabs = [
            {
                name: 'GENERAL',
                label: this.translateService.instant('SETTINGS.KIOSK.TABS.GENERAL'),
                form: this.generalForm,
                formInvalid: () => this.generalForm.invalid
            },
            {
                name: 'APPARANCE',
                label: this.translateService.instant('SETTINGS.KIOSK.TABS.APPARANCE'),
                form: this.apparanceForm,
                formInvalid: () => this.apparanceForm.invalid
            },
            {
                name: 'SERVICE',
                label: this.translateService.instant('SETTINGS.KIOSK.TABS.SERVICE'),
                form: this.serviceForm,
                formInvalid: () => this.serviceForm.invalid
            },
            {
                name: 'CATALOG',
                label: this.translateService.instant('SETTINGS.KIOSK.TABS.CATALOG'),
                form: this.catalogForm,
                formInvalid: () => this.catalogForm.invalid
            },
            {
                name: 'PAYMENTS',
                label: this.translateService.instant('SETTINGS.KIOSK.TABS.PAYMENTS'),
                form: this.paymentsForm,
                formInvalid: () => this.paymentsForm.invalid
            },
            {
                name: 'ADVANCED',
                label: this.translateService.instant('SETTINGS.KIOSK.TABS.ADVANCED'),
                form: this.advancedForm,
                formInvalid: () => this.advancedForm.invalid
            },
        ];
    }

    ngOnInit(): void {
        this.setModuleTitle();
        this.createToolbarButtons();
        const inputOFgroupImages: Array<LabelPreference> = [
            // Indirizzo immagine/video standby
            {
                label: 'SETTINGS.KIOSK.GENERAL.MEDIA_URL_STANDBY',
                preference: 'kiosk.standby.media_url'
            },
            // Indirizzo immagine/video saluti
            {
                label: 'SETTINGS.KIOSK.CONFIRMATION.MEDIA_URL_CONFIRMATION',
                preference: 'kiosk.confirmation.media_url'
            },
            // Indirizzo immagine logo azienda
            {
                label: 'SETTINGS.KIOSK.GENERAL.MEDIA_URL_LOGO',
                preference: 'kiosk.general.media_url_logo'
            },
            // Indirizzo immagine sfondo selezione servizio
            {
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.BACKGROUND_URL',
                preference: 'kiosk.service_selection.background_url'
            },
            // Indirizzo immagine testata selezione servizio
            {
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.BANNER_URL',
                preference: 'kiosk.service_selection.banner_url'
            },
            // Indirizzo immagine sfondo catalogo
            {
                label: 'SETTINGS.KIOSK.CATALOG.BACKGROUND_URL',
                preference: 'kiosk.catalog.background_url'
            },
            // Indirizzo immagine testata catalogo
            {
                label: 'SETTINGS.KIOSK.CATALOG.BANNER_URL',
                preference: 'kiosk.catalog.banner_url'
            },
            // Indirizzo immagine sfondo carrello
            {
                label: 'SETTINGS.KIOSK.CART.BACKGROUND_URL',
                preference: 'kiosk.cart.background_url'
            },
            // Indirizzo immagine testata carrello
            {
                label: 'SETTINGS.KIOSK.CART.BANNER_URL',
                preference: 'kiosk.cart.banner_url'
            },
            // Indirizzo immagine sfondo selezione documento
            {
                label: 'SETTINGS.KIOSK.DOCUMENT_SELECTION.BACKGROUND_URL',
                preference: 'kiosk.document_selection.background_url'
            },
            // Indirizzo immagine testata selezione documento
            {
                label: 'SETTINGS.KIOSK.DOCUMENT_SELECTION.BANNER_URL',
                preference: 'kiosk.document_selection.banner_url'
            },
            // Indirizzo immagine sfondo sezione cliente
            {
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.BACKGROUND_URL',
                preference: 'kiosk.customer_selection.background_url'
            },
            // Indirizzo immagine testata sezione cliente
            {
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.BANNER_URL',
                preference: 'kiosk.customer_selection.banner_url'
            },
            // Indirizzo immagine sfondo pagamento
            {
                label: 'SETTINGS.KIOSK.PAYMENT_SELECTION.BACKGROUND_URL',
                preference: 'kiosk.payment_selection.background_url'
            },
            // Indirizzo immagine testata pagamento
            {
                label: 'SETTINGS.KIOSK.PAYMENT_SELECTION.BANNER_URL',
                preference: 'kiosk.payment_selection.banner_url'
            },
            // last element is read in catalog content
            // Indirizzo immagine di default articolo del catalogo
            {
                label: 'SETTINGS.KIOSK.CATALOG.ITEM_IMAGE_PLACEHOLDER',
                preference: 'kiosk.catalog.item_image_placeholder'
            }
        ];

        inputOFgroupImages.forEach((obj, i, array) => {
            const isLast = i == (array.length - 1);
            const preference: SettingKioskImagePreference = obj.preference;

            const imageUrl = this.configurationManagerService.getShopPreference(preference as keyof ConfigurationPreferences);
            if (isLast) {
                this.catalogFormImages?.addControl(preference, new CustomFormControl(imageUrl, {updateOn: 'blur'}, {
                    ...new CustomFormControlProps(),
                    inputType: 'imageHandler',
                    label: obj.label,
                    class:'tw-w-full md:tw-w-[48%] lg:tw-w-[32%] tw-mb-2 tw-flex-none tw-self-start',
                    customActions: {suffix: {callback: (formControl: CustomFormControl | undefined, fileHandle: File) => this.imagesManagerService.handleNewFileLoaded(formControl!, fileHandle)}}
                }));
            }
            else {
                this.appearenceFormImages?.addControl(preference, new CustomFormControl(imageUrl, {updateOn: 'blur'}, {
                    ...new CustomFormControlProps(),
                    inputType: 'imageHandler',
                    label: obj.label,
                    class:'tw-w-full md:tw-w-1/3 lg:tw-w-1/4 tw-mb-2 tw-self-start',
                    customActions: {suffix: {callback: (formControl: CustomFormControl | undefined, fileHandle: File) => this.imagesManagerService.handleNewFileLoaded(formControl!, fileHandle)}}
                }));
            }
        });
    }


    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('KIOSK_SETTINGS');
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => true,
                    name: 'save',
                    icon: () => 'check',
                    click: _ => this.savePreferences(),
                    isDisable: () => this.generalForm.invalid || this.apparanceForm.invalid || this.serviceForm.invalid || this.catalogForm.invalid || this.paymentsForm.invalid || this.advancedForm.invalid
                }
            ],
            panelButtons: []
        });
    }

    private createRanges(start: number = 0, end: number) {
        return Array.from({length: end + 1}, (_, i) => ({
            key: String(i + start),
            value: i + start
        }));
    }

    createFormGeneral(): GeneralForm {
        const kiosk_general_standby_timeout = Number(this.configurationManagerService.getShopPreference('kiosk.general.standby_timeout') || 20);
        const kiosk_general_customer_assistant_timeout = Number(this.configurationManagerService.getShopPreference('kiosk.general.customer_assistant_timeout') || 10);
        const kiosk_confirmation_timeout = Number(this.configurationManagerService.getShopPreference('kiosk.confirmation.timeout') || 10);
        const kiosk_general_customer_assistant = Boolean(this.configurationManagerService.getShopPreference('kiosk.general.customer_assistant'));
        const kiosk_document_selection_enabled = Boolean(this.configurationManagerService.getShopPreference('kiosk.document_selection.enabled'));
        const kiosk_customer_selection_enabled = Boolean(this.configurationManagerService.getShopPreference('kiosk.customer_selection.enabled'));
        const kiosk_customer_selection_mandatory = Boolean(this.configurationManagerService.getShopPreference('kiosk.customer_selection.mandatory'));
        const kiosk_confirmation_enabled = Boolean(this.configurationManagerService.getShopPreference('kiosk.confirmation.enabled'));
        return new CustomFormGroup<CustomForm<GeneralFormValue>>({
            //SETTINGS.KIOSK.GENERAL.STANDBY_TIMEOUT
            standby_timeout: new CustomFormControl<number>(kiosk_general_standby_timeout, { validators: Validators.min(0) }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.STANDBY_TIMEOUT',
                inputType: 'number',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_TIMEOUT
            customer_assistant_timeout: new CustomFormControl<number>(kiosk_general_customer_assistant_timeout, { validators: Validators.min(0) }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_TIMEOUT',
                inputType: 'number',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CONFIRMATION.CONFIRMATION_TIMEOUT
            confirmation_timeout: new CustomFormControl<number>(kiosk_confirmation_timeout, { validators: Validators.min(0) }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CONFIRMATION.CONFIRMATION_TIMEOUT',
                inputType: 'number',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT
            customer_assistant: new CustomFormControl<boolean>(kiosk_general_customer_assistant, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.DOCUMENT_SELECTION.ENABLED
            document_selection_enabled: new CustomFormControl<boolean>(kiosk_document_selection_enabled, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.DOCUMENT_SELECTION.ENABLED',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CUSTOMER_SELECTION.ENABLED
            customer_selection_enabled: new CustomFormControl<boolean>(kiosk_customer_selection_enabled, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.ENABLED',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CUSTOMER_SELECTION.MANDATORY
            customer_selection_mandatory: new CustomFormControl<boolean>(kiosk_customer_selection_mandatory, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.MANDATORY',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[calc(50%-0.75rem)] lg:tw-w-[calc(33%-0.75rem)] tw-mb-3 tw-flex-none'
            }),
            //SETTINGS.KIOSK.CONFIRMATION.ENABLED
            confirmation_enabled: new CustomFormControl<boolean>(kiosk_confirmation_enabled, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CONFIRMATION.ENABLED',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[calc(50%-0.75rem)] lg:tw-w-[calc(33%-0.75rem)] tw-mb-3 tw-flex-none lg:tw-pr-[34%]'
            }),
        });
    }

    createFormApparance(): ApparanceForm {
        const zoomLevels = [
            { key: "1", value: 1 },
            { key: "1.1", value: 1.1 },
            { key: "1.2", value: 1.2 },
            { key: "1.3", value: 1.3 },
            { key: "1.4", value: 1.4 },
            { key: "1.5", value: 1.5 },
            { key: "1.6", value: 1.6 },
            { key: "1.7", value: 1.7 },
            { key: "1.8", value: 1.8 },
            { key: "1.9", value: 1.9 },
            { key: "2", value: 2 }
        ];
        const textFonts = [
            { key: "Arial", value: "Arial" },
            { key: "Verdana", value: "Verdana" },
            { key: "Helvetica", value: "Helvetica" },
            { key: "Tahoma", value: "Tahoma" },
            { key: "Trebuchet MS", value: "Trebuchet MS" },
            { key: "Times New Roman", value: "Times New Roman" },
            { key: "Georgia", value: "Georgia" },
            { key: "Garamond", value: "Garamond" },
            { key: "Courier New", value: "Courier New" },
            { key: "Brush Script MT", value: "Brush Script MT" }
        ];
        const itemBorderSize = this.createRanges(0, 5);
        const itemMarginSize = this.createRanges(0, 15);
        const kiosk_standby_show_button_standby = Boolean(this.configurationManagerService.getShopPreference('kiosk.standby.show_button_standby'));
        const kiosk_general_zoom_level = Number(this.configurationManagerService.getShopPreference('kiosk.general.zoom_level') || 1);
        const kiosk_general_text_font = String(this.configurationManagerService.getShopPreference('kiosk.general.text_font') || 'Arial');
        const kiosk_general_header_height = Number(this.configurationManagerService.getShopPreference('kiosk.general.header_height') || 144);
        const kiosk_general_header_background_color = String(this.configurationManagerService.getShopPreference('kiosk.general.header_background_color') || '#000');
        const kiosk_general_base_text_color = String(this.configurationManagerService.getShopPreference('kiosk.general.base_text_color') || '#000');
        const kiosk_general_base_button_text_color = String(this.configurationManagerService.getShopPreference('kiosk.general.base_button_text_color') || '#000');
        const kiosk_general_base_button_bgcolor = String(this.configurationManagerService.getShopPreference('kiosk.general.base_button_bgcolor') || '#000');
        const kiosk_general_next_button_color = String(this.configurationManagerService.getShopPreference('kiosk.general.next_button_color') || '#000');
        const kiosk_general_next_button_bgcolor = String(this.configurationManagerService.getShopPreference('kiosk.general.next_button_bgcolor') || '#000');
        const kiosk_general_back_button_color = String(this.configurationManagerService.getShopPreference('kiosk.general.back_button_color') || '#000');
        const kiosk_general_back_button_bgcolor = String(this.configurationManagerService.getShopPreference('kiosk.general.back_button_bgcolor') || '#000');
        const kiosk_general_active_text_color = String(this.configurationManagerService.getShopPreference('kiosk.general.active_text_color') || '#000');
        const kiosk_general_active_text_bgcolor = String(this.configurationManagerService.getShopPreference('kiosk.general.active_text_bgcolor') || '#000');
        const kiosk_catalog_item_border_color = String(this.configurationManagerService.getShopPreference('kiosk.catalog.item_border_color') || '#000');
        const kiosk_catalog_item_border_size = Number(this.configurationManagerService.getShopPreference('kiosk.catalog.item_border_size') || 0);
        const kiosk_catalog_item_margin_size = Number(this.configurationManagerService.getShopPreference('kiosk.catalog.item_margin_size') || 0);

        return new CustomFormGroup<CustomForm<ApparanceFormValue>>({
            //SETTINGS.KIOSK.GENERAL.SHOW_BUTTON_STANDBY
            show_button_standby: new CustomFormControl<boolean>(kiosk_standby_show_button_standby, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.SHOW_BUTTON_STANDBY',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.GENERAL.ZOOM_LEVEL
            zoom_level: new CustomFormControl<number>(kiosk_general_zoom_level, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.ZOOM_LEVEL',
                inputType: 'select',
                inputChoices: zoomLevels,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.TEXT_FONT
            text_font: new CustomFormControl<string>(kiosk_general_text_font, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.TEXT_FONT',
                inputType: 'select',
                inputChoices: textFonts,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.HEADER_HEIGHT
            header_height: new CustomFormControl<number>(kiosk_general_header_height, { validators: [Validators.min(0)] }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.HEADER_HEIGHT',
                inputType: 'number',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.HEADER_BACKGROUND_COLOR
            header_background_color: new CustomFormControl<string>(kiosk_general_header_background_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.HEADER_BACKGROUND_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.BASE_TEXT_COLOR
            base_text_color: new CustomFormControl<string>(kiosk_general_base_text_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.BASE_TEXT_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.BASE_BUTTON_TEXT_COLOR
            base_button_text_color: new CustomFormControl<string>(kiosk_general_base_button_text_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.BASE_BUTTON_TEXT_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.BASE_BUTTON_BGCOLOR
            base_button_bgcolor: new CustomFormControl<string>(kiosk_general_base_button_bgcolor, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.BASE_BUTTON_BGCOLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.NEXT_BUTTON_COLOR
            next_button_color: new CustomFormControl<string>(kiosk_general_next_button_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.NEXT_BUTTON_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.NEXT_BUTTON_BGCOLOR
            next_button_bgcolor: new CustomFormControl<string>(kiosk_general_next_button_bgcolor, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.NEXT_BUTTON_BGCOLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.BACK_BUTTON_COLOR
            back_button_color: new CustomFormControl<string>(kiosk_general_back_button_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.BACK_BUTTON_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.BACK_BUTTON_BGCOLOR
            back_button_bgcolor: new CustomFormControl<string>(kiosk_general_back_button_bgcolor, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.BACK_BUTTON_BGCOLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.ACTIVE_TEXT_COLOR
            active_text_color: new CustomFormControl<string>(kiosk_general_active_text_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.ACTIVE_TEXT_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.ACTIVE_TEXT_BGCOLOR
            active_text_bgcolor: new CustomFormControl<string>(kiosk_general_active_text_bgcolor, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.ACTIVE_TEXT_BGCOLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.ITEM_BORDER_COLOR
            catalog_item_border_color: new CustomFormControl<string>(kiosk_catalog_item_border_color, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.ITEM_BORDER_COLOR',
                inputType: 'color',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.ITEM_BORDER_SIZE
            catalog_item_border_size: new CustomFormControl<number>(kiosk_catalog_item_border_size, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.ITEM_BORDER_SIZE',
                inputType: 'select',
                inputChoices: itemBorderSize,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.ITEM_MARGIN_SIZE
            catalog_item_margin_size: new CustomFormControl<number>(kiosk_catalog_item_margin_size, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.ITEM_MARGIN_SIZE',
                inputType: 'select',
                inputChoices: itemMarginSize,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
        });
    }

    createFormService(): ServiceForm {
        const kiosk_service_selection_enabled = Boolean(this.configurationManagerService.getShopPreference('kiosk.service_selection.enabled'));
        const kiosk_service_selection_ask_table_number = Boolean(this.configurationManagerService.getShopPreference('kiosk.service_selection.ask_table_number'));
        const kiosk_service_selection_table_number_prompt_label = String(this.configurationManagerService.getShopPreference('kiosk.service_selection.table_number_prompt_label') || '');

        return new CustomFormGroup<CustomForm<ServiceFormValue>>({
            //SETTINGS.KIOSK.SERVICE_SELECTION.ENABLED
            service_selection_enabled: new CustomFormControl<boolean>(kiosk_service_selection_enabled, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.ENABLED',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.SERVICE_SELECTION.ASK_TABLE_NUMBER
            service_selection_ask_table_number: new CustomFormControl<boolean>(kiosk_service_selection_ask_table_number, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.ASK_TABLE_NUMBER',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.SERVICE_SELECTION.TABLE_NUMBER_PROMPT_LABEL
            service_selection_table_number_prompt_label: new CustomFormControl<string>(kiosk_service_selection_table_number_prompt_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.TABLE_NUMBER_PROMPT_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
        });
    }

    createFormCatalog(): CatalogForm {
        const kiosk_catalog_enable_categories = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.enable_categories'));
        const kiosk_catalog_show_out_of_stock = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.show_out_of_stock'));
        const kiosk_catalog_sell_out_of_stock = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.sell_out_of_stock'));
        const kiosk_catalog_show_prices = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.show_prices'));
        const kiosk_catalog_show_names = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.show_names'));
        const kiosk_catalog_show_description = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.show_description'));
        const kiosk_catalog_show_item_image = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.show_item_image'));
        const kiosk_catalog_item_image_cover = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.item_image_cover'));
        const kiosk_catalog_single_product_checkout = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.single_product_checkout'));
        const kiosk_catalog_show_view_mode = Boolean(this.configurationManagerService.getShopPreference('kiosk.catalog.show_view_mode'));
        const kiosk_cart_skip_cart = Boolean(this.configurationManagerService.getShopPreference('kiosk.cart.skip_cart'));
        const kiosk_catalog_default_view_mode = String(this.configurationManagerService.getShopPreference('kiosk.catalog.default_view_mode') || '');
        const defaultViewMode = [
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.GRID'), value: "gridView" },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.LIST'), value: "listView" }
        ];
        const kiosk_catalog_items_per_row = Number(this.configurationManagerService.getShopPreference('kiosk.catalog.items_per_row') || 1);
        const itemsPerRow = this.createRanges(1, 5);
        const kiosk_catalog_grid_text_alignment = String(this.configurationManagerService.getShopPreference('kiosk.catalog.grid_text_alignment') || '');
        const gridTextAlignment = [
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.LEFT'),      value: "left"    },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.CENTER'),    value: "center"  },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.RIGHT'),     value: "right"   },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.JUSTIFIED'), value: "justify" }
        ];
        // ---------- start priceListNames ----------
        const kiosk_catalog_default_pricelist = Number(this.configurationManagerService.getShopPreference('kiosk.catalog.default_pricelist') || 1);
        const priceLists = [];
        for(let i = 1; i <= 10; i++) {
            priceLists.push({
                index: i,
                fieldName: `price${i}`,
                value: this.configurationManagerService.getShopPreference(`price_list_${i}_name`) || `${this.translateService.instant('SETTINGS.KIOSK.CATALOG.PRICE_LIST_START')} ${i}`,
                hidden: this.configurationManagerService.getShopPreference(`price_list_${i}_hide`) ? true : false
            });
        }
        const defaultPriceLists = priceLists.filter(priceList => !priceList.hidden).map(priceList => ({
            key: priceList.value,
            value: priceList.index
        }));
        // ---------- end priceListNames ----------
        const kiosk_catalog_grid_order = String(this.configurationManagerService.getShopPreference('kiosk.catalog.grid_order') || '');
        const gridOrder = [
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.NAME'),             value: "+name"  },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.INDEX'),            value: "+index" },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.ASCENDING_PRICE'),  value: "+price" },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.DESCENDING_PRICE'), value: "-price" }
        ];
        const kiosk_catalog_default_discount_type = String(this.configurationManagerService.getShopPreference('kiosk.catalog.default_discount_type') || '');
        const defaultDiscountType = [
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.NONE'),       value: "none"  },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.FIXED'),      value: "fixed" },
            { key: this.translateService.instant('SETTINGS.KIOSK.CATALOG.PERCENTAGE'), value: "prec"  }
        ];
        const kiosk_catalog_default_discount_value = Number(this.configurationManagerService.getShopPreference('kiosk.catalog.default_discount_value') || 0);

        return new CustomFormGroup<CustomForm<CatalogFormValue>>({
            //SETTINGS.KIOSK.CATALOG.ENABLE_CATEGORIES
            catalog_enable_categories: new CustomFormControl<boolean>(kiosk_catalog_enable_categories, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.ENABLE_CATEGORIES',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SHOW_OUT_OF_STOCK
            catalog_show_out_of_stock: new CustomFormControl<boolean>(kiosk_catalog_show_out_of_stock, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SHOW_OUT_OF_STOCK',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SELL_OUT_OF_STOCK
            catalog_sell_out_of_stock: new CustomFormControl<boolean>(kiosk_catalog_sell_out_of_stock, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SELL_OUT_OF_STOCK',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SHOW_PRICES
            catalog_show_prices: new CustomFormControl<boolean>(kiosk_catalog_show_prices, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SHOW_PRICES',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SHOW_NAMES
            catalog_show_names: new CustomFormControl<boolean>(kiosk_catalog_show_names, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SHOW_NAMES',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SHOW_DESCRIPTION
            catalog_show_description: new CustomFormControl<boolean>(kiosk_catalog_show_description, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SHOW_DESCRIPTION',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SHOW_ITEM_IMAGE
            catalog_show_item_image: new CustomFormControl<boolean>(kiosk_catalog_show_item_image, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SHOW_ITEM_IMAGE',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.ITEM_IMAGE_COVER
            catalog_item_image_cover: new CustomFormControl<boolean>(kiosk_catalog_item_image_cover, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.ITEM_IMAGE_COVER',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SINGLE_PRODUCT_CHECKOUT
            catalog_single_product_checkout: new CustomFormControl<boolean>(kiosk_catalog_single_product_checkout, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SINGLE_PRODUCT_CHECKOUT',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.SHOW_VIEW_MODE
            catalog_show_view_mode: new CustomFormControl<boolean>(kiosk_catalog_show_view_mode, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.SHOW_VIEW_MODE',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CART.SKIP_CART
            cart_skip_cart: new CustomFormControl<boolean>(kiosk_cart_skip_cart, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CART.SKIP_CART',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
            //SETTINGS.KIOSK.CATALOG.DEFAULT_VIEW_MODE
            catalog_default_view_mode: new CustomFormControl<string>(kiosk_catalog_default_view_mode, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.DEFAULT_VIEW_MODE',
                inputType: 'select',
                inputChoices: defaultViewMode,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.ITEMS_PER_ROW
            catalog_items_per_row: new CustomFormControl<number>(kiosk_catalog_items_per_row, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.ITEMS_PER_ROW',
                inputType: 'select',
                inputChoices: itemsPerRow,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.GRID_TEXT_ALIGNMENT
            catalog_grid_text_alignment: new CustomFormControl<string>(kiosk_catalog_grid_text_alignment, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.GRID_TEXT_ALIGNMENT',
                inputType: 'select',
                inputChoices: gridTextAlignment,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.DEFAULT_PRICELIST
            catalog_default_pricelist: new CustomFormControl<number>(kiosk_catalog_default_pricelist, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.DEFAULT_PRICELIST',
                inputType: 'select',
                inputChoices: defaultPriceLists,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.GRID_ORDER
            catalog_grid_order: new CustomFormControl<string>(kiosk_catalog_grid_order, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.GRID_ORDER',
                inputType: 'select',
                inputChoices: gridOrder,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.DEFAULT_DISCOUNT_TYPE
            catalog_default_discount_type: new CustomFormControl<string>(kiosk_catalog_default_discount_type, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.DEFAULT_DISCOUNT_TYPE',
                inputType: 'select',
                inputChoices: defaultDiscountType,
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.DEFAULT_DISCOUNT_VALUE
            catalog_default_discount_value: new CustomFormControl<number>(kiosk_catalog_default_discount_value, { validators: Validators.min(0) }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.DEFAULT_DISCOUNT_VALUE',
                inputType: 'number',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
        });
    }

    createFormPayments(): PaymentsForm {
        const kiosk_payment_selection_enable_pay_on_cashdesk = Boolean(this.configurationManagerService.getShopPreference('kiosk.payment_selection.enable_pay_on_cashdesk'));

        return new CustomFormGroup<CustomForm<PaymentsFormValue>>({
            //SETTINGS.KIOSK.PAYMENT_SELECTION.ENABLE_PAY_ON_CASHDESK
            enable_pay_on_cashdesk: new CustomFormControl<boolean>(kiosk_payment_selection_enable_pay_on_cashdesk, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.PAYMENT_SELECTION.ENABLE_PAY_ON_CASHDESK',
                inputType: 'slideToggle',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%] tw-mb-3'
            }),
        });
    }

    createFormAdvanced(): AdvancedForm {
        const kiosk_general_customer_assistant_button_label = String(this.configurationManagerService.getShopPreference('kiosk.general.customer_assistant_button_label') || '');
        const kiosk_general_customer_assistant_timeout_message = String(this.configurationManagerService.getShopPreference('kiosk.general.customer_assistant_timeout_message') || '');
        const kiosk_general_customer_assistant_message = String(this.configurationManagerService.getShopPreference('kiosk.general.customer_assistant_message') || '');
        const kiosk_general_next_button_label = String(this.configurationManagerService.getShopPreference('kiosk.general.next_button_label') || '');
        const kiosk_general_back_button_label = String(this.configurationManagerService.getShopPreference('kiosk.general.back_button_label') || '');
        const kiosk_cart_total_label = String(this.configurationManagerService.getShopPreference('kiosk.cart.total_label') || '');
        const kiosk_service_selection_title_label = String(this.configurationManagerService.getShopPreference('kiosk.service_selection.title_label') || '');
        const kiosk_service_selection_takeaway_label = String(this.configurationManagerService.getShopPreference('kiosk.service_selection.takeaway_label') || '');
        const kiosk_service_selection_onsite_label = String(this.configurationManagerService.getShopPreference('kiosk.service_selection.onsite_label') || '');
        const kiosk_catalog_title_label = String(this.configurationManagerService.getShopPreference('kiosk.catalog.title_label') || '');
        const kiosk_cart_title_label = String(this.configurationManagerService.getShopPreference('kiosk.cart.title_label') || '');
        const kiosk_document_selection_title_label = String(this.configurationManagerService.getShopPreference('kiosk.document_selection.title_label') || '');
        const kiosk_payment_selection_title_label = String(this.configurationManagerService.getShopPreference('kiosk.payment_selection.title_label') || '');
        const kiosk_customer_selection_main_text = String(this.configurationManagerService.getShopPreference('kiosk.customer_selection.main_text') || '');
        const kiosk_customer_selection_main_text_invoice = String(this.configurationManagerService.getShopPreference('kiosk.customer_selection.main_text_invoice') || '');
        const kiosk_customer_selection_guest_label = String(this.configurationManagerService.getShopPreference('kiosk.customer_selection.guest_label') || '');
        const kiosk_customer_selection_create_label = String(this.configurationManagerService.getShopPreference('kiosk.customer_selection.create_label') || '');

        return new CustomFormGroup<CustomForm<AdvancedFormValue>>({
            //SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_BUTTON_LABEL
            customer_assistant_button_label: new CustomFormControl<string>(kiosk_general_customer_assistant_button_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_BUTTON_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_TIMEOUT_MESSAGE
            customer_assistant_timeout_message: new CustomFormControl<string>(kiosk_general_customer_assistant_timeout_message, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_TIMEOUT_MESSAGE',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_MESSAGE
            customer_assistant_message: new CustomFormControl<string>(kiosk_general_customer_assistant_message, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.CUSTOMER_ASSISTANT_MESSAGE',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.NEXT_BUTTON_LABEL
            next_button_label: new CustomFormControl<string>(kiosk_general_next_button_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.NEXT_BUTTON_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.GENERAL.BACK_BUTTON_LABEL
            back_button_label: new CustomFormControl<string>(kiosk_general_back_button_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.GENERAL.BACK_BUTTON_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CART.TOTAL_LABEL
            cart_total_label: new CustomFormControl<string>(kiosk_cart_total_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CART.TOTAL_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.SERVICE_SELECTION.TITLE_LABEL
            service_selection_title_label: new CustomFormControl<string>(kiosk_service_selection_title_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.TITLE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.SERVICE_SELECTION.TAKEAWAY_LABEL
            service_selection_takeaway_label: new CustomFormControl<string>(kiosk_service_selection_takeaway_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.TAKEAWAY_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.SERVICE_SELECTION.ONSITE_LABEL
            service_selection_onsite_label: new CustomFormControl<string>(kiosk_service_selection_onsite_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.SERVICE_SELECTION.ONSITE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CATALOG.TITLE_LABEL
            catalog_title_label: new CustomFormControl<string>(kiosk_catalog_title_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CATALOG.TITLE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CART.TITLE_LABEL
            cart_title_label: new CustomFormControl<string>(kiosk_cart_title_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CART.TITLE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.DOCUMENT_SELECTION.TITLE_LABEL
            document_selection_title_label: new CustomFormControl<string>(kiosk_document_selection_title_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.DOCUMENT_SELECTION.TITLE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.PAYMENT_SELECTION.TITLE_LABEL
            payment_selection_title_label: new CustomFormControl<string>(kiosk_payment_selection_title_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.PAYMENT_SELECTION.TITLE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CUSTOMER_SELECTION.MAIN_TEXT
            customer_selection_main_text: new CustomFormControl<string>(kiosk_customer_selection_main_text, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.MAIN_TEXT',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CUSTOMER_SELECTION.MAIN_TEXT_INVOICE
            customer_selection_main_text_invoice: new CustomFormControl<string>(kiosk_customer_selection_main_text_invoice, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.MAIN_TEXT_INVOICE',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CUSTOMER_SELECTION.GUEST_LABEL
            customer_selection_guest_label: new CustomFormControl<string>(kiosk_customer_selection_guest_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.GUEST_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
            //SETTINGS.KIOSK.CUSTOMER_SELECTION.CREATE_LABEL
            customer_selection_create_label: new CustomFormControl<string>(kiosk_customer_selection_create_label, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'SETTINGS.KIOSK.CUSTOMER_SELECTION.CREATE_LABEL',
                inputType: 'text',
                class: 'tw-w-full md:tw-w-[48%] lg:tw-w-[31%]'
            }),
        });
    }

    async savePreferences() {
        if (this.generalForm.invalid || this.apparanceForm.invalid || this.serviceForm.invalid || this.catalogForm.invalid || this.paymentsForm.invalid || this.advancedForm.invalid || this.appearenceFormImages.invalid || this.catalogFormImages.invalid) {
            this.openDialogsService.openAlertDialog({data: {messageLabel: 'SETTINGS.SETTINGS.FORM_INVALID'}});
            return;
        }

        const confirm = await this.openDialogsService.openConfirmDialog({
            data: {
                messageLabel: 'SETTINGS.SETTINGS.SAVE_CONFIRM',
                confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
                cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
            }
        });

        if (confirm) {
            if (this.generalForm.valid && this.generalForm.touched) {
                this.log('GENERAL FORM VALID', this.generalForm);
                const generalFormValue = this.generalForm.value;
                const generalPreferences = [
                    {
                        preference_name: 'kiosk.general.standby_timeout',
                        preference_value: generalFormValue.standby_timeout
                    },
                    {
                        preference_name: 'kiosk.general.customer_assistant_timeout',
                        preference_value: generalFormValue.customer_assistant_timeout
                    },
                    {
                        preference_name: 'kiosk.confirmation.timeout',
                        preference_value: generalFormValue.confirmation_timeout
                    },
                    {
                        preference_name: 'kiosk.general.customer_assistant',
                        preference_value: generalFormValue.customer_assistant
                    },
                    {
                        preference_name: 'kiosk.document_selection.enabled',
                        preference_value: generalFormValue.document_selection_enabled
                    },
                    {
                        preference_name: 'kiosk.customer_selection.enabled',
                        preference_value: generalFormValue.customer_selection_enabled
                    },
                    {
                        preference_name: 'kiosk.customer_selection.mandatory',
                        preference_value: generalFormValue.customer_selection_mandatory
                    },
                    {
                        preference_name: 'kiosk.confirmation.enabled',
                        preference_value: generalFormValue.confirmation_enabled
                    },
                ];
                generalPreferences.forEach(obj => {
                    this.configurationManagerService.setShopPreference(obj.preference_name as keyof ConfigurationPreferences, obj.preference_value);
                });
            }

            if (this.apparanceForm.valid && this.apparanceForm.touched) {
                this.log('APPEARANCE FORM VALID', this.apparanceForm);
                const apparanceFormValue = this.apparanceForm.value;
                const apparancePreferences = [
                    {
                        preference_name: 'kiosk.standby.show_button_standby',
                        preference_value: apparanceFormValue.show_button_standby
                    },
                    {
                        preference_name: 'kiosk.general.zoom_level',
                        preference_value: apparanceFormValue.zoom_level
                    },
                    {
                        preference_name: 'kiosk.general.text_font',
                        preference_value: apparanceFormValue.text_font
                    },
                    {
                        preference_name: 'kiosk.general.header_height',
                        preference_value: apparanceFormValue.header_height
                    },
                    {
                        preference_name: 'kiosk.general.header_background_color',
                        preference_value: apparanceFormValue.header_background_color
                    },
                    {
                        preference_name: 'kiosk.general.base_text_color',
                        preference_value: apparanceFormValue.base_text_color
                    },
                    {
                        preference_name: 'kiosk.general.base_button_text_color',
                        preference_value: apparanceFormValue.base_button_text_color
                    },
                    {
                        preference_name: 'kiosk.general.base_button_bgcolor',
                        preference_value: apparanceFormValue.base_button_bgcolor
                    },
                    {
                        preference_name: 'kiosk.general.next_button_color',
                        preference_value: apparanceFormValue.next_button_color
                    },
                    {
                        preference_name: 'kiosk.general.next_button_bgcolor',
                        preference_value: apparanceFormValue.next_button_bgcolor
                    },
                    {
                        preference_name: 'kiosk.general.back_button_color',
                        preference_value: apparanceFormValue.back_button_color
                    },
                    {
                        preference_name: 'kiosk.general.back_button_bgcolor',
                        preference_value: apparanceFormValue.back_button_bgcolor
                    },
                    {
                        preference_name: 'kiosk.general.active_text_color',
                        preference_value: apparanceFormValue.active_text_color
                    },
                    {
                        preference_name: 'kiosk.general.active_text_bgcolor',
                        preference_value: apparanceFormValue.active_text_bgcolor
                    },
                    {
                        preference_name: 'kiosk.catalog.item_border_color',
                        preference_value: apparanceFormValue.catalog_item_border_color
                    },
                    {
                        preference_name: 'kiosk.catalog.item_border_size',
                        preference_value: apparanceFormValue.catalog_item_border_size
                    },
                    {
                        preference_name: 'kiosk.catalog.item_margin_size',
                        preference_value: apparanceFormValue.catalog_item_margin_size
                    },
                ];
                apparancePreferences.forEach(obj => {
                    this.configurationManagerService.setShopPreference(obj.preference_name as keyof ConfigurationPreferences, obj.preference_value);
                });
            }

            if (this.serviceForm.valid && this.serviceForm.touched) {
                this.log('SERVICE FORM VALID', this.serviceForm);
                const serviceFormValue = this.serviceForm.value;
                const servicePreferences = [
                    {
                        preference_name: 'kiosk.service_selection.enabled',
                        preference_value: serviceFormValue.service_selection_enabled
                    },
                    {
                        preference_name: 'kiosk.service_selection.ask_table_number',
                        preference_value: serviceFormValue.service_selection_ask_table_number
                    },
                    {
                        preference_name: 'kiosk.service_selection.table_number_prompt_label',
                        preference_value: serviceFormValue.service_selection_table_number_prompt_label
                    },
                ];
                servicePreferences.forEach(obj => {
                    this.configurationManagerService.setShopPreference(obj.preference_name as keyof ConfigurationPreferences, obj.preference_value);
                });
            }

            if (this.catalogForm.valid && this.catalogForm.touched) {
                this.log('CATALOG FORM VALID', this.catalogForm);
                const catalogFormValue = this.catalogForm.value;
                const catalogPreferences = [
                    {
                        preference_name: 'kiosk.catalog.enable_categories',
                        preference_value: catalogFormValue.catalog_enable_categories
                    },
                    {
                        preference_name: 'kiosk.catalog.show_out_of_stock',
                        preference_value: catalogFormValue.catalog_show_out_of_stock
                    },
                    {
                        preference_name: 'kiosk.catalog.sell_out_of_stock',
                        preference_value: catalogFormValue.catalog_sell_out_of_stock
                    },
                    {
                        preference_name: 'kiosk.catalog.show_prices',
                        preference_value: catalogFormValue.catalog_show_prices
                    },
                    {
                        preference_name: 'kiosk.catalog.show_names',
                        preference_value: catalogFormValue.catalog_show_names
                    },
                    {
                        preference_name: 'kiosk.catalog.show_description',
                        preference_value: catalogFormValue.catalog_show_description
                    },
                    {
                        preference_name: 'kiosk.catalog.show_item_image',
                        preference_value: catalogFormValue.catalog_show_item_image
                    },
                    {
                        preference_name: 'kiosk.catalog.item_image_cover',
                        preference_value: catalogFormValue.catalog_item_image_cover
                    },
                    {
                        preference_name: 'kiosk.catalog.single_product_checkout',
                        preference_value: catalogFormValue.catalog_single_product_checkout
                    },
                    {
                        preference_name: 'kiosk.catalog.show_view_mode',
                        preference_value: catalogFormValue.catalog_show_view_mode
                    },
                    {
                        preference_name: 'kiosk.cart.skip_cart',
                        preference_value: catalogFormValue.cart_skip_cart
                    },
                    {
                        preference_name: 'kiosk.catalog.default_view_mode',
                        preference_value: catalogFormValue.catalog_default_view_mode
                    },
                    {
                        preference_name: 'kiosk.catalog.items_per_row',
                        preference_value: catalogFormValue.catalog_items_per_row
                    },
                    {
                        preference_name: 'kiosk.catalog.grid_text_alignment',
                        preference_value: catalogFormValue.catalog_grid_text_alignment
                    },
                    {
                        preference_name: 'kiosk.catalog.default_pricelist',
                        preference_value: catalogFormValue.catalog_default_pricelist
                    },
                    {
                        preference_name: 'kiosk.catalog.grid_order',
                        preference_value: catalogFormValue.catalog_grid_order
                    },
                    {
                        preference_name: 'kiosk.catalog.default_discount_type',
                        preference_value: catalogFormValue.catalog_default_discount_type
                    },
                    {
                        preference_name: 'kiosk.catalog.default_discount_value',
                        preference_value: catalogFormValue.catalog_default_discount_value
                    },
                ];
                catalogPreferences.forEach(obj => {
                    this.configurationManagerService.setShopPreference(obj.preference_name as keyof ConfigurationPreferences, obj.preference_value);
                });
            }

            if (this.paymentsForm.valid && this.paymentsForm.touched) {
                this.log('PAYMENTS FORM VALID', this.paymentsForm);
                const paymentsFormValue = this.paymentsForm.value;
                const paymentsPreferences = [
                    {
                        preference_name: 'kiosk.payment_selection.enable_pay_on_cashdesk',
                        preference_value: paymentsFormValue.enable_pay_on_cashdesk
                    },
                ];
                paymentsPreferences.forEach(obj => {
                    this.configurationManagerService.setShopPreference(obj.preference_name as keyof ConfigurationPreferences, obj.preference_value);
                });
            }

            if (this.advancedForm.valid && this.advancedForm.touched) {
                this.log('ADVANCED FORM VALID', this.advancedForm);
                const advancedFormValue = this.advancedForm.value;
                const advancedPreferences = [
                    {
                        preference_name: 'kiosk.general.customer_assistant_button_label',
                        preference_value: advancedFormValue.customer_assistant_button_label
                    },
                    {
                        preference_name: 'kiosk.general.customer_assistant_timeout_message',
                        preference_value: advancedFormValue.customer_assistant_timeout_message
                    },
                    {
                        preference_name: 'kiosk.general.customer_assistant_message',
                        preference_value: advancedFormValue.customer_assistant_message
                    },
                    {
                        preference_name: 'kiosk.general.next_button_label',
                        preference_value: advancedFormValue.next_button_label
                    },
                    {
                        preference_name: 'kiosk.general.back_button_label',
                        preference_value: advancedFormValue.back_button_label
                    },
                    {
                        preference_name: 'kiosk.cart.total_label',
                        preference_value: advancedFormValue.cart_total_label
                    },
                    {
                        preference_name: 'kiosk.service_selection.title_label',
                        preference_value: advancedFormValue.service_selection_title_label
                    },
                    {
                        preference_name: 'kiosk.service_selection.takeaway_label',
                        preference_value: advancedFormValue.service_selection_takeaway_label
                    },
                    {
                        preference_name: 'kiosk.service_selection.onsite_label',
                        preference_value: advancedFormValue.service_selection_onsite_label
                    },
                    {
                        preference_name: 'kiosk.catalog.title_label',
                        preference_value: advancedFormValue.catalog_title_label
                    },
                    {
                        preference_name: 'kiosk.cart.title_label',
                        preference_value: advancedFormValue.cart_title_label
                    },
                    {
                        preference_name: 'kiosk.document_selection.title_label',
                        preference_value: advancedFormValue.document_selection_title_label
                    },
                    {
                        preference_name: 'kiosk.payment_selection.title_label',
                        preference_value: advancedFormValue.payment_selection_title_label
                    },
                    {
                        preference_name: 'kiosk.customer_selection.main_text',
                        preference_value: advancedFormValue.customer_selection_main_text
                    },
                    {
                        preference_name: 'kiosk.customer_selection.main_text_invoice',
                        preference_value: advancedFormValue.customer_selection_main_text_invoice
                    },
                    {
                        preference_name: 'kiosk.customer_selection.guest_label',
                        preference_value: advancedFormValue.customer_selection_guest_label
                    },
                    {
                        preference_name: 'kiosk.customer_selection.create_label',
                        preference_value: advancedFormValue.customer_selection_create_label
                    },
                ];
                advancedPreferences.forEach(obj => {
                    this.configurationManagerService.setShopPreference(obj.preference_name as keyof ConfigurationPreferences, obj.preference_value);
                });
            }

            if (this.appearenceFormImages.valid && this.appearenceFormImages.dirty) {
                for (const [key, control] of Object.entries(this.appearenceFormImages.controls)) {
                    if (control.dirty) {
                        this.configurationManagerService.setShopPreference(key as keyof ConfigurationPreferences, control.value || null);
                    }
                }
                this.appearenceFormImages.markAsPristine();
                this.appearenceFormImages.markAsUntouched();
            }

            if (this.catalogFormImages.valid && this.catalogFormImages.dirty) {
                for (const [key, control] of Object.entries(this.catalogFormImages.controls)) {
                    if (control.dirty) {
                        this.configurationManagerService.setShopPreference(key as keyof ConfigurationPreferences, control.value || null);
                    }
                }
                this.catalogFormImages.markAsPristine();
                this.catalogFormImages.markAsUntouched();
            }

            const labelSuccess = this.translateService.instant('SETTINGS.SETTINGS.SAVE_EDIT_SUCCESSFUL');
            this._snackBar.open(labelSuccess, undefined, {
                duration: 3000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom'
            });
        }
    }

    private log(...args: any[]) {
        DevLogger.log('SettingsKioskComponent ', args);
    }
}
