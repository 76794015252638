<tilby-dialog-toolbar class="mat-mdc-dialog-title" mat-dialog-title
    [hideConfirm]="!confirmMode"
    title="HISTORY.DOCUMENTS_VIEWER.TITLE"
    [customActions]="customActions"
    (confirm)="confirm()"
/>
@if(confirmMode) {
    <div class="tw-flex tw-items-center tw-justify-center tw-mb-2" translate>HISTORY.DOCUMENTS_VIEWER.CONFIRM_EMITTED</div>
} @else {
    <tilby-dialog-tabs [tabs]="tabs" (tabSelected)="onTabChange($event)"></tilby-dialog-tabs>
}
<tilby-dialog-content>
    <div class="tw-min-h-[calc(100vh-110px)] sm:tw-min-h-[200px] tw-w-full sm:tw-w-[40rem] tw-mt-12">
        <div class="historymodule-receipt-box" *ngIf="selectedDocument?.document_type !== 'attachment'">
            @if(selectedDocument?.document_content) {
                <pre class="tw-select-text" [ngClass]="'type_' + selectedDocument?.document_type">{{selectedDocument?.document_content}}</pre>
            } @else {
                <pre translate>HISTORY.DOCUMENTS_VIEWER.NO_DOCUMENTS</pre>
            }
        </div>
        @if(selectedDocument?.document_type === 'attachment') {
            <div class="historymodule-receipt-box">
                <pre>{{selectedDocument?.meta.file_name}}</pre>
            </div>
        }
    </div>
</tilby-dialog-content>