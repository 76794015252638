import {Component} from '@angular/core';

@Component({
    selector: 'app-skeleton-article',
    templateUrl: './skeleton-article.component.html',
    styleUrls: ['./skeleton-article.component.scss'],
    host: {class:'tw-flex-1 tw-p-4 tw-block'}
})
export class SkeletonArticleComponent {

}
