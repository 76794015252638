import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('application').factory('confirmDialog', ["$rootScope", "$mdDialog", "$translate", function($rootScope, $mdDialog, $translate) {
    confirmDialogController.$inject = ["$scope", "message", "options"];

    function confirmDialogController($scope, message, options) {
        if(!_.isObject(options)) {
            options = {};
        }

        $scope.message = message;
        $scope.yesLabel = options.yesLabel || $translate.instant('APPLICATION.CONFIRM_DIALOG.YES');
        $scope.noLabel =  options.noLabel || $translate.instant('APPLICATION.CONFIRM_DIALOG.NO');

        $scope.cancel = function() {
            $mdDialog.hide(false);
        };

        $scope.confirm = function() {
            $mdDialog.hide(true);
        };
    }

    var confirmDialog = {
        show: function(message, options) {
            $rootScope.hideAppLoader();

            var defaultOptions = {
                controller: confirmDialogController,
                multiple: true,
                template: require('./confirm-dialog.html'),
                locals: {
                    message: message,
                    options: options
                }
            };

            if (options) {
                _.defaults(options, defaultOptions);

                if(options.blocking) {
                    options.clickOutsideToClose = false;
                    options.escapeToClose = false;
                }
            } else {
                options = defaultOptions;
            }

            return $mdDialog.show(options);
        }
    };

    return confirmDialog;
}]);
