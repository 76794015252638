import { CommonModule } from '@angular/common';
import { Component, Injector, inject, runInInjectionContext } from '@angular/core';
import { CustomForm, CustomFormGroup, TilbyMagicFormComponent, TilbyMapCoordinatesComponent } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { GeneralFormFields } from '../../../model/general-form-fields';
import { SettingsAlvoloStateService } from '../../../service/settings-alvolo.state.service';
import { ToolbarEventsService } from 'src/app/core';
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import { ReactiveFormsModule } from '@angular/forms';
const { apiKeys } = require('app/tilby.properties.json');

@Component({
    selector: 'app-settings-alvolo-create-shop',
    templateUrl: './settings-alvolo-create-shop.component.html',
    styleUrls: ['./settings-alvolo-create-shop.component.scss'],
    standalone: true,
    imports: [CommonModule, TilbyMagicFormComponent, TilbyMapCoordinatesComponent, ReactiveFormsModule],
})
export class SettingsAlvoloCreateShopComponent {
    private settingsAlvoloStateService = inject(SettingsAlvoloStateService);
    private toolbarEventsService = inject(ToolbarEventsService);
    private injector = inject(Injector);
    generalShopInformationsFormGroup: CustomFormGroup<CustomForm<GeneralFormFields>> = this.settingsAlvoloStateService.createGeneralShopInformationsForm();
    city: string = "Torino";
    country: string = "IT";
    address!: string; 
    latitude!: number;
    longitude!: number;
    google_maps_api = apiKeys.googleMaps;
    withoutAddress: boolean = true;
    latitudeAndLongitude: boolean = false;

    constructor() {
        this.toolbarEventsService.showButtonsBar$.next(true);
    }

    ngAfterViewInit() {
        runInInjectionContext(
            this.injector, 
            () => {
                subscribeInComponent (
                    this.generalShopInformationsFormGroup.controls.addresses.valueChanges,
                    () => {
                        if(this.generalShopInformationsFormGroup.controls.addresses.controls.latitude.value && this.generalShopInformationsFormGroup.controls.addresses.controls.longitude.value) {
                            this.latitude = this.generalShopInformationsFormGroup.controls.addresses.controls.latitude.value;
                            this.longitude = this.generalShopInformationsFormGroup.controls.addresses.controls.longitude.value;

                            this.withoutAddress = false;
                            this.latitudeAndLongitude = true;
                            return;
                        };
                    }
                );
                subscribeInComponent (
                    this.settingsAlvoloStateService.$place,
                    () => {
                        this.settingsAlvoloStateService.$place.subscribe((place) => {
                            
                            if(place) {
                                place.city && (this.city = place.locality);
                                place.country && (this.country = place.country);
                                place.address && (this.address = place.address);
                            }
                        })
                    }
                );
            }
        )
    }
}