import {
    Injectable,
    inject,
} from "@angular/core";

import {
    ConfigurationManagerService,
} from "src/app/core";

import { EnvironmentConfig } from "src/environments/environment-config";

@Injectable({
    providedIn: 'root'
})
export class AlvoloUtilsService {
    private configurationManagerService = inject(ConfigurationManagerService);

    /**
     * Check if Alvolo is enabled
     * @returns {boolean} true if Alvolo is enabled
     */
    public isEnabled(): boolean {
        return !!this.configurationManagerService.getSetting('alvolo.general.alias');
    }

    /**
     * Retrieves the alias from the configuration manager service.
     *
     * @return {string | null} The alias value if it exists, otherwise null.
     */
    public getAlias(): string | null {
        return this.configurationManagerService.getSetting('alvolo.general.alias');
    }

    /**
     * Returns the base URL for the Alvolo API based on the current environment.
     *
     * @return {string} The base URL for the Alvolo API.
     */
    public getBaseUrl(): string {
        return EnvironmentConfig.environment === 'stable' ?
            'https://alvolo.app' :
            'https://devwebapp.alvolo.app';
    }

    /**
     * Returns the Alvolo dashboard URL based on the current environment.
     *
     * @return {string} The dashboard URL.
     */
    public getDashboardUrl(): string {
        return EnvironmentConfig.environment === 'stable' ?
            'https://dashboard.alvolo.app' :
            'https://devdashboard.alvolo.app';
    }
}