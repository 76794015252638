import { Component, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OpenDialogsService } from "../../services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationManagerService, UserActiveSessionManagerService } from "src/app/core";
import { restManager } from "app/ajs-upgraded-providers";

@Component({
    selector: 'app-link-to-deliverect-dialog.component',
    templateUrl: './link-to-deliverect-dialog.component.html',
    styleUrls: ['./link-to-deliverect-dialog.component.scss']
})
export class LinkToDeliverectDialogComponent {

    errorMessage: string = '';
    errorShow: boolean = false;

    shop!: string;
    syncProducts!: string;
    webhookOrders!: string;

    linkToDeliverectDialogForm!: FormGroup;

    isDeliverectEnabled = this.configurationManagerService.getPreference('application.modules.deliverect') || false;

    constructor(
        public dialogRef: MatDialogRef<OpenDialogsService>,
        @Inject(MAT_DIALOG_DATA) public data: {accountID: string, locationID: string},
        @Inject(restManager) private restManagerService: any,
        protected configurationManagerService: ConfigurationManagerService,
        private userActiveSessionManagerService: UserActiveSessionManagerService,
        private formBuilder: FormBuilder,
        private translateService: TranslateService
    )
        {
            this.shop = this.userActiveSessionManagerService.getSession()?.shop.name || '';
            this.syncProducts = 'https://deliverect-webhook.scloby.com/deliverect/products?shop=' + this.shop;
            this.webhookOrders = 'https://deliverect-webhook.scloby.com/deliverect/orders';
    }

    ngOnInit(): void {
        this.linkToDeliverectDialogForm = this.formBuilder.group({
            account_id: ['', Validators.required],
            location_id: ['', Validators.required]
          });
    }

    proceed() {
        const data = this.linkToDeliverectDialogForm.value;
        const accountID = data.account_id;
        const locationID = data.location_id;
        const payload = {
            location_id_deliverect: locationID,
            account_id_deliverect: accountID
        };
        this.restManagerService.post('deliverect/shop', payload).then((result: { message: string; }) => {
            if(result) {
                if(result.message === 'ok') {
                    this.configurationManagerService.setShopPreference('application.modules.deliverect', true);
                    this.dialogRef.close(this.translateService.instant('SETTINGS.LINK_TO_DELIVERECT_DIALOG.SUCCESS_MSG'))
                }
            }}).catch((err: any) => {
                let errorMessage = this.translateService.instant('SETTINGS.LINK_TO_DELIVERECT_DIALOG.ERROR.WRONG_INPUT');
                if(err.data) {
                    const message = err.data.error.message;
                    switch(message) {
                        case 'ACCOUNT_NOT_CONFIGURED':
                            errorMessage =this.translateService.instant('SETTINGS.LINK_TO_DELIVERECT_DIALOG.ERROR.ACCOUNT_NOT_CONFIGURED');
                            break;
                        case 'LOCATION_NOT_FOUND':
                            errorMessage = this.translateService.instant('SETTINGS.LINK_TO_DELIVERECT_DIALOG.ERROR.LOCATION_NOT_FOUND');
                            break;
                        case 'NO_LOCATIONS':
                            errorMessage = this.translateService.instant('SETTINGS.LINK_TO_DELIVERECT_DIALOG.ERROR.NO_LOCATIONS');
                            break;
                        case 'WRONG_INPUT':
                            errorMessage = this.translateService.instant('SETTINGS.LINK_TO_DELIVERECT_DIALOG.ERROR.WRONG_INPUT');
                            break;
                    }
                    this.errorMessage = errorMessage;
                    this.errorShow = true;
                }
                console.log(err);
            });
    }

    isFormInvalid(){
        return this.linkToDeliverectDialogForm.invalid;
    }
}
