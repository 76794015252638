<!--<h2 mat-dialog-title>{{data.messageLabel || 'Title'|translate}}</h2>-->
<form *ngIf="emailForm" autocomplete="off" [formGroup]="emailForm" class="tw-flex">
    <mat-form-field color="primary" class="items tw-flex-1 tw-px-4">
        <mat-label>{{'CUSTOMERS.DETAILS.EMAIL'|translate}}</mat-label>
        <input matInput formControlName="email" type="email" required>
        <mat-hint align="end">{{email.value?.length || '0'}} / {{validators.emailMaxLength}}</mat-hint>
        <mat-error *ngIf="email?.hasError('required')">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
        <mat-error *ngIf="email?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
        <mat-error *ngIf="email?.hasError('maxLength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
            (max. {{validators.emailMaxLength}})
        </mat-error>
    </mat-form-field>
</form>
<!--<mat-dialog-actions align="end" class="tw-px-4 tw-pb-2">-->
<!--    <button mat-button [mat-dialog-close]="false">{{data.cancelLabel || 'DIALOG.EMAIL.ACTION.CANCEL'|translate}}</button>-->
<!--    <button mat-flat-button [mat-dialog-close]="emailForm.value" cdkFocusInitial color="primary">{{(data.confirmLabel || 'DIALOG.EMAIL.ACTION.CONFIRM')|translate}}</button>-->
<!--</mat-dialog-actions>-->
