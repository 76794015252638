<ngx-skeleton-loader count="1" [theme]="{ width: '100vw', height: '70px', margin:'auto', background: 'darkgray'}"></ngx-skeleton-loader>
<ngx-skeleton-loader
    count="1"
    [theme]="{
    width: '10rem',
    height: '2rem',
    margin:'auto',
    position: 'absolute',
    top: '-200%',
    left: '5%'
    }"></ngx-skeleton-loader>
