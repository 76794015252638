import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';

angular.module('fileImporter').controller('FileImporterAddCtrl', ["$scope", "$state", "$translate", "$q", "alertDialog", "confirmDialog", "restManager", "checkManager", "util", "fileConfig", function($scope, $state, $translate, $q, alertDialog, confirmDialog, restManager, checkManager, util, fileConfig) {
    _.assign($scope, {
        topbar_context: {
            title: $translate.instant('FILE_IMPORTER.ADD.TITLE'),
            canGoBack: function() { return true; },
            goBack: function() {
                confirmDialog.show($translate.instant('FILE_IMPORTER.BACK_CONFIRM')).then(function(answer) {
                    if(answer) {
                        $state.go('app.file_importer.showcase');
                    }
                });
            },
            canConfirm: function() { return true; },
        },
        isProcessing: false
    });

    $scope.importTypes = [
        {
            id: 'items',
            name: $translate.instant('FILE_IMPORTER.IMPORT_TYPES.ITEMS')
        },
        {
            id: 'stock_movements',
            name: $translate.instant('FILE_IMPORTER.IMPORT_TYPES.STOCK_MOVEMENTS')
        }
    ];

    $scope.fileTypes = [
        {
            id: 'xls',
            name: $translate.instant('FILE_IMPORTER.ADD.FILE_TYPES.XLS')
        }, {
            id: 'xlsx',
            name: $translate.instant('FILE_IMPORTER.ADD.FILE_TYPES.XLSX')
        }, {
            id: 'csv',
            name: $translate.instant('FILE_IMPORTER.ADD.FILE_TYPES.CSV')
        }
    ];

    $scope.columnDelimiters = [',',';','|'];
    $scope.stringDelimiters = ['\'', '"'];

    $scope.separatorType = 'stringDelimited';

    $scope.decimalSeparators = [{
        name: $translate.instant('FILE_IMPORTER.ADD.DECIMAL_SEPARATORS.DOT'),
        value: '.'
    }, {
        name: $translate.instant('FILE_IMPORTER.ADD.DECIMAL_SEPARATORS.COMMA'),
        value: ','
    }];

    $scope.fileConfig = fileConfig;

    var checkExtension = function(fileName) {
        if(_.includes(fileName, '.csv')) {
            $scope.fileConfig.type = 'csv';
        } else if (_.includes(fileName, '.xlsx')) {
            $scope.fileConfig.type = 'xlsx';
        } else if (_.includes(fileName, '.xls')) {
            $scope.fileConfig.type = 'xls';
        }
    };

    $scope.checkUrlExtension = function() {
        return checkExtension($scope.fileConfig.upload.url);
    };

    $scope.checkFileExtension = function() {
        if(_.get($scope.uploadedFile, [0, 'name'])) {
            return checkExtension($scope.uploadedFile[0].name);
        }
    };

    var addFile = function() {
        var d = $q.defer();
        var fileToUpload = $scope.uploadedFile[0];
        var dataToSend = _.cloneDeep($scope.fileConfig);

        restManager.post('importing/file', _.cloneDeep(dataToSend)).then(function(result) {
            if(result.url) {
                if(!_.isNil(result.sizeMax) && _.toInteger(result.sizeMax) * 1024 < fileToUpload.size) {
                    d.reject({
                        code: 'FILE_TOO_BIG',
                        values: { size: _.toInteger(result.sizeMax / 1000) }
                    });
                } else {
                    var reader = new FileReader();
                    var mimeType = fileToUpload.type;
    
                    reader.onload = function(e) {
                        var fileMeta = e.target.result;
    
                        $.ajax({
                            url: result.url,
                            type: 'PUT',
                            data: util.dataURLToBlob(fileMeta),
                            contentType: mimeType,
                            processData: false
                        }).done(function(resultUpload) {
                            d.resolve(result);
                        }).fail(function(e) {
                            d.reject('FILE_ADD_ERROR');
                        });
                    };
    
                    reader.onerror = function(e) {
                        d.reject("FILE_ADD_ERROR");
                    };
    
                    reader.readAsDataURL(fileToUpload);
                }
            } else {
                d.resolve(result);
            }
        }, d.reject);

        return d.promise;
    };

    $scope.topbar_context.confirm = function() {
        if($scope.fileImporterAddForm.$valid) {
            if(!$scope.isProcessing) {
                if(!_.isNil(_.get($scope.uploadedFile,[0])) || $scope.fileConfig.upload.mode === 'url') {
                    $scope.isProcessing = true;

                    if($scope.fileConfig.decimalSeparator) {
                        checkManager.setUserPreference('file_importer.decimal_separator', $scope.fileConfig.decimalSeparator);
                    }
                    if($scope.fileConfig.delimitatorColumn) {
                        checkManager.setUserPreference('file_importer.delimitator_column', $scope.fileConfig.delimitatorColumn);
                    }
                    if($scope.fileConfig.separationFixed) {
                        checkManager.setUserPreference('file_importer.separation_fixed', $scope.fileConfig.separationFixed);
                    }
                    if($scope.fileConfig.delimitatorString) {
                        checkManager.setUserPreference('file_importer.delimitator_string', $scope.fileConfig.delimitatorString);
                    }

                    addFile().then(function(result) {
                        return $state.go('app.file_importer.fields',{
                            id: result.id,
                            fileConfig: _.cloneDeep($scope.fileConfig)
                        });
                    }, function(error) {
                        if(_.isObject(error)) {
                            alertDialog.show($translate.instant('FILE_IMPORTER.ADD.' + error.code, error.values));
                        } else {
                            alertDialog.show($translate.instant('FILE_IMPORTER.ADD.' + error));
                        }
                    }).finally(function() {
                        $scope.isProcessing = false;
                    });
                } else {
                    alertDialog.show($translate.instant('FILE_IMPORTER.ADD.FILE_MISSING'));
                }
            }
        } else {
            alertDialog.show($translate.instant($scope.fileImporterAddForm.$error.required ? 'FORM_ERRORS.INSERT_REQUIRED_DATA' : 'FORM_ERRORS.THERE_ARE_ERRORS'));
        }
    };
}]);