import {Component, Inject, OnInit} from '@angular/core';
import {$state} from "app/ajs-upgraded-providers";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

    constructor(@Inject($state)private state: any) {
        this.state.go("app.history");
    }

  ngOnInit(): void {
  }

}
