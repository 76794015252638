import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import { DataAlertDialog } from 'src/app/dialogs/dialog.model';
import {AlertDialogComponent} from "../../dialogs";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService {

    constructor(private translate: TranslateService, private dialog: MatDialog,) {
    }

    async printError(errorMessage: string) {
        return await lastValueFrom(this.openAlertDialog({messageLabel:this.translate.instant(errorMessage)}).afterClosed());
    }

    private openAlertDialog({messageLabel, confirmLabel}: DataAlertDialog) {
        return this.dialog.open<AlertDialogComponent, DataAlertDialog>(AlertDialogComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: {
                messageLabel: this.translate.instant(messageLabel),
                confirmLabel: confirmLabel ? this.translate.instant(confirmLabel) : 'Ok',
            }
        });
    }
}
