<mat-tab-group #tabs [mat-stretch-tabs]="true" mat-align-tabs="start"
               class="tw-h-full tw-w-full"
               [selectedIndex]="tabIndexValueMatTabsAtInit"
               (selectedTabChange)="selectedTabChange($event)"
               (touchstart)="onTouchStart($event)"
               tilbyGestures (swipeRight)="!isEditMode()&&swipeRight($event)" (swipeLeft)="!isEditMode()&&swipeLeft($event)">
    @for(room of rooms; track room.id) {
        <mat-tab [label]="room.name">
            <ng-template matTabContent  #roomElement>
                    <app-room class="tw-relative tw-block tw-h-full" [roomHeight]="(room.height||1)*roomMultiplier+'px'" [roomWidth]="(room.width||1)*roomMultiplier+'px'" [roomFloor]="$any(room.floor)" [room]="room" [isEditMode]="isEditMode()" (click)="onRoomClick()" (sizeEmitter)="saveNewRoomSizes($event)">
                        @for(table of (room.tables||[]);track table.id){
                            <app-room-element
                                #tables class="tw-block"
                                [hangingAlarm]="hangingAlarm"
                                [sales]="tableSalesMap[table.id!] || []"
                                [table]="table"
                                [tableBookingWarning]="!!bookingPerTableMap[table.id!]"
                                [useLastupdateTimer]="useLastupdateTimer"
                                [disabledToggleShowAmount]="disabledToggleShowAmount"
                                (tableClick)="tableClick($event,isEditMode())"
                                (tableLongPress)="tableClick($event,isEditMode(),true)"
                                (tableMoved)="tableMoved($event,room)"/>
                        }
                    </app-room>
            </ng-template>
        </mat-tab>
    }
    <mat-tab label="{{'TABLES.DELIVERY.TITLE' | translate}}"><ng-template matTabContent><table-delivery [sales]="sales"></table-delivery></ng-template></mat-tab>
</mat-tab-group>

