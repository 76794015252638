@if(canUseCameraBarcode) {
    <div>
        @switch(mode) {
            @case ('normal') {
                <img class="barcode-scan-image" [src]="'assets/images/sales/scan-icon.png'" (click)="openCameraBarcode()" alt=""/>
            }
            @case ('topbar') {
                <button mat-icon-button aria-label="Foto Barcode" (click)="openCameraBarcode()">
                    <mat-icon alt="Menu Icon">photo_camera</mat-icon>
                </button>
            }
        }
    </div>
}