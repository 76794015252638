<div class="first-section-wrapper">
    <div class="tw-flex tw-gap-x-10">
        <ngx-skeleton-loader
            [appearance]="$any('square')"
            [theme]="{ width: '80px', height: '80px', background: 'gainsboro' }">
        </ngx-skeleton-loader>
        <div class="tw-grid tw-grid-cols-6 tw-grid-rows-2 tw-gap-x-4 tw-items-center tw-w-1/2">
            <ngx-skeleton-loader class="tw-col-span-5" [theme]="{ background: 'gainsboro'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="tw-col-span-3" [theme]="{ background: 'gainsboro'}"></ngx-skeleton-loader>
        </div>
    </div>
</div>

