import {
	Component,
	Injectable,
	OnInit
} from "@angular/core";

import {
	MatSnackBarRef,
	MatSnackBar,
	MatSnackBarConfig
} from "@angular/material/snack-bar";

import {
	TranslateModule,
	TranslateService
} from "@ngx-translate/core";

@Component({
	selector: 'app-generic-toast',
	templateUrl: './generic-toast.component.html',
	styleUrls: ['./generic-toast.component.scss'],
	standalone: true,
	imports: [
		TranslateModule
	]
})
export class GenericToastComponent implements OnInit {

	data: { title: string, titleParams: { [key: string]: string }, actionDismiss: string, actionDismissWithAction: string } = this.sbRef.containerInstance.snackBarConfig.data;

	constructor(
		public sbRef: MatSnackBarRef<GenericToastComponent>,
		public translate: TranslateService) {
	}

	dismissWithAction() {
		this.sbRef.dismissWithAction();
	}

	dismiss() {
		this.sbRef.dismiss();
	}

	ngOnInit() {
	}
}


@Injectable({
	providedIn: 'root'
})
export class GenericToastService {
	constructor(
		private matSnackBar: MatSnackBar
	) { }

	show(title: string, titleParams: { [key: string]: string }, actionDismiss: string, actionDismissWithAction: string, config?: Partial<MatSnackBarConfig>) {
		return this.matSnackBar.openFromComponent(GenericToastComponent, {
			data: { title: title, titleParams: titleParams, actionDismiss: actionDismiss, actionDismissWithAction: actionDismissWithAction},
			verticalPosition: "bottom",
			horizontalPosition: "left",
			panelClass:['mat-snack-bar-tilby'],
			...config
		});
	}
}