import { AWSCredentials, CredentialsProvider } from "aws-crt/dist.browser/browser/auth";

export type AWS_CREDENTIALS = {
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken: string,
    expiration: string,
    endpoint_iot: string
    region_iot: string,
    shop_name: string
}
export type MessagingConfig = {
    userId: string;
    client: string;
    shop: string;
}
export class IotError {
    message: string;
    payload: any;
    constructor(message: string, payload: any) {
        this.message = message;
        this.payload = payload;
    }
}
export enum MESSAGE_ORIGIN {
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    DELETED = "DELETED",
    CLOSED = "CLOSED",
    STOCK_UPDATED = "STOCK_UPDATED",
    FIDELITY_UPDATED = "FIDELITY_UPDATED",
    PREPAID_UPDATED = "PREPAID_UPDATED",
    IMPORTING_ENDED = "IMPORTING_ENDED"
}
export enum EVENTS_IOT {
    resume = "resume",
    disconnect = "disconnect",
    error = "error",
    iot_delay_next_id = "iot_delay_next_id",
    iot_need_login = "iot_need_login"
}
export enum ORDER_HISTORY {
    desc = "desc",
    asc = "asc"
}
export type Notification = {
    msg_id: number,
    type: MESSAGE_ORIGIN,
    progressive: number,
    entity_name: string,
    time: number,
    entity_uuid: string,
    entity_id: string,
    entities: any[],
    client_id: string,
    user_id: string,
    session_origin: string
    external_client: boolean
}
export type HistoryPagination = {
    range_max: number | undefined,
    range_min: number | undefined,
    limit: number,
    order: ORDER_HISTORY,
    is_v2_table: boolean
}
export type NotificationResult = {
    items: Notification[]
    firstItem: Notification
    count: number
}

export type IotEmitter = (event_name: EVENTS_IOT, message: any) => void
export type StorageManagerFunction = (entity_name: any, entity: any) => Promise<any>
export type GetAPi = (entity_name: any, id: any | undefined, queryString: any | undefined) => Promise<any>
export type OnMessageIot = (msg: Notification) => void
export type IotBroadcastCallback = (msg: Notification) => void
export type MissingMessagesResult = { missing: boolean, firstElementMissing: number };
export class Provider extends CredentialsProvider {
    credentials: AWSCredentials;
    credentials_from_api?: AWS_CREDENTIALS
    constructor(credentials_from_api?: AWS_CREDENTIALS) {
        super();
        if (credentials_from_api) {
            this.credentials = {
                aws_access_id: credentials_from_api.accessKeyId,
                aws_secret_key: credentials_from_api.secretAccessKey,
                aws_region: credentials_from_api.region_iot,
                aws_sts_token: credentials_from_api.sessionToken
            }
            this.credentials_from_api = credentials_from_api;
        } else {
            this.credentials = {
                aws_access_id: "",
                aws_secret_key: "",
                aws_region: "",
                aws_sts_token: ""
            }
        }

    }
    async refreshCredentialsFromApi(credentials_from_api: AWS_CREDENTIALS) {
        this.credentials_from_api = credentials_from_api;
        return;
    }
    async refreshCredentials() {
        this.credentials = {
            aws_access_id: this.credentials_from_api!.accessKeyId,
            aws_secret_key: this.credentials_from_api!.secretAccessKey,
            aws_region: this.credentials_from_api!.region_iot,
            aws_sts_token: this.credentials_from_api!.sessionToken
        };
        return;
    }
    getCredentials(): AWSCredentials {
        return this.credentials;
    }
}