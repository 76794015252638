<div class="tw-container tw-h-[calc(100vh-113px)] tw-overflow-scroll tw-w-[calc(100%-41px)] tw-p-5">
    <section *ngIf="option.key == 0" id="logo_alvolo" class="tw-text-right tw-my-5">
        <img ngSrc="assets/images/alvolo/logo_alvolo.svg" width="100" height="20">
    </section>
    <section *ngIf="option.key == 0" id="summary" class="tw-flex">
        <mat-list class="tw-w-2/3" role="list">
            <span class="tw-flex tw-items-center tw-justify-between text-stone-500" style="color: grey;" translate="SETTINGS.ALVOLO.SUMMARY.STATE">
            </span>
            <mat-divider></mat-divider>
            <span class="tw-flex tw-items-center tw-justify-between" translate="SETTINGS.ALVOLO.SUMMARY.SALES">
                <p *skeleton="isLoading;appearance:'line'; theme:{width:'200px', height:'15px', background: 'darkgray', margin:'16px'}"></p>
                <p *ngIf="!isLoading && shopStatus.payload!.sales_enabled"  style="color: green" translate="SETTINGS.ALVOLO.SUMMARY.ACTIVE"></p>
                <p *ngIf="!isLoading && !shopStatus.payload!.sales_enabled" style="color: orange" translate="SETTINGS.ALVOLO.SUMMARY.DEACTIVE"></p>
            </span>
            <mat-divider></mat-divider>
            <span class="tw-flex tw-items-center tw-justify-between" translate="SETTINGS.ALVOLO.SUMMARY.RESERVATIONS">
                <p *skeleton="isLoading;appearance:'line'; theme:{width:'200px', height:'15px', background: 'darkgray', margin:'16px'}"></p>
                <p *ngIf="!isLoading && shopStatus.payload!.bookings_enabled"  style="color: green" translate="SETTINGS.ALVOLO.SUMMARY.ACTIVE"></p>
                <p *ngIf="!isLoading && !shopStatus.payload!.bookings_enabled" style="color: orange" translate="SETTINGS.ALVOLO.SUMMARY.DEACTIVE"></p>
            </span>
        </mat-list>
        <div id="qrcode" class="tw-flex-auto tw-text-center tw-ml-[80px]">
            <div *ngIf="qrCode" id="qr-code" class="tw-flex tw-items-center tw-justify-center tw-flex-none tw-w-72 tw-h-72 tw-bg-blue-500 tw-rounded-3xl tw-mx-auto">
                <qrcode #parent [qrdata]="qrCode" [elementType]="elementType" [width]="220" [errorCorrectionLevel]="'M'" cssClass="tw-rounded-3xl"></qrcode>
            </div>
            <button mat-button (click)="saveAsImage(parent)" class="tw-m-4">
                <span class="material-symbols-outlined">download</span>
                <span translate="SETTINGS.ALVOLO.SUMMARY.SAVE_IMAGE"></span>
            </button>
            <div>
                <button mat-button (click)="redirectToAlvolo()">
                    <mat-icon>open_in_new</mat-icon>
                    {{"SETTINGS.ALVOLO.SUMMARY.GO_TO_SITE" | translate}}
                </button>
            </div>
        </div>
    </section>
    <section *ngIf="option.key == 1" id="shop_informations" class="tw-flex">
        <tilby-magic-form [form]="generalShopInformationsFormGroup">
            <ng-container [formGroup]="generalShopInformationsFormGroup">
                <tilby-map-coordinates #contentProjection formControlLabel="map">
                    @if(withoutAddress){
                        <img id="mapCoordinates" mapCoordinates src="https://maps.googleapis.com/maps/api/staticmap?center={{city}},{{country}}&zoom=12&size=1600x300&key={{google_maps_api}}"/>
                    }
                    @if(latitudeAndLongitude){
                        <img id="mapCoordinates" mapCoordinates src="https://maps.googleapis.com/maps/api/staticmap?center={{latitude}},{{longitude}}&zoom=16&size=600x400&markers=color:red%7C{{latitude}},{{longitude}}&key={{google_maps_api}}"/>
                    }
                </tilby-map-coordinates>
            </ng-container>
        </tilby-magic-form>
    </section>
    <section *ngIf="option.key == 2" id="time_slots" class="tw-flex tw-h-full">
        <div class="tw-flex tw-flex-col tw-h-max tw-w-full tw-gap-5">
            <div>
                <button mat-raised-button (click)="addTimeSlot()" translate="SETTINGS.ALVOLO.HOURS.ADD_TIME_SLOT"></button>
            </div>
            <div>
                <section class="tw-h-[calc(100vh-180px)] tw-overflow-auto" tabindex="0">
                    <table mat-table [dataSource]="dataSource" class="tw-bg-transparent">
                        <ng-container matColumnDef="order">
                          <th mat-header-cell *matHeaderCellDef translate="SETTINGS.ALVOLO.HOURS.ORDER"></th>
                          <td mat-cell *matCellDef="let element; let i = index" [hidden]="!element.name">{{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef translate="SETTINGS.ALVOLO.HOURS.NAME"></th>
                          <td mat-cell *matCellDef="let element" [hidden]="!element.name"> {{element.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="validity">
                            <th mat-header-cell *matHeaderCellDef translate="SETTINGS.ALVOLO.HOURS.VALIDITY"></th>
                            <td mat-cell *matCellDef="let element" class="tw-py-2" [hidden]="!element.name"> 
                                <li *ngIf="element.start || element.end" class="tw-flex tw-p-0 tw-list-none">{{element.start}} - {{element.end}}</li>
                                <br>
                                <div *ngIf="element.week_day">
                                    {{week_days[element.week_day] | translate}} 
                                </div>
                                <!-- <ul *ngIf="element.week_day" class="tw-flex tw-p-0 tw-m-0 tw-list-none">
                                    <li *ngFor="let day of element.week_day; let i = index">
                                        {{week_days[day] | translate}}{{i === element.week_day.length - 1 ? '' : '-'}}
                                    </li>
                                </ul> -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="star" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                            <td mat-cell *matCellDef="let element; let i = index; let isLast = last" class="tw-text-center" [hidden]="!element.name">
                                <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item translate="SETTINGS.ALVOLO.HOURS.OPTIONS.DETAILS" (click)="editTimeSlot(element, i)"></button>
                                    <button mat-menu-item [disabled]="i == 0 || disableMoveUpButton" translate="SETTINGS.ALVOLO.HOURS.OPTIONS.MOVE_UP" (click)="moveUpTimeSlot(i)"></button>
                                    <button mat-menu-item [disabled]="isLast || disableMoveDownButton" translate="SETTINGS.ALVOLO.HOURS.OPTIONS.MOVE_DOWN" (click)="moveDownTimeSlot(i)"></button>
                                    <button mat-menu-item disabled={{disableDuplicateButton}} translate="SETTINGS.ALVOLO.HOURS.OPTIONS.DUPLICATE" (click)="duplicateTimeSlot(i)"></button>
                                    <button mat-menu-item disabled={{disableDeleteButton}} translate="SETTINGS.ALVOLO.HOURS.OPTIONS.DELETE" (click)="removeTimeSlot(i)"></button>
                                </mat-menu>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>              
                        <tr mat-row
                            (click)="editTimeSlot(row)"
                            *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                </section>
            </div>
        </div>
    </section>
    <section *ngIf="option.key == 3" id="management" class="tw-flex-1 tw-flex-col">
        <div class="sass tw-w-[100%] tw-text-xl tw-pb-4 tw-ml-2">
            <span translate="SETTINGS.ALVOLO.MANAGEMENT.TITLE"></span>
        </div>
        <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
            <div class=" tw-flex tw-items-center tw-gap-x-10">
                <mat-label class="tw-text-gray-400" translate="SETTINGS.ALVOLO.MANAGEMENT.LIST_IN_USE"></mat-label>
                <mat-form-field class="the-dense-zone" subscriptSizing="dynamic">
                    <mat-select (selectionChange)="changePriceListId($event.value)" [(ngModel)]="priceList">
                        <mat-option  *ngFor="let list of priceLists" [value]="list">{{list.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="tw-px-8">
                <mat-error *ngIf="" translate="SETTINGS.ALVOLO.ACTIVATE.LIST_MANDATORY">
                </mat-error>
            </div>
        </section>
        <mat-divider></mat-divider>
        <div class="tw-flex-1 tw-flex-row tw-flex-nowrap tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
            <button mat-stroked-button class="tw-p-6" translate="SETTINGS.ALVOLO.MANAGEMENT.PUBLISH_PRODUCT" (click)="publish()"></button>
            <span *ngIf="lastSync && !isPublishing" class="tw-px-5">{{'SETTINGS.ALVOLO.MANAGEMENT.LAST_PUBLISH_DAY' | translate}} {{lastPublishDay | translate}} {{'SETTINGS.ALVOLO.MANAGEMENT.LAST_PUBLISH_HOUR' | translate}} {{lastPublishHour | translate}}</span>
            <span *ngIf="isPublishing" class="tw-px-5">{{'SETTINGS.ALVOLO.MANAGEMENT.PUBLICATION_IN_PROGRESS' | translate}}</span>
        </div>
        <mat-divider class="tw-border-[#000000]"></mat-divider>
        <div class="sass tw-w-[100%] tw-text-xl tw-py-8 tw-ml-2">
            <span translate="SETTINGS.ALVOLO.MANAGEMENT.MANAGE_IMAGE_PANEL"></span>
        </div>
        <div class="tw-flex tw-flex-col tw-ml-2 tw-gap-3">
            <div class="tw-flex tw-flex-row tw-items-baseline tw-gap-5 tw-h-[55px]">
                <span translate="SETTINGS.ALVOLO.MANAGEMENT.SHOP_KEY"></span>
                <mat-form-field subscriptSizing="dynamic">
                    <input matInput #shopkey [(ngModel)]="shopSettingKey" readonly="true">
                </mat-form-field>
                <div>
                    <button mat-button (click)="copyText('SETTINGS.ALVOLO.MANAGEMENT.SHOP_KEY',shopkey)">
                        <span class="material-icons-outlined">
                            content_copy
                        </span>
                    </button>
                </div>
            </div>
            <div class="tw-flex tw-flex-row tw-items-baseline tw-gap-4 tw-h-[55px]">
                <span translate="SETTINGS.ALVOLO.MANAGEMENT.PASSWORD"></span>
                <mat-form-field subscriptSizing="dynamic">
                    <input matInput #password [(ngModel)]="shopSettingPassword" readonly="true">
                </mat-form-field>
                <div class="tw-pl-1">
                    <button mat-button (click)="copyText('SETTINGS.ALVOLO.MANAGEMENT.PASSWORD',password)">
                        <span class="material-icons-outlined">
                            content_copy
                        </span>
                    </button>
                </div>
            </div>
            <div>
                <button mat-button (click)="redirectToDashboard()">
                    <mat-icon>open_in_new</mat-icon>
                    {{"SETTINGS.ALVOLO.SUMMARY.GO_TO_SITE" | translate}}
                </button>
            </div>
        </div>
    </section>
    <section *ngIf="option.key == 4" id="payments" class="tw-flex tw-flex-col">
        <div class="sass tw-w-[100%] tw-text-xl tw-pb-4 tw-ml-2">
            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS"></span>
        </div>
        <form [formGroup]="paymentsAvailablesForm" (ngSubmit)="onSubmitAvailablePaymentsForm()">
            <div class="container tw-flex tw-w-full">
                <div class="col tw-w-1/2 tw-mr-8">
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="credit_card">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.CREDIT_CARD"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="debit_card">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.DEBIT_CARD"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="cash">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.CASH"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                        <mat-checkbox formControlName="app_nexi">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.APP_NEXI"></span>
                        </mat-checkbox>
                        <span *ngIf="paymentsLinksToShow.app_nexi">
                            <button (click)="configureAlvoloNexi($event)" class="tw-border-0 tw-bg-transparent">
                                <span class="material-symbols-outlined tw-flex">settings</span>
                            </button>
                        </span>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="webapp_nexi">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.WEB_APP_NEXI"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="app_paypal">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.APP_PAYPAL"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                        <mat-checkbox formControlName="paypal">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.PAYPAL"></span>
                        </mat-checkbox>
                        <span *ngIf="paymentsLinksToShow.paypal">
                            <button (click)="configureAlvoloPaypal($event)" class="tw-border-0 tw-bg-transparent">
                                <span class="material-symbols-outlined tw-flex">settings</span>
                            </button>
                        </span>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="webapp_paypal">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.WEB_APP_PAYPAL"></span>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="col tw-w-1/2 tw-mr-8">
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="app_satispay">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.APP_SATISPAY"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                        <mat-checkbox formControlName="satispay">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.SATISPAY"></span>
                        </mat-checkbox>
                        <span *ngIf="paymentsLinksToShow.satispay">
                            <button (click)="configureAlvoloSatispay($event)" class="tw-border-0 tw-bg-transparent">
                                <span class="material-symbols-outlined tw-flex">settings</span>
                            </button>
                        </span>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="webapp_satispay">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.WEB_APP_SATISPAY"></span>
                        </mat-checkbox>
                    </div>
                    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                        <mat-checkbox formControlName="app_stripe">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.APP_STRIPE"></span>
                        </mat-checkbox>
                        <span *ngIf="paymentsLinksToShow.app_stripe" style="padding-right: 0.35rem!important;">
                            <button class="tw-bg-[inherit] tw-border-0 tw-p-0" (click)="redirectToStripeConfiguration($event, shopSettingKey, shopSettingAlias)">
                                <span class="material-symbols-outlined tw-flex">settings</span>
                            </button>
                        </span>
                    </div>
                    <div class="tw-flex tw-flex-row tw-align-middle tw-gap-4">
                        <mat-checkbox formControlName="webapp_stripe">
                            <span translate="SETTINGS.ALVOLO.PAYMENTS.AVAILABLE_PAYMENTS_TYPE.WEB_APP_STRIPE"></span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </section>
    <section *ngIf="option.key == 6" id="self_ordering" class="tw-text-center">
        <h1 class="tw-text-left" translate="SETTINGS.ALVOLO.SELF_ORDERING.GENERATE_QRCODE"></h1>
        <div class="tw-flex tw-flex-col tw-text-left">
            <label class="tw-my-5" translate="SETTINGS.ALVOLO.SELF_ORDERING.SELF_ORDERING_TYPE"></label>
            <mat-radio-group aria-label="generate_qrcode" class="tw-flex tw-flex-col" [(ngModel)]="generateCodeType" (change)="radioChange($event)">
                <mat-radio-button value="free_order" disabled={{disableDownloadButton}}><span translate="SETTINGS.ALVOLO.SELF_ORDERING.FREE_ORDER"></span></mat-radio-button>
                <mat-radio-button value="tables" disabled={{disableDownloadButton}}><span translate="SETTINGS.ALVOLO.SELF_ORDERING.TABLES"></span></mat-radio-button>
                <mat-radio-button value="rooms" disabled={{disableDownloadButton}}><span translate="SETTINGS.ALVOLO.SELF_ORDERING.ROOMS"></span></mat-radio-button>
                <mat-radio-button value="custom_codes" disabled={{disableDownloadButton}}><span translate="SETTINGS.ALVOLO.SELF_ORDERING.CUSTOM_CODES"></span></mat-radio-button>
            </mat-radio-group>
            <div *ngIf="generateCodeType == 'rooms'" class="tw-flex tw-flex-col tw-mt-3">
                <tilby-magic-form [form]="roomsFormGroup"/>
            </div>
            <div *ngIf="generateCodeType == 'custom_codes'" class="tw-mt-3">
                <div class="tw-flex tw-flex-col">
                    <span class="tw-py-1" translate="SETTINGS.ALVOLO.SELF_ORDERING.CUSTOM_CODES"></span>
                    <label class="tw-italic tw-pb-2" translate="SETTINGS.ALVOLO.SELF_ORDERING.INSERT_CODE_PER_LINE"></label>
                </div>
                <mat-form-field class="tw-w-1/2">
                    <textarea matInput id="text_area_custom_codes" rows="5" [(ngModel)]="custom_codes" (keyup)="onCustomCodesChange($event)"></textarea>
                </mat-form-field>
            </div>

            <label class="tw-my-5" translate="SETTINGS.ALVOLO.SELF_ORDERING.SELF_ORDERING_GRAPHIC"></label>
            <mat-radio-group aria-label="graphic" class="tw-flex tw-flex-col" [(ngModel)]="generateCodeGraphic" (change)="radioChangeGraphic($event)">
                <mat-radio-button value="vertical_placeholder" disabled={{disableDownloadButton}}><span translate="SETTINGS.ALVOLO.SELF_ORDERING.VERTICAL_PLACEHOLDER"></span></mat-radio-button>
                <mat-radio-button value="only_qrcode" disabled={{disableDownloadButton}}><span translate="SETTINGS.ALVOLO.SELF_ORDERING.ONLY_QRCODE"></span></mat-radio-button>
            </mat-radio-group>
            <div class="tw-w-2/5 tw-mt-3">
                <p *skeleton="disableDownloadButton;appearance:'line'; theme:{width:'200px', height:'15px', background: 'darkgray', margin:'16px'}"></p>

                <button mat-stroked-button class="tw-p-6" *ngIf="!disableDownloadButton" mat-button class="tw-w-2/3" (click)="downloadQrCodes()" translate="SETTINGS.ALVOLO.SELF_ORDERING.GENERATE_CODES"
                    disabled="{{!radioButtonSelected || !generateCodeGraphic || (radioButtonSelected === 'rooms' && (!roomsFormGroup.controls.final_room_id.valid || !roomsFormGroup.controls.final_room_id.value || !roomsFormGroup.controls.initial_room_id.valid || !roomsFormGroup.controls.initial_room_id.value) || disableSubmitSelfOrderingButtonWait)
                    || (radioButtonSelected === 'custom_codes' && !custom_codes) || (radioButtonSelected === 'tables' ?  disableSubmitSelfOrderingButtonWait : false)}}">
                </button>
            </div>
        </div>
    </section>
    <section *ngIf="option.key == 7" id="table_reservation" class="tw-flex tw-flex-col tw-h-full">      
        @if(!this.alvolo_bookings) {
            <div class="tw-py-3">
                <span translate="SETTINGS.ALVOLO.TABLE_RESERVATION.INACTIVE_MODULE"></span>
            </div>
        }
        <div class="tw-flex tw-flex-col tw-h-max tw-w-full tw-gap-5">
            <tilby-magic-form [form]="tableReservationForm"/>
            <mat-divider></mat-divider>
            <div>
                <button mat-raised-button disabled="{{!this.alvolo_bookings}}" (click)="addBookingTimeSlot()" translate="SETTINGS.ALVOLO.TABLE_RESERVATION.ADD_BOOKINGS_TIME"></button>
            </div>
            <div>
                <section class="tw-overflow-auto" tabindex="0">
                    <table mat-table [class.disabled-table]="!this.alvolo_bookings" [dataSource]="bookings_time" class="tw-bg-transparent">
                        <ng-container matColumnDef="order">
                          <th mat-header-cell *matHeaderCellDef translate="SETTINGS.ALVOLO.HOURS.ORDER"></th>
                          <td mat-cell *matCellDef="let element; let i = index" [hidden]="!element.name">{{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef translate="SETTINGS.ALVOLO.HOURS.NAME"></th>
                          <td mat-cell *matCellDef="let element" [hidden]="!element.name"> {{element.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="validity">
                            <th mat-header-cell *matHeaderCellDef translate="SETTINGS.ALVOLO.HOURS.VALIDITY"></th>
                            <td mat-cell *matCellDef="let element" class="tw-py-2" [hidden]="!element.name"> 
                                <li *ngIf="element.start || element.end" class="tw-flex tw-p-0 tw-list-none">{{element.start}} - {{element.end}}</li>
                                <br>
                                <div *ngIf="element.week_day">
                                    {{week_days[element.week_day] | translate}} 
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="star" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                            <td mat-cell *matCellDef="let element; let i = index; let isLast = last" class="tw-text-center" [hidden]="!element.name">
                                <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item translate="SETTINGS.ALVOLO.HOURS.OPTIONS.DETAILS" (click)="editBookingTimeSlot(element, i)"></button>
                                    <button mat-menu-item [disabled]="i == 0 || disableMoveUpButton" translate="SETTINGS.ALVOLO.HOURS.OPTIONS.MOVE_UP" (click)="moveUpBookingTimeSlot(i)"></button>
                                    <button mat-menu-item [disabled]="isLast || disableMoveDownButton" translate="SETTINGS.ALVOLO.HOURS.OPTIONS.MOVE_DOWN" (click)="moveDownBookingTimeSlot(i)"></button>
                                    <button mat-menu-item disabled={{disableDuplicateButton}} translate="SETTINGS.ALVOLO.HOURS.OPTIONS.DUPLICATE" (click)="duplicateBookingTimeSlot(i)"></button>
                                    <button mat-menu-item disabled={{disableDeleteButton}} translate="SETTINGS.ALVOLO.HOURS.OPTIONS.DELETE" (click)="removeBookingTimeSlot(i)"></button>
                                </mat-menu>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>              
                        <tr mat-row
                            (click)="editBookingTimeSlot(row)"
                            *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                </section>
            </div>
        </div>
    </section>
</div>