<mat-toolbar class="tw-flex tw-flex-row tw-text-base tw-pt-2 tw-h-max tw-w-full" [attr.border-color]="'primary'">
    <form *ngIf="prepaidCreditForm" autocomplete="off" [formGroup]="prepaidCreditForm" class="form tw-flex tw-flex-col sm:tw-flex-row" style="width: 100%;">
        <ng-container formGroupName="customersPrepaidCreditForm">
            <div class="tw-basis-6/12 tw-text-3xl tw-p-3 sm:tw-ml-5">{{credit|tilbyCurrency}}</div>
            <div class="tw-basis-2/12">
                <mat-form-field color="primary" class="items tw-flex" >
                    <mat-label>{{'CUSTOMERS.DETAILS.CREDIT.FROM' | translate}}-{{'CUSTOMERS.DETAILS.CREDIT.TO' | translate}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="date_since" (dateChange)="submit.emit(prepaidCreditForm)" placeholder="{{'CUSTOMERS.DETAILS.CREDIT.PLACEHOLDER_FROM' | translate}}">
                        <input matEndDate formControlName="date_max" (dateChange)="submit.emit(prepaidCreditForm)" placeholder="{{'CUSTOMERS.DETAILS.CREDIT.PLACEHOLDER_TO' | translate}}">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker>
                        <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>{{'CUSTOMERS.DETAILS.CREDIT.CANCEL' | translate}}</button>
                            <button mat-button matDateRangePickerCancel (click)="onCancelDateRange()">{{'CUSTOMERS.DETAILS.CREDIT.RESET' | translate}}</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply>{{'CUSTOMERS.DETAILS.CREDIT.APPLY' | translate}}</button>
                          </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="tw-basis-2/12 sm:tw-ml-3">
                <mat-form-field color="primary" class="items tw-flex">
                    <mat-label>{{'CUSTOMERS.DETAILS.CREDIT.SHOP' | translate}}</mat-label>
                    <mat-select formControlName="db_name" (selectionChange)="submit.emit(prepaidCreditForm)">
                        <mat-option *ngFor="let s of chainShops" [value]="s.uuid">{{s.shop_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </form>
</mat-toolbar>
