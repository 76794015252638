import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InputItemData, ItemData} from "src/app/shared/model/model";
import { OpenDialogsService } from '../services/open-dialogs.service';

@Component({
  selector: 'app-edit-rows-dialog',
  templateUrl: './edit-rows-dialog.component.html',
  styleUrls: ['./edit-rows-dialog.component.scss']
})
export class EditRowsDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<OpenDialogsService>,
      @Inject(MAT_DIALOG_DATA) public data:InputItemData,
  ) {
  }

  ngOnInit(): void {
  }

    setNewValue() {
        if(!!this.data.itemData.filter(col=>col.isChecked).length){
            this.dialogRef.close(this.data.itemData.filter(col=>col.isChecked))
        }
    }
}
