<div class="category-tab tw-text-sm">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" id="tabGroup" (selectedTabChange)="emitCategorySelection($event)" tilbyGestures (swipeLeft)="swipeLeft()"
    (swipeRight)="swipeRight()">
        <mat-tab *ngIf="itemsFavorite" [label]="'CASHREGISTER.SHOWCASE.FAVOURITES' | translate"></mat-tab>
        <mat-tab *ngIf="isCategoryGridMode" [label]="'CASHREGISTER.SHOWCASE.PRODUCTS' | translate"></mat-tab>
        <div *ngIf="isCategoryTabMode">
            <mat-tab [label]="category.name" *ngFor="let category of categoriesSet"></mat-tab>
        </div>
    </mat-tab-group>
</div>
