import { Observable } from "rxjs"
import {
    ChromeTcpSocket
} from "./chrome-tcp-socket";

import {
    NodeTcpSocket,
    NodeTcpSocketServer
} from "./node-tcp-socket";

export interface TilbyTcpSocketConnectOptions {
    timeout: number
}

export interface TilbyTcpSocket {
    onData: Observable<Uint8Array>
    onError: Observable<number>

    connect(ipAddress: string, port: number, options: TilbyTcpSocketConnectOptions): Promise<void>
    send(data: Uint8Array): Promise<void>
    disconnect(): Promise<void>
    close(): Promise<void>
}

export interface TilbyTcpSocketServer {
    onConnection: Observable<TilbyTcpSocket>

    listen(port: number): void
    close(): void
}

export class TilbyTcpSocketUtils {
    public static ab2Hex(ab: Uint8Array, byteSeparator: string = ' '): string {
        return Array.from(ab, (byte) => byte.toString(16).padStart(2, '0'))
            .join(byteSeparator);
    }

    public static byteArrayToString = (byteArray: Uint8Array) => {
        return byteArray ? String.fromCharCode(...byteArray) : '';
    }

    public static getSocket(): (TilbyTcpSocket | undefined) {
        if (window.require) {
            return new NodeTcpSocket();
        } else if (window.chrome?.sockets?.tcp) {
            return new ChromeTcpSocket();
        }
    }

    public static getSocketServer(): (TilbyTcpSocketServer | undefined) {
        if (window.require) {
            return new NodeTcpSocketServer();
        }
    }
}