import * as angular from 'angular';

import { Bookings } from 'tilby-models';

import { EntityManagerService } from 'src/app/core';

import {
    SaleTransactionUtilsService,
} from 'src/app/features/cashregister';

angular.module('tablesNew', ['application'])
    .config(["$stateProvider", function($stateProvider:any) {

        $stateProvider
            .state('app.new.tables', {
            url: '/rooms',
            params: {
                id: null
            },
            template: '<tables [rooms]="$resolve.rooms" [sales]="$resolve.sales" [bookings]="$resolve.bookings"></tables>',
            resolve: {
                rooms: ["entityManager", (entityManager: EntityManagerService) => entityManager.rooms.fetchCollectionOffline()],
                sales: ["saleTransactionUtils", async (saleTransactionUtils: SaleTransactionUtilsService) => {
                    const sales = (await saleTransactionUtils.fetchOpenSales({ prepareSales: true, applyCoverToSales: true }))
                        .filter((sale) => sale.table_id || ['take_away', 'delivery'].includes(sale.order_type!))
                        .sort((sale1, sale2) => sale2.open_at > sale1.open_at ? 1 : -1)

                    return sales;
                }],
                bookings: ["entityManager", async (entityManager:any) =>await entityManager.bookings.fetchCollectionOffline() as Bookings[]]
            }
        })
            .state('app.new.tables.rooms-view', {
                url: '/:id/view',
                redirectTo: 'app.new.tables'
            });
    }]);
