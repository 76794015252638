import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { TilbyItemElementComponent } from "@tilby/tilby-ui-lib/components/tilby-item-element";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { Items } from "tilby-models";

@Component({
    selector: "app-showcase-list",
    templateUrl: "./showcase-list.component.html",
    styleUrls: ["./showcase-list.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        ScrollingModule,
        TilbyCurrencyPipe,
        TilbyGesturesDirective,
        TilbyItemElementComponent
    ]
})
export class ShowcaseListComponent implements OnChanges {
    @Input({required:true}) itemsSet!: Items[];
    @Input() gridCols: number = 4;
    @Input() priceList: number = 1;
    @Input() getStockStatus: Function = () => {};
    @Input() showThumbnail: boolean = true;
    @Input() showUnavailableStock: boolean = true;
    @Input() truncatePriceWithDots: boolean = true;
    @Input() priceWithCurrency: boolean = true;
    @Input() customClass: string = '';
    @Output() onItemTap: EventEmitter<Items> = new EventEmitter<Items>();
    @Output() onItemPress: EventEmitter<Items> = new EventEmitter<Items>();
    protected screenHeight=screen.height;
    priceIdentifier: keyof Items = "price1";

    itemTap(item: Items): void {
        this.onItemTap.emit(item);
    }

    itemPress(item: Items): void {
        this.onItemPress.emit(item);
    }

    trackByFunction(index: number, item: Items): number {
        return item.id!;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.priceList) {
            this.priceIdentifier = `price${changes.priceList.currentValue}` as keyof Items;
        }
    }
}
