import {Component, ElementRef, Inject, ViewChild} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { DataInsertNewDeviceDialog, InsertNewDeviceFields, InsertNewDeviceForm } from "./insert-new-device-waiter-dialog.model";

@Component({
    selector: 'app-insert-new-device-waiter-dialog',
    templateUrl: './insert-new-device-waiter-dialog.component.html',
    styleUrls: ['./insert-new-device-waiter-dialog.component.scss']
})
export class InsertNewDeviceWaiterDialogComponent {

    @ViewChild('nameField', { static: false, read: ElementRef }) nameField: ElementRef<HTMLInputElement> | undefined;

    insertNewDeviceForm?: FormGroup<InsertNewDeviceForm>;

    constructor(
        public dialogRef: MatDialogRef<InsertNewDeviceWaiterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataInsertNewDeviceDialog,
    ) {}

    ngOnInit() {
        this.createForm();
    }

    ngAfterViewInit() {
        setTimeout(() => {
          this.nameField?.nativeElement.focus();
        }, 0);
      }

    createForm(){
        this.insertNewDeviceForm = new FormGroup<InsertNewDeviceForm>({
            name: new FormControl('',[Validators.required]),
        });
    }

    submit(insertNewDeviceForm?: FormGroup<InsertNewDeviceForm>) {
        const {name} = insertNewDeviceForm?.value || {};
        this.dialogRef.close({name: name});
    }
}
