import { Injectable, inject } from "@angular/core";
import { OauthService } from "./oauth.service";
import { EnvironmentConfig } from "src/environments/environment-config";
import { EnvironmentInfoService } from "./environment-info.service";

@Injectable({
    providedIn: 'root'
})
export class LoginViewService {
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly oAuthService = inject(OauthService);

    /**
     * Redirects the user to the login URL.
     *
     * @param {string} loginUrl - The URL to redirect the user to for login.
     * @return {Promise<string>} - A promise that never resolves since it is a redirect
     */
    private getWebLogin(loginUrl: string): Promise<string> {
        return new Promise(() => {
            location.href = loginUrl;
        });
    }

    /**
     * Loads the login page in a Cordova inAppBrowser and retrieves the OAuth code
     *
     * @param {string} loginUrl - The URL of the login page.
     * @return {Promise<string>} A promise that resolves with the OAuth code.
     */
    private getCordovaLogin(loginUrl: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const ref = window.open(loginUrl, '_blank', 'location=no,toolbar=yes,hardwareback=yes');

            if (!ref) {
                return reject();
            }

            let hasCompleted = false;

            ref.addEventListener('loadstart', function (e: any) {
                const url = e.url;
                const code = /\?code=(.+)$/.exec(url);
                const error = /\?error=(.+)$/.exec(url);

                if (code || error) {
                    hasCompleted = true;
                    ref.close();

                    if (code) {
                        resolve(code[1]);
                    } else {
                        reject(error);
                    }
                }
            });

            ref.addEventListener('exit', function (e: any) {
                if (!hasCompleted) {
                    reject();
                }
            });
        });
    }

    /**
     * Shows the login page for the application.
     *
     * @return {Promise} The result of the login operation.
     */
    public show(): Promise<string> {
        const loginUrl = this.oAuthService.getTilbyLoginUrl(['clientId', 'redirectUri']);

        if (this.environmentInfoService.isElectronApp()) {
            return window.require('electron').ipcRenderer.invoke('tilby-login', { loginUrl: loginUrl, redirectUri: EnvironmentConfig.redirectUri });
        } else if (this.environmentInfoService.isMobileApp()) {
            return this.getCordovaLogin(loginUrl);
        } else {
            return this.getWebLogin(loginUrl);
        }
    }
}