import angular from 'angular';
import { ItemListSelector } from 'app/modules/application/service/dialog/item-list-selector/item-list-selector';

const supportedPosPaymentTypes = [
    14, //Ingenico 17 Protocol
    30, //Nexi mobile POS
    31  //ZVT Protocol
];

type CoverflexPrepareResponse = {
    transactionId: string
};

type CoverflexVoucher = {
    amount: number,
    reference: string,
    type: string
};

type CoverflexTransactionStatus = {
    status: 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'EXPIRED' | 'FAILED' | 'CANCELLED' | 'VOID',
    total: number,
    voucher: number,
    paid_by_voucher: number,
    paid_by_card: number,
    vouchers_used: CoverflexVoucher[]
};

export class CoverflexDigitalPaymentService {
    constructor(
        private $injector: any,
        private $translate: any,
        private entityManager: any,
        private itemListSelector: ItemListSelector,
        private restManager: any
    ) {
    }

    public async payment(amount: number, options: any) {
        //Obtain the merchantId from Coverflex payment bundle name
        const paymentMethod = options.paymentMethod;
        const merchantId = paymentMethod.bundle_name;

        if (!merchantId) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.BUNDLE_NAME_NOT_FOUND');
        }

        //Obtain compatible payment methods
        const paymentMethods = await this.entityManager.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: supportedPosPaymentTypes });

        //TODO: throw if no compatible payment method is available
        if (!paymentMethods.length) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COVERFLEX.NO_COMPATIBLE_PAYMENT');
        }

        //Ask the user the payment method
        let selectResult = await this.itemListSelector.show(this.$translate.instant("CASHREGISTER.ACTIVE_SALE.SELECT_PAYMENT"), paymentMethods, { disableSetAsDefault: true });

        //Obtain the terminalId from the payment method
        const posPayment = selectResult.element;
        const terminalId = posPayment.bundle_name;

        if (!terminalId) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.BUNDLE_NAME_NOT_FOUND');
        }

        //Prepare the transaction on Coverflex and obtain the transaction ID
        const { transactionId }: CoverflexPrepareResponse = await this.restManager.post('coverflex/v1/prepare', {
            merchantId: merchantId,
            terminalId: terminalId,
            total: Math.round(amount * 100),
            voucher: Math.round(amount * 100)
        });

        //Start the payment on the terminal
        const DigitalPaymentsManager = this.$injector.get('DigitalPaymentsManager');

        const paymentResult = await DigitalPaymentsManager.digitalPayment(amount, posPayment.id, { sale: options.sale });

        //Once the payment on the terminal ends, wait for the completion on Coverflex
        let transactionStatus: CoverflexTransactionStatus;
        let attemptCount = 0;

        do {
            transactionStatus = await this.restManager.getOne('coverflex/v1/transaction', transactionId);
            attemptCount++;

            //Wait 1 second if the transaction is still PENDING
            if (transactionStatus.status === 'PENDING') {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        } while (transactionStatus.status === 'PENDING' && attemptCount <= 10)

        if (transactionStatus.status !== 'COMPLETED') {
            throw `COVERFLEX_TRANSACTION_${transactionStatus.status}`;
        }

        //Return the terminal payment and the voucher used
        const paymentsUsed = transactionStatus.vouchers_used.map((voucher) => ({
            amount: (voucher.amount / 100),
            code: voucher.reference,
            unclaimed: true
        }));

        if(transactionStatus.paid_by_card) {
            paymentsUsed.unshift(Object.assign(paymentResult, {
                amount: (transactionStatus.paid_by_card / 100)
            }));
        }

        return paymentsUsed;
    }
}

CoverflexDigitalPaymentService.$inject = ['$injector', '$translate', 'entityManager', 'itemListSelector', 'restManager'];

angular.module('digitalPayments').service('Coverflex', CoverflexDigitalPaymentService);