import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('tables').factory('editRoom', editRoom);

editRoom.$inject = ["$mdDialog", "$state", "checkManager", "entityManager", "confirmDialog", "alertDialog"];

function editRoom($mdDialog, $state, checkManager, entityManager, confirmDialog, alertDialog) {
    editRoomController.$inject = ["$scope", "$mdDialog", "$translate", "dialog", "currentRoom", "orders", "floors"];

    function editRoomController($scope, $mdDialog, $translate, dialog, currentRoom, orders, floors) {
        Object.assign($scope, {
            priceLists: []
        });

        //Setup pricelists
        for(let idx = 1; idx <= 10; idx++) {
            $scope.priceLists.push({
                id: idx,
                name: checkManager.getPreference(`price_list_${idx}_name`) || `${$translate.instant('ITEMS.DETAILS.PRICE_LIST_START')} ${idx}`
            });
        }

        $scope.priceLists.unshift({
            id: null,
            name: $translate.instant("CUSTOMERS.DETAILS.NONE")
        });

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            $mdDialog.hide($scope.data);
        };

        $scope.checkNewSizes = function() {
            $scope.editRoomForm.width.$setValidity('tablesInBounds',true);
            $scope.editRoomForm.height.$setValidity('tablesInBounds',true);
            if($scope.minWidth > $scope.data.width * 80){
                $scope.editRoomForm.width.$setValidity('tablesInBounds',false);
            }
            if($scope.minHeight > $scope.data.height * 80){
                $scope.editRoomForm.height.$setValidity('tablesInBounds',false);
            }
        };

        var reShowDialog = function() {
             if(dialog) {
                dialog.show($scope.data, orders, dialog);
            }
        };

        $scope.deleteRoom = function() {
            var pendingOrder = _.find(orders, function(order) {
                if (order.room_id === currentRoom.id && order.status !== "closed") {
                    return true;
                }
            });
            if (pendingOrder) {
                alertDialog.show($translate.instant('TABLES.DELETE_ROOM.BUSY_TABLES')).finally(function() {
                    reShowDialog();
                });
                return;
            }

            confirmDialog.show($translate.instant('TABLES.DELETE_ROOM.CONFIRM', { roomName: currentRoom.name })).then(function(answer) {
                if (answer) {
                    entityManager.rooms.deleteOneOnline(currentRoom.id).then(function() {
                        $state.reload('app.tables');
                    });
                }
                else {
                    reShowDialog();
                }
            });
        };

        var lengths = _.range(3,51);

        $scope.heights = lengths;
        $scope.widths = lengths;

        $scope.data = _.clone(currentRoom);

        var tmpTable = null;

        tmpTable = _.maxBy($scope.data.tables, function(table) {
            return table.height + table.ypos;
        });

        $scope.minHeight = tmpTable ? (tmpTable.height + tmpTable.ypos + 40) : 40;

        tmpTable = _.maxBy($scope.data.tables, function(table) {
            return table.width + table.xpos;
        });

        $scope.minWidth = tmpTable ? (tmpTable.width + tmpTable.xpos + 40) : 40;

        $scope.floor = _.cloneDeep(floors);
    }

    var editRoom = {
        show: function(currentRoom, orders, dialog) {
            $mdDialog.show({
                controller: editRoomController,
                template: require('./edit-room.html'),
                locals: {
                    dialog: dialog,
                    currentRoom: currentRoom,
                    orders: orders
                }
            }).then(function(answer) { //TODO: handle entityManager errors
                entityManager.rooms.putOneOnline(answer).finally(function(){
                    $state.reload("app.tables");
                });
            });
        }
    };

    return editRoom;
}
