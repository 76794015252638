import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('printers').factory('shippingInvoiceDialog', ["$mdDialog", "checkManager", function($mdDialog, checkManager) {
    shippingInvoiceDialogController.$inject = ["$scope", "sale", "defaults"];
    function shippingInvoiceDialogController($scope, sale, defaults) {
        $scope.meta = {};
        if(!_.isObject(defaults)) {
            defaults = {};
        }

        $scope.transports = _.split(_.toString(checkManager.getSetting("shipping_invoice.transports")), '\n');
        $scope.causes = _.split(_.toString(checkManager.getSetting("shipping_invoice.causes")), '\n');
        $scope.ports = _.split(_.toString(checkManager.getSetting("shipping_invoice.ports")), '\n');
        $scope.appearances = _.split(_.toString(checkManager.getSetting("shipping_invoice.appearances")), '\n');
        $scope.payments = _.split(_.toString(checkManager.getSetting("shipping_invoice.payments")), '\n');
        $scope.carriers = _.split(_.toString(checkManager.getSetting("shipping_invoice.carriers")), '\n');

        $scope.meta = {
            appearance: _.head($scope.appearances),
            carrier: _.head($scope.carriers),
            cause: _.head($scope.causes),
            payment: _.head($scope.payments),
            port: _.head($scope.ports),
            tDate: new Date(),
            transport: _.head($scope.transports),
            package_count: defaults.itemsCount || 0,
            notes: $scope.notes
        };

        $scope.meta.tDate.setMilliseconds(0);
        $scope.meta.tDate.setSeconds(0);

        $scope.confirm = function() {
            $scope.meta.transport_date = $scope.meta.tDate.toISOString();
            $mdDialog.hide($scope.meta);
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    var shippingInvoiceDialog = {
        show: function(sale, defaults) {
            return $mdDialog.show({
                controller: shippingInvoiceDialogController,
                template: require('./shipping-invoice-dialog.html'),
                locals: {
                    sale: sale,
                    defaults: defaults
                }
            });
        }
    };

    return shippingInvoiceDialog;
}]);