import {Component, OnInit} from '@angular/core';
import {ILoadingCellRendererAngularComp} from "ag-grid-angular";
import {ILoadingCellRendererParams} from "ag-grid-community";

@Component({
    selector: 'app-skeleton-topbar',
    templateUrl: './skeleton-topbar.component.html',
    styleUrls: ['./skeleton-topbar.component.scss'],
    host:{style:"position: relative"}
})
export class SkeletonTopbarComponent implements ILoadingCellRendererAngularComp {

    constructor() {
    }

    ngOnInit(): void {
    }

    agInit(params: ILoadingCellRendererParams): void {
    }

}
