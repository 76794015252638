import angular from 'angular';
import { ConfigurationManagerService } from 'src/app/core';
import { ActiveSaleService } from 'src/app/features';

export class MigrationHelper {
    constructor(
        private readonly configurationManagerService: ConfigurationManagerService,
        private readonly injector: angular.auto.IInjectorService
    ) {
    }

    getActiveSale(): ActiveSaleService {
        return this.configurationManagerService.isModuleAngular('tables_and_cashregister')
            ? this.injector.get('ActiveSaleNew')
            : this.injector.get('ActiveSale');
    }
}

MigrationHelper.$inject = ['checkManager', '$injector'];

angular.module('application').service('migrationHelper', MigrationHelper);