import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTabChangeEvent, MatTabGroup, MatTabsModule} from '@angular/material/tabs';
import { Categories } from 'tilby-models';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { TranslateModule } from '@ngx-translate/core';
import { Section } from '../cashregister-showcase';

@Component({
  selector: 'app-category-tab',
  templateUrl: 'category-tab.component.html',
  styleUrls: ["./category-tab.component.scss"],
  standalone: true,
  imports: [CommonModule, ScrollingModule, MatTabsModule, TilbyGesturesDirective, TranslateModule],
})
export class CategoryTabComponent implements OnInit, AfterViewInit {


    @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
    @Input() categoriesSet!: Categories[];
    @Input() itemsFavorite: boolean = false;
    @Input() isCategoryGridMode: boolean = false;
    @Input() isCategoryTabMode: boolean = false;
    @Output() categorySelected = new EventEmitter<Categories>();
    @Output() goToSection = new EventEmitter<Section>();
    @Input() initCategorySelected : Categories | undefined = undefined;

    categorySel : any;
    itemSize = 48;
    tabCount: number = 0;
    trackByCategory(index:number,category:Categories){return category.id};

    ngOnInit(): void {
        this.tabCount = this.categoriesSet.length;
    }

    ngAfterViewInit(): void {
        const index = this.initCategorySelected ? this.categoriesSet.findIndex(category => category.id === this.initCategorySelected!.id) : -1;
        this.tabGroup.selectedIndex = !this.itemsFavorite ? index : (index+1) || 0;
    }

    emitCategorySelection(event: MatTabChangeEvent) {
        let index = !this.itemsFavorite ? event.index : (event.index - 1);

        if (this.itemsFavorite && index === -1) {
            this.goToSection.emit('favorites');
        } else {
            this.categorySelected.emit(this.categoriesSet[index]);
        }

    }

    swipeRight() {
        this.tabGroup._tabHeader.scrollDistance -= 300;
    }


    swipeLeft() {
        this.tabGroup._tabHeader.scrollDistance += 300;
    }
}
