<mat-progress-bar mode="query" *ngIf="inProgress"></mat-progress-bar>
<tilby-dialog-toolbar mat-dialog-title
    title="SETTINGS.EDIT_USER_ACEESS_DIALOG.TITLE_EMAIL"
    [disabled]="!editEmailDialogForm.valid || inProgress"
    (confirm)="confirm()"></tilby-dialog-toolbar>
<mat-dialog-content>
    <form autocomplete="off" [formGroup]="editEmailDialogForm" class="tw-flex tw-flex-col">
        <mat-form-field appearance="fill" class="tw-flex-auto">
            <mat-label translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.EMAIL</mat-label>
            <input matInput placeholder="example@domain.com" formControlName="email" type="email" [pattern]="regexEmail" required [readonly]="waitingCode" cdkFocusInitial>
            <mat-error *ngIf="email?.hasError('required')">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
            <mat-error *ngIf="email?.hasError('email')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
            <mat-error *ngIf="email?.hasError('pattern')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
        </mat-form-field>
        <div class="mat-mdc-form-field-error tw-px-4" *ngIf="errorEmail">{{errorEmailMessage}}</div>

        <div *ngIf="waitingCode" class="tw-flex tw-flex-col">
            <p translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.TEXT_WAIT_CODE_EMAIL</p>
            <mat-form-field appearance="fill" class="tw-flex-auto">
                <mat-label translate>SETTINGS.EDIT_USER_ACEESS_DIALOG.VERIFICATION_CODE</mat-label>
                <input matInput placeholder="123456" formControlName="verification_code" type="text" required>
                <mat-error *ngIf="verification_code?.hasError('required')">{{'NG_MESSAGES.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-error *ngIf="verificationCodeError">{{verificationCodeErrorMessage}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
