import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('cashregister').factory('peopleSplit', ["$mdDialog", "ActiveSale", "checkManager", "util", function($mdDialog, ActiveSale, checkManager, util) {
    peopleSplitController.$inject = ["$scope", "$mdDialog"];
    function peopleSplitController($scope, $mdDialog) {
        $scope.peopleCount = null;

        if(checkManager.getPreference('orders.automated_add_cover') && checkManager.getPreference('orders.automated_add_cover.type') === 'id'){
            var idCover = _.toInteger(checkManager.getPreference('orders.automated_add_cover.value'));

            $scope.peopleCount = _.get(_.find(ActiveSale.currentSale.sale_items, { item_id: idCover }), 'quantity') || 1;
        } else {
            $scope.peopleCount = 1;
        }

        $scope.previewPeopleTotal = function() {
            if($scope.peopleCount > 0) {
                return util.round(ActiveSale.currentSale.final_amount / $scope.peopleCount);
            }
            else {
                return util.round(ActiveSale.currentSale.final_amount);
            }
        };

        $scope.previewTotal = function() {
            return util.round(ActiveSale.currentSale.final_amount);
        };

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    var peopleSplit = {
        show: function() {
            return $mdDialog.show({
                controller: peopleSplitController,
                template: require('./people-split.html'),
            });
        }
    };

    return peopleSplit;
}]);
