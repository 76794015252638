import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  computed,
  inject,
  signal
} from '@angular/core';

import {BaseInputButton, GeneralInputButton, LongPressInputButton} from "@tilby/tilby-ui-lib/models";
import {CustomForm, CustomFormGroup} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {TotalFormValue} from "../tilby-sale-keypad";
import {TilbyKeypadDisplayComponent} from "../tilby-keypad-display";
import {CashregisterKeypadComponent} from "../cashregister-keypad";
import {TilbyGeneralInputButtonComponent} from "@tilby/tilby-ui-lib/components/tilby-buttons";
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TilbyGesturesDirective } from '@tilby/tilby-ui-lib/directives/tilby-gestures';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActiveSaleService } from '../../../services';
import { Sales } from 'tilby-models';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cashregister-side-keyboard',
    standalone: true,
    imports: [
        TilbyKeypadDisplayComponent,
        CashregisterKeypadComponent,
        TilbyGeneralInputButtonComponent,
        CommonModule,
        MatButtonModule,
        TilbyGesturesDirective,
        MatProgressSpinnerModule,
        TilbyCurrencyPipe,
        TranslateModule
    ],
    templateUrl: './cashregister-side-keyboard.component.html',
    styleUrl: './cashregister-side-keyboard.component.scss'
})
export class CashregisterSideKeyboardComponent {

    protected readonly activeSaleService = inject(ActiveSaleService);
    protected readonly translateService = inject(TranslateService);
    protected readonly rendererService = inject(Renderer2);
    protected readonly relementRefService = inject(ElementRef);

    private _principalActionButton?: GeneralInputButton;

    protected change : number | null = null;

    @Input({required:true}) form!: CustomFormGroup<CustomForm<TotalFormValue>>;
    @Input({required:true}) cashValue!: string;
    @Input({required:true}) toggleMainKeyboardButton!: GeneralInputButton;
    @Input({required:true}) buttons: GeneralInputButton[]=[];
    @Input({required:true}) fastPaymentsButtons: LongPressInputButton[] | undefined =[];
    @Input({required:true}) sale: Sales | undefined;
    @Input({ required: true })
    set principalActionButton(baseButton: BaseInputButton) {
        this._principalActionButton = this.transformBaseButton(baseButton);
    }

    get principalActionButton(): GeneralInputButton | undefined {
        return this._principalActionButton;
    }

    private transformBaseButton(baseButton: BaseInputButton): GeneralInputButton {
        return {
            ...baseButton,
            icon: undefined,
            class: "tilby-body-background-primary-color tw-h-full tw-w-full tw-rounded-none tw-px-0 tw-py-0",
            label: signal(this.translateService.instant('CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.OTHER')),
            isIt: signal(true)
        };
    }

    @Input() integerMultiply = false;

    finalAmount : number | undefined = undefined ;
    totalQuantity : number | undefined = undefined ;

    @Output() onFieldChange= new EventEmitter<string>();

    protected isLoading = computed(() =>
        this.activeSaleService.printOrderInProgress() ||
        this.activeSaleService.printDocumentInProgress() ||
        this.activeSaleService.paymentInProgress()
    ) || false;

    ngOnChanges(simpleChanges: SimpleChanges){
        if(simpleChanges.sale && simpleChanges.sale.currentValue){
            this.finalAmount = simpleChanges.sale.currentValue.final_amount;
            this.totalQuantity = this.getQuantity();
            setTimeout(() => {
                this.adjustFontSize();
            }, 10);
        } else {
            this.change = this.activeSaleService.lastSaleChangeAmount();
        }
    }

    getQuantity() : number {
        let totalQuantity = 0;

        for (const item of this.sale?.sale_items || []) {
            const quantity = item.quantity;

            if (typeof quantity === 'number') {
                if (Number.isInteger(quantity)) {
                    totalQuantity += quantity;
                } else {
                    totalQuantity += 1;
                }
            }
        }

        return totalQuantity;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.adjustFontSize();
    }

    adjustFontSize() {
        const container = this.relementRefService.nativeElement.querySelector('#infoKeypadSide');
        const width = container.offsetWidth;

        const finalAmountAndQuantityDiv = document.getElementById('finalAmountAndQuantityId');
        const finalAmountDiv = document.getElementById('finalAmountId');
        const totalQuantityDiv = document.getElementById('quantityId');

        const textLength = finalAmountAndQuantityDiv!.innerText.length;
        const fontSize = this.calculateFontSize(width, textLength);
        this.rendererService.setStyle(finalAmountDiv, 'font-size', fontSize);
        this.rendererService.setStyle(totalQuantityDiv, 'font-size', fontSize);
    }

    calculateFontSize(containerWidth: number, textLength: number): string {
        const size = 20 + (containerWidth / 10) - (textLength * 1.2) ;
        return `${size}px`;
    }
}
