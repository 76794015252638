
import {
    Injectable,
    inject
} from "@angular/core";

import {
    BookingShiftsDefault,
    BookingShiftsFields,
    BookingShiftsFormGroup,
    PriceList
} from "../settings-shifts-form";
import { Validators } from "@angular/forms";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { BookingShifts, Rooms } from "tilby-models";
import { KeyValue } from "@angular/common";
import {Subject, takeUntil} from "rxjs";
import { TranslateService } from "@ngx-translate/core";


@Injectable()
export class ShiftsFormService {
    private readonly onDestroy$ = new Subject<void>();
    private readonly translate = inject(TranslateService);
    bookingShiftsForm!: CustomFormGroup<BookingShiftsFormGroup>;

    constructor(
    ) {
    }

    ngOnDestroy(){
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    createForm(shift: BookingShiftsFields, priceLists:Array<PriceList>, rooms: Array<Rooms>, weekDays: Array<{id: string, name: string}>): CustomFormGroup<BookingShiftsFormGroup> {
        if(!shift) {
            shift = new BookingShiftsDefault(this.translate.instant('SETTINGS.SHIFTS.NEW_SHIFTS'));
        }

        const roomsChoices: Array<KeyValue<string, number>> = rooms.map((room, i) => ({key: room.name, value: room.id || i}));
        const priceListsChoices: Array<KeyValue<string, number>> = priceLists.filter(priceList => !priceList.hidden).map(priceList => ({key: priceList.value, value: priceList.index}));
        const weekDaysChoices: Array<KeyValue<string, string>> = weekDays.map(weekDay => ({key: weekDay.name, value: weekDay.id}));

        this.bookingShiftsForm = new CustomFormGroup<BookingShiftsFormGroup>({
            generalForm: new CustomFormGroup({
                name: new CustomFormControl(shift.name || '', { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.NAME',
                    inputType: 'text',
                    class: 'tw-w-full tw-mb-2'
                }),
                start_period: new CustomFormControl(shift.start_period || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.START_PERIOD',
                    inputType: 'date',
                    class: 'tw-w-[48%] tw-mb-2'
                }),
                end_period: new CustomFormControl(shift.end_period || '', {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.END_PERIOD',
                    inputType: 'date',
                    class: 'tw-w-[48%] tw-mb-2',
                    inputConstraint: { minDate: this.start_period?.value }
                }),
                start_time: new CustomFormControl(shift.start_time || '', { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.START_TIME',
                    inputType: 'time',
                    class: 'tw-w-[48%] tw-mb-2'
                }),
                end_time: new CustomFormControl(shift.end_time || '', { validators: Validators.required }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.END_TIME',
                    inputType: 'time',
                    class: 'tw-w-[48%] tw-mb-2'
                }),
                default_duration: new CustomFormControl(shift.default_duration || 60, {validators: [Validators.min(15)]}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.DEFAULT_DURATION',
                    inputType: 'number',
                    class: 'tw-w-full tw-mb-2'
                }),
                default_pricelist: new CustomFormControl(shift.default_pricelist || -1, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.DEFAULT_PRICELIST',
                    inputType: 'select',
                    inputChoices: priceListsChoices,
                    class: 'tw-w-full tw-mb-2'
                }),
                instore_seats_limit: new CustomFormControl(shift.instore_seats_limit || '', { validators: [Validators.min(0)] }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.INSTORE_SEATS_LIMIT',
                    inputType: 'number',
                    class: 'tw-w-full tw-mb-2'
                }),
                online_seats_limit: new CustomFormControl(shift.online_seats_limit || '', { validators: [Validators.min(0)] }, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.ONLINE_SEATS_LIMIT',
                    inputType: 'number',
                    class: 'tw-w-full tw-mb-2'
                }),
                room_restrictions: new CustomFormControl(shift.room_restrictions || [], {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.ROOM_RESTRICTIONS',
                    inputType: 'selectMultiple',
                    inputChoices: roomsChoices,
                    class: 'tw-w-full tw-mb-2'
                }),
                weekdays_period: new CustomFormControl(shift.weekdays_period || [], {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.WEEKDAYS_PERIOD',
                    inputType: 'selectMultiple',
                    inputChoices: weekDaysChoices,
                    class: 'tw-w-full tw-mb-2'
                }),
                apply_to_tables_only: new CustomFormControl(shift.apply_to_tables_only || false, {}, {
                    ...new CustomFormControlProps(),
                    hint: () => ``,
                    label: 'SETTINGS.SHIFTS.FORM.APPLY_TO_TABLES_ONLY',
                    inputType: 'checkbox',
                    class: 'tw-w-full tw-mb-2'
                }),
            }, {}, {...new CustomFormGroupProps()}),
        });

        this.start_period.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(
            (start_period) => this.end_period.customProps.inputConstraint!.minDate = start_period
        );

        return this.bookingShiftsForm;
    }

    updateForm(shift: BookingShiftsFields): void {
        this.bookingShiftsForm?.controls.generalForm.reset(shift);
    }

    getNumDay(arrNameDay: Array<BookingShifts.WeekdaysPeriodEnum>): Array<number> {
        if(arrNameDay && arrNameDay.length > 0) {
            return arrNameDay.map((nameDay: BookingShifts.WeekdaysPeriodEnum) => {
                switch (nameDay) {
                    case 'Su': return 0;
                    case 'Mo': return 1;
                    case 'Tu': return 2;
                    case 'We': return 3;
                    case 'Th': return 4;
                    case 'Fr': return 5;
                    case 'Sa': return 6;
                }
            });
        } else {
            return [];
        }
    }

    get generalForm() {
        return this.bookingShiftsForm?.controls.generalForm;
    }
    get name() {
        return this.generalForm.controls?.name as CustomFormControl;
    }
    get start_period() {
        return this.generalForm?.controls?.start_period as CustomFormControl;
    }
    get end_period() {
        return this.generalForm?.controls?.end_period as CustomFormControl;
    }
    get start_time() {
        return this.generalForm?.controls?.start_time as CustomFormControl;
    }
    get end_time() {
        return this.generalForm?.controls?.end_time as CustomFormControl;
    }
    get default_duration() {
        return this.generalForm?.controls?.default_duration as CustomFormControl;
    }
    get default_pricelist() {
        return this.generalForm?.controls?.default_pricelist as CustomFormControl;
    }
    get instore_seats_limit() {
        return this.generalForm?.controls?.instore_seats_limit as CustomFormControl;
    }
    get online_seats_limit() {
        return this.generalForm?.controls?.online_seats_limit as CustomFormControl;
    }
    get room_restrictions() {
        return this.generalForm?.controls?.room_restrictions as CustomFormControl;
    }
    get weekdays_period() {
        return this.generalForm?.controls?.weekdays_period as CustomFormControl;
    }
}
