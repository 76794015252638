<h1 mat-dialog-title class="tw-mb-2" translate>SETTINGS.ADD_NEW_USER_DIALOG.TITLE</h1>
<div mat-dialog-content style="max-height: 75vh">
    <form [formGroup]="addNewUserDialogForm">
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 sm:tw-gap-4">
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_USER_DIALOG.FIRST_NAME</mat-label>
                <input matInput id="first_name" formControlName="first_name" type="text" class="form-control" required>
                <mat-error *ngIf="addNewUserDialogForm.get('first_name')!.invalid" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
            </mat-form-field>
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_USER_DIALOG.LAST_NAME</mat-label>
                <input matInput id="last_name" formControlName="last_name" type="text" class="form-control" required>
                <mat-error *ngIf="addNewUserDialogForm.get('last_name')!.invalid" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
            </mat-form-field>
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_USER_DIALOG.EMAIL</mat-label>
                <input matInput id="email" formControlName="email" type="email" class="form-control" (input)="onEmailInputChange()">
                <mat-error *ngIf="addNewUserDialogForm.get('email')!.invalid">
                    <span *ngIf="addNewUserDialogForm.get('email')!.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</span>
                    <span *ngIf="addNewUserDialogForm.get('email')!.hasError('email')" translate>NG_MESSAGES.INVALID_VALUE</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_USER_DIALOG.REPEAT_EMAIL</mat-label>
                <input matInput id="repeat_email" formControlName="repeat_email" type="email" class="form-control" (input)="onEmailInputChange()">
                <mat-error *ngIf="addNewUserDialogForm.get('repeat_email')!.invalid ">
                    <span *ngIf="addNewUserDialogForm.get('repeat_email')!.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</span>
                    <span *ngIf="addNewUserDialogForm.get('repeat_email')!.hasError('email')" translate>NG_MESSAGES.INVALID_VALUE</span>
                    <span *ngIf="addNewUserDialogForm.hasError('emailMismatch') && !addNewUserDialogForm.get('repeat_email')!.hasError('email')" translate>SETTINGS.ADD_NEW_USER_DIALOG.EMAIL_MUST_BE_EQUAL</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_USER_DIALOG.PHONE</mat-label>
                <input matInput id="phone" formControlName="phone" type="text" class="form-control" (input)="onPhoneInputChange()">
                <mat-error *ngIf="addNewUserDialogForm.get('phone')!.invalid">
                    <span *ngIf="addNewUserDialogForm.hasError('phoneMismatch2')" translate>NG_MESSAGES.REQUIRED_FIELD</span>
                    <span *ngIf="addNewUserDialogForm.hasError('phoneMismatch') || addNewUserDialogForm.hasError('phoneMismatch1')" translate>SETTINGS.ADD_NEW_USER_DIALOG.PHONE_MUST_BE_EQUAL</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="tw-w-full">
                <mat-label translate>SETTINGS.ADD_NEW_USER_DIALOG.REPEAT_PHONE</mat-label>
                <input matInput id="repeat_phone" formControlName="repeat_phone" type="text" class="form-control" (input)="onPhoneInputChange()">
                <mat-error *ngIf="addNewUserDialogForm.get('repeat_phone')!.invalid ">
                    <span *ngIf="addNewUserDialogForm.hasError('phoneMismatch1')" translate>NG_MESSAGES.REQUIRED_FIELD</span>
                    <span *ngIf="addNewUserDialogForm.hasError('phoneMismatch') || addNewUserDialogForm.hasError('phoneMismatch2')" translate>SETTINGS.ADD_NEW_USER_DIALOG.PHONE_MUST_BE_EQUAL</span>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions class="tw-justify-end">
    <button mat-button (click)="dialogRef.close()"><span translate>SETTINGS.ADD_NEW_USER_DIALOG.ACTION.CANCEL</span></button>
    <button mat-raised-button color="primary" (click)="submit()" cdkFocusInitial [disabled]="isFormInvalid()"><span translate>SETTINGS.ADD_NEW_USER_DIALOG.ACTION.SUBMIT</span></button>
</div>
