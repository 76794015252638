import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { PaymentsListComponent } from '../payments-list/payments-list.component';
import { Sales, SalesPayments } from 'tilby-models';
import _ from 'lodash';
import { CashregisterStateService } from '../../../services/cashregister.state.service';
import { ActiveSaleService } from '../../../services/active-sale.service';
import { OpenDialogsService } from 'src/app/dialogs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payments-list-wrapper',
  templateUrl: './payments-list-wrapper.component.html',
  styleUrls: ['./payments-list-wrapper.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, PaymentsListComponent],
    host: {class:'tw-flex tw-flex-col tw-h-full'}
})
export class PaymentsListWrapperComponent {

    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly translateService = inject(TranslateService);

    @Input() sale: Sales | undefined;

    protected getPaymentDetail(payment : SalesPayments) {
        switch(payment.payment_method_type_id) {
            case 17:
                return (payment.payment_data) ? _.get(this.cashregisterStateService.deliveryChannels, [payment.payment_data, 'name'], payment.payment_data) : '';
            case 33:
                return payment.code;
            default:
                if (payment.card_circuit_id) {
                    return payment.card_circuit_name;
                }
                if (payment.ticket_id) {
                    return payment.ticket_name;
                }
                return '';
        }
    };

    protected async removeSalesPayment(salePayments: SalesPayments){
        if (salePayments.paid) {
            const answer = await this.openDialogsService.openConfirmDialog({data: {
                messageLabel: this.translateService.instant('CASHREGISTER.ACTIVE_SALE.REMOVE_PAID_PAYMENT_CONFIRM'),
                confirmLabel: this.translateService.instant('MISC.YES'),
                cancelLabel: this.translateService.instant('MISC.NO')
            }});
            if (answer) {
                this.activeSaleService.removePaymentFromSale(salePayments);
            }
        } else {
            this.activeSaleService.removePaymentFromSale(salePayments);
        }
    }
}
