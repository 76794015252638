import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { Categories as TilbyModelsCategories} from "tilby-models";
import { Section } from "../cashregister-showcase";

interface Categories extends TilbyModelsCategories {
    color?: string;
}

@Component({
    selector: "app-category-grid",
    templateUrl: "./category-grid.component.html",
    styleUrls: ["./category-grid.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatGridListModule,
        TilbyGesturesDirective,
        TranslateModule
    ]
})
export class CategoryGridComponent implements OnInit{

    @Input() gridCols: number = 4;
    @Input() categoriesSet!: Categories[];
    @Input() itemsFavorite: boolean = false;
    @Input() customClass: string = '';
    @Output() categorySelected = new EventEmitter<Categories>();
    @Output() goToSection = new EventEmitter<Section>();
    rowHeight : string = '48px';
    protected _categorySelected? : Categories;
    protected _favoriteSelected? = true;
    @Input() initCategorySelected : Categories | undefined = undefined;

    ngOnInit(): void {
        this._categorySelected = !this.itemsFavorite ? this.initCategorySelected || this.categoriesSet[0] : this.initCategorySelected ||  undefined;
        if (this.initCategorySelected ) {
            this._favoriteSelected = false;
        }
    }

    emitCategorySelection(category: Categories) {
        if (this.itemsFavorite && category.id === -1) {
            this._categorySelected = undefined;
            this._favoriteSelected = true;
            this.goToSection.emit('favorites');
        } else {
            this._categorySelected = category;
            this._favoriteSelected = false;
            this.categorySelected.emit(category);
        }
    }

    trackByFunction(index: number, category: Categories): number {
        return category.id!;
    }
}
