import * as angular from 'angular';

import {
    OperatorManagerService
} from 'app/modules/core/service/operator-manager/operator-manager';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
    ConfigurationManagerService,
    UserActiveSessionManagerService,
    paymentMethodTypesById
} from 'src/app/core';

import {
    SaleUtilsService
} from 'src/app/features/cashregister';

import { MathUtils, keyBy } from 'src/app/shared/utils';

import {
    Departments,
    Items,
    Sales,
    SalesItems,
    SalesPayments,
    Vat 
} from 'tilby-models';

import { v4 as generateUuid } from 'uuid';

type BedzzleCommonResources = {
    departments: Record<string, Departments>,
    items: Record<string, Items>
}

export type BedzzleCommonFoliosResponse = {
    status: string,
    folios: BedzzleCommonFolio[]
}

export type BedzzleCommonTransactionResponse = {
    status: string,
    data: {
        folioId: string | null
    }
}

export type BedzzleCommonFolio = {
    folioId: number | null,
    propertyId: string,
    stayId?: number | null,
    keyDoorCode?: string | null,
    customerId?: string | null,
    companyName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    folioAlias?: string | null,
    checkIn?: string | null,
    checkOut?: string | null,
    currentBalance: number,
    totalAmount: number
};

export type BedzzleCommonTransactionItem = {
    sellingDate: string,
    productId: string,
    productAlias: string,
    productName: string,
    productCode?: string | null,
    categoryId: number,
    categoryAlias: string,
    categoryName: string,
    groupId?: number | null,
    groupAlias?: string | null,
    groupName?: string | null,
    productCustomName?: string | null,
    quantity?: number | null,
    productUnitId?: number | null,
    priceBeforeDiscount?: number | null,
    completeUnitPrice?: number | null,
    completeNetUnitPrice?: number | null,
    vatId?: number | null,
    vatValue?: number | null,
    vatName?: string | null,
    vatCode?: string | null,
    vatDescription?: string | null,
    vatNature?: string | null,
    remark?: string | null
}

export type BedzzleCommonTransactionPayment = {
    id?: string | null,
    methodId?: number | null,
    datetime: string | null,
    amount?: number | null,
    reason?: string | null,
    remark?: string | null
}

export type BedzzleCommonFolioProfile = {
    id: string,
    companyName?: string,
    firstName?: string,
    lastName?: string,
    address?: string,
    zipCode?: string,
    city?: string,
    province?: string,
    country?: string,
    vatNumber?: string,
    taxCode?: string,
    sdiCode?: string,
    pec?: string
}

export type BedzzleCommonExtTransaction = BedzzleCommonTransaction & {
    accounted?: boolean,
    docEmitdate?: string,
    docNum?: string,
    docType?: 'R' | 'F' | 'NC',
    originalOrderId?: string,
    profile?: BedzzleCommonFolioProfile
    sentToSDI: boolean,
}

export type BedzzleCommonTransaction = {
    orderId?: string | null,
    stayId?: number | null,
    orderName?: string | null,
    propertyId?: string,
    outletId: number,
    outletName: string,
    outletDescription: string,
    outletAlias?: string | null,
    folioId: number | null,
    folioStatus?: string | null,
    folioAlias?: string | null,
    folioRemark?: string | null,
    customerFirstname?: string | null,
    customerLastname?: string | null,
    customerId?: string | null,
    companyName?: string | null,
    currency?: string | null,
    amount?: number | null,
    netAmount?: number | null,
    taxAmount?: number | null,
    ticket_image_url?: string,
    totalAmount?: number | null,
    totalNetAmount?: number | null,
    totalTaxAmount?: number | null,
    createAtDate: string | null,
    isMiscSale: boolean | null,
    isPaid?: boolean | null,
    items: BedzzleCommonTransactionItem[],
    payments: BedzzleCommonTransactionPayment[],
    pmsUser: {
        firstName: string,
        lastName: string
    }
}

export type BedzzleCommonTransactionOptions = {
    disableQuantityRounding?: boolean
}

type ProviderName = 'Bezzle' | 'Spiagge.it' | 'Beddy' | 'PMS Zucchetti';
type ProviderID = 'bedzzle' | 'spiagge_it' | 'beddy' | 'zucchetti_pms';

export class BezzleCommonService {
    constructor(
        private readonly configurationManagerService: ConfigurationManagerService,
        private readonly entityManager: any,
        private readonly OperatorManager: OperatorManagerService,
        private readonly saleUtilsService: SaleUtilsService,
        private readonly userActiveSession: UserActiveSessionManagerService
    ) { }

    private static readonly ProviderNameMap: Record<ProviderID, ProviderName> = {
        bedzzle: 'Bezzle',
        spiagge_it: 'Spiagge.it',
        beddy: 'Beddy',
        zucchetti_pms: 'PMS Zucchetti'
    };

    private async getRequiredResources(): Promise<BedzzleCommonResources> {
        const departments = await this.entityManager.departments.fetchCollectionOffline();
        const items = await this.entityManager.items.fetchCollectionOffline();

        return {
            departments: keyBy(departments, d => d.id),
            items: keyBy(items, i => i.id)
        };
    }

    private getItemUnit(item?: Items) {
        switch (_.upperFirst(item?.unit)) {
            case 'Pz': case 'Pezzo': case 'Pezzi': return 1;
            case 'Bottiglia': return 2;
            case 'Mazzo': return 3;
            case 'Sacco': return 4;
            case 'Lattina': return 5;
            case 'Grappolo': return 6;
            case 'Cassa': return 7;
            case 'Cad': case 'Cadauno': return 8;
            case 'Bicchiere': return 9;
            case 'Grammo': return 10;
            case 'G': case 'Grammi': return 11;
            case 'Chilo': return 12;
            case 'Kg': case 'Chili': return 13;
            case 'Pacco': return 14;
            case 'Paio': return 15;
            case 'ml': return 16;
            case 'Rotolo': return 18;
            case 'Porzione': return 19;
            case 'Totale': return 20;
            case 'N/A': default: return 17;
        }
    }

    private mapItemToTransaction(item: SalesItems, resources: BedzzleCommonResources, disableQuantityRounding?: boolean): BedzzleCommonTransactionItem {
        const itemVat = resources.departments[item.department_id]?.vat || {} as Vat;

        return {
            categoryAlias: item.category_name || `CAT${item.category_id || 0}`,
            categoryId: item.category_id || 0,
            categoryName: item.category_name || "Nessuna categoria",
            completeNetUnitPrice: item.final_net_price,
            completeUnitPrice: item.final_price,
            groupAlias: item.department_name,
            groupId: item.department_id,
            groupName: item.department_name,
            priceBeforeDiscount: this.saleUtilsService.getItemPartialUnitPrice(item),
            productAlias: item.name || "",
            productCode: item.sku || `SCL${item.item_id || item.department_id}`,
            productCustomName: null,
            productId: item.item_id?.toString() || `SCL${item.department_id}`,
            productName: item.name || "",
            productUnitId: this.getItemUnit(resources.items[item.item_id || ''] || {}),
            quantity: item.quantity && !disableQuantityRounding ? Math.floor(item.quantity) || 1 : item.quantity,
            remark: item.notes,
            sellingDate: moment(item.added_at).format('YY-MM-DD HH:mm:ss'),
            vatCode: itemVat.value?.toString(),
            vatDescription: item.department_name,
            vatId: itemVat.id,
            vatName: item.department_name,
            vatNature: itemVat.value ? null : itemVat.code || 'N4',
            vatValue: itemVat.value,
        };
    }

    private getPaymentMethodId(payment: SalesPayments): number {
        let methodId: number = 1; //Default Cash

        switch (payment.payment_method_type_id) {
            case 2: case 26: //Unclaimed (services)
                methodId = 19;
                break;
            case 3: //Cheque
                methodId = 6;
                break;
            case 8: //Bank transfer
                methodId = 5;
                break;
            case 18: //Satispay
                methodId = 11;
                break;
            case 24: case 25: //Unclaimed (Later Invoice)
                methodId = 20;
                break;
            default:
                //Check payment class
                if (paymentMethodTypesById[payment.payment_method_type_id]?.class === 'cash') {
                    methodId = 1;
                } else if (paymentMethodTypesById[payment.payment_method_type_id]?.class === 'digital') {
                    methodId = 2;
                }
                break;
        }

        return methodId;
    }

    public mapPaymentToTransaction(payment: SalesPayments): BedzzleCommonTransactionPayment {
        return {
            id: generateUuid(),
            methodId: this.getPaymentMethodId(payment),
            datetime: moment(payment.date).format('YY-MM-DD HH:mm:ss'),
            amount: payment.amount,
            reason: null,
            remark: null
        }
    }

    public buildTail(folio: BedzzleCommonFolio, saleData: Sales, providerID: ProviderID) {
        if (!folio) {
            return "";
        }

        const providerName = BezzleCommonService.ProviderNameMap[providerID];

        let tailLines = [];

        if (folio.keyDoorCode) {
            let headerFirstLine;
            let doorLabel;

            switch (providerID) {
                case 'spiagge_it':
                    headerFirstLine = '* ADDEBITO SU CONTO SPIAGGE.IT *';
                    doorLabel = 'Ombrellone';
                    break;
                default:
                    headerFirstLine = `* ADDEBITO IN CAMERA - ${providerName.toUpperCase()} *`;
                    doorLabel = 'Camera';
                    break;
            }

            tailLines.push(_.pad(headerFirstLine, 46));
            if (folio.firstName && folio.lastName) {
                tailLines.push(`Ospite: ${folio.firstName} ${folio.lastName}`);
            } else if (folio.companyName) {
                tailLines.push(`Ospite: ${folio.companyName}`);
            }
            tailLines.push(`${doorLabel}: ${folio.keyDoorCode}`);
        } else {
            tailLines.push(_.pad(`* ADDEBITO CONTO PASSANTE ${providerName.toUpperCase()} *`, 46));
        }

        tailLines.push(`Conto: ${folio.folioId}`);

        if (folio.checkIn && folio.checkOut) {
            tailLines.push(`Permanenza: ${moment(folio.checkIn, 'YYYY-MM-DD').format('L')}  - ${moment(folio.checkOut, 'YYYY-MM-DD').format('L')}`);
        }

        if (!this.configurationManagerService.getPreference(`${providerID}.hide_balance`)) {
            if (folio.currentBalance) {
                tailLines.push(`Saldo precedente: ${MathUtils.round(folio.currentBalance)} euro`);
            }

            tailLines.push(`Nuovo saldo: ${MathUtils.round((folio.currentBalance || 0) + saleData.final_amount!)} euro`);
        }

        return tailLines.join('\n');
    }

    public async buildTransaction(folio: Partial<BedzzleCommonFolio>, saleData: Sales, options?: BedzzleCommonTransactionOptions): Promise<BedzzleCommonTransaction> {
        const opData = this.OperatorManager.getSellerData();
        const resources = await this.getRequiredResources();

        const shop = this.userActiveSession.getSession()?.shop;
        const shopName = this.configurationManagerService.getPreference("general.shopname") || shop?.name || '';
        const documentUrl = shop ? `https://er.tilby.com/${shop.id}/${saleData.uuid}.pdf` : undefined;
        const disableQuantityRounding = !!options?.disableQuantityRounding;

        const otherPayments = saleData.payments?.filter(p => ![22, 23, 36].includes(p.payment_method_type_id)) || [];
        //Sale is paid if there is no folioId, there are other payments and there is no bank transfer payment
        const isPaidSale = !!(folio.folioId == null && otherPayments.length && !otherPayments.some(p => p.payment_method_type_id == 8));

        return {
            amount: saleData.final_amount,
            companyName: folio.companyName || saleData.sale_customer?.company_name,
            createAtDate: moment(saleData.open_at).format('YY-MM-DD HH:mm:ss'),
            currency: saleData.currency,
            customerFirstname: folio.firstName || saleData.sale_customer?.first_name,
            customerLastname: folio.lastName || saleData.sale_customer?.last_name,
            customerId: folio.customerId || saleData.sale_customer?.uuid,
            folioAlias: folio.folioAlias || saleData.notes,
            folioId: folio.folioId || null,
            folioRemark: "",
            folioStatus: isPaidSale ? 'closed' : undefined,
            isMiscSale: folio.folioId == null || folio.stayId == null,
            isPaid: isPaidSale,
            items: saleData.sale_items!.map((item) => this.mapItemToTransaction(item, resources, disableQuantityRounding)),
            netAmount: saleData.final_net_amount,
            orderId: saleData.uuid,
            orderName: saleData.name,
            outletAlias: shop?.name,
            outletDescription: shopName,
            outletId: shop?.id || 0,
            outletName: shopName,
            payments: otherPayments.map((p) => this.mapPaymentToTransaction(p)),
            pmsUser: {
                firstName: opData.first_name,
                lastName: opData.last_name
            },
            stayId: folio.stayId,
            taxAmount: MathUtils.round(saleData.final_amount! - saleData.final_net_amount!),
            ticket_image_url: documentUrl,
            totalAmount: saleData.final_amount,
            totalNetAmount: saleData.final_net_amount,
            totalTaxAmount: MathUtils.round(saleData.final_amount! - saleData.final_net_amount!)
        };
    }

    public async buildExtTransaction(folio: Partial<BedzzleCommonFolio>, saleData: Sales, options?: BedzzleCommonTransactionOptions): Promise<BedzzleCommonExtTransaction> {
        const transaction = await this.buildTransaction(folio, saleData, options);
        const isMagoEnabled = !!(this.configurationManagerService.getPreference('integrations.mago.publish_sales') || this.configurationManagerService.getPreference('integrations.mago.publish_customers'));
        const invoiceSendMode = this.configurationManagerService.getSetting('e_invoice.send_mode');
        const hasIntermediary = !!invoiceSendMode && !['disabled', 'download'].includes(invoiceSendMode);
        
        // Extract profile
        let profile: BedzzleCommonFolioProfile | undefined = undefined;
        const saleCustomer = saleData.sale_customer;

        if(saleCustomer) {
            profile = {
                id: saleCustomer.uuid!,
                companyName: saleCustomer.company_name,
                firstName: saleCustomer.first_name,
                lastName: saleCustomer.last_name,
                address: saleCustomer.billing_street,
                zipCode: saleCustomer.billing_zip,
                city: saleCustomer.billing_city,
                province: saleCustomer.billing_prov,
                country: saleCustomer.billing_country,
                vatNumber: saleCustomer.vat_code,
                taxCode: saleCustomer.tax_code,
                sdiCode: saleCustomer.sdi_code,
                pec: saleCustomer.email_pec
            };
        }

        const saleDocument = saleData.sale_documents?.find(sd => !['commercial_doc_signed', 'fiscal_provider', 'attachment'].includes(sd.document_type));

        let docEmitdate, docNum;
        let docType: BedzzleCommonExtTransaction['docType'];

        if(saleDocument) {
            docEmitdate = moment(saleDocument.date).format('YYYY-MM-DD HH:mm:ss');
            docNum = (saleDocument.sequential_number_prefix || '') + (saleDocument.sequential_number || '');

            if(saleData.final_amount! < 0) {
                docType = 'NC';
            } else {
                switch(saleDocument.document_type) {
                    case 'invoice':
                    case 'receipt_invoice':
                    case 'summary_invoice':
                    case 'shipping_invoice':
                    case 'e_invoice':
                    case 'summary_e_rc':
                    case 'summary_e_nrc':
                    case 'generic_invoice':
                        docType = 'F';
                    break;
                    case 'fiscal_receipt':
                    case 'receipt':
                    case 'commercial_doc':
                    case 'generic_receipt':
                    case 'generic_document':
                        docType = 'R';
                    break;
                }
            }   
        }

        const extTransaction = {
            ...transaction,
            accounted: isMagoEnabled,
            docEmitdate: transaction.isPaid ? docEmitdate : undefined,
            docNum: transaction.isPaid ? docNum : undefined,
            docType: transaction.isPaid ? docType : undefined,
            originalOrderId: saleData.final_amount! < 0 ? saleData.sale_parent_uuid : undefined,
            sentToSDI: hasIntermediary,
            profile
        }

        return extTransaction;
    }
}

BezzleCommonService.$inject = ["checkManager", "entityManager", "OperatorManager", "newSaleUtils", "userActiveSession"];

angular.module('digitalPayments').factory('bedzzleCommon', BezzleCommonService);