import { Injectable, Injector, inject } from "@angular/core";
import { EntityHook } from "../entity.model";
import { ConfigurationManagerService } from "src/app/core";
import { FiscalProviders } from "app/ajs-upgraded-providers";

@Injectable({
    providedIn: 'root'
})
export class ApplicationReportsEntityHook implements EntityHook {
    private readonly injector = inject(Injector)

    public async createOfflineFirstBeforeApi(entityId: string | number, entityData: any) {
        let skipApiCreate = false;
        let updateStorage = false;

        if (entityData.type?.startsWith('audit') && !entityData.fiscal_provider_sent) {
            const configurationManager = this.injector.get(ConfigurationManagerService);
            const fiscalProviders = this.injector.get(FiscalProviders);

            const fiscalProvider = fiscalProviders.getConfiguredFiscalProvider();

            if (typeof fiscalProvider?.sendAuditLog == 'function') {
                await fiscalProvider.sendAuditLog(entityData);
                entityData.fiscal_provider_sent = true;
                updateStorage = true;
            }

            if (!configurationManager.getShopPreference('logging.enable_audits')) {
                skipApiCreate = true;
            }
        }

        return { skipApiCreate, updateStorage };
    }
}