import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { Component, inject, Injectable, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyDialogContentComponent, TilbyDialogToolbarComponent, BaseDialogService, NonNullableConfigData } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { restManager } from 'app/ajs-upgraded-providers';
import { lastValueFrom } from "rxjs";
import { EnvironmentInfoService } from 'src/app/core';
import { CashregisterStateService } from "src/app/features";
import { Items } from "tilby-models";

@Component({
    selector: 'app-item-reorder-dialog',
    standalone: true,
    imports: [CommonModule, TilbyDialogContentComponent,
        TilbyDialogToolbarComponent, MatDialogModule, ScrollingModule, MatIconModule,
        TranslateModule, CdkDropList, CdkDrag, TilbyCurrencyPipe],
    templateUrl: './items-reorder-dialog.component.html',
    styleUrls: ['./items-reorder-dialog.component.scss']
})
export class ItemReorderDialogComponent implements OnInit {

    protected readonly data: any = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly cashregisterStateService = inject(CashregisterStateService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly restManager = inject(restManager);

    protected readonly dialogTitle = this.data.title || 'ITEM REORDER';

    getStockStatus: Function = () => {};
    visibleItems : any = [];
    copiedVisibileItems : any = [];
    favoriteSelected = this.cashregisterStateService.favoriteSelectedReorder;
    priceList = `price${this.cashregisterStateService.priceList()}`;
    confirmEnabled = false;
    isMobilePortrait = this.cashregisterStateService.isMobilePortrait;
    isMobileApp = this.environmentInfoService.isMobileApp();

    ngOnInit(): void {
        const jsonString = JSON.stringify(this.cashregisterStateService.itemVisibleReorder);
        this.copiedVisibileItems = JSON.parse(jsonString);
    }

    protected drop(event: CdkDragDrop<string[]>) {
            moveItemInArray(this.copiedVisibileItems, event.previousIndex, event.currentIndex);
            this.confirmEnabled = true;
    }

    protected getItemStockStatus(item : Items) {
        return { status: 'stock-disabled', stock_quantity: undefined };
    };

    protected async confirm() {
        const ids = this.copiedVisibileItems.map((item: any) => item.id);
        let result = await this.restManager.put('items/reorder', null, { "items_ids": ids });
        this.matDialogRef.close({ result: result });
    }
}

@Injectable({
    providedIn: 'root'
})
export class ItemReorderDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(options?: NonNullableConfigData<any>) {
        const config: NonNullableConfigData<any> = {
            ...this.switchMobileDesktopDimensions({ height: '70vh', width: '80%' }),
            disableClose: true,
            data: {},
            ...options
        };

        return lastValueFrom(this.dialogRef.open(ItemReorderDialogComponent, config).afterClosed()).then((res: any) => {
            return {
                data: res
            }
        });
    }
}
