import {
    Component,
    inject,
    OnInit
} from '@angular/core';
import {GridClientSide, GridComponent} from '../grid';
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';
import {GridTraductionsService} from '../grid/utils/grid-traductions.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {GridReadyEvent} from 'ag-grid-community';
import {OpenDialogsService} from 'src/app/dialogs';
import {RowModelType} from '../../model/model';
import {GridService} from '../grid/grid.service';
import {ConfigurationManagerService} from 'src/app/core/services/configuration-manager/configuration-manager.service';

@Component({
    selector: 'app-grid-client-side',
    templateUrl: '../grid/grid.component.html',
    styleUrls: ['../grid/grid.component.scss'],
    providers: [OnDestroyService]
})
export class GridClientSideComponent extends GridComponent implements OnInit,GridClientSide {
    protected readonly translateService= inject(TranslateService);
    protected readonly configurationManager= inject(ConfigurationManagerService);
    protected readonly _snackBar= inject(MatSnackBar);
    protected readonly gridTraductionsService= inject(GridTraductionsService);
    protected readonly onDestroyService= inject(OnDestroyService);
    protected readonly gridService= inject(GridService);
    protected readonly openDialogsService= inject(OpenDialogsService);

    rowModelType: RowModelType = "clientSide";

    ngOnInit(): void {
        this.defaultColDef.filter = true;
        this.initTable();
        super.ngOnInit();
    }

    onGridReady(params: GridReadyEvent) {
        super.onGridReady(params);
        this.setColumnsPreferences();
        this.gridReady(itemsRowAdded => this.gridApi?.applyTransaction({add: [itemsRowAdded]})?.add[0]);
    }

    onRemoveSelected(id?: number) {
        const selectedRows = this.gridApi?.getSelectedRows();
        const key = this.idField || 'id';
        const ids = id ? [{ [key as string]: id }] : selectedRows?.map(({ id }) => ({ [key as string]: id }));
        this.gridApi?.applyTransaction({remove: ids});
        this.deleteChange.emit(ids?.map(({ [key as string]: id })=>id));
    }

}
