import * as angular from 'angular';
import _ from "lodash";
import { Subject, map } from "rxjs";
import { CashdrawerDriver, CashdrawerDriverCommand, CashdrawerDriverController } from "src/app/shared/model/cash-drawer.model";

type VneCashdrawerStatusResponse = {
    id: string,
    payment_status: number,
    reqStatus: number,
    tipo: number,
    payment_details: VneCashdrawerPaymentDetails
}

type VneCashdrawerPaymentDetails = {
    amount: number,
    inserted: number,
    rest: number,
    status: string,
    not_returned: number
}

type VneCashdrawerStatus = VneCashdrawerStatusResponse & {
    transactionCanceled: boolean
}

type VneCashdrawerPaymentRequest = {
    tipo: number
    importo: number
    opName: string
    operatore: string
    refundable: number
    credit_card: number
}

type VneCashdrawerRequestError = {
    errorDescription: string,
    exception?: String
}

type VneCashdrawerHttpError = {
    status: number,
    data: VneCashdrawerRequestError
}

export class VneCashdrawerDriver implements CashdrawerDriver {
    idTransaction: number = -1;

    constructor(
        private $http: any,
        private operatorManager: any
    ) {
    }

    private handleHttpError(error: VneCashdrawerHttpError) {
        if (error.status === -1) {
            return { errorDescription: 'CONNECTION_ERROR' };
        }

        return error.data;
    }

    private async sendRequest(baseUrl: string, endpoint: string, method: string, payload?: any): Promise<any> {
        const requestConfig = {
            data: payload,
            method: method,
            timeout: 5000,
            url: `${baseUrl}${endpoint}`
        };

        return this.$http(requestConfig);
    }

    private async requestPayment(amount: number, baseUrl: string) {
        const opName = this.operatorManager.getOperatorData().full_name;

        const paymentPayload: VneCashdrawerPaymentRequest = {
            tipo: 1,
            importo: (amount * 100),
            opName: opName,
            operatore: opName,
            refundable: 1,
            credit_card: 0
        }

        try {
            const result = await this.sendRequest(baseUrl, '/', 'POST', paymentPayload);

            if (result.data.req_status === 1) {
                this.idTransaction = result.data.id;
            } else if (result.data.req_status === 2) {
                throw { status: result.status, data: { errorDescription: result.data.mess } };
            }
        } catch (error: any) {
            throw this.handleHttpError(error);
        }
    }

    private async transactionStatusLoop(baseUrl: string, statusSubject: Subject<VneCashdrawerStatus>, controllerSubject: Subject<CashdrawerDriverCommand>) {
        let data: VneCashdrawerStatus | null = null;
        let transactionStatus: ('ACTIVE' | 'ABORTING' | 'ABORTED') = 'ACTIVE';

        controllerSubject.subscribe({
            next: async (command) => {
                switch (command) {
                    case 'ABORT_TRANSACTION':
                        if (transactionStatus === 'ACTIVE') {
                            transactionStatus = 'ABORTING';

                            try {
                                await this.rollbackPendingPayment(baseUrl, 2);
                            } catch (err) {
                                transactionStatus = 'ACTIVE';
                            }
                            transactionStatus = 'ABORTED';
                        }
                        break;
                }
            }
        })

        do {
            //Wait 250 ms if this isn't the first status request
            if (data) {
                await new Promise(resolve => setTimeout(resolve, 250));
            }

            try {
                //Ask transaction status
                const answer: { status: number; data: VneCashdrawerStatusResponse } = await this.sendRequest(baseUrl, '/', 'POST', { tipo: 2, id: this.idTransaction, opName: this.operatorManager.getOperatorData().full_name });

                data = Object.assign(answer.data, {
                    transactionCanceled: ['ABORTED', 'ABORTING'].includes(transactionStatus)
                });

                //Send status to subject
                statusSubject.next(data);

                if (['returned', 'deleted', 'notCompleted'].includes(data.payment_details.status)) {
                    if (data.payment_details.status === "notCompleted") {
                        // Cancel transaction if not completed
                        const result = await this.sendRequest(baseUrl, '/', 'POST', { tipo: 3, id: this.idTransaction, opName: this.operatorManager.getOperatorData().full_name, tipo_annullamento: 1 });

                        if (result.data.req_status === 2) {
                            throw { status: result.status, data: { errorDescription: result.data.mess } }
                        }
                    } else {
                        throw { status: answer.status, data: { errorDescription: data.payment_details.status.toUpperCase() } }
                    }
                }
                if (['pending'].includes(data.payment_details.status) && data.payment_details.not_returned === 1) {
                    await this.rollbackPendingPayment(baseUrl, 1);
                }
            } catch (error: any) {
                throw this.handleHttpError(error);
            }
        } while (data && !(data.payment_status === 1 && (data.payment_details.status === 'completed' || data.payment_details.status === 'partial')));
    }

    private async startTransaction(amount: number, baseUrl: string, statusSubject: Subject<VneCashdrawerStatus>, controllerSubject: Subject<CashdrawerDriverCommand>) {
        try {
            //Send payment request
            await this.requestPayment(amount, baseUrl);

            //Start transaction loop
            await this.transactionStatusLoop(baseUrl, statusSubject, controllerSubject);

            //Send completion event
            statusSubject.complete();
        } catch (error: any) {
            if (error?.errorDescription) {
                error = `VNE_CASHDRAWER.${_.chain(error.errorDescription).snakeCase().toUpper().value()}`;
            }

            statusSubject.error(error);
        } finally {
            controllerSubject.complete();
        }
    }

    performPayment(amount: number, ipAddress: string): CashdrawerDriverController {
        const baseUrl = `http://${ipAddress}/selfcashapi`;

        const statusSubject = new Subject<VneCashdrawerStatus>();
        const controllerSubject = new Subject<CashdrawerDriverCommand>();

        this.startTransaction(amount, baseUrl, statusSubject, controllerSubject);

        const transactionStatus = statusSubject.asObservable().pipe(
            map((value: VneCashdrawerStatus) => {
                let response = {
                    toPay: amount,
                    paid: (value.payment_details?.inserted / 100) || 0,
                    changeGiven: (value.payment_details?.rest / 100) || 0,
                    changeNotGiven: 0,
                    message: `DIGITAL_PAYMENTS.VNE_CASHDRAWER.PAYMENT_STATUSES.${_.chain(value.payment_details.status || 'unknown').snakeCase().toUpper().value()}`,
                    canceled: value.transactionCanceled,
                    idTransaction: this.idTransaction
                }

                if (value.payment_details.status === 'partial' || value.payment_details.status === 'completed') {
                    response.changeNotGiven = (response.paid - (response.toPay + response.changeGiven));
                }

                return response;
            })
        );

        return {
            transactionStatus: transactionStatus,
            driverController: controllerSubject
        }
    }

    public async rollbackPendingPayment(baseUrl: string, cancelType: number) {
        const result = await this.sendRequest(baseUrl, '/', 'POST', { tipo: 3, id: this.idTransaction, opName: this.operatorManager.getOperatorData().full_name, tipo_annullamento: cancelType });
        if (result.data.req_status === 2) {
            throw { status: result.status, data: { errorDescription: result.data.mess } }
        }
    }

    public async rollbackPayment(baseUrl: string) {
        const result = await this.sendRequest(baseUrl, '/', 'POST', { tipo: 65, id: this.idTransaction, opName: this.operatorManager.getOperatorData().full_name });
        if (result.data.req_status === 2) {
            throw { status: result.status, data: { errorDescription: result.data.mess } }
        }
    }
}

VneCashdrawerDriver.$inject = ['$http', 'OperatorManager'];

angular.module('digitalPayments').service('VneCashdrawerDriver', VneCashdrawerDriver);
