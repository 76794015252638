import * as angular from 'angular';

angular.module('application').factory('onlineOnlyDialog', onlineOnlyDialog);

onlineOnlyDialog.$inject = ["$mdDialog", "$state", "startup", "connection"];

function onlineOnlyDialog($mdDialog, $state, startup, connection) {
    onlineOnlyDialogController.$inject = ["$scope", "$mdDialog"];
    
    function onlineOnlyDialogController($scope, $mdDialog) {
        $scope.context = "notify";

        $scope.returnToDefaultState = () => {
            $mdDialog.hide();
        };

        $scope.showConfirmation = () => {
            $scope.context = "confirm";
        };

        $scope.cancelReturn = () => {
            $scope.context = "notify";
        };

        $scope.isNotifyContext = () => $scope.context === "notify";
        $scope.isConfirmContext = () => $scope.context === "confirm";

        const timeoutLoop = async () => {
            let backOnline = false;

            while(!backOnline && !$scope.$$destroyed) {
                try {
                    await connection.waitForConnection();
                    backOnline = true;
                } catch(e) {}
            }

            if(!$scope.$$destroyed) {
                $mdDialog.cancel();
            }
        };

        timeoutLoop();
    }

    var onlineOnlyDialog = {
        show: () => {
            return $mdDialog.show({
                controller: onlineOnlyDialogController,
                template: require('./online-only-dialog.html'),
                clickOutsideToClose: false,
                escapeToClose: false
            }).then(() => {
                $state.go("app." + startup.getStartupState());
            });
        }
    };

    return onlineOnlyDialog;
}
