import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('history').factory('invoicePassivePaymentsDialog', ["$mdDialog", "$translate", "toast", "alertDialog", "restManager", "errorsLogger", function ($mdDialog, $translate, toast, alertDialog, restManager, errorsLogger) {

    invoicePassivePaymentsDialogController.$inject = ["$scope", "$rootScope", "$timeout", "$mdDialog", "invoicePassive"];
    function invoicePassivePaymentsDialogController($scope, $rootScope, $timeout, $mdDialog, invoicePassive) {
        $scope.invoicePassive = invoicePassive;
        $scope.invoicePassivePayments = [];
        $scope.invoicePassivePaymentsToDelete = [];
        $scope.loadingInProgress = true;
        $scope.errorAmount = 'true';
        $scope.methodPayments = [
            { id: "MP01", value: "contanti" },
            { id: "MP02", value: "assegno" },
            { id: "MP03", value: "assegno circolare" },
            { id: "MP04", value: "contanti presso Tesoreria" },
            { id: "MP05", value: "bonifico" },
            { id: "MP06", value: "vaglia cambiario" },
            { id: "MP07", value: "bollettino bancario" },
            { id: "MP08", value: "carta di pagamento" },
            { id: "MP09", value: "RID" },
            { id: "MP10", value: "RID utenze" },
            { id: "MP11", value: "RID veloce" },
            { id: "MP12", value: "Riba" },
            { id: "MP13", value: "MAV" },
            { id: "MP14", value: "quietanza erario stato" },
            { id: "MP15", value: "giroconto su conti di contabilità speciale" },
            { id: "MP16", value: "domiciliazione bancaria" },
            { id: "MP17", value: "domiciliazione postale" },
            { id: "MP18", value: "bollettino di c/c postale" },
            { id: "MP19", value: "SEPA Direct Debit" },
            { id: "MP20", value: "SEPA Direct Debit CORE" },
            { id: "MP21", value: "SEPA Direct Debit B2B" },
            { id: "MP22", value: "Trattenuta su somme già riscosse" },
            { id: "MP23", value: "PagoPA" }
        ];
        function getSafe(obj, propertyName) {
            if (obj.hasOwnProperty(propertyName)) {
                return obj[propertyName];
            }
            return "empty"; // throw or some other error reporting.
        }
        $scope.getMethod = function (method) {
            if (method === null || method === undefined) {
                return "empty";
            }
            return getSafe($scope.methodPayments, method);
        };
        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.addNewPayment = function () {
            var now = new Date();
            var p = {
                paid: false,
                expiredAt: now,
                amount: 0.00,
                paidAt: null,
                method: $scope.methodPayments[0]
            };
            $scope.invoicePassivePayments.push(p);
        };
        $scope.setCheck = function (payment, paid) {
            $scope.invoicePassivePayments.forEach(function (element) {
                if (element.id === payment.id && paid === false) {

                    element.paidAt = null;
                }
            });
        };
        $scope.nothing = function () {
        };
        var turnOffLoader = function () {
            $rootScope.$broadcast("loader:changeStatus", "invoicePassivePaymentsLoader", {
                enabled: false
            });
            $scope.loadingInProgress = false;
        };
        $scope.hasNoResults = function () {
            return false;
        };
        $scope.deletePayment = function (payment) {
            $scope.invoicePassivePayments = _.filter($scope.invoicePassivePayments, function (e) {
                if (e === payment) {
                    if (e.id != null) {
                        $scope.invoicePassivePaymentsToDelete.push(payment);
                    }
                }
                return e !== payment;
            });
        };
        $scope.changeAmount = function (formPayments, payment) {
            if (payment.amount > ($scope.invoicePassive.totalPrice / 100)) {
                formPayments[payment.uuid].$setValidity("amountValidation", false);
                return;
            }
            var totalAmount = 0;
            $scope.invoicePassivePayments.forEach(function (e) {
                totalAmount = totalAmount + e.amount;
            });
            if (totalAmount > ($scope.invoicePassive.totalPrice / 100)) {
                formPayments[payment.uuid].$setValidity("amountValidation", false);
                return;
            }
            $scope.invoicePassivePayments.forEach(function (e) {
                formPayments[e.uuid].$setValidity("amountValidation", true);
            });

        };

        $scope.save = function () {
            var p = [];
            var checkTotal = 0;
            var checkTotalPaid = 0;
            $scope.invoicePassivePayments.forEach(function (e) {
                var newE = {};
                checkTotal = checkTotal + e.amount;
                newE = Object.assign({}, e);
                if (e.paid === true) {
                    checkTotalPaid = checkTotalPaid + e.amount;
                }
                if (newE.paidAt != null) {
                    newE.paidAt = newE.paidAt.getDate() + "/" + (newE.paidAt.getMonth() + 1) + "/" + newE.paidAt.getFullYear();
                }
                if (newE.expiredAt != null) {
                    newE.expiredAt = newE.expiredAt.getDate() + "/" + (newE.expiredAt.getMonth() + 1) + "/" + newE.expiredAt.getFullYear();
                }
                p.push(newE);
            });
            if (checkTotal > ($scope.invoicePassive.totalPrice / 100)) {
                alertDialog.show("La somma dei pagamenti supera l'importo totale!");
                return;
            }
            $scope.loadingInProgress = true;
            $rootScope.$broadcast("loader:changeStatus", "invoicePassivePaymentsLoader", {
                enabled: true
            });
            var params = {
                idInvoice: invoicePassive.id,
                payments: p,
                paymentsToDelete: $scope.invoicePassivePaymentsToDelete
            };

            $rootScope.$applyAsync();

            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "payments", params, "POST").then(function (result) {
                    turnOffLoader();
                    errorsLogger.debug("checkTotal " + checkTotal + ", total price " + $scope.invoicePassive.totalPrice);
                    if (p.length === $scope.invoicePassivePayments.length) {
                        var paid = false;
                        if (checkTotalPaid * 100 === $scope.invoicePassive.totalPrice) {
                            for (var i = 0; i < p.length; i++) {
                                var e = p[i];
                                if (e.paid === true || e.paid === true) {
                                    paid = true;
                                } else {
                                    paid = false;
                                    break;
                                }
                            }
                        }
                        $rootScope.$emit("passiveInvoice:update", { id: invoicePassive.id, paid: paid, amount: checkTotalPaid });
                    }
                    $scope.invoicePassivePaymentsToDelete = [];
                    $scope.errorAmount = false;
                    getInvoicePassivePayments();
                    $mdDialog.cancel();
                }).catch(function (err) {
                    turnOffLoader();
                    toast.show({
                        message: err.data.message || "Error saving invoices payments",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                });
            });

        };

        var getInvoicePassivePayments = function () {
            var params = {
                id: invoicePassive.id
            };

            $rootScope.$broadcast("loader:changeStatus", "invoicePassivePaymentsLoader", {
                enabled: true
            });

            $rootScope.$applyAsync();

            $timeout(function () {
                restManager.invoicePassive("e_invoice/accounts_payable", "payments", params, "GET").then(function (result) {
                    result.listPayments.forEach(function (element, i) {

                        element.uuid = "" + i;
                        if (element.paid === 1) {
                            element.paid = true;
                        }
                        if (element.amount != null) {
                            element.amount = element.amount / 100;
                        }
                        if (!element["method"]) {
                            element["method"] = null;
                        } else {
                            var methodToShow = _.find($scope.methodPayments, function (e) {
                                return e.id === element["method"];
                            });
                            element["method"] = methodToShow;
                        }
                        if (element.expiredAt != null) {
                            //string formatted in dd/mm/YYYY so i split the string
                            // and get each to create a js date
                            var dExpArray = element.expiredAt.split('/');
                            element.expiredAt = new Date(dExpArray[2], dExpArray[1] - 1, dExpArray[0]);
                        }
                        if (element.paidAt != null) {
                            //string formatted in dd/mm/YYYY so i split the string
                            // and get each to create a js date
                            var dPaidArray = element.paidAt.split('/');
                            element.paidAt = new Date(dPaidArray[2], dPaidArray[1] - 1, dPaidArray[0]);
                        }

                    });
                    $scope.errorAmount = false;
                    $scope.invoicePassivePayments = result.listPayments;
                    turnOffLoader();

                }).catch(function (err) {
                    toast.show({
                        message: err.data.message || "Error getting invoices payments",
                        actions: [{
                            text: $translate.instant('MISC.OK'),
                            action: 'dismiss',
                            highlight: true
                        }],
                        hideDelay: 0
                    });
                    turnOffLoader();
                });

            });

        };
        getInvoicePassivePayments();

    }
    var invoicePassivePaymentsDialog = {
        show: function (invoicePassive) {
            return $mdDialog.show({
                controller: invoicePassivePaymentsDialogController,
                template: require('./invoice-passive-payments.html'),
                locals: {
                    invoicePassive: invoicePassive
                }
            });
        }
    };

    return invoicePassivePaymentsDialog;
}]);