import { Injectable } from "@angular/core";

@Injectable()
export class GridCellExportStyleService {

    constructor() {
    }

    // customers = new Map<string, String>([
    //     ["birthdate", "dateFormat"],
    //     ["tot_spent", "currencyFormat"],
    //     ["discount_perc", "percentuageFormat"]
    // ]);
}
