<ng-container>
<!--    <topbar-suppliers-showcase-->
<!--        [hidden]="!gridPath"-->
<!--        title="SUPPLIERS.TOPBAR_SHOWCASE.SUPPLIERS"-->
<!--        feature="suppliers"-->
<!--        [tableData]="tableData[0]"-->
<!--        [isSelected]="isSelected"-->
<!--        (addItem)="addSupplier()"-->
<!--        (searchEvent)="appGridComponent?.onFilterTextBoxChanged($event)"-->
<!--        (deleteRowsEvent)="appGridComponent.onRemoveSelected()"-->
<!--        (exportEvent)="appGridComponent?.onBtnExport($event)"-->
<!--        (openToolPanel)="appGridComponent?.openToolPanel($event)"-->
<!--        (updateItems)="appGridComponent.updateItems($event)"-->
<!--        (advancedFilters)="appGridComponent?.advancedFilterSwitch($event)"-->
<!--        (columnMovable)="appGridComponent?.columnMovableSwitch($event)"-->
<!--        (saveColumnsPreferences)="appGridComponent?.openDialogSaveColumnsPreference()"-->
<!--    ></topbar-suppliers-showcase>-->
    <app-grid-client-side
        #appGridComponent
        (areRowsSelected)="isSelected=$event"
        (clickableButton)="crud($event)"
        (rowClicked)="rowClicked($event)"
        [hidden]="!gridPath"
        [tableData]="tableData[0]"
        (updateChange)="updateItems($event)"
        (deleteChange)="deleteSuppliers($event)"
        [exportFormatterOptions]="exportFormatterOptions"
        feature="suppliers"
    ></app-grid-client-side>
</ng-container>

<div appUpgradeUiView *ngIf="!gridPath"></div>
