import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ShepherdService } from "angular-shepherd";
import Step from 'shepherd.js/src/types/step';

@Injectable()
export abstract class TutorialInAppService {

    steps: Step.StepOptions[] = [];
    builtInButtons: any = {};

    defaultStepOptions = {
        classes: 'shepherd-custom-theme',
        scrollTo: true,
        cancelIcon: {
          enabled: true
        }
    };

    constructor(protected translate: TranslateService, protected shepherdService: ShepherdService) {
    }

    protected generateButtons() {

        this.builtInButtons = {
            cancel: {
              classes: 'cancel-button',
              secondary: true,
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.BUTTONS.EXIT"),
              type: 'cancel'
            },
            next: {
              classes: 'next-button',
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.BUTTONS.NEXT"),
              type: 'next'
            },
            back: {
              classes: 'back-button',
              secondary: true,
              text: this.translate.instant("SUPPLIERS.TUTORIAL_IN_APP.BUTTONS.BACK"),
              type: 'back'
            }
        };
    }

    protected abstract generateSteps() : void;

    init() {
        this.generateButtons();
        this.generateSteps();
    }

    start() {
        this.shepherdService.defaultStepOptions = this.defaultStepOptions;
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
        this.shepherdService.addSteps(this.steps);
        this.shepherdService.start();
    }
}
