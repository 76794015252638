import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfigurationManagerService} from "../../core/services";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { Printers } from 'tilby-models';

export interface DailyClosingDialogData { printer: Printers }

@Component({
    selector: 'app-daily-closing-dialog',
    templateUrl: './daily-closing-dialog.component.html',
    styleUrls: ['./daily-closing-dialog.component.scss']
})
export class DailyClosingDialogComponent{
    verifyCashRequired = this.configurationManagerService.getPreference('printers.verify_cash_on_dailyclousure')||false;
    dailyClosingDialogForm = new FormGroup<{cashVerification:FormControl<number|null>}>({
        cashVerification: new FormControl(null, [...(this.verifyCashRequired?[Validators.required]:[]), Validators.min(0)])
    });
    constructor(
        public dialogRef: MatDialogRef<DailyClosingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DailyClosingDialogData,
        private configurationManagerService:ConfigurationManagerService
    ) {}

    get cashVerification(){
        return this.dailyClosingDialogForm.controls.cashVerification;
    }

    answer() {
        if(this.dailyClosingDialogForm.valid) {
           this.dialogRef.close({cash_verification: this.cashVerification.value});
        }
    }
}
