import * as angular from 'angular';

angular.module('application').directive('barcodeScanner', function() {
    return {
        restrict: 'E',
        template: require('./barcode-scanner.html'),
        scope: {
            onBarcodeScanned: '=',
            topbarMode: '='
        },
        controller: ["$scope", "barcodeManager", "environmentInfo", function($scope, barcodeManager, environmentInfo) {
            if($scope.topbarMode) {
                $scope.mode = 'topbar';
            } else {
                $scope.mode = 'normal';
            }

            $scope.canUseCameraBarcode = environmentInfo.isCameraBarcodeSupported();

            $scope.openCameraBarcode = async () => {
                const barcodeResult = await barcodeManager.openCameraBarcode();
                return $scope.onBarcodeScanned(barcodeResult);
            };
        }]
    };
});
