import * as angular from 'angular';
import { Subject, map } from "rxjs";
import { EnvironmentInfoService } from 'src/app/core';
import { XMLNode, XMLNodeObject } from 'src/app/shared/XmlNode';
import { CashdrawerDriver, CashdrawerDriverCommand, CashdrawerDriverController } from "src/app/shared/model/cash-drawer.model";
import { TilbySoapClient } from 'src/app/shared/net/soap-client';
import { TilbyTcpSocket, TilbyTcpSocketUtils } from 'src/app/shared/net/tilby-tcp-socket';
import { keyBy } from 'src/app/shared/utils';
import { v4 as generateUuid } from 'uuid';

type GlorySessionHandler = {
    sessionId: string
    client: TilbySoapClient
};

type GloryLoginCredentials = {
    username: string
    password?: string
};

type GloryCashStatus = {
    canceled?: boolean
    message?: string
    cashIn?: number
    cashOut?: number
};

const errorCodes: Record<string, string> = {
    '1': 'CANCELED',
    '2': 'RESET',
    '3': 'OCCUPIED_BY_OTHER',
    '5': 'NOT_OCCUPIED',
    '6': 'DESIGNATION_DENOMINATION_SHORTAGE',
    '9': 'CANCEL_CHANGE_SHORTAGE',
    '10': 'CHANGE_SHORTAGE',
    '11': 'EXCLUSIVE_ERROR',
    '12': 'DISPENSED_CHANGE_INCONSISTENCY',
    '13': 'AUTO_RECOVERY_FAILURE',
    '17': 'OCCUPIED_BY_ITSELF',
    '20': 'SESSION_NOT_AVAILABLE',
    '21': 'INVALID_SESSION',
    '22': 'SESSION_TIMEOUT',
    '40': 'INVALID_CASSETTE_NUMBER',
    '41': 'IMPROPER_CASSETTE',
    '43': 'EXCHANGE_RATE_ERROR',
    '44': 'COUNTED_CATEGORY',
    '96': 'DUPLICATE_TRANSACTION',
    '99': 'PROGRAM_INNER_ERROR',
    '100': 'DEVICE_ERROR',
}

const statusMessages: Record<string, string> = {
    '3': 'WAITING_CASH',
    '4': 'COUNTING',
    '5': 'DISPENSING',
    '7': 'TAKE_CASH_OUT'
}

export class GloryCashdrawerDriver implements CashdrawerDriver {
    constructor(
        private environmentInfo: EnvironmentInfoService,
    ) {
    }

    private getErrorFromCode(code: string): string {
        return errorCodes[code] ? `GLORY_CASHDRAWER.${errorCodes[code]}` : 'UNKNOWN_ERROR';
    }

    private async sendRequest(sessionHandler: GlorySessionHandler, methodName: string, requestName: string, request: Record<string, string>) {
        const requestId = generateUuid().replace('-', '').substring(0, 12);

        const requestBody: XMLNodeObject[] = [
            { name: 'xsd1:Id', children: [{ content: requestId }] },
            { name: 'xsd1:SeqNo' }
        ];

        if (sessionHandler.sessionId) {
            requestBody.push({ name: 'xsd1:SessionID', children: [{ content: sessionHandler.sessionId }] });
        }

        for (const key in request) {
            requestBody.push({ name: `xsd1:${key}`, children: [{ content: request[key] }] });
        }

        const requestPayload = XMLNode.fromObject({
            name: `xsd1:${requestName}`,
            attributes: {
                "xmlns:xsd1": "http://www.glory.co.jp/bruebox.xsd"
            },
            children: requestBody
        });

        return sessionHandler.client.sendRequest(methodName, requestPayload, undefined);
    }

    private async login(ipAddress: string, credentials: GloryLoginCredentials): Promise<GlorySessionHandler> {
        const client = new TilbySoapClient(`http://${ipAddress}/axis2/services/BrueBoxService`);

        const requestPayload: Record<string, string> = {
            'User': credentials.username
        };

        if(credentials.password) {
            requestPayload['UserPwd'] = credentials.password;
        }

        requestPayload['DeviceName'] = 'Tilby';

        const response = await this.sendRequest({ client, sessionId: '' }, 'OpenOperation', 'OpenRequest', requestPayload);

        if (!response?.body) {
            throw 'GLORY_CASHDRAWER.PARSE_ERROR';
        }

        const openResponseNode = response.body.children?.find((node) => node.name === 'OpenResponse');

        if (!openResponseNode) {
            throw 'GLORY_CASHDRAWER.PARSE_ERROR';
        }

        const openResult = openResponseNode.attributes?.result || '';

        if (openResult !== '0') {
            throw this.getErrorFromCode(openResult);
        }

        const nodesByName = keyBy(openResponseNode.children || [], (node) => node?.name || '');

        return {
            sessionId: nodesByName['SessionID'].content!,
            client
        };
    }

    private async logout(session: GlorySessionHandler) {
        return this.sendRequest(session, 'CloseOperation', 'CloseRequest', {});
    }

    private getTotalFromCashNode(cashNode: XMLNodeObject): number {
        let result = 0;

        const denominationInNodes = cashNode.children?.filter((node) => node.name === 'Denomination') || [];

        for (const denominationNode of denominationInNodes) {
            const pieceNode = denominationNode.children?.find((node) => node.name === 'Piece');

            if (!pieceNode) {
                throw 'GLORY_CASHDRAWER.PARSE_ERROR';
            }

            result += Number(pieceNode.content) * Number(denominationNode.attributes?.fv || 0);
        }

        return result;
    }

    private async cancelPayment(session: GlorySessionHandler) {
        return this.sendRequest(session, 'ChangeCancelOperation', 'ChangeCancelRequest', {
            'Option': '0'
        });
    }

    private async requestPayment(session: GlorySessionHandler, amount: number): Promise<GloryCashStatus> {
        const cashAmount = Math.round(amount * 100);

        const response = await this.sendRequest(session, 'ChangeOperation', 'ChangeRequest', {
            'Amount': String(cashAmount)
        });

        if (!response?.body) {
            throw 'GLORY_CASHDRAWER.PARSE_ERROR';
        }

        const changeResponseNode = response.body.children?.find((node) => node.name === 'ChangeResponse');

        if (!changeResponseNode) {
            throw 'GLORY_CASHDRAWER.PARSE_ERROR';
        }

        const paymentResult = changeResponseNode.attributes?.result || '';

        if (!['0', '10'].includes(paymentResult)) {
            throw this.getErrorFromCode(paymentResult);
        }

        //Extract cash-in status (type 1)
        const cashInNode = changeResponseNode.children?.find((node) => node.name === 'Cash' && node.attributes?.type === '1');

        if (!cashInNode) {
            throw 'GLORY_CASHDRAWER.PARSE_ERROR';
        }

        const cashIn = this.getTotalFromCashNode(cashInNode);

        //Extract cash-out (change) status (type 2)
        let cashOut = 0;

        const cashOutNode = changeResponseNode.children?.find((node) => node.name === 'Cash' && node.attributes?.type === '2');

        if (cashOutNode) {
            cashOut = this.getTotalFromCashNode(cashOutNode);
        }

        return { cashIn: cashIn, cashOut: cashOut };
    }

    private async registerSocket(session: GlorySessionHandler, ipAddress: string) {
        return this.sendRequest(session, 'RegisterEventOperation', 'RegisterEventRequest', {
            'Url': ipAddress,
            'Port': '2014'
        });
    }

    private async unregisterSocket(session: GlorySessionHandler, ipAddress: string) {
        return this.sendRequest(session, 'UnRegisterEventOperation', 'UnRegisterEventRequest', {
            'Url': ipAddress,
            'Port': '2014',
        });
    }

    private onSocketData(data: Uint8Array, statusSubject: Subject<GloryCashStatus>) {
        const responseString = TilbyTcpSocketUtils.byteArrayToString(data);
        const responseArray = XMLNode.stringToObject(responseString);

        for (const responseData of responseArray) {
            if (responseData?.name !== 'BbxEventRequest') {
                continue;
            }

            const statusChangeEvent = responseData.children?.find((node) => node.name === 'StatusChangeEvent');

            if (!statusChangeEvent) {
                continue;
            }

            const statusChangeParams = keyBy(statusChangeEvent.children || [], (node) => node.name || '');
            const status = statusChangeParams['Status']?.content || '-1';
            const amount = statusChangeParams['Amount']?.content || '0';

            switch(status) {
                case '4': //Counting
                    statusSubject.next({
                        message: statusMessages[status],
                        cashIn: parseInt(amount)
                    });
                break;
                case '5': //Dispensing
                    statusSubject.next({
                        message: statusMessages[status],
                        cashOut: parseInt(amount)
                    });
                break;
                default:
                    //Return a status message if we have one
                    if(statusMessages[status]) {
                        statusSubject.next({
                            message: statusMessages[status]
                        });
                    }
                break;
            }
        }
    }

    private async startTransaction(amount: number, ipAddress: string, loginCredentials: GloryLoginCredentials, statusSubject: Subject<GloryCashStatus>, controllerSubject: Subject<CashdrawerDriverCommand>) {
        let clientIp: string = '';
        let tcpSocket: TilbyTcpSocket | undefined;
        let sessionHandler: GlorySessionHandler | undefined;

        const tcpServer = TilbyTcpSocketUtils.getSocketServer();

        try {
            sessionHandler = await this.login(ipAddress, loginCredentials);

            let transactionStatus: ('ACTIVE' | 'ABORTING' | 'ABORTED') = 'ACTIVE';

            statusSubject.next({});

            controllerSubject.subscribe({
                next: async (command) => {
                    switch (command) {
                        case 'ABORT_TRANSACTION':

                            if (transactionStatus === 'ACTIVE') {
                                transactionStatus = 'ABORTING';

                                if (sessionHandler) {
                                    statusSubject.next({ canceled: true });

                                    try {
                                        await this.cancelPayment(sessionHandler);
                                    } catch (err) {
                                        statusSubject.next({ canceled: false });
                                        transactionStatus = 'ACTIVE';
                                    }
                                }

                                transactionStatus = 'ABORTED';
                            }
                            break;
                    }
                }
            });

            //Setup TCP server if the environment supports it
            if (tcpServer) {
                const clientInfo = await this.environmentInfo.getNetworkInfo();

                //Register socket
                if (clientInfo.client_lan_ip) {
                    clientIp = clientInfo.client_lan_ip;
                    tcpServer.listen(2014);

                    const cleanup = () => {
                        if (tcpSocket) {
                            tcpSocket.close();
                        }

                        tcpServer.close();
                    };

                    //Cleanup TCP socket when we are done
                    statusSubject.subscribe({
                        complete: cleanup,
                        error: cleanup
                    });

                    //Handle cashdrawer connection
                    tcpServer.onConnection.subscribe({
                        next: (socket) => {
                            if(!tcpSocket) {
                                tcpSocket = socket;
                                tcpSocket.onData.subscribe(data => this.onSocketData(data, statusSubject));
                            } else {
                                // We already have a socket, close the new one
                                socket.close();
                            }
                        }
                    });

                    //Ask the cashdrawer to send status updates to us
                    await this.registerSocket(sessionHandler, clientInfo.client_lan_ip);
                }
            }

            //Send payment request
            const result = await this.requestPayment(sessionHandler, amount);

            //Send final status and complete
            statusSubject.next(result);
            statusSubject.complete();
        } catch (error: any) {
            console.log(error);
            statusSubject.error(error);
        } finally {
            if (sessionHandler) {
                //Send completion event
                if (clientIp) {
                    await this.unregisterSocket(sessionHandler, clientIp);
                }

                await this.logout(sessionHandler);
            }

            controllerSubject.complete();
        }
    }

    public performPayment(amount: number, ipAddress: string, loginInfo: string): CashdrawerDriverController {
        // Extract username and password from loginInfo (bundle_name, format: username:password)
        const [username, password] = loginInfo.split(':');
        const loginCredentials: GloryLoginCredentials = { username: username, password: password };

        const statusSubject = new Subject<GloryCashStatus>();
        const controllerSubject = new Subject<CashdrawerDriverCommand>();

        this.startTransaction(amount, ipAddress, loginCredentials, statusSubject, controllerSubject);

        const status = {
            toPay: amount,
            paid: 0,
            changeGiven: 0,
            changeNotGiven: 0,
            canceled: false,
            message: ''
        };

        const transactionStatus = statusSubject.asObservable().pipe(
            map((value: GloryCashStatus) => {
                if(value.cashIn != null) {
                    status.paid = (value.cashIn / 100) || 0;
                }

                if(value.cashOut != null) {
                    status.changeGiven = (value.cashOut / 100) || 0;
                }

                if(value.canceled != null) {
                    status.canceled = value.canceled;
                }

                if(value.message != null) {
                    status.message = `DIGITAL_PAYMENTS.GLORY_CASHDRAWER.PAYMENT_STATUSES.${value.message}`;
                }

                const changeNotGiven = (status.paid - (status.toPay + status.changeGiven));
                status.changeNotGiven = (changeNotGiven < 0) ? 0 : changeNotGiven;

                return status;
            })
        );

        return {
            transactionStatus: transactionStatus,
            driverController: controllerSubject
        }
    }
}

GloryCashdrawerDriver.$inject = ["environmentInfo"];

angular.module('digitalPayments').service('GloryCashdrawerDriver', GloryCashdrawerDriver);
