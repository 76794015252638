import { Form } from 'src/app/models';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Channels } from 'tilby-models';

interface ChannelsFieldsFe {
    isNew?:boolean;
}

export type ChannelsFields = Channels & ChannelsFieldsFe;

export class ChannelsDefault implements ChannelsFields {
    id: string = '';
    name: string = '';
    image_url?: string | undefined;
    url?: string | undefined;
    enabled?: boolean | undefined;
    allow_automatic_print?: boolean | undefined;
    force_paid?: boolean | undefined;
    add_to_mail_receipts?: boolean | undefined;
    add_to_new_items?: boolean | undefined;
    default_payment_method_id?: number | undefined;
    isNew?: boolean | undefined;
}

export type ChannelsFieldsNullable = { [prop in string & keyof ChannelsFields]:ChannelsFields[prop]|null }
export type ChannelsForm = Partial<Form<ChannelsFieldsNullable>>;

export type ChannelsFormGroup = {
    generalForm: CustomFormGroup<any>
}

