import { XMLNode, XMLNodeObject } from 'src/app/shared/XmlNode';

export type TilbySoapResponse = {
	header?: XMLNodeObject,
	body?: XMLNodeObject
}

export class TilbySoapClient {
	constructor(
		private url: string
	) {
	}

	
	/**
	 * Sends a SOAP request to the specified URL with the given method name, body, and optional header.
	 *
	 * @param {string} methodName - The name of the SOAP method to call.
	 * @param {XMLNode | string} body - The body of the SOAP request.
	 * @param {XMLNode | string} [header] - The optional header of the SOAP request.
	 * @return {Promise<TilbySoapResponse | undefined>} A promise that resolves to the parsed SOAP response, or undefined if there was an error.
	 */
	sendRequest(methodName: string, body: XMLNode | string, header?: XMLNode | string): Promise<TilbySoapResponse | undefined> {
		const soapRequestPayload = this.getSoapPayload(body, header);
		const headers = {
			'Content-Type': 'text/xml; charset=utf-8',
			'SOAPAction': `${methodName}`
		};

		return fetch(this.url, { method: "POST", body: soapRequestPayload, headers })
			.then(res => res.text())
			.then(response => TilbySoapClient.parseResponse(response));
	}

	private getSoapPayload(body: XMLNode | string, header?: XMLNode | string): string {
		const bodyString = body.toString();
		const headerString = header?.toString();

		const soapEnvelope = `<?xml version="1.0" encoding="utf-8"?>
			<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
				${header ? `<soap:Header>${headerString}</soap:Header>` : ''}
				<soap:Body>${bodyString}</soap:Body>
			</soap:Envelope>`;

		return soapEnvelope;
	}

	/**
	 * Parses the given XML soap response and returns a TilbySoapResponse object.
	 * Namespaces are removed from the parsed response.
	 *
	 * @param {string} xmlString - The XML string to parse.
	 * @return {TilbySoapResponse | undefined} The parsed TilbySoapResponse object, or undefined if parsing fails.
	 */
	public static parseResponse(xmlString: string): TilbySoapResponse | undefined {
		try {
			const parsed = XMLNode.stringToObject(xmlString);

			if (parsed[0]) {
				return {
					header: parsed[0].children?.find(node => node.name === 'Header'),
					body: parsed[0].children?.find(node => node.name === 'Body')
				}
			}
		} catch (e) {
			console.log(e);
		}
	}
}