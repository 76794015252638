import {Component} from '@angular/core';

@Component({
    selector: 'app-skeleton-page',
    templateUrl: './skeleton-page.component.html',
    styleUrls: ['./skeleton-page.component.scss'],
    host:{class:'tw-block tw-h-full'}
})
export class SkeletonPageComponent {

}
