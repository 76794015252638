import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { countryCodes, currencies, paymentMethodTypes, uiLanguages } from 'src/app/core/constants';
import { TIPS_DEFAULT_VALUES } from 'src/app/dialogs/cashregister/tip-dialog';
import { EnvironmentConfig } from 'src/environments/environment-config';

angular.module('settings').controller('GeneralSettingsCtrl', GeneralSettingsCtrl);

GeneralSettingsCtrl.$inject = ["$scope", "$rootScope", "$timeout", "$http", "$translate", "restManager", "ImagesManager", "documentPrintersManager", "logoEditor", "preferencesData", "alertDialog", "confirmDialog", "checkManager", "entityManager", "departments", "paymentMethods", "nonFiscalDocuments", "printers", "itemSelector", "taxRegimes", "user", "activationRequestPosDialog", "toast",  "leanPMS", "leanPMSApi", "errorsLogger", "util", "userActiveSession", "signupFormDialog"];

function GeneralSettingsCtrl($scope, $rootScope, $timeout, $http, $translate, restManager, ImagesManager, documentPrintersManager, logoEditor, preferencesData, alertDialog, confirmDialog, checkManager, entityManager, departments, paymentMethods, nonFiscalDocuments, printers, itemSelector, taxRegimes, user, activationRequestPosDialog, toast, leanPMS, leanPMSApi, errorsLogger, util, userActiveSession, signupFormDialog) {
    const integerPreferences = [{
        name: 'cashregister.queue.max_value',
        defaultValue: 99
    }, {
        name: "cashregister.split_sale.default_department_id",
        defaultValue: 0
    }, {
        name: 'customer_display.thankyou_message_timeout',
        defaultValue: 10
    }, {
        name: 'orders.automated_add_cover.value',
        defaultValue: 0
    }, {
        name: 'lean_pms.cash_payment_type_id',
        defaultValue: undefined
    }, {
        name: 'lean_pms.card_payment_type_id',
        defaultValue: undefined
    }, {
        name: 'lean_pms.cash_difference_payment_type_id',
        defaultValue: undefined
    }, {
        name: 'lean_pms.webhooks.hotel_id',
        defaultValue: undefined
    }, {
        name: 'local_server.port',
        defaultValue: 2013
    }, {
        name: "orders.delivery_pricelist",
        defaultValue: undefined
    }, {
        name: "orders.normal_pricelist",
        defaultValue: undefined
    }, {
        name: "orders.take_away_pricelist",
        defaultValue: undefined
    }, {
        name: 'retailforce.qr_code_size',
        defaultValue: 6
    }];

    //Parse some specific preferences
    for(let preference of integerPreferences) {
        preferencesData[preference.name] = !_.isNil(preferencesData[preference.name]) ? _.toInteger(preferencesData[preference.name]) : preference.defaultValue;
    }

    for(let type of ['normal', 'take_away', 'delivery']) {
        preferencesData[`orders.${type}_pricelist`] = preferencesData[`orders.${type}_pricelist`] || 0;
    }

    if(preferencesData['cashregister.queue.print_type']) {
        try {
            preferencesData['cashregister.queue.print_type'] = JSON.parse(preferencesData['cashregister.queue.print_type']);
        } catch(e) {
            preferencesData['cashregister.queue.print_type'] = null;
        }
    }

    // Parse opt-out preferences
    const optOutPreferences = [
        'cashregister.add_item_on_barcode_not_found',
        'cashregister.on_taxcode_not_found_show_add_customer',
        'users.enable_user_logout',
        'users.enable_user_switch'
    ];

    for(const optOutPreference of optOutPreferences) {
        if(preferencesData[optOutPreference] !== false) {
            preferencesData[optOutPreference] = true;
        }
    }

    // parse customers custom types
    $scope.customers_custom_types = preferencesData['customers.custom_types'] ? preferencesData['customers.custom_types'].split('\n') : [];

    $scope.resetMaxDefaultExits = () => {
        $scope.defaultExits =  _.range(0, (Number(preferencesData['orders.exits']) + 1) || 11);
    };

    $scope.getDefaulExitLabel = (exit) => {
        return exit ? $translate.instant('SETTINGS.SETTINGS.DEFAULT_EXIT_LABELS.EXIT', { value: exit }) : $translate.instant('SETTINGS.SETTINGS.DEFAULT_EXIT_LABELS.NO_EXIT_LABEL');
    };

    Object.assign($scope, {
        currencies: _.cloneDeep(currencies),
        countries: _.map(countryCodes, function(val, key) { return { code: key, name: val.name }; }),
        departments: departments,
        enableLeanPMSPreferences: false,
        enablePrepaidPreferences: checkManager.isModuleEnabled('prepaid'),
        exits: _.range(0, 11),
        defaultExits: _.range(0, (Number(preferencesData['orders.exits']) + 1) || 11),
        hhmmList: [],
        integerval: /^\d*$/,
        items: [],
        paymentMethodTypes: _(paymentMethodTypes).sortBy('name').cloneDeep(),
        preferencesData: preferencesData,
        preferencesDataReset: _.cloneDeep(preferencesData),
        documentPrinters: printers.filter((printer) => ['fiscal', 'rt', 'receipt'].includes(printer.type)),
        printAttempts: _.range(1, 11),
        paymentClasses: [
            { value: null, name: $translate.instant('PAYMENT_CLASSES.NONE')},
            { value: 'cash', name: $translate.instant('PAYMENT_CLASSES.CASH') },
            { value: 'digital', name: $translate.instant('PAYMENT_CLASSES.DIGITAL') },
            { value: 'ticket', name: $translate.instant('PAYMENT_CLASSES.TICKET') },
            { value: 'prepaid', name: $translate.instant('PAYMENT_CLASSES.PREPAID') },
            { value: 'pms', name: $translate.instant('PAYMENT_CLASSES.PMS') },
        ],
        paymentMethods: _.cloneDeep(paymentMethods),
        qrCodeSizes: _.range(1, 17),
        taxRegimes: _.cloneDeep(taxRegimes),
        timezoneList: _.clone(moment.tz.names()),
        uiLanguages: _(uiLanguages).reject({ ui_hide: true }).cloneDeep(),
        expenseReportDocuments: nonFiscalDocuments.filter((doc) => doc.type === 'expense_report'),
        topbar_context:  {
            tabView: 'general',
            title: $translate.instant('SETTINGS.TOPBAR_SETTINGS.SETTINGS'),
            landscapeMode: false,
            view: 'home',
            menu: {
                enabled: false
            }
        },
        vatValidateStatus: 'default',
        vatRegExp: util.getVatRegexp(),
        bicSwiftRegExp: util.getBicSwiftRegexp(),
        bookingsSlotDurationArray: _.range(15, 241, 15),
        urlRegExp: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        cache_expiration_times: [1, 10, 20, 30],
        isDemo: !!userActiveSession.getSession()?.shop?.name.startsWith('demo_')
    });

    if(!preferencesData['lean_pms.cache_expiration_time']) {
        $scope.preferencesData['lean_pms.cache_expiration_time'] = 30;
    }

    $scope.documentPrinters.unshift({ id: null, name: $translate.instant('SETTINGS.SETTINGS.OVERRIDE_PRINTER_NONE'), type: 'wildcard' });

    try {
        $scope.tip_predefined_values = preferencesData['cashregister.tip.predefined_values'] ? JSON.parse(preferencesData['cashregister.tip.predefined_values']) : TIPS_DEFAULT_VALUES;
    } catch(e) {
        $scope.tip_predefined_values = TIPS_DEFAULT_VALUES;
    }

    $scope.filterOnlyNumber = (value) => {
        //accept only positive percentuals
        value = value.trim();
        return (+value == +value && +value > 0 && +value < 101) ? Math.trunc(value) : null;
    };

    documentPrintersManager.scanPrintersCapabilities($scope.documentPrinters, nonFiscalDocuments, { skipProtocolCheck: true });

    _.forEach($scope.paymentMethodTypes, function(methodType) {
        methodType.int_id = _.toInteger(methodType.id);
    });

    _.forEach(_.range(0,24), function(hour) {
        _.forEach(['00', '30'], function(min) {
            $scope.hhmmList.push({ value: _.padStart(hour, 2, '0') + ':' + min });
        });
    });
    var ignoreDirtyForm = false;

    $scope.listColors = [{
        value: null,
        name: $translate.instant('ITEMS.DETAILS.COLOR_NONE')
    }, {
        value: 'D1C4E9',
        name: $translate.instant('ITEMS.DETAILS.COLOR_PURPLE')
    }, {
        value: 'C5CAE9',
        name: $translate.instant('ITEMS.DETAILS.COLOR_INDIGO')
    }, {
        value: 'B3E5FC',
        name: $translate.instant('ITEMS.DETAILS.COLOR_LIGHT_BLUE')
    }, {
        value: 'B2DFDB',
        name: $translate.instant('ITEMS.DETAILS.COLOR_WATER_GREEN')
    }, {
        value: 'C8E6C9',
        name: $translate.instant('ITEMS.DETAILS.COLOR_GREEN')
    }, {
        value: 'FFECB3',
        name: $translate.instant('ITEMS.DETAILS.COLOR_AMBER')
    }, {
        value: 'FFE0B2',
        name: $translate.instant('ITEMS.DETAILS.COLOR_ORANGE')
    }, {
        value: 'FFCDD2',
        name: $translate.instant('ITEMS.DETAILS.COLOR_RED')
    }];

    $scope.listPaymentConditions = [
        { value: null, name: $translate.instant('SETTINGS.SETTINGS.PAYMENT_CONDITION_NONE') }
    ];

    const getPaymentCondition = async () => {
        let paymentConditions = [];
        try {
            paymentConditions = await restManager.getList('payment_conditions');
        } catch(e) {
            console.error('Error getList payment_conditions: ', e);
        }

        for (let pc of paymentConditions) {
            const temp = { value: pc.id, name: pc.name };
            $scope.listPaymentConditions.push(temp);
        }
    };
    getPaymentCondition();

    $scope.tables_booking_warning = [0, 10, 15, 20, 30, 45, 60, 90, 120].map( minutes => (
        {
            value: minutes,
            label: minutes === 0 ? $translate.instant('SETTINGS.SETTINGS.TABLES_BOOKING_WARNING_LABEL.NONE') : $translate.instant('SETTINGS.SETTINGS.TABLES_BOOKING_WARNING_LABEL.MINUTES', { minutes })
        }
    ));

    const generatePricelistNames = () => {
        $scope.priceLists = [{
            value: 0,
            name: $translate.instant('SETTINGS.SETTINGS.NONE')
        }];

        for(let i = 1; i <= 10; i++) {
            $scope.priceLists.push({
                value: i,
                name: $scope.preferencesData[`price_list_${i}_name`] || $translate.instant('SETTINGS.SETTINGS.PRICE_LIST_DEFAULT_NAME', { value: i })
            });
        }
    };

    generatePricelistNames();

    $scope.getDocumentTypeName = (document) => {
        return documentPrintersManager.getDocumentTypeName(document, 'CASHREGISTER.DOCUMENTS_MANAGER');
    };

    $scope.goToTab = function(id) {
        if (!ignoreDirtyForm && (($scope.preferencesForm && $scope.preferencesForm.$dirty) || ($scope.generalForm && $scope.generalForm.$dirty))) {
            confirmDialog.show($translate.instant('SETTINGS.SETTINGS.WANT_TO_CONTINUE_WITHOUT_SAVE')).then(function(result) {
                if (result) {
                    // set forms to pristine
                    switch ($scope.topbar_context.tabView) {
                        case 'general':
                            $scope.preferencesData = _.cloneDeep($scope.preferencesDataReset);
                            break;
                        case 'preferences':
                            $scope.preferencesData = _.cloneDeep($scope.preferencesDataReset);
                            break;
                        case 'payment_methods':
                            $scope.paymentMethodsForm.$setPristine();
                            break;
                    }
                    ignoreDirtyForm = false;
                    $scope.topbar_context.tabView = id;
                } else {
                    $scope.selectedTabIndex = $scope.tabIndexCopy;
                    ignoreDirtyForm = true;
                    $timeout(function() {
                        ignoreDirtyForm = false;
                    }, 100);
                }
            }, function() {
                //$scope.selectedTabIndex = $scope.tabIndexCopy;
                ignoreDirtyForm = true;
            });
        } else {
            $scope.topbar_context.tabView = id;

            var saveButton = {};

            switch ($scope.topbar_context.tabView) {
                case 'general':
                case 'preferences':
                case 'eInvoice':
                    saveButton = {
                        label: $translate.instant('SETTINGS.SETTINGS.SAVE'),
                        action: $scope.savePreferences,
                        disabled: function() {
                            return false;
                        },
                        visible: function() {
                            return true;
                        }
                    };
                    break;
                case 'payment_methods':
                    // nonfiscal
                    saveButton = {};
                    $scope.topbar_context.menu.enabled = true;
                    $scope.topbar_context.menu.sections = [{
                        name: $translate.instant('SETTINGS.ACTIVATION_REQUEST_POS_DIALOG.MENU'),
                        action: () => {
                            activationRequestPosDialog.show();
                        }
                    }];
                    break;
            }
            $scope.topbar_context.saveButton = saveButton;
            $scope.tabIndexCopy = $scope.selectedTabIndex;
        }
    };

    $scope.openLogoEditor = function() {
        ImagesManager.getImage("logo_small.png")
        .then(function(image){
            return logoEditor.show(image);
        })
        .catch(function(error) {
            return logoEditor.show();
        });
    };

    $scope.compileMerchant = function() {
        var vatCode = preferencesData['e_invoice.merchant.vat_code'];

        $http.get('https://x.scloby.com/fe_to_scloby.php', {
            params: {
                ambiente: user.shop.name,
                piva: vatCode || undefined
            }
        }).then(function(success) {
            var dataToAssign = success.data;

            if(!_.isEmpty(dataToAssign) && !dataToAssign.error) {
                _.assign(preferencesData, {
                    'e_invoice.merchant.tax_code': dataToAssign.azienda_cf,
                    'e_invoice.merchant.company_name': dataToAssign.azienda_denominazione,
                    'e_invoice.merchant.vat_code': dataToAssign.azienda_piva,
                    'e_invoice.merchant.country': 'IT',
                });

                _.assign(preferencesData, {
                    'e_invoice.merchant.head_office.street': dataToAssign.azienda_indirizzo,
                    'e_invoice.merchant.head_office.zip': dataToAssign.azienda_indirizzo_cap,
                    'e_invoice.merchant.head_office.city': dataToAssign.azienda_indirizzo_citta,
                    'e_invoice.merchant.head_office.prov': dataToAssign.azienda_indirizzo_provincia,
                    'e_invoice.merchant.head_office.country': 'IT'
                });
            } else {
                alertDialog.show($translate.instant('SETTINGS.SETTINGS.NO_RESULTS'));
            }
        }, function(error) {
            alertDialog.show($translate.instant('SETTINGS.SETTINGS.NO_INFO_FOUND'));
        });
    };

    const getChangedPreferences = (current, previous) => {
        let changedPrefs = {};

        _.forIn(current, function(val, key) {
            if(!_.isEqual(val, previous[key])) {
                _.set(changedPrefs, [key], val);
            }
        });

        return changedPrefs;
    };

    const onlineFirstPrefs = ['fdem_sync', 'fdem_apikey'];

    $scope.savePreferences = async () => {
        //Uve Check section START
        const uveRequiredFields = ['uve.user', 'uve.password', 'uve.appClientId', 'uve.url', 'uve.idCompany', 'uve.idPagamento'];
        const uveOptionalFields = ['uve.area', 'uve.lotto'];

        if($scope.preferencesData['uve.enabled']) {
            //Check required fields
            for(let field of uveRequiredFields) {
                if(!$scope.preferencesData[field]) {
                    return alertDialog.show($translate.instant('SETTINGS.SETTINGS.ERROR_UVE_FIELDS'));
                }
            }
        } else {
            // Reset all uve fields
            for(let field of [... uveRequiredFields, ...uveOptionalFields]) {
                $scope.preferencesData[field] = $scope.preferencesDataReset?.[field] ?? null;
            }
        }
        //Uve Check section END

        let result = await confirmDialog.show($translate.instant('SETTINGS.SETTINGS.SAVE_CONFIRM'));

        if(!result) {
            return;
        }

        // Delete labelHardcoded from signup_form.fields
        const signup_form_fields_to_save = $scope.signup_form_fields.map(field => {
            delete field.labelHardcoded;
            return field;
        });
        $scope.preferencesData['signup_form.fields'] = JSON.stringify(signup_form_fields_to_save);

        if($scope.preferencesData['cashregister.tip.enable']) {
            $scope.preferencesData['cashregister.tip.predefined_values'] = $scope.tip_predefined_values.length ? JSON.stringify($scope.tip_predefined_values) : null;
        }

        // Assemble customers custom types
        if($scope.customers_custom_types.length > 0) {
            let custom_types = '';
            $scope.customers_custom_types.forEach((type, i, arr) => {
                custom_types += (i === arr.length - 1) ? type : `${type}\n`;
            });
            $scope.preferencesData['customers.custom_types'] = custom_types;
        } else {
            $scope.preferencesData['customers.custom_types'] = null;
        }

        saveFidelityImages();

        //stringify cashregister.quick_coupons_list
        const quick_coupons_list = $scope.quick_coupons_list.filter(c => c.name && c.value && c.department_id).map(c => ({ name: c.name, value: c.value, department_id: c.department_id }));
        preferencesData['cashregister.quick_coupons_list'] = JSON.stringify(quick_coupons_list);

        // delete prefernce when false
        if($scope.lean_pms_webhooks_bus === false) {
            entityManager.shopPreferences.deleteOneOfflineFirst('lean_pms.webhooks.bus');
            delete $scope.preferencesData['lean_pms.webhooks.bus'];
        }

        //Get changed preferences
        let prefsToSave = getChangedPreferences($scope.preferencesData, $scope.preferencesDataReset);

        //Save changed preferences
        for(let key in prefsToSave) {
            let val = prefsToSave[key];

            if(!_.startsWith(key, '_')) {
                let value = _.isObject(val) ? JSON.stringify(val) : val;

                if(onlineFirstPrefs.includes(key)) {
                    try {
                        await checkManager.setShopPreferenceSync(key, value);
                    } catch(err) {
                        checkManager.setShopPreference(key, value);
                    }
                } else {
                    checkManager.setShopPreference(key, value);
                }
            }
        }

        //Prepare 4Dem if the api key has been changed
        if(prefsToSave['fdem_apikey']) {
            try {
                await restManager.getOne('four_dem', 'check');
            } catch(err) {
                //TODO: log warning
            }
        }

        //Update pristine data
        $scope.preferencesDataReset = _.cloneDeep($scope.preferencesData);
        generatePricelistNames();

        //Notify user
        toast.show({ message: $translate.instant('SETTINGS.SETTINGS.SAVE_EDIT_SUCCESSFUL'), hideDelay: 10000 });

        //Check if any preference starting with "local_server" has been changed. If that's the case, ask for an app restart
        if(Object.keys(prefsToSave).some(key => key.startsWith('local_server'))) {
            const answer = await confirmDialog.show($translate.instant('SETTINGS.SETTINGS.RESTART_CONFIRM'));

            if(answer) {
                $rootScope.restartApplication();
            }
        }
        //update lean_pms_webhooks_hotel_id
        $scope.lean_pms_webhooks_hotel_id = $scope.preferencesData['lean_pms.webhooks.hotel_id'];
    };

    $scope.refeshItemsCache = function() {
        restManager.getOne("items", "refresh_cache").then(function(result) {
            alertDialog.show($translate.instant('SETTINGS.SETTINGS.CACHE_GENERATION_REQUEST_SENT'));
        }, function(error) {
            alertDialog.show($translate.instant('SETTINGS.SETTINGS.CACHE_GENERATION_REQUEST_ERROR'));
        });
    };

    $scope.forceFourDemSync = function() {
        restManager.getOne("customers", "sync_4dem").then(function(result) {
            alertDialog.show($translate.instant('SETTINGS.SETTINGS.SYNC_REQUEST_SENT'));
        }, function(error) {
            alertDialog.show($translate.instant('SETTINGS.SETTINGS.SYNC_REQUEST_ERROR'));
        });
    };

    //Covers Section
    entityManager.items.fetchCollectionOffline().then(function(items) {
        $scope.items = _.sortBy(items, 'name');
    });

    if ($scope.preferencesData['orders.automated_add_cover.type'] === 'id') {
        entityManager.items.fetchOneOffline($scope.preferencesData['orders.automated_add_cover.value']).then(function(item) {
            if (item) {
                $scope.preferencesData._coverItemName = item.name;
            }
        });
    }

    $scope.onCoverTypeChange = function() {
        delete $scope.preferencesData['orders.automated_add_cover.value'];
        delete $scope.preferencesData._coverItemName;
    };

    $scope.selectCoverItem = function() {
        itemSelector.show($scope.items).then(function(item) {
            if (item) {
                $scope.preferencesData._coverItemName = item.name;
                $scope.preferencesData['orders.automated_add_cover.value'] = item.id;
            }
        });
    };

    // Payment methods START
    $scope.paymentConfigurationFields = {
        37: [{
                id: "merchantId",
                name: "SETTINGS.PAYMENT_METHODS.REDSYS.MERCHANT_ID"
            }, {
                id: "terminalId",
                name: "SETTINGS.PAYMENT_METHODS.REDSYS.TERMINAL_ID"
            }, {
                id: "signature",
                name: "SETTINGS.PAYMENT_METHODS.REDSYS.SIGNATURE"
            }, {
                id: "port",
                name: "SETTINGS.PAYMENT_METHODS.REDSYS.PORT"
            }, {
                id: "version",
                name: "SETTINGS.PAYMENT_METHODS.REDSYS.VERSION"
            }
        ]
    };

    var pristinePaymentMethodsById;
    var paymentMethodTypesById;
    var paymentMethodsById;

    const updatePaymentMethodsIndexes = () => {
        pristinePaymentMethodsById = _.keyBy(paymentMethods, 'id');
        paymentMethodTypesById = _.keyBy($scope.paymentMethodTypes, 'id');
        paymentMethodsById = _.keyBy($scope.paymentMethods, 'id');
    };

    const updatePaymentRelatedPreferences = () =>  {
        $scope.enableLeanPMSPreferences = false;
        $scope.enableZucchettiPMSPreferences = false;

        for(const paymentMethod of $scope.paymentMethods) {
            switch(paymentMethod.payment_method_type_id) {
                case 22: case 23: case 36:
                    $scope.enableZucchettiPMSPreferences = true;
                break;
                case 28:
                    $scope.enableLeanPMSPreferences = true;
                break;
            }
        }
    };

    updatePaymentMethodsIndexes();
    updatePaymentRelatedPreferences();

    $scope.switchPaymentMethodEdit = async (paymentMethod) => {
        if(!paymentMethod._show) {
            paymentMethod._show = true;

            paymentMethod._overridePrinter = $scope.documentPrinters.find(printer => (printer.id == paymentMethod.printer_id));

            if(paymentMethod._overridePrinter && paymentMethod.document_type_id) {
                paymentMethod._overrideDocument = paymentMethod._overridePrinter.availableDocuments.find(document => (document.document_template?.id === _.toInteger(paymentMethod.document_type_id)) || (document.document_type?.id === paymentMethod.document_type_id));
            } else {
                paymentMethod._overrideDocument = null;
            }
        } else if(paymentMethod.form.$dirty) {
            let result = await confirmDialog.show($translate.instant('SETTINGS.SETTINGS.WANT_TO_CONTINUE_PAYMENT'));

            if (result) {
                angular.copy(pristinePaymentMethodsById[paymentMethod.id], paymentMethod);
            }
        } else {
            delete paymentMethod._show;
        }
    };

    $scope.getPaymentMethodTypeNameFromId = (id) => paymentMethodTypesById[id]?.name || '';

    $scope.getPaymentMethodTypesIcon = (id) => {
        switch(id) {
            case 1: case 19: case 21: case 32: case 38: case 40:
                return 'local_atm';
            case 2: case 6:
                return 'confirmation_number';
            case 8:
                return 'account_balance';
            case 3:
                return 'picture_in_picture';
            case 9: case 10:
                return 'card_giftcard';
            case 3:
                return 'local_atm';
            default:
                return 'credit_card';
        }
    };

    $scope.returnErrorElement = (id) => paymentMethodsById[id].form.$error;

    $scope.savePaymentMethod = async (paymentMethod) => {
        const saveFunction = paymentMethod._new ? 'postOneOnline' : 'putOneOnline';

        let paymentToSave = _.chain(paymentMethod)
            .assign({
                printer_id: paymentMethod._overridePrinter?.id || null,
                document_type_id: paymentMethod._overrideDocument?.document_template?.id || paymentMethod._overrideDocument?.document_type?.id || null
            })
            .omit(['form', '_new', '_show', '$hashKey', '_overridePrinter', '_overrideDocument'])
            .cloneDeep()
            .value();

        let answer = await entityManager.paymentMethods[saveFunction](paymentToSave);

        angular.copy(answer, paymentMethod);
        reloadPaymentMethodsAll();
    };

    $scope.removePaymentMethod = function(paymentMethod) {
        if ((paymentMethod._new)) {
            _.remove($scope.paymentMethods, { _new: true });
            updatePaymentMethodsIndexes();
        } else {
            entityManager.paymentMethods.deleteOneOnline(paymentMethod.id).then(function() {
                _.remove($scope.paymentMethods, { id: paymentMethod.id });
                updatePaymentMethodsIndexes();
            });
        }
    };

    var determineMinAvailableId = function() {
        var currentIds = _(paymentMethodsById).map('id').value();
        var candidateIds = _.range(1, _.max(currentIds) + 2);

        return _.find(candidateIds, function(id) {
            return _.isNil(paymentMethodsById[id]);
        });
    };

    $scope.addNewPaymentMethod = function() {
        var foundNewNotCompleted = _.find($scope.paymentMethods, { _new: true });

        if (foundNewNotCompleted) {
            foundNewNotCompleted._show = true;
            return;
        }

        $scope.paymentMethods.push({
            id: determineMinAvailableId(),
            unclaimed: false,
            hidden: false,
            color: null,
            require_signature: false,
            configuration: {},
            _show: true,
            _new: true,
        });

        updatePaymentMethodsIndexes();
    };

    const reloadPaymentMethodsAll = async () => {
        try {
            let newPaymentMethods = await entityManager.paymentMethods.fetchCollectionOffline();
            angular.copy(newPaymentMethods, paymentMethods);

            updatePaymentMethodsIndexes();
            updatePaymentRelatedPreferences();
        } catch(e) {}

        alertDialog.show($translate.instant('SETTINGS.SETTINGS.EDIT_OF_METHOD_SUCCESSFULL'));
    };
    // Payments method END

    $scope.goToTab('general');

    let cashTransactionsTypesLeanPMS = [];

    const fetchCashTransactionsTypes = async() => {
        if(!leanPMS.isEnabled()) {
            return;
        }

        try {
            const data = await leanPMSApi.getCashTransactionsTypes();
            cashTransactionsTypesLeanPMS = data;
        } catch(error) {
            errorsLogger.err(error);
        }
    };

    fetchCashTransactionsTypes();

    let lean_pms_advanced_payment_mapping = [];

    const initLeanAdvancedPaymentMapping = () => {
        $scope.advanced_payment_mapping = [];
        $scope.paymentMethods.filter((pm) => pm.payment_method_type_id !== 28).forEach((pm) => $scope.advanced_payment_mapping[pm.id] = null);

        try {
            lean_pms_advanced_payment_mapping = JSON.parse($scope.preferencesData['lean_pms.advanced_payment_mapping']);
        } catch (err) {
            lean_pms_advanced_payment_mapping = [];
        }

        if (!Array.isArray(lean_pms_advanced_payment_mapping)) {
            lean_pms_advanced_payment_mapping = [];
        }

        lean_pms_advanced_payment_mapping.forEach((obj) => {
            if (obj && obj.tilby in $scope.advanced_payment_mapping) {
                $scope.advanced_payment_mapping[obj.tilby] = Number(obj.lean);
            }
        });
    };

    initLeanAdvancedPaymentMapping();

    $scope.selectCashTransactionsTypesLeanPMSbyIndex = (index) => {
        itemSelector.show(cashTransactionsTypesLeanPMS, true).then(function(selectedItem) {
            $scope.advanced_payment_mapping[index] = selectedItem.id;
            const foundByIndex = lean_pms_advanced_payment_mapping.find((obj) => obj.tilby === index);
            if (foundByIndex) {
                foundByIndex.lean = selectedItem.id;
            } else {
                const tempObj = { tilby: index, lean: selectedItem.id };
                lean_pms_advanced_payment_mapping.push(tempObj);
            }

            $scope.preferencesData['lean_pms.advanced_payment_mapping'] = JSON.stringify(lean_pms_advanced_payment_mapping);
        });
    };

    $scope.selectCashTransactionsTypesLeanPMS = (option) => {
        itemSelector.show(cashTransactionsTypesLeanPMS, true).then(function(selectedItem) {
            $scope.preferencesData[option] = selectedItem.id;
        });
    };

    $scope.updateCashTransactionsTypesLeanPMS = (index) => {
        if ($scope.advanced_payment_mapping[index] === null) {
            // remove object
            const objToRemoveIndex = lean_pms_advanced_payment_mapping.findIndex((obj) => obj.tilby === index);
            if (objToRemoveIndex > -1) {
                lean_pms_advanced_payment_mapping.splice(objToRemoveIndex, 1);
            }
        } else {
            const foundByIndex = lean_pms_advanced_payment_mapping.find((obj) => obj.tilby === index);

            if (foundByIndex) {
                foundByIndex.lean = $scope.advanced_payment_mapping[index];
            } else {
                const tempObj = { tilby: index, lean: $scope.advanced_payment_mapping[index] };
                lean_pms_advanced_payment_mapping.push(tempObj);
            }
        }

        $scope.preferencesData['lean_pms.advanced_payment_mapping'] = JSON.stringify(lean_pms_advanced_payment_mapping);
    };

    $scope.lean_pms_webhooks_hotel_id = $scope.preferencesData['lean_pms.webhooks.hotel_id'];
    $scope.reattachWebhook = async () => {
        if(!leanPMS.isEnabled() || $scope.preferencesData['lean_pms.webhooks.hotel_id'] === null) {
            errorsLogger.err("reattachWebhook: not Permission");
            return;
        }

        try {
            const data = await leanPMSApi.setupWebhooks();
            //console.log('data', data);
            if(data) {
                toast.show({ message: $translate.instant('SETTINGS.LEAN_PMS.REATTACH_WEBHOOK_COMPLETE'), hideDelay: 10000 });
            } else {
                toast.show({ message: $translate.instant('SETTINGS.LEAN_PMS.REATTACH_WEBHOOK_FAILED'), hideDelay: 10000 });
            }
        } catch(error) {
            errorsLogger.err(error);
            if(error.data.type ==="not_found") {
                toast.show({ message: $translate.instant('SETTINGS.LEAN_PMS.REATTACH_WEBHOOK_ERROR'), hideDelay: 10000 });
            } else {
                toast.show({ message: $translate.instant('SETTINGS.LEAN_PMS.REATTACH_WEBHOOK_FAILED'), hideDelay: 10000 });
            }
        }
    };

    $scope.public_receipt_duration_array = [
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.NONE', value: 0 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.1_DAY', value: 24 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.2_DAYS', value: 48 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.3_DAYS', value: 72 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.1_WEEK', value: 168 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.1_MONTH', value: 720 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.3_MONTHS', value: 2160 },
        { label: 'SETTINGS.SETTINGS.PUBLIC_RECEIPT_DURATION.1_YEAR', value: 8640 }
    ];

    //init fidelity color: preferencesData['digital_card.primary_color']
    preferencesData['digital_card.primary_color'] = preferencesData['digital_card.primary_color'] || '#000000';

    //upload image fidelity
    Object.assign($scope, {
        fidelity_imgLogo: preferencesData['digital_card.logo_url'],
        fidelity_thumbnail: null,
        fidelity_itemThumbnail: null,
        fidelity_itemImages: [],
        fidelity_imagesCallbacks: [],
        fidelity_imagesDirty: false,
        fidelity_hasImage: function() {
            return _(this.fidelity_itemImages).reject({ deleted: true }).size() >= 1;
        }
    });

    if ($scope.fidelity_imgLogo) {
        const img = { image_url: $scope.fidelity_imgLogo, deleted: false };
        $scope.fidelity_itemImages.push(img);
        $scope.fidelity_itemThumbnail = img;
    }

    const saveFidelityImages = async () => {
        const imgPromises = $scope.fidelity_imagesCallbacks.map((callback) => callback());

        try {
            await Promise.all(imgPromises);
        } catch (e) {
            try {
                await alertDialog.show($translate.instant('ITEMS.DETAILS.IMAGES_SAVE_HAS_ERRORS'));
            } catch (err) {
            }
        } finally {
            if($scope.fidelity_itemImages[0]) {
                $scope.fidelity_imgLogo = $scope.fidelity_itemImages[0].deleted ? null : $scope.fidelity_itemImages[0].image_url;
            } else {
                $scope.fidelity_imgLogo = null;
            }
            checkManager.setShopPreference('digital_card.logo_url', $scope.fidelity_imgLogo);
        }
    };

    $scope.quick_coupons_list = preferencesData['cashregister.quick_coupons_list'] ? JSON.parse(preferencesData['cashregister.quick_coupons_list']) : [];

    $scope.disconnectLean = async () => {
        const confirm = await confirmDialog.show($translate.instant('SETTINGS.LEAN_PMS.DISCONNECT_LEAN_CONFIRMATION'));

        if(confirm) {
            try {
                await leanPMSApi.disconnect();
                toast.show({ message: $translate.instant('SETTINGS.LEAN_PMS.DISCONNECT_LEAN_COMPLETE'), hideDelay: 10000 });
            } catch(error) {
                toast.show({ message: $translate.instant('SETTINGS.LEAN_PMS.DISCONNECT_LEAN_FAILED'), hideDelay: 10000 });
            }
        }
    };

    if($scope.preferencesData['lean_pms.webhooks.bus'] === 'pre-bus') {
        $scope.lean_pms_webhooks_bus = true;
    } else {
        $scope.lean_pms_webhooks_bus = false;
    }

    $scope.toggleLean_pms_webhooks_bus = (lean_pms_webhooks_bus) => {
        $scope.lean_pms_webhooks_bus = lean_pms_webhooks_bus;
        if(lean_pms_webhooks_bus) {
            // attiva
            $scope.preferencesData['lean_pms.webhooks.bus'] = 'pre-bus';
        } else {
            // disattiva
            $scope.preferencesData['lean_pms.webhooks.bus'] = undefined;
        }
    };

    $scope.initAutoClose = async () => {
        try {
            await restManager.getOne('importing/retail_force');
            toast.show({ message: $translate.instant('SETTINGS.RETAILFORCE.INIT_AUTO_CLOSE_SUCCESS'), hideDelay: 10000 });
        } catch(e) {
            toast.show({ message: $translate.instant('SETTINGS.RETAILFORCE.INIT_AUTO_CLOSE_FAILED'), hideDelay: 10000 });
        }
    };

    $scope.fields = [
        {
            name: 'first_name',
            label: 'CUSTOMERS.SHOWCASE.FIRST_NAME'
        },
        {
            name: 'last_name',
            label: 'CUSTOMERS.SHOWCASE.LAST_NAME'
        },
        {
            name: 'company_name',
            label: 'CUSTOMERS.SHOWCASE.COMPANY_NAME'
        },
        {
            name: 'gender',
            label: 'CUSTOMERS.SHOWCASE.GENDER'
        },
        {
            name: 'birthdate',
            label: 'CUSTOMERS.SHOWCASE.BIRTHDATE'
        },
        {
            name: 'vat_code',
            label: 'CUSTOMERS.SHOWCASE.VAT_CODE'
        },
        {
            name: 'tax_code',
            label: 'CUSTOMERS.SHOWCASE.TAX_CODE'
        },
        {
            name: 'billing_city',
            label: 'CUSTOMERS.SHOWCASE.BILLING_CITY'
        },
        {
            name: 'billing_country',
            label: 'CUSTOMERS.SHOWCASE.BILLING_COUNTRY'
        },
        {
            name: 'billing_prov',
            label: 'CUSTOMERS.SHOWCASE.BILLING_PROV'
        },
        {
            name: 'billing_street',
            label: 'CUSTOMERS.SHOWCASE.BILLING_STREET'
        },
        {
            name: 'billing_zip',
            label: 'CUSTOMERS.SHOWCASE.BILLING_ZIP'
        },
        {
            name: 'sdi_code',
            label: 'CUSTOMERS.SHOWCASE.SDI_CODE'
        },
        {
            name: 'mobile',
            label: 'CUSTOMERS.SHOWCASE.MOBILE'
        },
        {
            name: 'phone',
            label: 'CUSTOMERS.SHOWCASE.PHONE'
        },
        {
            name: 'email',
            label: 'CUSTOMERS.SHOWCASE.EMAIL'
        },
        {
            name: 'email_pec',
            label: 'CUSTOMERS.SHOWCASE.EMAIL_PEC'
        },
        {
            name: 'external_id',
            label: 'CUSTOMERS.SHOWCASE.EXTERNAL_ID'
        },
        {
            name: 'facebook',
            label: 'CUSTOMERS.SHOWCASE.FACEBOOK'
        },
        {
            name: 'instagram',
            label: 'CUSTOMERS.SHOWCASE.INSTAGRAM'
        },
        {
            name: 'linkedin',
            label: 'CUSTOMERS.SHOWCASE.LINKEDIN'
        },
        {
            name: 'twitter',
            label: 'CUSTOMERS.SHOWCASE.TWITTER'
        },
        {
            name: 'shipping_city',
            label: 'CUSTOMERS.SHOWCASE.SHIPPING_CITY'
        },
        {
            name: 'shipping_country',
            label: 'CUSTOMERS.SHOWCASE.SHIPPING_COUNTRY'
        },
        {
            name: 'shipping_prov',
            label: 'CUSTOMERS.SHOWCASE.SHIPPING_PROV'
        },
        {
            name: 'shipping_street',
            label: 'CUSTOMERS.SHOWCASE.SHIPPING_STREET'
        },
        {
            name: 'shipping_zip',
            label: 'CUSTOMERS.SHOWCASE.SHIPPING_ZIP'
        },
        {
            name: 'custom_1',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_1'
        },
        {
            name: 'custom_2',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_2'
        },
        {
            name: 'custom_3',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_3'
        },
        {
            name: 'custom_4',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_4'
        },
        {
            name: 'custom_5',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_5'
        },
        {
            name: 'custom_6',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_6'
        },
        {
            name: 'custom_7',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_7'
        },
        {
            name: 'custom_8',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_8'
        },
        {
            name: 'custom_9',
            label: 'CUSTOMERS.SHOWCASE.CUSTOM_9'
        }
    ];

    const initializeSignupForm = () => {
        const fieldsByName = new Map($scope.fields.map((f) => [f.name, f]));

        try {
            $scope.signup_form_fields = $scope.preferencesData['signup_form.fields'] ? JSON.parse($scope.preferencesData['signup_form.fields']) : [];

            // add label for customer fields
            for (const formField of $scope.signup_form_fields) {
                const field = fieldsByName.get(formField.name);

                if (field) {
                    formField.labelHardcoded = $translate.instant(field.label);
                }
            }
        } catch (e) {
            $scope.signup_form_fields = [];
        }
    }

    initializeSignupForm();

    $scope.openSignupFormModal = async () => {
        $scope.signup_form_fields = Array.isArray($scope.signup_form_fields) ? $scope.signup_form_fields : [];

        const currentFields = new Set($scope.signup_form_fields.map((f) => f.name));
        const signupFormFields = $scope.fields.filter((field) => !currentFields.has(field.name));
        const opt = { data: { title: 'DIALOG.SIGNUP_FORM_DIALOG.TITLE', signupFormFields } };
        const res = await signupFormDialog.openDialog(opt);

        if (res) {
            for (const field of res.signupFormFields) {
                $scope.signup_form_fields.push(field);
            }
        }
    };

    $scope.moveUpSignupFormField = (index) => {
        index += 0;
        if (index > 0) {
            const field = $scope.signup_form_fields[index];
            $scope.signup_form_fields[index] = $scope.signup_form_fields[index - 1];
            $scope.signup_form_fields[index - 1] = field;
        }
    };
    $scope.moveDownSignupFormField = (index) => {
        index += 0;
        if (index < $scope.signup_form_fields.length - 1) {
            const field = $scope.signup_form_fields[index];
            $scope.signup_form_fields[index] = $scope.signup_form_fields[index + 1];
            $scope.signup_form_fields[index + 1] = field;
        }
    };

    $scope.removeSignupFormField = (index) => {
        index += 0;
        $scope.signup_form_fields.splice(index, 1);
    };

    $scope.openSignupFormPreview = () => {
        const shop = $rootScope.userActiveSession.shop.name;
        const prefix = ['beta'].includes(EnvironmentConfig.environment) ? `https://forms.beta` : `https://forms`;
        const url = `${prefix}.tilby.com/registration?shop=${shop}`;
        util.openExternalLink(url);
    };
}
