<tilby-dialog-toolbar (confirm)="export()" title="DIALOG.GRID_DOWNLOAD.TITLE" confirmLabel="DIALOG.GRID_DOWNLOAD.ACTION.EXPORT" [disabled]="isAllColumnsUnchecked() || !rowCount"/>
<tilby-dialog-content class="tw-min-w-[300px] tw-max-h-[50vh] tw-flex tw-flex-col tw-gap-4 tw-pt-2 tw-py-0">
    <div class="tw-flex tw-items-center">
        <div translate="DIALOG.GRID_DOWNLOAD.PREVIEW"></div>
        @if(!rowCount && rowCount!==0){
            <mat-spinner color="primary tw-h-4 tw-w-12"/>
        }
        @else {
            <div class="tw-pl-1" translate="DIALOG.GRID_DOWNLOAD.ROWS" [translateParams]="{rowCount:rowCount}"></div>
        }
    </div>
    <div class="tw-h-[55px]">
        <mat-form-field>
            <mat-label>{{ 'DIALOG.GRID_DOWNLOAD.SELECT.TITLE' | translate}}</mat-label>
            <select matNativeControl (change)="data.typeExport = $any($event.target).value">
                <option [value]="'csv'" selected>CSV</option>
                <option [value]="'excel'">EXCEL</option>
            </select>
        </mat-form-field>
    </div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-px-0">
        <div>
            <div (click)="openColumnsAction()" class="tw-mb-4">
                @if(openColumns){<mat-icon>expand_less</mat-icon>}
                @else{<mat-icon>expand_more</mat-icon>}
                <span class="tw-align-super tw-ml-1">Colonne</span>
            </div>
            @if(openColumns){
                <div class="tw-mt-3">
                    <ul class="tw-p-0 tw-mt-0">
                        <div class="tw-flex tw-justify-around tw-mb-3">
                            <button class="tw-mr-4" mat-stroked-button color="primary" (click)="setAllCheckbox(false)">
                                <span translate>DIALOG.GRID_DOWNLOAD.ACTION.UNSELECT_ALL</span>
                            </button>
                            <button mat-stroked-button color="primary" (click)="setAllCheckbox(true)">
                                <span translate>DIALOG.GRID_DOWNLOAD.ACTION.SELECT_ALL</span>
                            </button>
                        </div>
                        @for(col of data.column; track $index){
                            <li>
                                @if(hasHeader(col.colField)){
                                    <mat-checkbox color="primary" class="column-list" [(ngModel)]="col.isChecked">
                                    <span>
                                        @if(isHeaderConfiguration(col.colField)){
                                            <span class="tw-whitespace-pre-wrap">{{getHeaderConfiguration(col.colField)}}</span>
                                        }
                                        @else{
                                            <span class="tw-whitespace-pre-wrap">{{((data.headersTranslate.get(col.colField))||'') | translate}}</span>
                                        }
                                    </span>
                                    </mat-checkbox>
                                }
                            </li>
                        }
                    </ul>
                </div>
            }
        </div>
    </div>
</tilby-dialog-content>
