
<div class="giftcard-types-panel-wrapper">
    <div class="tw-flex tw-flex-row content-wrapper">
        <section class="tw-flex tw-flex-col left-section">
            <div class="tw-flex tw-justify-end wrapper-icon">
                <button mat-icon-button aria-label="Add button" (click)="addNew()" [disabled]="isNewEntryInProgress || giftcardTypesForm?.dirty">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="tw-flex tw-flex-col" *ngIf="giftcardTypesList && giftcardTypesList.length > 0">
                <mat-nav-list dense>
                    <mat-list-item *ngFor="let giftcardType of giftcardTypesList" (click)="select(giftcardType, giftcardTypesForm?.dirty)" [activated]="isSelected(giftcardType)">
                        <div matListItemTitle>{{giftcardType.name}}</div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </section>
        <section class="tw-flex tw-flex-col right-section" [ngClass]="{'tw-flex-wrap tw-content-center tw-justify-center' : !selected}">
            <div class="tw-flex tw-justify-end wrapper-icon wrapper-icon-border" *ngIf="selected">
                <button mat-icon-button aria-label="Save" (click)="save()" [disabled]="giftcardTypesForm?.invalid">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete" (click)="delete()" [disabled]="firstEntry">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="tw-flex tw-flex-col wrapper-magic-form" *ngIf="selected && giftcardTypesForm">
                <tilby-magic-form [form]="giftcardTypesForm"></tilby-magic-form>
            </div>
            <div *ngIf="!selected" class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[calc(100vh)]">
                <mat-icon style="transform: scale(7);" class="tw-mb-20">
                    <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
                <div class="tw-pl-10 tw-pr-10 tw-text-center">{{'GIFTCARD.TYPES_PANEL.NOT_SELECTED_MSG'|translate}}</div>
            </div>
        </section>
    </div>
</div>
