import * as angular from 'angular';
import * as _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';
import {Channels, Departments, Items, Stock } from 'tilby-models';
import {
    ConfigurationManagerService,
    EntityManagerService
} from 'src/app/core';
import {Transition} from "angular-ui-router";
import {
    CashregisterStateService,
    parseItemsCollection,
    StockDictionaryKey,
    StockFieldToPick
} from 'src/app/features';

angular.module('cashregisterNew', ['application']);

angular.module('cashregisterNew').config(["$stateProvider", "$mdThemingProvider", "$translatePartialLoaderProvider", function($stateProvider:any) {


    $stateProvider.state('app.new.cashregister', {
        url: '/cashregister',
        redirectTo: 'app.new.cashregister.content.showcase',
        template: '<cashregister-new-component [items]="$resolve.items" [stock-dictionary]="$resolve.stockDictionary" [categories]="$resolve.categories" [departments]="$resolve.departments" [delivery-channels]="$resolve.deliveryChannels"></cashregister-new-component>',
        resolve: {
            items: ["entityManager", function(entityManager:EntityManagerService) {
                return entityManager.items.fetchCollectionOffline({on_sale: true}).then(function(items:Items[]) {
                    return parseItemsCollection(items);
                });
            }],
            stockDictionary: ["entityManager", async (entityManager:EntityManagerService) => {
                const result = new SclDictionary<StockDictionaryKey,Pick<Stock, StockFieldToPick>>();
                const fields:StockFieldToPick[] = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];
                const stockCollection = await entityManager.stock.fetchCollectionOffline() as Stock[];

                for(let stock of stockCollection) {
                    if (stock.combination_id) {
                        result.set(`combination_id_${stock.combination_id}`, _.pick(stock, fields));
                    } else if (stock.item_id) {
                        result.set(`item_id_${stock.item_id}`, _.pick(stock, fields));
                    }
                }

                return result;
            }],
            categories: ["entityManager", function(entityManager:EntityManagerService) {
                return entityManager.categories.fetchCollectionOffline();
            }],
            departments: ["entityManager", function(entityManager:EntityManagerService) {
                return entityManager.departments.fetchCollectionOffline();
            }],
            deliveryChannels: ["entityManager", function(entityManager:EntityManagerService) {
                return entityManager.channels.fetchCollectionOffline().then(function(channels:Channels[]) {
                    return _.reject(channels, { id: 'pos' });
                });
            }]
        },
        controller: ["$scope", "checkManager", "entityManager", "items", "departments", "stockDictionary", function($scope:any, checkManager:ConfigurationManagerService, entityManager:EntityManagerService, items: _.Dictionary<Items[]>, departments:Departments[], stockDictionary:any) {
            $scope.$on("storage-updated:departments", async (_event:unknown, _data:any) => {
                const newDepartments = await entityManager.departments.fetchCollectionOffline();
                angular.copy(newDepartments, departments);

                $scope.$broadcast("cashregister-showcase:update-departments");
            });

            $scope.$on("storage-updated:items", function(_event:unknown, data:{action:string, id:number}&any) {
                if (!data.id) {
                    if(data.action !== 'DELETED') { //DELETED happens when reloading the items, so wait for the UPDATED event instead
                        entityManager.items.fetchCollectionOffline({ on_sale: true }).then((result:Items[])=> {
                            _.forIn(items, function(val, key) { delete items[key]; });
                            _.assign(items, parseItemsCollection(result));

                            $scope.$broadcast("cashregister-showcase:update-items");
                        });
                    }
                } else {
                    switch (data.action) {
                        case 'UPDATED': case 'CREATED':
                            entityManager.items.fetchOneOffline(data.id).then((src)=> {
                                if (src) {
                                    if (!src.favorite) {
                                        _.remove(items.favorite, {
                                            id: src.id
                                        });
                                    }

                                    const catToScan = _.clone(items);
                                    delete catToScan.favorite;

                                    let dst;
                                    _.find(catToScan, function (itemsInCategory:Items[], categoryId:string) {
                                        const srcCatId = src.category_id ? src.category_id.toString() : "null";

                                        if (srcCatId !== categoryId) {
                                            _.remove(itemsInCategory, { id: src.id });
                                            return false;
                                        }

                                        const foundInCategory = _.find(itemsInCategory, { id: data.id });

                                        if (foundInCategory) {
                                            dst = foundInCategory;
                                        } else {
                                            if (srcCatId === categoryId) {
                                                itemsInCategory.push(src);
                                            }
                                        }
                                        return !!foundInCategory;
                                    });

                                    if (dst) {
                                        if (src !== dst) {
                                            angular.copy(src, dst);
                                        }
                                    }
                                    if (src.favorite) {
                                        const isAlreadyFavorite = _.find(items.favorite, {
                                            id: src.id
                                        });

                                        if (!isAlreadyFavorite) {
                                            items.favorite.push(dst || src);
                                        }
                                    }
                                }
                                $scope.$broadcast("cashregister-showcase:update-items");
                            });
                            break;
                        case 'DELETED':
                            _.each(items, function(itemsInCategory) {
                                _.remove(itemsInCategory, {
                                    id: data.id
                                });
                            });
                            $scope.$broadcast("cashregister-showcase:update-items");
                            break;

                        default:
                            $scope.$broadcast("cashregister-showcase:update-items");
                            break;
                    }
                }
            });

            $scope.$on("stock-updated", function(_event:unknown, stockMessage:Stock) {
                if (!!checkManager.getPreference('cashregister.check_stock')) {
                    let dictIndex;
                    const fields = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];

                    if (stockMessage.combination_id) {
                        dictIndex = 'combination_id_' + stockMessage.combination_id;
                    } else if (stockMessage.item_id) {
                        dictIndex = 'item_id_' + stockMessage.item_id;
                    } else {
                        return;
                    }

                    const prevStock = stockDictionary.get(dictIndex);
                    stockDictionary.set(dictIndex, _.pick(stockMessage, fields));

                    if (_.get(prevStock, 'available') !== stockMessage.available) {
                        CashregisterStateService.notifyStockSubject.next(stockMessage);
                    }
                }
                $scope.$broadcast('activeSale:updateSaleStock');
            });
        }],
        params: {
            action: { type: 'string', value: null, dynamic: true },
            advancedpayments: { type: 'bool', value: false, dynamic: true },
            autohide: { type: 'bool', value: false,  dynamic: true },
            from: null,
            id: null,
            saleType: null,
            room: null,
            table: null,
            booking: null,
            customer: null,
            longPress: false
        }
    });

    $stateProvider.state('app.new.cashregister.sale', {
        url: '/sale/:id?advancedpayments&autohide',
        params: {
            id: { type: 'int', value: null },
            advancedpayments: { type: 'bool', value: false,  dynamic: true },
            autohide: { type: 'bool', value: false, dynamic: true }
        },
        redirectTo: function(transition:Transition) {
            return transition.router.stateService.target('app.new.cashregister.content.showcase', _.assign({}, _.pick(transition.params(), ['id', 'autohide', 'advancedpayments']), { action: 'open-sale-id' }), { reload: 'app.new.cashregister' });
        }
    });

    $stateProvider.state('app.new.cashregister.daily-closing', {
        url: '/dailyclosing?autohide',
        params: {
            id: { type: 'int', value: null },
            autohide: { type: 'bool', value: false }
        },
        redirectTo: function(transition:Transition) {
            return transition.router.stateService.target('app.new.cashregister.content.showcase', _.assign({}, _.pick(transition.params(), ['autohide']), { action: 'daily-closing' }), { reload: 'app.new.cashregister' });
        }
    });

    $stateProvider.state('app.new.cashregister.content', {
        abstract: true,
        views: {
            "two": {
                template: '<active-sale-component/>',
            }
        },

    });

    $stateProvider.state('app.new.cashregister.content.showcase', {
        url: '/showcase',
        views: {
            "one@app.new.cashregister": {
                template: '<cashregister-showcase-component/>'
            }
        },
    });

    $stateProvider.state('app.new.cashregister.content.payments', {
        url: '/payments',
        onEnter: ["$state", "ActiveSaleNew", function($state:any, ActiveSale:any) {
            if (!ActiveSale.isActiveSale()) {
                $state.go('app.new.cashregister.content.showcase');
            }
        }],
        views: {
            "one@app.new.cashregister": {
                template: `<cashregister-payments-component [payment-methods]="$resolve.paymentMethods" [tickets-collection]="$resolve.ticketsCollection"/>`
            }
        },
        resolve: {
            paymentMethods: ["entityManager", function(entityManager:EntityManagerService) {
                return entityManager.paymentMethods.fetchCollectionOffline();
            }],
            ticketsCollection: ["entityManager", function(entityManager:EntityManagerService) {
                return entityManager.tickets.fetchCollectionOffline();
            }]
        }
    });
}]);
