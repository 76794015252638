import {
    Component,
    EventEmitter,
    Input,
    Output,
    inject
} from '@angular/core';

import {
    BarcodeManagerService,
    EnvironmentInfoService
} from 'src/app/core';

@Component({
    selector: 'app-barcode-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent {
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);

    @Input() onBarcodeScanned?: string;
    @Input() topbarMode = false;
    @Output() onBarcodeScannedChange = new EventEmitter<string>();
    @Output() topBarModeChange = new EventEmitter();
    mode: 'topbar' | 'normal' = 'normal';
    canUseCameraBarcode = this.environmentInfoService.isCameraBarcodeSupported();

    constructor() {
    }

    ngOnInit(): void {
        this.mode = this.topbarMode ? 'topbar' : 'normal';
    }

    async openCameraBarcode() {
        const barcodeResult = await this.barcodeManagerService.openCameraBarcode();
        this.onBarcodeScannedChange.emit(barcodeResult);
    };

}
