import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('printers').factory('FiscalProviders', fiscalProviders);

fiscalProviders.$inject =  ['$injector', 'checkManager'];

function fiscalProviders($injector, checkManager) {
    const fiscalProvidersMap = {
        retailforce: 'RetailForceProvider'
    };

    const docTypeBlacklist = new Set([
        'expense_report',
    ]);

    const providerDocumentTypes = {
        generic_receipt: 'RECEIPT',
        generic_invoice: 'INVOICE',
        preliminary_receipt: 'PRELIMINARY_RECEIPT'
    };

    const FiscalProviders = {
        getSupportedFiscalProviders: () => _.keys(fiscalProvidersMap),
        getConfiguredFiscalProvider: function() {
            if(checkManager.getSettingUserFirst('retailforce.client_id')) {
                return $injector.get('RetailForceProvider');
            }
        },
        getFiscalProvider: function(providerName) {
            let providerServiceName = fiscalProvidersMap[providerName];

            if(providerServiceName) {
                return $injector.get(providerServiceName);
            }
        },
        getProviderDocumentType: function(docType) {
            if (docTypeBlacklist.has(docType)) {
                return 'DONT_SEND';
            }

            return providerDocumentTypes[docType];
        }
    };

    return FiscalProviders;
}
