<tilby-dialog-toolbar mat-dialog-title
    title="DIALOG.REORDER_DIALOG.TITLE"
    (confirm)="confirm()"
    [disabled]="!confirmEnabled"
/>
<tilby-dialog-content>
    @if (!favoriteSelected) {
        @if (isMobileApp) {
            <div class="list-container" cdkDropList (cdkDropListDropped)="drop($event)">
                @for (item of this.copiedVisibileItems; track item) {
                    <div class="tw-pb-[2px] tw-pr-2">
                        <div class="tw-cursor-pointer tw-border tw-border-solid tw-border-gray-300 tw-rounded-lg tw-flex tw-flex-row tw-h-12 tw-font-medium tw-items-center tw-overflow-hidden">
                            <div class="tw-w-3 tw-h-full tw-flex-shrink-0" [ngStyle]="{ 'background-color': '#' + (item.color || 'BBDEFB') }"></div>
                            <mat-icon cdkDrag class="tw-pl-2">open_with</mat-icon>
                            <div class="tw-flex-auto tw-text-sm tw-ml-2">{{ item.name }}</div>
                            @if (item.thumbnail) {
                                <img class="tw-rounded-md tw-h-10 tw-w-10 tw-object-cover tw-ml-1 tw-pointer-events-none" [src]="item.thumbnail"/>
                            }
                            <div [ngClass]="{'truncate' : !isMobilePortrait()}" class=" tw-text-sm tw-mx-2 tw-text-right tw-flex-shrink-0">{{ $any(item[priceList]) | tilbyCurrency : (!isMobilePortrait() ? undefined : '') }}</div>
                        </div>
                    </div>
                    }
            </div>
        } @else {
            <div class="list-container" cdkDropList (cdkDropListDropped)="drop($event)">
                @for (item of this.copiedVisibileItems; track item) {
                    <div class="tw-pb-[1px] tw-pt-[1px] tw-pr-2">
                        <div class="tw-cursor-pointer tw-border tw-border-solid tw-border-gray-300 tw-rounded-lg tw-flex tw-flex-row tw-h-12 tw-font-medium tw-items-center tw-overflow-hidden" cdkDrag>
                            <div class="tw-w-3 tw-h-full tw-flex-shrink-0" [ngStyle]="{ 'background-color': '#' + (item.color || 'BBDEFB') }"></div>
                            <div class="tw-flex-auto tw-text-sm tw-ml-2">{{ item.name }}</div>
                            @if (item.thumbnail) {
                                <img class="tw-rounded-md tw-h-10 tw-w-10 tw-object-cover tw-ml-1 tw-pointer-events-none" [src]="item.thumbnail"/>
                            }
                            <div [ngClass]="{'truncate' : !isMobilePortrait()}" class=" tw-text-sm tw-mx-2 tw-text-right tw-flex-shrink-0">{{ $any(item[priceList]) | tilbyCurrency : (!isMobilePortrait() ? undefined : '') }}</div>
                        </div>
                    </div>
                    }
            </div>
        }

    } @else {
        <div>{{"DIALOG.REORDER_DIALOG.NO_FAVORITE" | translate}}</div>
    }
</tilby-dialog-content>
