import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('orders').directive('topbarOrders', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-orders.html'),
        controller: topbarOrdersController
    };
});

topbarOrdersController.$inject = ["$scope", "$rootScope", "$mdSidenav", "$state", "$timeout", "$translate", "$mdMedia", "checkManager", "connection", "util", "offlineDialog", "ordersTableView", "leanPMS"];

function topbarOrdersController($scope, $rootScope, $mdSidenav, $state, $timeout, $translate, $mdMedia, checkManager, connection, util, offlineDialog, ordersTableView, leanPMS) {
    const supportedPMSRoomServices = [leanPMS];

    Object.assign($scope, {
        isPriceListUpdateEnabled: checkManager.getPreference("orders.disable_price_list_apply_prompt") ? false : true,
        isSwitchPriceListEnabled: !!checkManager.getPreference('cashregister.enable_switch_price_list'),
        isTablesModuleEnabled: checkManager.isModuleEnabled('tables'),
        PMSRoomService: supportedPMSRoomServices.find((service) => service.isEnabled()),
        priceLists: [],
        useFullMenuBar: $mdMedia('min-width: 800px')
    });

    for(let idx = 1; idx <= 10; idx++) {
        if(!checkManager.getPreference(`price_list_${idx}_hide`)) {
            $scope.priceLists.push({
                index: idx,
                name: checkManager.getPreference(`price_list_${idx}_name`) || $translate.instant('ORDERS.TOPBAR.PRICE_LIST_START') + ' ' + (idx)
            });
        }
    }

    $scope.priceListMenuHide =  _.isEmpty($scope.priceLists);

    $scope.isConnectionOffline = function() {
        return connection.isOffline();
    };

    $scope.openOfflineDialog = function() {
        offlineDialog.show();
    };

    $scope.openLeftMenu = function() {
        $mdSidenav('left').toggle();
    };

    $scope.goTo = function(destination) {
        $mdSidenav('left').close();
        $state.go("app." + destination);
    };

    $scope.goToTables = function() {
        if($scope.isPortrait()) {
            $scope.openOrdersTableView();
        } else {
            if(checkManager.isModuleAngular('tables_and_cashregister')) {
                $scope.goTo('new.tables');
            } else {
                $scope.goTo('tables.rooms-view');
            }
        }
    };

    $scope.openOrdersTableView = function() {
        ordersTableView.show().then(function(result) {
            $scope.context.openTable(result);
        });
    };

    $scope.$on("goToTables", $scope.goToTables);

    $scope.openPMSRoomService = function() {
        if($scope.PMSRoomService) {
            $scope.PMSRoomService.openDialog();
        }
    };

    $scope.viewPhotoGrid = function() {
        if ($scope.context.showcaseMode !== 'photoGrid') {
            $scope.context.showcaseMode = 'photoGrid';
            checkManager.setUserPreference("showcase_mode", 'photoGrid');
        }
    };

    $scope.viewNoPhotoGrid = function() {
        if ($scope.context.showcaseMode !== 'noPhotoGrid') {
            $scope.context.showcaseMode = 'noPhotoGrid';
            checkManager.setUserPreference("showcase_mode", 'noPhotoGrid');
        }
    };

    $scope.viewList = function() {
        if ($scope.context.showcaseMode !== 'list') {
            $scope.context.showcaseMode = 'list';
            checkManager.setUserPreference("showcase_mode", 'list');
        }
    };

    $scope.orderByName = function() {
        $scope.context.order_by = '+name';
        checkManager.setUserPreference("showcase_order_by", '+name');
    };

    $scope.orderByIndex = function() {
        $scope.context.order_by = '+index';
        checkManager.setUserPreference("showcase_order_by", '+index');
    };

    $scope.orderByPriceAsc = function() {
        $scope.context.order_by = '+price';
        checkManager.setUserPreference("showcase_order_by", '+price');
    };

    $scope.orderByPriceDesc = function() {
        $scope.context.order_by = '-price';
        checkManager.setUserPreference("showcase_order_by", '-price');
    };

    $scope.showUnavailableItems = function(show) {
        $scope.context.showUnavailable = show;
        checkManager.setUserPreference("showcase_show_unavailable", show);
    };

    $scope.selectCategoryTabMode = function() {
        if ($scope.context.categoryView !== 'tab') {
            $scope.context.categoryView = 'tab';
            checkManager.setUserPreference("showcase_category_view", 'tab');
        }
    };

    $scope.selectCategoryGridMode = function() {
        if ($scope.context.categoryView !== 'grid') {
            $scope.context.categoryView = 'grid';
            checkManager.setUserPreference("showcase_category_view", 'grid');
        }
    };

    // seleziona la visualizzazione normale o compatta
    $scope.selectDisplayMode = function(mode) {
        switch(mode) {
            case 'normal':
                $scope.context.displayMode = 'normal';
                checkManager.setUserPreference("showcase_display_mode", 'normal');
                break;
            case 'compact':
                $scope.context.displayMode = 'compact';
                checkManager.setUserPreference("showcase_display_mode", 'compact');
                break;
        }
        $rootScope.$broadcast('active-order:updateDisplayMode');
    };

    $scope.showSearch = function() {
        $scope.context.showSearch = true;
        $timeout(function() {
            angular.element('#ordersShowcaseSearch').trigger('focus');
        });
    };

    $scope.goToPriceList = function(priceList) {
        if ($scope.isSwitchPriceListEnabled && priceList !== $scope.context.priceList) {
            $scope.context.priceList = priceList;
            checkManager.setUserPreference("price_list", String(priceList));
            if($scope.isPriceListUpdateEnabled) {
                $rootScope.$broadcast("orders:pricelist-changed");
            }
        }
    };

    $scope.showCashRegisterShortcut = checkManager.getPreference('ui.show_cashregister_shortcut');

    $scope.goToCashRegister = function() {
        $state.go("app.cashregister");
    };

    $scope.$on("orders:reset-pricelist", function(event, data) {
        $scope.context.priceList = util.resetPriceList($scope.context.priceList);
    });

    $scope.$on("orders:use-pricelist", function(event, data) {
        var newPriceList;

        if(data.priceList === 'default') {
            newPriceList = util.getCurrentPriceList();
        } else {
            newPriceList = _.toInteger(data.priceList);
        }

        if(newPriceList !== $scope.context.priceList) {
            $scope.context.priceList = newPriceList;
            if(!data.skipNotify) {
                $rootScope.$broadcast("orders:pricelist-changed");
            }
        }
    });

    $scope.$watch(function() { return $mdMedia('min-width: 800px'); }, function(full) {
        $scope.useFullMenuBar = full;
    });

    $scope.isPortrait = function() {
        return $mdMedia('xs');
    };
}
