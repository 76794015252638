<div class="keyboard-boundary tw-pointer-events-none sm:tw-max-w-xl sm:tw-min-w-[80%]" [class.isMobilePotrait]="isMobilePotrait">
    <div class="tw-shadow-[0_-4px_6px_0_rgba(0,0,0,0.05)] tw-rounded-t-3xl tw-px-4 tw-pb-3 bottom-sheet-bg tw-pointer-events-auto draggable-bottom-sheet-bg" cdkDragBoundary=".keyboard-boundary" cdkDrag (cdkDragEnded)="toggleBottomSheet({dragEndEvent:$event})" cdkDragLockAxis="y" [cdkDragFreeDragPosition]="dragPosition">
        <div class="tw-w-full tw-flex tw-justify-center tw-rounded-t-3xl tw-items-center">
            @if(isAccessibility){
                <div class="tw-flex tw-justify-center tw-gap-x-1 tw-rounded-t-3xl tw-items-center" cdkDragHandle (mousedown)="isDragging=false" (click)="!isDragging&&toggleBottomSheet()">
                    <mat-icon fontIcon="keyboard" class="tw-text-gray-500 tw-rounded-xl tw-cursor-grab active:tw-cursor-grabbing tw-font-materialSymbols"/>
                    <div class="tw-text-gray-500 tw-rounded-xl tw-cursor-grab active:tw-cursor-grabbing">TASTIERA</div>
                </div>
            } @else {
                <mat-icon fontIcon="horizontal_rule" class="tw-text-gray-300 tw-font-semibold tw-rounded-xl tw-cursor-grab active:tw-cursor-grabbing tw-scale-x-150" cdkDragHandle (mousedown)="isDragging=false" (click)="!isDragging&&toggleBottomSheet()"/>
            }
        </div>
       <ng-content/>
    </div>
</div>
