import { 
    Component,
    Injectable,
    inject
} from "@angular/core";

import {
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";

import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { filter, lastValueFrom } from "rxjs";

import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";

import { TranslateModule } from "@ngx-translate/core";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogTabsComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    PendingPrint
} from "src/app/shared/model/cashregister.model";

import {
    EntityManagerService,
    StorageManagerService
} from "src/app/core";

import {
    subscribeInComponent
} from "@tilby/tilby-ui-lib/utilities";

import {
    Printers
} from "tilby-models";

import {
    documentPrinter
} from "app/ajs-upgraded-providers";

import {
    OrderPrinterSelectDialogOutput,
    OrderPrinterSelectDialogService
} from "src/app/dialogs";

import { 
    SalePrintingUtilsService
} from "src/app/features";

type PendingPrintDialog = (PendingPrint & { is_printing?: boolean });

@Component({
    selector: "pending-prints-dialog",
    standalone: true,
    imports: [
        TilbyDialogTabsComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        TilbyDatePipe,
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule
    ],
    templateUrl: "./pending-prints-dialog.component.html",
    styleUrls: ["./pending-prints-dialog.component.scss"],
})
export class PendingPrintsDialogComponent {
    private readonly matDialogRef = inject(MatDialogRef);

    //Injects
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly documentPrinterService = inject(documentPrinter);
    private readonly storageManagerService = inject(StorageManagerService);
    private readonly orderPrinterSelectDialogService = inject(OrderPrinterSelectDialogService);
    private readonly salePrintingUtilsService = inject(SalePrintingUtilsService)
    //Data
    protected pendingPrints: PendingPrintDialog[] = [];

    constructor() {
        this.updatePendingPrints();

        subscribeInComponent(StorageManagerService.storageUpdates$.pipe(
            filter((upd) => upd.entityName === 'pending_prints')
        ), (upd) => this.updatePendingPrints());
    }

    private updatePendingPrints () {
        this.storageManagerService.getCollection('pending_prints').then((res) => this.pendingPrints = res);
    }

    protected async retryPendingPrint (pendingPrint: PendingPrintDialog, printer?: Printers) {
        try {
            pendingPrint.is_printing = true;

            //Remove is_printing from pendingPrint passed to retry function
            const { is_printing, ...pendingPrintData} = pendingPrint;

            await this.salePrintingUtilsService.retryPendingPrint(pendingPrintData, printer);
        } finally {
            pendingPrint.is_printing = false;
        }
    }

    protected deletePendingPrint (pendingPrint: PendingPrintDialog) {
        this.storageManagerService.deleteOne('pending_prints', pendingPrint.id);
    }

    protected async useAlternativePrinter (pendingPrint: PendingPrintDialog) {
        const printers = await this.entityManagerService.printers.fetchCollectionOffline({ type_in: ['nonfiscal', 'receipt'] }).then((printers) => printers.filter(printer => this.documentPrinterService.isPrinterUsable(printer)));
        const dialogOutput: OrderPrinterSelectDialogOutput | undefined  = await this.orderPrinterSelectDialogService.openDialog({ data: { printers, defaultPrinterId: 0 } });
        
        const targetPrinter = dialogOutput?.selectedPrinter;

        if(!targetPrinter) {
            return;
        }

        this.retryPendingPrint(pendingPrint, targetPrinter);
    }

    protected confirm() {
        this.matDialogRef.close();
    }
}

@Injectable({
    providedIn: "root",
})
export class PendingPrintsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog() {
        const config: NonNullableConfigData<any> = {
            ...this.switchMobileDesktopDimensions({ width: "600px" }, { fullScreenForMobile: true }),
            disableClose: true,
            data: {},
        };

        return lastValueFrom(
            this.dialogRef.open(PendingPrintsDialogComponent, config).afterClosed()
        );
    }
}
