<div class="first-section-wrapper">
    <div class="tw-flex tw-gap-x-10 tw-my-5">
        <ngx-skeleton-loader
            appearance="circle"
            [theme]="{ width: '80px', height: '80px', background: 'lightgray' }">
        </ngx-skeleton-loader>
        <div class="tw-grid tw-grid-cols-6 tw-grid-rows-2 tw-gap-x-4 tw-items-center tw-w-1/2">
            <ngx-skeleton-loader class="tw-col-span-5" [theme]="{ height: '40px', background: 'lightgray', margin:0 }"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="tw-col-span-2" [theme]="{ height: '30px', background: 'lightgray', margin:0 }"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="tw-col-span-2" [theme]="{ height: '30px', background: 'lightgray', margin:0 }"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="tw-col-span-2" [theme]="{ height: '30px', background: 'lightgray', margin:0 }"></ngx-skeleton-loader>
        </div>
    </div>
</div>
<div class="second-section-wrapper">
    <div *ngFor="let x of [1,2]" class="wrapper">
        <ngx-skeleton-loader
            [theme]="{
                    width: '90%',
                    'border-radius': '0',
                    height: '15px',
                    'margin-bottom': '10px',
                    background: 'lightgray'
             }">
        </ngx-skeleton-loader>
    </div>
    <div class="wrapper">
        <ngx-skeleton-loader
            [theme]="{
                    width: '60%',
                    'border-radius': '0',
                    height: '15px',
                    'margin-bottom': '10px',
                    background: 'lightgray'
            }">
        </ngx-skeleton-loader>
    </div>
</div>


