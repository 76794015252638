<ng-container>
    <app-customer-prepaid-credit-form
        #appCustomerPrepaidCreditForm
        [customer]="customer"
        [chainShops]="chainShops"
        [credit]="credit||0"
        (submit)="refresh($event)"
    ></app-customer-prepaid-credit-form>
    <app-grid-server-side
        #appGridComponent
        class="tw-block tw-h-[calc(100%-5.5rem)]"
        (rowClicked)="rowClicked($event)"
        [fixedButtons]="fixedButtons"
        [exportFormatterOptions]="exportFormatterOptions"
        [cacheBlockSize]="query.per_page"
        [datasource]="datasource"
        [tableData]="tableData[0]"
        [feature]="'prepaid'"
    ></app-grid-server-side>
</ng-container>
