import * as angular from 'angular';
import * as async from 'async';
import * as _ from 'lodash';

angular.module('printers').factory('eInvoicePlugin', ["$q", "confirmDialog", "restManager", function($q, confirmDialog, restManager) {
    var vatTest = /^(IT)?([0-9]{11})$/;

    var getErrorCode = function(error) {
        if(_.isString(error)) {
            return error;
        } else if(_.get(error, 'status') === -1) {
            return 'E_INVOICE_OFFLINE';
        } else if(_.get(error, ['data', 'error_code'])) {
            return error.data.error_code;
        } else {
            return 'UNKNOWN_ERROR';
        }
    };

    var checkCustomer = function(customer) {
        if(customer) {
            var isItalianCustomer = (customer.country || customer.billing_country || 'IT') === 'IT';
    
            //Customer personal data (1.2.1)
            if((!_.isEmpty(customer.country) && customer.country.length !== 2)) { return 'INVALID_CUSTOMER_COUNTRY'; }
            if(_.isEmpty(customer.vat_code) && _.isEmpty(customer.tax_code)) { return 'MISSING_CUSTOMER_TAX_VAT'; }
            if(!_.isEmpty(customer.vat_code) && (isItalianCustomer && !vatTest.test(customer.vat_code) || (!isItalianCustomer && !_.inRange(customer.vat_code.length, 1, 29)))) { return 'INVALID_CUSTOMER_VAT_CODE'; }
            if(!_.isEmpty(customer.tax_code) && !_.inRange(customer.tax_code.length, 11, 17)) { return 'INVALID_CUSTOMER_TAX_CODE'; }
            if(!_.isEmpty(customer.sdi_code) && ![6, 7].includes(customer.sdi_code.length)) { return 'INVALID_CUSTOMER_SDI_CODE'; }

            if((customer.first_name && !customer.last_name) || (!customer.first_name && !customer.company_name)) {
                return 'INVALID_CUSTOMER_NAME';
            }
        
            //Customer billing address (1.2.2)
            if(!(!_.isEmpty(customer.billing_street) && _.inRange(customer.billing_street.length, 1, 61))) { return 'INVALID_CUSTOMER_BILLING_STREET'; }
            if(!(!_.isEmpty(customer.billing_zip) && ((isItalianCustomer && customer.billing_zip.length === 5) || !isItalianCustomer) )) { return 'INVALID_CUSTOMER_BILLING_ZIP'; }
            if(!(!_.isEmpty(customer.billing_city) && _.inRange(customer.billing_city.length, 1, 61))) { return 'INVALID_CUSTOMER_BILLING_CITY'; }
            if((!_.isEmpty(customer.billing_country) && customer.billing_country.length !== 2)) { return 'INVALID_CUSTOMER_BILLING_COUNTRY'; }
            if((!_.isEmpty(customer.billing_number) && !_.inRange(customer.billing_number.length, 1, 9))) { return 'INVALID_CUSTOMER_BILLING_NUMBER'; }
            if((!_.isEmpty(customer.billing_prov) && customer.billing_prov.length !== 2)) { return 'INVALID_CUSTOMER_BILLING_PROV'; }
        } else {
            return 'CUSTOMER_MISSING';
        }
    };

	var invoicePlugin =  {
		isEnabled: function isEnabled() {
			return true; //We don't need checks here
        },
        isCustomerValid: function(sale_customer) {
            return checkCustomer(sale_customer) ? false : true;
        },
		isPrintable: function isPrintable(sale, printerDocumentData, options) {
            var d = $q.defer();
            
            if(!_.isObject(options)) {
                options = {};
            }
            
            restManager.post('/sales/e_invoice/check', _.cloneDeep(sale)).then(function() {
                var warnings = [];

                //Customer (1.4)
                var customer = sale.sale_customer;
    
                if(!_.isEmpty(customer) && _.isEmpty(customer.sdi_code) && _.isEmpty(customer.email_pec)) {
                    warnings.push("Non hai inserito codice destinatario o PEC per questo Cliente. Se non li conosci puoi comunque emettere la fattura che sarà consegnata nel cassetto fiscale del Cliente sul sito dell’Agenzia delle Entrate");
                }
    
                if(!_.isEmpty(warnings) && !options.skip_warnings) {
                    async.eachSeries(warnings, function(warning, next) {
                        confirmDialog.show(warning + '. Vuoi Procedere?').then(function(answer) {
                            next(answer ? null : true); //Set error flag in callback if answer is not true
                        });
                    }, function(err, results) {
                        if(err) {
                            d.reject('CANCELED');
                        } else {
                            d.resolve();
                        }
                    });
                } else {
                    d.resolve();
                }
            }, function(error) {
                d.reject(getErrorCode(error));
            });

            return d.promise;
		}
	};

	return invoicePlugin;
}]);