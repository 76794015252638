<mat-toolbar color="primary" class="tw-flex tw-flex-row tw-justify-between tw-p-0 sm:tw-p-4">
    <button mat-icon-button (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
    <span class="title tw-flex tw-whitespace-pre-wrap tw-ml-2" translate>{{data.title|| 'Title'}}</span>
    <div class="tw-flex">
        <button mat-icon-button (click)="saveFile()">
            <mat-icon>file_download</mat-icon>
        </button>
    </div>
</mat-toolbar>
<mat-dialog-content class="tw-max-h-[calc(100vh-64px)] sm:tw-max-h-[80vh] tw-w-full sm:tw-w-[60vh]">
    <div class="tw-select-text tw-max-w-screen-sm">
        <ngx-json-viewer [json]="dataObj"></ngx-json-viewer>
    </div>
</mat-dialog-content>

