import { datadogLogs } from '@datadog/browser-logs';
import * as angular from 'angular';
import * as _ from 'lodash';
import { EnvironmentConfig } from 'src/environments/environment-config';

angular.module('core').factory('errorsLogger', errorsLogger);

errorsLogger.$inject = ["$injector", "$rootScope", "environmentInfo"];

function errorsLogger($injector, $rootScope, environmentInfo) {
	const getLogContext = (sessionData) => {
		let logContext = {
			client: EnvironmentConfig.clientId,
			env: EnvironmentConfig.environment
		};

		if(sessionData) {
			Object.assign(logContext, {
				shop: sessionData.shop?.name,
				username: sessionData.username,
			});
		}

		return logContext;
	};

	datadogLogs.setLoggerGlobalContext(getLogContext());

	const errorsLogger = {
		err: (...args) => console.error(...args),
		warn: (...args) => console.warn(...args),
		info: (...args) => console.info(...args),
		debug: (...args) => console.debug(...args),
		sendReport: async(message, options) => {
			if(!_.isObject(options)) {
				options = {};
			}

			let messagePayload;

			try {
				messagePayload = JSON.parse(JSON.stringify(message));
			} catch(error) {
				messagePayload = _.cloneDeep(message);
			}

			Object.assign(messagePayload, environmentInfo.getDeviceMeta(), { timestamp: new Date().getTime() });

			try {
				let netInfo = await environmentInfo.getNetworkInfo();
				Object.assign(messagePayload, netInfo);
			} catch(err) {
				//Nothing to do
			} finally {
				if(options.skipStorage) {
					await $injector.get('restManager').post('application_reports', messagePayload);
				} else {
					await $injector.get('entityManager').applicationReports.postOneOfflineFirst(messagePayload);
				}
			}
		},
		sendAudit: async (type, data) => {
			let reportData = Object.assign({
				type: `audit${_.upperFirst(type)}`,
				user: _.pick($rootScope.userActiveSession, ['id', 'first_name', 'last_name', 'username'])
			}, _.cloneDeep(data || {}));

			return errorsLogger.sendReport(reportData);
		},
		setContext: (sessionData) => {
			datadogLogs.setLoggerGlobalContext(getLogContext(sessionData));
		},
		setupLogCollection: () => {
			let checkManager = $injector.get('checkManager');
			let logToHttp = checkManager.getSetting('logging.enable_log_collection');
			let loggingLevel = checkManager.getSetting('logging.min_logging_level') || (['unstable', 'local', 'beta'].includes(EnvironmentConfig.environment) ? 'debug' : 'info');

			datadogLogs.logger.setHandler(logToHttp ? ['http', 'console'] : 'console');
			datadogLogs.logger.setLevel(loggingLevel);
		}
	};

	return errorsLogger;
}

angular.module('core').factory('$exceptionHandler', exceptionHandler);

exceptionHandler.$inject = [];

function exceptionHandler () {
	return function (exception, cause) {
		try {
			if(exception.line && exception.column) {
				exception.message += ` ${exception.line}:${exception.column}`;
			}
		} catch(err) {
			//Nothing to do
		} finally {
			console.error.apply(console.error, arguments);
		}
	};
}