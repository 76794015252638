export type MobilePrefixType = {
    country: string,
    name: string,
    value: string
}

export const mobilePrefixes: MobilePrefixType[] = [
    {
        "country": "DZ",
        "name": "Algeria (+213)",
        "value": "213"
    },
    {
        "country": "AD",
        "name": "Andorra (+376)",
        "value": "376"
    },
    {
        "country": "AO",
        "name": "Angola (+244)",
        "value": "244"
    },
    {
        "country": "AI",
        "name": "Anguilla (+1264)",
        "value": "1264"
    },
    {
        "country": "AG",
        "name": "Antigua & Barbuda (+1268)",
        "value": "1268"
    },
    {
        "country": "AR",
        "name": "Argentina (+54)",
        "value": "54"
    },
    {
        "country": "AM",
        "name": "Armenia (+374)",
        "value": "374"
    },
    {
        "country": "AW",
        "name": "Aruba (+297)",
        "value": "297"
    },
    {
        "country": "AU",
        "name": "Australia (+61)",
        "value": "61"
    },
    {
        "country": "AT",
        "name": "Austria (+43)",
        "value": "43"
    },
    {
        "country": "AZ",
        "name": "Azerbaijan (+994)",
        "value": "994"
    },
    {
        "country": "BS",
        "name": "Bahamas (+1242)",
        "value": "1242"
    },
    {
        "country": "BH",
        "name": "Bahrain (+973)",
        "value": "973"
    },
    {
        "country": "BD",
        "name": "Bangladesh (+880)",
        "value": "880"
    },
    {
        "country": "BB",
        "name": "Barbados (+1246)",
        "value": "1246"
    },
    {
        "country": "BY",
        "name": "Belarus (+375)",
        "value": "375"
    },
    {
        "country": "BE",
        "name": "Belgium (+32)",
        "value": "32"
    },
    {
        "country": "BZ",
        "name": "Belize (+501)",
        "value": "501"
    },
    {
        "country": "BJ",
        "name": "Benin (+229)",
        "value": "229"
    },
    {
        "country": "BM",
        "name": "Bermuda (+1441)",
        "value": "1441"
    },
    {
        "country": "BT",
        "name": "Bhutan (+975)",
        "value": "975"
    },
    {
        "country": "BO",
        "name": "Bolivia (+591)",
        "value": "591"
    },
    {
        "country": "BA",
        "name": "Bosnia Herzegovina (+387)",
        "value": "387"
    },
    {
        "country": "BW",
        "name": "Botswana (+267)",
        "value": "267"
    },
    {
        "country": "BR",
        "name": "Brazil (+55)",
        "value": "55"
    },
    {
        "country": "BN",
        "name": "Brunei (+673)",
        "value": "673"
    },
    {
        "country": "BG",
        "name": "Bulgaria (+359)",
        "value": "359"
    },
    {
        "country": "BF",
        "name": "Burkina Faso (+226)",
        "value": "226"
    },
    {
        "country": "BI",
        "name": "Burundi (+257)",
        "value": "257"
    },
    {
        "country": "KH",
        "name": "Cambodia (+855)",
        "value": "855"
    },
    {
        "country": "CM",
        "name": "Cameroon (+237)",
        "value": "237"
    },
    {
        "country": "CA",
        "name": "Canada (+1)",
        "value": "1"
    },
    {
        "country": "CV",
        "name": "Cape Verde Islands (+238)",
        "value": "238"
    },
    {
        "country": "KY",
        "name": "Cayman Islands (+1345)",
        "value": "1345"
    },
    {
        "country": "CF",
        "name": "Central African Republic (+236)",
        "value": "236"
    },
    {
        "country": "CL",
        "name": "Chile (+56)",
        "value": "56"
    },
    {
        "country": "CN",
        "name": "China (+86)",
        "value": "86"
    },
    {
        "country": "CO",
        "name": "Colombia (+57)",
        "value": "57"
    },
    {
        "country": "KM",
        "name": "Comoros (+269)",
        "value": "269"
    },
    {
        "country": "CG",
        "name": "Congo (+242)",
        "value": "242"
    },
    {
        "country": "CK",
        "name": "Cook Islands (+682)",
        "value": "682"
    },
    {
        "country": "CR",
        "name": "Costa Rica (+506)",
        "value": "506"
    },
    {
        "country": "HR",
        "name": "Croatia (+385)",
        "value": "385"
    },
    {
        "country": "CU",
        "name": "Cuba (+53)",
        "value": "53"
    },
    {
        "country": "CY",
        "name": "Cyprus North (+90392)",
        "value": "90392"
    },
    {
        "country": "CY",
        "name": "Cyprus South (+357)",
        "value": "357"
    },
    {
        "country": "CZ",
        "name": "Czech Republic (+42)",
        "value": "42"
    },
    {
        "country": "DK",
        "name": "Denmark (+45)",
        "value": "45"
    },
    {
        "country": "DJ",
        "name": "Djibouti (+253)",
        "value": "253"
    },
    {
        "country": "DM",
        "name": "Dominica (+1767)",
        "value": "1767"
    },
    {
        "country": "DO",
        "name": "Dominican Republic (+1809)",
        "value": "1809"
    },
    {
        "country": "EC",
        "name": "Ecuador (+593)",
        "value": "593"
    },
    {
        "country": "EG",
        "name": "Egypt (+20)",
        "value": "20"
    },
    {
        "country": "SV",
        "name": "El Salvador (+503)",
        "value": "503"
    },
    {
        "country": "GQ",
        "name": "Equatorial Guinea (+240)",
        "value": "240"
    },
    {
        "country": "ER",
        "name": "Eritrea (+291)",
        "value": "291"
    },
    {
        "country": "EE",
        "name": "Estonia (+372)",
        "value": "372"
    },
    {
        "country": "ET",
        "name": "Ethiopia (+251)",
        "value": "251"
    },
    {
        "country": "FK",
        "name": "Falkland Islands (+500)",
        "value": "500"
    },
    {
        "country": "FO",
        "name": "Faroe Islands (+298)",
        "value": "298"
    },
    {
        "country": "FJ",
        "name": "Fiji (+679)",
        "value": "679"
    },
    {
        "country": "FI",
        "name": "Finland (+358)",
        "value": "358"
    },
    {
        "country": "FR",
        "name": "France (+33)",
        "value": "33"
    },
    {
        "country": "GF",
        "name": "French Guiana (+594)",
        "value": "594"
    },
    {
        "country": "PF",
        "name": "French Polynesia (+689)",
        "value": "689"
    },
    {
        "country": "GA",
        "name": "Gabon (+241)",
        "value": "241"
    },
    {
        "country": "GM",
        "name": "Gambia (+220)",
        "value": "220"
    },
    {
        "country": "GE",
        "name": "Georgia (+7880)",
        "value": "7880"
    },
    {
        "country": "DE",
        "name": "Germany (+49)",
        "value": "49"
    },
    {
        "country": "GH",
        "name": "Ghana (+233)",
        "value": "233"
    },
    {
        "country": "GI",
        "name": "Gibraltar (+350)",
        "value": "350"
    },
    {
        "country": "GR",
        "name": "Greece (+30)",
        "value": "30"
    },
    {
        "country": "GL",
        "name": "Greenland (+299)",
        "value": "299"
    },
    {
        "country": "GD",
        "name": "Grenada (+1473)",
        "value": "1473"
    },
    {
        "country": "GP",
        "name": "Guadeloupe (+590)",
        "value": "590"
    },
    {
        "country": "GU",
        "name": "Guam (+671)",
        "value": "671"
    },
    {
        "country": "GT",
        "name": "Guatemala (+502)",
        "value": "502"
    },
    {
        "country": "GN",
        "name": "Guinea (+224)",
        "value": "224"
    },
    {
        "country": "GW",
        "name": "Guinea - Bissau (+245)",
        "value": "245"
    },
    {
        "country": "GY",
        "name": "Guyana (+592)",
        "value": "592"
    },
    {
        "country": "HT",
        "name": "Haiti (+509)",
        "value": "509"
    },
    {
        "country": "HN",
        "name": "Honduras (+504)",
        "value": "504"
    },
    {
        "country": "HK",
        "name": "Hong Kong (+852)",
        "value": "852"
    },
    {
        "country": "HU",
        "name": "Hungary (+36)",
        "value": "36"
    },
    {
        "country": "IS",
        "name": "Iceland (+354)",
        "value": "354"
    },
    {
        "country": "IN",
        "name": "India (+91)",
        "value": "91"
    },
    {
        "country": "ID",
        "name": "Indonesia (+62)",
        "value": "62"
    },
    {
        "country": "IR",
        "name": "Iran (+98)",
        "value": "98"
    },
    {
        "country": "IQ",
        "name": "Iraq (+964)",
        "value": "964"
    },
    {
        "country": "IE",
        "name": "Ireland (+353)",
        "value": "353"
    },
    {
        "country": "IL",
        "name": "Israel (+972)",
        "value": "972"
    },
    {
        "country": "IT",
        "name": "Italia (+39)",
        "value": "39"
    },
    {
        "country": "JM",
        "name": "Jamaica (+1876)",
        "value": "1876"
    },
    {
        "country": "JP",
        "name": "Japan (+81)",
        "value": "81"
    },
    {
        "country": "JO",
        "name": "Jordan (+962)",
        "value": "962"
    },
    {
        "country": "KZ",
        "name": "Kazakhstan (+7)",
        "value": "7"
    },
    {
        "country": "KE",
        "name": "Kenya (+254)",
        "value": "254"
    },
    {
        "country": "KI",
        "name": "Kiribati (+686)",
        "value": "686"
    },
    {
        "country": "KP",
        "name": "Korea North (+850)",
        "value": "850"
    },
    {
        "country": "KR",
        "name": "Korea South (+82)",
        "value": "82"
    },
    {
        "country": "KW",
        "name": "Kuwait (+965)",
        "value": "965"
    },
    {
        "country": "KG",
        "name": "Kyrgyzstan (+996)",
        "value": "996"
    },
    {
        "country": "LA",
        "name": "Laos (+856)",
        "value": "856"
    },
    {
        "country": "LV",
        "name": "Latvia (+371)",
        "value": "371"
    },
    {
        "country": "LB",
        "name": "Lebanon (+961)",
        "value": "961"
    },
    {
        "country": "LS",
        "name": "Lesotho (+266)",
        "value": "266"
    },
    {
        "country": "LR",
        "name": "Liberia (+231)",
        "value": "231"
    },
    {
        "country": "LY",
        "name": "Libya (+218)",
        "value": "218"
    },
    {
        "country": "LI",
        "name": "Liechtenstein (+417)",
        "value": "417"
    },
    {
        "country": "LT",
        "name": "Lithuania (+370)",
        "value": "370"
    },
    {
        "country": "LU",
        "name": "Luxembourg (+352)",
        "value": "352"
    },
    {
        "country": "MO",
        "name": "Macao (+853)",
        "value": "853"
    },
    {
        "country": "MK",
        "name": "Macedonia (+389)",
        "value": "389"
    },
    {
        "country": "MG",
        "name": "Madagascar (+261)",
        "value": "261"
    },
    {
        "country": "MW",
        "name": "Malawi (+265)",
        "value": "265"
    },
    {
        "country": "MY",
        "name": "Malaysia (+60)",
        "value": "60"
    },
    {
        "country": "MV",
        "name": "Maldives (+960)",
        "value": "960"
    },
    {
        "country": "ML",
        "name": "Mali (+223)",
        "value": "223"
    },
    {
        "country": "MT",
        "name": "Malta (+356)",
        "value": "356"
    },
    {
        "country": "MH",
        "name": "Marshall Islands (+692)",
        "value": "692"
    },
    {
        "country": "MQ",
        "name": "Martinique (+596)",
        "value": "596"
    },
    {
        "country": "MR",
        "name": "Mauritania (+222)",
        "value": "222"
    },
    {
        "country": "YT",
        "name": "Mayotte (+269)",
        "value": "269"
    },
    {
        "country": "MX",
        "name": "Mexico (+52)",
        "value": "52"
    },
    {
        "country": "FM",
        "name": "Micronesia (+691)",
        "value": "691"
    },
    {
        "country": "MD",
        "name": "Moldova (+373)",
        "value": "373"
    },
    {
        "country": "MC",
        "name": "Monaco (+377)",
        "value": "377"
    },
    {
        "country": "MN",
        "name": "Mongolia (+976)",
        "value": "976"
    },
    {
        "country": "MS",
        "name": "Montserrat (+1664)",
        "value": "1664"
    },
    {
        "country": "MA",
        "name": "Morocco (+212)",
        "value": "212"
    },
    {
        "country": "MZ",
        "name": "Mozambique (+258)",
        "value": "258"
    },
    {
        "country": "MN",
        "name": "Myanmar (+95)",
        "value": "95"
    },
    {
        "country": "NA",
        "name": "Namibia (+264)",
        "value": "264"
    },
    {
        "country": "NR",
        "name": "Nauru (+674)",
        "value": "674"
    },
    {
        "country": "NP",
        "name": "Nepal (+977)",
        "value": "977"
    },
    {
        "country": "NL",
        "name": "Netherlands (+31)",
        "value": "31"
    },
    {
        "country": "NC",
        "name": "New Caledonia (+687)",
        "value": "687"
    },
    {
        "country": "NZ",
        "name": "New Zealand (+64)",
        "value": "64"
    },
    {
        "country": "NI",
        "name": "Nicaragua (+505)",
        "value": "505"
    },
    {
        "country": "NE",
        "name": "Niger (+227)",
        "value": "227"
    },
    {
        "country": "NG",
        "name": "Nigeria (+234)",
        "value": "234"
    },
    {
        "country": "NU",
        "name": "Niue (+683)",
        "value": "683"
    },
    {
        "country": "NF",
        "name": "Norfolk Islands (+672)",
        "value": "672"
    },
    {
        "country": "NP",
        "name": "Northern Marianas (+670)",
        "value": "670"
    },
    {
        "country": "NO",
        "name": "Norway (+47)",
        "value": "47"
    },
    {
        "country": "OM",
        "name": "Oman (+968)",
        "value": "968"
    },
    {
        "country": "PW",
        "name": "Palau (+680)",
        "value": "680"
    },
    {
        "country": "PA",
        "name": "Panama (+507)",
        "value": "507"
    },
    {
        "country": "PG",
        "name": "Papua New Guinea (+675)",
        "value": "675"
    },
    {
        "country": "PY",
        "name": "Paraguay (+595)",
        "value": "595"
    },
    {
        "country": "PE",
        "name": "Peru (+51)",
        "value": "51"
    },
    {
        "country": "PH",
        "name": "Philippines (+63)",
        "value": "63"
    },
    {
        "country": "PL",
        "name": "Poland (+48)",
        "value": "48"
    },
    {
        "country": "PT",
        "name": "Portugal (+351)",
        "value": "351"
    },
    {
        "country": "PR",
        "name": "Puerto Rico (+1787)",
        "value": "1787"
    },
    {
        "country": "QA",
        "name": "Qatar (+974)",
        "value": "974"
    },
    {
        "country": "RE",
        "name": "Reunion (+262)",
        "value": "262"
    },
    {
        "country": "RO",
        "name": "Romania (+40)",
        "value": "40"
    },
    {
        "country": "RU",
        "name": "Russia (+7)",
        "value": "7"
    },
    {
        "country": "RW",
        "name": "Rwanda (+250)",
        "value": "250"
    },
    {
        "country": "SM",
        "name": "San Marino (+378)",
        "value": "378"
    },
    {
        "country": "ST",
        "name": "Sao Tome & Principe (+239)",
        "value": "239"
    },
    {
        "country": "SA",
        "name": "Saudi Arabia (+966)",
        "value": "966"
    },
    {
        "country": "SN",
        "name": "Senegal (+221)",
        "value": "221"
    },
    {
        "country": "CS",
        "name": "Serbia (+381)",
        "value": "381"
    },
    {
        "country": "SC",
        "name": "Seychelles (+248)",
        "value": "248"
    },
    {
        "country": "SL",
        "name": "Sierra Leone (+232)",
        "value": "232"
    },
    {
        "country": "SG",
        "name": "Singapore (+65)",
        "value": "65"
    },
    {
        "country": "SK",
        "name": "Slovak Republic (+421)",
        "value": "421"
    },
    {
        "country": "SI",
        "name": "Slovenia (+386)",
        "value": "386"
    },
    {
        "country": "SB",
        "name": "Solomon Islands (+677)",
        "value": "677"
    },
    {
        "country": "SO",
        "name": "Somalia (+252)",
        "value": "252"
    },
    {
        "country": "ZA",
        "name": "South Africa (+27)",
        "value": "27"
    },
    {
        "country": "ES",
        "name": "Spain (+34)",
        "value": "34"
    },
    {
        "country": "LK",
        "name": "Sri Lanka (+94)",
        "value": "94"
    },
    {
        "country": "SH",
        "name": "St. Helena (+290)",
        "value": "290"
    },
    {
        "country": "KN",
        "name": "St. Kitts (+1869)",
        "value": "1869"
    },
    {
        "country": "SC",
        "name": "St. Lucia (+1758)",
        "value": "1758"
    },
    {
        "country": "SD",
        "name": "Sudan (+249)",
        "value": "249"
    },
    {
        "country": "SR",
        "name": "Suriname (+597)",
        "value": "597"
    },
    {
        "country": "SZ",
        "name": "Swaziland (+268)",
        "value": "268"
    },
    {
        "country": "SE",
        "name": "Sweden (+46)",
        "value": "46"
    },
    {
        "country": "CH",
        "name": "Switzerland (+41)",
        "value": "41"
    },
    {
        "country": "SI",
        "name": "Syria (+963)",
        "value": "963"
    },
    {
        "country": "TW",
        "name": "Taiwan (+886)",
        "value": "886"
    },
    {
        "country": "TJ",
        "name": "Tajikstan (+992)",
        "value": "992"
    },
    {
        "country": "TH",
        "name": "Thailand (+66)",
        "value": "66"
    },
    {
        "country": "TG",
        "name": "Togo (+228)",
        "value": "228"
    },
    {
        "country": "TO",
        "name": "Tonga (+676)",
        "value": "676"
    },
    {
        "country": "TT",
        "name": "Trinidad & Tobago (+1868)",
        "value": "1868"
    },
    {
        "country": "TN",
        "name": "Tunisia (+216)",
        "value": "216"
    },
    {
        "country": "TR",
        "name": "Turkey (+90)",
        "value": "90"
    },
    {
        "country": "TM",
        "name": "Turkmenistan (+993)",
        "value": "993"
    },
    {
        "country": "TC",
        "name": "Turks & Caicos Islands (+1649)",
        "value": "1649"
    },
    {
        "country": "TV",
        "name": "Tuvalu (+688)",
        "value": "688"
    },
    {
        "country": "UG",
        "name": "Uganda (+256)",
        "value": "256"
    },
    {
        "country": "GB",
        "name": "UK (+44)",
        "value": "44"
    },
    {
        "country": "UA",
        "name": "Ukraine (+380)",
        "value": "380"
    },
    {
        "country": "AE",
        "name": "United Arab Emirates (+971)",
        "value": "971"
    },
    {
        "country": "UY",
        "name": "Uruguay (+598)",
        "value": "598"
    },
    {
        "country": "US",
        "name": "USA (+1)",
        "value": "1"
    },
    {
        "country": "UZ",
        "name": "Uzbekistan (+998)",
        "value": "998"
    },
    {
        "country": "VU",
        "name": "Vanuatu (+678)",
        "value": "678"
    },
    {
        "country": "VA",
        "name": "Vatican City (+379)",
        "value": "379"
    },
    {
        "country": "VE",
        "name": "Venezuela (+58)",
        "value": "58"
    },
    {
        "country": "VN",
        "name": "Vietnam (+84)",
        "value": "84"
    },
    {
        "country": "VG",
        "name": "Virgin Islands - British (+1284)",
        "value": "1284"
    },
    {
        "country": "VI",
        "name": "Virgin Islands - US (+1340)",
        "value": "1340"
    },
    {
        "country": "WF",
        "name": "Wallis & Futuna (+681)",
        "value": "681"
    },
    {
        "country": "YE",
        "name": "Yemen (North)(+969)",
        "value": "969"
    },
    {
        "country": "YE",
        "name": "Yemen (South)(+967)",
        "value": "967"
    },
    {
        "country": "ZM",
        "name": "Zambia (+260)",
        "value": "260"
    },
    {
        "country": "ZW",
        "name": "Zimbabwe (+263)",
        "value": "263"
    }
];
