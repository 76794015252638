import {Component, Injectable, OnInit, ViewChild, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { CommonModule } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';

type dialogOptions = {
    title: string;
    signupFormFields: Array<any>;
}
@Component({
    selector: 'app-signup-form-dialog',
    templateUrl: './signup-form-dialog.component.html',
    styleUrls: ['./signup-form-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        TranslateModule,
        MatDialogModule,
        FormsModule,
        MatListModule
    ]
})
export class SignupFormDialogComponent implements OnInit {
    protected readonly data: dialogOptions = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    protected readonly dialogTitle = this.data.title || 'Best Products';
    private readonly translateService = inject(TranslateService);
    signupFormFields: Array<any> = [];
    @ViewChild('fields') fields: any;
    selectedOptions: Array<any> = [];

    ngOnInit() {
        this.signupFormFields = this.data?.signupFormFields || [];
    }

    protected confirm() {
        const signupFormFields = [];
        for (const option of this.selectedOptions) {
            const label = this.translateService.instant(option.label);
            const field = {
                name: option.name,
                label: label,
                labelHardcoded: label,
                hidden: false,
                required: false
            };
            signupFormFields.push(field);
        }
        this.matDialogRef.close({signupFormFields});
    }

}

@Injectable({
    providedIn: 'root'
})
export class SignupFormDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(options?: NonNullableConfigData<dialogOptions>) {
        const config: NonNullableConfigData<dialogOptions> = {
            ...this.switchMobileDesktopDimensions({ height: '70vh', width: '800px' }),
            disableClose: true,
            data: {
                title: options?.data?.title || 'Scegli un campo',
                signupFormFields: options?.data?.signupFormFields || []
             },
            ...options
        };

        return lastValueFrom(this.dialogRef.open(SignupFormDialogComponent, config).afterClosed());
    }
}
