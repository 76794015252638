import {Pipe, PipeTransform} from '@angular/core';
import {codeToCountry} from "../../utilities";

@Pipe({
    name: 'codeToCountry',
})
export class CodeToCountryPipe implements PipeTransform {

    transform(countryCode:string): string {
         return codeToCountry('country',countryCode) || '';
    }

}
