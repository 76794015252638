export type RefundCausesType = 'REFUND_CAUSES.QUALITY_NOT_ADEQUATE'|'REFUND_CAUSES.NOT_SUITABLE'|'REFUND_CAUSES.MISSING_PARTS'|'REFUND_CAUSES.FAULTY'|
                                'REFUND_CAUSES.NOT_WANTED'|'REFUND_CAUSES.WRONG_RECEIPT'|'REFUND_CAUSES.OTHER';

export interface RefundCauses {
    id: number;
    translation_id: RefundCausesType;
    showInCashregister: boolean;
}

export const REFUND_CAUSES: RefundCauses[] = [
    {
		"id": 1,
		"translation_id": "REFUND_CAUSES.QUALITY_NOT_ADEQUATE",
		"showInCashregister": true
	}, {
		"id": 2,
		"translation_id": "REFUND_CAUSES.NOT_SUITABLE",
		"showInCashregister": true
	}, {
		"id": 3,
		"translation_id": "REFUND_CAUSES.MISSING_PARTS",
		"showInCashregister": true
	}, {
		"id": 4,
		"translation_id": "REFUND_CAUSES.FAULTY",
		"showInCashregister": true
	}, {
		"id": 5,
		"translation_id": "REFUND_CAUSES.NOT_WANTED",
		"showInCashregister": true
	} ,	{
		"id": 6,
		"translation_id": "REFUND_CAUSES.WRONG_RECEIPT",
		"showInCashregister": false
	} , {
		"id": 7,
		"translation_id": "REFUND_CAUSES.OTHER",
		"showInCashregister": true
	}
];
