import * as angular from 'angular';
import * as async from 'async';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { countryCodes } from 'src/app/core/constants/country-codes';
import { v4 as generateUuid } from 'uuid';

angular.module('application').factory('addSelectCustomer', addSelectCustomer);

addSelectCustomer.$inject = ["$mdDialog", "$state", "$timeout", "$filter", "$translate", "entityManager", "restManager", "util", "fiscalUtils", "connection", "checkManager", "confirmDialog", "customerUtils"];

function addSelectCustomer($mdDialog, $state, $timeout, $filter, $translate, entityManager, restManager, util, fiscalUtils, connection, checkManager, confirmDialog, customerUtils) {
    addSelectCustomerController.$inject = ["$scope", "$mdDialog", "currentCustomer", "options"];

    function addSelectCustomerController($scope, $mdDialog, currentCustomer, options) {
        const currentState = $state.current.name.split('.')[1];
        $scope.fidelityLabel = checkManager.getPreference('fidelity.fidelity_label') || $translate.instant("APPLICATION.ADD_SELECT_CUSTOMER.FIDELITY");

        if(!_.isObject(options)) {
            options = {};
        }

        const getCustomerTemplate = () => {
            const shopCountry = checkManager.getShopCountry(); 

            return {
                type: 'private',
                gender: 'M',
                country: shopCountry,
                shipping_country: shopCountry,
                billing_country: shopCountry,
                first_name: checkManager.getPreference("customers.default_first_name") || null,
                last_name: checkManager.getPreference("customers.default_last_name") || null,
                send_commercials: true,
                disable_mail_receipts: null,
                uuid: generateUuid()
            };
        };

        $scope.context = 'select';
        $scope.searchEnabled = false;
        $scope.searchText = "";
        $scope.customers = [];
        $scope.data = getCustomerTemplate();
        entityManager.customers.fetchCollectionOffline().then(function(customers) {
            $scope.customers = customers || [];
            $scope.searchCustomers();
        });
        $scope.foundCustomers = [];
        $scope.connection = connection;
        $scope.customerPoints = null;
        $scope.vatValidateStatus = 'default';
        $scope.vatRegExp = util.getVatRegexp();
        $scope.country = checkManager.getShopCountry();
        $scope.provMaxLength = ['IT', 'MT'].includes($scope.country) ? 2 : 255;
        $scope.countryCodes = _.map(countryCodes, function(val, key) { return { code: key, name: val.name }; });
        $scope.countryCodesDict = _.keyBy($scope.countryCodes, 'code');
        $scope.shippingAddressRange = _.range(1, 11);
        $scope.shippingAddressTmp = {};
        $scope.currentShippingAddress = 1;

        var activeCampaign = null;
        var isQuickAdding = false;

        //Setup custom type
        if(checkManager.getPreference("customers.custom_types")) {
            $scope.customTypes = _.split(checkManager.getPreference("customers.custom_types"), '\n');
            $scope.hasCustomTypes = true;
        } else {
            $scope.customTypes = [];
            $scope.hasCustomTypes = false;
        }

        var parseCustomerData = function(customer) {
            if (customer.first_name) {
                var bDate = Date.parse(customer.birthdate);
                customer.birthdate = !_.isNaN(bDate) ? new Date(bDate) : null;
                customer.type = 'private';
            } else if (customer.company_name) {
                customer.type = 'company';
            } else if (customer.tax_code) {
                customer.type = 'private';
            }

            if(customer.billing_country) {
                customer._billingCountry =  $scope.countryCodesDict[customer.billing_country];
            }

            if($scope.addCustomerForm) {
                $scope.validateTaxCode($scope.addCustomerForm.taxCode);
            }
        };

        var resetForm = function(form) {
            form.$setPristine();
            form.$setUntouched();

            _.forEach(form, function(control, key) {
                // filter out angular properties
                if (key.indexOf('$') === -1) {
                    control.$setViewValue(undefined);
                    control.$$setModelValue(undefined);
                }
            });
        };

        var saveShippingAddress = function(slot) {
            var targetCustomer = $scope.data;
            var sourceCustomer = $scope.shippingAddressTmp;
            var slotSuffix = slot === 1 ? "" : "_" + (slot - 1);

            _.set(targetCustomer, 'shipping_street' + slotSuffix, sourceCustomer.shipping_street);
            _.set(targetCustomer, 'shipping_number' + slotSuffix, sourceCustomer.shipping_number);
            _.set(targetCustomer, 'shipping_zip' + slotSuffix, sourceCustomer.shipping_zip);
            _.set(targetCustomer, 'shipping_city' + slotSuffix, sourceCustomer.shipping_city);
            _.set(targetCustomer, 'shipping_prov' + slotSuffix, sourceCustomer.shipping_prov);
            _.set(targetCustomer, 'shipping_country' + slotSuffix, _.get(sourceCustomer, '_shippingCountry.code'));
        };

        var loadShippingAddress = function(slot) {
            var sourceCustomer = $scope.data;
            var slotSuffix = slot === 1 ? "" : "_" + (slot - 1);
            angular.copy({}, $scope.shippingAddressTmp);

            _.assign($scope.shippingAddressTmp, {
                shipping_prov: _.get(sourceCustomer, 'shipping_prov' + slotSuffix),
                shipping_street: _.get(sourceCustomer, 'shipping_street' + slotSuffix),
                shipping_number: _.get(sourceCustomer, 'shipping_number' + slotSuffix),
                shipping_zip: _.get(sourceCustomer, 'shipping_zip' + slotSuffix),
                shipping_city: _.get(sourceCustomer, 'shipping_city' + slotSuffix),
                _shippingCountry: $scope.countryCodesDict[_.get(sourceCustomer, 'shipping_country' + slotSuffix)]
            });
        };

        $scope.$watch('currentShippingAddress', function(newSlot, oldSlot) {
            if(newSlot !== oldSlot) {
                saveShippingAddress(oldSlot);
                $scope.shippingCountrySearch = "";
                loadShippingAddress(newSlot);
            }
        });

        $scope.getShippingAddress = function(slot) {
            var slotSuffix;
            var source;
            var result = "";

            if(slot === $scope.currentShippingAddress) {
                source = $scope.shippingAddressTmp;
                slotSuffix = "";
            } else {
                source = $scope.data;
                slotSuffix = slot === 1 ? "" : "_" + (slot - 1);
            }

            if(!_.isEmpty(source['shipping_street' + slotSuffix])) {
                result += " - " + source['shipping_street' + slotSuffix] + " ";
                result += (source['shipping_number' + slotSuffix] || "") + " ";
                result += (source['shipping_zip' + slotSuffix] || "") + " ";
                result += (source['shipping_city' + slotSuffix] || "") + " ";
                result += (source['shipping_prov' + slotSuffix] ? "(" +  source['shipping_prov' + slotSuffix] + ")" : "") + " ";
            }

            return result;
        };

        $scope.getCustomerCaption = function(customer) {
            var customerCaption = util.getCustomerCaption(customer);

            if(_.isNil(customer.id)) {
                customerCaption += ' (' + $translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.QUICK_ADD') + ')';
            }

            return customerCaption;
        };

        $scope.getCustomerAddress = function(customer) {
            if(currentState === 'orders') {
                for(var i = 0; i <= 9; i++) {
                    var suffix = (i === 0 ? '' : '_' + i);

                    if(customer['shipping_street' + suffix]) {
                        return customer['shipping_street' + suffix] + " " +
                            (customer['shipping_number' + suffix] ? (customer['shipping_number' + suffix] + " ") : "") +
                            (customer['shipping_zip' + suffix] ? (customer['shipping_zip' + suffix] + " ") : "") +
                            (customer['shipping_city' + suffix] ? customer['shipping_city' + suffix] + " " : "") +
                            (customer['shipping_prov' + suffix] ? "(" + customer['shipping_prov' + suffix] + ")" : "");
                    }
                }
            } else {
                if(customer.billing_street) {
                    return customer.billing_street + " " + (customer.billing_number ? (customer.billing_number + " ") : "") + (customer.billing_zip ? (customer.billing_zip + " ") : "") + (customer.billing_city ? customer.billing_city + " " : "") + (customer.billing_prov ? "(" + customer.billing_prov + ")" : "");
                }
            }
        };

        var getPointsForCustomer = function(customer) {
            if (activeCampaign && customer.fidelity && !!checkManager.getPreference("cashregister.show_fidelity_points")) {
                entityManager.fidelitiesPoints.fetchCollectionOffline({ fidelity: customer.fidelity }).then(function(fidelitiesPoints) {
                    $scope.customerPoints = _.find(fidelitiesPoints, { campaign_id: activeCampaign.id });
                });
            }
        };

        var getCustomerCredit = function(customer) {
            if(customer && customer.uuid && customer.fidelity) {
                var currentUuid = customer.uuid; //Keep track of the current uuid to avoid showing someone else's credit
                restManager.getList('prepaid_movements', {
                    customer_uuid: currentUuid,
                    valid_to: 'null',
                    pagination: 'false'
                }).then(function(movements) {
                    if(currentUuid === customer.uuid) {
                        if(!_.isEmpty(movements)) {
                            var lastMovement = _.head(movements);
                            customer.$prepaidCredit = (lastMovement.credit + lastMovement.ticket_credit);
                        }
                    } else {
                        customer.$prepaidCredit = "0";
                    }
                });
            }
        };

        $scope.isOffline = function() {
            return connection.isOffline();
        };

        $scope.isOnline = function() {
            return connection.isOnline();
        };

        $scope.filterCountry = function(searchText) {
            return $filter('filter')($scope.countryCodes, searchText);
        };

        $scope.onVatCodeChange = function(form) {
            $scope.vatValidateStatus = 'default';
            $scope.vatValidateError = null;
        };

        $scope.searchVat = function(form) {
            if ($scope.vatValidateStatus === 'default') {
                customerUtils.getVatInfo($scope.data.vat_code).then(function(result) {
                    if(result) {
                        _.assign($scope.data, result);
                        $scope.vatValidateStatus = 'found';
                        $("md-dialog-content:visible").scrollTop(0);

                        if(result.billing_country) {
                            _.assign($scope.data, {
                                _billingCountry:  $scope.countryCodesDict[result.billing_country]
                            });
                        }
                    } else {
                        $timeout(function() {
                            $scope.vatValidateStatus = 'invalid';
                            $scope.vatValidateError = $translate.instant('APPLICATION.VAT_VALIDATOR.NOT_FOUND');
                        });
                    }
                }).catch(function(error) {
                    var errorCode = _.get(error, 'data.error.message.code');

                    if(errorCode) {
                        $timeout(function() {
                            $scope.vatValidateError = $translate.instant('APPLICATION.VAT_VALIDATOR.' + errorCode);
                        });
                    }
                });
            }
        };

        $scope.onFidelityScanned = function(result) {
            $scope.data['fidelity'] = result;
        };

        $scope.searchCustomers = function() {
            if ($scope.searchText.length < 3) {
                $scope.foundCustomers = [];
            } else {
                performSearch();
            }
        };

        var performSearch = function() {
            var searchWords = _.words($scope.searchText);
            $scope.foundCustomers = $scope.customers;
            _.each(searchWords, function(word) {
                word = _.toLower(word);
                $scope.foundCustomers = _.filter($scope.foundCustomers, function(customer) {
                    return _.includes(_.toLower(customer.first_name), word) || _.includes(_.toLower(customer.company_name), word) || _.includes(_.toLower(customer.last_name), word) || _.includes(_.toLower(customer.vat_code), word) || _.includes(_.toLower(customer.tax_code), word) || _.includes(_.toLower(customer.fidelity), word) || _.includes(_.toLower(customer.email), word) || _.includes(_.toLower(customer.phone), word) || _.includes(_.toLower(customer.billing_street), word);
                });
            });
            $scope.foundCustomers = _.sortBy($scope.foundCustomers, ['last_name', 'company_name']);

            var searchVatRegExp = util.getVatRegexp(true);

            if(searchVatRegExp.test($scope.searchText)) {
                customerUtils.getVatInfo($scope.searchText).then(function(result) {
                    if(result) {
                        $scope.foundCustomers.push(result);
                    }
                });
            }
        };

        $scope.validateTaxCode = function(taxCodeField) {
            taxCodeField.$setValidity('taxCode', _.isEmpty($scope.data.tax_code) || !$scope.isPrivateCustomer() || fiscalUtils.checkTaxCode($scope.data.tax_code));
        };

        $scope.onCustomerTypeChange = function(form) {
            $scope.validateTaxCode(form.taxCode);
        };

        $scope.isSelectingCustomer = function() {
            return $scope.context === "select";
        };

        $scope.isCreatingCustomer = function() {
            return $scope.context === "add";
        };

        $scope.isEditingCustomer = function() {
            return $scope.context === "edit";
        };

        $scope.isCustomerSelected = function() {
            return ($scope.currentCustomer && ($scope.currentCustomer.id || $scope.currentCustomer.customer_id));
        };

        $scope.startSearch = function() {
            $scope.searchEnabled = true;
            $timeout(function() {
                angular.element('#customerSearchInput').trigger('focus');
            });
        };

        $scope.cancelSearch = function() {
            $scope.searchEnabled = false;
            $scope.searchText = "";
        };

        $scope.clearSearchText = function() {
            $scope.searchText = "";
            $scope.foundCustomers = [];
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.addCustomerMode = function() {
            $scope.cancelSearch();
            $scope.context = "add";
        };

        $scope.cancelAddCustomer = function(form) {
            resetForm(form);
            angular.copy(getCustomerTemplate(), $scope.data);
            parseCustomerData($scope.data);
            loadShippingAddress(1);
            $scope.context = "select";
        };

        $scope.isPrivateCustomer = function() {
            return $scope.data.type === 'private';
        };

        $scope.confirm = function(form) {
            if ($scope.isSelectingCustomer()) {
                if ($scope.currentCustomer.uuid || $scope.currentCustomer.id) {
                    $mdDialog.hide($scope.currentCustomer);
                } else {
                    $mdDialog.cancel();
                }
            } else if ($scope.isCreatingCustomer() || $scope.isEditingCustomer()) {
                $scope.confirmCustomer(form);
            }
        };

        $scope.isCompany = function() {
            return $scope.data.type === 'company';
        };

        $scope.confirmCustomer = function(form) {
            saveShippingAddress($scope.currentShippingAddress);
            var tmpCustomer = _.cloneDeep($scope.data);

            if ($scope.isPrivateCustomer()) {
                delete tmpCustomer.company_name;
            } else {
                delete tmpCustomer.first_name;
                delete tmpCustomer.last_name;
                delete tmpCustomer.gender;
                delete tmpCustomer.birthdate;
                delete tmpCustomer.mobile;
                delete tmpCustomer.phone;
            }

            if (tmpCustomer.birthdate) {
                try {
                    tmpCustomer.birthdate = tmpCustomer.birthdate.toISOString();
                } catch (err) {
                    delete tmpCustomer.birthdate;
                }
            }

            if(_.isEmpty(tmpCustomer.sdi_code)) {
                delete tmpCustomer.sdi_code;
            }

            tmpCustomer.country =  _.get(tmpCustomer, '_country.code') || null;
            tmpCustomer.billing_country =  _.get(tmpCustomer, '_billingCountry.code') || null;

            async.series([
                function(lotteryCheckDone) {
                    if(tmpCustomer.lottery_code !== $scope.currentCustomer.lottery_code && !_.isEmpty(tmpCustomer.lottery_code)) {
                        confirmDialog.show($translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE'), {
                            yesLabel: $translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE_YES'),
                            noLabel: $translate.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE_NO')
                        }).then(function(answer) {
                            if(answer) {
                                lotteryCheckDone();
                            } else {
                                lotteryCheckDone(true); //Return error and stop the save
                            }
                        });
                    } else {
                        lotteryCheckDone();
                    }
                }, function(fidelityCheckDone) {
                    if (tmpCustomer.fidelity) {
                        if (connection.isOnline()) {
                            var fidelityErr;

                            entityManager.customers.fetchCollectionOnline({ fidelity: tmpCustomer.fidelity }).then(function(results) {
                                if(!_.isEmpty(results) && ($scope.isCreatingCustomer() || !_.some(results, { id: tmpCustomer.id }))) {
                                    fidelityErr = true;
                                    form.fidelity.$setValidity('alreadyExists', false);
                                }
                            }).catch(function(e) {
                                fidelityErr = true;
                            }).finally(function() {
                                fidelityCheckDone(fidelityErr);
                            });
                        } else {
                            tmpCustomer.fidelity = $scope.currentCustomer.fidelity;
                            fidelityCheckDone();
                        }
                    } else {
                        fidelityCheckDone();
                    }
                }
            ],function(err, results) {
                if(!err) {
                    if ($scope.isEditingCustomer()) {
                        entityManager.customers.putOneOfflineFirst(tmpCustomer).then(function(result) {
                            angular.copy(result, $scope.currentCustomer);
                            $scope.cancelAddCustomer(form);
                        });
                    } else if ($scope.isCreatingCustomer()) {
                        entityManager.customers.postOneOfflineFirst(tmpCustomer).then(function(result) {
                            $scope.customers.push(result);
                            $scope.selectCustomer(result);
                            $scope.cancelAddCustomer(form);
                        });
                    }
                }
            });
        };

        $scope.selectCustomer = function(customer) {
            async.series([function(cb) {
                if(_.isNil(customer.id)) {
                    if(!isQuickAdding) {
                        isQuickAdding = true;

                        entityManager.customers.postOneOnline(customer).then(function(result) {
                            angular.copy(result, customer);
                            cb(null);
                        }, cb).finally(function() {
                            isQuickAdding = false;
                        });
                    } else {
                        cb(true);
                    }
                } else {
                    cb();
                }
            }], function(err, res) {
                if(!err) {
                    $scope.currentCustomer = customer;
                    $scope.customerPoints = null;

                    getPointsForCustomer(customer);
                    getCustomerCredit(customer);
                    $scope.searchEnabled = false;
                }
            });
        };

        $scope.editCustomer = function() {
            var customerToEdit = _.cloneDeep($scope.currentCustomer);
            parseCustomerData(customerToEdit);
            $scope.cancelSearch();
            $scope.data = customerToEdit;

            $scope.currentShippingAddress = 1;
            loadShippingAddress($scope.currentShippingAddress);
            $scope.context = "edit";
        };

        $scope.onShowComplete = function() {
            if (!options.newCustomerData && !currentCustomer) {
                $scope.startSearch();
            }
        };

        //Init customer data
        $timeout(function() {
            $scope.currentCustomer = currentCustomer ? _.cloneDeep(currentCustomer) : {};
            var customerId = $scope.currentCustomer.customer_id || $scope.currentCustomer.uuid;

            if (customerId) {
                entityManager.customers.fetchOneOffline(customerId).then(function(result) {
                    if(result) {
                        angular.copy(result, $scope.currentCustomer);
                    } else {
                        $scope.currentCustomer.id = customerId;
                        $scope.currentCustomer.customer_id = null;
                    }

                    if(options.editMode) {
                        $scope.editCustomer();
                    }
                });
            } else if (_.isObject(options.newCustomerData)) {
                _.assign($scope.data, options.newCustomerData);
                $scope.context = 'add';
                $scope.addCustomerForm.$setDirty();
            } else {
                loadShippingAddress(1);
                parseCustomerData($scope.data);
                $scope.addCustomerForm.$setDirty();
            }

            if (checkManager.getPreference("cashregister.show_fidelity_points")) {
                entityManager.campaigns.fetchCollectionOnline({}).then(function(campaigns) {
                    activeCampaign = _.find(campaigns, { isValid: true });
                    getPointsForCustomer($scope.currentCustomer);
                    getCustomerCredit($scope.customer);
                });

                $scope.$on("fidelity-updated", function(event, fidelityPoints) {
                    if ($scope.customerPoints && fidelityPoints.id === $scope.customerPoints.id) {
                        _.assign($scope.customerPoints, fidelityPoints);
                    }
                });
            }

            if(checkManager.isFunctionEnabledOptin("prepaid.enabled")) {
                $scope.$on('prepaid-updated', function(event, prepaidInfo) {
                    if(prepaidInfo.customer_uuid === _.get($scope, 'currentCustomer.uuid')) {
                        $scope.currentCustomer.$prepaidCredit = (prepaidInfo.credit + prepaidInfo.ticket_credit);
                    }
                });
            }
        });

        $scope.$on("OfflineFirst-customers:completed", function(event, data) {
            var replaceCustomerIds = function(customer) {
                if (data.uuid === customer.id) {
                    customer.id = data.entity.id;
                }
            };
            replaceCustomerIds($scope.currentCustomer);
            var found = _.find($scope.customers, {
                id: data.uuid
            });
            if (found) {
                replaceCustomerIds(found);
            }
        });

        $scope.$on("connection:changed", function(event, data) {
            if(event.status === 'offline') {
                if ($scope.isCreatingCustomer()) {
                    $scope.data.fidelity = "";
                }
                if ($scope.isEditingCustomer()) {
                    $scope.data.fidelity = $scope.currentCustomer.fidelity;
                }
            }
        });
    }

    var addSelectCustomer = {
        show: function(currentCustomer, options) {
            return $mdDialog.show({
                controller: addSelectCustomerController,
                template: require('./add-select-customer.html'),
                multiple: true,
                locals: {
                    currentCustomer: currentCustomer,
                    options: options
                },
                onComplete: function(scope) {
                    scope.onShowComplete();
                    //check if we have a dialog from Angular 2+ and, if so, increase the z-index
                    if($('mat-dialog-container').length > 0) {
                        $(".md-dialog-container").css("z-index", 1000);
                    }
                }
            });
        }
    };

    return addSelectCustomer;
}
