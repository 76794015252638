@if ({
    saleUpdates:(saleUpdates$ | async)
}; as data) {

        <!--HEADER-->
        <app-active-sale-header-info-wrapper [sale]="data.saleUpdates?.currentSale" [isPaymentsState]="isPaymentsState"/>

        <!--SHOP_CART/PAYMENT_LIST-->
        <div #shopCart class="tw-border-solid tw-border tw-border-gray-200 tw-rounded-lg tw-overflow-y-hidden tw-flex-1">
            @if(isPaymentsState){
                <app-payments-list-wrapper [sale]="data.saleUpdates?.currentSale"/>
            } @else {
                <app-shop-cart-wrapper [saleUpdates]="data.saleUpdates!"/>
            }
        </div>

        <!--BOTTOM_BUTTONS-->
        @if(isPaymentsState && data.saleUpdates?.currentSale){
            <app-payments-action-buttons-wrapper [sale]="data.saleUpdates?.currentSale"/>
        } @else if(data.saleUpdates?.currentSale || (!isPaymentsState && sideKeyboard())){
             <app-active-sale-action-buttons-wrapper [saleUpdates]="data.saleUpdates!" />
        }
}
